/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
"use strict";

var $protobuf = require("protobufjs/minimal");

// Common aliases
var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

$root.fastcity = (function() {

    /**
     * Namespace fastcity.
     * @exports fastcity
     * @namespace
     */
    var fastcity = {};

    fastcity.admin = (function() {

        /**
         * Namespace admin.
         * @memberof fastcity
         * @namespace
         */
        var admin = {};

        admin.Activate = (function() {

            /**
             * Properties of an Activate.
             * @memberof fastcity.admin
             * @interface IActivate
             * @property {string|null} [activationId] Activate activationId
             */

            /**
             * Constructs a new Activate.
             * @memberof fastcity.admin
             * @classdesc Represents an Activate.
             * @implements IActivate
             * @constructor
             * @param {fastcity.admin.IActivate=} [properties] Properties to set
             */
            function Activate(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Activate activationId.
             * @member {string} activationId
             * @memberof fastcity.admin.Activate
             * @instance
             */
            Activate.prototype.activationId = "";

            /**
             * Creates a new Activate instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.Activate
             * @static
             * @param {fastcity.admin.IActivate=} [properties] Properties to set
             * @returns {fastcity.admin.Activate} Activate instance
             */
            Activate.create = function create(properties) {
                return new Activate(properties);
            };

            /**
             * Encodes the specified Activate message. Does not implicitly {@link fastcity.admin.Activate.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.Activate
             * @static
             * @param {fastcity.admin.IActivate} message Activate message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Activate.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.activationId != null && Object.hasOwnProperty.call(message, "activationId"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.activationId);
                return writer;
            };

            /**
             * Decodes an Activate message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.Activate
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.Activate} Activate
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Activate.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.Activate();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.activationId = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an Activate message.
             * @function verify
             * @memberof fastcity.admin.Activate
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Activate.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.activationId != null && message.hasOwnProperty("activationId"))
                    if (!$util.isString(message.activationId))
                        return "activationId: string expected";
                return null;
            };

            /**
             * Creates an Activate message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.Activate
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.Activate} Activate
             */
            Activate.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.Activate)
                    return object;
                var message = new $root.fastcity.admin.Activate();
                if (object.activationId != null)
                    message.activationId = String(object.activationId);
                return message;
            };

            /**
             * Creates a plain object from an Activate message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.Activate
             * @static
             * @param {fastcity.admin.Activate} message Activate
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Activate.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.activationId = "";
                if (message.activationId != null && message.hasOwnProperty("activationId"))
                    object.activationId = message.activationId;
                return object;
            };

            /**
             * Converts this Activate to JSON.
             * @function toJSON
             * @memberof fastcity.admin.Activate
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Activate.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Activate;
        })();

        /**
         * AdminRequestResult enum.
         * @name fastcity.admin.AdminRequestResult
         * @enum {number}
         * @property {number} ARR_NOT_FOUND=0 ARR_NOT_FOUND value
         * @property {number} ARR_ALREADY_EXIST=1 ARR_ALREADY_EXIST value
         * @property {number} ARR_BAD_LOGIN=2 ARR_BAD_LOGIN value
         * @property {number} ARR_BAD_COMPANY_ID_COMPANY_NOT_FOUND=3 ARR_BAD_COMPANY_ID_COMPANY_NOT_FOUND value
         * @property {number} ARR_BAD_COMPANY_ID_COMPANY_IS_DELETED=4 ARR_BAD_COMPANY_ID_COMPANY_IS_DELETED value
         * @property {number} ARR_BAD_NAME=5 ARR_BAD_NAME value
         * @property {number} ARR_BAD_SUB_COMPANY_ID_SUB_COMPANY_NOT_FOUND=6 ARR_BAD_SUB_COMPANY_ID_SUB_COMPANY_NOT_FOUND value
         * @property {number} ARR_BAD_SUB_COMPANY_ID_SUB_COMPANY_IS_DELETED=7 ARR_BAD_SUB_COMPANY_ID_SUB_COMPANY_IS_DELETED value
         * @property {number} ARR_BAD_SUB_COMPANY_ID_MUST_BE_AT_LEAST_ONE_ID=8 ARR_BAD_SUB_COMPANY_ID_MUST_BE_AT_LEAST_ONE_ID value
         * @property {number} ARR_BAD_EMAIL=9 ARR_BAD_EMAIL value
         * @property {number} ARR_BAD_ROLE_EMPTY=10 ARR_BAD_ROLE_EMPTY value
         * @property {number} ARR_SUPER_ADMIN_HAVE_NO_COMPANY_ID=11 ARR_SUPER_ADMIN_HAVE_NO_COMPANY_ID value
         */
        admin.AdminRequestResult = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "ARR_NOT_FOUND"] = 0;
            values[valuesById[1] = "ARR_ALREADY_EXIST"] = 1;
            values[valuesById[2] = "ARR_BAD_LOGIN"] = 2;
            values[valuesById[3] = "ARR_BAD_COMPANY_ID_COMPANY_NOT_FOUND"] = 3;
            values[valuesById[4] = "ARR_BAD_COMPANY_ID_COMPANY_IS_DELETED"] = 4;
            values[valuesById[5] = "ARR_BAD_NAME"] = 5;
            values[valuesById[6] = "ARR_BAD_SUB_COMPANY_ID_SUB_COMPANY_NOT_FOUND"] = 6;
            values[valuesById[7] = "ARR_BAD_SUB_COMPANY_ID_SUB_COMPANY_IS_DELETED"] = 7;
            values[valuesById[8] = "ARR_BAD_SUB_COMPANY_ID_MUST_BE_AT_LEAST_ONE_ID"] = 8;
            values[valuesById[9] = "ARR_BAD_EMAIL"] = 9;
            values[valuesById[10] = "ARR_BAD_ROLE_EMPTY"] = 10;
            values[valuesById[11] = "ARR_SUPER_ADMIN_HAVE_NO_COMPANY_ID"] = 11;
            return values;
        })();

        admin.Admin = (function() {

            /**
             * Properties of an Admin.
             * @memberof fastcity.admin
             * @interface IAdmin
             * @property {string|null} [login] Admin login
             * @property {number|Long|null} [companyId] Admin companyId
             * @property {string|null} [name] Admin name
             * @property {boolean|null} [isDeleted] Admin isDeleted
             * @property {Array.<number|Long>|null} [subCompanyId] Admin subCompanyId
             * @property {string|null} [email] Admin email
             * @property {fastcity.common.Company.MapType|null} [mapType] Admin mapType
             * @property {Array.<fastcity.common.UserRole>|null} [roles] Admin roles
             */

            /**
             * Constructs a new Admin.
             * @memberof fastcity.admin
             * @classdesc Represents an Admin.
             * @implements IAdmin
             * @constructor
             * @param {fastcity.admin.IAdmin=} [properties] Properties to set
             */
            function Admin(properties) {
                this.subCompanyId = [];
                this.roles = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Admin login.
             * @member {string} login
             * @memberof fastcity.admin.Admin
             * @instance
             */
            Admin.prototype.login = "";

            /**
             * Admin companyId.
             * @member {number|Long} companyId
             * @memberof fastcity.admin.Admin
             * @instance
             */
            Admin.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Admin name.
             * @member {string} name
             * @memberof fastcity.admin.Admin
             * @instance
             */
            Admin.prototype.name = "";

            /**
             * Admin isDeleted.
             * @member {boolean} isDeleted
             * @memberof fastcity.admin.Admin
             * @instance
             */
            Admin.prototype.isDeleted = false;

            /**
             * Admin subCompanyId.
             * @member {Array.<number|Long>} subCompanyId
             * @memberof fastcity.admin.Admin
             * @instance
             */
            Admin.prototype.subCompanyId = $util.emptyArray;

            /**
             * Admin email.
             * @member {string} email
             * @memberof fastcity.admin.Admin
             * @instance
             */
            Admin.prototype.email = "";

            /**
             * Admin mapType.
             * @member {fastcity.common.Company.MapType} mapType
             * @memberof fastcity.admin.Admin
             * @instance
             */
            Admin.prototype.mapType = 0;

            /**
             * Admin roles.
             * @member {Array.<fastcity.common.UserRole>} roles
             * @memberof fastcity.admin.Admin
             * @instance
             */
            Admin.prototype.roles = $util.emptyArray;

            /**
             * Creates a new Admin instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.Admin
             * @static
             * @param {fastcity.admin.IAdmin=} [properties] Properties to set
             * @returns {fastcity.admin.Admin} Admin instance
             */
            Admin.create = function create(properties) {
                return new Admin(properties);
            };

            /**
             * Encodes the specified Admin message. Does not implicitly {@link fastcity.admin.Admin.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.Admin
             * @static
             * @param {fastcity.admin.IAdmin} message Admin message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Admin.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.login != null && Object.hasOwnProperty.call(message, "login"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.login);
                if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.companyId);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
                if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                    writer.uint32(/* id 4, wireType 0 =*/32).bool(message.isDeleted);
                if (message.subCompanyId != null && message.subCompanyId.length) {
                    writer.uint32(/* id 5, wireType 2 =*/42).fork();
                    for (var i = 0; i < message.subCompanyId.length; ++i)
                        writer.uint64(message.subCompanyId[i]);
                    writer.ldelim();
                }
                if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.email);
                if (message.mapType != null && Object.hasOwnProperty.call(message, "mapType"))
                    writer.uint32(/* id 7, wireType 0 =*/56).int32(message.mapType);
                if (message.roles != null && message.roles.length) {
                    writer.uint32(/* id 8, wireType 2 =*/66).fork();
                    for (var i = 0; i < message.roles.length; ++i)
                        writer.int32(message.roles[i]);
                    writer.ldelim();
                }
                return writer;
            };

            /**
             * Decodes an Admin message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.Admin
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.Admin} Admin
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Admin.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.Admin();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.login = reader.string();
                        break;
                    case 2:
                        message.companyId = reader.uint64();
                        break;
                    case 3:
                        message.name = reader.string();
                        break;
                    case 4:
                        message.isDeleted = reader.bool();
                        break;
                    case 5:
                        if (!(message.subCompanyId && message.subCompanyId.length))
                            message.subCompanyId = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.subCompanyId.push(reader.uint64());
                        } else
                            message.subCompanyId.push(reader.uint64());
                        break;
                    case 6:
                        message.email = reader.string();
                        break;
                    case 7:
                        message.mapType = reader.int32();
                        break;
                    case 8:
                        if (!(message.roles && message.roles.length))
                            message.roles = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.roles.push(reader.int32());
                        } else
                            message.roles.push(reader.int32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an Admin message.
             * @function verify
             * @memberof fastcity.admin.Admin
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Admin.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.login != null && message.hasOwnProperty("login"))
                    if (!$util.isString(message.login))
                        return "login: string expected";
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                        return "companyId: integer|Long expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.isDeleted != null && message.hasOwnProperty("isDeleted"))
                    if (typeof message.isDeleted !== "boolean")
                        return "isDeleted: boolean expected";
                if (message.subCompanyId != null && message.hasOwnProperty("subCompanyId")) {
                    if (!Array.isArray(message.subCompanyId))
                        return "subCompanyId: array expected";
                    for (var i = 0; i < message.subCompanyId.length; ++i)
                        if (!$util.isInteger(message.subCompanyId[i]) && !(message.subCompanyId[i] && $util.isInteger(message.subCompanyId[i].low) && $util.isInteger(message.subCompanyId[i].high)))
                            return "subCompanyId: integer|Long[] expected";
                }
                if (message.email != null && message.hasOwnProperty("email"))
                    if (!$util.isString(message.email))
                        return "email: string expected";
                if (message.mapType != null && message.hasOwnProperty("mapType"))
                    switch (message.mapType) {
                    default:
                        return "mapType: enum value expected";
                    case 0:
                    case 1:
                        break;
                    }
                if (message.roles != null && message.hasOwnProperty("roles")) {
                    if (!Array.isArray(message.roles))
                        return "roles: array expected";
                    for (var i = 0; i < message.roles.length; ++i)
                        switch (message.roles[i]) {
                        default:
                            return "roles: enum value[] expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                            break;
                        }
                }
                return null;
            };

            /**
             * Creates an Admin message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.Admin
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.Admin} Admin
             */
            Admin.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.Admin)
                    return object;
                var message = new $root.fastcity.admin.Admin();
                if (object.login != null)
                    message.login = String(object.login);
                if (object.companyId != null)
                    if ($util.Long)
                        (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = true;
                    else if (typeof object.companyId === "string")
                        message.companyId = parseInt(object.companyId, 10);
                    else if (typeof object.companyId === "number")
                        message.companyId = object.companyId;
                    else if (typeof object.companyId === "object")
                        message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber(true);
                if (object.name != null)
                    message.name = String(object.name);
                if (object.isDeleted != null)
                    message.isDeleted = Boolean(object.isDeleted);
                if (object.subCompanyId) {
                    if (!Array.isArray(object.subCompanyId))
                        throw TypeError(".fastcity.admin.Admin.subCompanyId: array expected");
                    message.subCompanyId = [];
                    for (var i = 0; i < object.subCompanyId.length; ++i)
                        if ($util.Long)
                            (message.subCompanyId[i] = $util.Long.fromValue(object.subCompanyId[i])).unsigned = true;
                        else if (typeof object.subCompanyId[i] === "string")
                            message.subCompanyId[i] = parseInt(object.subCompanyId[i], 10);
                        else if (typeof object.subCompanyId[i] === "number")
                            message.subCompanyId[i] = object.subCompanyId[i];
                        else if (typeof object.subCompanyId[i] === "object")
                            message.subCompanyId[i] = new $util.LongBits(object.subCompanyId[i].low >>> 0, object.subCompanyId[i].high >>> 0).toNumber(true);
                }
                if (object.email != null)
                    message.email = String(object.email);
                switch (object.mapType) {
                case "GOOGLE":
                case 0:
                    message.mapType = 0;
                    break;
                case "YANDEX":
                case 1:
                    message.mapType = 1;
                    break;
                }
                if (object.roles) {
                    if (!Array.isArray(object.roles))
                        throw TypeError(".fastcity.admin.Admin.roles: array expected");
                    message.roles = [];
                    for (var i = 0; i < object.roles.length; ++i)
                        switch (object.roles[i]) {
                        default:
                        case "GLOBAL_ADMIN":
                        case 0:
                            message.roles[i] = 0;
                            break;
                        case "SUPPORT":
                        case 1:
                            message.roles[i] = 1;
                            break;
                        case "COMPANY_ADMIN":
                        case 2:
                            message.roles[i] = 2;
                            break;
                        case "COMPANY_MANAGER":
                        case 3:
                            message.roles[i] = 3;
                            break;
                        case "COMPANY_COURIER":
                        case 4:
                            message.roles[i] = 4;
                            break;
                        }
                }
                return message;
            };

            /**
             * Creates a plain object from an Admin message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.Admin
             * @static
             * @param {fastcity.admin.Admin} message Admin
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Admin.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults) {
                    object.subCompanyId = [];
                    object.roles = [];
                }
                if (options.defaults) {
                    object.login = "";
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.companyId = options.longs === String ? "0" : 0;
                    object.name = "";
                    object.isDeleted = false;
                    object.email = "";
                    object.mapType = options.enums === String ? "GOOGLE" : 0;
                }
                if (message.login != null && message.hasOwnProperty("login"))
                    object.login = message.login;
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (typeof message.companyId === "number")
                        object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                    else
                        object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber(true) : message.companyId;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.isDeleted != null && message.hasOwnProperty("isDeleted"))
                    object.isDeleted = message.isDeleted;
                if (message.subCompanyId && message.subCompanyId.length) {
                    object.subCompanyId = [];
                    for (var j = 0; j < message.subCompanyId.length; ++j)
                        if (typeof message.subCompanyId[j] === "number")
                            object.subCompanyId[j] = options.longs === String ? String(message.subCompanyId[j]) : message.subCompanyId[j];
                        else
                            object.subCompanyId[j] = options.longs === String ? $util.Long.prototype.toString.call(message.subCompanyId[j]) : options.longs === Number ? new $util.LongBits(message.subCompanyId[j].low >>> 0, message.subCompanyId[j].high >>> 0).toNumber(true) : message.subCompanyId[j];
                }
                if (message.email != null && message.hasOwnProperty("email"))
                    object.email = message.email;
                if (message.mapType != null && message.hasOwnProperty("mapType"))
                    object.mapType = options.enums === String ? $root.fastcity.common.Company.MapType[message.mapType] : message.mapType;
                if (message.roles && message.roles.length) {
                    object.roles = [];
                    for (var j = 0; j < message.roles.length; ++j)
                        object.roles[j] = options.enums === String ? $root.fastcity.common.UserRole[message.roles[j]] : message.roles[j];
                }
                return object;
            };

            /**
             * Converts this Admin to JSON.
             * @function toJSON
             * @memberof fastcity.admin.Admin
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Admin.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Admin;
        })();

        admin.GetAdminList = (function() {

            /**
             * Properties of a GetAdminList.
             * @memberof fastcity.admin
             * @interface IGetAdminList
             * @property {number|Long|null} [companyId] GetAdminList companyId
             * @property {Array.<string>|null} [login] GetAdminList login
             * @property {boolean|null} [withDeleted] GetAdminList withDeleted
             */

            /**
             * Constructs a new GetAdminList.
             * @memberof fastcity.admin
             * @classdesc Represents a GetAdminList.
             * @implements IGetAdminList
             * @constructor
             * @param {fastcity.admin.IGetAdminList=} [properties] Properties to set
             */
            function GetAdminList(properties) {
                this.login = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GetAdminList companyId.
             * @member {number|Long} companyId
             * @memberof fastcity.admin.GetAdminList
             * @instance
             */
            GetAdminList.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * GetAdminList login.
             * @member {Array.<string>} login
             * @memberof fastcity.admin.GetAdminList
             * @instance
             */
            GetAdminList.prototype.login = $util.emptyArray;

            /**
             * GetAdminList withDeleted.
             * @member {boolean} withDeleted
             * @memberof fastcity.admin.GetAdminList
             * @instance
             */
            GetAdminList.prototype.withDeleted = false;

            /**
             * Creates a new GetAdminList instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.GetAdminList
             * @static
             * @param {fastcity.admin.IGetAdminList=} [properties] Properties to set
             * @returns {fastcity.admin.GetAdminList} GetAdminList instance
             */
            GetAdminList.create = function create(properties) {
                return new GetAdminList(properties);
            };

            /**
             * Encodes the specified GetAdminList message. Does not implicitly {@link fastcity.admin.GetAdminList.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.GetAdminList
             * @static
             * @param {fastcity.admin.IGetAdminList} message GetAdminList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetAdminList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.companyId);
                if (message.login != null && message.login.length)
                    for (var i = 0; i < message.login.length; ++i)
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.login[i]);
                if (message.withDeleted != null && Object.hasOwnProperty.call(message, "withDeleted"))
                    writer.uint32(/* id 3, wireType 0 =*/24).bool(message.withDeleted);
                return writer;
            };

            /**
             * Decodes a GetAdminList message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.GetAdminList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.GetAdminList} GetAdminList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetAdminList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.GetAdminList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.companyId = reader.uint64();
                        break;
                    case 2:
                        if (!(message.login && message.login.length))
                            message.login = [];
                        message.login.push(reader.string());
                        break;
                    case 3:
                        message.withDeleted = reader.bool();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a GetAdminList message.
             * @function verify
             * @memberof fastcity.admin.GetAdminList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetAdminList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                        return "companyId: integer|Long expected";
                if (message.login != null && message.hasOwnProperty("login")) {
                    if (!Array.isArray(message.login))
                        return "login: array expected";
                    for (var i = 0; i < message.login.length; ++i)
                        if (!$util.isString(message.login[i]))
                            return "login: string[] expected";
                }
                if (message.withDeleted != null && message.hasOwnProperty("withDeleted"))
                    if (typeof message.withDeleted !== "boolean")
                        return "withDeleted: boolean expected";
                return null;
            };

            /**
             * Creates a GetAdminList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.GetAdminList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.GetAdminList} GetAdminList
             */
            GetAdminList.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.GetAdminList)
                    return object;
                var message = new $root.fastcity.admin.GetAdminList();
                if (object.companyId != null)
                    if ($util.Long)
                        (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = true;
                    else if (typeof object.companyId === "string")
                        message.companyId = parseInt(object.companyId, 10);
                    else if (typeof object.companyId === "number")
                        message.companyId = object.companyId;
                    else if (typeof object.companyId === "object")
                        message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber(true);
                if (object.login) {
                    if (!Array.isArray(object.login))
                        throw TypeError(".fastcity.admin.GetAdminList.login: array expected");
                    message.login = [];
                    for (var i = 0; i < object.login.length; ++i)
                        message.login[i] = String(object.login[i]);
                }
                if (object.withDeleted != null)
                    message.withDeleted = Boolean(object.withDeleted);
                return message;
            };

            /**
             * Creates a plain object from a GetAdminList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.GetAdminList
             * @static
             * @param {fastcity.admin.GetAdminList} message GetAdminList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetAdminList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.login = [];
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.companyId = options.longs === String ? "0" : 0;
                    object.withDeleted = false;
                }
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (typeof message.companyId === "number")
                        object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                    else
                        object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber(true) : message.companyId;
                if (message.login && message.login.length) {
                    object.login = [];
                    for (var j = 0; j < message.login.length; ++j)
                        object.login[j] = message.login[j];
                }
                if (message.withDeleted != null && message.hasOwnProperty("withDeleted"))
                    object.withDeleted = message.withDeleted;
                return object;
            };

            /**
             * Converts this GetAdminList to JSON.
             * @function toJSON
             * @memberof fastcity.admin.GetAdminList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetAdminList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return GetAdminList;
        })();

        admin.AdminList = (function() {

            /**
             * Properties of an AdminList.
             * @memberof fastcity.admin
             * @interface IAdminList
             * @property {Array.<fastcity.admin.IAdmin>|null} [admin] AdminList admin
             */

            /**
             * Constructs a new AdminList.
             * @memberof fastcity.admin
             * @classdesc Represents an AdminList.
             * @implements IAdminList
             * @constructor
             * @param {fastcity.admin.IAdminList=} [properties] Properties to set
             */
            function AdminList(properties) {
                this.admin = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * AdminList admin.
             * @member {Array.<fastcity.admin.IAdmin>} admin
             * @memberof fastcity.admin.AdminList
             * @instance
             */
            AdminList.prototype.admin = $util.emptyArray;

            /**
             * Creates a new AdminList instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.AdminList
             * @static
             * @param {fastcity.admin.IAdminList=} [properties] Properties to set
             * @returns {fastcity.admin.AdminList} AdminList instance
             */
            AdminList.create = function create(properties) {
                return new AdminList(properties);
            };

            /**
             * Encodes the specified AdminList message. Does not implicitly {@link fastcity.admin.AdminList.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.AdminList
             * @static
             * @param {fastcity.admin.IAdminList} message AdminList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AdminList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.admin != null && message.admin.length)
                    for (var i = 0; i < message.admin.length; ++i)
                        $root.fastcity.admin.Admin.encode(message.admin[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes an AdminList message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.AdminList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.AdminList} AdminList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AdminList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.AdminList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.admin && message.admin.length))
                            message.admin = [];
                        message.admin.push($root.fastcity.admin.Admin.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an AdminList message.
             * @function verify
             * @memberof fastcity.admin.AdminList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AdminList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.admin != null && message.hasOwnProperty("admin")) {
                    if (!Array.isArray(message.admin))
                        return "admin: array expected";
                    for (var i = 0; i < message.admin.length; ++i) {
                        var error = $root.fastcity.admin.Admin.verify(message.admin[i]);
                        if (error)
                            return "admin." + error;
                    }
                }
                return null;
            };

            /**
             * Creates an AdminList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.AdminList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.AdminList} AdminList
             */
            AdminList.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.AdminList)
                    return object;
                var message = new $root.fastcity.admin.AdminList();
                if (object.admin) {
                    if (!Array.isArray(object.admin))
                        throw TypeError(".fastcity.admin.AdminList.admin: array expected");
                    message.admin = [];
                    for (var i = 0; i < object.admin.length; ++i) {
                        if (typeof object.admin[i] !== "object")
                            throw TypeError(".fastcity.admin.AdminList.admin: object expected");
                        message.admin[i] = $root.fastcity.admin.Admin.fromObject(object.admin[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from an AdminList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.AdminList
             * @static
             * @param {fastcity.admin.AdminList} message AdminList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AdminList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.admin = [];
                if (message.admin && message.admin.length) {
                    object.admin = [];
                    for (var j = 0; j < message.admin.length; ++j)
                        object.admin[j] = $root.fastcity.admin.Admin.toObject(message.admin[j], options);
                }
                return object;
            };

            /**
             * Converts this AdminList to JSON.
             * @function toJSON
             * @memberof fastcity.admin.AdminList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AdminList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return AdminList;
        })();

        admin.GetCurrentAdmin = (function() {

            /**
             * Properties of a GetCurrentAdmin.
             * @memberof fastcity.admin
             * @interface IGetCurrentAdmin
             */

            /**
             * Constructs a new GetCurrentAdmin.
             * @memberof fastcity.admin
             * @classdesc Represents a GetCurrentAdmin.
             * @implements IGetCurrentAdmin
             * @constructor
             * @param {fastcity.admin.IGetCurrentAdmin=} [properties] Properties to set
             */
            function GetCurrentAdmin(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new GetCurrentAdmin instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.GetCurrentAdmin
             * @static
             * @param {fastcity.admin.IGetCurrentAdmin=} [properties] Properties to set
             * @returns {fastcity.admin.GetCurrentAdmin} GetCurrentAdmin instance
             */
            GetCurrentAdmin.create = function create(properties) {
                return new GetCurrentAdmin(properties);
            };

            /**
             * Encodes the specified GetCurrentAdmin message. Does not implicitly {@link fastcity.admin.GetCurrentAdmin.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.GetCurrentAdmin
             * @static
             * @param {fastcity.admin.IGetCurrentAdmin} message GetCurrentAdmin message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetCurrentAdmin.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Decodes a GetCurrentAdmin message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.GetCurrentAdmin
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.GetCurrentAdmin} GetCurrentAdmin
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetCurrentAdmin.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.GetCurrentAdmin();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a GetCurrentAdmin message.
             * @function verify
             * @memberof fastcity.admin.GetCurrentAdmin
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetCurrentAdmin.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a GetCurrentAdmin message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.GetCurrentAdmin
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.GetCurrentAdmin} GetCurrentAdmin
             */
            GetCurrentAdmin.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.GetCurrentAdmin)
                    return object;
                return new $root.fastcity.admin.GetCurrentAdmin();
            };

            /**
             * Creates a plain object from a GetCurrentAdmin message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.GetCurrentAdmin
             * @static
             * @param {fastcity.admin.GetCurrentAdmin} message GetCurrentAdmin
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetCurrentAdmin.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this GetCurrentAdmin to JSON.
             * @function toJSON
             * @memberof fastcity.admin.GetCurrentAdmin
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetCurrentAdmin.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return GetCurrentAdmin;
        })();

        admin.CreateAdmin = (function() {

            /**
             * Properties of a CreateAdmin.
             * @memberof fastcity.admin
             * @interface ICreateAdmin
             * @property {string|null} [login] CreateAdmin login
             * @property {number|Long|null} [companyId] CreateAdmin companyId
             * @property {string|null} [name] CreateAdmin name
             * @property {string|null} [password] CreateAdmin password
             * @property {Array.<number|Long>|null} [subCompanyId] CreateAdmin subCompanyId
             * @property {string|null} [email] CreateAdmin email
             * @property {Array.<fastcity.common.UserRole>|null} [roles] CreateAdmin roles
             */

            /**
             * Constructs a new CreateAdmin.
             * @memberof fastcity.admin
             * @classdesc Represents a CreateAdmin.
             * @implements ICreateAdmin
             * @constructor
             * @param {fastcity.admin.ICreateAdmin=} [properties] Properties to set
             */
            function CreateAdmin(properties) {
                this.subCompanyId = [];
                this.roles = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CreateAdmin login.
             * @member {string} login
             * @memberof fastcity.admin.CreateAdmin
             * @instance
             */
            CreateAdmin.prototype.login = "";

            /**
             * CreateAdmin companyId.
             * @member {number|Long} companyId
             * @memberof fastcity.admin.CreateAdmin
             * @instance
             */
            CreateAdmin.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * CreateAdmin name.
             * @member {string} name
             * @memberof fastcity.admin.CreateAdmin
             * @instance
             */
            CreateAdmin.prototype.name = "";

            /**
             * CreateAdmin password.
             * @member {string} password
             * @memberof fastcity.admin.CreateAdmin
             * @instance
             */
            CreateAdmin.prototype.password = "";

            /**
             * CreateAdmin subCompanyId.
             * @member {Array.<number|Long>} subCompanyId
             * @memberof fastcity.admin.CreateAdmin
             * @instance
             */
            CreateAdmin.prototype.subCompanyId = $util.emptyArray;

            /**
             * CreateAdmin email.
             * @member {string} email
             * @memberof fastcity.admin.CreateAdmin
             * @instance
             */
            CreateAdmin.prototype.email = "";

            /**
             * CreateAdmin roles.
             * @member {Array.<fastcity.common.UserRole>} roles
             * @memberof fastcity.admin.CreateAdmin
             * @instance
             */
            CreateAdmin.prototype.roles = $util.emptyArray;

            /**
             * Creates a new CreateAdmin instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.CreateAdmin
             * @static
             * @param {fastcity.admin.ICreateAdmin=} [properties] Properties to set
             * @returns {fastcity.admin.CreateAdmin} CreateAdmin instance
             */
            CreateAdmin.create = function create(properties) {
                return new CreateAdmin(properties);
            };

            /**
             * Encodes the specified CreateAdmin message. Does not implicitly {@link fastcity.admin.CreateAdmin.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.CreateAdmin
             * @static
             * @param {fastcity.admin.ICreateAdmin} message CreateAdmin message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateAdmin.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.login != null && Object.hasOwnProperty.call(message, "login"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.login);
                if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.companyId);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
                if (message.password != null && Object.hasOwnProperty.call(message, "password"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.password);
                if (message.subCompanyId != null && message.subCompanyId.length) {
                    writer.uint32(/* id 5, wireType 2 =*/42).fork();
                    for (var i = 0; i < message.subCompanyId.length; ++i)
                        writer.uint64(message.subCompanyId[i]);
                    writer.ldelim();
                }
                if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.email);
                if (message.roles != null && message.roles.length) {
                    writer.uint32(/* id 7, wireType 2 =*/58).fork();
                    for (var i = 0; i < message.roles.length; ++i)
                        writer.int32(message.roles[i]);
                    writer.ldelim();
                }
                return writer;
            };

            /**
             * Decodes a CreateAdmin message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.CreateAdmin
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.CreateAdmin} CreateAdmin
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateAdmin.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.CreateAdmin();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.login = reader.string();
                        break;
                    case 2:
                        message.companyId = reader.uint64();
                        break;
                    case 3:
                        message.name = reader.string();
                        break;
                    case 4:
                        message.password = reader.string();
                        break;
                    case 5:
                        if (!(message.subCompanyId && message.subCompanyId.length))
                            message.subCompanyId = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.subCompanyId.push(reader.uint64());
                        } else
                            message.subCompanyId.push(reader.uint64());
                        break;
                    case 6:
                        message.email = reader.string();
                        break;
                    case 7:
                        if (!(message.roles && message.roles.length))
                            message.roles = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.roles.push(reader.int32());
                        } else
                            message.roles.push(reader.int32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a CreateAdmin message.
             * @function verify
             * @memberof fastcity.admin.CreateAdmin
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CreateAdmin.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.login != null && message.hasOwnProperty("login"))
                    if (!$util.isString(message.login))
                        return "login: string expected";
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                        return "companyId: integer|Long expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.password != null && message.hasOwnProperty("password"))
                    if (!$util.isString(message.password))
                        return "password: string expected";
                if (message.subCompanyId != null && message.hasOwnProperty("subCompanyId")) {
                    if (!Array.isArray(message.subCompanyId))
                        return "subCompanyId: array expected";
                    for (var i = 0; i < message.subCompanyId.length; ++i)
                        if (!$util.isInteger(message.subCompanyId[i]) && !(message.subCompanyId[i] && $util.isInteger(message.subCompanyId[i].low) && $util.isInteger(message.subCompanyId[i].high)))
                            return "subCompanyId: integer|Long[] expected";
                }
                if (message.email != null && message.hasOwnProperty("email"))
                    if (!$util.isString(message.email))
                        return "email: string expected";
                if (message.roles != null && message.hasOwnProperty("roles")) {
                    if (!Array.isArray(message.roles))
                        return "roles: array expected";
                    for (var i = 0; i < message.roles.length; ++i)
                        switch (message.roles[i]) {
                        default:
                            return "roles: enum value[] expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                            break;
                        }
                }
                return null;
            };

            /**
             * Creates a CreateAdmin message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.CreateAdmin
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.CreateAdmin} CreateAdmin
             */
            CreateAdmin.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.CreateAdmin)
                    return object;
                var message = new $root.fastcity.admin.CreateAdmin();
                if (object.login != null)
                    message.login = String(object.login);
                if (object.companyId != null)
                    if ($util.Long)
                        (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = true;
                    else if (typeof object.companyId === "string")
                        message.companyId = parseInt(object.companyId, 10);
                    else if (typeof object.companyId === "number")
                        message.companyId = object.companyId;
                    else if (typeof object.companyId === "object")
                        message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber(true);
                if (object.name != null)
                    message.name = String(object.name);
                if (object.password != null)
                    message.password = String(object.password);
                if (object.subCompanyId) {
                    if (!Array.isArray(object.subCompanyId))
                        throw TypeError(".fastcity.admin.CreateAdmin.subCompanyId: array expected");
                    message.subCompanyId = [];
                    for (var i = 0; i < object.subCompanyId.length; ++i)
                        if ($util.Long)
                            (message.subCompanyId[i] = $util.Long.fromValue(object.subCompanyId[i])).unsigned = true;
                        else if (typeof object.subCompanyId[i] === "string")
                            message.subCompanyId[i] = parseInt(object.subCompanyId[i], 10);
                        else if (typeof object.subCompanyId[i] === "number")
                            message.subCompanyId[i] = object.subCompanyId[i];
                        else if (typeof object.subCompanyId[i] === "object")
                            message.subCompanyId[i] = new $util.LongBits(object.subCompanyId[i].low >>> 0, object.subCompanyId[i].high >>> 0).toNumber(true);
                }
                if (object.email != null)
                    message.email = String(object.email);
                if (object.roles) {
                    if (!Array.isArray(object.roles))
                        throw TypeError(".fastcity.admin.CreateAdmin.roles: array expected");
                    message.roles = [];
                    for (var i = 0; i < object.roles.length; ++i)
                        switch (object.roles[i]) {
                        default:
                        case "GLOBAL_ADMIN":
                        case 0:
                            message.roles[i] = 0;
                            break;
                        case "SUPPORT":
                        case 1:
                            message.roles[i] = 1;
                            break;
                        case "COMPANY_ADMIN":
                        case 2:
                            message.roles[i] = 2;
                            break;
                        case "COMPANY_MANAGER":
                        case 3:
                            message.roles[i] = 3;
                            break;
                        case "COMPANY_COURIER":
                        case 4:
                            message.roles[i] = 4;
                            break;
                        }
                }
                return message;
            };

            /**
             * Creates a plain object from a CreateAdmin message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.CreateAdmin
             * @static
             * @param {fastcity.admin.CreateAdmin} message CreateAdmin
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateAdmin.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults) {
                    object.subCompanyId = [];
                    object.roles = [];
                }
                if (options.defaults) {
                    object.login = "";
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.companyId = options.longs === String ? "0" : 0;
                    object.name = "";
                    object.password = "";
                    object.email = "";
                }
                if (message.login != null && message.hasOwnProperty("login"))
                    object.login = message.login;
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (typeof message.companyId === "number")
                        object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                    else
                        object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber(true) : message.companyId;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.password != null && message.hasOwnProperty("password"))
                    object.password = message.password;
                if (message.subCompanyId && message.subCompanyId.length) {
                    object.subCompanyId = [];
                    for (var j = 0; j < message.subCompanyId.length; ++j)
                        if (typeof message.subCompanyId[j] === "number")
                            object.subCompanyId[j] = options.longs === String ? String(message.subCompanyId[j]) : message.subCompanyId[j];
                        else
                            object.subCompanyId[j] = options.longs === String ? $util.Long.prototype.toString.call(message.subCompanyId[j]) : options.longs === Number ? new $util.LongBits(message.subCompanyId[j].low >>> 0, message.subCompanyId[j].high >>> 0).toNumber(true) : message.subCompanyId[j];
                }
                if (message.email != null && message.hasOwnProperty("email"))
                    object.email = message.email;
                if (message.roles && message.roles.length) {
                    object.roles = [];
                    for (var j = 0; j < message.roles.length; ++j)
                        object.roles[j] = options.enums === String ? $root.fastcity.common.UserRole[message.roles[j]] : message.roles[j];
                }
                return object;
            };

            /**
             * Converts this CreateAdmin to JSON.
             * @function toJSON
             * @memberof fastcity.admin.CreateAdmin
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateAdmin.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return CreateAdmin;
        })();

        admin.UpdateAdmin = (function() {

            /**
             * Properties of an UpdateAdmin.
             * @memberof fastcity.admin
             * @interface IUpdateAdmin
             * @property {string|null} [login] UpdateAdmin login
             * @property {string|null} [name] UpdateAdmin name
             * @property {string|null} [password] UpdateAdmin password
             * @property {string|null} [email] UpdateAdmin email
             * @property {Array.<number|Long>|null} [subCompanyId] UpdateAdmin subCompanyId
             * @property {Array.<fastcity.common.UserRole>|null} [roles] UpdateAdmin roles
             */

            /**
             * Constructs a new UpdateAdmin.
             * @memberof fastcity.admin
             * @classdesc Represents an UpdateAdmin.
             * @implements IUpdateAdmin
             * @constructor
             * @param {fastcity.admin.IUpdateAdmin=} [properties] Properties to set
             */
            function UpdateAdmin(properties) {
                this.subCompanyId = [];
                this.roles = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * UpdateAdmin login.
             * @member {string} login
             * @memberof fastcity.admin.UpdateAdmin
             * @instance
             */
            UpdateAdmin.prototype.login = "";

            /**
             * UpdateAdmin name.
             * @member {string} name
             * @memberof fastcity.admin.UpdateAdmin
             * @instance
             */
            UpdateAdmin.prototype.name = "";

            /**
             * UpdateAdmin password.
             * @member {string} password
             * @memberof fastcity.admin.UpdateAdmin
             * @instance
             */
            UpdateAdmin.prototype.password = "";

            /**
             * UpdateAdmin email.
             * @member {string} email
             * @memberof fastcity.admin.UpdateAdmin
             * @instance
             */
            UpdateAdmin.prototype.email = "";

            /**
             * UpdateAdmin subCompanyId.
             * @member {Array.<number|Long>} subCompanyId
             * @memberof fastcity.admin.UpdateAdmin
             * @instance
             */
            UpdateAdmin.prototype.subCompanyId = $util.emptyArray;

            /**
             * UpdateAdmin roles.
             * @member {Array.<fastcity.common.UserRole>} roles
             * @memberof fastcity.admin.UpdateAdmin
             * @instance
             */
            UpdateAdmin.prototype.roles = $util.emptyArray;

            /**
             * Creates a new UpdateAdmin instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.UpdateAdmin
             * @static
             * @param {fastcity.admin.IUpdateAdmin=} [properties] Properties to set
             * @returns {fastcity.admin.UpdateAdmin} UpdateAdmin instance
             */
            UpdateAdmin.create = function create(properties) {
                return new UpdateAdmin(properties);
            };

            /**
             * Encodes the specified UpdateAdmin message. Does not implicitly {@link fastcity.admin.UpdateAdmin.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.UpdateAdmin
             * @static
             * @param {fastcity.admin.IUpdateAdmin} message UpdateAdmin message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateAdmin.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.login != null && Object.hasOwnProperty.call(message, "login"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.login);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                if (message.password != null && Object.hasOwnProperty.call(message, "password"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.password);
                if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.email);
                if (message.subCompanyId != null && message.subCompanyId.length) {
                    writer.uint32(/* id 5, wireType 2 =*/42).fork();
                    for (var i = 0; i < message.subCompanyId.length; ++i)
                        writer.uint64(message.subCompanyId[i]);
                    writer.ldelim();
                }
                if (message.roles != null && message.roles.length) {
                    writer.uint32(/* id 6, wireType 2 =*/50).fork();
                    for (var i = 0; i < message.roles.length; ++i)
                        writer.int32(message.roles[i]);
                    writer.ldelim();
                }
                return writer;
            };

            /**
             * Decodes an UpdateAdmin message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.UpdateAdmin
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.UpdateAdmin} UpdateAdmin
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateAdmin.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.UpdateAdmin();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.login = reader.string();
                        break;
                    case 2:
                        message.name = reader.string();
                        break;
                    case 3:
                        message.password = reader.string();
                        break;
                    case 4:
                        message.email = reader.string();
                        break;
                    case 5:
                        if (!(message.subCompanyId && message.subCompanyId.length))
                            message.subCompanyId = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.subCompanyId.push(reader.uint64());
                        } else
                            message.subCompanyId.push(reader.uint64());
                        break;
                    case 6:
                        if (!(message.roles && message.roles.length))
                            message.roles = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.roles.push(reader.int32());
                        } else
                            message.roles.push(reader.int32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an UpdateAdmin message.
             * @function verify
             * @memberof fastcity.admin.UpdateAdmin
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            UpdateAdmin.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.login != null && message.hasOwnProperty("login"))
                    if (!$util.isString(message.login))
                        return "login: string expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.password != null && message.hasOwnProperty("password"))
                    if (!$util.isString(message.password))
                        return "password: string expected";
                if (message.email != null && message.hasOwnProperty("email"))
                    if (!$util.isString(message.email))
                        return "email: string expected";
                if (message.subCompanyId != null && message.hasOwnProperty("subCompanyId")) {
                    if (!Array.isArray(message.subCompanyId))
                        return "subCompanyId: array expected";
                    for (var i = 0; i < message.subCompanyId.length; ++i)
                        if (!$util.isInteger(message.subCompanyId[i]) && !(message.subCompanyId[i] && $util.isInteger(message.subCompanyId[i].low) && $util.isInteger(message.subCompanyId[i].high)))
                            return "subCompanyId: integer|Long[] expected";
                }
                if (message.roles != null && message.hasOwnProperty("roles")) {
                    if (!Array.isArray(message.roles))
                        return "roles: array expected";
                    for (var i = 0; i < message.roles.length; ++i)
                        switch (message.roles[i]) {
                        default:
                            return "roles: enum value[] expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                            break;
                        }
                }
                return null;
            };

            /**
             * Creates an UpdateAdmin message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.UpdateAdmin
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.UpdateAdmin} UpdateAdmin
             */
            UpdateAdmin.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.UpdateAdmin)
                    return object;
                var message = new $root.fastcity.admin.UpdateAdmin();
                if (object.login != null)
                    message.login = String(object.login);
                if (object.name != null)
                    message.name = String(object.name);
                if (object.password != null)
                    message.password = String(object.password);
                if (object.email != null)
                    message.email = String(object.email);
                if (object.subCompanyId) {
                    if (!Array.isArray(object.subCompanyId))
                        throw TypeError(".fastcity.admin.UpdateAdmin.subCompanyId: array expected");
                    message.subCompanyId = [];
                    for (var i = 0; i < object.subCompanyId.length; ++i)
                        if ($util.Long)
                            (message.subCompanyId[i] = $util.Long.fromValue(object.subCompanyId[i])).unsigned = true;
                        else if (typeof object.subCompanyId[i] === "string")
                            message.subCompanyId[i] = parseInt(object.subCompanyId[i], 10);
                        else if (typeof object.subCompanyId[i] === "number")
                            message.subCompanyId[i] = object.subCompanyId[i];
                        else if (typeof object.subCompanyId[i] === "object")
                            message.subCompanyId[i] = new $util.LongBits(object.subCompanyId[i].low >>> 0, object.subCompanyId[i].high >>> 0).toNumber(true);
                }
                if (object.roles) {
                    if (!Array.isArray(object.roles))
                        throw TypeError(".fastcity.admin.UpdateAdmin.roles: array expected");
                    message.roles = [];
                    for (var i = 0; i < object.roles.length; ++i)
                        switch (object.roles[i]) {
                        default:
                        case "GLOBAL_ADMIN":
                        case 0:
                            message.roles[i] = 0;
                            break;
                        case "SUPPORT":
                        case 1:
                            message.roles[i] = 1;
                            break;
                        case "COMPANY_ADMIN":
                        case 2:
                            message.roles[i] = 2;
                            break;
                        case "COMPANY_MANAGER":
                        case 3:
                            message.roles[i] = 3;
                            break;
                        case "COMPANY_COURIER":
                        case 4:
                            message.roles[i] = 4;
                            break;
                        }
                }
                return message;
            };

            /**
             * Creates a plain object from an UpdateAdmin message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.UpdateAdmin
             * @static
             * @param {fastcity.admin.UpdateAdmin} message UpdateAdmin
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UpdateAdmin.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults) {
                    object.subCompanyId = [];
                    object.roles = [];
                }
                if (options.defaults) {
                    object.login = "";
                    object.name = "";
                    object.password = "";
                    object.email = "";
                }
                if (message.login != null && message.hasOwnProperty("login"))
                    object.login = message.login;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.password != null && message.hasOwnProperty("password"))
                    object.password = message.password;
                if (message.email != null && message.hasOwnProperty("email"))
                    object.email = message.email;
                if (message.subCompanyId && message.subCompanyId.length) {
                    object.subCompanyId = [];
                    for (var j = 0; j < message.subCompanyId.length; ++j)
                        if (typeof message.subCompanyId[j] === "number")
                            object.subCompanyId[j] = options.longs === String ? String(message.subCompanyId[j]) : message.subCompanyId[j];
                        else
                            object.subCompanyId[j] = options.longs === String ? $util.Long.prototype.toString.call(message.subCompanyId[j]) : options.longs === Number ? new $util.LongBits(message.subCompanyId[j].low >>> 0, message.subCompanyId[j].high >>> 0).toNumber(true) : message.subCompanyId[j];
                }
                if (message.roles && message.roles.length) {
                    object.roles = [];
                    for (var j = 0; j < message.roles.length; ++j)
                        object.roles[j] = options.enums === String ? $root.fastcity.common.UserRole[message.roles[j]] : message.roles[j];
                }
                return object;
            };

            /**
             * Converts this UpdateAdmin to JSON.
             * @function toJSON
             * @memberof fastcity.admin.UpdateAdmin
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UpdateAdmin.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return UpdateAdmin;
        })();

        admin.DeleteAdmin = (function() {

            /**
             * Properties of a DeleteAdmin.
             * @memberof fastcity.admin
             * @interface IDeleteAdmin
             * @property {string|null} [login] DeleteAdmin login
             */

            /**
             * Constructs a new DeleteAdmin.
             * @memberof fastcity.admin
             * @classdesc Represents a DeleteAdmin.
             * @implements IDeleteAdmin
             * @constructor
             * @param {fastcity.admin.IDeleteAdmin=} [properties] Properties to set
             */
            function DeleteAdmin(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * DeleteAdmin login.
             * @member {string} login
             * @memberof fastcity.admin.DeleteAdmin
             * @instance
             */
            DeleteAdmin.prototype.login = "";

            /**
             * Creates a new DeleteAdmin instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.DeleteAdmin
             * @static
             * @param {fastcity.admin.IDeleteAdmin=} [properties] Properties to set
             * @returns {fastcity.admin.DeleteAdmin} DeleteAdmin instance
             */
            DeleteAdmin.create = function create(properties) {
                return new DeleteAdmin(properties);
            };

            /**
             * Encodes the specified DeleteAdmin message. Does not implicitly {@link fastcity.admin.DeleteAdmin.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.DeleteAdmin
             * @static
             * @param {fastcity.admin.IDeleteAdmin} message DeleteAdmin message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteAdmin.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.login != null && Object.hasOwnProperty.call(message, "login"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.login);
                return writer;
            };

            /**
             * Decodes a DeleteAdmin message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.DeleteAdmin
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.DeleteAdmin} DeleteAdmin
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteAdmin.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.DeleteAdmin();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.login = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a DeleteAdmin message.
             * @function verify
             * @memberof fastcity.admin.DeleteAdmin
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DeleteAdmin.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.login != null && message.hasOwnProperty("login"))
                    if (!$util.isString(message.login))
                        return "login: string expected";
                return null;
            };

            /**
             * Creates a DeleteAdmin message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.DeleteAdmin
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.DeleteAdmin} DeleteAdmin
             */
            DeleteAdmin.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.DeleteAdmin)
                    return object;
                var message = new $root.fastcity.admin.DeleteAdmin();
                if (object.login != null)
                    message.login = String(object.login);
                return message;
            };

            /**
             * Creates a plain object from a DeleteAdmin message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.DeleteAdmin
             * @static
             * @param {fastcity.admin.DeleteAdmin} message DeleteAdmin
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DeleteAdmin.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.login = "";
                if (message.login != null && message.hasOwnProperty("login"))
                    object.login = message.login;
                return object;
            };

            /**
             * Converts this DeleteAdmin to JSON.
             * @function toJSON
             * @memberof fastcity.admin.DeleteAdmin
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DeleteAdmin.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return DeleteAdmin;
        })();

        /**
         * AvailableOrderStatusesRequestResult enum.
         * @name fastcity.admin.AvailableOrderStatusesRequestResult
         * @enum {number}
         * @property {number} AOSRR_NOT_FOUND=0 AOSRR_NOT_FOUND value
         * @property {number} AOSRR_ALREADY_EXISTS=1 AOSRR_ALREADY_EXISTS value
         * @property {number} AOSRR_BAD_SUB_COMPANY_ID_SUB_COMPANY_NOT_FOUND=2 AOSRR_BAD_SUB_COMPANY_ID_SUB_COMPANY_NOT_FOUND value
         */
        admin.AvailableOrderStatusesRequestResult = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "AOSRR_NOT_FOUND"] = 0;
            values[valuesById[1] = "AOSRR_ALREADY_EXISTS"] = 1;
            values[valuesById[2] = "AOSRR_BAD_SUB_COMPANY_ID_SUB_COMPANY_NOT_FOUND"] = 2;
            return values;
        })();

        admin.AvailableOrderStatuses = (function() {

            /**
             * Properties of an AvailableOrderStatuses.
             * @memberof fastcity.admin
             * @interface IAvailableOrderStatuses
             * @property {number|Long|null} [subCompanyId] AvailableOrderStatuses subCompanyId
             * @property {Array.<fastcity.admin.IIKOOrderStatus>|null} [orderStatus] AvailableOrderStatuses orderStatus
             * @property {boolean|null} [isDeleted] AvailableOrderStatuses isDeleted
             */

            /**
             * Constructs a new AvailableOrderStatuses.
             * @memberof fastcity.admin
             * @classdesc Represents an AvailableOrderStatuses.
             * @implements IAvailableOrderStatuses
             * @constructor
             * @param {fastcity.admin.IAvailableOrderStatuses=} [properties] Properties to set
             */
            function AvailableOrderStatuses(properties) {
                this.orderStatus = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * AvailableOrderStatuses subCompanyId.
             * @member {number|Long} subCompanyId
             * @memberof fastcity.admin.AvailableOrderStatuses
             * @instance
             */
            AvailableOrderStatuses.prototype.subCompanyId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * AvailableOrderStatuses orderStatus.
             * @member {Array.<fastcity.admin.IIKOOrderStatus>} orderStatus
             * @memberof fastcity.admin.AvailableOrderStatuses
             * @instance
             */
            AvailableOrderStatuses.prototype.orderStatus = $util.emptyArray;

            /**
             * AvailableOrderStatuses isDeleted.
             * @member {boolean} isDeleted
             * @memberof fastcity.admin.AvailableOrderStatuses
             * @instance
             */
            AvailableOrderStatuses.prototype.isDeleted = false;

            /**
             * Creates a new AvailableOrderStatuses instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.AvailableOrderStatuses
             * @static
             * @param {fastcity.admin.IAvailableOrderStatuses=} [properties] Properties to set
             * @returns {fastcity.admin.AvailableOrderStatuses} AvailableOrderStatuses instance
             */
            AvailableOrderStatuses.create = function create(properties) {
                return new AvailableOrderStatuses(properties);
            };

            /**
             * Encodes the specified AvailableOrderStatuses message. Does not implicitly {@link fastcity.admin.AvailableOrderStatuses.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.AvailableOrderStatuses
             * @static
             * @param {fastcity.admin.IAvailableOrderStatuses} message AvailableOrderStatuses message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AvailableOrderStatuses.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.subCompanyId != null && Object.hasOwnProperty.call(message, "subCompanyId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.subCompanyId);
                if (message.orderStatus != null && message.orderStatus.length) {
                    writer.uint32(/* id 2, wireType 2 =*/18).fork();
                    for (var i = 0; i < message.orderStatus.length; ++i)
                        writer.int32(message.orderStatus[i]);
                    writer.ldelim();
                }
                if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                    writer.uint32(/* id 3, wireType 0 =*/24).bool(message.isDeleted);
                return writer;
            };

            /**
             * Decodes an AvailableOrderStatuses message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.AvailableOrderStatuses
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.AvailableOrderStatuses} AvailableOrderStatuses
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AvailableOrderStatuses.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.AvailableOrderStatuses();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.subCompanyId = reader.uint64();
                        break;
                    case 2:
                        if (!(message.orderStatus && message.orderStatus.length))
                            message.orderStatus = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.orderStatus.push(reader.int32());
                        } else
                            message.orderStatus.push(reader.int32());
                        break;
                    case 3:
                        message.isDeleted = reader.bool();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an AvailableOrderStatuses message.
             * @function verify
             * @memberof fastcity.admin.AvailableOrderStatuses
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AvailableOrderStatuses.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.subCompanyId != null && message.hasOwnProperty("subCompanyId"))
                    if (!$util.isInteger(message.subCompanyId) && !(message.subCompanyId && $util.isInteger(message.subCompanyId.low) && $util.isInteger(message.subCompanyId.high)))
                        return "subCompanyId: integer|Long expected";
                if (message.orderStatus != null && message.hasOwnProperty("orderStatus")) {
                    if (!Array.isArray(message.orderStatus))
                        return "orderStatus: array expected";
                    for (var i = 0; i < message.orderStatus.length; ++i)
                        switch (message.orderStatus[i]) {
                        default:
                            return "orderStatus: enum value[] expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                        case 9:
                            break;
                        }
                }
                if (message.isDeleted != null && message.hasOwnProperty("isDeleted"))
                    if (typeof message.isDeleted !== "boolean")
                        return "isDeleted: boolean expected";
                return null;
            };

            /**
             * Creates an AvailableOrderStatuses message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.AvailableOrderStatuses
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.AvailableOrderStatuses} AvailableOrderStatuses
             */
            AvailableOrderStatuses.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.AvailableOrderStatuses)
                    return object;
                var message = new $root.fastcity.admin.AvailableOrderStatuses();
                if (object.subCompanyId != null)
                    if ($util.Long)
                        (message.subCompanyId = $util.Long.fromValue(object.subCompanyId)).unsigned = true;
                    else if (typeof object.subCompanyId === "string")
                        message.subCompanyId = parseInt(object.subCompanyId, 10);
                    else if (typeof object.subCompanyId === "number")
                        message.subCompanyId = object.subCompanyId;
                    else if (typeof object.subCompanyId === "object")
                        message.subCompanyId = new $util.LongBits(object.subCompanyId.low >>> 0, object.subCompanyId.high >>> 0).toNumber(true);
                if (object.orderStatus) {
                    if (!Array.isArray(object.orderStatus))
                        throw TypeError(".fastcity.admin.AvailableOrderStatuses.orderStatus: array expected");
                    message.orderStatus = [];
                    for (var i = 0; i < object.orderStatus.length; ++i)
                        switch (object.orderStatus[i]) {
                        default:
                        case "UNCONFIRMED":
                        case 0:
                            message.orderStatus[i] = 0;
                            break;
                        case "WAIT_COOKING":
                        case 1:
                            message.orderStatus[i] = 1;
                            break;
                        case "READY_FOR_COOKING":
                        case 2:
                            message.orderStatus[i] = 2;
                            break;
                        case "COOKING_STARTED":
                        case 3:
                            message.orderStatus[i] = 3;
                            break;
                        case "COOKING_COMPLETED":
                        case 4:
                            message.orderStatus[i] = 4;
                            break;
                        case "WAITING":
                        case 5:
                            message.orderStatus[i] = 5;
                            break;
                        case "ON_WAY":
                        case 6:
                            message.orderStatus[i] = 6;
                            break;
                        case "DELIVERED":
                        case 7:
                            message.orderStatus[i] = 7;
                            break;
                        case "CLOSED":
                        case 8:
                            message.orderStatus[i] = 8;
                            break;
                        case "CANCELLED":
                        case 9:
                            message.orderStatus[i] = 9;
                            break;
                        }
                }
                if (object.isDeleted != null)
                    message.isDeleted = Boolean(object.isDeleted);
                return message;
            };

            /**
             * Creates a plain object from an AvailableOrderStatuses message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.AvailableOrderStatuses
             * @static
             * @param {fastcity.admin.AvailableOrderStatuses} message AvailableOrderStatuses
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AvailableOrderStatuses.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.orderStatus = [];
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.subCompanyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.subCompanyId = options.longs === String ? "0" : 0;
                    object.isDeleted = false;
                }
                if (message.subCompanyId != null && message.hasOwnProperty("subCompanyId"))
                    if (typeof message.subCompanyId === "number")
                        object.subCompanyId = options.longs === String ? String(message.subCompanyId) : message.subCompanyId;
                    else
                        object.subCompanyId = options.longs === String ? $util.Long.prototype.toString.call(message.subCompanyId) : options.longs === Number ? new $util.LongBits(message.subCompanyId.low >>> 0, message.subCompanyId.high >>> 0).toNumber(true) : message.subCompanyId;
                if (message.orderStatus && message.orderStatus.length) {
                    object.orderStatus = [];
                    for (var j = 0; j < message.orderStatus.length; ++j)
                        object.orderStatus[j] = options.enums === String ? $root.fastcity.admin.IIKOOrderStatus[message.orderStatus[j]] : message.orderStatus[j];
                }
                if (message.isDeleted != null && message.hasOwnProperty("isDeleted"))
                    object.isDeleted = message.isDeleted;
                return object;
            };

            /**
             * Converts this AvailableOrderStatuses to JSON.
             * @function toJSON
             * @memberof fastcity.admin.AvailableOrderStatuses
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AvailableOrderStatuses.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return AvailableOrderStatuses;
        })();

        admin.AvailableOrderStatusesList = (function() {

            /**
             * Properties of an AvailableOrderStatusesList.
             * @memberof fastcity.admin
             * @interface IAvailableOrderStatusesList
             * @property {Array.<fastcity.admin.IAvailableOrderStatuses>|null} [availableOrderStatuses] AvailableOrderStatusesList availableOrderStatuses
             */

            /**
             * Constructs a new AvailableOrderStatusesList.
             * @memberof fastcity.admin
             * @classdesc Represents an AvailableOrderStatusesList.
             * @implements IAvailableOrderStatusesList
             * @constructor
             * @param {fastcity.admin.IAvailableOrderStatusesList=} [properties] Properties to set
             */
            function AvailableOrderStatusesList(properties) {
                this.availableOrderStatuses = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * AvailableOrderStatusesList availableOrderStatuses.
             * @member {Array.<fastcity.admin.IAvailableOrderStatuses>} availableOrderStatuses
             * @memberof fastcity.admin.AvailableOrderStatusesList
             * @instance
             */
            AvailableOrderStatusesList.prototype.availableOrderStatuses = $util.emptyArray;

            /**
             * Creates a new AvailableOrderStatusesList instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.AvailableOrderStatusesList
             * @static
             * @param {fastcity.admin.IAvailableOrderStatusesList=} [properties] Properties to set
             * @returns {fastcity.admin.AvailableOrderStatusesList} AvailableOrderStatusesList instance
             */
            AvailableOrderStatusesList.create = function create(properties) {
                return new AvailableOrderStatusesList(properties);
            };

            /**
             * Encodes the specified AvailableOrderStatusesList message. Does not implicitly {@link fastcity.admin.AvailableOrderStatusesList.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.AvailableOrderStatusesList
             * @static
             * @param {fastcity.admin.IAvailableOrderStatusesList} message AvailableOrderStatusesList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AvailableOrderStatusesList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.availableOrderStatuses != null && message.availableOrderStatuses.length)
                    for (var i = 0; i < message.availableOrderStatuses.length; ++i)
                        $root.fastcity.admin.AvailableOrderStatuses.encode(message.availableOrderStatuses[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes an AvailableOrderStatusesList message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.AvailableOrderStatusesList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.AvailableOrderStatusesList} AvailableOrderStatusesList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AvailableOrderStatusesList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.AvailableOrderStatusesList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.availableOrderStatuses && message.availableOrderStatuses.length))
                            message.availableOrderStatuses = [];
                        message.availableOrderStatuses.push($root.fastcity.admin.AvailableOrderStatuses.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an AvailableOrderStatusesList message.
             * @function verify
             * @memberof fastcity.admin.AvailableOrderStatusesList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AvailableOrderStatusesList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.availableOrderStatuses != null && message.hasOwnProperty("availableOrderStatuses")) {
                    if (!Array.isArray(message.availableOrderStatuses))
                        return "availableOrderStatuses: array expected";
                    for (var i = 0; i < message.availableOrderStatuses.length; ++i) {
                        var error = $root.fastcity.admin.AvailableOrderStatuses.verify(message.availableOrderStatuses[i]);
                        if (error)
                            return "availableOrderStatuses." + error;
                    }
                }
                return null;
            };

            /**
             * Creates an AvailableOrderStatusesList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.AvailableOrderStatusesList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.AvailableOrderStatusesList} AvailableOrderStatusesList
             */
            AvailableOrderStatusesList.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.AvailableOrderStatusesList)
                    return object;
                var message = new $root.fastcity.admin.AvailableOrderStatusesList();
                if (object.availableOrderStatuses) {
                    if (!Array.isArray(object.availableOrderStatuses))
                        throw TypeError(".fastcity.admin.AvailableOrderStatusesList.availableOrderStatuses: array expected");
                    message.availableOrderStatuses = [];
                    for (var i = 0; i < object.availableOrderStatuses.length; ++i) {
                        if (typeof object.availableOrderStatuses[i] !== "object")
                            throw TypeError(".fastcity.admin.AvailableOrderStatusesList.availableOrderStatuses: object expected");
                        message.availableOrderStatuses[i] = $root.fastcity.admin.AvailableOrderStatuses.fromObject(object.availableOrderStatuses[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from an AvailableOrderStatusesList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.AvailableOrderStatusesList
             * @static
             * @param {fastcity.admin.AvailableOrderStatusesList} message AvailableOrderStatusesList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AvailableOrderStatusesList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.availableOrderStatuses = [];
                if (message.availableOrderStatuses && message.availableOrderStatuses.length) {
                    object.availableOrderStatuses = [];
                    for (var j = 0; j < message.availableOrderStatuses.length; ++j)
                        object.availableOrderStatuses[j] = $root.fastcity.admin.AvailableOrderStatuses.toObject(message.availableOrderStatuses[j], options);
                }
                return object;
            };

            /**
             * Converts this AvailableOrderStatusesList to JSON.
             * @function toJSON
             * @memberof fastcity.admin.AvailableOrderStatusesList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AvailableOrderStatusesList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return AvailableOrderStatusesList;
        })();

        admin.CreateAvailableOrderStatuses = (function() {

            /**
             * Properties of a CreateAvailableOrderStatuses.
             * @memberof fastcity.admin
             * @interface ICreateAvailableOrderStatuses
             * @property {number|Long|null} [subCompanyId] CreateAvailableOrderStatuses subCompanyId
             * @property {Array.<fastcity.admin.IIKOOrderStatus>|null} [orderStatus] CreateAvailableOrderStatuses orderStatus
             */

            /**
             * Constructs a new CreateAvailableOrderStatuses.
             * @memberof fastcity.admin
             * @classdesc Represents a CreateAvailableOrderStatuses.
             * @implements ICreateAvailableOrderStatuses
             * @constructor
             * @param {fastcity.admin.ICreateAvailableOrderStatuses=} [properties] Properties to set
             */
            function CreateAvailableOrderStatuses(properties) {
                this.orderStatus = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CreateAvailableOrderStatuses subCompanyId.
             * @member {number|Long} subCompanyId
             * @memberof fastcity.admin.CreateAvailableOrderStatuses
             * @instance
             */
            CreateAvailableOrderStatuses.prototype.subCompanyId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * CreateAvailableOrderStatuses orderStatus.
             * @member {Array.<fastcity.admin.IIKOOrderStatus>} orderStatus
             * @memberof fastcity.admin.CreateAvailableOrderStatuses
             * @instance
             */
            CreateAvailableOrderStatuses.prototype.orderStatus = $util.emptyArray;

            /**
             * Creates a new CreateAvailableOrderStatuses instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.CreateAvailableOrderStatuses
             * @static
             * @param {fastcity.admin.ICreateAvailableOrderStatuses=} [properties] Properties to set
             * @returns {fastcity.admin.CreateAvailableOrderStatuses} CreateAvailableOrderStatuses instance
             */
            CreateAvailableOrderStatuses.create = function create(properties) {
                return new CreateAvailableOrderStatuses(properties);
            };

            /**
             * Encodes the specified CreateAvailableOrderStatuses message. Does not implicitly {@link fastcity.admin.CreateAvailableOrderStatuses.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.CreateAvailableOrderStatuses
             * @static
             * @param {fastcity.admin.ICreateAvailableOrderStatuses} message CreateAvailableOrderStatuses message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateAvailableOrderStatuses.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.subCompanyId != null && Object.hasOwnProperty.call(message, "subCompanyId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.subCompanyId);
                if (message.orderStatus != null && message.orderStatus.length) {
                    writer.uint32(/* id 2, wireType 2 =*/18).fork();
                    for (var i = 0; i < message.orderStatus.length; ++i)
                        writer.int32(message.orderStatus[i]);
                    writer.ldelim();
                }
                return writer;
            };

            /**
             * Decodes a CreateAvailableOrderStatuses message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.CreateAvailableOrderStatuses
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.CreateAvailableOrderStatuses} CreateAvailableOrderStatuses
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateAvailableOrderStatuses.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.CreateAvailableOrderStatuses();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.subCompanyId = reader.uint64();
                        break;
                    case 2:
                        if (!(message.orderStatus && message.orderStatus.length))
                            message.orderStatus = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.orderStatus.push(reader.int32());
                        } else
                            message.orderStatus.push(reader.int32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a CreateAvailableOrderStatuses message.
             * @function verify
             * @memberof fastcity.admin.CreateAvailableOrderStatuses
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CreateAvailableOrderStatuses.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.subCompanyId != null && message.hasOwnProperty("subCompanyId"))
                    if (!$util.isInteger(message.subCompanyId) && !(message.subCompanyId && $util.isInteger(message.subCompanyId.low) && $util.isInteger(message.subCompanyId.high)))
                        return "subCompanyId: integer|Long expected";
                if (message.orderStatus != null && message.hasOwnProperty("orderStatus")) {
                    if (!Array.isArray(message.orderStatus))
                        return "orderStatus: array expected";
                    for (var i = 0; i < message.orderStatus.length; ++i)
                        switch (message.orderStatus[i]) {
                        default:
                            return "orderStatus: enum value[] expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                        case 9:
                            break;
                        }
                }
                return null;
            };

            /**
             * Creates a CreateAvailableOrderStatuses message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.CreateAvailableOrderStatuses
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.CreateAvailableOrderStatuses} CreateAvailableOrderStatuses
             */
            CreateAvailableOrderStatuses.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.CreateAvailableOrderStatuses)
                    return object;
                var message = new $root.fastcity.admin.CreateAvailableOrderStatuses();
                if (object.subCompanyId != null)
                    if ($util.Long)
                        (message.subCompanyId = $util.Long.fromValue(object.subCompanyId)).unsigned = true;
                    else if (typeof object.subCompanyId === "string")
                        message.subCompanyId = parseInt(object.subCompanyId, 10);
                    else if (typeof object.subCompanyId === "number")
                        message.subCompanyId = object.subCompanyId;
                    else if (typeof object.subCompanyId === "object")
                        message.subCompanyId = new $util.LongBits(object.subCompanyId.low >>> 0, object.subCompanyId.high >>> 0).toNumber(true);
                if (object.orderStatus) {
                    if (!Array.isArray(object.orderStatus))
                        throw TypeError(".fastcity.admin.CreateAvailableOrderStatuses.orderStatus: array expected");
                    message.orderStatus = [];
                    for (var i = 0; i < object.orderStatus.length; ++i)
                        switch (object.orderStatus[i]) {
                        default:
                        case "UNCONFIRMED":
                        case 0:
                            message.orderStatus[i] = 0;
                            break;
                        case "WAIT_COOKING":
                        case 1:
                            message.orderStatus[i] = 1;
                            break;
                        case "READY_FOR_COOKING":
                        case 2:
                            message.orderStatus[i] = 2;
                            break;
                        case "COOKING_STARTED":
                        case 3:
                            message.orderStatus[i] = 3;
                            break;
                        case "COOKING_COMPLETED":
                        case 4:
                            message.orderStatus[i] = 4;
                            break;
                        case "WAITING":
                        case 5:
                            message.orderStatus[i] = 5;
                            break;
                        case "ON_WAY":
                        case 6:
                            message.orderStatus[i] = 6;
                            break;
                        case "DELIVERED":
                        case 7:
                            message.orderStatus[i] = 7;
                            break;
                        case "CLOSED":
                        case 8:
                            message.orderStatus[i] = 8;
                            break;
                        case "CANCELLED":
                        case 9:
                            message.orderStatus[i] = 9;
                            break;
                        }
                }
                return message;
            };

            /**
             * Creates a plain object from a CreateAvailableOrderStatuses message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.CreateAvailableOrderStatuses
             * @static
             * @param {fastcity.admin.CreateAvailableOrderStatuses} message CreateAvailableOrderStatuses
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateAvailableOrderStatuses.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.orderStatus = [];
                if (options.defaults)
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.subCompanyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.subCompanyId = options.longs === String ? "0" : 0;
                if (message.subCompanyId != null && message.hasOwnProperty("subCompanyId"))
                    if (typeof message.subCompanyId === "number")
                        object.subCompanyId = options.longs === String ? String(message.subCompanyId) : message.subCompanyId;
                    else
                        object.subCompanyId = options.longs === String ? $util.Long.prototype.toString.call(message.subCompanyId) : options.longs === Number ? new $util.LongBits(message.subCompanyId.low >>> 0, message.subCompanyId.high >>> 0).toNumber(true) : message.subCompanyId;
                if (message.orderStatus && message.orderStatus.length) {
                    object.orderStatus = [];
                    for (var j = 0; j < message.orderStatus.length; ++j)
                        object.orderStatus[j] = options.enums === String ? $root.fastcity.admin.IIKOOrderStatus[message.orderStatus[j]] : message.orderStatus[j];
                }
                return object;
            };

            /**
             * Converts this CreateAvailableOrderStatuses to JSON.
             * @function toJSON
             * @memberof fastcity.admin.CreateAvailableOrderStatuses
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateAvailableOrderStatuses.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return CreateAvailableOrderStatuses;
        })();

        admin.UpdateAvailableOrderStatuses = (function() {

            /**
             * Properties of an UpdateAvailableOrderStatuses.
             * @memberof fastcity.admin
             * @interface IUpdateAvailableOrderStatuses
             * @property {number|Long|null} [subCompanyId] UpdateAvailableOrderStatuses subCompanyId
             * @property {Array.<fastcity.admin.IIKOOrderStatus>|null} [orderStatus] UpdateAvailableOrderStatuses orderStatus
             * @property {boolean|null} [isDeleted] UpdateAvailableOrderStatuses isDeleted
             */

            /**
             * Constructs a new UpdateAvailableOrderStatuses.
             * @memberof fastcity.admin
             * @classdesc Represents an UpdateAvailableOrderStatuses.
             * @implements IUpdateAvailableOrderStatuses
             * @constructor
             * @param {fastcity.admin.IUpdateAvailableOrderStatuses=} [properties] Properties to set
             */
            function UpdateAvailableOrderStatuses(properties) {
                this.orderStatus = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * UpdateAvailableOrderStatuses subCompanyId.
             * @member {number|Long} subCompanyId
             * @memberof fastcity.admin.UpdateAvailableOrderStatuses
             * @instance
             */
            UpdateAvailableOrderStatuses.prototype.subCompanyId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * UpdateAvailableOrderStatuses orderStatus.
             * @member {Array.<fastcity.admin.IIKOOrderStatus>} orderStatus
             * @memberof fastcity.admin.UpdateAvailableOrderStatuses
             * @instance
             */
            UpdateAvailableOrderStatuses.prototype.orderStatus = $util.emptyArray;

            /**
             * UpdateAvailableOrderStatuses isDeleted.
             * @member {boolean} isDeleted
             * @memberof fastcity.admin.UpdateAvailableOrderStatuses
             * @instance
             */
            UpdateAvailableOrderStatuses.prototype.isDeleted = false;

            /**
             * Creates a new UpdateAvailableOrderStatuses instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.UpdateAvailableOrderStatuses
             * @static
             * @param {fastcity.admin.IUpdateAvailableOrderStatuses=} [properties] Properties to set
             * @returns {fastcity.admin.UpdateAvailableOrderStatuses} UpdateAvailableOrderStatuses instance
             */
            UpdateAvailableOrderStatuses.create = function create(properties) {
                return new UpdateAvailableOrderStatuses(properties);
            };

            /**
             * Encodes the specified UpdateAvailableOrderStatuses message. Does not implicitly {@link fastcity.admin.UpdateAvailableOrderStatuses.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.UpdateAvailableOrderStatuses
             * @static
             * @param {fastcity.admin.IUpdateAvailableOrderStatuses} message UpdateAvailableOrderStatuses message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateAvailableOrderStatuses.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.subCompanyId != null && Object.hasOwnProperty.call(message, "subCompanyId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.subCompanyId);
                if (message.orderStatus != null && message.orderStatus.length) {
                    writer.uint32(/* id 2, wireType 2 =*/18).fork();
                    for (var i = 0; i < message.orderStatus.length; ++i)
                        writer.int32(message.orderStatus[i]);
                    writer.ldelim();
                }
                if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                    writer.uint32(/* id 3, wireType 0 =*/24).bool(message.isDeleted);
                return writer;
            };

            /**
             * Decodes an UpdateAvailableOrderStatuses message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.UpdateAvailableOrderStatuses
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.UpdateAvailableOrderStatuses} UpdateAvailableOrderStatuses
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateAvailableOrderStatuses.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.UpdateAvailableOrderStatuses();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.subCompanyId = reader.uint64();
                        break;
                    case 2:
                        if (!(message.orderStatus && message.orderStatus.length))
                            message.orderStatus = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.orderStatus.push(reader.int32());
                        } else
                            message.orderStatus.push(reader.int32());
                        break;
                    case 3:
                        message.isDeleted = reader.bool();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an UpdateAvailableOrderStatuses message.
             * @function verify
             * @memberof fastcity.admin.UpdateAvailableOrderStatuses
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            UpdateAvailableOrderStatuses.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.subCompanyId != null && message.hasOwnProperty("subCompanyId"))
                    if (!$util.isInteger(message.subCompanyId) && !(message.subCompanyId && $util.isInteger(message.subCompanyId.low) && $util.isInteger(message.subCompanyId.high)))
                        return "subCompanyId: integer|Long expected";
                if (message.orderStatus != null && message.hasOwnProperty("orderStatus")) {
                    if (!Array.isArray(message.orderStatus))
                        return "orderStatus: array expected";
                    for (var i = 0; i < message.orderStatus.length; ++i)
                        switch (message.orderStatus[i]) {
                        default:
                            return "orderStatus: enum value[] expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                        case 9:
                            break;
                        }
                }
                if (message.isDeleted != null && message.hasOwnProperty("isDeleted"))
                    if (typeof message.isDeleted !== "boolean")
                        return "isDeleted: boolean expected";
                return null;
            };

            /**
             * Creates an UpdateAvailableOrderStatuses message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.UpdateAvailableOrderStatuses
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.UpdateAvailableOrderStatuses} UpdateAvailableOrderStatuses
             */
            UpdateAvailableOrderStatuses.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.UpdateAvailableOrderStatuses)
                    return object;
                var message = new $root.fastcity.admin.UpdateAvailableOrderStatuses();
                if (object.subCompanyId != null)
                    if ($util.Long)
                        (message.subCompanyId = $util.Long.fromValue(object.subCompanyId)).unsigned = true;
                    else if (typeof object.subCompanyId === "string")
                        message.subCompanyId = parseInt(object.subCompanyId, 10);
                    else if (typeof object.subCompanyId === "number")
                        message.subCompanyId = object.subCompanyId;
                    else if (typeof object.subCompanyId === "object")
                        message.subCompanyId = new $util.LongBits(object.subCompanyId.low >>> 0, object.subCompanyId.high >>> 0).toNumber(true);
                if (object.orderStatus) {
                    if (!Array.isArray(object.orderStatus))
                        throw TypeError(".fastcity.admin.UpdateAvailableOrderStatuses.orderStatus: array expected");
                    message.orderStatus = [];
                    for (var i = 0; i < object.orderStatus.length; ++i)
                        switch (object.orderStatus[i]) {
                        default:
                        case "UNCONFIRMED":
                        case 0:
                            message.orderStatus[i] = 0;
                            break;
                        case "WAIT_COOKING":
                        case 1:
                            message.orderStatus[i] = 1;
                            break;
                        case "READY_FOR_COOKING":
                        case 2:
                            message.orderStatus[i] = 2;
                            break;
                        case "COOKING_STARTED":
                        case 3:
                            message.orderStatus[i] = 3;
                            break;
                        case "COOKING_COMPLETED":
                        case 4:
                            message.orderStatus[i] = 4;
                            break;
                        case "WAITING":
                        case 5:
                            message.orderStatus[i] = 5;
                            break;
                        case "ON_WAY":
                        case 6:
                            message.orderStatus[i] = 6;
                            break;
                        case "DELIVERED":
                        case 7:
                            message.orderStatus[i] = 7;
                            break;
                        case "CLOSED":
                        case 8:
                            message.orderStatus[i] = 8;
                            break;
                        case "CANCELLED":
                        case 9:
                            message.orderStatus[i] = 9;
                            break;
                        }
                }
                if (object.isDeleted != null)
                    message.isDeleted = Boolean(object.isDeleted);
                return message;
            };

            /**
             * Creates a plain object from an UpdateAvailableOrderStatuses message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.UpdateAvailableOrderStatuses
             * @static
             * @param {fastcity.admin.UpdateAvailableOrderStatuses} message UpdateAvailableOrderStatuses
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UpdateAvailableOrderStatuses.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.orderStatus = [];
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.subCompanyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.subCompanyId = options.longs === String ? "0" : 0;
                    object.isDeleted = false;
                }
                if (message.subCompanyId != null && message.hasOwnProperty("subCompanyId"))
                    if (typeof message.subCompanyId === "number")
                        object.subCompanyId = options.longs === String ? String(message.subCompanyId) : message.subCompanyId;
                    else
                        object.subCompanyId = options.longs === String ? $util.Long.prototype.toString.call(message.subCompanyId) : options.longs === Number ? new $util.LongBits(message.subCompanyId.low >>> 0, message.subCompanyId.high >>> 0).toNumber(true) : message.subCompanyId;
                if (message.orderStatus && message.orderStatus.length) {
                    object.orderStatus = [];
                    for (var j = 0; j < message.orderStatus.length; ++j)
                        object.orderStatus[j] = options.enums === String ? $root.fastcity.admin.IIKOOrderStatus[message.orderStatus[j]] : message.orderStatus[j];
                }
                if (message.isDeleted != null && message.hasOwnProperty("isDeleted"))
                    object.isDeleted = message.isDeleted;
                return object;
            };

            /**
             * Converts this UpdateAvailableOrderStatuses to JSON.
             * @function toJSON
             * @memberof fastcity.admin.UpdateAvailableOrderStatuses
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UpdateAvailableOrderStatuses.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return UpdateAvailableOrderStatuses;
        })();

        admin.GetAvailableOrderStatuses = (function() {

            /**
             * Properties of a GetAvailableOrderStatuses.
             * @memberof fastcity.admin
             * @interface IGetAvailableOrderStatuses
             * @property {number|Long|null} [subCompanyId] GetAvailableOrderStatuses subCompanyId
             */

            /**
             * Constructs a new GetAvailableOrderStatuses.
             * @memberof fastcity.admin
             * @classdesc Represents a GetAvailableOrderStatuses.
             * @implements IGetAvailableOrderStatuses
             * @constructor
             * @param {fastcity.admin.IGetAvailableOrderStatuses=} [properties] Properties to set
             */
            function GetAvailableOrderStatuses(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GetAvailableOrderStatuses subCompanyId.
             * @member {number|Long} subCompanyId
             * @memberof fastcity.admin.GetAvailableOrderStatuses
             * @instance
             */
            GetAvailableOrderStatuses.prototype.subCompanyId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Creates a new GetAvailableOrderStatuses instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.GetAvailableOrderStatuses
             * @static
             * @param {fastcity.admin.IGetAvailableOrderStatuses=} [properties] Properties to set
             * @returns {fastcity.admin.GetAvailableOrderStatuses} GetAvailableOrderStatuses instance
             */
            GetAvailableOrderStatuses.create = function create(properties) {
                return new GetAvailableOrderStatuses(properties);
            };

            /**
             * Encodes the specified GetAvailableOrderStatuses message. Does not implicitly {@link fastcity.admin.GetAvailableOrderStatuses.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.GetAvailableOrderStatuses
             * @static
             * @param {fastcity.admin.IGetAvailableOrderStatuses} message GetAvailableOrderStatuses message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetAvailableOrderStatuses.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.subCompanyId != null && Object.hasOwnProperty.call(message, "subCompanyId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.subCompanyId);
                return writer;
            };

            /**
             * Decodes a GetAvailableOrderStatuses message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.GetAvailableOrderStatuses
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.GetAvailableOrderStatuses} GetAvailableOrderStatuses
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetAvailableOrderStatuses.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.GetAvailableOrderStatuses();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.subCompanyId = reader.uint64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a GetAvailableOrderStatuses message.
             * @function verify
             * @memberof fastcity.admin.GetAvailableOrderStatuses
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetAvailableOrderStatuses.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.subCompanyId != null && message.hasOwnProperty("subCompanyId"))
                    if (!$util.isInteger(message.subCompanyId) && !(message.subCompanyId && $util.isInteger(message.subCompanyId.low) && $util.isInteger(message.subCompanyId.high)))
                        return "subCompanyId: integer|Long expected";
                return null;
            };

            /**
             * Creates a GetAvailableOrderStatuses message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.GetAvailableOrderStatuses
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.GetAvailableOrderStatuses} GetAvailableOrderStatuses
             */
            GetAvailableOrderStatuses.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.GetAvailableOrderStatuses)
                    return object;
                var message = new $root.fastcity.admin.GetAvailableOrderStatuses();
                if (object.subCompanyId != null)
                    if ($util.Long)
                        (message.subCompanyId = $util.Long.fromValue(object.subCompanyId)).unsigned = true;
                    else if (typeof object.subCompanyId === "string")
                        message.subCompanyId = parseInt(object.subCompanyId, 10);
                    else if (typeof object.subCompanyId === "number")
                        message.subCompanyId = object.subCompanyId;
                    else if (typeof object.subCompanyId === "object")
                        message.subCompanyId = new $util.LongBits(object.subCompanyId.low >>> 0, object.subCompanyId.high >>> 0).toNumber(true);
                return message;
            };

            /**
             * Creates a plain object from a GetAvailableOrderStatuses message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.GetAvailableOrderStatuses
             * @static
             * @param {fastcity.admin.GetAvailableOrderStatuses} message GetAvailableOrderStatuses
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetAvailableOrderStatuses.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.subCompanyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.subCompanyId = options.longs === String ? "0" : 0;
                if (message.subCompanyId != null && message.hasOwnProperty("subCompanyId"))
                    if (typeof message.subCompanyId === "number")
                        object.subCompanyId = options.longs === String ? String(message.subCompanyId) : message.subCompanyId;
                    else
                        object.subCompanyId = options.longs === String ? $util.Long.prototype.toString.call(message.subCompanyId) : options.longs === Number ? new $util.LongBits(message.subCompanyId.low >>> 0, message.subCompanyId.high >>> 0).toNumber(true) : message.subCompanyId;
                return object;
            };

            /**
             * Converts this GetAvailableOrderStatuses to JSON.
             * @function toJSON
             * @memberof fastcity.admin.GetAvailableOrderStatuses
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetAvailableOrderStatuses.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return GetAvailableOrderStatuses;
        })();

        admin.GetAvailableOrderStatusesList = (function() {

            /**
             * Properties of a GetAvailableOrderStatusesList.
             * @memberof fastcity.admin
             * @interface IGetAvailableOrderStatusesList
             * @property {Array.<number|Long>|null} [subCompanyId] GetAvailableOrderStatusesList subCompanyId
             */

            /**
             * Constructs a new GetAvailableOrderStatusesList.
             * @memberof fastcity.admin
             * @classdesc Represents a GetAvailableOrderStatusesList.
             * @implements IGetAvailableOrderStatusesList
             * @constructor
             * @param {fastcity.admin.IGetAvailableOrderStatusesList=} [properties] Properties to set
             */
            function GetAvailableOrderStatusesList(properties) {
                this.subCompanyId = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GetAvailableOrderStatusesList subCompanyId.
             * @member {Array.<number|Long>} subCompanyId
             * @memberof fastcity.admin.GetAvailableOrderStatusesList
             * @instance
             */
            GetAvailableOrderStatusesList.prototype.subCompanyId = $util.emptyArray;

            /**
             * Creates a new GetAvailableOrderStatusesList instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.GetAvailableOrderStatusesList
             * @static
             * @param {fastcity.admin.IGetAvailableOrderStatusesList=} [properties] Properties to set
             * @returns {fastcity.admin.GetAvailableOrderStatusesList} GetAvailableOrderStatusesList instance
             */
            GetAvailableOrderStatusesList.create = function create(properties) {
                return new GetAvailableOrderStatusesList(properties);
            };

            /**
             * Encodes the specified GetAvailableOrderStatusesList message. Does not implicitly {@link fastcity.admin.GetAvailableOrderStatusesList.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.GetAvailableOrderStatusesList
             * @static
             * @param {fastcity.admin.IGetAvailableOrderStatusesList} message GetAvailableOrderStatusesList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetAvailableOrderStatusesList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.subCompanyId != null && message.subCompanyId.length) {
                    writer.uint32(/* id 1, wireType 2 =*/10).fork();
                    for (var i = 0; i < message.subCompanyId.length; ++i)
                        writer.uint64(message.subCompanyId[i]);
                    writer.ldelim();
                }
                return writer;
            };

            /**
             * Decodes a GetAvailableOrderStatusesList message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.GetAvailableOrderStatusesList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.GetAvailableOrderStatusesList} GetAvailableOrderStatusesList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetAvailableOrderStatusesList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.GetAvailableOrderStatusesList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.subCompanyId && message.subCompanyId.length))
                            message.subCompanyId = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.subCompanyId.push(reader.uint64());
                        } else
                            message.subCompanyId.push(reader.uint64());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a GetAvailableOrderStatusesList message.
             * @function verify
             * @memberof fastcity.admin.GetAvailableOrderStatusesList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetAvailableOrderStatusesList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.subCompanyId != null && message.hasOwnProperty("subCompanyId")) {
                    if (!Array.isArray(message.subCompanyId))
                        return "subCompanyId: array expected";
                    for (var i = 0; i < message.subCompanyId.length; ++i)
                        if (!$util.isInteger(message.subCompanyId[i]) && !(message.subCompanyId[i] && $util.isInteger(message.subCompanyId[i].low) && $util.isInteger(message.subCompanyId[i].high)))
                            return "subCompanyId: integer|Long[] expected";
                }
                return null;
            };

            /**
             * Creates a GetAvailableOrderStatusesList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.GetAvailableOrderStatusesList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.GetAvailableOrderStatusesList} GetAvailableOrderStatusesList
             */
            GetAvailableOrderStatusesList.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.GetAvailableOrderStatusesList)
                    return object;
                var message = new $root.fastcity.admin.GetAvailableOrderStatusesList();
                if (object.subCompanyId) {
                    if (!Array.isArray(object.subCompanyId))
                        throw TypeError(".fastcity.admin.GetAvailableOrderStatusesList.subCompanyId: array expected");
                    message.subCompanyId = [];
                    for (var i = 0; i < object.subCompanyId.length; ++i)
                        if ($util.Long)
                            (message.subCompanyId[i] = $util.Long.fromValue(object.subCompanyId[i])).unsigned = true;
                        else if (typeof object.subCompanyId[i] === "string")
                            message.subCompanyId[i] = parseInt(object.subCompanyId[i], 10);
                        else if (typeof object.subCompanyId[i] === "number")
                            message.subCompanyId[i] = object.subCompanyId[i];
                        else if (typeof object.subCompanyId[i] === "object")
                            message.subCompanyId[i] = new $util.LongBits(object.subCompanyId[i].low >>> 0, object.subCompanyId[i].high >>> 0).toNumber(true);
                }
                return message;
            };

            /**
             * Creates a plain object from a GetAvailableOrderStatusesList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.GetAvailableOrderStatusesList
             * @static
             * @param {fastcity.admin.GetAvailableOrderStatusesList} message GetAvailableOrderStatusesList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetAvailableOrderStatusesList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.subCompanyId = [];
                if (message.subCompanyId && message.subCompanyId.length) {
                    object.subCompanyId = [];
                    for (var j = 0; j < message.subCompanyId.length; ++j)
                        if (typeof message.subCompanyId[j] === "number")
                            object.subCompanyId[j] = options.longs === String ? String(message.subCompanyId[j]) : message.subCompanyId[j];
                        else
                            object.subCompanyId[j] = options.longs === String ? $util.Long.prototype.toString.call(message.subCompanyId[j]) : options.longs === Number ? new $util.LongBits(message.subCompanyId[j].low >>> 0, message.subCompanyId[j].high >>> 0).toNumber(true) : message.subCompanyId[j];
                }
                return object;
            };

            /**
             * Converts this GetAvailableOrderStatusesList to JSON.
             * @function toJSON
             * @memberof fastcity.admin.GetAvailableOrderStatusesList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetAvailableOrderStatusesList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return GetAvailableOrderStatusesList;
        })();

        /**
         * IIKOOrderStatus enum.
         * @name fastcity.admin.IIKOOrderStatus
         * @enum {number}
         * @property {number} UNCONFIRMED=0 UNCONFIRMED value
         * @property {number} WAIT_COOKING=1 WAIT_COOKING value
         * @property {number} READY_FOR_COOKING=2 READY_FOR_COOKING value
         * @property {number} COOKING_STARTED=3 COOKING_STARTED value
         * @property {number} COOKING_COMPLETED=4 COOKING_COMPLETED value
         * @property {number} WAITING=5 WAITING value
         * @property {number} ON_WAY=6 ON_WAY value
         * @property {number} DELIVERED=7 DELIVERED value
         * @property {number} CLOSED=8 CLOSED value
         * @property {number} CANCELLED=9 CANCELLED value
         */
        admin.IIKOOrderStatus = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "UNCONFIRMED"] = 0;
            values[valuesById[1] = "WAIT_COOKING"] = 1;
            values[valuesById[2] = "READY_FOR_COOKING"] = 2;
            values[valuesById[3] = "COOKING_STARTED"] = 3;
            values[valuesById[4] = "COOKING_COMPLETED"] = 4;
            values[valuesById[5] = "WAITING"] = 5;
            values[valuesById[6] = "ON_WAY"] = 6;
            values[valuesById[7] = "DELIVERED"] = 7;
            values[valuesById[8] = "CLOSED"] = 8;
            values[valuesById[9] = "CANCELLED"] = 9;
            return values;
        })();

        /**
         * CompanyRequestResult enum.
         * @name fastcity.admin.CompanyRequestResult
         * @enum {number}
         * @property {number} CRR_NOT_FOUND=0 CRR_NOT_FOUND value
         * @property {number} CRR_ALREADY_EXISTS=1 CRR_ALREADY_EXISTS value
         * @property {number} CRR_BAD_NAME=2 CRR_BAD_NAME value
         * @property {number} CRR_BAD_MAXIMUM_DELIVERY_TIME_IS_NULL=3 CRR_BAD_MAXIMUM_DELIVERY_TIME_IS_NULL value
         * @property {number} CRR_BAD_MAXIMUM_ACTIVE_ORDERS_ON_COURIER_IS_NULL=4 CRR_BAD_MAXIMUM_ACTIVE_ORDERS_ON_COURIER_IS_NULL value
         * @property {number} CRR_IS_DELETED=5 CRR_IS_DELETED value
         */
        admin.CompanyRequestResult = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "CRR_NOT_FOUND"] = 0;
            values[valuesById[1] = "CRR_ALREADY_EXISTS"] = 1;
            values[valuesById[2] = "CRR_BAD_NAME"] = 2;
            values[valuesById[3] = "CRR_BAD_MAXIMUM_DELIVERY_TIME_IS_NULL"] = 3;
            values[valuesById[4] = "CRR_BAD_MAXIMUM_ACTIVE_ORDERS_ON_COURIER_IS_NULL"] = 4;
            values[valuesById[5] = "CRR_IS_DELETED"] = 5;
            return values;
        })();

        admin.Company = (function() {

            /**
             * Properties of a Company.
             * @memberof fastcity.admin
             * @interface ICompany
             * @property {number|Long|null} [companyId] Company companyId
             * @property {string|null} [name] Company name
             * @property {string|null} [description] Company description
             * @property {string|null} [site] Company site
             * @property {string|null} [email] Company email
             * @property {string|null} [authToken] Company authToken
             * @property {boolean|null} [isDeleted] Company isDeleted
             * @property {string|null} [supportPhone] Company supportPhone
             * @property {number|null} [maximumDeliveryTime] Company maximumDeliveryTime
             * @property {fastcity.common.Company.MapType|null} [mapType] Company mapType
             * @property {number|null} [maximumActiveOrdersOnCourier] Company maximumActiveOrdersOnCourier
             */

            /**
             * Constructs a new Company.
             * @memberof fastcity.admin
             * @classdesc Represents a Company.
             * @implements ICompany
             * @constructor
             * @param {fastcity.admin.ICompany=} [properties] Properties to set
             */
            function Company(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Company companyId.
             * @member {number|Long} companyId
             * @memberof fastcity.admin.Company
             * @instance
             */
            Company.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Company name.
             * @member {string} name
             * @memberof fastcity.admin.Company
             * @instance
             */
            Company.prototype.name = "";

            /**
             * Company description.
             * @member {string} description
             * @memberof fastcity.admin.Company
             * @instance
             */
            Company.prototype.description = "";

            /**
             * Company site.
             * @member {string} site
             * @memberof fastcity.admin.Company
             * @instance
             */
            Company.prototype.site = "";

            /**
             * Company email.
             * @member {string} email
             * @memberof fastcity.admin.Company
             * @instance
             */
            Company.prototype.email = "";

            /**
             * Company authToken.
             * @member {string} authToken
             * @memberof fastcity.admin.Company
             * @instance
             */
            Company.prototype.authToken = "";

            /**
             * Company isDeleted.
             * @member {boolean} isDeleted
             * @memberof fastcity.admin.Company
             * @instance
             */
            Company.prototype.isDeleted = false;

            /**
             * Company supportPhone.
             * @member {string} supportPhone
             * @memberof fastcity.admin.Company
             * @instance
             */
            Company.prototype.supportPhone = "";

            /**
             * Company maximumDeliveryTime.
             * @member {number} maximumDeliveryTime
             * @memberof fastcity.admin.Company
             * @instance
             */
            Company.prototype.maximumDeliveryTime = 0;

            /**
             * Company mapType.
             * @member {fastcity.common.Company.MapType} mapType
             * @memberof fastcity.admin.Company
             * @instance
             */
            Company.prototype.mapType = 0;

            /**
             * Company maximumActiveOrdersOnCourier.
             * @member {number} maximumActiveOrdersOnCourier
             * @memberof fastcity.admin.Company
             * @instance
             */
            Company.prototype.maximumActiveOrdersOnCourier = 0;

            /**
             * Creates a new Company instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.Company
             * @static
             * @param {fastcity.admin.ICompany=} [properties] Properties to set
             * @returns {fastcity.admin.Company} Company instance
             */
            Company.create = function create(properties) {
                return new Company(properties);
            };

            /**
             * Encodes the specified Company message. Does not implicitly {@link fastcity.admin.Company.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.Company
             * @static
             * @param {fastcity.admin.ICompany} message Company message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Company.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.companyId);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.description);
                if (message.site != null && Object.hasOwnProperty.call(message, "site"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.site);
                if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.email);
                if (message.authToken != null && Object.hasOwnProperty.call(message, "authToken"))
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.authToken);
                if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                    writer.uint32(/* id 7, wireType 0 =*/56).bool(message.isDeleted);
                if (message.supportPhone != null && Object.hasOwnProperty.call(message, "supportPhone"))
                    writer.uint32(/* id 8, wireType 2 =*/66).string(message.supportPhone);
                if (message.maximumDeliveryTime != null && Object.hasOwnProperty.call(message, "maximumDeliveryTime"))
                    writer.uint32(/* id 9, wireType 0 =*/72).uint32(message.maximumDeliveryTime);
                if (message.mapType != null && Object.hasOwnProperty.call(message, "mapType"))
                    writer.uint32(/* id 10, wireType 0 =*/80).int32(message.mapType);
                if (message.maximumActiveOrdersOnCourier != null && Object.hasOwnProperty.call(message, "maximumActiveOrdersOnCourier"))
                    writer.uint32(/* id 11, wireType 0 =*/88).uint32(message.maximumActiveOrdersOnCourier);
                return writer;
            };

            /**
             * Decodes a Company message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.Company
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.Company} Company
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Company.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.Company();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.companyId = reader.uint64();
                        break;
                    case 2:
                        message.name = reader.string();
                        break;
                    case 3:
                        message.description = reader.string();
                        break;
                    case 4:
                        message.site = reader.string();
                        break;
                    case 5:
                        message.email = reader.string();
                        break;
                    case 6:
                        message.authToken = reader.string();
                        break;
                    case 7:
                        message.isDeleted = reader.bool();
                        break;
                    case 8:
                        message.supportPhone = reader.string();
                        break;
                    case 9:
                        message.maximumDeliveryTime = reader.uint32();
                        break;
                    case 10:
                        message.mapType = reader.int32();
                        break;
                    case 11:
                        message.maximumActiveOrdersOnCourier = reader.uint32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a Company message.
             * @function verify
             * @memberof fastcity.admin.Company
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Company.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                        return "companyId: integer|Long expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.description != null && message.hasOwnProperty("description"))
                    if (!$util.isString(message.description))
                        return "description: string expected";
                if (message.site != null && message.hasOwnProperty("site"))
                    if (!$util.isString(message.site))
                        return "site: string expected";
                if (message.email != null && message.hasOwnProperty("email"))
                    if (!$util.isString(message.email))
                        return "email: string expected";
                if (message.authToken != null && message.hasOwnProperty("authToken"))
                    if (!$util.isString(message.authToken))
                        return "authToken: string expected";
                if (message.isDeleted != null && message.hasOwnProperty("isDeleted"))
                    if (typeof message.isDeleted !== "boolean")
                        return "isDeleted: boolean expected";
                if (message.supportPhone != null && message.hasOwnProperty("supportPhone"))
                    if (!$util.isString(message.supportPhone))
                        return "supportPhone: string expected";
                if (message.maximumDeliveryTime != null && message.hasOwnProperty("maximumDeliveryTime"))
                    if (!$util.isInteger(message.maximumDeliveryTime))
                        return "maximumDeliveryTime: integer expected";
                if (message.mapType != null && message.hasOwnProperty("mapType"))
                    switch (message.mapType) {
                    default:
                        return "mapType: enum value expected";
                    case 0:
                    case 1:
                        break;
                    }
                if (message.maximumActiveOrdersOnCourier != null && message.hasOwnProperty("maximumActiveOrdersOnCourier"))
                    if (!$util.isInteger(message.maximumActiveOrdersOnCourier))
                        return "maximumActiveOrdersOnCourier: integer expected";
                return null;
            };

            /**
             * Creates a Company message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.Company
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.Company} Company
             */
            Company.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.Company)
                    return object;
                var message = new $root.fastcity.admin.Company();
                if (object.companyId != null)
                    if ($util.Long)
                        (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = true;
                    else if (typeof object.companyId === "string")
                        message.companyId = parseInt(object.companyId, 10);
                    else if (typeof object.companyId === "number")
                        message.companyId = object.companyId;
                    else if (typeof object.companyId === "object")
                        message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber(true);
                if (object.name != null)
                    message.name = String(object.name);
                if (object.description != null)
                    message.description = String(object.description);
                if (object.site != null)
                    message.site = String(object.site);
                if (object.email != null)
                    message.email = String(object.email);
                if (object.authToken != null)
                    message.authToken = String(object.authToken);
                if (object.isDeleted != null)
                    message.isDeleted = Boolean(object.isDeleted);
                if (object.supportPhone != null)
                    message.supportPhone = String(object.supportPhone);
                if (object.maximumDeliveryTime != null)
                    message.maximumDeliveryTime = object.maximumDeliveryTime >>> 0;
                switch (object.mapType) {
                case "GOOGLE":
                case 0:
                    message.mapType = 0;
                    break;
                case "YANDEX":
                case 1:
                    message.mapType = 1;
                    break;
                }
                if (object.maximumActiveOrdersOnCourier != null)
                    message.maximumActiveOrdersOnCourier = object.maximumActiveOrdersOnCourier >>> 0;
                return message;
            };

            /**
             * Creates a plain object from a Company message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.Company
             * @static
             * @param {fastcity.admin.Company} message Company
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Company.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.companyId = options.longs === String ? "0" : 0;
                    object.name = "";
                    object.description = "";
                    object.site = "";
                    object.email = "";
                    object.authToken = "";
                    object.isDeleted = false;
                    object.supportPhone = "";
                    object.maximumDeliveryTime = 0;
                    object.mapType = options.enums === String ? "GOOGLE" : 0;
                    object.maximumActiveOrdersOnCourier = 0;
                }
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (typeof message.companyId === "number")
                        object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                    else
                        object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber(true) : message.companyId;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.description != null && message.hasOwnProperty("description"))
                    object.description = message.description;
                if (message.site != null && message.hasOwnProperty("site"))
                    object.site = message.site;
                if (message.email != null && message.hasOwnProperty("email"))
                    object.email = message.email;
                if (message.authToken != null && message.hasOwnProperty("authToken"))
                    object.authToken = message.authToken;
                if (message.isDeleted != null && message.hasOwnProperty("isDeleted"))
                    object.isDeleted = message.isDeleted;
                if (message.supportPhone != null && message.hasOwnProperty("supportPhone"))
                    object.supportPhone = message.supportPhone;
                if (message.maximumDeliveryTime != null && message.hasOwnProperty("maximumDeliveryTime"))
                    object.maximumDeliveryTime = message.maximumDeliveryTime;
                if (message.mapType != null && message.hasOwnProperty("mapType"))
                    object.mapType = options.enums === String ? $root.fastcity.common.Company.MapType[message.mapType] : message.mapType;
                if (message.maximumActiveOrdersOnCourier != null && message.hasOwnProperty("maximumActiveOrdersOnCourier"))
                    object.maximumActiveOrdersOnCourier = message.maximumActiveOrdersOnCourier;
                return object;
            };

            /**
             * Converts this Company to JSON.
             * @function toJSON
             * @memberof fastcity.admin.Company
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Company.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Company;
        })();

        admin.CreateCompany = (function() {

            /**
             * Properties of a CreateCompany.
             * @memberof fastcity.admin
             * @interface ICreateCompany
             * @property {string|null} [name] CreateCompany name
             * @property {string|null} [description] CreateCompany description
             * @property {string|null} [site] CreateCompany site
             * @property {string|null} [email] CreateCompany email
             * @property {string|null} [supportPhone] CreateCompany supportPhone
             * @property {number|null} [maximumDeliveryTime] CreateCompany maximumDeliveryTime
             * @property {fastcity.common.Company.MapType|null} [mapType] CreateCompany mapType
             * @property {number|null} [maximumActiveOrdersOnCourier] CreateCompany maximumActiveOrdersOnCourier
             */

            /**
             * Constructs a new CreateCompany.
             * @memberof fastcity.admin
             * @classdesc Represents a CreateCompany.
             * @implements ICreateCompany
             * @constructor
             * @param {fastcity.admin.ICreateCompany=} [properties] Properties to set
             */
            function CreateCompany(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CreateCompany name.
             * @member {string} name
             * @memberof fastcity.admin.CreateCompany
             * @instance
             */
            CreateCompany.prototype.name = "";

            /**
             * CreateCompany description.
             * @member {string} description
             * @memberof fastcity.admin.CreateCompany
             * @instance
             */
            CreateCompany.prototype.description = "";

            /**
             * CreateCompany site.
             * @member {string} site
             * @memberof fastcity.admin.CreateCompany
             * @instance
             */
            CreateCompany.prototype.site = "";

            /**
             * CreateCompany email.
             * @member {string} email
             * @memberof fastcity.admin.CreateCompany
             * @instance
             */
            CreateCompany.prototype.email = "";

            /**
             * CreateCompany supportPhone.
             * @member {string} supportPhone
             * @memberof fastcity.admin.CreateCompany
             * @instance
             */
            CreateCompany.prototype.supportPhone = "";

            /**
             * CreateCompany maximumDeliveryTime.
             * @member {number} maximumDeliveryTime
             * @memberof fastcity.admin.CreateCompany
             * @instance
             */
            CreateCompany.prototype.maximumDeliveryTime = 0;

            /**
             * CreateCompany mapType.
             * @member {fastcity.common.Company.MapType} mapType
             * @memberof fastcity.admin.CreateCompany
             * @instance
             */
            CreateCompany.prototype.mapType = 0;

            /**
             * CreateCompany maximumActiveOrdersOnCourier.
             * @member {number} maximumActiveOrdersOnCourier
             * @memberof fastcity.admin.CreateCompany
             * @instance
             */
            CreateCompany.prototype.maximumActiveOrdersOnCourier = 0;

            /**
             * Creates a new CreateCompany instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.CreateCompany
             * @static
             * @param {fastcity.admin.ICreateCompany=} [properties] Properties to set
             * @returns {fastcity.admin.CreateCompany} CreateCompany instance
             */
            CreateCompany.create = function create(properties) {
                return new CreateCompany(properties);
            };

            /**
             * Encodes the specified CreateCompany message. Does not implicitly {@link fastcity.admin.CreateCompany.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.CreateCompany
             * @static
             * @param {fastcity.admin.ICreateCompany} message CreateCompany message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateCompany.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.description);
                if (message.site != null && Object.hasOwnProperty.call(message, "site"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.site);
                if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.email);
                if (message.supportPhone != null && Object.hasOwnProperty.call(message, "supportPhone"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.supportPhone);
                if (message.maximumDeliveryTime != null && Object.hasOwnProperty.call(message, "maximumDeliveryTime"))
                    writer.uint32(/* id 6, wireType 0 =*/48).uint32(message.maximumDeliveryTime);
                if (message.mapType != null && Object.hasOwnProperty.call(message, "mapType"))
                    writer.uint32(/* id 7, wireType 0 =*/56).int32(message.mapType);
                if (message.maximumActiveOrdersOnCourier != null && Object.hasOwnProperty.call(message, "maximumActiveOrdersOnCourier"))
                    writer.uint32(/* id 8, wireType 0 =*/64).uint32(message.maximumActiveOrdersOnCourier);
                return writer;
            };

            /**
             * Decodes a CreateCompany message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.CreateCompany
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.CreateCompany} CreateCompany
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateCompany.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.CreateCompany();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.name = reader.string();
                        break;
                    case 2:
                        message.description = reader.string();
                        break;
                    case 3:
                        message.site = reader.string();
                        break;
                    case 4:
                        message.email = reader.string();
                        break;
                    case 5:
                        message.supportPhone = reader.string();
                        break;
                    case 6:
                        message.maximumDeliveryTime = reader.uint32();
                        break;
                    case 7:
                        message.mapType = reader.int32();
                        break;
                    case 8:
                        message.maximumActiveOrdersOnCourier = reader.uint32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a CreateCompany message.
             * @function verify
             * @memberof fastcity.admin.CreateCompany
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CreateCompany.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.description != null && message.hasOwnProperty("description"))
                    if (!$util.isString(message.description))
                        return "description: string expected";
                if (message.site != null && message.hasOwnProperty("site"))
                    if (!$util.isString(message.site))
                        return "site: string expected";
                if (message.email != null && message.hasOwnProperty("email"))
                    if (!$util.isString(message.email))
                        return "email: string expected";
                if (message.supportPhone != null && message.hasOwnProperty("supportPhone"))
                    if (!$util.isString(message.supportPhone))
                        return "supportPhone: string expected";
                if (message.maximumDeliveryTime != null && message.hasOwnProperty("maximumDeliveryTime"))
                    if (!$util.isInteger(message.maximumDeliveryTime))
                        return "maximumDeliveryTime: integer expected";
                if (message.mapType != null && message.hasOwnProperty("mapType"))
                    switch (message.mapType) {
                    default:
                        return "mapType: enum value expected";
                    case 0:
                    case 1:
                        break;
                    }
                if (message.maximumActiveOrdersOnCourier != null && message.hasOwnProperty("maximumActiveOrdersOnCourier"))
                    if (!$util.isInteger(message.maximumActiveOrdersOnCourier))
                        return "maximumActiveOrdersOnCourier: integer expected";
                return null;
            };

            /**
             * Creates a CreateCompany message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.CreateCompany
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.CreateCompany} CreateCompany
             */
            CreateCompany.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.CreateCompany)
                    return object;
                var message = new $root.fastcity.admin.CreateCompany();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.description != null)
                    message.description = String(object.description);
                if (object.site != null)
                    message.site = String(object.site);
                if (object.email != null)
                    message.email = String(object.email);
                if (object.supportPhone != null)
                    message.supportPhone = String(object.supportPhone);
                if (object.maximumDeliveryTime != null)
                    message.maximumDeliveryTime = object.maximumDeliveryTime >>> 0;
                switch (object.mapType) {
                case "GOOGLE":
                case 0:
                    message.mapType = 0;
                    break;
                case "YANDEX":
                case 1:
                    message.mapType = 1;
                    break;
                }
                if (object.maximumActiveOrdersOnCourier != null)
                    message.maximumActiveOrdersOnCourier = object.maximumActiveOrdersOnCourier >>> 0;
                return message;
            };

            /**
             * Creates a plain object from a CreateCompany message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.CreateCompany
             * @static
             * @param {fastcity.admin.CreateCompany} message CreateCompany
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateCompany.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.name = "";
                    object.description = "";
                    object.site = "";
                    object.email = "";
                    object.supportPhone = "";
                    object.maximumDeliveryTime = 0;
                    object.mapType = options.enums === String ? "GOOGLE" : 0;
                    object.maximumActiveOrdersOnCourier = 0;
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.description != null && message.hasOwnProperty("description"))
                    object.description = message.description;
                if (message.site != null && message.hasOwnProperty("site"))
                    object.site = message.site;
                if (message.email != null && message.hasOwnProperty("email"))
                    object.email = message.email;
                if (message.supportPhone != null && message.hasOwnProperty("supportPhone"))
                    object.supportPhone = message.supportPhone;
                if (message.maximumDeliveryTime != null && message.hasOwnProperty("maximumDeliveryTime"))
                    object.maximumDeliveryTime = message.maximumDeliveryTime;
                if (message.mapType != null && message.hasOwnProperty("mapType"))
                    object.mapType = options.enums === String ? $root.fastcity.common.Company.MapType[message.mapType] : message.mapType;
                if (message.maximumActiveOrdersOnCourier != null && message.hasOwnProperty("maximumActiveOrdersOnCourier"))
                    object.maximumActiveOrdersOnCourier = message.maximumActiveOrdersOnCourier;
                return object;
            };

            /**
             * Converts this CreateCompany to JSON.
             * @function toJSON
             * @memberof fastcity.admin.CreateCompany
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateCompany.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return CreateCompany;
        })();

        admin.UpdateCompany = (function() {

            /**
             * Properties of an UpdateCompany.
             * @memberof fastcity.admin
             * @interface IUpdateCompany
             * @property {number|Long|null} [companyId] UpdateCompany companyId
             * @property {string|null} [name] UpdateCompany name
             * @property {string|null} [description] UpdateCompany description
             * @property {string|null} [site] UpdateCompany site
             * @property {string|null} [email] UpdateCompany email
             * @property {string|null} [supportPhone] UpdateCompany supportPhone
             * @property {number|null} [maximumDeliveryTime] UpdateCompany maximumDeliveryTime
             * @property {number|Long|null} [minimalDistanceToOrderTarget] UpdateCompany minimalDistanceToOrderTarget
             * @property {fastcity.common.Company.MapType|null} [mapType] UpdateCompany mapType
             * @property {number|null} [maximumActiveOrdersOnCourier] UpdateCompany maximumActiveOrdersOnCourier
             */

            /**
             * Constructs a new UpdateCompany.
             * @memberof fastcity.admin
             * @classdesc Represents an UpdateCompany.
             * @implements IUpdateCompany
             * @constructor
             * @param {fastcity.admin.IUpdateCompany=} [properties] Properties to set
             */
            function UpdateCompany(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * UpdateCompany companyId.
             * @member {number|Long} companyId
             * @memberof fastcity.admin.UpdateCompany
             * @instance
             */
            UpdateCompany.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * UpdateCompany name.
             * @member {string} name
             * @memberof fastcity.admin.UpdateCompany
             * @instance
             */
            UpdateCompany.prototype.name = "";

            /**
             * UpdateCompany description.
             * @member {string} description
             * @memberof fastcity.admin.UpdateCompany
             * @instance
             */
            UpdateCompany.prototype.description = "";

            /**
             * UpdateCompany site.
             * @member {string} site
             * @memberof fastcity.admin.UpdateCompany
             * @instance
             */
            UpdateCompany.prototype.site = "";

            /**
             * UpdateCompany email.
             * @member {string} email
             * @memberof fastcity.admin.UpdateCompany
             * @instance
             */
            UpdateCompany.prototype.email = "";

            /**
             * UpdateCompany supportPhone.
             * @member {string} supportPhone
             * @memberof fastcity.admin.UpdateCompany
             * @instance
             */
            UpdateCompany.prototype.supportPhone = "";

            /**
             * UpdateCompany maximumDeliveryTime.
             * @member {number} maximumDeliveryTime
             * @memberof fastcity.admin.UpdateCompany
             * @instance
             */
            UpdateCompany.prototype.maximumDeliveryTime = 0;

            /**
             * UpdateCompany minimalDistanceToOrderTarget.
             * @member {number|Long} minimalDistanceToOrderTarget
             * @memberof fastcity.admin.UpdateCompany
             * @instance
             */
            UpdateCompany.prototype.minimalDistanceToOrderTarget = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * UpdateCompany mapType.
             * @member {fastcity.common.Company.MapType} mapType
             * @memberof fastcity.admin.UpdateCompany
             * @instance
             */
            UpdateCompany.prototype.mapType = 0;

            /**
             * UpdateCompany maximumActiveOrdersOnCourier.
             * @member {number} maximumActiveOrdersOnCourier
             * @memberof fastcity.admin.UpdateCompany
             * @instance
             */
            UpdateCompany.prototype.maximumActiveOrdersOnCourier = 0;

            /**
             * Creates a new UpdateCompany instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.UpdateCompany
             * @static
             * @param {fastcity.admin.IUpdateCompany=} [properties] Properties to set
             * @returns {fastcity.admin.UpdateCompany} UpdateCompany instance
             */
            UpdateCompany.create = function create(properties) {
                return new UpdateCompany(properties);
            };

            /**
             * Encodes the specified UpdateCompany message. Does not implicitly {@link fastcity.admin.UpdateCompany.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.UpdateCompany
             * @static
             * @param {fastcity.admin.IUpdateCompany} message UpdateCompany message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateCompany.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.companyId);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.description);
                if (message.site != null && Object.hasOwnProperty.call(message, "site"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.site);
                if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.email);
                if (message.supportPhone != null && Object.hasOwnProperty.call(message, "supportPhone"))
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.supportPhone);
                if (message.maximumDeliveryTime != null && Object.hasOwnProperty.call(message, "maximumDeliveryTime"))
                    writer.uint32(/* id 7, wireType 0 =*/56).uint32(message.maximumDeliveryTime);
                if (message.minimalDistanceToOrderTarget != null && Object.hasOwnProperty.call(message, "minimalDistanceToOrderTarget"))
                    writer.uint32(/* id 8, wireType 0 =*/64).uint64(message.minimalDistanceToOrderTarget);
                if (message.mapType != null && Object.hasOwnProperty.call(message, "mapType"))
                    writer.uint32(/* id 9, wireType 0 =*/72).int32(message.mapType);
                if (message.maximumActiveOrdersOnCourier != null && Object.hasOwnProperty.call(message, "maximumActiveOrdersOnCourier"))
                    writer.uint32(/* id 10, wireType 0 =*/80).uint32(message.maximumActiveOrdersOnCourier);
                return writer;
            };

            /**
             * Decodes an UpdateCompany message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.UpdateCompany
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.UpdateCompany} UpdateCompany
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateCompany.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.UpdateCompany();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.companyId = reader.uint64();
                        break;
                    case 2:
                        message.name = reader.string();
                        break;
                    case 3:
                        message.description = reader.string();
                        break;
                    case 4:
                        message.site = reader.string();
                        break;
                    case 5:
                        message.email = reader.string();
                        break;
                    case 6:
                        message.supportPhone = reader.string();
                        break;
                    case 7:
                        message.maximumDeliveryTime = reader.uint32();
                        break;
                    case 8:
                        message.minimalDistanceToOrderTarget = reader.uint64();
                        break;
                    case 9:
                        message.mapType = reader.int32();
                        break;
                    case 10:
                        message.maximumActiveOrdersOnCourier = reader.uint32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an UpdateCompany message.
             * @function verify
             * @memberof fastcity.admin.UpdateCompany
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            UpdateCompany.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                        return "companyId: integer|Long expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.description != null && message.hasOwnProperty("description"))
                    if (!$util.isString(message.description))
                        return "description: string expected";
                if (message.site != null && message.hasOwnProperty("site"))
                    if (!$util.isString(message.site))
                        return "site: string expected";
                if (message.email != null && message.hasOwnProperty("email"))
                    if (!$util.isString(message.email))
                        return "email: string expected";
                if (message.supportPhone != null && message.hasOwnProperty("supportPhone"))
                    if (!$util.isString(message.supportPhone))
                        return "supportPhone: string expected";
                if (message.maximumDeliveryTime != null && message.hasOwnProperty("maximumDeliveryTime"))
                    if (!$util.isInteger(message.maximumDeliveryTime))
                        return "maximumDeliveryTime: integer expected";
                if (message.minimalDistanceToOrderTarget != null && message.hasOwnProperty("minimalDistanceToOrderTarget"))
                    if (!$util.isInteger(message.minimalDistanceToOrderTarget) && !(message.minimalDistanceToOrderTarget && $util.isInteger(message.minimalDistanceToOrderTarget.low) && $util.isInteger(message.minimalDistanceToOrderTarget.high)))
                        return "minimalDistanceToOrderTarget: integer|Long expected";
                if (message.mapType != null && message.hasOwnProperty("mapType"))
                    switch (message.mapType) {
                    default:
                        return "mapType: enum value expected";
                    case 0:
                    case 1:
                        break;
                    }
                if (message.maximumActiveOrdersOnCourier != null && message.hasOwnProperty("maximumActiveOrdersOnCourier"))
                    if (!$util.isInteger(message.maximumActiveOrdersOnCourier))
                        return "maximumActiveOrdersOnCourier: integer expected";
                return null;
            };

            /**
             * Creates an UpdateCompany message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.UpdateCompany
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.UpdateCompany} UpdateCompany
             */
            UpdateCompany.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.UpdateCompany)
                    return object;
                var message = new $root.fastcity.admin.UpdateCompany();
                if (object.companyId != null)
                    if ($util.Long)
                        (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = true;
                    else if (typeof object.companyId === "string")
                        message.companyId = parseInt(object.companyId, 10);
                    else if (typeof object.companyId === "number")
                        message.companyId = object.companyId;
                    else if (typeof object.companyId === "object")
                        message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber(true);
                if (object.name != null)
                    message.name = String(object.name);
                if (object.description != null)
                    message.description = String(object.description);
                if (object.site != null)
                    message.site = String(object.site);
                if (object.email != null)
                    message.email = String(object.email);
                if (object.supportPhone != null)
                    message.supportPhone = String(object.supportPhone);
                if (object.maximumDeliveryTime != null)
                    message.maximumDeliveryTime = object.maximumDeliveryTime >>> 0;
                if (object.minimalDistanceToOrderTarget != null)
                    if ($util.Long)
                        (message.minimalDistanceToOrderTarget = $util.Long.fromValue(object.minimalDistanceToOrderTarget)).unsigned = true;
                    else if (typeof object.minimalDistanceToOrderTarget === "string")
                        message.minimalDistanceToOrderTarget = parseInt(object.minimalDistanceToOrderTarget, 10);
                    else if (typeof object.minimalDistanceToOrderTarget === "number")
                        message.minimalDistanceToOrderTarget = object.minimalDistanceToOrderTarget;
                    else if (typeof object.minimalDistanceToOrderTarget === "object")
                        message.minimalDistanceToOrderTarget = new $util.LongBits(object.minimalDistanceToOrderTarget.low >>> 0, object.minimalDistanceToOrderTarget.high >>> 0).toNumber(true);
                switch (object.mapType) {
                case "GOOGLE":
                case 0:
                    message.mapType = 0;
                    break;
                case "YANDEX":
                case 1:
                    message.mapType = 1;
                    break;
                }
                if (object.maximumActiveOrdersOnCourier != null)
                    message.maximumActiveOrdersOnCourier = object.maximumActiveOrdersOnCourier >>> 0;
                return message;
            };

            /**
             * Creates a plain object from an UpdateCompany message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.UpdateCompany
             * @static
             * @param {fastcity.admin.UpdateCompany} message UpdateCompany
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UpdateCompany.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.companyId = options.longs === String ? "0" : 0;
                    object.name = "";
                    object.description = "";
                    object.site = "";
                    object.email = "";
                    object.supportPhone = "";
                    object.maximumDeliveryTime = 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.minimalDistanceToOrderTarget = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.minimalDistanceToOrderTarget = options.longs === String ? "0" : 0;
                    object.mapType = options.enums === String ? "GOOGLE" : 0;
                    object.maximumActiveOrdersOnCourier = 0;
                }
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (typeof message.companyId === "number")
                        object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                    else
                        object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber(true) : message.companyId;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.description != null && message.hasOwnProperty("description"))
                    object.description = message.description;
                if (message.site != null && message.hasOwnProperty("site"))
                    object.site = message.site;
                if (message.email != null && message.hasOwnProperty("email"))
                    object.email = message.email;
                if (message.supportPhone != null && message.hasOwnProperty("supportPhone"))
                    object.supportPhone = message.supportPhone;
                if (message.maximumDeliveryTime != null && message.hasOwnProperty("maximumDeliveryTime"))
                    object.maximumDeliveryTime = message.maximumDeliveryTime;
                if (message.minimalDistanceToOrderTarget != null && message.hasOwnProperty("minimalDistanceToOrderTarget"))
                    if (typeof message.minimalDistanceToOrderTarget === "number")
                        object.minimalDistanceToOrderTarget = options.longs === String ? String(message.minimalDistanceToOrderTarget) : message.minimalDistanceToOrderTarget;
                    else
                        object.minimalDistanceToOrderTarget = options.longs === String ? $util.Long.prototype.toString.call(message.minimalDistanceToOrderTarget) : options.longs === Number ? new $util.LongBits(message.minimalDistanceToOrderTarget.low >>> 0, message.minimalDistanceToOrderTarget.high >>> 0).toNumber(true) : message.minimalDistanceToOrderTarget;
                if (message.mapType != null && message.hasOwnProperty("mapType"))
                    object.mapType = options.enums === String ? $root.fastcity.common.Company.MapType[message.mapType] : message.mapType;
                if (message.maximumActiveOrdersOnCourier != null && message.hasOwnProperty("maximumActiveOrdersOnCourier"))
                    object.maximumActiveOrdersOnCourier = message.maximumActiveOrdersOnCourier;
                return object;
            };

            /**
             * Converts this UpdateCompany to JSON.
             * @function toJSON
             * @memberof fastcity.admin.UpdateCompany
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UpdateCompany.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return UpdateCompany;
        })();

        admin.DeleteCompany = (function() {

            /**
             * Properties of a DeleteCompany.
             * @memberof fastcity.admin
             * @interface IDeleteCompany
             * @property {number|Long|null} [companyId] DeleteCompany companyId
             */

            /**
             * Constructs a new DeleteCompany.
             * @memberof fastcity.admin
             * @classdesc Represents a DeleteCompany.
             * @implements IDeleteCompany
             * @constructor
             * @param {fastcity.admin.IDeleteCompany=} [properties] Properties to set
             */
            function DeleteCompany(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * DeleteCompany companyId.
             * @member {number|Long} companyId
             * @memberof fastcity.admin.DeleteCompany
             * @instance
             */
            DeleteCompany.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Creates a new DeleteCompany instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.DeleteCompany
             * @static
             * @param {fastcity.admin.IDeleteCompany=} [properties] Properties to set
             * @returns {fastcity.admin.DeleteCompany} DeleteCompany instance
             */
            DeleteCompany.create = function create(properties) {
                return new DeleteCompany(properties);
            };

            /**
             * Encodes the specified DeleteCompany message. Does not implicitly {@link fastcity.admin.DeleteCompany.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.DeleteCompany
             * @static
             * @param {fastcity.admin.IDeleteCompany} message DeleteCompany message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteCompany.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.companyId);
                return writer;
            };

            /**
             * Decodes a DeleteCompany message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.DeleteCompany
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.DeleteCompany} DeleteCompany
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteCompany.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.DeleteCompany();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.companyId = reader.uint64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a DeleteCompany message.
             * @function verify
             * @memberof fastcity.admin.DeleteCompany
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DeleteCompany.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                        return "companyId: integer|Long expected";
                return null;
            };

            /**
             * Creates a DeleteCompany message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.DeleteCompany
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.DeleteCompany} DeleteCompany
             */
            DeleteCompany.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.DeleteCompany)
                    return object;
                var message = new $root.fastcity.admin.DeleteCompany();
                if (object.companyId != null)
                    if ($util.Long)
                        (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = true;
                    else if (typeof object.companyId === "string")
                        message.companyId = parseInt(object.companyId, 10);
                    else if (typeof object.companyId === "number")
                        message.companyId = object.companyId;
                    else if (typeof object.companyId === "object")
                        message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber(true);
                return message;
            };

            /**
             * Creates a plain object from a DeleteCompany message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.DeleteCompany
             * @static
             * @param {fastcity.admin.DeleteCompany} message DeleteCompany
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DeleteCompany.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.companyId = options.longs === String ? "0" : 0;
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (typeof message.companyId === "number")
                        object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                    else
                        object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber(true) : message.companyId;
                return object;
            };

            /**
             * Converts this DeleteCompany to JSON.
             * @function toJSON
             * @memberof fastcity.admin.DeleteCompany
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DeleteCompany.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return DeleteCompany;
        })();

        admin.GetCurrentCompany = (function() {

            /**
             * Properties of a GetCurrentCompany.
             * @memberof fastcity.admin
             * @interface IGetCurrentCompany
             */

            /**
             * Constructs a new GetCurrentCompany.
             * @memberof fastcity.admin
             * @classdesc Represents a GetCurrentCompany.
             * @implements IGetCurrentCompany
             * @constructor
             * @param {fastcity.admin.IGetCurrentCompany=} [properties] Properties to set
             */
            function GetCurrentCompany(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new GetCurrentCompany instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.GetCurrentCompany
             * @static
             * @param {fastcity.admin.IGetCurrentCompany=} [properties] Properties to set
             * @returns {fastcity.admin.GetCurrentCompany} GetCurrentCompany instance
             */
            GetCurrentCompany.create = function create(properties) {
                return new GetCurrentCompany(properties);
            };

            /**
             * Encodes the specified GetCurrentCompany message. Does not implicitly {@link fastcity.admin.GetCurrentCompany.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.GetCurrentCompany
             * @static
             * @param {fastcity.admin.IGetCurrentCompany} message GetCurrentCompany message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetCurrentCompany.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Decodes a GetCurrentCompany message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.GetCurrentCompany
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.GetCurrentCompany} GetCurrentCompany
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetCurrentCompany.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.GetCurrentCompany();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a GetCurrentCompany message.
             * @function verify
             * @memberof fastcity.admin.GetCurrentCompany
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetCurrentCompany.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a GetCurrentCompany message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.GetCurrentCompany
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.GetCurrentCompany} GetCurrentCompany
             */
            GetCurrentCompany.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.GetCurrentCompany)
                    return object;
                return new $root.fastcity.admin.GetCurrentCompany();
            };

            /**
             * Creates a plain object from a GetCurrentCompany message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.GetCurrentCompany
             * @static
             * @param {fastcity.admin.GetCurrentCompany} message GetCurrentCompany
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetCurrentCompany.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this GetCurrentCompany to JSON.
             * @function toJSON
             * @memberof fastcity.admin.GetCurrentCompany
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetCurrentCompany.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return GetCurrentCompany;
        })();

        admin.GetCompanyList = (function() {

            /**
             * Properties of a GetCompanyList.
             * @memberof fastcity.admin
             * @interface IGetCompanyList
             * @property {Array.<number|Long>|null} [companyId] GetCompanyList companyId
             * @property {boolean|null} [withDeleted] GetCompanyList withDeleted
             */

            /**
             * Constructs a new GetCompanyList.
             * @memberof fastcity.admin
             * @classdesc Represents a GetCompanyList.
             * @implements IGetCompanyList
             * @constructor
             * @param {fastcity.admin.IGetCompanyList=} [properties] Properties to set
             */
            function GetCompanyList(properties) {
                this.companyId = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GetCompanyList companyId.
             * @member {Array.<number|Long>} companyId
             * @memberof fastcity.admin.GetCompanyList
             * @instance
             */
            GetCompanyList.prototype.companyId = $util.emptyArray;

            /**
             * GetCompanyList withDeleted.
             * @member {boolean} withDeleted
             * @memberof fastcity.admin.GetCompanyList
             * @instance
             */
            GetCompanyList.prototype.withDeleted = false;

            /**
             * Creates a new GetCompanyList instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.GetCompanyList
             * @static
             * @param {fastcity.admin.IGetCompanyList=} [properties] Properties to set
             * @returns {fastcity.admin.GetCompanyList} GetCompanyList instance
             */
            GetCompanyList.create = function create(properties) {
                return new GetCompanyList(properties);
            };

            /**
             * Encodes the specified GetCompanyList message. Does not implicitly {@link fastcity.admin.GetCompanyList.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.GetCompanyList
             * @static
             * @param {fastcity.admin.IGetCompanyList} message GetCompanyList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetCompanyList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.companyId != null && message.companyId.length) {
                    writer.uint32(/* id 1, wireType 2 =*/10).fork();
                    for (var i = 0; i < message.companyId.length; ++i)
                        writer.uint64(message.companyId[i]);
                    writer.ldelim();
                }
                if (message.withDeleted != null && Object.hasOwnProperty.call(message, "withDeleted"))
                    writer.uint32(/* id 2, wireType 0 =*/16).bool(message.withDeleted);
                return writer;
            };

            /**
             * Decodes a GetCompanyList message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.GetCompanyList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.GetCompanyList} GetCompanyList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetCompanyList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.GetCompanyList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.companyId && message.companyId.length))
                            message.companyId = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.companyId.push(reader.uint64());
                        } else
                            message.companyId.push(reader.uint64());
                        break;
                    case 2:
                        message.withDeleted = reader.bool();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a GetCompanyList message.
             * @function verify
             * @memberof fastcity.admin.GetCompanyList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetCompanyList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.companyId != null && message.hasOwnProperty("companyId")) {
                    if (!Array.isArray(message.companyId))
                        return "companyId: array expected";
                    for (var i = 0; i < message.companyId.length; ++i)
                        if (!$util.isInteger(message.companyId[i]) && !(message.companyId[i] && $util.isInteger(message.companyId[i].low) && $util.isInteger(message.companyId[i].high)))
                            return "companyId: integer|Long[] expected";
                }
                if (message.withDeleted != null && message.hasOwnProperty("withDeleted"))
                    if (typeof message.withDeleted !== "boolean")
                        return "withDeleted: boolean expected";
                return null;
            };

            /**
             * Creates a GetCompanyList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.GetCompanyList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.GetCompanyList} GetCompanyList
             */
            GetCompanyList.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.GetCompanyList)
                    return object;
                var message = new $root.fastcity.admin.GetCompanyList();
                if (object.companyId) {
                    if (!Array.isArray(object.companyId))
                        throw TypeError(".fastcity.admin.GetCompanyList.companyId: array expected");
                    message.companyId = [];
                    for (var i = 0; i < object.companyId.length; ++i)
                        if ($util.Long)
                            (message.companyId[i] = $util.Long.fromValue(object.companyId[i])).unsigned = true;
                        else if (typeof object.companyId[i] === "string")
                            message.companyId[i] = parseInt(object.companyId[i], 10);
                        else if (typeof object.companyId[i] === "number")
                            message.companyId[i] = object.companyId[i];
                        else if (typeof object.companyId[i] === "object")
                            message.companyId[i] = new $util.LongBits(object.companyId[i].low >>> 0, object.companyId[i].high >>> 0).toNumber(true);
                }
                if (object.withDeleted != null)
                    message.withDeleted = Boolean(object.withDeleted);
                return message;
            };

            /**
             * Creates a plain object from a GetCompanyList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.GetCompanyList
             * @static
             * @param {fastcity.admin.GetCompanyList} message GetCompanyList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetCompanyList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.companyId = [];
                if (options.defaults)
                    object.withDeleted = false;
                if (message.companyId && message.companyId.length) {
                    object.companyId = [];
                    for (var j = 0; j < message.companyId.length; ++j)
                        if (typeof message.companyId[j] === "number")
                            object.companyId[j] = options.longs === String ? String(message.companyId[j]) : message.companyId[j];
                        else
                            object.companyId[j] = options.longs === String ? $util.Long.prototype.toString.call(message.companyId[j]) : options.longs === Number ? new $util.LongBits(message.companyId[j].low >>> 0, message.companyId[j].high >>> 0).toNumber(true) : message.companyId[j];
                }
                if (message.withDeleted != null && message.hasOwnProperty("withDeleted"))
                    object.withDeleted = message.withDeleted;
                return object;
            };

            /**
             * Converts this GetCompanyList to JSON.
             * @function toJSON
             * @memberof fastcity.admin.GetCompanyList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetCompanyList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return GetCompanyList;
        })();

        admin.CompanyList = (function() {

            /**
             * Properties of a CompanyList.
             * @memberof fastcity.admin
             * @interface ICompanyList
             * @property {Array.<fastcity.admin.ICompany>|null} [company] CompanyList company
             */

            /**
             * Constructs a new CompanyList.
             * @memberof fastcity.admin
             * @classdesc Represents a CompanyList.
             * @implements ICompanyList
             * @constructor
             * @param {fastcity.admin.ICompanyList=} [properties] Properties to set
             */
            function CompanyList(properties) {
                this.company = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CompanyList company.
             * @member {Array.<fastcity.admin.ICompany>} company
             * @memberof fastcity.admin.CompanyList
             * @instance
             */
            CompanyList.prototype.company = $util.emptyArray;

            /**
             * Creates a new CompanyList instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.CompanyList
             * @static
             * @param {fastcity.admin.ICompanyList=} [properties] Properties to set
             * @returns {fastcity.admin.CompanyList} CompanyList instance
             */
            CompanyList.create = function create(properties) {
                return new CompanyList(properties);
            };

            /**
             * Encodes the specified CompanyList message. Does not implicitly {@link fastcity.admin.CompanyList.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.CompanyList
             * @static
             * @param {fastcity.admin.ICompanyList} message CompanyList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CompanyList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.company != null && message.company.length)
                    for (var i = 0; i < message.company.length; ++i)
                        $root.fastcity.admin.Company.encode(message.company[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a CompanyList message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.CompanyList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.CompanyList} CompanyList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CompanyList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.CompanyList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.company && message.company.length))
                            message.company = [];
                        message.company.push($root.fastcity.admin.Company.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a CompanyList message.
             * @function verify
             * @memberof fastcity.admin.CompanyList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CompanyList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.company != null && message.hasOwnProperty("company")) {
                    if (!Array.isArray(message.company))
                        return "company: array expected";
                    for (var i = 0; i < message.company.length; ++i) {
                        var error = $root.fastcity.admin.Company.verify(message.company[i]);
                        if (error)
                            return "company." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a CompanyList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.CompanyList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.CompanyList} CompanyList
             */
            CompanyList.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.CompanyList)
                    return object;
                var message = new $root.fastcity.admin.CompanyList();
                if (object.company) {
                    if (!Array.isArray(object.company))
                        throw TypeError(".fastcity.admin.CompanyList.company: array expected");
                    message.company = [];
                    for (var i = 0; i < object.company.length; ++i) {
                        if (typeof object.company[i] !== "object")
                            throw TypeError(".fastcity.admin.CompanyList.company: object expected");
                        message.company[i] = $root.fastcity.admin.Company.fromObject(object.company[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a CompanyList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.CompanyList
             * @static
             * @param {fastcity.admin.CompanyList} message CompanyList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CompanyList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.company = [];
                if (message.company && message.company.length) {
                    object.company = [];
                    for (var j = 0; j < message.company.length; ++j)
                        object.company[j] = $root.fastcity.admin.Company.toObject(message.company[j], options);
                }
                return object;
            };

            /**
             * Converts this CompanyList to JSON.
             * @function toJSON
             * @memberof fastcity.admin.CompanyList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CompanyList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return CompanyList;
        })();

        admin.GetSubCompanyIdList = (function() {

            /**
             * Properties of a GetSubCompanyIdList.
             * @memberof fastcity.admin
             * @interface IGetSubCompanyIdList
             * @property {Array.<number|Long>|null} [companyId] GetSubCompanyIdList companyId
             * @property {boolean|null} [withDeleted] GetSubCompanyIdList withDeleted
             */

            /**
             * Constructs a new GetSubCompanyIdList.
             * @memberof fastcity.admin
             * @classdesc Represents a GetSubCompanyIdList.
             * @implements IGetSubCompanyIdList
             * @constructor
             * @param {fastcity.admin.IGetSubCompanyIdList=} [properties] Properties to set
             */
            function GetSubCompanyIdList(properties) {
                this.companyId = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GetSubCompanyIdList companyId.
             * @member {Array.<number|Long>} companyId
             * @memberof fastcity.admin.GetSubCompanyIdList
             * @instance
             */
            GetSubCompanyIdList.prototype.companyId = $util.emptyArray;

            /**
             * GetSubCompanyIdList withDeleted.
             * @member {boolean} withDeleted
             * @memberof fastcity.admin.GetSubCompanyIdList
             * @instance
             */
            GetSubCompanyIdList.prototype.withDeleted = false;

            /**
             * Creates a new GetSubCompanyIdList instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.GetSubCompanyIdList
             * @static
             * @param {fastcity.admin.IGetSubCompanyIdList=} [properties] Properties to set
             * @returns {fastcity.admin.GetSubCompanyIdList} GetSubCompanyIdList instance
             */
            GetSubCompanyIdList.create = function create(properties) {
                return new GetSubCompanyIdList(properties);
            };

            /**
             * Encodes the specified GetSubCompanyIdList message. Does not implicitly {@link fastcity.admin.GetSubCompanyIdList.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.GetSubCompanyIdList
             * @static
             * @param {fastcity.admin.IGetSubCompanyIdList} message GetSubCompanyIdList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetSubCompanyIdList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.companyId != null && message.companyId.length) {
                    writer.uint32(/* id 1, wireType 2 =*/10).fork();
                    for (var i = 0; i < message.companyId.length; ++i)
                        writer.uint64(message.companyId[i]);
                    writer.ldelim();
                }
                if (message.withDeleted != null && Object.hasOwnProperty.call(message, "withDeleted"))
                    writer.uint32(/* id 2, wireType 0 =*/16).bool(message.withDeleted);
                return writer;
            };

            /**
             * Decodes a GetSubCompanyIdList message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.GetSubCompanyIdList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.GetSubCompanyIdList} GetSubCompanyIdList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetSubCompanyIdList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.GetSubCompanyIdList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.companyId && message.companyId.length))
                            message.companyId = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.companyId.push(reader.uint64());
                        } else
                            message.companyId.push(reader.uint64());
                        break;
                    case 2:
                        message.withDeleted = reader.bool();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a GetSubCompanyIdList message.
             * @function verify
             * @memberof fastcity.admin.GetSubCompanyIdList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetSubCompanyIdList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.companyId != null && message.hasOwnProperty("companyId")) {
                    if (!Array.isArray(message.companyId))
                        return "companyId: array expected";
                    for (var i = 0; i < message.companyId.length; ++i)
                        if (!$util.isInteger(message.companyId[i]) && !(message.companyId[i] && $util.isInteger(message.companyId[i].low) && $util.isInteger(message.companyId[i].high)))
                            return "companyId: integer|Long[] expected";
                }
                if (message.withDeleted != null && message.hasOwnProperty("withDeleted"))
                    if (typeof message.withDeleted !== "boolean")
                        return "withDeleted: boolean expected";
                return null;
            };

            /**
             * Creates a GetSubCompanyIdList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.GetSubCompanyIdList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.GetSubCompanyIdList} GetSubCompanyIdList
             */
            GetSubCompanyIdList.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.GetSubCompanyIdList)
                    return object;
                var message = new $root.fastcity.admin.GetSubCompanyIdList();
                if (object.companyId) {
                    if (!Array.isArray(object.companyId))
                        throw TypeError(".fastcity.admin.GetSubCompanyIdList.companyId: array expected");
                    message.companyId = [];
                    for (var i = 0; i < object.companyId.length; ++i)
                        if ($util.Long)
                            (message.companyId[i] = $util.Long.fromValue(object.companyId[i])).unsigned = true;
                        else if (typeof object.companyId[i] === "string")
                            message.companyId[i] = parseInt(object.companyId[i], 10);
                        else if (typeof object.companyId[i] === "number")
                            message.companyId[i] = object.companyId[i];
                        else if (typeof object.companyId[i] === "object")
                            message.companyId[i] = new $util.LongBits(object.companyId[i].low >>> 0, object.companyId[i].high >>> 0).toNumber(true);
                }
                if (object.withDeleted != null)
                    message.withDeleted = Boolean(object.withDeleted);
                return message;
            };

            /**
             * Creates a plain object from a GetSubCompanyIdList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.GetSubCompanyIdList
             * @static
             * @param {fastcity.admin.GetSubCompanyIdList} message GetSubCompanyIdList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetSubCompanyIdList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.companyId = [];
                if (options.defaults)
                    object.withDeleted = false;
                if (message.companyId && message.companyId.length) {
                    object.companyId = [];
                    for (var j = 0; j < message.companyId.length; ++j)
                        if (typeof message.companyId[j] === "number")
                            object.companyId[j] = options.longs === String ? String(message.companyId[j]) : message.companyId[j];
                        else
                            object.companyId[j] = options.longs === String ? $util.Long.prototype.toString.call(message.companyId[j]) : options.longs === Number ? new $util.LongBits(message.companyId[j].low >>> 0, message.companyId[j].high >>> 0).toNumber(true) : message.companyId[j];
                }
                if (message.withDeleted != null && message.hasOwnProperty("withDeleted"))
                    object.withDeleted = message.withDeleted;
                return object;
            };

            /**
             * Converts this GetSubCompanyIdList to JSON.
             * @function toJSON
             * @memberof fastcity.admin.GetSubCompanyIdList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetSubCompanyIdList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return GetSubCompanyIdList;
        })();

        admin.SubCompanyIdList = (function() {

            /**
             * Properties of a SubCompanyIdList.
             * @memberof fastcity.admin
             * @interface ISubCompanyIdList
             * @property {Array.<fastcity.admin.SubCompanyIdList.IValue>|null} [value] SubCompanyIdList value
             */

            /**
             * Constructs a new SubCompanyIdList.
             * @memberof fastcity.admin
             * @classdesc Represents a SubCompanyIdList.
             * @implements ISubCompanyIdList
             * @constructor
             * @param {fastcity.admin.ISubCompanyIdList=} [properties] Properties to set
             */
            function SubCompanyIdList(properties) {
                this.value = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * SubCompanyIdList value.
             * @member {Array.<fastcity.admin.SubCompanyIdList.IValue>} value
             * @memberof fastcity.admin.SubCompanyIdList
             * @instance
             */
            SubCompanyIdList.prototype.value = $util.emptyArray;

            /**
             * Creates a new SubCompanyIdList instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.SubCompanyIdList
             * @static
             * @param {fastcity.admin.ISubCompanyIdList=} [properties] Properties to set
             * @returns {fastcity.admin.SubCompanyIdList} SubCompanyIdList instance
             */
            SubCompanyIdList.create = function create(properties) {
                return new SubCompanyIdList(properties);
            };

            /**
             * Encodes the specified SubCompanyIdList message. Does not implicitly {@link fastcity.admin.SubCompanyIdList.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.SubCompanyIdList
             * @static
             * @param {fastcity.admin.ISubCompanyIdList} message SubCompanyIdList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SubCompanyIdList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.value != null && message.value.length)
                    for (var i = 0; i < message.value.length; ++i)
                        $root.fastcity.admin.SubCompanyIdList.Value.encode(message.value[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a SubCompanyIdList message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.SubCompanyIdList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.SubCompanyIdList} SubCompanyIdList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SubCompanyIdList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.SubCompanyIdList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.value && message.value.length))
                            message.value = [];
                        message.value.push($root.fastcity.admin.SubCompanyIdList.Value.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a SubCompanyIdList message.
             * @function verify
             * @memberof fastcity.admin.SubCompanyIdList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SubCompanyIdList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.value != null && message.hasOwnProperty("value")) {
                    if (!Array.isArray(message.value))
                        return "value: array expected";
                    for (var i = 0; i < message.value.length; ++i) {
                        var error = $root.fastcity.admin.SubCompanyIdList.Value.verify(message.value[i]);
                        if (error)
                            return "value." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a SubCompanyIdList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.SubCompanyIdList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.SubCompanyIdList} SubCompanyIdList
             */
            SubCompanyIdList.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.SubCompanyIdList)
                    return object;
                var message = new $root.fastcity.admin.SubCompanyIdList();
                if (object.value) {
                    if (!Array.isArray(object.value))
                        throw TypeError(".fastcity.admin.SubCompanyIdList.value: array expected");
                    message.value = [];
                    for (var i = 0; i < object.value.length; ++i) {
                        if (typeof object.value[i] !== "object")
                            throw TypeError(".fastcity.admin.SubCompanyIdList.value: object expected");
                        message.value[i] = $root.fastcity.admin.SubCompanyIdList.Value.fromObject(object.value[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a SubCompanyIdList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.SubCompanyIdList
             * @static
             * @param {fastcity.admin.SubCompanyIdList} message SubCompanyIdList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SubCompanyIdList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.value = [];
                if (message.value && message.value.length) {
                    object.value = [];
                    for (var j = 0; j < message.value.length; ++j)
                        object.value[j] = $root.fastcity.admin.SubCompanyIdList.Value.toObject(message.value[j], options);
                }
                return object;
            };

            /**
             * Converts this SubCompanyIdList to JSON.
             * @function toJSON
             * @memberof fastcity.admin.SubCompanyIdList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SubCompanyIdList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            SubCompanyIdList.Value = (function() {

                /**
                 * Properties of a Value.
                 * @memberof fastcity.admin.SubCompanyIdList
                 * @interface IValue
                 * @property {number|Long|null} [companyId] Value companyId
                 * @property {number|Long|null} [subCompanyId] Value subCompanyId
                 */

                /**
                 * Constructs a new Value.
                 * @memberof fastcity.admin.SubCompanyIdList
                 * @classdesc Represents a Value.
                 * @implements IValue
                 * @constructor
                 * @param {fastcity.admin.SubCompanyIdList.IValue=} [properties] Properties to set
                 */
                function Value(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Value companyId.
                 * @member {number|Long} companyId
                 * @memberof fastcity.admin.SubCompanyIdList.Value
                 * @instance
                 */
                Value.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

                /**
                 * Value subCompanyId.
                 * @member {number|Long} subCompanyId
                 * @memberof fastcity.admin.SubCompanyIdList.Value
                 * @instance
                 */
                Value.prototype.subCompanyId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

                /**
                 * Creates a new Value instance using the specified properties.
                 * @function create
                 * @memberof fastcity.admin.SubCompanyIdList.Value
                 * @static
                 * @param {fastcity.admin.SubCompanyIdList.IValue=} [properties] Properties to set
                 * @returns {fastcity.admin.SubCompanyIdList.Value} Value instance
                 */
                Value.create = function create(properties) {
                    return new Value(properties);
                };

                /**
                 * Encodes the specified Value message. Does not implicitly {@link fastcity.admin.SubCompanyIdList.Value.verify|verify} messages.
                 * @function encode
                 * @memberof fastcity.admin.SubCompanyIdList.Value
                 * @static
                 * @param {fastcity.admin.SubCompanyIdList.IValue} message Value message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Value.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                        writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.companyId);
                    if (message.subCompanyId != null && Object.hasOwnProperty.call(message, "subCompanyId"))
                        writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.subCompanyId);
                    return writer;
                };

                /**
                 * Decodes a Value message from the specified reader or buffer.
                 * @function decode
                 * @memberof fastcity.admin.SubCompanyIdList.Value
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {fastcity.admin.SubCompanyIdList.Value} Value
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Value.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.SubCompanyIdList.Value();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.companyId = reader.uint64();
                            break;
                        case 2:
                            message.subCompanyId = reader.uint64();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Verifies a Value message.
                 * @function verify
                 * @memberof fastcity.admin.SubCompanyIdList.Value
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Value.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.companyId != null && message.hasOwnProperty("companyId"))
                        if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                            return "companyId: integer|Long expected";
                    if (message.subCompanyId != null && message.hasOwnProperty("subCompanyId"))
                        if (!$util.isInteger(message.subCompanyId) && !(message.subCompanyId && $util.isInteger(message.subCompanyId.low) && $util.isInteger(message.subCompanyId.high)))
                            return "subCompanyId: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a Value message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof fastcity.admin.SubCompanyIdList.Value
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {fastcity.admin.SubCompanyIdList.Value} Value
                 */
                Value.fromObject = function fromObject(object) {
                    if (object instanceof $root.fastcity.admin.SubCompanyIdList.Value)
                        return object;
                    var message = new $root.fastcity.admin.SubCompanyIdList.Value();
                    if (object.companyId != null)
                        if ($util.Long)
                            (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = true;
                        else if (typeof object.companyId === "string")
                            message.companyId = parseInt(object.companyId, 10);
                        else if (typeof object.companyId === "number")
                            message.companyId = object.companyId;
                        else if (typeof object.companyId === "object")
                            message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber(true);
                    if (object.subCompanyId != null)
                        if ($util.Long)
                            (message.subCompanyId = $util.Long.fromValue(object.subCompanyId)).unsigned = true;
                        else if (typeof object.subCompanyId === "string")
                            message.subCompanyId = parseInt(object.subCompanyId, 10);
                        else if (typeof object.subCompanyId === "number")
                            message.subCompanyId = object.subCompanyId;
                        else if (typeof object.subCompanyId === "object")
                            message.subCompanyId = new $util.LongBits(object.subCompanyId.low >>> 0, object.subCompanyId.high >>> 0).toNumber(true);
                    return message;
                };

                /**
                 * Creates a plain object from a Value message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof fastcity.admin.SubCompanyIdList.Value
                 * @static
                 * @param {fastcity.admin.SubCompanyIdList.Value} message Value
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Value.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, true);
                            object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.companyId = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, true);
                            object.subCompanyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.subCompanyId = options.longs === String ? "0" : 0;
                    }
                    if (message.companyId != null && message.hasOwnProperty("companyId"))
                        if (typeof message.companyId === "number")
                            object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                        else
                            object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber(true) : message.companyId;
                    if (message.subCompanyId != null && message.hasOwnProperty("subCompanyId"))
                        if (typeof message.subCompanyId === "number")
                            object.subCompanyId = options.longs === String ? String(message.subCompanyId) : message.subCompanyId;
                        else
                            object.subCompanyId = options.longs === String ? $util.Long.prototype.toString.call(message.subCompanyId) : options.longs === Number ? new $util.LongBits(message.subCompanyId.low >>> 0, message.subCompanyId.high >>> 0).toNumber(true) : message.subCompanyId;
                    return object;
                };

                /**
                 * Converts this Value to JSON.
                 * @function toJSON
                 * @memberof fastcity.admin.SubCompanyIdList.Value
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Value.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return Value;
            })();

            return SubCompanyIdList;
        })();

        admin.ConnectionStatus = (function() {

            /**
             * Properties of a ConnectionStatus.
             * @memberof fastcity.admin
             * @interface IConnectionStatus
             * @property {number|Long|null} [companyId] ConnectionStatus companyId
             * @property {string|null} [externalSubCompanyId] ConnectionStatus externalSubCompanyId
             * @property {fastcity.admin.ConnectionStatus.Status|null} [status] ConnectionStatus status
             * @property {fastcity.admin.ConnectionStatus.ILauncher|null} [launcher] ConnectionStatus launcher
             * @property {fastcity.admin.ConnectionStatus.IConnector|null} [connector] ConnectionStatus connector
             */

            /**
             * Constructs a new ConnectionStatus.
             * @memberof fastcity.admin
             * @classdesc Represents a ConnectionStatus.
             * @implements IConnectionStatus
             * @constructor
             * @param {fastcity.admin.IConnectionStatus=} [properties] Properties to set
             */
            function ConnectionStatus(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ConnectionStatus companyId.
             * @member {number|Long} companyId
             * @memberof fastcity.admin.ConnectionStatus
             * @instance
             */
            ConnectionStatus.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * ConnectionStatus externalSubCompanyId.
             * @member {string} externalSubCompanyId
             * @memberof fastcity.admin.ConnectionStatus
             * @instance
             */
            ConnectionStatus.prototype.externalSubCompanyId = "";

            /**
             * ConnectionStatus status.
             * @member {fastcity.admin.ConnectionStatus.Status} status
             * @memberof fastcity.admin.ConnectionStatus
             * @instance
             */
            ConnectionStatus.prototype.status = 0;

            /**
             * ConnectionStatus launcher.
             * @member {fastcity.admin.ConnectionStatus.ILauncher|null|undefined} launcher
             * @memberof fastcity.admin.ConnectionStatus
             * @instance
             */
            ConnectionStatus.prototype.launcher = null;

            /**
             * ConnectionStatus connector.
             * @member {fastcity.admin.ConnectionStatus.IConnector|null|undefined} connector
             * @memberof fastcity.admin.ConnectionStatus
             * @instance
             */
            ConnectionStatus.prototype.connector = null;

            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;

            /**
             * ConnectionStatus type.
             * @member {"launcher"|"connector"|undefined} type
             * @memberof fastcity.admin.ConnectionStatus
             * @instance
             */
            Object.defineProperty(ConnectionStatus.prototype, "type", {
                get: $util.oneOfGetter($oneOfFields = ["launcher", "connector"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Creates a new ConnectionStatus instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.ConnectionStatus
             * @static
             * @param {fastcity.admin.IConnectionStatus=} [properties] Properties to set
             * @returns {fastcity.admin.ConnectionStatus} ConnectionStatus instance
             */
            ConnectionStatus.create = function create(properties) {
                return new ConnectionStatus(properties);
            };

            /**
             * Encodes the specified ConnectionStatus message. Does not implicitly {@link fastcity.admin.ConnectionStatus.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.ConnectionStatus
             * @static
             * @param {fastcity.admin.IConnectionStatus} message ConnectionStatus message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ConnectionStatus.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.companyId);
                if (message.externalSubCompanyId != null && Object.hasOwnProperty.call(message, "externalSubCompanyId"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.externalSubCompanyId);
                if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.status);
                if (message.launcher != null && Object.hasOwnProperty.call(message, "launcher"))
                    $root.fastcity.admin.ConnectionStatus.Launcher.encode(message.launcher, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.connector != null && Object.hasOwnProperty.call(message, "connector"))
                    $root.fastcity.admin.ConnectionStatus.Connector.encode(message.connector, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a ConnectionStatus message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.ConnectionStatus
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.ConnectionStatus} ConnectionStatus
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ConnectionStatus.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.ConnectionStatus();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.companyId = reader.uint64();
                        break;
                    case 2:
                        message.externalSubCompanyId = reader.string();
                        break;
                    case 3:
                        message.status = reader.int32();
                        break;
                    case 4:
                        message.launcher = $root.fastcity.admin.ConnectionStatus.Launcher.decode(reader, reader.uint32());
                        break;
                    case 5:
                        message.connector = $root.fastcity.admin.ConnectionStatus.Connector.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a ConnectionStatus message.
             * @function verify
             * @memberof fastcity.admin.ConnectionStatus
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ConnectionStatus.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                var properties = {};
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                        return "companyId: integer|Long expected";
                if (message.externalSubCompanyId != null && message.hasOwnProperty("externalSubCompanyId"))
                    if (!$util.isString(message.externalSubCompanyId))
                        return "externalSubCompanyId: string expected";
                if (message.status != null && message.hasOwnProperty("status"))
                    switch (message.status) {
                    default:
                        return "status: enum value expected";
                    case 0:
                    case 1:
                        break;
                    }
                if (message.launcher != null && message.hasOwnProperty("launcher")) {
                    properties.type = 1;
                    {
                        var error = $root.fastcity.admin.ConnectionStatus.Launcher.verify(message.launcher);
                        if (error)
                            return "launcher." + error;
                    }
                }
                if (message.connector != null && message.hasOwnProperty("connector")) {
                    if (properties.type === 1)
                        return "type: multiple values";
                    properties.type = 1;
                    {
                        var error = $root.fastcity.admin.ConnectionStatus.Connector.verify(message.connector);
                        if (error)
                            return "connector." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a ConnectionStatus message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.ConnectionStatus
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.ConnectionStatus} ConnectionStatus
             */
            ConnectionStatus.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.ConnectionStatus)
                    return object;
                var message = new $root.fastcity.admin.ConnectionStatus();
                if (object.companyId != null)
                    if ($util.Long)
                        (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = true;
                    else if (typeof object.companyId === "string")
                        message.companyId = parseInt(object.companyId, 10);
                    else if (typeof object.companyId === "number")
                        message.companyId = object.companyId;
                    else if (typeof object.companyId === "object")
                        message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber(true);
                if (object.externalSubCompanyId != null)
                    message.externalSubCompanyId = String(object.externalSubCompanyId);
                switch (object.status) {
                case "CONNECTED":
                case 0:
                    message.status = 0;
                    break;
                case "DISCONNECTED":
                case 1:
                    message.status = 1;
                    break;
                }
                if (object.launcher != null) {
                    if (typeof object.launcher !== "object")
                        throw TypeError(".fastcity.admin.ConnectionStatus.launcher: object expected");
                    message.launcher = $root.fastcity.admin.ConnectionStatus.Launcher.fromObject(object.launcher);
                }
                if (object.connector != null) {
                    if (typeof object.connector !== "object")
                        throw TypeError(".fastcity.admin.ConnectionStatus.connector: object expected");
                    message.connector = $root.fastcity.admin.ConnectionStatus.Connector.fromObject(object.connector);
                }
                return message;
            };

            /**
             * Creates a plain object from a ConnectionStatus message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.ConnectionStatus
             * @static
             * @param {fastcity.admin.ConnectionStatus} message ConnectionStatus
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ConnectionStatus.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.companyId = options.longs === String ? "0" : 0;
                    object.externalSubCompanyId = "";
                    object.status = options.enums === String ? "CONNECTED" : 0;
                }
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (typeof message.companyId === "number")
                        object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                    else
                        object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber(true) : message.companyId;
                if (message.externalSubCompanyId != null && message.hasOwnProperty("externalSubCompanyId"))
                    object.externalSubCompanyId = message.externalSubCompanyId;
                if (message.status != null && message.hasOwnProperty("status"))
                    object.status = options.enums === String ? $root.fastcity.admin.ConnectionStatus.Status[message.status] : message.status;
                if (message.launcher != null && message.hasOwnProperty("launcher")) {
                    object.launcher = $root.fastcity.admin.ConnectionStatus.Launcher.toObject(message.launcher, options);
                    if (options.oneofs)
                        object.type = "launcher";
                }
                if (message.connector != null && message.hasOwnProperty("connector")) {
                    object.connector = $root.fastcity.admin.ConnectionStatus.Connector.toObject(message.connector, options);
                    if (options.oneofs)
                        object.type = "connector";
                }
                return object;
            };

            /**
             * Converts this ConnectionStatus to JSON.
             * @function toJSON
             * @memberof fastcity.admin.ConnectionStatus
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ConnectionStatus.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            ConnectionStatus.Launcher = (function() {

                /**
                 * Properties of a Launcher.
                 * @memberof fastcity.admin.ConnectionStatus
                 * @interface ILauncher
                 * @property {fastcity.common.IVersion|null} [launcherVersion] Launcher launcherVersion
                 * @property {fastcity.common.IVersion|null} [connectorVersion] Launcher connectorVersion
                 */

                /**
                 * Constructs a new Launcher.
                 * @memberof fastcity.admin.ConnectionStatus
                 * @classdesc Represents a Launcher.
                 * @implements ILauncher
                 * @constructor
                 * @param {fastcity.admin.ConnectionStatus.ILauncher=} [properties] Properties to set
                 */
                function Launcher(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Launcher launcherVersion.
                 * @member {fastcity.common.IVersion|null|undefined} launcherVersion
                 * @memberof fastcity.admin.ConnectionStatus.Launcher
                 * @instance
                 */
                Launcher.prototype.launcherVersion = null;

                /**
                 * Launcher connectorVersion.
                 * @member {fastcity.common.IVersion|null|undefined} connectorVersion
                 * @memberof fastcity.admin.ConnectionStatus.Launcher
                 * @instance
                 */
                Launcher.prototype.connectorVersion = null;

                /**
                 * Creates a new Launcher instance using the specified properties.
                 * @function create
                 * @memberof fastcity.admin.ConnectionStatus.Launcher
                 * @static
                 * @param {fastcity.admin.ConnectionStatus.ILauncher=} [properties] Properties to set
                 * @returns {fastcity.admin.ConnectionStatus.Launcher} Launcher instance
                 */
                Launcher.create = function create(properties) {
                    return new Launcher(properties);
                };

                /**
                 * Encodes the specified Launcher message. Does not implicitly {@link fastcity.admin.ConnectionStatus.Launcher.verify|verify} messages.
                 * @function encode
                 * @memberof fastcity.admin.ConnectionStatus.Launcher
                 * @static
                 * @param {fastcity.admin.ConnectionStatus.ILauncher} message Launcher message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Launcher.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.launcherVersion != null && Object.hasOwnProperty.call(message, "launcherVersion"))
                        $root.fastcity.common.Version.encode(message.launcherVersion, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.connectorVersion != null && Object.hasOwnProperty.call(message, "connectorVersion"))
                        $root.fastcity.common.Version.encode(message.connectorVersion, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Decodes a Launcher message from the specified reader or buffer.
                 * @function decode
                 * @memberof fastcity.admin.ConnectionStatus.Launcher
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {fastcity.admin.ConnectionStatus.Launcher} Launcher
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Launcher.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.ConnectionStatus.Launcher();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.launcherVersion = $root.fastcity.common.Version.decode(reader, reader.uint32());
                            break;
                        case 2:
                            message.connectorVersion = $root.fastcity.common.Version.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Verifies a Launcher message.
                 * @function verify
                 * @memberof fastcity.admin.ConnectionStatus.Launcher
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Launcher.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.launcherVersion != null && message.hasOwnProperty("launcherVersion")) {
                        var error = $root.fastcity.common.Version.verify(message.launcherVersion);
                        if (error)
                            return "launcherVersion." + error;
                    }
                    if (message.connectorVersion != null && message.hasOwnProperty("connectorVersion")) {
                        var error = $root.fastcity.common.Version.verify(message.connectorVersion);
                        if (error)
                            return "connectorVersion." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Launcher message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof fastcity.admin.ConnectionStatus.Launcher
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {fastcity.admin.ConnectionStatus.Launcher} Launcher
                 */
                Launcher.fromObject = function fromObject(object) {
                    if (object instanceof $root.fastcity.admin.ConnectionStatus.Launcher)
                        return object;
                    var message = new $root.fastcity.admin.ConnectionStatus.Launcher();
                    if (object.launcherVersion != null) {
                        if (typeof object.launcherVersion !== "object")
                            throw TypeError(".fastcity.admin.ConnectionStatus.Launcher.launcherVersion: object expected");
                        message.launcherVersion = $root.fastcity.common.Version.fromObject(object.launcherVersion);
                    }
                    if (object.connectorVersion != null) {
                        if (typeof object.connectorVersion !== "object")
                            throw TypeError(".fastcity.admin.ConnectionStatus.Launcher.connectorVersion: object expected");
                        message.connectorVersion = $root.fastcity.common.Version.fromObject(object.connectorVersion);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Launcher message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof fastcity.admin.ConnectionStatus.Launcher
                 * @static
                 * @param {fastcity.admin.ConnectionStatus.Launcher} message Launcher
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Launcher.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.launcherVersion = null;
                        object.connectorVersion = null;
                    }
                    if (message.launcherVersion != null && message.hasOwnProperty("launcherVersion"))
                        object.launcherVersion = $root.fastcity.common.Version.toObject(message.launcherVersion, options);
                    if (message.connectorVersion != null && message.hasOwnProperty("connectorVersion"))
                        object.connectorVersion = $root.fastcity.common.Version.toObject(message.connectorVersion, options);
                    return object;
                };

                /**
                 * Converts this Launcher to JSON.
                 * @function toJSON
                 * @memberof fastcity.admin.ConnectionStatus.Launcher
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Launcher.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return Launcher;
            })();

            ConnectionStatus.Connector = (function() {

                /**
                 * Properties of a Connector.
                 * @memberof fastcity.admin.ConnectionStatus
                 * @interface IConnector
                 * @property {fastcity.common.IVersion|null} [version] Connector version
                 */

                /**
                 * Constructs a new Connector.
                 * @memberof fastcity.admin.ConnectionStatus
                 * @classdesc Represents a Connector.
                 * @implements IConnector
                 * @constructor
                 * @param {fastcity.admin.ConnectionStatus.IConnector=} [properties] Properties to set
                 */
                function Connector(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Connector version.
                 * @member {fastcity.common.IVersion|null|undefined} version
                 * @memberof fastcity.admin.ConnectionStatus.Connector
                 * @instance
                 */
                Connector.prototype.version = null;

                /**
                 * Creates a new Connector instance using the specified properties.
                 * @function create
                 * @memberof fastcity.admin.ConnectionStatus.Connector
                 * @static
                 * @param {fastcity.admin.ConnectionStatus.IConnector=} [properties] Properties to set
                 * @returns {fastcity.admin.ConnectionStatus.Connector} Connector instance
                 */
                Connector.create = function create(properties) {
                    return new Connector(properties);
                };

                /**
                 * Encodes the specified Connector message. Does not implicitly {@link fastcity.admin.ConnectionStatus.Connector.verify|verify} messages.
                 * @function encode
                 * @memberof fastcity.admin.ConnectionStatus.Connector
                 * @static
                 * @param {fastcity.admin.ConnectionStatus.IConnector} message Connector message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Connector.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.version != null && Object.hasOwnProperty.call(message, "version"))
                        $root.fastcity.common.Version.encode(message.version, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Decodes a Connector message from the specified reader or buffer.
                 * @function decode
                 * @memberof fastcity.admin.ConnectionStatus.Connector
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {fastcity.admin.ConnectionStatus.Connector} Connector
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Connector.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.ConnectionStatus.Connector();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.version = $root.fastcity.common.Version.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Verifies a Connector message.
                 * @function verify
                 * @memberof fastcity.admin.ConnectionStatus.Connector
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Connector.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.version != null && message.hasOwnProperty("version")) {
                        var error = $root.fastcity.common.Version.verify(message.version);
                        if (error)
                            return "version." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Connector message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof fastcity.admin.ConnectionStatus.Connector
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {fastcity.admin.ConnectionStatus.Connector} Connector
                 */
                Connector.fromObject = function fromObject(object) {
                    if (object instanceof $root.fastcity.admin.ConnectionStatus.Connector)
                        return object;
                    var message = new $root.fastcity.admin.ConnectionStatus.Connector();
                    if (object.version != null) {
                        if (typeof object.version !== "object")
                            throw TypeError(".fastcity.admin.ConnectionStatus.Connector.version: object expected");
                        message.version = $root.fastcity.common.Version.fromObject(object.version);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Connector message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof fastcity.admin.ConnectionStatus.Connector
                 * @static
                 * @param {fastcity.admin.ConnectionStatus.Connector} message Connector
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Connector.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.version = null;
                    if (message.version != null && message.hasOwnProperty("version"))
                        object.version = $root.fastcity.common.Version.toObject(message.version, options);
                    return object;
                };

                /**
                 * Converts this Connector to JSON.
                 * @function toJSON
                 * @memberof fastcity.admin.ConnectionStatus.Connector
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Connector.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return Connector;
            })();

            /**
             * Status enum.
             * @name fastcity.admin.ConnectionStatus.Status
             * @enum {number}
             * @property {number} CONNECTED=0 CONNECTED value
             * @property {number} DISCONNECTED=1 DISCONNECTED value
             */
            ConnectionStatus.Status = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "CONNECTED"] = 0;
                values[valuesById[1] = "DISCONNECTED"] = 1;
                return values;
            })();

            return ConnectionStatus;
        })();

        admin.ConnectionStatusList = (function() {

            /**
             * Properties of a ConnectionStatusList.
             * @memberof fastcity.admin
             * @interface IConnectionStatusList
             * @property {Array.<fastcity.admin.IConnectionStatus>|null} [connectionStatus] ConnectionStatusList connectionStatus
             */

            /**
             * Constructs a new ConnectionStatusList.
             * @memberof fastcity.admin
             * @classdesc Represents a ConnectionStatusList.
             * @implements IConnectionStatusList
             * @constructor
             * @param {fastcity.admin.IConnectionStatusList=} [properties] Properties to set
             */
            function ConnectionStatusList(properties) {
                this.connectionStatus = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ConnectionStatusList connectionStatus.
             * @member {Array.<fastcity.admin.IConnectionStatus>} connectionStatus
             * @memberof fastcity.admin.ConnectionStatusList
             * @instance
             */
            ConnectionStatusList.prototype.connectionStatus = $util.emptyArray;

            /**
             * Creates a new ConnectionStatusList instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.ConnectionStatusList
             * @static
             * @param {fastcity.admin.IConnectionStatusList=} [properties] Properties to set
             * @returns {fastcity.admin.ConnectionStatusList} ConnectionStatusList instance
             */
            ConnectionStatusList.create = function create(properties) {
                return new ConnectionStatusList(properties);
            };

            /**
             * Encodes the specified ConnectionStatusList message. Does not implicitly {@link fastcity.admin.ConnectionStatusList.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.ConnectionStatusList
             * @static
             * @param {fastcity.admin.IConnectionStatusList} message ConnectionStatusList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ConnectionStatusList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.connectionStatus != null && message.connectionStatus.length)
                    for (var i = 0; i < message.connectionStatus.length; ++i)
                        $root.fastcity.admin.ConnectionStatus.encode(message.connectionStatus[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a ConnectionStatusList message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.ConnectionStatusList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.ConnectionStatusList} ConnectionStatusList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ConnectionStatusList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.ConnectionStatusList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.connectionStatus && message.connectionStatus.length))
                            message.connectionStatus = [];
                        message.connectionStatus.push($root.fastcity.admin.ConnectionStatus.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a ConnectionStatusList message.
             * @function verify
             * @memberof fastcity.admin.ConnectionStatusList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ConnectionStatusList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.connectionStatus != null && message.hasOwnProperty("connectionStatus")) {
                    if (!Array.isArray(message.connectionStatus))
                        return "connectionStatus: array expected";
                    for (var i = 0; i < message.connectionStatus.length; ++i) {
                        var error = $root.fastcity.admin.ConnectionStatus.verify(message.connectionStatus[i]);
                        if (error)
                            return "connectionStatus." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a ConnectionStatusList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.ConnectionStatusList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.ConnectionStatusList} ConnectionStatusList
             */
            ConnectionStatusList.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.ConnectionStatusList)
                    return object;
                var message = new $root.fastcity.admin.ConnectionStatusList();
                if (object.connectionStatus) {
                    if (!Array.isArray(object.connectionStatus))
                        throw TypeError(".fastcity.admin.ConnectionStatusList.connectionStatus: array expected");
                    message.connectionStatus = [];
                    for (var i = 0; i < object.connectionStatus.length; ++i) {
                        if (typeof object.connectionStatus[i] !== "object")
                            throw TypeError(".fastcity.admin.ConnectionStatusList.connectionStatus: object expected");
                        message.connectionStatus[i] = $root.fastcity.admin.ConnectionStatus.fromObject(object.connectionStatus[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a ConnectionStatusList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.ConnectionStatusList
             * @static
             * @param {fastcity.admin.ConnectionStatusList} message ConnectionStatusList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ConnectionStatusList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.connectionStatus = [];
                if (message.connectionStatus && message.connectionStatus.length) {
                    object.connectionStatus = [];
                    for (var j = 0; j < message.connectionStatus.length; ++j)
                        object.connectionStatus[j] = $root.fastcity.admin.ConnectionStatus.toObject(message.connectionStatus[j], options);
                }
                return object;
            };

            /**
             * Converts this ConnectionStatusList to JSON.
             * @function toJSON
             * @memberof fastcity.admin.ConnectionStatusList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ConnectionStatusList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return ConnectionStatusList;
        })();

        admin.GetConnectionStatusList = (function() {

            /**
             * Properties of a GetConnectionStatusList.
             * @memberof fastcity.admin
             * @interface IGetConnectionStatusList
             */

            /**
             * Constructs a new GetConnectionStatusList.
             * @memberof fastcity.admin
             * @classdesc Represents a GetConnectionStatusList.
             * @implements IGetConnectionStatusList
             * @constructor
             * @param {fastcity.admin.IGetConnectionStatusList=} [properties] Properties to set
             */
            function GetConnectionStatusList(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new GetConnectionStatusList instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.GetConnectionStatusList
             * @static
             * @param {fastcity.admin.IGetConnectionStatusList=} [properties] Properties to set
             * @returns {fastcity.admin.GetConnectionStatusList} GetConnectionStatusList instance
             */
            GetConnectionStatusList.create = function create(properties) {
                return new GetConnectionStatusList(properties);
            };

            /**
             * Encodes the specified GetConnectionStatusList message. Does not implicitly {@link fastcity.admin.GetConnectionStatusList.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.GetConnectionStatusList
             * @static
             * @param {fastcity.admin.IGetConnectionStatusList} message GetConnectionStatusList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetConnectionStatusList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Decodes a GetConnectionStatusList message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.GetConnectionStatusList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.GetConnectionStatusList} GetConnectionStatusList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetConnectionStatusList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.GetConnectionStatusList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a GetConnectionStatusList message.
             * @function verify
             * @memberof fastcity.admin.GetConnectionStatusList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetConnectionStatusList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a GetConnectionStatusList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.GetConnectionStatusList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.GetConnectionStatusList} GetConnectionStatusList
             */
            GetConnectionStatusList.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.GetConnectionStatusList)
                    return object;
                return new $root.fastcity.admin.GetConnectionStatusList();
            };

            /**
             * Creates a plain object from a GetConnectionStatusList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.GetConnectionStatusList
             * @static
             * @param {fastcity.admin.GetConnectionStatusList} message GetConnectionStatusList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetConnectionStatusList.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this GetConnectionStatusList to JSON.
             * @function toJSON
             * @memberof fastcity.admin.GetConnectionStatusList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetConnectionStatusList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return GetConnectionStatusList;
        })();

        /**
         * CourierRequestResult enum.
         * @name fastcity.admin.CourierRequestResult
         * @enum {number}
         * @property {number} CourRR_NOT_FOUND=0 CourRR_NOT_FOUND value
         * @property {number} CourRR_ALREADY_EXISTS=1 CourRR_ALREADY_EXISTS value
         * @property {number} CourRR_BAD_COMPANY_ID_COMPANY_NOT_FOUND=2 CourRR_BAD_COMPANY_ID_COMPANY_NOT_FOUND value
         * @property {number} CourRR_BAD_SUB_COMPANY_ID_SUB_COMPANY_NOT_FOUND=3 CourRR_BAD_SUB_COMPANY_ID_SUB_COMPANY_NOT_FOUND value
         * @property {number} CourRR_BAD_PHONE=4 CourRR_BAD_PHONE value
         * @property {number} CourRR_BAD_PASSWORD=5 CourRR_BAD_PASSWORD value
         * @property {number} CourRR_BAD_GROUP_ID_GROUP_NOT_FOUND=6 CourRR_BAD_GROUP_ID_GROUP_NOT_FOUND value
         * @property {number} CourRR_BAD_GROUP_ID_GROUP_IS_DELETED=7 CourRR_BAD_GROUP_ID_GROUP_IS_DELETED value
         * @property {number} CourRR_BAD_MINIMAL_DISTANCE_TO_ORDER_TARGET_IS_NULL=8 CourRR_BAD_MINIMAL_DISTANCE_TO_ORDER_TARGET_IS_NULL value
         * @property {number} CourRR_COURIER_ALREADY_HAVE_CONNECT_TO_SUB_COMPANY=9 CourRR_COURIER_ALREADY_HAVE_CONNECT_TO_SUB_COMPANY value
         * @property {number} CourRR_CONNECT_OF_COURIER_TO_SUB_COMPANY_NOT_FOUND=10 CourRR_CONNECT_OF_COURIER_TO_SUB_COMPANY_NOT_FOUND value
         */
        admin.CourierRequestResult = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "CourRR_NOT_FOUND"] = 0;
            values[valuesById[1] = "CourRR_ALREADY_EXISTS"] = 1;
            values[valuesById[2] = "CourRR_BAD_COMPANY_ID_COMPANY_NOT_FOUND"] = 2;
            values[valuesById[3] = "CourRR_BAD_SUB_COMPANY_ID_SUB_COMPANY_NOT_FOUND"] = 3;
            values[valuesById[4] = "CourRR_BAD_PHONE"] = 4;
            values[valuesById[5] = "CourRR_BAD_PASSWORD"] = 5;
            values[valuesById[6] = "CourRR_BAD_GROUP_ID_GROUP_NOT_FOUND"] = 6;
            values[valuesById[7] = "CourRR_BAD_GROUP_ID_GROUP_IS_DELETED"] = 7;
            values[valuesById[8] = "CourRR_BAD_MINIMAL_DISTANCE_TO_ORDER_TARGET_IS_NULL"] = 8;
            values[valuesById[9] = "CourRR_COURIER_ALREADY_HAVE_CONNECT_TO_SUB_COMPANY"] = 9;
            values[valuesById[10] = "CourRR_CONNECT_OF_COURIER_TO_SUB_COMPANY_NOT_FOUND"] = 10;
            return values;
        })();

        admin.Courier = (function() {

            /**
             * Properties of a Courier.
             * @memberof fastcity.admin
             * @interface ICourier
             * @property {number|Long|null} [courierId] Courier courierId
             * @property {number|Long|null} [companyId] Courier companyId
             * @property {string|null} [name] Courier name
             * @property {string|null} [phone] Courier phone
             * @property {number|Long|null} [groupId] Courier groupId
             * @property {number|Long|null} [timestamp] Courier timestamp
             * @property {boolean|null} [isSynchronized] Courier isSynchronized
             * @property {boolean|null} [isDeleted] Courier isDeleted
             * @property {boolean|null} [isConnected] Courier isConnected
             * @property {number|Long|null} [minimalDistanceToOrderTarget] Courier minimalDistanceToOrderTarget
             * @property {boolean|null} [useDifferentColorsForAllOrders] Courier useDifferentColorsForAllOrders
             * @property {fastcity.common.IVersion|null} [appVersion] Courier appVersion
             */

            /**
             * Constructs a new Courier.
             * @memberof fastcity.admin
             * @classdesc Represents a Courier.
             * @implements ICourier
             * @constructor
             * @param {fastcity.admin.ICourier=} [properties] Properties to set
             */
            function Courier(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Courier courierId.
             * @member {number|Long} courierId
             * @memberof fastcity.admin.Courier
             * @instance
             */
            Courier.prototype.courierId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Courier companyId.
             * @member {number|Long} companyId
             * @memberof fastcity.admin.Courier
             * @instance
             */
            Courier.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Courier name.
             * @member {string} name
             * @memberof fastcity.admin.Courier
             * @instance
             */
            Courier.prototype.name = "";

            /**
             * Courier phone.
             * @member {string} phone
             * @memberof fastcity.admin.Courier
             * @instance
             */
            Courier.prototype.phone = "";

            /**
             * Courier groupId.
             * @member {number|Long} groupId
             * @memberof fastcity.admin.Courier
             * @instance
             */
            Courier.prototype.groupId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Courier timestamp.
             * @member {number|Long} timestamp
             * @memberof fastcity.admin.Courier
             * @instance
             */
            Courier.prototype.timestamp = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Courier isSynchronized.
             * @member {boolean} isSynchronized
             * @memberof fastcity.admin.Courier
             * @instance
             */
            Courier.prototype.isSynchronized = false;

            /**
             * Courier isDeleted.
             * @member {boolean} isDeleted
             * @memberof fastcity.admin.Courier
             * @instance
             */
            Courier.prototype.isDeleted = false;

            /**
             * Courier isConnected.
             * @member {boolean} isConnected
             * @memberof fastcity.admin.Courier
             * @instance
             */
            Courier.prototype.isConnected = false;

            /**
             * Courier minimalDistanceToOrderTarget.
             * @member {number|Long} minimalDistanceToOrderTarget
             * @memberof fastcity.admin.Courier
             * @instance
             */
            Courier.prototype.minimalDistanceToOrderTarget = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Courier useDifferentColorsForAllOrders.
             * @member {boolean} useDifferentColorsForAllOrders
             * @memberof fastcity.admin.Courier
             * @instance
             */
            Courier.prototype.useDifferentColorsForAllOrders = false;

            /**
             * Courier appVersion.
             * @member {fastcity.common.IVersion|null|undefined} appVersion
             * @memberof fastcity.admin.Courier
             * @instance
             */
            Courier.prototype.appVersion = null;

            /**
             * Creates a new Courier instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.Courier
             * @static
             * @param {fastcity.admin.ICourier=} [properties] Properties to set
             * @returns {fastcity.admin.Courier} Courier instance
             */
            Courier.create = function create(properties) {
                return new Courier(properties);
            };

            /**
             * Encodes the specified Courier message. Does not implicitly {@link fastcity.admin.Courier.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.Courier
             * @static
             * @param {fastcity.admin.ICourier} message Courier message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Courier.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.courierId != null && Object.hasOwnProperty.call(message, "courierId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.courierId);
                if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.companyId);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
                if (message.phone != null && Object.hasOwnProperty.call(message, "phone"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.phone);
                if (message.groupId != null && Object.hasOwnProperty.call(message, "groupId"))
                    writer.uint32(/* id 5, wireType 0 =*/40).uint64(message.groupId);
                if (message.timestamp != null && Object.hasOwnProperty.call(message, "timestamp"))
                    writer.uint32(/* id 6, wireType 0 =*/48).int64(message.timestamp);
                if (message.isSynchronized != null && Object.hasOwnProperty.call(message, "isSynchronized"))
                    writer.uint32(/* id 7, wireType 0 =*/56).bool(message.isSynchronized);
                if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                    writer.uint32(/* id 8, wireType 0 =*/64).bool(message.isDeleted);
                if (message.isConnected != null && Object.hasOwnProperty.call(message, "isConnected"))
                    writer.uint32(/* id 9, wireType 0 =*/72).bool(message.isConnected);
                if (message.minimalDistanceToOrderTarget != null && Object.hasOwnProperty.call(message, "minimalDistanceToOrderTarget"))
                    writer.uint32(/* id 10, wireType 0 =*/80).uint64(message.minimalDistanceToOrderTarget);
                if (message.useDifferentColorsForAllOrders != null && Object.hasOwnProperty.call(message, "useDifferentColorsForAllOrders"))
                    writer.uint32(/* id 11, wireType 0 =*/88).bool(message.useDifferentColorsForAllOrders);
                if (message.appVersion != null && Object.hasOwnProperty.call(message, "appVersion"))
                    $root.fastcity.common.Version.encode(message.appVersion, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a Courier message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.Courier
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.Courier} Courier
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Courier.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.Courier();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.courierId = reader.uint64();
                        break;
                    case 2:
                        message.companyId = reader.uint64();
                        break;
                    case 3:
                        message.name = reader.string();
                        break;
                    case 4:
                        message.phone = reader.string();
                        break;
                    case 5:
                        message.groupId = reader.uint64();
                        break;
                    case 6:
                        message.timestamp = reader.int64();
                        break;
                    case 7:
                        message.isSynchronized = reader.bool();
                        break;
                    case 8:
                        message.isDeleted = reader.bool();
                        break;
                    case 9:
                        message.isConnected = reader.bool();
                        break;
                    case 10:
                        message.minimalDistanceToOrderTarget = reader.uint64();
                        break;
                    case 11:
                        message.useDifferentColorsForAllOrders = reader.bool();
                        break;
                    case 12:
                        message.appVersion = $root.fastcity.common.Version.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a Courier message.
             * @function verify
             * @memberof fastcity.admin.Courier
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Courier.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.courierId != null && message.hasOwnProperty("courierId"))
                    if (!$util.isInteger(message.courierId) && !(message.courierId && $util.isInteger(message.courierId.low) && $util.isInteger(message.courierId.high)))
                        return "courierId: integer|Long expected";
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                        return "companyId: integer|Long expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.phone != null && message.hasOwnProperty("phone"))
                    if (!$util.isString(message.phone))
                        return "phone: string expected";
                if (message.groupId != null && message.hasOwnProperty("groupId"))
                    if (!$util.isInteger(message.groupId) && !(message.groupId && $util.isInteger(message.groupId.low) && $util.isInteger(message.groupId.high)))
                        return "groupId: integer|Long expected";
                if (message.timestamp != null && message.hasOwnProperty("timestamp"))
                    if (!$util.isInteger(message.timestamp) && !(message.timestamp && $util.isInteger(message.timestamp.low) && $util.isInteger(message.timestamp.high)))
                        return "timestamp: integer|Long expected";
                if (message.isSynchronized != null && message.hasOwnProperty("isSynchronized"))
                    if (typeof message.isSynchronized !== "boolean")
                        return "isSynchronized: boolean expected";
                if (message.isDeleted != null && message.hasOwnProperty("isDeleted"))
                    if (typeof message.isDeleted !== "boolean")
                        return "isDeleted: boolean expected";
                if (message.isConnected != null && message.hasOwnProperty("isConnected"))
                    if (typeof message.isConnected !== "boolean")
                        return "isConnected: boolean expected";
                if (message.minimalDistanceToOrderTarget != null && message.hasOwnProperty("minimalDistanceToOrderTarget"))
                    if (!$util.isInteger(message.minimalDistanceToOrderTarget) && !(message.minimalDistanceToOrderTarget && $util.isInteger(message.minimalDistanceToOrderTarget.low) && $util.isInteger(message.minimalDistanceToOrderTarget.high)))
                        return "minimalDistanceToOrderTarget: integer|Long expected";
                if (message.useDifferentColorsForAllOrders != null && message.hasOwnProperty("useDifferentColorsForAllOrders"))
                    if (typeof message.useDifferentColorsForAllOrders !== "boolean")
                        return "useDifferentColorsForAllOrders: boolean expected";
                if (message.appVersion != null && message.hasOwnProperty("appVersion")) {
                    var error = $root.fastcity.common.Version.verify(message.appVersion);
                    if (error)
                        return "appVersion." + error;
                }
                return null;
            };

            /**
             * Creates a Courier message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.Courier
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.Courier} Courier
             */
            Courier.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.Courier)
                    return object;
                var message = new $root.fastcity.admin.Courier();
                if (object.courierId != null)
                    if ($util.Long)
                        (message.courierId = $util.Long.fromValue(object.courierId)).unsigned = true;
                    else if (typeof object.courierId === "string")
                        message.courierId = parseInt(object.courierId, 10);
                    else if (typeof object.courierId === "number")
                        message.courierId = object.courierId;
                    else if (typeof object.courierId === "object")
                        message.courierId = new $util.LongBits(object.courierId.low >>> 0, object.courierId.high >>> 0).toNumber(true);
                if (object.companyId != null)
                    if ($util.Long)
                        (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = true;
                    else if (typeof object.companyId === "string")
                        message.companyId = parseInt(object.companyId, 10);
                    else if (typeof object.companyId === "number")
                        message.companyId = object.companyId;
                    else if (typeof object.companyId === "object")
                        message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber(true);
                if (object.name != null)
                    message.name = String(object.name);
                if (object.phone != null)
                    message.phone = String(object.phone);
                if (object.groupId != null)
                    if ($util.Long)
                        (message.groupId = $util.Long.fromValue(object.groupId)).unsigned = true;
                    else if (typeof object.groupId === "string")
                        message.groupId = parseInt(object.groupId, 10);
                    else if (typeof object.groupId === "number")
                        message.groupId = object.groupId;
                    else if (typeof object.groupId === "object")
                        message.groupId = new $util.LongBits(object.groupId.low >>> 0, object.groupId.high >>> 0).toNumber(true);
                if (object.timestamp != null)
                    if ($util.Long)
                        (message.timestamp = $util.Long.fromValue(object.timestamp)).unsigned = false;
                    else if (typeof object.timestamp === "string")
                        message.timestamp = parseInt(object.timestamp, 10);
                    else if (typeof object.timestamp === "number")
                        message.timestamp = object.timestamp;
                    else if (typeof object.timestamp === "object")
                        message.timestamp = new $util.LongBits(object.timestamp.low >>> 0, object.timestamp.high >>> 0).toNumber();
                if (object.isSynchronized != null)
                    message.isSynchronized = Boolean(object.isSynchronized);
                if (object.isDeleted != null)
                    message.isDeleted = Boolean(object.isDeleted);
                if (object.isConnected != null)
                    message.isConnected = Boolean(object.isConnected);
                if (object.minimalDistanceToOrderTarget != null)
                    if ($util.Long)
                        (message.minimalDistanceToOrderTarget = $util.Long.fromValue(object.minimalDistanceToOrderTarget)).unsigned = true;
                    else if (typeof object.minimalDistanceToOrderTarget === "string")
                        message.minimalDistanceToOrderTarget = parseInt(object.minimalDistanceToOrderTarget, 10);
                    else if (typeof object.minimalDistanceToOrderTarget === "number")
                        message.minimalDistanceToOrderTarget = object.minimalDistanceToOrderTarget;
                    else if (typeof object.minimalDistanceToOrderTarget === "object")
                        message.minimalDistanceToOrderTarget = new $util.LongBits(object.minimalDistanceToOrderTarget.low >>> 0, object.minimalDistanceToOrderTarget.high >>> 0).toNumber(true);
                if (object.useDifferentColorsForAllOrders != null)
                    message.useDifferentColorsForAllOrders = Boolean(object.useDifferentColorsForAllOrders);
                if (object.appVersion != null) {
                    if (typeof object.appVersion !== "object")
                        throw TypeError(".fastcity.admin.Courier.appVersion: object expected");
                    message.appVersion = $root.fastcity.common.Version.fromObject(object.appVersion);
                }
                return message;
            };

            /**
             * Creates a plain object from a Courier message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.Courier
             * @static
             * @param {fastcity.admin.Courier} message Courier
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Courier.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.courierId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.courierId = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.companyId = options.longs === String ? "0" : 0;
                    object.name = "";
                    object.phone = "";
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.groupId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.groupId = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.timestamp = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.timestamp = options.longs === String ? "0" : 0;
                    object.isSynchronized = false;
                    object.isDeleted = false;
                    object.isConnected = false;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.minimalDistanceToOrderTarget = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.minimalDistanceToOrderTarget = options.longs === String ? "0" : 0;
                    object.useDifferentColorsForAllOrders = false;
                    object.appVersion = null;
                }
                if (message.courierId != null && message.hasOwnProperty("courierId"))
                    if (typeof message.courierId === "number")
                        object.courierId = options.longs === String ? String(message.courierId) : message.courierId;
                    else
                        object.courierId = options.longs === String ? $util.Long.prototype.toString.call(message.courierId) : options.longs === Number ? new $util.LongBits(message.courierId.low >>> 0, message.courierId.high >>> 0).toNumber(true) : message.courierId;
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (typeof message.companyId === "number")
                        object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                    else
                        object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber(true) : message.companyId;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.phone != null && message.hasOwnProperty("phone"))
                    object.phone = message.phone;
                if (message.groupId != null && message.hasOwnProperty("groupId"))
                    if (typeof message.groupId === "number")
                        object.groupId = options.longs === String ? String(message.groupId) : message.groupId;
                    else
                        object.groupId = options.longs === String ? $util.Long.prototype.toString.call(message.groupId) : options.longs === Number ? new $util.LongBits(message.groupId.low >>> 0, message.groupId.high >>> 0).toNumber(true) : message.groupId;
                if (message.timestamp != null && message.hasOwnProperty("timestamp"))
                    if (typeof message.timestamp === "number")
                        object.timestamp = options.longs === String ? String(message.timestamp) : message.timestamp;
                    else
                        object.timestamp = options.longs === String ? $util.Long.prototype.toString.call(message.timestamp) : options.longs === Number ? new $util.LongBits(message.timestamp.low >>> 0, message.timestamp.high >>> 0).toNumber() : message.timestamp;
                if (message.isSynchronized != null && message.hasOwnProperty("isSynchronized"))
                    object.isSynchronized = message.isSynchronized;
                if (message.isDeleted != null && message.hasOwnProperty("isDeleted"))
                    object.isDeleted = message.isDeleted;
                if (message.isConnected != null && message.hasOwnProperty("isConnected"))
                    object.isConnected = message.isConnected;
                if (message.minimalDistanceToOrderTarget != null && message.hasOwnProperty("minimalDistanceToOrderTarget"))
                    if (typeof message.minimalDistanceToOrderTarget === "number")
                        object.minimalDistanceToOrderTarget = options.longs === String ? String(message.minimalDistanceToOrderTarget) : message.minimalDistanceToOrderTarget;
                    else
                        object.minimalDistanceToOrderTarget = options.longs === String ? $util.Long.prototype.toString.call(message.minimalDistanceToOrderTarget) : options.longs === Number ? new $util.LongBits(message.minimalDistanceToOrderTarget.low >>> 0, message.minimalDistanceToOrderTarget.high >>> 0).toNumber(true) : message.minimalDistanceToOrderTarget;
                if (message.useDifferentColorsForAllOrders != null && message.hasOwnProperty("useDifferentColorsForAllOrders"))
                    object.useDifferentColorsForAllOrders = message.useDifferentColorsForAllOrders;
                if (message.appVersion != null && message.hasOwnProperty("appVersion"))
                    object.appVersion = $root.fastcity.common.Version.toObject(message.appVersion, options);
                return object;
            };

            /**
             * Converts this Courier to JSON.
             * @function toJSON
             * @memberof fastcity.admin.Courier
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Courier.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Courier;
        })();

        admin.CreateCourier = (function() {

            /**
             * Properties of a CreateCourier.
             * @memberof fastcity.admin
             * @interface ICreateCourier
             * @property {number|Long|null} [companyId] CreateCourier companyId
             * @property {string|null} [name] CreateCourier name
             * @property {string|null} [phone] CreateCourier phone
             * @property {number|Long|null} [groupId] CreateCourier groupId
             * @property {string|null} [password] CreateCourier password
             * @property {number|Long|null} [minimalDistanceToOrderTarget] CreateCourier minimalDistanceToOrderTarget
             * @property {boolean|null} [useDifferentColorsForAllOrders] CreateCourier useDifferentColorsForAllOrders
             */

            /**
             * Constructs a new CreateCourier.
             * @memberof fastcity.admin
             * @classdesc Represents a CreateCourier.
             * @implements ICreateCourier
             * @constructor
             * @param {fastcity.admin.ICreateCourier=} [properties] Properties to set
             */
            function CreateCourier(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CreateCourier companyId.
             * @member {number|Long} companyId
             * @memberof fastcity.admin.CreateCourier
             * @instance
             */
            CreateCourier.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * CreateCourier name.
             * @member {string} name
             * @memberof fastcity.admin.CreateCourier
             * @instance
             */
            CreateCourier.prototype.name = "";

            /**
             * CreateCourier phone.
             * @member {string} phone
             * @memberof fastcity.admin.CreateCourier
             * @instance
             */
            CreateCourier.prototype.phone = "";

            /**
             * CreateCourier groupId.
             * @member {number|Long} groupId
             * @memberof fastcity.admin.CreateCourier
             * @instance
             */
            CreateCourier.prototype.groupId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * CreateCourier password.
             * @member {string} password
             * @memberof fastcity.admin.CreateCourier
             * @instance
             */
            CreateCourier.prototype.password = "";

            /**
             * CreateCourier minimalDistanceToOrderTarget.
             * @member {number|Long} minimalDistanceToOrderTarget
             * @memberof fastcity.admin.CreateCourier
             * @instance
             */
            CreateCourier.prototype.minimalDistanceToOrderTarget = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * CreateCourier useDifferentColorsForAllOrders.
             * @member {boolean} useDifferentColorsForAllOrders
             * @memberof fastcity.admin.CreateCourier
             * @instance
             */
            CreateCourier.prototype.useDifferentColorsForAllOrders = false;

            /**
             * Creates a new CreateCourier instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.CreateCourier
             * @static
             * @param {fastcity.admin.ICreateCourier=} [properties] Properties to set
             * @returns {fastcity.admin.CreateCourier} CreateCourier instance
             */
            CreateCourier.create = function create(properties) {
                return new CreateCourier(properties);
            };

            /**
             * Encodes the specified CreateCourier message. Does not implicitly {@link fastcity.admin.CreateCourier.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.CreateCourier
             * @static
             * @param {fastcity.admin.ICreateCourier} message CreateCourier message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateCourier.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.companyId);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                if (message.phone != null && Object.hasOwnProperty.call(message, "phone"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.phone);
                if (message.groupId != null && Object.hasOwnProperty.call(message, "groupId"))
                    writer.uint32(/* id 4, wireType 0 =*/32).uint64(message.groupId);
                if (message.password != null && Object.hasOwnProperty.call(message, "password"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.password);
                if (message.minimalDistanceToOrderTarget != null && Object.hasOwnProperty.call(message, "minimalDistanceToOrderTarget"))
                    writer.uint32(/* id 6, wireType 0 =*/48).uint64(message.minimalDistanceToOrderTarget);
                if (message.useDifferentColorsForAllOrders != null && Object.hasOwnProperty.call(message, "useDifferentColorsForAllOrders"))
                    writer.uint32(/* id 7, wireType 0 =*/56).bool(message.useDifferentColorsForAllOrders);
                return writer;
            };

            /**
             * Decodes a CreateCourier message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.CreateCourier
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.CreateCourier} CreateCourier
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateCourier.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.CreateCourier();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.companyId = reader.uint64();
                        break;
                    case 2:
                        message.name = reader.string();
                        break;
                    case 3:
                        message.phone = reader.string();
                        break;
                    case 4:
                        message.groupId = reader.uint64();
                        break;
                    case 5:
                        message.password = reader.string();
                        break;
                    case 6:
                        message.minimalDistanceToOrderTarget = reader.uint64();
                        break;
                    case 7:
                        message.useDifferentColorsForAllOrders = reader.bool();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a CreateCourier message.
             * @function verify
             * @memberof fastcity.admin.CreateCourier
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CreateCourier.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                        return "companyId: integer|Long expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.phone != null && message.hasOwnProperty("phone"))
                    if (!$util.isString(message.phone))
                        return "phone: string expected";
                if (message.groupId != null && message.hasOwnProperty("groupId"))
                    if (!$util.isInteger(message.groupId) && !(message.groupId && $util.isInteger(message.groupId.low) && $util.isInteger(message.groupId.high)))
                        return "groupId: integer|Long expected";
                if (message.password != null && message.hasOwnProperty("password"))
                    if (!$util.isString(message.password))
                        return "password: string expected";
                if (message.minimalDistanceToOrderTarget != null && message.hasOwnProperty("minimalDistanceToOrderTarget"))
                    if (!$util.isInteger(message.minimalDistanceToOrderTarget) && !(message.minimalDistanceToOrderTarget && $util.isInteger(message.minimalDistanceToOrderTarget.low) && $util.isInteger(message.minimalDistanceToOrderTarget.high)))
                        return "minimalDistanceToOrderTarget: integer|Long expected";
                if (message.useDifferentColorsForAllOrders != null && message.hasOwnProperty("useDifferentColorsForAllOrders"))
                    if (typeof message.useDifferentColorsForAllOrders !== "boolean")
                        return "useDifferentColorsForAllOrders: boolean expected";
                return null;
            };

            /**
             * Creates a CreateCourier message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.CreateCourier
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.CreateCourier} CreateCourier
             */
            CreateCourier.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.CreateCourier)
                    return object;
                var message = new $root.fastcity.admin.CreateCourier();
                if (object.companyId != null)
                    if ($util.Long)
                        (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = true;
                    else if (typeof object.companyId === "string")
                        message.companyId = parseInt(object.companyId, 10);
                    else if (typeof object.companyId === "number")
                        message.companyId = object.companyId;
                    else if (typeof object.companyId === "object")
                        message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber(true);
                if (object.name != null)
                    message.name = String(object.name);
                if (object.phone != null)
                    message.phone = String(object.phone);
                if (object.groupId != null)
                    if ($util.Long)
                        (message.groupId = $util.Long.fromValue(object.groupId)).unsigned = true;
                    else if (typeof object.groupId === "string")
                        message.groupId = parseInt(object.groupId, 10);
                    else if (typeof object.groupId === "number")
                        message.groupId = object.groupId;
                    else if (typeof object.groupId === "object")
                        message.groupId = new $util.LongBits(object.groupId.low >>> 0, object.groupId.high >>> 0).toNumber(true);
                if (object.password != null)
                    message.password = String(object.password);
                if (object.minimalDistanceToOrderTarget != null)
                    if ($util.Long)
                        (message.minimalDistanceToOrderTarget = $util.Long.fromValue(object.minimalDistanceToOrderTarget)).unsigned = true;
                    else if (typeof object.minimalDistanceToOrderTarget === "string")
                        message.minimalDistanceToOrderTarget = parseInt(object.minimalDistanceToOrderTarget, 10);
                    else if (typeof object.minimalDistanceToOrderTarget === "number")
                        message.minimalDistanceToOrderTarget = object.minimalDistanceToOrderTarget;
                    else if (typeof object.minimalDistanceToOrderTarget === "object")
                        message.minimalDistanceToOrderTarget = new $util.LongBits(object.minimalDistanceToOrderTarget.low >>> 0, object.minimalDistanceToOrderTarget.high >>> 0).toNumber(true);
                if (object.useDifferentColorsForAllOrders != null)
                    message.useDifferentColorsForAllOrders = Boolean(object.useDifferentColorsForAllOrders);
                return message;
            };

            /**
             * Creates a plain object from a CreateCourier message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.CreateCourier
             * @static
             * @param {fastcity.admin.CreateCourier} message CreateCourier
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateCourier.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.companyId = options.longs === String ? "0" : 0;
                    object.name = "";
                    object.phone = "";
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.groupId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.groupId = options.longs === String ? "0" : 0;
                    object.password = "";
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.minimalDistanceToOrderTarget = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.minimalDistanceToOrderTarget = options.longs === String ? "0" : 0;
                    object.useDifferentColorsForAllOrders = false;
                }
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (typeof message.companyId === "number")
                        object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                    else
                        object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber(true) : message.companyId;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.phone != null && message.hasOwnProperty("phone"))
                    object.phone = message.phone;
                if (message.groupId != null && message.hasOwnProperty("groupId"))
                    if (typeof message.groupId === "number")
                        object.groupId = options.longs === String ? String(message.groupId) : message.groupId;
                    else
                        object.groupId = options.longs === String ? $util.Long.prototype.toString.call(message.groupId) : options.longs === Number ? new $util.LongBits(message.groupId.low >>> 0, message.groupId.high >>> 0).toNumber(true) : message.groupId;
                if (message.password != null && message.hasOwnProperty("password"))
                    object.password = message.password;
                if (message.minimalDistanceToOrderTarget != null && message.hasOwnProperty("minimalDistanceToOrderTarget"))
                    if (typeof message.minimalDistanceToOrderTarget === "number")
                        object.minimalDistanceToOrderTarget = options.longs === String ? String(message.minimalDistanceToOrderTarget) : message.minimalDistanceToOrderTarget;
                    else
                        object.minimalDistanceToOrderTarget = options.longs === String ? $util.Long.prototype.toString.call(message.minimalDistanceToOrderTarget) : options.longs === Number ? new $util.LongBits(message.minimalDistanceToOrderTarget.low >>> 0, message.minimalDistanceToOrderTarget.high >>> 0).toNumber(true) : message.minimalDistanceToOrderTarget;
                if (message.useDifferentColorsForAllOrders != null && message.hasOwnProperty("useDifferentColorsForAllOrders"))
                    object.useDifferentColorsForAllOrders = message.useDifferentColorsForAllOrders;
                return object;
            };

            /**
             * Converts this CreateCourier to JSON.
             * @function toJSON
             * @memberof fastcity.admin.CreateCourier
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateCourier.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return CreateCourier;
        })();

        admin.UpdateCourier = (function() {

            /**
             * Properties of an UpdateCourier.
             * @memberof fastcity.admin
             * @interface IUpdateCourier
             * @property {number|Long|null} [courierId] UpdateCourier courierId
             * @property {number|Long|null} [companyId] UpdateCourier companyId
             * @property {string|null} [name] UpdateCourier name
             * @property {string|null} [phone] UpdateCourier phone
             * @property {number|Long|null} [groupId] UpdateCourier groupId
             * @property {number|Long|null} [minimalDistanceToOrderTarget] UpdateCourier minimalDistanceToOrderTarget
             * @property {boolean|null} [useDifferentColorsForAllOrders] UpdateCourier useDifferentColorsForAllOrders
             * @property {boolean|null} [isDeleted] UpdateCourier isDeleted
             * @property {string|null} [newPassword] UpdateCourier newPassword
             */

            /**
             * Constructs a new UpdateCourier.
             * @memberof fastcity.admin
             * @classdesc Represents an UpdateCourier.
             * @implements IUpdateCourier
             * @constructor
             * @param {fastcity.admin.IUpdateCourier=} [properties] Properties to set
             */
            function UpdateCourier(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * UpdateCourier courierId.
             * @member {number|Long} courierId
             * @memberof fastcity.admin.UpdateCourier
             * @instance
             */
            UpdateCourier.prototype.courierId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * UpdateCourier companyId.
             * @member {number|Long} companyId
             * @memberof fastcity.admin.UpdateCourier
             * @instance
             */
            UpdateCourier.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * UpdateCourier name.
             * @member {string} name
             * @memberof fastcity.admin.UpdateCourier
             * @instance
             */
            UpdateCourier.prototype.name = "";

            /**
             * UpdateCourier phone.
             * @member {string} phone
             * @memberof fastcity.admin.UpdateCourier
             * @instance
             */
            UpdateCourier.prototype.phone = "";

            /**
             * UpdateCourier groupId.
             * @member {number|Long} groupId
             * @memberof fastcity.admin.UpdateCourier
             * @instance
             */
            UpdateCourier.prototype.groupId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * UpdateCourier minimalDistanceToOrderTarget.
             * @member {number|Long} minimalDistanceToOrderTarget
             * @memberof fastcity.admin.UpdateCourier
             * @instance
             */
            UpdateCourier.prototype.minimalDistanceToOrderTarget = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * UpdateCourier useDifferentColorsForAllOrders.
             * @member {boolean} useDifferentColorsForAllOrders
             * @memberof fastcity.admin.UpdateCourier
             * @instance
             */
            UpdateCourier.prototype.useDifferentColorsForAllOrders = false;

            /**
             * UpdateCourier isDeleted.
             * @member {boolean} isDeleted
             * @memberof fastcity.admin.UpdateCourier
             * @instance
             */
            UpdateCourier.prototype.isDeleted = false;

            /**
             * UpdateCourier newPassword.
             * @member {string|null|undefined} newPassword
             * @memberof fastcity.admin.UpdateCourier
             * @instance
             */
            UpdateCourier.prototype.newPassword = null;

            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;

            /**
             * UpdateCourier updatePassword.
             * @member {"newPassword"|undefined} updatePassword
             * @memberof fastcity.admin.UpdateCourier
             * @instance
             */
            Object.defineProperty(UpdateCourier.prototype, "updatePassword", {
                get: $util.oneOfGetter($oneOfFields = ["newPassword"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Creates a new UpdateCourier instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.UpdateCourier
             * @static
             * @param {fastcity.admin.IUpdateCourier=} [properties] Properties to set
             * @returns {fastcity.admin.UpdateCourier} UpdateCourier instance
             */
            UpdateCourier.create = function create(properties) {
                return new UpdateCourier(properties);
            };

            /**
             * Encodes the specified UpdateCourier message. Does not implicitly {@link fastcity.admin.UpdateCourier.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.UpdateCourier
             * @static
             * @param {fastcity.admin.IUpdateCourier} message UpdateCourier message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateCourier.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.courierId != null && Object.hasOwnProperty.call(message, "courierId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.courierId);
                if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.companyId);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
                if (message.phone != null && Object.hasOwnProperty.call(message, "phone"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.phone);
                if (message.groupId != null && Object.hasOwnProperty.call(message, "groupId"))
                    writer.uint32(/* id 5, wireType 0 =*/40).uint64(message.groupId);
                if (message.minimalDistanceToOrderTarget != null && Object.hasOwnProperty.call(message, "minimalDistanceToOrderTarget"))
                    writer.uint32(/* id 6, wireType 0 =*/48).uint64(message.minimalDistanceToOrderTarget);
                if (message.useDifferentColorsForAllOrders != null && Object.hasOwnProperty.call(message, "useDifferentColorsForAllOrders"))
                    writer.uint32(/* id 7, wireType 0 =*/56).bool(message.useDifferentColorsForAllOrders);
                if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                    writer.uint32(/* id 8, wireType 0 =*/64).bool(message.isDeleted);
                if (message.newPassword != null && Object.hasOwnProperty.call(message, "newPassword"))
                    writer.uint32(/* id 11, wireType 2 =*/90).string(message.newPassword);
                return writer;
            };

            /**
             * Decodes an UpdateCourier message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.UpdateCourier
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.UpdateCourier} UpdateCourier
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateCourier.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.UpdateCourier();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.courierId = reader.uint64();
                        break;
                    case 2:
                        message.companyId = reader.uint64();
                        break;
                    case 3:
                        message.name = reader.string();
                        break;
                    case 4:
                        message.phone = reader.string();
                        break;
                    case 5:
                        message.groupId = reader.uint64();
                        break;
                    case 6:
                        message.minimalDistanceToOrderTarget = reader.uint64();
                        break;
                    case 7:
                        message.useDifferentColorsForAllOrders = reader.bool();
                        break;
                    case 8:
                        message.isDeleted = reader.bool();
                        break;
                    case 11:
                        message.newPassword = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an UpdateCourier message.
             * @function verify
             * @memberof fastcity.admin.UpdateCourier
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            UpdateCourier.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                var properties = {};
                if (message.courierId != null && message.hasOwnProperty("courierId"))
                    if (!$util.isInteger(message.courierId) && !(message.courierId && $util.isInteger(message.courierId.low) && $util.isInteger(message.courierId.high)))
                        return "courierId: integer|Long expected";
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                        return "companyId: integer|Long expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.phone != null && message.hasOwnProperty("phone"))
                    if (!$util.isString(message.phone))
                        return "phone: string expected";
                if (message.groupId != null && message.hasOwnProperty("groupId"))
                    if (!$util.isInteger(message.groupId) && !(message.groupId && $util.isInteger(message.groupId.low) && $util.isInteger(message.groupId.high)))
                        return "groupId: integer|Long expected";
                if (message.minimalDistanceToOrderTarget != null && message.hasOwnProperty("minimalDistanceToOrderTarget"))
                    if (!$util.isInteger(message.minimalDistanceToOrderTarget) && !(message.minimalDistanceToOrderTarget && $util.isInteger(message.minimalDistanceToOrderTarget.low) && $util.isInteger(message.minimalDistanceToOrderTarget.high)))
                        return "minimalDistanceToOrderTarget: integer|Long expected";
                if (message.useDifferentColorsForAllOrders != null && message.hasOwnProperty("useDifferentColorsForAllOrders"))
                    if (typeof message.useDifferentColorsForAllOrders !== "boolean")
                        return "useDifferentColorsForAllOrders: boolean expected";
                if (message.isDeleted != null && message.hasOwnProperty("isDeleted"))
                    if (typeof message.isDeleted !== "boolean")
                        return "isDeleted: boolean expected";
                if (message.newPassword != null && message.hasOwnProperty("newPassword")) {
                    properties.updatePassword = 1;
                    if (!$util.isString(message.newPassword))
                        return "newPassword: string expected";
                }
                return null;
            };

            /**
             * Creates an UpdateCourier message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.UpdateCourier
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.UpdateCourier} UpdateCourier
             */
            UpdateCourier.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.UpdateCourier)
                    return object;
                var message = new $root.fastcity.admin.UpdateCourier();
                if (object.courierId != null)
                    if ($util.Long)
                        (message.courierId = $util.Long.fromValue(object.courierId)).unsigned = true;
                    else if (typeof object.courierId === "string")
                        message.courierId = parseInt(object.courierId, 10);
                    else if (typeof object.courierId === "number")
                        message.courierId = object.courierId;
                    else if (typeof object.courierId === "object")
                        message.courierId = new $util.LongBits(object.courierId.low >>> 0, object.courierId.high >>> 0).toNumber(true);
                if (object.companyId != null)
                    if ($util.Long)
                        (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = true;
                    else if (typeof object.companyId === "string")
                        message.companyId = parseInt(object.companyId, 10);
                    else if (typeof object.companyId === "number")
                        message.companyId = object.companyId;
                    else if (typeof object.companyId === "object")
                        message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber(true);
                if (object.name != null)
                    message.name = String(object.name);
                if (object.phone != null)
                    message.phone = String(object.phone);
                if (object.groupId != null)
                    if ($util.Long)
                        (message.groupId = $util.Long.fromValue(object.groupId)).unsigned = true;
                    else if (typeof object.groupId === "string")
                        message.groupId = parseInt(object.groupId, 10);
                    else if (typeof object.groupId === "number")
                        message.groupId = object.groupId;
                    else if (typeof object.groupId === "object")
                        message.groupId = new $util.LongBits(object.groupId.low >>> 0, object.groupId.high >>> 0).toNumber(true);
                if (object.minimalDistanceToOrderTarget != null)
                    if ($util.Long)
                        (message.minimalDistanceToOrderTarget = $util.Long.fromValue(object.minimalDistanceToOrderTarget)).unsigned = true;
                    else if (typeof object.minimalDistanceToOrderTarget === "string")
                        message.minimalDistanceToOrderTarget = parseInt(object.minimalDistanceToOrderTarget, 10);
                    else if (typeof object.minimalDistanceToOrderTarget === "number")
                        message.minimalDistanceToOrderTarget = object.minimalDistanceToOrderTarget;
                    else if (typeof object.minimalDistanceToOrderTarget === "object")
                        message.minimalDistanceToOrderTarget = new $util.LongBits(object.minimalDistanceToOrderTarget.low >>> 0, object.minimalDistanceToOrderTarget.high >>> 0).toNumber(true);
                if (object.useDifferentColorsForAllOrders != null)
                    message.useDifferentColorsForAllOrders = Boolean(object.useDifferentColorsForAllOrders);
                if (object.isDeleted != null)
                    message.isDeleted = Boolean(object.isDeleted);
                if (object.newPassword != null)
                    message.newPassword = String(object.newPassword);
                return message;
            };

            /**
             * Creates a plain object from an UpdateCourier message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.UpdateCourier
             * @static
             * @param {fastcity.admin.UpdateCourier} message UpdateCourier
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UpdateCourier.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.courierId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.courierId = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.companyId = options.longs === String ? "0" : 0;
                    object.name = "";
                    object.phone = "";
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.groupId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.groupId = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.minimalDistanceToOrderTarget = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.minimalDistanceToOrderTarget = options.longs === String ? "0" : 0;
                    object.useDifferentColorsForAllOrders = false;
                    object.isDeleted = false;
                }
                if (message.courierId != null && message.hasOwnProperty("courierId"))
                    if (typeof message.courierId === "number")
                        object.courierId = options.longs === String ? String(message.courierId) : message.courierId;
                    else
                        object.courierId = options.longs === String ? $util.Long.prototype.toString.call(message.courierId) : options.longs === Number ? new $util.LongBits(message.courierId.low >>> 0, message.courierId.high >>> 0).toNumber(true) : message.courierId;
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (typeof message.companyId === "number")
                        object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                    else
                        object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber(true) : message.companyId;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.phone != null && message.hasOwnProperty("phone"))
                    object.phone = message.phone;
                if (message.groupId != null && message.hasOwnProperty("groupId"))
                    if (typeof message.groupId === "number")
                        object.groupId = options.longs === String ? String(message.groupId) : message.groupId;
                    else
                        object.groupId = options.longs === String ? $util.Long.prototype.toString.call(message.groupId) : options.longs === Number ? new $util.LongBits(message.groupId.low >>> 0, message.groupId.high >>> 0).toNumber(true) : message.groupId;
                if (message.minimalDistanceToOrderTarget != null && message.hasOwnProperty("minimalDistanceToOrderTarget"))
                    if (typeof message.minimalDistanceToOrderTarget === "number")
                        object.minimalDistanceToOrderTarget = options.longs === String ? String(message.minimalDistanceToOrderTarget) : message.minimalDistanceToOrderTarget;
                    else
                        object.minimalDistanceToOrderTarget = options.longs === String ? $util.Long.prototype.toString.call(message.minimalDistanceToOrderTarget) : options.longs === Number ? new $util.LongBits(message.minimalDistanceToOrderTarget.low >>> 0, message.minimalDistanceToOrderTarget.high >>> 0).toNumber(true) : message.minimalDistanceToOrderTarget;
                if (message.useDifferentColorsForAllOrders != null && message.hasOwnProperty("useDifferentColorsForAllOrders"))
                    object.useDifferentColorsForAllOrders = message.useDifferentColorsForAllOrders;
                if (message.isDeleted != null && message.hasOwnProperty("isDeleted"))
                    object.isDeleted = message.isDeleted;
                if (message.newPassword != null && message.hasOwnProperty("newPassword")) {
                    object.newPassword = message.newPassword;
                    if (options.oneofs)
                        object.updatePassword = "newPassword";
                }
                return object;
            };

            /**
             * Converts this UpdateCourier to JSON.
             * @function toJSON
             * @memberof fastcity.admin.UpdateCourier
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UpdateCourier.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return UpdateCourier;
        })();

        admin.GetCourierList = (function() {

            /**
             * Properties of a GetCourierList.
             * @memberof fastcity.admin
             * @interface IGetCourierList
             * @property {number|Long|null} [companyId] GetCourierList companyId
             * @property {Array.<number|Long>|null} [courierId] GetCourierList courierId
             * @property {Array.<number|Long>|null} [groupId] GetCourierList groupId
             * @property {number|null} [offset] GetCourierList offset
             * @property {number|null} [limit] GetCourierList limit
             * @property {fastcity.common.SortOrder.Order|null} [sortOrder] GetCourierList sortOrder
             * @property {Array.<number|Long>|null} [subCompanyId] GetCourierList subCompanyId
             * @property {boolean|null} [withDeleted] GetCourierList withDeleted
             */

            /**
             * Constructs a new GetCourierList.
             * @memberof fastcity.admin
             * @classdesc Represents a GetCourierList.
             * @implements IGetCourierList
             * @constructor
             * @param {fastcity.admin.IGetCourierList=} [properties] Properties to set
             */
            function GetCourierList(properties) {
                this.courierId = [];
                this.groupId = [];
                this.subCompanyId = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GetCourierList companyId.
             * @member {number|Long} companyId
             * @memberof fastcity.admin.GetCourierList
             * @instance
             */
            GetCourierList.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * GetCourierList courierId.
             * @member {Array.<number|Long>} courierId
             * @memberof fastcity.admin.GetCourierList
             * @instance
             */
            GetCourierList.prototype.courierId = $util.emptyArray;

            /**
             * GetCourierList groupId.
             * @member {Array.<number|Long>} groupId
             * @memberof fastcity.admin.GetCourierList
             * @instance
             */
            GetCourierList.prototype.groupId = $util.emptyArray;

            /**
             * GetCourierList offset.
             * @member {number} offset
             * @memberof fastcity.admin.GetCourierList
             * @instance
             */
            GetCourierList.prototype.offset = 0;

            /**
             * GetCourierList limit.
             * @member {number} limit
             * @memberof fastcity.admin.GetCourierList
             * @instance
             */
            GetCourierList.prototype.limit = 0;

            /**
             * GetCourierList sortOrder.
             * @member {fastcity.common.SortOrder.Order} sortOrder
             * @memberof fastcity.admin.GetCourierList
             * @instance
             */
            GetCourierList.prototype.sortOrder = 0;

            /**
             * GetCourierList subCompanyId.
             * @member {Array.<number|Long>} subCompanyId
             * @memberof fastcity.admin.GetCourierList
             * @instance
             */
            GetCourierList.prototype.subCompanyId = $util.emptyArray;

            /**
             * GetCourierList withDeleted.
             * @member {boolean} withDeleted
             * @memberof fastcity.admin.GetCourierList
             * @instance
             */
            GetCourierList.prototype.withDeleted = false;

            /**
             * Creates a new GetCourierList instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.GetCourierList
             * @static
             * @param {fastcity.admin.IGetCourierList=} [properties] Properties to set
             * @returns {fastcity.admin.GetCourierList} GetCourierList instance
             */
            GetCourierList.create = function create(properties) {
                return new GetCourierList(properties);
            };

            /**
             * Encodes the specified GetCourierList message. Does not implicitly {@link fastcity.admin.GetCourierList.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.GetCourierList
             * @static
             * @param {fastcity.admin.IGetCourierList} message GetCourierList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetCourierList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.companyId);
                if (message.courierId != null && message.courierId.length) {
                    writer.uint32(/* id 2, wireType 2 =*/18).fork();
                    for (var i = 0; i < message.courierId.length; ++i)
                        writer.uint64(message.courierId[i]);
                    writer.ldelim();
                }
                if (message.groupId != null && message.groupId.length) {
                    writer.uint32(/* id 3, wireType 2 =*/26).fork();
                    for (var i = 0; i < message.groupId.length; ++i)
                        writer.uint64(message.groupId[i]);
                    writer.ldelim();
                }
                if (message.offset != null && Object.hasOwnProperty.call(message, "offset"))
                    writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.offset);
                if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                    writer.uint32(/* id 5, wireType 0 =*/40).uint32(message.limit);
                if (message.sortOrder != null && Object.hasOwnProperty.call(message, "sortOrder"))
                    writer.uint32(/* id 6, wireType 0 =*/48).int32(message.sortOrder);
                if (message.subCompanyId != null && message.subCompanyId.length) {
                    writer.uint32(/* id 7, wireType 2 =*/58).fork();
                    for (var i = 0; i < message.subCompanyId.length; ++i)
                        writer.uint64(message.subCompanyId[i]);
                    writer.ldelim();
                }
                if (message.withDeleted != null && Object.hasOwnProperty.call(message, "withDeleted"))
                    writer.uint32(/* id 8, wireType 0 =*/64).bool(message.withDeleted);
                return writer;
            };

            /**
             * Decodes a GetCourierList message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.GetCourierList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.GetCourierList} GetCourierList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetCourierList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.GetCourierList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.companyId = reader.uint64();
                        break;
                    case 2:
                        if (!(message.courierId && message.courierId.length))
                            message.courierId = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.courierId.push(reader.uint64());
                        } else
                            message.courierId.push(reader.uint64());
                        break;
                    case 3:
                        if (!(message.groupId && message.groupId.length))
                            message.groupId = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.groupId.push(reader.uint64());
                        } else
                            message.groupId.push(reader.uint64());
                        break;
                    case 4:
                        message.offset = reader.uint32();
                        break;
                    case 5:
                        message.limit = reader.uint32();
                        break;
                    case 6:
                        message.sortOrder = reader.int32();
                        break;
                    case 7:
                        if (!(message.subCompanyId && message.subCompanyId.length))
                            message.subCompanyId = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.subCompanyId.push(reader.uint64());
                        } else
                            message.subCompanyId.push(reader.uint64());
                        break;
                    case 8:
                        message.withDeleted = reader.bool();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a GetCourierList message.
             * @function verify
             * @memberof fastcity.admin.GetCourierList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetCourierList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                        return "companyId: integer|Long expected";
                if (message.courierId != null && message.hasOwnProperty("courierId")) {
                    if (!Array.isArray(message.courierId))
                        return "courierId: array expected";
                    for (var i = 0; i < message.courierId.length; ++i)
                        if (!$util.isInteger(message.courierId[i]) && !(message.courierId[i] && $util.isInteger(message.courierId[i].low) && $util.isInteger(message.courierId[i].high)))
                            return "courierId: integer|Long[] expected";
                }
                if (message.groupId != null && message.hasOwnProperty("groupId")) {
                    if (!Array.isArray(message.groupId))
                        return "groupId: array expected";
                    for (var i = 0; i < message.groupId.length; ++i)
                        if (!$util.isInteger(message.groupId[i]) && !(message.groupId[i] && $util.isInteger(message.groupId[i].low) && $util.isInteger(message.groupId[i].high)))
                            return "groupId: integer|Long[] expected";
                }
                if (message.offset != null && message.hasOwnProperty("offset"))
                    if (!$util.isInteger(message.offset))
                        return "offset: integer expected";
                if (message.limit != null && message.hasOwnProperty("limit"))
                    if (!$util.isInteger(message.limit))
                        return "limit: integer expected";
                if (message.sortOrder != null && message.hasOwnProperty("sortOrder"))
                    switch (message.sortOrder) {
                    default:
                        return "sortOrder: enum value expected";
                    case 0:
                    case 1:
                        break;
                    }
                if (message.subCompanyId != null && message.hasOwnProperty("subCompanyId")) {
                    if (!Array.isArray(message.subCompanyId))
                        return "subCompanyId: array expected";
                    for (var i = 0; i < message.subCompanyId.length; ++i)
                        if (!$util.isInteger(message.subCompanyId[i]) && !(message.subCompanyId[i] && $util.isInteger(message.subCompanyId[i].low) && $util.isInteger(message.subCompanyId[i].high)))
                            return "subCompanyId: integer|Long[] expected";
                }
                if (message.withDeleted != null && message.hasOwnProperty("withDeleted"))
                    if (typeof message.withDeleted !== "boolean")
                        return "withDeleted: boolean expected";
                return null;
            };

            /**
             * Creates a GetCourierList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.GetCourierList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.GetCourierList} GetCourierList
             */
            GetCourierList.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.GetCourierList)
                    return object;
                var message = new $root.fastcity.admin.GetCourierList();
                if (object.companyId != null)
                    if ($util.Long)
                        (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = true;
                    else if (typeof object.companyId === "string")
                        message.companyId = parseInt(object.companyId, 10);
                    else if (typeof object.companyId === "number")
                        message.companyId = object.companyId;
                    else if (typeof object.companyId === "object")
                        message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber(true);
                if (object.courierId) {
                    if (!Array.isArray(object.courierId))
                        throw TypeError(".fastcity.admin.GetCourierList.courierId: array expected");
                    message.courierId = [];
                    for (var i = 0; i < object.courierId.length; ++i)
                        if ($util.Long)
                            (message.courierId[i] = $util.Long.fromValue(object.courierId[i])).unsigned = true;
                        else if (typeof object.courierId[i] === "string")
                            message.courierId[i] = parseInt(object.courierId[i], 10);
                        else if (typeof object.courierId[i] === "number")
                            message.courierId[i] = object.courierId[i];
                        else if (typeof object.courierId[i] === "object")
                            message.courierId[i] = new $util.LongBits(object.courierId[i].low >>> 0, object.courierId[i].high >>> 0).toNumber(true);
                }
                if (object.groupId) {
                    if (!Array.isArray(object.groupId))
                        throw TypeError(".fastcity.admin.GetCourierList.groupId: array expected");
                    message.groupId = [];
                    for (var i = 0; i < object.groupId.length; ++i)
                        if ($util.Long)
                            (message.groupId[i] = $util.Long.fromValue(object.groupId[i])).unsigned = true;
                        else if (typeof object.groupId[i] === "string")
                            message.groupId[i] = parseInt(object.groupId[i], 10);
                        else if (typeof object.groupId[i] === "number")
                            message.groupId[i] = object.groupId[i];
                        else if (typeof object.groupId[i] === "object")
                            message.groupId[i] = new $util.LongBits(object.groupId[i].low >>> 0, object.groupId[i].high >>> 0).toNumber(true);
                }
                if (object.offset != null)
                    message.offset = object.offset >>> 0;
                if (object.limit != null)
                    message.limit = object.limit >>> 0;
                switch (object.sortOrder) {
                case "ASC":
                case 0:
                    message.sortOrder = 0;
                    break;
                case "DESC":
                case 1:
                    message.sortOrder = 1;
                    break;
                }
                if (object.subCompanyId) {
                    if (!Array.isArray(object.subCompanyId))
                        throw TypeError(".fastcity.admin.GetCourierList.subCompanyId: array expected");
                    message.subCompanyId = [];
                    for (var i = 0; i < object.subCompanyId.length; ++i)
                        if ($util.Long)
                            (message.subCompanyId[i] = $util.Long.fromValue(object.subCompanyId[i])).unsigned = true;
                        else if (typeof object.subCompanyId[i] === "string")
                            message.subCompanyId[i] = parseInt(object.subCompanyId[i], 10);
                        else if (typeof object.subCompanyId[i] === "number")
                            message.subCompanyId[i] = object.subCompanyId[i];
                        else if (typeof object.subCompanyId[i] === "object")
                            message.subCompanyId[i] = new $util.LongBits(object.subCompanyId[i].low >>> 0, object.subCompanyId[i].high >>> 0).toNumber(true);
                }
                if (object.withDeleted != null)
                    message.withDeleted = Boolean(object.withDeleted);
                return message;
            };

            /**
             * Creates a plain object from a GetCourierList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.GetCourierList
             * @static
             * @param {fastcity.admin.GetCourierList} message GetCourierList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetCourierList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults) {
                    object.courierId = [];
                    object.groupId = [];
                    object.subCompanyId = [];
                }
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.companyId = options.longs === String ? "0" : 0;
                    object.offset = 0;
                    object.limit = 0;
                    object.sortOrder = options.enums === String ? "ASC" : 0;
                    object.withDeleted = false;
                }
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (typeof message.companyId === "number")
                        object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                    else
                        object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber(true) : message.companyId;
                if (message.courierId && message.courierId.length) {
                    object.courierId = [];
                    for (var j = 0; j < message.courierId.length; ++j)
                        if (typeof message.courierId[j] === "number")
                            object.courierId[j] = options.longs === String ? String(message.courierId[j]) : message.courierId[j];
                        else
                            object.courierId[j] = options.longs === String ? $util.Long.prototype.toString.call(message.courierId[j]) : options.longs === Number ? new $util.LongBits(message.courierId[j].low >>> 0, message.courierId[j].high >>> 0).toNumber(true) : message.courierId[j];
                }
                if (message.groupId && message.groupId.length) {
                    object.groupId = [];
                    for (var j = 0; j < message.groupId.length; ++j)
                        if (typeof message.groupId[j] === "number")
                            object.groupId[j] = options.longs === String ? String(message.groupId[j]) : message.groupId[j];
                        else
                            object.groupId[j] = options.longs === String ? $util.Long.prototype.toString.call(message.groupId[j]) : options.longs === Number ? new $util.LongBits(message.groupId[j].low >>> 0, message.groupId[j].high >>> 0).toNumber(true) : message.groupId[j];
                }
                if (message.offset != null && message.hasOwnProperty("offset"))
                    object.offset = message.offset;
                if (message.limit != null && message.hasOwnProperty("limit"))
                    object.limit = message.limit;
                if (message.sortOrder != null && message.hasOwnProperty("sortOrder"))
                    object.sortOrder = options.enums === String ? $root.fastcity.common.SortOrder.Order[message.sortOrder] : message.sortOrder;
                if (message.subCompanyId && message.subCompanyId.length) {
                    object.subCompanyId = [];
                    for (var j = 0; j < message.subCompanyId.length; ++j)
                        if (typeof message.subCompanyId[j] === "number")
                            object.subCompanyId[j] = options.longs === String ? String(message.subCompanyId[j]) : message.subCompanyId[j];
                        else
                            object.subCompanyId[j] = options.longs === String ? $util.Long.prototype.toString.call(message.subCompanyId[j]) : options.longs === Number ? new $util.LongBits(message.subCompanyId[j].low >>> 0, message.subCompanyId[j].high >>> 0).toNumber(true) : message.subCompanyId[j];
                }
                if (message.withDeleted != null && message.hasOwnProperty("withDeleted"))
                    object.withDeleted = message.withDeleted;
                return object;
            };

            /**
             * Converts this GetCourierList to JSON.
             * @function toJSON
             * @memberof fastcity.admin.GetCourierList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetCourierList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return GetCourierList;
        })();

        admin.SetCourierGroup = (function() {

            /**
             * Properties of a SetCourierGroup.
             * @memberof fastcity.admin
             * @interface ISetCourierGroup
             * @property {number|Long|null} [courierId] SetCourierGroup courierId
             * @property {number|Long|null} [groupId] SetCourierGroup groupId
             */

            /**
             * Constructs a new SetCourierGroup.
             * @memberof fastcity.admin
             * @classdesc Represents a SetCourierGroup.
             * @implements ISetCourierGroup
             * @constructor
             * @param {fastcity.admin.ISetCourierGroup=} [properties] Properties to set
             */
            function SetCourierGroup(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * SetCourierGroup courierId.
             * @member {number|Long} courierId
             * @memberof fastcity.admin.SetCourierGroup
             * @instance
             */
            SetCourierGroup.prototype.courierId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * SetCourierGroup groupId.
             * @member {number|Long} groupId
             * @memberof fastcity.admin.SetCourierGroup
             * @instance
             */
            SetCourierGroup.prototype.groupId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Creates a new SetCourierGroup instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.SetCourierGroup
             * @static
             * @param {fastcity.admin.ISetCourierGroup=} [properties] Properties to set
             * @returns {fastcity.admin.SetCourierGroup} SetCourierGroup instance
             */
            SetCourierGroup.create = function create(properties) {
                return new SetCourierGroup(properties);
            };

            /**
             * Encodes the specified SetCourierGroup message. Does not implicitly {@link fastcity.admin.SetCourierGroup.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.SetCourierGroup
             * @static
             * @param {fastcity.admin.ISetCourierGroup} message SetCourierGroup message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SetCourierGroup.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.courierId != null && Object.hasOwnProperty.call(message, "courierId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.courierId);
                if (message.groupId != null && Object.hasOwnProperty.call(message, "groupId"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.groupId);
                return writer;
            };

            /**
             * Decodes a SetCourierGroup message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.SetCourierGroup
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.SetCourierGroup} SetCourierGroup
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SetCourierGroup.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.SetCourierGroup();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.courierId = reader.uint64();
                        break;
                    case 2:
                        message.groupId = reader.uint64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a SetCourierGroup message.
             * @function verify
             * @memberof fastcity.admin.SetCourierGroup
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SetCourierGroup.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.courierId != null && message.hasOwnProperty("courierId"))
                    if (!$util.isInteger(message.courierId) && !(message.courierId && $util.isInteger(message.courierId.low) && $util.isInteger(message.courierId.high)))
                        return "courierId: integer|Long expected";
                if (message.groupId != null && message.hasOwnProperty("groupId"))
                    if (!$util.isInteger(message.groupId) && !(message.groupId && $util.isInteger(message.groupId.low) && $util.isInteger(message.groupId.high)))
                        return "groupId: integer|Long expected";
                return null;
            };

            /**
             * Creates a SetCourierGroup message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.SetCourierGroup
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.SetCourierGroup} SetCourierGroup
             */
            SetCourierGroup.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.SetCourierGroup)
                    return object;
                var message = new $root.fastcity.admin.SetCourierGroup();
                if (object.courierId != null)
                    if ($util.Long)
                        (message.courierId = $util.Long.fromValue(object.courierId)).unsigned = true;
                    else if (typeof object.courierId === "string")
                        message.courierId = parseInt(object.courierId, 10);
                    else if (typeof object.courierId === "number")
                        message.courierId = object.courierId;
                    else if (typeof object.courierId === "object")
                        message.courierId = new $util.LongBits(object.courierId.low >>> 0, object.courierId.high >>> 0).toNumber(true);
                if (object.groupId != null)
                    if ($util.Long)
                        (message.groupId = $util.Long.fromValue(object.groupId)).unsigned = true;
                    else if (typeof object.groupId === "string")
                        message.groupId = parseInt(object.groupId, 10);
                    else if (typeof object.groupId === "number")
                        message.groupId = object.groupId;
                    else if (typeof object.groupId === "object")
                        message.groupId = new $util.LongBits(object.groupId.low >>> 0, object.groupId.high >>> 0).toNumber(true);
                return message;
            };

            /**
             * Creates a plain object from a SetCourierGroup message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.SetCourierGroup
             * @static
             * @param {fastcity.admin.SetCourierGroup} message SetCourierGroup
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SetCourierGroup.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.courierId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.courierId = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.groupId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.groupId = options.longs === String ? "0" : 0;
                }
                if (message.courierId != null && message.hasOwnProperty("courierId"))
                    if (typeof message.courierId === "number")
                        object.courierId = options.longs === String ? String(message.courierId) : message.courierId;
                    else
                        object.courierId = options.longs === String ? $util.Long.prototype.toString.call(message.courierId) : options.longs === Number ? new $util.LongBits(message.courierId.low >>> 0, message.courierId.high >>> 0).toNumber(true) : message.courierId;
                if (message.groupId != null && message.hasOwnProperty("groupId"))
                    if (typeof message.groupId === "number")
                        object.groupId = options.longs === String ? String(message.groupId) : message.groupId;
                    else
                        object.groupId = options.longs === String ? $util.Long.prototype.toString.call(message.groupId) : options.longs === Number ? new $util.LongBits(message.groupId.low >>> 0, message.groupId.high >>> 0).toNumber(true) : message.groupId;
                return object;
            };

            /**
             * Converts this SetCourierGroup to JSON.
             * @function toJSON
             * @memberof fastcity.admin.SetCourierGroup
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SetCourierGroup.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return SetCourierGroup;
        })();

        admin.CourierList = (function() {

            /**
             * Properties of a CourierList.
             * @memberof fastcity.admin
             * @interface ICourierList
             * @property {Array.<fastcity.admin.ICourier>|null} [courier] CourierList courier
             * @property {number|null} [totalCouriers] CourierList totalCouriers
             */

            /**
             * Constructs a new CourierList.
             * @memberof fastcity.admin
             * @classdesc Represents a CourierList.
             * @implements ICourierList
             * @constructor
             * @param {fastcity.admin.ICourierList=} [properties] Properties to set
             */
            function CourierList(properties) {
                this.courier = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CourierList courier.
             * @member {Array.<fastcity.admin.ICourier>} courier
             * @memberof fastcity.admin.CourierList
             * @instance
             */
            CourierList.prototype.courier = $util.emptyArray;

            /**
             * CourierList totalCouriers.
             * @member {number} totalCouriers
             * @memberof fastcity.admin.CourierList
             * @instance
             */
            CourierList.prototype.totalCouriers = 0;

            /**
             * Creates a new CourierList instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.CourierList
             * @static
             * @param {fastcity.admin.ICourierList=} [properties] Properties to set
             * @returns {fastcity.admin.CourierList} CourierList instance
             */
            CourierList.create = function create(properties) {
                return new CourierList(properties);
            };

            /**
             * Encodes the specified CourierList message. Does not implicitly {@link fastcity.admin.CourierList.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.CourierList
             * @static
             * @param {fastcity.admin.ICourierList} message CourierList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CourierList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.courier != null && message.courier.length)
                    for (var i = 0; i < message.courier.length; ++i)
                        $root.fastcity.admin.Courier.encode(message.courier[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.totalCouriers != null && Object.hasOwnProperty.call(message, "totalCouriers"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.totalCouriers);
                return writer;
            };

            /**
             * Decodes a CourierList message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.CourierList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.CourierList} CourierList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CourierList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.CourierList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.courier && message.courier.length))
                            message.courier = [];
                        message.courier.push($root.fastcity.admin.Courier.decode(reader, reader.uint32()));
                        break;
                    case 2:
                        message.totalCouriers = reader.uint32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a CourierList message.
             * @function verify
             * @memberof fastcity.admin.CourierList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CourierList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.courier != null && message.hasOwnProperty("courier")) {
                    if (!Array.isArray(message.courier))
                        return "courier: array expected";
                    for (var i = 0; i < message.courier.length; ++i) {
                        var error = $root.fastcity.admin.Courier.verify(message.courier[i]);
                        if (error)
                            return "courier." + error;
                    }
                }
                if (message.totalCouriers != null && message.hasOwnProperty("totalCouriers"))
                    if (!$util.isInteger(message.totalCouriers))
                        return "totalCouriers: integer expected";
                return null;
            };

            /**
             * Creates a CourierList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.CourierList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.CourierList} CourierList
             */
            CourierList.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.CourierList)
                    return object;
                var message = new $root.fastcity.admin.CourierList();
                if (object.courier) {
                    if (!Array.isArray(object.courier))
                        throw TypeError(".fastcity.admin.CourierList.courier: array expected");
                    message.courier = [];
                    for (var i = 0; i < object.courier.length; ++i) {
                        if (typeof object.courier[i] !== "object")
                            throw TypeError(".fastcity.admin.CourierList.courier: object expected");
                        message.courier[i] = $root.fastcity.admin.Courier.fromObject(object.courier[i]);
                    }
                }
                if (object.totalCouriers != null)
                    message.totalCouriers = object.totalCouriers >>> 0;
                return message;
            };

            /**
             * Creates a plain object from a CourierList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.CourierList
             * @static
             * @param {fastcity.admin.CourierList} message CourierList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CourierList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.courier = [];
                if (options.defaults)
                    object.totalCouriers = 0;
                if (message.courier && message.courier.length) {
                    object.courier = [];
                    for (var j = 0; j < message.courier.length; ++j)
                        object.courier[j] = $root.fastcity.admin.Courier.toObject(message.courier[j], options);
                }
                if (message.totalCouriers != null && message.hasOwnProperty("totalCouriers"))
                    object.totalCouriers = message.totalCouriers;
                return object;
            };

            /**
             * Converts this CourierList to JSON.
             * @function toJSON
             * @memberof fastcity.admin.CourierList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CourierList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return CourierList;
        })();

        admin.ChangedCourierIdList = (function() {

            /**
             * Properties of a ChangedCourierIdList.
             * @memberof fastcity.admin
             * @interface IChangedCourierIdList
             * @property {Array.<number|Long>|null} [courierId] ChangedCourierIdList courierId
             * @property {number|Long|null} [companyId] ChangedCourierIdList companyId
             * @property {Array.<number|Long>|null} [subCompanyId] ChangedCourierIdList subCompanyId
             */

            /**
             * Constructs a new ChangedCourierIdList.
             * @memberof fastcity.admin
             * @classdesc Represents a ChangedCourierIdList.
             * @implements IChangedCourierIdList
             * @constructor
             * @param {fastcity.admin.IChangedCourierIdList=} [properties] Properties to set
             */
            function ChangedCourierIdList(properties) {
                this.courierId = [];
                this.subCompanyId = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ChangedCourierIdList courierId.
             * @member {Array.<number|Long>} courierId
             * @memberof fastcity.admin.ChangedCourierIdList
             * @instance
             */
            ChangedCourierIdList.prototype.courierId = $util.emptyArray;

            /**
             * ChangedCourierIdList companyId.
             * @member {number|Long} companyId
             * @memberof fastcity.admin.ChangedCourierIdList
             * @instance
             */
            ChangedCourierIdList.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * ChangedCourierIdList subCompanyId.
             * @member {Array.<number|Long>} subCompanyId
             * @memberof fastcity.admin.ChangedCourierIdList
             * @instance
             */
            ChangedCourierIdList.prototype.subCompanyId = $util.emptyArray;

            /**
             * Creates a new ChangedCourierIdList instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.ChangedCourierIdList
             * @static
             * @param {fastcity.admin.IChangedCourierIdList=} [properties] Properties to set
             * @returns {fastcity.admin.ChangedCourierIdList} ChangedCourierIdList instance
             */
            ChangedCourierIdList.create = function create(properties) {
                return new ChangedCourierIdList(properties);
            };

            /**
             * Encodes the specified ChangedCourierIdList message. Does not implicitly {@link fastcity.admin.ChangedCourierIdList.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.ChangedCourierIdList
             * @static
             * @param {fastcity.admin.IChangedCourierIdList} message ChangedCourierIdList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ChangedCourierIdList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.courierId != null && message.courierId.length) {
                    writer.uint32(/* id 1, wireType 2 =*/10).fork();
                    for (var i = 0; i < message.courierId.length; ++i)
                        writer.uint64(message.courierId[i]);
                    writer.ldelim();
                }
                if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.companyId);
                if (message.subCompanyId != null && message.subCompanyId.length) {
                    writer.uint32(/* id 3, wireType 2 =*/26).fork();
                    for (var i = 0; i < message.subCompanyId.length; ++i)
                        writer.uint64(message.subCompanyId[i]);
                    writer.ldelim();
                }
                return writer;
            };

            /**
             * Decodes a ChangedCourierIdList message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.ChangedCourierIdList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.ChangedCourierIdList} ChangedCourierIdList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ChangedCourierIdList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.ChangedCourierIdList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.courierId && message.courierId.length))
                            message.courierId = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.courierId.push(reader.uint64());
                        } else
                            message.courierId.push(reader.uint64());
                        break;
                    case 2:
                        message.companyId = reader.uint64();
                        break;
                    case 3:
                        if (!(message.subCompanyId && message.subCompanyId.length))
                            message.subCompanyId = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.subCompanyId.push(reader.uint64());
                        } else
                            message.subCompanyId.push(reader.uint64());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a ChangedCourierIdList message.
             * @function verify
             * @memberof fastcity.admin.ChangedCourierIdList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ChangedCourierIdList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.courierId != null && message.hasOwnProperty("courierId")) {
                    if (!Array.isArray(message.courierId))
                        return "courierId: array expected";
                    for (var i = 0; i < message.courierId.length; ++i)
                        if (!$util.isInteger(message.courierId[i]) && !(message.courierId[i] && $util.isInteger(message.courierId[i].low) && $util.isInteger(message.courierId[i].high)))
                            return "courierId: integer|Long[] expected";
                }
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                        return "companyId: integer|Long expected";
                if (message.subCompanyId != null && message.hasOwnProperty("subCompanyId")) {
                    if (!Array.isArray(message.subCompanyId))
                        return "subCompanyId: array expected";
                    for (var i = 0; i < message.subCompanyId.length; ++i)
                        if (!$util.isInteger(message.subCompanyId[i]) && !(message.subCompanyId[i] && $util.isInteger(message.subCompanyId[i].low) && $util.isInteger(message.subCompanyId[i].high)))
                            return "subCompanyId: integer|Long[] expected";
                }
                return null;
            };

            /**
             * Creates a ChangedCourierIdList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.ChangedCourierIdList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.ChangedCourierIdList} ChangedCourierIdList
             */
            ChangedCourierIdList.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.ChangedCourierIdList)
                    return object;
                var message = new $root.fastcity.admin.ChangedCourierIdList();
                if (object.courierId) {
                    if (!Array.isArray(object.courierId))
                        throw TypeError(".fastcity.admin.ChangedCourierIdList.courierId: array expected");
                    message.courierId = [];
                    for (var i = 0; i < object.courierId.length; ++i)
                        if ($util.Long)
                            (message.courierId[i] = $util.Long.fromValue(object.courierId[i])).unsigned = true;
                        else if (typeof object.courierId[i] === "string")
                            message.courierId[i] = parseInt(object.courierId[i], 10);
                        else if (typeof object.courierId[i] === "number")
                            message.courierId[i] = object.courierId[i];
                        else if (typeof object.courierId[i] === "object")
                            message.courierId[i] = new $util.LongBits(object.courierId[i].low >>> 0, object.courierId[i].high >>> 0).toNumber(true);
                }
                if (object.companyId != null)
                    if ($util.Long)
                        (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = true;
                    else if (typeof object.companyId === "string")
                        message.companyId = parseInt(object.companyId, 10);
                    else if (typeof object.companyId === "number")
                        message.companyId = object.companyId;
                    else if (typeof object.companyId === "object")
                        message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber(true);
                if (object.subCompanyId) {
                    if (!Array.isArray(object.subCompanyId))
                        throw TypeError(".fastcity.admin.ChangedCourierIdList.subCompanyId: array expected");
                    message.subCompanyId = [];
                    for (var i = 0; i < object.subCompanyId.length; ++i)
                        if ($util.Long)
                            (message.subCompanyId[i] = $util.Long.fromValue(object.subCompanyId[i])).unsigned = true;
                        else if (typeof object.subCompanyId[i] === "string")
                            message.subCompanyId[i] = parseInt(object.subCompanyId[i], 10);
                        else if (typeof object.subCompanyId[i] === "number")
                            message.subCompanyId[i] = object.subCompanyId[i];
                        else if (typeof object.subCompanyId[i] === "object")
                            message.subCompanyId[i] = new $util.LongBits(object.subCompanyId[i].low >>> 0, object.subCompanyId[i].high >>> 0).toNumber(true);
                }
                return message;
            };

            /**
             * Creates a plain object from a ChangedCourierIdList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.ChangedCourierIdList
             * @static
             * @param {fastcity.admin.ChangedCourierIdList} message ChangedCourierIdList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ChangedCourierIdList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults) {
                    object.courierId = [];
                    object.subCompanyId = [];
                }
                if (options.defaults)
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.companyId = options.longs === String ? "0" : 0;
                if (message.courierId && message.courierId.length) {
                    object.courierId = [];
                    for (var j = 0; j < message.courierId.length; ++j)
                        if (typeof message.courierId[j] === "number")
                            object.courierId[j] = options.longs === String ? String(message.courierId[j]) : message.courierId[j];
                        else
                            object.courierId[j] = options.longs === String ? $util.Long.prototype.toString.call(message.courierId[j]) : options.longs === Number ? new $util.LongBits(message.courierId[j].low >>> 0, message.courierId[j].high >>> 0).toNumber(true) : message.courierId[j];
                }
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (typeof message.companyId === "number")
                        object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                    else
                        object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber(true) : message.companyId;
                if (message.subCompanyId && message.subCompanyId.length) {
                    object.subCompanyId = [];
                    for (var j = 0; j < message.subCompanyId.length; ++j)
                        if (typeof message.subCompanyId[j] === "number")
                            object.subCompanyId[j] = options.longs === String ? String(message.subCompanyId[j]) : message.subCompanyId[j];
                        else
                            object.subCompanyId[j] = options.longs === String ? $util.Long.prototype.toString.call(message.subCompanyId[j]) : options.longs === Number ? new $util.LongBits(message.subCompanyId[j].low >>> 0, message.subCompanyId[j].high >>> 0).toNumber(true) : message.subCompanyId[j];
                }
                return object;
            };

            /**
             * Converts this ChangedCourierIdList to JSON.
             * @function toJSON
             * @memberof fastcity.admin.ChangedCourierIdList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ChangedCourierIdList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return ChangedCourierIdList;
        })();

        admin.SetCourierIsDeleted = (function() {

            /**
             * Properties of a SetCourierIsDeleted.
             * @memberof fastcity.admin
             * @interface ISetCourierIsDeleted
             * @property {number|Long|null} [courierId] SetCourierIsDeleted courierId
             * @property {boolean|null} [isDeleted] SetCourierIsDeleted isDeleted
             */

            /**
             * Constructs a new SetCourierIsDeleted.
             * @memberof fastcity.admin
             * @classdesc Represents a SetCourierIsDeleted.
             * @implements ISetCourierIsDeleted
             * @constructor
             * @param {fastcity.admin.ISetCourierIsDeleted=} [properties] Properties to set
             */
            function SetCourierIsDeleted(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * SetCourierIsDeleted courierId.
             * @member {number|Long} courierId
             * @memberof fastcity.admin.SetCourierIsDeleted
             * @instance
             */
            SetCourierIsDeleted.prototype.courierId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * SetCourierIsDeleted isDeleted.
             * @member {boolean} isDeleted
             * @memberof fastcity.admin.SetCourierIsDeleted
             * @instance
             */
            SetCourierIsDeleted.prototype.isDeleted = false;

            /**
             * Creates a new SetCourierIsDeleted instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.SetCourierIsDeleted
             * @static
             * @param {fastcity.admin.ISetCourierIsDeleted=} [properties] Properties to set
             * @returns {fastcity.admin.SetCourierIsDeleted} SetCourierIsDeleted instance
             */
            SetCourierIsDeleted.create = function create(properties) {
                return new SetCourierIsDeleted(properties);
            };

            /**
             * Encodes the specified SetCourierIsDeleted message. Does not implicitly {@link fastcity.admin.SetCourierIsDeleted.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.SetCourierIsDeleted
             * @static
             * @param {fastcity.admin.ISetCourierIsDeleted} message SetCourierIsDeleted message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SetCourierIsDeleted.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.courierId != null && Object.hasOwnProperty.call(message, "courierId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.courierId);
                if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                    writer.uint32(/* id 2, wireType 0 =*/16).bool(message.isDeleted);
                return writer;
            };

            /**
             * Decodes a SetCourierIsDeleted message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.SetCourierIsDeleted
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.SetCourierIsDeleted} SetCourierIsDeleted
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SetCourierIsDeleted.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.SetCourierIsDeleted();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.courierId = reader.uint64();
                        break;
                    case 2:
                        message.isDeleted = reader.bool();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a SetCourierIsDeleted message.
             * @function verify
             * @memberof fastcity.admin.SetCourierIsDeleted
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SetCourierIsDeleted.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.courierId != null && message.hasOwnProperty("courierId"))
                    if (!$util.isInteger(message.courierId) && !(message.courierId && $util.isInteger(message.courierId.low) && $util.isInteger(message.courierId.high)))
                        return "courierId: integer|Long expected";
                if (message.isDeleted != null && message.hasOwnProperty("isDeleted"))
                    if (typeof message.isDeleted !== "boolean")
                        return "isDeleted: boolean expected";
                return null;
            };

            /**
             * Creates a SetCourierIsDeleted message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.SetCourierIsDeleted
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.SetCourierIsDeleted} SetCourierIsDeleted
             */
            SetCourierIsDeleted.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.SetCourierIsDeleted)
                    return object;
                var message = new $root.fastcity.admin.SetCourierIsDeleted();
                if (object.courierId != null)
                    if ($util.Long)
                        (message.courierId = $util.Long.fromValue(object.courierId)).unsigned = true;
                    else if (typeof object.courierId === "string")
                        message.courierId = parseInt(object.courierId, 10);
                    else if (typeof object.courierId === "number")
                        message.courierId = object.courierId;
                    else if (typeof object.courierId === "object")
                        message.courierId = new $util.LongBits(object.courierId.low >>> 0, object.courierId.high >>> 0).toNumber(true);
                if (object.isDeleted != null)
                    message.isDeleted = Boolean(object.isDeleted);
                return message;
            };

            /**
             * Creates a plain object from a SetCourierIsDeleted message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.SetCourierIsDeleted
             * @static
             * @param {fastcity.admin.SetCourierIsDeleted} message SetCourierIsDeleted
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SetCourierIsDeleted.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.courierId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.courierId = options.longs === String ? "0" : 0;
                    object.isDeleted = false;
                }
                if (message.courierId != null && message.hasOwnProperty("courierId"))
                    if (typeof message.courierId === "number")
                        object.courierId = options.longs === String ? String(message.courierId) : message.courierId;
                    else
                        object.courierId = options.longs === String ? $util.Long.prototype.toString.call(message.courierId) : options.longs === Number ? new $util.LongBits(message.courierId.low >>> 0, message.courierId.high >>> 0).toNumber(true) : message.courierId;
                if (message.isDeleted != null && message.hasOwnProperty("isDeleted"))
                    object.isDeleted = message.isDeleted;
                return object;
            };

            /**
             * Converts this SetCourierIsDeleted to JSON.
             * @function toJSON
             * @memberof fastcity.admin.SetCourierIsDeleted
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SetCourierIsDeleted.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return SetCourierIsDeleted;
        })();

        admin.SubCompanyIdToExternalCourierId = (function() {

            /**
             * Properties of a SubCompanyIdToExternalCourierId.
             * @memberof fastcity.admin
             * @interface ISubCompanyIdToExternalCourierId
             * @property {number|Long|null} [courierId] SubCompanyIdToExternalCourierId courierId
             * @property {number|Long|null} [subCompanyId] SubCompanyIdToExternalCourierId subCompanyId
             * @property {string|null} [externalCourierId] SubCompanyIdToExternalCourierId externalCourierId
             */

            /**
             * Constructs a new SubCompanyIdToExternalCourierId.
             * @memberof fastcity.admin
             * @classdesc Represents a SubCompanyIdToExternalCourierId.
             * @implements ISubCompanyIdToExternalCourierId
             * @constructor
             * @param {fastcity.admin.ISubCompanyIdToExternalCourierId=} [properties] Properties to set
             */
            function SubCompanyIdToExternalCourierId(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * SubCompanyIdToExternalCourierId courierId.
             * @member {number|Long} courierId
             * @memberof fastcity.admin.SubCompanyIdToExternalCourierId
             * @instance
             */
            SubCompanyIdToExternalCourierId.prototype.courierId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * SubCompanyIdToExternalCourierId subCompanyId.
             * @member {number|Long} subCompanyId
             * @memberof fastcity.admin.SubCompanyIdToExternalCourierId
             * @instance
             */
            SubCompanyIdToExternalCourierId.prototype.subCompanyId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * SubCompanyIdToExternalCourierId externalCourierId.
             * @member {string} externalCourierId
             * @memberof fastcity.admin.SubCompanyIdToExternalCourierId
             * @instance
             */
            SubCompanyIdToExternalCourierId.prototype.externalCourierId = "";

            /**
             * Creates a new SubCompanyIdToExternalCourierId instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.SubCompanyIdToExternalCourierId
             * @static
             * @param {fastcity.admin.ISubCompanyIdToExternalCourierId=} [properties] Properties to set
             * @returns {fastcity.admin.SubCompanyIdToExternalCourierId} SubCompanyIdToExternalCourierId instance
             */
            SubCompanyIdToExternalCourierId.create = function create(properties) {
                return new SubCompanyIdToExternalCourierId(properties);
            };

            /**
             * Encodes the specified SubCompanyIdToExternalCourierId message. Does not implicitly {@link fastcity.admin.SubCompanyIdToExternalCourierId.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.SubCompanyIdToExternalCourierId
             * @static
             * @param {fastcity.admin.ISubCompanyIdToExternalCourierId} message SubCompanyIdToExternalCourierId message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SubCompanyIdToExternalCourierId.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.courierId != null && Object.hasOwnProperty.call(message, "courierId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.courierId);
                if (message.subCompanyId != null && Object.hasOwnProperty.call(message, "subCompanyId"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.subCompanyId);
                if (message.externalCourierId != null && Object.hasOwnProperty.call(message, "externalCourierId"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.externalCourierId);
                return writer;
            };

            /**
             * Decodes a SubCompanyIdToExternalCourierId message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.SubCompanyIdToExternalCourierId
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.SubCompanyIdToExternalCourierId} SubCompanyIdToExternalCourierId
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SubCompanyIdToExternalCourierId.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.SubCompanyIdToExternalCourierId();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.courierId = reader.uint64();
                        break;
                    case 2:
                        message.subCompanyId = reader.uint64();
                        break;
                    case 3:
                        message.externalCourierId = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a SubCompanyIdToExternalCourierId message.
             * @function verify
             * @memberof fastcity.admin.SubCompanyIdToExternalCourierId
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SubCompanyIdToExternalCourierId.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.courierId != null && message.hasOwnProperty("courierId"))
                    if (!$util.isInteger(message.courierId) && !(message.courierId && $util.isInteger(message.courierId.low) && $util.isInteger(message.courierId.high)))
                        return "courierId: integer|Long expected";
                if (message.subCompanyId != null && message.hasOwnProperty("subCompanyId"))
                    if (!$util.isInteger(message.subCompanyId) && !(message.subCompanyId && $util.isInteger(message.subCompanyId.low) && $util.isInteger(message.subCompanyId.high)))
                        return "subCompanyId: integer|Long expected";
                if (message.externalCourierId != null && message.hasOwnProperty("externalCourierId"))
                    if (!$util.isString(message.externalCourierId))
                        return "externalCourierId: string expected";
                return null;
            };

            /**
             * Creates a SubCompanyIdToExternalCourierId message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.SubCompanyIdToExternalCourierId
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.SubCompanyIdToExternalCourierId} SubCompanyIdToExternalCourierId
             */
            SubCompanyIdToExternalCourierId.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.SubCompanyIdToExternalCourierId)
                    return object;
                var message = new $root.fastcity.admin.SubCompanyIdToExternalCourierId();
                if (object.courierId != null)
                    if ($util.Long)
                        (message.courierId = $util.Long.fromValue(object.courierId)).unsigned = true;
                    else if (typeof object.courierId === "string")
                        message.courierId = parseInt(object.courierId, 10);
                    else if (typeof object.courierId === "number")
                        message.courierId = object.courierId;
                    else if (typeof object.courierId === "object")
                        message.courierId = new $util.LongBits(object.courierId.low >>> 0, object.courierId.high >>> 0).toNumber(true);
                if (object.subCompanyId != null)
                    if ($util.Long)
                        (message.subCompanyId = $util.Long.fromValue(object.subCompanyId)).unsigned = true;
                    else if (typeof object.subCompanyId === "string")
                        message.subCompanyId = parseInt(object.subCompanyId, 10);
                    else if (typeof object.subCompanyId === "number")
                        message.subCompanyId = object.subCompanyId;
                    else if (typeof object.subCompanyId === "object")
                        message.subCompanyId = new $util.LongBits(object.subCompanyId.low >>> 0, object.subCompanyId.high >>> 0).toNumber(true);
                if (object.externalCourierId != null)
                    message.externalCourierId = String(object.externalCourierId);
                return message;
            };

            /**
             * Creates a plain object from a SubCompanyIdToExternalCourierId message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.SubCompanyIdToExternalCourierId
             * @static
             * @param {fastcity.admin.SubCompanyIdToExternalCourierId} message SubCompanyIdToExternalCourierId
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SubCompanyIdToExternalCourierId.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.courierId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.courierId = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.subCompanyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.subCompanyId = options.longs === String ? "0" : 0;
                    object.externalCourierId = "";
                }
                if (message.courierId != null && message.hasOwnProperty("courierId"))
                    if (typeof message.courierId === "number")
                        object.courierId = options.longs === String ? String(message.courierId) : message.courierId;
                    else
                        object.courierId = options.longs === String ? $util.Long.prototype.toString.call(message.courierId) : options.longs === Number ? new $util.LongBits(message.courierId.low >>> 0, message.courierId.high >>> 0).toNumber(true) : message.courierId;
                if (message.subCompanyId != null && message.hasOwnProperty("subCompanyId"))
                    if (typeof message.subCompanyId === "number")
                        object.subCompanyId = options.longs === String ? String(message.subCompanyId) : message.subCompanyId;
                    else
                        object.subCompanyId = options.longs === String ? $util.Long.prototype.toString.call(message.subCompanyId) : options.longs === Number ? new $util.LongBits(message.subCompanyId.low >>> 0, message.subCompanyId.high >>> 0).toNumber(true) : message.subCompanyId;
                if (message.externalCourierId != null && message.hasOwnProperty("externalCourierId"))
                    object.externalCourierId = message.externalCourierId;
                return object;
            };

            /**
             * Converts this SubCompanyIdToExternalCourierId to JSON.
             * @function toJSON
             * @memberof fastcity.admin.SubCompanyIdToExternalCourierId
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SubCompanyIdToExternalCourierId.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return SubCompanyIdToExternalCourierId;
        })();

        admin.SubCompanyIdToExternalCourierIdList = (function() {

            /**
             * Properties of a SubCompanyIdToExternalCourierIdList.
             * @memberof fastcity.admin
             * @interface ISubCompanyIdToExternalCourierIdList
             * @property {Array.<fastcity.admin.ISubCompanyIdToExternalCourierId>|null} [subCompanyIdToExternalCourierId] SubCompanyIdToExternalCourierIdList subCompanyIdToExternalCourierId
             */

            /**
             * Constructs a new SubCompanyIdToExternalCourierIdList.
             * @memberof fastcity.admin
             * @classdesc Represents a SubCompanyIdToExternalCourierIdList.
             * @implements ISubCompanyIdToExternalCourierIdList
             * @constructor
             * @param {fastcity.admin.ISubCompanyIdToExternalCourierIdList=} [properties] Properties to set
             */
            function SubCompanyIdToExternalCourierIdList(properties) {
                this.subCompanyIdToExternalCourierId = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * SubCompanyIdToExternalCourierIdList subCompanyIdToExternalCourierId.
             * @member {Array.<fastcity.admin.ISubCompanyIdToExternalCourierId>} subCompanyIdToExternalCourierId
             * @memberof fastcity.admin.SubCompanyIdToExternalCourierIdList
             * @instance
             */
            SubCompanyIdToExternalCourierIdList.prototype.subCompanyIdToExternalCourierId = $util.emptyArray;

            /**
             * Creates a new SubCompanyIdToExternalCourierIdList instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.SubCompanyIdToExternalCourierIdList
             * @static
             * @param {fastcity.admin.ISubCompanyIdToExternalCourierIdList=} [properties] Properties to set
             * @returns {fastcity.admin.SubCompanyIdToExternalCourierIdList} SubCompanyIdToExternalCourierIdList instance
             */
            SubCompanyIdToExternalCourierIdList.create = function create(properties) {
                return new SubCompanyIdToExternalCourierIdList(properties);
            };

            /**
             * Encodes the specified SubCompanyIdToExternalCourierIdList message. Does not implicitly {@link fastcity.admin.SubCompanyIdToExternalCourierIdList.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.SubCompanyIdToExternalCourierIdList
             * @static
             * @param {fastcity.admin.ISubCompanyIdToExternalCourierIdList} message SubCompanyIdToExternalCourierIdList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SubCompanyIdToExternalCourierIdList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.subCompanyIdToExternalCourierId != null && message.subCompanyIdToExternalCourierId.length)
                    for (var i = 0; i < message.subCompanyIdToExternalCourierId.length; ++i)
                        $root.fastcity.admin.SubCompanyIdToExternalCourierId.encode(message.subCompanyIdToExternalCourierId[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a SubCompanyIdToExternalCourierIdList message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.SubCompanyIdToExternalCourierIdList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.SubCompanyIdToExternalCourierIdList} SubCompanyIdToExternalCourierIdList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SubCompanyIdToExternalCourierIdList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.SubCompanyIdToExternalCourierIdList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.subCompanyIdToExternalCourierId && message.subCompanyIdToExternalCourierId.length))
                            message.subCompanyIdToExternalCourierId = [];
                        message.subCompanyIdToExternalCourierId.push($root.fastcity.admin.SubCompanyIdToExternalCourierId.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a SubCompanyIdToExternalCourierIdList message.
             * @function verify
             * @memberof fastcity.admin.SubCompanyIdToExternalCourierIdList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SubCompanyIdToExternalCourierIdList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.subCompanyIdToExternalCourierId != null && message.hasOwnProperty("subCompanyIdToExternalCourierId")) {
                    if (!Array.isArray(message.subCompanyIdToExternalCourierId))
                        return "subCompanyIdToExternalCourierId: array expected";
                    for (var i = 0; i < message.subCompanyIdToExternalCourierId.length; ++i) {
                        var error = $root.fastcity.admin.SubCompanyIdToExternalCourierId.verify(message.subCompanyIdToExternalCourierId[i]);
                        if (error)
                            return "subCompanyIdToExternalCourierId." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a SubCompanyIdToExternalCourierIdList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.SubCompanyIdToExternalCourierIdList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.SubCompanyIdToExternalCourierIdList} SubCompanyIdToExternalCourierIdList
             */
            SubCompanyIdToExternalCourierIdList.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.SubCompanyIdToExternalCourierIdList)
                    return object;
                var message = new $root.fastcity.admin.SubCompanyIdToExternalCourierIdList();
                if (object.subCompanyIdToExternalCourierId) {
                    if (!Array.isArray(object.subCompanyIdToExternalCourierId))
                        throw TypeError(".fastcity.admin.SubCompanyIdToExternalCourierIdList.subCompanyIdToExternalCourierId: array expected");
                    message.subCompanyIdToExternalCourierId = [];
                    for (var i = 0; i < object.subCompanyIdToExternalCourierId.length; ++i) {
                        if (typeof object.subCompanyIdToExternalCourierId[i] !== "object")
                            throw TypeError(".fastcity.admin.SubCompanyIdToExternalCourierIdList.subCompanyIdToExternalCourierId: object expected");
                        message.subCompanyIdToExternalCourierId[i] = $root.fastcity.admin.SubCompanyIdToExternalCourierId.fromObject(object.subCompanyIdToExternalCourierId[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a SubCompanyIdToExternalCourierIdList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.SubCompanyIdToExternalCourierIdList
             * @static
             * @param {fastcity.admin.SubCompanyIdToExternalCourierIdList} message SubCompanyIdToExternalCourierIdList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SubCompanyIdToExternalCourierIdList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.subCompanyIdToExternalCourierId = [];
                if (message.subCompanyIdToExternalCourierId && message.subCompanyIdToExternalCourierId.length) {
                    object.subCompanyIdToExternalCourierId = [];
                    for (var j = 0; j < message.subCompanyIdToExternalCourierId.length; ++j)
                        object.subCompanyIdToExternalCourierId[j] = $root.fastcity.admin.SubCompanyIdToExternalCourierId.toObject(message.subCompanyIdToExternalCourierId[j], options);
                }
                return object;
            };

            /**
             * Converts this SubCompanyIdToExternalCourierIdList to JSON.
             * @function toJSON
             * @memberof fastcity.admin.SubCompanyIdToExternalCourierIdList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SubCompanyIdToExternalCourierIdList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return SubCompanyIdToExternalCourierIdList;
        })();

        admin.CreateSubCompanyIdToExternalCourierId = (function() {

            /**
             * Properties of a CreateSubCompanyIdToExternalCourierId.
             * @memberof fastcity.admin
             * @interface ICreateSubCompanyIdToExternalCourierId
             * @property {number|Long|null} [courierId] CreateSubCompanyIdToExternalCourierId courierId
             * @property {number|Long|null} [subCompanyId] CreateSubCompanyIdToExternalCourierId subCompanyId
             * @property {string|null} [externalCourierId] CreateSubCompanyIdToExternalCourierId externalCourierId
             */

            /**
             * Constructs a new CreateSubCompanyIdToExternalCourierId.
             * @memberof fastcity.admin
             * @classdesc Represents a CreateSubCompanyIdToExternalCourierId.
             * @implements ICreateSubCompanyIdToExternalCourierId
             * @constructor
             * @param {fastcity.admin.ICreateSubCompanyIdToExternalCourierId=} [properties] Properties to set
             */
            function CreateSubCompanyIdToExternalCourierId(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CreateSubCompanyIdToExternalCourierId courierId.
             * @member {number|Long} courierId
             * @memberof fastcity.admin.CreateSubCompanyIdToExternalCourierId
             * @instance
             */
            CreateSubCompanyIdToExternalCourierId.prototype.courierId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * CreateSubCompanyIdToExternalCourierId subCompanyId.
             * @member {number|Long} subCompanyId
             * @memberof fastcity.admin.CreateSubCompanyIdToExternalCourierId
             * @instance
             */
            CreateSubCompanyIdToExternalCourierId.prototype.subCompanyId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * CreateSubCompanyIdToExternalCourierId externalCourierId.
             * @member {string} externalCourierId
             * @memberof fastcity.admin.CreateSubCompanyIdToExternalCourierId
             * @instance
             */
            CreateSubCompanyIdToExternalCourierId.prototype.externalCourierId = "";

            /**
             * Creates a new CreateSubCompanyIdToExternalCourierId instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.CreateSubCompanyIdToExternalCourierId
             * @static
             * @param {fastcity.admin.ICreateSubCompanyIdToExternalCourierId=} [properties] Properties to set
             * @returns {fastcity.admin.CreateSubCompanyIdToExternalCourierId} CreateSubCompanyIdToExternalCourierId instance
             */
            CreateSubCompanyIdToExternalCourierId.create = function create(properties) {
                return new CreateSubCompanyIdToExternalCourierId(properties);
            };

            /**
             * Encodes the specified CreateSubCompanyIdToExternalCourierId message. Does not implicitly {@link fastcity.admin.CreateSubCompanyIdToExternalCourierId.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.CreateSubCompanyIdToExternalCourierId
             * @static
             * @param {fastcity.admin.ICreateSubCompanyIdToExternalCourierId} message CreateSubCompanyIdToExternalCourierId message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateSubCompanyIdToExternalCourierId.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.courierId != null && Object.hasOwnProperty.call(message, "courierId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.courierId);
                if (message.subCompanyId != null && Object.hasOwnProperty.call(message, "subCompanyId"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.subCompanyId);
                if (message.externalCourierId != null && Object.hasOwnProperty.call(message, "externalCourierId"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.externalCourierId);
                return writer;
            };

            /**
             * Decodes a CreateSubCompanyIdToExternalCourierId message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.CreateSubCompanyIdToExternalCourierId
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.CreateSubCompanyIdToExternalCourierId} CreateSubCompanyIdToExternalCourierId
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateSubCompanyIdToExternalCourierId.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.CreateSubCompanyIdToExternalCourierId();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.courierId = reader.uint64();
                        break;
                    case 2:
                        message.subCompanyId = reader.uint64();
                        break;
                    case 3:
                        message.externalCourierId = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a CreateSubCompanyIdToExternalCourierId message.
             * @function verify
             * @memberof fastcity.admin.CreateSubCompanyIdToExternalCourierId
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CreateSubCompanyIdToExternalCourierId.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.courierId != null && message.hasOwnProperty("courierId"))
                    if (!$util.isInteger(message.courierId) && !(message.courierId && $util.isInteger(message.courierId.low) && $util.isInteger(message.courierId.high)))
                        return "courierId: integer|Long expected";
                if (message.subCompanyId != null && message.hasOwnProperty("subCompanyId"))
                    if (!$util.isInteger(message.subCompanyId) && !(message.subCompanyId && $util.isInteger(message.subCompanyId.low) && $util.isInteger(message.subCompanyId.high)))
                        return "subCompanyId: integer|Long expected";
                if (message.externalCourierId != null && message.hasOwnProperty("externalCourierId"))
                    if (!$util.isString(message.externalCourierId))
                        return "externalCourierId: string expected";
                return null;
            };

            /**
             * Creates a CreateSubCompanyIdToExternalCourierId message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.CreateSubCompanyIdToExternalCourierId
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.CreateSubCompanyIdToExternalCourierId} CreateSubCompanyIdToExternalCourierId
             */
            CreateSubCompanyIdToExternalCourierId.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.CreateSubCompanyIdToExternalCourierId)
                    return object;
                var message = new $root.fastcity.admin.CreateSubCompanyIdToExternalCourierId();
                if (object.courierId != null)
                    if ($util.Long)
                        (message.courierId = $util.Long.fromValue(object.courierId)).unsigned = true;
                    else if (typeof object.courierId === "string")
                        message.courierId = parseInt(object.courierId, 10);
                    else if (typeof object.courierId === "number")
                        message.courierId = object.courierId;
                    else if (typeof object.courierId === "object")
                        message.courierId = new $util.LongBits(object.courierId.low >>> 0, object.courierId.high >>> 0).toNumber(true);
                if (object.subCompanyId != null)
                    if ($util.Long)
                        (message.subCompanyId = $util.Long.fromValue(object.subCompanyId)).unsigned = true;
                    else if (typeof object.subCompanyId === "string")
                        message.subCompanyId = parseInt(object.subCompanyId, 10);
                    else if (typeof object.subCompanyId === "number")
                        message.subCompanyId = object.subCompanyId;
                    else if (typeof object.subCompanyId === "object")
                        message.subCompanyId = new $util.LongBits(object.subCompanyId.low >>> 0, object.subCompanyId.high >>> 0).toNumber(true);
                if (object.externalCourierId != null)
                    message.externalCourierId = String(object.externalCourierId);
                return message;
            };

            /**
             * Creates a plain object from a CreateSubCompanyIdToExternalCourierId message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.CreateSubCompanyIdToExternalCourierId
             * @static
             * @param {fastcity.admin.CreateSubCompanyIdToExternalCourierId} message CreateSubCompanyIdToExternalCourierId
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateSubCompanyIdToExternalCourierId.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.courierId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.courierId = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.subCompanyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.subCompanyId = options.longs === String ? "0" : 0;
                    object.externalCourierId = "";
                }
                if (message.courierId != null && message.hasOwnProperty("courierId"))
                    if (typeof message.courierId === "number")
                        object.courierId = options.longs === String ? String(message.courierId) : message.courierId;
                    else
                        object.courierId = options.longs === String ? $util.Long.prototype.toString.call(message.courierId) : options.longs === Number ? new $util.LongBits(message.courierId.low >>> 0, message.courierId.high >>> 0).toNumber(true) : message.courierId;
                if (message.subCompanyId != null && message.hasOwnProperty("subCompanyId"))
                    if (typeof message.subCompanyId === "number")
                        object.subCompanyId = options.longs === String ? String(message.subCompanyId) : message.subCompanyId;
                    else
                        object.subCompanyId = options.longs === String ? $util.Long.prototype.toString.call(message.subCompanyId) : options.longs === Number ? new $util.LongBits(message.subCompanyId.low >>> 0, message.subCompanyId.high >>> 0).toNumber(true) : message.subCompanyId;
                if (message.externalCourierId != null && message.hasOwnProperty("externalCourierId"))
                    object.externalCourierId = message.externalCourierId;
                return object;
            };

            /**
             * Converts this CreateSubCompanyIdToExternalCourierId to JSON.
             * @function toJSON
             * @memberof fastcity.admin.CreateSubCompanyIdToExternalCourierId
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateSubCompanyIdToExternalCourierId.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return CreateSubCompanyIdToExternalCourierId;
        })();

        admin.UpdateSubCompanyIdToExternalCourierId = (function() {

            /**
             * Properties of an UpdateSubCompanyIdToExternalCourierId.
             * @memberof fastcity.admin
             * @interface IUpdateSubCompanyIdToExternalCourierId
             * @property {number|Long|null} [courierId] UpdateSubCompanyIdToExternalCourierId courierId
             * @property {number|Long|null} [subCompanyId] UpdateSubCompanyIdToExternalCourierId subCompanyId
             * @property {string|null} [externalCourierId] UpdateSubCompanyIdToExternalCourierId externalCourierId
             */

            /**
             * Constructs a new UpdateSubCompanyIdToExternalCourierId.
             * @memberof fastcity.admin
             * @classdesc Represents an UpdateSubCompanyIdToExternalCourierId.
             * @implements IUpdateSubCompanyIdToExternalCourierId
             * @constructor
             * @param {fastcity.admin.IUpdateSubCompanyIdToExternalCourierId=} [properties] Properties to set
             */
            function UpdateSubCompanyIdToExternalCourierId(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * UpdateSubCompanyIdToExternalCourierId courierId.
             * @member {number|Long} courierId
             * @memberof fastcity.admin.UpdateSubCompanyIdToExternalCourierId
             * @instance
             */
            UpdateSubCompanyIdToExternalCourierId.prototype.courierId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * UpdateSubCompanyIdToExternalCourierId subCompanyId.
             * @member {number|Long} subCompanyId
             * @memberof fastcity.admin.UpdateSubCompanyIdToExternalCourierId
             * @instance
             */
            UpdateSubCompanyIdToExternalCourierId.prototype.subCompanyId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * UpdateSubCompanyIdToExternalCourierId externalCourierId.
             * @member {string} externalCourierId
             * @memberof fastcity.admin.UpdateSubCompanyIdToExternalCourierId
             * @instance
             */
            UpdateSubCompanyIdToExternalCourierId.prototype.externalCourierId = "";

            /**
             * Creates a new UpdateSubCompanyIdToExternalCourierId instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.UpdateSubCompanyIdToExternalCourierId
             * @static
             * @param {fastcity.admin.IUpdateSubCompanyIdToExternalCourierId=} [properties] Properties to set
             * @returns {fastcity.admin.UpdateSubCompanyIdToExternalCourierId} UpdateSubCompanyIdToExternalCourierId instance
             */
            UpdateSubCompanyIdToExternalCourierId.create = function create(properties) {
                return new UpdateSubCompanyIdToExternalCourierId(properties);
            };

            /**
             * Encodes the specified UpdateSubCompanyIdToExternalCourierId message. Does not implicitly {@link fastcity.admin.UpdateSubCompanyIdToExternalCourierId.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.UpdateSubCompanyIdToExternalCourierId
             * @static
             * @param {fastcity.admin.IUpdateSubCompanyIdToExternalCourierId} message UpdateSubCompanyIdToExternalCourierId message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateSubCompanyIdToExternalCourierId.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.courierId != null && Object.hasOwnProperty.call(message, "courierId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.courierId);
                if (message.subCompanyId != null && Object.hasOwnProperty.call(message, "subCompanyId"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.subCompanyId);
                if (message.externalCourierId != null && Object.hasOwnProperty.call(message, "externalCourierId"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.externalCourierId);
                return writer;
            };

            /**
             * Decodes an UpdateSubCompanyIdToExternalCourierId message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.UpdateSubCompanyIdToExternalCourierId
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.UpdateSubCompanyIdToExternalCourierId} UpdateSubCompanyIdToExternalCourierId
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateSubCompanyIdToExternalCourierId.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.UpdateSubCompanyIdToExternalCourierId();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.courierId = reader.uint64();
                        break;
                    case 2:
                        message.subCompanyId = reader.uint64();
                        break;
                    case 3:
                        message.externalCourierId = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an UpdateSubCompanyIdToExternalCourierId message.
             * @function verify
             * @memberof fastcity.admin.UpdateSubCompanyIdToExternalCourierId
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            UpdateSubCompanyIdToExternalCourierId.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.courierId != null && message.hasOwnProperty("courierId"))
                    if (!$util.isInteger(message.courierId) && !(message.courierId && $util.isInteger(message.courierId.low) && $util.isInteger(message.courierId.high)))
                        return "courierId: integer|Long expected";
                if (message.subCompanyId != null && message.hasOwnProperty("subCompanyId"))
                    if (!$util.isInteger(message.subCompanyId) && !(message.subCompanyId && $util.isInteger(message.subCompanyId.low) && $util.isInteger(message.subCompanyId.high)))
                        return "subCompanyId: integer|Long expected";
                if (message.externalCourierId != null && message.hasOwnProperty("externalCourierId"))
                    if (!$util.isString(message.externalCourierId))
                        return "externalCourierId: string expected";
                return null;
            };

            /**
             * Creates an UpdateSubCompanyIdToExternalCourierId message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.UpdateSubCompanyIdToExternalCourierId
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.UpdateSubCompanyIdToExternalCourierId} UpdateSubCompanyIdToExternalCourierId
             */
            UpdateSubCompanyIdToExternalCourierId.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.UpdateSubCompanyIdToExternalCourierId)
                    return object;
                var message = new $root.fastcity.admin.UpdateSubCompanyIdToExternalCourierId();
                if (object.courierId != null)
                    if ($util.Long)
                        (message.courierId = $util.Long.fromValue(object.courierId)).unsigned = true;
                    else if (typeof object.courierId === "string")
                        message.courierId = parseInt(object.courierId, 10);
                    else if (typeof object.courierId === "number")
                        message.courierId = object.courierId;
                    else if (typeof object.courierId === "object")
                        message.courierId = new $util.LongBits(object.courierId.low >>> 0, object.courierId.high >>> 0).toNumber(true);
                if (object.subCompanyId != null)
                    if ($util.Long)
                        (message.subCompanyId = $util.Long.fromValue(object.subCompanyId)).unsigned = true;
                    else if (typeof object.subCompanyId === "string")
                        message.subCompanyId = parseInt(object.subCompanyId, 10);
                    else if (typeof object.subCompanyId === "number")
                        message.subCompanyId = object.subCompanyId;
                    else if (typeof object.subCompanyId === "object")
                        message.subCompanyId = new $util.LongBits(object.subCompanyId.low >>> 0, object.subCompanyId.high >>> 0).toNumber(true);
                if (object.externalCourierId != null)
                    message.externalCourierId = String(object.externalCourierId);
                return message;
            };

            /**
             * Creates a plain object from an UpdateSubCompanyIdToExternalCourierId message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.UpdateSubCompanyIdToExternalCourierId
             * @static
             * @param {fastcity.admin.UpdateSubCompanyIdToExternalCourierId} message UpdateSubCompanyIdToExternalCourierId
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UpdateSubCompanyIdToExternalCourierId.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.courierId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.courierId = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.subCompanyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.subCompanyId = options.longs === String ? "0" : 0;
                    object.externalCourierId = "";
                }
                if (message.courierId != null && message.hasOwnProperty("courierId"))
                    if (typeof message.courierId === "number")
                        object.courierId = options.longs === String ? String(message.courierId) : message.courierId;
                    else
                        object.courierId = options.longs === String ? $util.Long.prototype.toString.call(message.courierId) : options.longs === Number ? new $util.LongBits(message.courierId.low >>> 0, message.courierId.high >>> 0).toNumber(true) : message.courierId;
                if (message.subCompanyId != null && message.hasOwnProperty("subCompanyId"))
                    if (typeof message.subCompanyId === "number")
                        object.subCompanyId = options.longs === String ? String(message.subCompanyId) : message.subCompanyId;
                    else
                        object.subCompanyId = options.longs === String ? $util.Long.prototype.toString.call(message.subCompanyId) : options.longs === Number ? new $util.LongBits(message.subCompanyId.low >>> 0, message.subCompanyId.high >>> 0).toNumber(true) : message.subCompanyId;
                if (message.externalCourierId != null && message.hasOwnProperty("externalCourierId"))
                    object.externalCourierId = message.externalCourierId;
                return object;
            };

            /**
             * Converts this UpdateSubCompanyIdToExternalCourierId to JSON.
             * @function toJSON
             * @memberof fastcity.admin.UpdateSubCompanyIdToExternalCourierId
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UpdateSubCompanyIdToExternalCourierId.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return UpdateSubCompanyIdToExternalCourierId;
        })();

        admin.GetSubCompanyIdToExternalCourierIdList = (function() {

            /**
             * Properties of a GetSubCompanyIdToExternalCourierIdList.
             * @memberof fastcity.admin
             * @interface IGetSubCompanyIdToExternalCourierIdList
             * @property {Array.<number|Long>|null} [courierId] GetSubCompanyIdToExternalCourierIdList courierId
             */

            /**
             * Constructs a new GetSubCompanyIdToExternalCourierIdList.
             * @memberof fastcity.admin
             * @classdesc Represents a GetSubCompanyIdToExternalCourierIdList.
             * @implements IGetSubCompanyIdToExternalCourierIdList
             * @constructor
             * @param {fastcity.admin.IGetSubCompanyIdToExternalCourierIdList=} [properties] Properties to set
             */
            function GetSubCompanyIdToExternalCourierIdList(properties) {
                this.courierId = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GetSubCompanyIdToExternalCourierIdList courierId.
             * @member {Array.<number|Long>} courierId
             * @memberof fastcity.admin.GetSubCompanyIdToExternalCourierIdList
             * @instance
             */
            GetSubCompanyIdToExternalCourierIdList.prototype.courierId = $util.emptyArray;

            /**
             * Creates a new GetSubCompanyIdToExternalCourierIdList instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.GetSubCompanyIdToExternalCourierIdList
             * @static
             * @param {fastcity.admin.IGetSubCompanyIdToExternalCourierIdList=} [properties] Properties to set
             * @returns {fastcity.admin.GetSubCompanyIdToExternalCourierIdList} GetSubCompanyIdToExternalCourierIdList instance
             */
            GetSubCompanyIdToExternalCourierIdList.create = function create(properties) {
                return new GetSubCompanyIdToExternalCourierIdList(properties);
            };

            /**
             * Encodes the specified GetSubCompanyIdToExternalCourierIdList message. Does not implicitly {@link fastcity.admin.GetSubCompanyIdToExternalCourierIdList.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.GetSubCompanyIdToExternalCourierIdList
             * @static
             * @param {fastcity.admin.IGetSubCompanyIdToExternalCourierIdList} message GetSubCompanyIdToExternalCourierIdList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetSubCompanyIdToExternalCourierIdList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.courierId != null && message.courierId.length) {
                    writer.uint32(/* id 1, wireType 2 =*/10).fork();
                    for (var i = 0; i < message.courierId.length; ++i)
                        writer.uint64(message.courierId[i]);
                    writer.ldelim();
                }
                return writer;
            };

            /**
             * Decodes a GetSubCompanyIdToExternalCourierIdList message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.GetSubCompanyIdToExternalCourierIdList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.GetSubCompanyIdToExternalCourierIdList} GetSubCompanyIdToExternalCourierIdList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetSubCompanyIdToExternalCourierIdList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.GetSubCompanyIdToExternalCourierIdList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.courierId && message.courierId.length))
                            message.courierId = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.courierId.push(reader.uint64());
                        } else
                            message.courierId.push(reader.uint64());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a GetSubCompanyIdToExternalCourierIdList message.
             * @function verify
             * @memberof fastcity.admin.GetSubCompanyIdToExternalCourierIdList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetSubCompanyIdToExternalCourierIdList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.courierId != null && message.hasOwnProperty("courierId")) {
                    if (!Array.isArray(message.courierId))
                        return "courierId: array expected";
                    for (var i = 0; i < message.courierId.length; ++i)
                        if (!$util.isInteger(message.courierId[i]) && !(message.courierId[i] && $util.isInteger(message.courierId[i].low) && $util.isInteger(message.courierId[i].high)))
                            return "courierId: integer|Long[] expected";
                }
                return null;
            };

            /**
             * Creates a GetSubCompanyIdToExternalCourierIdList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.GetSubCompanyIdToExternalCourierIdList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.GetSubCompanyIdToExternalCourierIdList} GetSubCompanyIdToExternalCourierIdList
             */
            GetSubCompanyIdToExternalCourierIdList.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.GetSubCompanyIdToExternalCourierIdList)
                    return object;
                var message = new $root.fastcity.admin.GetSubCompanyIdToExternalCourierIdList();
                if (object.courierId) {
                    if (!Array.isArray(object.courierId))
                        throw TypeError(".fastcity.admin.GetSubCompanyIdToExternalCourierIdList.courierId: array expected");
                    message.courierId = [];
                    for (var i = 0; i < object.courierId.length; ++i)
                        if ($util.Long)
                            (message.courierId[i] = $util.Long.fromValue(object.courierId[i])).unsigned = true;
                        else if (typeof object.courierId[i] === "string")
                            message.courierId[i] = parseInt(object.courierId[i], 10);
                        else if (typeof object.courierId[i] === "number")
                            message.courierId[i] = object.courierId[i];
                        else if (typeof object.courierId[i] === "object")
                            message.courierId[i] = new $util.LongBits(object.courierId[i].low >>> 0, object.courierId[i].high >>> 0).toNumber(true);
                }
                return message;
            };

            /**
             * Creates a plain object from a GetSubCompanyIdToExternalCourierIdList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.GetSubCompanyIdToExternalCourierIdList
             * @static
             * @param {fastcity.admin.GetSubCompanyIdToExternalCourierIdList} message GetSubCompanyIdToExternalCourierIdList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetSubCompanyIdToExternalCourierIdList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.courierId = [];
                if (message.courierId && message.courierId.length) {
                    object.courierId = [];
                    for (var j = 0; j < message.courierId.length; ++j)
                        if (typeof message.courierId[j] === "number")
                            object.courierId[j] = options.longs === String ? String(message.courierId[j]) : message.courierId[j];
                        else
                            object.courierId[j] = options.longs === String ? $util.Long.prototype.toString.call(message.courierId[j]) : options.longs === Number ? new $util.LongBits(message.courierId[j].low >>> 0, message.courierId[j].high >>> 0).toNumber(true) : message.courierId[j];
                }
                return object;
            };

            /**
             * Converts this GetSubCompanyIdToExternalCourierIdList to JSON.
             * @function toJSON
             * @memberof fastcity.admin.GetSubCompanyIdToExternalCourierIdList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetSubCompanyIdToExternalCourierIdList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return GetSubCompanyIdToExternalCourierIdList;
        })();

        admin.RemoveSubCompanyIdToExternalCourierId = (function() {

            /**
             * Properties of a RemoveSubCompanyIdToExternalCourierId.
             * @memberof fastcity.admin
             * @interface IRemoveSubCompanyIdToExternalCourierId
             * @property {number|Long|null} [courierId] RemoveSubCompanyIdToExternalCourierId courierId
             * @property {number|Long|null} [subCompanyId] RemoveSubCompanyIdToExternalCourierId subCompanyId
             */

            /**
             * Constructs a new RemoveSubCompanyIdToExternalCourierId.
             * @memberof fastcity.admin
             * @classdesc Represents a RemoveSubCompanyIdToExternalCourierId.
             * @implements IRemoveSubCompanyIdToExternalCourierId
             * @constructor
             * @param {fastcity.admin.IRemoveSubCompanyIdToExternalCourierId=} [properties] Properties to set
             */
            function RemoveSubCompanyIdToExternalCourierId(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * RemoveSubCompanyIdToExternalCourierId courierId.
             * @member {number|Long} courierId
             * @memberof fastcity.admin.RemoveSubCompanyIdToExternalCourierId
             * @instance
             */
            RemoveSubCompanyIdToExternalCourierId.prototype.courierId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * RemoveSubCompanyIdToExternalCourierId subCompanyId.
             * @member {number|Long} subCompanyId
             * @memberof fastcity.admin.RemoveSubCompanyIdToExternalCourierId
             * @instance
             */
            RemoveSubCompanyIdToExternalCourierId.prototype.subCompanyId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Creates a new RemoveSubCompanyIdToExternalCourierId instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.RemoveSubCompanyIdToExternalCourierId
             * @static
             * @param {fastcity.admin.IRemoveSubCompanyIdToExternalCourierId=} [properties] Properties to set
             * @returns {fastcity.admin.RemoveSubCompanyIdToExternalCourierId} RemoveSubCompanyIdToExternalCourierId instance
             */
            RemoveSubCompanyIdToExternalCourierId.create = function create(properties) {
                return new RemoveSubCompanyIdToExternalCourierId(properties);
            };

            /**
             * Encodes the specified RemoveSubCompanyIdToExternalCourierId message. Does not implicitly {@link fastcity.admin.RemoveSubCompanyIdToExternalCourierId.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.RemoveSubCompanyIdToExternalCourierId
             * @static
             * @param {fastcity.admin.IRemoveSubCompanyIdToExternalCourierId} message RemoveSubCompanyIdToExternalCourierId message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RemoveSubCompanyIdToExternalCourierId.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.courierId != null && Object.hasOwnProperty.call(message, "courierId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.courierId);
                if (message.subCompanyId != null && Object.hasOwnProperty.call(message, "subCompanyId"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.subCompanyId);
                return writer;
            };

            /**
             * Decodes a RemoveSubCompanyIdToExternalCourierId message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.RemoveSubCompanyIdToExternalCourierId
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.RemoveSubCompanyIdToExternalCourierId} RemoveSubCompanyIdToExternalCourierId
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RemoveSubCompanyIdToExternalCourierId.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.RemoveSubCompanyIdToExternalCourierId();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.courierId = reader.uint64();
                        break;
                    case 2:
                        message.subCompanyId = reader.uint64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a RemoveSubCompanyIdToExternalCourierId message.
             * @function verify
             * @memberof fastcity.admin.RemoveSubCompanyIdToExternalCourierId
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            RemoveSubCompanyIdToExternalCourierId.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.courierId != null && message.hasOwnProperty("courierId"))
                    if (!$util.isInteger(message.courierId) && !(message.courierId && $util.isInteger(message.courierId.low) && $util.isInteger(message.courierId.high)))
                        return "courierId: integer|Long expected";
                if (message.subCompanyId != null && message.hasOwnProperty("subCompanyId"))
                    if (!$util.isInteger(message.subCompanyId) && !(message.subCompanyId && $util.isInteger(message.subCompanyId.low) && $util.isInteger(message.subCompanyId.high)))
                        return "subCompanyId: integer|Long expected";
                return null;
            };

            /**
             * Creates a RemoveSubCompanyIdToExternalCourierId message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.RemoveSubCompanyIdToExternalCourierId
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.RemoveSubCompanyIdToExternalCourierId} RemoveSubCompanyIdToExternalCourierId
             */
            RemoveSubCompanyIdToExternalCourierId.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.RemoveSubCompanyIdToExternalCourierId)
                    return object;
                var message = new $root.fastcity.admin.RemoveSubCompanyIdToExternalCourierId();
                if (object.courierId != null)
                    if ($util.Long)
                        (message.courierId = $util.Long.fromValue(object.courierId)).unsigned = true;
                    else if (typeof object.courierId === "string")
                        message.courierId = parseInt(object.courierId, 10);
                    else if (typeof object.courierId === "number")
                        message.courierId = object.courierId;
                    else if (typeof object.courierId === "object")
                        message.courierId = new $util.LongBits(object.courierId.low >>> 0, object.courierId.high >>> 0).toNumber(true);
                if (object.subCompanyId != null)
                    if ($util.Long)
                        (message.subCompanyId = $util.Long.fromValue(object.subCompanyId)).unsigned = true;
                    else if (typeof object.subCompanyId === "string")
                        message.subCompanyId = parseInt(object.subCompanyId, 10);
                    else if (typeof object.subCompanyId === "number")
                        message.subCompanyId = object.subCompanyId;
                    else if (typeof object.subCompanyId === "object")
                        message.subCompanyId = new $util.LongBits(object.subCompanyId.low >>> 0, object.subCompanyId.high >>> 0).toNumber(true);
                return message;
            };

            /**
             * Creates a plain object from a RemoveSubCompanyIdToExternalCourierId message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.RemoveSubCompanyIdToExternalCourierId
             * @static
             * @param {fastcity.admin.RemoveSubCompanyIdToExternalCourierId} message RemoveSubCompanyIdToExternalCourierId
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            RemoveSubCompanyIdToExternalCourierId.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.courierId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.courierId = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.subCompanyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.subCompanyId = options.longs === String ? "0" : 0;
                }
                if (message.courierId != null && message.hasOwnProperty("courierId"))
                    if (typeof message.courierId === "number")
                        object.courierId = options.longs === String ? String(message.courierId) : message.courierId;
                    else
                        object.courierId = options.longs === String ? $util.Long.prototype.toString.call(message.courierId) : options.longs === Number ? new $util.LongBits(message.courierId.low >>> 0, message.courierId.high >>> 0).toNumber(true) : message.courierId;
                if (message.subCompanyId != null && message.hasOwnProperty("subCompanyId"))
                    if (typeof message.subCompanyId === "number")
                        object.subCompanyId = options.longs === String ? String(message.subCompanyId) : message.subCompanyId;
                    else
                        object.subCompanyId = options.longs === String ? $util.Long.prototype.toString.call(message.subCompanyId) : options.longs === Number ? new $util.LongBits(message.subCompanyId.low >>> 0, message.subCompanyId.high >>> 0).toNumber(true) : message.subCompanyId;
                return object;
            };

            /**
             * Converts this RemoveSubCompanyIdToExternalCourierId to JSON.
             * @function toJSON
             * @memberof fastcity.admin.RemoveSubCompanyIdToExternalCourierId
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            RemoveSubCompanyIdToExternalCourierId.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return RemoveSubCompanyIdToExternalCourierId;
        })();

        /**
         * DeliveryStatRequestResult enum.
         * @name fastcity.admin.DeliveryStatRequestResult
         * @enum {number}
         * @property {number} DSRR_COURIER_NOT_FOUND=0 DSRR_COURIER_NOT_FOUND value
         */
        admin.DeliveryStatRequestResult = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "DSRR_COURIER_NOT_FOUND"] = 0;
            return values;
        })();

        admin.DeliveryStatDay = (function() {

            /**
             * Properties of a DeliveryStatDay.
             * @memberof fastcity.admin
             * @interface IDeliveryStatDay
             * @property {number|Long|null} [courierId] DeliveryStatDay courierId
             * @property {number|Long|null} [timestampWorkingDay] DeliveryStatDay timestampWorkingDay
             * @property {number|null} [numberDelivered] DeliveryStatDay numberDelivered
             * @property {number|null} [numberDeliveredWithoutDelay] DeliveryStatDay numberDeliveredWithoutDelay
             * @property {number|null} [numberDeliveredWithDelay] DeliveryStatDay numberDeliveredWithDelay
             * @property {number|null} [averageDeliveryTime] DeliveryStatDay averageDeliveryTime
             */

            /**
             * Constructs a new DeliveryStatDay.
             * @memberof fastcity.admin
             * @classdesc Represents a DeliveryStatDay.
             * @implements IDeliveryStatDay
             * @constructor
             * @param {fastcity.admin.IDeliveryStatDay=} [properties] Properties to set
             */
            function DeliveryStatDay(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * DeliveryStatDay courierId.
             * @member {number|Long} courierId
             * @memberof fastcity.admin.DeliveryStatDay
             * @instance
             */
            DeliveryStatDay.prototype.courierId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * DeliveryStatDay timestampWorkingDay.
             * @member {number|Long} timestampWorkingDay
             * @memberof fastcity.admin.DeliveryStatDay
             * @instance
             */
            DeliveryStatDay.prototype.timestampWorkingDay = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * DeliveryStatDay numberDelivered.
             * @member {number} numberDelivered
             * @memberof fastcity.admin.DeliveryStatDay
             * @instance
             */
            DeliveryStatDay.prototype.numberDelivered = 0;

            /**
             * DeliveryStatDay numberDeliveredWithoutDelay.
             * @member {number} numberDeliveredWithoutDelay
             * @memberof fastcity.admin.DeliveryStatDay
             * @instance
             */
            DeliveryStatDay.prototype.numberDeliveredWithoutDelay = 0;

            /**
             * DeliveryStatDay numberDeliveredWithDelay.
             * @member {number} numberDeliveredWithDelay
             * @memberof fastcity.admin.DeliveryStatDay
             * @instance
             */
            DeliveryStatDay.prototype.numberDeliveredWithDelay = 0;

            /**
             * DeliveryStatDay averageDeliveryTime.
             * @member {number} averageDeliveryTime
             * @memberof fastcity.admin.DeliveryStatDay
             * @instance
             */
            DeliveryStatDay.prototype.averageDeliveryTime = 0;

            /**
             * Creates a new DeliveryStatDay instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.DeliveryStatDay
             * @static
             * @param {fastcity.admin.IDeliveryStatDay=} [properties] Properties to set
             * @returns {fastcity.admin.DeliveryStatDay} DeliveryStatDay instance
             */
            DeliveryStatDay.create = function create(properties) {
                return new DeliveryStatDay(properties);
            };

            /**
             * Encodes the specified DeliveryStatDay message. Does not implicitly {@link fastcity.admin.DeliveryStatDay.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.DeliveryStatDay
             * @static
             * @param {fastcity.admin.IDeliveryStatDay} message DeliveryStatDay message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeliveryStatDay.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.courierId != null && Object.hasOwnProperty.call(message, "courierId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.courierId);
                if (message.timestampWorkingDay != null && Object.hasOwnProperty.call(message, "timestampWorkingDay"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int64(message.timestampWorkingDay);
                if (message.numberDelivered != null && Object.hasOwnProperty.call(message, "numberDelivered"))
                    writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.numberDelivered);
                if (message.numberDeliveredWithoutDelay != null && Object.hasOwnProperty.call(message, "numberDeliveredWithoutDelay"))
                    writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.numberDeliveredWithoutDelay);
                if (message.numberDeliveredWithDelay != null && Object.hasOwnProperty.call(message, "numberDeliveredWithDelay"))
                    writer.uint32(/* id 5, wireType 0 =*/40).uint32(message.numberDeliveredWithDelay);
                if (message.averageDeliveryTime != null && Object.hasOwnProperty.call(message, "averageDeliveryTime"))
                    writer.uint32(/* id 6, wireType 0 =*/48).uint32(message.averageDeliveryTime);
                return writer;
            };

            /**
             * Decodes a DeliveryStatDay message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.DeliveryStatDay
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.DeliveryStatDay} DeliveryStatDay
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeliveryStatDay.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.DeliveryStatDay();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.courierId = reader.uint64();
                        break;
                    case 2:
                        message.timestampWorkingDay = reader.int64();
                        break;
                    case 3:
                        message.numberDelivered = reader.uint32();
                        break;
                    case 4:
                        message.numberDeliveredWithoutDelay = reader.uint32();
                        break;
                    case 5:
                        message.numberDeliveredWithDelay = reader.uint32();
                        break;
                    case 6:
                        message.averageDeliveryTime = reader.uint32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a DeliveryStatDay message.
             * @function verify
             * @memberof fastcity.admin.DeliveryStatDay
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DeliveryStatDay.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.courierId != null && message.hasOwnProperty("courierId"))
                    if (!$util.isInteger(message.courierId) && !(message.courierId && $util.isInteger(message.courierId.low) && $util.isInteger(message.courierId.high)))
                        return "courierId: integer|Long expected";
                if (message.timestampWorkingDay != null && message.hasOwnProperty("timestampWorkingDay"))
                    if (!$util.isInteger(message.timestampWorkingDay) && !(message.timestampWorkingDay && $util.isInteger(message.timestampWorkingDay.low) && $util.isInteger(message.timestampWorkingDay.high)))
                        return "timestampWorkingDay: integer|Long expected";
                if (message.numberDelivered != null && message.hasOwnProperty("numberDelivered"))
                    if (!$util.isInteger(message.numberDelivered))
                        return "numberDelivered: integer expected";
                if (message.numberDeliveredWithoutDelay != null && message.hasOwnProperty("numberDeliveredWithoutDelay"))
                    if (!$util.isInteger(message.numberDeliveredWithoutDelay))
                        return "numberDeliveredWithoutDelay: integer expected";
                if (message.numberDeliveredWithDelay != null && message.hasOwnProperty("numberDeliveredWithDelay"))
                    if (!$util.isInteger(message.numberDeliveredWithDelay))
                        return "numberDeliveredWithDelay: integer expected";
                if (message.averageDeliveryTime != null && message.hasOwnProperty("averageDeliveryTime"))
                    if (!$util.isInteger(message.averageDeliveryTime))
                        return "averageDeliveryTime: integer expected";
                return null;
            };

            /**
             * Creates a DeliveryStatDay message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.DeliveryStatDay
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.DeliveryStatDay} DeliveryStatDay
             */
            DeliveryStatDay.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.DeliveryStatDay)
                    return object;
                var message = new $root.fastcity.admin.DeliveryStatDay();
                if (object.courierId != null)
                    if ($util.Long)
                        (message.courierId = $util.Long.fromValue(object.courierId)).unsigned = true;
                    else if (typeof object.courierId === "string")
                        message.courierId = parseInt(object.courierId, 10);
                    else if (typeof object.courierId === "number")
                        message.courierId = object.courierId;
                    else if (typeof object.courierId === "object")
                        message.courierId = new $util.LongBits(object.courierId.low >>> 0, object.courierId.high >>> 0).toNumber(true);
                if (object.timestampWorkingDay != null)
                    if ($util.Long)
                        (message.timestampWorkingDay = $util.Long.fromValue(object.timestampWorkingDay)).unsigned = false;
                    else if (typeof object.timestampWorkingDay === "string")
                        message.timestampWorkingDay = parseInt(object.timestampWorkingDay, 10);
                    else if (typeof object.timestampWorkingDay === "number")
                        message.timestampWorkingDay = object.timestampWorkingDay;
                    else if (typeof object.timestampWorkingDay === "object")
                        message.timestampWorkingDay = new $util.LongBits(object.timestampWorkingDay.low >>> 0, object.timestampWorkingDay.high >>> 0).toNumber();
                if (object.numberDelivered != null)
                    message.numberDelivered = object.numberDelivered >>> 0;
                if (object.numberDeliveredWithoutDelay != null)
                    message.numberDeliveredWithoutDelay = object.numberDeliveredWithoutDelay >>> 0;
                if (object.numberDeliveredWithDelay != null)
                    message.numberDeliveredWithDelay = object.numberDeliveredWithDelay >>> 0;
                if (object.averageDeliveryTime != null)
                    message.averageDeliveryTime = object.averageDeliveryTime >>> 0;
                return message;
            };

            /**
             * Creates a plain object from a DeliveryStatDay message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.DeliveryStatDay
             * @static
             * @param {fastcity.admin.DeliveryStatDay} message DeliveryStatDay
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DeliveryStatDay.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.courierId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.courierId = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.timestampWorkingDay = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.timestampWorkingDay = options.longs === String ? "0" : 0;
                    object.numberDelivered = 0;
                    object.numberDeliveredWithoutDelay = 0;
                    object.numberDeliveredWithDelay = 0;
                    object.averageDeliveryTime = 0;
                }
                if (message.courierId != null && message.hasOwnProperty("courierId"))
                    if (typeof message.courierId === "number")
                        object.courierId = options.longs === String ? String(message.courierId) : message.courierId;
                    else
                        object.courierId = options.longs === String ? $util.Long.prototype.toString.call(message.courierId) : options.longs === Number ? new $util.LongBits(message.courierId.low >>> 0, message.courierId.high >>> 0).toNumber(true) : message.courierId;
                if (message.timestampWorkingDay != null && message.hasOwnProperty("timestampWorkingDay"))
                    if (typeof message.timestampWorkingDay === "number")
                        object.timestampWorkingDay = options.longs === String ? String(message.timestampWorkingDay) : message.timestampWorkingDay;
                    else
                        object.timestampWorkingDay = options.longs === String ? $util.Long.prototype.toString.call(message.timestampWorkingDay) : options.longs === Number ? new $util.LongBits(message.timestampWorkingDay.low >>> 0, message.timestampWorkingDay.high >>> 0).toNumber() : message.timestampWorkingDay;
                if (message.numberDelivered != null && message.hasOwnProperty("numberDelivered"))
                    object.numberDelivered = message.numberDelivered;
                if (message.numberDeliveredWithoutDelay != null && message.hasOwnProperty("numberDeliveredWithoutDelay"))
                    object.numberDeliveredWithoutDelay = message.numberDeliveredWithoutDelay;
                if (message.numberDeliveredWithDelay != null && message.hasOwnProperty("numberDeliveredWithDelay"))
                    object.numberDeliveredWithDelay = message.numberDeliveredWithDelay;
                if (message.averageDeliveryTime != null && message.hasOwnProperty("averageDeliveryTime"))
                    object.averageDeliveryTime = message.averageDeliveryTime;
                return object;
            };

            /**
             * Converts this DeliveryStatDay to JSON.
             * @function toJSON
             * @memberof fastcity.admin.DeliveryStatDay
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DeliveryStatDay.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return DeliveryStatDay;
        })();

        admin.DeliveryStat = (function() {

            /**
             * Properties of a DeliveryStat.
             * @memberof fastcity.admin
             * @interface IDeliveryStat
             * @property {fastcity.admin.IDeliveryStatDay|null} [currentStatDay] DeliveryStat currentStatDay
             * @property {number|null} [averageDeliveryTime_1w] DeliveryStat averageDeliveryTime_1w
             * @property {number|null} [averageDeliveryTime_1m] DeliveryStat averageDeliveryTime_1m
             * @property {number|null} [maximumNumberDelivered] DeliveryStat maximumNumberDelivered
             * @property {number|null} [totalNumberDelivered] DeliveryStat totalNumberDelivered
             */

            /**
             * Constructs a new DeliveryStat.
             * @memberof fastcity.admin
             * @classdesc Represents a DeliveryStat.
             * @implements IDeliveryStat
             * @constructor
             * @param {fastcity.admin.IDeliveryStat=} [properties] Properties to set
             */
            function DeliveryStat(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * DeliveryStat currentStatDay.
             * @member {fastcity.admin.IDeliveryStatDay|null|undefined} currentStatDay
             * @memberof fastcity.admin.DeliveryStat
             * @instance
             */
            DeliveryStat.prototype.currentStatDay = null;

            /**
             * DeliveryStat averageDeliveryTime_1w.
             * @member {number} averageDeliveryTime_1w
             * @memberof fastcity.admin.DeliveryStat
             * @instance
             */
            DeliveryStat.prototype.averageDeliveryTime_1w = 0;

            /**
             * DeliveryStat averageDeliveryTime_1m.
             * @member {number} averageDeliveryTime_1m
             * @memberof fastcity.admin.DeliveryStat
             * @instance
             */
            DeliveryStat.prototype.averageDeliveryTime_1m = 0;

            /**
             * DeliveryStat maximumNumberDelivered.
             * @member {number} maximumNumberDelivered
             * @memberof fastcity.admin.DeliveryStat
             * @instance
             */
            DeliveryStat.prototype.maximumNumberDelivered = 0;

            /**
             * DeliveryStat totalNumberDelivered.
             * @member {number} totalNumberDelivered
             * @memberof fastcity.admin.DeliveryStat
             * @instance
             */
            DeliveryStat.prototype.totalNumberDelivered = 0;

            /**
             * Creates a new DeliveryStat instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.DeliveryStat
             * @static
             * @param {fastcity.admin.IDeliveryStat=} [properties] Properties to set
             * @returns {fastcity.admin.DeliveryStat} DeliveryStat instance
             */
            DeliveryStat.create = function create(properties) {
                return new DeliveryStat(properties);
            };

            /**
             * Encodes the specified DeliveryStat message. Does not implicitly {@link fastcity.admin.DeliveryStat.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.DeliveryStat
             * @static
             * @param {fastcity.admin.IDeliveryStat} message DeliveryStat message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeliveryStat.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.currentStatDay != null && Object.hasOwnProperty.call(message, "currentStatDay"))
                    $root.fastcity.admin.DeliveryStatDay.encode(message.currentStatDay, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.averageDeliveryTime_1w != null && Object.hasOwnProperty.call(message, "averageDeliveryTime_1w"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.averageDeliveryTime_1w);
                if (message.averageDeliveryTime_1m != null && Object.hasOwnProperty.call(message, "averageDeliveryTime_1m"))
                    writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.averageDeliveryTime_1m);
                if (message.maximumNumberDelivered != null && Object.hasOwnProperty.call(message, "maximumNumberDelivered"))
                    writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.maximumNumberDelivered);
                if (message.totalNumberDelivered != null && Object.hasOwnProperty.call(message, "totalNumberDelivered"))
                    writer.uint32(/* id 5, wireType 0 =*/40).uint32(message.totalNumberDelivered);
                return writer;
            };

            /**
             * Decodes a DeliveryStat message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.DeliveryStat
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.DeliveryStat} DeliveryStat
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeliveryStat.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.DeliveryStat();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.currentStatDay = $root.fastcity.admin.DeliveryStatDay.decode(reader, reader.uint32());
                        break;
                    case 2:
                        message.averageDeliveryTime_1w = reader.uint32();
                        break;
                    case 3:
                        message.averageDeliveryTime_1m = reader.uint32();
                        break;
                    case 4:
                        message.maximumNumberDelivered = reader.uint32();
                        break;
                    case 5:
                        message.totalNumberDelivered = reader.uint32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a DeliveryStat message.
             * @function verify
             * @memberof fastcity.admin.DeliveryStat
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DeliveryStat.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.currentStatDay != null && message.hasOwnProperty("currentStatDay")) {
                    var error = $root.fastcity.admin.DeliveryStatDay.verify(message.currentStatDay);
                    if (error)
                        return "currentStatDay." + error;
                }
                if (message.averageDeliveryTime_1w != null && message.hasOwnProperty("averageDeliveryTime_1w"))
                    if (!$util.isInteger(message.averageDeliveryTime_1w))
                        return "averageDeliveryTime_1w: integer expected";
                if (message.averageDeliveryTime_1m != null && message.hasOwnProperty("averageDeliveryTime_1m"))
                    if (!$util.isInteger(message.averageDeliveryTime_1m))
                        return "averageDeliveryTime_1m: integer expected";
                if (message.maximumNumberDelivered != null && message.hasOwnProperty("maximumNumberDelivered"))
                    if (!$util.isInteger(message.maximumNumberDelivered))
                        return "maximumNumberDelivered: integer expected";
                if (message.totalNumberDelivered != null && message.hasOwnProperty("totalNumberDelivered"))
                    if (!$util.isInteger(message.totalNumberDelivered))
                        return "totalNumberDelivered: integer expected";
                return null;
            };

            /**
             * Creates a DeliveryStat message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.DeliveryStat
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.DeliveryStat} DeliveryStat
             */
            DeliveryStat.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.DeliveryStat)
                    return object;
                var message = new $root.fastcity.admin.DeliveryStat();
                if (object.currentStatDay != null) {
                    if (typeof object.currentStatDay !== "object")
                        throw TypeError(".fastcity.admin.DeliveryStat.currentStatDay: object expected");
                    message.currentStatDay = $root.fastcity.admin.DeliveryStatDay.fromObject(object.currentStatDay);
                }
                if (object.averageDeliveryTime_1w != null)
                    message.averageDeliveryTime_1w = object.averageDeliveryTime_1w >>> 0;
                if (object.averageDeliveryTime_1m != null)
                    message.averageDeliveryTime_1m = object.averageDeliveryTime_1m >>> 0;
                if (object.maximumNumberDelivered != null)
                    message.maximumNumberDelivered = object.maximumNumberDelivered >>> 0;
                if (object.totalNumberDelivered != null)
                    message.totalNumberDelivered = object.totalNumberDelivered >>> 0;
                return message;
            };

            /**
             * Creates a plain object from a DeliveryStat message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.DeliveryStat
             * @static
             * @param {fastcity.admin.DeliveryStat} message DeliveryStat
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DeliveryStat.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.currentStatDay = null;
                    object.averageDeliveryTime_1w = 0;
                    object.averageDeliveryTime_1m = 0;
                    object.maximumNumberDelivered = 0;
                    object.totalNumberDelivered = 0;
                }
                if (message.currentStatDay != null && message.hasOwnProperty("currentStatDay"))
                    object.currentStatDay = $root.fastcity.admin.DeliveryStatDay.toObject(message.currentStatDay, options);
                if (message.averageDeliveryTime_1w != null && message.hasOwnProperty("averageDeliveryTime_1w"))
                    object.averageDeliveryTime_1w = message.averageDeliveryTime_1w;
                if (message.averageDeliveryTime_1m != null && message.hasOwnProperty("averageDeliveryTime_1m"))
                    object.averageDeliveryTime_1m = message.averageDeliveryTime_1m;
                if (message.maximumNumberDelivered != null && message.hasOwnProperty("maximumNumberDelivered"))
                    object.maximumNumberDelivered = message.maximumNumberDelivered;
                if (message.totalNumberDelivered != null && message.hasOwnProperty("totalNumberDelivered"))
                    object.totalNumberDelivered = message.totalNumberDelivered;
                return object;
            };

            /**
             * Converts this DeliveryStat to JSON.
             * @function toJSON
             * @memberof fastcity.admin.DeliveryStat
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DeliveryStat.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return DeliveryStat;
        })();

        admin.DeliveryStatList = (function() {

            /**
             * Properties of a DeliveryStatList.
             * @memberof fastcity.admin
             * @interface IDeliveryStatList
             * @property {Array.<fastcity.admin.IDeliveryStat>|null} [deliveryStat] DeliveryStatList deliveryStat
             */

            /**
             * Constructs a new DeliveryStatList.
             * @memberof fastcity.admin
             * @classdesc Represents a DeliveryStatList.
             * @implements IDeliveryStatList
             * @constructor
             * @param {fastcity.admin.IDeliveryStatList=} [properties] Properties to set
             */
            function DeliveryStatList(properties) {
                this.deliveryStat = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * DeliveryStatList deliveryStat.
             * @member {Array.<fastcity.admin.IDeliveryStat>} deliveryStat
             * @memberof fastcity.admin.DeliveryStatList
             * @instance
             */
            DeliveryStatList.prototype.deliveryStat = $util.emptyArray;

            /**
             * Creates a new DeliveryStatList instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.DeliveryStatList
             * @static
             * @param {fastcity.admin.IDeliveryStatList=} [properties] Properties to set
             * @returns {fastcity.admin.DeliveryStatList} DeliveryStatList instance
             */
            DeliveryStatList.create = function create(properties) {
                return new DeliveryStatList(properties);
            };

            /**
             * Encodes the specified DeliveryStatList message. Does not implicitly {@link fastcity.admin.DeliveryStatList.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.DeliveryStatList
             * @static
             * @param {fastcity.admin.IDeliveryStatList} message DeliveryStatList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeliveryStatList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.deliveryStat != null && message.deliveryStat.length)
                    for (var i = 0; i < message.deliveryStat.length; ++i)
                        $root.fastcity.admin.DeliveryStat.encode(message.deliveryStat[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a DeliveryStatList message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.DeliveryStatList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.DeliveryStatList} DeliveryStatList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeliveryStatList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.DeliveryStatList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.deliveryStat && message.deliveryStat.length))
                            message.deliveryStat = [];
                        message.deliveryStat.push($root.fastcity.admin.DeliveryStat.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a DeliveryStatList message.
             * @function verify
             * @memberof fastcity.admin.DeliveryStatList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DeliveryStatList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.deliveryStat != null && message.hasOwnProperty("deliveryStat")) {
                    if (!Array.isArray(message.deliveryStat))
                        return "deliveryStat: array expected";
                    for (var i = 0; i < message.deliveryStat.length; ++i) {
                        var error = $root.fastcity.admin.DeliveryStat.verify(message.deliveryStat[i]);
                        if (error)
                            return "deliveryStat." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a DeliveryStatList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.DeliveryStatList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.DeliveryStatList} DeliveryStatList
             */
            DeliveryStatList.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.DeliveryStatList)
                    return object;
                var message = new $root.fastcity.admin.DeliveryStatList();
                if (object.deliveryStat) {
                    if (!Array.isArray(object.deliveryStat))
                        throw TypeError(".fastcity.admin.DeliveryStatList.deliveryStat: array expected");
                    message.deliveryStat = [];
                    for (var i = 0; i < object.deliveryStat.length; ++i) {
                        if (typeof object.deliveryStat[i] !== "object")
                            throw TypeError(".fastcity.admin.DeliveryStatList.deliveryStat: object expected");
                        message.deliveryStat[i] = $root.fastcity.admin.DeliveryStat.fromObject(object.deliveryStat[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a DeliveryStatList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.DeliveryStatList
             * @static
             * @param {fastcity.admin.DeliveryStatList} message DeliveryStatList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DeliveryStatList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.deliveryStat = [];
                if (message.deliveryStat && message.deliveryStat.length) {
                    object.deliveryStat = [];
                    for (var j = 0; j < message.deliveryStat.length; ++j)
                        object.deliveryStat[j] = $root.fastcity.admin.DeliveryStat.toObject(message.deliveryStat[j], options);
                }
                return object;
            };

            /**
             * Converts this DeliveryStatList to JSON.
             * @function toJSON
             * @memberof fastcity.admin.DeliveryStatList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DeliveryStatList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return DeliveryStatList;
        })();

        admin.DeliveryStatDayList = (function() {

            /**
             * Properties of a DeliveryStatDayList.
             * @memberof fastcity.admin
             * @interface IDeliveryStatDayList
             * @property {Array.<fastcity.admin.IDeliveryStatDay>|null} [deliveryStatDay] DeliveryStatDayList deliveryStatDay
             * @property {number|null} [totalDeliveryStatDays] DeliveryStatDayList totalDeliveryStatDays
             */

            /**
             * Constructs a new DeliveryStatDayList.
             * @memberof fastcity.admin
             * @classdesc Represents a DeliveryStatDayList.
             * @implements IDeliveryStatDayList
             * @constructor
             * @param {fastcity.admin.IDeliveryStatDayList=} [properties] Properties to set
             */
            function DeliveryStatDayList(properties) {
                this.deliveryStatDay = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * DeliveryStatDayList deliveryStatDay.
             * @member {Array.<fastcity.admin.IDeliveryStatDay>} deliveryStatDay
             * @memberof fastcity.admin.DeliveryStatDayList
             * @instance
             */
            DeliveryStatDayList.prototype.deliveryStatDay = $util.emptyArray;

            /**
             * DeliveryStatDayList totalDeliveryStatDays.
             * @member {number} totalDeliveryStatDays
             * @memberof fastcity.admin.DeliveryStatDayList
             * @instance
             */
            DeliveryStatDayList.prototype.totalDeliveryStatDays = 0;

            /**
             * Creates a new DeliveryStatDayList instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.DeliveryStatDayList
             * @static
             * @param {fastcity.admin.IDeliveryStatDayList=} [properties] Properties to set
             * @returns {fastcity.admin.DeliveryStatDayList} DeliveryStatDayList instance
             */
            DeliveryStatDayList.create = function create(properties) {
                return new DeliveryStatDayList(properties);
            };

            /**
             * Encodes the specified DeliveryStatDayList message. Does not implicitly {@link fastcity.admin.DeliveryStatDayList.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.DeliveryStatDayList
             * @static
             * @param {fastcity.admin.IDeliveryStatDayList} message DeliveryStatDayList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeliveryStatDayList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.deliveryStatDay != null && message.deliveryStatDay.length)
                    for (var i = 0; i < message.deliveryStatDay.length; ++i)
                        $root.fastcity.admin.DeliveryStatDay.encode(message.deliveryStatDay[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.totalDeliveryStatDays != null && Object.hasOwnProperty.call(message, "totalDeliveryStatDays"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.totalDeliveryStatDays);
                return writer;
            };

            /**
             * Decodes a DeliveryStatDayList message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.DeliveryStatDayList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.DeliveryStatDayList} DeliveryStatDayList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeliveryStatDayList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.DeliveryStatDayList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.deliveryStatDay && message.deliveryStatDay.length))
                            message.deliveryStatDay = [];
                        message.deliveryStatDay.push($root.fastcity.admin.DeliveryStatDay.decode(reader, reader.uint32()));
                        break;
                    case 2:
                        message.totalDeliveryStatDays = reader.uint32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a DeliveryStatDayList message.
             * @function verify
             * @memberof fastcity.admin.DeliveryStatDayList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DeliveryStatDayList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.deliveryStatDay != null && message.hasOwnProperty("deliveryStatDay")) {
                    if (!Array.isArray(message.deliveryStatDay))
                        return "deliveryStatDay: array expected";
                    for (var i = 0; i < message.deliveryStatDay.length; ++i) {
                        var error = $root.fastcity.admin.DeliveryStatDay.verify(message.deliveryStatDay[i]);
                        if (error)
                            return "deliveryStatDay." + error;
                    }
                }
                if (message.totalDeliveryStatDays != null && message.hasOwnProperty("totalDeliveryStatDays"))
                    if (!$util.isInteger(message.totalDeliveryStatDays))
                        return "totalDeliveryStatDays: integer expected";
                return null;
            };

            /**
             * Creates a DeliveryStatDayList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.DeliveryStatDayList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.DeliveryStatDayList} DeliveryStatDayList
             */
            DeliveryStatDayList.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.DeliveryStatDayList)
                    return object;
                var message = new $root.fastcity.admin.DeliveryStatDayList();
                if (object.deliveryStatDay) {
                    if (!Array.isArray(object.deliveryStatDay))
                        throw TypeError(".fastcity.admin.DeliveryStatDayList.deliveryStatDay: array expected");
                    message.deliveryStatDay = [];
                    for (var i = 0; i < object.deliveryStatDay.length; ++i) {
                        if (typeof object.deliveryStatDay[i] !== "object")
                            throw TypeError(".fastcity.admin.DeliveryStatDayList.deliveryStatDay: object expected");
                        message.deliveryStatDay[i] = $root.fastcity.admin.DeliveryStatDay.fromObject(object.deliveryStatDay[i]);
                    }
                }
                if (object.totalDeliveryStatDays != null)
                    message.totalDeliveryStatDays = object.totalDeliveryStatDays >>> 0;
                return message;
            };

            /**
             * Creates a plain object from a DeliveryStatDayList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.DeliveryStatDayList
             * @static
             * @param {fastcity.admin.DeliveryStatDayList} message DeliveryStatDayList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DeliveryStatDayList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.deliveryStatDay = [];
                if (options.defaults)
                    object.totalDeliveryStatDays = 0;
                if (message.deliveryStatDay && message.deliveryStatDay.length) {
                    object.deliveryStatDay = [];
                    for (var j = 0; j < message.deliveryStatDay.length; ++j)
                        object.deliveryStatDay[j] = $root.fastcity.admin.DeliveryStatDay.toObject(message.deliveryStatDay[j], options);
                }
                if (message.totalDeliveryStatDays != null && message.hasOwnProperty("totalDeliveryStatDays"))
                    object.totalDeliveryStatDays = message.totalDeliveryStatDays;
                return object;
            };

            /**
             * Converts this DeliveryStatDayList to JSON.
             * @function toJSON
             * @memberof fastcity.admin.DeliveryStatDayList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DeliveryStatDayList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return DeliveryStatDayList;
        })();

        admin.GetDeliveryStatDayList = (function() {

            /**
             * Properties of a GetDeliveryStatDayList.
             * @memberof fastcity.admin
             * @interface IGetDeliveryStatDayList
             * @property {fastcity.admin.GetDeliveryStatDayList.ITimeInterval|null} [timeInterval] GetDeliveryStatDayList timeInterval
             */

            /**
             * Constructs a new GetDeliveryStatDayList.
             * @memberof fastcity.admin
             * @classdesc Represents a GetDeliveryStatDayList.
             * @implements IGetDeliveryStatDayList
             * @constructor
             * @param {fastcity.admin.IGetDeliveryStatDayList=} [properties] Properties to set
             */
            function GetDeliveryStatDayList(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GetDeliveryStatDayList timeInterval.
             * @member {fastcity.admin.GetDeliveryStatDayList.ITimeInterval|null|undefined} timeInterval
             * @memberof fastcity.admin.GetDeliveryStatDayList
             * @instance
             */
            GetDeliveryStatDayList.prototype.timeInterval = null;

            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;

            /**
             * GetDeliveryStatDayList type.
             * @member {"timeInterval"|undefined} type
             * @memberof fastcity.admin.GetDeliveryStatDayList
             * @instance
             */
            Object.defineProperty(GetDeliveryStatDayList.prototype, "type", {
                get: $util.oneOfGetter($oneOfFields = ["timeInterval"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Creates a new GetDeliveryStatDayList instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.GetDeliveryStatDayList
             * @static
             * @param {fastcity.admin.IGetDeliveryStatDayList=} [properties] Properties to set
             * @returns {fastcity.admin.GetDeliveryStatDayList} GetDeliveryStatDayList instance
             */
            GetDeliveryStatDayList.create = function create(properties) {
                return new GetDeliveryStatDayList(properties);
            };

            /**
             * Encodes the specified GetDeliveryStatDayList message. Does not implicitly {@link fastcity.admin.GetDeliveryStatDayList.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.GetDeliveryStatDayList
             * @static
             * @param {fastcity.admin.IGetDeliveryStatDayList} message GetDeliveryStatDayList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetDeliveryStatDayList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.timeInterval != null && Object.hasOwnProperty.call(message, "timeInterval"))
                    $root.fastcity.admin.GetDeliveryStatDayList.TimeInterval.encode(message.timeInterval, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a GetDeliveryStatDayList message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.GetDeliveryStatDayList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.GetDeliveryStatDayList} GetDeliveryStatDayList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetDeliveryStatDayList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.GetDeliveryStatDayList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.timeInterval = $root.fastcity.admin.GetDeliveryStatDayList.TimeInterval.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a GetDeliveryStatDayList message.
             * @function verify
             * @memberof fastcity.admin.GetDeliveryStatDayList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetDeliveryStatDayList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                var properties = {};
                if (message.timeInterval != null && message.hasOwnProperty("timeInterval")) {
                    properties.type = 1;
                    {
                        var error = $root.fastcity.admin.GetDeliveryStatDayList.TimeInterval.verify(message.timeInterval);
                        if (error)
                            return "timeInterval." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a GetDeliveryStatDayList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.GetDeliveryStatDayList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.GetDeliveryStatDayList} GetDeliveryStatDayList
             */
            GetDeliveryStatDayList.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.GetDeliveryStatDayList)
                    return object;
                var message = new $root.fastcity.admin.GetDeliveryStatDayList();
                if (object.timeInterval != null) {
                    if (typeof object.timeInterval !== "object")
                        throw TypeError(".fastcity.admin.GetDeliveryStatDayList.timeInterval: object expected");
                    message.timeInterval = $root.fastcity.admin.GetDeliveryStatDayList.TimeInterval.fromObject(object.timeInterval);
                }
                return message;
            };

            /**
             * Creates a plain object from a GetDeliveryStatDayList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.GetDeliveryStatDayList
             * @static
             * @param {fastcity.admin.GetDeliveryStatDayList} message GetDeliveryStatDayList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetDeliveryStatDayList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (message.timeInterval != null && message.hasOwnProperty("timeInterval")) {
                    object.timeInterval = $root.fastcity.admin.GetDeliveryStatDayList.TimeInterval.toObject(message.timeInterval, options);
                    if (options.oneofs)
                        object.type = "timeInterval";
                }
                return object;
            };

            /**
             * Converts this GetDeliveryStatDayList to JSON.
             * @function toJSON
             * @memberof fastcity.admin.GetDeliveryStatDayList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetDeliveryStatDayList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            GetDeliveryStatDayList.TimeInterval = (function() {

                /**
                 * Properties of a TimeInterval.
                 * @memberof fastcity.admin.GetDeliveryStatDayList
                 * @interface ITimeInterval
                 * @property {Array.<number|Long>|null} [courierId] TimeInterval courierId
                 * @property {number|Long|null} [fromTime] TimeInterval fromTime
                 * @property {number|Long|null} [toTime] TimeInterval toTime
                 * @property {number|null} [offset] TimeInterval offset
                 * @property {number|null} [limit] TimeInterval limit
                 * @property {fastcity.common.SortOrder.Order|null} [sortOrder] TimeInterval sortOrder
                 */

                /**
                 * Constructs a new TimeInterval.
                 * @memberof fastcity.admin.GetDeliveryStatDayList
                 * @classdesc Represents a TimeInterval.
                 * @implements ITimeInterval
                 * @constructor
                 * @param {fastcity.admin.GetDeliveryStatDayList.ITimeInterval=} [properties] Properties to set
                 */
                function TimeInterval(properties) {
                    this.courierId = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * TimeInterval courierId.
                 * @member {Array.<number|Long>} courierId
                 * @memberof fastcity.admin.GetDeliveryStatDayList.TimeInterval
                 * @instance
                 */
                TimeInterval.prototype.courierId = $util.emptyArray;

                /**
                 * TimeInterval fromTime.
                 * @member {number|Long} fromTime
                 * @memberof fastcity.admin.GetDeliveryStatDayList.TimeInterval
                 * @instance
                 */
                TimeInterval.prototype.fromTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * TimeInterval toTime.
                 * @member {number|Long} toTime
                 * @memberof fastcity.admin.GetDeliveryStatDayList.TimeInterval
                 * @instance
                 */
                TimeInterval.prototype.toTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * TimeInterval offset.
                 * @member {number} offset
                 * @memberof fastcity.admin.GetDeliveryStatDayList.TimeInterval
                 * @instance
                 */
                TimeInterval.prototype.offset = 0;

                /**
                 * TimeInterval limit.
                 * @member {number} limit
                 * @memberof fastcity.admin.GetDeliveryStatDayList.TimeInterval
                 * @instance
                 */
                TimeInterval.prototype.limit = 0;

                /**
                 * TimeInterval sortOrder.
                 * @member {fastcity.common.SortOrder.Order} sortOrder
                 * @memberof fastcity.admin.GetDeliveryStatDayList.TimeInterval
                 * @instance
                 */
                TimeInterval.prototype.sortOrder = 0;

                /**
                 * Creates a new TimeInterval instance using the specified properties.
                 * @function create
                 * @memberof fastcity.admin.GetDeliveryStatDayList.TimeInterval
                 * @static
                 * @param {fastcity.admin.GetDeliveryStatDayList.ITimeInterval=} [properties] Properties to set
                 * @returns {fastcity.admin.GetDeliveryStatDayList.TimeInterval} TimeInterval instance
                 */
                TimeInterval.create = function create(properties) {
                    return new TimeInterval(properties);
                };

                /**
                 * Encodes the specified TimeInterval message. Does not implicitly {@link fastcity.admin.GetDeliveryStatDayList.TimeInterval.verify|verify} messages.
                 * @function encode
                 * @memberof fastcity.admin.GetDeliveryStatDayList.TimeInterval
                 * @static
                 * @param {fastcity.admin.GetDeliveryStatDayList.ITimeInterval} message TimeInterval message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                TimeInterval.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.courierId != null && message.courierId.length) {
                        writer.uint32(/* id 1, wireType 2 =*/10).fork();
                        for (var i = 0; i < message.courierId.length; ++i)
                            writer.uint64(message.courierId[i]);
                        writer.ldelim();
                    }
                    if (message.fromTime != null && Object.hasOwnProperty.call(message, "fromTime"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int64(message.fromTime);
                    if (message.toTime != null && Object.hasOwnProperty.call(message, "toTime"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int64(message.toTime);
                    if (message.offset != null && Object.hasOwnProperty.call(message, "offset"))
                        writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.offset);
                    if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                        writer.uint32(/* id 5, wireType 0 =*/40).uint32(message.limit);
                    if (message.sortOrder != null && Object.hasOwnProperty.call(message, "sortOrder"))
                        writer.uint32(/* id 6, wireType 0 =*/48).int32(message.sortOrder);
                    return writer;
                };

                /**
                 * Decodes a TimeInterval message from the specified reader or buffer.
                 * @function decode
                 * @memberof fastcity.admin.GetDeliveryStatDayList.TimeInterval
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {fastcity.admin.GetDeliveryStatDayList.TimeInterval} TimeInterval
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                TimeInterval.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.GetDeliveryStatDayList.TimeInterval();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            if (!(message.courierId && message.courierId.length))
                                message.courierId = [];
                            if ((tag & 7) === 2) {
                                var end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.courierId.push(reader.uint64());
                            } else
                                message.courierId.push(reader.uint64());
                            break;
                        case 2:
                            message.fromTime = reader.int64();
                            break;
                        case 3:
                            message.toTime = reader.int64();
                            break;
                        case 4:
                            message.offset = reader.uint32();
                            break;
                        case 5:
                            message.limit = reader.uint32();
                            break;
                        case 6:
                            message.sortOrder = reader.int32();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Verifies a TimeInterval message.
                 * @function verify
                 * @memberof fastcity.admin.GetDeliveryStatDayList.TimeInterval
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                TimeInterval.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.courierId != null && message.hasOwnProperty("courierId")) {
                        if (!Array.isArray(message.courierId))
                            return "courierId: array expected";
                        for (var i = 0; i < message.courierId.length; ++i)
                            if (!$util.isInteger(message.courierId[i]) && !(message.courierId[i] && $util.isInteger(message.courierId[i].low) && $util.isInteger(message.courierId[i].high)))
                                return "courierId: integer|Long[] expected";
                    }
                    if (message.fromTime != null && message.hasOwnProperty("fromTime"))
                        if (!$util.isInteger(message.fromTime) && !(message.fromTime && $util.isInteger(message.fromTime.low) && $util.isInteger(message.fromTime.high)))
                            return "fromTime: integer|Long expected";
                    if (message.toTime != null && message.hasOwnProperty("toTime"))
                        if (!$util.isInteger(message.toTime) && !(message.toTime && $util.isInteger(message.toTime.low) && $util.isInteger(message.toTime.high)))
                            return "toTime: integer|Long expected";
                    if (message.offset != null && message.hasOwnProperty("offset"))
                        if (!$util.isInteger(message.offset))
                            return "offset: integer expected";
                    if (message.limit != null && message.hasOwnProperty("limit"))
                        if (!$util.isInteger(message.limit))
                            return "limit: integer expected";
                    if (message.sortOrder != null && message.hasOwnProperty("sortOrder"))
                        switch (message.sortOrder) {
                        default:
                            return "sortOrder: enum value expected";
                        case 0:
                        case 1:
                            break;
                        }
                    return null;
                };

                /**
                 * Creates a TimeInterval message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof fastcity.admin.GetDeliveryStatDayList.TimeInterval
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {fastcity.admin.GetDeliveryStatDayList.TimeInterval} TimeInterval
                 */
                TimeInterval.fromObject = function fromObject(object) {
                    if (object instanceof $root.fastcity.admin.GetDeliveryStatDayList.TimeInterval)
                        return object;
                    var message = new $root.fastcity.admin.GetDeliveryStatDayList.TimeInterval();
                    if (object.courierId) {
                        if (!Array.isArray(object.courierId))
                            throw TypeError(".fastcity.admin.GetDeliveryStatDayList.TimeInterval.courierId: array expected");
                        message.courierId = [];
                        for (var i = 0; i < object.courierId.length; ++i)
                            if ($util.Long)
                                (message.courierId[i] = $util.Long.fromValue(object.courierId[i])).unsigned = true;
                            else if (typeof object.courierId[i] === "string")
                                message.courierId[i] = parseInt(object.courierId[i], 10);
                            else if (typeof object.courierId[i] === "number")
                                message.courierId[i] = object.courierId[i];
                            else if (typeof object.courierId[i] === "object")
                                message.courierId[i] = new $util.LongBits(object.courierId[i].low >>> 0, object.courierId[i].high >>> 0).toNumber(true);
                    }
                    if (object.fromTime != null)
                        if ($util.Long)
                            (message.fromTime = $util.Long.fromValue(object.fromTime)).unsigned = false;
                        else if (typeof object.fromTime === "string")
                            message.fromTime = parseInt(object.fromTime, 10);
                        else if (typeof object.fromTime === "number")
                            message.fromTime = object.fromTime;
                        else if (typeof object.fromTime === "object")
                            message.fromTime = new $util.LongBits(object.fromTime.low >>> 0, object.fromTime.high >>> 0).toNumber();
                    if (object.toTime != null)
                        if ($util.Long)
                            (message.toTime = $util.Long.fromValue(object.toTime)).unsigned = false;
                        else if (typeof object.toTime === "string")
                            message.toTime = parseInt(object.toTime, 10);
                        else if (typeof object.toTime === "number")
                            message.toTime = object.toTime;
                        else if (typeof object.toTime === "object")
                            message.toTime = new $util.LongBits(object.toTime.low >>> 0, object.toTime.high >>> 0).toNumber();
                    if (object.offset != null)
                        message.offset = object.offset >>> 0;
                    if (object.limit != null)
                        message.limit = object.limit >>> 0;
                    switch (object.sortOrder) {
                    case "ASC":
                    case 0:
                        message.sortOrder = 0;
                        break;
                    case "DESC":
                    case 1:
                        message.sortOrder = 1;
                        break;
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a TimeInterval message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof fastcity.admin.GetDeliveryStatDayList.TimeInterval
                 * @static
                 * @param {fastcity.admin.GetDeliveryStatDayList.TimeInterval} message TimeInterval
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                TimeInterval.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.courierId = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.fromTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.fromTime = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.toTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.toTime = options.longs === String ? "0" : 0;
                        object.offset = 0;
                        object.limit = 0;
                        object.sortOrder = options.enums === String ? "ASC" : 0;
                    }
                    if (message.courierId && message.courierId.length) {
                        object.courierId = [];
                        for (var j = 0; j < message.courierId.length; ++j)
                            if (typeof message.courierId[j] === "number")
                                object.courierId[j] = options.longs === String ? String(message.courierId[j]) : message.courierId[j];
                            else
                                object.courierId[j] = options.longs === String ? $util.Long.prototype.toString.call(message.courierId[j]) : options.longs === Number ? new $util.LongBits(message.courierId[j].low >>> 0, message.courierId[j].high >>> 0).toNumber(true) : message.courierId[j];
                    }
                    if (message.fromTime != null && message.hasOwnProperty("fromTime"))
                        if (typeof message.fromTime === "number")
                            object.fromTime = options.longs === String ? String(message.fromTime) : message.fromTime;
                        else
                            object.fromTime = options.longs === String ? $util.Long.prototype.toString.call(message.fromTime) : options.longs === Number ? new $util.LongBits(message.fromTime.low >>> 0, message.fromTime.high >>> 0).toNumber() : message.fromTime;
                    if (message.toTime != null && message.hasOwnProperty("toTime"))
                        if (typeof message.toTime === "number")
                            object.toTime = options.longs === String ? String(message.toTime) : message.toTime;
                        else
                            object.toTime = options.longs === String ? $util.Long.prototype.toString.call(message.toTime) : options.longs === Number ? new $util.LongBits(message.toTime.low >>> 0, message.toTime.high >>> 0).toNumber() : message.toTime;
                    if (message.offset != null && message.hasOwnProperty("offset"))
                        object.offset = message.offset;
                    if (message.limit != null && message.hasOwnProperty("limit"))
                        object.limit = message.limit;
                    if (message.sortOrder != null && message.hasOwnProperty("sortOrder"))
                        object.sortOrder = options.enums === String ? $root.fastcity.common.SortOrder.Order[message.sortOrder] : message.sortOrder;
                    return object;
                };

                /**
                 * Converts this TimeInterval to JSON.
                 * @function toJSON
                 * @memberof fastcity.admin.GetDeliveryStatDayList.TimeInterval
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                TimeInterval.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return TimeInterval;
            })();

            return GetDeliveryStatDayList;
        })();

        admin.GetDeliveryStatList = (function() {

            /**
             * Properties of a GetDeliveryStatList.
             * @memberof fastcity.admin
             * @interface IGetDeliveryStatList
             * @property {fastcity.admin.GetDeliveryStatList.ITimestamp|null} [timestamp] GetDeliveryStatList timestamp
             */

            /**
             * Constructs a new GetDeliveryStatList.
             * @memberof fastcity.admin
             * @classdesc Represents a GetDeliveryStatList.
             * @implements IGetDeliveryStatList
             * @constructor
             * @param {fastcity.admin.IGetDeliveryStatList=} [properties] Properties to set
             */
            function GetDeliveryStatList(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GetDeliveryStatList timestamp.
             * @member {fastcity.admin.GetDeliveryStatList.ITimestamp|null|undefined} timestamp
             * @memberof fastcity.admin.GetDeliveryStatList
             * @instance
             */
            GetDeliveryStatList.prototype.timestamp = null;

            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;

            /**
             * GetDeliveryStatList type.
             * @member {"timestamp"|undefined} type
             * @memberof fastcity.admin.GetDeliveryStatList
             * @instance
             */
            Object.defineProperty(GetDeliveryStatList.prototype, "type", {
                get: $util.oneOfGetter($oneOfFields = ["timestamp"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Creates a new GetDeliveryStatList instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.GetDeliveryStatList
             * @static
             * @param {fastcity.admin.IGetDeliveryStatList=} [properties] Properties to set
             * @returns {fastcity.admin.GetDeliveryStatList} GetDeliveryStatList instance
             */
            GetDeliveryStatList.create = function create(properties) {
                return new GetDeliveryStatList(properties);
            };

            /**
             * Encodes the specified GetDeliveryStatList message. Does not implicitly {@link fastcity.admin.GetDeliveryStatList.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.GetDeliveryStatList
             * @static
             * @param {fastcity.admin.IGetDeliveryStatList} message GetDeliveryStatList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetDeliveryStatList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.timestamp != null && Object.hasOwnProperty.call(message, "timestamp"))
                    $root.fastcity.admin.GetDeliveryStatList.Timestamp.encode(message.timestamp, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a GetDeliveryStatList message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.GetDeliveryStatList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.GetDeliveryStatList} GetDeliveryStatList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetDeliveryStatList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.GetDeliveryStatList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.timestamp = $root.fastcity.admin.GetDeliveryStatList.Timestamp.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a GetDeliveryStatList message.
             * @function verify
             * @memberof fastcity.admin.GetDeliveryStatList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetDeliveryStatList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                var properties = {};
                if (message.timestamp != null && message.hasOwnProperty("timestamp")) {
                    properties.type = 1;
                    {
                        var error = $root.fastcity.admin.GetDeliveryStatList.Timestamp.verify(message.timestamp);
                        if (error)
                            return "timestamp." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a GetDeliveryStatList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.GetDeliveryStatList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.GetDeliveryStatList} GetDeliveryStatList
             */
            GetDeliveryStatList.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.GetDeliveryStatList)
                    return object;
                var message = new $root.fastcity.admin.GetDeliveryStatList();
                if (object.timestamp != null) {
                    if (typeof object.timestamp !== "object")
                        throw TypeError(".fastcity.admin.GetDeliveryStatList.timestamp: object expected");
                    message.timestamp = $root.fastcity.admin.GetDeliveryStatList.Timestamp.fromObject(object.timestamp);
                }
                return message;
            };

            /**
             * Creates a plain object from a GetDeliveryStatList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.GetDeliveryStatList
             * @static
             * @param {fastcity.admin.GetDeliveryStatList} message GetDeliveryStatList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetDeliveryStatList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (message.timestamp != null && message.hasOwnProperty("timestamp")) {
                    object.timestamp = $root.fastcity.admin.GetDeliveryStatList.Timestamp.toObject(message.timestamp, options);
                    if (options.oneofs)
                        object.type = "timestamp";
                }
                return object;
            };

            /**
             * Converts this GetDeliveryStatList to JSON.
             * @function toJSON
             * @memberof fastcity.admin.GetDeliveryStatList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetDeliveryStatList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            GetDeliveryStatList.Timestamp = (function() {

                /**
                 * Properties of a Timestamp.
                 * @memberof fastcity.admin.GetDeliveryStatList
                 * @interface ITimestamp
                 * @property {Array.<number|Long>|null} [courierId] Timestamp courierId
                 * @property {number|Long|null} [timestampWorkingDay] Timestamp timestampWorkingDay
                 */

                /**
                 * Constructs a new Timestamp.
                 * @memberof fastcity.admin.GetDeliveryStatList
                 * @classdesc Represents a Timestamp.
                 * @implements ITimestamp
                 * @constructor
                 * @param {fastcity.admin.GetDeliveryStatList.ITimestamp=} [properties] Properties to set
                 */
                function Timestamp(properties) {
                    this.courierId = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Timestamp courierId.
                 * @member {Array.<number|Long>} courierId
                 * @memberof fastcity.admin.GetDeliveryStatList.Timestamp
                 * @instance
                 */
                Timestamp.prototype.courierId = $util.emptyArray;

                /**
                 * Timestamp timestampWorkingDay.
                 * @member {number|Long} timestampWorkingDay
                 * @memberof fastcity.admin.GetDeliveryStatList.Timestamp
                 * @instance
                 */
                Timestamp.prototype.timestampWorkingDay = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Creates a new Timestamp instance using the specified properties.
                 * @function create
                 * @memberof fastcity.admin.GetDeliveryStatList.Timestamp
                 * @static
                 * @param {fastcity.admin.GetDeliveryStatList.ITimestamp=} [properties] Properties to set
                 * @returns {fastcity.admin.GetDeliveryStatList.Timestamp} Timestamp instance
                 */
                Timestamp.create = function create(properties) {
                    return new Timestamp(properties);
                };

                /**
                 * Encodes the specified Timestamp message. Does not implicitly {@link fastcity.admin.GetDeliveryStatList.Timestamp.verify|verify} messages.
                 * @function encode
                 * @memberof fastcity.admin.GetDeliveryStatList.Timestamp
                 * @static
                 * @param {fastcity.admin.GetDeliveryStatList.ITimestamp} message Timestamp message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Timestamp.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.courierId != null && message.courierId.length) {
                        writer.uint32(/* id 1, wireType 2 =*/10).fork();
                        for (var i = 0; i < message.courierId.length; ++i)
                            writer.uint64(message.courierId[i]);
                        writer.ldelim();
                    }
                    if (message.timestampWorkingDay != null && Object.hasOwnProperty.call(message, "timestampWorkingDay"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int64(message.timestampWorkingDay);
                    return writer;
                };

                /**
                 * Decodes a Timestamp message from the specified reader or buffer.
                 * @function decode
                 * @memberof fastcity.admin.GetDeliveryStatList.Timestamp
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {fastcity.admin.GetDeliveryStatList.Timestamp} Timestamp
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Timestamp.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.GetDeliveryStatList.Timestamp();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            if (!(message.courierId && message.courierId.length))
                                message.courierId = [];
                            if ((tag & 7) === 2) {
                                var end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.courierId.push(reader.uint64());
                            } else
                                message.courierId.push(reader.uint64());
                            break;
                        case 2:
                            message.timestampWorkingDay = reader.int64();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Verifies a Timestamp message.
                 * @function verify
                 * @memberof fastcity.admin.GetDeliveryStatList.Timestamp
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Timestamp.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.courierId != null && message.hasOwnProperty("courierId")) {
                        if (!Array.isArray(message.courierId))
                            return "courierId: array expected";
                        for (var i = 0; i < message.courierId.length; ++i)
                            if (!$util.isInteger(message.courierId[i]) && !(message.courierId[i] && $util.isInteger(message.courierId[i].low) && $util.isInteger(message.courierId[i].high)))
                                return "courierId: integer|Long[] expected";
                    }
                    if (message.timestampWorkingDay != null && message.hasOwnProperty("timestampWorkingDay"))
                        if (!$util.isInteger(message.timestampWorkingDay) && !(message.timestampWorkingDay && $util.isInteger(message.timestampWorkingDay.low) && $util.isInteger(message.timestampWorkingDay.high)))
                            return "timestampWorkingDay: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a Timestamp message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof fastcity.admin.GetDeliveryStatList.Timestamp
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {fastcity.admin.GetDeliveryStatList.Timestamp} Timestamp
                 */
                Timestamp.fromObject = function fromObject(object) {
                    if (object instanceof $root.fastcity.admin.GetDeliveryStatList.Timestamp)
                        return object;
                    var message = new $root.fastcity.admin.GetDeliveryStatList.Timestamp();
                    if (object.courierId) {
                        if (!Array.isArray(object.courierId))
                            throw TypeError(".fastcity.admin.GetDeliveryStatList.Timestamp.courierId: array expected");
                        message.courierId = [];
                        for (var i = 0; i < object.courierId.length; ++i)
                            if ($util.Long)
                                (message.courierId[i] = $util.Long.fromValue(object.courierId[i])).unsigned = true;
                            else if (typeof object.courierId[i] === "string")
                                message.courierId[i] = parseInt(object.courierId[i], 10);
                            else if (typeof object.courierId[i] === "number")
                                message.courierId[i] = object.courierId[i];
                            else if (typeof object.courierId[i] === "object")
                                message.courierId[i] = new $util.LongBits(object.courierId[i].low >>> 0, object.courierId[i].high >>> 0).toNumber(true);
                    }
                    if (object.timestampWorkingDay != null)
                        if ($util.Long)
                            (message.timestampWorkingDay = $util.Long.fromValue(object.timestampWorkingDay)).unsigned = false;
                        else if (typeof object.timestampWorkingDay === "string")
                            message.timestampWorkingDay = parseInt(object.timestampWorkingDay, 10);
                        else if (typeof object.timestampWorkingDay === "number")
                            message.timestampWorkingDay = object.timestampWorkingDay;
                        else if (typeof object.timestampWorkingDay === "object")
                            message.timestampWorkingDay = new $util.LongBits(object.timestampWorkingDay.low >>> 0, object.timestampWorkingDay.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a Timestamp message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof fastcity.admin.GetDeliveryStatList.Timestamp
                 * @static
                 * @param {fastcity.admin.GetDeliveryStatList.Timestamp} message Timestamp
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Timestamp.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.courierId = [];
                    if (options.defaults)
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.timestampWorkingDay = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.timestampWorkingDay = options.longs === String ? "0" : 0;
                    if (message.courierId && message.courierId.length) {
                        object.courierId = [];
                        for (var j = 0; j < message.courierId.length; ++j)
                            if (typeof message.courierId[j] === "number")
                                object.courierId[j] = options.longs === String ? String(message.courierId[j]) : message.courierId[j];
                            else
                                object.courierId[j] = options.longs === String ? $util.Long.prototype.toString.call(message.courierId[j]) : options.longs === Number ? new $util.LongBits(message.courierId[j].low >>> 0, message.courierId[j].high >>> 0).toNumber(true) : message.courierId[j];
                    }
                    if (message.timestampWorkingDay != null && message.hasOwnProperty("timestampWorkingDay"))
                        if (typeof message.timestampWorkingDay === "number")
                            object.timestampWorkingDay = options.longs === String ? String(message.timestampWorkingDay) : message.timestampWorkingDay;
                        else
                            object.timestampWorkingDay = options.longs === String ? $util.Long.prototype.toString.call(message.timestampWorkingDay) : options.longs === Number ? new $util.LongBits(message.timestampWorkingDay.low >>> 0, message.timestampWorkingDay.high >>> 0).toNumber() : message.timestampWorkingDay;
                    return object;
                };

                /**
                 * Converts this Timestamp to JSON.
                 * @function toJSON
                 * @memberof fastcity.admin.GetDeliveryStatList.Timestamp
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Timestamp.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return Timestamp;
            })();

            return GetDeliveryStatList;
        })();

        admin.WorkingDayList = (function() {

            /**
             * Properties of a WorkingDayList.
             * @memberof fastcity.admin
             * @interface IWorkingDayList
             * @property {Array.<number|Long>|null} [workingDay] WorkingDayList workingDay
             * @property {number|null} [totalWorkingDays] WorkingDayList totalWorkingDays
             */

            /**
             * Constructs a new WorkingDayList.
             * @memberof fastcity.admin
             * @classdesc Represents a WorkingDayList.
             * @implements IWorkingDayList
             * @constructor
             * @param {fastcity.admin.IWorkingDayList=} [properties] Properties to set
             */
            function WorkingDayList(properties) {
                this.workingDay = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * WorkingDayList workingDay.
             * @member {Array.<number|Long>} workingDay
             * @memberof fastcity.admin.WorkingDayList
             * @instance
             */
            WorkingDayList.prototype.workingDay = $util.emptyArray;

            /**
             * WorkingDayList totalWorkingDays.
             * @member {number} totalWorkingDays
             * @memberof fastcity.admin.WorkingDayList
             * @instance
             */
            WorkingDayList.prototype.totalWorkingDays = 0;

            /**
             * Creates a new WorkingDayList instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.WorkingDayList
             * @static
             * @param {fastcity.admin.IWorkingDayList=} [properties] Properties to set
             * @returns {fastcity.admin.WorkingDayList} WorkingDayList instance
             */
            WorkingDayList.create = function create(properties) {
                return new WorkingDayList(properties);
            };

            /**
             * Encodes the specified WorkingDayList message. Does not implicitly {@link fastcity.admin.WorkingDayList.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.WorkingDayList
             * @static
             * @param {fastcity.admin.IWorkingDayList} message WorkingDayList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WorkingDayList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.workingDay != null && message.workingDay.length) {
                    writer.uint32(/* id 1, wireType 2 =*/10).fork();
                    for (var i = 0; i < message.workingDay.length; ++i)
                        writer.int64(message.workingDay[i]);
                    writer.ldelim();
                }
                if (message.totalWorkingDays != null && Object.hasOwnProperty.call(message, "totalWorkingDays"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.totalWorkingDays);
                return writer;
            };

            /**
             * Decodes a WorkingDayList message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.WorkingDayList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.WorkingDayList} WorkingDayList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WorkingDayList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.WorkingDayList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.workingDay && message.workingDay.length))
                            message.workingDay = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.workingDay.push(reader.int64());
                        } else
                            message.workingDay.push(reader.int64());
                        break;
                    case 2:
                        message.totalWorkingDays = reader.uint32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a WorkingDayList message.
             * @function verify
             * @memberof fastcity.admin.WorkingDayList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            WorkingDayList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.workingDay != null && message.hasOwnProperty("workingDay")) {
                    if (!Array.isArray(message.workingDay))
                        return "workingDay: array expected";
                    for (var i = 0; i < message.workingDay.length; ++i)
                        if (!$util.isInteger(message.workingDay[i]) && !(message.workingDay[i] && $util.isInteger(message.workingDay[i].low) && $util.isInteger(message.workingDay[i].high)))
                            return "workingDay: integer|Long[] expected";
                }
                if (message.totalWorkingDays != null && message.hasOwnProperty("totalWorkingDays"))
                    if (!$util.isInteger(message.totalWorkingDays))
                        return "totalWorkingDays: integer expected";
                return null;
            };

            /**
             * Creates a WorkingDayList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.WorkingDayList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.WorkingDayList} WorkingDayList
             */
            WorkingDayList.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.WorkingDayList)
                    return object;
                var message = new $root.fastcity.admin.WorkingDayList();
                if (object.workingDay) {
                    if (!Array.isArray(object.workingDay))
                        throw TypeError(".fastcity.admin.WorkingDayList.workingDay: array expected");
                    message.workingDay = [];
                    for (var i = 0; i < object.workingDay.length; ++i)
                        if ($util.Long)
                            (message.workingDay[i] = $util.Long.fromValue(object.workingDay[i])).unsigned = false;
                        else if (typeof object.workingDay[i] === "string")
                            message.workingDay[i] = parseInt(object.workingDay[i], 10);
                        else if (typeof object.workingDay[i] === "number")
                            message.workingDay[i] = object.workingDay[i];
                        else if (typeof object.workingDay[i] === "object")
                            message.workingDay[i] = new $util.LongBits(object.workingDay[i].low >>> 0, object.workingDay[i].high >>> 0).toNumber();
                }
                if (object.totalWorkingDays != null)
                    message.totalWorkingDays = object.totalWorkingDays >>> 0;
                return message;
            };

            /**
             * Creates a plain object from a WorkingDayList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.WorkingDayList
             * @static
             * @param {fastcity.admin.WorkingDayList} message WorkingDayList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            WorkingDayList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.workingDay = [];
                if (options.defaults)
                    object.totalWorkingDays = 0;
                if (message.workingDay && message.workingDay.length) {
                    object.workingDay = [];
                    for (var j = 0; j < message.workingDay.length; ++j)
                        if (typeof message.workingDay[j] === "number")
                            object.workingDay[j] = options.longs === String ? String(message.workingDay[j]) : message.workingDay[j];
                        else
                            object.workingDay[j] = options.longs === String ? $util.Long.prototype.toString.call(message.workingDay[j]) : options.longs === Number ? new $util.LongBits(message.workingDay[j].low >>> 0, message.workingDay[j].high >>> 0).toNumber() : message.workingDay[j];
                }
                if (message.totalWorkingDays != null && message.hasOwnProperty("totalWorkingDays"))
                    object.totalWorkingDays = message.totalWorkingDays;
                return object;
            };

            /**
             * Converts this WorkingDayList to JSON.
             * @function toJSON
             * @memberof fastcity.admin.WorkingDayList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            WorkingDayList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return WorkingDayList;
        })();

        admin.GetWorkingDayList = (function() {

            /**
             * Properties of a GetWorkingDayList.
             * @memberof fastcity.admin
             * @interface IGetWorkingDayList
             * @property {fastcity.admin.GetWorkingDayList.ITimeInterval|null} [timeInterval] GetWorkingDayList timeInterval
             */

            /**
             * Constructs a new GetWorkingDayList.
             * @memberof fastcity.admin
             * @classdesc Represents a GetWorkingDayList.
             * @implements IGetWorkingDayList
             * @constructor
             * @param {fastcity.admin.IGetWorkingDayList=} [properties] Properties to set
             */
            function GetWorkingDayList(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GetWorkingDayList timeInterval.
             * @member {fastcity.admin.GetWorkingDayList.ITimeInterval|null|undefined} timeInterval
             * @memberof fastcity.admin.GetWorkingDayList
             * @instance
             */
            GetWorkingDayList.prototype.timeInterval = null;

            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;

            /**
             * GetWorkingDayList type.
             * @member {"timeInterval"|undefined} type
             * @memberof fastcity.admin.GetWorkingDayList
             * @instance
             */
            Object.defineProperty(GetWorkingDayList.prototype, "type", {
                get: $util.oneOfGetter($oneOfFields = ["timeInterval"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Creates a new GetWorkingDayList instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.GetWorkingDayList
             * @static
             * @param {fastcity.admin.IGetWorkingDayList=} [properties] Properties to set
             * @returns {fastcity.admin.GetWorkingDayList} GetWorkingDayList instance
             */
            GetWorkingDayList.create = function create(properties) {
                return new GetWorkingDayList(properties);
            };

            /**
             * Encodes the specified GetWorkingDayList message. Does not implicitly {@link fastcity.admin.GetWorkingDayList.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.GetWorkingDayList
             * @static
             * @param {fastcity.admin.IGetWorkingDayList} message GetWorkingDayList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetWorkingDayList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.timeInterval != null && Object.hasOwnProperty.call(message, "timeInterval"))
                    $root.fastcity.admin.GetWorkingDayList.TimeInterval.encode(message.timeInterval, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a GetWorkingDayList message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.GetWorkingDayList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.GetWorkingDayList} GetWorkingDayList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetWorkingDayList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.GetWorkingDayList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.timeInterval = $root.fastcity.admin.GetWorkingDayList.TimeInterval.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a GetWorkingDayList message.
             * @function verify
             * @memberof fastcity.admin.GetWorkingDayList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetWorkingDayList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                var properties = {};
                if (message.timeInterval != null && message.hasOwnProperty("timeInterval")) {
                    properties.type = 1;
                    {
                        var error = $root.fastcity.admin.GetWorkingDayList.TimeInterval.verify(message.timeInterval);
                        if (error)
                            return "timeInterval." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a GetWorkingDayList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.GetWorkingDayList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.GetWorkingDayList} GetWorkingDayList
             */
            GetWorkingDayList.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.GetWorkingDayList)
                    return object;
                var message = new $root.fastcity.admin.GetWorkingDayList();
                if (object.timeInterval != null) {
                    if (typeof object.timeInterval !== "object")
                        throw TypeError(".fastcity.admin.GetWorkingDayList.timeInterval: object expected");
                    message.timeInterval = $root.fastcity.admin.GetWorkingDayList.TimeInterval.fromObject(object.timeInterval);
                }
                return message;
            };

            /**
             * Creates a plain object from a GetWorkingDayList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.GetWorkingDayList
             * @static
             * @param {fastcity.admin.GetWorkingDayList} message GetWorkingDayList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetWorkingDayList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (message.timeInterval != null && message.hasOwnProperty("timeInterval")) {
                    object.timeInterval = $root.fastcity.admin.GetWorkingDayList.TimeInterval.toObject(message.timeInterval, options);
                    if (options.oneofs)
                        object.type = "timeInterval";
                }
                return object;
            };

            /**
             * Converts this GetWorkingDayList to JSON.
             * @function toJSON
             * @memberof fastcity.admin.GetWorkingDayList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetWorkingDayList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            GetWorkingDayList.TimeInterval = (function() {

                /**
                 * Properties of a TimeInterval.
                 * @memberof fastcity.admin.GetWorkingDayList
                 * @interface ITimeInterval
                 * @property {Array.<number|Long>|null} [courierId] TimeInterval courierId
                 * @property {number|Long|null} [fromTime] TimeInterval fromTime
                 * @property {number|Long|null} [toTime] TimeInterval toTime
                 * @property {number|null} [offset] TimeInterval offset
                 * @property {number|null} [limit] TimeInterval limit
                 * @property {fastcity.common.SortOrder.Order|null} [sortOrder] TimeInterval sortOrder
                 */

                /**
                 * Constructs a new TimeInterval.
                 * @memberof fastcity.admin.GetWorkingDayList
                 * @classdesc Represents a TimeInterval.
                 * @implements ITimeInterval
                 * @constructor
                 * @param {fastcity.admin.GetWorkingDayList.ITimeInterval=} [properties] Properties to set
                 */
                function TimeInterval(properties) {
                    this.courierId = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * TimeInterval courierId.
                 * @member {Array.<number|Long>} courierId
                 * @memberof fastcity.admin.GetWorkingDayList.TimeInterval
                 * @instance
                 */
                TimeInterval.prototype.courierId = $util.emptyArray;

                /**
                 * TimeInterval fromTime.
                 * @member {number|Long} fromTime
                 * @memberof fastcity.admin.GetWorkingDayList.TimeInterval
                 * @instance
                 */
                TimeInterval.prototype.fromTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * TimeInterval toTime.
                 * @member {number|Long} toTime
                 * @memberof fastcity.admin.GetWorkingDayList.TimeInterval
                 * @instance
                 */
                TimeInterval.prototype.toTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * TimeInterval offset.
                 * @member {number} offset
                 * @memberof fastcity.admin.GetWorkingDayList.TimeInterval
                 * @instance
                 */
                TimeInterval.prototype.offset = 0;

                /**
                 * TimeInterval limit.
                 * @member {number} limit
                 * @memberof fastcity.admin.GetWorkingDayList.TimeInterval
                 * @instance
                 */
                TimeInterval.prototype.limit = 0;

                /**
                 * TimeInterval sortOrder.
                 * @member {fastcity.common.SortOrder.Order} sortOrder
                 * @memberof fastcity.admin.GetWorkingDayList.TimeInterval
                 * @instance
                 */
                TimeInterval.prototype.sortOrder = 0;

                /**
                 * Creates a new TimeInterval instance using the specified properties.
                 * @function create
                 * @memberof fastcity.admin.GetWorkingDayList.TimeInterval
                 * @static
                 * @param {fastcity.admin.GetWorkingDayList.ITimeInterval=} [properties] Properties to set
                 * @returns {fastcity.admin.GetWorkingDayList.TimeInterval} TimeInterval instance
                 */
                TimeInterval.create = function create(properties) {
                    return new TimeInterval(properties);
                };

                /**
                 * Encodes the specified TimeInterval message. Does not implicitly {@link fastcity.admin.GetWorkingDayList.TimeInterval.verify|verify} messages.
                 * @function encode
                 * @memberof fastcity.admin.GetWorkingDayList.TimeInterval
                 * @static
                 * @param {fastcity.admin.GetWorkingDayList.ITimeInterval} message TimeInterval message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                TimeInterval.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.courierId != null && message.courierId.length) {
                        writer.uint32(/* id 1, wireType 2 =*/10).fork();
                        for (var i = 0; i < message.courierId.length; ++i)
                            writer.uint64(message.courierId[i]);
                        writer.ldelim();
                    }
                    if (message.fromTime != null && Object.hasOwnProperty.call(message, "fromTime"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int64(message.fromTime);
                    if (message.toTime != null && Object.hasOwnProperty.call(message, "toTime"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int64(message.toTime);
                    if (message.offset != null && Object.hasOwnProperty.call(message, "offset"))
                        writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.offset);
                    if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                        writer.uint32(/* id 5, wireType 0 =*/40).uint32(message.limit);
                    if (message.sortOrder != null && Object.hasOwnProperty.call(message, "sortOrder"))
                        writer.uint32(/* id 6, wireType 0 =*/48).int32(message.sortOrder);
                    return writer;
                };

                /**
                 * Decodes a TimeInterval message from the specified reader or buffer.
                 * @function decode
                 * @memberof fastcity.admin.GetWorkingDayList.TimeInterval
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {fastcity.admin.GetWorkingDayList.TimeInterval} TimeInterval
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                TimeInterval.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.GetWorkingDayList.TimeInterval();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            if (!(message.courierId && message.courierId.length))
                                message.courierId = [];
                            if ((tag & 7) === 2) {
                                var end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.courierId.push(reader.uint64());
                            } else
                                message.courierId.push(reader.uint64());
                            break;
                        case 2:
                            message.fromTime = reader.int64();
                            break;
                        case 3:
                            message.toTime = reader.int64();
                            break;
                        case 4:
                            message.offset = reader.uint32();
                            break;
                        case 5:
                            message.limit = reader.uint32();
                            break;
                        case 6:
                            message.sortOrder = reader.int32();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Verifies a TimeInterval message.
                 * @function verify
                 * @memberof fastcity.admin.GetWorkingDayList.TimeInterval
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                TimeInterval.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.courierId != null && message.hasOwnProperty("courierId")) {
                        if (!Array.isArray(message.courierId))
                            return "courierId: array expected";
                        for (var i = 0; i < message.courierId.length; ++i)
                            if (!$util.isInteger(message.courierId[i]) && !(message.courierId[i] && $util.isInteger(message.courierId[i].low) && $util.isInteger(message.courierId[i].high)))
                                return "courierId: integer|Long[] expected";
                    }
                    if (message.fromTime != null && message.hasOwnProperty("fromTime"))
                        if (!$util.isInteger(message.fromTime) && !(message.fromTime && $util.isInteger(message.fromTime.low) && $util.isInteger(message.fromTime.high)))
                            return "fromTime: integer|Long expected";
                    if (message.toTime != null && message.hasOwnProperty("toTime"))
                        if (!$util.isInteger(message.toTime) && !(message.toTime && $util.isInteger(message.toTime.low) && $util.isInteger(message.toTime.high)))
                            return "toTime: integer|Long expected";
                    if (message.offset != null && message.hasOwnProperty("offset"))
                        if (!$util.isInteger(message.offset))
                            return "offset: integer expected";
                    if (message.limit != null && message.hasOwnProperty("limit"))
                        if (!$util.isInteger(message.limit))
                            return "limit: integer expected";
                    if (message.sortOrder != null && message.hasOwnProperty("sortOrder"))
                        switch (message.sortOrder) {
                        default:
                            return "sortOrder: enum value expected";
                        case 0:
                        case 1:
                            break;
                        }
                    return null;
                };

                /**
                 * Creates a TimeInterval message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof fastcity.admin.GetWorkingDayList.TimeInterval
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {fastcity.admin.GetWorkingDayList.TimeInterval} TimeInterval
                 */
                TimeInterval.fromObject = function fromObject(object) {
                    if (object instanceof $root.fastcity.admin.GetWorkingDayList.TimeInterval)
                        return object;
                    var message = new $root.fastcity.admin.GetWorkingDayList.TimeInterval();
                    if (object.courierId) {
                        if (!Array.isArray(object.courierId))
                            throw TypeError(".fastcity.admin.GetWorkingDayList.TimeInterval.courierId: array expected");
                        message.courierId = [];
                        for (var i = 0; i < object.courierId.length; ++i)
                            if ($util.Long)
                                (message.courierId[i] = $util.Long.fromValue(object.courierId[i])).unsigned = true;
                            else if (typeof object.courierId[i] === "string")
                                message.courierId[i] = parseInt(object.courierId[i], 10);
                            else if (typeof object.courierId[i] === "number")
                                message.courierId[i] = object.courierId[i];
                            else if (typeof object.courierId[i] === "object")
                                message.courierId[i] = new $util.LongBits(object.courierId[i].low >>> 0, object.courierId[i].high >>> 0).toNumber(true);
                    }
                    if (object.fromTime != null)
                        if ($util.Long)
                            (message.fromTime = $util.Long.fromValue(object.fromTime)).unsigned = false;
                        else if (typeof object.fromTime === "string")
                            message.fromTime = parseInt(object.fromTime, 10);
                        else if (typeof object.fromTime === "number")
                            message.fromTime = object.fromTime;
                        else if (typeof object.fromTime === "object")
                            message.fromTime = new $util.LongBits(object.fromTime.low >>> 0, object.fromTime.high >>> 0).toNumber();
                    if (object.toTime != null)
                        if ($util.Long)
                            (message.toTime = $util.Long.fromValue(object.toTime)).unsigned = false;
                        else if (typeof object.toTime === "string")
                            message.toTime = parseInt(object.toTime, 10);
                        else if (typeof object.toTime === "number")
                            message.toTime = object.toTime;
                        else if (typeof object.toTime === "object")
                            message.toTime = new $util.LongBits(object.toTime.low >>> 0, object.toTime.high >>> 0).toNumber();
                    if (object.offset != null)
                        message.offset = object.offset >>> 0;
                    if (object.limit != null)
                        message.limit = object.limit >>> 0;
                    switch (object.sortOrder) {
                    case "ASC":
                    case 0:
                        message.sortOrder = 0;
                        break;
                    case "DESC":
                    case 1:
                        message.sortOrder = 1;
                        break;
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a TimeInterval message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof fastcity.admin.GetWorkingDayList.TimeInterval
                 * @static
                 * @param {fastcity.admin.GetWorkingDayList.TimeInterval} message TimeInterval
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                TimeInterval.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.courierId = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.fromTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.fromTime = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.toTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.toTime = options.longs === String ? "0" : 0;
                        object.offset = 0;
                        object.limit = 0;
                        object.sortOrder = options.enums === String ? "ASC" : 0;
                    }
                    if (message.courierId && message.courierId.length) {
                        object.courierId = [];
                        for (var j = 0; j < message.courierId.length; ++j)
                            if (typeof message.courierId[j] === "number")
                                object.courierId[j] = options.longs === String ? String(message.courierId[j]) : message.courierId[j];
                            else
                                object.courierId[j] = options.longs === String ? $util.Long.prototype.toString.call(message.courierId[j]) : options.longs === Number ? new $util.LongBits(message.courierId[j].low >>> 0, message.courierId[j].high >>> 0).toNumber(true) : message.courierId[j];
                    }
                    if (message.fromTime != null && message.hasOwnProperty("fromTime"))
                        if (typeof message.fromTime === "number")
                            object.fromTime = options.longs === String ? String(message.fromTime) : message.fromTime;
                        else
                            object.fromTime = options.longs === String ? $util.Long.prototype.toString.call(message.fromTime) : options.longs === Number ? new $util.LongBits(message.fromTime.low >>> 0, message.fromTime.high >>> 0).toNumber() : message.fromTime;
                    if (message.toTime != null && message.hasOwnProperty("toTime"))
                        if (typeof message.toTime === "number")
                            object.toTime = options.longs === String ? String(message.toTime) : message.toTime;
                        else
                            object.toTime = options.longs === String ? $util.Long.prototype.toString.call(message.toTime) : options.longs === Number ? new $util.LongBits(message.toTime.low >>> 0, message.toTime.high >>> 0).toNumber() : message.toTime;
                    if (message.offset != null && message.hasOwnProperty("offset"))
                        object.offset = message.offset;
                    if (message.limit != null && message.hasOwnProperty("limit"))
                        object.limit = message.limit;
                    if (message.sortOrder != null && message.hasOwnProperty("sortOrder"))
                        object.sortOrder = options.enums === String ? $root.fastcity.common.SortOrder.Order[message.sortOrder] : message.sortOrder;
                    return object;
                };

                /**
                 * Converts this TimeInterval to JSON.
                 * @function toJSON
                 * @memberof fastcity.admin.GetWorkingDayList.TimeInterval
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                TimeInterval.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return TimeInterval;
            })();

            return GetWorkingDayList;
        })();

        /**
         * ExternalAuthTokenRequestResult enum.
         * @name fastcity.admin.ExternalAuthTokenRequestResult
         * @enum {number}
         * @property {number} EATRR_NOT_FOUND=0 EATRR_NOT_FOUND value
         * @property {number} EATRR_BAD_COMPANY_ID_COMPANY_NOT_FOUND=1 EATRR_BAD_COMPANY_ID_COMPANY_NOT_FOUND value
         * @property {number} EATRR_BAD_COMPANY_ID_COMPANY_IS_DELETED=2 EATRR_BAD_COMPANY_ID_COMPANY_IS_DELETED value
         * @property {number} EATRR_BAD_EXTERNAL_TOKEN=3 EATRR_BAD_EXTERNAL_TOKEN value
         * @property {number} EATRR_BAD_URL=4 EATRR_BAD_URL value
         * @property {number} EATRR_BAD_SUB_COMPANY_ID_SUB_COMPANY_NOT_FOUND=5 EATRR_BAD_SUB_COMPANY_ID_SUB_COMPANY_NOT_FOUND value
         * @property {number} EATRR_BAD_SUB_COMPANY_ID_SUB_COMPANY_IS_DELETED=6 EATRR_BAD_SUB_COMPANY_ID_SUB_COMPANY_IS_DELETED value
         * @property {number} EATRR_EXTERNAL_AUTH_TOKEN_CONNECTED_TO_SUB_COMPANY=7 EATRR_EXTERNAL_AUTH_TOKEN_CONNECTED_TO_SUB_COMPANY value
         * @property {number} EATRR_FAILED_TO_GET_COURIERS=8 EATRR_FAILED_TO_GET_COURIERS value
         * @property {number} EATRR_FAILED_TO_GET_SUB_COMPANIES=9 EATRR_FAILED_TO_GET_SUB_COMPANIES value
         * @property {number} EATRR_CHECK_TOKEN_URL_MOVED=10 EATRR_CHECK_TOKEN_URL_MOVED value
         * @property {number} EATRR_CHECK_TOKEN_NOT_AUTHORIZED=11 EATRR_CHECK_TOKEN_NOT_AUTHORIZED value
         * @property {number} EATRR_CHECK_TOKEN_BAD_REQUEST=12 EATRR_CHECK_TOKEN_BAD_REQUEST value
         */
        admin.ExternalAuthTokenRequestResult = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "EATRR_NOT_FOUND"] = 0;
            values[valuesById[1] = "EATRR_BAD_COMPANY_ID_COMPANY_NOT_FOUND"] = 1;
            values[valuesById[2] = "EATRR_BAD_COMPANY_ID_COMPANY_IS_DELETED"] = 2;
            values[valuesById[3] = "EATRR_BAD_EXTERNAL_TOKEN"] = 3;
            values[valuesById[4] = "EATRR_BAD_URL"] = 4;
            values[valuesById[5] = "EATRR_BAD_SUB_COMPANY_ID_SUB_COMPANY_NOT_FOUND"] = 5;
            values[valuesById[6] = "EATRR_BAD_SUB_COMPANY_ID_SUB_COMPANY_IS_DELETED"] = 6;
            values[valuesById[7] = "EATRR_EXTERNAL_AUTH_TOKEN_CONNECTED_TO_SUB_COMPANY"] = 7;
            values[valuesById[8] = "EATRR_FAILED_TO_GET_COURIERS"] = 8;
            values[valuesById[9] = "EATRR_FAILED_TO_GET_SUB_COMPANIES"] = 9;
            values[valuesById[10] = "EATRR_CHECK_TOKEN_URL_MOVED"] = 10;
            values[valuesById[11] = "EATRR_CHECK_TOKEN_NOT_AUTHORIZED"] = 11;
            values[valuesById[12] = "EATRR_CHECK_TOKEN_BAD_REQUEST"] = 12;
            return values;
        })();

        admin.ExternalAuthToken = (function() {

            /**
             * Properties of an ExternalAuthToken.
             * @memberof fastcity.admin
             * @interface IExternalAuthToken
             * @property {number|Long|null} [externalAuthTokenId] ExternalAuthToken externalAuthTokenId
             * @property {number|Long|null} [companyId] ExternalAuthToken companyId
             * @property {string|null} [externalToken] ExternalAuthToken externalToken
             * @property {string|null} [urlToAuth] ExternalAuthToken urlToAuth
             * @property {fastcity.common.Source|null} [source] ExternalAuthToken source
             */

            /**
             * Constructs a new ExternalAuthToken.
             * @memberof fastcity.admin
             * @classdesc Represents an ExternalAuthToken.
             * @implements IExternalAuthToken
             * @constructor
             * @param {fastcity.admin.IExternalAuthToken=} [properties] Properties to set
             */
            function ExternalAuthToken(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ExternalAuthToken externalAuthTokenId.
             * @member {number|Long} externalAuthTokenId
             * @memberof fastcity.admin.ExternalAuthToken
             * @instance
             */
            ExternalAuthToken.prototype.externalAuthTokenId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * ExternalAuthToken companyId.
             * @member {number|Long} companyId
             * @memberof fastcity.admin.ExternalAuthToken
             * @instance
             */
            ExternalAuthToken.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * ExternalAuthToken externalToken.
             * @member {string} externalToken
             * @memberof fastcity.admin.ExternalAuthToken
             * @instance
             */
            ExternalAuthToken.prototype.externalToken = "";

            /**
             * ExternalAuthToken urlToAuth.
             * @member {string} urlToAuth
             * @memberof fastcity.admin.ExternalAuthToken
             * @instance
             */
            ExternalAuthToken.prototype.urlToAuth = "";

            /**
             * ExternalAuthToken source.
             * @member {fastcity.common.Source} source
             * @memberof fastcity.admin.ExternalAuthToken
             * @instance
             */
            ExternalAuthToken.prototype.source = 0;

            /**
             * Creates a new ExternalAuthToken instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.ExternalAuthToken
             * @static
             * @param {fastcity.admin.IExternalAuthToken=} [properties] Properties to set
             * @returns {fastcity.admin.ExternalAuthToken} ExternalAuthToken instance
             */
            ExternalAuthToken.create = function create(properties) {
                return new ExternalAuthToken(properties);
            };

            /**
             * Encodes the specified ExternalAuthToken message. Does not implicitly {@link fastcity.admin.ExternalAuthToken.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.ExternalAuthToken
             * @static
             * @param {fastcity.admin.IExternalAuthToken} message ExternalAuthToken message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ExternalAuthToken.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.externalAuthTokenId != null && Object.hasOwnProperty.call(message, "externalAuthTokenId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.externalAuthTokenId);
                if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.companyId);
                if (message.externalToken != null && Object.hasOwnProperty.call(message, "externalToken"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.externalToken);
                if (message.urlToAuth != null && Object.hasOwnProperty.call(message, "urlToAuth"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.urlToAuth);
                if (message.source != null && Object.hasOwnProperty.call(message, "source"))
                    writer.uint32(/* id 5, wireType 0 =*/40).int32(message.source);
                return writer;
            };

            /**
             * Decodes an ExternalAuthToken message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.ExternalAuthToken
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.ExternalAuthToken} ExternalAuthToken
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ExternalAuthToken.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.ExternalAuthToken();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.externalAuthTokenId = reader.uint64();
                        break;
                    case 2:
                        message.companyId = reader.uint64();
                        break;
                    case 3:
                        message.externalToken = reader.string();
                        break;
                    case 4:
                        message.urlToAuth = reader.string();
                        break;
                    case 5:
                        message.source = reader.int32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an ExternalAuthToken message.
             * @function verify
             * @memberof fastcity.admin.ExternalAuthToken
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ExternalAuthToken.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.externalAuthTokenId != null && message.hasOwnProperty("externalAuthTokenId"))
                    if (!$util.isInteger(message.externalAuthTokenId) && !(message.externalAuthTokenId && $util.isInteger(message.externalAuthTokenId.low) && $util.isInteger(message.externalAuthTokenId.high)))
                        return "externalAuthTokenId: integer|Long expected";
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                        return "companyId: integer|Long expected";
                if (message.externalToken != null && message.hasOwnProperty("externalToken"))
                    if (!$util.isString(message.externalToken))
                        return "externalToken: string expected";
                if (message.urlToAuth != null && message.hasOwnProperty("urlToAuth"))
                    if (!$util.isString(message.urlToAuth))
                        return "urlToAuth: string expected";
                if (message.source != null && message.hasOwnProperty("source"))
                    switch (message.source) {
                    default:
                        return "source: enum value expected";
                    case 0:
                    case 1:
                        break;
                    }
                return null;
            };

            /**
             * Creates an ExternalAuthToken message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.ExternalAuthToken
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.ExternalAuthToken} ExternalAuthToken
             */
            ExternalAuthToken.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.ExternalAuthToken)
                    return object;
                var message = new $root.fastcity.admin.ExternalAuthToken();
                if (object.externalAuthTokenId != null)
                    if ($util.Long)
                        (message.externalAuthTokenId = $util.Long.fromValue(object.externalAuthTokenId)).unsigned = true;
                    else if (typeof object.externalAuthTokenId === "string")
                        message.externalAuthTokenId = parseInt(object.externalAuthTokenId, 10);
                    else if (typeof object.externalAuthTokenId === "number")
                        message.externalAuthTokenId = object.externalAuthTokenId;
                    else if (typeof object.externalAuthTokenId === "object")
                        message.externalAuthTokenId = new $util.LongBits(object.externalAuthTokenId.low >>> 0, object.externalAuthTokenId.high >>> 0).toNumber(true);
                if (object.companyId != null)
                    if ($util.Long)
                        (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = true;
                    else if (typeof object.companyId === "string")
                        message.companyId = parseInt(object.companyId, 10);
                    else if (typeof object.companyId === "number")
                        message.companyId = object.companyId;
                    else if (typeof object.companyId === "object")
                        message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber(true);
                if (object.externalToken != null)
                    message.externalToken = String(object.externalToken);
                if (object.urlToAuth != null)
                    message.urlToAuth = String(object.urlToAuth);
                switch (object.source) {
                case "UNKNOWN":
                case 0:
                    message.source = 0;
                    break;
                case "IIKO":
                case 1:
                    message.source = 1;
                    break;
                }
                return message;
            };

            /**
             * Creates a plain object from an ExternalAuthToken message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.ExternalAuthToken
             * @static
             * @param {fastcity.admin.ExternalAuthToken} message ExternalAuthToken
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ExternalAuthToken.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.externalAuthTokenId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.externalAuthTokenId = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.companyId = options.longs === String ? "0" : 0;
                    object.externalToken = "";
                    object.urlToAuth = "";
                    object.source = options.enums === String ? "UNKNOWN" : 0;
                }
                if (message.externalAuthTokenId != null && message.hasOwnProperty("externalAuthTokenId"))
                    if (typeof message.externalAuthTokenId === "number")
                        object.externalAuthTokenId = options.longs === String ? String(message.externalAuthTokenId) : message.externalAuthTokenId;
                    else
                        object.externalAuthTokenId = options.longs === String ? $util.Long.prototype.toString.call(message.externalAuthTokenId) : options.longs === Number ? new $util.LongBits(message.externalAuthTokenId.low >>> 0, message.externalAuthTokenId.high >>> 0).toNumber(true) : message.externalAuthTokenId;
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (typeof message.companyId === "number")
                        object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                    else
                        object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber(true) : message.companyId;
                if (message.externalToken != null && message.hasOwnProperty("externalToken"))
                    object.externalToken = message.externalToken;
                if (message.urlToAuth != null && message.hasOwnProperty("urlToAuth"))
                    object.urlToAuth = message.urlToAuth;
                if (message.source != null && message.hasOwnProperty("source"))
                    object.source = options.enums === String ? $root.fastcity.common.Source[message.source] : message.source;
                return object;
            };

            /**
             * Converts this ExternalAuthToken to JSON.
             * @function toJSON
             * @memberof fastcity.admin.ExternalAuthToken
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ExternalAuthToken.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return ExternalAuthToken;
        })();

        admin.ExternalAuthTokenList = (function() {

            /**
             * Properties of an ExternalAuthTokenList.
             * @memberof fastcity.admin
             * @interface IExternalAuthTokenList
             * @property {Array.<fastcity.admin.IExternalAuthToken>|null} [externalAuthToken] ExternalAuthTokenList externalAuthToken
             */

            /**
             * Constructs a new ExternalAuthTokenList.
             * @memberof fastcity.admin
             * @classdesc Represents an ExternalAuthTokenList.
             * @implements IExternalAuthTokenList
             * @constructor
             * @param {fastcity.admin.IExternalAuthTokenList=} [properties] Properties to set
             */
            function ExternalAuthTokenList(properties) {
                this.externalAuthToken = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ExternalAuthTokenList externalAuthToken.
             * @member {Array.<fastcity.admin.IExternalAuthToken>} externalAuthToken
             * @memberof fastcity.admin.ExternalAuthTokenList
             * @instance
             */
            ExternalAuthTokenList.prototype.externalAuthToken = $util.emptyArray;

            /**
             * Creates a new ExternalAuthTokenList instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.ExternalAuthTokenList
             * @static
             * @param {fastcity.admin.IExternalAuthTokenList=} [properties] Properties to set
             * @returns {fastcity.admin.ExternalAuthTokenList} ExternalAuthTokenList instance
             */
            ExternalAuthTokenList.create = function create(properties) {
                return new ExternalAuthTokenList(properties);
            };

            /**
             * Encodes the specified ExternalAuthTokenList message. Does not implicitly {@link fastcity.admin.ExternalAuthTokenList.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.ExternalAuthTokenList
             * @static
             * @param {fastcity.admin.IExternalAuthTokenList} message ExternalAuthTokenList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ExternalAuthTokenList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.externalAuthToken != null && message.externalAuthToken.length)
                    for (var i = 0; i < message.externalAuthToken.length; ++i)
                        $root.fastcity.admin.ExternalAuthToken.encode(message.externalAuthToken[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes an ExternalAuthTokenList message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.ExternalAuthTokenList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.ExternalAuthTokenList} ExternalAuthTokenList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ExternalAuthTokenList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.ExternalAuthTokenList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.externalAuthToken && message.externalAuthToken.length))
                            message.externalAuthToken = [];
                        message.externalAuthToken.push($root.fastcity.admin.ExternalAuthToken.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an ExternalAuthTokenList message.
             * @function verify
             * @memberof fastcity.admin.ExternalAuthTokenList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ExternalAuthTokenList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.externalAuthToken != null && message.hasOwnProperty("externalAuthToken")) {
                    if (!Array.isArray(message.externalAuthToken))
                        return "externalAuthToken: array expected";
                    for (var i = 0; i < message.externalAuthToken.length; ++i) {
                        var error = $root.fastcity.admin.ExternalAuthToken.verify(message.externalAuthToken[i]);
                        if (error)
                            return "externalAuthToken." + error;
                    }
                }
                return null;
            };

            /**
             * Creates an ExternalAuthTokenList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.ExternalAuthTokenList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.ExternalAuthTokenList} ExternalAuthTokenList
             */
            ExternalAuthTokenList.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.ExternalAuthTokenList)
                    return object;
                var message = new $root.fastcity.admin.ExternalAuthTokenList();
                if (object.externalAuthToken) {
                    if (!Array.isArray(object.externalAuthToken))
                        throw TypeError(".fastcity.admin.ExternalAuthTokenList.externalAuthToken: array expected");
                    message.externalAuthToken = [];
                    for (var i = 0; i < object.externalAuthToken.length; ++i) {
                        if (typeof object.externalAuthToken[i] !== "object")
                            throw TypeError(".fastcity.admin.ExternalAuthTokenList.externalAuthToken: object expected");
                        message.externalAuthToken[i] = $root.fastcity.admin.ExternalAuthToken.fromObject(object.externalAuthToken[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from an ExternalAuthTokenList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.ExternalAuthTokenList
             * @static
             * @param {fastcity.admin.ExternalAuthTokenList} message ExternalAuthTokenList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ExternalAuthTokenList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.externalAuthToken = [];
                if (message.externalAuthToken && message.externalAuthToken.length) {
                    object.externalAuthToken = [];
                    for (var j = 0; j < message.externalAuthToken.length; ++j)
                        object.externalAuthToken[j] = $root.fastcity.admin.ExternalAuthToken.toObject(message.externalAuthToken[j], options);
                }
                return object;
            };

            /**
             * Converts this ExternalAuthTokenList to JSON.
             * @function toJSON
             * @memberof fastcity.admin.ExternalAuthTokenList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ExternalAuthTokenList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return ExternalAuthTokenList;
        })();

        admin.CreateExternalAuthToken = (function() {

            /**
             * Properties of a CreateExternalAuthToken.
             * @memberof fastcity.admin
             * @interface ICreateExternalAuthToken
             * @property {number|Long|null} [companyId] CreateExternalAuthToken companyId
             * @property {string|null} [externalToken] CreateExternalAuthToken externalToken
             * @property {string|null} [urlToAuth] CreateExternalAuthToken urlToAuth
             * @property {fastcity.common.Source|null} [source] CreateExternalAuthToken source
             */

            /**
             * Constructs a new CreateExternalAuthToken.
             * @memberof fastcity.admin
             * @classdesc Represents a CreateExternalAuthToken.
             * @implements ICreateExternalAuthToken
             * @constructor
             * @param {fastcity.admin.ICreateExternalAuthToken=} [properties] Properties to set
             */
            function CreateExternalAuthToken(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CreateExternalAuthToken companyId.
             * @member {number|Long} companyId
             * @memberof fastcity.admin.CreateExternalAuthToken
             * @instance
             */
            CreateExternalAuthToken.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * CreateExternalAuthToken externalToken.
             * @member {string} externalToken
             * @memberof fastcity.admin.CreateExternalAuthToken
             * @instance
             */
            CreateExternalAuthToken.prototype.externalToken = "";

            /**
             * CreateExternalAuthToken urlToAuth.
             * @member {string} urlToAuth
             * @memberof fastcity.admin.CreateExternalAuthToken
             * @instance
             */
            CreateExternalAuthToken.prototype.urlToAuth = "";

            /**
             * CreateExternalAuthToken source.
             * @member {fastcity.common.Source} source
             * @memberof fastcity.admin.CreateExternalAuthToken
             * @instance
             */
            CreateExternalAuthToken.prototype.source = 0;

            /**
             * Creates a new CreateExternalAuthToken instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.CreateExternalAuthToken
             * @static
             * @param {fastcity.admin.ICreateExternalAuthToken=} [properties] Properties to set
             * @returns {fastcity.admin.CreateExternalAuthToken} CreateExternalAuthToken instance
             */
            CreateExternalAuthToken.create = function create(properties) {
                return new CreateExternalAuthToken(properties);
            };

            /**
             * Encodes the specified CreateExternalAuthToken message. Does not implicitly {@link fastcity.admin.CreateExternalAuthToken.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.CreateExternalAuthToken
             * @static
             * @param {fastcity.admin.ICreateExternalAuthToken} message CreateExternalAuthToken message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateExternalAuthToken.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.companyId);
                if (message.externalToken != null && Object.hasOwnProperty.call(message, "externalToken"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.externalToken);
                if (message.urlToAuth != null && Object.hasOwnProperty.call(message, "urlToAuth"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.urlToAuth);
                if (message.source != null && Object.hasOwnProperty.call(message, "source"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int32(message.source);
                return writer;
            };

            /**
             * Decodes a CreateExternalAuthToken message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.CreateExternalAuthToken
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.CreateExternalAuthToken} CreateExternalAuthToken
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateExternalAuthToken.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.CreateExternalAuthToken();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.companyId = reader.uint64();
                        break;
                    case 2:
                        message.externalToken = reader.string();
                        break;
                    case 3:
                        message.urlToAuth = reader.string();
                        break;
                    case 4:
                        message.source = reader.int32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a CreateExternalAuthToken message.
             * @function verify
             * @memberof fastcity.admin.CreateExternalAuthToken
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CreateExternalAuthToken.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                        return "companyId: integer|Long expected";
                if (message.externalToken != null && message.hasOwnProperty("externalToken"))
                    if (!$util.isString(message.externalToken))
                        return "externalToken: string expected";
                if (message.urlToAuth != null && message.hasOwnProperty("urlToAuth"))
                    if (!$util.isString(message.urlToAuth))
                        return "urlToAuth: string expected";
                if (message.source != null && message.hasOwnProperty("source"))
                    switch (message.source) {
                    default:
                        return "source: enum value expected";
                    case 0:
                    case 1:
                        break;
                    }
                return null;
            };

            /**
             * Creates a CreateExternalAuthToken message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.CreateExternalAuthToken
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.CreateExternalAuthToken} CreateExternalAuthToken
             */
            CreateExternalAuthToken.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.CreateExternalAuthToken)
                    return object;
                var message = new $root.fastcity.admin.CreateExternalAuthToken();
                if (object.companyId != null)
                    if ($util.Long)
                        (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = true;
                    else if (typeof object.companyId === "string")
                        message.companyId = parseInt(object.companyId, 10);
                    else if (typeof object.companyId === "number")
                        message.companyId = object.companyId;
                    else if (typeof object.companyId === "object")
                        message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber(true);
                if (object.externalToken != null)
                    message.externalToken = String(object.externalToken);
                if (object.urlToAuth != null)
                    message.urlToAuth = String(object.urlToAuth);
                switch (object.source) {
                case "UNKNOWN":
                case 0:
                    message.source = 0;
                    break;
                case "IIKO":
                case 1:
                    message.source = 1;
                    break;
                }
                return message;
            };

            /**
             * Creates a plain object from a CreateExternalAuthToken message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.CreateExternalAuthToken
             * @static
             * @param {fastcity.admin.CreateExternalAuthToken} message CreateExternalAuthToken
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateExternalAuthToken.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.companyId = options.longs === String ? "0" : 0;
                    object.externalToken = "";
                    object.urlToAuth = "";
                    object.source = options.enums === String ? "UNKNOWN" : 0;
                }
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (typeof message.companyId === "number")
                        object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                    else
                        object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber(true) : message.companyId;
                if (message.externalToken != null && message.hasOwnProperty("externalToken"))
                    object.externalToken = message.externalToken;
                if (message.urlToAuth != null && message.hasOwnProperty("urlToAuth"))
                    object.urlToAuth = message.urlToAuth;
                if (message.source != null && message.hasOwnProperty("source"))
                    object.source = options.enums === String ? $root.fastcity.common.Source[message.source] : message.source;
                return object;
            };

            /**
             * Converts this CreateExternalAuthToken to JSON.
             * @function toJSON
             * @memberof fastcity.admin.CreateExternalAuthToken
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateExternalAuthToken.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return CreateExternalAuthToken;
        })();

        admin.UpdateExternalAuthToken = (function() {

            /**
             * Properties of an UpdateExternalAuthToken.
             * @memberof fastcity.admin
             * @interface IUpdateExternalAuthToken
             * @property {number|Long|null} [externalAuthTokenId] UpdateExternalAuthToken externalAuthTokenId
             * @property {number|Long|null} [companyId] UpdateExternalAuthToken companyId
             * @property {string|null} [externalToken] UpdateExternalAuthToken externalToken
             * @property {string|null} [urlToAuth] UpdateExternalAuthToken urlToAuth
             * @property {fastcity.common.Source|null} [source] UpdateExternalAuthToken source
             */

            /**
             * Constructs a new UpdateExternalAuthToken.
             * @memberof fastcity.admin
             * @classdesc Represents an UpdateExternalAuthToken.
             * @implements IUpdateExternalAuthToken
             * @constructor
             * @param {fastcity.admin.IUpdateExternalAuthToken=} [properties] Properties to set
             */
            function UpdateExternalAuthToken(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * UpdateExternalAuthToken externalAuthTokenId.
             * @member {number|Long} externalAuthTokenId
             * @memberof fastcity.admin.UpdateExternalAuthToken
             * @instance
             */
            UpdateExternalAuthToken.prototype.externalAuthTokenId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * UpdateExternalAuthToken companyId.
             * @member {number|Long} companyId
             * @memberof fastcity.admin.UpdateExternalAuthToken
             * @instance
             */
            UpdateExternalAuthToken.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * UpdateExternalAuthToken externalToken.
             * @member {string} externalToken
             * @memberof fastcity.admin.UpdateExternalAuthToken
             * @instance
             */
            UpdateExternalAuthToken.prototype.externalToken = "";

            /**
             * UpdateExternalAuthToken urlToAuth.
             * @member {string} urlToAuth
             * @memberof fastcity.admin.UpdateExternalAuthToken
             * @instance
             */
            UpdateExternalAuthToken.prototype.urlToAuth = "";

            /**
             * UpdateExternalAuthToken source.
             * @member {fastcity.common.Source} source
             * @memberof fastcity.admin.UpdateExternalAuthToken
             * @instance
             */
            UpdateExternalAuthToken.prototype.source = 0;

            /**
             * Creates a new UpdateExternalAuthToken instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.UpdateExternalAuthToken
             * @static
             * @param {fastcity.admin.IUpdateExternalAuthToken=} [properties] Properties to set
             * @returns {fastcity.admin.UpdateExternalAuthToken} UpdateExternalAuthToken instance
             */
            UpdateExternalAuthToken.create = function create(properties) {
                return new UpdateExternalAuthToken(properties);
            };

            /**
             * Encodes the specified UpdateExternalAuthToken message. Does not implicitly {@link fastcity.admin.UpdateExternalAuthToken.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.UpdateExternalAuthToken
             * @static
             * @param {fastcity.admin.IUpdateExternalAuthToken} message UpdateExternalAuthToken message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateExternalAuthToken.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.externalAuthTokenId != null && Object.hasOwnProperty.call(message, "externalAuthTokenId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.externalAuthTokenId);
                if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.companyId);
                if (message.externalToken != null && Object.hasOwnProperty.call(message, "externalToken"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.externalToken);
                if (message.urlToAuth != null && Object.hasOwnProperty.call(message, "urlToAuth"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.urlToAuth);
                if (message.source != null && Object.hasOwnProperty.call(message, "source"))
                    writer.uint32(/* id 5, wireType 0 =*/40).int32(message.source);
                return writer;
            };

            /**
             * Decodes an UpdateExternalAuthToken message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.UpdateExternalAuthToken
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.UpdateExternalAuthToken} UpdateExternalAuthToken
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateExternalAuthToken.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.UpdateExternalAuthToken();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.externalAuthTokenId = reader.uint64();
                        break;
                    case 2:
                        message.companyId = reader.uint64();
                        break;
                    case 3:
                        message.externalToken = reader.string();
                        break;
                    case 4:
                        message.urlToAuth = reader.string();
                        break;
                    case 5:
                        message.source = reader.int32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an UpdateExternalAuthToken message.
             * @function verify
             * @memberof fastcity.admin.UpdateExternalAuthToken
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            UpdateExternalAuthToken.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.externalAuthTokenId != null && message.hasOwnProperty("externalAuthTokenId"))
                    if (!$util.isInteger(message.externalAuthTokenId) && !(message.externalAuthTokenId && $util.isInteger(message.externalAuthTokenId.low) && $util.isInteger(message.externalAuthTokenId.high)))
                        return "externalAuthTokenId: integer|Long expected";
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                        return "companyId: integer|Long expected";
                if (message.externalToken != null && message.hasOwnProperty("externalToken"))
                    if (!$util.isString(message.externalToken))
                        return "externalToken: string expected";
                if (message.urlToAuth != null && message.hasOwnProperty("urlToAuth"))
                    if (!$util.isString(message.urlToAuth))
                        return "urlToAuth: string expected";
                if (message.source != null && message.hasOwnProperty("source"))
                    switch (message.source) {
                    default:
                        return "source: enum value expected";
                    case 0:
                    case 1:
                        break;
                    }
                return null;
            };

            /**
             * Creates an UpdateExternalAuthToken message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.UpdateExternalAuthToken
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.UpdateExternalAuthToken} UpdateExternalAuthToken
             */
            UpdateExternalAuthToken.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.UpdateExternalAuthToken)
                    return object;
                var message = new $root.fastcity.admin.UpdateExternalAuthToken();
                if (object.externalAuthTokenId != null)
                    if ($util.Long)
                        (message.externalAuthTokenId = $util.Long.fromValue(object.externalAuthTokenId)).unsigned = true;
                    else if (typeof object.externalAuthTokenId === "string")
                        message.externalAuthTokenId = parseInt(object.externalAuthTokenId, 10);
                    else if (typeof object.externalAuthTokenId === "number")
                        message.externalAuthTokenId = object.externalAuthTokenId;
                    else if (typeof object.externalAuthTokenId === "object")
                        message.externalAuthTokenId = new $util.LongBits(object.externalAuthTokenId.low >>> 0, object.externalAuthTokenId.high >>> 0).toNumber(true);
                if (object.companyId != null)
                    if ($util.Long)
                        (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = true;
                    else if (typeof object.companyId === "string")
                        message.companyId = parseInt(object.companyId, 10);
                    else if (typeof object.companyId === "number")
                        message.companyId = object.companyId;
                    else if (typeof object.companyId === "object")
                        message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber(true);
                if (object.externalToken != null)
                    message.externalToken = String(object.externalToken);
                if (object.urlToAuth != null)
                    message.urlToAuth = String(object.urlToAuth);
                switch (object.source) {
                case "UNKNOWN":
                case 0:
                    message.source = 0;
                    break;
                case "IIKO":
                case 1:
                    message.source = 1;
                    break;
                }
                return message;
            };

            /**
             * Creates a plain object from an UpdateExternalAuthToken message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.UpdateExternalAuthToken
             * @static
             * @param {fastcity.admin.UpdateExternalAuthToken} message UpdateExternalAuthToken
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UpdateExternalAuthToken.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.externalAuthTokenId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.externalAuthTokenId = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.companyId = options.longs === String ? "0" : 0;
                    object.externalToken = "";
                    object.urlToAuth = "";
                    object.source = options.enums === String ? "UNKNOWN" : 0;
                }
                if (message.externalAuthTokenId != null && message.hasOwnProperty("externalAuthTokenId"))
                    if (typeof message.externalAuthTokenId === "number")
                        object.externalAuthTokenId = options.longs === String ? String(message.externalAuthTokenId) : message.externalAuthTokenId;
                    else
                        object.externalAuthTokenId = options.longs === String ? $util.Long.prototype.toString.call(message.externalAuthTokenId) : options.longs === Number ? new $util.LongBits(message.externalAuthTokenId.low >>> 0, message.externalAuthTokenId.high >>> 0).toNumber(true) : message.externalAuthTokenId;
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (typeof message.companyId === "number")
                        object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                    else
                        object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber(true) : message.companyId;
                if (message.externalToken != null && message.hasOwnProperty("externalToken"))
                    object.externalToken = message.externalToken;
                if (message.urlToAuth != null && message.hasOwnProperty("urlToAuth"))
                    object.urlToAuth = message.urlToAuth;
                if (message.source != null && message.hasOwnProperty("source"))
                    object.source = options.enums === String ? $root.fastcity.common.Source[message.source] : message.source;
                return object;
            };

            /**
             * Converts this UpdateExternalAuthToken to JSON.
             * @function toJSON
             * @memberof fastcity.admin.UpdateExternalAuthToken
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UpdateExternalAuthToken.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return UpdateExternalAuthToken;
        })();

        admin.BindExternalAuthTokenToSubCompany = (function() {

            /**
             * Properties of a BindExternalAuthTokenToSubCompany.
             * @memberof fastcity.admin
             * @interface IBindExternalAuthTokenToSubCompany
             * @property {number|Long|null} [externalAuthTokenId] BindExternalAuthTokenToSubCompany externalAuthTokenId
             * @property {number|Long|null} [subCompanyId] BindExternalAuthTokenToSubCompany subCompanyId
             */

            /**
             * Constructs a new BindExternalAuthTokenToSubCompany.
             * @memberof fastcity.admin
             * @classdesc Represents a BindExternalAuthTokenToSubCompany.
             * @implements IBindExternalAuthTokenToSubCompany
             * @constructor
             * @param {fastcity.admin.IBindExternalAuthTokenToSubCompany=} [properties] Properties to set
             */
            function BindExternalAuthTokenToSubCompany(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * BindExternalAuthTokenToSubCompany externalAuthTokenId.
             * @member {number|Long} externalAuthTokenId
             * @memberof fastcity.admin.BindExternalAuthTokenToSubCompany
             * @instance
             */
            BindExternalAuthTokenToSubCompany.prototype.externalAuthTokenId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * BindExternalAuthTokenToSubCompany subCompanyId.
             * @member {number|Long} subCompanyId
             * @memberof fastcity.admin.BindExternalAuthTokenToSubCompany
             * @instance
             */
            BindExternalAuthTokenToSubCompany.prototype.subCompanyId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Creates a new BindExternalAuthTokenToSubCompany instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.BindExternalAuthTokenToSubCompany
             * @static
             * @param {fastcity.admin.IBindExternalAuthTokenToSubCompany=} [properties] Properties to set
             * @returns {fastcity.admin.BindExternalAuthTokenToSubCompany} BindExternalAuthTokenToSubCompany instance
             */
            BindExternalAuthTokenToSubCompany.create = function create(properties) {
                return new BindExternalAuthTokenToSubCompany(properties);
            };

            /**
             * Encodes the specified BindExternalAuthTokenToSubCompany message. Does not implicitly {@link fastcity.admin.BindExternalAuthTokenToSubCompany.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.BindExternalAuthTokenToSubCompany
             * @static
             * @param {fastcity.admin.IBindExternalAuthTokenToSubCompany} message BindExternalAuthTokenToSubCompany message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BindExternalAuthTokenToSubCompany.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.externalAuthTokenId != null && Object.hasOwnProperty.call(message, "externalAuthTokenId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.externalAuthTokenId);
                if (message.subCompanyId != null && Object.hasOwnProperty.call(message, "subCompanyId"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.subCompanyId);
                return writer;
            };

            /**
             * Decodes a BindExternalAuthTokenToSubCompany message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.BindExternalAuthTokenToSubCompany
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.BindExternalAuthTokenToSubCompany} BindExternalAuthTokenToSubCompany
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BindExternalAuthTokenToSubCompany.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.BindExternalAuthTokenToSubCompany();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.externalAuthTokenId = reader.uint64();
                        break;
                    case 2:
                        message.subCompanyId = reader.uint64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a BindExternalAuthTokenToSubCompany message.
             * @function verify
             * @memberof fastcity.admin.BindExternalAuthTokenToSubCompany
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            BindExternalAuthTokenToSubCompany.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.externalAuthTokenId != null && message.hasOwnProperty("externalAuthTokenId"))
                    if (!$util.isInteger(message.externalAuthTokenId) && !(message.externalAuthTokenId && $util.isInteger(message.externalAuthTokenId.low) && $util.isInteger(message.externalAuthTokenId.high)))
                        return "externalAuthTokenId: integer|Long expected";
                if (message.subCompanyId != null && message.hasOwnProperty("subCompanyId"))
                    if (!$util.isInteger(message.subCompanyId) && !(message.subCompanyId && $util.isInteger(message.subCompanyId.low) && $util.isInteger(message.subCompanyId.high)))
                        return "subCompanyId: integer|Long expected";
                return null;
            };

            /**
             * Creates a BindExternalAuthTokenToSubCompany message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.BindExternalAuthTokenToSubCompany
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.BindExternalAuthTokenToSubCompany} BindExternalAuthTokenToSubCompany
             */
            BindExternalAuthTokenToSubCompany.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.BindExternalAuthTokenToSubCompany)
                    return object;
                var message = new $root.fastcity.admin.BindExternalAuthTokenToSubCompany();
                if (object.externalAuthTokenId != null)
                    if ($util.Long)
                        (message.externalAuthTokenId = $util.Long.fromValue(object.externalAuthTokenId)).unsigned = true;
                    else if (typeof object.externalAuthTokenId === "string")
                        message.externalAuthTokenId = parseInt(object.externalAuthTokenId, 10);
                    else if (typeof object.externalAuthTokenId === "number")
                        message.externalAuthTokenId = object.externalAuthTokenId;
                    else if (typeof object.externalAuthTokenId === "object")
                        message.externalAuthTokenId = new $util.LongBits(object.externalAuthTokenId.low >>> 0, object.externalAuthTokenId.high >>> 0).toNumber(true);
                if (object.subCompanyId != null)
                    if ($util.Long)
                        (message.subCompanyId = $util.Long.fromValue(object.subCompanyId)).unsigned = true;
                    else if (typeof object.subCompanyId === "string")
                        message.subCompanyId = parseInt(object.subCompanyId, 10);
                    else if (typeof object.subCompanyId === "number")
                        message.subCompanyId = object.subCompanyId;
                    else if (typeof object.subCompanyId === "object")
                        message.subCompanyId = new $util.LongBits(object.subCompanyId.low >>> 0, object.subCompanyId.high >>> 0).toNumber(true);
                return message;
            };

            /**
             * Creates a plain object from a BindExternalAuthTokenToSubCompany message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.BindExternalAuthTokenToSubCompany
             * @static
             * @param {fastcity.admin.BindExternalAuthTokenToSubCompany} message BindExternalAuthTokenToSubCompany
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            BindExternalAuthTokenToSubCompany.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.externalAuthTokenId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.externalAuthTokenId = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.subCompanyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.subCompanyId = options.longs === String ? "0" : 0;
                }
                if (message.externalAuthTokenId != null && message.hasOwnProperty("externalAuthTokenId"))
                    if (typeof message.externalAuthTokenId === "number")
                        object.externalAuthTokenId = options.longs === String ? String(message.externalAuthTokenId) : message.externalAuthTokenId;
                    else
                        object.externalAuthTokenId = options.longs === String ? $util.Long.prototype.toString.call(message.externalAuthTokenId) : options.longs === Number ? new $util.LongBits(message.externalAuthTokenId.low >>> 0, message.externalAuthTokenId.high >>> 0).toNumber(true) : message.externalAuthTokenId;
                if (message.subCompanyId != null && message.hasOwnProperty("subCompanyId"))
                    if (typeof message.subCompanyId === "number")
                        object.subCompanyId = options.longs === String ? String(message.subCompanyId) : message.subCompanyId;
                    else
                        object.subCompanyId = options.longs === String ? $util.Long.prototype.toString.call(message.subCompanyId) : options.longs === Number ? new $util.LongBits(message.subCompanyId.low >>> 0, message.subCompanyId.high >>> 0).toNumber(true) : message.subCompanyId;
                return object;
            };

            /**
             * Converts this BindExternalAuthTokenToSubCompany to JSON.
             * @function toJSON
             * @memberof fastcity.admin.BindExternalAuthTokenToSubCompany
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            BindExternalAuthTokenToSubCompany.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return BindExternalAuthTokenToSubCompany;
        })();

        admin.DeleteExternalAuthToken = (function() {

            /**
             * Properties of a DeleteExternalAuthToken.
             * @memberof fastcity.admin
             * @interface IDeleteExternalAuthToken
             * @property {number|Long|null} [externalAuthTokenId] DeleteExternalAuthToken externalAuthTokenId
             */

            /**
             * Constructs a new DeleteExternalAuthToken.
             * @memberof fastcity.admin
             * @classdesc Represents a DeleteExternalAuthToken.
             * @implements IDeleteExternalAuthToken
             * @constructor
             * @param {fastcity.admin.IDeleteExternalAuthToken=} [properties] Properties to set
             */
            function DeleteExternalAuthToken(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * DeleteExternalAuthToken externalAuthTokenId.
             * @member {number|Long} externalAuthTokenId
             * @memberof fastcity.admin.DeleteExternalAuthToken
             * @instance
             */
            DeleteExternalAuthToken.prototype.externalAuthTokenId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Creates a new DeleteExternalAuthToken instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.DeleteExternalAuthToken
             * @static
             * @param {fastcity.admin.IDeleteExternalAuthToken=} [properties] Properties to set
             * @returns {fastcity.admin.DeleteExternalAuthToken} DeleteExternalAuthToken instance
             */
            DeleteExternalAuthToken.create = function create(properties) {
                return new DeleteExternalAuthToken(properties);
            };

            /**
             * Encodes the specified DeleteExternalAuthToken message. Does not implicitly {@link fastcity.admin.DeleteExternalAuthToken.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.DeleteExternalAuthToken
             * @static
             * @param {fastcity.admin.IDeleteExternalAuthToken} message DeleteExternalAuthToken message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteExternalAuthToken.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.externalAuthTokenId != null && Object.hasOwnProperty.call(message, "externalAuthTokenId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.externalAuthTokenId);
                return writer;
            };

            /**
             * Decodes a DeleteExternalAuthToken message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.DeleteExternalAuthToken
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.DeleteExternalAuthToken} DeleteExternalAuthToken
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteExternalAuthToken.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.DeleteExternalAuthToken();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.externalAuthTokenId = reader.uint64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a DeleteExternalAuthToken message.
             * @function verify
             * @memberof fastcity.admin.DeleteExternalAuthToken
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DeleteExternalAuthToken.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.externalAuthTokenId != null && message.hasOwnProperty("externalAuthTokenId"))
                    if (!$util.isInteger(message.externalAuthTokenId) && !(message.externalAuthTokenId && $util.isInteger(message.externalAuthTokenId.low) && $util.isInteger(message.externalAuthTokenId.high)))
                        return "externalAuthTokenId: integer|Long expected";
                return null;
            };

            /**
             * Creates a DeleteExternalAuthToken message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.DeleteExternalAuthToken
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.DeleteExternalAuthToken} DeleteExternalAuthToken
             */
            DeleteExternalAuthToken.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.DeleteExternalAuthToken)
                    return object;
                var message = new $root.fastcity.admin.DeleteExternalAuthToken();
                if (object.externalAuthTokenId != null)
                    if ($util.Long)
                        (message.externalAuthTokenId = $util.Long.fromValue(object.externalAuthTokenId)).unsigned = true;
                    else if (typeof object.externalAuthTokenId === "string")
                        message.externalAuthTokenId = parseInt(object.externalAuthTokenId, 10);
                    else if (typeof object.externalAuthTokenId === "number")
                        message.externalAuthTokenId = object.externalAuthTokenId;
                    else if (typeof object.externalAuthTokenId === "object")
                        message.externalAuthTokenId = new $util.LongBits(object.externalAuthTokenId.low >>> 0, object.externalAuthTokenId.high >>> 0).toNumber(true);
                return message;
            };

            /**
             * Creates a plain object from a DeleteExternalAuthToken message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.DeleteExternalAuthToken
             * @static
             * @param {fastcity.admin.DeleteExternalAuthToken} message DeleteExternalAuthToken
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DeleteExternalAuthToken.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.externalAuthTokenId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.externalAuthTokenId = options.longs === String ? "0" : 0;
                if (message.externalAuthTokenId != null && message.hasOwnProperty("externalAuthTokenId"))
                    if (typeof message.externalAuthTokenId === "number")
                        object.externalAuthTokenId = options.longs === String ? String(message.externalAuthTokenId) : message.externalAuthTokenId;
                    else
                        object.externalAuthTokenId = options.longs === String ? $util.Long.prototype.toString.call(message.externalAuthTokenId) : options.longs === Number ? new $util.LongBits(message.externalAuthTokenId.low >>> 0, message.externalAuthTokenId.high >>> 0).toNumber(true) : message.externalAuthTokenId;
                return object;
            };

            /**
             * Converts this DeleteExternalAuthToken to JSON.
             * @function toJSON
             * @memberof fastcity.admin.DeleteExternalAuthToken
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DeleteExternalAuthToken.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return DeleteExternalAuthToken;
        })();

        admin.GetExternalAuthTokenList = (function() {

            /**
             * Properties of a GetExternalAuthTokenList.
             * @memberof fastcity.admin
             * @interface IGetExternalAuthTokenList
             * @property {number|Long|null} [companyId] GetExternalAuthTokenList companyId
             */

            /**
             * Constructs a new GetExternalAuthTokenList.
             * @memberof fastcity.admin
             * @classdesc Represents a GetExternalAuthTokenList.
             * @implements IGetExternalAuthTokenList
             * @constructor
             * @param {fastcity.admin.IGetExternalAuthTokenList=} [properties] Properties to set
             */
            function GetExternalAuthTokenList(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GetExternalAuthTokenList companyId.
             * @member {number|Long} companyId
             * @memberof fastcity.admin.GetExternalAuthTokenList
             * @instance
             */
            GetExternalAuthTokenList.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Creates a new GetExternalAuthTokenList instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.GetExternalAuthTokenList
             * @static
             * @param {fastcity.admin.IGetExternalAuthTokenList=} [properties] Properties to set
             * @returns {fastcity.admin.GetExternalAuthTokenList} GetExternalAuthTokenList instance
             */
            GetExternalAuthTokenList.create = function create(properties) {
                return new GetExternalAuthTokenList(properties);
            };

            /**
             * Encodes the specified GetExternalAuthTokenList message. Does not implicitly {@link fastcity.admin.GetExternalAuthTokenList.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.GetExternalAuthTokenList
             * @static
             * @param {fastcity.admin.IGetExternalAuthTokenList} message GetExternalAuthTokenList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetExternalAuthTokenList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.companyId);
                return writer;
            };

            /**
             * Decodes a GetExternalAuthTokenList message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.GetExternalAuthTokenList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.GetExternalAuthTokenList} GetExternalAuthTokenList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetExternalAuthTokenList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.GetExternalAuthTokenList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.companyId = reader.uint64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a GetExternalAuthTokenList message.
             * @function verify
             * @memberof fastcity.admin.GetExternalAuthTokenList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetExternalAuthTokenList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                        return "companyId: integer|Long expected";
                return null;
            };

            /**
             * Creates a GetExternalAuthTokenList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.GetExternalAuthTokenList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.GetExternalAuthTokenList} GetExternalAuthTokenList
             */
            GetExternalAuthTokenList.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.GetExternalAuthTokenList)
                    return object;
                var message = new $root.fastcity.admin.GetExternalAuthTokenList();
                if (object.companyId != null)
                    if ($util.Long)
                        (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = true;
                    else if (typeof object.companyId === "string")
                        message.companyId = parseInt(object.companyId, 10);
                    else if (typeof object.companyId === "number")
                        message.companyId = object.companyId;
                    else if (typeof object.companyId === "object")
                        message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber(true);
                return message;
            };

            /**
             * Creates a plain object from a GetExternalAuthTokenList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.GetExternalAuthTokenList
             * @static
             * @param {fastcity.admin.GetExternalAuthTokenList} message GetExternalAuthTokenList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetExternalAuthTokenList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.companyId = options.longs === String ? "0" : 0;
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (typeof message.companyId === "number")
                        object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                    else
                        object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber(true) : message.companyId;
                return object;
            };

            /**
             * Converts this GetExternalAuthTokenList to JSON.
             * @function toJSON
             * @memberof fastcity.admin.GetExternalAuthTokenList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetExternalAuthTokenList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return GetExternalAuthTokenList;
        })();

        admin.GetActiveExternalAuthToken = (function() {

            /**
             * Properties of a GetActiveExternalAuthToken.
             * @memberof fastcity.admin
             * @interface IGetActiveExternalAuthToken
             * @property {number|Long|null} [subCompanyId] GetActiveExternalAuthToken subCompanyId
             */

            /**
             * Constructs a new GetActiveExternalAuthToken.
             * @memberof fastcity.admin
             * @classdesc Represents a GetActiveExternalAuthToken.
             * @implements IGetActiveExternalAuthToken
             * @constructor
             * @param {fastcity.admin.IGetActiveExternalAuthToken=} [properties] Properties to set
             */
            function GetActiveExternalAuthToken(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GetActiveExternalAuthToken subCompanyId.
             * @member {number|Long} subCompanyId
             * @memberof fastcity.admin.GetActiveExternalAuthToken
             * @instance
             */
            GetActiveExternalAuthToken.prototype.subCompanyId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Creates a new GetActiveExternalAuthToken instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.GetActiveExternalAuthToken
             * @static
             * @param {fastcity.admin.IGetActiveExternalAuthToken=} [properties] Properties to set
             * @returns {fastcity.admin.GetActiveExternalAuthToken} GetActiveExternalAuthToken instance
             */
            GetActiveExternalAuthToken.create = function create(properties) {
                return new GetActiveExternalAuthToken(properties);
            };

            /**
             * Encodes the specified GetActiveExternalAuthToken message. Does not implicitly {@link fastcity.admin.GetActiveExternalAuthToken.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.GetActiveExternalAuthToken
             * @static
             * @param {fastcity.admin.IGetActiveExternalAuthToken} message GetActiveExternalAuthToken message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetActiveExternalAuthToken.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.subCompanyId != null && Object.hasOwnProperty.call(message, "subCompanyId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.subCompanyId);
                return writer;
            };

            /**
             * Decodes a GetActiveExternalAuthToken message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.GetActiveExternalAuthToken
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.GetActiveExternalAuthToken} GetActiveExternalAuthToken
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetActiveExternalAuthToken.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.GetActiveExternalAuthToken();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.subCompanyId = reader.uint64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a GetActiveExternalAuthToken message.
             * @function verify
             * @memberof fastcity.admin.GetActiveExternalAuthToken
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetActiveExternalAuthToken.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.subCompanyId != null && message.hasOwnProperty("subCompanyId"))
                    if (!$util.isInteger(message.subCompanyId) && !(message.subCompanyId && $util.isInteger(message.subCompanyId.low) && $util.isInteger(message.subCompanyId.high)))
                        return "subCompanyId: integer|Long expected";
                return null;
            };

            /**
             * Creates a GetActiveExternalAuthToken message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.GetActiveExternalAuthToken
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.GetActiveExternalAuthToken} GetActiveExternalAuthToken
             */
            GetActiveExternalAuthToken.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.GetActiveExternalAuthToken)
                    return object;
                var message = new $root.fastcity.admin.GetActiveExternalAuthToken();
                if (object.subCompanyId != null)
                    if ($util.Long)
                        (message.subCompanyId = $util.Long.fromValue(object.subCompanyId)).unsigned = true;
                    else if (typeof object.subCompanyId === "string")
                        message.subCompanyId = parseInt(object.subCompanyId, 10);
                    else if (typeof object.subCompanyId === "number")
                        message.subCompanyId = object.subCompanyId;
                    else if (typeof object.subCompanyId === "object")
                        message.subCompanyId = new $util.LongBits(object.subCompanyId.low >>> 0, object.subCompanyId.high >>> 0).toNumber(true);
                return message;
            };

            /**
             * Creates a plain object from a GetActiveExternalAuthToken message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.GetActiveExternalAuthToken
             * @static
             * @param {fastcity.admin.GetActiveExternalAuthToken} message GetActiveExternalAuthToken
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetActiveExternalAuthToken.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.subCompanyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.subCompanyId = options.longs === String ? "0" : 0;
                if (message.subCompanyId != null && message.hasOwnProperty("subCompanyId"))
                    if (typeof message.subCompanyId === "number")
                        object.subCompanyId = options.longs === String ? String(message.subCompanyId) : message.subCompanyId;
                    else
                        object.subCompanyId = options.longs === String ? $util.Long.prototype.toString.call(message.subCompanyId) : options.longs === Number ? new $util.LongBits(message.subCompanyId.low >>> 0, message.subCompanyId.high >>> 0).toNumber(true) : message.subCompanyId;
                return object;
            };

            /**
             * Converts this GetActiveExternalAuthToken to JSON.
             * @function toJSON
             * @memberof fastcity.admin.GetActiveExternalAuthToken
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetActiveExternalAuthToken.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return GetActiveExternalAuthToken;
        })();

        admin.CourierFromExternal = (function() {

            /**
             * Properties of a CourierFromExternal.
             * @memberof fastcity.admin
             * @interface ICourierFromExternal
             * @property {string|null} [externalCourierId] CourierFromExternal externalCourierId
             * @property {string|null} [name] CourierFromExternal name
             * @property {string|null} [phone] CourierFromExternal phone
             * @property {string|null} [alternativeName] CourierFromExternal alternativeName
             */

            /**
             * Constructs a new CourierFromExternal.
             * @memberof fastcity.admin
             * @classdesc Represents a CourierFromExternal.
             * @implements ICourierFromExternal
             * @constructor
             * @param {fastcity.admin.ICourierFromExternal=} [properties] Properties to set
             */
            function CourierFromExternal(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CourierFromExternal externalCourierId.
             * @member {string} externalCourierId
             * @memberof fastcity.admin.CourierFromExternal
             * @instance
             */
            CourierFromExternal.prototype.externalCourierId = "";

            /**
             * CourierFromExternal name.
             * @member {string} name
             * @memberof fastcity.admin.CourierFromExternal
             * @instance
             */
            CourierFromExternal.prototype.name = "";

            /**
             * CourierFromExternal phone.
             * @member {string} phone
             * @memberof fastcity.admin.CourierFromExternal
             * @instance
             */
            CourierFromExternal.prototype.phone = "";

            /**
             * CourierFromExternal alternativeName.
             * @member {string} alternativeName
             * @memberof fastcity.admin.CourierFromExternal
             * @instance
             */
            CourierFromExternal.prototype.alternativeName = "";

            /**
             * Creates a new CourierFromExternal instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.CourierFromExternal
             * @static
             * @param {fastcity.admin.ICourierFromExternal=} [properties] Properties to set
             * @returns {fastcity.admin.CourierFromExternal} CourierFromExternal instance
             */
            CourierFromExternal.create = function create(properties) {
                return new CourierFromExternal(properties);
            };

            /**
             * Encodes the specified CourierFromExternal message. Does not implicitly {@link fastcity.admin.CourierFromExternal.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.CourierFromExternal
             * @static
             * @param {fastcity.admin.ICourierFromExternal} message CourierFromExternal message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CourierFromExternal.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.externalCourierId != null && Object.hasOwnProperty.call(message, "externalCourierId"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.externalCourierId);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                if (message.phone != null && Object.hasOwnProperty.call(message, "phone"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.phone);
                if (message.alternativeName != null && Object.hasOwnProperty.call(message, "alternativeName"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.alternativeName);
                return writer;
            };

            /**
             * Decodes a CourierFromExternal message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.CourierFromExternal
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.CourierFromExternal} CourierFromExternal
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CourierFromExternal.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.CourierFromExternal();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.externalCourierId = reader.string();
                        break;
                    case 2:
                        message.name = reader.string();
                        break;
                    case 3:
                        message.phone = reader.string();
                        break;
                    case 4:
                        message.alternativeName = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a CourierFromExternal message.
             * @function verify
             * @memberof fastcity.admin.CourierFromExternal
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CourierFromExternal.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.externalCourierId != null && message.hasOwnProperty("externalCourierId"))
                    if (!$util.isString(message.externalCourierId))
                        return "externalCourierId: string expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.phone != null && message.hasOwnProperty("phone"))
                    if (!$util.isString(message.phone))
                        return "phone: string expected";
                if (message.alternativeName != null && message.hasOwnProperty("alternativeName"))
                    if (!$util.isString(message.alternativeName))
                        return "alternativeName: string expected";
                return null;
            };

            /**
             * Creates a CourierFromExternal message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.CourierFromExternal
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.CourierFromExternal} CourierFromExternal
             */
            CourierFromExternal.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.CourierFromExternal)
                    return object;
                var message = new $root.fastcity.admin.CourierFromExternal();
                if (object.externalCourierId != null)
                    message.externalCourierId = String(object.externalCourierId);
                if (object.name != null)
                    message.name = String(object.name);
                if (object.phone != null)
                    message.phone = String(object.phone);
                if (object.alternativeName != null)
                    message.alternativeName = String(object.alternativeName);
                return message;
            };

            /**
             * Creates a plain object from a CourierFromExternal message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.CourierFromExternal
             * @static
             * @param {fastcity.admin.CourierFromExternal} message CourierFromExternal
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CourierFromExternal.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.externalCourierId = "";
                    object.name = "";
                    object.phone = "";
                    object.alternativeName = "";
                }
                if (message.externalCourierId != null && message.hasOwnProperty("externalCourierId"))
                    object.externalCourierId = message.externalCourierId;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.phone != null && message.hasOwnProperty("phone"))
                    object.phone = message.phone;
                if (message.alternativeName != null && message.hasOwnProperty("alternativeName"))
                    object.alternativeName = message.alternativeName;
                return object;
            };

            /**
             * Converts this CourierFromExternal to JSON.
             * @function toJSON
             * @memberof fastcity.admin.CourierFromExternal
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CourierFromExternal.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return CourierFromExternal;
        })();

        admin.CourierFromExternalList = (function() {

            /**
             * Properties of a CourierFromExternalList.
             * @memberof fastcity.admin
             * @interface ICourierFromExternalList
             * @property {Array.<fastcity.admin.ICourierFromExternal>|null} [courierFromExternal] CourierFromExternalList courierFromExternal
             */

            /**
             * Constructs a new CourierFromExternalList.
             * @memberof fastcity.admin
             * @classdesc Represents a CourierFromExternalList.
             * @implements ICourierFromExternalList
             * @constructor
             * @param {fastcity.admin.ICourierFromExternalList=} [properties] Properties to set
             */
            function CourierFromExternalList(properties) {
                this.courierFromExternal = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CourierFromExternalList courierFromExternal.
             * @member {Array.<fastcity.admin.ICourierFromExternal>} courierFromExternal
             * @memberof fastcity.admin.CourierFromExternalList
             * @instance
             */
            CourierFromExternalList.prototype.courierFromExternal = $util.emptyArray;

            /**
             * Creates a new CourierFromExternalList instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.CourierFromExternalList
             * @static
             * @param {fastcity.admin.ICourierFromExternalList=} [properties] Properties to set
             * @returns {fastcity.admin.CourierFromExternalList} CourierFromExternalList instance
             */
            CourierFromExternalList.create = function create(properties) {
                return new CourierFromExternalList(properties);
            };

            /**
             * Encodes the specified CourierFromExternalList message. Does not implicitly {@link fastcity.admin.CourierFromExternalList.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.CourierFromExternalList
             * @static
             * @param {fastcity.admin.ICourierFromExternalList} message CourierFromExternalList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CourierFromExternalList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.courierFromExternal != null && message.courierFromExternal.length)
                    for (var i = 0; i < message.courierFromExternal.length; ++i)
                        $root.fastcity.admin.CourierFromExternal.encode(message.courierFromExternal[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a CourierFromExternalList message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.CourierFromExternalList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.CourierFromExternalList} CourierFromExternalList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CourierFromExternalList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.CourierFromExternalList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.courierFromExternal && message.courierFromExternal.length))
                            message.courierFromExternal = [];
                        message.courierFromExternal.push($root.fastcity.admin.CourierFromExternal.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a CourierFromExternalList message.
             * @function verify
             * @memberof fastcity.admin.CourierFromExternalList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CourierFromExternalList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.courierFromExternal != null && message.hasOwnProperty("courierFromExternal")) {
                    if (!Array.isArray(message.courierFromExternal))
                        return "courierFromExternal: array expected";
                    for (var i = 0; i < message.courierFromExternal.length; ++i) {
                        var error = $root.fastcity.admin.CourierFromExternal.verify(message.courierFromExternal[i]);
                        if (error)
                            return "courierFromExternal." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a CourierFromExternalList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.CourierFromExternalList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.CourierFromExternalList} CourierFromExternalList
             */
            CourierFromExternalList.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.CourierFromExternalList)
                    return object;
                var message = new $root.fastcity.admin.CourierFromExternalList();
                if (object.courierFromExternal) {
                    if (!Array.isArray(object.courierFromExternal))
                        throw TypeError(".fastcity.admin.CourierFromExternalList.courierFromExternal: array expected");
                    message.courierFromExternal = [];
                    for (var i = 0; i < object.courierFromExternal.length; ++i) {
                        if (typeof object.courierFromExternal[i] !== "object")
                            throw TypeError(".fastcity.admin.CourierFromExternalList.courierFromExternal: object expected");
                        message.courierFromExternal[i] = $root.fastcity.admin.CourierFromExternal.fromObject(object.courierFromExternal[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a CourierFromExternalList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.CourierFromExternalList
             * @static
             * @param {fastcity.admin.CourierFromExternalList} message CourierFromExternalList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CourierFromExternalList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.courierFromExternal = [];
                if (message.courierFromExternal && message.courierFromExternal.length) {
                    object.courierFromExternal = [];
                    for (var j = 0; j < message.courierFromExternal.length; ++j)
                        object.courierFromExternal[j] = $root.fastcity.admin.CourierFromExternal.toObject(message.courierFromExternal[j], options);
                }
                return object;
            };

            /**
             * Converts this CourierFromExternalList to JSON.
             * @function toJSON
             * @memberof fastcity.admin.CourierFromExternalList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CourierFromExternalList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return CourierFromExternalList;
        })();

        admin.GetCouriersFromExternal = (function() {

            /**
             * Properties of a GetCouriersFromExternal.
             * @memberof fastcity.admin
             * @interface IGetCouriersFromExternal
             * @property {number|Long|null} [subCompanyId] GetCouriersFromExternal subCompanyId
             * @property {boolean|null} [loadAdditionalInfo] GetCouriersFromExternal loadAdditionalInfo
             */

            /**
             * Constructs a new GetCouriersFromExternal.
             * @memberof fastcity.admin
             * @classdesc Represents a GetCouriersFromExternal.
             * @implements IGetCouriersFromExternal
             * @constructor
             * @param {fastcity.admin.IGetCouriersFromExternal=} [properties] Properties to set
             */
            function GetCouriersFromExternal(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GetCouriersFromExternal subCompanyId.
             * @member {number|Long} subCompanyId
             * @memberof fastcity.admin.GetCouriersFromExternal
             * @instance
             */
            GetCouriersFromExternal.prototype.subCompanyId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * GetCouriersFromExternal loadAdditionalInfo.
             * @member {boolean} loadAdditionalInfo
             * @memberof fastcity.admin.GetCouriersFromExternal
             * @instance
             */
            GetCouriersFromExternal.prototype.loadAdditionalInfo = false;

            /**
             * Creates a new GetCouriersFromExternal instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.GetCouriersFromExternal
             * @static
             * @param {fastcity.admin.IGetCouriersFromExternal=} [properties] Properties to set
             * @returns {fastcity.admin.GetCouriersFromExternal} GetCouriersFromExternal instance
             */
            GetCouriersFromExternal.create = function create(properties) {
                return new GetCouriersFromExternal(properties);
            };

            /**
             * Encodes the specified GetCouriersFromExternal message. Does not implicitly {@link fastcity.admin.GetCouriersFromExternal.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.GetCouriersFromExternal
             * @static
             * @param {fastcity.admin.IGetCouriersFromExternal} message GetCouriersFromExternal message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetCouriersFromExternal.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.subCompanyId != null && Object.hasOwnProperty.call(message, "subCompanyId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.subCompanyId);
                if (message.loadAdditionalInfo != null && Object.hasOwnProperty.call(message, "loadAdditionalInfo"))
                    writer.uint32(/* id 2, wireType 0 =*/16).bool(message.loadAdditionalInfo);
                return writer;
            };

            /**
             * Decodes a GetCouriersFromExternal message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.GetCouriersFromExternal
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.GetCouriersFromExternal} GetCouriersFromExternal
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetCouriersFromExternal.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.GetCouriersFromExternal();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.subCompanyId = reader.uint64();
                        break;
                    case 2:
                        message.loadAdditionalInfo = reader.bool();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a GetCouriersFromExternal message.
             * @function verify
             * @memberof fastcity.admin.GetCouriersFromExternal
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetCouriersFromExternal.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.subCompanyId != null && message.hasOwnProperty("subCompanyId"))
                    if (!$util.isInteger(message.subCompanyId) && !(message.subCompanyId && $util.isInteger(message.subCompanyId.low) && $util.isInteger(message.subCompanyId.high)))
                        return "subCompanyId: integer|Long expected";
                if (message.loadAdditionalInfo != null && message.hasOwnProperty("loadAdditionalInfo"))
                    if (typeof message.loadAdditionalInfo !== "boolean")
                        return "loadAdditionalInfo: boolean expected";
                return null;
            };

            /**
             * Creates a GetCouriersFromExternal message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.GetCouriersFromExternal
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.GetCouriersFromExternal} GetCouriersFromExternal
             */
            GetCouriersFromExternal.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.GetCouriersFromExternal)
                    return object;
                var message = new $root.fastcity.admin.GetCouriersFromExternal();
                if (object.subCompanyId != null)
                    if ($util.Long)
                        (message.subCompanyId = $util.Long.fromValue(object.subCompanyId)).unsigned = true;
                    else if (typeof object.subCompanyId === "string")
                        message.subCompanyId = parseInt(object.subCompanyId, 10);
                    else if (typeof object.subCompanyId === "number")
                        message.subCompanyId = object.subCompanyId;
                    else if (typeof object.subCompanyId === "object")
                        message.subCompanyId = new $util.LongBits(object.subCompanyId.low >>> 0, object.subCompanyId.high >>> 0).toNumber(true);
                if (object.loadAdditionalInfo != null)
                    message.loadAdditionalInfo = Boolean(object.loadAdditionalInfo);
                return message;
            };

            /**
             * Creates a plain object from a GetCouriersFromExternal message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.GetCouriersFromExternal
             * @static
             * @param {fastcity.admin.GetCouriersFromExternal} message GetCouriersFromExternal
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetCouriersFromExternal.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.subCompanyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.subCompanyId = options.longs === String ? "0" : 0;
                    object.loadAdditionalInfo = false;
                }
                if (message.subCompanyId != null && message.hasOwnProperty("subCompanyId"))
                    if (typeof message.subCompanyId === "number")
                        object.subCompanyId = options.longs === String ? String(message.subCompanyId) : message.subCompanyId;
                    else
                        object.subCompanyId = options.longs === String ? $util.Long.prototype.toString.call(message.subCompanyId) : options.longs === Number ? new $util.LongBits(message.subCompanyId.low >>> 0, message.subCompanyId.high >>> 0).toNumber(true) : message.subCompanyId;
                if (message.loadAdditionalInfo != null && message.hasOwnProperty("loadAdditionalInfo"))
                    object.loadAdditionalInfo = message.loadAdditionalInfo;
                return object;
            };

            /**
             * Converts this GetCouriersFromExternal to JSON.
             * @function toJSON
             * @memberof fastcity.admin.GetCouriersFromExternal
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetCouriersFromExternal.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return GetCouriersFromExternal;
        })();

        admin.GetSubCompaniesFromExternal = (function() {

            /**
             * Properties of a GetSubCompaniesFromExternal.
             * @memberof fastcity.admin
             * @interface IGetSubCompaniesFromExternal
             * @property {number|Long|null} [subCompanyId] GetSubCompaniesFromExternal subCompanyId
             */

            /**
             * Constructs a new GetSubCompaniesFromExternal.
             * @memberof fastcity.admin
             * @classdesc Represents a GetSubCompaniesFromExternal.
             * @implements IGetSubCompaniesFromExternal
             * @constructor
             * @param {fastcity.admin.IGetSubCompaniesFromExternal=} [properties] Properties to set
             */
            function GetSubCompaniesFromExternal(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GetSubCompaniesFromExternal subCompanyId.
             * @member {number|Long} subCompanyId
             * @memberof fastcity.admin.GetSubCompaniesFromExternal
             * @instance
             */
            GetSubCompaniesFromExternal.prototype.subCompanyId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Creates a new GetSubCompaniesFromExternal instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.GetSubCompaniesFromExternal
             * @static
             * @param {fastcity.admin.IGetSubCompaniesFromExternal=} [properties] Properties to set
             * @returns {fastcity.admin.GetSubCompaniesFromExternal} GetSubCompaniesFromExternal instance
             */
            GetSubCompaniesFromExternal.create = function create(properties) {
                return new GetSubCompaniesFromExternal(properties);
            };

            /**
             * Encodes the specified GetSubCompaniesFromExternal message. Does not implicitly {@link fastcity.admin.GetSubCompaniesFromExternal.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.GetSubCompaniesFromExternal
             * @static
             * @param {fastcity.admin.IGetSubCompaniesFromExternal} message GetSubCompaniesFromExternal message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetSubCompaniesFromExternal.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.subCompanyId != null && Object.hasOwnProperty.call(message, "subCompanyId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.subCompanyId);
                return writer;
            };

            /**
             * Decodes a GetSubCompaniesFromExternal message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.GetSubCompaniesFromExternal
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.GetSubCompaniesFromExternal} GetSubCompaniesFromExternal
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetSubCompaniesFromExternal.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.GetSubCompaniesFromExternal();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.subCompanyId = reader.uint64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a GetSubCompaniesFromExternal message.
             * @function verify
             * @memberof fastcity.admin.GetSubCompaniesFromExternal
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetSubCompaniesFromExternal.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.subCompanyId != null && message.hasOwnProperty("subCompanyId"))
                    if (!$util.isInteger(message.subCompanyId) && !(message.subCompanyId && $util.isInteger(message.subCompanyId.low) && $util.isInteger(message.subCompanyId.high)))
                        return "subCompanyId: integer|Long expected";
                return null;
            };

            /**
             * Creates a GetSubCompaniesFromExternal message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.GetSubCompaniesFromExternal
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.GetSubCompaniesFromExternal} GetSubCompaniesFromExternal
             */
            GetSubCompaniesFromExternal.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.GetSubCompaniesFromExternal)
                    return object;
                var message = new $root.fastcity.admin.GetSubCompaniesFromExternal();
                if (object.subCompanyId != null)
                    if ($util.Long)
                        (message.subCompanyId = $util.Long.fromValue(object.subCompanyId)).unsigned = true;
                    else if (typeof object.subCompanyId === "string")
                        message.subCompanyId = parseInt(object.subCompanyId, 10);
                    else if (typeof object.subCompanyId === "number")
                        message.subCompanyId = object.subCompanyId;
                    else if (typeof object.subCompanyId === "object")
                        message.subCompanyId = new $util.LongBits(object.subCompanyId.low >>> 0, object.subCompanyId.high >>> 0).toNumber(true);
                return message;
            };

            /**
             * Creates a plain object from a GetSubCompaniesFromExternal message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.GetSubCompaniesFromExternal
             * @static
             * @param {fastcity.admin.GetSubCompaniesFromExternal} message GetSubCompaniesFromExternal
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetSubCompaniesFromExternal.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.subCompanyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.subCompanyId = options.longs === String ? "0" : 0;
                if (message.subCompanyId != null && message.hasOwnProperty("subCompanyId"))
                    if (typeof message.subCompanyId === "number")
                        object.subCompanyId = options.longs === String ? String(message.subCompanyId) : message.subCompanyId;
                    else
                        object.subCompanyId = options.longs === String ? $util.Long.prototype.toString.call(message.subCompanyId) : options.longs === Number ? new $util.LongBits(message.subCompanyId.low >>> 0, message.subCompanyId.high >>> 0).toNumber(true) : message.subCompanyId;
                return object;
            };

            /**
             * Converts this GetSubCompaniesFromExternal to JSON.
             * @function toJSON
             * @memberof fastcity.admin.GetSubCompaniesFromExternal
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetSubCompaniesFromExternal.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return GetSubCompaniesFromExternal;
        })();

        admin.IntegrationConnectionStatus = (function() {

            /**
             * Properties of an IntegrationConnectionStatus.
             * @memberof fastcity.admin
             * @interface IIntegrationConnectionStatus
             * @property {number|Long|null} [subCompanyId] IntegrationConnectionStatus subCompanyId
             * @property {fastcity.admin.IntegrationConnectionStatus.Status|null} [connectionStatusCode] IntegrationConnectionStatus connectionStatusCode
             * @property {string|null} [description] IntegrationConnectionStatus description
             */

            /**
             * Constructs a new IntegrationConnectionStatus.
             * @memberof fastcity.admin
             * @classdesc Represents an IntegrationConnectionStatus.
             * @implements IIntegrationConnectionStatus
             * @constructor
             * @param {fastcity.admin.IIntegrationConnectionStatus=} [properties] Properties to set
             */
            function IntegrationConnectionStatus(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * IntegrationConnectionStatus subCompanyId.
             * @member {number|Long} subCompanyId
             * @memberof fastcity.admin.IntegrationConnectionStatus
             * @instance
             */
            IntegrationConnectionStatus.prototype.subCompanyId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * IntegrationConnectionStatus connectionStatusCode.
             * @member {fastcity.admin.IntegrationConnectionStatus.Status} connectionStatusCode
             * @memberof fastcity.admin.IntegrationConnectionStatus
             * @instance
             */
            IntegrationConnectionStatus.prototype.connectionStatusCode = 0;

            /**
             * IntegrationConnectionStatus description.
             * @member {string} description
             * @memberof fastcity.admin.IntegrationConnectionStatus
             * @instance
             */
            IntegrationConnectionStatus.prototype.description = "";

            /**
             * Creates a new IntegrationConnectionStatus instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.IntegrationConnectionStatus
             * @static
             * @param {fastcity.admin.IIntegrationConnectionStatus=} [properties] Properties to set
             * @returns {fastcity.admin.IntegrationConnectionStatus} IntegrationConnectionStatus instance
             */
            IntegrationConnectionStatus.create = function create(properties) {
                return new IntegrationConnectionStatus(properties);
            };

            /**
             * Encodes the specified IntegrationConnectionStatus message. Does not implicitly {@link fastcity.admin.IntegrationConnectionStatus.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.IntegrationConnectionStatus
             * @static
             * @param {fastcity.admin.IIntegrationConnectionStatus} message IntegrationConnectionStatus message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            IntegrationConnectionStatus.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.subCompanyId != null && Object.hasOwnProperty.call(message, "subCompanyId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.subCompanyId);
                if (message.connectionStatusCode != null && Object.hasOwnProperty.call(message, "connectionStatusCode"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.connectionStatusCode);
                if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.description);
                return writer;
            };

            /**
             * Decodes an IntegrationConnectionStatus message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.IntegrationConnectionStatus
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.IntegrationConnectionStatus} IntegrationConnectionStatus
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            IntegrationConnectionStatus.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.IntegrationConnectionStatus();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.subCompanyId = reader.uint64();
                        break;
                    case 2:
                        message.connectionStatusCode = reader.int32();
                        break;
                    case 3:
                        message.description = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an IntegrationConnectionStatus message.
             * @function verify
             * @memberof fastcity.admin.IntegrationConnectionStatus
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            IntegrationConnectionStatus.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.subCompanyId != null && message.hasOwnProperty("subCompanyId"))
                    if (!$util.isInteger(message.subCompanyId) && !(message.subCompanyId && $util.isInteger(message.subCompanyId.low) && $util.isInteger(message.subCompanyId.high)))
                        return "subCompanyId: integer|Long expected";
                if (message.connectionStatusCode != null && message.hasOwnProperty("connectionStatusCode"))
                    switch (message.connectionStatusCode) {
                    default:
                        return "connectionStatusCode: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                        break;
                    }
                if (message.description != null && message.hasOwnProperty("description"))
                    if (!$util.isString(message.description))
                        return "description: string expected";
                return null;
            };

            /**
             * Creates an IntegrationConnectionStatus message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.IntegrationConnectionStatus
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.IntegrationConnectionStatus} IntegrationConnectionStatus
             */
            IntegrationConnectionStatus.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.IntegrationConnectionStatus)
                    return object;
                var message = new $root.fastcity.admin.IntegrationConnectionStatus();
                if (object.subCompanyId != null)
                    if ($util.Long)
                        (message.subCompanyId = $util.Long.fromValue(object.subCompanyId)).unsigned = true;
                    else if (typeof object.subCompanyId === "string")
                        message.subCompanyId = parseInt(object.subCompanyId, 10);
                    else if (typeof object.subCompanyId === "number")
                        message.subCompanyId = object.subCompanyId;
                    else if (typeof object.subCompanyId === "object")
                        message.subCompanyId = new $util.LongBits(object.subCompanyId.low >>> 0, object.subCompanyId.high >>> 0).toNumber(true);
                switch (object.connectionStatusCode) {
                case "C_OK":
                case 0:
                    message.connectionStatusCode = 0;
                    break;
                case "C_NOT_KNOWN":
                case 1:
                    message.connectionStatusCode = 1;
                    break;
                case "C_NO_EXTERNAL_AUTH_TOKEN_STRUCT":
                case 2:
                    message.connectionStatusCode = 2;
                    break;
                case "C_NO_API_KEY":
                case 3:
                    message.connectionStatusCode = 3;
                    break;
                case "C_NO_AUTH_TOKEN":
                case 4:
                    message.connectionStatusCode = 4;
                    break;
                case "C_TOO_MANY_REQUEST":
                case 5:
                    message.connectionStatusCode = 5;
                    break;
                case "C_UNAUTHORIZED":
                case 6:
                    message.connectionStatusCode = 6;
                    break;
                case "C_REQUEST_TIMEOUT":
                case 7:
                    message.connectionStatusCode = 7;
                    break;
                case "C_SERVER_ERROR":
                case 8:
                    message.connectionStatusCode = 8;
                    break;
                }
                if (object.description != null)
                    message.description = String(object.description);
                return message;
            };

            /**
             * Creates a plain object from an IntegrationConnectionStatus message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.IntegrationConnectionStatus
             * @static
             * @param {fastcity.admin.IntegrationConnectionStatus} message IntegrationConnectionStatus
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            IntegrationConnectionStatus.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.subCompanyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.subCompanyId = options.longs === String ? "0" : 0;
                    object.connectionStatusCode = options.enums === String ? "C_OK" : 0;
                    object.description = "";
                }
                if (message.subCompanyId != null && message.hasOwnProperty("subCompanyId"))
                    if (typeof message.subCompanyId === "number")
                        object.subCompanyId = options.longs === String ? String(message.subCompanyId) : message.subCompanyId;
                    else
                        object.subCompanyId = options.longs === String ? $util.Long.prototype.toString.call(message.subCompanyId) : options.longs === Number ? new $util.LongBits(message.subCompanyId.low >>> 0, message.subCompanyId.high >>> 0).toNumber(true) : message.subCompanyId;
                if (message.connectionStatusCode != null && message.hasOwnProperty("connectionStatusCode"))
                    object.connectionStatusCode = options.enums === String ? $root.fastcity.admin.IntegrationConnectionStatus.Status[message.connectionStatusCode] : message.connectionStatusCode;
                if (message.description != null && message.hasOwnProperty("description"))
                    object.description = message.description;
                return object;
            };

            /**
             * Converts this IntegrationConnectionStatus to JSON.
             * @function toJSON
             * @memberof fastcity.admin.IntegrationConnectionStatus
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            IntegrationConnectionStatus.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Status enum.
             * @name fastcity.admin.IntegrationConnectionStatus.Status
             * @enum {number}
             * @property {number} C_OK=0 C_OK value
             * @property {number} C_NOT_KNOWN=1 C_NOT_KNOWN value
             * @property {number} C_NO_EXTERNAL_AUTH_TOKEN_STRUCT=2 C_NO_EXTERNAL_AUTH_TOKEN_STRUCT value
             * @property {number} C_NO_API_KEY=3 C_NO_API_KEY value
             * @property {number} C_NO_AUTH_TOKEN=4 C_NO_AUTH_TOKEN value
             * @property {number} C_TOO_MANY_REQUEST=5 C_TOO_MANY_REQUEST value
             * @property {number} C_UNAUTHORIZED=6 C_UNAUTHORIZED value
             * @property {number} C_REQUEST_TIMEOUT=7 C_REQUEST_TIMEOUT value
             * @property {number} C_SERVER_ERROR=8 C_SERVER_ERROR value
             */
            IntegrationConnectionStatus.Status = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "C_OK"] = 0;
                values[valuesById[1] = "C_NOT_KNOWN"] = 1;
                values[valuesById[2] = "C_NO_EXTERNAL_AUTH_TOKEN_STRUCT"] = 2;
                values[valuesById[3] = "C_NO_API_KEY"] = 3;
                values[valuesById[4] = "C_NO_AUTH_TOKEN"] = 4;
                values[valuesById[5] = "C_TOO_MANY_REQUEST"] = 5;
                values[valuesById[6] = "C_UNAUTHORIZED"] = 6;
                values[valuesById[7] = "C_REQUEST_TIMEOUT"] = 7;
                values[valuesById[8] = "C_SERVER_ERROR"] = 8;
                return values;
            })();

            return IntegrationConnectionStatus;
        })();

        admin.IntegrationConnectionStatusList = (function() {

            /**
             * Properties of an IntegrationConnectionStatusList.
             * @memberof fastcity.admin
             * @interface IIntegrationConnectionStatusList
             * @property {Array.<fastcity.admin.IIntegrationConnectionStatus>|null} [connectionStatus] IntegrationConnectionStatusList connectionStatus
             */

            /**
             * Constructs a new IntegrationConnectionStatusList.
             * @memberof fastcity.admin
             * @classdesc Represents an IntegrationConnectionStatusList.
             * @implements IIntegrationConnectionStatusList
             * @constructor
             * @param {fastcity.admin.IIntegrationConnectionStatusList=} [properties] Properties to set
             */
            function IntegrationConnectionStatusList(properties) {
                this.connectionStatus = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * IntegrationConnectionStatusList connectionStatus.
             * @member {Array.<fastcity.admin.IIntegrationConnectionStatus>} connectionStatus
             * @memberof fastcity.admin.IntegrationConnectionStatusList
             * @instance
             */
            IntegrationConnectionStatusList.prototype.connectionStatus = $util.emptyArray;

            /**
             * Creates a new IntegrationConnectionStatusList instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.IntegrationConnectionStatusList
             * @static
             * @param {fastcity.admin.IIntegrationConnectionStatusList=} [properties] Properties to set
             * @returns {fastcity.admin.IntegrationConnectionStatusList} IntegrationConnectionStatusList instance
             */
            IntegrationConnectionStatusList.create = function create(properties) {
                return new IntegrationConnectionStatusList(properties);
            };

            /**
             * Encodes the specified IntegrationConnectionStatusList message. Does not implicitly {@link fastcity.admin.IntegrationConnectionStatusList.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.IntegrationConnectionStatusList
             * @static
             * @param {fastcity.admin.IIntegrationConnectionStatusList} message IntegrationConnectionStatusList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            IntegrationConnectionStatusList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.connectionStatus != null && message.connectionStatus.length)
                    for (var i = 0; i < message.connectionStatus.length; ++i)
                        $root.fastcity.admin.IntegrationConnectionStatus.encode(message.connectionStatus[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes an IntegrationConnectionStatusList message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.IntegrationConnectionStatusList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.IntegrationConnectionStatusList} IntegrationConnectionStatusList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            IntegrationConnectionStatusList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.IntegrationConnectionStatusList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.connectionStatus && message.connectionStatus.length))
                            message.connectionStatus = [];
                        message.connectionStatus.push($root.fastcity.admin.IntegrationConnectionStatus.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an IntegrationConnectionStatusList message.
             * @function verify
             * @memberof fastcity.admin.IntegrationConnectionStatusList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            IntegrationConnectionStatusList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.connectionStatus != null && message.hasOwnProperty("connectionStatus")) {
                    if (!Array.isArray(message.connectionStatus))
                        return "connectionStatus: array expected";
                    for (var i = 0; i < message.connectionStatus.length; ++i) {
                        var error = $root.fastcity.admin.IntegrationConnectionStatus.verify(message.connectionStatus[i]);
                        if (error)
                            return "connectionStatus." + error;
                    }
                }
                return null;
            };

            /**
             * Creates an IntegrationConnectionStatusList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.IntegrationConnectionStatusList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.IntegrationConnectionStatusList} IntegrationConnectionStatusList
             */
            IntegrationConnectionStatusList.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.IntegrationConnectionStatusList)
                    return object;
                var message = new $root.fastcity.admin.IntegrationConnectionStatusList();
                if (object.connectionStatus) {
                    if (!Array.isArray(object.connectionStatus))
                        throw TypeError(".fastcity.admin.IntegrationConnectionStatusList.connectionStatus: array expected");
                    message.connectionStatus = [];
                    for (var i = 0; i < object.connectionStatus.length; ++i) {
                        if (typeof object.connectionStatus[i] !== "object")
                            throw TypeError(".fastcity.admin.IntegrationConnectionStatusList.connectionStatus: object expected");
                        message.connectionStatus[i] = $root.fastcity.admin.IntegrationConnectionStatus.fromObject(object.connectionStatus[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from an IntegrationConnectionStatusList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.IntegrationConnectionStatusList
             * @static
             * @param {fastcity.admin.IntegrationConnectionStatusList} message IntegrationConnectionStatusList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            IntegrationConnectionStatusList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.connectionStatus = [];
                if (message.connectionStatus && message.connectionStatus.length) {
                    object.connectionStatus = [];
                    for (var j = 0; j < message.connectionStatus.length; ++j)
                        object.connectionStatus[j] = $root.fastcity.admin.IntegrationConnectionStatus.toObject(message.connectionStatus[j], options);
                }
                return object;
            };

            /**
             * Converts this IntegrationConnectionStatusList to JSON.
             * @function toJSON
             * @memberof fastcity.admin.IntegrationConnectionStatusList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            IntegrationConnectionStatusList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return IntegrationConnectionStatusList;
        })();

        admin.GetIntegrationConnectionStatus = (function() {

            /**
             * Properties of a GetIntegrationConnectionStatus.
             * @memberof fastcity.admin
             * @interface IGetIntegrationConnectionStatus
             * @property {fastcity.common.Source|null} [source] GetIntegrationConnectionStatus source
             * @property {number|Long|null} [subCompanyId] GetIntegrationConnectionStatus subCompanyId
             */

            /**
             * Constructs a new GetIntegrationConnectionStatus.
             * @memberof fastcity.admin
             * @classdesc Represents a GetIntegrationConnectionStatus.
             * @implements IGetIntegrationConnectionStatus
             * @constructor
             * @param {fastcity.admin.IGetIntegrationConnectionStatus=} [properties] Properties to set
             */
            function GetIntegrationConnectionStatus(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GetIntegrationConnectionStatus source.
             * @member {fastcity.common.Source} source
             * @memberof fastcity.admin.GetIntegrationConnectionStatus
             * @instance
             */
            GetIntegrationConnectionStatus.prototype.source = 0;

            /**
             * GetIntegrationConnectionStatus subCompanyId.
             * @member {number|Long} subCompanyId
             * @memberof fastcity.admin.GetIntegrationConnectionStatus
             * @instance
             */
            GetIntegrationConnectionStatus.prototype.subCompanyId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Creates a new GetIntegrationConnectionStatus instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.GetIntegrationConnectionStatus
             * @static
             * @param {fastcity.admin.IGetIntegrationConnectionStatus=} [properties] Properties to set
             * @returns {fastcity.admin.GetIntegrationConnectionStatus} GetIntegrationConnectionStatus instance
             */
            GetIntegrationConnectionStatus.create = function create(properties) {
                return new GetIntegrationConnectionStatus(properties);
            };

            /**
             * Encodes the specified GetIntegrationConnectionStatus message. Does not implicitly {@link fastcity.admin.GetIntegrationConnectionStatus.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.GetIntegrationConnectionStatus
             * @static
             * @param {fastcity.admin.IGetIntegrationConnectionStatus} message GetIntegrationConnectionStatus message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetIntegrationConnectionStatus.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.source != null && Object.hasOwnProperty.call(message, "source"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.source);
                if (message.subCompanyId != null && Object.hasOwnProperty.call(message, "subCompanyId"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.subCompanyId);
                return writer;
            };

            /**
             * Decodes a GetIntegrationConnectionStatus message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.GetIntegrationConnectionStatus
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.GetIntegrationConnectionStatus} GetIntegrationConnectionStatus
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetIntegrationConnectionStatus.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.GetIntegrationConnectionStatus();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.source = reader.int32();
                        break;
                    case 2:
                        message.subCompanyId = reader.uint64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a GetIntegrationConnectionStatus message.
             * @function verify
             * @memberof fastcity.admin.GetIntegrationConnectionStatus
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetIntegrationConnectionStatus.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.source != null && message.hasOwnProperty("source"))
                    switch (message.source) {
                    default:
                        return "source: enum value expected";
                    case 0:
                    case 1:
                        break;
                    }
                if (message.subCompanyId != null && message.hasOwnProperty("subCompanyId"))
                    if (!$util.isInteger(message.subCompanyId) && !(message.subCompanyId && $util.isInteger(message.subCompanyId.low) && $util.isInteger(message.subCompanyId.high)))
                        return "subCompanyId: integer|Long expected";
                return null;
            };

            /**
             * Creates a GetIntegrationConnectionStatus message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.GetIntegrationConnectionStatus
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.GetIntegrationConnectionStatus} GetIntegrationConnectionStatus
             */
            GetIntegrationConnectionStatus.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.GetIntegrationConnectionStatus)
                    return object;
                var message = new $root.fastcity.admin.GetIntegrationConnectionStatus();
                switch (object.source) {
                case "UNKNOWN":
                case 0:
                    message.source = 0;
                    break;
                case "IIKO":
                case 1:
                    message.source = 1;
                    break;
                }
                if (object.subCompanyId != null)
                    if ($util.Long)
                        (message.subCompanyId = $util.Long.fromValue(object.subCompanyId)).unsigned = true;
                    else if (typeof object.subCompanyId === "string")
                        message.subCompanyId = parseInt(object.subCompanyId, 10);
                    else if (typeof object.subCompanyId === "number")
                        message.subCompanyId = object.subCompanyId;
                    else if (typeof object.subCompanyId === "object")
                        message.subCompanyId = new $util.LongBits(object.subCompanyId.low >>> 0, object.subCompanyId.high >>> 0).toNumber(true);
                return message;
            };

            /**
             * Creates a plain object from a GetIntegrationConnectionStatus message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.GetIntegrationConnectionStatus
             * @static
             * @param {fastcity.admin.GetIntegrationConnectionStatus} message GetIntegrationConnectionStatus
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetIntegrationConnectionStatus.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.source = options.enums === String ? "UNKNOWN" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.subCompanyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.subCompanyId = options.longs === String ? "0" : 0;
                }
                if (message.source != null && message.hasOwnProperty("source"))
                    object.source = options.enums === String ? $root.fastcity.common.Source[message.source] : message.source;
                if (message.subCompanyId != null && message.hasOwnProperty("subCompanyId"))
                    if (typeof message.subCompanyId === "number")
                        object.subCompanyId = options.longs === String ? String(message.subCompanyId) : message.subCompanyId;
                    else
                        object.subCompanyId = options.longs === String ? $util.Long.prototype.toString.call(message.subCompanyId) : options.longs === Number ? new $util.LongBits(message.subCompanyId.low >>> 0, message.subCompanyId.high >>> 0).toNumber(true) : message.subCompanyId;
                return object;
            };

            /**
             * Converts this GetIntegrationConnectionStatus to JSON.
             * @function toJSON
             * @memberof fastcity.admin.GetIntegrationConnectionStatus
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetIntegrationConnectionStatus.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return GetIntegrationConnectionStatus;
        })();

        admin.GetIntegrationConnectionStatusList = (function() {

            /**
             * Properties of a GetIntegrationConnectionStatusList.
             * @memberof fastcity.admin
             * @interface IGetIntegrationConnectionStatusList
             * @property {fastcity.common.Source|null} [source] GetIntegrationConnectionStatusList source
             * @property {Array.<number|Long>|null} [subCompanyId] GetIntegrationConnectionStatusList subCompanyId
             */

            /**
             * Constructs a new GetIntegrationConnectionStatusList.
             * @memberof fastcity.admin
             * @classdesc Represents a GetIntegrationConnectionStatusList.
             * @implements IGetIntegrationConnectionStatusList
             * @constructor
             * @param {fastcity.admin.IGetIntegrationConnectionStatusList=} [properties] Properties to set
             */
            function GetIntegrationConnectionStatusList(properties) {
                this.subCompanyId = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GetIntegrationConnectionStatusList source.
             * @member {fastcity.common.Source} source
             * @memberof fastcity.admin.GetIntegrationConnectionStatusList
             * @instance
             */
            GetIntegrationConnectionStatusList.prototype.source = 0;

            /**
             * GetIntegrationConnectionStatusList subCompanyId.
             * @member {Array.<number|Long>} subCompanyId
             * @memberof fastcity.admin.GetIntegrationConnectionStatusList
             * @instance
             */
            GetIntegrationConnectionStatusList.prototype.subCompanyId = $util.emptyArray;

            /**
             * Creates a new GetIntegrationConnectionStatusList instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.GetIntegrationConnectionStatusList
             * @static
             * @param {fastcity.admin.IGetIntegrationConnectionStatusList=} [properties] Properties to set
             * @returns {fastcity.admin.GetIntegrationConnectionStatusList} GetIntegrationConnectionStatusList instance
             */
            GetIntegrationConnectionStatusList.create = function create(properties) {
                return new GetIntegrationConnectionStatusList(properties);
            };

            /**
             * Encodes the specified GetIntegrationConnectionStatusList message. Does not implicitly {@link fastcity.admin.GetIntegrationConnectionStatusList.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.GetIntegrationConnectionStatusList
             * @static
             * @param {fastcity.admin.IGetIntegrationConnectionStatusList} message GetIntegrationConnectionStatusList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetIntegrationConnectionStatusList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.source != null && Object.hasOwnProperty.call(message, "source"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.source);
                if (message.subCompanyId != null && message.subCompanyId.length) {
                    writer.uint32(/* id 2, wireType 2 =*/18).fork();
                    for (var i = 0; i < message.subCompanyId.length; ++i)
                        writer.uint64(message.subCompanyId[i]);
                    writer.ldelim();
                }
                return writer;
            };

            /**
             * Decodes a GetIntegrationConnectionStatusList message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.GetIntegrationConnectionStatusList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.GetIntegrationConnectionStatusList} GetIntegrationConnectionStatusList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetIntegrationConnectionStatusList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.GetIntegrationConnectionStatusList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.source = reader.int32();
                        break;
                    case 2:
                        if (!(message.subCompanyId && message.subCompanyId.length))
                            message.subCompanyId = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.subCompanyId.push(reader.uint64());
                        } else
                            message.subCompanyId.push(reader.uint64());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a GetIntegrationConnectionStatusList message.
             * @function verify
             * @memberof fastcity.admin.GetIntegrationConnectionStatusList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetIntegrationConnectionStatusList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.source != null && message.hasOwnProperty("source"))
                    switch (message.source) {
                    default:
                        return "source: enum value expected";
                    case 0:
                    case 1:
                        break;
                    }
                if (message.subCompanyId != null && message.hasOwnProperty("subCompanyId")) {
                    if (!Array.isArray(message.subCompanyId))
                        return "subCompanyId: array expected";
                    for (var i = 0; i < message.subCompanyId.length; ++i)
                        if (!$util.isInteger(message.subCompanyId[i]) && !(message.subCompanyId[i] && $util.isInteger(message.subCompanyId[i].low) && $util.isInteger(message.subCompanyId[i].high)))
                            return "subCompanyId: integer|Long[] expected";
                }
                return null;
            };

            /**
             * Creates a GetIntegrationConnectionStatusList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.GetIntegrationConnectionStatusList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.GetIntegrationConnectionStatusList} GetIntegrationConnectionStatusList
             */
            GetIntegrationConnectionStatusList.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.GetIntegrationConnectionStatusList)
                    return object;
                var message = new $root.fastcity.admin.GetIntegrationConnectionStatusList();
                switch (object.source) {
                case "UNKNOWN":
                case 0:
                    message.source = 0;
                    break;
                case "IIKO":
                case 1:
                    message.source = 1;
                    break;
                }
                if (object.subCompanyId) {
                    if (!Array.isArray(object.subCompanyId))
                        throw TypeError(".fastcity.admin.GetIntegrationConnectionStatusList.subCompanyId: array expected");
                    message.subCompanyId = [];
                    for (var i = 0; i < object.subCompanyId.length; ++i)
                        if ($util.Long)
                            (message.subCompanyId[i] = $util.Long.fromValue(object.subCompanyId[i])).unsigned = true;
                        else if (typeof object.subCompanyId[i] === "string")
                            message.subCompanyId[i] = parseInt(object.subCompanyId[i], 10);
                        else if (typeof object.subCompanyId[i] === "number")
                            message.subCompanyId[i] = object.subCompanyId[i];
                        else if (typeof object.subCompanyId[i] === "object")
                            message.subCompanyId[i] = new $util.LongBits(object.subCompanyId[i].low >>> 0, object.subCompanyId[i].high >>> 0).toNumber(true);
                }
                return message;
            };

            /**
             * Creates a plain object from a GetIntegrationConnectionStatusList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.GetIntegrationConnectionStatusList
             * @static
             * @param {fastcity.admin.GetIntegrationConnectionStatusList} message GetIntegrationConnectionStatusList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetIntegrationConnectionStatusList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.subCompanyId = [];
                if (options.defaults)
                    object.source = options.enums === String ? "UNKNOWN" : 0;
                if (message.source != null && message.hasOwnProperty("source"))
                    object.source = options.enums === String ? $root.fastcity.common.Source[message.source] : message.source;
                if (message.subCompanyId && message.subCompanyId.length) {
                    object.subCompanyId = [];
                    for (var j = 0; j < message.subCompanyId.length; ++j)
                        if (typeof message.subCompanyId[j] === "number")
                            object.subCompanyId[j] = options.longs === String ? String(message.subCompanyId[j]) : message.subCompanyId[j];
                        else
                            object.subCompanyId[j] = options.longs === String ? $util.Long.prototype.toString.call(message.subCompanyId[j]) : options.longs === Number ? new $util.LongBits(message.subCompanyId[j].low >>> 0, message.subCompanyId[j].high >>> 0).toNumber(true) : message.subCompanyId[j];
                }
                return object;
            };

            /**
             * Converts this GetIntegrationConnectionStatusList to JSON.
             * @function toJSON
             * @memberof fastcity.admin.GetIntegrationConnectionStatusList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetIntegrationConnectionStatusList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return GetIntegrationConnectionStatusList;
        })();

        /**
         * GroupRequestResult enum.
         * @name fastcity.admin.GroupRequestResult
         * @enum {number}
         * @property {number} GRR_NOT_FOUND=0 GRR_NOT_FOUND value
         * @property {number} GRR_BAD_COMPANY_ID_COMPANY_NOT_FOUND=1 GRR_BAD_COMPANY_ID_COMPANY_NOT_FOUND value
         * @property {number} GRR_BAD_COMPANY_ID_COMPANY_IS_DELETED=2 GRR_BAD_COMPANY_ID_COMPANY_IS_DELETED value
         * @property {number} GRR_BAD_NAME_EMPTY=3 GRR_BAD_NAME_EMPTY value
         */
        admin.GroupRequestResult = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "GRR_NOT_FOUND"] = 0;
            values[valuesById[1] = "GRR_BAD_COMPANY_ID_COMPANY_NOT_FOUND"] = 1;
            values[valuesById[2] = "GRR_BAD_COMPANY_ID_COMPANY_IS_DELETED"] = 2;
            values[valuesById[3] = "GRR_BAD_NAME_EMPTY"] = 3;
            return values;
        })();

        admin.Group = (function() {

            /**
             * Properties of a Group.
             * @memberof fastcity.admin
             * @interface IGroup
             * @property {number|Long|null} [groupId] Group groupId
             * @property {number|Long|null} [companyId] Group companyId
             * @property {string|null} [name] Group name
             * @property {boolean|null} [isDeleted] Group isDeleted
             */

            /**
             * Constructs a new Group.
             * @memberof fastcity.admin
             * @classdesc Represents a Group.
             * @implements IGroup
             * @constructor
             * @param {fastcity.admin.IGroup=} [properties] Properties to set
             */
            function Group(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Group groupId.
             * @member {number|Long} groupId
             * @memberof fastcity.admin.Group
             * @instance
             */
            Group.prototype.groupId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Group companyId.
             * @member {number|Long} companyId
             * @memberof fastcity.admin.Group
             * @instance
             */
            Group.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Group name.
             * @member {string} name
             * @memberof fastcity.admin.Group
             * @instance
             */
            Group.prototype.name = "";

            /**
             * Group isDeleted.
             * @member {boolean} isDeleted
             * @memberof fastcity.admin.Group
             * @instance
             */
            Group.prototype.isDeleted = false;

            /**
             * Creates a new Group instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.Group
             * @static
             * @param {fastcity.admin.IGroup=} [properties] Properties to set
             * @returns {fastcity.admin.Group} Group instance
             */
            Group.create = function create(properties) {
                return new Group(properties);
            };

            /**
             * Encodes the specified Group message. Does not implicitly {@link fastcity.admin.Group.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.Group
             * @static
             * @param {fastcity.admin.IGroup} message Group message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Group.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.groupId != null && Object.hasOwnProperty.call(message, "groupId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.groupId);
                if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.companyId);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
                if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                    writer.uint32(/* id 4, wireType 0 =*/32).bool(message.isDeleted);
                return writer;
            };

            /**
             * Decodes a Group message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.Group
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.Group} Group
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Group.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.Group();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.groupId = reader.uint64();
                        break;
                    case 2:
                        message.companyId = reader.uint64();
                        break;
                    case 3:
                        message.name = reader.string();
                        break;
                    case 4:
                        message.isDeleted = reader.bool();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a Group message.
             * @function verify
             * @memberof fastcity.admin.Group
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Group.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.groupId != null && message.hasOwnProperty("groupId"))
                    if (!$util.isInteger(message.groupId) && !(message.groupId && $util.isInteger(message.groupId.low) && $util.isInteger(message.groupId.high)))
                        return "groupId: integer|Long expected";
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                        return "companyId: integer|Long expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.isDeleted != null && message.hasOwnProperty("isDeleted"))
                    if (typeof message.isDeleted !== "boolean")
                        return "isDeleted: boolean expected";
                return null;
            };

            /**
             * Creates a Group message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.Group
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.Group} Group
             */
            Group.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.Group)
                    return object;
                var message = new $root.fastcity.admin.Group();
                if (object.groupId != null)
                    if ($util.Long)
                        (message.groupId = $util.Long.fromValue(object.groupId)).unsigned = true;
                    else if (typeof object.groupId === "string")
                        message.groupId = parseInt(object.groupId, 10);
                    else if (typeof object.groupId === "number")
                        message.groupId = object.groupId;
                    else if (typeof object.groupId === "object")
                        message.groupId = new $util.LongBits(object.groupId.low >>> 0, object.groupId.high >>> 0).toNumber(true);
                if (object.companyId != null)
                    if ($util.Long)
                        (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = true;
                    else if (typeof object.companyId === "string")
                        message.companyId = parseInt(object.companyId, 10);
                    else if (typeof object.companyId === "number")
                        message.companyId = object.companyId;
                    else if (typeof object.companyId === "object")
                        message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber(true);
                if (object.name != null)
                    message.name = String(object.name);
                if (object.isDeleted != null)
                    message.isDeleted = Boolean(object.isDeleted);
                return message;
            };

            /**
             * Creates a plain object from a Group message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.Group
             * @static
             * @param {fastcity.admin.Group} message Group
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Group.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.groupId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.groupId = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.companyId = options.longs === String ? "0" : 0;
                    object.name = "";
                    object.isDeleted = false;
                }
                if (message.groupId != null && message.hasOwnProperty("groupId"))
                    if (typeof message.groupId === "number")
                        object.groupId = options.longs === String ? String(message.groupId) : message.groupId;
                    else
                        object.groupId = options.longs === String ? $util.Long.prototype.toString.call(message.groupId) : options.longs === Number ? new $util.LongBits(message.groupId.low >>> 0, message.groupId.high >>> 0).toNumber(true) : message.groupId;
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (typeof message.companyId === "number")
                        object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                    else
                        object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber(true) : message.companyId;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.isDeleted != null && message.hasOwnProperty("isDeleted"))
                    object.isDeleted = message.isDeleted;
                return object;
            };

            /**
             * Converts this Group to JSON.
             * @function toJSON
             * @memberof fastcity.admin.Group
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Group.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Group;
        })();

        admin.CreateGroup = (function() {

            /**
             * Properties of a CreateGroup.
             * @memberof fastcity.admin
             * @interface ICreateGroup
             * @property {number|Long|null} [companyId] CreateGroup companyId
             * @property {string|null} [name] CreateGroup name
             */

            /**
             * Constructs a new CreateGroup.
             * @memberof fastcity.admin
             * @classdesc Represents a CreateGroup.
             * @implements ICreateGroup
             * @constructor
             * @param {fastcity.admin.ICreateGroup=} [properties] Properties to set
             */
            function CreateGroup(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CreateGroup companyId.
             * @member {number|Long} companyId
             * @memberof fastcity.admin.CreateGroup
             * @instance
             */
            CreateGroup.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * CreateGroup name.
             * @member {string} name
             * @memberof fastcity.admin.CreateGroup
             * @instance
             */
            CreateGroup.prototype.name = "";

            /**
             * Creates a new CreateGroup instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.CreateGroup
             * @static
             * @param {fastcity.admin.ICreateGroup=} [properties] Properties to set
             * @returns {fastcity.admin.CreateGroup} CreateGroup instance
             */
            CreateGroup.create = function create(properties) {
                return new CreateGroup(properties);
            };

            /**
             * Encodes the specified CreateGroup message. Does not implicitly {@link fastcity.admin.CreateGroup.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.CreateGroup
             * @static
             * @param {fastcity.admin.ICreateGroup} message CreateGroup message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateGroup.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.companyId);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                return writer;
            };

            /**
             * Decodes a CreateGroup message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.CreateGroup
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.CreateGroup} CreateGroup
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateGroup.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.CreateGroup();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.companyId = reader.uint64();
                        break;
                    case 2:
                        message.name = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a CreateGroup message.
             * @function verify
             * @memberof fastcity.admin.CreateGroup
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CreateGroup.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                        return "companyId: integer|Long expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                return null;
            };

            /**
             * Creates a CreateGroup message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.CreateGroup
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.CreateGroup} CreateGroup
             */
            CreateGroup.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.CreateGroup)
                    return object;
                var message = new $root.fastcity.admin.CreateGroup();
                if (object.companyId != null)
                    if ($util.Long)
                        (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = true;
                    else if (typeof object.companyId === "string")
                        message.companyId = parseInt(object.companyId, 10);
                    else if (typeof object.companyId === "number")
                        message.companyId = object.companyId;
                    else if (typeof object.companyId === "object")
                        message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber(true);
                if (object.name != null)
                    message.name = String(object.name);
                return message;
            };

            /**
             * Creates a plain object from a CreateGroup message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.CreateGroup
             * @static
             * @param {fastcity.admin.CreateGroup} message CreateGroup
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateGroup.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.companyId = options.longs === String ? "0" : 0;
                    object.name = "";
                }
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (typeof message.companyId === "number")
                        object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                    else
                        object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber(true) : message.companyId;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                return object;
            };

            /**
             * Converts this CreateGroup to JSON.
             * @function toJSON
             * @memberof fastcity.admin.CreateGroup
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateGroup.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return CreateGroup;
        })();

        admin.UpdateGroup = (function() {

            /**
             * Properties of an UpdateGroup.
             * @memberof fastcity.admin
             * @interface IUpdateGroup
             * @property {number|Long|null} [groupId] UpdateGroup groupId
             * @property {string|null} [name] UpdateGroup name
             */

            /**
             * Constructs a new UpdateGroup.
             * @memberof fastcity.admin
             * @classdesc Represents an UpdateGroup.
             * @implements IUpdateGroup
             * @constructor
             * @param {fastcity.admin.IUpdateGroup=} [properties] Properties to set
             */
            function UpdateGroup(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * UpdateGroup groupId.
             * @member {number|Long} groupId
             * @memberof fastcity.admin.UpdateGroup
             * @instance
             */
            UpdateGroup.prototype.groupId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * UpdateGroup name.
             * @member {string} name
             * @memberof fastcity.admin.UpdateGroup
             * @instance
             */
            UpdateGroup.prototype.name = "";

            /**
             * Creates a new UpdateGroup instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.UpdateGroup
             * @static
             * @param {fastcity.admin.IUpdateGroup=} [properties] Properties to set
             * @returns {fastcity.admin.UpdateGroup} UpdateGroup instance
             */
            UpdateGroup.create = function create(properties) {
                return new UpdateGroup(properties);
            };

            /**
             * Encodes the specified UpdateGroup message. Does not implicitly {@link fastcity.admin.UpdateGroup.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.UpdateGroup
             * @static
             * @param {fastcity.admin.IUpdateGroup} message UpdateGroup message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateGroup.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.groupId != null && Object.hasOwnProperty.call(message, "groupId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.groupId);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                return writer;
            };

            /**
             * Decodes an UpdateGroup message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.UpdateGroup
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.UpdateGroup} UpdateGroup
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateGroup.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.UpdateGroup();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.groupId = reader.uint64();
                        break;
                    case 2:
                        message.name = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an UpdateGroup message.
             * @function verify
             * @memberof fastcity.admin.UpdateGroup
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            UpdateGroup.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.groupId != null && message.hasOwnProperty("groupId"))
                    if (!$util.isInteger(message.groupId) && !(message.groupId && $util.isInteger(message.groupId.low) && $util.isInteger(message.groupId.high)))
                        return "groupId: integer|Long expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                return null;
            };

            /**
             * Creates an UpdateGroup message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.UpdateGroup
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.UpdateGroup} UpdateGroup
             */
            UpdateGroup.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.UpdateGroup)
                    return object;
                var message = new $root.fastcity.admin.UpdateGroup();
                if (object.groupId != null)
                    if ($util.Long)
                        (message.groupId = $util.Long.fromValue(object.groupId)).unsigned = true;
                    else if (typeof object.groupId === "string")
                        message.groupId = parseInt(object.groupId, 10);
                    else if (typeof object.groupId === "number")
                        message.groupId = object.groupId;
                    else if (typeof object.groupId === "object")
                        message.groupId = new $util.LongBits(object.groupId.low >>> 0, object.groupId.high >>> 0).toNumber(true);
                if (object.name != null)
                    message.name = String(object.name);
                return message;
            };

            /**
             * Creates a plain object from an UpdateGroup message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.UpdateGroup
             * @static
             * @param {fastcity.admin.UpdateGroup} message UpdateGroup
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UpdateGroup.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.groupId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.groupId = options.longs === String ? "0" : 0;
                    object.name = "";
                }
                if (message.groupId != null && message.hasOwnProperty("groupId"))
                    if (typeof message.groupId === "number")
                        object.groupId = options.longs === String ? String(message.groupId) : message.groupId;
                    else
                        object.groupId = options.longs === String ? $util.Long.prototype.toString.call(message.groupId) : options.longs === Number ? new $util.LongBits(message.groupId.low >>> 0, message.groupId.high >>> 0).toNumber(true) : message.groupId;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                return object;
            };

            /**
             * Converts this UpdateGroup to JSON.
             * @function toJSON
             * @memberof fastcity.admin.UpdateGroup
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UpdateGroup.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return UpdateGroup;
        })();

        admin.DeleteGroup = (function() {

            /**
             * Properties of a DeleteGroup.
             * @memberof fastcity.admin
             * @interface IDeleteGroup
             * @property {number|Long|null} [groupId] DeleteGroup groupId
             */

            /**
             * Constructs a new DeleteGroup.
             * @memberof fastcity.admin
             * @classdesc Represents a DeleteGroup.
             * @implements IDeleteGroup
             * @constructor
             * @param {fastcity.admin.IDeleteGroup=} [properties] Properties to set
             */
            function DeleteGroup(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * DeleteGroup groupId.
             * @member {number|Long} groupId
             * @memberof fastcity.admin.DeleteGroup
             * @instance
             */
            DeleteGroup.prototype.groupId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Creates a new DeleteGroup instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.DeleteGroup
             * @static
             * @param {fastcity.admin.IDeleteGroup=} [properties] Properties to set
             * @returns {fastcity.admin.DeleteGroup} DeleteGroup instance
             */
            DeleteGroup.create = function create(properties) {
                return new DeleteGroup(properties);
            };

            /**
             * Encodes the specified DeleteGroup message. Does not implicitly {@link fastcity.admin.DeleteGroup.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.DeleteGroup
             * @static
             * @param {fastcity.admin.IDeleteGroup} message DeleteGroup message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteGroup.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.groupId != null && Object.hasOwnProperty.call(message, "groupId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.groupId);
                return writer;
            };

            /**
             * Decodes a DeleteGroup message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.DeleteGroup
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.DeleteGroup} DeleteGroup
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteGroup.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.DeleteGroup();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.groupId = reader.uint64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a DeleteGroup message.
             * @function verify
             * @memberof fastcity.admin.DeleteGroup
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DeleteGroup.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.groupId != null && message.hasOwnProperty("groupId"))
                    if (!$util.isInteger(message.groupId) && !(message.groupId && $util.isInteger(message.groupId.low) && $util.isInteger(message.groupId.high)))
                        return "groupId: integer|Long expected";
                return null;
            };

            /**
             * Creates a DeleteGroup message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.DeleteGroup
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.DeleteGroup} DeleteGroup
             */
            DeleteGroup.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.DeleteGroup)
                    return object;
                var message = new $root.fastcity.admin.DeleteGroup();
                if (object.groupId != null)
                    if ($util.Long)
                        (message.groupId = $util.Long.fromValue(object.groupId)).unsigned = true;
                    else if (typeof object.groupId === "string")
                        message.groupId = parseInt(object.groupId, 10);
                    else if (typeof object.groupId === "number")
                        message.groupId = object.groupId;
                    else if (typeof object.groupId === "object")
                        message.groupId = new $util.LongBits(object.groupId.low >>> 0, object.groupId.high >>> 0).toNumber(true);
                return message;
            };

            /**
             * Creates a plain object from a DeleteGroup message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.DeleteGroup
             * @static
             * @param {fastcity.admin.DeleteGroup} message DeleteGroup
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DeleteGroup.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.groupId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.groupId = options.longs === String ? "0" : 0;
                if (message.groupId != null && message.hasOwnProperty("groupId"))
                    if (typeof message.groupId === "number")
                        object.groupId = options.longs === String ? String(message.groupId) : message.groupId;
                    else
                        object.groupId = options.longs === String ? $util.Long.prototype.toString.call(message.groupId) : options.longs === Number ? new $util.LongBits(message.groupId.low >>> 0, message.groupId.high >>> 0).toNumber(true) : message.groupId;
                return object;
            };

            /**
             * Converts this DeleteGroup to JSON.
             * @function toJSON
             * @memberof fastcity.admin.DeleteGroup
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DeleteGroup.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return DeleteGroup;
        })();

        admin.GetGroupList = (function() {

            /**
             * Properties of a GetGroupList.
             * @memberof fastcity.admin
             * @interface IGetGroupList
             * @property {number|Long|null} [companyId] GetGroupList companyId
             * @property {boolean|null} [withDeleted] GetGroupList withDeleted
             */

            /**
             * Constructs a new GetGroupList.
             * @memberof fastcity.admin
             * @classdesc Represents a GetGroupList.
             * @implements IGetGroupList
             * @constructor
             * @param {fastcity.admin.IGetGroupList=} [properties] Properties to set
             */
            function GetGroupList(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GetGroupList companyId.
             * @member {number|Long} companyId
             * @memberof fastcity.admin.GetGroupList
             * @instance
             */
            GetGroupList.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * GetGroupList withDeleted.
             * @member {boolean} withDeleted
             * @memberof fastcity.admin.GetGroupList
             * @instance
             */
            GetGroupList.prototype.withDeleted = false;

            /**
             * Creates a new GetGroupList instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.GetGroupList
             * @static
             * @param {fastcity.admin.IGetGroupList=} [properties] Properties to set
             * @returns {fastcity.admin.GetGroupList} GetGroupList instance
             */
            GetGroupList.create = function create(properties) {
                return new GetGroupList(properties);
            };

            /**
             * Encodes the specified GetGroupList message. Does not implicitly {@link fastcity.admin.GetGroupList.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.GetGroupList
             * @static
             * @param {fastcity.admin.IGetGroupList} message GetGroupList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetGroupList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.companyId);
                if (message.withDeleted != null && Object.hasOwnProperty.call(message, "withDeleted"))
                    writer.uint32(/* id 2, wireType 0 =*/16).bool(message.withDeleted);
                return writer;
            };

            /**
             * Decodes a GetGroupList message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.GetGroupList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.GetGroupList} GetGroupList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetGroupList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.GetGroupList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.companyId = reader.uint64();
                        break;
                    case 2:
                        message.withDeleted = reader.bool();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a GetGroupList message.
             * @function verify
             * @memberof fastcity.admin.GetGroupList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetGroupList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                        return "companyId: integer|Long expected";
                if (message.withDeleted != null && message.hasOwnProperty("withDeleted"))
                    if (typeof message.withDeleted !== "boolean")
                        return "withDeleted: boolean expected";
                return null;
            };

            /**
             * Creates a GetGroupList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.GetGroupList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.GetGroupList} GetGroupList
             */
            GetGroupList.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.GetGroupList)
                    return object;
                var message = new $root.fastcity.admin.GetGroupList();
                if (object.companyId != null)
                    if ($util.Long)
                        (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = true;
                    else if (typeof object.companyId === "string")
                        message.companyId = parseInt(object.companyId, 10);
                    else if (typeof object.companyId === "number")
                        message.companyId = object.companyId;
                    else if (typeof object.companyId === "object")
                        message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber(true);
                if (object.withDeleted != null)
                    message.withDeleted = Boolean(object.withDeleted);
                return message;
            };

            /**
             * Creates a plain object from a GetGroupList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.GetGroupList
             * @static
             * @param {fastcity.admin.GetGroupList} message GetGroupList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetGroupList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.companyId = options.longs === String ? "0" : 0;
                    object.withDeleted = false;
                }
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (typeof message.companyId === "number")
                        object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                    else
                        object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber(true) : message.companyId;
                if (message.withDeleted != null && message.hasOwnProperty("withDeleted"))
                    object.withDeleted = message.withDeleted;
                return object;
            };

            /**
             * Converts this GetGroupList to JSON.
             * @function toJSON
             * @memberof fastcity.admin.GetGroupList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetGroupList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return GetGroupList;
        })();

        admin.GroupList = (function() {

            /**
             * Properties of a GroupList.
             * @memberof fastcity.admin
             * @interface IGroupList
             * @property {Array.<fastcity.admin.IGroup>|null} [group] GroupList group
             */

            /**
             * Constructs a new GroupList.
             * @memberof fastcity.admin
             * @classdesc Represents a GroupList.
             * @implements IGroupList
             * @constructor
             * @param {fastcity.admin.IGroupList=} [properties] Properties to set
             */
            function GroupList(properties) {
                this.group = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GroupList group.
             * @member {Array.<fastcity.admin.IGroup>} group
             * @memberof fastcity.admin.GroupList
             * @instance
             */
            GroupList.prototype.group = $util.emptyArray;

            /**
             * Creates a new GroupList instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.GroupList
             * @static
             * @param {fastcity.admin.IGroupList=} [properties] Properties to set
             * @returns {fastcity.admin.GroupList} GroupList instance
             */
            GroupList.create = function create(properties) {
                return new GroupList(properties);
            };

            /**
             * Encodes the specified GroupList message. Does not implicitly {@link fastcity.admin.GroupList.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.GroupList
             * @static
             * @param {fastcity.admin.IGroupList} message GroupList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GroupList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.group != null && message.group.length)
                    for (var i = 0; i < message.group.length; ++i)
                        $root.fastcity.admin.Group.encode(message.group[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a GroupList message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.GroupList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.GroupList} GroupList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GroupList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.GroupList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.group && message.group.length))
                            message.group = [];
                        message.group.push($root.fastcity.admin.Group.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a GroupList message.
             * @function verify
             * @memberof fastcity.admin.GroupList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GroupList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.group != null && message.hasOwnProperty("group")) {
                    if (!Array.isArray(message.group))
                        return "group: array expected";
                    for (var i = 0; i < message.group.length; ++i) {
                        var error = $root.fastcity.admin.Group.verify(message.group[i]);
                        if (error)
                            return "group." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a GroupList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.GroupList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.GroupList} GroupList
             */
            GroupList.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.GroupList)
                    return object;
                var message = new $root.fastcity.admin.GroupList();
                if (object.group) {
                    if (!Array.isArray(object.group))
                        throw TypeError(".fastcity.admin.GroupList.group: array expected");
                    message.group = [];
                    for (var i = 0; i < object.group.length; ++i) {
                        if (typeof object.group[i] !== "object")
                            throw TypeError(".fastcity.admin.GroupList.group: object expected");
                        message.group[i] = $root.fastcity.admin.Group.fromObject(object.group[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a GroupList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.GroupList
             * @static
             * @param {fastcity.admin.GroupList} message GroupList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GroupList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.group = [];
                if (message.group && message.group.length) {
                    object.group = [];
                    for (var j = 0; j < message.group.length; ++j)
                        object.group[j] = $root.fastcity.admin.Group.toObject(message.group[j], options);
                }
                return object;
            };

            /**
             * Converts this GroupList to JSON.
             * @function toJSON
             * @memberof fastcity.admin.GroupList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GroupList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return GroupList;
        })();

        admin.IntegrationToken = (function() {

            /**
             * Properties of an IntegrationToken.
             * @memberof fastcity.admin
             * @interface IIntegrationToken
             * @property {number|Long|null} [tokenId] IntegrationToken tokenId
             * @property {number|Long|null} [companyId] IntegrationToken companyId
             * @property {string|null} [token] IntegrationToken token
             * @property {boolean|null} [isDeleted] IntegrationToken isDeleted
             */

            /**
             * Constructs a new IntegrationToken.
             * @memberof fastcity.admin
             * @classdesc Represents an IntegrationToken.
             * @implements IIntegrationToken
             * @constructor
             * @param {fastcity.admin.IIntegrationToken=} [properties] Properties to set
             */
            function IntegrationToken(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * IntegrationToken tokenId.
             * @member {number|Long} tokenId
             * @memberof fastcity.admin.IntegrationToken
             * @instance
             */
            IntegrationToken.prototype.tokenId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * IntegrationToken companyId.
             * @member {number|Long} companyId
             * @memberof fastcity.admin.IntegrationToken
             * @instance
             */
            IntegrationToken.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * IntegrationToken token.
             * @member {string} token
             * @memberof fastcity.admin.IntegrationToken
             * @instance
             */
            IntegrationToken.prototype.token = "";

            /**
             * IntegrationToken isDeleted.
             * @member {boolean} isDeleted
             * @memberof fastcity.admin.IntegrationToken
             * @instance
             */
            IntegrationToken.prototype.isDeleted = false;

            /**
             * Creates a new IntegrationToken instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.IntegrationToken
             * @static
             * @param {fastcity.admin.IIntegrationToken=} [properties] Properties to set
             * @returns {fastcity.admin.IntegrationToken} IntegrationToken instance
             */
            IntegrationToken.create = function create(properties) {
                return new IntegrationToken(properties);
            };

            /**
             * Encodes the specified IntegrationToken message. Does not implicitly {@link fastcity.admin.IntegrationToken.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.IntegrationToken
             * @static
             * @param {fastcity.admin.IIntegrationToken} message IntegrationToken message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            IntegrationToken.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.tokenId != null && Object.hasOwnProperty.call(message, "tokenId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.tokenId);
                if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.companyId);
                if (message.token != null && Object.hasOwnProperty.call(message, "token"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.token);
                if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                    writer.uint32(/* id 4, wireType 0 =*/32).bool(message.isDeleted);
                return writer;
            };

            /**
             * Decodes an IntegrationToken message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.IntegrationToken
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.IntegrationToken} IntegrationToken
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            IntegrationToken.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.IntegrationToken();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.tokenId = reader.uint64();
                        break;
                    case 2:
                        message.companyId = reader.uint64();
                        break;
                    case 3:
                        message.token = reader.string();
                        break;
                    case 4:
                        message.isDeleted = reader.bool();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an IntegrationToken message.
             * @function verify
             * @memberof fastcity.admin.IntegrationToken
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            IntegrationToken.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.tokenId != null && message.hasOwnProperty("tokenId"))
                    if (!$util.isInteger(message.tokenId) && !(message.tokenId && $util.isInteger(message.tokenId.low) && $util.isInteger(message.tokenId.high)))
                        return "tokenId: integer|Long expected";
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                        return "companyId: integer|Long expected";
                if (message.token != null && message.hasOwnProperty("token"))
                    if (!$util.isString(message.token))
                        return "token: string expected";
                if (message.isDeleted != null && message.hasOwnProperty("isDeleted"))
                    if (typeof message.isDeleted !== "boolean")
                        return "isDeleted: boolean expected";
                return null;
            };

            /**
             * Creates an IntegrationToken message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.IntegrationToken
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.IntegrationToken} IntegrationToken
             */
            IntegrationToken.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.IntegrationToken)
                    return object;
                var message = new $root.fastcity.admin.IntegrationToken();
                if (object.tokenId != null)
                    if ($util.Long)
                        (message.tokenId = $util.Long.fromValue(object.tokenId)).unsigned = true;
                    else if (typeof object.tokenId === "string")
                        message.tokenId = parseInt(object.tokenId, 10);
                    else if (typeof object.tokenId === "number")
                        message.tokenId = object.tokenId;
                    else if (typeof object.tokenId === "object")
                        message.tokenId = new $util.LongBits(object.tokenId.low >>> 0, object.tokenId.high >>> 0).toNumber(true);
                if (object.companyId != null)
                    if ($util.Long)
                        (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = true;
                    else if (typeof object.companyId === "string")
                        message.companyId = parseInt(object.companyId, 10);
                    else if (typeof object.companyId === "number")
                        message.companyId = object.companyId;
                    else if (typeof object.companyId === "object")
                        message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber(true);
                if (object.token != null)
                    message.token = String(object.token);
                if (object.isDeleted != null)
                    message.isDeleted = Boolean(object.isDeleted);
                return message;
            };

            /**
             * Creates a plain object from an IntegrationToken message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.IntegrationToken
             * @static
             * @param {fastcity.admin.IntegrationToken} message IntegrationToken
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            IntegrationToken.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.tokenId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.tokenId = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.companyId = options.longs === String ? "0" : 0;
                    object.token = "";
                    object.isDeleted = false;
                }
                if (message.tokenId != null && message.hasOwnProperty("tokenId"))
                    if (typeof message.tokenId === "number")
                        object.tokenId = options.longs === String ? String(message.tokenId) : message.tokenId;
                    else
                        object.tokenId = options.longs === String ? $util.Long.prototype.toString.call(message.tokenId) : options.longs === Number ? new $util.LongBits(message.tokenId.low >>> 0, message.tokenId.high >>> 0).toNumber(true) : message.tokenId;
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (typeof message.companyId === "number")
                        object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                    else
                        object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber(true) : message.companyId;
                if (message.token != null && message.hasOwnProperty("token"))
                    object.token = message.token;
                if (message.isDeleted != null && message.hasOwnProperty("isDeleted"))
                    object.isDeleted = message.isDeleted;
                return object;
            };

            /**
             * Converts this IntegrationToken to JSON.
             * @function toJSON
             * @memberof fastcity.admin.IntegrationToken
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            IntegrationToken.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return IntegrationToken;
        })();

        admin.CreateIntegrationToken = (function() {

            /**
             * Properties of a CreateIntegrationToken.
             * @memberof fastcity.admin
             * @interface ICreateIntegrationToken
             * @property {number|Long|null} [companyId] CreateIntegrationToken companyId
             */

            /**
             * Constructs a new CreateIntegrationToken.
             * @memberof fastcity.admin
             * @classdesc Represents a CreateIntegrationToken.
             * @implements ICreateIntegrationToken
             * @constructor
             * @param {fastcity.admin.ICreateIntegrationToken=} [properties] Properties to set
             */
            function CreateIntegrationToken(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CreateIntegrationToken companyId.
             * @member {number|Long} companyId
             * @memberof fastcity.admin.CreateIntegrationToken
             * @instance
             */
            CreateIntegrationToken.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Creates a new CreateIntegrationToken instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.CreateIntegrationToken
             * @static
             * @param {fastcity.admin.ICreateIntegrationToken=} [properties] Properties to set
             * @returns {fastcity.admin.CreateIntegrationToken} CreateIntegrationToken instance
             */
            CreateIntegrationToken.create = function create(properties) {
                return new CreateIntegrationToken(properties);
            };

            /**
             * Encodes the specified CreateIntegrationToken message. Does not implicitly {@link fastcity.admin.CreateIntegrationToken.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.CreateIntegrationToken
             * @static
             * @param {fastcity.admin.ICreateIntegrationToken} message CreateIntegrationToken message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateIntegrationToken.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.companyId);
                return writer;
            };

            /**
             * Decodes a CreateIntegrationToken message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.CreateIntegrationToken
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.CreateIntegrationToken} CreateIntegrationToken
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateIntegrationToken.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.CreateIntegrationToken();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.companyId = reader.uint64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a CreateIntegrationToken message.
             * @function verify
             * @memberof fastcity.admin.CreateIntegrationToken
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CreateIntegrationToken.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                        return "companyId: integer|Long expected";
                return null;
            };

            /**
             * Creates a CreateIntegrationToken message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.CreateIntegrationToken
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.CreateIntegrationToken} CreateIntegrationToken
             */
            CreateIntegrationToken.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.CreateIntegrationToken)
                    return object;
                var message = new $root.fastcity.admin.CreateIntegrationToken();
                if (object.companyId != null)
                    if ($util.Long)
                        (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = true;
                    else if (typeof object.companyId === "string")
                        message.companyId = parseInt(object.companyId, 10);
                    else if (typeof object.companyId === "number")
                        message.companyId = object.companyId;
                    else if (typeof object.companyId === "object")
                        message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber(true);
                return message;
            };

            /**
             * Creates a plain object from a CreateIntegrationToken message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.CreateIntegrationToken
             * @static
             * @param {fastcity.admin.CreateIntegrationToken} message CreateIntegrationToken
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateIntegrationToken.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.companyId = options.longs === String ? "0" : 0;
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (typeof message.companyId === "number")
                        object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                    else
                        object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber(true) : message.companyId;
                return object;
            };

            /**
             * Converts this CreateIntegrationToken to JSON.
             * @function toJSON
             * @memberof fastcity.admin.CreateIntegrationToken
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateIntegrationToken.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return CreateIntegrationToken;
        })();

        admin.DeleteIntegrationToken = (function() {

            /**
             * Properties of a DeleteIntegrationToken.
             * @memberof fastcity.admin
             * @interface IDeleteIntegrationToken
             * @property {number|Long|null} [tokenId] DeleteIntegrationToken tokenId
             */

            /**
             * Constructs a new DeleteIntegrationToken.
             * @memberof fastcity.admin
             * @classdesc Represents a DeleteIntegrationToken.
             * @implements IDeleteIntegrationToken
             * @constructor
             * @param {fastcity.admin.IDeleteIntegrationToken=} [properties] Properties to set
             */
            function DeleteIntegrationToken(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * DeleteIntegrationToken tokenId.
             * @member {number|Long} tokenId
             * @memberof fastcity.admin.DeleteIntegrationToken
             * @instance
             */
            DeleteIntegrationToken.prototype.tokenId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Creates a new DeleteIntegrationToken instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.DeleteIntegrationToken
             * @static
             * @param {fastcity.admin.IDeleteIntegrationToken=} [properties] Properties to set
             * @returns {fastcity.admin.DeleteIntegrationToken} DeleteIntegrationToken instance
             */
            DeleteIntegrationToken.create = function create(properties) {
                return new DeleteIntegrationToken(properties);
            };

            /**
             * Encodes the specified DeleteIntegrationToken message. Does not implicitly {@link fastcity.admin.DeleteIntegrationToken.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.DeleteIntegrationToken
             * @static
             * @param {fastcity.admin.IDeleteIntegrationToken} message DeleteIntegrationToken message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteIntegrationToken.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.tokenId != null && Object.hasOwnProperty.call(message, "tokenId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.tokenId);
                return writer;
            };

            /**
             * Decodes a DeleteIntegrationToken message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.DeleteIntegrationToken
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.DeleteIntegrationToken} DeleteIntegrationToken
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteIntegrationToken.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.DeleteIntegrationToken();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.tokenId = reader.uint64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a DeleteIntegrationToken message.
             * @function verify
             * @memberof fastcity.admin.DeleteIntegrationToken
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DeleteIntegrationToken.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.tokenId != null && message.hasOwnProperty("tokenId"))
                    if (!$util.isInteger(message.tokenId) && !(message.tokenId && $util.isInteger(message.tokenId.low) && $util.isInteger(message.tokenId.high)))
                        return "tokenId: integer|Long expected";
                return null;
            };

            /**
             * Creates a DeleteIntegrationToken message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.DeleteIntegrationToken
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.DeleteIntegrationToken} DeleteIntegrationToken
             */
            DeleteIntegrationToken.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.DeleteIntegrationToken)
                    return object;
                var message = new $root.fastcity.admin.DeleteIntegrationToken();
                if (object.tokenId != null)
                    if ($util.Long)
                        (message.tokenId = $util.Long.fromValue(object.tokenId)).unsigned = true;
                    else if (typeof object.tokenId === "string")
                        message.tokenId = parseInt(object.tokenId, 10);
                    else if (typeof object.tokenId === "number")
                        message.tokenId = object.tokenId;
                    else if (typeof object.tokenId === "object")
                        message.tokenId = new $util.LongBits(object.tokenId.low >>> 0, object.tokenId.high >>> 0).toNumber(true);
                return message;
            };

            /**
             * Creates a plain object from a DeleteIntegrationToken message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.DeleteIntegrationToken
             * @static
             * @param {fastcity.admin.DeleteIntegrationToken} message DeleteIntegrationToken
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DeleteIntegrationToken.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.tokenId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.tokenId = options.longs === String ? "0" : 0;
                if (message.tokenId != null && message.hasOwnProperty("tokenId"))
                    if (typeof message.tokenId === "number")
                        object.tokenId = options.longs === String ? String(message.tokenId) : message.tokenId;
                    else
                        object.tokenId = options.longs === String ? $util.Long.prototype.toString.call(message.tokenId) : options.longs === Number ? new $util.LongBits(message.tokenId.low >>> 0, message.tokenId.high >>> 0).toNumber(true) : message.tokenId;
                return object;
            };

            /**
             * Converts this DeleteIntegrationToken to JSON.
             * @function toJSON
             * @memberof fastcity.admin.DeleteIntegrationToken
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DeleteIntegrationToken.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return DeleteIntegrationToken;
        })();

        admin.GetIntegrationTokenList = (function() {

            /**
             * Properties of a GetIntegrationTokenList.
             * @memberof fastcity.admin
             * @interface IGetIntegrationTokenList
             * @property {number|Long|null} [companyId] GetIntegrationTokenList companyId
             */

            /**
             * Constructs a new GetIntegrationTokenList.
             * @memberof fastcity.admin
             * @classdesc Represents a GetIntegrationTokenList.
             * @implements IGetIntegrationTokenList
             * @constructor
             * @param {fastcity.admin.IGetIntegrationTokenList=} [properties] Properties to set
             */
            function GetIntegrationTokenList(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GetIntegrationTokenList companyId.
             * @member {number|Long} companyId
             * @memberof fastcity.admin.GetIntegrationTokenList
             * @instance
             */
            GetIntegrationTokenList.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Creates a new GetIntegrationTokenList instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.GetIntegrationTokenList
             * @static
             * @param {fastcity.admin.IGetIntegrationTokenList=} [properties] Properties to set
             * @returns {fastcity.admin.GetIntegrationTokenList} GetIntegrationTokenList instance
             */
            GetIntegrationTokenList.create = function create(properties) {
                return new GetIntegrationTokenList(properties);
            };

            /**
             * Encodes the specified GetIntegrationTokenList message. Does not implicitly {@link fastcity.admin.GetIntegrationTokenList.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.GetIntegrationTokenList
             * @static
             * @param {fastcity.admin.IGetIntegrationTokenList} message GetIntegrationTokenList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetIntegrationTokenList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.companyId);
                return writer;
            };

            /**
             * Decodes a GetIntegrationTokenList message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.GetIntegrationTokenList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.GetIntegrationTokenList} GetIntegrationTokenList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetIntegrationTokenList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.GetIntegrationTokenList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.companyId = reader.uint64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a GetIntegrationTokenList message.
             * @function verify
             * @memberof fastcity.admin.GetIntegrationTokenList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetIntegrationTokenList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                        return "companyId: integer|Long expected";
                return null;
            };

            /**
             * Creates a GetIntegrationTokenList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.GetIntegrationTokenList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.GetIntegrationTokenList} GetIntegrationTokenList
             */
            GetIntegrationTokenList.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.GetIntegrationTokenList)
                    return object;
                var message = new $root.fastcity.admin.GetIntegrationTokenList();
                if (object.companyId != null)
                    if ($util.Long)
                        (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = true;
                    else if (typeof object.companyId === "string")
                        message.companyId = parseInt(object.companyId, 10);
                    else if (typeof object.companyId === "number")
                        message.companyId = object.companyId;
                    else if (typeof object.companyId === "object")
                        message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber(true);
                return message;
            };

            /**
             * Creates a plain object from a GetIntegrationTokenList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.GetIntegrationTokenList
             * @static
             * @param {fastcity.admin.GetIntegrationTokenList} message GetIntegrationTokenList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetIntegrationTokenList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.companyId = options.longs === String ? "0" : 0;
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (typeof message.companyId === "number")
                        object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                    else
                        object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber(true) : message.companyId;
                return object;
            };

            /**
             * Converts this GetIntegrationTokenList to JSON.
             * @function toJSON
             * @memberof fastcity.admin.GetIntegrationTokenList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetIntegrationTokenList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return GetIntegrationTokenList;
        })();

        admin.IntegrationTokenList = (function() {

            /**
             * Properties of an IntegrationTokenList.
             * @memberof fastcity.admin
             * @interface IIntegrationTokenList
             * @property {Array.<fastcity.admin.IIntegrationToken>|null} [integrationToken] IntegrationTokenList integrationToken
             */

            /**
             * Constructs a new IntegrationTokenList.
             * @memberof fastcity.admin
             * @classdesc Represents an IntegrationTokenList.
             * @implements IIntegrationTokenList
             * @constructor
             * @param {fastcity.admin.IIntegrationTokenList=} [properties] Properties to set
             */
            function IntegrationTokenList(properties) {
                this.integrationToken = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * IntegrationTokenList integrationToken.
             * @member {Array.<fastcity.admin.IIntegrationToken>} integrationToken
             * @memberof fastcity.admin.IntegrationTokenList
             * @instance
             */
            IntegrationTokenList.prototype.integrationToken = $util.emptyArray;

            /**
             * Creates a new IntegrationTokenList instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.IntegrationTokenList
             * @static
             * @param {fastcity.admin.IIntegrationTokenList=} [properties] Properties to set
             * @returns {fastcity.admin.IntegrationTokenList} IntegrationTokenList instance
             */
            IntegrationTokenList.create = function create(properties) {
                return new IntegrationTokenList(properties);
            };

            /**
             * Encodes the specified IntegrationTokenList message. Does not implicitly {@link fastcity.admin.IntegrationTokenList.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.IntegrationTokenList
             * @static
             * @param {fastcity.admin.IIntegrationTokenList} message IntegrationTokenList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            IntegrationTokenList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.integrationToken != null && message.integrationToken.length)
                    for (var i = 0; i < message.integrationToken.length; ++i)
                        $root.fastcity.admin.IntegrationToken.encode(message.integrationToken[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes an IntegrationTokenList message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.IntegrationTokenList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.IntegrationTokenList} IntegrationTokenList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            IntegrationTokenList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.IntegrationTokenList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.integrationToken && message.integrationToken.length))
                            message.integrationToken = [];
                        message.integrationToken.push($root.fastcity.admin.IntegrationToken.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an IntegrationTokenList message.
             * @function verify
             * @memberof fastcity.admin.IntegrationTokenList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            IntegrationTokenList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.integrationToken != null && message.hasOwnProperty("integrationToken")) {
                    if (!Array.isArray(message.integrationToken))
                        return "integrationToken: array expected";
                    for (var i = 0; i < message.integrationToken.length; ++i) {
                        var error = $root.fastcity.admin.IntegrationToken.verify(message.integrationToken[i]);
                        if (error)
                            return "integrationToken." + error;
                    }
                }
                return null;
            };

            /**
             * Creates an IntegrationTokenList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.IntegrationTokenList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.IntegrationTokenList} IntegrationTokenList
             */
            IntegrationTokenList.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.IntegrationTokenList)
                    return object;
                var message = new $root.fastcity.admin.IntegrationTokenList();
                if (object.integrationToken) {
                    if (!Array.isArray(object.integrationToken))
                        throw TypeError(".fastcity.admin.IntegrationTokenList.integrationToken: array expected");
                    message.integrationToken = [];
                    for (var i = 0; i < object.integrationToken.length; ++i) {
                        if (typeof object.integrationToken[i] !== "object")
                            throw TypeError(".fastcity.admin.IntegrationTokenList.integrationToken: object expected");
                        message.integrationToken[i] = $root.fastcity.admin.IntegrationToken.fromObject(object.integrationToken[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from an IntegrationTokenList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.IntegrationTokenList
             * @static
             * @param {fastcity.admin.IntegrationTokenList} message IntegrationTokenList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            IntegrationTokenList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.integrationToken = [];
                if (message.integrationToken && message.integrationToken.length) {
                    object.integrationToken = [];
                    for (var j = 0; j < message.integrationToken.length; ++j)
                        object.integrationToken[j] = $root.fastcity.admin.IntegrationToken.toObject(message.integrationToken[j], options);
                }
                return object;
            };

            /**
             * Converts this IntegrationTokenList to JSON.
             * @function toJSON
             * @memberof fastcity.admin.IntegrationTokenList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            IntegrationTokenList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return IntegrationTokenList;
        })();

        /**
         * LocationRequestResult enum.
         * @name fastcity.admin.LocationRequestResult
         * @enum {number}
         * @property {number} LocationRR_BAD_COURIER_ID_COURIER_NOT_FOUND=0 LocationRR_BAD_COURIER_ID_COURIER_NOT_FOUND value
         * @property {number} LocationRR_BAD_COMPANY_ID_COMPANY_NOT_FOUND=1 LocationRR_BAD_COMPANY_ID_COMPANY_NOT_FOUND value
         * @property {number} LocationRR_BAD_ORDER_ID_ORDER_NOT_FOUND=2 LocationRR_BAD_ORDER_ID_ORDER_NOT_FOUND value
         */
        admin.LocationRequestResult = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "LocationRR_BAD_COURIER_ID_COURIER_NOT_FOUND"] = 0;
            values[valuesById[1] = "LocationRR_BAD_COMPANY_ID_COMPANY_NOT_FOUND"] = 1;
            values[valuesById[2] = "LocationRR_BAD_ORDER_ID_ORDER_NOT_FOUND"] = 2;
            return values;
        })();

        admin.Location = (function() {

            /**
             * Properties of a Location.
             * @memberof fastcity.admin
             * @interface ILocation
             * @property {number|Long|null} [courierId] Location courierId
             * @property {number|null} [latitude] Location latitude
             * @property {number|null} [longitude] Location longitude
             * @property {number|Long|null} [timestamp] Location timestamp
             * @property {number|null} [bearing] Location bearing
             * @property {number|null} [altitude] Location altitude
             * @property {number|null} [speed] Location speed
             * @property {number|Long|null} [orderId] Location orderId
             */

            /**
             * Constructs a new Location.
             * @memberof fastcity.admin
             * @classdesc Represents a Location.
             * @implements ILocation
             * @constructor
             * @param {fastcity.admin.ILocation=} [properties] Properties to set
             */
            function Location(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Location courierId.
             * @member {number|Long} courierId
             * @memberof fastcity.admin.Location
             * @instance
             */
            Location.prototype.courierId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Location latitude.
             * @member {number} latitude
             * @memberof fastcity.admin.Location
             * @instance
             */
            Location.prototype.latitude = 0;

            /**
             * Location longitude.
             * @member {number} longitude
             * @memberof fastcity.admin.Location
             * @instance
             */
            Location.prototype.longitude = 0;

            /**
             * Location timestamp.
             * @member {number|Long} timestamp
             * @memberof fastcity.admin.Location
             * @instance
             */
            Location.prototype.timestamp = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Location bearing.
             * @member {number} bearing
             * @memberof fastcity.admin.Location
             * @instance
             */
            Location.prototype.bearing = 0;

            /**
             * Location altitude.
             * @member {number} altitude
             * @memberof fastcity.admin.Location
             * @instance
             */
            Location.prototype.altitude = 0;

            /**
             * Location speed.
             * @member {number} speed
             * @memberof fastcity.admin.Location
             * @instance
             */
            Location.prototype.speed = 0;

            /**
             * Location orderId.
             * @member {number|Long} orderId
             * @memberof fastcity.admin.Location
             * @instance
             */
            Location.prototype.orderId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Creates a new Location instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.Location
             * @static
             * @param {fastcity.admin.ILocation=} [properties] Properties to set
             * @returns {fastcity.admin.Location} Location instance
             */
            Location.create = function create(properties) {
                return new Location(properties);
            };

            /**
             * Encodes the specified Location message. Does not implicitly {@link fastcity.admin.Location.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.Location
             * @static
             * @param {fastcity.admin.ILocation} message Location message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Location.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.courierId != null && Object.hasOwnProperty.call(message, "courierId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.courierId);
                if (message.latitude != null && Object.hasOwnProperty.call(message, "latitude"))
                    writer.uint32(/* id 2, wireType 1 =*/17).double(message.latitude);
                if (message.longitude != null && Object.hasOwnProperty.call(message, "longitude"))
                    writer.uint32(/* id 3, wireType 1 =*/25).double(message.longitude);
                if (message.timestamp != null && Object.hasOwnProperty.call(message, "timestamp"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int64(message.timestamp);
                if (message.bearing != null && Object.hasOwnProperty.call(message, "bearing"))
                    writer.uint32(/* id 5, wireType 1 =*/41).double(message.bearing);
                if (message.altitude != null && Object.hasOwnProperty.call(message, "altitude"))
                    writer.uint32(/* id 6, wireType 1 =*/49).double(message.altitude);
                if (message.speed != null && Object.hasOwnProperty.call(message, "speed"))
                    writer.uint32(/* id 7, wireType 1 =*/57).double(message.speed);
                if (message.orderId != null && Object.hasOwnProperty.call(message, "orderId"))
                    writer.uint32(/* id 8, wireType 0 =*/64).uint64(message.orderId);
                return writer;
            };

            /**
             * Decodes a Location message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.Location
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.Location} Location
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Location.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.Location();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.courierId = reader.uint64();
                        break;
                    case 2:
                        message.latitude = reader.double();
                        break;
                    case 3:
                        message.longitude = reader.double();
                        break;
                    case 4:
                        message.timestamp = reader.int64();
                        break;
                    case 5:
                        message.bearing = reader.double();
                        break;
                    case 6:
                        message.altitude = reader.double();
                        break;
                    case 7:
                        message.speed = reader.double();
                        break;
                    case 8:
                        message.orderId = reader.uint64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a Location message.
             * @function verify
             * @memberof fastcity.admin.Location
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Location.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.courierId != null && message.hasOwnProperty("courierId"))
                    if (!$util.isInteger(message.courierId) && !(message.courierId && $util.isInteger(message.courierId.low) && $util.isInteger(message.courierId.high)))
                        return "courierId: integer|Long expected";
                if (message.latitude != null && message.hasOwnProperty("latitude"))
                    if (typeof message.latitude !== "number")
                        return "latitude: number expected";
                if (message.longitude != null && message.hasOwnProperty("longitude"))
                    if (typeof message.longitude !== "number")
                        return "longitude: number expected";
                if (message.timestamp != null && message.hasOwnProperty("timestamp"))
                    if (!$util.isInteger(message.timestamp) && !(message.timestamp && $util.isInteger(message.timestamp.low) && $util.isInteger(message.timestamp.high)))
                        return "timestamp: integer|Long expected";
                if (message.bearing != null && message.hasOwnProperty("bearing"))
                    if (typeof message.bearing !== "number")
                        return "bearing: number expected";
                if (message.altitude != null && message.hasOwnProperty("altitude"))
                    if (typeof message.altitude !== "number")
                        return "altitude: number expected";
                if (message.speed != null && message.hasOwnProperty("speed"))
                    if (typeof message.speed !== "number")
                        return "speed: number expected";
                if (message.orderId != null && message.hasOwnProperty("orderId"))
                    if (!$util.isInteger(message.orderId) && !(message.orderId && $util.isInteger(message.orderId.low) && $util.isInteger(message.orderId.high)))
                        return "orderId: integer|Long expected";
                return null;
            };

            /**
             * Creates a Location message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.Location
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.Location} Location
             */
            Location.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.Location)
                    return object;
                var message = new $root.fastcity.admin.Location();
                if (object.courierId != null)
                    if ($util.Long)
                        (message.courierId = $util.Long.fromValue(object.courierId)).unsigned = true;
                    else if (typeof object.courierId === "string")
                        message.courierId = parseInt(object.courierId, 10);
                    else if (typeof object.courierId === "number")
                        message.courierId = object.courierId;
                    else if (typeof object.courierId === "object")
                        message.courierId = new $util.LongBits(object.courierId.low >>> 0, object.courierId.high >>> 0).toNumber(true);
                if (object.latitude != null)
                    message.latitude = Number(object.latitude);
                if (object.longitude != null)
                    message.longitude = Number(object.longitude);
                if (object.timestamp != null)
                    if ($util.Long)
                        (message.timestamp = $util.Long.fromValue(object.timestamp)).unsigned = false;
                    else if (typeof object.timestamp === "string")
                        message.timestamp = parseInt(object.timestamp, 10);
                    else if (typeof object.timestamp === "number")
                        message.timestamp = object.timestamp;
                    else if (typeof object.timestamp === "object")
                        message.timestamp = new $util.LongBits(object.timestamp.low >>> 0, object.timestamp.high >>> 0).toNumber();
                if (object.bearing != null)
                    message.bearing = Number(object.bearing);
                if (object.altitude != null)
                    message.altitude = Number(object.altitude);
                if (object.speed != null)
                    message.speed = Number(object.speed);
                if (object.orderId != null)
                    if ($util.Long)
                        (message.orderId = $util.Long.fromValue(object.orderId)).unsigned = true;
                    else if (typeof object.orderId === "string")
                        message.orderId = parseInt(object.orderId, 10);
                    else if (typeof object.orderId === "number")
                        message.orderId = object.orderId;
                    else if (typeof object.orderId === "object")
                        message.orderId = new $util.LongBits(object.orderId.low >>> 0, object.orderId.high >>> 0).toNumber(true);
                return message;
            };

            /**
             * Creates a plain object from a Location message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.Location
             * @static
             * @param {fastcity.admin.Location} message Location
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Location.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.courierId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.courierId = options.longs === String ? "0" : 0;
                    object.latitude = 0;
                    object.longitude = 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.timestamp = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.timestamp = options.longs === String ? "0" : 0;
                    object.bearing = 0;
                    object.altitude = 0;
                    object.speed = 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.orderId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.orderId = options.longs === String ? "0" : 0;
                }
                if (message.courierId != null && message.hasOwnProperty("courierId"))
                    if (typeof message.courierId === "number")
                        object.courierId = options.longs === String ? String(message.courierId) : message.courierId;
                    else
                        object.courierId = options.longs === String ? $util.Long.prototype.toString.call(message.courierId) : options.longs === Number ? new $util.LongBits(message.courierId.low >>> 0, message.courierId.high >>> 0).toNumber(true) : message.courierId;
                if (message.latitude != null && message.hasOwnProperty("latitude"))
                    object.latitude = options.json && !isFinite(message.latitude) ? String(message.latitude) : message.latitude;
                if (message.longitude != null && message.hasOwnProperty("longitude"))
                    object.longitude = options.json && !isFinite(message.longitude) ? String(message.longitude) : message.longitude;
                if (message.timestamp != null && message.hasOwnProperty("timestamp"))
                    if (typeof message.timestamp === "number")
                        object.timestamp = options.longs === String ? String(message.timestamp) : message.timestamp;
                    else
                        object.timestamp = options.longs === String ? $util.Long.prototype.toString.call(message.timestamp) : options.longs === Number ? new $util.LongBits(message.timestamp.low >>> 0, message.timestamp.high >>> 0).toNumber() : message.timestamp;
                if (message.bearing != null && message.hasOwnProperty("bearing"))
                    object.bearing = options.json && !isFinite(message.bearing) ? String(message.bearing) : message.bearing;
                if (message.altitude != null && message.hasOwnProperty("altitude"))
                    object.altitude = options.json && !isFinite(message.altitude) ? String(message.altitude) : message.altitude;
                if (message.speed != null && message.hasOwnProperty("speed"))
                    object.speed = options.json && !isFinite(message.speed) ? String(message.speed) : message.speed;
                if (message.orderId != null && message.hasOwnProperty("orderId"))
                    if (typeof message.orderId === "number")
                        object.orderId = options.longs === String ? String(message.orderId) : message.orderId;
                    else
                        object.orderId = options.longs === String ? $util.Long.prototype.toString.call(message.orderId) : options.longs === Number ? new $util.LongBits(message.orderId.low >>> 0, message.orderId.high >>> 0).toNumber(true) : message.orderId;
                return object;
            };

            /**
             * Converts this Location to JSON.
             * @function toJSON
             * @memberof fastcity.admin.Location
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Location.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Location;
        })();

        admin.GetLastLocationList = (function() {

            /**
             * Properties of a GetLastLocationList.
             * @memberof fastcity.admin
             * @interface IGetLastLocationList
             * @property {number|Long|null} [companyId] GetLastLocationList companyId
             * @property {Array.<number|Long>|null} [courierId] GetLastLocationList courierId
             */

            /**
             * Constructs a new GetLastLocationList.
             * @memberof fastcity.admin
             * @classdesc Represents a GetLastLocationList.
             * @implements IGetLastLocationList
             * @constructor
             * @param {fastcity.admin.IGetLastLocationList=} [properties] Properties to set
             */
            function GetLastLocationList(properties) {
                this.courierId = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GetLastLocationList companyId.
             * @member {number|Long} companyId
             * @memberof fastcity.admin.GetLastLocationList
             * @instance
             */
            GetLastLocationList.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * GetLastLocationList courierId.
             * @member {Array.<number|Long>} courierId
             * @memberof fastcity.admin.GetLastLocationList
             * @instance
             */
            GetLastLocationList.prototype.courierId = $util.emptyArray;

            /**
             * Creates a new GetLastLocationList instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.GetLastLocationList
             * @static
             * @param {fastcity.admin.IGetLastLocationList=} [properties] Properties to set
             * @returns {fastcity.admin.GetLastLocationList} GetLastLocationList instance
             */
            GetLastLocationList.create = function create(properties) {
                return new GetLastLocationList(properties);
            };

            /**
             * Encodes the specified GetLastLocationList message. Does not implicitly {@link fastcity.admin.GetLastLocationList.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.GetLastLocationList
             * @static
             * @param {fastcity.admin.IGetLastLocationList} message GetLastLocationList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetLastLocationList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.companyId);
                if (message.courierId != null && message.courierId.length) {
                    writer.uint32(/* id 2, wireType 2 =*/18).fork();
                    for (var i = 0; i < message.courierId.length; ++i)
                        writer.uint64(message.courierId[i]);
                    writer.ldelim();
                }
                return writer;
            };

            /**
             * Decodes a GetLastLocationList message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.GetLastLocationList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.GetLastLocationList} GetLastLocationList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetLastLocationList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.GetLastLocationList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.companyId = reader.uint64();
                        break;
                    case 2:
                        if (!(message.courierId && message.courierId.length))
                            message.courierId = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.courierId.push(reader.uint64());
                        } else
                            message.courierId.push(reader.uint64());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a GetLastLocationList message.
             * @function verify
             * @memberof fastcity.admin.GetLastLocationList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetLastLocationList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                        return "companyId: integer|Long expected";
                if (message.courierId != null && message.hasOwnProperty("courierId")) {
                    if (!Array.isArray(message.courierId))
                        return "courierId: array expected";
                    for (var i = 0; i < message.courierId.length; ++i)
                        if (!$util.isInteger(message.courierId[i]) && !(message.courierId[i] && $util.isInteger(message.courierId[i].low) && $util.isInteger(message.courierId[i].high)))
                            return "courierId: integer|Long[] expected";
                }
                return null;
            };

            /**
             * Creates a GetLastLocationList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.GetLastLocationList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.GetLastLocationList} GetLastLocationList
             */
            GetLastLocationList.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.GetLastLocationList)
                    return object;
                var message = new $root.fastcity.admin.GetLastLocationList();
                if (object.companyId != null)
                    if ($util.Long)
                        (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = true;
                    else if (typeof object.companyId === "string")
                        message.companyId = parseInt(object.companyId, 10);
                    else if (typeof object.companyId === "number")
                        message.companyId = object.companyId;
                    else if (typeof object.companyId === "object")
                        message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber(true);
                if (object.courierId) {
                    if (!Array.isArray(object.courierId))
                        throw TypeError(".fastcity.admin.GetLastLocationList.courierId: array expected");
                    message.courierId = [];
                    for (var i = 0; i < object.courierId.length; ++i)
                        if ($util.Long)
                            (message.courierId[i] = $util.Long.fromValue(object.courierId[i])).unsigned = true;
                        else if (typeof object.courierId[i] === "string")
                            message.courierId[i] = parseInt(object.courierId[i], 10);
                        else if (typeof object.courierId[i] === "number")
                            message.courierId[i] = object.courierId[i];
                        else if (typeof object.courierId[i] === "object")
                            message.courierId[i] = new $util.LongBits(object.courierId[i].low >>> 0, object.courierId[i].high >>> 0).toNumber(true);
                }
                return message;
            };

            /**
             * Creates a plain object from a GetLastLocationList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.GetLastLocationList
             * @static
             * @param {fastcity.admin.GetLastLocationList} message GetLastLocationList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetLastLocationList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.courierId = [];
                if (options.defaults)
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.companyId = options.longs === String ? "0" : 0;
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (typeof message.companyId === "number")
                        object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                    else
                        object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber(true) : message.companyId;
                if (message.courierId && message.courierId.length) {
                    object.courierId = [];
                    for (var j = 0; j < message.courierId.length; ++j)
                        if (typeof message.courierId[j] === "number")
                            object.courierId[j] = options.longs === String ? String(message.courierId[j]) : message.courierId[j];
                        else
                            object.courierId[j] = options.longs === String ? $util.Long.prototype.toString.call(message.courierId[j]) : options.longs === Number ? new $util.LongBits(message.courierId[j].low >>> 0, message.courierId[j].high >>> 0).toNumber(true) : message.courierId[j];
                }
                return object;
            };

            /**
             * Converts this GetLastLocationList to JSON.
             * @function toJSON
             * @memberof fastcity.admin.GetLastLocationList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetLastLocationList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return GetLastLocationList;
        })();

        admin.GetLocationList = (function() {

            /**
             * Properties of a GetLocationList.
             * @memberof fastcity.admin
             * @interface IGetLocationList
             * @property {number|Long|null} [courierId] GetLocationList courierId
             * @property {number|Long|null} [orderId] GetLocationList orderId
             * @property {number|Long|null} [fromTime] GetLocationList fromTime
             * @property {number|Long|null} [toTime] GetLocationList toTime
             * @property {number|null} [offset] GetLocationList offset
             * @property {number|null} [limit] GetLocationList limit
             * @property {fastcity.common.SortOrder.Order|null} [sortOrder] GetLocationList sortOrder
             */

            /**
             * Constructs a new GetLocationList.
             * @memberof fastcity.admin
             * @classdesc Represents a GetLocationList.
             * @implements IGetLocationList
             * @constructor
             * @param {fastcity.admin.IGetLocationList=} [properties] Properties to set
             */
            function GetLocationList(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GetLocationList courierId.
             * @member {number|Long} courierId
             * @memberof fastcity.admin.GetLocationList
             * @instance
             */
            GetLocationList.prototype.courierId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * GetLocationList orderId.
             * @member {number|Long} orderId
             * @memberof fastcity.admin.GetLocationList
             * @instance
             */
            GetLocationList.prototype.orderId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * GetLocationList fromTime.
             * @member {number|Long} fromTime
             * @memberof fastcity.admin.GetLocationList
             * @instance
             */
            GetLocationList.prototype.fromTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * GetLocationList toTime.
             * @member {number|Long} toTime
             * @memberof fastcity.admin.GetLocationList
             * @instance
             */
            GetLocationList.prototype.toTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * GetLocationList offset.
             * @member {number} offset
             * @memberof fastcity.admin.GetLocationList
             * @instance
             */
            GetLocationList.prototype.offset = 0;

            /**
             * GetLocationList limit.
             * @member {number} limit
             * @memberof fastcity.admin.GetLocationList
             * @instance
             */
            GetLocationList.prototype.limit = 0;

            /**
             * GetLocationList sortOrder.
             * @member {fastcity.common.SortOrder.Order} sortOrder
             * @memberof fastcity.admin.GetLocationList
             * @instance
             */
            GetLocationList.prototype.sortOrder = 0;

            /**
             * Creates a new GetLocationList instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.GetLocationList
             * @static
             * @param {fastcity.admin.IGetLocationList=} [properties] Properties to set
             * @returns {fastcity.admin.GetLocationList} GetLocationList instance
             */
            GetLocationList.create = function create(properties) {
                return new GetLocationList(properties);
            };

            /**
             * Encodes the specified GetLocationList message. Does not implicitly {@link fastcity.admin.GetLocationList.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.GetLocationList
             * @static
             * @param {fastcity.admin.IGetLocationList} message GetLocationList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetLocationList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.courierId != null && Object.hasOwnProperty.call(message, "courierId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.courierId);
                if (message.orderId != null && Object.hasOwnProperty.call(message, "orderId"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.orderId);
                if (message.fromTime != null && Object.hasOwnProperty.call(message, "fromTime"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int64(message.fromTime);
                if (message.toTime != null && Object.hasOwnProperty.call(message, "toTime"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int64(message.toTime);
                if (message.offset != null && Object.hasOwnProperty.call(message, "offset"))
                    writer.uint32(/* id 5, wireType 0 =*/40).uint32(message.offset);
                if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                    writer.uint32(/* id 6, wireType 0 =*/48).uint32(message.limit);
                if (message.sortOrder != null && Object.hasOwnProperty.call(message, "sortOrder"))
                    writer.uint32(/* id 7, wireType 0 =*/56).int32(message.sortOrder);
                return writer;
            };

            /**
             * Decodes a GetLocationList message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.GetLocationList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.GetLocationList} GetLocationList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetLocationList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.GetLocationList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.courierId = reader.uint64();
                        break;
                    case 2:
                        message.orderId = reader.uint64();
                        break;
                    case 3:
                        message.fromTime = reader.int64();
                        break;
                    case 4:
                        message.toTime = reader.int64();
                        break;
                    case 5:
                        message.offset = reader.uint32();
                        break;
                    case 6:
                        message.limit = reader.uint32();
                        break;
                    case 7:
                        message.sortOrder = reader.int32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a GetLocationList message.
             * @function verify
             * @memberof fastcity.admin.GetLocationList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetLocationList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.courierId != null && message.hasOwnProperty("courierId"))
                    if (!$util.isInteger(message.courierId) && !(message.courierId && $util.isInteger(message.courierId.low) && $util.isInteger(message.courierId.high)))
                        return "courierId: integer|Long expected";
                if (message.orderId != null && message.hasOwnProperty("orderId"))
                    if (!$util.isInteger(message.orderId) && !(message.orderId && $util.isInteger(message.orderId.low) && $util.isInteger(message.orderId.high)))
                        return "orderId: integer|Long expected";
                if (message.fromTime != null && message.hasOwnProperty("fromTime"))
                    if (!$util.isInteger(message.fromTime) && !(message.fromTime && $util.isInteger(message.fromTime.low) && $util.isInteger(message.fromTime.high)))
                        return "fromTime: integer|Long expected";
                if (message.toTime != null && message.hasOwnProperty("toTime"))
                    if (!$util.isInteger(message.toTime) && !(message.toTime && $util.isInteger(message.toTime.low) && $util.isInteger(message.toTime.high)))
                        return "toTime: integer|Long expected";
                if (message.offset != null && message.hasOwnProperty("offset"))
                    if (!$util.isInteger(message.offset))
                        return "offset: integer expected";
                if (message.limit != null && message.hasOwnProperty("limit"))
                    if (!$util.isInteger(message.limit))
                        return "limit: integer expected";
                if (message.sortOrder != null && message.hasOwnProperty("sortOrder"))
                    switch (message.sortOrder) {
                    default:
                        return "sortOrder: enum value expected";
                    case 0:
                    case 1:
                        break;
                    }
                return null;
            };

            /**
             * Creates a GetLocationList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.GetLocationList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.GetLocationList} GetLocationList
             */
            GetLocationList.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.GetLocationList)
                    return object;
                var message = new $root.fastcity.admin.GetLocationList();
                if (object.courierId != null)
                    if ($util.Long)
                        (message.courierId = $util.Long.fromValue(object.courierId)).unsigned = true;
                    else if (typeof object.courierId === "string")
                        message.courierId = parseInt(object.courierId, 10);
                    else if (typeof object.courierId === "number")
                        message.courierId = object.courierId;
                    else if (typeof object.courierId === "object")
                        message.courierId = new $util.LongBits(object.courierId.low >>> 0, object.courierId.high >>> 0).toNumber(true);
                if (object.orderId != null)
                    if ($util.Long)
                        (message.orderId = $util.Long.fromValue(object.orderId)).unsigned = true;
                    else if (typeof object.orderId === "string")
                        message.orderId = parseInt(object.orderId, 10);
                    else if (typeof object.orderId === "number")
                        message.orderId = object.orderId;
                    else if (typeof object.orderId === "object")
                        message.orderId = new $util.LongBits(object.orderId.low >>> 0, object.orderId.high >>> 0).toNumber(true);
                if (object.fromTime != null)
                    if ($util.Long)
                        (message.fromTime = $util.Long.fromValue(object.fromTime)).unsigned = false;
                    else if (typeof object.fromTime === "string")
                        message.fromTime = parseInt(object.fromTime, 10);
                    else if (typeof object.fromTime === "number")
                        message.fromTime = object.fromTime;
                    else if (typeof object.fromTime === "object")
                        message.fromTime = new $util.LongBits(object.fromTime.low >>> 0, object.fromTime.high >>> 0).toNumber();
                if (object.toTime != null)
                    if ($util.Long)
                        (message.toTime = $util.Long.fromValue(object.toTime)).unsigned = false;
                    else if (typeof object.toTime === "string")
                        message.toTime = parseInt(object.toTime, 10);
                    else if (typeof object.toTime === "number")
                        message.toTime = object.toTime;
                    else if (typeof object.toTime === "object")
                        message.toTime = new $util.LongBits(object.toTime.low >>> 0, object.toTime.high >>> 0).toNumber();
                if (object.offset != null)
                    message.offset = object.offset >>> 0;
                if (object.limit != null)
                    message.limit = object.limit >>> 0;
                switch (object.sortOrder) {
                case "ASC":
                case 0:
                    message.sortOrder = 0;
                    break;
                case "DESC":
                case 1:
                    message.sortOrder = 1;
                    break;
                }
                return message;
            };

            /**
             * Creates a plain object from a GetLocationList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.GetLocationList
             * @static
             * @param {fastcity.admin.GetLocationList} message GetLocationList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetLocationList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.courierId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.courierId = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.orderId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.orderId = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.fromTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.fromTime = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.toTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.toTime = options.longs === String ? "0" : 0;
                    object.offset = 0;
                    object.limit = 0;
                    object.sortOrder = options.enums === String ? "ASC" : 0;
                }
                if (message.courierId != null && message.hasOwnProperty("courierId"))
                    if (typeof message.courierId === "number")
                        object.courierId = options.longs === String ? String(message.courierId) : message.courierId;
                    else
                        object.courierId = options.longs === String ? $util.Long.prototype.toString.call(message.courierId) : options.longs === Number ? new $util.LongBits(message.courierId.low >>> 0, message.courierId.high >>> 0).toNumber(true) : message.courierId;
                if (message.orderId != null && message.hasOwnProperty("orderId"))
                    if (typeof message.orderId === "number")
                        object.orderId = options.longs === String ? String(message.orderId) : message.orderId;
                    else
                        object.orderId = options.longs === String ? $util.Long.prototype.toString.call(message.orderId) : options.longs === Number ? new $util.LongBits(message.orderId.low >>> 0, message.orderId.high >>> 0).toNumber(true) : message.orderId;
                if (message.fromTime != null && message.hasOwnProperty("fromTime"))
                    if (typeof message.fromTime === "number")
                        object.fromTime = options.longs === String ? String(message.fromTime) : message.fromTime;
                    else
                        object.fromTime = options.longs === String ? $util.Long.prototype.toString.call(message.fromTime) : options.longs === Number ? new $util.LongBits(message.fromTime.low >>> 0, message.fromTime.high >>> 0).toNumber() : message.fromTime;
                if (message.toTime != null && message.hasOwnProperty("toTime"))
                    if (typeof message.toTime === "number")
                        object.toTime = options.longs === String ? String(message.toTime) : message.toTime;
                    else
                        object.toTime = options.longs === String ? $util.Long.prototype.toString.call(message.toTime) : options.longs === Number ? new $util.LongBits(message.toTime.low >>> 0, message.toTime.high >>> 0).toNumber() : message.toTime;
                if (message.offset != null && message.hasOwnProperty("offset"))
                    object.offset = message.offset;
                if (message.limit != null && message.hasOwnProperty("limit"))
                    object.limit = message.limit;
                if (message.sortOrder != null && message.hasOwnProperty("sortOrder"))
                    object.sortOrder = options.enums === String ? $root.fastcity.common.SortOrder.Order[message.sortOrder] : message.sortOrder;
                return object;
            };

            /**
             * Converts this GetLocationList to JSON.
             * @function toJSON
             * @memberof fastcity.admin.GetLocationList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetLocationList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return GetLocationList;
        })();

        admin.LocationList = (function() {

            /**
             * Properties of a LocationList.
             * @memberof fastcity.admin
             * @interface ILocationList
             * @property {Array.<fastcity.admin.ILocation>|null} [location] LocationList location
             * @property {number|null} [totalLocations] LocationList totalLocations
             */

            /**
             * Constructs a new LocationList.
             * @memberof fastcity.admin
             * @classdesc Represents a LocationList.
             * @implements ILocationList
             * @constructor
             * @param {fastcity.admin.ILocationList=} [properties] Properties to set
             */
            function LocationList(properties) {
                this.location = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * LocationList location.
             * @member {Array.<fastcity.admin.ILocation>} location
             * @memberof fastcity.admin.LocationList
             * @instance
             */
            LocationList.prototype.location = $util.emptyArray;

            /**
             * LocationList totalLocations.
             * @member {number} totalLocations
             * @memberof fastcity.admin.LocationList
             * @instance
             */
            LocationList.prototype.totalLocations = 0;

            /**
             * Creates a new LocationList instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.LocationList
             * @static
             * @param {fastcity.admin.ILocationList=} [properties] Properties to set
             * @returns {fastcity.admin.LocationList} LocationList instance
             */
            LocationList.create = function create(properties) {
                return new LocationList(properties);
            };

            /**
             * Encodes the specified LocationList message. Does not implicitly {@link fastcity.admin.LocationList.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.LocationList
             * @static
             * @param {fastcity.admin.ILocationList} message LocationList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            LocationList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.location != null && message.location.length)
                    for (var i = 0; i < message.location.length; ++i)
                        $root.fastcity.admin.Location.encode(message.location[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.totalLocations != null && Object.hasOwnProperty.call(message, "totalLocations"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.totalLocations);
                return writer;
            };

            /**
             * Decodes a LocationList message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.LocationList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.LocationList} LocationList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            LocationList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.LocationList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.location && message.location.length))
                            message.location = [];
                        message.location.push($root.fastcity.admin.Location.decode(reader, reader.uint32()));
                        break;
                    case 2:
                        message.totalLocations = reader.uint32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a LocationList message.
             * @function verify
             * @memberof fastcity.admin.LocationList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            LocationList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.location != null && message.hasOwnProperty("location")) {
                    if (!Array.isArray(message.location))
                        return "location: array expected";
                    for (var i = 0; i < message.location.length; ++i) {
                        var error = $root.fastcity.admin.Location.verify(message.location[i]);
                        if (error)
                            return "location." + error;
                    }
                }
                if (message.totalLocations != null && message.hasOwnProperty("totalLocations"))
                    if (!$util.isInteger(message.totalLocations))
                        return "totalLocations: integer expected";
                return null;
            };

            /**
             * Creates a LocationList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.LocationList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.LocationList} LocationList
             */
            LocationList.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.LocationList)
                    return object;
                var message = new $root.fastcity.admin.LocationList();
                if (object.location) {
                    if (!Array.isArray(object.location))
                        throw TypeError(".fastcity.admin.LocationList.location: array expected");
                    message.location = [];
                    for (var i = 0; i < object.location.length; ++i) {
                        if (typeof object.location[i] !== "object")
                            throw TypeError(".fastcity.admin.LocationList.location: object expected");
                        message.location[i] = $root.fastcity.admin.Location.fromObject(object.location[i]);
                    }
                }
                if (object.totalLocations != null)
                    message.totalLocations = object.totalLocations >>> 0;
                return message;
            };

            /**
             * Creates a plain object from a LocationList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.LocationList
             * @static
             * @param {fastcity.admin.LocationList} message LocationList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            LocationList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.location = [];
                if (options.defaults)
                    object.totalLocations = 0;
                if (message.location && message.location.length) {
                    object.location = [];
                    for (var j = 0; j < message.location.length; ++j)
                        object.location[j] = $root.fastcity.admin.Location.toObject(message.location[j], options);
                }
                if (message.totalLocations != null && message.hasOwnProperty("totalLocations"))
                    object.totalLocations = message.totalLocations;
                return object;
            };

            /**
             * Converts this LocationList to JSON.
             * @function toJSON
             * @memberof fastcity.admin.LocationList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            LocationList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return LocationList;
        })();

        admin.ChangedLastLocation = (function() {

            /**
             * Properties of a ChangedLastLocation.
             * @memberof fastcity.admin
             * @interface IChangedLastLocation
             * @property {Array.<number|Long>|null} [courierId] ChangedLastLocation courierId
             */

            /**
             * Constructs a new ChangedLastLocation.
             * @memberof fastcity.admin
             * @classdesc Represents a ChangedLastLocation.
             * @implements IChangedLastLocation
             * @constructor
             * @param {fastcity.admin.IChangedLastLocation=} [properties] Properties to set
             */
            function ChangedLastLocation(properties) {
                this.courierId = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ChangedLastLocation courierId.
             * @member {Array.<number|Long>} courierId
             * @memberof fastcity.admin.ChangedLastLocation
             * @instance
             */
            ChangedLastLocation.prototype.courierId = $util.emptyArray;

            /**
             * Creates a new ChangedLastLocation instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.ChangedLastLocation
             * @static
             * @param {fastcity.admin.IChangedLastLocation=} [properties] Properties to set
             * @returns {fastcity.admin.ChangedLastLocation} ChangedLastLocation instance
             */
            ChangedLastLocation.create = function create(properties) {
                return new ChangedLastLocation(properties);
            };

            /**
             * Encodes the specified ChangedLastLocation message. Does not implicitly {@link fastcity.admin.ChangedLastLocation.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.ChangedLastLocation
             * @static
             * @param {fastcity.admin.IChangedLastLocation} message ChangedLastLocation message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ChangedLastLocation.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.courierId != null && message.courierId.length) {
                    writer.uint32(/* id 1, wireType 2 =*/10).fork();
                    for (var i = 0; i < message.courierId.length; ++i)
                        writer.uint64(message.courierId[i]);
                    writer.ldelim();
                }
                return writer;
            };

            /**
             * Decodes a ChangedLastLocation message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.ChangedLastLocation
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.ChangedLastLocation} ChangedLastLocation
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ChangedLastLocation.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.ChangedLastLocation();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.courierId && message.courierId.length))
                            message.courierId = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.courierId.push(reader.uint64());
                        } else
                            message.courierId.push(reader.uint64());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a ChangedLastLocation message.
             * @function verify
             * @memberof fastcity.admin.ChangedLastLocation
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ChangedLastLocation.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.courierId != null && message.hasOwnProperty("courierId")) {
                    if (!Array.isArray(message.courierId))
                        return "courierId: array expected";
                    for (var i = 0; i < message.courierId.length; ++i)
                        if (!$util.isInteger(message.courierId[i]) && !(message.courierId[i] && $util.isInteger(message.courierId[i].low) && $util.isInteger(message.courierId[i].high)))
                            return "courierId: integer|Long[] expected";
                }
                return null;
            };

            /**
             * Creates a ChangedLastLocation message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.ChangedLastLocation
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.ChangedLastLocation} ChangedLastLocation
             */
            ChangedLastLocation.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.ChangedLastLocation)
                    return object;
                var message = new $root.fastcity.admin.ChangedLastLocation();
                if (object.courierId) {
                    if (!Array.isArray(object.courierId))
                        throw TypeError(".fastcity.admin.ChangedLastLocation.courierId: array expected");
                    message.courierId = [];
                    for (var i = 0; i < object.courierId.length; ++i)
                        if ($util.Long)
                            (message.courierId[i] = $util.Long.fromValue(object.courierId[i])).unsigned = true;
                        else if (typeof object.courierId[i] === "string")
                            message.courierId[i] = parseInt(object.courierId[i], 10);
                        else if (typeof object.courierId[i] === "number")
                            message.courierId[i] = object.courierId[i];
                        else if (typeof object.courierId[i] === "object")
                            message.courierId[i] = new $util.LongBits(object.courierId[i].low >>> 0, object.courierId[i].high >>> 0).toNumber(true);
                }
                return message;
            };

            /**
             * Creates a plain object from a ChangedLastLocation message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.ChangedLastLocation
             * @static
             * @param {fastcity.admin.ChangedLastLocation} message ChangedLastLocation
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ChangedLastLocation.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.courierId = [];
                if (message.courierId && message.courierId.length) {
                    object.courierId = [];
                    for (var j = 0; j < message.courierId.length; ++j)
                        if (typeof message.courierId[j] === "number")
                            object.courierId[j] = options.longs === String ? String(message.courierId[j]) : message.courierId[j];
                        else
                            object.courierId[j] = options.longs === String ? $util.Long.prototype.toString.call(message.courierId[j]) : options.longs === Number ? new $util.LongBits(message.courierId[j].low >>> 0, message.courierId[j].high >>> 0).toNumber(true) : message.courierId[j];
                }
                return object;
            };

            /**
             * Converts this ChangedLastLocation to JSON.
             * @function toJSON
             * @memberof fastcity.admin.ChangedLastLocation
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ChangedLastLocation.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return ChangedLastLocation;
        })();

        admin.LogRecord = (function() {

            /**
             * Properties of a LogRecord.
             * @memberof fastcity.admin
             * @interface ILogRecord
             * @property {number|Long|null} [timestamp] LogRecord timestamp
             * @property {number|null} [placeInQueue] LogRecord placeInQueue
             * @property {fastcity.admin.LogRecord.LogLevel|null} [level] LogRecord level
             * @property {Array.<string>|null} [tags] LogRecord tags
             * @property {string|null} [text] LogRecord text
             */

            /**
             * Constructs a new LogRecord.
             * @memberof fastcity.admin
             * @classdesc Represents a LogRecord.
             * @implements ILogRecord
             * @constructor
             * @param {fastcity.admin.ILogRecord=} [properties] Properties to set
             */
            function LogRecord(properties) {
                this.tags = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * LogRecord timestamp.
             * @member {number|Long} timestamp
             * @memberof fastcity.admin.LogRecord
             * @instance
             */
            LogRecord.prototype.timestamp = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * LogRecord placeInQueue.
             * @member {number} placeInQueue
             * @memberof fastcity.admin.LogRecord
             * @instance
             */
            LogRecord.prototype.placeInQueue = 0;

            /**
             * LogRecord level.
             * @member {fastcity.admin.LogRecord.LogLevel} level
             * @memberof fastcity.admin.LogRecord
             * @instance
             */
            LogRecord.prototype.level = 0;

            /**
             * LogRecord tags.
             * @member {Array.<string>} tags
             * @memberof fastcity.admin.LogRecord
             * @instance
             */
            LogRecord.prototype.tags = $util.emptyArray;

            /**
             * LogRecord text.
             * @member {string} text
             * @memberof fastcity.admin.LogRecord
             * @instance
             */
            LogRecord.prototype.text = "";

            /**
             * Creates a new LogRecord instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.LogRecord
             * @static
             * @param {fastcity.admin.ILogRecord=} [properties] Properties to set
             * @returns {fastcity.admin.LogRecord} LogRecord instance
             */
            LogRecord.create = function create(properties) {
                return new LogRecord(properties);
            };

            /**
             * Encodes the specified LogRecord message. Does not implicitly {@link fastcity.admin.LogRecord.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.LogRecord
             * @static
             * @param {fastcity.admin.ILogRecord} message LogRecord message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            LogRecord.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.timestamp != null && Object.hasOwnProperty.call(message, "timestamp"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.timestamp);
                if (message.placeInQueue != null && Object.hasOwnProperty.call(message, "placeInQueue"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.placeInQueue);
                if (message.level != null && Object.hasOwnProperty.call(message, "level"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.level);
                if (message.tags != null && message.tags.length)
                    for (var i = 0; i < message.tags.length; ++i)
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.tags[i]);
                if (message.text != null && Object.hasOwnProperty.call(message, "text"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.text);
                return writer;
            };

            /**
             * Decodes a LogRecord message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.LogRecord
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.LogRecord} LogRecord
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            LogRecord.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.LogRecord();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.timestamp = reader.int64();
                        break;
                    case 2:
                        message.placeInQueue = reader.int32();
                        break;
                    case 3:
                        message.level = reader.int32();
                        break;
                    case 4:
                        if (!(message.tags && message.tags.length))
                            message.tags = [];
                        message.tags.push(reader.string());
                        break;
                    case 5:
                        message.text = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a LogRecord message.
             * @function verify
             * @memberof fastcity.admin.LogRecord
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            LogRecord.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.timestamp != null && message.hasOwnProperty("timestamp"))
                    if (!$util.isInteger(message.timestamp) && !(message.timestamp && $util.isInteger(message.timestamp.low) && $util.isInteger(message.timestamp.high)))
                        return "timestamp: integer|Long expected";
                if (message.placeInQueue != null && message.hasOwnProperty("placeInQueue"))
                    if (!$util.isInteger(message.placeInQueue))
                        return "placeInQueue: integer expected";
                if (message.level != null && message.hasOwnProperty("level"))
                    switch (message.level) {
                    default:
                        return "level: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
                if (message.tags != null && message.hasOwnProperty("tags")) {
                    if (!Array.isArray(message.tags))
                        return "tags: array expected";
                    for (var i = 0; i < message.tags.length; ++i)
                        if (!$util.isString(message.tags[i]))
                            return "tags: string[] expected";
                }
                if (message.text != null && message.hasOwnProperty("text"))
                    if (!$util.isString(message.text))
                        return "text: string expected";
                return null;
            };

            /**
             * Creates a LogRecord message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.LogRecord
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.LogRecord} LogRecord
             */
            LogRecord.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.LogRecord)
                    return object;
                var message = new $root.fastcity.admin.LogRecord();
                if (object.timestamp != null)
                    if ($util.Long)
                        (message.timestamp = $util.Long.fromValue(object.timestamp)).unsigned = false;
                    else if (typeof object.timestamp === "string")
                        message.timestamp = parseInt(object.timestamp, 10);
                    else if (typeof object.timestamp === "number")
                        message.timestamp = object.timestamp;
                    else if (typeof object.timestamp === "object")
                        message.timestamp = new $util.LongBits(object.timestamp.low >>> 0, object.timestamp.high >>> 0).toNumber();
                if (object.placeInQueue != null)
                    message.placeInQueue = object.placeInQueue | 0;
                switch (object.level) {
                case "LL_ERROR":
                case 0:
                    message.level = 0;
                    break;
                case "LL_INFO":
                case 1:
                    message.level = 1;
                    break;
                case "LL_DEBUG":
                case 2:
                    message.level = 2;
                    break;
                case "LL_TRACE":
                case 3:
                    message.level = 3;
                    break;
                }
                if (object.tags) {
                    if (!Array.isArray(object.tags))
                        throw TypeError(".fastcity.admin.LogRecord.tags: array expected");
                    message.tags = [];
                    for (var i = 0; i < object.tags.length; ++i)
                        message.tags[i] = String(object.tags[i]);
                }
                if (object.text != null)
                    message.text = String(object.text);
                return message;
            };

            /**
             * Creates a plain object from a LogRecord message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.LogRecord
             * @static
             * @param {fastcity.admin.LogRecord} message LogRecord
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            LogRecord.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.tags = [];
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.timestamp = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.timestamp = options.longs === String ? "0" : 0;
                    object.placeInQueue = 0;
                    object.level = options.enums === String ? "LL_ERROR" : 0;
                    object.text = "";
                }
                if (message.timestamp != null && message.hasOwnProperty("timestamp"))
                    if (typeof message.timestamp === "number")
                        object.timestamp = options.longs === String ? String(message.timestamp) : message.timestamp;
                    else
                        object.timestamp = options.longs === String ? $util.Long.prototype.toString.call(message.timestamp) : options.longs === Number ? new $util.LongBits(message.timestamp.low >>> 0, message.timestamp.high >>> 0).toNumber() : message.timestamp;
                if (message.placeInQueue != null && message.hasOwnProperty("placeInQueue"))
                    object.placeInQueue = message.placeInQueue;
                if (message.level != null && message.hasOwnProperty("level"))
                    object.level = options.enums === String ? $root.fastcity.admin.LogRecord.LogLevel[message.level] : message.level;
                if (message.tags && message.tags.length) {
                    object.tags = [];
                    for (var j = 0; j < message.tags.length; ++j)
                        object.tags[j] = message.tags[j];
                }
                if (message.text != null && message.hasOwnProperty("text"))
                    object.text = message.text;
                return object;
            };

            /**
             * Converts this LogRecord to JSON.
             * @function toJSON
             * @memberof fastcity.admin.LogRecord
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            LogRecord.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * LogLevel enum.
             * @name fastcity.admin.LogRecord.LogLevel
             * @enum {number}
             * @property {number} LL_ERROR=0 LL_ERROR value
             * @property {number} LL_INFO=1 LL_INFO value
             * @property {number} LL_DEBUG=2 LL_DEBUG value
             * @property {number} LL_TRACE=3 LL_TRACE value
             */
            LogRecord.LogLevel = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "LL_ERROR"] = 0;
                values[valuesById[1] = "LL_INFO"] = 1;
                values[valuesById[2] = "LL_DEBUG"] = 2;
                values[valuesById[3] = "LL_TRACE"] = 3;
                return values;
            })();

            return LogRecord;
        })();

        admin.LogRecordList = (function() {

            /**
             * Properties of a LogRecordList.
             * @memberof fastcity.admin
             * @interface ILogRecordList
             * @property {Array.<fastcity.admin.ILogRecord>|null} [logs] LogRecordList logs
             */

            /**
             * Constructs a new LogRecordList.
             * @memberof fastcity.admin
             * @classdesc Represents a LogRecordList.
             * @implements ILogRecordList
             * @constructor
             * @param {fastcity.admin.ILogRecordList=} [properties] Properties to set
             */
            function LogRecordList(properties) {
                this.logs = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * LogRecordList logs.
             * @member {Array.<fastcity.admin.ILogRecord>} logs
             * @memberof fastcity.admin.LogRecordList
             * @instance
             */
            LogRecordList.prototype.logs = $util.emptyArray;

            /**
             * Creates a new LogRecordList instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.LogRecordList
             * @static
             * @param {fastcity.admin.ILogRecordList=} [properties] Properties to set
             * @returns {fastcity.admin.LogRecordList} LogRecordList instance
             */
            LogRecordList.create = function create(properties) {
                return new LogRecordList(properties);
            };

            /**
             * Encodes the specified LogRecordList message. Does not implicitly {@link fastcity.admin.LogRecordList.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.LogRecordList
             * @static
             * @param {fastcity.admin.ILogRecordList} message LogRecordList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            LogRecordList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.logs != null && message.logs.length)
                    for (var i = 0; i < message.logs.length; ++i)
                        $root.fastcity.admin.LogRecord.encode(message.logs[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a LogRecordList message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.LogRecordList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.LogRecordList} LogRecordList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            LogRecordList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.LogRecordList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.logs && message.logs.length))
                            message.logs = [];
                        message.logs.push($root.fastcity.admin.LogRecord.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a LogRecordList message.
             * @function verify
             * @memberof fastcity.admin.LogRecordList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            LogRecordList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.logs != null && message.hasOwnProperty("logs")) {
                    if (!Array.isArray(message.logs))
                        return "logs: array expected";
                    for (var i = 0; i < message.logs.length; ++i) {
                        var error = $root.fastcity.admin.LogRecord.verify(message.logs[i]);
                        if (error)
                            return "logs." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a LogRecordList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.LogRecordList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.LogRecordList} LogRecordList
             */
            LogRecordList.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.LogRecordList)
                    return object;
                var message = new $root.fastcity.admin.LogRecordList();
                if (object.logs) {
                    if (!Array.isArray(object.logs))
                        throw TypeError(".fastcity.admin.LogRecordList.logs: array expected");
                    message.logs = [];
                    for (var i = 0; i < object.logs.length; ++i) {
                        if (typeof object.logs[i] !== "object")
                            throw TypeError(".fastcity.admin.LogRecordList.logs: object expected");
                        message.logs[i] = $root.fastcity.admin.LogRecord.fromObject(object.logs[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a LogRecordList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.LogRecordList
             * @static
             * @param {fastcity.admin.LogRecordList} message LogRecordList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            LogRecordList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.logs = [];
                if (message.logs && message.logs.length) {
                    object.logs = [];
                    for (var j = 0; j < message.logs.length; ++j)
                        object.logs[j] = $root.fastcity.admin.LogRecord.toObject(message.logs[j], options);
                }
                return object;
            };

            /**
             * Converts this LogRecordList to JSON.
             * @function toJSON
             * @memberof fastcity.admin.LogRecordList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            LogRecordList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return LogRecordList;
        })();

        admin.GetLogRecordList = (function() {

            /**
             * Properties of a GetLogRecordList.
             * @memberof fastcity.admin
             * @interface IGetLogRecordList
             * @property {number|Long|null} [fromTime] GetLogRecordList fromTime
             * @property {number|Long|null} [toTime] GetLogRecordList toTime
             * @property {string|null} [text] GetLogRecordList text
             * @property {number|null} [offset] GetLogRecordList offset
             * @property {number|null} [limit] GetLogRecordList limit
             * @property {fastcity.common.SortOrder.Order|null} [sortOrder] GetLogRecordList sortOrder
             */

            /**
             * Constructs a new GetLogRecordList.
             * @memberof fastcity.admin
             * @classdesc Represents a GetLogRecordList.
             * @implements IGetLogRecordList
             * @constructor
             * @param {fastcity.admin.IGetLogRecordList=} [properties] Properties to set
             */
            function GetLogRecordList(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GetLogRecordList fromTime.
             * @member {number|Long} fromTime
             * @memberof fastcity.admin.GetLogRecordList
             * @instance
             */
            GetLogRecordList.prototype.fromTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * GetLogRecordList toTime.
             * @member {number|Long} toTime
             * @memberof fastcity.admin.GetLogRecordList
             * @instance
             */
            GetLogRecordList.prototype.toTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * GetLogRecordList text.
             * @member {string} text
             * @memberof fastcity.admin.GetLogRecordList
             * @instance
             */
            GetLogRecordList.prototype.text = "";

            /**
             * GetLogRecordList offset.
             * @member {number} offset
             * @memberof fastcity.admin.GetLogRecordList
             * @instance
             */
            GetLogRecordList.prototype.offset = 0;

            /**
             * GetLogRecordList limit.
             * @member {number} limit
             * @memberof fastcity.admin.GetLogRecordList
             * @instance
             */
            GetLogRecordList.prototype.limit = 0;

            /**
             * GetLogRecordList sortOrder.
             * @member {fastcity.common.SortOrder.Order} sortOrder
             * @memberof fastcity.admin.GetLogRecordList
             * @instance
             */
            GetLogRecordList.prototype.sortOrder = 0;

            /**
             * Creates a new GetLogRecordList instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.GetLogRecordList
             * @static
             * @param {fastcity.admin.IGetLogRecordList=} [properties] Properties to set
             * @returns {fastcity.admin.GetLogRecordList} GetLogRecordList instance
             */
            GetLogRecordList.create = function create(properties) {
                return new GetLogRecordList(properties);
            };

            /**
             * Encodes the specified GetLogRecordList message. Does not implicitly {@link fastcity.admin.GetLogRecordList.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.GetLogRecordList
             * @static
             * @param {fastcity.admin.IGetLogRecordList} message GetLogRecordList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetLogRecordList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.fromTime != null && Object.hasOwnProperty.call(message, "fromTime"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.fromTime);
                if (message.toTime != null && Object.hasOwnProperty.call(message, "toTime"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int64(message.toTime);
                if (message.text != null && Object.hasOwnProperty.call(message, "text"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.text);
                if (message.offset != null && Object.hasOwnProperty.call(message, "offset"))
                    writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.offset);
                if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                    writer.uint32(/* id 5, wireType 0 =*/40).uint32(message.limit);
                if (message.sortOrder != null && Object.hasOwnProperty.call(message, "sortOrder"))
                    writer.uint32(/* id 6, wireType 0 =*/48).int32(message.sortOrder);
                return writer;
            };

            /**
             * Decodes a GetLogRecordList message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.GetLogRecordList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.GetLogRecordList} GetLogRecordList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetLogRecordList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.GetLogRecordList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.fromTime = reader.int64();
                        break;
                    case 2:
                        message.toTime = reader.int64();
                        break;
                    case 3:
                        message.text = reader.string();
                        break;
                    case 4:
                        message.offset = reader.uint32();
                        break;
                    case 5:
                        message.limit = reader.uint32();
                        break;
                    case 6:
                        message.sortOrder = reader.int32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a GetLogRecordList message.
             * @function verify
             * @memberof fastcity.admin.GetLogRecordList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetLogRecordList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.fromTime != null && message.hasOwnProperty("fromTime"))
                    if (!$util.isInteger(message.fromTime) && !(message.fromTime && $util.isInteger(message.fromTime.low) && $util.isInteger(message.fromTime.high)))
                        return "fromTime: integer|Long expected";
                if (message.toTime != null && message.hasOwnProperty("toTime"))
                    if (!$util.isInteger(message.toTime) && !(message.toTime && $util.isInteger(message.toTime.low) && $util.isInteger(message.toTime.high)))
                        return "toTime: integer|Long expected";
                if (message.text != null && message.hasOwnProperty("text"))
                    if (!$util.isString(message.text))
                        return "text: string expected";
                if (message.offset != null && message.hasOwnProperty("offset"))
                    if (!$util.isInteger(message.offset))
                        return "offset: integer expected";
                if (message.limit != null && message.hasOwnProperty("limit"))
                    if (!$util.isInteger(message.limit))
                        return "limit: integer expected";
                if (message.sortOrder != null && message.hasOwnProperty("sortOrder"))
                    switch (message.sortOrder) {
                    default:
                        return "sortOrder: enum value expected";
                    case 0:
                    case 1:
                        break;
                    }
                return null;
            };

            /**
             * Creates a GetLogRecordList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.GetLogRecordList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.GetLogRecordList} GetLogRecordList
             */
            GetLogRecordList.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.GetLogRecordList)
                    return object;
                var message = new $root.fastcity.admin.GetLogRecordList();
                if (object.fromTime != null)
                    if ($util.Long)
                        (message.fromTime = $util.Long.fromValue(object.fromTime)).unsigned = false;
                    else if (typeof object.fromTime === "string")
                        message.fromTime = parseInt(object.fromTime, 10);
                    else if (typeof object.fromTime === "number")
                        message.fromTime = object.fromTime;
                    else if (typeof object.fromTime === "object")
                        message.fromTime = new $util.LongBits(object.fromTime.low >>> 0, object.fromTime.high >>> 0).toNumber();
                if (object.toTime != null)
                    if ($util.Long)
                        (message.toTime = $util.Long.fromValue(object.toTime)).unsigned = false;
                    else if (typeof object.toTime === "string")
                        message.toTime = parseInt(object.toTime, 10);
                    else if (typeof object.toTime === "number")
                        message.toTime = object.toTime;
                    else if (typeof object.toTime === "object")
                        message.toTime = new $util.LongBits(object.toTime.low >>> 0, object.toTime.high >>> 0).toNumber();
                if (object.text != null)
                    message.text = String(object.text);
                if (object.offset != null)
                    message.offset = object.offset >>> 0;
                if (object.limit != null)
                    message.limit = object.limit >>> 0;
                switch (object.sortOrder) {
                case "ASC":
                case 0:
                    message.sortOrder = 0;
                    break;
                case "DESC":
                case 1:
                    message.sortOrder = 1;
                    break;
                }
                return message;
            };

            /**
             * Creates a plain object from a GetLogRecordList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.GetLogRecordList
             * @static
             * @param {fastcity.admin.GetLogRecordList} message GetLogRecordList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetLogRecordList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.fromTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.fromTime = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.toTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.toTime = options.longs === String ? "0" : 0;
                    object.text = "";
                    object.offset = 0;
                    object.limit = 0;
                    object.sortOrder = options.enums === String ? "ASC" : 0;
                }
                if (message.fromTime != null && message.hasOwnProperty("fromTime"))
                    if (typeof message.fromTime === "number")
                        object.fromTime = options.longs === String ? String(message.fromTime) : message.fromTime;
                    else
                        object.fromTime = options.longs === String ? $util.Long.prototype.toString.call(message.fromTime) : options.longs === Number ? new $util.LongBits(message.fromTime.low >>> 0, message.fromTime.high >>> 0).toNumber() : message.fromTime;
                if (message.toTime != null && message.hasOwnProperty("toTime"))
                    if (typeof message.toTime === "number")
                        object.toTime = options.longs === String ? String(message.toTime) : message.toTime;
                    else
                        object.toTime = options.longs === String ? $util.Long.prototype.toString.call(message.toTime) : options.longs === Number ? new $util.LongBits(message.toTime.low >>> 0, message.toTime.high >>> 0).toNumber() : message.toTime;
                if (message.text != null && message.hasOwnProperty("text"))
                    object.text = message.text;
                if (message.offset != null && message.hasOwnProperty("offset"))
                    object.offset = message.offset;
                if (message.limit != null && message.hasOwnProperty("limit"))
                    object.limit = message.limit;
                if (message.sortOrder != null && message.hasOwnProperty("sortOrder"))
                    object.sortOrder = options.enums === String ? $root.fastcity.common.SortOrder.Order[message.sortOrder] : message.sortOrder;
                return object;
            };

            /**
             * Converts this GetLogRecordList to JSON.
             * @function toJSON
             * @memberof fastcity.admin.GetLogRecordList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetLogRecordList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return GetLogRecordList;
        })();

        admin.Logon = (function() {

            /**
             * Properties of a Logon.
             * @memberof fastcity.admin
             * @interface ILogon
             * @property {string|null} [token] Logon token
             */

            /**
             * Constructs a new Logon.
             * @memberof fastcity.admin
             * @classdesc Represents a Logon.
             * @implements ILogon
             * @constructor
             * @param {fastcity.admin.ILogon=} [properties] Properties to set
             */
            function Logon(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Logon token.
             * @member {string} token
             * @memberof fastcity.admin.Logon
             * @instance
             */
            Logon.prototype.token = "";

            /**
             * Creates a new Logon instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.Logon
             * @static
             * @param {fastcity.admin.ILogon=} [properties] Properties to set
             * @returns {fastcity.admin.Logon} Logon instance
             */
            Logon.create = function create(properties) {
                return new Logon(properties);
            };

            /**
             * Encodes the specified Logon message. Does not implicitly {@link fastcity.admin.Logon.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.Logon
             * @static
             * @param {fastcity.admin.ILogon} message Logon message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Logon.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.token != null && Object.hasOwnProperty.call(message, "token"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.token);
                return writer;
            };

            /**
             * Decodes a Logon message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.Logon
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.Logon} Logon
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Logon.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.Logon();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.token = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a Logon message.
             * @function verify
             * @memberof fastcity.admin.Logon
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Logon.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.token != null && message.hasOwnProperty("token"))
                    if (!$util.isString(message.token))
                        return "token: string expected";
                return null;
            };

            /**
             * Creates a Logon message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.Logon
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.Logon} Logon
             */
            Logon.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.Logon)
                    return object;
                var message = new $root.fastcity.admin.Logon();
                if (object.token != null)
                    message.token = String(object.token);
                return message;
            };

            /**
             * Creates a plain object from a Logon message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.Logon
             * @static
             * @param {fastcity.admin.Logon} message Logon
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Logon.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.token = "";
                if (message.token != null && message.hasOwnProperty("token"))
                    object.token = message.token;
                return object;
            };

            /**
             * Converts this Logon to JSON.
             * @function toJSON
             * @memberof fastcity.admin.Logon
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Logon.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Logon;
        })();

        admin.LogonResult = (function() {

            /**
             * Properties of a LogonResult.
             * @memberof fastcity.admin
             * @interface ILogonResult
             * @property {fastcity.admin.LogonResult.Code|null} [code] LogonResult code
             * @property {string|null} [description] LogonResult description
             */

            /**
             * Constructs a new LogonResult.
             * @memberof fastcity.admin
             * @classdesc Represents a LogonResult.
             * @implements ILogonResult
             * @constructor
             * @param {fastcity.admin.ILogonResult=} [properties] Properties to set
             */
            function LogonResult(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * LogonResult code.
             * @member {fastcity.admin.LogonResult.Code} code
             * @memberof fastcity.admin.LogonResult
             * @instance
             */
            LogonResult.prototype.code = 0;

            /**
             * LogonResult description.
             * @member {string} description
             * @memberof fastcity.admin.LogonResult
             * @instance
             */
            LogonResult.prototype.description = "";

            /**
             * Creates a new LogonResult instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.LogonResult
             * @static
             * @param {fastcity.admin.ILogonResult=} [properties] Properties to set
             * @returns {fastcity.admin.LogonResult} LogonResult instance
             */
            LogonResult.create = function create(properties) {
                return new LogonResult(properties);
            };

            /**
             * Encodes the specified LogonResult message. Does not implicitly {@link fastcity.admin.LogonResult.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.LogonResult
             * @static
             * @param {fastcity.admin.ILogonResult} message LogonResult message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            LogonResult.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.code != null && Object.hasOwnProperty.call(message, "code"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.code);
                if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.description);
                return writer;
            };

            /**
             * Decodes a LogonResult message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.LogonResult
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.LogonResult} LogonResult
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            LogonResult.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.LogonResult();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.code = reader.int32();
                        break;
                    case 2:
                        message.description = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a LogonResult message.
             * @function verify
             * @memberof fastcity.admin.LogonResult
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            LogonResult.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.code != null && message.hasOwnProperty("code"))
                    switch (message.code) {
                    default:
                        return "code: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
                if (message.description != null && message.hasOwnProperty("description"))
                    if (!$util.isString(message.description))
                        return "description: string expected";
                return null;
            };

            /**
             * Creates a LogonResult message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.LogonResult
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.LogonResult} LogonResult
             */
            LogonResult.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.LogonResult)
                    return object;
                var message = new $root.fastcity.admin.LogonResult();
                switch (object.code) {
                case "SUCCESS":
                case 0:
                    message.code = 0;
                    break;
                case "INCORRECT_LOGIN_PASSWORD":
                case 1:
                    message.code = 1;
                    break;
                case "INACTIVE":
                case 2:
                    message.code = 2;
                    break;
                case "INCORRECT_REQUEST":
                case 3:
                    message.code = 3;
                    break;
                }
                if (object.description != null)
                    message.description = String(object.description);
                return message;
            };

            /**
             * Creates a plain object from a LogonResult message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.LogonResult
             * @static
             * @param {fastcity.admin.LogonResult} message LogonResult
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            LogonResult.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.code = options.enums === String ? "SUCCESS" : 0;
                    object.description = "";
                }
                if (message.code != null && message.hasOwnProperty("code"))
                    object.code = options.enums === String ? $root.fastcity.admin.LogonResult.Code[message.code] : message.code;
                if (message.description != null && message.hasOwnProperty("description"))
                    object.description = message.description;
                return object;
            };

            /**
             * Converts this LogonResult to JSON.
             * @function toJSON
             * @memberof fastcity.admin.LogonResult
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            LogonResult.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Code enum.
             * @name fastcity.admin.LogonResult.Code
             * @enum {number}
             * @property {number} SUCCESS=0 SUCCESS value
             * @property {number} INCORRECT_LOGIN_PASSWORD=1 INCORRECT_LOGIN_PASSWORD value
             * @property {number} INACTIVE=2 INACTIVE value
             * @property {number} INCORRECT_REQUEST=3 INCORRECT_REQUEST value
             */
            LogonResult.Code = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "SUCCESS"] = 0;
                values[valuesById[1] = "INCORRECT_LOGIN_PASSWORD"] = 1;
                values[valuesById[2] = "INACTIVE"] = 2;
                values[valuesById[3] = "INCORRECT_REQUEST"] = 3;
                return values;
            })();

            return LogonResult;
        })();

        /**
         * OrderUpdateRequestResult enum.
         * @name fastcity.admin.OrderUpdateRequestResult
         * @enum {number}
         * @property {number} OURR_NOT_FOUND=0 OURR_NOT_FOUND value
         */
        admin.OrderUpdateRequestResult = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "OURR_NOT_FOUND"] = 0;
            return values;
        })();

        admin.OrderUpdate = (function() {

            /**
             * Properties of an OrderUpdate.
             * @memberof fastcity.admin
             * @interface IOrderUpdate
             * @property {number|Long|null} [orderId] OrderUpdate orderId
             * @property {number|Long|null} [timestampUpdate] OrderUpdate timestampUpdate
             * @property {fastcity.common.Source|null} [updateSource] OrderUpdate updateSource
             * @property {fastcity.admin.ICourier|null} [courier] OrderUpdate courier
             * @property {fastcity.common.Order.Status|null} [commonStatusFrom] OrderUpdate commonStatusFrom
             * @property {fastcity.admin.IIKOOrderStatus|null} [iikoStatusFrom] OrderUpdate iikoStatusFrom
             * @property {fastcity.common.Order.Status|null} [commonStatusTo] OrderUpdate commonStatusTo
             * @property {fastcity.admin.IIKOOrderStatus|null} [iikoStatusTo] OrderUpdate iikoStatusTo
             */

            /**
             * Constructs a new OrderUpdate.
             * @memberof fastcity.admin
             * @classdesc Represents an OrderUpdate.
             * @implements IOrderUpdate
             * @constructor
             * @param {fastcity.admin.IOrderUpdate=} [properties] Properties to set
             */
            function OrderUpdate(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * OrderUpdate orderId.
             * @member {number|Long} orderId
             * @memberof fastcity.admin.OrderUpdate
             * @instance
             */
            OrderUpdate.prototype.orderId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * OrderUpdate timestampUpdate.
             * @member {number|Long} timestampUpdate
             * @memberof fastcity.admin.OrderUpdate
             * @instance
             */
            OrderUpdate.prototype.timestampUpdate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * OrderUpdate updateSource.
             * @member {fastcity.common.Source} updateSource
             * @memberof fastcity.admin.OrderUpdate
             * @instance
             */
            OrderUpdate.prototype.updateSource = 0;

            /**
             * OrderUpdate courier.
             * @member {fastcity.admin.ICourier|null|undefined} courier
             * @memberof fastcity.admin.OrderUpdate
             * @instance
             */
            OrderUpdate.prototype.courier = null;

            /**
             * OrderUpdate commonStatusFrom.
             * @member {fastcity.common.Order.Status|null|undefined} commonStatusFrom
             * @memberof fastcity.admin.OrderUpdate
             * @instance
             */
            OrderUpdate.prototype.commonStatusFrom = null;

            /**
             * OrderUpdate iikoStatusFrom.
             * @member {fastcity.admin.IIKOOrderStatus|null|undefined} iikoStatusFrom
             * @memberof fastcity.admin.OrderUpdate
             * @instance
             */
            OrderUpdate.prototype.iikoStatusFrom = null;

            /**
             * OrderUpdate commonStatusTo.
             * @member {fastcity.common.Order.Status|null|undefined} commonStatusTo
             * @memberof fastcity.admin.OrderUpdate
             * @instance
             */
            OrderUpdate.prototype.commonStatusTo = null;

            /**
             * OrderUpdate iikoStatusTo.
             * @member {fastcity.admin.IIKOOrderStatus|null|undefined} iikoStatusTo
             * @memberof fastcity.admin.OrderUpdate
             * @instance
             */
            OrderUpdate.prototype.iikoStatusTo = null;

            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;

            /**
             * OrderUpdate optionalOrderChanger.
             * @member {"courier"|undefined} optionalOrderChanger
             * @memberof fastcity.admin.OrderUpdate
             * @instance
             */
            Object.defineProperty(OrderUpdate.prototype, "optionalOrderChanger", {
                get: $util.oneOfGetter($oneOfFields = ["courier"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * OrderUpdate statusFrom.
             * @member {"commonStatusFrom"|"iikoStatusFrom"|undefined} statusFrom
             * @memberof fastcity.admin.OrderUpdate
             * @instance
             */
            Object.defineProperty(OrderUpdate.prototype, "statusFrom", {
                get: $util.oneOfGetter($oneOfFields = ["commonStatusFrom", "iikoStatusFrom"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * OrderUpdate statusTo.
             * @member {"commonStatusTo"|"iikoStatusTo"|undefined} statusTo
             * @memberof fastcity.admin.OrderUpdate
             * @instance
             */
            Object.defineProperty(OrderUpdate.prototype, "statusTo", {
                get: $util.oneOfGetter($oneOfFields = ["commonStatusTo", "iikoStatusTo"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Creates a new OrderUpdate instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.OrderUpdate
             * @static
             * @param {fastcity.admin.IOrderUpdate=} [properties] Properties to set
             * @returns {fastcity.admin.OrderUpdate} OrderUpdate instance
             */
            OrderUpdate.create = function create(properties) {
                return new OrderUpdate(properties);
            };

            /**
             * Encodes the specified OrderUpdate message. Does not implicitly {@link fastcity.admin.OrderUpdate.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.OrderUpdate
             * @static
             * @param {fastcity.admin.IOrderUpdate} message OrderUpdate message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            OrderUpdate.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.orderId != null && Object.hasOwnProperty.call(message, "orderId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.orderId);
                if (message.timestampUpdate != null && Object.hasOwnProperty.call(message, "timestampUpdate"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int64(message.timestampUpdate);
                if (message.updateSource != null && Object.hasOwnProperty.call(message, "updateSource"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.updateSource);
                if (message.courier != null && Object.hasOwnProperty.call(message, "courier"))
                    $root.fastcity.admin.Courier.encode(message.courier, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.commonStatusFrom != null && Object.hasOwnProperty.call(message, "commonStatusFrom"))
                    writer.uint32(/* id 5, wireType 0 =*/40).int32(message.commonStatusFrom);
                if (message.iikoStatusFrom != null && Object.hasOwnProperty.call(message, "iikoStatusFrom"))
                    writer.uint32(/* id 6, wireType 0 =*/48).int32(message.iikoStatusFrom);
                if (message.commonStatusTo != null && Object.hasOwnProperty.call(message, "commonStatusTo"))
                    writer.uint32(/* id 7, wireType 0 =*/56).int32(message.commonStatusTo);
                if (message.iikoStatusTo != null && Object.hasOwnProperty.call(message, "iikoStatusTo"))
                    writer.uint32(/* id 8, wireType 0 =*/64).int32(message.iikoStatusTo);
                return writer;
            };

            /**
             * Decodes an OrderUpdate message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.OrderUpdate
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.OrderUpdate} OrderUpdate
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            OrderUpdate.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.OrderUpdate();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.orderId = reader.uint64();
                        break;
                    case 2:
                        message.timestampUpdate = reader.int64();
                        break;
                    case 3:
                        message.updateSource = reader.int32();
                        break;
                    case 4:
                        message.courier = $root.fastcity.admin.Courier.decode(reader, reader.uint32());
                        break;
                    case 5:
                        message.commonStatusFrom = reader.int32();
                        break;
                    case 6:
                        message.iikoStatusFrom = reader.int32();
                        break;
                    case 7:
                        message.commonStatusTo = reader.int32();
                        break;
                    case 8:
                        message.iikoStatusTo = reader.int32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an OrderUpdate message.
             * @function verify
             * @memberof fastcity.admin.OrderUpdate
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            OrderUpdate.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                var properties = {};
                if (message.orderId != null && message.hasOwnProperty("orderId"))
                    if (!$util.isInteger(message.orderId) && !(message.orderId && $util.isInteger(message.orderId.low) && $util.isInteger(message.orderId.high)))
                        return "orderId: integer|Long expected";
                if (message.timestampUpdate != null && message.hasOwnProperty("timestampUpdate"))
                    if (!$util.isInteger(message.timestampUpdate) && !(message.timestampUpdate && $util.isInteger(message.timestampUpdate.low) && $util.isInteger(message.timestampUpdate.high)))
                        return "timestampUpdate: integer|Long expected";
                if (message.updateSource != null && message.hasOwnProperty("updateSource"))
                    switch (message.updateSource) {
                    default:
                        return "updateSource: enum value expected";
                    case 0:
                    case 1:
                        break;
                    }
                if (message.courier != null && message.hasOwnProperty("courier")) {
                    properties.optionalOrderChanger = 1;
                    {
                        var error = $root.fastcity.admin.Courier.verify(message.courier);
                        if (error)
                            return "courier." + error;
                    }
                }
                if (message.commonStatusFrom != null && message.hasOwnProperty("commonStatusFrom")) {
                    properties.statusFrom = 1;
                    switch (message.commonStatusFrom) {
                    default:
                        return "commonStatusFrom: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                    case 9:
                    case 10:
                    case 11:
                    case 12:
                    case 13:
                    case 14:
                    case 15:
                    case 16:
                        break;
                    }
                }
                if (message.iikoStatusFrom != null && message.hasOwnProperty("iikoStatusFrom")) {
                    if (properties.statusFrom === 1)
                        return "statusFrom: multiple values";
                    properties.statusFrom = 1;
                    switch (message.iikoStatusFrom) {
                    default:
                        return "iikoStatusFrom: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                    case 9:
                        break;
                    }
                }
                if (message.commonStatusTo != null && message.hasOwnProperty("commonStatusTo")) {
                    properties.statusTo = 1;
                    switch (message.commonStatusTo) {
                    default:
                        return "commonStatusTo: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                    case 9:
                    case 10:
                    case 11:
                    case 12:
                    case 13:
                    case 14:
                    case 15:
                    case 16:
                        break;
                    }
                }
                if (message.iikoStatusTo != null && message.hasOwnProperty("iikoStatusTo")) {
                    if (properties.statusTo === 1)
                        return "statusTo: multiple values";
                    properties.statusTo = 1;
                    switch (message.iikoStatusTo) {
                    default:
                        return "iikoStatusTo: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                    case 9:
                        break;
                    }
                }
                return null;
            };

            /**
             * Creates an OrderUpdate message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.OrderUpdate
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.OrderUpdate} OrderUpdate
             */
            OrderUpdate.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.OrderUpdate)
                    return object;
                var message = new $root.fastcity.admin.OrderUpdate();
                if (object.orderId != null)
                    if ($util.Long)
                        (message.orderId = $util.Long.fromValue(object.orderId)).unsigned = true;
                    else if (typeof object.orderId === "string")
                        message.orderId = parseInt(object.orderId, 10);
                    else if (typeof object.orderId === "number")
                        message.orderId = object.orderId;
                    else if (typeof object.orderId === "object")
                        message.orderId = new $util.LongBits(object.orderId.low >>> 0, object.orderId.high >>> 0).toNumber(true);
                if (object.timestampUpdate != null)
                    if ($util.Long)
                        (message.timestampUpdate = $util.Long.fromValue(object.timestampUpdate)).unsigned = false;
                    else if (typeof object.timestampUpdate === "string")
                        message.timestampUpdate = parseInt(object.timestampUpdate, 10);
                    else if (typeof object.timestampUpdate === "number")
                        message.timestampUpdate = object.timestampUpdate;
                    else if (typeof object.timestampUpdate === "object")
                        message.timestampUpdate = new $util.LongBits(object.timestampUpdate.low >>> 0, object.timestampUpdate.high >>> 0).toNumber();
                switch (object.updateSource) {
                case "UNKNOWN":
                case 0:
                    message.updateSource = 0;
                    break;
                case "IIKO":
                case 1:
                    message.updateSource = 1;
                    break;
                }
                if (object.courier != null) {
                    if (typeof object.courier !== "object")
                        throw TypeError(".fastcity.admin.OrderUpdate.courier: object expected");
                    message.courier = $root.fastcity.admin.Courier.fromObject(object.courier);
                }
                switch (object.commonStatusFrom) {
                case "S_UNKNOWN":
                case 0:
                    message.commonStatusFrom = 0;
                    break;
                case "S_IN_PROGRESS":
                case 1:
                    message.commonStatusFrom = 1;
                    break;
                case "S_MAKELINE":
                case 2:
                    message.commonStatusFrom = 2;
                    break;
                case "S_ROUTING_STATION":
                case 3:
                    message.commonStatusFrom = 3;
                    break;
                case "S_OUT_THE_DOOR":
                case 4:
                    message.commonStatusFrom = 4;
                    break;
                case "S_COMPLETE":
                case 5:
                    message.commonStatusFrom = 5;
                    break;
                case "S_GIFT":
                case 6:
                    message.commonStatusFrom = 6;
                    break;
                case "S_BAD":
                case 7:
                    message.commonStatusFrom = 7;
                    break;
                case "S_VOID":
                case 8:
                    message.commonStatusFrom = 8;
                    break;
                case "S_BEING_TAKEN":
                case 9:
                    message.commonStatusFrom = 9;
                    break;
                case "S_ABANDONED":
                case 10:
                    message.commonStatusFrom = 10;
                    break;
                case "S_SUSPENDED":
                case 11:
                    message.commonStatusFrom = 11;
                    break;
                case "S_GIFT_CARD_PURCHASE":
                case 12:
                    message.commonStatusFrom = 12;
                    break;
                case "S_GIFT_CARD_VOID":
                case 13:
                    message.commonStatusFrom = 13;
                    break;
                case "S_TRANSFERRED":
                case 14:
                    message.commonStatusFrom = 14;
                    break;
                case "S_HISTORIC_ORDER":
                case 15:
                    message.commonStatusFrom = 15;
                    break;
                case "S_BEING_ASSIGNED":
                case 16:
                    message.commonStatusFrom = 16;
                    break;
                }
                switch (object.iikoStatusFrom) {
                case "UNCONFIRMED":
                case 0:
                    message.iikoStatusFrom = 0;
                    break;
                case "WAIT_COOKING":
                case 1:
                    message.iikoStatusFrom = 1;
                    break;
                case "READY_FOR_COOKING":
                case 2:
                    message.iikoStatusFrom = 2;
                    break;
                case "COOKING_STARTED":
                case 3:
                    message.iikoStatusFrom = 3;
                    break;
                case "COOKING_COMPLETED":
                case 4:
                    message.iikoStatusFrom = 4;
                    break;
                case "WAITING":
                case 5:
                    message.iikoStatusFrom = 5;
                    break;
                case "ON_WAY":
                case 6:
                    message.iikoStatusFrom = 6;
                    break;
                case "DELIVERED":
                case 7:
                    message.iikoStatusFrom = 7;
                    break;
                case "CLOSED":
                case 8:
                    message.iikoStatusFrom = 8;
                    break;
                case "CANCELLED":
                case 9:
                    message.iikoStatusFrom = 9;
                    break;
                }
                switch (object.commonStatusTo) {
                case "S_UNKNOWN":
                case 0:
                    message.commonStatusTo = 0;
                    break;
                case "S_IN_PROGRESS":
                case 1:
                    message.commonStatusTo = 1;
                    break;
                case "S_MAKELINE":
                case 2:
                    message.commonStatusTo = 2;
                    break;
                case "S_ROUTING_STATION":
                case 3:
                    message.commonStatusTo = 3;
                    break;
                case "S_OUT_THE_DOOR":
                case 4:
                    message.commonStatusTo = 4;
                    break;
                case "S_COMPLETE":
                case 5:
                    message.commonStatusTo = 5;
                    break;
                case "S_GIFT":
                case 6:
                    message.commonStatusTo = 6;
                    break;
                case "S_BAD":
                case 7:
                    message.commonStatusTo = 7;
                    break;
                case "S_VOID":
                case 8:
                    message.commonStatusTo = 8;
                    break;
                case "S_BEING_TAKEN":
                case 9:
                    message.commonStatusTo = 9;
                    break;
                case "S_ABANDONED":
                case 10:
                    message.commonStatusTo = 10;
                    break;
                case "S_SUSPENDED":
                case 11:
                    message.commonStatusTo = 11;
                    break;
                case "S_GIFT_CARD_PURCHASE":
                case 12:
                    message.commonStatusTo = 12;
                    break;
                case "S_GIFT_CARD_VOID":
                case 13:
                    message.commonStatusTo = 13;
                    break;
                case "S_TRANSFERRED":
                case 14:
                    message.commonStatusTo = 14;
                    break;
                case "S_HISTORIC_ORDER":
                case 15:
                    message.commonStatusTo = 15;
                    break;
                case "S_BEING_ASSIGNED":
                case 16:
                    message.commonStatusTo = 16;
                    break;
                }
                switch (object.iikoStatusTo) {
                case "UNCONFIRMED":
                case 0:
                    message.iikoStatusTo = 0;
                    break;
                case "WAIT_COOKING":
                case 1:
                    message.iikoStatusTo = 1;
                    break;
                case "READY_FOR_COOKING":
                case 2:
                    message.iikoStatusTo = 2;
                    break;
                case "COOKING_STARTED":
                case 3:
                    message.iikoStatusTo = 3;
                    break;
                case "COOKING_COMPLETED":
                case 4:
                    message.iikoStatusTo = 4;
                    break;
                case "WAITING":
                case 5:
                    message.iikoStatusTo = 5;
                    break;
                case "ON_WAY":
                case 6:
                    message.iikoStatusTo = 6;
                    break;
                case "DELIVERED":
                case 7:
                    message.iikoStatusTo = 7;
                    break;
                case "CLOSED":
                case 8:
                    message.iikoStatusTo = 8;
                    break;
                case "CANCELLED":
                case 9:
                    message.iikoStatusTo = 9;
                    break;
                }
                return message;
            };

            /**
             * Creates a plain object from an OrderUpdate message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.OrderUpdate
             * @static
             * @param {fastcity.admin.OrderUpdate} message OrderUpdate
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            OrderUpdate.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.orderId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.orderId = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.timestampUpdate = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.timestampUpdate = options.longs === String ? "0" : 0;
                    object.updateSource = options.enums === String ? "UNKNOWN" : 0;
                }
                if (message.orderId != null && message.hasOwnProperty("orderId"))
                    if (typeof message.orderId === "number")
                        object.orderId = options.longs === String ? String(message.orderId) : message.orderId;
                    else
                        object.orderId = options.longs === String ? $util.Long.prototype.toString.call(message.orderId) : options.longs === Number ? new $util.LongBits(message.orderId.low >>> 0, message.orderId.high >>> 0).toNumber(true) : message.orderId;
                if (message.timestampUpdate != null && message.hasOwnProperty("timestampUpdate"))
                    if (typeof message.timestampUpdate === "number")
                        object.timestampUpdate = options.longs === String ? String(message.timestampUpdate) : message.timestampUpdate;
                    else
                        object.timestampUpdate = options.longs === String ? $util.Long.prototype.toString.call(message.timestampUpdate) : options.longs === Number ? new $util.LongBits(message.timestampUpdate.low >>> 0, message.timestampUpdate.high >>> 0).toNumber() : message.timestampUpdate;
                if (message.updateSource != null && message.hasOwnProperty("updateSource"))
                    object.updateSource = options.enums === String ? $root.fastcity.common.Source[message.updateSource] : message.updateSource;
                if (message.courier != null && message.hasOwnProperty("courier")) {
                    object.courier = $root.fastcity.admin.Courier.toObject(message.courier, options);
                    if (options.oneofs)
                        object.optionalOrderChanger = "courier";
                }
                if (message.commonStatusFrom != null && message.hasOwnProperty("commonStatusFrom")) {
                    object.commonStatusFrom = options.enums === String ? $root.fastcity.common.Order.Status[message.commonStatusFrom] : message.commonStatusFrom;
                    if (options.oneofs)
                        object.statusFrom = "commonStatusFrom";
                }
                if (message.iikoStatusFrom != null && message.hasOwnProperty("iikoStatusFrom")) {
                    object.iikoStatusFrom = options.enums === String ? $root.fastcity.admin.IIKOOrderStatus[message.iikoStatusFrom] : message.iikoStatusFrom;
                    if (options.oneofs)
                        object.statusFrom = "iikoStatusFrom";
                }
                if (message.commonStatusTo != null && message.hasOwnProperty("commonStatusTo")) {
                    object.commonStatusTo = options.enums === String ? $root.fastcity.common.Order.Status[message.commonStatusTo] : message.commonStatusTo;
                    if (options.oneofs)
                        object.statusTo = "commonStatusTo";
                }
                if (message.iikoStatusTo != null && message.hasOwnProperty("iikoStatusTo")) {
                    object.iikoStatusTo = options.enums === String ? $root.fastcity.admin.IIKOOrderStatus[message.iikoStatusTo] : message.iikoStatusTo;
                    if (options.oneofs)
                        object.statusTo = "iikoStatusTo";
                }
                return object;
            };

            /**
             * Converts this OrderUpdate to JSON.
             * @function toJSON
             * @memberof fastcity.admin.OrderUpdate
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            OrderUpdate.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return OrderUpdate;
        })();

        admin.OrderUpdateList = (function() {

            /**
             * Properties of an OrderUpdateList.
             * @memberof fastcity.admin
             * @interface IOrderUpdateList
             * @property {Array.<fastcity.admin.IOrderUpdate>|null} [orderUpdate] OrderUpdateList orderUpdate
             */

            /**
             * Constructs a new OrderUpdateList.
             * @memberof fastcity.admin
             * @classdesc Represents an OrderUpdateList.
             * @implements IOrderUpdateList
             * @constructor
             * @param {fastcity.admin.IOrderUpdateList=} [properties] Properties to set
             */
            function OrderUpdateList(properties) {
                this.orderUpdate = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * OrderUpdateList orderUpdate.
             * @member {Array.<fastcity.admin.IOrderUpdate>} orderUpdate
             * @memberof fastcity.admin.OrderUpdateList
             * @instance
             */
            OrderUpdateList.prototype.orderUpdate = $util.emptyArray;

            /**
             * Creates a new OrderUpdateList instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.OrderUpdateList
             * @static
             * @param {fastcity.admin.IOrderUpdateList=} [properties] Properties to set
             * @returns {fastcity.admin.OrderUpdateList} OrderUpdateList instance
             */
            OrderUpdateList.create = function create(properties) {
                return new OrderUpdateList(properties);
            };

            /**
             * Encodes the specified OrderUpdateList message. Does not implicitly {@link fastcity.admin.OrderUpdateList.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.OrderUpdateList
             * @static
             * @param {fastcity.admin.IOrderUpdateList} message OrderUpdateList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            OrderUpdateList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.orderUpdate != null && message.orderUpdate.length)
                    for (var i = 0; i < message.orderUpdate.length; ++i)
                        $root.fastcity.admin.OrderUpdate.encode(message.orderUpdate[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes an OrderUpdateList message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.OrderUpdateList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.OrderUpdateList} OrderUpdateList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            OrderUpdateList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.OrderUpdateList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.orderUpdate && message.orderUpdate.length))
                            message.orderUpdate = [];
                        message.orderUpdate.push($root.fastcity.admin.OrderUpdate.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an OrderUpdateList message.
             * @function verify
             * @memberof fastcity.admin.OrderUpdateList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            OrderUpdateList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.orderUpdate != null && message.hasOwnProperty("orderUpdate")) {
                    if (!Array.isArray(message.orderUpdate))
                        return "orderUpdate: array expected";
                    for (var i = 0; i < message.orderUpdate.length; ++i) {
                        var error = $root.fastcity.admin.OrderUpdate.verify(message.orderUpdate[i]);
                        if (error)
                            return "orderUpdate." + error;
                    }
                }
                return null;
            };

            /**
             * Creates an OrderUpdateList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.OrderUpdateList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.OrderUpdateList} OrderUpdateList
             */
            OrderUpdateList.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.OrderUpdateList)
                    return object;
                var message = new $root.fastcity.admin.OrderUpdateList();
                if (object.orderUpdate) {
                    if (!Array.isArray(object.orderUpdate))
                        throw TypeError(".fastcity.admin.OrderUpdateList.orderUpdate: array expected");
                    message.orderUpdate = [];
                    for (var i = 0; i < object.orderUpdate.length; ++i) {
                        if (typeof object.orderUpdate[i] !== "object")
                            throw TypeError(".fastcity.admin.OrderUpdateList.orderUpdate: object expected");
                        message.orderUpdate[i] = $root.fastcity.admin.OrderUpdate.fromObject(object.orderUpdate[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from an OrderUpdateList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.OrderUpdateList
             * @static
             * @param {fastcity.admin.OrderUpdateList} message OrderUpdateList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            OrderUpdateList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.orderUpdate = [];
                if (message.orderUpdate && message.orderUpdate.length) {
                    object.orderUpdate = [];
                    for (var j = 0; j < message.orderUpdate.length; ++j)
                        object.orderUpdate[j] = $root.fastcity.admin.OrderUpdate.toObject(message.orderUpdate[j], options);
                }
                return object;
            };

            /**
             * Converts this OrderUpdateList to JSON.
             * @function toJSON
             * @memberof fastcity.admin.OrderUpdateList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            OrderUpdateList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return OrderUpdateList;
        })();

        admin.GetOrderUpdateList = (function() {

            /**
             * Properties of a GetOrderUpdateList.
             * @memberof fastcity.admin
             * @interface IGetOrderUpdateList
             * @property {number|Long|null} [orderId] GetOrderUpdateList orderId
             */

            /**
             * Constructs a new GetOrderUpdateList.
             * @memberof fastcity.admin
             * @classdesc Represents a GetOrderUpdateList.
             * @implements IGetOrderUpdateList
             * @constructor
             * @param {fastcity.admin.IGetOrderUpdateList=} [properties] Properties to set
             */
            function GetOrderUpdateList(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GetOrderUpdateList orderId.
             * @member {number|Long} orderId
             * @memberof fastcity.admin.GetOrderUpdateList
             * @instance
             */
            GetOrderUpdateList.prototype.orderId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Creates a new GetOrderUpdateList instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.GetOrderUpdateList
             * @static
             * @param {fastcity.admin.IGetOrderUpdateList=} [properties] Properties to set
             * @returns {fastcity.admin.GetOrderUpdateList} GetOrderUpdateList instance
             */
            GetOrderUpdateList.create = function create(properties) {
                return new GetOrderUpdateList(properties);
            };

            /**
             * Encodes the specified GetOrderUpdateList message. Does not implicitly {@link fastcity.admin.GetOrderUpdateList.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.GetOrderUpdateList
             * @static
             * @param {fastcity.admin.IGetOrderUpdateList} message GetOrderUpdateList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetOrderUpdateList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.orderId != null && Object.hasOwnProperty.call(message, "orderId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.orderId);
                return writer;
            };

            /**
             * Decodes a GetOrderUpdateList message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.GetOrderUpdateList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.GetOrderUpdateList} GetOrderUpdateList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetOrderUpdateList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.GetOrderUpdateList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.orderId = reader.int64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a GetOrderUpdateList message.
             * @function verify
             * @memberof fastcity.admin.GetOrderUpdateList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetOrderUpdateList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.orderId != null && message.hasOwnProperty("orderId"))
                    if (!$util.isInteger(message.orderId) && !(message.orderId && $util.isInteger(message.orderId.low) && $util.isInteger(message.orderId.high)))
                        return "orderId: integer|Long expected";
                return null;
            };

            /**
             * Creates a GetOrderUpdateList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.GetOrderUpdateList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.GetOrderUpdateList} GetOrderUpdateList
             */
            GetOrderUpdateList.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.GetOrderUpdateList)
                    return object;
                var message = new $root.fastcity.admin.GetOrderUpdateList();
                if (object.orderId != null)
                    if ($util.Long)
                        (message.orderId = $util.Long.fromValue(object.orderId)).unsigned = false;
                    else if (typeof object.orderId === "string")
                        message.orderId = parseInt(object.orderId, 10);
                    else if (typeof object.orderId === "number")
                        message.orderId = object.orderId;
                    else if (typeof object.orderId === "object")
                        message.orderId = new $util.LongBits(object.orderId.low >>> 0, object.orderId.high >>> 0).toNumber();
                return message;
            };

            /**
             * Creates a plain object from a GetOrderUpdateList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.GetOrderUpdateList
             * @static
             * @param {fastcity.admin.GetOrderUpdateList} message GetOrderUpdateList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetOrderUpdateList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.orderId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.orderId = options.longs === String ? "0" : 0;
                if (message.orderId != null && message.hasOwnProperty("orderId"))
                    if (typeof message.orderId === "number")
                        object.orderId = options.longs === String ? String(message.orderId) : message.orderId;
                    else
                        object.orderId = options.longs === String ? $util.Long.prototype.toString.call(message.orderId) : options.longs === Number ? new $util.LongBits(message.orderId.low >>> 0, message.orderId.high >>> 0).toNumber() : message.orderId;
                return object;
            };

            /**
             * Converts this GetOrderUpdateList to JSON.
             * @function toJSON
             * @memberof fastcity.admin.GetOrderUpdateList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetOrderUpdateList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return GetOrderUpdateList;
        })();

        /**
         * OrderRequestResult enum.
         * @name fastcity.admin.OrderRequestResult
         * @enum {number}
         * @property {number} ORR_NOT_FOUND=0 ORR_NOT_FOUND value
         * @property {number} ORR_BAD_COMPANY_ID_COMPANY_NOT_FOUND=1 ORR_BAD_COMPANY_ID_COMPANY_NOT_FOUND value
         * @property {number} ORR_BAD_COMPANY_ID_COMPANY_IS_DELETED=2 ORR_BAD_COMPANY_ID_COMPANY_IS_DELETED value
         * @property {number} ORR_BAD_SUB_COMPANY_ID_SUB_COMPANY_NOT_FOUND=3 ORR_BAD_SUB_COMPANY_ID_SUB_COMPANY_NOT_FOUND value
         * @property {number} ORR_BAD_SUB_COMPANY_ID_SUB_COMPANY_IS_DELETED=4 ORR_BAD_SUB_COMPANY_ID_SUB_COMPANY_IS_DELETED value
         * @property {number} ORR_BAD_COURIER_ID_COURIER_NOT_FOUND=5 ORR_BAD_COURIER_ID_COURIER_NOT_FOUND value
         * @property {number} ORR_BAD_COURIER_ID_COURIER_IS_DELETED=6 ORR_BAD_COURIER_ID_COURIER_IS_DELETED value
         * @property {number} ORR_BAD_ORDER_ID_ORDER_IS_DELETED=7 ORR_BAD_ORDER_ID_ORDER_IS_DELETED value
         */
        admin.OrderRequestResult = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "ORR_NOT_FOUND"] = 0;
            values[valuesById[1] = "ORR_BAD_COMPANY_ID_COMPANY_NOT_FOUND"] = 1;
            values[valuesById[2] = "ORR_BAD_COMPANY_ID_COMPANY_IS_DELETED"] = 2;
            values[valuesById[3] = "ORR_BAD_SUB_COMPANY_ID_SUB_COMPANY_NOT_FOUND"] = 3;
            values[valuesById[4] = "ORR_BAD_SUB_COMPANY_ID_SUB_COMPANY_IS_DELETED"] = 4;
            values[valuesById[5] = "ORR_BAD_COURIER_ID_COURIER_NOT_FOUND"] = 5;
            values[valuesById[6] = "ORR_BAD_COURIER_ID_COURIER_IS_DELETED"] = 6;
            values[valuesById[7] = "ORR_BAD_ORDER_ID_ORDER_IS_DELETED"] = 7;
            return values;
        })();

        admin.OrderItem = (function() {

            /**
             * Properties of an OrderItem.
             * @memberof fastcity.admin
             * @interface IOrderItem
             * @property {number|Long|null} [orderItemId] OrderItem orderItemId
             * @property {string|null} [externalOrderItemId] OrderItem externalOrderItemId
             * @property {string|null} [externalItemCode] OrderItem externalItemCode
             * @property {string|null} [name] OrderItem name
             * @property {string|null} [description] OrderItem description
             * @property {string|null} [finalPrice] OrderItem finalPrice
             * @property {string|null} [priceForOne] OrderItem priceForOne
             * @property {number|null} [quantity] OrderItem quantity
             * @property {fastcity.common.OrderItem.ProductType|null} [productType] OrderItem productType
             */

            /**
             * Constructs a new OrderItem.
             * @memberof fastcity.admin
             * @classdesc Represents an OrderItem.
             * @implements IOrderItem
             * @constructor
             * @param {fastcity.admin.IOrderItem=} [properties] Properties to set
             */
            function OrderItem(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * OrderItem orderItemId.
             * @member {number|Long} orderItemId
             * @memberof fastcity.admin.OrderItem
             * @instance
             */
            OrderItem.prototype.orderItemId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * OrderItem externalOrderItemId.
             * @member {string} externalOrderItemId
             * @memberof fastcity.admin.OrderItem
             * @instance
             */
            OrderItem.prototype.externalOrderItemId = "";

            /**
             * OrderItem externalItemCode.
             * @member {string} externalItemCode
             * @memberof fastcity.admin.OrderItem
             * @instance
             */
            OrderItem.prototype.externalItemCode = "";

            /**
             * OrderItem name.
             * @member {string} name
             * @memberof fastcity.admin.OrderItem
             * @instance
             */
            OrderItem.prototype.name = "";

            /**
             * OrderItem description.
             * @member {string} description
             * @memberof fastcity.admin.OrderItem
             * @instance
             */
            OrderItem.prototype.description = "";

            /**
             * OrderItem finalPrice.
             * @member {string} finalPrice
             * @memberof fastcity.admin.OrderItem
             * @instance
             */
            OrderItem.prototype.finalPrice = "";

            /**
             * OrderItem priceForOne.
             * @member {string} priceForOne
             * @memberof fastcity.admin.OrderItem
             * @instance
             */
            OrderItem.prototype.priceForOne = "";

            /**
             * OrderItem quantity.
             * @member {number} quantity
             * @memberof fastcity.admin.OrderItem
             * @instance
             */
            OrderItem.prototype.quantity = 0;

            /**
             * OrderItem productType.
             * @member {fastcity.common.OrderItem.ProductType} productType
             * @memberof fastcity.admin.OrderItem
             * @instance
             */
            OrderItem.prototype.productType = 0;

            /**
             * Creates a new OrderItem instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.OrderItem
             * @static
             * @param {fastcity.admin.IOrderItem=} [properties] Properties to set
             * @returns {fastcity.admin.OrderItem} OrderItem instance
             */
            OrderItem.create = function create(properties) {
                return new OrderItem(properties);
            };

            /**
             * Encodes the specified OrderItem message. Does not implicitly {@link fastcity.admin.OrderItem.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.OrderItem
             * @static
             * @param {fastcity.admin.IOrderItem} message OrderItem message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            OrderItem.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.orderItemId != null && Object.hasOwnProperty.call(message, "orderItemId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.orderItemId);
                if (message.externalOrderItemId != null && Object.hasOwnProperty.call(message, "externalOrderItemId"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.externalOrderItemId);
                if (message.externalItemCode != null && Object.hasOwnProperty.call(message, "externalItemCode"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.externalItemCode);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.name);
                if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.description);
                if (message.finalPrice != null && Object.hasOwnProperty.call(message, "finalPrice"))
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.finalPrice);
                if (message.priceForOne != null && Object.hasOwnProperty.call(message, "priceForOne"))
                    writer.uint32(/* id 7, wireType 2 =*/58).string(message.priceForOne);
                if (message.quantity != null && Object.hasOwnProperty.call(message, "quantity"))
                    writer.uint32(/* id 8, wireType 0 =*/64).int32(message.quantity);
                if (message.productType != null && Object.hasOwnProperty.call(message, "productType"))
                    writer.uint32(/* id 9, wireType 0 =*/72).int32(message.productType);
                return writer;
            };

            /**
             * Decodes an OrderItem message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.OrderItem
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.OrderItem} OrderItem
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            OrderItem.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.OrderItem();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.orderItemId = reader.uint64();
                        break;
                    case 2:
                        message.externalOrderItemId = reader.string();
                        break;
                    case 3:
                        message.externalItemCode = reader.string();
                        break;
                    case 4:
                        message.name = reader.string();
                        break;
                    case 5:
                        message.description = reader.string();
                        break;
                    case 6:
                        message.finalPrice = reader.string();
                        break;
                    case 7:
                        message.priceForOne = reader.string();
                        break;
                    case 8:
                        message.quantity = reader.int32();
                        break;
                    case 9:
                        message.productType = reader.int32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an OrderItem message.
             * @function verify
             * @memberof fastcity.admin.OrderItem
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            OrderItem.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.orderItemId != null && message.hasOwnProperty("orderItemId"))
                    if (!$util.isInteger(message.orderItemId) && !(message.orderItemId && $util.isInteger(message.orderItemId.low) && $util.isInteger(message.orderItemId.high)))
                        return "orderItemId: integer|Long expected";
                if (message.externalOrderItemId != null && message.hasOwnProperty("externalOrderItemId"))
                    if (!$util.isString(message.externalOrderItemId))
                        return "externalOrderItemId: string expected";
                if (message.externalItemCode != null && message.hasOwnProperty("externalItemCode"))
                    if (!$util.isString(message.externalItemCode))
                        return "externalItemCode: string expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.description != null && message.hasOwnProperty("description"))
                    if (!$util.isString(message.description))
                        return "description: string expected";
                if (message.finalPrice != null && message.hasOwnProperty("finalPrice"))
                    if (!$util.isString(message.finalPrice))
                        return "finalPrice: string expected";
                if (message.priceForOne != null && message.hasOwnProperty("priceForOne"))
                    if (!$util.isString(message.priceForOne))
                        return "priceForOne: string expected";
                if (message.quantity != null && message.hasOwnProperty("quantity"))
                    if (!$util.isInteger(message.quantity))
                        return "quantity: integer expected";
                if (message.productType != null && message.hasOwnProperty("productType"))
                    switch (message.productType) {
                    default:
                        return "productType: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                    case 9:
                    case 10:
                    case 11:
                        break;
                    }
                return null;
            };

            /**
             * Creates an OrderItem message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.OrderItem
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.OrderItem} OrderItem
             */
            OrderItem.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.OrderItem)
                    return object;
                var message = new $root.fastcity.admin.OrderItem();
                if (object.orderItemId != null)
                    if ($util.Long)
                        (message.orderItemId = $util.Long.fromValue(object.orderItemId)).unsigned = true;
                    else if (typeof object.orderItemId === "string")
                        message.orderItemId = parseInt(object.orderItemId, 10);
                    else if (typeof object.orderItemId === "number")
                        message.orderItemId = object.orderItemId;
                    else if (typeof object.orderItemId === "object")
                        message.orderItemId = new $util.LongBits(object.orderItemId.low >>> 0, object.orderItemId.high >>> 0).toNumber(true);
                if (object.externalOrderItemId != null)
                    message.externalOrderItemId = String(object.externalOrderItemId);
                if (object.externalItemCode != null)
                    message.externalItemCode = String(object.externalItemCode);
                if (object.name != null)
                    message.name = String(object.name);
                if (object.description != null)
                    message.description = String(object.description);
                if (object.finalPrice != null)
                    message.finalPrice = String(object.finalPrice);
                if (object.priceForOne != null)
                    message.priceForOne = String(object.priceForOne);
                if (object.quantity != null)
                    message.quantity = object.quantity | 0;
                switch (object.productType) {
                case "T_UNKNOWN":
                case 0:
                    message.productType = 0;
                    break;
                case "T_DESSERT":
                case 1:
                    message.productType = 1;
                    break;
                case "T_SNACK":
                case 2:
                    message.productType = 2;
                    break;
                case "T_DRINK":
                case 3:
                    message.productType = 3;
                    break;
                case "T_BEER":
                case 4:
                    message.productType = 4;
                    break;
                case "T_PIZZA":
                case 5:
                    message.productType = 5;
                    break;
                case "T_GIFT":
                case 6:
                    message.productType = 6;
                    break;
                case "T_SALAD":
                case 7:
                    message.productType = 7;
                    break;
                case "T_BREAD":
                case 8:
                    message.productType = 8;
                    break;
                case "T_SAUCE":
                case 9:
                    message.productType = 9;
                    break;
                case "T_COMPOUND":
                case 10:
                    message.productType = 10;
                    break;
                case "T_SERVICE":
                case 11:
                    message.productType = 11;
                    break;
                }
                return message;
            };

            /**
             * Creates a plain object from an OrderItem message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.OrderItem
             * @static
             * @param {fastcity.admin.OrderItem} message OrderItem
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            OrderItem.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.orderItemId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.orderItemId = options.longs === String ? "0" : 0;
                    object.externalOrderItemId = "";
                    object.externalItemCode = "";
                    object.name = "";
                    object.description = "";
                    object.finalPrice = "";
                    object.priceForOne = "";
                    object.quantity = 0;
                    object.productType = options.enums === String ? "T_UNKNOWN" : 0;
                }
                if (message.orderItemId != null && message.hasOwnProperty("orderItemId"))
                    if (typeof message.orderItemId === "number")
                        object.orderItemId = options.longs === String ? String(message.orderItemId) : message.orderItemId;
                    else
                        object.orderItemId = options.longs === String ? $util.Long.prototype.toString.call(message.orderItemId) : options.longs === Number ? new $util.LongBits(message.orderItemId.low >>> 0, message.orderItemId.high >>> 0).toNumber(true) : message.orderItemId;
                if (message.externalOrderItemId != null && message.hasOwnProperty("externalOrderItemId"))
                    object.externalOrderItemId = message.externalOrderItemId;
                if (message.externalItemCode != null && message.hasOwnProperty("externalItemCode"))
                    object.externalItemCode = message.externalItemCode;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.description != null && message.hasOwnProperty("description"))
                    object.description = message.description;
                if (message.finalPrice != null && message.hasOwnProperty("finalPrice"))
                    object.finalPrice = message.finalPrice;
                if (message.priceForOne != null && message.hasOwnProperty("priceForOne"))
                    object.priceForOne = message.priceForOne;
                if (message.quantity != null && message.hasOwnProperty("quantity"))
                    object.quantity = message.quantity;
                if (message.productType != null && message.hasOwnProperty("productType"))
                    object.productType = options.enums === String ? $root.fastcity.common.OrderItem.ProductType[message.productType] : message.productType;
                return object;
            };

            /**
             * Converts this OrderItem to JSON.
             * @function toJSON
             * @memberof fastcity.admin.OrderItem
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            OrderItem.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return OrderItem;
        })();

        admin.Order = (function() {

            /**
             * Properties of an Order.
             * @memberof fastcity.admin
             * @interface IOrder
             * @property {number|Long|null} [orderId] Order orderId
             * @property {string|null} [orderIdToPrint] Order orderIdToPrint
             * @property {string|null} [externalOrderId] Order externalOrderId
             * @property {number|Long|null} [companyId] Order companyId
             * @property {number|Long|null} [subCompanyId] Order subCompanyId
             * @property {number|Long|null} [courierId] Order courierId
             * @property {number|Long|null} [timestampOpen] Order timestampOpen
             * @property {number|Long|null} [timestampClose] Order timestampClose
             * @property {fastcity.common.Source|null} [source] Order source
             * @property {string|null} [finalPrice] Order finalPrice
             * @property {fastcity.common.Order.PaymentType|null} [paymentType] Order paymentType
             * @property {string|null} [clientPhone] Order clientPhone
             * @property {string|null} [clientName] Order clientName
             * @property {string|null} [clientAddress] Order clientAddress
             * @property {string|null} [comments] Order comments
             * @property {Array.<fastcity.admin.IOrderItem>|null} [orderItem] Order orderItem
             * @property {fastcity.common.ICoordinate|null} [coordinate] Order coordinate
             * @property {string|null} [currencySymbol] Order currencySymbol
             * @property {number|Long|null} [timestampCancel] Order timestampCancel
             * @property {string|null} [clientRoom] Order clientRoom
             * @property {number|Long|null} [timestampAccept] Order timestampAccept
             * @property {number|Long|null} [timestampUpdated] Order timestampUpdated
             * @property {number|Long|null} [timestampDeliveryBefore] Order timestampDeliveryBefore
             * @property {Array.<fastcity.common.Order.PaymentType>|null} [paymentTypes] Order paymentTypes
             * @property {fastcity.common.Order.Status|null} [commonStatus] Order commonStatus
             * @property {fastcity.admin.IIKOOrderStatus|null} [iikoStatus] Order iikoStatus
             */

            /**
             * Constructs a new Order.
             * @memberof fastcity.admin
             * @classdesc Represents an Order.
             * @implements IOrder
             * @constructor
             * @param {fastcity.admin.IOrder=} [properties] Properties to set
             */
            function Order(properties) {
                this.orderItem = [];
                this.paymentTypes = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Order orderId.
             * @member {number|Long} orderId
             * @memberof fastcity.admin.Order
             * @instance
             */
            Order.prototype.orderId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Order orderIdToPrint.
             * @member {string} orderIdToPrint
             * @memberof fastcity.admin.Order
             * @instance
             */
            Order.prototype.orderIdToPrint = "";

            /**
             * Order externalOrderId.
             * @member {string} externalOrderId
             * @memberof fastcity.admin.Order
             * @instance
             */
            Order.prototype.externalOrderId = "";

            /**
             * Order companyId.
             * @member {number|Long} companyId
             * @memberof fastcity.admin.Order
             * @instance
             */
            Order.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Order subCompanyId.
             * @member {number|Long} subCompanyId
             * @memberof fastcity.admin.Order
             * @instance
             */
            Order.prototype.subCompanyId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Order courierId.
             * @member {number|Long} courierId
             * @memberof fastcity.admin.Order
             * @instance
             */
            Order.prototype.courierId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Order timestampOpen.
             * @member {number|Long} timestampOpen
             * @memberof fastcity.admin.Order
             * @instance
             */
            Order.prototype.timestampOpen = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Order timestampClose.
             * @member {number|Long} timestampClose
             * @memberof fastcity.admin.Order
             * @instance
             */
            Order.prototype.timestampClose = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Order source.
             * @member {fastcity.common.Source} source
             * @memberof fastcity.admin.Order
             * @instance
             */
            Order.prototype.source = 0;

            /**
             * Order finalPrice.
             * @member {string} finalPrice
             * @memberof fastcity.admin.Order
             * @instance
             */
            Order.prototype.finalPrice = "";

            /**
             * Order paymentType.
             * @member {fastcity.common.Order.PaymentType} paymentType
             * @memberof fastcity.admin.Order
             * @instance
             */
            Order.prototype.paymentType = 0;

            /**
             * Order clientPhone.
             * @member {string} clientPhone
             * @memberof fastcity.admin.Order
             * @instance
             */
            Order.prototype.clientPhone = "";

            /**
             * Order clientName.
             * @member {string} clientName
             * @memberof fastcity.admin.Order
             * @instance
             */
            Order.prototype.clientName = "";

            /**
             * Order clientAddress.
             * @member {string} clientAddress
             * @memberof fastcity.admin.Order
             * @instance
             */
            Order.prototype.clientAddress = "";

            /**
             * Order comments.
             * @member {string} comments
             * @memberof fastcity.admin.Order
             * @instance
             */
            Order.prototype.comments = "";

            /**
             * Order orderItem.
             * @member {Array.<fastcity.admin.IOrderItem>} orderItem
             * @memberof fastcity.admin.Order
             * @instance
             */
            Order.prototype.orderItem = $util.emptyArray;

            /**
             * Order coordinate.
             * @member {fastcity.common.ICoordinate|null|undefined} coordinate
             * @memberof fastcity.admin.Order
             * @instance
             */
            Order.prototype.coordinate = null;

            /**
             * Order currencySymbol.
             * @member {string} currencySymbol
             * @memberof fastcity.admin.Order
             * @instance
             */
            Order.prototype.currencySymbol = "";

            /**
             * Order timestampCancel.
             * @member {number|Long} timestampCancel
             * @memberof fastcity.admin.Order
             * @instance
             */
            Order.prototype.timestampCancel = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Order clientRoom.
             * @member {string} clientRoom
             * @memberof fastcity.admin.Order
             * @instance
             */
            Order.prototype.clientRoom = "";

            /**
             * Order timestampAccept.
             * @member {number|Long} timestampAccept
             * @memberof fastcity.admin.Order
             * @instance
             */
            Order.prototype.timestampAccept = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Order timestampUpdated.
             * @member {number|Long} timestampUpdated
             * @memberof fastcity.admin.Order
             * @instance
             */
            Order.prototype.timestampUpdated = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Order timestampDeliveryBefore.
             * @member {number|Long} timestampDeliveryBefore
             * @memberof fastcity.admin.Order
             * @instance
             */
            Order.prototype.timestampDeliveryBefore = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Order paymentTypes.
             * @member {Array.<fastcity.common.Order.PaymentType>} paymentTypes
             * @memberof fastcity.admin.Order
             * @instance
             */
            Order.prototype.paymentTypes = $util.emptyArray;

            /**
             * Order commonStatus.
             * @member {fastcity.common.Order.Status|null|undefined} commonStatus
             * @memberof fastcity.admin.Order
             * @instance
             */
            Order.prototype.commonStatus = null;

            /**
             * Order iikoStatus.
             * @member {fastcity.admin.IIKOOrderStatus|null|undefined} iikoStatus
             * @memberof fastcity.admin.Order
             * @instance
             */
            Order.prototype.iikoStatus = null;

            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;

            /**
             * Order status.
             * @member {"commonStatus"|"iikoStatus"|undefined} status
             * @memberof fastcity.admin.Order
             * @instance
             */
            Object.defineProperty(Order.prototype, "status", {
                get: $util.oneOfGetter($oneOfFields = ["commonStatus", "iikoStatus"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Creates a new Order instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.Order
             * @static
             * @param {fastcity.admin.IOrder=} [properties] Properties to set
             * @returns {fastcity.admin.Order} Order instance
             */
            Order.create = function create(properties) {
                return new Order(properties);
            };

            /**
             * Encodes the specified Order message. Does not implicitly {@link fastcity.admin.Order.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.Order
             * @static
             * @param {fastcity.admin.IOrder} message Order message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Order.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.orderId != null && Object.hasOwnProperty.call(message, "orderId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.orderId);
                if (message.externalOrderId != null && Object.hasOwnProperty.call(message, "externalOrderId"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.externalOrderId);
                if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                    writer.uint32(/* id 3, wireType 0 =*/24).uint64(message.companyId);
                if (message.subCompanyId != null && Object.hasOwnProperty.call(message, "subCompanyId"))
                    writer.uint32(/* id 4, wireType 0 =*/32).uint64(message.subCompanyId);
                if (message.courierId != null && Object.hasOwnProperty.call(message, "courierId"))
                    writer.uint32(/* id 5, wireType 0 =*/40).uint64(message.courierId);
                if (message.timestampOpen != null && Object.hasOwnProperty.call(message, "timestampOpen"))
                    writer.uint32(/* id 6, wireType 0 =*/48).int64(message.timestampOpen);
                if (message.timestampClose != null && Object.hasOwnProperty.call(message, "timestampClose"))
                    writer.uint32(/* id 7, wireType 0 =*/56).int64(message.timestampClose);
                if (message.source != null && Object.hasOwnProperty.call(message, "source"))
                    writer.uint32(/* id 8, wireType 0 =*/64).int32(message.source);
                if (message.finalPrice != null && Object.hasOwnProperty.call(message, "finalPrice"))
                    writer.uint32(/* id 9, wireType 2 =*/74).string(message.finalPrice);
                if (message.paymentType != null && Object.hasOwnProperty.call(message, "paymentType"))
                    writer.uint32(/* id 10, wireType 0 =*/80).int32(message.paymentType);
                if (message.clientPhone != null && Object.hasOwnProperty.call(message, "clientPhone"))
                    writer.uint32(/* id 11, wireType 2 =*/90).string(message.clientPhone);
                if (message.clientName != null && Object.hasOwnProperty.call(message, "clientName"))
                    writer.uint32(/* id 12, wireType 2 =*/98).string(message.clientName);
                if (message.clientAddress != null && Object.hasOwnProperty.call(message, "clientAddress"))
                    writer.uint32(/* id 13, wireType 2 =*/106).string(message.clientAddress);
                if (message.comments != null && Object.hasOwnProperty.call(message, "comments"))
                    writer.uint32(/* id 14, wireType 2 =*/114).string(message.comments);
                if (message.orderItem != null && message.orderItem.length)
                    for (var i = 0; i < message.orderItem.length; ++i)
                        $root.fastcity.admin.OrderItem.encode(message.orderItem[i], writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
                if (message.coordinate != null && Object.hasOwnProperty.call(message, "coordinate"))
                    $root.fastcity.common.Coordinate.encode(message.coordinate, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
                if (message.currencySymbol != null && Object.hasOwnProperty.call(message, "currencySymbol"))
                    writer.uint32(/* id 17, wireType 2 =*/138).string(message.currencySymbol);
                if (message.timestampCancel != null && Object.hasOwnProperty.call(message, "timestampCancel"))
                    writer.uint32(/* id 18, wireType 0 =*/144).int64(message.timestampCancel);
                if (message.clientRoom != null && Object.hasOwnProperty.call(message, "clientRoom"))
                    writer.uint32(/* id 19, wireType 2 =*/154).string(message.clientRoom);
                if (message.timestampAccept != null && Object.hasOwnProperty.call(message, "timestampAccept"))
                    writer.uint32(/* id 20, wireType 0 =*/160).int64(message.timestampAccept);
                if (message.timestampUpdated != null && Object.hasOwnProperty.call(message, "timestampUpdated"))
                    writer.uint32(/* id 21, wireType 0 =*/168).int64(message.timestampUpdated);
                if (message.orderIdToPrint != null && Object.hasOwnProperty.call(message, "orderIdToPrint"))
                    writer.uint32(/* id 22, wireType 2 =*/178).string(message.orderIdToPrint);
                if (message.timestampDeliveryBefore != null && Object.hasOwnProperty.call(message, "timestampDeliveryBefore"))
                    writer.uint32(/* id 23, wireType 0 =*/184).int64(message.timestampDeliveryBefore);
                if (message.paymentTypes != null && message.paymentTypes.length) {
                    writer.uint32(/* id 24, wireType 2 =*/194).fork();
                    for (var i = 0; i < message.paymentTypes.length; ++i)
                        writer.int32(message.paymentTypes[i]);
                    writer.ldelim();
                }
                if (message.commonStatus != null && Object.hasOwnProperty.call(message, "commonStatus"))
                    writer.uint32(/* id 25, wireType 0 =*/200).int32(message.commonStatus);
                if (message.iikoStatus != null && Object.hasOwnProperty.call(message, "iikoStatus"))
                    writer.uint32(/* id 26, wireType 0 =*/208).int32(message.iikoStatus);
                return writer;
            };

            /**
             * Decodes an Order message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.Order
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.Order} Order
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Order.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.Order();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.orderId = reader.uint64();
                        break;
                    case 22:
                        message.orderIdToPrint = reader.string();
                        break;
                    case 2:
                        message.externalOrderId = reader.string();
                        break;
                    case 3:
                        message.companyId = reader.uint64();
                        break;
                    case 4:
                        message.subCompanyId = reader.uint64();
                        break;
                    case 5:
                        message.courierId = reader.uint64();
                        break;
                    case 6:
                        message.timestampOpen = reader.int64();
                        break;
                    case 7:
                        message.timestampClose = reader.int64();
                        break;
                    case 8:
                        message.source = reader.int32();
                        break;
                    case 9:
                        message.finalPrice = reader.string();
                        break;
                    case 10:
                        message.paymentType = reader.int32();
                        break;
                    case 11:
                        message.clientPhone = reader.string();
                        break;
                    case 12:
                        message.clientName = reader.string();
                        break;
                    case 13:
                        message.clientAddress = reader.string();
                        break;
                    case 14:
                        message.comments = reader.string();
                        break;
                    case 15:
                        if (!(message.orderItem && message.orderItem.length))
                            message.orderItem = [];
                        message.orderItem.push($root.fastcity.admin.OrderItem.decode(reader, reader.uint32()));
                        break;
                    case 16:
                        message.coordinate = $root.fastcity.common.Coordinate.decode(reader, reader.uint32());
                        break;
                    case 17:
                        message.currencySymbol = reader.string();
                        break;
                    case 18:
                        message.timestampCancel = reader.int64();
                        break;
                    case 19:
                        message.clientRoom = reader.string();
                        break;
                    case 20:
                        message.timestampAccept = reader.int64();
                        break;
                    case 21:
                        message.timestampUpdated = reader.int64();
                        break;
                    case 23:
                        message.timestampDeliveryBefore = reader.int64();
                        break;
                    case 24:
                        if (!(message.paymentTypes && message.paymentTypes.length))
                            message.paymentTypes = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.paymentTypes.push(reader.int32());
                        } else
                            message.paymentTypes.push(reader.int32());
                        break;
                    case 25:
                        message.commonStatus = reader.int32();
                        break;
                    case 26:
                        message.iikoStatus = reader.int32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an Order message.
             * @function verify
             * @memberof fastcity.admin.Order
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Order.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                var properties = {};
                if (message.orderId != null && message.hasOwnProperty("orderId"))
                    if (!$util.isInteger(message.orderId) && !(message.orderId && $util.isInteger(message.orderId.low) && $util.isInteger(message.orderId.high)))
                        return "orderId: integer|Long expected";
                if (message.orderIdToPrint != null && message.hasOwnProperty("orderIdToPrint"))
                    if (!$util.isString(message.orderIdToPrint))
                        return "orderIdToPrint: string expected";
                if (message.externalOrderId != null && message.hasOwnProperty("externalOrderId"))
                    if (!$util.isString(message.externalOrderId))
                        return "externalOrderId: string expected";
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                        return "companyId: integer|Long expected";
                if (message.subCompanyId != null && message.hasOwnProperty("subCompanyId"))
                    if (!$util.isInteger(message.subCompanyId) && !(message.subCompanyId && $util.isInteger(message.subCompanyId.low) && $util.isInteger(message.subCompanyId.high)))
                        return "subCompanyId: integer|Long expected";
                if (message.courierId != null && message.hasOwnProperty("courierId"))
                    if (!$util.isInteger(message.courierId) && !(message.courierId && $util.isInteger(message.courierId.low) && $util.isInteger(message.courierId.high)))
                        return "courierId: integer|Long expected";
                if (message.timestampOpen != null && message.hasOwnProperty("timestampOpen"))
                    if (!$util.isInteger(message.timestampOpen) && !(message.timestampOpen && $util.isInteger(message.timestampOpen.low) && $util.isInteger(message.timestampOpen.high)))
                        return "timestampOpen: integer|Long expected";
                if (message.timestampClose != null && message.hasOwnProperty("timestampClose"))
                    if (!$util.isInteger(message.timestampClose) && !(message.timestampClose && $util.isInteger(message.timestampClose.low) && $util.isInteger(message.timestampClose.high)))
                        return "timestampClose: integer|Long expected";
                if (message.source != null && message.hasOwnProperty("source"))
                    switch (message.source) {
                    default:
                        return "source: enum value expected";
                    case 0:
                    case 1:
                        break;
                    }
                if (message.finalPrice != null && message.hasOwnProperty("finalPrice"))
                    if (!$util.isString(message.finalPrice))
                        return "finalPrice: string expected";
                if (message.paymentType != null && message.hasOwnProperty("paymentType"))
                    switch (message.paymentType) {
                    default:
                        return "paymentType: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                    case 9:
                    case 10:
                    case 11:
                    case 12:
                    case 13:
                        break;
                    }
                if (message.clientPhone != null && message.hasOwnProperty("clientPhone"))
                    if (!$util.isString(message.clientPhone))
                        return "clientPhone: string expected";
                if (message.clientName != null && message.hasOwnProperty("clientName"))
                    if (!$util.isString(message.clientName))
                        return "clientName: string expected";
                if (message.clientAddress != null && message.hasOwnProperty("clientAddress"))
                    if (!$util.isString(message.clientAddress))
                        return "clientAddress: string expected";
                if (message.comments != null && message.hasOwnProperty("comments"))
                    if (!$util.isString(message.comments))
                        return "comments: string expected";
                if (message.orderItem != null && message.hasOwnProperty("orderItem")) {
                    if (!Array.isArray(message.orderItem))
                        return "orderItem: array expected";
                    for (var i = 0; i < message.orderItem.length; ++i) {
                        var error = $root.fastcity.admin.OrderItem.verify(message.orderItem[i]);
                        if (error)
                            return "orderItem." + error;
                    }
                }
                if (message.coordinate != null && message.hasOwnProperty("coordinate")) {
                    var error = $root.fastcity.common.Coordinate.verify(message.coordinate);
                    if (error)
                        return "coordinate." + error;
                }
                if (message.currencySymbol != null && message.hasOwnProperty("currencySymbol"))
                    if (!$util.isString(message.currencySymbol))
                        return "currencySymbol: string expected";
                if (message.timestampCancel != null && message.hasOwnProperty("timestampCancel"))
                    if (!$util.isInteger(message.timestampCancel) && !(message.timestampCancel && $util.isInteger(message.timestampCancel.low) && $util.isInteger(message.timestampCancel.high)))
                        return "timestampCancel: integer|Long expected";
                if (message.clientRoom != null && message.hasOwnProperty("clientRoom"))
                    if (!$util.isString(message.clientRoom))
                        return "clientRoom: string expected";
                if (message.timestampAccept != null && message.hasOwnProperty("timestampAccept"))
                    if (!$util.isInteger(message.timestampAccept) && !(message.timestampAccept && $util.isInteger(message.timestampAccept.low) && $util.isInteger(message.timestampAccept.high)))
                        return "timestampAccept: integer|Long expected";
                if (message.timestampUpdated != null && message.hasOwnProperty("timestampUpdated"))
                    if (!$util.isInteger(message.timestampUpdated) && !(message.timestampUpdated && $util.isInteger(message.timestampUpdated.low) && $util.isInteger(message.timestampUpdated.high)))
                        return "timestampUpdated: integer|Long expected";
                if (message.timestampDeliveryBefore != null && message.hasOwnProperty("timestampDeliveryBefore"))
                    if (!$util.isInteger(message.timestampDeliveryBefore) && !(message.timestampDeliveryBefore && $util.isInteger(message.timestampDeliveryBefore.low) && $util.isInteger(message.timestampDeliveryBefore.high)))
                        return "timestampDeliveryBefore: integer|Long expected";
                if (message.paymentTypes != null && message.hasOwnProperty("paymentTypes")) {
                    if (!Array.isArray(message.paymentTypes))
                        return "paymentTypes: array expected";
                    for (var i = 0; i < message.paymentTypes.length; ++i)
                        switch (message.paymentTypes[i]) {
                        default:
                            return "paymentTypes: enum value[] expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                        case 9:
                        case 10:
                        case 11:
                        case 12:
                        case 13:
                            break;
                        }
                }
                if (message.commonStatus != null && message.hasOwnProperty("commonStatus")) {
                    properties.status = 1;
                    switch (message.commonStatus) {
                    default:
                        return "commonStatus: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                    case 9:
                    case 10:
                    case 11:
                    case 12:
                    case 13:
                    case 14:
                    case 15:
                    case 16:
                        break;
                    }
                }
                if (message.iikoStatus != null && message.hasOwnProperty("iikoStatus")) {
                    if (properties.status === 1)
                        return "status: multiple values";
                    properties.status = 1;
                    switch (message.iikoStatus) {
                    default:
                        return "iikoStatus: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                    case 9:
                        break;
                    }
                }
                return null;
            };

            /**
             * Creates an Order message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.Order
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.Order} Order
             */
            Order.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.Order)
                    return object;
                var message = new $root.fastcity.admin.Order();
                if (object.orderId != null)
                    if ($util.Long)
                        (message.orderId = $util.Long.fromValue(object.orderId)).unsigned = true;
                    else if (typeof object.orderId === "string")
                        message.orderId = parseInt(object.orderId, 10);
                    else if (typeof object.orderId === "number")
                        message.orderId = object.orderId;
                    else if (typeof object.orderId === "object")
                        message.orderId = new $util.LongBits(object.orderId.low >>> 0, object.orderId.high >>> 0).toNumber(true);
                if (object.orderIdToPrint != null)
                    message.orderIdToPrint = String(object.orderIdToPrint);
                if (object.externalOrderId != null)
                    message.externalOrderId = String(object.externalOrderId);
                if (object.companyId != null)
                    if ($util.Long)
                        (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = true;
                    else if (typeof object.companyId === "string")
                        message.companyId = parseInt(object.companyId, 10);
                    else if (typeof object.companyId === "number")
                        message.companyId = object.companyId;
                    else if (typeof object.companyId === "object")
                        message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber(true);
                if (object.subCompanyId != null)
                    if ($util.Long)
                        (message.subCompanyId = $util.Long.fromValue(object.subCompanyId)).unsigned = true;
                    else if (typeof object.subCompanyId === "string")
                        message.subCompanyId = parseInt(object.subCompanyId, 10);
                    else if (typeof object.subCompanyId === "number")
                        message.subCompanyId = object.subCompanyId;
                    else if (typeof object.subCompanyId === "object")
                        message.subCompanyId = new $util.LongBits(object.subCompanyId.low >>> 0, object.subCompanyId.high >>> 0).toNumber(true);
                if (object.courierId != null)
                    if ($util.Long)
                        (message.courierId = $util.Long.fromValue(object.courierId)).unsigned = true;
                    else if (typeof object.courierId === "string")
                        message.courierId = parseInt(object.courierId, 10);
                    else if (typeof object.courierId === "number")
                        message.courierId = object.courierId;
                    else if (typeof object.courierId === "object")
                        message.courierId = new $util.LongBits(object.courierId.low >>> 0, object.courierId.high >>> 0).toNumber(true);
                if (object.timestampOpen != null)
                    if ($util.Long)
                        (message.timestampOpen = $util.Long.fromValue(object.timestampOpen)).unsigned = false;
                    else if (typeof object.timestampOpen === "string")
                        message.timestampOpen = parseInt(object.timestampOpen, 10);
                    else if (typeof object.timestampOpen === "number")
                        message.timestampOpen = object.timestampOpen;
                    else if (typeof object.timestampOpen === "object")
                        message.timestampOpen = new $util.LongBits(object.timestampOpen.low >>> 0, object.timestampOpen.high >>> 0).toNumber();
                if (object.timestampClose != null)
                    if ($util.Long)
                        (message.timestampClose = $util.Long.fromValue(object.timestampClose)).unsigned = false;
                    else if (typeof object.timestampClose === "string")
                        message.timestampClose = parseInt(object.timestampClose, 10);
                    else if (typeof object.timestampClose === "number")
                        message.timestampClose = object.timestampClose;
                    else if (typeof object.timestampClose === "object")
                        message.timestampClose = new $util.LongBits(object.timestampClose.low >>> 0, object.timestampClose.high >>> 0).toNumber();
                switch (object.source) {
                case "UNKNOWN":
                case 0:
                    message.source = 0;
                    break;
                case "IIKO":
                case 1:
                    message.source = 1;
                    break;
                }
                if (object.finalPrice != null)
                    message.finalPrice = String(object.finalPrice);
                switch (object.paymentType) {
                case "T_UNKNOWN":
                case 0:
                    message.paymentType = 0;
                    break;
                case "T_PAID":
                case 1:
                    message.paymentType = 1;
                    break;
                case "T_BY_CASH_TO_THE_COURIER":
                case 2:
                    message.paymentType = 2;
                    break;
                case "T_BY_CARD_TO_THE_COURIER":
                case 3:
                    message.paymentType = 3;
                    break;
                case "T_PAID_BY_CASH":
                case 4:
                    message.paymentType = 4;
                    break;
                case "T_PAID_BY_CARD":
                case 5:
                    message.paymentType = 5;
                    break;
                case "T_PAID_BY_CHECK":
                case 6:
                    message.paymentType = 6;
                    break;
                case "T_PAID_BY_CREDIT":
                case 7:
                    message.paymentType = 7;
                    break;
                case "T_PAID_WRITEOFF":
                case 8:
                    message.paymentType = 8;
                    break;
                case "T_PAID_BY_VOUCHER":
                case 9:
                    message.paymentType = 9;
                    break;
                case "T_PAID_FROM_EXTERNAL":
                case 10:
                    message.paymentType = 10;
                    break;
                case "T_PAID_SMART_SALE":
                case 11:
                    message.paymentType = 11;
                    break;
                case "T_PAID_BY_SBERBANK":
                case 12:
                    message.paymentType = 12;
                    break;
                case "T_PAID_BY_TRPOS":
                case 13:
                    message.paymentType = 13;
                    break;
                }
                if (object.clientPhone != null)
                    message.clientPhone = String(object.clientPhone);
                if (object.clientName != null)
                    message.clientName = String(object.clientName);
                if (object.clientAddress != null)
                    message.clientAddress = String(object.clientAddress);
                if (object.comments != null)
                    message.comments = String(object.comments);
                if (object.orderItem) {
                    if (!Array.isArray(object.orderItem))
                        throw TypeError(".fastcity.admin.Order.orderItem: array expected");
                    message.orderItem = [];
                    for (var i = 0; i < object.orderItem.length; ++i) {
                        if (typeof object.orderItem[i] !== "object")
                            throw TypeError(".fastcity.admin.Order.orderItem: object expected");
                        message.orderItem[i] = $root.fastcity.admin.OrderItem.fromObject(object.orderItem[i]);
                    }
                }
                if (object.coordinate != null) {
                    if (typeof object.coordinate !== "object")
                        throw TypeError(".fastcity.admin.Order.coordinate: object expected");
                    message.coordinate = $root.fastcity.common.Coordinate.fromObject(object.coordinate);
                }
                if (object.currencySymbol != null)
                    message.currencySymbol = String(object.currencySymbol);
                if (object.timestampCancel != null)
                    if ($util.Long)
                        (message.timestampCancel = $util.Long.fromValue(object.timestampCancel)).unsigned = false;
                    else if (typeof object.timestampCancel === "string")
                        message.timestampCancel = parseInt(object.timestampCancel, 10);
                    else if (typeof object.timestampCancel === "number")
                        message.timestampCancel = object.timestampCancel;
                    else if (typeof object.timestampCancel === "object")
                        message.timestampCancel = new $util.LongBits(object.timestampCancel.low >>> 0, object.timestampCancel.high >>> 0).toNumber();
                if (object.clientRoom != null)
                    message.clientRoom = String(object.clientRoom);
                if (object.timestampAccept != null)
                    if ($util.Long)
                        (message.timestampAccept = $util.Long.fromValue(object.timestampAccept)).unsigned = false;
                    else if (typeof object.timestampAccept === "string")
                        message.timestampAccept = parseInt(object.timestampAccept, 10);
                    else if (typeof object.timestampAccept === "number")
                        message.timestampAccept = object.timestampAccept;
                    else if (typeof object.timestampAccept === "object")
                        message.timestampAccept = new $util.LongBits(object.timestampAccept.low >>> 0, object.timestampAccept.high >>> 0).toNumber();
                if (object.timestampUpdated != null)
                    if ($util.Long)
                        (message.timestampUpdated = $util.Long.fromValue(object.timestampUpdated)).unsigned = false;
                    else if (typeof object.timestampUpdated === "string")
                        message.timestampUpdated = parseInt(object.timestampUpdated, 10);
                    else if (typeof object.timestampUpdated === "number")
                        message.timestampUpdated = object.timestampUpdated;
                    else if (typeof object.timestampUpdated === "object")
                        message.timestampUpdated = new $util.LongBits(object.timestampUpdated.low >>> 0, object.timestampUpdated.high >>> 0).toNumber();
                if (object.timestampDeliveryBefore != null)
                    if ($util.Long)
                        (message.timestampDeliveryBefore = $util.Long.fromValue(object.timestampDeliveryBefore)).unsigned = false;
                    else if (typeof object.timestampDeliveryBefore === "string")
                        message.timestampDeliveryBefore = parseInt(object.timestampDeliveryBefore, 10);
                    else if (typeof object.timestampDeliveryBefore === "number")
                        message.timestampDeliveryBefore = object.timestampDeliveryBefore;
                    else if (typeof object.timestampDeliveryBefore === "object")
                        message.timestampDeliveryBefore = new $util.LongBits(object.timestampDeliveryBefore.low >>> 0, object.timestampDeliveryBefore.high >>> 0).toNumber();
                if (object.paymentTypes) {
                    if (!Array.isArray(object.paymentTypes))
                        throw TypeError(".fastcity.admin.Order.paymentTypes: array expected");
                    message.paymentTypes = [];
                    for (var i = 0; i < object.paymentTypes.length; ++i)
                        switch (object.paymentTypes[i]) {
                        default:
                        case "T_UNKNOWN":
                        case 0:
                            message.paymentTypes[i] = 0;
                            break;
                        case "T_PAID":
                        case 1:
                            message.paymentTypes[i] = 1;
                            break;
                        case "T_BY_CASH_TO_THE_COURIER":
                        case 2:
                            message.paymentTypes[i] = 2;
                            break;
                        case "T_BY_CARD_TO_THE_COURIER":
                        case 3:
                            message.paymentTypes[i] = 3;
                            break;
                        case "T_PAID_BY_CASH":
                        case 4:
                            message.paymentTypes[i] = 4;
                            break;
                        case "T_PAID_BY_CARD":
                        case 5:
                            message.paymentTypes[i] = 5;
                            break;
                        case "T_PAID_BY_CHECK":
                        case 6:
                            message.paymentTypes[i] = 6;
                            break;
                        case "T_PAID_BY_CREDIT":
                        case 7:
                            message.paymentTypes[i] = 7;
                            break;
                        case "T_PAID_WRITEOFF":
                        case 8:
                            message.paymentTypes[i] = 8;
                            break;
                        case "T_PAID_BY_VOUCHER":
                        case 9:
                            message.paymentTypes[i] = 9;
                            break;
                        case "T_PAID_FROM_EXTERNAL":
                        case 10:
                            message.paymentTypes[i] = 10;
                            break;
                        case "T_PAID_SMART_SALE":
                        case 11:
                            message.paymentTypes[i] = 11;
                            break;
                        case "T_PAID_BY_SBERBANK":
                        case 12:
                            message.paymentTypes[i] = 12;
                            break;
                        case "T_PAID_BY_TRPOS":
                        case 13:
                            message.paymentTypes[i] = 13;
                            break;
                        }
                }
                switch (object.commonStatus) {
                case "S_UNKNOWN":
                case 0:
                    message.commonStatus = 0;
                    break;
                case "S_IN_PROGRESS":
                case 1:
                    message.commonStatus = 1;
                    break;
                case "S_MAKELINE":
                case 2:
                    message.commonStatus = 2;
                    break;
                case "S_ROUTING_STATION":
                case 3:
                    message.commonStatus = 3;
                    break;
                case "S_OUT_THE_DOOR":
                case 4:
                    message.commonStatus = 4;
                    break;
                case "S_COMPLETE":
                case 5:
                    message.commonStatus = 5;
                    break;
                case "S_GIFT":
                case 6:
                    message.commonStatus = 6;
                    break;
                case "S_BAD":
                case 7:
                    message.commonStatus = 7;
                    break;
                case "S_VOID":
                case 8:
                    message.commonStatus = 8;
                    break;
                case "S_BEING_TAKEN":
                case 9:
                    message.commonStatus = 9;
                    break;
                case "S_ABANDONED":
                case 10:
                    message.commonStatus = 10;
                    break;
                case "S_SUSPENDED":
                case 11:
                    message.commonStatus = 11;
                    break;
                case "S_GIFT_CARD_PURCHASE":
                case 12:
                    message.commonStatus = 12;
                    break;
                case "S_GIFT_CARD_VOID":
                case 13:
                    message.commonStatus = 13;
                    break;
                case "S_TRANSFERRED":
                case 14:
                    message.commonStatus = 14;
                    break;
                case "S_HISTORIC_ORDER":
                case 15:
                    message.commonStatus = 15;
                    break;
                case "S_BEING_ASSIGNED":
                case 16:
                    message.commonStatus = 16;
                    break;
                }
                switch (object.iikoStatus) {
                case "UNCONFIRMED":
                case 0:
                    message.iikoStatus = 0;
                    break;
                case "WAIT_COOKING":
                case 1:
                    message.iikoStatus = 1;
                    break;
                case "READY_FOR_COOKING":
                case 2:
                    message.iikoStatus = 2;
                    break;
                case "COOKING_STARTED":
                case 3:
                    message.iikoStatus = 3;
                    break;
                case "COOKING_COMPLETED":
                case 4:
                    message.iikoStatus = 4;
                    break;
                case "WAITING":
                case 5:
                    message.iikoStatus = 5;
                    break;
                case "ON_WAY":
                case 6:
                    message.iikoStatus = 6;
                    break;
                case "DELIVERED":
                case 7:
                    message.iikoStatus = 7;
                    break;
                case "CLOSED":
                case 8:
                    message.iikoStatus = 8;
                    break;
                case "CANCELLED":
                case 9:
                    message.iikoStatus = 9;
                    break;
                }
                return message;
            };

            /**
             * Creates a plain object from an Order message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.Order
             * @static
             * @param {fastcity.admin.Order} message Order
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Order.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults) {
                    object.orderItem = [];
                    object.paymentTypes = [];
                }
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.orderId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.orderId = options.longs === String ? "0" : 0;
                    object.externalOrderId = "";
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.companyId = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.subCompanyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.subCompanyId = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.courierId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.courierId = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.timestampOpen = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.timestampOpen = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.timestampClose = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.timestampClose = options.longs === String ? "0" : 0;
                    object.source = options.enums === String ? "UNKNOWN" : 0;
                    object.finalPrice = "";
                    object.paymentType = options.enums === String ? "T_UNKNOWN" : 0;
                    object.clientPhone = "";
                    object.clientName = "";
                    object.clientAddress = "";
                    object.comments = "";
                    object.coordinate = null;
                    object.currencySymbol = "";
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.timestampCancel = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.timestampCancel = options.longs === String ? "0" : 0;
                    object.clientRoom = "";
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.timestampAccept = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.timestampAccept = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.timestampUpdated = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.timestampUpdated = options.longs === String ? "0" : 0;
                    object.orderIdToPrint = "";
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.timestampDeliveryBefore = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.timestampDeliveryBefore = options.longs === String ? "0" : 0;
                }
                if (message.orderId != null && message.hasOwnProperty("orderId"))
                    if (typeof message.orderId === "number")
                        object.orderId = options.longs === String ? String(message.orderId) : message.orderId;
                    else
                        object.orderId = options.longs === String ? $util.Long.prototype.toString.call(message.orderId) : options.longs === Number ? new $util.LongBits(message.orderId.low >>> 0, message.orderId.high >>> 0).toNumber(true) : message.orderId;
                if (message.externalOrderId != null && message.hasOwnProperty("externalOrderId"))
                    object.externalOrderId = message.externalOrderId;
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (typeof message.companyId === "number")
                        object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                    else
                        object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber(true) : message.companyId;
                if (message.subCompanyId != null && message.hasOwnProperty("subCompanyId"))
                    if (typeof message.subCompanyId === "number")
                        object.subCompanyId = options.longs === String ? String(message.subCompanyId) : message.subCompanyId;
                    else
                        object.subCompanyId = options.longs === String ? $util.Long.prototype.toString.call(message.subCompanyId) : options.longs === Number ? new $util.LongBits(message.subCompanyId.low >>> 0, message.subCompanyId.high >>> 0).toNumber(true) : message.subCompanyId;
                if (message.courierId != null && message.hasOwnProperty("courierId"))
                    if (typeof message.courierId === "number")
                        object.courierId = options.longs === String ? String(message.courierId) : message.courierId;
                    else
                        object.courierId = options.longs === String ? $util.Long.prototype.toString.call(message.courierId) : options.longs === Number ? new $util.LongBits(message.courierId.low >>> 0, message.courierId.high >>> 0).toNumber(true) : message.courierId;
                if (message.timestampOpen != null && message.hasOwnProperty("timestampOpen"))
                    if (typeof message.timestampOpen === "number")
                        object.timestampOpen = options.longs === String ? String(message.timestampOpen) : message.timestampOpen;
                    else
                        object.timestampOpen = options.longs === String ? $util.Long.prototype.toString.call(message.timestampOpen) : options.longs === Number ? new $util.LongBits(message.timestampOpen.low >>> 0, message.timestampOpen.high >>> 0).toNumber() : message.timestampOpen;
                if (message.timestampClose != null && message.hasOwnProperty("timestampClose"))
                    if (typeof message.timestampClose === "number")
                        object.timestampClose = options.longs === String ? String(message.timestampClose) : message.timestampClose;
                    else
                        object.timestampClose = options.longs === String ? $util.Long.prototype.toString.call(message.timestampClose) : options.longs === Number ? new $util.LongBits(message.timestampClose.low >>> 0, message.timestampClose.high >>> 0).toNumber() : message.timestampClose;
                if (message.source != null && message.hasOwnProperty("source"))
                    object.source = options.enums === String ? $root.fastcity.common.Source[message.source] : message.source;
                if (message.finalPrice != null && message.hasOwnProperty("finalPrice"))
                    object.finalPrice = message.finalPrice;
                if (message.paymentType != null && message.hasOwnProperty("paymentType"))
                    object.paymentType = options.enums === String ? $root.fastcity.common.Order.PaymentType[message.paymentType] : message.paymentType;
                if (message.clientPhone != null && message.hasOwnProperty("clientPhone"))
                    object.clientPhone = message.clientPhone;
                if (message.clientName != null && message.hasOwnProperty("clientName"))
                    object.clientName = message.clientName;
                if (message.clientAddress != null && message.hasOwnProperty("clientAddress"))
                    object.clientAddress = message.clientAddress;
                if (message.comments != null && message.hasOwnProperty("comments"))
                    object.comments = message.comments;
                if (message.orderItem && message.orderItem.length) {
                    object.orderItem = [];
                    for (var j = 0; j < message.orderItem.length; ++j)
                        object.orderItem[j] = $root.fastcity.admin.OrderItem.toObject(message.orderItem[j], options);
                }
                if (message.coordinate != null && message.hasOwnProperty("coordinate"))
                    object.coordinate = $root.fastcity.common.Coordinate.toObject(message.coordinate, options);
                if (message.currencySymbol != null && message.hasOwnProperty("currencySymbol"))
                    object.currencySymbol = message.currencySymbol;
                if (message.timestampCancel != null && message.hasOwnProperty("timestampCancel"))
                    if (typeof message.timestampCancel === "number")
                        object.timestampCancel = options.longs === String ? String(message.timestampCancel) : message.timestampCancel;
                    else
                        object.timestampCancel = options.longs === String ? $util.Long.prototype.toString.call(message.timestampCancel) : options.longs === Number ? new $util.LongBits(message.timestampCancel.low >>> 0, message.timestampCancel.high >>> 0).toNumber() : message.timestampCancel;
                if (message.clientRoom != null && message.hasOwnProperty("clientRoom"))
                    object.clientRoom = message.clientRoom;
                if (message.timestampAccept != null && message.hasOwnProperty("timestampAccept"))
                    if (typeof message.timestampAccept === "number")
                        object.timestampAccept = options.longs === String ? String(message.timestampAccept) : message.timestampAccept;
                    else
                        object.timestampAccept = options.longs === String ? $util.Long.prototype.toString.call(message.timestampAccept) : options.longs === Number ? new $util.LongBits(message.timestampAccept.low >>> 0, message.timestampAccept.high >>> 0).toNumber() : message.timestampAccept;
                if (message.timestampUpdated != null && message.hasOwnProperty("timestampUpdated"))
                    if (typeof message.timestampUpdated === "number")
                        object.timestampUpdated = options.longs === String ? String(message.timestampUpdated) : message.timestampUpdated;
                    else
                        object.timestampUpdated = options.longs === String ? $util.Long.prototype.toString.call(message.timestampUpdated) : options.longs === Number ? new $util.LongBits(message.timestampUpdated.low >>> 0, message.timestampUpdated.high >>> 0).toNumber() : message.timestampUpdated;
                if (message.orderIdToPrint != null && message.hasOwnProperty("orderIdToPrint"))
                    object.orderIdToPrint = message.orderIdToPrint;
                if (message.timestampDeliveryBefore != null && message.hasOwnProperty("timestampDeliveryBefore"))
                    if (typeof message.timestampDeliveryBefore === "number")
                        object.timestampDeliveryBefore = options.longs === String ? String(message.timestampDeliveryBefore) : message.timestampDeliveryBefore;
                    else
                        object.timestampDeliveryBefore = options.longs === String ? $util.Long.prototype.toString.call(message.timestampDeliveryBefore) : options.longs === Number ? new $util.LongBits(message.timestampDeliveryBefore.low >>> 0, message.timestampDeliveryBefore.high >>> 0).toNumber() : message.timestampDeliveryBefore;
                if (message.paymentTypes && message.paymentTypes.length) {
                    object.paymentTypes = [];
                    for (var j = 0; j < message.paymentTypes.length; ++j)
                        object.paymentTypes[j] = options.enums === String ? $root.fastcity.common.Order.PaymentType[message.paymentTypes[j]] : message.paymentTypes[j];
                }
                if (message.commonStatus != null && message.hasOwnProperty("commonStatus")) {
                    object.commonStatus = options.enums === String ? $root.fastcity.common.Order.Status[message.commonStatus] : message.commonStatus;
                    if (options.oneofs)
                        object.status = "commonStatus";
                }
                if (message.iikoStatus != null && message.hasOwnProperty("iikoStatus")) {
                    object.iikoStatus = options.enums === String ? $root.fastcity.admin.IIKOOrderStatus[message.iikoStatus] : message.iikoStatus;
                    if (options.oneofs)
                        object.status = "iikoStatus";
                }
                return object;
            };

            /**
             * Converts this Order to JSON.
             * @function toJSON
             * @memberof fastcity.admin.Order
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Order.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Order;
        })();

        admin.GetOrderList = (function() {

            /**
             * Properties of a GetOrderList.
             * @memberof fastcity.admin
             * @interface IGetOrderList
             * @property {Array.<number|Long>|null} [orderId] GetOrderList orderId
             * @property {number|Long|null} [companyId] GetOrderList companyId
             * @property {Array.<number|Long>|null} [courierId] GetOrderList courierId
             * @property {fastcity.common.Source|null} [source] GetOrderList source
             * @property {number|Long|null} [fromTime] GetOrderList fromTime
             * @property {number|Long|null} [toTime] GetOrderList toTime
             * @property {number|null} [offset] GetOrderList offset
             * @property {number|null} [limit] GetOrderList limit
             * @property {fastcity.common.SortOrder.Order|null} [sortOrder] GetOrderList sortOrder
             */

            /**
             * Constructs a new GetOrderList.
             * @memberof fastcity.admin
             * @classdesc Represents a GetOrderList.
             * @implements IGetOrderList
             * @constructor
             * @param {fastcity.admin.IGetOrderList=} [properties] Properties to set
             */
            function GetOrderList(properties) {
                this.orderId = [];
                this.courierId = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GetOrderList orderId.
             * @member {Array.<number|Long>} orderId
             * @memberof fastcity.admin.GetOrderList
             * @instance
             */
            GetOrderList.prototype.orderId = $util.emptyArray;

            /**
             * GetOrderList companyId.
             * @member {number|Long} companyId
             * @memberof fastcity.admin.GetOrderList
             * @instance
             */
            GetOrderList.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * GetOrderList courierId.
             * @member {Array.<number|Long>} courierId
             * @memberof fastcity.admin.GetOrderList
             * @instance
             */
            GetOrderList.prototype.courierId = $util.emptyArray;

            /**
             * GetOrderList source.
             * @member {fastcity.common.Source} source
             * @memberof fastcity.admin.GetOrderList
             * @instance
             */
            GetOrderList.prototype.source = 0;

            /**
             * GetOrderList fromTime.
             * @member {number|Long} fromTime
             * @memberof fastcity.admin.GetOrderList
             * @instance
             */
            GetOrderList.prototype.fromTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * GetOrderList toTime.
             * @member {number|Long} toTime
             * @memberof fastcity.admin.GetOrderList
             * @instance
             */
            GetOrderList.prototype.toTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * GetOrderList offset.
             * @member {number} offset
             * @memberof fastcity.admin.GetOrderList
             * @instance
             */
            GetOrderList.prototype.offset = 0;

            /**
             * GetOrderList limit.
             * @member {number} limit
             * @memberof fastcity.admin.GetOrderList
             * @instance
             */
            GetOrderList.prototype.limit = 0;

            /**
             * GetOrderList sortOrder.
             * @member {fastcity.common.SortOrder.Order} sortOrder
             * @memberof fastcity.admin.GetOrderList
             * @instance
             */
            GetOrderList.prototype.sortOrder = 0;

            /**
             * Creates a new GetOrderList instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.GetOrderList
             * @static
             * @param {fastcity.admin.IGetOrderList=} [properties] Properties to set
             * @returns {fastcity.admin.GetOrderList} GetOrderList instance
             */
            GetOrderList.create = function create(properties) {
                return new GetOrderList(properties);
            };

            /**
             * Encodes the specified GetOrderList message. Does not implicitly {@link fastcity.admin.GetOrderList.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.GetOrderList
             * @static
             * @param {fastcity.admin.IGetOrderList} message GetOrderList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetOrderList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.orderId != null && message.orderId.length) {
                    writer.uint32(/* id 1, wireType 2 =*/10).fork();
                    for (var i = 0; i < message.orderId.length; ++i)
                        writer.uint64(message.orderId[i]);
                    writer.ldelim();
                }
                if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.companyId);
                if (message.courierId != null && message.courierId.length) {
                    writer.uint32(/* id 3, wireType 2 =*/26).fork();
                    for (var i = 0; i < message.courierId.length; ++i)
                        writer.uint64(message.courierId[i]);
                    writer.ldelim();
                }
                if (message.fromTime != null && Object.hasOwnProperty.call(message, "fromTime"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int64(message.fromTime);
                if (message.toTime != null && Object.hasOwnProperty.call(message, "toTime"))
                    writer.uint32(/* id 5, wireType 0 =*/40).int64(message.toTime);
                if (message.offset != null && Object.hasOwnProperty.call(message, "offset"))
                    writer.uint32(/* id 6, wireType 0 =*/48).uint32(message.offset);
                if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                    writer.uint32(/* id 7, wireType 0 =*/56).uint32(message.limit);
                if (message.sortOrder != null && Object.hasOwnProperty.call(message, "sortOrder"))
                    writer.uint32(/* id 8, wireType 0 =*/64).int32(message.sortOrder);
                if (message.source != null && Object.hasOwnProperty.call(message, "source"))
                    writer.uint32(/* id 9, wireType 0 =*/72).int32(message.source);
                return writer;
            };

            /**
             * Decodes a GetOrderList message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.GetOrderList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.GetOrderList} GetOrderList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetOrderList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.GetOrderList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.orderId && message.orderId.length))
                            message.orderId = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.orderId.push(reader.uint64());
                        } else
                            message.orderId.push(reader.uint64());
                        break;
                    case 2:
                        message.companyId = reader.uint64();
                        break;
                    case 3:
                        if (!(message.courierId && message.courierId.length))
                            message.courierId = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.courierId.push(reader.uint64());
                        } else
                            message.courierId.push(reader.uint64());
                        break;
                    case 9:
                        message.source = reader.int32();
                        break;
                    case 4:
                        message.fromTime = reader.int64();
                        break;
                    case 5:
                        message.toTime = reader.int64();
                        break;
                    case 6:
                        message.offset = reader.uint32();
                        break;
                    case 7:
                        message.limit = reader.uint32();
                        break;
                    case 8:
                        message.sortOrder = reader.int32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a GetOrderList message.
             * @function verify
             * @memberof fastcity.admin.GetOrderList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetOrderList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.orderId != null && message.hasOwnProperty("orderId")) {
                    if (!Array.isArray(message.orderId))
                        return "orderId: array expected";
                    for (var i = 0; i < message.orderId.length; ++i)
                        if (!$util.isInteger(message.orderId[i]) && !(message.orderId[i] && $util.isInteger(message.orderId[i].low) && $util.isInteger(message.orderId[i].high)))
                            return "orderId: integer|Long[] expected";
                }
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                        return "companyId: integer|Long expected";
                if (message.courierId != null && message.hasOwnProperty("courierId")) {
                    if (!Array.isArray(message.courierId))
                        return "courierId: array expected";
                    for (var i = 0; i < message.courierId.length; ++i)
                        if (!$util.isInteger(message.courierId[i]) && !(message.courierId[i] && $util.isInteger(message.courierId[i].low) && $util.isInteger(message.courierId[i].high)))
                            return "courierId: integer|Long[] expected";
                }
                if (message.source != null && message.hasOwnProperty("source"))
                    switch (message.source) {
                    default:
                        return "source: enum value expected";
                    case 0:
                    case 1:
                        break;
                    }
                if (message.fromTime != null && message.hasOwnProperty("fromTime"))
                    if (!$util.isInteger(message.fromTime) && !(message.fromTime && $util.isInteger(message.fromTime.low) && $util.isInteger(message.fromTime.high)))
                        return "fromTime: integer|Long expected";
                if (message.toTime != null && message.hasOwnProperty("toTime"))
                    if (!$util.isInteger(message.toTime) && !(message.toTime && $util.isInteger(message.toTime.low) && $util.isInteger(message.toTime.high)))
                        return "toTime: integer|Long expected";
                if (message.offset != null && message.hasOwnProperty("offset"))
                    if (!$util.isInteger(message.offset))
                        return "offset: integer expected";
                if (message.limit != null && message.hasOwnProperty("limit"))
                    if (!$util.isInteger(message.limit))
                        return "limit: integer expected";
                if (message.sortOrder != null && message.hasOwnProperty("sortOrder"))
                    switch (message.sortOrder) {
                    default:
                        return "sortOrder: enum value expected";
                    case 0:
                    case 1:
                        break;
                    }
                return null;
            };

            /**
             * Creates a GetOrderList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.GetOrderList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.GetOrderList} GetOrderList
             */
            GetOrderList.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.GetOrderList)
                    return object;
                var message = new $root.fastcity.admin.GetOrderList();
                if (object.orderId) {
                    if (!Array.isArray(object.orderId))
                        throw TypeError(".fastcity.admin.GetOrderList.orderId: array expected");
                    message.orderId = [];
                    for (var i = 0; i < object.orderId.length; ++i)
                        if ($util.Long)
                            (message.orderId[i] = $util.Long.fromValue(object.orderId[i])).unsigned = true;
                        else if (typeof object.orderId[i] === "string")
                            message.orderId[i] = parseInt(object.orderId[i], 10);
                        else if (typeof object.orderId[i] === "number")
                            message.orderId[i] = object.orderId[i];
                        else if (typeof object.orderId[i] === "object")
                            message.orderId[i] = new $util.LongBits(object.orderId[i].low >>> 0, object.orderId[i].high >>> 0).toNumber(true);
                }
                if (object.companyId != null)
                    if ($util.Long)
                        (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = true;
                    else if (typeof object.companyId === "string")
                        message.companyId = parseInt(object.companyId, 10);
                    else if (typeof object.companyId === "number")
                        message.companyId = object.companyId;
                    else if (typeof object.companyId === "object")
                        message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber(true);
                if (object.courierId) {
                    if (!Array.isArray(object.courierId))
                        throw TypeError(".fastcity.admin.GetOrderList.courierId: array expected");
                    message.courierId = [];
                    for (var i = 0; i < object.courierId.length; ++i)
                        if ($util.Long)
                            (message.courierId[i] = $util.Long.fromValue(object.courierId[i])).unsigned = true;
                        else if (typeof object.courierId[i] === "string")
                            message.courierId[i] = parseInt(object.courierId[i], 10);
                        else if (typeof object.courierId[i] === "number")
                            message.courierId[i] = object.courierId[i];
                        else if (typeof object.courierId[i] === "object")
                            message.courierId[i] = new $util.LongBits(object.courierId[i].low >>> 0, object.courierId[i].high >>> 0).toNumber(true);
                }
                switch (object.source) {
                case "UNKNOWN":
                case 0:
                    message.source = 0;
                    break;
                case "IIKO":
                case 1:
                    message.source = 1;
                    break;
                }
                if (object.fromTime != null)
                    if ($util.Long)
                        (message.fromTime = $util.Long.fromValue(object.fromTime)).unsigned = false;
                    else if (typeof object.fromTime === "string")
                        message.fromTime = parseInt(object.fromTime, 10);
                    else if (typeof object.fromTime === "number")
                        message.fromTime = object.fromTime;
                    else if (typeof object.fromTime === "object")
                        message.fromTime = new $util.LongBits(object.fromTime.low >>> 0, object.fromTime.high >>> 0).toNumber();
                if (object.toTime != null)
                    if ($util.Long)
                        (message.toTime = $util.Long.fromValue(object.toTime)).unsigned = false;
                    else if (typeof object.toTime === "string")
                        message.toTime = parseInt(object.toTime, 10);
                    else if (typeof object.toTime === "number")
                        message.toTime = object.toTime;
                    else if (typeof object.toTime === "object")
                        message.toTime = new $util.LongBits(object.toTime.low >>> 0, object.toTime.high >>> 0).toNumber();
                if (object.offset != null)
                    message.offset = object.offset >>> 0;
                if (object.limit != null)
                    message.limit = object.limit >>> 0;
                switch (object.sortOrder) {
                case "ASC":
                case 0:
                    message.sortOrder = 0;
                    break;
                case "DESC":
                case 1:
                    message.sortOrder = 1;
                    break;
                }
                return message;
            };

            /**
             * Creates a plain object from a GetOrderList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.GetOrderList
             * @static
             * @param {fastcity.admin.GetOrderList} message GetOrderList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetOrderList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults) {
                    object.orderId = [];
                    object.courierId = [];
                }
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.companyId = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.fromTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.fromTime = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.toTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.toTime = options.longs === String ? "0" : 0;
                    object.offset = 0;
                    object.limit = 0;
                    object.sortOrder = options.enums === String ? "ASC" : 0;
                    object.source = options.enums === String ? "UNKNOWN" : 0;
                }
                if (message.orderId && message.orderId.length) {
                    object.orderId = [];
                    for (var j = 0; j < message.orderId.length; ++j)
                        if (typeof message.orderId[j] === "number")
                            object.orderId[j] = options.longs === String ? String(message.orderId[j]) : message.orderId[j];
                        else
                            object.orderId[j] = options.longs === String ? $util.Long.prototype.toString.call(message.orderId[j]) : options.longs === Number ? new $util.LongBits(message.orderId[j].low >>> 0, message.orderId[j].high >>> 0).toNumber(true) : message.orderId[j];
                }
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (typeof message.companyId === "number")
                        object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                    else
                        object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber(true) : message.companyId;
                if (message.courierId && message.courierId.length) {
                    object.courierId = [];
                    for (var j = 0; j < message.courierId.length; ++j)
                        if (typeof message.courierId[j] === "number")
                            object.courierId[j] = options.longs === String ? String(message.courierId[j]) : message.courierId[j];
                        else
                            object.courierId[j] = options.longs === String ? $util.Long.prototype.toString.call(message.courierId[j]) : options.longs === Number ? new $util.LongBits(message.courierId[j].low >>> 0, message.courierId[j].high >>> 0).toNumber(true) : message.courierId[j];
                }
                if (message.fromTime != null && message.hasOwnProperty("fromTime"))
                    if (typeof message.fromTime === "number")
                        object.fromTime = options.longs === String ? String(message.fromTime) : message.fromTime;
                    else
                        object.fromTime = options.longs === String ? $util.Long.prototype.toString.call(message.fromTime) : options.longs === Number ? new $util.LongBits(message.fromTime.low >>> 0, message.fromTime.high >>> 0).toNumber() : message.fromTime;
                if (message.toTime != null && message.hasOwnProperty("toTime"))
                    if (typeof message.toTime === "number")
                        object.toTime = options.longs === String ? String(message.toTime) : message.toTime;
                    else
                        object.toTime = options.longs === String ? $util.Long.prototype.toString.call(message.toTime) : options.longs === Number ? new $util.LongBits(message.toTime.low >>> 0, message.toTime.high >>> 0).toNumber() : message.toTime;
                if (message.offset != null && message.hasOwnProperty("offset"))
                    object.offset = message.offset;
                if (message.limit != null && message.hasOwnProperty("limit"))
                    object.limit = message.limit;
                if (message.sortOrder != null && message.hasOwnProperty("sortOrder"))
                    object.sortOrder = options.enums === String ? $root.fastcity.common.SortOrder.Order[message.sortOrder] : message.sortOrder;
                if (message.source != null && message.hasOwnProperty("source"))
                    object.source = options.enums === String ? $root.fastcity.common.Source[message.source] : message.source;
                return object;
            };

            /**
             * Converts this GetOrderList to JSON.
             * @function toJSON
             * @memberof fastcity.admin.GetOrderList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetOrderList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return GetOrderList;
        })();

        admin.OrderList = (function() {

            /**
             * Properties of an OrderList.
             * @memberof fastcity.admin
             * @interface IOrderList
             * @property {Array.<fastcity.admin.IOrder>|null} [order] OrderList order
             * @property {number|null} [totalOrders] OrderList totalOrders
             */

            /**
             * Constructs a new OrderList.
             * @memberof fastcity.admin
             * @classdesc Represents an OrderList.
             * @implements IOrderList
             * @constructor
             * @param {fastcity.admin.IOrderList=} [properties] Properties to set
             */
            function OrderList(properties) {
                this.order = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * OrderList order.
             * @member {Array.<fastcity.admin.IOrder>} order
             * @memberof fastcity.admin.OrderList
             * @instance
             */
            OrderList.prototype.order = $util.emptyArray;

            /**
             * OrderList totalOrders.
             * @member {number} totalOrders
             * @memberof fastcity.admin.OrderList
             * @instance
             */
            OrderList.prototype.totalOrders = 0;

            /**
             * Creates a new OrderList instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.OrderList
             * @static
             * @param {fastcity.admin.IOrderList=} [properties] Properties to set
             * @returns {fastcity.admin.OrderList} OrderList instance
             */
            OrderList.create = function create(properties) {
                return new OrderList(properties);
            };

            /**
             * Encodes the specified OrderList message. Does not implicitly {@link fastcity.admin.OrderList.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.OrderList
             * @static
             * @param {fastcity.admin.IOrderList} message OrderList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            OrderList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.order != null && message.order.length)
                    for (var i = 0; i < message.order.length; ++i)
                        $root.fastcity.admin.Order.encode(message.order[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.totalOrders != null && Object.hasOwnProperty.call(message, "totalOrders"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.totalOrders);
                return writer;
            };

            /**
             * Decodes an OrderList message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.OrderList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.OrderList} OrderList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            OrderList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.OrderList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.order && message.order.length))
                            message.order = [];
                        message.order.push($root.fastcity.admin.Order.decode(reader, reader.uint32()));
                        break;
                    case 2:
                        message.totalOrders = reader.uint32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an OrderList message.
             * @function verify
             * @memberof fastcity.admin.OrderList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            OrderList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.order != null && message.hasOwnProperty("order")) {
                    if (!Array.isArray(message.order))
                        return "order: array expected";
                    for (var i = 0; i < message.order.length; ++i) {
                        var error = $root.fastcity.admin.Order.verify(message.order[i]);
                        if (error)
                            return "order." + error;
                    }
                }
                if (message.totalOrders != null && message.hasOwnProperty("totalOrders"))
                    if (!$util.isInteger(message.totalOrders))
                        return "totalOrders: integer expected";
                return null;
            };

            /**
             * Creates an OrderList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.OrderList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.OrderList} OrderList
             */
            OrderList.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.OrderList)
                    return object;
                var message = new $root.fastcity.admin.OrderList();
                if (object.order) {
                    if (!Array.isArray(object.order))
                        throw TypeError(".fastcity.admin.OrderList.order: array expected");
                    message.order = [];
                    for (var i = 0; i < object.order.length; ++i) {
                        if (typeof object.order[i] !== "object")
                            throw TypeError(".fastcity.admin.OrderList.order: object expected");
                        message.order[i] = $root.fastcity.admin.Order.fromObject(object.order[i]);
                    }
                }
                if (object.totalOrders != null)
                    message.totalOrders = object.totalOrders >>> 0;
                return message;
            };

            /**
             * Creates a plain object from an OrderList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.OrderList
             * @static
             * @param {fastcity.admin.OrderList} message OrderList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            OrderList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.order = [];
                if (options.defaults)
                    object.totalOrders = 0;
                if (message.order && message.order.length) {
                    object.order = [];
                    for (var j = 0; j < message.order.length; ++j)
                        object.order[j] = $root.fastcity.admin.Order.toObject(message.order[j], options);
                }
                if (message.totalOrders != null && message.hasOwnProperty("totalOrders"))
                    object.totalOrders = message.totalOrders;
                return object;
            };

            /**
             * Converts this OrderList to JSON.
             * @function toJSON
             * @memberof fastcity.admin.OrderList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            OrderList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return OrderList;
        })();

        admin.ChangedOrderIdList = (function() {

            /**
             * Properties of a ChangedOrderIdList.
             * @memberof fastcity.admin
             * @interface IChangedOrderIdList
             * @property {Array.<number|Long>|null} [orderId] ChangedOrderIdList orderId
             * @property {number|Long|null} [companyId] ChangedOrderIdList companyId
             * @property {number|Long|null} [subCompanyId] ChangedOrderIdList subCompanyId
             */

            /**
             * Constructs a new ChangedOrderIdList.
             * @memberof fastcity.admin
             * @classdesc Represents a ChangedOrderIdList.
             * @implements IChangedOrderIdList
             * @constructor
             * @param {fastcity.admin.IChangedOrderIdList=} [properties] Properties to set
             */
            function ChangedOrderIdList(properties) {
                this.orderId = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ChangedOrderIdList orderId.
             * @member {Array.<number|Long>} orderId
             * @memberof fastcity.admin.ChangedOrderIdList
             * @instance
             */
            ChangedOrderIdList.prototype.orderId = $util.emptyArray;

            /**
             * ChangedOrderIdList companyId.
             * @member {number|Long} companyId
             * @memberof fastcity.admin.ChangedOrderIdList
             * @instance
             */
            ChangedOrderIdList.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * ChangedOrderIdList subCompanyId.
             * @member {number|Long} subCompanyId
             * @memberof fastcity.admin.ChangedOrderIdList
             * @instance
             */
            ChangedOrderIdList.prototype.subCompanyId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Creates a new ChangedOrderIdList instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.ChangedOrderIdList
             * @static
             * @param {fastcity.admin.IChangedOrderIdList=} [properties] Properties to set
             * @returns {fastcity.admin.ChangedOrderIdList} ChangedOrderIdList instance
             */
            ChangedOrderIdList.create = function create(properties) {
                return new ChangedOrderIdList(properties);
            };

            /**
             * Encodes the specified ChangedOrderIdList message. Does not implicitly {@link fastcity.admin.ChangedOrderIdList.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.ChangedOrderIdList
             * @static
             * @param {fastcity.admin.IChangedOrderIdList} message ChangedOrderIdList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ChangedOrderIdList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.orderId != null && message.orderId.length) {
                    writer.uint32(/* id 1, wireType 2 =*/10).fork();
                    for (var i = 0; i < message.orderId.length; ++i)
                        writer.uint64(message.orderId[i]);
                    writer.ldelim();
                }
                if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.companyId);
                if (message.subCompanyId != null && Object.hasOwnProperty.call(message, "subCompanyId"))
                    writer.uint32(/* id 3, wireType 0 =*/24).uint64(message.subCompanyId);
                return writer;
            };

            /**
             * Decodes a ChangedOrderIdList message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.ChangedOrderIdList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.ChangedOrderIdList} ChangedOrderIdList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ChangedOrderIdList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.ChangedOrderIdList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.orderId && message.orderId.length))
                            message.orderId = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.orderId.push(reader.uint64());
                        } else
                            message.orderId.push(reader.uint64());
                        break;
                    case 2:
                        message.companyId = reader.uint64();
                        break;
                    case 3:
                        message.subCompanyId = reader.uint64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a ChangedOrderIdList message.
             * @function verify
             * @memberof fastcity.admin.ChangedOrderIdList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ChangedOrderIdList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.orderId != null && message.hasOwnProperty("orderId")) {
                    if (!Array.isArray(message.orderId))
                        return "orderId: array expected";
                    for (var i = 0; i < message.orderId.length; ++i)
                        if (!$util.isInteger(message.orderId[i]) && !(message.orderId[i] && $util.isInteger(message.orderId[i].low) && $util.isInteger(message.orderId[i].high)))
                            return "orderId: integer|Long[] expected";
                }
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                        return "companyId: integer|Long expected";
                if (message.subCompanyId != null && message.hasOwnProperty("subCompanyId"))
                    if (!$util.isInteger(message.subCompanyId) && !(message.subCompanyId && $util.isInteger(message.subCompanyId.low) && $util.isInteger(message.subCompanyId.high)))
                        return "subCompanyId: integer|Long expected";
                return null;
            };

            /**
             * Creates a ChangedOrderIdList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.ChangedOrderIdList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.ChangedOrderIdList} ChangedOrderIdList
             */
            ChangedOrderIdList.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.ChangedOrderIdList)
                    return object;
                var message = new $root.fastcity.admin.ChangedOrderIdList();
                if (object.orderId) {
                    if (!Array.isArray(object.orderId))
                        throw TypeError(".fastcity.admin.ChangedOrderIdList.orderId: array expected");
                    message.orderId = [];
                    for (var i = 0; i < object.orderId.length; ++i)
                        if ($util.Long)
                            (message.orderId[i] = $util.Long.fromValue(object.orderId[i])).unsigned = true;
                        else if (typeof object.orderId[i] === "string")
                            message.orderId[i] = parseInt(object.orderId[i], 10);
                        else if (typeof object.orderId[i] === "number")
                            message.orderId[i] = object.orderId[i];
                        else if (typeof object.orderId[i] === "object")
                            message.orderId[i] = new $util.LongBits(object.orderId[i].low >>> 0, object.orderId[i].high >>> 0).toNumber(true);
                }
                if (object.companyId != null)
                    if ($util.Long)
                        (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = true;
                    else if (typeof object.companyId === "string")
                        message.companyId = parseInt(object.companyId, 10);
                    else if (typeof object.companyId === "number")
                        message.companyId = object.companyId;
                    else if (typeof object.companyId === "object")
                        message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber(true);
                if (object.subCompanyId != null)
                    if ($util.Long)
                        (message.subCompanyId = $util.Long.fromValue(object.subCompanyId)).unsigned = true;
                    else if (typeof object.subCompanyId === "string")
                        message.subCompanyId = parseInt(object.subCompanyId, 10);
                    else if (typeof object.subCompanyId === "number")
                        message.subCompanyId = object.subCompanyId;
                    else if (typeof object.subCompanyId === "object")
                        message.subCompanyId = new $util.LongBits(object.subCompanyId.low >>> 0, object.subCompanyId.high >>> 0).toNumber(true);
                return message;
            };

            /**
             * Creates a plain object from a ChangedOrderIdList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.ChangedOrderIdList
             * @static
             * @param {fastcity.admin.ChangedOrderIdList} message ChangedOrderIdList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ChangedOrderIdList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.orderId = [];
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.companyId = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.subCompanyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.subCompanyId = options.longs === String ? "0" : 0;
                }
                if (message.orderId && message.orderId.length) {
                    object.orderId = [];
                    for (var j = 0; j < message.orderId.length; ++j)
                        if (typeof message.orderId[j] === "number")
                            object.orderId[j] = options.longs === String ? String(message.orderId[j]) : message.orderId[j];
                        else
                            object.orderId[j] = options.longs === String ? $util.Long.prototype.toString.call(message.orderId[j]) : options.longs === Number ? new $util.LongBits(message.orderId[j].low >>> 0, message.orderId[j].high >>> 0).toNumber(true) : message.orderId[j];
                }
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (typeof message.companyId === "number")
                        object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                    else
                        object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber(true) : message.companyId;
                if (message.subCompanyId != null && message.hasOwnProperty("subCompanyId"))
                    if (typeof message.subCompanyId === "number")
                        object.subCompanyId = options.longs === String ? String(message.subCompanyId) : message.subCompanyId;
                    else
                        object.subCompanyId = options.longs === String ? $util.Long.prototype.toString.call(message.subCompanyId) : options.longs === Number ? new $util.LongBits(message.subCompanyId.low >>> 0, message.subCompanyId.high >>> 0).toNumber(true) : message.subCompanyId;
                return object;
            };

            /**
             * Converts this ChangedOrderIdList to JSON.
             * @function toJSON
             * @memberof fastcity.admin.ChangedOrderIdList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ChangedOrderIdList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return ChangedOrderIdList;
        })();

        admin.ChangeOrderStatus = (function() {

            /**
             * Properties of a ChangeOrderStatus.
             * @memberof fastcity.admin
             * @interface IChangeOrderStatus
             * @property {Array.<number|Long>|null} [orderId] ChangeOrderStatus orderId
             * @property {fastcity.common.Order.Status|null} [statusUnknownExternalSystem] ChangeOrderStatus statusUnknownExternalSystem
             * @property {fastcity.admin.IIKOOrderStatus|null} [statusIikoExternalSystem] ChangeOrderStatus statusIikoExternalSystem
             */

            /**
             * Constructs a new ChangeOrderStatus.
             * @memberof fastcity.admin
             * @classdesc Represents a ChangeOrderStatus.
             * @implements IChangeOrderStatus
             * @constructor
             * @param {fastcity.admin.IChangeOrderStatus=} [properties] Properties to set
             */
            function ChangeOrderStatus(properties) {
                this.orderId = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ChangeOrderStatus orderId.
             * @member {Array.<number|Long>} orderId
             * @memberof fastcity.admin.ChangeOrderStatus
             * @instance
             */
            ChangeOrderStatus.prototype.orderId = $util.emptyArray;

            /**
             * ChangeOrderStatus statusUnknownExternalSystem.
             * @member {fastcity.common.Order.Status|null|undefined} statusUnknownExternalSystem
             * @memberof fastcity.admin.ChangeOrderStatus
             * @instance
             */
            ChangeOrderStatus.prototype.statusUnknownExternalSystem = null;

            /**
             * ChangeOrderStatus statusIikoExternalSystem.
             * @member {fastcity.admin.IIKOOrderStatus|null|undefined} statusIikoExternalSystem
             * @memberof fastcity.admin.ChangeOrderStatus
             * @instance
             */
            ChangeOrderStatus.prototype.statusIikoExternalSystem = null;

            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;

            /**
             * ChangeOrderStatus body.
             * @member {"statusUnknownExternalSystem"|"statusIikoExternalSystem"|undefined} body
             * @memberof fastcity.admin.ChangeOrderStatus
             * @instance
             */
            Object.defineProperty(ChangeOrderStatus.prototype, "body", {
                get: $util.oneOfGetter($oneOfFields = ["statusUnknownExternalSystem", "statusIikoExternalSystem"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Creates a new ChangeOrderStatus instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.ChangeOrderStatus
             * @static
             * @param {fastcity.admin.IChangeOrderStatus=} [properties] Properties to set
             * @returns {fastcity.admin.ChangeOrderStatus} ChangeOrderStatus instance
             */
            ChangeOrderStatus.create = function create(properties) {
                return new ChangeOrderStatus(properties);
            };

            /**
             * Encodes the specified ChangeOrderStatus message. Does not implicitly {@link fastcity.admin.ChangeOrderStatus.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.ChangeOrderStatus
             * @static
             * @param {fastcity.admin.IChangeOrderStatus} message ChangeOrderStatus message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ChangeOrderStatus.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.orderId != null && message.orderId.length) {
                    writer.uint32(/* id 1, wireType 2 =*/10).fork();
                    for (var i = 0; i < message.orderId.length; ++i)
                        writer.uint64(message.orderId[i]);
                    writer.ldelim();
                }
                if (message.statusUnknownExternalSystem != null && Object.hasOwnProperty.call(message, "statusUnknownExternalSystem"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.statusUnknownExternalSystem);
                if (message.statusIikoExternalSystem != null && Object.hasOwnProperty.call(message, "statusIikoExternalSystem"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.statusIikoExternalSystem);
                return writer;
            };

            /**
             * Decodes a ChangeOrderStatus message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.ChangeOrderStatus
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.ChangeOrderStatus} ChangeOrderStatus
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ChangeOrderStatus.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.ChangeOrderStatus();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.orderId && message.orderId.length))
                            message.orderId = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.orderId.push(reader.uint64());
                        } else
                            message.orderId.push(reader.uint64());
                        break;
                    case 2:
                        message.statusUnknownExternalSystem = reader.int32();
                        break;
                    case 3:
                        message.statusIikoExternalSystem = reader.int32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a ChangeOrderStatus message.
             * @function verify
             * @memberof fastcity.admin.ChangeOrderStatus
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ChangeOrderStatus.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                var properties = {};
                if (message.orderId != null && message.hasOwnProperty("orderId")) {
                    if (!Array.isArray(message.orderId))
                        return "orderId: array expected";
                    for (var i = 0; i < message.orderId.length; ++i)
                        if (!$util.isInteger(message.orderId[i]) && !(message.orderId[i] && $util.isInteger(message.orderId[i].low) && $util.isInteger(message.orderId[i].high)))
                            return "orderId: integer|Long[] expected";
                }
                if (message.statusUnknownExternalSystem != null && message.hasOwnProperty("statusUnknownExternalSystem")) {
                    properties.body = 1;
                    switch (message.statusUnknownExternalSystem) {
                    default:
                        return "statusUnknownExternalSystem: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                    case 9:
                    case 10:
                    case 11:
                    case 12:
                    case 13:
                    case 14:
                    case 15:
                    case 16:
                        break;
                    }
                }
                if (message.statusIikoExternalSystem != null && message.hasOwnProperty("statusIikoExternalSystem")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    switch (message.statusIikoExternalSystem) {
                    default:
                        return "statusIikoExternalSystem: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                    case 9:
                        break;
                    }
                }
                return null;
            };

            /**
             * Creates a ChangeOrderStatus message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.ChangeOrderStatus
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.ChangeOrderStatus} ChangeOrderStatus
             */
            ChangeOrderStatus.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.ChangeOrderStatus)
                    return object;
                var message = new $root.fastcity.admin.ChangeOrderStatus();
                if (object.orderId) {
                    if (!Array.isArray(object.orderId))
                        throw TypeError(".fastcity.admin.ChangeOrderStatus.orderId: array expected");
                    message.orderId = [];
                    for (var i = 0; i < object.orderId.length; ++i)
                        if ($util.Long)
                            (message.orderId[i] = $util.Long.fromValue(object.orderId[i])).unsigned = true;
                        else if (typeof object.orderId[i] === "string")
                            message.orderId[i] = parseInt(object.orderId[i], 10);
                        else if (typeof object.orderId[i] === "number")
                            message.orderId[i] = object.orderId[i];
                        else if (typeof object.orderId[i] === "object")
                            message.orderId[i] = new $util.LongBits(object.orderId[i].low >>> 0, object.orderId[i].high >>> 0).toNumber(true);
                }
                switch (object.statusUnknownExternalSystem) {
                case "S_UNKNOWN":
                case 0:
                    message.statusUnknownExternalSystem = 0;
                    break;
                case "S_IN_PROGRESS":
                case 1:
                    message.statusUnknownExternalSystem = 1;
                    break;
                case "S_MAKELINE":
                case 2:
                    message.statusUnknownExternalSystem = 2;
                    break;
                case "S_ROUTING_STATION":
                case 3:
                    message.statusUnknownExternalSystem = 3;
                    break;
                case "S_OUT_THE_DOOR":
                case 4:
                    message.statusUnknownExternalSystem = 4;
                    break;
                case "S_COMPLETE":
                case 5:
                    message.statusUnknownExternalSystem = 5;
                    break;
                case "S_GIFT":
                case 6:
                    message.statusUnknownExternalSystem = 6;
                    break;
                case "S_BAD":
                case 7:
                    message.statusUnknownExternalSystem = 7;
                    break;
                case "S_VOID":
                case 8:
                    message.statusUnknownExternalSystem = 8;
                    break;
                case "S_BEING_TAKEN":
                case 9:
                    message.statusUnknownExternalSystem = 9;
                    break;
                case "S_ABANDONED":
                case 10:
                    message.statusUnknownExternalSystem = 10;
                    break;
                case "S_SUSPENDED":
                case 11:
                    message.statusUnknownExternalSystem = 11;
                    break;
                case "S_GIFT_CARD_PURCHASE":
                case 12:
                    message.statusUnknownExternalSystem = 12;
                    break;
                case "S_GIFT_CARD_VOID":
                case 13:
                    message.statusUnknownExternalSystem = 13;
                    break;
                case "S_TRANSFERRED":
                case 14:
                    message.statusUnknownExternalSystem = 14;
                    break;
                case "S_HISTORIC_ORDER":
                case 15:
                    message.statusUnknownExternalSystem = 15;
                    break;
                case "S_BEING_ASSIGNED":
                case 16:
                    message.statusUnknownExternalSystem = 16;
                    break;
                }
                switch (object.statusIikoExternalSystem) {
                case "UNCONFIRMED":
                case 0:
                    message.statusIikoExternalSystem = 0;
                    break;
                case "WAIT_COOKING":
                case 1:
                    message.statusIikoExternalSystem = 1;
                    break;
                case "READY_FOR_COOKING":
                case 2:
                    message.statusIikoExternalSystem = 2;
                    break;
                case "COOKING_STARTED":
                case 3:
                    message.statusIikoExternalSystem = 3;
                    break;
                case "COOKING_COMPLETED":
                case 4:
                    message.statusIikoExternalSystem = 4;
                    break;
                case "WAITING":
                case 5:
                    message.statusIikoExternalSystem = 5;
                    break;
                case "ON_WAY":
                case 6:
                    message.statusIikoExternalSystem = 6;
                    break;
                case "DELIVERED":
                case 7:
                    message.statusIikoExternalSystem = 7;
                    break;
                case "CLOSED":
                case 8:
                    message.statusIikoExternalSystem = 8;
                    break;
                case "CANCELLED":
                case 9:
                    message.statusIikoExternalSystem = 9;
                    break;
                }
                return message;
            };

            /**
             * Creates a plain object from a ChangeOrderStatus message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.ChangeOrderStatus
             * @static
             * @param {fastcity.admin.ChangeOrderStatus} message ChangeOrderStatus
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ChangeOrderStatus.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.orderId = [];
                if (message.orderId && message.orderId.length) {
                    object.orderId = [];
                    for (var j = 0; j < message.orderId.length; ++j)
                        if (typeof message.orderId[j] === "number")
                            object.orderId[j] = options.longs === String ? String(message.orderId[j]) : message.orderId[j];
                        else
                            object.orderId[j] = options.longs === String ? $util.Long.prototype.toString.call(message.orderId[j]) : options.longs === Number ? new $util.LongBits(message.orderId[j].low >>> 0, message.orderId[j].high >>> 0).toNumber(true) : message.orderId[j];
                }
                if (message.statusUnknownExternalSystem != null && message.hasOwnProperty("statusUnknownExternalSystem")) {
                    object.statusUnknownExternalSystem = options.enums === String ? $root.fastcity.common.Order.Status[message.statusUnknownExternalSystem] : message.statusUnknownExternalSystem;
                    if (options.oneofs)
                        object.body = "statusUnknownExternalSystem";
                }
                if (message.statusIikoExternalSystem != null && message.hasOwnProperty("statusIikoExternalSystem")) {
                    object.statusIikoExternalSystem = options.enums === String ? $root.fastcity.admin.IIKOOrderStatus[message.statusIikoExternalSystem] : message.statusIikoExternalSystem;
                    if (options.oneofs)
                        object.body = "statusIikoExternalSystem";
                }
                return object;
            };

            /**
             * Converts this ChangeOrderStatus to JSON.
             * @function toJSON
             * @memberof fastcity.admin.ChangeOrderStatus
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ChangeOrderStatus.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return ChangeOrderStatus;
        })();

        admin.ClientMessage = (function() {

            /**
             * Properties of a ClientMessage.
             * @memberof fastcity.admin
             * @interface IClientMessage
             * @property {number|Long|null} [requestId] ClientMessage requestId
             * @property {fastcity.admin.IGetCurrentAdmin|null} [getCurrentAdmin] ClientMessage getCurrentAdmin
             * @property {fastcity.admin.IGetAdminList|null} [getAdminList] ClientMessage getAdminList
             * @property {fastcity.admin.ICreateAdmin|null} [createAdmin] ClientMessage createAdmin
             * @property {fastcity.admin.IUpdateAdmin|null} [updateAdmin] ClientMessage updateAdmin
             * @property {fastcity.admin.IDeleteAdmin|null} [deleteAdmin] ClientMessage deleteAdmin
             * @property {fastcity.admin.IGetCurrentCompany|null} [getCurrentCompany] ClientMessage getCurrentCompany
             * @property {fastcity.admin.IGetCompanyList|null} [getCompanyList] ClientMessage getCompanyList
             * @property {fastcity.admin.ICreateCompany|null} [createCompany] ClientMessage createCompany
             * @property {fastcity.admin.IUpdateCompany|null} [updateCompany] ClientMessage updateCompany
             * @property {fastcity.admin.IDeleteCompany|null} [deleteCompany] ClientMessage deleteCompany
             * @property {fastcity.admin.IGetCourierList|null} [getCourierList] ClientMessage getCourierList
             * @property {fastcity.admin.ICreateCourier|null} [createCourier] ClientMessage createCourier
             * @property {fastcity.admin.IUpdateCourier|null} [updateCourier] ClientMessage updateCourier
             * @property {fastcity.admin.ISetCourierGroup|null} [setCourierGroup] ClientMessage setCourierGroup
             * @property {fastcity.admin.ISetCourierIsDeleted|null} [setCourierIsDeleted] ClientMessage setCourierIsDeleted
             * @property {fastcity.admin.IGetGroupList|null} [getGroupList] ClientMessage getGroupList
             * @property {fastcity.admin.ICreateGroup|null} [createGroup] ClientMessage createGroup
             * @property {fastcity.admin.IDeleteGroup|null} [deleteGroup] ClientMessage deleteGroup
             * @property {fastcity.admin.IUpdateGroup|null} [updateGroup] ClientMessage updateGroup
             * @property {fastcity.admin.IGetLastLocationList|null} [getLastLocationList] ClientMessage getLastLocationList
             * @property {fastcity.admin.IGetLocationList|null} [getLocationList] ClientMessage getLocationList
             * @property {fastcity.admin.IGetOrderList|null} [getOrderList] ClientMessage getOrderList
             * @property {fastcity.admin.IGetConnectionStatusList|null} [getConnectionStatusList] ClientMessage getConnectionStatusList
             * @property {fastcity.admin.ICreateIntegrationToken|null} [createIntegrationToken] ClientMessage createIntegrationToken
             * @property {fastcity.admin.IDeleteIntegrationToken|null} [deleteIntegrationToken] ClientMessage deleteIntegrationToken
             * @property {fastcity.admin.IGetIntegrationTokenList|null} [getIntegrationTokenList] ClientMessage getIntegrationTokenList
             * @property {fastcity.admin.ILogon|null} [logon] ClientMessage logon
             * @property {fastcity.admin.IResetPassword|null} [resetPassword] ClientMessage resetPassword
             * @property {fastcity.admin.IGetDeliveryStatDayList|null} [getDeliveryStatDayList] ClientMessage getDeliveryStatDayList
             * @property {fastcity.admin.IGetDeliveryStatList|null} [getDeliveryStatList] ClientMessage getDeliveryStatList
             * @property {fastcity.admin.IGetWorkingDayList|null} [getWorkingDayList] ClientMessage getWorkingDayList
             * @property {fastcity.admin.IGetSubCompanyIdList|null} [getSubCompanyIdList] ClientMessage getSubCompanyIdList
             * @property {fastcity.admin.IGetSubCompanyList|null} [getSubCompanyList] ClientMessage getSubCompanyList
             * @property {fastcity.admin.IDeleteSubCompany|null} [deleteSubCompany] ClientMessage deleteSubCompany
             * @property {fastcity.admin.ICreateSubCompany|null} [createSubCompany] ClientMessage createSubCompany
             * @property {fastcity.admin.IUpdateSubCompany|null} [updateSubCompany] ClientMessage updateSubCompany
             * @property {fastcity.admin.IActivate|null} [activate] ClientMessage activate
             * @property {fastcity.admin.ICreateSubCompanyZone|null} [createSubCompanyZone] ClientMessage createSubCompanyZone
             * @property {fastcity.admin.ICreateSubCompanyZoneFromKML|null} [createSubCompanyZoneFromKml] ClientMessage createSubCompanyZoneFromKml
             * @property {fastcity.admin.IDeleteSubCompanyZone|null} [deleteSubCompanyZone] ClientMessage deleteSubCompanyZone
             * @property {fastcity.admin.IRecoverSubCompanyZone|null} [recoverSubCompanyZone] ClientMessage recoverSubCompanyZone
             * @property {fastcity.admin.IUpdateSubCompanyZone|null} [updateSubCompanyZone] ClientMessage updateSubCompanyZone
             * @property {fastcity.admin.IUpdateSubCompanyZoneUsingKML|null} [updateSubCompanyZoneUsingKml] ClientMessage updateSubCompanyZoneUsingKml
             * @property {fastcity.admin.IGetSubCompanyZone|null} [getSubCompanyZone] ClientMessage getSubCompanyZone
             * @property {fastcity.admin.IGetSubCompanyZoneList|null} [getSubCompanyZoneList] ClientMessage getSubCompanyZoneList
             * @property {fastcity.admin.ICreateAvailableOrderStatuses|null} [createAvailableOrderStatuses] ClientMessage createAvailableOrderStatuses
             * @property {fastcity.admin.IUpdateAvailableOrderStatuses|null} [updateAvailableOrderStatuses] ClientMessage updateAvailableOrderStatuses
             * @property {fastcity.admin.IGetAvailableOrderStatuses|null} [getAvailableOrderStatuses] ClientMessage getAvailableOrderStatuses
             * @property {fastcity.admin.IGetAvailableOrderStatusesList|null} [getAvailableOrderStatusesList] ClientMessage getAvailableOrderStatusesList
             * @property {fastcity.admin.ICreateExternalAuthToken|null} [createExternalAuthToken] ClientMessage createExternalAuthToken
             * @property {fastcity.admin.IUpdateExternalAuthToken|null} [updateExternalAuthToken] ClientMessage updateExternalAuthToken
             * @property {fastcity.admin.IDeleteExternalAuthToken|null} [deleteExternalAuthToken] ClientMessage deleteExternalAuthToken
             * @property {fastcity.admin.IGetActiveExternalAuthToken|null} [getActiveExternalAuthToken] ClientMessage getActiveExternalAuthToken
             * @property {fastcity.admin.IGetExternalAuthTokenList|null} [getExternalAuthTokenList] ClientMessage getExternalAuthTokenList
             * @property {fastcity.admin.IBindExternalAuthTokenToSubCompany|null} [bindExternalAuthTokenToSubCompany] ClientMessage bindExternalAuthTokenToSubCompany
             * @property {fastcity.admin.IGetIntegrationConnectionStatus|null} [getIntegrationConnectionStatus] ClientMessage getIntegrationConnectionStatus
             * @property {fastcity.admin.IGetIntegrationConnectionStatusList|null} [getIntegrationConnectionStatusList] ClientMessage getIntegrationConnectionStatusList
             * @property {fastcity.admin.IGetCouriersFromExternal|null} [getCouriersFromExternal] ClientMessage getCouriersFromExternal
             * @property {fastcity.admin.IGetSubCompaniesFromExternal|null} [getSubCompaniesFromExternal] ClientMessage getSubCompaniesFromExternal
             * @property {fastcity.admin.IGetServerVersion|null} [getServerVersion] ClientMessage getServerVersion
             * @property {fastcity.admin.IGetOrderUpdateList|null} [getOrderUpdateList] ClientMessage getOrderUpdateList
             * @property {fastcity.admin.IGetLogRecordList|null} [getLogRecordList] ClientMessage getLogRecordList
             * @property {fastcity.common.IGetOrderPath|null} [getOrderPath] ClientMessage getOrderPath
             * @property {fastcity.common.IGetOrderPathList|null} [getOrderPathList] ClientMessage getOrderPathList
             * @property {fastcity.admin.ICreateSubCompanyIdToExternalCourierId|null} [createSubCompanyIdToExternalCourierId] ClientMessage createSubCompanyIdToExternalCourierId
             * @property {fastcity.admin.IUpdateSubCompanyIdToExternalCourierId|null} [updateSubCompanyIdToExternalCourierId] ClientMessage updateSubCompanyIdToExternalCourierId
             * @property {fastcity.admin.IRemoveSubCompanyIdToExternalCourierId|null} [removeSubCompanyIdToExternalCourierId] ClientMessage removeSubCompanyIdToExternalCourierId
             * @property {fastcity.admin.IGetSubCompanyIdToExternalCourierIdList|null} [getSubCompanyIdToExternalCourierIdList] ClientMessage getSubCompanyIdToExternalCourierIdList
             * @property {fastcity.admin.IChangeOrderStatus|null} [changeOrderStatus] ClientMessage changeOrderStatus
             * @property {fastcity.admin.IGetServerConfig|null} [getServerConfig] ClientMessage getServerConfig
             * @property {fastcity.admin.IUpdateServerConfig|null} [updateServerConfig] ClientMessage updateServerConfig
             */

            /**
             * Constructs a new ClientMessage.
             * @memberof fastcity.admin
             * @classdesc Represents a ClientMessage.
             * @implements IClientMessage
             * @constructor
             * @param {fastcity.admin.IClientMessage=} [properties] Properties to set
             */
            function ClientMessage(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ClientMessage requestId.
             * @member {number|Long} requestId
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.requestId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * ClientMessage getCurrentAdmin.
             * @member {fastcity.admin.IGetCurrentAdmin|null|undefined} getCurrentAdmin
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.getCurrentAdmin = null;

            /**
             * ClientMessage getAdminList.
             * @member {fastcity.admin.IGetAdminList|null|undefined} getAdminList
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.getAdminList = null;

            /**
             * ClientMessage createAdmin.
             * @member {fastcity.admin.ICreateAdmin|null|undefined} createAdmin
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.createAdmin = null;

            /**
             * ClientMessage updateAdmin.
             * @member {fastcity.admin.IUpdateAdmin|null|undefined} updateAdmin
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.updateAdmin = null;

            /**
             * ClientMessage deleteAdmin.
             * @member {fastcity.admin.IDeleteAdmin|null|undefined} deleteAdmin
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.deleteAdmin = null;

            /**
             * ClientMessage getCurrentCompany.
             * @member {fastcity.admin.IGetCurrentCompany|null|undefined} getCurrentCompany
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.getCurrentCompany = null;

            /**
             * ClientMessage getCompanyList.
             * @member {fastcity.admin.IGetCompanyList|null|undefined} getCompanyList
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.getCompanyList = null;

            /**
             * ClientMessage createCompany.
             * @member {fastcity.admin.ICreateCompany|null|undefined} createCompany
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.createCompany = null;

            /**
             * ClientMessage updateCompany.
             * @member {fastcity.admin.IUpdateCompany|null|undefined} updateCompany
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.updateCompany = null;

            /**
             * ClientMessage deleteCompany.
             * @member {fastcity.admin.IDeleteCompany|null|undefined} deleteCompany
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.deleteCompany = null;

            /**
             * ClientMessage getCourierList.
             * @member {fastcity.admin.IGetCourierList|null|undefined} getCourierList
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.getCourierList = null;

            /**
             * ClientMessage createCourier.
             * @member {fastcity.admin.ICreateCourier|null|undefined} createCourier
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.createCourier = null;

            /**
             * ClientMessage updateCourier.
             * @member {fastcity.admin.IUpdateCourier|null|undefined} updateCourier
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.updateCourier = null;

            /**
             * ClientMessage setCourierGroup.
             * @member {fastcity.admin.ISetCourierGroup|null|undefined} setCourierGroup
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.setCourierGroup = null;

            /**
             * ClientMessage setCourierIsDeleted.
             * @member {fastcity.admin.ISetCourierIsDeleted|null|undefined} setCourierIsDeleted
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.setCourierIsDeleted = null;

            /**
             * ClientMessage getGroupList.
             * @member {fastcity.admin.IGetGroupList|null|undefined} getGroupList
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.getGroupList = null;

            /**
             * ClientMessage createGroup.
             * @member {fastcity.admin.ICreateGroup|null|undefined} createGroup
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.createGroup = null;

            /**
             * ClientMessage deleteGroup.
             * @member {fastcity.admin.IDeleteGroup|null|undefined} deleteGroup
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.deleteGroup = null;

            /**
             * ClientMessage updateGroup.
             * @member {fastcity.admin.IUpdateGroup|null|undefined} updateGroup
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.updateGroup = null;

            /**
             * ClientMessage getLastLocationList.
             * @member {fastcity.admin.IGetLastLocationList|null|undefined} getLastLocationList
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.getLastLocationList = null;

            /**
             * ClientMessage getLocationList.
             * @member {fastcity.admin.IGetLocationList|null|undefined} getLocationList
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.getLocationList = null;

            /**
             * ClientMessage getOrderList.
             * @member {fastcity.admin.IGetOrderList|null|undefined} getOrderList
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.getOrderList = null;

            /**
             * ClientMessage getConnectionStatusList.
             * @member {fastcity.admin.IGetConnectionStatusList|null|undefined} getConnectionStatusList
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.getConnectionStatusList = null;

            /**
             * ClientMessage createIntegrationToken.
             * @member {fastcity.admin.ICreateIntegrationToken|null|undefined} createIntegrationToken
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.createIntegrationToken = null;

            /**
             * ClientMessage deleteIntegrationToken.
             * @member {fastcity.admin.IDeleteIntegrationToken|null|undefined} deleteIntegrationToken
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.deleteIntegrationToken = null;

            /**
             * ClientMessage getIntegrationTokenList.
             * @member {fastcity.admin.IGetIntegrationTokenList|null|undefined} getIntegrationTokenList
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.getIntegrationTokenList = null;

            /**
             * ClientMessage logon.
             * @member {fastcity.admin.ILogon|null|undefined} logon
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.logon = null;

            /**
             * ClientMessage resetPassword.
             * @member {fastcity.admin.IResetPassword|null|undefined} resetPassword
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.resetPassword = null;

            /**
             * ClientMessage getDeliveryStatDayList.
             * @member {fastcity.admin.IGetDeliveryStatDayList|null|undefined} getDeliveryStatDayList
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.getDeliveryStatDayList = null;

            /**
             * ClientMessage getDeliveryStatList.
             * @member {fastcity.admin.IGetDeliveryStatList|null|undefined} getDeliveryStatList
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.getDeliveryStatList = null;

            /**
             * ClientMessage getWorkingDayList.
             * @member {fastcity.admin.IGetWorkingDayList|null|undefined} getWorkingDayList
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.getWorkingDayList = null;

            /**
             * ClientMessage getSubCompanyIdList.
             * @member {fastcity.admin.IGetSubCompanyIdList|null|undefined} getSubCompanyIdList
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.getSubCompanyIdList = null;

            /**
             * ClientMessage getSubCompanyList.
             * @member {fastcity.admin.IGetSubCompanyList|null|undefined} getSubCompanyList
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.getSubCompanyList = null;

            /**
             * ClientMessage deleteSubCompany.
             * @member {fastcity.admin.IDeleteSubCompany|null|undefined} deleteSubCompany
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.deleteSubCompany = null;

            /**
             * ClientMessage createSubCompany.
             * @member {fastcity.admin.ICreateSubCompany|null|undefined} createSubCompany
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.createSubCompany = null;

            /**
             * ClientMessage updateSubCompany.
             * @member {fastcity.admin.IUpdateSubCompany|null|undefined} updateSubCompany
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.updateSubCompany = null;

            /**
             * ClientMessage activate.
             * @member {fastcity.admin.IActivate|null|undefined} activate
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.activate = null;

            /**
             * ClientMessage createSubCompanyZone.
             * @member {fastcity.admin.ICreateSubCompanyZone|null|undefined} createSubCompanyZone
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.createSubCompanyZone = null;

            /**
             * ClientMessage createSubCompanyZoneFromKml.
             * @member {fastcity.admin.ICreateSubCompanyZoneFromKML|null|undefined} createSubCompanyZoneFromKml
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.createSubCompanyZoneFromKml = null;

            /**
             * ClientMessage deleteSubCompanyZone.
             * @member {fastcity.admin.IDeleteSubCompanyZone|null|undefined} deleteSubCompanyZone
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.deleteSubCompanyZone = null;

            /**
             * ClientMessage recoverSubCompanyZone.
             * @member {fastcity.admin.IRecoverSubCompanyZone|null|undefined} recoverSubCompanyZone
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.recoverSubCompanyZone = null;

            /**
             * ClientMessage updateSubCompanyZone.
             * @member {fastcity.admin.IUpdateSubCompanyZone|null|undefined} updateSubCompanyZone
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.updateSubCompanyZone = null;

            /**
             * ClientMessage updateSubCompanyZoneUsingKml.
             * @member {fastcity.admin.IUpdateSubCompanyZoneUsingKML|null|undefined} updateSubCompanyZoneUsingKml
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.updateSubCompanyZoneUsingKml = null;

            /**
             * ClientMessage getSubCompanyZone.
             * @member {fastcity.admin.IGetSubCompanyZone|null|undefined} getSubCompanyZone
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.getSubCompanyZone = null;

            /**
             * ClientMessage getSubCompanyZoneList.
             * @member {fastcity.admin.IGetSubCompanyZoneList|null|undefined} getSubCompanyZoneList
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.getSubCompanyZoneList = null;

            /**
             * ClientMessage createAvailableOrderStatuses.
             * @member {fastcity.admin.ICreateAvailableOrderStatuses|null|undefined} createAvailableOrderStatuses
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.createAvailableOrderStatuses = null;

            /**
             * ClientMessage updateAvailableOrderStatuses.
             * @member {fastcity.admin.IUpdateAvailableOrderStatuses|null|undefined} updateAvailableOrderStatuses
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.updateAvailableOrderStatuses = null;

            /**
             * ClientMessage getAvailableOrderStatuses.
             * @member {fastcity.admin.IGetAvailableOrderStatuses|null|undefined} getAvailableOrderStatuses
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.getAvailableOrderStatuses = null;

            /**
             * ClientMessage getAvailableOrderStatusesList.
             * @member {fastcity.admin.IGetAvailableOrderStatusesList|null|undefined} getAvailableOrderStatusesList
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.getAvailableOrderStatusesList = null;

            /**
             * ClientMessage createExternalAuthToken.
             * @member {fastcity.admin.ICreateExternalAuthToken|null|undefined} createExternalAuthToken
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.createExternalAuthToken = null;

            /**
             * ClientMessage updateExternalAuthToken.
             * @member {fastcity.admin.IUpdateExternalAuthToken|null|undefined} updateExternalAuthToken
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.updateExternalAuthToken = null;

            /**
             * ClientMessage deleteExternalAuthToken.
             * @member {fastcity.admin.IDeleteExternalAuthToken|null|undefined} deleteExternalAuthToken
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.deleteExternalAuthToken = null;

            /**
             * ClientMessage getActiveExternalAuthToken.
             * @member {fastcity.admin.IGetActiveExternalAuthToken|null|undefined} getActiveExternalAuthToken
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.getActiveExternalAuthToken = null;

            /**
             * ClientMessage getExternalAuthTokenList.
             * @member {fastcity.admin.IGetExternalAuthTokenList|null|undefined} getExternalAuthTokenList
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.getExternalAuthTokenList = null;

            /**
             * ClientMessage bindExternalAuthTokenToSubCompany.
             * @member {fastcity.admin.IBindExternalAuthTokenToSubCompany|null|undefined} bindExternalAuthTokenToSubCompany
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.bindExternalAuthTokenToSubCompany = null;

            /**
             * ClientMessage getIntegrationConnectionStatus.
             * @member {fastcity.admin.IGetIntegrationConnectionStatus|null|undefined} getIntegrationConnectionStatus
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.getIntegrationConnectionStatus = null;

            /**
             * ClientMessage getIntegrationConnectionStatusList.
             * @member {fastcity.admin.IGetIntegrationConnectionStatusList|null|undefined} getIntegrationConnectionStatusList
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.getIntegrationConnectionStatusList = null;

            /**
             * ClientMessage getCouriersFromExternal.
             * @member {fastcity.admin.IGetCouriersFromExternal|null|undefined} getCouriersFromExternal
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.getCouriersFromExternal = null;

            /**
             * ClientMessage getSubCompaniesFromExternal.
             * @member {fastcity.admin.IGetSubCompaniesFromExternal|null|undefined} getSubCompaniesFromExternal
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.getSubCompaniesFromExternal = null;

            /**
             * ClientMessage getServerVersion.
             * @member {fastcity.admin.IGetServerVersion|null|undefined} getServerVersion
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.getServerVersion = null;

            /**
             * ClientMessage getOrderUpdateList.
             * @member {fastcity.admin.IGetOrderUpdateList|null|undefined} getOrderUpdateList
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.getOrderUpdateList = null;

            /**
             * ClientMessage getLogRecordList.
             * @member {fastcity.admin.IGetLogRecordList|null|undefined} getLogRecordList
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.getLogRecordList = null;

            /**
             * ClientMessage getOrderPath.
             * @member {fastcity.common.IGetOrderPath|null|undefined} getOrderPath
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.getOrderPath = null;

            /**
             * ClientMessage getOrderPathList.
             * @member {fastcity.common.IGetOrderPathList|null|undefined} getOrderPathList
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.getOrderPathList = null;

            /**
             * ClientMessage createSubCompanyIdToExternalCourierId.
             * @member {fastcity.admin.ICreateSubCompanyIdToExternalCourierId|null|undefined} createSubCompanyIdToExternalCourierId
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.createSubCompanyIdToExternalCourierId = null;

            /**
             * ClientMessage updateSubCompanyIdToExternalCourierId.
             * @member {fastcity.admin.IUpdateSubCompanyIdToExternalCourierId|null|undefined} updateSubCompanyIdToExternalCourierId
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.updateSubCompanyIdToExternalCourierId = null;

            /**
             * ClientMessage removeSubCompanyIdToExternalCourierId.
             * @member {fastcity.admin.IRemoveSubCompanyIdToExternalCourierId|null|undefined} removeSubCompanyIdToExternalCourierId
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.removeSubCompanyIdToExternalCourierId = null;

            /**
             * ClientMessage getSubCompanyIdToExternalCourierIdList.
             * @member {fastcity.admin.IGetSubCompanyIdToExternalCourierIdList|null|undefined} getSubCompanyIdToExternalCourierIdList
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.getSubCompanyIdToExternalCourierIdList = null;

            /**
             * ClientMessage changeOrderStatus.
             * @member {fastcity.admin.IChangeOrderStatus|null|undefined} changeOrderStatus
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.changeOrderStatus = null;

            /**
             * ClientMessage getServerConfig.
             * @member {fastcity.admin.IGetServerConfig|null|undefined} getServerConfig
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.getServerConfig = null;

            /**
             * ClientMessage updateServerConfig.
             * @member {fastcity.admin.IUpdateServerConfig|null|undefined} updateServerConfig
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.updateServerConfig = null;

            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;

            /**
             * ClientMessage body.
             * @member {"getCurrentAdmin"|"getAdminList"|"createAdmin"|"updateAdmin"|"deleteAdmin"|"getCurrentCompany"|"getCompanyList"|"createCompany"|"updateCompany"|"deleteCompany"|"getCourierList"|"createCourier"|"updateCourier"|"setCourierGroup"|"setCourierIsDeleted"|"getGroupList"|"createGroup"|"deleteGroup"|"updateGroup"|"getLastLocationList"|"getLocationList"|"getOrderList"|"getConnectionStatusList"|"createIntegrationToken"|"deleteIntegrationToken"|"getIntegrationTokenList"|"logon"|"resetPassword"|"getDeliveryStatDayList"|"getDeliveryStatList"|"getWorkingDayList"|"getSubCompanyIdList"|"getSubCompanyList"|"deleteSubCompany"|"createSubCompany"|"updateSubCompany"|"activate"|"createSubCompanyZone"|"createSubCompanyZoneFromKml"|"deleteSubCompanyZone"|"recoverSubCompanyZone"|"updateSubCompanyZone"|"updateSubCompanyZoneUsingKml"|"getSubCompanyZone"|"getSubCompanyZoneList"|"createAvailableOrderStatuses"|"updateAvailableOrderStatuses"|"getAvailableOrderStatuses"|"getAvailableOrderStatusesList"|"createExternalAuthToken"|"updateExternalAuthToken"|"deleteExternalAuthToken"|"getActiveExternalAuthToken"|"getExternalAuthTokenList"|"bindExternalAuthTokenToSubCompany"|"getIntegrationConnectionStatus"|"getIntegrationConnectionStatusList"|"getCouriersFromExternal"|"getSubCompaniesFromExternal"|"getServerVersion"|"getOrderUpdateList"|"getLogRecordList"|"getOrderPath"|"getOrderPathList"|"createSubCompanyIdToExternalCourierId"|"updateSubCompanyIdToExternalCourierId"|"removeSubCompanyIdToExternalCourierId"|"getSubCompanyIdToExternalCourierIdList"|"changeOrderStatus"|"getServerConfig"|"updateServerConfig"|undefined} body
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            Object.defineProperty(ClientMessage.prototype, "body", {
                get: $util.oneOfGetter($oneOfFields = ["getCurrentAdmin", "getAdminList", "createAdmin", "updateAdmin", "deleteAdmin", "getCurrentCompany", "getCompanyList", "createCompany", "updateCompany", "deleteCompany", "getCourierList", "createCourier", "updateCourier", "setCourierGroup", "setCourierIsDeleted", "getGroupList", "createGroup", "deleteGroup", "updateGroup", "getLastLocationList", "getLocationList", "getOrderList", "getConnectionStatusList", "createIntegrationToken", "deleteIntegrationToken", "getIntegrationTokenList", "logon", "resetPassword", "getDeliveryStatDayList", "getDeliveryStatList", "getWorkingDayList", "getSubCompanyIdList", "getSubCompanyList", "deleteSubCompany", "createSubCompany", "updateSubCompany", "activate", "createSubCompanyZone", "createSubCompanyZoneFromKml", "deleteSubCompanyZone", "recoverSubCompanyZone", "updateSubCompanyZone", "updateSubCompanyZoneUsingKml", "getSubCompanyZone", "getSubCompanyZoneList", "createAvailableOrderStatuses", "updateAvailableOrderStatuses", "getAvailableOrderStatuses", "getAvailableOrderStatusesList", "createExternalAuthToken", "updateExternalAuthToken", "deleteExternalAuthToken", "getActiveExternalAuthToken", "getExternalAuthTokenList", "bindExternalAuthTokenToSubCompany", "getIntegrationConnectionStatus", "getIntegrationConnectionStatusList", "getCouriersFromExternal", "getSubCompaniesFromExternal", "getServerVersion", "getOrderUpdateList", "getLogRecordList", "getOrderPath", "getOrderPathList", "createSubCompanyIdToExternalCourierId", "updateSubCompanyIdToExternalCourierId", "removeSubCompanyIdToExternalCourierId", "getSubCompanyIdToExternalCourierIdList", "changeOrderStatus", "getServerConfig", "updateServerConfig"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Creates a new ClientMessage instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.ClientMessage
             * @static
             * @param {fastcity.admin.IClientMessage=} [properties] Properties to set
             * @returns {fastcity.admin.ClientMessage} ClientMessage instance
             */
            ClientMessage.create = function create(properties) {
                return new ClientMessage(properties);
            };

            /**
             * Encodes the specified ClientMessage message. Does not implicitly {@link fastcity.admin.ClientMessage.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.ClientMessage
             * @static
             * @param {fastcity.admin.IClientMessage} message ClientMessage message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ClientMessage.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.requestId != null && Object.hasOwnProperty.call(message, "requestId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.requestId);
                if (message.getCurrentAdmin != null && Object.hasOwnProperty.call(message, "getCurrentAdmin"))
                    $root.fastcity.admin.GetCurrentAdmin.encode(message.getCurrentAdmin, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.getAdminList != null && Object.hasOwnProperty.call(message, "getAdminList"))
                    $root.fastcity.admin.GetAdminList.encode(message.getAdminList, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.createAdmin != null && Object.hasOwnProperty.call(message, "createAdmin"))
                    $root.fastcity.admin.CreateAdmin.encode(message.createAdmin, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.updateAdmin != null && Object.hasOwnProperty.call(message, "updateAdmin"))
                    $root.fastcity.admin.UpdateAdmin.encode(message.updateAdmin, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.getCurrentCompany != null && Object.hasOwnProperty.call(message, "getCurrentCompany"))
                    $root.fastcity.admin.GetCurrentCompany.encode(message.getCurrentCompany, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.getCompanyList != null && Object.hasOwnProperty.call(message, "getCompanyList"))
                    $root.fastcity.admin.GetCompanyList.encode(message.getCompanyList, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                if (message.createCompany != null && Object.hasOwnProperty.call(message, "createCompany"))
                    $root.fastcity.admin.CreateCompany.encode(message.createCompany, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                if (message.updateCompany != null && Object.hasOwnProperty.call(message, "updateCompany"))
                    $root.fastcity.admin.UpdateCompany.encode(message.updateCompany, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                if (message.getCourierList != null && Object.hasOwnProperty.call(message, "getCourierList"))
                    $root.fastcity.admin.GetCourierList.encode(message.getCourierList, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                if (message.createCourier != null && Object.hasOwnProperty.call(message, "createCourier"))
                    $root.fastcity.admin.CreateCourier.encode(message.createCourier, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                if (message.getGroupList != null && Object.hasOwnProperty.call(message, "getGroupList"))
                    $root.fastcity.admin.GetGroupList.encode(message.getGroupList, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
                if (message.createGroup != null && Object.hasOwnProperty.call(message, "createGroup"))
                    $root.fastcity.admin.CreateGroup.encode(message.createGroup, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
                if (message.getLastLocationList != null && Object.hasOwnProperty.call(message, "getLastLocationList"))
                    $root.fastcity.admin.GetLastLocationList.encode(message.getLastLocationList, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
                if (message.getLocationList != null && Object.hasOwnProperty.call(message, "getLocationList"))
                    $root.fastcity.admin.GetLocationList.encode(message.getLocationList, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
                if (message.getOrderList != null && Object.hasOwnProperty.call(message, "getOrderList"))
                    $root.fastcity.admin.GetOrderList.encode(message.getOrderList, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
                if (message.setCourierGroup != null && Object.hasOwnProperty.call(message, "setCourierGroup"))
                    $root.fastcity.admin.SetCourierGroup.encode(message.setCourierGroup, writer.uint32(/* id 17, wireType 2 =*/138).fork()).ldelim();
                if (message.getConnectionStatusList != null && Object.hasOwnProperty.call(message, "getConnectionStatusList"))
                    $root.fastcity.admin.GetConnectionStatusList.encode(message.getConnectionStatusList, writer.uint32(/* id 18, wireType 2 =*/146).fork()).ldelim();
                if (message.createIntegrationToken != null && Object.hasOwnProperty.call(message, "createIntegrationToken"))
                    $root.fastcity.admin.CreateIntegrationToken.encode(message.createIntegrationToken, writer.uint32(/* id 19, wireType 2 =*/154).fork()).ldelim();
                if (message.deleteIntegrationToken != null && Object.hasOwnProperty.call(message, "deleteIntegrationToken"))
                    $root.fastcity.admin.DeleteIntegrationToken.encode(message.deleteIntegrationToken, writer.uint32(/* id 20, wireType 2 =*/162).fork()).ldelim();
                if (message.getIntegrationTokenList != null && Object.hasOwnProperty.call(message, "getIntegrationTokenList"))
                    $root.fastcity.admin.GetIntegrationTokenList.encode(message.getIntegrationTokenList, writer.uint32(/* id 21, wireType 2 =*/170).fork()).ldelim();
                if (message.logon != null && Object.hasOwnProperty.call(message, "logon"))
                    $root.fastcity.admin.Logon.encode(message.logon, writer.uint32(/* id 22, wireType 2 =*/178).fork()).ldelim();
                if (message.deleteGroup != null && Object.hasOwnProperty.call(message, "deleteGroup"))
                    $root.fastcity.admin.DeleteGroup.encode(message.deleteGroup, writer.uint32(/* id 23, wireType 2 =*/186).fork()).ldelim();
                if (message.updateGroup != null && Object.hasOwnProperty.call(message, "updateGroup"))
                    $root.fastcity.admin.UpdateGroup.encode(message.updateGroup, writer.uint32(/* id 24, wireType 2 =*/194).fork()).ldelim();
                if (message.getDeliveryStatDayList != null && Object.hasOwnProperty.call(message, "getDeliveryStatDayList"))
                    $root.fastcity.admin.GetDeliveryStatDayList.encode(message.getDeliveryStatDayList, writer.uint32(/* id 25, wireType 2 =*/202).fork()).ldelim();
                if (message.getDeliveryStatList != null && Object.hasOwnProperty.call(message, "getDeliveryStatList"))
                    $root.fastcity.admin.GetDeliveryStatList.encode(message.getDeliveryStatList, writer.uint32(/* id 26, wireType 2 =*/210).fork()).ldelim();
                if (message.getWorkingDayList != null && Object.hasOwnProperty.call(message, "getWorkingDayList"))
                    $root.fastcity.admin.GetWorkingDayList.encode(message.getWorkingDayList, writer.uint32(/* id 27, wireType 2 =*/218).fork()).ldelim();
                if (message.getSubCompanyIdList != null && Object.hasOwnProperty.call(message, "getSubCompanyIdList"))
                    $root.fastcity.admin.GetSubCompanyIdList.encode(message.getSubCompanyIdList, writer.uint32(/* id 28, wireType 2 =*/226).fork()).ldelim();
                if (message.setCourierIsDeleted != null && Object.hasOwnProperty.call(message, "setCourierIsDeleted"))
                    $root.fastcity.admin.SetCourierIsDeleted.encode(message.setCourierIsDeleted, writer.uint32(/* id 29, wireType 2 =*/234).fork()).ldelim();
                if (message.getSubCompanyList != null && Object.hasOwnProperty.call(message, "getSubCompanyList"))
                    $root.fastcity.admin.GetSubCompanyList.encode(message.getSubCompanyList, writer.uint32(/* id 30, wireType 2 =*/242).fork()).ldelim();
                if (message.deleteAdmin != null && Object.hasOwnProperty.call(message, "deleteAdmin"))
                    $root.fastcity.admin.DeleteAdmin.encode(message.deleteAdmin, writer.uint32(/* id 31, wireType 2 =*/250).fork()).ldelim();
                if (message.deleteCompany != null && Object.hasOwnProperty.call(message, "deleteCompany"))
                    $root.fastcity.admin.DeleteCompany.encode(message.deleteCompany, writer.uint32(/* id 32, wireType 2 =*/258).fork()).ldelim();
                if (message.deleteSubCompany != null && Object.hasOwnProperty.call(message, "deleteSubCompany"))
                    $root.fastcity.admin.DeleteSubCompany.encode(message.deleteSubCompany, writer.uint32(/* id 33, wireType 2 =*/266).fork()).ldelim();
                if (message.resetPassword != null && Object.hasOwnProperty.call(message, "resetPassword"))
                    $root.fastcity.admin.ResetPassword.encode(message.resetPassword, writer.uint32(/* id 34, wireType 2 =*/274).fork()).ldelim();
                if (message.activate != null && Object.hasOwnProperty.call(message, "activate"))
                    $root.fastcity.admin.Activate.encode(message.activate, writer.uint32(/* id 35, wireType 2 =*/282).fork()).ldelim();
                if (message.updateSubCompany != null && Object.hasOwnProperty.call(message, "updateSubCompany"))
                    $root.fastcity.admin.UpdateSubCompany.encode(message.updateSubCompany, writer.uint32(/* id 36, wireType 2 =*/290).fork()).ldelim();
                if (message.updateCourier != null && Object.hasOwnProperty.call(message, "updateCourier"))
                    $root.fastcity.admin.UpdateCourier.encode(message.updateCourier, writer.uint32(/* id 38, wireType 2 =*/306).fork()).ldelim();
                if (message.createSubCompanyZone != null && Object.hasOwnProperty.call(message, "createSubCompanyZone"))
                    $root.fastcity.admin.CreateSubCompanyZone.encode(message.createSubCompanyZone, writer.uint32(/* id 39, wireType 2 =*/314).fork()).ldelim();
                if (message.updateSubCompanyZone != null && Object.hasOwnProperty.call(message, "updateSubCompanyZone"))
                    $root.fastcity.admin.UpdateSubCompanyZone.encode(message.updateSubCompanyZone, writer.uint32(/* id 40, wireType 2 =*/322).fork()).ldelim();
                if (message.getSubCompanyZone != null && Object.hasOwnProperty.call(message, "getSubCompanyZone"))
                    $root.fastcity.admin.GetSubCompanyZone.encode(message.getSubCompanyZone, writer.uint32(/* id 41, wireType 2 =*/330).fork()).ldelim();
                if (message.getSubCompanyZoneList != null && Object.hasOwnProperty.call(message, "getSubCompanyZoneList"))
                    $root.fastcity.admin.GetSubCompanyZoneList.encode(message.getSubCompanyZoneList, writer.uint32(/* id 42, wireType 2 =*/338).fork()).ldelim();
                if (message.createSubCompany != null && Object.hasOwnProperty.call(message, "createSubCompany"))
                    $root.fastcity.admin.CreateSubCompany.encode(message.createSubCompany, writer.uint32(/* id 43, wireType 2 =*/346).fork()).ldelim();
                if (message.createAvailableOrderStatuses != null && Object.hasOwnProperty.call(message, "createAvailableOrderStatuses"))
                    $root.fastcity.admin.CreateAvailableOrderStatuses.encode(message.createAvailableOrderStatuses, writer.uint32(/* id 44, wireType 2 =*/354).fork()).ldelim();
                if (message.updateAvailableOrderStatuses != null && Object.hasOwnProperty.call(message, "updateAvailableOrderStatuses"))
                    $root.fastcity.admin.UpdateAvailableOrderStatuses.encode(message.updateAvailableOrderStatuses, writer.uint32(/* id 45, wireType 2 =*/362).fork()).ldelim();
                if (message.createSubCompanyZoneFromKml != null && Object.hasOwnProperty.call(message, "createSubCompanyZoneFromKml"))
                    $root.fastcity.admin.CreateSubCompanyZoneFromKML.encode(message.createSubCompanyZoneFromKml, writer.uint32(/* id 46, wireType 2 =*/370).fork()).ldelim();
                if (message.createExternalAuthToken != null && Object.hasOwnProperty.call(message, "createExternalAuthToken"))
                    $root.fastcity.admin.CreateExternalAuthToken.encode(message.createExternalAuthToken, writer.uint32(/* id 47, wireType 2 =*/378).fork()).ldelim();
                if (message.updateExternalAuthToken != null && Object.hasOwnProperty.call(message, "updateExternalAuthToken"))
                    $root.fastcity.admin.UpdateExternalAuthToken.encode(message.updateExternalAuthToken, writer.uint32(/* id 48, wireType 2 =*/386).fork()).ldelim();
                if (message.deleteExternalAuthToken != null && Object.hasOwnProperty.call(message, "deleteExternalAuthToken"))
                    $root.fastcity.admin.DeleteExternalAuthToken.encode(message.deleteExternalAuthToken, writer.uint32(/* id 49, wireType 2 =*/394).fork()).ldelim();
                if (message.getActiveExternalAuthToken != null && Object.hasOwnProperty.call(message, "getActiveExternalAuthToken"))
                    $root.fastcity.admin.GetActiveExternalAuthToken.encode(message.getActiveExternalAuthToken, writer.uint32(/* id 50, wireType 2 =*/402).fork()).ldelim();
                if (message.getExternalAuthTokenList != null && Object.hasOwnProperty.call(message, "getExternalAuthTokenList"))
                    $root.fastcity.admin.GetExternalAuthTokenList.encode(message.getExternalAuthTokenList, writer.uint32(/* id 51, wireType 2 =*/410).fork()).ldelim();
                if (message.bindExternalAuthTokenToSubCompany != null && Object.hasOwnProperty.call(message, "bindExternalAuthTokenToSubCompany"))
                    $root.fastcity.admin.BindExternalAuthTokenToSubCompany.encode(message.bindExternalAuthTokenToSubCompany, writer.uint32(/* id 52, wireType 2 =*/418).fork()).ldelim();
                if (message.updateSubCompanyZoneUsingKml != null && Object.hasOwnProperty.call(message, "updateSubCompanyZoneUsingKml"))
                    $root.fastcity.admin.UpdateSubCompanyZoneUsingKML.encode(message.updateSubCompanyZoneUsingKml, writer.uint32(/* id 54, wireType 2 =*/434).fork()).ldelim();
                if (message.getAvailableOrderStatuses != null && Object.hasOwnProperty.call(message, "getAvailableOrderStatuses"))
                    $root.fastcity.admin.GetAvailableOrderStatuses.encode(message.getAvailableOrderStatuses, writer.uint32(/* id 55, wireType 2 =*/442).fork()).ldelim();
                if (message.getAvailableOrderStatusesList != null && Object.hasOwnProperty.call(message, "getAvailableOrderStatusesList"))
                    $root.fastcity.admin.GetAvailableOrderStatusesList.encode(message.getAvailableOrderStatusesList, writer.uint32(/* id 56, wireType 2 =*/450).fork()).ldelim();
                if (message.getServerVersion != null && Object.hasOwnProperty.call(message, "getServerVersion"))
                    $root.fastcity.admin.GetServerVersion.encode(message.getServerVersion, writer.uint32(/* id 59, wireType 2 =*/474).fork()).ldelim();
                if (message.deleteSubCompanyZone != null && Object.hasOwnProperty.call(message, "deleteSubCompanyZone"))
                    $root.fastcity.admin.DeleteSubCompanyZone.encode(message.deleteSubCompanyZone, writer.uint32(/* id 60, wireType 2 =*/482).fork()).ldelim();
                if (message.recoverSubCompanyZone != null && Object.hasOwnProperty.call(message, "recoverSubCompanyZone"))
                    $root.fastcity.admin.RecoverSubCompanyZone.encode(message.recoverSubCompanyZone, writer.uint32(/* id 61, wireType 2 =*/490).fork()).ldelim();
                if (message.getOrderUpdateList != null && Object.hasOwnProperty.call(message, "getOrderUpdateList"))
                    $root.fastcity.admin.GetOrderUpdateList.encode(message.getOrderUpdateList, writer.uint32(/* id 62, wireType 2 =*/498).fork()).ldelim();
                if (message.getCouriersFromExternal != null && Object.hasOwnProperty.call(message, "getCouriersFromExternal"))
                    $root.fastcity.admin.GetCouriersFromExternal.encode(message.getCouriersFromExternal, writer.uint32(/* id 63, wireType 2 =*/506).fork()).ldelim();
                if (message.getSubCompaniesFromExternal != null && Object.hasOwnProperty.call(message, "getSubCompaniesFromExternal"))
                    $root.fastcity.admin.GetSubCompaniesFromExternal.encode(message.getSubCompaniesFromExternal, writer.uint32(/* id 64, wireType 2 =*/514).fork()).ldelim();
                if (message.getIntegrationConnectionStatus != null && Object.hasOwnProperty.call(message, "getIntegrationConnectionStatus"))
                    $root.fastcity.admin.GetIntegrationConnectionStatus.encode(message.getIntegrationConnectionStatus, writer.uint32(/* id 66, wireType 2 =*/530).fork()).ldelim();
                if (message.getIntegrationConnectionStatusList != null && Object.hasOwnProperty.call(message, "getIntegrationConnectionStatusList"))
                    $root.fastcity.admin.GetIntegrationConnectionStatusList.encode(message.getIntegrationConnectionStatusList, writer.uint32(/* id 67, wireType 2 =*/538).fork()).ldelim();
                if (message.getLogRecordList != null && Object.hasOwnProperty.call(message, "getLogRecordList"))
                    $root.fastcity.admin.GetLogRecordList.encode(message.getLogRecordList, writer.uint32(/* id 68, wireType 2 =*/546).fork()).ldelim();
                if (message.getOrderPath != null && Object.hasOwnProperty.call(message, "getOrderPath"))
                    $root.fastcity.common.GetOrderPath.encode(message.getOrderPath, writer.uint32(/* id 69, wireType 2 =*/554).fork()).ldelim();
                if (message.getOrderPathList != null && Object.hasOwnProperty.call(message, "getOrderPathList"))
                    $root.fastcity.common.GetOrderPathList.encode(message.getOrderPathList, writer.uint32(/* id 70, wireType 2 =*/562).fork()).ldelim();
                if (message.createSubCompanyIdToExternalCourierId != null && Object.hasOwnProperty.call(message, "createSubCompanyIdToExternalCourierId"))
                    $root.fastcity.admin.CreateSubCompanyIdToExternalCourierId.encode(message.createSubCompanyIdToExternalCourierId, writer.uint32(/* id 71, wireType 2 =*/570).fork()).ldelim();
                if (message.updateSubCompanyIdToExternalCourierId != null && Object.hasOwnProperty.call(message, "updateSubCompanyIdToExternalCourierId"))
                    $root.fastcity.admin.UpdateSubCompanyIdToExternalCourierId.encode(message.updateSubCompanyIdToExternalCourierId, writer.uint32(/* id 72, wireType 2 =*/578).fork()).ldelim();
                if (message.removeSubCompanyIdToExternalCourierId != null && Object.hasOwnProperty.call(message, "removeSubCompanyIdToExternalCourierId"))
                    $root.fastcity.admin.RemoveSubCompanyIdToExternalCourierId.encode(message.removeSubCompanyIdToExternalCourierId, writer.uint32(/* id 73, wireType 2 =*/586).fork()).ldelim();
                if (message.getSubCompanyIdToExternalCourierIdList != null && Object.hasOwnProperty.call(message, "getSubCompanyIdToExternalCourierIdList"))
                    $root.fastcity.admin.GetSubCompanyIdToExternalCourierIdList.encode(message.getSubCompanyIdToExternalCourierIdList, writer.uint32(/* id 74, wireType 2 =*/594).fork()).ldelim();
                if (message.changeOrderStatus != null && Object.hasOwnProperty.call(message, "changeOrderStatus"))
                    $root.fastcity.admin.ChangeOrderStatus.encode(message.changeOrderStatus, writer.uint32(/* id 75, wireType 2 =*/602).fork()).ldelim();
                if (message.getServerConfig != null && Object.hasOwnProperty.call(message, "getServerConfig"))
                    $root.fastcity.admin.GetServerConfig.encode(message.getServerConfig, writer.uint32(/* id 76, wireType 2 =*/610).fork()).ldelim();
                if (message.updateServerConfig != null && Object.hasOwnProperty.call(message, "updateServerConfig"))
                    $root.fastcity.admin.UpdateServerConfig.encode(message.updateServerConfig, writer.uint32(/* id 77, wireType 2 =*/618).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a ClientMessage message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.ClientMessage
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.ClientMessage} ClientMessage
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ClientMessage.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.ClientMessage();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.requestId = reader.uint64();
                        break;
                    case 2:
                        message.getCurrentAdmin = $root.fastcity.admin.GetCurrentAdmin.decode(reader, reader.uint32());
                        break;
                    case 3:
                        message.getAdminList = $root.fastcity.admin.GetAdminList.decode(reader, reader.uint32());
                        break;
                    case 4:
                        message.createAdmin = $root.fastcity.admin.CreateAdmin.decode(reader, reader.uint32());
                        break;
                    case 5:
                        message.updateAdmin = $root.fastcity.admin.UpdateAdmin.decode(reader, reader.uint32());
                        break;
                    case 31:
                        message.deleteAdmin = $root.fastcity.admin.DeleteAdmin.decode(reader, reader.uint32());
                        break;
                    case 6:
                        message.getCurrentCompany = $root.fastcity.admin.GetCurrentCompany.decode(reader, reader.uint32());
                        break;
                    case 7:
                        message.getCompanyList = $root.fastcity.admin.GetCompanyList.decode(reader, reader.uint32());
                        break;
                    case 8:
                        message.createCompany = $root.fastcity.admin.CreateCompany.decode(reader, reader.uint32());
                        break;
                    case 9:
                        message.updateCompany = $root.fastcity.admin.UpdateCompany.decode(reader, reader.uint32());
                        break;
                    case 32:
                        message.deleteCompany = $root.fastcity.admin.DeleteCompany.decode(reader, reader.uint32());
                        break;
                    case 10:
                        message.getCourierList = $root.fastcity.admin.GetCourierList.decode(reader, reader.uint32());
                        break;
                    case 11:
                        message.createCourier = $root.fastcity.admin.CreateCourier.decode(reader, reader.uint32());
                        break;
                    case 38:
                        message.updateCourier = $root.fastcity.admin.UpdateCourier.decode(reader, reader.uint32());
                        break;
                    case 17:
                        message.setCourierGroup = $root.fastcity.admin.SetCourierGroup.decode(reader, reader.uint32());
                        break;
                    case 29:
                        message.setCourierIsDeleted = $root.fastcity.admin.SetCourierIsDeleted.decode(reader, reader.uint32());
                        break;
                    case 12:
                        message.getGroupList = $root.fastcity.admin.GetGroupList.decode(reader, reader.uint32());
                        break;
                    case 13:
                        message.createGroup = $root.fastcity.admin.CreateGroup.decode(reader, reader.uint32());
                        break;
                    case 23:
                        message.deleteGroup = $root.fastcity.admin.DeleteGroup.decode(reader, reader.uint32());
                        break;
                    case 24:
                        message.updateGroup = $root.fastcity.admin.UpdateGroup.decode(reader, reader.uint32());
                        break;
                    case 14:
                        message.getLastLocationList = $root.fastcity.admin.GetLastLocationList.decode(reader, reader.uint32());
                        break;
                    case 15:
                        message.getLocationList = $root.fastcity.admin.GetLocationList.decode(reader, reader.uint32());
                        break;
                    case 16:
                        message.getOrderList = $root.fastcity.admin.GetOrderList.decode(reader, reader.uint32());
                        break;
                    case 18:
                        message.getConnectionStatusList = $root.fastcity.admin.GetConnectionStatusList.decode(reader, reader.uint32());
                        break;
                    case 19:
                        message.createIntegrationToken = $root.fastcity.admin.CreateIntegrationToken.decode(reader, reader.uint32());
                        break;
                    case 20:
                        message.deleteIntegrationToken = $root.fastcity.admin.DeleteIntegrationToken.decode(reader, reader.uint32());
                        break;
                    case 21:
                        message.getIntegrationTokenList = $root.fastcity.admin.GetIntegrationTokenList.decode(reader, reader.uint32());
                        break;
                    case 22:
                        message.logon = $root.fastcity.admin.Logon.decode(reader, reader.uint32());
                        break;
                    case 34:
                        message.resetPassword = $root.fastcity.admin.ResetPassword.decode(reader, reader.uint32());
                        break;
                    case 25:
                        message.getDeliveryStatDayList = $root.fastcity.admin.GetDeliveryStatDayList.decode(reader, reader.uint32());
                        break;
                    case 26:
                        message.getDeliveryStatList = $root.fastcity.admin.GetDeliveryStatList.decode(reader, reader.uint32());
                        break;
                    case 27:
                        message.getWorkingDayList = $root.fastcity.admin.GetWorkingDayList.decode(reader, reader.uint32());
                        break;
                    case 28:
                        message.getSubCompanyIdList = $root.fastcity.admin.GetSubCompanyIdList.decode(reader, reader.uint32());
                        break;
                    case 30:
                        message.getSubCompanyList = $root.fastcity.admin.GetSubCompanyList.decode(reader, reader.uint32());
                        break;
                    case 33:
                        message.deleteSubCompany = $root.fastcity.admin.DeleteSubCompany.decode(reader, reader.uint32());
                        break;
                    case 43:
                        message.createSubCompany = $root.fastcity.admin.CreateSubCompany.decode(reader, reader.uint32());
                        break;
                    case 36:
                        message.updateSubCompany = $root.fastcity.admin.UpdateSubCompany.decode(reader, reader.uint32());
                        break;
                    case 35:
                        message.activate = $root.fastcity.admin.Activate.decode(reader, reader.uint32());
                        break;
                    case 39:
                        message.createSubCompanyZone = $root.fastcity.admin.CreateSubCompanyZone.decode(reader, reader.uint32());
                        break;
                    case 46:
                        message.createSubCompanyZoneFromKml = $root.fastcity.admin.CreateSubCompanyZoneFromKML.decode(reader, reader.uint32());
                        break;
                    case 60:
                        message.deleteSubCompanyZone = $root.fastcity.admin.DeleteSubCompanyZone.decode(reader, reader.uint32());
                        break;
                    case 61:
                        message.recoverSubCompanyZone = $root.fastcity.admin.RecoverSubCompanyZone.decode(reader, reader.uint32());
                        break;
                    case 40:
                        message.updateSubCompanyZone = $root.fastcity.admin.UpdateSubCompanyZone.decode(reader, reader.uint32());
                        break;
                    case 54:
                        message.updateSubCompanyZoneUsingKml = $root.fastcity.admin.UpdateSubCompanyZoneUsingKML.decode(reader, reader.uint32());
                        break;
                    case 41:
                        message.getSubCompanyZone = $root.fastcity.admin.GetSubCompanyZone.decode(reader, reader.uint32());
                        break;
                    case 42:
                        message.getSubCompanyZoneList = $root.fastcity.admin.GetSubCompanyZoneList.decode(reader, reader.uint32());
                        break;
                    case 44:
                        message.createAvailableOrderStatuses = $root.fastcity.admin.CreateAvailableOrderStatuses.decode(reader, reader.uint32());
                        break;
                    case 45:
                        message.updateAvailableOrderStatuses = $root.fastcity.admin.UpdateAvailableOrderStatuses.decode(reader, reader.uint32());
                        break;
                    case 55:
                        message.getAvailableOrderStatuses = $root.fastcity.admin.GetAvailableOrderStatuses.decode(reader, reader.uint32());
                        break;
                    case 56:
                        message.getAvailableOrderStatusesList = $root.fastcity.admin.GetAvailableOrderStatusesList.decode(reader, reader.uint32());
                        break;
                    case 47:
                        message.createExternalAuthToken = $root.fastcity.admin.CreateExternalAuthToken.decode(reader, reader.uint32());
                        break;
                    case 48:
                        message.updateExternalAuthToken = $root.fastcity.admin.UpdateExternalAuthToken.decode(reader, reader.uint32());
                        break;
                    case 49:
                        message.deleteExternalAuthToken = $root.fastcity.admin.DeleteExternalAuthToken.decode(reader, reader.uint32());
                        break;
                    case 50:
                        message.getActiveExternalAuthToken = $root.fastcity.admin.GetActiveExternalAuthToken.decode(reader, reader.uint32());
                        break;
                    case 51:
                        message.getExternalAuthTokenList = $root.fastcity.admin.GetExternalAuthTokenList.decode(reader, reader.uint32());
                        break;
                    case 52:
                        message.bindExternalAuthTokenToSubCompany = $root.fastcity.admin.BindExternalAuthTokenToSubCompany.decode(reader, reader.uint32());
                        break;
                    case 66:
                        message.getIntegrationConnectionStatus = $root.fastcity.admin.GetIntegrationConnectionStatus.decode(reader, reader.uint32());
                        break;
                    case 67:
                        message.getIntegrationConnectionStatusList = $root.fastcity.admin.GetIntegrationConnectionStatusList.decode(reader, reader.uint32());
                        break;
                    case 63:
                        message.getCouriersFromExternal = $root.fastcity.admin.GetCouriersFromExternal.decode(reader, reader.uint32());
                        break;
                    case 64:
                        message.getSubCompaniesFromExternal = $root.fastcity.admin.GetSubCompaniesFromExternal.decode(reader, reader.uint32());
                        break;
                    case 59:
                        message.getServerVersion = $root.fastcity.admin.GetServerVersion.decode(reader, reader.uint32());
                        break;
                    case 62:
                        message.getOrderUpdateList = $root.fastcity.admin.GetOrderUpdateList.decode(reader, reader.uint32());
                        break;
                    case 68:
                        message.getLogRecordList = $root.fastcity.admin.GetLogRecordList.decode(reader, reader.uint32());
                        break;
                    case 69:
                        message.getOrderPath = $root.fastcity.common.GetOrderPath.decode(reader, reader.uint32());
                        break;
                    case 70:
                        message.getOrderPathList = $root.fastcity.common.GetOrderPathList.decode(reader, reader.uint32());
                        break;
                    case 71:
                        message.createSubCompanyIdToExternalCourierId = $root.fastcity.admin.CreateSubCompanyIdToExternalCourierId.decode(reader, reader.uint32());
                        break;
                    case 72:
                        message.updateSubCompanyIdToExternalCourierId = $root.fastcity.admin.UpdateSubCompanyIdToExternalCourierId.decode(reader, reader.uint32());
                        break;
                    case 73:
                        message.removeSubCompanyIdToExternalCourierId = $root.fastcity.admin.RemoveSubCompanyIdToExternalCourierId.decode(reader, reader.uint32());
                        break;
                    case 74:
                        message.getSubCompanyIdToExternalCourierIdList = $root.fastcity.admin.GetSubCompanyIdToExternalCourierIdList.decode(reader, reader.uint32());
                        break;
                    case 75:
                        message.changeOrderStatus = $root.fastcity.admin.ChangeOrderStatus.decode(reader, reader.uint32());
                        break;
                    case 76:
                        message.getServerConfig = $root.fastcity.admin.GetServerConfig.decode(reader, reader.uint32());
                        break;
                    case 77:
                        message.updateServerConfig = $root.fastcity.admin.UpdateServerConfig.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a ClientMessage message.
             * @function verify
             * @memberof fastcity.admin.ClientMessage
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ClientMessage.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                var properties = {};
                if (message.requestId != null && message.hasOwnProperty("requestId"))
                    if (!$util.isInteger(message.requestId) && !(message.requestId && $util.isInteger(message.requestId.low) && $util.isInteger(message.requestId.high)))
                        return "requestId: integer|Long expected";
                if (message.getCurrentAdmin != null && message.hasOwnProperty("getCurrentAdmin")) {
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.GetCurrentAdmin.verify(message.getCurrentAdmin);
                        if (error)
                            return "getCurrentAdmin." + error;
                    }
                }
                if (message.getAdminList != null && message.hasOwnProperty("getAdminList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.GetAdminList.verify(message.getAdminList);
                        if (error)
                            return "getAdminList." + error;
                    }
                }
                if (message.createAdmin != null && message.hasOwnProperty("createAdmin")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.CreateAdmin.verify(message.createAdmin);
                        if (error)
                            return "createAdmin." + error;
                    }
                }
                if (message.updateAdmin != null && message.hasOwnProperty("updateAdmin")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.UpdateAdmin.verify(message.updateAdmin);
                        if (error)
                            return "updateAdmin." + error;
                    }
                }
                if (message.deleteAdmin != null && message.hasOwnProperty("deleteAdmin")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.DeleteAdmin.verify(message.deleteAdmin);
                        if (error)
                            return "deleteAdmin." + error;
                    }
                }
                if (message.getCurrentCompany != null && message.hasOwnProperty("getCurrentCompany")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.GetCurrentCompany.verify(message.getCurrentCompany);
                        if (error)
                            return "getCurrentCompany." + error;
                    }
                }
                if (message.getCompanyList != null && message.hasOwnProperty("getCompanyList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.GetCompanyList.verify(message.getCompanyList);
                        if (error)
                            return "getCompanyList." + error;
                    }
                }
                if (message.createCompany != null && message.hasOwnProperty("createCompany")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.CreateCompany.verify(message.createCompany);
                        if (error)
                            return "createCompany." + error;
                    }
                }
                if (message.updateCompany != null && message.hasOwnProperty("updateCompany")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.UpdateCompany.verify(message.updateCompany);
                        if (error)
                            return "updateCompany." + error;
                    }
                }
                if (message.deleteCompany != null && message.hasOwnProperty("deleteCompany")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.DeleteCompany.verify(message.deleteCompany);
                        if (error)
                            return "deleteCompany." + error;
                    }
                }
                if (message.getCourierList != null && message.hasOwnProperty("getCourierList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.GetCourierList.verify(message.getCourierList);
                        if (error)
                            return "getCourierList." + error;
                    }
                }
                if (message.createCourier != null && message.hasOwnProperty("createCourier")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.CreateCourier.verify(message.createCourier);
                        if (error)
                            return "createCourier." + error;
                    }
                }
                if (message.updateCourier != null && message.hasOwnProperty("updateCourier")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.UpdateCourier.verify(message.updateCourier);
                        if (error)
                            return "updateCourier." + error;
                    }
                }
                if (message.setCourierGroup != null && message.hasOwnProperty("setCourierGroup")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.SetCourierGroup.verify(message.setCourierGroup);
                        if (error)
                            return "setCourierGroup." + error;
                    }
                }
                if (message.setCourierIsDeleted != null && message.hasOwnProperty("setCourierIsDeleted")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.SetCourierIsDeleted.verify(message.setCourierIsDeleted);
                        if (error)
                            return "setCourierIsDeleted." + error;
                    }
                }
                if (message.getGroupList != null && message.hasOwnProperty("getGroupList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.GetGroupList.verify(message.getGroupList);
                        if (error)
                            return "getGroupList." + error;
                    }
                }
                if (message.createGroup != null && message.hasOwnProperty("createGroup")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.CreateGroup.verify(message.createGroup);
                        if (error)
                            return "createGroup." + error;
                    }
                }
                if (message.deleteGroup != null && message.hasOwnProperty("deleteGroup")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.DeleteGroup.verify(message.deleteGroup);
                        if (error)
                            return "deleteGroup." + error;
                    }
                }
                if (message.updateGroup != null && message.hasOwnProperty("updateGroup")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.UpdateGroup.verify(message.updateGroup);
                        if (error)
                            return "updateGroup." + error;
                    }
                }
                if (message.getLastLocationList != null && message.hasOwnProperty("getLastLocationList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.GetLastLocationList.verify(message.getLastLocationList);
                        if (error)
                            return "getLastLocationList." + error;
                    }
                }
                if (message.getLocationList != null && message.hasOwnProperty("getLocationList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.GetLocationList.verify(message.getLocationList);
                        if (error)
                            return "getLocationList." + error;
                    }
                }
                if (message.getOrderList != null && message.hasOwnProperty("getOrderList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.GetOrderList.verify(message.getOrderList);
                        if (error)
                            return "getOrderList." + error;
                    }
                }
                if (message.getConnectionStatusList != null && message.hasOwnProperty("getConnectionStatusList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.GetConnectionStatusList.verify(message.getConnectionStatusList);
                        if (error)
                            return "getConnectionStatusList." + error;
                    }
                }
                if (message.createIntegrationToken != null && message.hasOwnProperty("createIntegrationToken")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.CreateIntegrationToken.verify(message.createIntegrationToken);
                        if (error)
                            return "createIntegrationToken." + error;
                    }
                }
                if (message.deleteIntegrationToken != null && message.hasOwnProperty("deleteIntegrationToken")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.DeleteIntegrationToken.verify(message.deleteIntegrationToken);
                        if (error)
                            return "deleteIntegrationToken." + error;
                    }
                }
                if (message.getIntegrationTokenList != null && message.hasOwnProperty("getIntegrationTokenList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.GetIntegrationTokenList.verify(message.getIntegrationTokenList);
                        if (error)
                            return "getIntegrationTokenList." + error;
                    }
                }
                if (message.logon != null && message.hasOwnProperty("logon")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.Logon.verify(message.logon);
                        if (error)
                            return "logon." + error;
                    }
                }
                if (message.resetPassword != null && message.hasOwnProperty("resetPassword")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.ResetPassword.verify(message.resetPassword);
                        if (error)
                            return "resetPassword." + error;
                    }
                }
                if (message.getDeliveryStatDayList != null && message.hasOwnProperty("getDeliveryStatDayList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.GetDeliveryStatDayList.verify(message.getDeliveryStatDayList);
                        if (error)
                            return "getDeliveryStatDayList." + error;
                    }
                }
                if (message.getDeliveryStatList != null && message.hasOwnProperty("getDeliveryStatList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.GetDeliveryStatList.verify(message.getDeliveryStatList);
                        if (error)
                            return "getDeliveryStatList." + error;
                    }
                }
                if (message.getWorkingDayList != null && message.hasOwnProperty("getWorkingDayList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.GetWorkingDayList.verify(message.getWorkingDayList);
                        if (error)
                            return "getWorkingDayList." + error;
                    }
                }
                if (message.getSubCompanyIdList != null && message.hasOwnProperty("getSubCompanyIdList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.GetSubCompanyIdList.verify(message.getSubCompanyIdList);
                        if (error)
                            return "getSubCompanyIdList." + error;
                    }
                }
                if (message.getSubCompanyList != null && message.hasOwnProperty("getSubCompanyList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.GetSubCompanyList.verify(message.getSubCompanyList);
                        if (error)
                            return "getSubCompanyList." + error;
                    }
                }
                if (message.deleteSubCompany != null && message.hasOwnProperty("deleteSubCompany")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.DeleteSubCompany.verify(message.deleteSubCompany);
                        if (error)
                            return "deleteSubCompany." + error;
                    }
                }
                if (message.createSubCompany != null && message.hasOwnProperty("createSubCompany")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.CreateSubCompany.verify(message.createSubCompany);
                        if (error)
                            return "createSubCompany." + error;
                    }
                }
                if (message.updateSubCompany != null && message.hasOwnProperty("updateSubCompany")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.UpdateSubCompany.verify(message.updateSubCompany);
                        if (error)
                            return "updateSubCompany." + error;
                    }
                }
                if (message.activate != null && message.hasOwnProperty("activate")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.Activate.verify(message.activate);
                        if (error)
                            return "activate." + error;
                    }
                }
                if (message.createSubCompanyZone != null && message.hasOwnProperty("createSubCompanyZone")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.CreateSubCompanyZone.verify(message.createSubCompanyZone);
                        if (error)
                            return "createSubCompanyZone." + error;
                    }
                }
                if (message.createSubCompanyZoneFromKml != null && message.hasOwnProperty("createSubCompanyZoneFromKml")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.CreateSubCompanyZoneFromKML.verify(message.createSubCompanyZoneFromKml);
                        if (error)
                            return "createSubCompanyZoneFromKml." + error;
                    }
                }
                if (message.deleteSubCompanyZone != null && message.hasOwnProperty("deleteSubCompanyZone")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.DeleteSubCompanyZone.verify(message.deleteSubCompanyZone);
                        if (error)
                            return "deleteSubCompanyZone." + error;
                    }
                }
                if (message.recoverSubCompanyZone != null && message.hasOwnProperty("recoverSubCompanyZone")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.RecoverSubCompanyZone.verify(message.recoverSubCompanyZone);
                        if (error)
                            return "recoverSubCompanyZone." + error;
                    }
                }
                if (message.updateSubCompanyZone != null && message.hasOwnProperty("updateSubCompanyZone")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.UpdateSubCompanyZone.verify(message.updateSubCompanyZone);
                        if (error)
                            return "updateSubCompanyZone." + error;
                    }
                }
                if (message.updateSubCompanyZoneUsingKml != null && message.hasOwnProperty("updateSubCompanyZoneUsingKml")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.UpdateSubCompanyZoneUsingKML.verify(message.updateSubCompanyZoneUsingKml);
                        if (error)
                            return "updateSubCompanyZoneUsingKml." + error;
                    }
                }
                if (message.getSubCompanyZone != null && message.hasOwnProperty("getSubCompanyZone")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.GetSubCompanyZone.verify(message.getSubCompanyZone);
                        if (error)
                            return "getSubCompanyZone." + error;
                    }
                }
                if (message.getSubCompanyZoneList != null && message.hasOwnProperty("getSubCompanyZoneList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.GetSubCompanyZoneList.verify(message.getSubCompanyZoneList);
                        if (error)
                            return "getSubCompanyZoneList." + error;
                    }
                }
                if (message.createAvailableOrderStatuses != null && message.hasOwnProperty("createAvailableOrderStatuses")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.CreateAvailableOrderStatuses.verify(message.createAvailableOrderStatuses);
                        if (error)
                            return "createAvailableOrderStatuses." + error;
                    }
                }
                if (message.updateAvailableOrderStatuses != null && message.hasOwnProperty("updateAvailableOrderStatuses")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.UpdateAvailableOrderStatuses.verify(message.updateAvailableOrderStatuses);
                        if (error)
                            return "updateAvailableOrderStatuses." + error;
                    }
                }
                if (message.getAvailableOrderStatuses != null && message.hasOwnProperty("getAvailableOrderStatuses")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.GetAvailableOrderStatuses.verify(message.getAvailableOrderStatuses);
                        if (error)
                            return "getAvailableOrderStatuses." + error;
                    }
                }
                if (message.getAvailableOrderStatusesList != null && message.hasOwnProperty("getAvailableOrderStatusesList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.GetAvailableOrderStatusesList.verify(message.getAvailableOrderStatusesList);
                        if (error)
                            return "getAvailableOrderStatusesList." + error;
                    }
                }
                if (message.createExternalAuthToken != null && message.hasOwnProperty("createExternalAuthToken")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.CreateExternalAuthToken.verify(message.createExternalAuthToken);
                        if (error)
                            return "createExternalAuthToken." + error;
                    }
                }
                if (message.updateExternalAuthToken != null && message.hasOwnProperty("updateExternalAuthToken")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.UpdateExternalAuthToken.verify(message.updateExternalAuthToken);
                        if (error)
                            return "updateExternalAuthToken." + error;
                    }
                }
                if (message.deleteExternalAuthToken != null && message.hasOwnProperty("deleteExternalAuthToken")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.DeleteExternalAuthToken.verify(message.deleteExternalAuthToken);
                        if (error)
                            return "deleteExternalAuthToken." + error;
                    }
                }
                if (message.getActiveExternalAuthToken != null && message.hasOwnProperty("getActiveExternalAuthToken")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.GetActiveExternalAuthToken.verify(message.getActiveExternalAuthToken);
                        if (error)
                            return "getActiveExternalAuthToken." + error;
                    }
                }
                if (message.getExternalAuthTokenList != null && message.hasOwnProperty("getExternalAuthTokenList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.GetExternalAuthTokenList.verify(message.getExternalAuthTokenList);
                        if (error)
                            return "getExternalAuthTokenList." + error;
                    }
                }
                if (message.bindExternalAuthTokenToSubCompany != null && message.hasOwnProperty("bindExternalAuthTokenToSubCompany")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.BindExternalAuthTokenToSubCompany.verify(message.bindExternalAuthTokenToSubCompany);
                        if (error)
                            return "bindExternalAuthTokenToSubCompany." + error;
                    }
                }
                if (message.getIntegrationConnectionStatus != null && message.hasOwnProperty("getIntegrationConnectionStatus")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.GetIntegrationConnectionStatus.verify(message.getIntegrationConnectionStatus);
                        if (error)
                            return "getIntegrationConnectionStatus." + error;
                    }
                }
                if (message.getIntegrationConnectionStatusList != null && message.hasOwnProperty("getIntegrationConnectionStatusList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.GetIntegrationConnectionStatusList.verify(message.getIntegrationConnectionStatusList);
                        if (error)
                            return "getIntegrationConnectionStatusList." + error;
                    }
                }
                if (message.getCouriersFromExternal != null && message.hasOwnProperty("getCouriersFromExternal")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.GetCouriersFromExternal.verify(message.getCouriersFromExternal);
                        if (error)
                            return "getCouriersFromExternal." + error;
                    }
                }
                if (message.getSubCompaniesFromExternal != null && message.hasOwnProperty("getSubCompaniesFromExternal")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.GetSubCompaniesFromExternal.verify(message.getSubCompaniesFromExternal);
                        if (error)
                            return "getSubCompaniesFromExternal." + error;
                    }
                }
                if (message.getServerVersion != null && message.hasOwnProperty("getServerVersion")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.GetServerVersion.verify(message.getServerVersion);
                        if (error)
                            return "getServerVersion." + error;
                    }
                }
                if (message.getOrderUpdateList != null && message.hasOwnProperty("getOrderUpdateList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.GetOrderUpdateList.verify(message.getOrderUpdateList);
                        if (error)
                            return "getOrderUpdateList." + error;
                    }
                }
                if (message.getLogRecordList != null && message.hasOwnProperty("getLogRecordList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.GetLogRecordList.verify(message.getLogRecordList);
                        if (error)
                            return "getLogRecordList." + error;
                    }
                }
                if (message.getOrderPath != null && message.hasOwnProperty("getOrderPath")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.common.GetOrderPath.verify(message.getOrderPath);
                        if (error)
                            return "getOrderPath." + error;
                    }
                }
                if (message.getOrderPathList != null && message.hasOwnProperty("getOrderPathList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.common.GetOrderPathList.verify(message.getOrderPathList);
                        if (error)
                            return "getOrderPathList." + error;
                    }
                }
                if (message.createSubCompanyIdToExternalCourierId != null && message.hasOwnProperty("createSubCompanyIdToExternalCourierId")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.CreateSubCompanyIdToExternalCourierId.verify(message.createSubCompanyIdToExternalCourierId);
                        if (error)
                            return "createSubCompanyIdToExternalCourierId." + error;
                    }
                }
                if (message.updateSubCompanyIdToExternalCourierId != null && message.hasOwnProperty("updateSubCompanyIdToExternalCourierId")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.UpdateSubCompanyIdToExternalCourierId.verify(message.updateSubCompanyIdToExternalCourierId);
                        if (error)
                            return "updateSubCompanyIdToExternalCourierId." + error;
                    }
                }
                if (message.removeSubCompanyIdToExternalCourierId != null && message.hasOwnProperty("removeSubCompanyIdToExternalCourierId")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.RemoveSubCompanyIdToExternalCourierId.verify(message.removeSubCompanyIdToExternalCourierId);
                        if (error)
                            return "removeSubCompanyIdToExternalCourierId." + error;
                    }
                }
                if (message.getSubCompanyIdToExternalCourierIdList != null && message.hasOwnProperty("getSubCompanyIdToExternalCourierIdList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.GetSubCompanyIdToExternalCourierIdList.verify(message.getSubCompanyIdToExternalCourierIdList);
                        if (error)
                            return "getSubCompanyIdToExternalCourierIdList." + error;
                    }
                }
                if (message.changeOrderStatus != null && message.hasOwnProperty("changeOrderStatus")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.ChangeOrderStatus.verify(message.changeOrderStatus);
                        if (error)
                            return "changeOrderStatus." + error;
                    }
                }
                if (message.getServerConfig != null && message.hasOwnProperty("getServerConfig")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.GetServerConfig.verify(message.getServerConfig);
                        if (error)
                            return "getServerConfig." + error;
                    }
                }
                if (message.updateServerConfig != null && message.hasOwnProperty("updateServerConfig")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.UpdateServerConfig.verify(message.updateServerConfig);
                        if (error)
                            return "updateServerConfig." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a ClientMessage message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.ClientMessage
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.ClientMessage} ClientMessage
             */
            ClientMessage.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.ClientMessage)
                    return object;
                var message = new $root.fastcity.admin.ClientMessage();
                if (object.requestId != null)
                    if ($util.Long)
                        (message.requestId = $util.Long.fromValue(object.requestId)).unsigned = true;
                    else if (typeof object.requestId === "string")
                        message.requestId = parseInt(object.requestId, 10);
                    else if (typeof object.requestId === "number")
                        message.requestId = object.requestId;
                    else if (typeof object.requestId === "object")
                        message.requestId = new $util.LongBits(object.requestId.low >>> 0, object.requestId.high >>> 0).toNumber(true);
                if (object.getCurrentAdmin != null) {
                    if (typeof object.getCurrentAdmin !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.getCurrentAdmin: object expected");
                    message.getCurrentAdmin = $root.fastcity.admin.GetCurrentAdmin.fromObject(object.getCurrentAdmin);
                }
                if (object.getAdminList != null) {
                    if (typeof object.getAdminList !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.getAdminList: object expected");
                    message.getAdminList = $root.fastcity.admin.GetAdminList.fromObject(object.getAdminList);
                }
                if (object.createAdmin != null) {
                    if (typeof object.createAdmin !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.createAdmin: object expected");
                    message.createAdmin = $root.fastcity.admin.CreateAdmin.fromObject(object.createAdmin);
                }
                if (object.updateAdmin != null) {
                    if (typeof object.updateAdmin !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.updateAdmin: object expected");
                    message.updateAdmin = $root.fastcity.admin.UpdateAdmin.fromObject(object.updateAdmin);
                }
                if (object.deleteAdmin != null) {
                    if (typeof object.deleteAdmin !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.deleteAdmin: object expected");
                    message.deleteAdmin = $root.fastcity.admin.DeleteAdmin.fromObject(object.deleteAdmin);
                }
                if (object.getCurrentCompany != null) {
                    if (typeof object.getCurrentCompany !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.getCurrentCompany: object expected");
                    message.getCurrentCompany = $root.fastcity.admin.GetCurrentCompany.fromObject(object.getCurrentCompany);
                }
                if (object.getCompanyList != null) {
                    if (typeof object.getCompanyList !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.getCompanyList: object expected");
                    message.getCompanyList = $root.fastcity.admin.GetCompanyList.fromObject(object.getCompanyList);
                }
                if (object.createCompany != null) {
                    if (typeof object.createCompany !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.createCompany: object expected");
                    message.createCompany = $root.fastcity.admin.CreateCompany.fromObject(object.createCompany);
                }
                if (object.updateCompany != null) {
                    if (typeof object.updateCompany !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.updateCompany: object expected");
                    message.updateCompany = $root.fastcity.admin.UpdateCompany.fromObject(object.updateCompany);
                }
                if (object.deleteCompany != null) {
                    if (typeof object.deleteCompany !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.deleteCompany: object expected");
                    message.deleteCompany = $root.fastcity.admin.DeleteCompany.fromObject(object.deleteCompany);
                }
                if (object.getCourierList != null) {
                    if (typeof object.getCourierList !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.getCourierList: object expected");
                    message.getCourierList = $root.fastcity.admin.GetCourierList.fromObject(object.getCourierList);
                }
                if (object.createCourier != null) {
                    if (typeof object.createCourier !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.createCourier: object expected");
                    message.createCourier = $root.fastcity.admin.CreateCourier.fromObject(object.createCourier);
                }
                if (object.updateCourier != null) {
                    if (typeof object.updateCourier !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.updateCourier: object expected");
                    message.updateCourier = $root.fastcity.admin.UpdateCourier.fromObject(object.updateCourier);
                }
                if (object.setCourierGroup != null) {
                    if (typeof object.setCourierGroup !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.setCourierGroup: object expected");
                    message.setCourierGroup = $root.fastcity.admin.SetCourierGroup.fromObject(object.setCourierGroup);
                }
                if (object.setCourierIsDeleted != null) {
                    if (typeof object.setCourierIsDeleted !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.setCourierIsDeleted: object expected");
                    message.setCourierIsDeleted = $root.fastcity.admin.SetCourierIsDeleted.fromObject(object.setCourierIsDeleted);
                }
                if (object.getGroupList != null) {
                    if (typeof object.getGroupList !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.getGroupList: object expected");
                    message.getGroupList = $root.fastcity.admin.GetGroupList.fromObject(object.getGroupList);
                }
                if (object.createGroup != null) {
                    if (typeof object.createGroup !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.createGroup: object expected");
                    message.createGroup = $root.fastcity.admin.CreateGroup.fromObject(object.createGroup);
                }
                if (object.deleteGroup != null) {
                    if (typeof object.deleteGroup !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.deleteGroup: object expected");
                    message.deleteGroup = $root.fastcity.admin.DeleteGroup.fromObject(object.deleteGroup);
                }
                if (object.updateGroup != null) {
                    if (typeof object.updateGroup !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.updateGroup: object expected");
                    message.updateGroup = $root.fastcity.admin.UpdateGroup.fromObject(object.updateGroup);
                }
                if (object.getLastLocationList != null) {
                    if (typeof object.getLastLocationList !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.getLastLocationList: object expected");
                    message.getLastLocationList = $root.fastcity.admin.GetLastLocationList.fromObject(object.getLastLocationList);
                }
                if (object.getLocationList != null) {
                    if (typeof object.getLocationList !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.getLocationList: object expected");
                    message.getLocationList = $root.fastcity.admin.GetLocationList.fromObject(object.getLocationList);
                }
                if (object.getOrderList != null) {
                    if (typeof object.getOrderList !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.getOrderList: object expected");
                    message.getOrderList = $root.fastcity.admin.GetOrderList.fromObject(object.getOrderList);
                }
                if (object.getConnectionStatusList != null) {
                    if (typeof object.getConnectionStatusList !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.getConnectionStatusList: object expected");
                    message.getConnectionStatusList = $root.fastcity.admin.GetConnectionStatusList.fromObject(object.getConnectionStatusList);
                }
                if (object.createIntegrationToken != null) {
                    if (typeof object.createIntegrationToken !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.createIntegrationToken: object expected");
                    message.createIntegrationToken = $root.fastcity.admin.CreateIntegrationToken.fromObject(object.createIntegrationToken);
                }
                if (object.deleteIntegrationToken != null) {
                    if (typeof object.deleteIntegrationToken !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.deleteIntegrationToken: object expected");
                    message.deleteIntegrationToken = $root.fastcity.admin.DeleteIntegrationToken.fromObject(object.deleteIntegrationToken);
                }
                if (object.getIntegrationTokenList != null) {
                    if (typeof object.getIntegrationTokenList !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.getIntegrationTokenList: object expected");
                    message.getIntegrationTokenList = $root.fastcity.admin.GetIntegrationTokenList.fromObject(object.getIntegrationTokenList);
                }
                if (object.logon != null) {
                    if (typeof object.logon !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.logon: object expected");
                    message.logon = $root.fastcity.admin.Logon.fromObject(object.logon);
                }
                if (object.resetPassword != null) {
                    if (typeof object.resetPassword !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.resetPassword: object expected");
                    message.resetPassword = $root.fastcity.admin.ResetPassword.fromObject(object.resetPassword);
                }
                if (object.getDeliveryStatDayList != null) {
                    if (typeof object.getDeliveryStatDayList !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.getDeliveryStatDayList: object expected");
                    message.getDeliveryStatDayList = $root.fastcity.admin.GetDeliveryStatDayList.fromObject(object.getDeliveryStatDayList);
                }
                if (object.getDeliveryStatList != null) {
                    if (typeof object.getDeliveryStatList !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.getDeliveryStatList: object expected");
                    message.getDeliveryStatList = $root.fastcity.admin.GetDeliveryStatList.fromObject(object.getDeliveryStatList);
                }
                if (object.getWorkingDayList != null) {
                    if (typeof object.getWorkingDayList !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.getWorkingDayList: object expected");
                    message.getWorkingDayList = $root.fastcity.admin.GetWorkingDayList.fromObject(object.getWorkingDayList);
                }
                if (object.getSubCompanyIdList != null) {
                    if (typeof object.getSubCompanyIdList !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.getSubCompanyIdList: object expected");
                    message.getSubCompanyIdList = $root.fastcity.admin.GetSubCompanyIdList.fromObject(object.getSubCompanyIdList);
                }
                if (object.getSubCompanyList != null) {
                    if (typeof object.getSubCompanyList !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.getSubCompanyList: object expected");
                    message.getSubCompanyList = $root.fastcity.admin.GetSubCompanyList.fromObject(object.getSubCompanyList);
                }
                if (object.deleteSubCompany != null) {
                    if (typeof object.deleteSubCompany !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.deleteSubCompany: object expected");
                    message.deleteSubCompany = $root.fastcity.admin.DeleteSubCompany.fromObject(object.deleteSubCompany);
                }
                if (object.createSubCompany != null) {
                    if (typeof object.createSubCompany !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.createSubCompany: object expected");
                    message.createSubCompany = $root.fastcity.admin.CreateSubCompany.fromObject(object.createSubCompany);
                }
                if (object.updateSubCompany != null) {
                    if (typeof object.updateSubCompany !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.updateSubCompany: object expected");
                    message.updateSubCompany = $root.fastcity.admin.UpdateSubCompany.fromObject(object.updateSubCompany);
                }
                if (object.activate != null) {
                    if (typeof object.activate !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.activate: object expected");
                    message.activate = $root.fastcity.admin.Activate.fromObject(object.activate);
                }
                if (object.createSubCompanyZone != null) {
                    if (typeof object.createSubCompanyZone !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.createSubCompanyZone: object expected");
                    message.createSubCompanyZone = $root.fastcity.admin.CreateSubCompanyZone.fromObject(object.createSubCompanyZone);
                }
                if (object.createSubCompanyZoneFromKml != null) {
                    if (typeof object.createSubCompanyZoneFromKml !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.createSubCompanyZoneFromKml: object expected");
                    message.createSubCompanyZoneFromKml = $root.fastcity.admin.CreateSubCompanyZoneFromKML.fromObject(object.createSubCompanyZoneFromKml);
                }
                if (object.deleteSubCompanyZone != null) {
                    if (typeof object.deleteSubCompanyZone !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.deleteSubCompanyZone: object expected");
                    message.deleteSubCompanyZone = $root.fastcity.admin.DeleteSubCompanyZone.fromObject(object.deleteSubCompanyZone);
                }
                if (object.recoverSubCompanyZone != null) {
                    if (typeof object.recoverSubCompanyZone !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.recoverSubCompanyZone: object expected");
                    message.recoverSubCompanyZone = $root.fastcity.admin.RecoverSubCompanyZone.fromObject(object.recoverSubCompanyZone);
                }
                if (object.updateSubCompanyZone != null) {
                    if (typeof object.updateSubCompanyZone !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.updateSubCompanyZone: object expected");
                    message.updateSubCompanyZone = $root.fastcity.admin.UpdateSubCompanyZone.fromObject(object.updateSubCompanyZone);
                }
                if (object.updateSubCompanyZoneUsingKml != null) {
                    if (typeof object.updateSubCompanyZoneUsingKml !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.updateSubCompanyZoneUsingKml: object expected");
                    message.updateSubCompanyZoneUsingKml = $root.fastcity.admin.UpdateSubCompanyZoneUsingKML.fromObject(object.updateSubCompanyZoneUsingKml);
                }
                if (object.getSubCompanyZone != null) {
                    if (typeof object.getSubCompanyZone !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.getSubCompanyZone: object expected");
                    message.getSubCompanyZone = $root.fastcity.admin.GetSubCompanyZone.fromObject(object.getSubCompanyZone);
                }
                if (object.getSubCompanyZoneList != null) {
                    if (typeof object.getSubCompanyZoneList !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.getSubCompanyZoneList: object expected");
                    message.getSubCompanyZoneList = $root.fastcity.admin.GetSubCompanyZoneList.fromObject(object.getSubCompanyZoneList);
                }
                if (object.createAvailableOrderStatuses != null) {
                    if (typeof object.createAvailableOrderStatuses !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.createAvailableOrderStatuses: object expected");
                    message.createAvailableOrderStatuses = $root.fastcity.admin.CreateAvailableOrderStatuses.fromObject(object.createAvailableOrderStatuses);
                }
                if (object.updateAvailableOrderStatuses != null) {
                    if (typeof object.updateAvailableOrderStatuses !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.updateAvailableOrderStatuses: object expected");
                    message.updateAvailableOrderStatuses = $root.fastcity.admin.UpdateAvailableOrderStatuses.fromObject(object.updateAvailableOrderStatuses);
                }
                if (object.getAvailableOrderStatuses != null) {
                    if (typeof object.getAvailableOrderStatuses !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.getAvailableOrderStatuses: object expected");
                    message.getAvailableOrderStatuses = $root.fastcity.admin.GetAvailableOrderStatuses.fromObject(object.getAvailableOrderStatuses);
                }
                if (object.getAvailableOrderStatusesList != null) {
                    if (typeof object.getAvailableOrderStatusesList !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.getAvailableOrderStatusesList: object expected");
                    message.getAvailableOrderStatusesList = $root.fastcity.admin.GetAvailableOrderStatusesList.fromObject(object.getAvailableOrderStatusesList);
                }
                if (object.createExternalAuthToken != null) {
                    if (typeof object.createExternalAuthToken !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.createExternalAuthToken: object expected");
                    message.createExternalAuthToken = $root.fastcity.admin.CreateExternalAuthToken.fromObject(object.createExternalAuthToken);
                }
                if (object.updateExternalAuthToken != null) {
                    if (typeof object.updateExternalAuthToken !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.updateExternalAuthToken: object expected");
                    message.updateExternalAuthToken = $root.fastcity.admin.UpdateExternalAuthToken.fromObject(object.updateExternalAuthToken);
                }
                if (object.deleteExternalAuthToken != null) {
                    if (typeof object.deleteExternalAuthToken !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.deleteExternalAuthToken: object expected");
                    message.deleteExternalAuthToken = $root.fastcity.admin.DeleteExternalAuthToken.fromObject(object.deleteExternalAuthToken);
                }
                if (object.getActiveExternalAuthToken != null) {
                    if (typeof object.getActiveExternalAuthToken !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.getActiveExternalAuthToken: object expected");
                    message.getActiveExternalAuthToken = $root.fastcity.admin.GetActiveExternalAuthToken.fromObject(object.getActiveExternalAuthToken);
                }
                if (object.getExternalAuthTokenList != null) {
                    if (typeof object.getExternalAuthTokenList !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.getExternalAuthTokenList: object expected");
                    message.getExternalAuthTokenList = $root.fastcity.admin.GetExternalAuthTokenList.fromObject(object.getExternalAuthTokenList);
                }
                if (object.bindExternalAuthTokenToSubCompany != null) {
                    if (typeof object.bindExternalAuthTokenToSubCompany !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.bindExternalAuthTokenToSubCompany: object expected");
                    message.bindExternalAuthTokenToSubCompany = $root.fastcity.admin.BindExternalAuthTokenToSubCompany.fromObject(object.bindExternalAuthTokenToSubCompany);
                }
                if (object.getIntegrationConnectionStatus != null) {
                    if (typeof object.getIntegrationConnectionStatus !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.getIntegrationConnectionStatus: object expected");
                    message.getIntegrationConnectionStatus = $root.fastcity.admin.GetIntegrationConnectionStatus.fromObject(object.getIntegrationConnectionStatus);
                }
                if (object.getIntegrationConnectionStatusList != null) {
                    if (typeof object.getIntegrationConnectionStatusList !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.getIntegrationConnectionStatusList: object expected");
                    message.getIntegrationConnectionStatusList = $root.fastcity.admin.GetIntegrationConnectionStatusList.fromObject(object.getIntegrationConnectionStatusList);
                }
                if (object.getCouriersFromExternal != null) {
                    if (typeof object.getCouriersFromExternal !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.getCouriersFromExternal: object expected");
                    message.getCouriersFromExternal = $root.fastcity.admin.GetCouriersFromExternal.fromObject(object.getCouriersFromExternal);
                }
                if (object.getSubCompaniesFromExternal != null) {
                    if (typeof object.getSubCompaniesFromExternal !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.getSubCompaniesFromExternal: object expected");
                    message.getSubCompaniesFromExternal = $root.fastcity.admin.GetSubCompaniesFromExternal.fromObject(object.getSubCompaniesFromExternal);
                }
                if (object.getServerVersion != null) {
                    if (typeof object.getServerVersion !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.getServerVersion: object expected");
                    message.getServerVersion = $root.fastcity.admin.GetServerVersion.fromObject(object.getServerVersion);
                }
                if (object.getOrderUpdateList != null) {
                    if (typeof object.getOrderUpdateList !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.getOrderUpdateList: object expected");
                    message.getOrderUpdateList = $root.fastcity.admin.GetOrderUpdateList.fromObject(object.getOrderUpdateList);
                }
                if (object.getLogRecordList != null) {
                    if (typeof object.getLogRecordList !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.getLogRecordList: object expected");
                    message.getLogRecordList = $root.fastcity.admin.GetLogRecordList.fromObject(object.getLogRecordList);
                }
                if (object.getOrderPath != null) {
                    if (typeof object.getOrderPath !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.getOrderPath: object expected");
                    message.getOrderPath = $root.fastcity.common.GetOrderPath.fromObject(object.getOrderPath);
                }
                if (object.getOrderPathList != null) {
                    if (typeof object.getOrderPathList !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.getOrderPathList: object expected");
                    message.getOrderPathList = $root.fastcity.common.GetOrderPathList.fromObject(object.getOrderPathList);
                }
                if (object.createSubCompanyIdToExternalCourierId != null) {
                    if (typeof object.createSubCompanyIdToExternalCourierId !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.createSubCompanyIdToExternalCourierId: object expected");
                    message.createSubCompanyIdToExternalCourierId = $root.fastcity.admin.CreateSubCompanyIdToExternalCourierId.fromObject(object.createSubCompanyIdToExternalCourierId);
                }
                if (object.updateSubCompanyIdToExternalCourierId != null) {
                    if (typeof object.updateSubCompanyIdToExternalCourierId !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.updateSubCompanyIdToExternalCourierId: object expected");
                    message.updateSubCompanyIdToExternalCourierId = $root.fastcity.admin.UpdateSubCompanyIdToExternalCourierId.fromObject(object.updateSubCompanyIdToExternalCourierId);
                }
                if (object.removeSubCompanyIdToExternalCourierId != null) {
                    if (typeof object.removeSubCompanyIdToExternalCourierId !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.removeSubCompanyIdToExternalCourierId: object expected");
                    message.removeSubCompanyIdToExternalCourierId = $root.fastcity.admin.RemoveSubCompanyIdToExternalCourierId.fromObject(object.removeSubCompanyIdToExternalCourierId);
                }
                if (object.getSubCompanyIdToExternalCourierIdList != null) {
                    if (typeof object.getSubCompanyIdToExternalCourierIdList !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.getSubCompanyIdToExternalCourierIdList: object expected");
                    message.getSubCompanyIdToExternalCourierIdList = $root.fastcity.admin.GetSubCompanyIdToExternalCourierIdList.fromObject(object.getSubCompanyIdToExternalCourierIdList);
                }
                if (object.changeOrderStatus != null) {
                    if (typeof object.changeOrderStatus !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.changeOrderStatus: object expected");
                    message.changeOrderStatus = $root.fastcity.admin.ChangeOrderStatus.fromObject(object.changeOrderStatus);
                }
                if (object.getServerConfig != null) {
                    if (typeof object.getServerConfig !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.getServerConfig: object expected");
                    message.getServerConfig = $root.fastcity.admin.GetServerConfig.fromObject(object.getServerConfig);
                }
                if (object.updateServerConfig != null) {
                    if (typeof object.updateServerConfig !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.updateServerConfig: object expected");
                    message.updateServerConfig = $root.fastcity.admin.UpdateServerConfig.fromObject(object.updateServerConfig);
                }
                return message;
            };

            /**
             * Creates a plain object from a ClientMessage message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.ClientMessage
             * @static
             * @param {fastcity.admin.ClientMessage} message ClientMessage
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ClientMessage.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.requestId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.requestId = options.longs === String ? "0" : 0;
                if (message.requestId != null && message.hasOwnProperty("requestId"))
                    if (typeof message.requestId === "number")
                        object.requestId = options.longs === String ? String(message.requestId) : message.requestId;
                    else
                        object.requestId = options.longs === String ? $util.Long.prototype.toString.call(message.requestId) : options.longs === Number ? new $util.LongBits(message.requestId.low >>> 0, message.requestId.high >>> 0).toNumber(true) : message.requestId;
                if (message.getCurrentAdmin != null && message.hasOwnProperty("getCurrentAdmin")) {
                    object.getCurrentAdmin = $root.fastcity.admin.GetCurrentAdmin.toObject(message.getCurrentAdmin, options);
                    if (options.oneofs)
                        object.body = "getCurrentAdmin";
                }
                if (message.getAdminList != null && message.hasOwnProperty("getAdminList")) {
                    object.getAdminList = $root.fastcity.admin.GetAdminList.toObject(message.getAdminList, options);
                    if (options.oneofs)
                        object.body = "getAdminList";
                }
                if (message.createAdmin != null && message.hasOwnProperty("createAdmin")) {
                    object.createAdmin = $root.fastcity.admin.CreateAdmin.toObject(message.createAdmin, options);
                    if (options.oneofs)
                        object.body = "createAdmin";
                }
                if (message.updateAdmin != null && message.hasOwnProperty("updateAdmin")) {
                    object.updateAdmin = $root.fastcity.admin.UpdateAdmin.toObject(message.updateAdmin, options);
                    if (options.oneofs)
                        object.body = "updateAdmin";
                }
                if (message.getCurrentCompany != null && message.hasOwnProperty("getCurrentCompany")) {
                    object.getCurrentCompany = $root.fastcity.admin.GetCurrentCompany.toObject(message.getCurrentCompany, options);
                    if (options.oneofs)
                        object.body = "getCurrentCompany";
                }
                if (message.getCompanyList != null && message.hasOwnProperty("getCompanyList")) {
                    object.getCompanyList = $root.fastcity.admin.GetCompanyList.toObject(message.getCompanyList, options);
                    if (options.oneofs)
                        object.body = "getCompanyList";
                }
                if (message.createCompany != null && message.hasOwnProperty("createCompany")) {
                    object.createCompany = $root.fastcity.admin.CreateCompany.toObject(message.createCompany, options);
                    if (options.oneofs)
                        object.body = "createCompany";
                }
                if (message.updateCompany != null && message.hasOwnProperty("updateCompany")) {
                    object.updateCompany = $root.fastcity.admin.UpdateCompany.toObject(message.updateCompany, options);
                    if (options.oneofs)
                        object.body = "updateCompany";
                }
                if (message.getCourierList != null && message.hasOwnProperty("getCourierList")) {
                    object.getCourierList = $root.fastcity.admin.GetCourierList.toObject(message.getCourierList, options);
                    if (options.oneofs)
                        object.body = "getCourierList";
                }
                if (message.createCourier != null && message.hasOwnProperty("createCourier")) {
                    object.createCourier = $root.fastcity.admin.CreateCourier.toObject(message.createCourier, options);
                    if (options.oneofs)
                        object.body = "createCourier";
                }
                if (message.getGroupList != null && message.hasOwnProperty("getGroupList")) {
                    object.getGroupList = $root.fastcity.admin.GetGroupList.toObject(message.getGroupList, options);
                    if (options.oneofs)
                        object.body = "getGroupList";
                }
                if (message.createGroup != null && message.hasOwnProperty("createGroup")) {
                    object.createGroup = $root.fastcity.admin.CreateGroup.toObject(message.createGroup, options);
                    if (options.oneofs)
                        object.body = "createGroup";
                }
                if (message.getLastLocationList != null && message.hasOwnProperty("getLastLocationList")) {
                    object.getLastLocationList = $root.fastcity.admin.GetLastLocationList.toObject(message.getLastLocationList, options);
                    if (options.oneofs)
                        object.body = "getLastLocationList";
                }
                if (message.getLocationList != null && message.hasOwnProperty("getLocationList")) {
                    object.getLocationList = $root.fastcity.admin.GetLocationList.toObject(message.getLocationList, options);
                    if (options.oneofs)
                        object.body = "getLocationList";
                }
                if (message.getOrderList != null && message.hasOwnProperty("getOrderList")) {
                    object.getOrderList = $root.fastcity.admin.GetOrderList.toObject(message.getOrderList, options);
                    if (options.oneofs)
                        object.body = "getOrderList";
                }
                if (message.setCourierGroup != null && message.hasOwnProperty("setCourierGroup")) {
                    object.setCourierGroup = $root.fastcity.admin.SetCourierGroup.toObject(message.setCourierGroup, options);
                    if (options.oneofs)
                        object.body = "setCourierGroup";
                }
                if (message.getConnectionStatusList != null && message.hasOwnProperty("getConnectionStatusList")) {
                    object.getConnectionStatusList = $root.fastcity.admin.GetConnectionStatusList.toObject(message.getConnectionStatusList, options);
                    if (options.oneofs)
                        object.body = "getConnectionStatusList";
                }
                if (message.createIntegrationToken != null && message.hasOwnProperty("createIntegrationToken")) {
                    object.createIntegrationToken = $root.fastcity.admin.CreateIntegrationToken.toObject(message.createIntegrationToken, options);
                    if (options.oneofs)
                        object.body = "createIntegrationToken";
                }
                if (message.deleteIntegrationToken != null && message.hasOwnProperty("deleteIntegrationToken")) {
                    object.deleteIntegrationToken = $root.fastcity.admin.DeleteIntegrationToken.toObject(message.deleteIntegrationToken, options);
                    if (options.oneofs)
                        object.body = "deleteIntegrationToken";
                }
                if (message.getIntegrationTokenList != null && message.hasOwnProperty("getIntegrationTokenList")) {
                    object.getIntegrationTokenList = $root.fastcity.admin.GetIntegrationTokenList.toObject(message.getIntegrationTokenList, options);
                    if (options.oneofs)
                        object.body = "getIntegrationTokenList";
                }
                if (message.logon != null && message.hasOwnProperty("logon")) {
                    object.logon = $root.fastcity.admin.Logon.toObject(message.logon, options);
                    if (options.oneofs)
                        object.body = "logon";
                }
                if (message.deleteGroup != null && message.hasOwnProperty("deleteGroup")) {
                    object.deleteGroup = $root.fastcity.admin.DeleteGroup.toObject(message.deleteGroup, options);
                    if (options.oneofs)
                        object.body = "deleteGroup";
                }
                if (message.updateGroup != null && message.hasOwnProperty("updateGroup")) {
                    object.updateGroup = $root.fastcity.admin.UpdateGroup.toObject(message.updateGroup, options);
                    if (options.oneofs)
                        object.body = "updateGroup";
                }
                if (message.getDeliveryStatDayList != null && message.hasOwnProperty("getDeliveryStatDayList")) {
                    object.getDeliveryStatDayList = $root.fastcity.admin.GetDeliveryStatDayList.toObject(message.getDeliveryStatDayList, options);
                    if (options.oneofs)
                        object.body = "getDeliveryStatDayList";
                }
                if (message.getDeliveryStatList != null && message.hasOwnProperty("getDeliveryStatList")) {
                    object.getDeliveryStatList = $root.fastcity.admin.GetDeliveryStatList.toObject(message.getDeliveryStatList, options);
                    if (options.oneofs)
                        object.body = "getDeliveryStatList";
                }
                if (message.getWorkingDayList != null && message.hasOwnProperty("getWorkingDayList")) {
                    object.getWorkingDayList = $root.fastcity.admin.GetWorkingDayList.toObject(message.getWorkingDayList, options);
                    if (options.oneofs)
                        object.body = "getWorkingDayList";
                }
                if (message.getSubCompanyIdList != null && message.hasOwnProperty("getSubCompanyIdList")) {
                    object.getSubCompanyIdList = $root.fastcity.admin.GetSubCompanyIdList.toObject(message.getSubCompanyIdList, options);
                    if (options.oneofs)
                        object.body = "getSubCompanyIdList";
                }
                if (message.setCourierIsDeleted != null && message.hasOwnProperty("setCourierIsDeleted")) {
                    object.setCourierIsDeleted = $root.fastcity.admin.SetCourierIsDeleted.toObject(message.setCourierIsDeleted, options);
                    if (options.oneofs)
                        object.body = "setCourierIsDeleted";
                }
                if (message.getSubCompanyList != null && message.hasOwnProperty("getSubCompanyList")) {
                    object.getSubCompanyList = $root.fastcity.admin.GetSubCompanyList.toObject(message.getSubCompanyList, options);
                    if (options.oneofs)
                        object.body = "getSubCompanyList";
                }
                if (message.deleteAdmin != null && message.hasOwnProperty("deleteAdmin")) {
                    object.deleteAdmin = $root.fastcity.admin.DeleteAdmin.toObject(message.deleteAdmin, options);
                    if (options.oneofs)
                        object.body = "deleteAdmin";
                }
                if (message.deleteCompany != null && message.hasOwnProperty("deleteCompany")) {
                    object.deleteCompany = $root.fastcity.admin.DeleteCompany.toObject(message.deleteCompany, options);
                    if (options.oneofs)
                        object.body = "deleteCompany";
                }
                if (message.deleteSubCompany != null && message.hasOwnProperty("deleteSubCompany")) {
                    object.deleteSubCompany = $root.fastcity.admin.DeleteSubCompany.toObject(message.deleteSubCompany, options);
                    if (options.oneofs)
                        object.body = "deleteSubCompany";
                }
                if (message.resetPassword != null && message.hasOwnProperty("resetPassword")) {
                    object.resetPassword = $root.fastcity.admin.ResetPassword.toObject(message.resetPassword, options);
                    if (options.oneofs)
                        object.body = "resetPassword";
                }
                if (message.activate != null && message.hasOwnProperty("activate")) {
                    object.activate = $root.fastcity.admin.Activate.toObject(message.activate, options);
                    if (options.oneofs)
                        object.body = "activate";
                }
                if (message.updateSubCompany != null && message.hasOwnProperty("updateSubCompany")) {
                    object.updateSubCompany = $root.fastcity.admin.UpdateSubCompany.toObject(message.updateSubCompany, options);
                    if (options.oneofs)
                        object.body = "updateSubCompany";
                }
                if (message.updateCourier != null && message.hasOwnProperty("updateCourier")) {
                    object.updateCourier = $root.fastcity.admin.UpdateCourier.toObject(message.updateCourier, options);
                    if (options.oneofs)
                        object.body = "updateCourier";
                }
                if (message.createSubCompanyZone != null && message.hasOwnProperty("createSubCompanyZone")) {
                    object.createSubCompanyZone = $root.fastcity.admin.CreateSubCompanyZone.toObject(message.createSubCompanyZone, options);
                    if (options.oneofs)
                        object.body = "createSubCompanyZone";
                }
                if (message.updateSubCompanyZone != null && message.hasOwnProperty("updateSubCompanyZone")) {
                    object.updateSubCompanyZone = $root.fastcity.admin.UpdateSubCompanyZone.toObject(message.updateSubCompanyZone, options);
                    if (options.oneofs)
                        object.body = "updateSubCompanyZone";
                }
                if (message.getSubCompanyZone != null && message.hasOwnProperty("getSubCompanyZone")) {
                    object.getSubCompanyZone = $root.fastcity.admin.GetSubCompanyZone.toObject(message.getSubCompanyZone, options);
                    if (options.oneofs)
                        object.body = "getSubCompanyZone";
                }
                if (message.getSubCompanyZoneList != null && message.hasOwnProperty("getSubCompanyZoneList")) {
                    object.getSubCompanyZoneList = $root.fastcity.admin.GetSubCompanyZoneList.toObject(message.getSubCompanyZoneList, options);
                    if (options.oneofs)
                        object.body = "getSubCompanyZoneList";
                }
                if (message.createSubCompany != null && message.hasOwnProperty("createSubCompany")) {
                    object.createSubCompany = $root.fastcity.admin.CreateSubCompany.toObject(message.createSubCompany, options);
                    if (options.oneofs)
                        object.body = "createSubCompany";
                }
                if (message.createAvailableOrderStatuses != null && message.hasOwnProperty("createAvailableOrderStatuses")) {
                    object.createAvailableOrderStatuses = $root.fastcity.admin.CreateAvailableOrderStatuses.toObject(message.createAvailableOrderStatuses, options);
                    if (options.oneofs)
                        object.body = "createAvailableOrderStatuses";
                }
                if (message.updateAvailableOrderStatuses != null && message.hasOwnProperty("updateAvailableOrderStatuses")) {
                    object.updateAvailableOrderStatuses = $root.fastcity.admin.UpdateAvailableOrderStatuses.toObject(message.updateAvailableOrderStatuses, options);
                    if (options.oneofs)
                        object.body = "updateAvailableOrderStatuses";
                }
                if (message.createSubCompanyZoneFromKml != null && message.hasOwnProperty("createSubCompanyZoneFromKml")) {
                    object.createSubCompanyZoneFromKml = $root.fastcity.admin.CreateSubCompanyZoneFromKML.toObject(message.createSubCompanyZoneFromKml, options);
                    if (options.oneofs)
                        object.body = "createSubCompanyZoneFromKml";
                }
                if (message.createExternalAuthToken != null && message.hasOwnProperty("createExternalAuthToken")) {
                    object.createExternalAuthToken = $root.fastcity.admin.CreateExternalAuthToken.toObject(message.createExternalAuthToken, options);
                    if (options.oneofs)
                        object.body = "createExternalAuthToken";
                }
                if (message.updateExternalAuthToken != null && message.hasOwnProperty("updateExternalAuthToken")) {
                    object.updateExternalAuthToken = $root.fastcity.admin.UpdateExternalAuthToken.toObject(message.updateExternalAuthToken, options);
                    if (options.oneofs)
                        object.body = "updateExternalAuthToken";
                }
                if (message.deleteExternalAuthToken != null && message.hasOwnProperty("deleteExternalAuthToken")) {
                    object.deleteExternalAuthToken = $root.fastcity.admin.DeleteExternalAuthToken.toObject(message.deleteExternalAuthToken, options);
                    if (options.oneofs)
                        object.body = "deleteExternalAuthToken";
                }
                if (message.getActiveExternalAuthToken != null && message.hasOwnProperty("getActiveExternalAuthToken")) {
                    object.getActiveExternalAuthToken = $root.fastcity.admin.GetActiveExternalAuthToken.toObject(message.getActiveExternalAuthToken, options);
                    if (options.oneofs)
                        object.body = "getActiveExternalAuthToken";
                }
                if (message.getExternalAuthTokenList != null && message.hasOwnProperty("getExternalAuthTokenList")) {
                    object.getExternalAuthTokenList = $root.fastcity.admin.GetExternalAuthTokenList.toObject(message.getExternalAuthTokenList, options);
                    if (options.oneofs)
                        object.body = "getExternalAuthTokenList";
                }
                if (message.bindExternalAuthTokenToSubCompany != null && message.hasOwnProperty("bindExternalAuthTokenToSubCompany")) {
                    object.bindExternalAuthTokenToSubCompany = $root.fastcity.admin.BindExternalAuthTokenToSubCompany.toObject(message.bindExternalAuthTokenToSubCompany, options);
                    if (options.oneofs)
                        object.body = "bindExternalAuthTokenToSubCompany";
                }
                if (message.updateSubCompanyZoneUsingKml != null && message.hasOwnProperty("updateSubCompanyZoneUsingKml")) {
                    object.updateSubCompanyZoneUsingKml = $root.fastcity.admin.UpdateSubCompanyZoneUsingKML.toObject(message.updateSubCompanyZoneUsingKml, options);
                    if (options.oneofs)
                        object.body = "updateSubCompanyZoneUsingKml";
                }
                if (message.getAvailableOrderStatuses != null && message.hasOwnProperty("getAvailableOrderStatuses")) {
                    object.getAvailableOrderStatuses = $root.fastcity.admin.GetAvailableOrderStatuses.toObject(message.getAvailableOrderStatuses, options);
                    if (options.oneofs)
                        object.body = "getAvailableOrderStatuses";
                }
                if (message.getAvailableOrderStatusesList != null && message.hasOwnProperty("getAvailableOrderStatusesList")) {
                    object.getAvailableOrderStatusesList = $root.fastcity.admin.GetAvailableOrderStatusesList.toObject(message.getAvailableOrderStatusesList, options);
                    if (options.oneofs)
                        object.body = "getAvailableOrderStatusesList";
                }
                if (message.getServerVersion != null && message.hasOwnProperty("getServerVersion")) {
                    object.getServerVersion = $root.fastcity.admin.GetServerVersion.toObject(message.getServerVersion, options);
                    if (options.oneofs)
                        object.body = "getServerVersion";
                }
                if (message.deleteSubCompanyZone != null && message.hasOwnProperty("deleteSubCompanyZone")) {
                    object.deleteSubCompanyZone = $root.fastcity.admin.DeleteSubCompanyZone.toObject(message.deleteSubCompanyZone, options);
                    if (options.oneofs)
                        object.body = "deleteSubCompanyZone";
                }
                if (message.recoverSubCompanyZone != null && message.hasOwnProperty("recoverSubCompanyZone")) {
                    object.recoverSubCompanyZone = $root.fastcity.admin.RecoverSubCompanyZone.toObject(message.recoverSubCompanyZone, options);
                    if (options.oneofs)
                        object.body = "recoverSubCompanyZone";
                }
                if (message.getOrderUpdateList != null && message.hasOwnProperty("getOrderUpdateList")) {
                    object.getOrderUpdateList = $root.fastcity.admin.GetOrderUpdateList.toObject(message.getOrderUpdateList, options);
                    if (options.oneofs)
                        object.body = "getOrderUpdateList";
                }
                if (message.getCouriersFromExternal != null && message.hasOwnProperty("getCouriersFromExternal")) {
                    object.getCouriersFromExternal = $root.fastcity.admin.GetCouriersFromExternal.toObject(message.getCouriersFromExternal, options);
                    if (options.oneofs)
                        object.body = "getCouriersFromExternal";
                }
                if (message.getSubCompaniesFromExternal != null && message.hasOwnProperty("getSubCompaniesFromExternal")) {
                    object.getSubCompaniesFromExternal = $root.fastcity.admin.GetSubCompaniesFromExternal.toObject(message.getSubCompaniesFromExternal, options);
                    if (options.oneofs)
                        object.body = "getSubCompaniesFromExternal";
                }
                if (message.getIntegrationConnectionStatus != null && message.hasOwnProperty("getIntegrationConnectionStatus")) {
                    object.getIntegrationConnectionStatus = $root.fastcity.admin.GetIntegrationConnectionStatus.toObject(message.getIntegrationConnectionStatus, options);
                    if (options.oneofs)
                        object.body = "getIntegrationConnectionStatus";
                }
                if (message.getIntegrationConnectionStatusList != null && message.hasOwnProperty("getIntegrationConnectionStatusList")) {
                    object.getIntegrationConnectionStatusList = $root.fastcity.admin.GetIntegrationConnectionStatusList.toObject(message.getIntegrationConnectionStatusList, options);
                    if (options.oneofs)
                        object.body = "getIntegrationConnectionStatusList";
                }
                if (message.getLogRecordList != null && message.hasOwnProperty("getLogRecordList")) {
                    object.getLogRecordList = $root.fastcity.admin.GetLogRecordList.toObject(message.getLogRecordList, options);
                    if (options.oneofs)
                        object.body = "getLogRecordList";
                }
                if (message.getOrderPath != null && message.hasOwnProperty("getOrderPath")) {
                    object.getOrderPath = $root.fastcity.common.GetOrderPath.toObject(message.getOrderPath, options);
                    if (options.oneofs)
                        object.body = "getOrderPath";
                }
                if (message.getOrderPathList != null && message.hasOwnProperty("getOrderPathList")) {
                    object.getOrderPathList = $root.fastcity.common.GetOrderPathList.toObject(message.getOrderPathList, options);
                    if (options.oneofs)
                        object.body = "getOrderPathList";
                }
                if (message.createSubCompanyIdToExternalCourierId != null && message.hasOwnProperty("createSubCompanyIdToExternalCourierId")) {
                    object.createSubCompanyIdToExternalCourierId = $root.fastcity.admin.CreateSubCompanyIdToExternalCourierId.toObject(message.createSubCompanyIdToExternalCourierId, options);
                    if (options.oneofs)
                        object.body = "createSubCompanyIdToExternalCourierId";
                }
                if (message.updateSubCompanyIdToExternalCourierId != null && message.hasOwnProperty("updateSubCompanyIdToExternalCourierId")) {
                    object.updateSubCompanyIdToExternalCourierId = $root.fastcity.admin.UpdateSubCompanyIdToExternalCourierId.toObject(message.updateSubCompanyIdToExternalCourierId, options);
                    if (options.oneofs)
                        object.body = "updateSubCompanyIdToExternalCourierId";
                }
                if (message.removeSubCompanyIdToExternalCourierId != null && message.hasOwnProperty("removeSubCompanyIdToExternalCourierId")) {
                    object.removeSubCompanyIdToExternalCourierId = $root.fastcity.admin.RemoveSubCompanyIdToExternalCourierId.toObject(message.removeSubCompanyIdToExternalCourierId, options);
                    if (options.oneofs)
                        object.body = "removeSubCompanyIdToExternalCourierId";
                }
                if (message.getSubCompanyIdToExternalCourierIdList != null && message.hasOwnProperty("getSubCompanyIdToExternalCourierIdList")) {
                    object.getSubCompanyIdToExternalCourierIdList = $root.fastcity.admin.GetSubCompanyIdToExternalCourierIdList.toObject(message.getSubCompanyIdToExternalCourierIdList, options);
                    if (options.oneofs)
                        object.body = "getSubCompanyIdToExternalCourierIdList";
                }
                if (message.changeOrderStatus != null && message.hasOwnProperty("changeOrderStatus")) {
                    object.changeOrderStatus = $root.fastcity.admin.ChangeOrderStatus.toObject(message.changeOrderStatus, options);
                    if (options.oneofs)
                        object.body = "changeOrderStatus";
                }
                if (message.getServerConfig != null && message.hasOwnProperty("getServerConfig")) {
                    object.getServerConfig = $root.fastcity.admin.GetServerConfig.toObject(message.getServerConfig, options);
                    if (options.oneofs)
                        object.body = "getServerConfig";
                }
                if (message.updateServerConfig != null && message.hasOwnProperty("updateServerConfig")) {
                    object.updateServerConfig = $root.fastcity.admin.UpdateServerConfig.toObject(message.updateServerConfig, options);
                    if (options.oneofs)
                        object.body = "updateServerConfig";
                }
                return object;
            };

            /**
             * Converts this ClientMessage to JSON.
             * @function toJSON
             * @memberof fastcity.admin.ClientMessage
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ClientMessage.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return ClientMessage;
        })();

        admin.ServerMessage = (function() {

            /**
             * Properties of a ServerMessage.
             * @memberof fastcity.admin
             * @interface IServerMessage
             * @property {number|Long|null} [requestId] ServerMessage requestId
             * @property {fastcity.admin.IResult|null} [result] ServerMessage result
             * @property {fastcity.admin.IAdmin|null} [admin] ServerMessage admin
             * @property {fastcity.admin.IAdminList|null} [adminList] ServerMessage adminList
             * @property {fastcity.admin.ICompany|null} [company] ServerMessage company
             * @property {fastcity.admin.ICompanyList|null} [companyList] ServerMessage companyList
             * @property {fastcity.admin.ICourier|null} [courier] ServerMessage courier
             * @property {fastcity.admin.ICourierList|null} [courierList] ServerMessage courierList
             * @property {fastcity.admin.ICourierFromExternal|null} [courierFromExternal] ServerMessage courierFromExternal
             * @property {fastcity.admin.ICourierFromExternalList|null} [courierFromExternalList] ServerMessage courierFromExternalList
             * @property {fastcity.admin.IGroup|null} [group] ServerMessage group
             * @property {fastcity.admin.IGroupList|null} [groupList] ServerMessage groupList
             * @property {fastcity.admin.ILocationList|null} [locationList] ServerMessage locationList
             * @property {fastcity.admin.IOrderList|null} [orderList] ServerMessage orderList
             * @property {fastcity.admin.IConnectionStatus|null} [connectionStatus] ServerMessage connectionStatus
             * @property {fastcity.admin.IConnectionStatusList|null} [connectionStatusList] ServerMessage connectionStatusList
             * @property {fastcity.admin.IIntegrationToken|null} [integrationToken] ServerMessage integrationToken
             * @property {fastcity.admin.IIntegrationTokenList|null} [integrationTokenList] ServerMessage integrationTokenList
             * @property {fastcity.admin.IChangedCourierIdList|null} [changedCourierIdList] ServerMessage changedCourierIdList
             * @property {fastcity.admin.IChangedOrderIdList|null} [changedOrderIdList] ServerMessage changedOrderIdList
             * @property {fastcity.admin.ILogonResult|null} [logonResult] ServerMessage logonResult
             * @property {fastcity.admin.IChangedLastLocation|null} [changedLastLocation] ServerMessage changedLastLocation
             * @property {fastcity.admin.IDeliveryStatDayList|null} [deliveryStatDayList] ServerMessage deliveryStatDayList
             * @property {fastcity.admin.IDeliveryStatList|null} [deliveryStatList] ServerMessage deliveryStatList
             * @property {fastcity.admin.IWorkingDayList|null} [workingDayList] ServerMessage workingDayList
             * @property {fastcity.admin.ISubCompanyIdList|null} [subCompanyIdList] ServerMessage subCompanyIdList
             * @property {fastcity.admin.ISubCompany|null} [subCompany] ServerMessage subCompany
             * @property {fastcity.admin.ISubCompanyList|null} [getSubCompanyList] ServerMessage getSubCompanyList
             * @property {fastcity.admin.ISubCompanyZone|null} [subCompanyZone] ServerMessage subCompanyZone
             * @property {fastcity.admin.ISubCompanyZoneList|null} [subCompanyZoneList] ServerMessage subCompanyZoneList
             * @property {fastcity.admin.IAvailableOrderStatuses|null} [availableOrderStatuses] ServerMessage availableOrderStatuses
             * @property {fastcity.admin.IAvailableOrderStatusesList|null} [availableOrderStatusesList] ServerMessage availableOrderStatusesList
             * @property {fastcity.admin.IExternalAuthToken|null} [externalAuthToken] ServerMessage externalAuthToken
             * @property {fastcity.admin.IExternalAuthTokenList|null} [externalAuthTokenList] ServerMessage externalAuthTokenList
             * @property {fastcity.common.IVersion|null} [serverVersion] ServerMessage serverVersion
             * @property {fastcity.admin.IOrderUpdateList|null} [orderUpdates] ServerMessage orderUpdates
             * @property {fastcity.admin.IIntegrationConnectionStatus|null} [integrationConnectionStatus] ServerMessage integrationConnectionStatus
             * @property {fastcity.admin.IIntegrationConnectionStatusList|null} [integrationConnectionStatusList] ServerMessage integrationConnectionStatusList
             * @property {fastcity.admin.ILogRecordList|null} [logList] ServerMessage logList
             * @property {fastcity.common.IOrderPath|null} [orderPath] ServerMessage orderPath
             * @property {fastcity.common.IOrderPathList|null} [orderPathList] ServerMessage orderPathList
             * @property {fastcity.admin.ISubCompanyIdToExternalCourierId|null} [subCompanyIdToExternalCourierId] ServerMessage subCompanyIdToExternalCourierId
             * @property {fastcity.admin.ISubCompanyIdToExternalCourierIdList|null} [subCompanyIdToExternalCourierIdList] ServerMessage subCompanyIdToExternalCourierIdList
             * @property {fastcity.admin.IServerConfig|null} [serverConfig] ServerMessage serverConfig
             */

            /**
             * Constructs a new ServerMessage.
             * @memberof fastcity.admin
             * @classdesc Represents a ServerMessage.
             * @implements IServerMessage
             * @constructor
             * @param {fastcity.admin.IServerMessage=} [properties] Properties to set
             */
            function ServerMessage(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ServerMessage requestId.
             * @member {number|Long} requestId
             * @memberof fastcity.admin.ServerMessage
             * @instance
             */
            ServerMessage.prototype.requestId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * ServerMessage result.
             * @member {fastcity.admin.IResult|null|undefined} result
             * @memberof fastcity.admin.ServerMessage
             * @instance
             */
            ServerMessage.prototype.result = null;

            /**
             * ServerMessage admin.
             * @member {fastcity.admin.IAdmin|null|undefined} admin
             * @memberof fastcity.admin.ServerMessage
             * @instance
             */
            ServerMessage.prototype.admin = null;

            /**
             * ServerMessage adminList.
             * @member {fastcity.admin.IAdminList|null|undefined} adminList
             * @memberof fastcity.admin.ServerMessage
             * @instance
             */
            ServerMessage.prototype.adminList = null;

            /**
             * ServerMessage company.
             * @member {fastcity.admin.ICompany|null|undefined} company
             * @memberof fastcity.admin.ServerMessage
             * @instance
             */
            ServerMessage.prototype.company = null;

            /**
             * ServerMessage companyList.
             * @member {fastcity.admin.ICompanyList|null|undefined} companyList
             * @memberof fastcity.admin.ServerMessage
             * @instance
             */
            ServerMessage.prototype.companyList = null;

            /**
             * ServerMessage courier.
             * @member {fastcity.admin.ICourier|null|undefined} courier
             * @memberof fastcity.admin.ServerMessage
             * @instance
             */
            ServerMessage.prototype.courier = null;

            /**
             * ServerMessage courierList.
             * @member {fastcity.admin.ICourierList|null|undefined} courierList
             * @memberof fastcity.admin.ServerMessage
             * @instance
             */
            ServerMessage.prototype.courierList = null;

            /**
             * ServerMessage courierFromExternal.
             * @member {fastcity.admin.ICourierFromExternal|null|undefined} courierFromExternal
             * @memberof fastcity.admin.ServerMessage
             * @instance
             */
            ServerMessage.prototype.courierFromExternal = null;

            /**
             * ServerMessage courierFromExternalList.
             * @member {fastcity.admin.ICourierFromExternalList|null|undefined} courierFromExternalList
             * @memberof fastcity.admin.ServerMessage
             * @instance
             */
            ServerMessage.prototype.courierFromExternalList = null;

            /**
             * ServerMessage group.
             * @member {fastcity.admin.IGroup|null|undefined} group
             * @memberof fastcity.admin.ServerMessage
             * @instance
             */
            ServerMessage.prototype.group = null;

            /**
             * ServerMessage groupList.
             * @member {fastcity.admin.IGroupList|null|undefined} groupList
             * @memberof fastcity.admin.ServerMessage
             * @instance
             */
            ServerMessage.prototype.groupList = null;

            /**
             * ServerMessage locationList.
             * @member {fastcity.admin.ILocationList|null|undefined} locationList
             * @memberof fastcity.admin.ServerMessage
             * @instance
             */
            ServerMessage.prototype.locationList = null;

            /**
             * ServerMessage orderList.
             * @member {fastcity.admin.IOrderList|null|undefined} orderList
             * @memberof fastcity.admin.ServerMessage
             * @instance
             */
            ServerMessage.prototype.orderList = null;

            /**
             * ServerMessage connectionStatus.
             * @member {fastcity.admin.IConnectionStatus|null|undefined} connectionStatus
             * @memberof fastcity.admin.ServerMessage
             * @instance
             */
            ServerMessage.prototype.connectionStatus = null;

            /**
             * ServerMessage connectionStatusList.
             * @member {fastcity.admin.IConnectionStatusList|null|undefined} connectionStatusList
             * @memberof fastcity.admin.ServerMessage
             * @instance
             */
            ServerMessage.prototype.connectionStatusList = null;

            /**
             * ServerMessage integrationToken.
             * @member {fastcity.admin.IIntegrationToken|null|undefined} integrationToken
             * @memberof fastcity.admin.ServerMessage
             * @instance
             */
            ServerMessage.prototype.integrationToken = null;

            /**
             * ServerMessage integrationTokenList.
             * @member {fastcity.admin.IIntegrationTokenList|null|undefined} integrationTokenList
             * @memberof fastcity.admin.ServerMessage
             * @instance
             */
            ServerMessage.prototype.integrationTokenList = null;

            /**
             * ServerMessage changedCourierIdList.
             * @member {fastcity.admin.IChangedCourierIdList|null|undefined} changedCourierIdList
             * @memberof fastcity.admin.ServerMessage
             * @instance
             */
            ServerMessage.prototype.changedCourierIdList = null;

            /**
             * ServerMessage changedOrderIdList.
             * @member {fastcity.admin.IChangedOrderIdList|null|undefined} changedOrderIdList
             * @memberof fastcity.admin.ServerMessage
             * @instance
             */
            ServerMessage.prototype.changedOrderIdList = null;

            /**
             * ServerMessage logonResult.
             * @member {fastcity.admin.ILogonResult|null|undefined} logonResult
             * @memberof fastcity.admin.ServerMessage
             * @instance
             */
            ServerMessage.prototype.logonResult = null;

            /**
             * ServerMessage changedLastLocation.
             * @member {fastcity.admin.IChangedLastLocation|null|undefined} changedLastLocation
             * @memberof fastcity.admin.ServerMessage
             * @instance
             */
            ServerMessage.prototype.changedLastLocation = null;

            /**
             * ServerMessage deliveryStatDayList.
             * @member {fastcity.admin.IDeliveryStatDayList|null|undefined} deliveryStatDayList
             * @memberof fastcity.admin.ServerMessage
             * @instance
             */
            ServerMessage.prototype.deliveryStatDayList = null;

            /**
             * ServerMessage deliveryStatList.
             * @member {fastcity.admin.IDeliveryStatList|null|undefined} deliveryStatList
             * @memberof fastcity.admin.ServerMessage
             * @instance
             */
            ServerMessage.prototype.deliveryStatList = null;

            /**
             * ServerMessage workingDayList.
             * @member {fastcity.admin.IWorkingDayList|null|undefined} workingDayList
             * @memberof fastcity.admin.ServerMessage
             * @instance
             */
            ServerMessage.prototype.workingDayList = null;

            /**
             * ServerMessage subCompanyIdList.
             * @member {fastcity.admin.ISubCompanyIdList|null|undefined} subCompanyIdList
             * @memberof fastcity.admin.ServerMessage
             * @instance
             */
            ServerMessage.prototype.subCompanyIdList = null;

            /**
             * ServerMessage subCompany.
             * @member {fastcity.admin.ISubCompany|null|undefined} subCompany
             * @memberof fastcity.admin.ServerMessage
             * @instance
             */
            ServerMessage.prototype.subCompany = null;

            /**
             * ServerMessage getSubCompanyList.
             * @member {fastcity.admin.ISubCompanyList|null|undefined} getSubCompanyList
             * @memberof fastcity.admin.ServerMessage
             * @instance
             */
            ServerMessage.prototype.getSubCompanyList = null;

            /**
             * ServerMessage subCompanyZone.
             * @member {fastcity.admin.ISubCompanyZone|null|undefined} subCompanyZone
             * @memberof fastcity.admin.ServerMessage
             * @instance
             */
            ServerMessage.prototype.subCompanyZone = null;

            /**
             * ServerMessage subCompanyZoneList.
             * @member {fastcity.admin.ISubCompanyZoneList|null|undefined} subCompanyZoneList
             * @memberof fastcity.admin.ServerMessage
             * @instance
             */
            ServerMessage.prototype.subCompanyZoneList = null;

            /**
             * ServerMessage availableOrderStatuses.
             * @member {fastcity.admin.IAvailableOrderStatuses|null|undefined} availableOrderStatuses
             * @memberof fastcity.admin.ServerMessage
             * @instance
             */
            ServerMessage.prototype.availableOrderStatuses = null;

            /**
             * ServerMessage availableOrderStatusesList.
             * @member {fastcity.admin.IAvailableOrderStatusesList|null|undefined} availableOrderStatusesList
             * @memberof fastcity.admin.ServerMessage
             * @instance
             */
            ServerMessage.prototype.availableOrderStatusesList = null;

            /**
             * ServerMessage externalAuthToken.
             * @member {fastcity.admin.IExternalAuthToken|null|undefined} externalAuthToken
             * @memberof fastcity.admin.ServerMessage
             * @instance
             */
            ServerMessage.prototype.externalAuthToken = null;

            /**
             * ServerMessage externalAuthTokenList.
             * @member {fastcity.admin.IExternalAuthTokenList|null|undefined} externalAuthTokenList
             * @memberof fastcity.admin.ServerMessage
             * @instance
             */
            ServerMessage.prototype.externalAuthTokenList = null;

            /**
             * ServerMessage serverVersion.
             * @member {fastcity.common.IVersion|null|undefined} serverVersion
             * @memberof fastcity.admin.ServerMessage
             * @instance
             */
            ServerMessage.prototype.serverVersion = null;

            /**
             * ServerMessage orderUpdates.
             * @member {fastcity.admin.IOrderUpdateList|null|undefined} orderUpdates
             * @memberof fastcity.admin.ServerMessage
             * @instance
             */
            ServerMessage.prototype.orderUpdates = null;

            /**
             * ServerMessage integrationConnectionStatus.
             * @member {fastcity.admin.IIntegrationConnectionStatus|null|undefined} integrationConnectionStatus
             * @memberof fastcity.admin.ServerMessage
             * @instance
             */
            ServerMessage.prototype.integrationConnectionStatus = null;

            /**
             * ServerMessage integrationConnectionStatusList.
             * @member {fastcity.admin.IIntegrationConnectionStatusList|null|undefined} integrationConnectionStatusList
             * @memberof fastcity.admin.ServerMessage
             * @instance
             */
            ServerMessage.prototype.integrationConnectionStatusList = null;

            /**
             * ServerMessage logList.
             * @member {fastcity.admin.ILogRecordList|null|undefined} logList
             * @memberof fastcity.admin.ServerMessage
             * @instance
             */
            ServerMessage.prototype.logList = null;

            /**
             * ServerMessage orderPath.
             * @member {fastcity.common.IOrderPath|null|undefined} orderPath
             * @memberof fastcity.admin.ServerMessage
             * @instance
             */
            ServerMessage.prototype.orderPath = null;

            /**
             * ServerMessage orderPathList.
             * @member {fastcity.common.IOrderPathList|null|undefined} orderPathList
             * @memberof fastcity.admin.ServerMessage
             * @instance
             */
            ServerMessage.prototype.orderPathList = null;

            /**
             * ServerMessage subCompanyIdToExternalCourierId.
             * @member {fastcity.admin.ISubCompanyIdToExternalCourierId|null|undefined} subCompanyIdToExternalCourierId
             * @memberof fastcity.admin.ServerMessage
             * @instance
             */
            ServerMessage.prototype.subCompanyIdToExternalCourierId = null;

            /**
             * ServerMessage subCompanyIdToExternalCourierIdList.
             * @member {fastcity.admin.ISubCompanyIdToExternalCourierIdList|null|undefined} subCompanyIdToExternalCourierIdList
             * @memberof fastcity.admin.ServerMessage
             * @instance
             */
            ServerMessage.prototype.subCompanyIdToExternalCourierIdList = null;

            /**
             * ServerMessage serverConfig.
             * @member {fastcity.admin.IServerConfig|null|undefined} serverConfig
             * @memberof fastcity.admin.ServerMessage
             * @instance
             */
            ServerMessage.prototype.serverConfig = null;

            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;

            /**
             * ServerMessage body.
             * @member {"result"|"admin"|"adminList"|"company"|"companyList"|"courier"|"courierList"|"courierFromExternal"|"courierFromExternalList"|"group"|"groupList"|"locationList"|"orderList"|"connectionStatus"|"connectionStatusList"|"integrationToken"|"integrationTokenList"|"changedCourierIdList"|"changedOrderIdList"|"logonResult"|"changedLastLocation"|"deliveryStatDayList"|"deliveryStatList"|"workingDayList"|"subCompanyIdList"|"subCompany"|"getSubCompanyList"|"subCompanyZone"|"subCompanyZoneList"|"availableOrderStatuses"|"availableOrderStatusesList"|"externalAuthToken"|"externalAuthTokenList"|"serverVersion"|"orderUpdates"|"integrationConnectionStatus"|"integrationConnectionStatusList"|"logList"|"orderPath"|"orderPathList"|"subCompanyIdToExternalCourierId"|"subCompanyIdToExternalCourierIdList"|"serverConfig"|undefined} body
             * @memberof fastcity.admin.ServerMessage
             * @instance
             */
            Object.defineProperty(ServerMessage.prototype, "body", {
                get: $util.oneOfGetter($oneOfFields = ["result", "admin", "adminList", "company", "companyList", "courier", "courierList", "courierFromExternal", "courierFromExternalList", "group", "groupList", "locationList", "orderList", "connectionStatus", "connectionStatusList", "integrationToken", "integrationTokenList", "changedCourierIdList", "changedOrderIdList", "logonResult", "changedLastLocation", "deliveryStatDayList", "deliveryStatList", "workingDayList", "subCompanyIdList", "subCompany", "getSubCompanyList", "subCompanyZone", "subCompanyZoneList", "availableOrderStatuses", "availableOrderStatusesList", "externalAuthToken", "externalAuthTokenList", "serverVersion", "orderUpdates", "integrationConnectionStatus", "integrationConnectionStatusList", "logList", "orderPath", "orderPathList", "subCompanyIdToExternalCourierId", "subCompanyIdToExternalCourierIdList", "serverConfig"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Creates a new ServerMessage instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.ServerMessage
             * @static
             * @param {fastcity.admin.IServerMessage=} [properties] Properties to set
             * @returns {fastcity.admin.ServerMessage} ServerMessage instance
             */
            ServerMessage.create = function create(properties) {
                return new ServerMessage(properties);
            };

            /**
             * Encodes the specified ServerMessage message. Does not implicitly {@link fastcity.admin.ServerMessage.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.ServerMessage
             * @static
             * @param {fastcity.admin.IServerMessage} message ServerMessage message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ServerMessage.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.requestId != null && Object.hasOwnProperty.call(message, "requestId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.requestId);
                if (message.result != null && Object.hasOwnProperty.call(message, "result"))
                    $root.fastcity.admin.Result.encode(message.result, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.admin != null && Object.hasOwnProperty.call(message, "admin"))
                    $root.fastcity.admin.Admin.encode(message.admin, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.adminList != null && Object.hasOwnProperty.call(message, "adminList"))
                    $root.fastcity.admin.AdminList.encode(message.adminList, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.company != null && Object.hasOwnProperty.call(message, "company"))
                    $root.fastcity.admin.Company.encode(message.company, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.companyList != null && Object.hasOwnProperty.call(message, "companyList"))
                    $root.fastcity.admin.CompanyList.encode(message.companyList, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.courier != null && Object.hasOwnProperty.call(message, "courier"))
                    $root.fastcity.admin.Courier.encode(message.courier, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                if (message.courierList != null && Object.hasOwnProperty.call(message, "courierList"))
                    $root.fastcity.admin.CourierList.encode(message.courierList, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                if (message.group != null && Object.hasOwnProperty.call(message, "group"))
                    $root.fastcity.admin.Group.encode(message.group, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                if (message.groupList != null && Object.hasOwnProperty.call(message, "groupList"))
                    $root.fastcity.admin.GroupList.encode(message.groupList, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                if (message.locationList != null && Object.hasOwnProperty.call(message, "locationList"))
                    $root.fastcity.admin.LocationList.encode(message.locationList, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
                if (message.orderList != null && Object.hasOwnProperty.call(message, "orderList"))
                    $root.fastcity.admin.OrderList.encode(message.orderList, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
                if (message.connectionStatus != null && Object.hasOwnProperty.call(message, "connectionStatus"))
                    $root.fastcity.admin.ConnectionStatus.encode(message.connectionStatus, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
                if (message.connectionStatusList != null && Object.hasOwnProperty.call(message, "connectionStatusList"))
                    $root.fastcity.admin.ConnectionStatusList.encode(message.connectionStatusList, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
                if (message.integrationToken != null && Object.hasOwnProperty.call(message, "integrationToken"))
                    $root.fastcity.admin.IntegrationToken.encode(message.integrationToken, writer.uint32(/* id 17, wireType 2 =*/138).fork()).ldelim();
                if (message.integrationTokenList != null && Object.hasOwnProperty.call(message, "integrationTokenList"))
                    $root.fastcity.admin.IntegrationTokenList.encode(message.integrationTokenList, writer.uint32(/* id 18, wireType 2 =*/146).fork()).ldelim();
                if (message.changedCourierIdList != null && Object.hasOwnProperty.call(message, "changedCourierIdList"))
                    $root.fastcity.admin.ChangedCourierIdList.encode(message.changedCourierIdList, writer.uint32(/* id 19, wireType 2 =*/154).fork()).ldelim();
                if (message.changedOrderIdList != null && Object.hasOwnProperty.call(message, "changedOrderIdList"))
                    $root.fastcity.admin.ChangedOrderIdList.encode(message.changedOrderIdList, writer.uint32(/* id 20, wireType 2 =*/162).fork()).ldelim();
                if (message.logonResult != null && Object.hasOwnProperty.call(message, "logonResult"))
                    $root.fastcity.admin.LogonResult.encode(message.logonResult, writer.uint32(/* id 21, wireType 2 =*/170).fork()).ldelim();
                if (message.changedLastLocation != null && Object.hasOwnProperty.call(message, "changedLastLocation"))
                    $root.fastcity.admin.ChangedLastLocation.encode(message.changedLastLocation, writer.uint32(/* id 22, wireType 2 =*/178).fork()).ldelim();
                if (message.deliveryStatDayList != null && Object.hasOwnProperty.call(message, "deliveryStatDayList"))
                    $root.fastcity.admin.DeliveryStatDayList.encode(message.deliveryStatDayList, writer.uint32(/* id 23, wireType 2 =*/186).fork()).ldelim();
                if (message.deliveryStatList != null && Object.hasOwnProperty.call(message, "deliveryStatList"))
                    $root.fastcity.admin.DeliveryStatList.encode(message.deliveryStatList, writer.uint32(/* id 24, wireType 2 =*/194).fork()).ldelim();
                if (message.workingDayList != null && Object.hasOwnProperty.call(message, "workingDayList"))
                    $root.fastcity.admin.WorkingDayList.encode(message.workingDayList, writer.uint32(/* id 25, wireType 2 =*/202).fork()).ldelim();
                if (message.subCompanyIdList != null && Object.hasOwnProperty.call(message, "subCompanyIdList"))
                    $root.fastcity.admin.SubCompanyIdList.encode(message.subCompanyIdList, writer.uint32(/* id 26, wireType 2 =*/210).fork()).ldelim();
                if (message.subCompany != null && Object.hasOwnProperty.call(message, "subCompany"))
                    $root.fastcity.admin.SubCompany.encode(message.subCompany, writer.uint32(/* id 27, wireType 2 =*/218).fork()).ldelim();
                if (message.getSubCompanyList != null && Object.hasOwnProperty.call(message, "getSubCompanyList"))
                    $root.fastcity.admin.SubCompanyList.encode(message.getSubCompanyList, writer.uint32(/* id 28, wireType 2 =*/226).fork()).ldelim();
                if (message.subCompanyZone != null && Object.hasOwnProperty.call(message, "subCompanyZone"))
                    $root.fastcity.admin.SubCompanyZone.encode(message.subCompanyZone, writer.uint32(/* id 30, wireType 2 =*/242).fork()).ldelim();
                if (message.subCompanyZoneList != null && Object.hasOwnProperty.call(message, "subCompanyZoneList"))
                    $root.fastcity.admin.SubCompanyZoneList.encode(message.subCompanyZoneList, writer.uint32(/* id 31, wireType 2 =*/250).fork()).ldelim();
                if (message.availableOrderStatuses != null && Object.hasOwnProperty.call(message, "availableOrderStatuses"))
                    $root.fastcity.admin.AvailableOrderStatuses.encode(message.availableOrderStatuses, writer.uint32(/* id 32, wireType 2 =*/258).fork()).ldelim();
                if (message.externalAuthToken != null && Object.hasOwnProperty.call(message, "externalAuthToken"))
                    $root.fastcity.admin.ExternalAuthToken.encode(message.externalAuthToken, writer.uint32(/* id 33, wireType 2 =*/266).fork()).ldelim();
                if (message.externalAuthTokenList != null && Object.hasOwnProperty.call(message, "externalAuthTokenList"))
                    $root.fastcity.admin.ExternalAuthTokenList.encode(message.externalAuthTokenList, writer.uint32(/* id 34, wireType 2 =*/274).fork()).ldelim();
                if (message.availableOrderStatusesList != null && Object.hasOwnProperty.call(message, "availableOrderStatusesList"))
                    $root.fastcity.admin.AvailableOrderStatusesList.encode(message.availableOrderStatusesList, writer.uint32(/* id 35, wireType 2 =*/282).fork()).ldelim();
                if (message.serverVersion != null && Object.hasOwnProperty.call(message, "serverVersion"))
                    $root.fastcity.common.Version.encode(message.serverVersion, writer.uint32(/* id 37, wireType 2 =*/298).fork()).ldelim();
                if (message.orderUpdates != null && Object.hasOwnProperty.call(message, "orderUpdates"))
                    $root.fastcity.admin.OrderUpdateList.encode(message.orderUpdates, writer.uint32(/* id 39, wireType 2 =*/314).fork()).ldelim();
                if (message.integrationConnectionStatus != null && Object.hasOwnProperty.call(message, "integrationConnectionStatus"))
                    $root.fastcity.admin.IntegrationConnectionStatus.encode(message.integrationConnectionStatus, writer.uint32(/* id 40, wireType 2 =*/322).fork()).ldelim();
                if (message.integrationConnectionStatusList != null && Object.hasOwnProperty.call(message, "integrationConnectionStatusList"))
                    $root.fastcity.admin.IntegrationConnectionStatusList.encode(message.integrationConnectionStatusList, writer.uint32(/* id 41, wireType 2 =*/330).fork()).ldelim();
                if (message.logList != null && Object.hasOwnProperty.call(message, "logList"))
                    $root.fastcity.admin.LogRecordList.encode(message.logList, writer.uint32(/* id 43, wireType 2 =*/346).fork()).ldelim();
                if (message.orderPath != null && Object.hasOwnProperty.call(message, "orderPath"))
                    $root.fastcity.common.OrderPath.encode(message.orderPath, writer.uint32(/* id 44, wireType 2 =*/354).fork()).ldelim();
                if (message.orderPathList != null && Object.hasOwnProperty.call(message, "orderPathList"))
                    $root.fastcity.common.OrderPathList.encode(message.orderPathList, writer.uint32(/* id 45, wireType 2 =*/362).fork()).ldelim();
                if (message.subCompanyIdToExternalCourierId != null && Object.hasOwnProperty.call(message, "subCompanyIdToExternalCourierId"))
                    $root.fastcity.admin.SubCompanyIdToExternalCourierId.encode(message.subCompanyIdToExternalCourierId, writer.uint32(/* id 46, wireType 2 =*/370).fork()).ldelim();
                if (message.subCompanyIdToExternalCourierIdList != null && Object.hasOwnProperty.call(message, "subCompanyIdToExternalCourierIdList"))
                    $root.fastcity.admin.SubCompanyIdToExternalCourierIdList.encode(message.subCompanyIdToExternalCourierIdList, writer.uint32(/* id 47, wireType 2 =*/378).fork()).ldelim();
                if (message.courierFromExternal != null && Object.hasOwnProperty.call(message, "courierFromExternal"))
                    $root.fastcity.admin.CourierFromExternal.encode(message.courierFromExternal, writer.uint32(/* id 48, wireType 2 =*/386).fork()).ldelim();
                if (message.courierFromExternalList != null && Object.hasOwnProperty.call(message, "courierFromExternalList"))
                    $root.fastcity.admin.CourierFromExternalList.encode(message.courierFromExternalList, writer.uint32(/* id 49, wireType 2 =*/394).fork()).ldelim();
                if (message.serverConfig != null && Object.hasOwnProperty.call(message, "serverConfig"))
                    $root.fastcity.admin.ServerConfig.encode(message.serverConfig, writer.uint32(/* id 50, wireType 2 =*/402).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a ServerMessage message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.ServerMessage
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.ServerMessage} ServerMessage
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ServerMessage.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.ServerMessage();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.requestId = reader.uint64();
                        break;
                    case 2:
                        message.result = $root.fastcity.admin.Result.decode(reader, reader.uint32());
                        break;
                    case 3:
                        message.admin = $root.fastcity.admin.Admin.decode(reader, reader.uint32());
                        break;
                    case 4:
                        message.adminList = $root.fastcity.admin.AdminList.decode(reader, reader.uint32());
                        break;
                    case 5:
                        message.company = $root.fastcity.admin.Company.decode(reader, reader.uint32());
                        break;
                    case 6:
                        message.companyList = $root.fastcity.admin.CompanyList.decode(reader, reader.uint32());
                        break;
                    case 7:
                        message.courier = $root.fastcity.admin.Courier.decode(reader, reader.uint32());
                        break;
                    case 8:
                        message.courierList = $root.fastcity.admin.CourierList.decode(reader, reader.uint32());
                        break;
                    case 48:
                        message.courierFromExternal = $root.fastcity.admin.CourierFromExternal.decode(reader, reader.uint32());
                        break;
                    case 49:
                        message.courierFromExternalList = $root.fastcity.admin.CourierFromExternalList.decode(reader, reader.uint32());
                        break;
                    case 9:
                        message.group = $root.fastcity.admin.Group.decode(reader, reader.uint32());
                        break;
                    case 10:
                        message.groupList = $root.fastcity.admin.GroupList.decode(reader, reader.uint32());
                        break;
                    case 12:
                        message.locationList = $root.fastcity.admin.LocationList.decode(reader, reader.uint32());
                        break;
                    case 14:
                        message.orderList = $root.fastcity.admin.OrderList.decode(reader, reader.uint32());
                        break;
                    case 15:
                        message.connectionStatus = $root.fastcity.admin.ConnectionStatus.decode(reader, reader.uint32());
                        break;
                    case 16:
                        message.connectionStatusList = $root.fastcity.admin.ConnectionStatusList.decode(reader, reader.uint32());
                        break;
                    case 17:
                        message.integrationToken = $root.fastcity.admin.IntegrationToken.decode(reader, reader.uint32());
                        break;
                    case 18:
                        message.integrationTokenList = $root.fastcity.admin.IntegrationTokenList.decode(reader, reader.uint32());
                        break;
                    case 19:
                        message.changedCourierIdList = $root.fastcity.admin.ChangedCourierIdList.decode(reader, reader.uint32());
                        break;
                    case 20:
                        message.changedOrderIdList = $root.fastcity.admin.ChangedOrderIdList.decode(reader, reader.uint32());
                        break;
                    case 21:
                        message.logonResult = $root.fastcity.admin.LogonResult.decode(reader, reader.uint32());
                        break;
                    case 22:
                        message.changedLastLocation = $root.fastcity.admin.ChangedLastLocation.decode(reader, reader.uint32());
                        break;
                    case 23:
                        message.deliveryStatDayList = $root.fastcity.admin.DeliveryStatDayList.decode(reader, reader.uint32());
                        break;
                    case 24:
                        message.deliveryStatList = $root.fastcity.admin.DeliveryStatList.decode(reader, reader.uint32());
                        break;
                    case 25:
                        message.workingDayList = $root.fastcity.admin.WorkingDayList.decode(reader, reader.uint32());
                        break;
                    case 26:
                        message.subCompanyIdList = $root.fastcity.admin.SubCompanyIdList.decode(reader, reader.uint32());
                        break;
                    case 27:
                        message.subCompany = $root.fastcity.admin.SubCompany.decode(reader, reader.uint32());
                        break;
                    case 28:
                        message.getSubCompanyList = $root.fastcity.admin.SubCompanyList.decode(reader, reader.uint32());
                        break;
                    case 30:
                        message.subCompanyZone = $root.fastcity.admin.SubCompanyZone.decode(reader, reader.uint32());
                        break;
                    case 31:
                        message.subCompanyZoneList = $root.fastcity.admin.SubCompanyZoneList.decode(reader, reader.uint32());
                        break;
                    case 32:
                        message.availableOrderStatuses = $root.fastcity.admin.AvailableOrderStatuses.decode(reader, reader.uint32());
                        break;
                    case 35:
                        message.availableOrderStatusesList = $root.fastcity.admin.AvailableOrderStatusesList.decode(reader, reader.uint32());
                        break;
                    case 33:
                        message.externalAuthToken = $root.fastcity.admin.ExternalAuthToken.decode(reader, reader.uint32());
                        break;
                    case 34:
                        message.externalAuthTokenList = $root.fastcity.admin.ExternalAuthTokenList.decode(reader, reader.uint32());
                        break;
                    case 37:
                        message.serverVersion = $root.fastcity.common.Version.decode(reader, reader.uint32());
                        break;
                    case 39:
                        message.orderUpdates = $root.fastcity.admin.OrderUpdateList.decode(reader, reader.uint32());
                        break;
                    case 40:
                        message.integrationConnectionStatus = $root.fastcity.admin.IntegrationConnectionStatus.decode(reader, reader.uint32());
                        break;
                    case 41:
                        message.integrationConnectionStatusList = $root.fastcity.admin.IntegrationConnectionStatusList.decode(reader, reader.uint32());
                        break;
                    case 43:
                        message.logList = $root.fastcity.admin.LogRecordList.decode(reader, reader.uint32());
                        break;
                    case 44:
                        message.orderPath = $root.fastcity.common.OrderPath.decode(reader, reader.uint32());
                        break;
                    case 45:
                        message.orderPathList = $root.fastcity.common.OrderPathList.decode(reader, reader.uint32());
                        break;
                    case 46:
                        message.subCompanyIdToExternalCourierId = $root.fastcity.admin.SubCompanyIdToExternalCourierId.decode(reader, reader.uint32());
                        break;
                    case 47:
                        message.subCompanyIdToExternalCourierIdList = $root.fastcity.admin.SubCompanyIdToExternalCourierIdList.decode(reader, reader.uint32());
                        break;
                    case 50:
                        message.serverConfig = $root.fastcity.admin.ServerConfig.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a ServerMessage message.
             * @function verify
             * @memberof fastcity.admin.ServerMessage
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ServerMessage.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                var properties = {};
                if (message.requestId != null && message.hasOwnProperty("requestId"))
                    if (!$util.isInteger(message.requestId) && !(message.requestId && $util.isInteger(message.requestId.low) && $util.isInteger(message.requestId.high)))
                        return "requestId: integer|Long expected";
                if (message.result != null && message.hasOwnProperty("result")) {
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.Result.verify(message.result);
                        if (error)
                            return "result." + error;
                    }
                }
                if (message.admin != null && message.hasOwnProperty("admin")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.Admin.verify(message.admin);
                        if (error)
                            return "admin." + error;
                    }
                }
                if (message.adminList != null && message.hasOwnProperty("adminList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.AdminList.verify(message.adminList);
                        if (error)
                            return "adminList." + error;
                    }
                }
                if (message.company != null && message.hasOwnProperty("company")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.Company.verify(message.company);
                        if (error)
                            return "company." + error;
                    }
                }
                if (message.companyList != null && message.hasOwnProperty("companyList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.CompanyList.verify(message.companyList);
                        if (error)
                            return "companyList." + error;
                    }
                }
                if (message.courier != null && message.hasOwnProperty("courier")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.Courier.verify(message.courier);
                        if (error)
                            return "courier." + error;
                    }
                }
                if (message.courierList != null && message.hasOwnProperty("courierList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.CourierList.verify(message.courierList);
                        if (error)
                            return "courierList." + error;
                    }
                }
                if (message.courierFromExternal != null && message.hasOwnProperty("courierFromExternal")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.CourierFromExternal.verify(message.courierFromExternal);
                        if (error)
                            return "courierFromExternal." + error;
                    }
                }
                if (message.courierFromExternalList != null && message.hasOwnProperty("courierFromExternalList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.CourierFromExternalList.verify(message.courierFromExternalList);
                        if (error)
                            return "courierFromExternalList." + error;
                    }
                }
                if (message.group != null && message.hasOwnProperty("group")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.Group.verify(message.group);
                        if (error)
                            return "group." + error;
                    }
                }
                if (message.groupList != null && message.hasOwnProperty("groupList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.GroupList.verify(message.groupList);
                        if (error)
                            return "groupList." + error;
                    }
                }
                if (message.locationList != null && message.hasOwnProperty("locationList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.LocationList.verify(message.locationList);
                        if (error)
                            return "locationList." + error;
                    }
                }
                if (message.orderList != null && message.hasOwnProperty("orderList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.OrderList.verify(message.orderList);
                        if (error)
                            return "orderList." + error;
                    }
                }
                if (message.connectionStatus != null && message.hasOwnProperty("connectionStatus")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.ConnectionStatus.verify(message.connectionStatus);
                        if (error)
                            return "connectionStatus." + error;
                    }
                }
                if (message.connectionStatusList != null && message.hasOwnProperty("connectionStatusList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.ConnectionStatusList.verify(message.connectionStatusList);
                        if (error)
                            return "connectionStatusList." + error;
                    }
                }
                if (message.integrationToken != null && message.hasOwnProperty("integrationToken")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.IntegrationToken.verify(message.integrationToken);
                        if (error)
                            return "integrationToken." + error;
                    }
                }
                if (message.integrationTokenList != null && message.hasOwnProperty("integrationTokenList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.IntegrationTokenList.verify(message.integrationTokenList);
                        if (error)
                            return "integrationTokenList." + error;
                    }
                }
                if (message.changedCourierIdList != null && message.hasOwnProperty("changedCourierIdList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.ChangedCourierIdList.verify(message.changedCourierIdList);
                        if (error)
                            return "changedCourierIdList." + error;
                    }
                }
                if (message.changedOrderIdList != null && message.hasOwnProperty("changedOrderIdList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.ChangedOrderIdList.verify(message.changedOrderIdList);
                        if (error)
                            return "changedOrderIdList." + error;
                    }
                }
                if (message.logonResult != null && message.hasOwnProperty("logonResult")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.LogonResult.verify(message.logonResult);
                        if (error)
                            return "logonResult." + error;
                    }
                }
                if (message.changedLastLocation != null && message.hasOwnProperty("changedLastLocation")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.ChangedLastLocation.verify(message.changedLastLocation);
                        if (error)
                            return "changedLastLocation." + error;
                    }
                }
                if (message.deliveryStatDayList != null && message.hasOwnProperty("deliveryStatDayList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.DeliveryStatDayList.verify(message.deliveryStatDayList);
                        if (error)
                            return "deliveryStatDayList." + error;
                    }
                }
                if (message.deliveryStatList != null && message.hasOwnProperty("deliveryStatList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.DeliveryStatList.verify(message.deliveryStatList);
                        if (error)
                            return "deliveryStatList." + error;
                    }
                }
                if (message.workingDayList != null && message.hasOwnProperty("workingDayList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.WorkingDayList.verify(message.workingDayList);
                        if (error)
                            return "workingDayList." + error;
                    }
                }
                if (message.subCompanyIdList != null && message.hasOwnProperty("subCompanyIdList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.SubCompanyIdList.verify(message.subCompanyIdList);
                        if (error)
                            return "subCompanyIdList." + error;
                    }
                }
                if (message.subCompany != null && message.hasOwnProperty("subCompany")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.SubCompany.verify(message.subCompany);
                        if (error)
                            return "subCompany." + error;
                    }
                }
                if (message.getSubCompanyList != null && message.hasOwnProperty("getSubCompanyList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.SubCompanyList.verify(message.getSubCompanyList);
                        if (error)
                            return "getSubCompanyList." + error;
                    }
                }
                if (message.subCompanyZone != null && message.hasOwnProperty("subCompanyZone")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.SubCompanyZone.verify(message.subCompanyZone);
                        if (error)
                            return "subCompanyZone." + error;
                    }
                }
                if (message.subCompanyZoneList != null && message.hasOwnProperty("subCompanyZoneList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.SubCompanyZoneList.verify(message.subCompanyZoneList);
                        if (error)
                            return "subCompanyZoneList." + error;
                    }
                }
                if (message.availableOrderStatuses != null && message.hasOwnProperty("availableOrderStatuses")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.AvailableOrderStatuses.verify(message.availableOrderStatuses);
                        if (error)
                            return "availableOrderStatuses." + error;
                    }
                }
                if (message.availableOrderStatusesList != null && message.hasOwnProperty("availableOrderStatusesList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.AvailableOrderStatusesList.verify(message.availableOrderStatusesList);
                        if (error)
                            return "availableOrderStatusesList." + error;
                    }
                }
                if (message.externalAuthToken != null && message.hasOwnProperty("externalAuthToken")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.ExternalAuthToken.verify(message.externalAuthToken);
                        if (error)
                            return "externalAuthToken." + error;
                    }
                }
                if (message.externalAuthTokenList != null && message.hasOwnProperty("externalAuthTokenList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.ExternalAuthTokenList.verify(message.externalAuthTokenList);
                        if (error)
                            return "externalAuthTokenList." + error;
                    }
                }
                if (message.serverVersion != null && message.hasOwnProperty("serverVersion")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.common.Version.verify(message.serverVersion);
                        if (error)
                            return "serverVersion." + error;
                    }
                }
                if (message.orderUpdates != null && message.hasOwnProperty("orderUpdates")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.OrderUpdateList.verify(message.orderUpdates);
                        if (error)
                            return "orderUpdates." + error;
                    }
                }
                if (message.integrationConnectionStatus != null && message.hasOwnProperty("integrationConnectionStatus")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.IntegrationConnectionStatus.verify(message.integrationConnectionStatus);
                        if (error)
                            return "integrationConnectionStatus." + error;
                    }
                }
                if (message.integrationConnectionStatusList != null && message.hasOwnProperty("integrationConnectionStatusList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.IntegrationConnectionStatusList.verify(message.integrationConnectionStatusList);
                        if (error)
                            return "integrationConnectionStatusList." + error;
                    }
                }
                if (message.logList != null && message.hasOwnProperty("logList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.LogRecordList.verify(message.logList);
                        if (error)
                            return "logList." + error;
                    }
                }
                if (message.orderPath != null && message.hasOwnProperty("orderPath")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.common.OrderPath.verify(message.orderPath);
                        if (error)
                            return "orderPath." + error;
                    }
                }
                if (message.orderPathList != null && message.hasOwnProperty("orderPathList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.common.OrderPathList.verify(message.orderPathList);
                        if (error)
                            return "orderPathList." + error;
                    }
                }
                if (message.subCompanyIdToExternalCourierId != null && message.hasOwnProperty("subCompanyIdToExternalCourierId")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.SubCompanyIdToExternalCourierId.verify(message.subCompanyIdToExternalCourierId);
                        if (error)
                            return "subCompanyIdToExternalCourierId." + error;
                    }
                }
                if (message.subCompanyIdToExternalCourierIdList != null && message.hasOwnProperty("subCompanyIdToExternalCourierIdList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.SubCompanyIdToExternalCourierIdList.verify(message.subCompanyIdToExternalCourierIdList);
                        if (error)
                            return "subCompanyIdToExternalCourierIdList." + error;
                    }
                }
                if (message.serverConfig != null && message.hasOwnProperty("serverConfig")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.ServerConfig.verify(message.serverConfig);
                        if (error)
                            return "serverConfig." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a ServerMessage message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.ServerMessage
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.ServerMessage} ServerMessage
             */
            ServerMessage.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.ServerMessage)
                    return object;
                var message = new $root.fastcity.admin.ServerMessage();
                if (object.requestId != null)
                    if ($util.Long)
                        (message.requestId = $util.Long.fromValue(object.requestId)).unsigned = true;
                    else if (typeof object.requestId === "string")
                        message.requestId = parseInt(object.requestId, 10);
                    else if (typeof object.requestId === "number")
                        message.requestId = object.requestId;
                    else if (typeof object.requestId === "object")
                        message.requestId = new $util.LongBits(object.requestId.low >>> 0, object.requestId.high >>> 0).toNumber(true);
                if (object.result != null) {
                    if (typeof object.result !== "object")
                        throw TypeError(".fastcity.admin.ServerMessage.result: object expected");
                    message.result = $root.fastcity.admin.Result.fromObject(object.result);
                }
                if (object.admin != null) {
                    if (typeof object.admin !== "object")
                        throw TypeError(".fastcity.admin.ServerMessage.admin: object expected");
                    message.admin = $root.fastcity.admin.Admin.fromObject(object.admin);
                }
                if (object.adminList != null) {
                    if (typeof object.adminList !== "object")
                        throw TypeError(".fastcity.admin.ServerMessage.adminList: object expected");
                    message.adminList = $root.fastcity.admin.AdminList.fromObject(object.adminList);
                }
                if (object.company != null) {
                    if (typeof object.company !== "object")
                        throw TypeError(".fastcity.admin.ServerMessage.company: object expected");
                    message.company = $root.fastcity.admin.Company.fromObject(object.company);
                }
                if (object.companyList != null) {
                    if (typeof object.companyList !== "object")
                        throw TypeError(".fastcity.admin.ServerMessage.companyList: object expected");
                    message.companyList = $root.fastcity.admin.CompanyList.fromObject(object.companyList);
                }
                if (object.courier != null) {
                    if (typeof object.courier !== "object")
                        throw TypeError(".fastcity.admin.ServerMessage.courier: object expected");
                    message.courier = $root.fastcity.admin.Courier.fromObject(object.courier);
                }
                if (object.courierList != null) {
                    if (typeof object.courierList !== "object")
                        throw TypeError(".fastcity.admin.ServerMessage.courierList: object expected");
                    message.courierList = $root.fastcity.admin.CourierList.fromObject(object.courierList);
                }
                if (object.courierFromExternal != null) {
                    if (typeof object.courierFromExternal !== "object")
                        throw TypeError(".fastcity.admin.ServerMessage.courierFromExternal: object expected");
                    message.courierFromExternal = $root.fastcity.admin.CourierFromExternal.fromObject(object.courierFromExternal);
                }
                if (object.courierFromExternalList != null) {
                    if (typeof object.courierFromExternalList !== "object")
                        throw TypeError(".fastcity.admin.ServerMessage.courierFromExternalList: object expected");
                    message.courierFromExternalList = $root.fastcity.admin.CourierFromExternalList.fromObject(object.courierFromExternalList);
                }
                if (object.group != null) {
                    if (typeof object.group !== "object")
                        throw TypeError(".fastcity.admin.ServerMessage.group: object expected");
                    message.group = $root.fastcity.admin.Group.fromObject(object.group);
                }
                if (object.groupList != null) {
                    if (typeof object.groupList !== "object")
                        throw TypeError(".fastcity.admin.ServerMessage.groupList: object expected");
                    message.groupList = $root.fastcity.admin.GroupList.fromObject(object.groupList);
                }
                if (object.locationList != null) {
                    if (typeof object.locationList !== "object")
                        throw TypeError(".fastcity.admin.ServerMessage.locationList: object expected");
                    message.locationList = $root.fastcity.admin.LocationList.fromObject(object.locationList);
                }
                if (object.orderList != null) {
                    if (typeof object.orderList !== "object")
                        throw TypeError(".fastcity.admin.ServerMessage.orderList: object expected");
                    message.orderList = $root.fastcity.admin.OrderList.fromObject(object.orderList);
                }
                if (object.connectionStatus != null) {
                    if (typeof object.connectionStatus !== "object")
                        throw TypeError(".fastcity.admin.ServerMessage.connectionStatus: object expected");
                    message.connectionStatus = $root.fastcity.admin.ConnectionStatus.fromObject(object.connectionStatus);
                }
                if (object.connectionStatusList != null) {
                    if (typeof object.connectionStatusList !== "object")
                        throw TypeError(".fastcity.admin.ServerMessage.connectionStatusList: object expected");
                    message.connectionStatusList = $root.fastcity.admin.ConnectionStatusList.fromObject(object.connectionStatusList);
                }
                if (object.integrationToken != null) {
                    if (typeof object.integrationToken !== "object")
                        throw TypeError(".fastcity.admin.ServerMessage.integrationToken: object expected");
                    message.integrationToken = $root.fastcity.admin.IntegrationToken.fromObject(object.integrationToken);
                }
                if (object.integrationTokenList != null) {
                    if (typeof object.integrationTokenList !== "object")
                        throw TypeError(".fastcity.admin.ServerMessage.integrationTokenList: object expected");
                    message.integrationTokenList = $root.fastcity.admin.IntegrationTokenList.fromObject(object.integrationTokenList);
                }
                if (object.changedCourierIdList != null) {
                    if (typeof object.changedCourierIdList !== "object")
                        throw TypeError(".fastcity.admin.ServerMessage.changedCourierIdList: object expected");
                    message.changedCourierIdList = $root.fastcity.admin.ChangedCourierIdList.fromObject(object.changedCourierIdList);
                }
                if (object.changedOrderIdList != null) {
                    if (typeof object.changedOrderIdList !== "object")
                        throw TypeError(".fastcity.admin.ServerMessage.changedOrderIdList: object expected");
                    message.changedOrderIdList = $root.fastcity.admin.ChangedOrderIdList.fromObject(object.changedOrderIdList);
                }
                if (object.logonResult != null) {
                    if (typeof object.logonResult !== "object")
                        throw TypeError(".fastcity.admin.ServerMessage.logonResult: object expected");
                    message.logonResult = $root.fastcity.admin.LogonResult.fromObject(object.logonResult);
                }
                if (object.changedLastLocation != null) {
                    if (typeof object.changedLastLocation !== "object")
                        throw TypeError(".fastcity.admin.ServerMessage.changedLastLocation: object expected");
                    message.changedLastLocation = $root.fastcity.admin.ChangedLastLocation.fromObject(object.changedLastLocation);
                }
                if (object.deliveryStatDayList != null) {
                    if (typeof object.deliveryStatDayList !== "object")
                        throw TypeError(".fastcity.admin.ServerMessage.deliveryStatDayList: object expected");
                    message.deliveryStatDayList = $root.fastcity.admin.DeliveryStatDayList.fromObject(object.deliveryStatDayList);
                }
                if (object.deliveryStatList != null) {
                    if (typeof object.deliveryStatList !== "object")
                        throw TypeError(".fastcity.admin.ServerMessage.deliveryStatList: object expected");
                    message.deliveryStatList = $root.fastcity.admin.DeliveryStatList.fromObject(object.deliveryStatList);
                }
                if (object.workingDayList != null) {
                    if (typeof object.workingDayList !== "object")
                        throw TypeError(".fastcity.admin.ServerMessage.workingDayList: object expected");
                    message.workingDayList = $root.fastcity.admin.WorkingDayList.fromObject(object.workingDayList);
                }
                if (object.subCompanyIdList != null) {
                    if (typeof object.subCompanyIdList !== "object")
                        throw TypeError(".fastcity.admin.ServerMessage.subCompanyIdList: object expected");
                    message.subCompanyIdList = $root.fastcity.admin.SubCompanyIdList.fromObject(object.subCompanyIdList);
                }
                if (object.subCompany != null) {
                    if (typeof object.subCompany !== "object")
                        throw TypeError(".fastcity.admin.ServerMessage.subCompany: object expected");
                    message.subCompany = $root.fastcity.admin.SubCompany.fromObject(object.subCompany);
                }
                if (object.getSubCompanyList != null) {
                    if (typeof object.getSubCompanyList !== "object")
                        throw TypeError(".fastcity.admin.ServerMessage.getSubCompanyList: object expected");
                    message.getSubCompanyList = $root.fastcity.admin.SubCompanyList.fromObject(object.getSubCompanyList);
                }
                if (object.subCompanyZone != null) {
                    if (typeof object.subCompanyZone !== "object")
                        throw TypeError(".fastcity.admin.ServerMessage.subCompanyZone: object expected");
                    message.subCompanyZone = $root.fastcity.admin.SubCompanyZone.fromObject(object.subCompanyZone);
                }
                if (object.subCompanyZoneList != null) {
                    if (typeof object.subCompanyZoneList !== "object")
                        throw TypeError(".fastcity.admin.ServerMessage.subCompanyZoneList: object expected");
                    message.subCompanyZoneList = $root.fastcity.admin.SubCompanyZoneList.fromObject(object.subCompanyZoneList);
                }
                if (object.availableOrderStatuses != null) {
                    if (typeof object.availableOrderStatuses !== "object")
                        throw TypeError(".fastcity.admin.ServerMessage.availableOrderStatuses: object expected");
                    message.availableOrderStatuses = $root.fastcity.admin.AvailableOrderStatuses.fromObject(object.availableOrderStatuses);
                }
                if (object.availableOrderStatusesList != null) {
                    if (typeof object.availableOrderStatusesList !== "object")
                        throw TypeError(".fastcity.admin.ServerMessage.availableOrderStatusesList: object expected");
                    message.availableOrderStatusesList = $root.fastcity.admin.AvailableOrderStatusesList.fromObject(object.availableOrderStatusesList);
                }
                if (object.externalAuthToken != null) {
                    if (typeof object.externalAuthToken !== "object")
                        throw TypeError(".fastcity.admin.ServerMessage.externalAuthToken: object expected");
                    message.externalAuthToken = $root.fastcity.admin.ExternalAuthToken.fromObject(object.externalAuthToken);
                }
                if (object.externalAuthTokenList != null) {
                    if (typeof object.externalAuthTokenList !== "object")
                        throw TypeError(".fastcity.admin.ServerMessage.externalAuthTokenList: object expected");
                    message.externalAuthTokenList = $root.fastcity.admin.ExternalAuthTokenList.fromObject(object.externalAuthTokenList);
                }
                if (object.serverVersion != null) {
                    if (typeof object.serverVersion !== "object")
                        throw TypeError(".fastcity.admin.ServerMessage.serverVersion: object expected");
                    message.serverVersion = $root.fastcity.common.Version.fromObject(object.serverVersion);
                }
                if (object.orderUpdates != null) {
                    if (typeof object.orderUpdates !== "object")
                        throw TypeError(".fastcity.admin.ServerMessage.orderUpdates: object expected");
                    message.orderUpdates = $root.fastcity.admin.OrderUpdateList.fromObject(object.orderUpdates);
                }
                if (object.integrationConnectionStatus != null) {
                    if (typeof object.integrationConnectionStatus !== "object")
                        throw TypeError(".fastcity.admin.ServerMessage.integrationConnectionStatus: object expected");
                    message.integrationConnectionStatus = $root.fastcity.admin.IntegrationConnectionStatus.fromObject(object.integrationConnectionStatus);
                }
                if (object.integrationConnectionStatusList != null) {
                    if (typeof object.integrationConnectionStatusList !== "object")
                        throw TypeError(".fastcity.admin.ServerMessage.integrationConnectionStatusList: object expected");
                    message.integrationConnectionStatusList = $root.fastcity.admin.IntegrationConnectionStatusList.fromObject(object.integrationConnectionStatusList);
                }
                if (object.logList != null) {
                    if (typeof object.logList !== "object")
                        throw TypeError(".fastcity.admin.ServerMessage.logList: object expected");
                    message.logList = $root.fastcity.admin.LogRecordList.fromObject(object.logList);
                }
                if (object.orderPath != null) {
                    if (typeof object.orderPath !== "object")
                        throw TypeError(".fastcity.admin.ServerMessage.orderPath: object expected");
                    message.orderPath = $root.fastcity.common.OrderPath.fromObject(object.orderPath);
                }
                if (object.orderPathList != null) {
                    if (typeof object.orderPathList !== "object")
                        throw TypeError(".fastcity.admin.ServerMessage.orderPathList: object expected");
                    message.orderPathList = $root.fastcity.common.OrderPathList.fromObject(object.orderPathList);
                }
                if (object.subCompanyIdToExternalCourierId != null) {
                    if (typeof object.subCompanyIdToExternalCourierId !== "object")
                        throw TypeError(".fastcity.admin.ServerMessage.subCompanyIdToExternalCourierId: object expected");
                    message.subCompanyIdToExternalCourierId = $root.fastcity.admin.SubCompanyIdToExternalCourierId.fromObject(object.subCompanyIdToExternalCourierId);
                }
                if (object.subCompanyIdToExternalCourierIdList != null) {
                    if (typeof object.subCompanyIdToExternalCourierIdList !== "object")
                        throw TypeError(".fastcity.admin.ServerMessage.subCompanyIdToExternalCourierIdList: object expected");
                    message.subCompanyIdToExternalCourierIdList = $root.fastcity.admin.SubCompanyIdToExternalCourierIdList.fromObject(object.subCompanyIdToExternalCourierIdList);
                }
                if (object.serverConfig != null) {
                    if (typeof object.serverConfig !== "object")
                        throw TypeError(".fastcity.admin.ServerMessage.serverConfig: object expected");
                    message.serverConfig = $root.fastcity.admin.ServerConfig.fromObject(object.serverConfig);
                }
                return message;
            };

            /**
             * Creates a plain object from a ServerMessage message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.ServerMessage
             * @static
             * @param {fastcity.admin.ServerMessage} message ServerMessage
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ServerMessage.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.requestId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.requestId = options.longs === String ? "0" : 0;
                if (message.requestId != null && message.hasOwnProperty("requestId"))
                    if (typeof message.requestId === "number")
                        object.requestId = options.longs === String ? String(message.requestId) : message.requestId;
                    else
                        object.requestId = options.longs === String ? $util.Long.prototype.toString.call(message.requestId) : options.longs === Number ? new $util.LongBits(message.requestId.low >>> 0, message.requestId.high >>> 0).toNumber(true) : message.requestId;
                if (message.result != null && message.hasOwnProperty("result")) {
                    object.result = $root.fastcity.admin.Result.toObject(message.result, options);
                    if (options.oneofs)
                        object.body = "result";
                }
                if (message.admin != null && message.hasOwnProperty("admin")) {
                    object.admin = $root.fastcity.admin.Admin.toObject(message.admin, options);
                    if (options.oneofs)
                        object.body = "admin";
                }
                if (message.adminList != null && message.hasOwnProperty("adminList")) {
                    object.adminList = $root.fastcity.admin.AdminList.toObject(message.adminList, options);
                    if (options.oneofs)
                        object.body = "adminList";
                }
                if (message.company != null && message.hasOwnProperty("company")) {
                    object.company = $root.fastcity.admin.Company.toObject(message.company, options);
                    if (options.oneofs)
                        object.body = "company";
                }
                if (message.companyList != null && message.hasOwnProperty("companyList")) {
                    object.companyList = $root.fastcity.admin.CompanyList.toObject(message.companyList, options);
                    if (options.oneofs)
                        object.body = "companyList";
                }
                if (message.courier != null && message.hasOwnProperty("courier")) {
                    object.courier = $root.fastcity.admin.Courier.toObject(message.courier, options);
                    if (options.oneofs)
                        object.body = "courier";
                }
                if (message.courierList != null && message.hasOwnProperty("courierList")) {
                    object.courierList = $root.fastcity.admin.CourierList.toObject(message.courierList, options);
                    if (options.oneofs)
                        object.body = "courierList";
                }
                if (message.group != null && message.hasOwnProperty("group")) {
                    object.group = $root.fastcity.admin.Group.toObject(message.group, options);
                    if (options.oneofs)
                        object.body = "group";
                }
                if (message.groupList != null && message.hasOwnProperty("groupList")) {
                    object.groupList = $root.fastcity.admin.GroupList.toObject(message.groupList, options);
                    if (options.oneofs)
                        object.body = "groupList";
                }
                if (message.locationList != null && message.hasOwnProperty("locationList")) {
                    object.locationList = $root.fastcity.admin.LocationList.toObject(message.locationList, options);
                    if (options.oneofs)
                        object.body = "locationList";
                }
                if (message.orderList != null && message.hasOwnProperty("orderList")) {
                    object.orderList = $root.fastcity.admin.OrderList.toObject(message.orderList, options);
                    if (options.oneofs)
                        object.body = "orderList";
                }
                if (message.connectionStatus != null && message.hasOwnProperty("connectionStatus")) {
                    object.connectionStatus = $root.fastcity.admin.ConnectionStatus.toObject(message.connectionStatus, options);
                    if (options.oneofs)
                        object.body = "connectionStatus";
                }
                if (message.connectionStatusList != null && message.hasOwnProperty("connectionStatusList")) {
                    object.connectionStatusList = $root.fastcity.admin.ConnectionStatusList.toObject(message.connectionStatusList, options);
                    if (options.oneofs)
                        object.body = "connectionStatusList";
                }
                if (message.integrationToken != null && message.hasOwnProperty("integrationToken")) {
                    object.integrationToken = $root.fastcity.admin.IntegrationToken.toObject(message.integrationToken, options);
                    if (options.oneofs)
                        object.body = "integrationToken";
                }
                if (message.integrationTokenList != null && message.hasOwnProperty("integrationTokenList")) {
                    object.integrationTokenList = $root.fastcity.admin.IntegrationTokenList.toObject(message.integrationTokenList, options);
                    if (options.oneofs)
                        object.body = "integrationTokenList";
                }
                if (message.changedCourierIdList != null && message.hasOwnProperty("changedCourierIdList")) {
                    object.changedCourierIdList = $root.fastcity.admin.ChangedCourierIdList.toObject(message.changedCourierIdList, options);
                    if (options.oneofs)
                        object.body = "changedCourierIdList";
                }
                if (message.changedOrderIdList != null && message.hasOwnProperty("changedOrderIdList")) {
                    object.changedOrderIdList = $root.fastcity.admin.ChangedOrderIdList.toObject(message.changedOrderIdList, options);
                    if (options.oneofs)
                        object.body = "changedOrderIdList";
                }
                if (message.logonResult != null && message.hasOwnProperty("logonResult")) {
                    object.logonResult = $root.fastcity.admin.LogonResult.toObject(message.logonResult, options);
                    if (options.oneofs)
                        object.body = "logonResult";
                }
                if (message.changedLastLocation != null && message.hasOwnProperty("changedLastLocation")) {
                    object.changedLastLocation = $root.fastcity.admin.ChangedLastLocation.toObject(message.changedLastLocation, options);
                    if (options.oneofs)
                        object.body = "changedLastLocation";
                }
                if (message.deliveryStatDayList != null && message.hasOwnProperty("deliveryStatDayList")) {
                    object.deliveryStatDayList = $root.fastcity.admin.DeliveryStatDayList.toObject(message.deliveryStatDayList, options);
                    if (options.oneofs)
                        object.body = "deliveryStatDayList";
                }
                if (message.deliveryStatList != null && message.hasOwnProperty("deliveryStatList")) {
                    object.deliveryStatList = $root.fastcity.admin.DeliveryStatList.toObject(message.deliveryStatList, options);
                    if (options.oneofs)
                        object.body = "deliveryStatList";
                }
                if (message.workingDayList != null && message.hasOwnProperty("workingDayList")) {
                    object.workingDayList = $root.fastcity.admin.WorkingDayList.toObject(message.workingDayList, options);
                    if (options.oneofs)
                        object.body = "workingDayList";
                }
                if (message.subCompanyIdList != null && message.hasOwnProperty("subCompanyIdList")) {
                    object.subCompanyIdList = $root.fastcity.admin.SubCompanyIdList.toObject(message.subCompanyIdList, options);
                    if (options.oneofs)
                        object.body = "subCompanyIdList";
                }
                if (message.subCompany != null && message.hasOwnProperty("subCompany")) {
                    object.subCompany = $root.fastcity.admin.SubCompany.toObject(message.subCompany, options);
                    if (options.oneofs)
                        object.body = "subCompany";
                }
                if (message.getSubCompanyList != null && message.hasOwnProperty("getSubCompanyList")) {
                    object.getSubCompanyList = $root.fastcity.admin.SubCompanyList.toObject(message.getSubCompanyList, options);
                    if (options.oneofs)
                        object.body = "getSubCompanyList";
                }
                if (message.subCompanyZone != null && message.hasOwnProperty("subCompanyZone")) {
                    object.subCompanyZone = $root.fastcity.admin.SubCompanyZone.toObject(message.subCompanyZone, options);
                    if (options.oneofs)
                        object.body = "subCompanyZone";
                }
                if (message.subCompanyZoneList != null && message.hasOwnProperty("subCompanyZoneList")) {
                    object.subCompanyZoneList = $root.fastcity.admin.SubCompanyZoneList.toObject(message.subCompanyZoneList, options);
                    if (options.oneofs)
                        object.body = "subCompanyZoneList";
                }
                if (message.availableOrderStatuses != null && message.hasOwnProperty("availableOrderStatuses")) {
                    object.availableOrderStatuses = $root.fastcity.admin.AvailableOrderStatuses.toObject(message.availableOrderStatuses, options);
                    if (options.oneofs)
                        object.body = "availableOrderStatuses";
                }
                if (message.externalAuthToken != null && message.hasOwnProperty("externalAuthToken")) {
                    object.externalAuthToken = $root.fastcity.admin.ExternalAuthToken.toObject(message.externalAuthToken, options);
                    if (options.oneofs)
                        object.body = "externalAuthToken";
                }
                if (message.externalAuthTokenList != null && message.hasOwnProperty("externalAuthTokenList")) {
                    object.externalAuthTokenList = $root.fastcity.admin.ExternalAuthTokenList.toObject(message.externalAuthTokenList, options);
                    if (options.oneofs)
                        object.body = "externalAuthTokenList";
                }
                if (message.availableOrderStatusesList != null && message.hasOwnProperty("availableOrderStatusesList")) {
                    object.availableOrderStatusesList = $root.fastcity.admin.AvailableOrderStatusesList.toObject(message.availableOrderStatusesList, options);
                    if (options.oneofs)
                        object.body = "availableOrderStatusesList";
                }
                if (message.serverVersion != null && message.hasOwnProperty("serverVersion")) {
                    object.serverVersion = $root.fastcity.common.Version.toObject(message.serverVersion, options);
                    if (options.oneofs)
                        object.body = "serverVersion";
                }
                if (message.orderUpdates != null && message.hasOwnProperty("orderUpdates")) {
                    object.orderUpdates = $root.fastcity.admin.OrderUpdateList.toObject(message.orderUpdates, options);
                    if (options.oneofs)
                        object.body = "orderUpdates";
                }
                if (message.integrationConnectionStatus != null && message.hasOwnProperty("integrationConnectionStatus")) {
                    object.integrationConnectionStatus = $root.fastcity.admin.IntegrationConnectionStatus.toObject(message.integrationConnectionStatus, options);
                    if (options.oneofs)
                        object.body = "integrationConnectionStatus";
                }
                if (message.integrationConnectionStatusList != null && message.hasOwnProperty("integrationConnectionStatusList")) {
                    object.integrationConnectionStatusList = $root.fastcity.admin.IntegrationConnectionStatusList.toObject(message.integrationConnectionStatusList, options);
                    if (options.oneofs)
                        object.body = "integrationConnectionStatusList";
                }
                if (message.logList != null && message.hasOwnProperty("logList")) {
                    object.logList = $root.fastcity.admin.LogRecordList.toObject(message.logList, options);
                    if (options.oneofs)
                        object.body = "logList";
                }
                if (message.orderPath != null && message.hasOwnProperty("orderPath")) {
                    object.orderPath = $root.fastcity.common.OrderPath.toObject(message.orderPath, options);
                    if (options.oneofs)
                        object.body = "orderPath";
                }
                if (message.orderPathList != null && message.hasOwnProperty("orderPathList")) {
                    object.orderPathList = $root.fastcity.common.OrderPathList.toObject(message.orderPathList, options);
                    if (options.oneofs)
                        object.body = "orderPathList";
                }
                if (message.subCompanyIdToExternalCourierId != null && message.hasOwnProperty("subCompanyIdToExternalCourierId")) {
                    object.subCompanyIdToExternalCourierId = $root.fastcity.admin.SubCompanyIdToExternalCourierId.toObject(message.subCompanyIdToExternalCourierId, options);
                    if (options.oneofs)
                        object.body = "subCompanyIdToExternalCourierId";
                }
                if (message.subCompanyIdToExternalCourierIdList != null && message.hasOwnProperty("subCompanyIdToExternalCourierIdList")) {
                    object.subCompanyIdToExternalCourierIdList = $root.fastcity.admin.SubCompanyIdToExternalCourierIdList.toObject(message.subCompanyIdToExternalCourierIdList, options);
                    if (options.oneofs)
                        object.body = "subCompanyIdToExternalCourierIdList";
                }
                if (message.courierFromExternal != null && message.hasOwnProperty("courierFromExternal")) {
                    object.courierFromExternal = $root.fastcity.admin.CourierFromExternal.toObject(message.courierFromExternal, options);
                    if (options.oneofs)
                        object.body = "courierFromExternal";
                }
                if (message.courierFromExternalList != null && message.hasOwnProperty("courierFromExternalList")) {
                    object.courierFromExternalList = $root.fastcity.admin.CourierFromExternalList.toObject(message.courierFromExternalList, options);
                    if (options.oneofs)
                        object.body = "courierFromExternalList";
                }
                if (message.serverConfig != null && message.hasOwnProperty("serverConfig")) {
                    object.serverConfig = $root.fastcity.admin.ServerConfig.toObject(message.serverConfig, options);
                    if (options.oneofs)
                        object.body = "serverConfig";
                }
                return object;
            };

            /**
             * Converts this ServerMessage to JSON.
             * @function toJSON
             * @memberof fastcity.admin.ServerMessage
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ServerMessage.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return ServerMessage;
        })();

        admin.ResetPassword = (function() {

            /**
             * Properties of a ResetPassword.
             * @memberof fastcity.admin
             * @interface IResetPassword
             * @property {string|null} [login] ResetPassword login
             */

            /**
             * Constructs a new ResetPassword.
             * @memberof fastcity.admin
             * @classdesc Represents a ResetPassword.
             * @implements IResetPassword
             * @constructor
             * @param {fastcity.admin.IResetPassword=} [properties] Properties to set
             */
            function ResetPassword(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ResetPassword login.
             * @member {string} login
             * @memberof fastcity.admin.ResetPassword
             * @instance
             */
            ResetPassword.prototype.login = "";

            /**
             * Creates a new ResetPassword instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.ResetPassword
             * @static
             * @param {fastcity.admin.IResetPassword=} [properties] Properties to set
             * @returns {fastcity.admin.ResetPassword} ResetPassword instance
             */
            ResetPassword.create = function create(properties) {
                return new ResetPassword(properties);
            };

            /**
             * Encodes the specified ResetPassword message. Does not implicitly {@link fastcity.admin.ResetPassword.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.ResetPassword
             * @static
             * @param {fastcity.admin.IResetPassword} message ResetPassword message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ResetPassword.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.login != null && Object.hasOwnProperty.call(message, "login"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.login);
                return writer;
            };

            /**
             * Decodes a ResetPassword message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.ResetPassword
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.ResetPassword} ResetPassword
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ResetPassword.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.ResetPassword();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.login = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a ResetPassword message.
             * @function verify
             * @memberof fastcity.admin.ResetPassword
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ResetPassword.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.login != null && message.hasOwnProperty("login"))
                    if (!$util.isString(message.login))
                        return "login: string expected";
                return null;
            };

            /**
             * Creates a ResetPassword message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.ResetPassword
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.ResetPassword} ResetPassword
             */
            ResetPassword.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.ResetPassword)
                    return object;
                var message = new $root.fastcity.admin.ResetPassword();
                if (object.login != null)
                    message.login = String(object.login);
                return message;
            };

            /**
             * Creates a plain object from a ResetPassword message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.ResetPassword
             * @static
             * @param {fastcity.admin.ResetPassword} message ResetPassword
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ResetPassword.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.login = "";
                if (message.login != null && message.hasOwnProperty("login"))
                    object.login = message.login;
                return object;
            };

            /**
             * Converts this ResetPassword to JSON.
             * @function toJSON
             * @memberof fastcity.admin.ResetPassword
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ResetPassword.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return ResetPassword;
        })();

        admin.Result = (function() {

            /**
             * Properties of a Result.
             * @memberof fastcity.admin
             * @interface IResult
             * @property {fastcity.admin.Result.Code|null} [code] Result code
             * @property {string|null} [description] Result description
             * @property {fastcity.admin.AdminRequestResult|null} [adminRequestResult] Result adminRequestResult
             * @property {fastcity.admin.AvailableOrderStatusesRequestResult|null} [availableOrderStatusesRequestResult] Result availableOrderStatusesRequestResult
             * @property {fastcity.admin.CompanyRequestResult|null} [companyRequestResult] Result companyRequestResult
             * @property {fastcity.admin.CourierRequestResult|null} [courierRequestResult] Result courierRequestResult
             * @property {fastcity.admin.DeliveryStatRequestResult|null} [deliveryStatRequestResult] Result deliveryStatRequestResult
             * @property {fastcity.admin.ExternalAuthTokenRequestResult|null} [externalAuthTokenRequestResult] Result externalAuthTokenRequestResult
             * @property {fastcity.admin.GroupRequestResult|null} [groupRequestResult] Result groupRequestResult
             * @property {fastcity.admin.LocationRequestResult|null} [locationRequestResult] Result locationRequestResult
             * @property {fastcity.common.OrderPathRequestResult|null} [orderPathRequestResult] Result orderPathRequestResult
             * @property {fastcity.admin.OrderRequestResult|null} [orderRequestResult] Result orderRequestResult
             * @property {fastcity.admin.OrderUpdateRequestResult|null} [orderUpdateRequestResult] Result orderUpdateRequestResult
             * @property {fastcity.admin.SubCompanyRequestResult|null} [subCompanyRequestResult] Result subCompanyRequestResult
             * @property {fastcity.admin.SubCompanyZoneRequestResult|null} [subCompanyZoneRequestResult] Result subCompanyZoneRequestResult
             */

            /**
             * Constructs a new Result.
             * @memberof fastcity.admin
             * @classdesc Represents a Result.
             * @implements IResult
             * @constructor
             * @param {fastcity.admin.IResult=} [properties] Properties to set
             */
            function Result(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Result code.
             * @member {fastcity.admin.Result.Code} code
             * @memberof fastcity.admin.Result
             * @instance
             */
            Result.prototype.code = 0;

            /**
             * Result description.
             * @member {string} description
             * @memberof fastcity.admin.Result
             * @instance
             */
            Result.prototype.description = "";

            /**
             * Result adminRequestResult.
             * @member {fastcity.admin.AdminRequestResult|null|undefined} adminRequestResult
             * @memberof fastcity.admin.Result
             * @instance
             */
            Result.prototype.adminRequestResult = null;

            /**
             * Result availableOrderStatusesRequestResult.
             * @member {fastcity.admin.AvailableOrderStatusesRequestResult|null|undefined} availableOrderStatusesRequestResult
             * @memberof fastcity.admin.Result
             * @instance
             */
            Result.prototype.availableOrderStatusesRequestResult = null;

            /**
             * Result companyRequestResult.
             * @member {fastcity.admin.CompanyRequestResult|null|undefined} companyRequestResult
             * @memberof fastcity.admin.Result
             * @instance
             */
            Result.prototype.companyRequestResult = null;

            /**
             * Result courierRequestResult.
             * @member {fastcity.admin.CourierRequestResult|null|undefined} courierRequestResult
             * @memberof fastcity.admin.Result
             * @instance
             */
            Result.prototype.courierRequestResult = null;

            /**
             * Result deliveryStatRequestResult.
             * @member {fastcity.admin.DeliveryStatRequestResult|null|undefined} deliveryStatRequestResult
             * @memberof fastcity.admin.Result
             * @instance
             */
            Result.prototype.deliveryStatRequestResult = null;

            /**
             * Result externalAuthTokenRequestResult.
             * @member {fastcity.admin.ExternalAuthTokenRequestResult|null|undefined} externalAuthTokenRequestResult
             * @memberof fastcity.admin.Result
             * @instance
             */
            Result.prototype.externalAuthTokenRequestResult = null;

            /**
             * Result groupRequestResult.
             * @member {fastcity.admin.GroupRequestResult|null|undefined} groupRequestResult
             * @memberof fastcity.admin.Result
             * @instance
             */
            Result.prototype.groupRequestResult = null;

            /**
             * Result locationRequestResult.
             * @member {fastcity.admin.LocationRequestResult|null|undefined} locationRequestResult
             * @memberof fastcity.admin.Result
             * @instance
             */
            Result.prototype.locationRequestResult = null;

            /**
             * Result orderPathRequestResult.
             * @member {fastcity.common.OrderPathRequestResult|null|undefined} orderPathRequestResult
             * @memberof fastcity.admin.Result
             * @instance
             */
            Result.prototype.orderPathRequestResult = null;

            /**
             * Result orderRequestResult.
             * @member {fastcity.admin.OrderRequestResult|null|undefined} orderRequestResult
             * @memberof fastcity.admin.Result
             * @instance
             */
            Result.prototype.orderRequestResult = null;

            /**
             * Result orderUpdateRequestResult.
             * @member {fastcity.admin.OrderUpdateRequestResult|null|undefined} orderUpdateRequestResult
             * @memberof fastcity.admin.Result
             * @instance
             */
            Result.prototype.orderUpdateRequestResult = null;

            /**
             * Result subCompanyRequestResult.
             * @member {fastcity.admin.SubCompanyRequestResult|null|undefined} subCompanyRequestResult
             * @memberof fastcity.admin.Result
             * @instance
             */
            Result.prototype.subCompanyRequestResult = null;

            /**
             * Result subCompanyZoneRequestResult.
             * @member {fastcity.admin.SubCompanyZoneRequestResult|null|undefined} subCompanyZoneRequestResult
             * @memberof fastcity.admin.Result
             * @instance
             */
            Result.prototype.subCompanyZoneRequestResult = null;

            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;

            /**
             * Result descriptionCode.
             * @member {"adminRequestResult"|"availableOrderStatusesRequestResult"|"companyRequestResult"|"courierRequestResult"|"deliveryStatRequestResult"|"externalAuthTokenRequestResult"|"groupRequestResult"|"locationRequestResult"|"orderPathRequestResult"|"orderRequestResult"|"orderUpdateRequestResult"|"subCompanyRequestResult"|"subCompanyZoneRequestResult"|undefined} descriptionCode
             * @memberof fastcity.admin.Result
             * @instance
             */
            Object.defineProperty(Result.prototype, "descriptionCode", {
                get: $util.oneOfGetter($oneOfFields = ["adminRequestResult", "availableOrderStatusesRequestResult", "companyRequestResult", "courierRequestResult", "deliveryStatRequestResult", "externalAuthTokenRequestResult", "groupRequestResult", "locationRequestResult", "orderPathRequestResult", "orderRequestResult", "orderUpdateRequestResult", "subCompanyRequestResult", "subCompanyZoneRequestResult"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Creates a new Result instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.Result
             * @static
             * @param {fastcity.admin.IResult=} [properties] Properties to set
             * @returns {fastcity.admin.Result} Result instance
             */
            Result.create = function create(properties) {
                return new Result(properties);
            };

            /**
             * Encodes the specified Result message. Does not implicitly {@link fastcity.admin.Result.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.Result
             * @static
             * @param {fastcity.admin.IResult} message Result message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Result.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.code != null && Object.hasOwnProperty.call(message, "code"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.code);
                if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.description);
                if (message.adminRequestResult != null && Object.hasOwnProperty.call(message, "adminRequestResult"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.adminRequestResult);
                if (message.availableOrderStatusesRequestResult != null && Object.hasOwnProperty.call(message, "availableOrderStatusesRequestResult"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int32(message.availableOrderStatusesRequestResult);
                if (message.companyRequestResult != null && Object.hasOwnProperty.call(message, "companyRequestResult"))
                    writer.uint32(/* id 5, wireType 0 =*/40).int32(message.companyRequestResult);
                if (message.courierRequestResult != null && Object.hasOwnProperty.call(message, "courierRequestResult"))
                    writer.uint32(/* id 6, wireType 0 =*/48).int32(message.courierRequestResult);
                if (message.deliveryStatRequestResult != null && Object.hasOwnProperty.call(message, "deliveryStatRequestResult"))
                    writer.uint32(/* id 7, wireType 0 =*/56).int32(message.deliveryStatRequestResult);
                if (message.externalAuthTokenRequestResult != null && Object.hasOwnProperty.call(message, "externalAuthTokenRequestResult"))
                    writer.uint32(/* id 8, wireType 0 =*/64).int32(message.externalAuthTokenRequestResult);
                if (message.groupRequestResult != null && Object.hasOwnProperty.call(message, "groupRequestResult"))
                    writer.uint32(/* id 9, wireType 0 =*/72).int32(message.groupRequestResult);
                if (message.locationRequestResult != null && Object.hasOwnProperty.call(message, "locationRequestResult"))
                    writer.uint32(/* id 10, wireType 0 =*/80).int32(message.locationRequestResult);
                if (message.orderPathRequestResult != null && Object.hasOwnProperty.call(message, "orderPathRequestResult"))
                    writer.uint32(/* id 11, wireType 0 =*/88).int32(message.orderPathRequestResult);
                if (message.orderRequestResult != null && Object.hasOwnProperty.call(message, "orderRequestResult"))
                    writer.uint32(/* id 12, wireType 0 =*/96).int32(message.orderRequestResult);
                if (message.orderUpdateRequestResult != null && Object.hasOwnProperty.call(message, "orderUpdateRequestResult"))
                    writer.uint32(/* id 13, wireType 0 =*/104).int32(message.orderUpdateRequestResult);
                if (message.subCompanyRequestResult != null && Object.hasOwnProperty.call(message, "subCompanyRequestResult"))
                    writer.uint32(/* id 14, wireType 0 =*/112).int32(message.subCompanyRequestResult);
                if (message.subCompanyZoneRequestResult != null && Object.hasOwnProperty.call(message, "subCompanyZoneRequestResult"))
                    writer.uint32(/* id 15, wireType 0 =*/120).int32(message.subCompanyZoneRequestResult);
                return writer;
            };

            /**
             * Decodes a Result message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.Result
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.Result} Result
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Result.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.Result();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.code = reader.int32();
                        break;
                    case 2:
                        message.description = reader.string();
                        break;
                    case 3:
                        message.adminRequestResult = reader.int32();
                        break;
                    case 4:
                        message.availableOrderStatusesRequestResult = reader.int32();
                        break;
                    case 5:
                        message.companyRequestResult = reader.int32();
                        break;
                    case 6:
                        message.courierRequestResult = reader.int32();
                        break;
                    case 7:
                        message.deliveryStatRequestResult = reader.int32();
                        break;
                    case 8:
                        message.externalAuthTokenRequestResult = reader.int32();
                        break;
                    case 9:
                        message.groupRequestResult = reader.int32();
                        break;
                    case 10:
                        message.locationRequestResult = reader.int32();
                        break;
                    case 11:
                        message.orderPathRequestResult = reader.int32();
                        break;
                    case 12:
                        message.orderRequestResult = reader.int32();
                        break;
                    case 13:
                        message.orderUpdateRequestResult = reader.int32();
                        break;
                    case 14:
                        message.subCompanyRequestResult = reader.int32();
                        break;
                    case 15:
                        message.subCompanyZoneRequestResult = reader.int32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a Result message.
             * @function verify
             * @memberof fastcity.admin.Result
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Result.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                var properties = {};
                if (message.code != null && message.hasOwnProperty("code"))
                    switch (message.code) {
                    default:
                        return "code: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                        break;
                    }
                if (message.description != null && message.hasOwnProperty("description"))
                    if (!$util.isString(message.description))
                        return "description: string expected";
                if (message.adminRequestResult != null && message.hasOwnProperty("adminRequestResult")) {
                    properties.descriptionCode = 1;
                    switch (message.adminRequestResult) {
                    default:
                        return "adminRequestResult: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                    case 9:
                    case 10:
                    case 11:
                        break;
                    }
                }
                if (message.availableOrderStatusesRequestResult != null && message.hasOwnProperty("availableOrderStatusesRequestResult")) {
                    if (properties.descriptionCode === 1)
                        return "descriptionCode: multiple values";
                    properties.descriptionCode = 1;
                    switch (message.availableOrderStatusesRequestResult) {
                    default:
                        return "availableOrderStatusesRequestResult: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
                }
                if (message.companyRequestResult != null && message.hasOwnProperty("companyRequestResult")) {
                    if (properties.descriptionCode === 1)
                        return "descriptionCode: multiple values";
                    properties.descriptionCode = 1;
                    switch (message.companyRequestResult) {
                    default:
                        return "companyRequestResult: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                        break;
                    }
                }
                if (message.courierRequestResult != null && message.hasOwnProperty("courierRequestResult")) {
                    if (properties.descriptionCode === 1)
                        return "descriptionCode: multiple values";
                    properties.descriptionCode = 1;
                    switch (message.courierRequestResult) {
                    default:
                        return "courierRequestResult: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                    case 9:
                    case 10:
                        break;
                    }
                }
                if (message.deliveryStatRequestResult != null && message.hasOwnProperty("deliveryStatRequestResult")) {
                    if (properties.descriptionCode === 1)
                        return "descriptionCode: multiple values";
                    properties.descriptionCode = 1;
                    switch (message.deliveryStatRequestResult) {
                    default:
                        return "deliveryStatRequestResult: enum value expected";
                    case 0:
                        break;
                    }
                }
                if (message.externalAuthTokenRequestResult != null && message.hasOwnProperty("externalAuthTokenRequestResult")) {
                    if (properties.descriptionCode === 1)
                        return "descriptionCode: multiple values";
                    properties.descriptionCode = 1;
                    switch (message.externalAuthTokenRequestResult) {
                    default:
                        return "externalAuthTokenRequestResult: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                    case 9:
                    case 10:
                    case 11:
                    case 12:
                        break;
                    }
                }
                if (message.groupRequestResult != null && message.hasOwnProperty("groupRequestResult")) {
                    if (properties.descriptionCode === 1)
                        return "descriptionCode: multiple values";
                    properties.descriptionCode = 1;
                    switch (message.groupRequestResult) {
                    default:
                        return "groupRequestResult: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
                }
                if (message.locationRequestResult != null && message.hasOwnProperty("locationRequestResult")) {
                    if (properties.descriptionCode === 1)
                        return "descriptionCode: multiple values";
                    properties.descriptionCode = 1;
                    switch (message.locationRequestResult) {
                    default:
                        return "locationRequestResult: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
                }
                if (message.orderPathRequestResult != null && message.hasOwnProperty("orderPathRequestResult")) {
                    if (properties.descriptionCode === 1)
                        return "descriptionCode: multiple values";
                    properties.descriptionCode = 1;
                    switch (message.orderPathRequestResult) {
                    default:
                        return "orderPathRequestResult: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
                }
                if (message.orderRequestResult != null && message.hasOwnProperty("orderRequestResult")) {
                    if (properties.descriptionCode === 1)
                        return "descriptionCode: multiple values";
                    properties.descriptionCode = 1;
                    switch (message.orderRequestResult) {
                    default:
                        return "orderRequestResult: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                        break;
                    }
                }
                if (message.orderUpdateRequestResult != null && message.hasOwnProperty("orderUpdateRequestResult")) {
                    if (properties.descriptionCode === 1)
                        return "descriptionCode: multiple values";
                    properties.descriptionCode = 1;
                    switch (message.orderUpdateRequestResult) {
                    default:
                        return "orderUpdateRequestResult: enum value expected";
                    case 0:
                        break;
                    }
                }
                if (message.subCompanyRequestResult != null && message.hasOwnProperty("subCompanyRequestResult")) {
                    if (properties.descriptionCode === 1)
                        return "descriptionCode: multiple values";
                    properties.descriptionCode = 1;
                    switch (message.subCompanyRequestResult) {
                    default:
                        return "subCompanyRequestResult: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                        break;
                    }
                }
                if (message.subCompanyZoneRequestResult != null && message.hasOwnProperty("subCompanyZoneRequestResult")) {
                    if (properties.descriptionCode === 1)
                        return "descriptionCode: multiple values";
                    properties.descriptionCode = 1;
                    switch (message.subCompanyZoneRequestResult) {
                    default:
                        return "subCompanyZoneRequestResult: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                        break;
                    }
                }
                return null;
            };

            /**
             * Creates a Result message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.Result
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.Result} Result
             */
            Result.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.Result)
                    return object;
                var message = new $root.fastcity.admin.Result();
                switch (object.code) {
                case "SUCCESS":
                case 0:
                    message.code = 0;
                    break;
                case "INVALID_REQUEST":
                case 1:
                    message.code = 1;
                    break;
                case "SERVER_ERROR":
                case 2:
                    message.code = 2;
                    break;
                case "NO_ACCESS":
                case 3:
                    message.code = 3;
                    break;
                case "CANNOT_SEND_EMAIL":
                case 4:
                    message.code = 4;
                    break;
                }
                if (object.description != null)
                    message.description = String(object.description);
                switch (object.adminRequestResult) {
                case "ARR_NOT_FOUND":
                case 0:
                    message.adminRequestResult = 0;
                    break;
                case "ARR_ALREADY_EXIST":
                case 1:
                    message.adminRequestResult = 1;
                    break;
                case "ARR_BAD_LOGIN":
                case 2:
                    message.adminRequestResult = 2;
                    break;
                case "ARR_BAD_COMPANY_ID_COMPANY_NOT_FOUND":
                case 3:
                    message.adminRequestResult = 3;
                    break;
                case "ARR_BAD_COMPANY_ID_COMPANY_IS_DELETED":
                case 4:
                    message.adminRequestResult = 4;
                    break;
                case "ARR_BAD_NAME":
                case 5:
                    message.adminRequestResult = 5;
                    break;
                case "ARR_BAD_SUB_COMPANY_ID_SUB_COMPANY_NOT_FOUND":
                case 6:
                    message.adminRequestResult = 6;
                    break;
                case "ARR_BAD_SUB_COMPANY_ID_SUB_COMPANY_IS_DELETED":
                case 7:
                    message.adminRequestResult = 7;
                    break;
                case "ARR_BAD_SUB_COMPANY_ID_MUST_BE_AT_LEAST_ONE_ID":
                case 8:
                    message.adminRequestResult = 8;
                    break;
                case "ARR_BAD_EMAIL":
                case 9:
                    message.adminRequestResult = 9;
                    break;
                case "ARR_BAD_ROLE_EMPTY":
                case 10:
                    message.adminRequestResult = 10;
                    break;
                case "ARR_SUPER_ADMIN_HAVE_NO_COMPANY_ID":
                case 11:
                    message.adminRequestResult = 11;
                    break;
                }
                switch (object.availableOrderStatusesRequestResult) {
                case "AOSRR_NOT_FOUND":
                case 0:
                    message.availableOrderStatusesRequestResult = 0;
                    break;
                case "AOSRR_ALREADY_EXISTS":
                case 1:
                    message.availableOrderStatusesRequestResult = 1;
                    break;
                case "AOSRR_BAD_SUB_COMPANY_ID_SUB_COMPANY_NOT_FOUND":
                case 2:
                    message.availableOrderStatusesRequestResult = 2;
                    break;
                }
                switch (object.companyRequestResult) {
                case "CRR_NOT_FOUND":
                case 0:
                    message.companyRequestResult = 0;
                    break;
                case "CRR_ALREADY_EXISTS":
                case 1:
                    message.companyRequestResult = 1;
                    break;
                case "CRR_BAD_NAME":
                case 2:
                    message.companyRequestResult = 2;
                    break;
                case "CRR_BAD_MAXIMUM_DELIVERY_TIME_IS_NULL":
                case 3:
                    message.companyRequestResult = 3;
                    break;
                case "CRR_BAD_MAXIMUM_ACTIVE_ORDERS_ON_COURIER_IS_NULL":
                case 4:
                    message.companyRequestResult = 4;
                    break;
                case "CRR_IS_DELETED":
                case 5:
                    message.companyRequestResult = 5;
                    break;
                }
                switch (object.courierRequestResult) {
                case "CourRR_NOT_FOUND":
                case 0:
                    message.courierRequestResult = 0;
                    break;
                case "CourRR_ALREADY_EXISTS":
                case 1:
                    message.courierRequestResult = 1;
                    break;
                case "CourRR_BAD_COMPANY_ID_COMPANY_NOT_FOUND":
                case 2:
                    message.courierRequestResult = 2;
                    break;
                case "CourRR_BAD_SUB_COMPANY_ID_SUB_COMPANY_NOT_FOUND":
                case 3:
                    message.courierRequestResult = 3;
                    break;
                case "CourRR_BAD_PHONE":
                case 4:
                    message.courierRequestResult = 4;
                    break;
                case "CourRR_BAD_PASSWORD":
                case 5:
                    message.courierRequestResult = 5;
                    break;
                case "CourRR_BAD_GROUP_ID_GROUP_NOT_FOUND":
                case 6:
                    message.courierRequestResult = 6;
                    break;
                case "CourRR_BAD_GROUP_ID_GROUP_IS_DELETED":
                case 7:
                    message.courierRequestResult = 7;
                    break;
                case "CourRR_BAD_MINIMAL_DISTANCE_TO_ORDER_TARGET_IS_NULL":
                case 8:
                    message.courierRequestResult = 8;
                    break;
                case "CourRR_COURIER_ALREADY_HAVE_CONNECT_TO_SUB_COMPANY":
                case 9:
                    message.courierRequestResult = 9;
                    break;
                case "CourRR_CONNECT_OF_COURIER_TO_SUB_COMPANY_NOT_FOUND":
                case 10:
                    message.courierRequestResult = 10;
                    break;
                }
                switch (object.deliveryStatRequestResult) {
                case "DSRR_COURIER_NOT_FOUND":
                case 0:
                    message.deliveryStatRequestResult = 0;
                    break;
                }
                switch (object.externalAuthTokenRequestResult) {
                case "EATRR_NOT_FOUND":
                case 0:
                    message.externalAuthTokenRequestResult = 0;
                    break;
                case "EATRR_BAD_COMPANY_ID_COMPANY_NOT_FOUND":
                case 1:
                    message.externalAuthTokenRequestResult = 1;
                    break;
                case "EATRR_BAD_COMPANY_ID_COMPANY_IS_DELETED":
                case 2:
                    message.externalAuthTokenRequestResult = 2;
                    break;
                case "EATRR_BAD_EXTERNAL_TOKEN":
                case 3:
                    message.externalAuthTokenRequestResult = 3;
                    break;
                case "EATRR_BAD_URL":
                case 4:
                    message.externalAuthTokenRequestResult = 4;
                    break;
                case "EATRR_BAD_SUB_COMPANY_ID_SUB_COMPANY_NOT_FOUND":
                case 5:
                    message.externalAuthTokenRequestResult = 5;
                    break;
                case "EATRR_BAD_SUB_COMPANY_ID_SUB_COMPANY_IS_DELETED":
                case 6:
                    message.externalAuthTokenRequestResult = 6;
                    break;
                case "EATRR_EXTERNAL_AUTH_TOKEN_CONNECTED_TO_SUB_COMPANY":
                case 7:
                    message.externalAuthTokenRequestResult = 7;
                    break;
                case "EATRR_FAILED_TO_GET_COURIERS":
                case 8:
                    message.externalAuthTokenRequestResult = 8;
                    break;
                case "EATRR_FAILED_TO_GET_SUB_COMPANIES":
                case 9:
                    message.externalAuthTokenRequestResult = 9;
                    break;
                case "EATRR_CHECK_TOKEN_URL_MOVED":
                case 10:
                    message.externalAuthTokenRequestResult = 10;
                    break;
                case "EATRR_CHECK_TOKEN_NOT_AUTHORIZED":
                case 11:
                    message.externalAuthTokenRequestResult = 11;
                    break;
                case "EATRR_CHECK_TOKEN_BAD_REQUEST":
                case 12:
                    message.externalAuthTokenRequestResult = 12;
                    break;
                }
                switch (object.groupRequestResult) {
                case "GRR_NOT_FOUND":
                case 0:
                    message.groupRequestResult = 0;
                    break;
                case "GRR_BAD_COMPANY_ID_COMPANY_NOT_FOUND":
                case 1:
                    message.groupRequestResult = 1;
                    break;
                case "GRR_BAD_COMPANY_ID_COMPANY_IS_DELETED":
                case 2:
                    message.groupRequestResult = 2;
                    break;
                case "GRR_BAD_NAME_EMPTY":
                case 3:
                    message.groupRequestResult = 3;
                    break;
                }
                switch (object.locationRequestResult) {
                case "LocationRR_BAD_COURIER_ID_COURIER_NOT_FOUND":
                case 0:
                    message.locationRequestResult = 0;
                    break;
                case "LocationRR_BAD_COMPANY_ID_COMPANY_NOT_FOUND":
                case 1:
                    message.locationRequestResult = 1;
                    break;
                case "LocationRR_BAD_ORDER_ID_ORDER_NOT_FOUND":
                case 2:
                    message.locationRequestResult = 2;
                    break;
                }
                switch (object.orderPathRequestResult) {
                case "OPRR_NOT_FOUND":
                case 0:
                    message.orderPathRequestResult = 0;
                    break;
                case "OPRR_BAD_ORDER_ID_ORDER_NOT_FOUND":
                case 1:
                    message.orderPathRequestResult = 1;
                    break;
                case "OPRR_NOT_FOUND_SUB_COMPANY_OF_ORDER":
                case 2:
                    message.orderPathRequestResult = 2;
                    break;
                }
                switch (object.orderRequestResult) {
                case "ORR_NOT_FOUND":
                case 0:
                    message.orderRequestResult = 0;
                    break;
                case "ORR_BAD_COMPANY_ID_COMPANY_NOT_FOUND":
                case 1:
                    message.orderRequestResult = 1;
                    break;
                case "ORR_BAD_COMPANY_ID_COMPANY_IS_DELETED":
                case 2:
                    message.orderRequestResult = 2;
                    break;
                case "ORR_BAD_SUB_COMPANY_ID_SUB_COMPANY_NOT_FOUND":
                case 3:
                    message.orderRequestResult = 3;
                    break;
                case "ORR_BAD_SUB_COMPANY_ID_SUB_COMPANY_IS_DELETED":
                case 4:
                    message.orderRequestResult = 4;
                    break;
                case "ORR_BAD_COURIER_ID_COURIER_NOT_FOUND":
                case 5:
                    message.orderRequestResult = 5;
                    break;
                case "ORR_BAD_COURIER_ID_COURIER_IS_DELETED":
                case 6:
                    message.orderRequestResult = 6;
                    break;
                case "ORR_BAD_ORDER_ID_ORDER_IS_DELETED":
                case 7:
                    message.orderRequestResult = 7;
                    break;
                }
                switch (object.orderUpdateRequestResult) {
                case "OURR_NOT_FOUND":
                case 0:
                    message.orderUpdateRequestResult = 0;
                    break;
                }
                switch (object.subCompanyRequestResult) {
                case "SBRR_NOT_FOUND":
                case 0:
                    message.subCompanyRequestResult = 0;
                    break;
                case "SBRR_ALREADY_EXISTS":
                case 1:
                    message.subCompanyRequestResult = 1;
                    break;
                case "SBRR_BAD_COMPANY_ID_COMPANY_NOT_FOUND":
                case 2:
                    message.subCompanyRequestResult = 2;
                    break;
                case "SBRR_BAD_COMPANY_ID_COMPANY_IS_DELETED":
                case 3:
                    message.subCompanyRequestResult = 3;
                    break;
                case "SBRR_BAD_EXTERNAL_SUB_COMPANY_ID":
                case 4:
                    message.subCompanyRequestResult = 4;
                    break;
                case "SBRR_BAD_UPDATE_ORDERS_INTERVAL":
                case 5:
                    message.subCompanyRequestResult = 5;
                    break;
                case "SBRR_BAD_AVERAGE_DELIVERY_TIME":
                case 6:
                    message.subCompanyRequestResult = 6;
                    break;
                case "SBRR_BAD_ADDRESS":
                case 7:
                    message.subCompanyRequestResult = 7;
                    break;
                }
                switch (object.subCompanyZoneRequestResult) {
                case "SBZRR_NOT_FOUND":
                case 0:
                    message.subCompanyZoneRequestResult = 0;
                    break;
                case "SBZRR_BAD_COMPANY_ID_COMPANY_NOT_FOUND":
                case 1:
                    message.subCompanyZoneRequestResult = 1;
                    break;
                case "SBZRR_BAD_COMPANY_ID_COMPANY_IS_DELETED":
                case 2:
                    message.subCompanyZoneRequestResult = 2;
                    break;
                case "SBZRR_BAD_SUB_COMPANY_ID_SUB_COMPANY_NOT_FOUND":
                case 3:
                    message.subCompanyZoneRequestResult = 3;
                    break;
                case "SBZRR_BAD_SUB_COMPANY_ID_SUB_COMPANY_IS_DELETED":
                case 4:
                    message.subCompanyZoneRequestResult = 4;
                    break;
                }
                return message;
            };

            /**
             * Creates a plain object from a Result message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.Result
             * @static
             * @param {fastcity.admin.Result} message Result
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Result.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.code = options.enums === String ? "SUCCESS" : 0;
                    object.description = "";
                }
                if (message.code != null && message.hasOwnProperty("code"))
                    object.code = options.enums === String ? $root.fastcity.admin.Result.Code[message.code] : message.code;
                if (message.description != null && message.hasOwnProperty("description"))
                    object.description = message.description;
                if (message.adminRequestResult != null && message.hasOwnProperty("adminRequestResult")) {
                    object.adminRequestResult = options.enums === String ? $root.fastcity.admin.AdminRequestResult[message.adminRequestResult] : message.adminRequestResult;
                    if (options.oneofs)
                        object.descriptionCode = "adminRequestResult";
                }
                if (message.availableOrderStatusesRequestResult != null && message.hasOwnProperty("availableOrderStatusesRequestResult")) {
                    object.availableOrderStatusesRequestResult = options.enums === String ? $root.fastcity.admin.AvailableOrderStatusesRequestResult[message.availableOrderStatusesRequestResult] : message.availableOrderStatusesRequestResult;
                    if (options.oneofs)
                        object.descriptionCode = "availableOrderStatusesRequestResult";
                }
                if (message.companyRequestResult != null && message.hasOwnProperty("companyRequestResult")) {
                    object.companyRequestResult = options.enums === String ? $root.fastcity.admin.CompanyRequestResult[message.companyRequestResult] : message.companyRequestResult;
                    if (options.oneofs)
                        object.descriptionCode = "companyRequestResult";
                }
                if (message.courierRequestResult != null && message.hasOwnProperty("courierRequestResult")) {
                    object.courierRequestResult = options.enums === String ? $root.fastcity.admin.CourierRequestResult[message.courierRequestResult] : message.courierRequestResult;
                    if (options.oneofs)
                        object.descriptionCode = "courierRequestResult";
                }
                if (message.deliveryStatRequestResult != null && message.hasOwnProperty("deliveryStatRequestResult")) {
                    object.deliveryStatRequestResult = options.enums === String ? $root.fastcity.admin.DeliveryStatRequestResult[message.deliveryStatRequestResult] : message.deliveryStatRequestResult;
                    if (options.oneofs)
                        object.descriptionCode = "deliveryStatRequestResult";
                }
                if (message.externalAuthTokenRequestResult != null && message.hasOwnProperty("externalAuthTokenRequestResult")) {
                    object.externalAuthTokenRequestResult = options.enums === String ? $root.fastcity.admin.ExternalAuthTokenRequestResult[message.externalAuthTokenRequestResult] : message.externalAuthTokenRequestResult;
                    if (options.oneofs)
                        object.descriptionCode = "externalAuthTokenRequestResult";
                }
                if (message.groupRequestResult != null && message.hasOwnProperty("groupRequestResult")) {
                    object.groupRequestResult = options.enums === String ? $root.fastcity.admin.GroupRequestResult[message.groupRequestResult] : message.groupRequestResult;
                    if (options.oneofs)
                        object.descriptionCode = "groupRequestResult";
                }
                if (message.locationRequestResult != null && message.hasOwnProperty("locationRequestResult")) {
                    object.locationRequestResult = options.enums === String ? $root.fastcity.admin.LocationRequestResult[message.locationRequestResult] : message.locationRequestResult;
                    if (options.oneofs)
                        object.descriptionCode = "locationRequestResult";
                }
                if (message.orderPathRequestResult != null && message.hasOwnProperty("orderPathRequestResult")) {
                    object.orderPathRequestResult = options.enums === String ? $root.fastcity.common.OrderPathRequestResult[message.orderPathRequestResult] : message.orderPathRequestResult;
                    if (options.oneofs)
                        object.descriptionCode = "orderPathRequestResult";
                }
                if (message.orderRequestResult != null && message.hasOwnProperty("orderRequestResult")) {
                    object.orderRequestResult = options.enums === String ? $root.fastcity.admin.OrderRequestResult[message.orderRequestResult] : message.orderRequestResult;
                    if (options.oneofs)
                        object.descriptionCode = "orderRequestResult";
                }
                if (message.orderUpdateRequestResult != null && message.hasOwnProperty("orderUpdateRequestResult")) {
                    object.orderUpdateRequestResult = options.enums === String ? $root.fastcity.admin.OrderUpdateRequestResult[message.orderUpdateRequestResult] : message.orderUpdateRequestResult;
                    if (options.oneofs)
                        object.descriptionCode = "orderUpdateRequestResult";
                }
                if (message.subCompanyRequestResult != null && message.hasOwnProperty("subCompanyRequestResult")) {
                    object.subCompanyRequestResult = options.enums === String ? $root.fastcity.admin.SubCompanyRequestResult[message.subCompanyRequestResult] : message.subCompanyRequestResult;
                    if (options.oneofs)
                        object.descriptionCode = "subCompanyRequestResult";
                }
                if (message.subCompanyZoneRequestResult != null && message.hasOwnProperty("subCompanyZoneRequestResult")) {
                    object.subCompanyZoneRequestResult = options.enums === String ? $root.fastcity.admin.SubCompanyZoneRequestResult[message.subCompanyZoneRequestResult] : message.subCompanyZoneRequestResult;
                    if (options.oneofs)
                        object.descriptionCode = "subCompanyZoneRequestResult";
                }
                return object;
            };

            /**
             * Converts this Result to JSON.
             * @function toJSON
             * @memberof fastcity.admin.Result
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Result.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Code enum.
             * @name fastcity.admin.Result.Code
             * @enum {number}
             * @property {number} SUCCESS=0 SUCCESS value
             * @property {number} INVALID_REQUEST=1 INVALID_REQUEST value
             * @property {number} SERVER_ERROR=2 SERVER_ERROR value
             * @property {number} NO_ACCESS=3 NO_ACCESS value
             * @property {number} CANNOT_SEND_EMAIL=4 CANNOT_SEND_EMAIL value
             */
            Result.Code = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "SUCCESS"] = 0;
                values[valuesById[1] = "INVALID_REQUEST"] = 1;
                values[valuesById[2] = "SERVER_ERROR"] = 2;
                values[valuesById[3] = "NO_ACCESS"] = 3;
                values[valuesById[4] = "CANNOT_SEND_EMAIL"] = 4;
                return values;
            })();

            return Result;
        })();

        /**
         * SubCompanyRequestResult enum.
         * @name fastcity.admin.SubCompanyRequestResult
         * @enum {number}
         * @property {number} SBRR_NOT_FOUND=0 SBRR_NOT_FOUND value
         * @property {number} SBRR_ALREADY_EXISTS=1 SBRR_ALREADY_EXISTS value
         * @property {number} SBRR_BAD_COMPANY_ID_COMPANY_NOT_FOUND=2 SBRR_BAD_COMPANY_ID_COMPANY_NOT_FOUND value
         * @property {number} SBRR_BAD_COMPANY_ID_COMPANY_IS_DELETED=3 SBRR_BAD_COMPANY_ID_COMPANY_IS_DELETED value
         * @property {number} SBRR_BAD_EXTERNAL_SUB_COMPANY_ID=4 SBRR_BAD_EXTERNAL_SUB_COMPANY_ID value
         * @property {number} SBRR_BAD_UPDATE_ORDERS_INTERVAL=5 SBRR_BAD_UPDATE_ORDERS_INTERVAL value
         * @property {number} SBRR_BAD_AVERAGE_DELIVERY_TIME=6 SBRR_BAD_AVERAGE_DELIVERY_TIME value
         * @property {number} SBRR_BAD_ADDRESS=7 SBRR_BAD_ADDRESS value
         */
        admin.SubCompanyRequestResult = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "SBRR_NOT_FOUND"] = 0;
            values[valuesById[1] = "SBRR_ALREADY_EXISTS"] = 1;
            values[valuesById[2] = "SBRR_BAD_COMPANY_ID_COMPANY_NOT_FOUND"] = 2;
            values[valuesById[3] = "SBRR_BAD_COMPANY_ID_COMPANY_IS_DELETED"] = 3;
            values[valuesById[4] = "SBRR_BAD_EXTERNAL_SUB_COMPANY_ID"] = 4;
            values[valuesById[5] = "SBRR_BAD_UPDATE_ORDERS_INTERVAL"] = 5;
            values[valuesById[6] = "SBRR_BAD_AVERAGE_DELIVERY_TIME"] = 6;
            values[valuesById[7] = "SBRR_BAD_ADDRESS"] = 7;
            return values;
        })();

        admin.SubCompany = (function() {

            /**
             * Properties of a SubCompany.
             * @memberof fastcity.admin
             * @interface ISubCompany
             * @property {number|Long|null} [subCompanyId] SubCompany subCompanyId
             * @property {number|Long|null} [companyId] SubCompany companyId
             * @property {string|null} [externalSubCompanyId] SubCompany externalSubCompanyId
             * @property {Uint8Array|null} [address] SubCompany address
             * @property {boolean|null} [isDeleted] SubCompany isDeleted
             * @property {fastcity.common.ICoordinate|null} [coordinate] SubCompany coordinate
             * @property {number|Long|null} [updateOrdersInterval] SubCompany updateOrdersInterval
             * @property {number|Long|null} [averageDeliveryTime] SubCompany averageDeliveryTime
             * @property {boolean|null} [ordersLatitudeLongitudeSwap] SubCompany ordersLatitudeLongitudeSwap
             * @property {Uint8Array|null} [name] SubCompany name
             */

            /**
             * Constructs a new SubCompany.
             * @memberof fastcity.admin
             * @classdesc Represents a SubCompany.
             * @implements ISubCompany
             * @constructor
             * @param {fastcity.admin.ISubCompany=} [properties] Properties to set
             */
            function SubCompany(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * SubCompany subCompanyId.
             * @member {number|Long} subCompanyId
             * @memberof fastcity.admin.SubCompany
             * @instance
             */
            SubCompany.prototype.subCompanyId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * SubCompany companyId.
             * @member {number|Long} companyId
             * @memberof fastcity.admin.SubCompany
             * @instance
             */
            SubCompany.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * SubCompany externalSubCompanyId.
             * @member {string} externalSubCompanyId
             * @memberof fastcity.admin.SubCompany
             * @instance
             */
            SubCompany.prototype.externalSubCompanyId = "";

            /**
             * SubCompany address.
             * @member {Uint8Array} address
             * @memberof fastcity.admin.SubCompany
             * @instance
             */
            SubCompany.prototype.address = $util.newBuffer([]);

            /**
             * SubCompany isDeleted.
             * @member {boolean} isDeleted
             * @memberof fastcity.admin.SubCompany
             * @instance
             */
            SubCompany.prototype.isDeleted = false;

            /**
             * SubCompany coordinate.
             * @member {fastcity.common.ICoordinate|null|undefined} coordinate
             * @memberof fastcity.admin.SubCompany
             * @instance
             */
            SubCompany.prototype.coordinate = null;

            /**
             * SubCompany updateOrdersInterval.
             * @member {number|Long} updateOrdersInterval
             * @memberof fastcity.admin.SubCompany
             * @instance
             */
            SubCompany.prototype.updateOrdersInterval = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * SubCompany averageDeliveryTime.
             * @member {number|Long} averageDeliveryTime
             * @memberof fastcity.admin.SubCompany
             * @instance
             */
            SubCompany.prototype.averageDeliveryTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * SubCompany ordersLatitudeLongitudeSwap.
             * @member {boolean} ordersLatitudeLongitudeSwap
             * @memberof fastcity.admin.SubCompany
             * @instance
             */
            SubCompany.prototype.ordersLatitudeLongitudeSwap = false;

            /**
             * SubCompany name.
             * @member {Uint8Array} name
             * @memberof fastcity.admin.SubCompany
             * @instance
             */
            SubCompany.prototype.name = $util.newBuffer([]);

            /**
             * Creates a new SubCompany instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.SubCompany
             * @static
             * @param {fastcity.admin.ISubCompany=} [properties] Properties to set
             * @returns {fastcity.admin.SubCompany} SubCompany instance
             */
            SubCompany.create = function create(properties) {
                return new SubCompany(properties);
            };

            /**
             * Encodes the specified SubCompany message. Does not implicitly {@link fastcity.admin.SubCompany.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.SubCompany
             * @static
             * @param {fastcity.admin.ISubCompany} message SubCompany message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SubCompany.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.subCompanyId != null && Object.hasOwnProperty.call(message, "subCompanyId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.subCompanyId);
                if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.companyId);
                if (message.externalSubCompanyId != null && Object.hasOwnProperty.call(message, "externalSubCompanyId"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.externalSubCompanyId);
                if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                    writer.uint32(/* id 4, wireType 2 =*/34).bytes(message.address);
                if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                    writer.uint32(/* id 5, wireType 0 =*/40).bool(message.isDeleted);
                if (message.coordinate != null && Object.hasOwnProperty.call(message, "coordinate"))
                    $root.fastcity.common.Coordinate.encode(message.coordinate, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.updateOrdersInterval != null && Object.hasOwnProperty.call(message, "updateOrdersInterval"))
                    writer.uint32(/* id 7, wireType 0 =*/56).int64(message.updateOrdersInterval);
                if (message.averageDeliveryTime != null && Object.hasOwnProperty.call(message, "averageDeliveryTime"))
                    writer.uint32(/* id 8, wireType 0 =*/64).int64(message.averageDeliveryTime);
                if (message.ordersLatitudeLongitudeSwap != null && Object.hasOwnProperty.call(message, "ordersLatitudeLongitudeSwap"))
                    writer.uint32(/* id 9, wireType 0 =*/72).bool(message.ordersLatitudeLongitudeSwap);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 10, wireType 2 =*/82).bytes(message.name);
                return writer;
            };

            /**
             * Decodes a SubCompany message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.SubCompany
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.SubCompany} SubCompany
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SubCompany.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.SubCompany();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.subCompanyId = reader.uint64();
                        break;
                    case 2:
                        message.companyId = reader.uint64();
                        break;
                    case 3:
                        message.externalSubCompanyId = reader.string();
                        break;
                    case 4:
                        message.address = reader.bytes();
                        break;
                    case 5:
                        message.isDeleted = reader.bool();
                        break;
                    case 6:
                        message.coordinate = $root.fastcity.common.Coordinate.decode(reader, reader.uint32());
                        break;
                    case 7:
                        message.updateOrdersInterval = reader.int64();
                        break;
                    case 8:
                        message.averageDeliveryTime = reader.int64();
                        break;
                    case 9:
                        message.ordersLatitudeLongitudeSwap = reader.bool();
                        break;
                    case 10:
                        message.name = reader.bytes();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a SubCompany message.
             * @function verify
             * @memberof fastcity.admin.SubCompany
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SubCompany.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.subCompanyId != null && message.hasOwnProperty("subCompanyId"))
                    if (!$util.isInteger(message.subCompanyId) && !(message.subCompanyId && $util.isInteger(message.subCompanyId.low) && $util.isInteger(message.subCompanyId.high)))
                        return "subCompanyId: integer|Long expected";
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                        return "companyId: integer|Long expected";
                if (message.externalSubCompanyId != null && message.hasOwnProperty("externalSubCompanyId"))
                    if (!$util.isString(message.externalSubCompanyId))
                        return "externalSubCompanyId: string expected";
                if (message.address != null && message.hasOwnProperty("address"))
                    if (!(message.address && typeof message.address.length === "number" || $util.isString(message.address)))
                        return "address: buffer expected";
                if (message.isDeleted != null && message.hasOwnProperty("isDeleted"))
                    if (typeof message.isDeleted !== "boolean")
                        return "isDeleted: boolean expected";
                if (message.coordinate != null && message.hasOwnProperty("coordinate")) {
                    var error = $root.fastcity.common.Coordinate.verify(message.coordinate);
                    if (error)
                        return "coordinate." + error;
                }
                if (message.updateOrdersInterval != null && message.hasOwnProperty("updateOrdersInterval"))
                    if (!$util.isInteger(message.updateOrdersInterval) && !(message.updateOrdersInterval && $util.isInteger(message.updateOrdersInterval.low) && $util.isInteger(message.updateOrdersInterval.high)))
                        return "updateOrdersInterval: integer|Long expected";
                if (message.averageDeliveryTime != null && message.hasOwnProperty("averageDeliveryTime"))
                    if (!$util.isInteger(message.averageDeliveryTime) && !(message.averageDeliveryTime && $util.isInteger(message.averageDeliveryTime.low) && $util.isInteger(message.averageDeliveryTime.high)))
                        return "averageDeliveryTime: integer|Long expected";
                if (message.ordersLatitudeLongitudeSwap != null && message.hasOwnProperty("ordersLatitudeLongitudeSwap"))
                    if (typeof message.ordersLatitudeLongitudeSwap !== "boolean")
                        return "ordersLatitudeLongitudeSwap: boolean expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!(message.name && typeof message.name.length === "number" || $util.isString(message.name)))
                        return "name: buffer expected";
                return null;
            };

            /**
             * Creates a SubCompany message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.SubCompany
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.SubCompany} SubCompany
             */
            SubCompany.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.SubCompany)
                    return object;
                var message = new $root.fastcity.admin.SubCompany();
                if (object.subCompanyId != null)
                    if ($util.Long)
                        (message.subCompanyId = $util.Long.fromValue(object.subCompanyId)).unsigned = true;
                    else if (typeof object.subCompanyId === "string")
                        message.subCompanyId = parseInt(object.subCompanyId, 10);
                    else if (typeof object.subCompanyId === "number")
                        message.subCompanyId = object.subCompanyId;
                    else if (typeof object.subCompanyId === "object")
                        message.subCompanyId = new $util.LongBits(object.subCompanyId.low >>> 0, object.subCompanyId.high >>> 0).toNumber(true);
                if (object.companyId != null)
                    if ($util.Long)
                        (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = true;
                    else if (typeof object.companyId === "string")
                        message.companyId = parseInt(object.companyId, 10);
                    else if (typeof object.companyId === "number")
                        message.companyId = object.companyId;
                    else if (typeof object.companyId === "object")
                        message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber(true);
                if (object.externalSubCompanyId != null)
                    message.externalSubCompanyId = String(object.externalSubCompanyId);
                if (object.address != null)
                    if (typeof object.address === "string")
                        $util.base64.decode(object.address, message.address = $util.newBuffer($util.base64.length(object.address)), 0);
                    else if (object.address.length)
                        message.address = object.address;
                if (object.isDeleted != null)
                    message.isDeleted = Boolean(object.isDeleted);
                if (object.coordinate != null) {
                    if (typeof object.coordinate !== "object")
                        throw TypeError(".fastcity.admin.SubCompany.coordinate: object expected");
                    message.coordinate = $root.fastcity.common.Coordinate.fromObject(object.coordinate);
                }
                if (object.updateOrdersInterval != null)
                    if ($util.Long)
                        (message.updateOrdersInterval = $util.Long.fromValue(object.updateOrdersInterval)).unsigned = false;
                    else if (typeof object.updateOrdersInterval === "string")
                        message.updateOrdersInterval = parseInt(object.updateOrdersInterval, 10);
                    else if (typeof object.updateOrdersInterval === "number")
                        message.updateOrdersInterval = object.updateOrdersInterval;
                    else if (typeof object.updateOrdersInterval === "object")
                        message.updateOrdersInterval = new $util.LongBits(object.updateOrdersInterval.low >>> 0, object.updateOrdersInterval.high >>> 0).toNumber();
                if (object.averageDeliveryTime != null)
                    if ($util.Long)
                        (message.averageDeliveryTime = $util.Long.fromValue(object.averageDeliveryTime)).unsigned = false;
                    else if (typeof object.averageDeliveryTime === "string")
                        message.averageDeliveryTime = parseInt(object.averageDeliveryTime, 10);
                    else if (typeof object.averageDeliveryTime === "number")
                        message.averageDeliveryTime = object.averageDeliveryTime;
                    else if (typeof object.averageDeliveryTime === "object")
                        message.averageDeliveryTime = new $util.LongBits(object.averageDeliveryTime.low >>> 0, object.averageDeliveryTime.high >>> 0).toNumber();
                if (object.ordersLatitudeLongitudeSwap != null)
                    message.ordersLatitudeLongitudeSwap = Boolean(object.ordersLatitudeLongitudeSwap);
                if (object.name != null)
                    if (typeof object.name === "string")
                        $util.base64.decode(object.name, message.name = $util.newBuffer($util.base64.length(object.name)), 0);
                    else if (object.name.length)
                        message.name = object.name;
                return message;
            };

            /**
             * Creates a plain object from a SubCompany message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.SubCompany
             * @static
             * @param {fastcity.admin.SubCompany} message SubCompany
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SubCompany.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.subCompanyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.subCompanyId = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.companyId = options.longs === String ? "0" : 0;
                    object.externalSubCompanyId = "";
                    if (options.bytes === String)
                        object.address = "";
                    else {
                        object.address = [];
                        if (options.bytes !== Array)
                            object.address = $util.newBuffer(object.address);
                    }
                    object.isDeleted = false;
                    object.coordinate = null;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.updateOrdersInterval = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.updateOrdersInterval = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.averageDeliveryTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.averageDeliveryTime = options.longs === String ? "0" : 0;
                    object.ordersLatitudeLongitudeSwap = false;
                    if (options.bytes === String)
                        object.name = "";
                    else {
                        object.name = [];
                        if (options.bytes !== Array)
                            object.name = $util.newBuffer(object.name);
                    }
                }
                if (message.subCompanyId != null && message.hasOwnProperty("subCompanyId"))
                    if (typeof message.subCompanyId === "number")
                        object.subCompanyId = options.longs === String ? String(message.subCompanyId) : message.subCompanyId;
                    else
                        object.subCompanyId = options.longs === String ? $util.Long.prototype.toString.call(message.subCompanyId) : options.longs === Number ? new $util.LongBits(message.subCompanyId.low >>> 0, message.subCompanyId.high >>> 0).toNumber(true) : message.subCompanyId;
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (typeof message.companyId === "number")
                        object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                    else
                        object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber(true) : message.companyId;
                if (message.externalSubCompanyId != null && message.hasOwnProperty("externalSubCompanyId"))
                    object.externalSubCompanyId = message.externalSubCompanyId;
                if (message.address != null && message.hasOwnProperty("address"))
                    object.address = options.bytes === String ? $util.base64.encode(message.address, 0, message.address.length) : options.bytes === Array ? Array.prototype.slice.call(message.address) : message.address;
                if (message.isDeleted != null && message.hasOwnProperty("isDeleted"))
                    object.isDeleted = message.isDeleted;
                if (message.coordinate != null && message.hasOwnProperty("coordinate"))
                    object.coordinate = $root.fastcity.common.Coordinate.toObject(message.coordinate, options);
                if (message.updateOrdersInterval != null && message.hasOwnProperty("updateOrdersInterval"))
                    if (typeof message.updateOrdersInterval === "number")
                        object.updateOrdersInterval = options.longs === String ? String(message.updateOrdersInterval) : message.updateOrdersInterval;
                    else
                        object.updateOrdersInterval = options.longs === String ? $util.Long.prototype.toString.call(message.updateOrdersInterval) : options.longs === Number ? new $util.LongBits(message.updateOrdersInterval.low >>> 0, message.updateOrdersInterval.high >>> 0).toNumber() : message.updateOrdersInterval;
                if (message.averageDeliveryTime != null && message.hasOwnProperty("averageDeliveryTime"))
                    if (typeof message.averageDeliveryTime === "number")
                        object.averageDeliveryTime = options.longs === String ? String(message.averageDeliveryTime) : message.averageDeliveryTime;
                    else
                        object.averageDeliveryTime = options.longs === String ? $util.Long.prototype.toString.call(message.averageDeliveryTime) : options.longs === Number ? new $util.LongBits(message.averageDeliveryTime.low >>> 0, message.averageDeliveryTime.high >>> 0).toNumber() : message.averageDeliveryTime;
                if (message.ordersLatitudeLongitudeSwap != null && message.hasOwnProperty("ordersLatitudeLongitudeSwap"))
                    object.ordersLatitudeLongitudeSwap = message.ordersLatitudeLongitudeSwap;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = options.bytes === String ? $util.base64.encode(message.name, 0, message.name.length) : options.bytes === Array ? Array.prototype.slice.call(message.name) : message.name;
                return object;
            };

            /**
             * Converts this SubCompany to JSON.
             * @function toJSON
             * @memberof fastcity.admin.SubCompany
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SubCompany.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return SubCompany;
        })();

        admin.CreateSubCompany = (function() {

            /**
             * Properties of a CreateSubCompany.
             * @memberof fastcity.admin
             * @interface ICreateSubCompany
             * @property {number|Long|null} [companyId] CreateSubCompany companyId
             * @property {string|null} [externalSubCompanyId] CreateSubCompany externalSubCompanyId
             * @property {Uint8Array|null} [address] CreateSubCompany address
             * @property {fastcity.common.ICoordinate|null} [coordinate] CreateSubCompany coordinate
             * @property {number|Long|null} [updateOrdersInterval] CreateSubCompany updateOrdersInterval
             * @property {number|Long|null} [averageDeliveryTime] CreateSubCompany averageDeliveryTime
             * @property {boolean|null} [ordersLatitudeLongitudeSwap] CreateSubCompany ordersLatitudeLongitudeSwap
             * @property {Uint8Array|null} [name] CreateSubCompany name
             */

            /**
             * Constructs a new CreateSubCompany.
             * @memberof fastcity.admin
             * @classdesc Represents a CreateSubCompany.
             * @implements ICreateSubCompany
             * @constructor
             * @param {fastcity.admin.ICreateSubCompany=} [properties] Properties to set
             */
            function CreateSubCompany(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CreateSubCompany companyId.
             * @member {number|Long} companyId
             * @memberof fastcity.admin.CreateSubCompany
             * @instance
             */
            CreateSubCompany.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * CreateSubCompany externalSubCompanyId.
             * @member {string} externalSubCompanyId
             * @memberof fastcity.admin.CreateSubCompany
             * @instance
             */
            CreateSubCompany.prototype.externalSubCompanyId = "";

            /**
             * CreateSubCompany address.
             * @member {Uint8Array} address
             * @memberof fastcity.admin.CreateSubCompany
             * @instance
             */
            CreateSubCompany.prototype.address = $util.newBuffer([]);

            /**
             * CreateSubCompany coordinate.
             * @member {fastcity.common.ICoordinate|null|undefined} coordinate
             * @memberof fastcity.admin.CreateSubCompany
             * @instance
             */
            CreateSubCompany.prototype.coordinate = null;

            /**
             * CreateSubCompany updateOrdersInterval.
             * @member {number|Long} updateOrdersInterval
             * @memberof fastcity.admin.CreateSubCompany
             * @instance
             */
            CreateSubCompany.prototype.updateOrdersInterval = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * CreateSubCompany averageDeliveryTime.
             * @member {number|Long} averageDeliveryTime
             * @memberof fastcity.admin.CreateSubCompany
             * @instance
             */
            CreateSubCompany.prototype.averageDeliveryTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * CreateSubCompany ordersLatitudeLongitudeSwap.
             * @member {boolean} ordersLatitudeLongitudeSwap
             * @memberof fastcity.admin.CreateSubCompany
             * @instance
             */
            CreateSubCompany.prototype.ordersLatitudeLongitudeSwap = false;

            /**
             * CreateSubCompany name.
             * @member {Uint8Array} name
             * @memberof fastcity.admin.CreateSubCompany
             * @instance
             */
            CreateSubCompany.prototype.name = $util.newBuffer([]);

            /**
             * Creates a new CreateSubCompany instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.CreateSubCompany
             * @static
             * @param {fastcity.admin.ICreateSubCompany=} [properties] Properties to set
             * @returns {fastcity.admin.CreateSubCompany} CreateSubCompany instance
             */
            CreateSubCompany.create = function create(properties) {
                return new CreateSubCompany(properties);
            };

            /**
             * Encodes the specified CreateSubCompany message. Does not implicitly {@link fastcity.admin.CreateSubCompany.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.CreateSubCompany
             * @static
             * @param {fastcity.admin.ICreateSubCompany} message CreateSubCompany message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateSubCompany.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.companyId);
                if (message.externalSubCompanyId != null && Object.hasOwnProperty.call(message, "externalSubCompanyId"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.externalSubCompanyId);
                if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                    writer.uint32(/* id 3, wireType 2 =*/26).bytes(message.address);
                if (message.coordinate != null && Object.hasOwnProperty.call(message, "coordinate"))
                    $root.fastcity.common.Coordinate.encode(message.coordinate, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.updateOrdersInterval != null && Object.hasOwnProperty.call(message, "updateOrdersInterval"))
                    writer.uint32(/* id 5, wireType 0 =*/40).int64(message.updateOrdersInterval);
                if (message.averageDeliveryTime != null && Object.hasOwnProperty.call(message, "averageDeliveryTime"))
                    writer.uint32(/* id 6, wireType 0 =*/48).int64(message.averageDeliveryTime);
                if (message.ordersLatitudeLongitudeSwap != null && Object.hasOwnProperty.call(message, "ordersLatitudeLongitudeSwap"))
                    writer.uint32(/* id 7, wireType 0 =*/56).bool(message.ordersLatitudeLongitudeSwap);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 8, wireType 2 =*/66).bytes(message.name);
                return writer;
            };

            /**
             * Decodes a CreateSubCompany message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.CreateSubCompany
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.CreateSubCompany} CreateSubCompany
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateSubCompany.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.CreateSubCompany();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.companyId = reader.uint64();
                        break;
                    case 2:
                        message.externalSubCompanyId = reader.string();
                        break;
                    case 3:
                        message.address = reader.bytes();
                        break;
                    case 4:
                        message.coordinate = $root.fastcity.common.Coordinate.decode(reader, reader.uint32());
                        break;
                    case 5:
                        message.updateOrdersInterval = reader.int64();
                        break;
                    case 6:
                        message.averageDeliveryTime = reader.int64();
                        break;
                    case 7:
                        message.ordersLatitudeLongitudeSwap = reader.bool();
                        break;
                    case 8:
                        message.name = reader.bytes();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a CreateSubCompany message.
             * @function verify
             * @memberof fastcity.admin.CreateSubCompany
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CreateSubCompany.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                        return "companyId: integer|Long expected";
                if (message.externalSubCompanyId != null && message.hasOwnProperty("externalSubCompanyId"))
                    if (!$util.isString(message.externalSubCompanyId))
                        return "externalSubCompanyId: string expected";
                if (message.address != null && message.hasOwnProperty("address"))
                    if (!(message.address && typeof message.address.length === "number" || $util.isString(message.address)))
                        return "address: buffer expected";
                if (message.coordinate != null && message.hasOwnProperty("coordinate")) {
                    var error = $root.fastcity.common.Coordinate.verify(message.coordinate);
                    if (error)
                        return "coordinate." + error;
                }
                if (message.updateOrdersInterval != null && message.hasOwnProperty("updateOrdersInterval"))
                    if (!$util.isInteger(message.updateOrdersInterval) && !(message.updateOrdersInterval && $util.isInteger(message.updateOrdersInterval.low) && $util.isInteger(message.updateOrdersInterval.high)))
                        return "updateOrdersInterval: integer|Long expected";
                if (message.averageDeliveryTime != null && message.hasOwnProperty("averageDeliveryTime"))
                    if (!$util.isInteger(message.averageDeliveryTime) && !(message.averageDeliveryTime && $util.isInteger(message.averageDeliveryTime.low) && $util.isInteger(message.averageDeliveryTime.high)))
                        return "averageDeliveryTime: integer|Long expected";
                if (message.ordersLatitudeLongitudeSwap != null && message.hasOwnProperty("ordersLatitudeLongitudeSwap"))
                    if (typeof message.ordersLatitudeLongitudeSwap !== "boolean")
                        return "ordersLatitudeLongitudeSwap: boolean expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!(message.name && typeof message.name.length === "number" || $util.isString(message.name)))
                        return "name: buffer expected";
                return null;
            };

            /**
             * Creates a CreateSubCompany message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.CreateSubCompany
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.CreateSubCompany} CreateSubCompany
             */
            CreateSubCompany.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.CreateSubCompany)
                    return object;
                var message = new $root.fastcity.admin.CreateSubCompany();
                if (object.companyId != null)
                    if ($util.Long)
                        (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = true;
                    else if (typeof object.companyId === "string")
                        message.companyId = parseInt(object.companyId, 10);
                    else if (typeof object.companyId === "number")
                        message.companyId = object.companyId;
                    else if (typeof object.companyId === "object")
                        message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber(true);
                if (object.externalSubCompanyId != null)
                    message.externalSubCompanyId = String(object.externalSubCompanyId);
                if (object.address != null)
                    if (typeof object.address === "string")
                        $util.base64.decode(object.address, message.address = $util.newBuffer($util.base64.length(object.address)), 0);
                    else if (object.address.length)
                        message.address = object.address;
                if (object.coordinate != null) {
                    if (typeof object.coordinate !== "object")
                        throw TypeError(".fastcity.admin.CreateSubCompany.coordinate: object expected");
                    message.coordinate = $root.fastcity.common.Coordinate.fromObject(object.coordinate);
                }
                if (object.updateOrdersInterval != null)
                    if ($util.Long)
                        (message.updateOrdersInterval = $util.Long.fromValue(object.updateOrdersInterval)).unsigned = false;
                    else if (typeof object.updateOrdersInterval === "string")
                        message.updateOrdersInterval = parseInt(object.updateOrdersInterval, 10);
                    else if (typeof object.updateOrdersInterval === "number")
                        message.updateOrdersInterval = object.updateOrdersInterval;
                    else if (typeof object.updateOrdersInterval === "object")
                        message.updateOrdersInterval = new $util.LongBits(object.updateOrdersInterval.low >>> 0, object.updateOrdersInterval.high >>> 0).toNumber();
                if (object.averageDeliveryTime != null)
                    if ($util.Long)
                        (message.averageDeliveryTime = $util.Long.fromValue(object.averageDeliveryTime)).unsigned = false;
                    else if (typeof object.averageDeliveryTime === "string")
                        message.averageDeliveryTime = parseInt(object.averageDeliveryTime, 10);
                    else if (typeof object.averageDeliveryTime === "number")
                        message.averageDeliveryTime = object.averageDeliveryTime;
                    else if (typeof object.averageDeliveryTime === "object")
                        message.averageDeliveryTime = new $util.LongBits(object.averageDeliveryTime.low >>> 0, object.averageDeliveryTime.high >>> 0).toNumber();
                if (object.ordersLatitudeLongitudeSwap != null)
                    message.ordersLatitudeLongitudeSwap = Boolean(object.ordersLatitudeLongitudeSwap);
                if (object.name != null)
                    if (typeof object.name === "string")
                        $util.base64.decode(object.name, message.name = $util.newBuffer($util.base64.length(object.name)), 0);
                    else if (object.name.length)
                        message.name = object.name;
                return message;
            };

            /**
             * Creates a plain object from a CreateSubCompany message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.CreateSubCompany
             * @static
             * @param {fastcity.admin.CreateSubCompany} message CreateSubCompany
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateSubCompany.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.companyId = options.longs === String ? "0" : 0;
                    object.externalSubCompanyId = "";
                    if (options.bytes === String)
                        object.address = "";
                    else {
                        object.address = [];
                        if (options.bytes !== Array)
                            object.address = $util.newBuffer(object.address);
                    }
                    object.coordinate = null;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.updateOrdersInterval = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.updateOrdersInterval = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.averageDeliveryTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.averageDeliveryTime = options.longs === String ? "0" : 0;
                    object.ordersLatitudeLongitudeSwap = false;
                    if (options.bytes === String)
                        object.name = "";
                    else {
                        object.name = [];
                        if (options.bytes !== Array)
                            object.name = $util.newBuffer(object.name);
                    }
                }
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (typeof message.companyId === "number")
                        object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                    else
                        object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber(true) : message.companyId;
                if (message.externalSubCompanyId != null && message.hasOwnProperty("externalSubCompanyId"))
                    object.externalSubCompanyId = message.externalSubCompanyId;
                if (message.address != null && message.hasOwnProperty("address"))
                    object.address = options.bytes === String ? $util.base64.encode(message.address, 0, message.address.length) : options.bytes === Array ? Array.prototype.slice.call(message.address) : message.address;
                if (message.coordinate != null && message.hasOwnProperty("coordinate"))
                    object.coordinate = $root.fastcity.common.Coordinate.toObject(message.coordinate, options);
                if (message.updateOrdersInterval != null && message.hasOwnProperty("updateOrdersInterval"))
                    if (typeof message.updateOrdersInterval === "number")
                        object.updateOrdersInterval = options.longs === String ? String(message.updateOrdersInterval) : message.updateOrdersInterval;
                    else
                        object.updateOrdersInterval = options.longs === String ? $util.Long.prototype.toString.call(message.updateOrdersInterval) : options.longs === Number ? new $util.LongBits(message.updateOrdersInterval.low >>> 0, message.updateOrdersInterval.high >>> 0).toNumber() : message.updateOrdersInterval;
                if (message.averageDeliveryTime != null && message.hasOwnProperty("averageDeliveryTime"))
                    if (typeof message.averageDeliveryTime === "number")
                        object.averageDeliveryTime = options.longs === String ? String(message.averageDeliveryTime) : message.averageDeliveryTime;
                    else
                        object.averageDeliveryTime = options.longs === String ? $util.Long.prototype.toString.call(message.averageDeliveryTime) : options.longs === Number ? new $util.LongBits(message.averageDeliveryTime.low >>> 0, message.averageDeliveryTime.high >>> 0).toNumber() : message.averageDeliveryTime;
                if (message.ordersLatitudeLongitudeSwap != null && message.hasOwnProperty("ordersLatitudeLongitudeSwap"))
                    object.ordersLatitudeLongitudeSwap = message.ordersLatitudeLongitudeSwap;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = options.bytes === String ? $util.base64.encode(message.name, 0, message.name.length) : options.bytes === Array ? Array.prototype.slice.call(message.name) : message.name;
                return object;
            };

            /**
             * Converts this CreateSubCompany to JSON.
             * @function toJSON
             * @memberof fastcity.admin.CreateSubCompany
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateSubCompany.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return CreateSubCompany;
        })();

        admin.UpdateSubCompany = (function() {

            /**
             * Properties of an UpdateSubCompany.
             * @memberof fastcity.admin
             * @interface IUpdateSubCompany
             * @property {number|Long|null} [subCompanyId] UpdateSubCompany subCompanyId
             * @property {number|Long|null} [companyId] UpdateSubCompany companyId
             * @property {string|null} [externalSubCompanyId] UpdateSubCompany externalSubCompanyId
             * @property {Uint8Array|null} [address] UpdateSubCompany address
             * @property {fastcity.common.ICoordinate|null} [coordinate] UpdateSubCompany coordinate
             * @property {number|Long|null} [updateOrdersInterval] UpdateSubCompany updateOrdersInterval
             * @property {number|Long|null} [averageDeliveryTime] UpdateSubCompany averageDeliveryTime
             * @property {boolean|null} [ordersLatitudeLongitudeSwap] UpdateSubCompany ordersLatitudeLongitudeSwap
             * @property {number|Long|null} [minimalDistanceToOrderTarget] UpdateSubCompany minimalDistanceToOrderTarget
             * @property {Uint8Array|null} [name] UpdateSubCompany name
             */

            /**
             * Constructs a new UpdateSubCompany.
             * @memberof fastcity.admin
             * @classdesc Represents an UpdateSubCompany.
             * @implements IUpdateSubCompany
             * @constructor
             * @param {fastcity.admin.IUpdateSubCompany=} [properties] Properties to set
             */
            function UpdateSubCompany(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * UpdateSubCompany subCompanyId.
             * @member {number|Long} subCompanyId
             * @memberof fastcity.admin.UpdateSubCompany
             * @instance
             */
            UpdateSubCompany.prototype.subCompanyId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * UpdateSubCompany companyId.
             * @member {number|Long} companyId
             * @memberof fastcity.admin.UpdateSubCompany
             * @instance
             */
            UpdateSubCompany.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * UpdateSubCompany externalSubCompanyId.
             * @member {string} externalSubCompanyId
             * @memberof fastcity.admin.UpdateSubCompany
             * @instance
             */
            UpdateSubCompany.prototype.externalSubCompanyId = "";

            /**
             * UpdateSubCompany address.
             * @member {Uint8Array} address
             * @memberof fastcity.admin.UpdateSubCompany
             * @instance
             */
            UpdateSubCompany.prototype.address = $util.newBuffer([]);

            /**
             * UpdateSubCompany coordinate.
             * @member {fastcity.common.ICoordinate|null|undefined} coordinate
             * @memberof fastcity.admin.UpdateSubCompany
             * @instance
             */
            UpdateSubCompany.prototype.coordinate = null;

            /**
             * UpdateSubCompany updateOrdersInterval.
             * @member {number|Long} updateOrdersInterval
             * @memberof fastcity.admin.UpdateSubCompany
             * @instance
             */
            UpdateSubCompany.prototype.updateOrdersInterval = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * UpdateSubCompany averageDeliveryTime.
             * @member {number|Long} averageDeliveryTime
             * @memberof fastcity.admin.UpdateSubCompany
             * @instance
             */
            UpdateSubCompany.prototype.averageDeliveryTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * UpdateSubCompany ordersLatitudeLongitudeSwap.
             * @member {boolean} ordersLatitudeLongitudeSwap
             * @memberof fastcity.admin.UpdateSubCompany
             * @instance
             */
            UpdateSubCompany.prototype.ordersLatitudeLongitudeSwap = false;

            /**
             * UpdateSubCompany minimalDistanceToOrderTarget.
             * @member {number|Long} minimalDistanceToOrderTarget
             * @memberof fastcity.admin.UpdateSubCompany
             * @instance
             */
            UpdateSubCompany.prototype.minimalDistanceToOrderTarget = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * UpdateSubCompany name.
             * @member {Uint8Array} name
             * @memberof fastcity.admin.UpdateSubCompany
             * @instance
             */
            UpdateSubCompany.prototype.name = $util.newBuffer([]);

            /**
             * Creates a new UpdateSubCompany instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.UpdateSubCompany
             * @static
             * @param {fastcity.admin.IUpdateSubCompany=} [properties] Properties to set
             * @returns {fastcity.admin.UpdateSubCompany} UpdateSubCompany instance
             */
            UpdateSubCompany.create = function create(properties) {
                return new UpdateSubCompany(properties);
            };

            /**
             * Encodes the specified UpdateSubCompany message. Does not implicitly {@link fastcity.admin.UpdateSubCompany.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.UpdateSubCompany
             * @static
             * @param {fastcity.admin.IUpdateSubCompany} message UpdateSubCompany message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateSubCompany.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.subCompanyId != null && Object.hasOwnProperty.call(message, "subCompanyId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.subCompanyId);
                if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.companyId);
                if (message.externalSubCompanyId != null && Object.hasOwnProperty.call(message, "externalSubCompanyId"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.externalSubCompanyId);
                if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                    writer.uint32(/* id 4, wireType 2 =*/34).bytes(message.address);
                if (message.coordinate != null && Object.hasOwnProperty.call(message, "coordinate"))
                    $root.fastcity.common.Coordinate.encode(message.coordinate, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.updateOrdersInterval != null && Object.hasOwnProperty.call(message, "updateOrdersInterval"))
                    writer.uint32(/* id 6, wireType 0 =*/48).int64(message.updateOrdersInterval);
                if (message.averageDeliveryTime != null && Object.hasOwnProperty.call(message, "averageDeliveryTime"))
                    writer.uint32(/* id 7, wireType 0 =*/56).int64(message.averageDeliveryTime);
                if (message.ordersLatitudeLongitudeSwap != null && Object.hasOwnProperty.call(message, "ordersLatitudeLongitudeSwap"))
                    writer.uint32(/* id 8, wireType 0 =*/64).bool(message.ordersLatitudeLongitudeSwap);
                if (message.minimalDistanceToOrderTarget != null && Object.hasOwnProperty.call(message, "minimalDistanceToOrderTarget"))
                    writer.uint32(/* id 9, wireType 0 =*/72).int64(message.minimalDistanceToOrderTarget);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 10, wireType 2 =*/82).bytes(message.name);
                return writer;
            };

            /**
             * Decodes an UpdateSubCompany message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.UpdateSubCompany
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.UpdateSubCompany} UpdateSubCompany
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateSubCompany.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.UpdateSubCompany();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.subCompanyId = reader.uint64();
                        break;
                    case 2:
                        message.companyId = reader.uint64();
                        break;
                    case 3:
                        message.externalSubCompanyId = reader.string();
                        break;
                    case 4:
                        message.address = reader.bytes();
                        break;
                    case 5:
                        message.coordinate = $root.fastcity.common.Coordinate.decode(reader, reader.uint32());
                        break;
                    case 6:
                        message.updateOrdersInterval = reader.int64();
                        break;
                    case 7:
                        message.averageDeliveryTime = reader.int64();
                        break;
                    case 8:
                        message.ordersLatitudeLongitudeSwap = reader.bool();
                        break;
                    case 9:
                        message.minimalDistanceToOrderTarget = reader.int64();
                        break;
                    case 10:
                        message.name = reader.bytes();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an UpdateSubCompany message.
             * @function verify
             * @memberof fastcity.admin.UpdateSubCompany
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            UpdateSubCompany.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.subCompanyId != null && message.hasOwnProperty("subCompanyId"))
                    if (!$util.isInteger(message.subCompanyId) && !(message.subCompanyId && $util.isInteger(message.subCompanyId.low) && $util.isInteger(message.subCompanyId.high)))
                        return "subCompanyId: integer|Long expected";
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                        return "companyId: integer|Long expected";
                if (message.externalSubCompanyId != null && message.hasOwnProperty("externalSubCompanyId"))
                    if (!$util.isString(message.externalSubCompanyId))
                        return "externalSubCompanyId: string expected";
                if (message.address != null && message.hasOwnProperty("address"))
                    if (!(message.address && typeof message.address.length === "number" || $util.isString(message.address)))
                        return "address: buffer expected";
                if (message.coordinate != null && message.hasOwnProperty("coordinate")) {
                    var error = $root.fastcity.common.Coordinate.verify(message.coordinate);
                    if (error)
                        return "coordinate." + error;
                }
                if (message.updateOrdersInterval != null && message.hasOwnProperty("updateOrdersInterval"))
                    if (!$util.isInteger(message.updateOrdersInterval) && !(message.updateOrdersInterval && $util.isInteger(message.updateOrdersInterval.low) && $util.isInteger(message.updateOrdersInterval.high)))
                        return "updateOrdersInterval: integer|Long expected";
                if (message.averageDeliveryTime != null && message.hasOwnProperty("averageDeliveryTime"))
                    if (!$util.isInteger(message.averageDeliveryTime) && !(message.averageDeliveryTime && $util.isInteger(message.averageDeliveryTime.low) && $util.isInteger(message.averageDeliveryTime.high)))
                        return "averageDeliveryTime: integer|Long expected";
                if (message.ordersLatitudeLongitudeSwap != null && message.hasOwnProperty("ordersLatitudeLongitudeSwap"))
                    if (typeof message.ordersLatitudeLongitudeSwap !== "boolean")
                        return "ordersLatitudeLongitudeSwap: boolean expected";
                if (message.minimalDistanceToOrderTarget != null && message.hasOwnProperty("minimalDistanceToOrderTarget"))
                    if (!$util.isInteger(message.minimalDistanceToOrderTarget) && !(message.minimalDistanceToOrderTarget && $util.isInteger(message.minimalDistanceToOrderTarget.low) && $util.isInteger(message.minimalDistanceToOrderTarget.high)))
                        return "minimalDistanceToOrderTarget: integer|Long expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!(message.name && typeof message.name.length === "number" || $util.isString(message.name)))
                        return "name: buffer expected";
                return null;
            };

            /**
             * Creates an UpdateSubCompany message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.UpdateSubCompany
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.UpdateSubCompany} UpdateSubCompany
             */
            UpdateSubCompany.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.UpdateSubCompany)
                    return object;
                var message = new $root.fastcity.admin.UpdateSubCompany();
                if (object.subCompanyId != null)
                    if ($util.Long)
                        (message.subCompanyId = $util.Long.fromValue(object.subCompanyId)).unsigned = true;
                    else if (typeof object.subCompanyId === "string")
                        message.subCompanyId = parseInt(object.subCompanyId, 10);
                    else if (typeof object.subCompanyId === "number")
                        message.subCompanyId = object.subCompanyId;
                    else if (typeof object.subCompanyId === "object")
                        message.subCompanyId = new $util.LongBits(object.subCompanyId.low >>> 0, object.subCompanyId.high >>> 0).toNumber(true);
                if (object.companyId != null)
                    if ($util.Long)
                        (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = true;
                    else if (typeof object.companyId === "string")
                        message.companyId = parseInt(object.companyId, 10);
                    else if (typeof object.companyId === "number")
                        message.companyId = object.companyId;
                    else if (typeof object.companyId === "object")
                        message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber(true);
                if (object.externalSubCompanyId != null)
                    message.externalSubCompanyId = String(object.externalSubCompanyId);
                if (object.address != null)
                    if (typeof object.address === "string")
                        $util.base64.decode(object.address, message.address = $util.newBuffer($util.base64.length(object.address)), 0);
                    else if (object.address.length)
                        message.address = object.address;
                if (object.coordinate != null) {
                    if (typeof object.coordinate !== "object")
                        throw TypeError(".fastcity.admin.UpdateSubCompany.coordinate: object expected");
                    message.coordinate = $root.fastcity.common.Coordinate.fromObject(object.coordinate);
                }
                if (object.updateOrdersInterval != null)
                    if ($util.Long)
                        (message.updateOrdersInterval = $util.Long.fromValue(object.updateOrdersInterval)).unsigned = false;
                    else if (typeof object.updateOrdersInterval === "string")
                        message.updateOrdersInterval = parseInt(object.updateOrdersInterval, 10);
                    else if (typeof object.updateOrdersInterval === "number")
                        message.updateOrdersInterval = object.updateOrdersInterval;
                    else if (typeof object.updateOrdersInterval === "object")
                        message.updateOrdersInterval = new $util.LongBits(object.updateOrdersInterval.low >>> 0, object.updateOrdersInterval.high >>> 0).toNumber();
                if (object.averageDeliveryTime != null)
                    if ($util.Long)
                        (message.averageDeliveryTime = $util.Long.fromValue(object.averageDeliveryTime)).unsigned = false;
                    else if (typeof object.averageDeliveryTime === "string")
                        message.averageDeliveryTime = parseInt(object.averageDeliveryTime, 10);
                    else if (typeof object.averageDeliveryTime === "number")
                        message.averageDeliveryTime = object.averageDeliveryTime;
                    else if (typeof object.averageDeliveryTime === "object")
                        message.averageDeliveryTime = new $util.LongBits(object.averageDeliveryTime.low >>> 0, object.averageDeliveryTime.high >>> 0).toNumber();
                if (object.ordersLatitudeLongitudeSwap != null)
                    message.ordersLatitudeLongitudeSwap = Boolean(object.ordersLatitudeLongitudeSwap);
                if (object.minimalDistanceToOrderTarget != null)
                    if ($util.Long)
                        (message.minimalDistanceToOrderTarget = $util.Long.fromValue(object.minimalDistanceToOrderTarget)).unsigned = false;
                    else if (typeof object.minimalDistanceToOrderTarget === "string")
                        message.minimalDistanceToOrderTarget = parseInt(object.minimalDistanceToOrderTarget, 10);
                    else if (typeof object.minimalDistanceToOrderTarget === "number")
                        message.minimalDistanceToOrderTarget = object.minimalDistanceToOrderTarget;
                    else if (typeof object.minimalDistanceToOrderTarget === "object")
                        message.minimalDistanceToOrderTarget = new $util.LongBits(object.minimalDistanceToOrderTarget.low >>> 0, object.minimalDistanceToOrderTarget.high >>> 0).toNumber();
                if (object.name != null)
                    if (typeof object.name === "string")
                        $util.base64.decode(object.name, message.name = $util.newBuffer($util.base64.length(object.name)), 0);
                    else if (object.name.length)
                        message.name = object.name;
                return message;
            };

            /**
             * Creates a plain object from an UpdateSubCompany message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.UpdateSubCompany
             * @static
             * @param {fastcity.admin.UpdateSubCompany} message UpdateSubCompany
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UpdateSubCompany.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.subCompanyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.subCompanyId = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.companyId = options.longs === String ? "0" : 0;
                    object.externalSubCompanyId = "";
                    if (options.bytes === String)
                        object.address = "";
                    else {
                        object.address = [];
                        if (options.bytes !== Array)
                            object.address = $util.newBuffer(object.address);
                    }
                    object.coordinate = null;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.updateOrdersInterval = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.updateOrdersInterval = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.averageDeliveryTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.averageDeliveryTime = options.longs === String ? "0" : 0;
                    object.ordersLatitudeLongitudeSwap = false;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.minimalDistanceToOrderTarget = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.minimalDistanceToOrderTarget = options.longs === String ? "0" : 0;
                    if (options.bytes === String)
                        object.name = "";
                    else {
                        object.name = [];
                        if (options.bytes !== Array)
                            object.name = $util.newBuffer(object.name);
                    }
                }
                if (message.subCompanyId != null && message.hasOwnProperty("subCompanyId"))
                    if (typeof message.subCompanyId === "number")
                        object.subCompanyId = options.longs === String ? String(message.subCompanyId) : message.subCompanyId;
                    else
                        object.subCompanyId = options.longs === String ? $util.Long.prototype.toString.call(message.subCompanyId) : options.longs === Number ? new $util.LongBits(message.subCompanyId.low >>> 0, message.subCompanyId.high >>> 0).toNumber(true) : message.subCompanyId;
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (typeof message.companyId === "number")
                        object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                    else
                        object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber(true) : message.companyId;
                if (message.externalSubCompanyId != null && message.hasOwnProperty("externalSubCompanyId"))
                    object.externalSubCompanyId = message.externalSubCompanyId;
                if (message.address != null && message.hasOwnProperty("address"))
                    object.address = options.bytes === String ? $util.base64.encode(message.address, 0, message.address.length) : options.bytes === Array ? Array.prototype.slice.call(message.address) : message.address;
                if (message.coordinate != null && message.hasOwnProperty("coordinate"))
                    object.coordinate = $root.fastcity.common.Coordinate.toObject(message.coordinate, options);
                if (message.updateOrdersInterval != null && message.hasOwnProperty("updateOrdersInterval"))
                    if (typeof message.updateOrdersInterval === "number")
                        object.updateOrdersInterval = options.longs === String ? String(message.updateOrdersInterval) : message.updateOrdersInterval;
                    else
                        object.updateOrdersInterval = options.longs === String ? $util.Long.prototype.toString.call(message.updateOrdersInterval) : options.longs === Number ? new $util.LongBits(message.updateOrdersInterval.low >>> 0, message.updateOrdersInterval.high >>> 0).toNumber() : message.updateOrdersInterval;
                if (message.averageDeliveryTime != null && message.hasOwnProperty("averageDeliveryTime"))
                    if (typeof message.averageDeliveryTime === "number")
                        object.averageDeliveryTime = options.longs === String ? String(message.averageDeliveryTime) : message.averageDeliveryTime;
                    else
                        object.averageDeliveryTime = options.longs === String ? $util.Long.prototype.toString.call(message.averageDeliveryTime) : options.longs === Number ? new $util.LongBits(message.averageDeliveryTime.low >>> 0, message.averageDeliveryTime.high >>> 0).toNumber() : message.averageDeliveryTime;
                if (message.ordersLatitudeLongitudeSwap != null && message.hasOwnProperty("ordersLatitudeLongitudeSwap"))
                    object.ordersLatitudeLongitudeSwap = message.ordersLatitudeLongitudeSwap;
                if (message.minimalDistanceToOrderTarget != null && message.hasOwnProperty("minimalDistanceToOrderTarget"))
                    if (typeof message.minimalDistanceToOrderTarget === "number")
                        object.minimalDistanceToOrderTarget = options.longs === String ? String(message.minimalDistanceToOrderTarget) : message.minimalDistanceToOrderTarget;
                    else
                        object.minimalDistanceToOrderTarget = options.longs === String ? $util.Long.prototype.toString.call(message.minimalDistanceToOrderTarget) : options.longs === Number ? new $util.LongBits(message.minimalDistanceToOrderTarget.low >>> 0, message.minimalDistanceToOrderTarget.high >>> 0).toNumber() : message.minimalDistanceToOrderTarget;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = options.bytes === String ? $util.base64.encode(message.name, 0, message.name.length) : options.bytes === Array ? Array.prototype.slice.call(message.name) : message.name;
                return object;
            };

            /**
             * Converts this UpdateSubCompany to JSON.
             * @function toJSON
             * @memberof fastcity.admin.UpdateSubCompany
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UpdateSubCompany.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return UpdateSubCompany;
        })();

        admin.DeleteSubCompany = (function() {

            /**
             * Properties of a DeleteSubCompany.
             * @memberof fastcity.admin
             * @interface IDeleteSubCompany
             * @property {number|Long|null} [subCompanyId] DeleteSubCompany subCompanyId
             */

            /**
             * Constructs a new DeleteSubCompany.
             * @memberof fastcity.admin
             * @classdesc Represents a DeleteSubCompany.
             * @implements IDeleteSubCompany
             * @constructor
             * @param {fastcity.admin.IDeleteSubCompany=} [properties] Properties to set
             */
            function DeleteSubCompany(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * DeleteSubCompany subCompanyId.
             * @member {number|Long} subCompanyId
             * @memberof fastcity.admin.DeleteSubCompany
             * @instance
             */
            DeleteSubCompany.prototype.subCompanyId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Creates a new DeleteSubCompany instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.DeleteSubCompany
             * @static
             * @param {fastcity.admin.IDeleteSubCompany=} [properties] Properties to set
             * @returns {fastcity.admin.DeleteSubCompany} DeleteSubCompany instance
             */
            DeleteSubCompany.create = function create(properties) {
                return new DeleteSubCompany(properties);
            };

            /**
             * Encodes the specified DeleteSubCompany message. Does not implicitly {@link fastcity.admin.DeleteSubCompany.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.DeleteSubCompany
             * @static
             * @param {fastcity.admin.IDeleteSubCompany} message DeleteSubCompany message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteSubCompany.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.subCompanyId != null && Object.hasOwnProperty.call(message, "subCompanyId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.subCompanyId);
                return writer;
            };

            /**
             * Decodes a DeleteSubCompany message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.DeleteSubCompany
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.DeleteSubCompany} DeleteSubCompany
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteSubCompany.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.DeleteSubCompany();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.subCompanyId = reader.uint64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a DeleteSubCompany message.
             * @function verify
             * @memberof fastcity.admin.DeleteSubCompany
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DeleteSubCompany.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.subCompanyId != null && message.hasOwnProperty("subCompanyId"))
                    if (!$util.isInteger(message.subCompanyId) && !(message.subCompanyId && $util.isInteger(message.subCompanyId.low) && $util.isInteger(message.subCompanyId.high)))
                        return "subCompanyId: integer|Long expected";
                return null;
            };

            /**
             * Creates a DeleteSubCompany message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.DeleteSubCompany
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.DeleteSubCompany} DeleteSubCompany
             */
            DeleteSubCompany.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.DeleteSubCompany)
                    return object;
                var message = new $root.fastcity.admin.DeleteSubCompany();
                if (object.subCompanyId != null)
                    if ($util.Long)
                        (message.subCompanyId = $util.Long.fromValue(object.subCompanyId)).unsigned = true;
                    else if (typeof object.subCompanyId === "string")
                        message.subCompanyId = parseInt(object.subCompanyId, 10);
                    else if (typeof object.subCompanyId === "number")
                        message.subCompanyId = object.subCompanyId;
                    else if (typeof object.subCompanyId === "object")
                        message.subCompanyId = new $util.LongBits(object.subCompanyId.low >>> 0, object.subCompanyId.high >>> 0).toNumber(true);
                return message;
            };

            /**
             * Creates a plain object from a DeleteSubCompany message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.DeleteSubCompany
             * @static
             * @param {fastcity.admin.DeleteSubCompany} message DeleteSubCompany
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DeleteSubCompany.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.subCompanyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.subCompanyId = options.longs === String ? "0" : 0;
                if (message.subCompanyId != null && message.hasOwnProperty("subCompanyId"))
                    if (typeof message.subCompanyId === "number")
                        object.subCompanyId = options.longs === String ? String(message.subCompanyId) : message.subCompanyId;
                    else
                        object.subCompanyId = options.longs === String ? $util.Long.prototype.toString.call(message.subCompanyId) : options.longs === Number ? new $util.LongBits(message.subCompanyId.low >>> 0, message.subCompanyId.high >>> 0).toNumber(true) : message.subCompanyId;
                return object;
            };

            /**
             * Converts this DeleteSubCompany to JSON.
             * @function toJSON
             * @memberof fastcity.admin.DeleteSubCompany
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DeleteSubCompany.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return DeleteSubCompany;
        })();

        admin.GetSubCompanyList = (function() {

            /**
             * Properties of a GetSubCompanyList.
             * @memberof fastcity.admin
             * @interface IGetSubCompanyList
             * @property {Array.<number|Long>|null} [subCompanyId] GetSubCompanyList subCompanyId
             * @property {boolean|null} [withDeleted] GetSubCompanyList withDeleted
             */

            /**
             * Constructs a new GetSubCompanyList.
             * @memberof fastcity.admin
             * @classdesc Represents a GetSubCompanyList.
             * @implements IGetSubCompanyList
             * @constructor
             * @param {fastcity.admin.IGetSubCompanyList=} [properties] Properties to set
             */
            function GetSubCompanyList(properties) {
                this.subCompanyId = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GetSubCompanyList subCompanyId.
             * @member {Array.<number|Long>} subCompanyId
             * @memberof fastcity.admin.GetSubCompanyList
             * @instance
             */
            GetSubCompanyList.prototype.subCompanyId = $util.emptyArray;

            /**
             * GetSubCompanyList withDeleted.
             * @member {boolean} withDeleted
             * @memberof fastcity.admin.GetSubCompanyList
             * @instance
             */
            GetSubCompanyList.prototype.withDeleted = false;

            /**
             * Creates a new GetSubCompanyList instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.GetSubCompanyList
             * @static
             * @param {fastcity.admin.IGetSubCompanyList=} [properties] Properties to set
             * @returns {fastcity.admin.GetSubCompanyList} GetSubCompanyList instance
             */
            GetSubCompanyList.create = function create(properties) {
                return new GetSubCompanyList(properties);
            };

            /**
             * Encodes the specified GetSubCompanyList message. Does not implicitly {@link fastcity.admin.GetSubCompanyList.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.GetSubCompanyList
             * @static
             * @param {fastcity.admin.IGetSubCompanyList} message GetSubCompanyList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetSubCompanyList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.subCompanyId != null && message.subCompanyId.length) {
                    writer.uint32(/* id 1, wireType 2 =*/10).fork();
                    for (var i = 0; i < message.subCompanyId.length; ++i)
                        writer.uint64(message.subCompanyId[i]);
                    writer.ldelim();
                }
                if (message.withDeleted != null && Object.hasOwnProperty.call(message, "withDeleted"))
                    writer.uint32(/* id 2, wireType 0 =*/16).bool(message.withDeleted);
                return writer;
            };

            /**
             * Decodes a GetSubCompanyList message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.GetSubCompanyList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.GetSubCompanyList} GetSubCompanyList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetSubCompanyList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.GetSubCompanyList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.subCompanyId && message.subCompanyId.length))
                            message.subCompanyId = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.subCompanyId.push(reader.uint64());
                        } else
                            message.subCompanyId.push(reader.uint64());
                        break;
                    case 2:
                        message.withDeleted = reader.bool();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a GetSubCompanyList message.
             * @function verify
             * @memberof fastcity.admin.GetSubCompanyList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetSubCompanyList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.subCompanyId != null && message.hasOwnProperty("subCompanyId")) {
                    if (!Array.isArray(message.subCompanyId))
                        return "subCompanyId: array expected";
                    for (var i = 0; i < message.subCompanyId.length; ++i)
                        if (!$util.isInteger(message.subCompanyId[i]) && !(message.subCompanyId[i] && $util.isInteger(message.subCompanyId[i].low) && $util.isInteger(message.subCompanyId[i].high)))
                            return "subCompanyId: integer|Long[] expected";
                }
                if (message.withDeleted != null && message.hasOwnProperty("withDeleted"))
                    if (typeof message.withDeleted !== "boolean")
                        return "withDeleted: boolean expected";
                return null;
            };

            /**
             * Creates a GetSubCompanyList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.GetSubCompanyList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.GetSubCompanyList} GetSubCompanyList
             */
            GetSubCompanyList.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.GetSubCompanyList)
                    return object;
                var message = new $root.fastcity.admin.GetSubCompanyList();
                if (object.subCompanyId) {
                    if (!Array.isArray(object.subCompanyId))
                        throw TypeError(".fastcity.admin.GetSubCompanyList.subCompanyId: array expected");
                    message.subCompanyId = [];
                    for (var i = 0; i < object.subCompanyId.length; ++i)
                        if ($util.Long)
                            (message.subCompanyId[i] = $util.Long.fromValue(object.subCompanyId[i])).unsigned = true;
                        else if (typeof object.subCompanyId[i] === "string")
                            message.subCompanyId[i] = parseInt(object.subCompanyId[i], 10);
                        else if (typeof object.subCompanyId[i] === "number")
                            message.subCompanyId[i] = object.subCompanyId[i];
                        else if (typeof object.subCompanyId[i] === "object")
                            message.subCompanyId[i] = new $util.LongBits(object.subCompanyId[i].low >>> 0, object.subCompanyId[i].high >>> 0).toNumber(true);
                }
                if (object.withDeleted != null)
                    message.withDeleted = Boolean(object.withDeleted);
                return message;
            };

            /**
             * Creates a plain object from a GetSubCompanyList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.GetSubCompanyList
             * @static
             * @param {fastcity.admin.GetSubCompanyList} message GetSubCompanyList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetSubCompanyList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.subCompanyId = [];
                if (options.defaults)
                    object.withDeleted = false;
                if (message.subCompanyId && message.subCompanyId.length) {
                    object.subCompanyId = [];
                    for (var j = 0; j < message.subCompanyId.length; ++j)
                        if (typeof message.subCompanyId[j] === "number")
                            object.subCompanyId[j] = options.longs === String ? String(message.subCompanyId[j]) : message.subCompanyId[j];
                        else
                            object.subCompanyId[j] = options.longs === String ? $util.Long.prototype.toString.call(message.subCompanyId[j]) : options.longs === Number ? new $util.LongBits(message.subCompanyId[j].low >>> 0, message.subCompanyId[j].high >>> 0).toNumber(true) : message.subCompanyId[j];
                }
                if (message.withDeleted != null && message.hasOwnProperty("withDeleted"))
                    object.withDeleted = message.withDeleted;
                return object;
            };

            /**
             * Converts this GetSubCompanyList to JSON.
             * @function toJSON
             * @memberof fastcity.admin.GetSubCompanyList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetSubCompanyList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return GetSubCompanyList;
        })();

        admin.SubCompanyList = (function() {

            /**
             * Properties of a SubCompanyList.
             * @memberof fastcity.admin
             * @interface ISubCompanyList
             * @property {Array.<fastcity.admin.ISubCompany>|null} [subCompany] SubCompanyList subCompany
             */

            /**
             * Constructs a new SubCompanyList.
             * @memberof fastcity.admin
             * @classdesc Represents a SubCompanyList.
             * @implements ISubCompanyList
             * @constructor
             * @param {fastcity.admin.ISubCompanyList=} [properties] Properties to set
             */
            function SubCompanyList(properties) {
                this.subCompany = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * SubCompanyList subCompany.
             * @member {Array.<fastcity.admin.ISubCompany>} subCompany
             * @memberof fastcity.admin.SubCompanyList
             * @instance
             */
            SubCompanyList.prototype.subCompany = $util.emptyArray;

            /**
             * Creates a new SubCompanyList instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.SubCompanyList
             * @static
             * @param {fastcity.admin.ISubCompanyList=} [properties] Properties to set
             * @returns {fastcity.admin.SubCompanyList} SubCompanyList instance
             */
            SubCompanyList.create = function create(properties) {
                return new SubCompanyList(properties);
            };

            /**
             * Encodes the specified SubCompanyList message. Does not implicitly {@link fastcity.admin.SubCompanyList.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.SubCompanyList
             * @static
             * @param {fastcity.admin.ISubCompanyList} message SubCompanyList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SubCompanyList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.subCompany != null && message.subCompany.length)
                    for (var i = 0; i < message.subCompany.length; ++i)
                        $root.fastcity.admin.SubCompany.encode(message.subCompany[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a SubCompanyList message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.SubCompanyList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.SubCompanyList} SubCompanyList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SubCompanyList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.SubCompanyList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.subCompany && message.subCompany.length))
                            message.subCompany = [];
                        message.subCompany.push($root.fastcity.admin.SubCompany.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a SubCompanyList message.
             * @function verify
             * @memberof fastcity.admin.SubCompanyList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SubCompanyList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.subCompany != null && message.hasOwnProperty("subCompany")) {
                    if (!Array.isArray(message.subCompany))
                        return "subCompany: array expected";
                    for (var i = 0; i < message.subCompany.length; ++i) {
                        var error = $root.fastcity.admin.SubCompany.verify(message.subCompany[i]);
                        if (error)
                            return "subCompany." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a SubCompanyList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.SubCompanyList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.SubCompanyList} SubCompanyList
             */
            SubCompanyList.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.SubCompanyList)
                    return object;
                var message = new $root.fastcity.admin.SubCompanyList();
                if (object.subCompany) {
                    if (!Array.isArray(object.subCompany))
                        throw TypeError(".fastcity.admin.SubCompanyList.subCompany: array expected");
                    message.subCompany = [];
                    for (var i = 0; i < object.subCompany.length; ++i) {
                        if (typeof object.subCompany[i] !== "object")
                            throw TypeError(".fastcity.admin.SubCompanyList.subCompany: object expected");
                        message.subCompany[i] = $root.fastcity.admin.SubCompany.fromObject(object.subCompany[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a SubCompanyList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.SubCompanyList
             * @static
             * @param {fastcity.admin.SubCompanyList} message SubCompanyList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SubCompanyList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.subCompany = [];
                if (message.subCompany && message.subCompany.length) {
                    object.subCompany = [];
                    for (var j = 0; j < message.subCompany.length; ++j)
                        object.subCompany[j] = $root.fastcity.admin.SubCompany.toObject(message.subCompany[j], options);
                }
                return object;
            };

            /**
             * Converts this SubCompanyList to JSON.
             * @function toJSON
             * @memberof fastcity.admin.SubCompanyList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SubCompanyList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return SubCompanyList;
        })();

        /**
         * SubCompanyZoneRequestResult enum.
         * @name fastcity.admin.SubCompanyZoneRequestResult
         * @enum {number}
         * @property {number} SBZRR_NOT_FOUND=0 SBZRR_NOT_FOUND value
         * @property {number} SBZRR_BAD_COMPANY_ID_COMPANY_NOT_FOUND=1 SBZRR_BAD_COMPANY_ID_COMPANY_NOT_FOUND value
         * @property {number} SBZRR_BAD_COMPANY_ID_COMPANY_IS_DELETED=2 SBZRR_BAD_COMPANY_ID_COMPANY_IS_DELETED value
         * @property {number} SBZRR_BAD_SUB_COMPANY_ID_SUB_COMPANY_NOT_FOUND=3 SBZRR_BAD_SUB_COMPANY_ID_SUB_COMPANY_NOT_FOUND value
         * @property {number} SBZRR_BAD_SUB_COMPANY_ID_SUB_COMPANY_IS_DELETED=4 SBZRR_BAD_SUB_COMPANY_ID_SUB_COMPANY_IS_DELETED value
         */
        admin.SubCompanyZoneRequestResult = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "SBZRR_NOT_FOUND"] = 0;
            values[valuesById[1] = "SBZRR_BAD_COMPANY_ID_COMPANY_NOT_FOUND"] = 1;
            values[valuesById[2] = "SBZRR_BAD_COMPANY_ID_COMPANY_IS_DELETED"] = 2;
            values[valuesById[3] = "SBZRR_BAD_SUB_COMPANY_ID_SUB_COMPANY_NOT_FOUND"] = 3;
            values[valuesById[4] = "SBZRR_BAD_SUB_COMPANY_ID_SUB_COMPANY_IS_DELETED"] = 4;
            return values;
        })();

        admin.Polygon = (function() {

            /**
             * Properties of a Polygon.
             * @memberof fastcity.admin
             * @interface IPolygon
             * @property {Array.<fastcity.common.ICoordinate>|null} [coordinates] Polygon coordinates
             */

            /**
             * Constructs a new Polygon.
             * @memberof fastcity.admin
             * @classdesc Represents a Polygon.
             * @implements IPolygon
             * @constructor
             * @param {fastcity.admin.IPolygon=} [properties] Properties to set
             */
            function Polygon(properties) {
                this.coordinates = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Polygon coordinates.
             * @member {Array.<fastcity.common.ICoordinate>} coordinates
             * @memberof fastcity.admin.Polygon
             * @instance
             */
            Polygon.prototype.coordinates = $util.emptyArray;

            /**
             * Creates a new Polygon instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.Polygon
             * @static
             * @param {fastcity.admin.IPolygon=} [properties] Properties to set
             * @returns {fastcity.admin.Polygon} Polygon instance
             */
            Polygon.create = function create(properties) {
                return new Polygon(properties);
            };

            /**
             * Encodes the specified Polygon message. Does not implicitly {@link fastcity.admin.Polygon.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.Polygon
             * @static
             * @param {fastcity.admin.IPolygon} message Polygon message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Polygon.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.coordinates != null && message.coordinates.length)
                    for (var i = 0; i < message.coordinates.length; ++i)
                        $root.fastcity.common.Coordinate.encode(message.coordinates[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a Polygon message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.Polygon
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.Polygon} Polygon
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Polygon.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.Polygon();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.coordinates && message.coordinates.length))
                            message.coordinates = [];
                        message.coordinates.push($root.fastcity.common.Coordinate.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a Polygon message.
             * @function verify
             * @memberof fastcity.admin.Polygon
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Polygon.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.coordinates != null && message.hasOwnProperty("coordinates")) {
                    if (!Array.isArray(message.coordinates))
                        return "coordinates: array expected";
                    for (var i = 0; i < message.coordinates.length; ++i) {
                        var error = $root.fastcity.common.Coordinate.verify(message.coordinates[i]);
                        if (error)
                            return "coordinates." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a Polygon message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.Polygon
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.Polygon} Polygon
             */
            Polygon.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.Polygon)
                    return object;
                var message = new $root.fastcity.admin.Polygon();
                if (object.coordinates) {
                    if (!Array.isArray(object.coordinates))
                        throw TypeError(".fastcity.admin.Polygon.coordinates: array expected");
                    message.coordinates = [];
                    for (var i = 0; i < object.coordinates.length; ++i) {
                        if (typeof object.coordinates[i] !== "object")
                            throw TypeError(".fastcity.admin.Polygon.coordinates: object expected");
                        message.coordinates[i] = $root.fastcity.common.Coordinate.fromObject(object.coordinates[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a Polygon message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.Polygon
             * @static
             * @param {fastcity.admin.Polygon} message Polygon
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Polygon.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.coordinates = [];
                if (message.coordinates && message.coordinates.length) {
                    object.coordinates = [];
                    for (var j = 0; j < message.coordinates.length; ++j)
                        object.coordinates[j] = $root.fastcity.common.Coordinate.toObject(message.coordinates[j], options);
                }
                return object;
            };

            /**
             * Converts this Polygon to JSON.
             * @function toJSON
             * @memberof fastcity.admin.Polygon
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Polygon.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Polygon;
        })();

        admin.SubCompanyZone = (function() {

            /**
             * Properties of a SubCompanyZone.
             * @memberof fastcity.admin
             * @interface ISubCompanyZone
             * @property {number|Long|null} [subCompanyZoneId] SubCompanyZone subCompanyZoneId
             * @property {number|Long|null} [companyId] SubCompanyZone companyId
             * @property {number|Long|null} [subCompanyId] SubCompanyZone subCompanyId
             * @property {string|null} [name] SubCompanyZone name
             * @property {fastcity.admin.IPolygon|null} [polygon] SubCompanyZone polygon
             * @property {string|null} [color] SubCompanyZone color
             * @property {boolean|null} [isDisabled] SubCompanyZone isDisabled
             * @property {boolean|null} [isDeleted] SubCompanyZone isDeleted
             */

            /**
             * Constructs a new SubCompanyZone.
             * @memberof fastcity.admin
             * @classdesc Represents a SubCompanyZone.
             * @implements ISubCompanyZone
             * @constructor
             * @param {fastcity.admin.ISubCompanyZone=} [properties] Properties to set
             */
            function SubCompanyZone(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * SubCompanyZone subCompanyZoneId.
             * @member {number|Long} subCompanyZoneId
             * @memberof fastcity.admin.SubCompanyZone
             * @instance
             */
            SubCompanyZone.prototype.subCompanyZoneId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * SubCompanyZone companyId.
             * @member {number|Long} companyId
             * @memberof fastcity.admin.SubCompanyZone
             * @instance
             */
            SubCompanyZone.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * SubCompanyZone subCompanyId.
             * @member {number|Long} subCompanyId
             * @memberof fastcity.admin.SubCompanyZone
             * @instance
             */
            SubCompanyZone.prototype.subCompanyId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * SubCompanyZone name.
             * @member {string} name
             * @memberof fastcity.admin.SubCompanyZone
             * @instance
             */
            SubCompanyZone.prototype.name = "";

            /**
             * SubCompanyZone polygon.
             * @member {fastcity.admin.IPolygon|null|undefined} polygon
             * @memberof fastcity.admin.SubCompanyZone
             * @instance
             */
            SubCompanyZone.prototype.polygon = null;

            /**
             * SubCompanyZone color.
             * @member {string} color
             * @memberof fastcity.admin.SubCompanyZone
             * @instance
             */
            SubCompanyZone.prototype.color = "";

            /**
             * SubCompanyZone isDisabled.
             * @member {boolean} isDisabled
             * @memberof fastcity.admin.SubCompanyZone
             * @instance
             */
            SubCompanyZone.prototype.isDisabled = false;

            /**
             * SubCompanyZone isDeleted.
             * @member {boolean} isDeleted
             * @memberof fastcity.admin.SubCompanyZone
             * @instance
             */
            SubCompanyZone.prototype.isDeleted = false;

            /**
             * Creates a new SubCompanyZone instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.SubCompanyZone
             * @static
             * @param {fastcity.admin.ISubCompanyZone=} [properties] Properties to set
             * @returns {fastcity.admin.SubCompanyZone} SubCompanyZone instance
             */
            SubCompanyZone.create = function create(properties) {
                return new SubCompanyZone(properties);
            };

            /**
             * Encodes the specified SubCompanyZone message. Does not implicitly {@link fastcity.admin.SubCompanyZone.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.SubCompanyZone
             * @static
             * @param {fastcity.admin.ISubCompanyZone} message SubCompanyZone message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SubCompanyZone.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.subCompanyZoneId != null && Object.hasOwnProperty.call(message, "subCompanyZoneId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.subCompanyZoneId);
                if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.companyId);
                if (message.subCompanyId != null && Object.hasOwnProperty.call(message, "subCompanyId"))
                    writer.uint32(/* id 3, wireType 0 =*/24).uint64(message.subCompanyId);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.name);
                if (message.polygon != null && Object.hasOwnProperty.call(message, "polygon"))
                    $root.fastcity.admin.Polygon.encode(message.polygon, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.color != null && Object.hasOwnProperty.call(message, "color"))
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.color);
                if (message.isDisabled != null && Object.hasOwnProperty.call(message, "isDisabled"))
                    writer.uint32(/* id 7, wireType 0 =*/56).bool(message.isDisabled);
                if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                    writer.uint32(/* id 8, wireType 0 =*/64).bool(message.isDeleted);
                return writer;
            };

            /**
             * Decodes a SubCompanyZone message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.SubCompanyZone
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.SubCompanyZone} SubCompanyZone
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SubCompanyZone.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.SubCompanyZone();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.subCompanyZoneId = reader.uint64();
                        break;
                    case 2:
                        message.companyId = reader.uint64();
                        break;
                    case 3:
                        message.subCompanyId = reader.uint64();
                        break;
                    case 4:
                        message.name = reader.string();
                        break;
                    case 5:
                        message.polygon = $root.fastcity.admin.Polygon.decode(reader, reader.uint32());
                        break;
                    case 6:
                        message.color = reader.string();
                        break;
                    case 7:
                        message.isDisabled = reader.bool();
                        break;
                    case 8:
                        message.isDeleted = reader.bool();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a SubCompanyZone message.
             * @function verify
             * @memberof fastcity.admin.SubCompanyZone
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SubCompanyZone.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.subCompanyZoneId != null && message.hasOwnProperty("subCompanyZoneId"))
                    if (!$util.isInteger(message.subCompanyZoneId) && !(message.subCompanyZoneId && $util.isInteger(message.subCompanyZoneId.low) && $util.isInteger(message.subCompanyZoneId.high)))
                        return "subCompanyZoneId: integer|Long expected";
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                        return "companyId: integer|Long expected";
                if (message.subCompanyId != null && message.hasOwnProperty("subCompanyId"))
                    if (!$util.isInteger(message.subCompanyId) && !(message.subCompanyId && $util.isInteger(message.subCompanyId.low) && $util.isInteger(message.subCompanyId.high)))
                        return "subCompanyId: integer|Long expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.polygon != null && message.hasOwnProperty("polygon")) {
                    var error = $root.fastcity.admin.Polygon.verify(message.polygon);
                    if (error)
                        return "polygon." + error;
                }
                if (message.color != null && message.hasOwnProperty("color"))
                    if (!$util.isString(message.color))
                        return "color: string expected";
                if (message.isDisabled != null && message.hasOwnProperty("isDisabled"))
                    if (typeof message.isDisabled !== "boolean")
                        return "isDisabled: boolean expected";
                if (message.isDeleted != null && message.hasOwnProperty("isDeleted"))
                    if (typeof message.isDeleted !== "boolean")
                        return "isDeleted: boolean expected";
                return null;
            };

            /**
             * Creates a SubCompanyZone message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.SubCompanyZone
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.SubCompanyZone} SubCompanyZone
             */
            SubCompanyZone.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.SubCompanyZone)
                    return object;
                var message = new $root.fastcity.admin.SubCompanyZone();
                if (object.subCompanyZoneId != null)
                    if ($util.Long)
                        (message.subCompanyZoneId = $util.Long.fromValue(object.subCompanyZoneId)).unsigned = true;
                    else if (typeof object.subCompanyZoneId === "string")
                        message.subCompanyZoneId = parseInt(object.subCompanyZoneId, 10);
                    else if (typeof object.subCompanyZoneId === "number")
                        message.subCompanyZoneId = object.subCompanyZoneId;
                    else if (typeof object.subCompanyZoneId === "object")
                        message.subCompanyZoneId = new $util.LongBits(object.subCompanyZoneId.low >>> 0, object.subCompanyZoneId.high >>> 0).toNumber(true);
                if (object.companyId != null)
                    if ($util.Long)
                        (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = true;
                    else if (typeof object.companyId === "string")
                        message.companyId = parseInt(object.companyId, 10);
                    else if (typeof object.companyId === "number")
                        message.companyId = object.companyId;
                    else if (typeof object.companyId === "object")
                        message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber(true);
                if (object.subCompanyId != null)
                    if ($util.Long)
                        (message.subCompanyId = $util.Long.fromValue(object.subCompanyId)).unsigned = true;
                    else if (typeof object.subCompanyId === "string")
                        message.subCompanyId = parseInt(object.subCompanyId, 10);
                    else if (typeof object.subCompanyId === "number")
                        message.subCompanyId = object.subCompanyId;
                    else if (typeof object.subCompanyId === "object")
                        message.subCompanyId = new $util.LongBits(object.subCompanyId.low >>> 0, object.subCompanyId.high >>> 0).toNumber(true);
                if (object.name != null)
                    message.name = String(object.name);
                if (object.polygon != null) {
                    if (typeof object.polygon !== "object")
                        throw TypeError(".fastcity.admin.SubCompanyZone.polygon: object expected");
                    message.polygon = $root.fastcity.admin.Polygon.fromObject(object.polygon);
                }
                if (object.color != null)
                    message.color = String(object.color);
                if (object.isDisabled != null)
                    message.isDisabled = Boolean(object.isDisabled);
                if (object.isDeleted != null)
                    message.isDeleted = Boolean(object.isDeleted);
                return message;
            };

            /**
             * Creates a plain object from a SubCompanyZone message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.SubCompanyZone
             * @static
             * @param {fastcity.admin.SubCompanyZone} message SubCompanyZone
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SubCompanyZone.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.subCompanyZoneId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.subCompanyZoneId = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.companyId = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.subCompanyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.subCompanyId = options.longs === String ? "0" : 0;
                    object.name = "";
                    object.polygon = null;
                    object.color = "";
                    object.isDisabled = false;
                    object.isDeleted = false;
                }
                if (message.subCompanyZoneId != null && message.hasOwnProperty("subCompanyZoneId"))
                    if (typeof message.subCompanyZoneId === "number")
                        object.subCompanyZoneId = options.longs === String ? String(message.subCompanyZoneId) : message.subCompanyZoneId;
                    else
                        object.subCompanyZoneId = options.longs === String ? $util.Long.prototype.toString.call(message.subCompanyZoneId) : options.longs === Number ? new $util.LongBits(message.subCompanyZoneId.low >>> 0, message.subCompanyZoneId.high >>> 0).toNumber(true) : message.subCompanyZoneId;
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (typeof message.companyId === "number")
                        object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                    else
                        object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber(true) : message.companyId;
                if (message.subCompanyId != null && message.hasOwnProperty("subCompanyId"))
                    if (typeof message.subCompanyId === "number")
                        object.subCompanyId = options.longs === String ? String(message.subCompanyId) : message.subCompanyId;
                    else
                        object.subCompanyId = options.longs === String ? $util.Long.prototype.toString.call(message.subCompanyId) : options.longs === Number ? new $util.LongBits(message.subCompanyId.low >>> 0, message.subCompanyId.high >>> 0).toNumber(true) : message.subCompanyId;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.polygon != null && message.hasOwnProperty("polygon"))
                    object.polygon = $root.fastcity.admin.Polygon.toObject(message.polygon, options);
                if (message.color != null && message.hasOwnProperty("color"))
                    object.color = message.color;
                if (message.isDisabled != null && message.hasOwnProperty("isDisabled"))
                    object.isDisabled = message.isDisabled;
                if (message.isDeleted != null && message.hasOwnProperty("isDeleted"))
                    object.isDeleted = message.isDeleted;
                return object;
            };

            /**
             * Converts this SubCompanyZone to JSON.
             * @function toJSON
             * @memberof fastcity.admin.SubCompanyZone
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SubCompanyZone.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return SubCompanyZone;
        })();

        admin.SubCompanyZoneList = (function() {

            /**
             * Properties of a SubCompanyZoneList.
             * @memberof fastcity.admin
             * @interface ISubCompanyZoneList
             * @property {Array.<fastcity.admin.ISubCompanyZone>|null} [subCompanyZone] SubCompanyZoneList subCompanyZone
             */

            /**
             * Constructs a new SubCompanyZoneList.
             * @memberof fastcity.admin
             * @classdesc Represents a SubCompanyZoneList.
             * @implements ISubCompanyZoneList
             * @constructor
             * @param {fastcity.admin.ISubCompanyZoneList=} [properties] Properties to set
             */
            function SubCompanyZoneList(properties) {
                this.subCompanyZone = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * SubCompanyZoneList subCompanyZone.
             * @member {Array.<fastcity.admin.ISubCompanyZone>} subCompanyZone
             * @memberof fastcity.admin.SubCompanyZoneList
             * @instance
             */
            SubCompanyZoneList.prototype.subCompanyZone = $util.emptyArray;

            /**
             * Creates a new SubCompanyZoneList instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.SubCompanyZoneList
             * @static
             * @param {fastcity.admin.ISubCompanyZoneList=} [properties] Properties to set
             * @returns {fastcity.admin.SubCompanyZoneList} SubCompanyZoneList instance
             */
            SubCompanyZoneList.create = function create(properties) {
                return new SubCompanyZoneList(properties);
            };

            /**
             * Encodes the specified SubCompanyZoneList message. Does not implicitly {@link fastcity.admin.SubCompanyZoneList.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.SubCompanyZoneList
             * @static
             * @param {fastcity.admin.ISubCompanyZoneList} message SubCompanyZoneList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SubCompanyZoneList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.subCompanyZone != null && message.subCompanyZone.length)
                    for (var i = 0; i < message.subCompanyZone.length; ++i)
                        $root.fastcity.admin.SubCompanyZone.encode(message.subCompanyZone[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a SubCompanyZoneList message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.SubCompanyZoneList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.SubCompanyZoneList} SubCompanyZoneList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SubCompanyZoneList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.SubCompanyZoneList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.subCompanyZone && message.subCompanyZone.length))
                            message.subCompanyZone = [];
                        message.subCompanyZone.push($root.fastcity.admin.SubCompanyZone.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a SubCompanyZoneList message.
             * @function verify
             * @memberof fastcity.admin.SubCompanyZoneList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SubCompanyZoneList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.subCompanyZone != null && message.hasOwnProperty("subCompanyZone")) {
                    if (!Array.isArray(message.subCompanyZone))
                        return "subCompanyZone: array expected";
                    for (var i = 0; i < message.subCompanyZone.length; ++i) {
                        var error = $root.fastcity.admin.SubCompanyZone.verify(message.subCompanyZone[i]);
                        if (error)
                            return "subCompanyZone." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a SubCompanyZoneList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.SubCompanyZoneList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.SubCompanyZoneList} SubCompanyZoneList
             */
            SubCompanyZoneList.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.SubCompanyZoneList)
                    return object;
                var message = new $root.fastcity.admin.SubCompanyZoneList();
                if (object.subCompanyZone) {
                    if (!Array.isArray(object.subCompanyZone))
                        throw TypeError(".fastcity.admin.SubCompanyZoneList.subCompanyZone: array expected");
                    message.subCompanyZone = [];
                    for (var i = 0; i < object.subCompanyZone.length; ++i) {
                        if (typeof object.subCompanyZone[i] !== "object")
                            throw TypeError(".fastcity.admin.SubCompanyZoneList.subCompanyZone: object expected");
                        message.subCompanyZone[i] = $root.fastcity.admin.SubCompanyZone.fromObject(object.subCompanyZone[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a SubCompanyZoneList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.SubCompanyZoneList
             * @static
             * @param {fastcity.admin.SubCompanyZoneList} message SubCompanyZoneList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SubCompanyZoneList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.subCompanyZone = [];
                if (message.subCompanyZone && message.subCompanyZone.length) {
                    object.subCompanyZone = [];
                    for (var j = 0; j < message.subCompanyZone.length; ++j)
                        object.subCompanyZone[j] = $root.fastcity.admin.SubCompanyZone.toObject(message.subCompanyZone[j], options);
                }
                return object;
            };

            /**
             * Converts this SubCompanyZoneList to JSON.
             * @function toJSON
             * @memberof fastcity.admin.SubCompanyZoneList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SubCompanyZoneList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return SubCompanyZoneList;
        })();

        admin.CreateSubCompanyZone = (function() {

            /**
             * Properties of a CreateSubCompanyZone.
             * @memberof fastcity.admin
             * @interface ICreateSubCompanyZone
             * @property {number|Long|null} [companyId] CreateSubCompanyZone companyId
             * @property {number|Long|null} [subCompanyId] CreateSubCompanyZone subCompanyId
             * @property {string|null} [name] CreateSubCompanyZone name
             * @property {fastcity.admin.IPolygon|null} [polygon] CreateSubCompanyZone polygon
             * @property {string|null} [color] CreateSubCompanyZone color
             * @property {boolean|null} [isDisabled] CreateSubCompanyZone isDisabled
             * @property {boolean|null} [latitudeLongitudeSwap] CreateSubCompanyZone latitudeLongitudeSwap
             */

            /**
             * Constructs a new CreateSubCompanyZone.
             * @memberof fastcity.admin
             * @classdesc Represents a CreateSubCompanyZone.
             * @implements ICreateSubCompanyZone
             * @constructor
             * @param {fastcity.admin.ICreateSubCompanyZone=} [properties] Properties to set
             */
            function CreateSubCompanyZone(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CreateSubCompanyZone companyId.
             * @member {number|Long} companyId
             * @memberof fastcity.admin.CreateSubCompanyZone
             * @instance
             */
            CreateSubCompanyZone.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * CreateSubCompanyZone subCompanyId.
             * @member {number|Long} subCompanyId
             * @memberof fastcity.admin.CreateSubCompanyZone
             * @instance
             */
            CreateSubCompanyZone.prototype.subCompanyId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * CreateSubCompanyZone name.
             * @member {string} name
             * @memberof fastcity.admin.CreateSubCompanyZone
             * @instance
             */
            CreateSubCompanyZone.prototype.name = "";

            /**
             * CreateSubCompanyZone polygon.
             * @member {fastcity.admin.IPolygon|null|undefined} polygon
             * @memberof fastcity.admin.CreateSubCompanyZone
             * @instance
             */
            CreateSubCompanyZone.prototype.polygon = null;

            /**
             * CreateSubCompanyZone color.
             * @member {string} color
             * @memberof fastcity.admin.CreateSubCompanyZone
             * @instance
             */
            CreateSubCompanyZone.prototype.color = "";

            /**
             * CreateSubCompanyZone isDisabled.
             * @member {boolean} isDisabled
             * @memberof fastcity.admin.CreateSubCompanyZone
             * @instance
             */
            CreateSubCompanyZone.prototype.isDisabled = false;

            /**
             * CreateSubCompanyZone latitudeLongitudeSwap.
             * @member {boolean} latitudeLongitudeSwap
             * @memberof fastcity.admin.CreateSubCompanyZone
             * @instance
             */
            CreateSubCompanyZone.prototype.latitudeLongitudeSwap = false;

            /**
             * Creates a new CreateSubCompanyZone instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.CreateSubCompanyZone
             * @static
             * @param {fastcity.admin.ICreateSubCompanyZone=} [properties] Properties to set
             * @returns {fastcity.admin.CreateSubCompanyZone} CreateSubCompanyZone instance
             */
            CreateSubCompanyZone.create = function create(properties) {
                return new CreateSubCompanyZone(properties);
            };

            /**
             * Encodes the specified CreateSubCompanyZone message. Does not implicitly {@link fastcity.admin.CreateSubCompanyZone.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.CreateSubCompanyZone
             * @static
             * @param {fastcity.admin.ICreateSubCompanyZone} message CreateSubCompanyZone message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateSubCompanyZone.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.companyId);
                if (message.subCompanyId != null && Object.hasOwnProperty.call(message, "subCompanyId"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.subCompanyId);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
                if (message.polygon != null && Object.hasOwnProperty.call(message, "polygon"))
                    $root.fastcity.admin.Polygon.encode(message.polygon, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.color != null && Object.hasOwnProperty.call(message, "color"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.color);
                if (message.isDisabled != null && Object.hasOwnProperty.call(message, "isDisabled"))
                    writer.uint32(/* id 6, wireType 0 =*/48).bool(message.isDisabled);
                if (message.latitudeLongitudeSwap != null && Object.hasOwnProperty.call(message, "latitudeLongitudeSwap"))
                    writer.uint32(/* id 7, wireType 0 =*/56).bool(message.latitudeLongitudeSwap);
                return writer;
            };

            /**
             * Decodes a CreateSubCompanyZone message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.CreateSubCompanyZone
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.CreateSubCompanyZone} CreateSubCompanyZone
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateSubCompanyZone.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.CreateSubCompanyZone();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.companyId = reader.uint64();
                        break;
                    case 2:
                        message.subCompanyId = reader.uint64();
                        break;
                    case 3:
                        message.name = reader.string();
                        break;
                    case 4:
                        message.polygon = $root.fastcity.admin.Polygon.decode(reader, reader.uint32());
                        break;
                    case 5:
                        message.color = reader.string();
                        break;
                    case 6:
                        message.isDisabled = reader.bool();
                        break;
                    case 7:
                        message.latitudeLongitudeSwap = reader.bool();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a CreateSubCompanyZone message.
             * @function verify
             * @memberof fastcity.admin.CreateSubCompanyZone
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CreateSubCompanyZone.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                        return "companyId: integer|Long expected";
                if (message.subCompanyId != null && message.hasOwnProperty("subCompanyId"))
                    if (!$util.isInteger(message.subCompanyId) && !(message.subCompanyId && $util.isInteger(message.subCompanyId.low) && $util.isInteger(message.subCompanyId.high)))
                        return "subCompanyId: integer|Long expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.polygon != null && message.hasOwnProperty("polygon")) {
                    var error = $root.fastcity.admin.Polygon.verify(message.polygon);
                    if (error)
                        return "polygon." + error;
                }
                if (message.color != null && message.hasOwnProperty("color"))
                    if (!$util.isString(message.color))
                        return "color: string expected";
                if (message.isDisabled != null && message.hasOwnProperty("isDisabled"))
                    if (typeof message.isDisabled !== "boolean")
                        return "isDisabled: boolean expected";
                if (message.latitudeLongitudeSwap != null && message.hasOwnProperty("latitudeLongitudeSwap"))
                    if (typeof message.latitudeLongitudeSwap !== "boolean")
                        return "latitudeLongitudeSwap: boolean expected";
                return null;
            };

            /**
             * Creates a CreateSubCompanyZone message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.CreateSubCompanyZone
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.CreateSubCompanyZone} CreateSubCompanyZone
             */
            CreateSubCompanyZone.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.CreateSubCompanyZone)
                    return object;
                var message = new $root.fastcity.admin.CreateSubCompanyZone();
                if (object.companyId != null)
                    if ($util.Long)
                        (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = true;
                    else if (typeof object.companyId === "string")
                        message.companyId = parseInt(object.companyId, 10);
                    else if (typeof object.companyId === "number")
                        message.companyId = object.companyId;
                    else if (typeof object.companyId === "object")
                        message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber(true);
                if (object.subCompanyId != null)
                    if ($util.Long)
                        (message.subCompanyId = $util.Long.fromValue(object.subCompanyId)).unsigned = true;
                    else if (typeof object.subCompanyId === "string")
                        message.subCompanyId = parseInt(object.subCompanyId, 10);
                    else if (typeof object.subCompanyId === "number")
                        message.subCompanyId = object.subCompanyId;
                    else if (typeof object.subCompanyId === "object")
                        message.subCompanyId = new $util.LongBits(object.subCompanyId.low >>> 0, object.subCompanyId.high >>> 0).toNumber(true);
                if (object.name != null)
                    message.name = String(object.name);
                if (object.polygon != null) {
                    if (typeof object.polygon !== "object")
                        throw TypeError(".fastcity.admin.CreateSubCompanyZone.polygon: object expected");
                    message.polygon = $root.fastcity.admin.Polygon.fromObject(object.polygon);
                }
                if (object.color != null)
                    message.color = String(object.color);
                if (object.isDisabled != null)
                    message.isDisabled = Boolean(object.isDisabled);
                if (object.latitudeLongitudeSwap != null)
                    message.latitudeLongitudeSwap = Boolean(object.latitudeLongitudeSwap);
                return message;
            };

            /**
             * Creates a plain object from a CreateSubCompanyZone message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.CreateSubCompanyZone
             * @static
             * @param {fastcity.admin.CreateSubCompanyZone} message CreateSubCompanyZone
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateSubCompanyZone.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.companyId = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.subCompanyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.subCompanyId = options.longs === String ? "0" : 0;
                    object.name = "";
                    object.polygon = null;
                    object.color = "";
                    object.isDisabled = false;
                    object.latitudeLongitudeSwap = false;
                }
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (typeof message.companyId === "number")
                        object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                    else
                        object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber(true) : message.companyId;
                if (message.subCompanyId != null && message.hasOwnProperty("subCompanyId"))
                    if (typeof message.subCompanyId === "number")
                        object.subCompanyId = options.longs === String ? String(message.subCompanyId) : message.subCompanyId;
                    else
                        object.subCompanyId = options.longs === String ? $util.Long.prototype.toString.call(message.subCompanyId) : options.longs === Number ? new $util.LongBits(message.subCompanyId.low >>> 0, message.subCompanyId.high >>> 0).toNumber(true) : message.subCompanyId;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.polygon != null && message.hasOwnProperty("polygon"))
                    object.polygon = $root.fastcity.admin.Polygon.toObject(message.polygon, options);
                if (message.color != null && message.hasOwnProperty("color"))
                    object.color = message.color;
                if (message.isDisabled != null && message.hasOwnProperty("isDisabled"))
                    object.isDisabled = message.isDisabled;
                if (message.latitudeLongitudeSwap != null && message.hasOwnProperty("latitudeLongitudeSwap"))
                    object.latitudeLongitudeSwap = message.latitudeLongitudeSwap;
                return object;
            };

            /**
             * Converts this CreateSubCompanyZone to JSON.
             * @function toJSON
             * @memberof fastcity.admin.CreateSubCompanyZone
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateSubCompanyZone.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return CreateSubCompanyZone;
        })();

        admin.CreateSubCompanyZoneFromKML = (function() {

            /**
             * Properties of a CreateSubCompanyZoneFromKML.
             * @memberof fastcity.admin
             * @interface ICreateSubCompanyZoneFromKML
             * @property {number|Long|null} [companyId] CreateSubCompanyZoneFromKML companyId
             * @property {number|Long|null} [subCompanyId] CreateSubCompanyZoneFromKML subCompanyId
             * @property {string|null} [name] CreateSubCompanyZoneFromKML name
             * @property {string|null} [kmlFileBody] CreateSubCompanyZoneFromKML kmlFileBody
             * @property {string|null} [color] CreateSubCompanyZoneFromKML color
             * @property {boolean|null} [isDisabled] CreateSubCompanyZoneFromKML isDisabled
             * @property {boolean|null} [latitudeLongitudeSwap] CreateSubCompanyZoneFromKML latitudeLongitudeSwap
             */

            /**
             * Constructs a new CreateSubCompanyZoneFromKML.
             * @memberof fastcity.admin
             * @classdesc Represents a CreateSubCompanyZoneFromKML.
             * @implements ICreateSubCompanyZoneFromKML
             * @constructor
             * @param {fastcity.admin.ICreateSubCompanyZoneFromKML=} [properties] Properties to set
             */
            function CreateSubCompanyZoneFromKML(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CreateSubCompanyZoneFromKML companyId.
             * @member {number|Long} companyId
             * @memberof fastcity.admin.CreateSubCompanyZoneFromKML
             * @instance
             */
            CreateSubCompanyZoneFromKML.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * CreateSubCompanyZoneFromKML subCompanyId.
             * @member {number|Long} subCompanyId
             * @memberof fastcity.admin.CreateSubCompanyZoneFromKML
             * @instance
             */
            CreateSubCompanyZoneFromKML.prototype.subCompanyId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * CreateSubCompanyZoneFromKML name.
             * @member {string} name
             * @memberof fastcity.admin.CreateSubCompanyZoneFromKML
             * @instance
             */
            CreateSubCompanyZoneFromKML.prototype.name = "";

            /**
             * CreateSubCompanyZoneFromKML kmlFileBody.
             * @member {string} kmlFileBody
             * @memberof fastcity.admin.CreateSubCompanyZoneFromKML
             * @instance
             */
            CreateSubCompanyZoneFromKML.prototype.kmlFileBody = "";

            /**
             * CreateSubCompanyZoneFromKML color.
             * @member {string} color
             * @memberof fastcity.admin.CreateSubCompanyZoneFromKML
             * @instance
             */
            CreateSubCompanyZoneFromKML.prototype.color = "";

            /**
             * CreateSubCompanyZoneFromKML isDisabled.
             * @member {boolean} isDisabled
             * @memberof fastcity.admin.CreateSubCompanyZoneFromKML
             * @instance
             */
            CreateSubCompanyZoneFromKML.prototype.isDisabled = false;

            /**
             * CreateSubCompanyZoneFromKML latitudeLongitudeSwap.
             * @member {boolean} latitudeLongitudeSwap
             * @memberof fastcity.admin.CreateSubCompanyZoneFromKML
             * @instance
             */
            CreateSubCompanyZoneFromKML.prototype.latitudeLongitudeSwap = false;

            /**
             * Creates a new CreateSubCompanyZoneFromKML instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.CreateSubCompanyZoneFromKML
             * @static
             * @param {fastcity.admin.ICreateSubCompanyZoneFromKML=} [properties] Properties to set
             * @returns {fastcity.admin.CreateSubCompanyZoneFromKML} CreateSubCompanyZoneFromKML instance
             */
            CreateSubCompanyZoneFromKML.create = function create(properties) {
                return new CreateSubCompanyZoneFromKML(properties);
            };

            /**
             * Encodes the specified CreateSubCompanyZoneFromKML message. Does not implicitly {@link fastcity.admin.CreateSubCompanyZoneFromKML.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.CreateSubCompanyZoneFromKML
             * @static
             * @param {fastcity.admin.ICreateSubCompanyZoneFromKML} message CreateSubCompanyZoneFromKML message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateSubCompanyZoneFromKML.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.companyId);
                if (message.subCompanyId != null && Object.hasOwnProperty.call(message, "subCompanyId"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.subCompanyId);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
                if (message.kmlFileBody != null && Object.hasOwnProperty.call(message, "kmlFileBody"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.kmlFileBody);
                if (message.color != null && Object.hasOwnProperty.call(message, "color"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.color);
                if (message.isDisabled != null && Object.hasOwnProperty.call(message, "isDisabled"))
                    writer.uint32(/* id 6, wireType 0 =*/48).bool(message.isDisabled);
                if (message.latitudeLongitudeSwap != null && Object.hasOwnProperty.call(message, "latitudeLongitudeSwap"))
                    writer.uint32(/* id 7, wireType 0 =*/56).bool(message.latitudeLongitudeSwap);
                return writer;
            };

            /**
             * Decodes a CreateSubCompanyZoneFromKML message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.CreateSubCompanyZoneFromKML
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.CreateSubCompanyZoneFromKML} CreateSubCompanyZoneFromKML
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateSubCompanyZoneFromKML.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.CreateSubCompanyZoneFromKML();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.companyId = reader.uint64();
                        break;
                    case 2:
                        message.subCompanyId = reader.uint64();
                        break;
                    case 3:
                        message.name = reader.string();
                        break;
                    case 4:
                        message.kmlFileBody = reader.string();
                        break;
                    case 5:
                        message.color = reader.string();
                        break;
                    case 6:
                        message.isDisabled = reader.bool();
                        break;
                    case 7:
                        message.latitudeLongitudeSwap = reader.bool();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a CreateSubCompanyZoneFromKML message.
             * @function verify
             * @memberof fastcity.admin.CreateSubCompanyZoneFromKML
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CreateSubCompanyZoneFromKML.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                        return "companyId: integer|Long expected";
                if (message.subCompanyId != null && message.hasOwnProperty("subCompanyId"))
                    if (!$util.isInteger(message.subCompanyId) && !(message.subCompanyId && $util.isInteger(message.subCompanyId.low) && $util.isInteger(message.subCompanyId.high)))
                        return "subCompanyId: integer|Long expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.kmlFileBody != null && message.hasOwnProperty("kmlFileBody"))
                    if (!$util.isString(message.kmlFileBody))
                        return "kmlFileBody: string expected";
                if (message.color != null && message.hasOwnProperty("color"))
                    if (!$util.isString(message.color))
                        return "color: string expected";
                if (message.isDisabled != null && message.hasOwnProperty("isDisabled"))
                    if (typeof message.isDisabled !== "boolean")
                        return "isDisabled: boolean expected";
                if (message.latitudeLongitudeSwap != null && message.hasOwnProperty("latitudeLongitudeSwap"))
                    if (typeof message.latitudeLongitudeSwap !== "boolean")
                        return "latitudeLongitudeSwap: boolean expected";
                return null;
            };

            /**
             * Creates a CreateSubCompanyZoneFromKML message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.CreateSubCompanyZoneFromKML
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.CreateSubCompanyZoneFromKML} CreateSubCompanyZoneFromKML
             */
            CreateSubCompanyZoneFromKML.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.CreateSubCompanyZoneFromKML)
                    return object;
                var message = new $root.fastcity.admin.CreateSubCompanyZoneFromKML();
                if (object.companyId != null)
                    if ($util.Long)
                        (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = true;
                    else if (typeof object.companyId === "string")
                        message.companyId = parseInt(object.companyId, 10);
                    else if (typeof object.companyId === "number")
                        message.companyId = object.companyId;
                    else if (typeof object.companyId === "object")
                        message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber(true);
                if (object.subCompanyId != null)
                    if ($util.Long)
                        (message.subCompanyId = $util.Long.fromValue(object.subCompanyId)).unsigned = true;
                    else if (typeof object.subCompanyId === "string")
                        message.subCompanyId = parseInt(object.subCompanyId, 10);
                    else if (typeof object.subCompanyId === "number")
                        message.subCompanyId = object.subCompanyId;
                    else if (typeof object.subCompanyId === "object")
                        message.subCompanyId = new $util.LongBits(object.subCompanyId.low >>> 0, object.subCompanyId.high >>> 0).toNumber(true);
                if (object.name != null)
                    message.name = String(object.name);
                if (object.kmlFileBody != null)
                    message.kmlFileBody = String(object.kmlFileBody);
                if (object.color != null)
                    message.color = String(object.color);
                if (object.isDisabled != null)
                    message.isDisabled = Boolean(object.isDisabled);
                if (object.latitudeLongitudeSwap != null)
                    message.latitudeLongitudeSwap = Boolean(object.latitudeLongitudeSwap);
                return message;
            };

            /**
             * Creates a plain object from a CreateSubCompanyZoneFromKML message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.CreateSubCompanyZoneFromKML
             * @static
             * @param {fastcity.admin.CreateSubCompanyZoneFromKML} message CreateSubCompanyZoneFromKML
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateSubCompanyZoneFromKML.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.companyId = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.subCompanyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.subCompanyId = options.longs === String ? "0" : 0;
                    object.name = "";
                    object.kmlFileBody = "";
                    object.color = "";
                    object.isDisabled = false;
                    object.latitudeLongitudeSwap = false;
                }
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (typeof message.companyId === "number")
                        object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                    else
                        object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber(true) : message.companyId;
                if (message.subCompanyId != null && message.hasOwnProperty("subCompanyId"))
                    if (typeof message.subCompanyId === "number")
                        object.subCompanyId = options.longs === String ? String(message.subCompanyId) : message.subCompanyId;
                    else
                        object.subCompanyId = options.longs === String ? $util.Long.prototype.toString.call(message.subCompanyId) : options.longs === Number ? new $util.LongBits(message.subCompanyId.low >>> 0, message.subCompanyId.high >>> 0).toNumber(true) : message.subCompanyId;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.kmlFileBody != null && message.hasOwnProperty("kmlFileBody"))
                    object.kmlFileBody = message.kmlFileBody;
                if (message.color != null && message.hasOwnProperty("color"))
                    object.color = message.color;
                if (message.isDisabled != null && message.hasOwnProperty("isDisabled"))
                    object.isDisabled = message.isDisabled;
                if (message.latitudeLongitudeSwap != null && message.hasOwnProperty("latitudeLongitudeSwap"))
                    object.latitudeLongitudeSwap = message.latitudeLongitudeSwap;
                return object;
            };

            /**
             * Converts this CreateSubCompanyZoneFromKML to JSON.
             * @function toJSON
             * @memberof fastcity.admin.CreateSubCompanyZoneFromKML
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateSubCompanyZoneFromKML.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return CreateSubCompanyZoneFromKML;
        })();

        admin.DeleteSubCompanyZone = (function() {

            /**
             * Properties of a DeleteSubCompanyZone.
             * @memberof fastcity.admin
             * @interface IDeleteSubCompanyZone
             * @property {number|Long|null} [subCompanyZoneId] DeleteSubCompanyZone subCompanyZoneId
             */

            /**
             * Constructs a new DeleteSubCompanyZone.
             * @memberof fastcity.admin
             * @classdesc Represents a DeleteSubCompanyZone.
             * @implements IDeleteSubCompanyZone
             * @constructor
             * @param {fastcity.admin.IDeleteSubCompanyZone=} [properties] Properties to set
             */
            function DeleteSubCompanyZone(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * DeleteSubCompanyZone subCompanyZoneId.
             * @member {number|Long} subCompanyZoneId
             * @memberof fastcity.admin.DeleteSubCompanyZone
             * @instance
             */
            DeleteSubCompanyZone.prototype.subCompanyZoneId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Creates a new DeleteSubCompanyZone instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.DeleteSubCompanyZone
             * @static
             * @param {fastcity.admin.IDeleteSubCompanyZone=} [properties] Properties to set
             * @returns {fastcity.admin.DeleteSubCompanyZone} DeleteSubCompanyZone instance
             */
            DeleteSubCompanyZone.create = function create(properties) {
                return new DeleteSubCompanyZone(properties);
            };

            /**
             * Encodes the specified DeleteSubCompanyZone message. Does not implicitly {@link fastcity.admin.DeleteSubCompanyZone.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.DeleteSubCompanyZone
             * @static
             * @param {fastcity.admin.IDeleteSubCompanyZone} message DeleteSubCompanyZone message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteSubCompanyZone.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.subCompanyZoneId != null && Object.hasOwnProperty.call(message, "subCompanyZoneId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.subCompanyZoneId);
                return writer;
            };

            /**
             * Decodes a DeleteSubCompanyZone message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.DeleteSubCompanyZone
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.DeleteSubCompanyZone} DeleteSubCompanyZone
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteSubCompanyZone.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.DeleteSubCompanyZone();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.subCompanyZoneId = reader.uint64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a DeleteSubCompanyZone message.
             * @function verify
             * @memberof fastcity.admin.DeleteSubCompanyZone
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DeleteSubCompanyZone.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.subCompanyZoneId != null && message.hasOwnProperty("subCompanyZoneId"))
                    if (!$util.isInteger(message.subCompanyZoneId) && !(message.subCompanyZoneId && $util.isInteger(message.subCompanyZoneId.low) && $util.isInteger(message.subCompanyZoneId.high)))
                        return "subCompanyZoneId: integer|Long expected";
                return null;
            };

            /**
             * Creates a DeleteSubCompanyZone message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.DeleteSubCompanyZone
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.DeleteSubCompanyZone} DeleteSubCompanyZone
             */
            DeleteSubCompanyZone.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.DeleteSubCompanyZone)
                    return object;
                var message = new $root.fastcity.admin.DeleteSubCompanyZone();
                if (object.subCompanyZoneId != null)
                    if ($util.Long)
                        (message.subCompanyZoneId = $util.Long.fromValue(object.subCompanyZoneId)).unsigned = true;
                    else if (typeof object.subCompanyZoneId === "string")
                        message.subCompanyZoneId = parseInt(object.subCompanyZoneId, 10);
                    else if (typeof object.subCompanyZoneId === "number")
                        message.subCompanyZoneId = object.subCompanyZoneId;
                    else if (typeof object.subCompanyZoneId === "object")
                        message.subCompanyZoneId = new $util.LongBits(object.subCompanyZoneId.low >>> 0, object.subCompanyZoneId.high >>> 0).toNumber(true);
                return message;
            };

            /**
             * Creates a plain object from a DeleteSubCompanyZone message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.DeleteSubCompanyZone
             * @static
             * @param {fastcity.admin.DeleteSubCompanyZone} message DeleteSubCompanyZone
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DeleteSubCompanyZone.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.subCompanyZoneId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.subCompanyZoneId = options.longs === String ? "0" : 0;
                if (message.subCompanyZoneId != null && message.hasOwnProperty("subCompanyZoneId"))
                    if (typeof message.subCompanyZoneId === "number")
                        object.subCompanyZoneId = options.longs === String ? String(message.subCompanyZoneId) : message.subCompanyZoneId;
                    else
                        object.subCompanyZoneId = options.longs === String ? $util.Long.prototype.toString.call(message.subCompanyZoneId) : options.longs === Number ? new $util.LongBits(message.subCompanyZoneId.low >>> 0, message.subCompanyZoneId.high >>> 0).toNumber(true) : message.subCompanyZoneId;
                return object;
            };

            /**
             * Converts this DeleteSubCompanyZone to JSON.
             * @function toJSON
             * @memberof fastcity.admin.DeleteSubCompanyZone
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DeleteSubCompanyZone.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return DeleteSubCompanyZone;
        })();

        admin.RecoverSubCompanyZone = (function() {

            /**
             * Properties of a RecoverSubCompanyZone.
             * @memberof fastcity.admin
             * @interface IRecoverSubCompanyZone
             * @property {number|Long|null} [subCompanyZoneId] RecoverSubCompanyZone subCompanyZoneId
             */

            /**
             * Constructs a new RecoverSubCompanyZone.
             * @memberof fastcity.admin
             * @classdesc Represents a RecoverSubCompanyZone.
             * @implements IRecoverSubCompanyZone
             * @constructor
             * @param {fastcity.admin.IRecoverSubCompanyZone=} [properties] Properties to set
             */
            function RecoverSubCompanyZone(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * RecoverSubCompanyZone subCompanyZoneId.
             * @member {number|Long} subCompanyZoneId
             * @memberof fastcity.admin.RecoverSubCompanyZone
             * @instance
             */
            RecoverSubCompanyZone.prototype.subCompanyZoneId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Creates a new RecoverSubCompanyZone instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.RecoverSubCompanyZone
             * @static
             * @param {fastcity.admin.IRecoverSubCompanyZone=} [properties] Properties to set
             * @returns {fastcity.admin.RecoverSubCompanyZone} RecoverSubCompanyZone instance
             */
            RecoverSubCompanyZone.create = function create(properties) {
                return new RecoverSubCompanyZone(properties);
            };

            /**
             * Encodes the specified RecoverSubCompanyZone message. Does not implicitly {@link fastcity.admin.RecoverSubCompanyZone.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.RecoverSubCompanyZone
             * @static
             * @param {fastcity.admin.IRecoverSubCompanyZone} message RecoverSubCompanyZone message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RecoverSubCompanyZone.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.subCompanyZoneId != null && Object.hasOwnProperty.call(message, "subCompanyZoneId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.subCompanyZoneId);
                return writer;
            };

            /**
             * Decodes a RecoverSubCompanyZone message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.RecoverSubCompanyZone
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.RecoverSubCompanyZone} RecoverSubCompanyZone
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RecoverSubCompanyZone.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.RecoverSubCompanyZone();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.subCompanyZoneId = reader.uint64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a RecoverSubCompanyZone message.
             * @function verify
             * @memberof fastcity.admin.RecoverSubCompanyZone
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            RecoverSubCompanyZone.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.subCompanyZoneId != null && message.hasOwnProperty("subCompanyZoneId"))
                    if (!$util.isInteger(message.subCompanyZoneId) && !(message.subCompanyZoneId && $util.isInteger(message.subCompanyZoneId.low) && $util.isInteger(message.subCompanyZoneId.high)))
                        return "subCompanyZoneId: integer|Long expected";
                return null;
            };

            /**
             * Creates a RecoverSubCompanyZone message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.RecoverSubCompanyZone
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.RecoverSubCompanyZone} RecoverSubCompanyZone
             */
            RecoverSubCompanyZone.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.RecoverSubCompanyZone)
                    return object;
                var message = new $root.fastcity.admin.RecoverSubCompanyZone();
                if (object.subCompanyZoneId != null)
                    if ($util.Long)
                        (message.subCompanyZoneId = $util.Long.fromValue(object.subCompanyZoneId)).unsigned = true;
                    else if (typeof object.subCompanyZoneId === "string")
                        message.subCompanyZoneId = parseInt(object.subCompanyZoneId, 10);
                    else if (typeof object.subCompanyZoneId === "number")
                        message.subCompanyZoneId = object.subCompanyZoneId;
                    else if (typeof object.subCompanyZoneId === "object")
                        message.subCompanyZoneId = new $util.LongBits(object.subCompanyZoneId.low >>> 0, object.subCompanyZoneId.high >>> 0).toNumber(true);
                return message;
            };

            /**
             * Creates a plain object from a RecoverSubCompanyZone message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.RecoverSubCompanyZone
             * @static
             * @param {fastcity.admin.RecoverSubCompanyZone} message RecoverSubCompanyZone
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            RecoverSubCompanyZone.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.subCompanyZoneId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.subCompanyZoneId = options.longs === String ? "0" : 0;
                if (message.subCompanyZoneId != null && message.hasOwnProperty("subCompanyZoneId"))
                    if (typeof message.subCompanyZoneId === "number")
                        object.subCompanyZoneId = options.longs === String ? String(message.subCompanyZoneId) : message.subCompanyZoneId;
                    else
                        object.subCompanyZoneId = options.longs === String ? $util.Long.prototype.toString.call(message.subCompanyZoneId) : options.longs === Number ? new $util.LongBits(message.subCompanyZoneId.low >>> 0, message.subCompanyZoneId.high >>> 0).toNumber(true) : message.subCompanyZoneId;
                return object;
            };

            /**
             * Converts this RecoverSubCompanyZone to JSON.
             * @function toJSON
             * @memberof fastcity.admin.RecoverSubCompanyZone
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            RecoverSubCompanyZone.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return RecoverSubCompanyZone;
        })();

        admin.UpdateSubCompanyZone = (function() {

            /**
             * Properties of an UpdateSubCompanyZone.
             * @memberof fastcity.admin
             * @interface IUpdateSubCompanyZone
             * @property {number|Long|null} [subCompanyZoneId] UpdateSubCompanyZone subCompanyZoneId
             * @property {string|null} [name] UpdateSubCompanyZone name
             * @property {fastcity.admin.IPolygon|null} [polygon] UpdateSubCompanyZone polygon
             * @property {string|null} [color] UpdateSubCompanyZone color
             * @property {boolean|null} [isDisabled] UpdateSubCompanyZone isDisabled
             */

            /**
             * Constructs a new UpdateSubCompanyZone.
             * @memberof fastcity.admin
             * @classdesc Represents an UpdateSubCompanyZone.
             * @implements IUpdateSubCompanyZone
             * @constructor
             * @param {fastcity.admin.IUpdateSubCompanyZone=} [properties] Properties to set
             */
            function UpdateSubCompanyZone(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * UpdateSubCompanyZone subCompanyZoneId.
             * @member {number|Long} subCompanyZoneId
             * @memberof fastcity.admin.UpdateSubCompanyZone
             * @instance
             */
            UpdateSubCompanyZone.prototype.subCompanyZoneId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * UpdateSubCompanyZone name.
             * @member {string} name
             * @memberof fastcity.admin.UpdateSubCompanyZone
             * @instance
             */
            UpdateSubCompanyZone.prototype.name = "";

            /**
             * UpdateSubCompanyZone polygon.
             * @member {fastcity.admin.IPolygon|null|undefined} polygon
             * @memberof fastcity.admin.UpdateSubCompanyZone
             * @instance
             */
            UpdateSubCompanyZone.prototype.polygon = null;

            /**
             * UpdateSubCompanyZone color.
             * @member {string} color
             * @memberof fastcity.admin.UpdateSubCompanyZone
             * @instance
             */
            UpdateSubCompanyZone.prototype.color = "";

            /**
             * UpdateSubCompanyZone isDisabled.
             * @member {boolean} isDisabled
             * @memberof fastcity.admin.UpdateSubCompanyZone
             * @instance
             */
            UpdateSubCompanyZone.prototype.isDisabled = false;

            /**
             * Creates a new UpdateSubCompanyZone instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.UpdateSubCompanyZone
             * @static
             * @param {fastcity.admin.IUpdateSubCompanyZone=} [properties] Properties to set
             * @returns {fastcity.admin.UpdateSubCompanyZone} UpdateSubCompanyZone instance
             */
            UpdateSubCompanyZone.create = function create(properties) {
                return new UpdateSubCompanyZone(properties);
            };

            /**
             * Encodes the specified UpdateSubCompanyZone message. Does not implicitly {@link fastcity.admin.UpdateSubCompanyZone.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.UpdateSubCompanyZone
             * @static
             * @param {fastcity.admin.IUpdateSubCompanyZone} message UpdateSubCompanyZone message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateSubCompanyZone.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.subCompanyZoneId != null && Object.hasOwnProperty.call(message, "subCompanyZoneId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.subCompanyZoneId);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                if (message.polygon != null && Object.hasOwnProperty.call(message, "polygon"))
                    $root.fastcity.admin.Polygon.encode(message.polygon, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.color != null && Object.hasOwnProperty.call(message, "color"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.color);
                if (message.isDisabled != null && Object.hasOwnProperty.call(message, "isDisabled"))
                    writer.uint32(/* id 5, wireType 0 =*/40).bool(message.isDisabled);
                return writer;
            };

            /**
             * Decodes an UpdateSubCompanyZone message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.UpdateSubCompanyZone
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.UpdateSubCompanyZone} UpdateSubCompanyZone
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateSubCompanyZone.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.UpdateSubCompanyZone();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.subCompanyZoneId = reader.uint64();
                        break;
                    case 2:
                        message.name = reader.string();
                        break;
                    case 3:
                        message.polygon = $root.fastcity.admin.Polygon.decode(reader, reader.uint32());
                        break;
                    case 4:
                        message.color = reader.string();
                        break;
                    case 5:
                        message.isDisabled = reader.bool();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an UpdateSubCompanyZone message.
             * @function verify
             * @memberof fastcity.admin.UpdateSubCompanyZone
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            UpdateSubCompanyZone.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.subCompanyZoneId != null && message.hasOwnProperty("subCompanyZoneId"))
                    if (!$util.isInteger(message.subCompanyZoneId) && !(message.subCompanyZoneId && $util.isInteger(message.subCompanyZoneId.low) && $util.isInteger(message.subCompanyZoneId.high)))
                        return "subCompanyZoneId: integer|Long expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.polygon != null && message.hasOwnProperty("polygon")) {
                    var error = $root.fastcity.admin.Polygon.verify(message.polygon);
                    if (error)
                        return "polygon." + error;
                }
                if (message.color != null && message.hasOwnProperty("color"))
                    if (!$util.isString(message.color))
                        return "color: string expected";
                if (message.isDisabled != null && message.hasOwnProperty("isDisabled"))
                    if (typeof message.isDisabled !== "boolean")
                        return "isDisabled: boolean expected";
                return null;
            };

            /**
             * Creates an UpdateSubCompanyZone message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.UpdateSubCompanyZone
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.UpdateSubCompanyZone} UpdateSubCompanyZone
             */
            UpdateSubCompanyZone.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.UpdateSubCompanyZone)
                    return object;
                var message = new $root.fastcity.admin.UpdateSubCompanyZone();
                if (object.subCompanyZoneId != null)
                    if ($util.Long)
                        (message.subCompanyZoneId = $util.Long.fromValue(object.subCompanyZoneId)).unsigned = true;
                    else if (typeof object.subCompanyZoneId === "string")
                        message.subCompanyZoneId = parseInt(object.subCompanyZoneId, 10);
                    else if (typeof object.subCompanyZoneId === "number")
                        message.subCompanyZoneId = object.subCompanyZoneId;
                    else if (typeof object.subCompanyZoneId === "object")
                        message.subCompanyZoneId = new $util.LongBits(object.subCompanyZoneId.low >>> 0, object.subCompanyZoneId.high >>> 0).toNumber(true);
                if (object.name != null)
                    message.name = String(object.name);
                if (object.polygon != null) {
                    if (typeof object.polygon !== "object")
                        throw TypeError(".fastcity.admin.UpdateSubCompanyZone.polygon: object expected");
                    message.polygon = $root.fastcity.admin.Polygon.fromObject(object.polygon);
                }
                if (object.color != null)
                    message.color = String(object.color);
                if (object.isDisabled != null)
                    message.isDisabled = Boolean(object.isDisabled);
                return message;
            };

            /**
             * Creates a plain object from an UpdateSubCompanyZone message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.UpdateSubCompanyZone
             * @static
             * @param {fastcity.admin.UpdateSubCompanyZone} message UpdateSubCompanyZone
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UpdateSubCompanyZone.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.subCompanyZoneId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.subCompanyZoneId = options.longs === String ? "0" : 0;
                    object.name = "";
                    object.polygon = null;
                    object.color = "";
                    object.isDisabled = false;
                }
                if (message.subCompanyZoneId != null && message.hasOwnProperty("subCompanyZoneId"))
                    if (typeof message.subCompanyZoneId === "number")
                        object.subCompanyZoneId = options.longs === String ? String(message.subCompanyZoneId) : message.subCompanyZoneId;
                    else
                        object.subCompanyZoneId = options.longs === String ? $util.Long.prototype.toString.call(message.subCompanyZoneId) : options.longs === Number ? new $util.LongBits(message.subCompanyZoneId.low >>> 0, message.subCompanyZoneId.high >>> 0).toNumber(true) : message.subCompanyZoneId;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.polygon != null && message.hasOwnProperty("polygon"))
                    object.polygon = $root.fastcity.admin.Polygon.toObject(message.polygon, options);
                if (message.color != null && message.hasOwnProperty("color"))
                    object.color = message.color;
                if (message.isDisabled != null && message.hasOwnProperty("isDisabled"))
                    object.isDisabled = message.isDisabled;
                return object;
            };

            /**
             * Converts this UpdateSubCompanyZone to JSON.
             * @function toJSON
             * @memberof fastcity.admin.UpdateSubCompanyZone
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UpdateSubCompanyZone.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return UpdateSubCompanyZone;
        })();

        admin.UpdateSubCompanyZoneUsingKML = (function() {

            /**
             * Properties of an UpdateSubCompanyZoneUsingKML.
             * @memberof fastcity.admin
             * @interface IUpdateSubCompanyZoneUsingKML
             * @property {number|Long|null} [subCompanyZoneId] UpdateSubCompanyZoneUsingKML subCompanyZoneId
             * @property {string|null} [name] UpdateSubCompanyZoneUsingKML name
             * @property {string|null} [kmlFileBody] UpdateSubCompanyZoneUsingKML kmlFileBody
             * @property {boolean|null} [latitudeLongitudeSwap] UpdateSubCompanyZoneUsingKML latitudeLongitudeSwap
             * @property {string|null} [color] UpdateSubCompanyZoneUsingKML color
             * @property {boolean|null} [isDisabled] UpdateSubCompanyZoneUsingKML isDisabled
             */

            /**
             * Constructs a new UpdateSubCompanyZoneUsingKML.
             * @memberof fastcity.admin
             * @classdesc Represents an UpdateSubCompanyZoneUsingKML.
             * @implements IUpdateSubCompanyZoneUsingKML
             * @constructor
             * @param {fastcity.admin.IUpdateSubCompanyZoneUsingKML=} [properties] Properties to set
             */
            function UpdateSubCompanyZoneUsingKML(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * UpdateSubCompanyZoneUsingKML subCompanyZoneId.
             * @member {number|Long} subCompanyZoneId
             * @memberof fastcity.admin.UpdateSubCompanyZoneUsingKML
             * @instance
             */
            UpdateSubCompanyZoneUsingKML.prototype.subCompanyZoneId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * UpdateSubCompanyZoneUsingKML name.
             * @member {string} name
             * @memberof fastcity.admin.UpdateSubCompanyZoneUsingKML
             * @instance
             */
            UpdateSubCompanyZoneUsingKML.prototype.name = "";

            /**
             * UpdateSubCompanyZoneUsingKML kmlFileBody.
             * @member {string} kmlFileBody
             * @memberof fastcity.admin.UpdateSubCompanyZoneUsingKML
             * @instance
             */
            UpdateSubCompanyZoneUsingKML.prototype.kmlFileBody = "";

            /**
             * UpdateSubCompanyZoneUsingKML latitudeLongitudeSwap.
             * @member {boolean} latitudeLongitudeSwap
             * @memberof fastcity.admin.UpdateSubCompanyZoneUsingKML
             * @instance
             */
            UpdateSubCompanyZoneUsingKML.prototype.latitudeLongitudeSwap = false;

            /**
             * UpdateSubCompanyZoneUsingKML color.
             * @member {string} color
             * @memberof fastcity.admin.UpdateSubCompanyZoneUsingKML
             * @instance
             */
            UpdateSubCompanyZoneUsingKML.prototype.color = "";

            /**
             * UpdateSubCompanyZoneUsingKML isDisabled.
             * @member {boolean} isDisabled
             * @memberof fastcity.admin.UpdateSubCompanyZoneUsingKML
             * @instance
             */
            UpdateSubCompanyZoneUsingKML.prototype.isDisabled = false;

            /**
             * Creates a new UpdateSubCompanyZoneUsingKML instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.UpdateSubCompanyZoneUsingKML
             * @static
             * @param {fastcity.admin.IUpdateSubCompanyZoneUsingKML=} [properties] Properties to set
             * @returns {fastcity.admin.UpdateSubCompanyZoneUsingKML} UpdateSubCompanyZoneUsingKML instance
             */
            UpdateSubCompanyZoneUsingKML.create = function create(properties) {
                return new UpdateSubCompanyZoneUsingKML(properties);
            };

            /**
             * Encodes the specified UpdateSubCompanyZoneUsingKML message. Does not implicitly {@link fastcity.admin.UpdateSubCompanyZoneUsingKML.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.UpdateSubCompanyZoneUsingKML
             * @static
             * @param {fastcity.admin.IUpdateSubCompanyZoneUsingKML} message UpdateSubCompanyZoneUsingKML message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateSubCompanyZoneUsingKML.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.subCompanyZoneId != null && Object.hasOwnProperty.call(message, "subCompanyZoneId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.subCompanyZoneId);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                if (message.kmlFileBody != null && Object.hasOwnProperty.call(message, "kmlFileBody"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.kmlFileBody);
                if (message.latitudeLongitudeSwap != null && Object.hasOwnProperty.call(message, "latitudeLongitudeSwap"))
                    writer.uint32(/* id 4, wireType 0 =*/32).bool(message.latitudeLongitudeSwap);
                if (message.color != null && Object.hasOwnProperty.call(message, "color"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.color);
                if (message.isDisabled != null && Object.hasOwnProperty.call(message, "isDisabled"))
                    writer.uint32(/* id 6, wireType 0 =*/48).bool(message.isDisabled);
                return writer;
            };

            /**
             * Decodes an UpdateSubCompanyZoneUsingKML message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.UpdateSubCompanyZoneUsingKML
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.UpdateSubCompanyZoneUsingKML} UpdateSubCompanyZoneUsingKML
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateSubCompanyZoneUsingKML.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.UpdateSubCompanyZoneUsingKML();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.subCompanyZoneId = reader.uint64();
                        break;
                    case 2:
                        message.name = reader.string();
                        break;
                    case 3:
                        message.kmlFileBody = reader.string();
                        break;
                    case 4:
                        message.latitudeLongitudeSwap = reader.bool();
                        break;
                    case 5:
                        message.color = reader.string();
                        break;
                    case 6:
                        message.isDisabled = reader.bool();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an UpdateSubCompanyZoneUsingKML message.
             * @function verify
             * @memberof fastcity.admin.UpdateSubCompanyZoneUsingKML
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            UpdateSubCompanyZoneUsingKML.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.subCompanyZoneId != null && message.hasOwnProperty("subCompanyZoneId"))
                    if (!$util.isInteger(message.subCompanyZoneId) && !(message.subCompanyZoneId && $util.isInteger(message.subCompanyZoneId.low) && $util.isInteger(message.subCompanyZoneId.high)))
                        return "subCompanyZoneId: integer|Long expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.kmlFileBody != null && message.hasOwnProperty("kmlFileBody"))
                    if (!$util.isString(message.kmlFileBody))
                        return "kmlFileBody: string expected";
                if (message.latitudeLongitudeSwap != null && message.hasOwnProperty("latitudeLongitudeSwap"))
                    if (typeof message.latitudeLongitudeSwap !== "boolean")
                        return "latitudeLongitudeSwap: boolean expected";
                if (message.color != null && message.hasOwnProperty("color"))
                    if (!$util.isString(message.color))
                        return "color: string expected";
                if (message.isDisabled != null && message.hasOwnProperty("isDisabled"))
                    if (typeof message.isDisabled !== "boolean")
                        return "isDisabled: boolean expected";
                return null;
            };

            /**
             * Creates an UpdateSubCompanyZoneUsingKML message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.UpdateSubCompanyZoneUsingKML
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.UpdateSubCompanyZoneUsingKML} UpdateSubCompanyZoneUsingKML
             */
            UpdateSubCompanyZoneUsingKML.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.UpdateSubCompanyZoneUsingKML)
                    return object;
                var message = new $root.fastcity.admin.UpdateSubCompanyZoneUsingKML();
                if (object.subCompanyZoneId != null)
                    if ($util.Long)
                        (message.subCompanyZoneId = $util.Long.fromValue(object.subCompanyZoneId)).unsigned = true;
                    else if (typeof object.subCompanyZoneId === "string")
                        message.subCompanyZoneId = parseInt(object.subCompanyZoneId, 10);
                    else if (typeof object.subCompanyZoneId === "number")
                        message.subCompanyZoneId = object.subCompanyZoneId;
                    else if (typeof object.subCompanyZoneId === "object")
                        message.subCompanyZoneId = new $util.LongBits(object.subCompanyZoneId.low >>> 0, object.subCompanyZoneId.high >>> 0).toNumber(true);
                if (object.name != null)
                    message.name = String(object.name);
                if (object.kmlFileBody != null)
                    message.kmlFileBody = String(object.kmlFileBody);
                if (object.latitudeLongitudeSwap != null)
                    message.latitudeLongitudeSwap = Boolean(object.latitudeLongitudeSwap);
                if (object.color != null)
                    message.color = String(object.color);
                if (object.isDisabled != null)
                    message.isDisabled = Boolean(object.isDisabled);
                return message;
            };

            /**
             * Creates a plain object from an UpdateSubCompanyZoneUsingKML message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.UpdateSubCompanyZoneUsingKML
             * @static
             * @param {fastcity.admin.UpdateSubCompanyZoneUsingKML} message UpdateSubCompanyZoneUsingKML
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UpdateSubCompanyZoneUsingKML.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.subCompanyZoneId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.subCompanyZoneId = options.longs === String ? "0" : 0;
                    object.name = "";
                    object.kmlFileBody = "";
                    object.latitudeLongitudeSwap = false;
                    object.color = "";
                    object.isDisabled = false;
                }
                if (message.subCompanyZoneId != null && message.hasOwnProperty("subCompanyZoneId"))
                    if (typeof message.subCompanyZoneId === "number")
                        object.subCompanyZoneId = options.longs === String ? String(message.subCompanyZoneId) : message.subCompanyZoneId;
                    else
                        object.subCompanyZoneId = options.longs === String ? $util.Long.prototype.toString.call(message.subCompanyZoneId) : options.longs === Number ? new $util.LongBits(message.subCompanyZoneId.low >>> 0, message.subCompanyZoneId.high >>> 0).toNumber(true) : message.subCompanyZoneId;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.kmlFileBody != null && message.hasOwnProperty("kmlFileBody"))
                    object.kmlFileBody = message.kmlFileBody;
                if (message.latitudeLongitudeSwap != null && message.hasOwnProperty("latitudeLongitudeSwap"))
                    object.latitudeLongitudeSwap = message.latitudeLongitudeSwap;
                if (message.color != null && message.hasOwnProperty("color"))
                    object.color = message.color;
                if (message.isDisabled != null && message.hasOwnProperty("isDisabled"))
                    object.isDisabled = message.isDisabled;
                return object;
            };

            /**
             * Converts this UpdateSubCompanyZoneUsingKML to JSON.
             * @function toJSON
             * @memberof fastcity.admin.UpdateSubCompanyZoneUsingKML
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UpdateSubCompanyZoneUsingKML.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return UpdateSubCompanyZoneUsingKML;
        })();

        admin.GetSubCompanyZone = (function() {

            /**
             * Properties of a GetSubCompanyZone.
             * @memberof fastcity.admin
             * @interface IGetSubCompanyZone
             * @property {number|Long|null} [subCompanyZoneId] GetSubCompanyZone subCompanyZoneId
             */

            /**
             * Constructs a new GetSubCompanyZone.
             * @memberof fastcity.admin
             * @classdesc Represents a GetSubCompanyZone.
             * @implements IGetSubCompanyZone
             * @constructor
             * @param {fastcity.admin.IGetSubCompanyZone=} [properties] Properties to set
             */
            function GetSubCompanyZone(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GetSubCompanyZone subCompanyZoneId.
             * @member {number|Long} subCompanyZoneId
             * @memberof fastcity.admin.GetSubCompanyZone
             * @instance
             */
            GetSubCompanyZone.prototype.subCompanyZoneId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Creates a new GetSubCompanyZone instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.GetSubCompanyZone
             * @static
             * @param {fastcity.admin.IGetSubCompanyZone=} [properties] Properties to set
             * @returns {fastcity.admin.GetSubCompanyZone} GetSubCompanyZone instance
             */
            GetSubCompanyZone.create = function create(properties) {
                return new GetSubCompanyZone(properties);
            };

            /**
             * Encodes the specified GetSubCompanyZone message. Does not implicitly {@link fastcity.admin.GetSubCompanyZone.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.GetSubCompanyZone
             * @static
             * @param {fastcity.admin.IGetSubCompanyZone} message GetSubCompanyZone message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetSubCompanyZone.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.subCompanyZoneId != null && Object.hasOwnProperty.call(message, "subCompanyZoneId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.subCompanyZoneId);
                return writer;
            };

            /**
             * Decodes a GetSubCompanyZone message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.GetSubCompanyZone
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.GetSubCompanyZone} GetSubCompanyZone
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetSubCompanyZone.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.GetSubCompanyZone();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.subCompanyZoneId = reader.uint64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a GetSubCompanyZone message.
             * @function verify
             * @memberof fastcity.admin.GetSubCompanyZone
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetSubCompanyZone.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.subCompanyZoneId != null && message.hasOwnProperty("subCompanyZoneId"))
                    if (!$util.isInteger(message.subCompanyZoneId) && !(message.subCompanyZoneId && $util.isInteger(message.subCompanyZoneId.low) && $util.isInteger(message.subCompanyZoneId.high)))
                        return "subCompanyZoneId: integer|Long expected";
                return null;
            };

            /**
             * Creates a GetSubCompanyZone message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.GetSubCompanyZone
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.GetSubCompanyZone} GetSubCompanyZone
             */
            GetSubCompanyZone.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.GetSubCompanyZone)
                    return object;
                var message = new $root.fastcity.admin.GetSubCompanyZone();
                if (object.subCompanyZoneId != null)
                    if ($util.Long)
                        (message.subCompanyZoneId = $util.Long.fromValue(object.subCompanyZoneId)).unsigned = true;
                    else if (typeof object.subCompanyZoneId === "string")
                        message.subCompanyZoneId = parseInt(object.subCompanyZoneId, 10);
                    else if (typeof object.subCompanyZoneId === "number")
                        message.subCompanyZoneId = object.subCompanyZoneId;
                    else if (typeof object.subCompanyZoneId === "object")
                        message.subCompanyZoneId = new $util.LongBits(object.subCompanyZoneId.low >>> 0, object.subCompanyZoneId.high >>> 0).toNumber(true);
                return message;
            };

            /**
             * Creates a plain object from a GetSubCompanyZone message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.GetSubCompanyZone
             * @static
             * @param {fastcity.admin.GetSubCompanyZone} message GetSubCompanyZone
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetSubCompanyZone.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.subCompanyZoneId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.subCompanyZoneId = options.longs === String ? "0" : 0;
                if (message.subCompanyZoneId != null && message.hasOwnProperty("subCompanyZoneId"))
                    if (typeof message.subCompanyZoneId === "number")
                        object.subCompanyZoneId = options.longs === String ? String(message.subCompanyZoneId) : message.subCompanyZoneId;
                    else
                        object.subCompanyZoneId = options.longs === String ? $util.Long.prototype.toString.call(message.subCompanyZoneId) : options.longs === Number ? new $util.LongBits(message.subCompanyZoneId.low >>> 0, message.subCompanyZoneId.high >>> 0).toNumber(true) : message.subCompanyZoneId;
                return object;
            };

            /**
             * Converts this GetSubCompanyZone to JSON.
             * @function toJSON
             * @memberof fastcity.admin.GetSubCompanyZone
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetSubCompanyZone.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return GetSubCompanyZone;
        })();

        admin.GetSubCompanyZoneList = (function() {

            /**
             * Properties of a GetSubCompanyZoneList.
             * @memberof fastcity.admin
             * @interface IGetSubCompanyZoneList
             * @property {number|Long|null} [companyId] GetSubCompanyZoneList companyId
             * @property {number|Long|null} [subCompanyId] GetSubCompanyZoneList subCompanyId
             * @property {boolean|null} [withDeleted] GetSubCompanyZoneList withDeleted
             */

            /**
             * Constructs a new GetSubCompanyZoneList.
             * @memberof fastcity.admin
             * @classdesc Represents a GetSubCompanyZoneList.
             * @implements IGetSubCompanyZoneList
             * @constructor
             * @param {fastcity.admin.IGetSubCompanyZoneList=} [properties] Properties to set
             */
            function GetSubCompanyZoneList(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GetSubCompanyZoneList companyId.
             * @member {number|Long} companyId
             * @memberof fastcity.admin.GetSubCompanyZoneList
             * @instance
             */
            GetSubCompanyZoneList.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * GetSubCompanyZoneList subCompanyId.
             * @member {number|Long} subCompanyId
             * @memberof fastcity.admin.GetSubCompanyZoneList
             * @instance
             */
            GetSubCompanyZoneList.prototype.subCompanyId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * GetSubCompanyZoneList withDeleted.
             * @member {boolean} withDeleted
             * @memberof fastcity.admin.GetSubCompanyZoneList
             * @instance
             */
            GetSubCompanyZoneList.prototype.withDeleted = false;

            /**
             * Creates a new GetSubCompanyZoneList instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.GetSubCompanyZoneList
             * @static
             * @param {fastcity.admin.IGetSubCompanyZoneList=} [properties] Properties to set
             * @returns {fastcity.admin.GetSubCompanyZoneList} GetSubCompanyZoneList instance
             */
            GetSubCompanyZoneList.create = function create(properties) {
                return new GetSubCompanyZoneList(properties);
            };

            /**
             * Encodes the specified GetSubCompanyZoneList message. Does not implicitly {@link fastcity.admin.GetSubCompanyZoneList.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.GetSubCompanyZoneList
             * @static
             * @param {fastcity.admin.IGetSubCompanyZoneList} message GetSubCompanyZoneList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetSubCompanyZoneList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.companyId);
                if (message.subCompanyId != null && Object.hasOwnProperty.call(message, "subCompanyId"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.subCompanyId);
                if (message.withDeleted != null && Object.hasOwnProperty.call(message, "withDeleted"))
                    writer.uint32(/* id 3, wireType 0 =*/24).bool(message.withDeleted);
                return writer;
            };

            /**
             * Decodes a GetSubCompanyZoneList message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.GetSubCompanyZoneList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.GetSubCompanyZoneList} GetSubCompanyZoneList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetSubCompanyZoneList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.GetSubCompanyZoneList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.companyId = reader.uint64();
                        break;
                    case 2:
                        message.subCompanyId = reader.uint64();
                        break;
                    case 3:
                        message.withDeleted = reader.bool();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a GetSubCompanyZoneList message.
             * @function verify
             * @memberof fastcity.admin.GetSubCompanyZoneList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetSubCompanyZoneList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                        return "companyId: integer|Long expected";
                if (message.subCompanyId != null && message.hasOwnProperty("subCompanyId"))
                    if (!$util.isInteger(message.subCompanyId) && !(message.subCompanyId && $util.isInteger(message.subCompanyId.low) && $util.isInteger(message.subCompanyId.high)))
                        return "subCompanyId: integer|Long expected";
                if (message.withDeleted != null && message.hasOwnProperty("withDeleted"))
                    if (typeof message.withDeleted !== "boolean")
                        return "withDeleted: boolean expected";
                return null;
            };

            /**
             * Creates a GetSubCompanyZoneList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.GetSubCompanyZoneList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.GetSubCompanyZoneList} GetSubCompanyZoneList
             */
            GetSubCompanyZoneList.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.GetSubCompanyZoneList)
                    return object;
                var message = new $root.fastcity.admin.GetSubCompanyZoneList();
                if (object.companyId != null)
                    if ($util.Long)
                        (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = true;
                    else if (typeof object.companyId === "string")
                        message.companyId = parseInt(object.companyId, 10);
                    else if (typeof object.companyId === "number")
                        message.companyId = object.companyId;
                    else if (typeof object.companyId === "object")
                        message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber(true);
                if (object.subCompanyId != null)
                    if ($util.Long)
                        (message.subCompanyId = $util.Long.fromValue(object.subCompanyId)).unsigned = true;
                    else if (typeof object.subCompanyId === "string")
                        message.subCompanyId = parseInt(object.subCompanyId, 10);
                    else if (typeof object.subCompanyId === "number")
                        message.subCompanyId = object.subCompanyId;
                    else if (typeof object.subCompanyId === "object")
                        message.subCompanyId = new $util.LongBits(object.subCompanyId.low >>> 0, object.subCompanyId.high >>> 0).toNumber(true);
                if (object.withDeleted != null)
                    message.withDeleted = Boolean(object.withDeleted);
                return message;
            };

            /**
             * Creates a plain object from a GetSubCompanyZoneList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.GetSubCompanyZoneList
             * @static
             * @param {fastcity.admin.GetSubCompanyZoneList} message GetSubCompanyZoneList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetSubCompanyZoneList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.companyId = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.subCompanyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.subCompanyId = options.longs === String ? "0" : 0;
                    object.withDeleted = false;
                }
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (typeof message.companyId === "number")
                        object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                    else
                        object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber(true) : message.companyId;
                if (message.subCompanyId != null && message.hasOwnProperty("subCompanyId"))
                    if (typeof message.subCompanyId === "number")
                        object.subCompanyId = options.longs === String ? String(message.subCompanyId) : message.subCompanyId;
                    else
                        object.subCompanyId = options.longs === String ? $util.Long.prototype.toString.call(message.subCompanyId) : options.longs === Number ? new $util.LongBits(message.subCompanyId.low >>> 0, message.subCompanyId.high >>> 0).toNumber(true) : message.subCompanyId;
                if (message.withDeleted != null && message.hasOwnProperty("withDeleted"))
                    object.withDeleted = message.withDeleted;
                return object;
            };

            /**
             * Converts this GetSubCompanyZoneList to JSON.
             * @function toJSON
             * @memberof fastcity.admin.GetSubCompanyZoneList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetSubCompanyZoneList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return GetSubCompanyZoneList;
        })();

        admin.LogConfig = (function() {

            /**
             * Properties of a LogConfig.
             * @memberof fastcity.admin
             * @interface ILogConfig
             * @property {fastcity.admin.LogRecord.LogLevel|null} [logLevel] LogConfig logLevel
             * @property {number|Long|null} [deleteLogsOlderThenDays] LogConfig deleteLogsOlderThenDays
             * @property {number|Long|null} [archiveLogsOlderThenDays] LogConfig archiveLogsOlderThenDays
             * @property {number|Long|null} [deleteLogsIfLogsLargerThanInMb] LogConfig deleteLogsIfLogsLargerThanInMb
             * @property {number|Long|null} [archiveLogsIfLogsLargerThanInMb] LogConfig archiveLogsIfLogsLargerThanInMb
             * @property {number|Long|null} [archiveLogsBufferInMb] LogConfig archiveLogsBufferInMb
             */

            /**
             * Constructs a new LogConfig.
             * @memberof fastcity.admin
             * @classdesc Represents a LogConfig.
             * @implements ILogConfig
             * @constructor
             * @param {fastcity.admin.ILogConfig=} [properties] Properties to set
             */
            function LogConfig(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * LogConfig logLevel.
             * @member {fastcity.admin.LogRecord.LogLevel} logLevel
             * @memberof fastcity.admin.LogConfig
             * @instance
             */
            LogConfig.prototype.logLevel = 0;

            /**
             * LogConfig deleteLogsOlderThenDays.
             * @member {number|Long} deleteLogsOlderThenDays
             * @memberof fastcity.admin.LogConfig
             * @instance
             */
            LogConfig.prototype.deleteLogsOlderThenDays = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * LogConfig archiveLogsOlderThenDays.
             * @member {number|Long} archiveLogsOlderThenDays
             * @memberof fastcity.admin.LogConfig
             * @instance
             */
            LogConfig.prototype.archiveLogsOlderThenDays = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * LogConfig deleteLogsIfLogsLargerThanInMb.
             * @member {number|Long} deleteLogsIfLogsLargerThanInMb
             * @memberof fastcity.admin.LogConfig
             * @instance
             */
            LogConfig.prototype.deleteLogsIfLogsLargerThanInMb = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * LogConfig archiveLogsIfLogsLargerThanInMb.
             * @member {number|Long} archiveLogsIfLogsLargerThanInMb
             * @memberof fastcity.admin.LogConfig
             * @instance
             */
            LogConfig.prototype.archiveLogsIfLogsLargerThanInMb = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * LogConfig archiveLogsBufferInMb.
             * @member {number|Long} archiveLogsBufferInMb
             * @memberof fastcity.admin.LogConfig
             * @instance
             */
            LogConfig.prototype.archiveLogsBufferInMb = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Creates a new LogConfig instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.LogConfig
             * @static
             * @param {fastcity.admin.ILogConfig=} [properties] Properties to set
             * @returns {fastcity.admin.LogConfig} LogConfig instance
             */
            LogConfig.create = function create(properties) {
                return new LogConfig(properties);
            };

            /**
             * Encodes the specified LogConfig message. Does not implicitly {@link fastcity.admin.LogConfig.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.LogConfig
             * @static
             * @param {fastcity.admin.ILogConfig} message LogConfig message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            LogConfig.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.logLevel != null && Object.hasOwnProperty.call(message, "logLevel"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.logLevel);
                if (message.deleteLogsOlderThenDays != null && Object.hasOwnProperty.call(message, "deleteLogsOlderThenDays"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.deleteLogsOlderThenDays);
                if (message.archiveLogsOlderThenDays != null && Object.hasOwnProperty.call(message, "archiveLogsOlderThenDays"))
                    writer.uint32(/* id 3, wireType 0 =*/24).uint64(message.archiveLogsOlderThenDays);
                if (message.deleteLogsIfLogsLargerThanInMb != null && Object.hasOwnProperty.call(message, "deleteLogsIfLogsLargerThanInMb"))
                    writer.uint32(/* id 4, wireType 0 =*/32).uint64(message.deleteLogsIfLogsLargerThanInMb);
                if (message.archiveLogsIfLogsLargerThanInMb != null && Object.hasOwnProperty.call(message, "archiveLogsIfLogsLargerThanInMb"))
                    writer.uint32(/* id 5, wireType 0 =*/40).uint64(message.archiveLogsIfLogsLargerThanInMb);
                if (message.archiveLogsBufferInMb != null && Object.hasOwnProperty.call(message, "archiveLogsBufferInMb"))
                    writer.uint32(/* id 6, wireType 0 =*/48).uint64(message.archiveLogsBufferInMb);
                return writer;
            };

            /**
             * Decodes a LogConfig message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.LogConfig
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.LogConfig} LogConfig
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            LogConfig.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.LogConfig();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.logLevel = reader.int32();
                        break;
                    case 2:
                        message.deleteLogsOlderThenDays = reader.uint64();
                        break;
                    case 3:
                        message.archiveLogsOlderThenDays = reader.uint64();
                        break;
                    case 4:
                        message.deleteLogsIfLogsLargerThanInMb = reader.uint64();
                        break;
                    case 5:
                        message.archiveLogsIfLogsLargerThanInMb = reader.uint64();
                        break;
                    case 6:
                        message.archiveLogsBufferInMb = reader.uint64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a LogConfig message.
             * @function verify
             * @memberof fastcity.admin.LogConfig
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            LogConfig.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.logLevel != null && message.hasOwnProperty("logLevel"))
                    switch (message.logLevel) {
                    default:
                        return "logLevel: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
                if (message.deleteLogsOlderThenDays != null && message.hasOwnProperty("deleteLogsOlderThenDays"))
                    if (!$util.isInteger(message.deleteLogsOlderThenDays) && !(message.deleteLogsOlderThenDays && $util.isInteger(message.deleteLogsOlderThenDays.low) && $util.isInteger(message.deleteLogsOlderThenDays.high)))
                        return "deleteLogsOlderThenDays: integer|Long expected";
                if (message.archiveLogsOlderThenDays != null && message.hasOwnProperty("archiveLogsOlderThenDays"))
                    if (!$util.isInteger(message.archiveLogsOlderThenDays) && !(message.archiveLogsOlderThenDays && $util.isInteger(message.archiveLogsOlderThenDays.low) && $util.isInteger(message.archiveLogsOlderThenDays.high)))
                        return "archiveLogsOlderThenDays: integer|Long expected";
                if (message.deleteLogsIfLogsLargerThanInMb != null && message.hasOwnProperty("deleteLogsIfLogsLargerThanInMb"))
                    if (!$util.isInteger(message.deleteLogsIfLogsLargerThanInMb) && !(message.deleteLogsIfLogsLargerThanInMb && $util.isInteger(message.deleteLogsIfLogsLargerThanInMb.low) && $util.isInteger(message.deleteLogsIfLogsLargerThanInMb.high)))
                        return "deleteLogsIfLogsLargerThanInMb: integer|Long expected";
                if (message.archiveLogsIfLogsLargerThanInMb != null && message.hasOwnProperty("archiveLogsIfLogsLargerThanInMb"))
                    if (!$util.isInteger(message.archiveLogsIfLogsLargerThanInMb) && !(message.archiveLogsIfLogsLargerThanInMb && $util.isInteger(message.archiveLogsIfLogsLargerThanInMb.low) && $util.isInteger(message.archiveLogsIfLogsLargerThanInMb.high)))
                        return "archiveLogsIfLogsLargerThanInMb: integer|Long expected";
                if (message.archiveLogsBufferInMb != null && message.hasOwnProperty("archiveLogsBufferInMb"))
                    if (!$util.isInteger(message.archiveLogsBufferInMb) && !(message.archiveLogsBufferInMb && $util.isInteger(message.archiveLogsBufferInMb.low) && $util.isInteger(message.archiveLogsBufferInMb.high)))
                        return "archiveLogsBufferInMb: integer|Long expected";
                return null;
            };

            /**
             * Creates a LogConfig message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.LogConfig
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.LogConfig} LogConfig
             */
            LogConfig.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.LogConfig)
                    return object;
                var message = new $root.fastcity.admin.LogConfig();
                switch (object.logLevel) {
                case "LL_ERROR":
                case 0:
                    message.logLevel = 0;
                    break;
                case "LL_INFO":
                case 1:
                    message.logLevel = 1;
                    break;
                case "LL_DEBUG":
                case 2:
                    message.logLevel = 2;
                    break;
                case "LL_TRACE":
                case 3:
                    message.logLevel = 3;
                    break;
                }
                if (object.deleteLogsOlderThenDays != null)
                    if ($util.Long)
                        (message.deleteLogsOlderThenDays = $util.Long.fromValue(object.deleteLogsOlderThenDays)).unsigned = true;
                    else if (typeof object.deleteLogsOlderThenDays === "string")
                        message.deleteLogsOlderThenDays = parseInt(object.deleteLogsOlderThenDays, 10);
                    else if (typeof object.deleteLogsOlderThenDays === "number")
                        message.deleteLogsOlderThenDays = object.deleteLogsOlderThenDays;
                    else if (typeof object.deleteLogsOlderThenDays === "object")
                        message.deleteLogsOlderThenDays = new $util.LongBits(object.deleteLogsOlderThenDays.low >>> 0, object.deleteLogsOlderThenDays.high >>> 0).toNumber(true);
                if (object.archiveLogsOlderThenDays != null)
                    if ($util.Long)
                        (message.archiveLogsOlderThenDays = $util.Long.fromValue(object.archiveLogsOlderThenDays)).unsigned = true;
                    else if (typeof object.archiveLogsOlderThenDays === "string")
                        message.archiveLogsOlderThenDays = parseInt(object.archiveLogsOlderThenDays, 10);
                    else if (typeof object.archiveLogsOlderThenDays === "number")
                        message.archiveLogsOlderThenDays = object.archiveLogsOlderThenDays;
                    else if (typeof object.archiveLogsOlderThenDays === "object")
                        message.archiveLogsOlderThenDays = new $util.LongBits(object.archiveLogsOlderThenDays.low >>> 0, object.archiveLogsOlderThenDays.high >>> 0).toNumber(true);
                if (object.deleteLogsIfLogsLargerThanInMb != null)
                    if ($util.Long)
                        (message.deleteLogsIfLogsLargerThanInMb = $util.Long.fromValue(object.deleteLogsIfLogsLargerThanInMb)).unsigned = true;
                    else if (typeof object.deleteLogsIfLogsLargerThanInMb === "string")
                        message.deleteLogsIfLogsLargerThanInMb = parseInt(object.deleteLogsIfLogsLargerThanInMb, 10);
                    else if (typeof object.deleteLogsIfLogsLargerThanInMb === "number")
                        message.deleteLogsIfLogsLargerThanInMb = object.deleteLogsIfLogsLargerThanInMb;
                    else if (typeof object.deleteLogsIfLogsLargerThanInMb === "object")
                        message.deleteLogsIfLogsLargerThanInMb = new $util.LongBits(object.deleteLogsIfLogsLargerThanInMb.low >>> 0, object.deleteLogsIfLogsLargerThanInMb.high >>> 0).toNumber(true);
                if (object.archiveLogsIfLogsLargerThanInMb != null)
                    if ($util.Long)
                        (message.archiveLogsIfLogsLargerThanInMb = $util.Long.fromValue(object.archiveLogsIfLogsLargerThanInMb)).unsigned = true;
                    else if (typeof object.archiveLogsIfLogsLargerThanInMb === "string")
                        message.archiveLogsIfLogsLargerThanInMb = parseInt(object.archiveLogsIfLogsLargerThanInMb, 10);
                    else if (typeof object.archiveLogsIfLogsLargerThanInMb === "number")
                        message.archiveLogsIfLogsLargerThanInMb = object.archiveLogsIfLogsLargerThanInMb;
                    else if (typeof object.archiveLogsIfLogsLargerThanInMb === "object")
                        message.archiveLogsIfLogsLargerThanInMb = new $util.LongBits(object.archiveLogsIfLogsLargerThanInMb.low >>> 0, object.archiveLogsIfLogsLargerThanInMb.high >>> 0).toNumber(true);
                if (object.archiveLogsBufferInMb != null)
                    if ($util.Long)
                        (message.archiveLogsBufferInMb = $util.Long.fromValue(object.archiveLogsBufferInMb)).unsigned = true;
                    else if (typeof object.archiveLogsBufferInMb === "string")
                        message.archiveLogsBufferInMb = parseInt(object.archiveLogsBufferInMb, 10);
                    else if (typeof object.archiveLogsBufferInMb === "number")
                        message.archiveLogsBufferInMb = object.archiveLogsBufferInMb;
                    else if (typeof object.archiveLogsBufferInMb === "object")
                        message.archiveLogsBufferInMb = new $util.LongBits(object.archiveLogsBufferInMb.low >>> 0, object.archiveLogsBufferInMb.high >>> 0).toNumber(true);
                return message;
            };

            /**
             * Creates a plain object from a LogConfig message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.LogConfig
             * @static
             * @param {fastcity.admin.LogConfig} message LogConfig
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            LogConfig.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.logLevel = options.enums === String ? "LL_ERROR" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.deleteLogsOlderThenDays = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.deleteLogsOlderThenDays = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.archiveLogsOlderThenDays = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.archiveLogsOlderThenDays = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.deleteLogsIfLogsLargerThanInMb = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.deleteLogsIfLogsLargerThanInMb = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.archiveLogsIfLogsLargerThanInMb = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.archiveLogsIfLogsLargerThanInMb = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.archiveLogsBufferInMb = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.archiveLogsBufferInMb = options.longs === String ? "0" : 0;
                }
                if (message.logLevel != null && message.hasOwnProperty("logLevel"))
                    object.logLevel = options.enums === String ? $root.fastcity.admin.LogRecord.LogLevel[message.logLevel] : message.logLevel;
                if (message.deleteLogsOlderThenDays != null && message.hasOwnProperty("deleteLogsOlderThenDays"))
                    if (typeof message.deleteLogsOlderThenDays === "number")
                        object.deleteLogsOlderThenDays = options.longs === String ? String(message.deleteLogsOlderThenDays) : message.deleteLogsOlderThenDays;
                    else
                        object.deleteLogsOlderThenDays = options.longs === String ? $util.Long.prototype.toString.call(message.deleteLogsOlderThenDays) : options.longs === Number ? new $util.LongBits(message.deleteLogsOlderThenDays.low >>> 0, message.deleteLogsOlderThenDays.high >>> 0).toNumber(true) : message.deleteLogsOlderThenDays;
                if (message.archiveLogsOlderThenDays != null && message.hasOwnProperty("archiveLogsOlderThenDays"))
                    if (typeof message.archiveLogsOlderThenDays === "number")
                        object.archiveLogsOlderThenDays = options.longs === String ? String(message.archiveLogsOlderThenDays) : message.archiveLogsOlderThenDays;
                    else
                        object.archiveLogsOlderThenDays = options.longs === String ? $util.Long.prototype.toString.call(message.archiveLogsOlderThenDays) : options.longs === Number ? new $util.LongBits(message.archiveLogsOlderThenDays.low >>> 0, message.archiveLogsOlderThenDays.high >>> 0).toNumber(true) : message.archiveLogsOlderThenDays;
                if (message.deleteLogsIfLogsLargerThanInMb != null && message.hasOwnProperty("deleteLogsIfLogsLargerThanInMb"))
                    if (typeof message.deleteLogsIfLogsLargerThanInMb === "number")
                        object.deleteLogsIfLogsLargerThanInMb = options.longs === String ? String(message.deleteLogsIfLogsLargerThanInMb) : message.deleteLogsIfLogsLargerThanInMb;
                    else
                        object.deleteLogsIfLogsLargerThanInMb = options.longs === String ? $util.Long.prototype.toString.call(message.deleteLogsIfLogsLargerThanInMb) : options.longs === Number ? new $util.LongBits(message.deleteLogsIfLogsLargerThanInMb.low >>> 0, message.deleteLogsIfLogsLargerThanInMb.high >>> 0).toNumber(true) : message.deleteLogsIfLogsLargerThanInMb;
                if (message.archiveLogsIfLogsLargerThanInMb != null && message.hasOwnProperty("archiveLogsIfLogsLargerThanInMb"))
                    if (typeof message.archiveLogsIfLogsLargerThanInMb === "number")
                        object.archiveLogsIfLogsLargerThanInMb = options.longs === String ? String(message.archiveLogsIfLogsLargerThanInMb) : message.archiveLogsIfLogsLargerThanInMb;
                    else
                        object.archiveLogsIfLogsLargerThanInMb = options.longs === String ? $util.Long.prototype.toString.call(message.archiveLogsIfLogsLargerThanInMb) : options.longs === Number ? new $util.LongBits(message.archiveLogsIfLogsLargerThanInMb.low >>> 0, message.archiveLogsIfLogsLargerThanInMb.high >>> 0).toNumber(true) : message.archiveLogsIfLogsLargerThanInMb;
                if (message.archiveLogsBufferInMb != null && message.hasOwnProperty("archiveLogsBufferInMb"))
                    if (typeof message.archiveLogsBufferInMb === "number")
                        object.archiveLogsBufferInMb = options.longs === String ? String(message.archiveLogsBufferInMb) : message.archiveLogsBufferInMb;
                    else
                        object.archiveLogsBufferInMb = options.longs === String ? $util.Long.prototype.toString.call(message.archiveLogsBufferInMb) : options.longs === Number ? new $util.LongBits(message.archiveLogsBufferInMb.low >>> 0, message.archiveLogsBufferInMb.high >>> 0).toNumber(true) : message.archiveLogsBufferInMb;
                return object;
            };

            /**
             * Converts this LogConfig to JSON.
             * @function toJSON
             * @memberof fastcity.admin.LogConfig
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            LogConfig.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return LogConfig;
        })();

        admin.AdminChannelConfig = (function() {

            /**
             * Properties of an AdminChannelConfig.
             * @memberof fastcity.admin
             * @interface IAdminChannelConfig
             * @property {string|null} [host] AdminChannelConfig host
             * @property {number|null} [port] AdminChannelConfig port
             */

            /**
             * Constructs a new AdminChannelConfig.
             * @memberof fastcity.admin
             * @classdesc Represents an AdminChannelConfig.
             * @implements IAdminChannelConfig
             * @constructor
             * @param {fastcity.admin.IAdminChannelConfig=} [properties] Properties to set
             */
            function AdminChannelConfig(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * AdminChannelConfig host.
             * @member {string} host
             * @memberof fastcity.admin.AdminChannelConfig
             * @instance
             */
            AdminChannelConfig.prototype.host = "";

            /**
             * AdminChannelConfig port.
             * @member {number} port
             * @memberof fastcity.admin.AdminChannelConfig
             * @instance
             */
            AdminChannelConfig.prototype.port = 0;

            /**
             * Creates a new AdminChannelConfig instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.AdminChannelConfig
             * @static
             * @param {fastcity.admin.IAdminChannelConfig=} [properties] Properties to set
             * @returns {fastcity.admin.AdminChannelConfig} AdminChannelConfig instance
             */
            AdminChannelConfig.create = function create(properties) {
                return new AdminChannelConfig(properties);
            };

            /**
             * Encodes the specified AdminChannelConfig message. Does not implicitly {@link fastcity.admin.AdminChannelConfig.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.AdminChannelConfig
             * @static
             * @param {fastcity.admin.IAdminChannelConfig} message AdminChannelConfig message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AdminChannelConfig.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.host != null && Object.hasOwnProperty.call(message, "host"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.host);
                if (message.port != null && Object.hasOwnProperty.call(message, "port"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.port);
                return writer;
            };

            /**
             * Decodes an AdminChannelConfig message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.AdminChannelConfig
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.AdminChannelConfig} AdminChannelConfig
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AdminChannelConfig.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.AdminChannelConfig();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.host = reader.string();
                        break;
                    case 2:
                        message.port = reader.uint32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an AdminChannelConfig message.
             * @function verify
             * @memberof fastcity.admin.AdminChannelConfig
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AdminChannelConfig.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.host != null && message.hasOwnProperty("host"))
                    if (!$util.isString(message.host))
                        return "host: string expected";
                if (message.port != null && message.hasOwnProperty("port"))
                    if (!$util.isInteger(message.port))
                        return "port: integer expected";
                return null;
            };

            /**
             * Creates an AdminChannelConfig message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.AdminChannelConfig
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.AdminChannelConfig} AdminChannelConfig
             */
            AdminChannelConfig.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.AdminChannelConfig)
                    return object;
                var message = new $root.fastcity.admin.AdminChannelConfig();
                if (object.host != null)
                    message.host = String(object.host);
                if (object.port != null)
                    message.port = object.port >>> 0;
                return message;
            };

            /**
             * Creates a plain object from an AdminChannelConfig message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.AdminChannelConfig
             * @static
             * @param {fastcity.admin.AdminChannelConfig} message AdminChannelConfig
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AdminChannelConfig.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.host = "";
                    object.port = 0;
                }
                if (message.host != null && message.hasOwnProperty("host"))
                    object.host = message.host;
                if (message.port != null && message.hasOwnProperty("port"))
                    object.port = message.port;
                return object;
            };

            /**
             * Converts this AdminChannelConfig to JSON.
             * @function toJSON
             * @memberof fastcity.admin.AdminChannelConfig
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AdminChannelConfig.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return AdminChannelConfig;
        })();

        admin.ConnectorChannelConfig = (function() {

            /**
             * Properties of a ConnectorChannelConfig.
             * @memberof fastcity.admin
             * @interface IConnectorChannelConfig
             * @property {string|null} [host] ConnectorChannelConfig host
             * @property {number|null} [port] ConnectorChannelConfig port
             */

            /**
             * Constructs a new ConnectorChannelConfig.
             * @memberof fastcity.admin
             * @classdesc Represents a ConnectorChannelConfig.
             * @implements IConnectorChannelConfig
             * @constructor
             * @param {fastcity.admin.IConnectorChannelConfig=} [properties] Properties to set
             */
            function ConnectorChannelConfig(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ConnectorChannelConfig host.
             * @member {string} host
             * @memberof fastcity.admin.ConnectorChannelConfig
             * @instance
             */
            ConnectorChannelConfig.prototype.host = "";

            /**
             * ConnectorChannelConfig port.
             * @member {number} port
             * @memberof fastcity.admin.ConnectorChannelConfig
             * @instance
             */
            ConnectorChannelConfig.prototype.port = 0;

            /**
             * Creates a new ConnectorChannelConfig instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.ConnectorChannelConfig
             * @static
             * @param {fastcity.admin.IConnectorChannelConfig=} [properties] Properties to set
             * @returns {fastcity.admin.ConnectorChannelConfig} ConnectorChannelConfig instance
             */
            ConnectorChannelConfig.create = function create(properties) {
                return new ConnectorChannelConfig(properties);
            };

            /**
             * Encodes the specified ConnectorChannelConfig message. Does not implicitly {@link fastcity.admin.ConnectorChannelConfig.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.ConnectorChannelConfig
             * @static
             * @param {fastcity.admin.IConnectorChannelConfig} message ConnectorChannelConfig message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ConnectorChannelConfig.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.host != null && Object.hasOwnProperty.call(message, "host"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.host);
                if (message.port != null && Object.hasOwnProperty.call(message, "port"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.port);
                return writer;
            };

            /**
             * Decodes a ConnectorChannelConfig message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.ConnectorChannelConfig
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.ConnectorChannelConfig} ConnectorChannelConfig
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ConnectorChannelConfig.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.ConnectorChannelConfig();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.host = reader.string();
                        break;
                    case 2:
                        message.port = reader.uint32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a ConnectorChannelConfig message.
             * @function verify
             * @memberof fastcity.admin.ConnectorChannelConfig
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ConnectorChannelConfig.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.host != null && message.hasOwnProperty("host"))
                    if (!$util.isString(message.host))
                        return "host: string expected";
                if (message.port != null && message.hasOwnProperty("port"))
                    if (!$util.isInteger(message.port))
                        return "port: integer expected";
                return null;
            };

            /**
             * Creates a ConnectorChannelConfig message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.ConnectorChannelConfig
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.ConnectorChannelConfig} ConnectorChannelConfig
             */
            ConnectorChannelConfig.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.ConnectorChannelConfig)
                    return object;
                var message = new $root.fastcity.admin.ConnectorChannelConfig();
                if (object.host != null)
                    message.host = String(object.host);
                if (object.port != null)
                    message.port = object.port >>> 0;
                return message;
            };

            /**
             * Creates a plain object from a ConnectorChannelConfig message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.ConnectorChannelConfig
             * @static
             * @param {fastcity.admin.ConnectorChannelConfig} message ConnectorChannelConfig
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ConnectorChannelConfig.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.host = "";
                    object.port = 0;
                }
                if (message.host != null && message.hasOwnProperty("host"))
                    object.host = message.host;
                if (message.port != null && message.hasOwnProperty("port"))
                    object.port = message.port;
                return object;
            };

            /**
             * Converts this ConnectorChannelConfig to JSON.
             * @function toJSON
             * @memberof fastcity.admin.ConnectorChannelConfig
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ConnectorChannelConfig.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return ConnectorChannelConfig;
        })();

        admin.CourierChannelConfig = (function() {

            /**
             * Properties of a CourierChannelConfig.
             * @memberof fastcity.admin
             * @interface ICourierChannelConfig
             * @property {string|null} [host] CourierChannelConfig host
             * @property {number|null} [port] CourierChannelConfig port
             * @property {boolean|null} [calculateDistanceBeforeOrderClose] CourierChannelConfig calculateDistanceBeforeOrderClose
             */

            /**
             * Constructs a new CourierChannelConfig.
             * @memberof fastcity.admin
             * @classdesc Represents a CourierChannelConfig.
             * @implements ICourierChannelConfig
             * @constructor
             * @param {fastcity.admin.ICourierChannelConfig=} [properties] Properties to set
             */
            function CourierChannelConfig(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CourierChannelConfig host.
             * @member {string} host
             * @memberof fastcity.admin.CourierChannelConfig
             * @instance
             */
            CourierChannelConfig.prototype.host = "";

            /**
             * CourierChannelConfig port.
             * @member {number} port
             * @memberof fastcity.admin.CourierChannelConfig
             * @instance
             */
            CourierChannelConfig.prototype.port = 0;

            /**
             * CourierChannelConfig calculateDistanceBeforeOrderClose.
             * @member {boolean} calculateDistanceBeforeOrderClose
             * @memberof fastcity.admin.CourierChannelConfig
             * @instance
             */
            CourierChannelConfig.prototype.calculateDistanceBeforeOrderClose = false;

            /**
             * Creates a new CourierChannelConfig instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.CourierChannelConfig
             * @static
             * @param {fastcity.admin.ICourierChannelConfig=} [properties] Properties to set
             * @returns {fastcity.admin.CourierChannelConfig} CourierChannelConfig instance
             */
            CourierChannelConfig.create = function create(properties) {
                return new CourierChannelConfig(properties);
            };

            /**
             * Encodes the specified CourierChannelConfig message. Does not implicitly {@link fastcity.admin.CourierChannelConfig.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.CourierChannelConfig
             * @static
             * @param {fastcity.admin.ICourierChannelConfig} message CourierChannelConfig message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CourierChannelConfig.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.host != null && Object.hasOwnProperty.call(message, "host"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.host);
                if (message.port != null && Object.hasOwnProperty.call(message, "port"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.port);
                if (message.calculateDistanceBeforeOrderClose != null && Object.hasOwnProperty.call(message, "calculateDistanceBeforeOrderClose"))
                    writer.uint32(/* id 3, wireType 0 =*/24).bool(message.calculateDistanceBeforeOrderClose);
                return writer;
            };

            /**
             * Decodes a CourierChannelConfig message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.CourierChannelConfig
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.CourierChannelConfig} CourierChannelConfig
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CourierChannelConfig.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.CourierChannelConfig();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.host = reader.string();
                        break;
                    case 2:
                        message.port = reader.uint32();
                        break;
                    case 3:
                        message.calculateDistanceBeforeOrderClose = reader.bool();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a CourierChannelConfig message.
             * @function verify
             * @memberof fastcity.admin.CourierChannelConfig
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CourierChannelConfig.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.host != null && message.hasOwnProperty("host"))
                    if (!$util.isString(message.host))
                        return "host: string expected";
                if (message.port != null && message.hasOwnProperty("port"))
                    if (!$util.isInteger(message.port))
                        return "port: integer expected";
                if (message.calculateDistanceBeforeOrderClose != null && message.hasOwnProperty("calculateDistanceBeforeOrderClose"))
                    if (typeof message.calculateDistanceBeforeOrderClose !== "boolean")
                        return "calculateDistanceBeforeOrderClose: boolean expected";
                return null;
            };

            /**
             * Creates a CourierChannelConfig message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.CourierChannelConfig
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.CourierChannelConfig} CourierChannelConfig
             */
            CourierChannelConfig.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.CourierChannelConfig)
                    return object;
                var message = new $root.fastcity.admin.CourierChannelConfig();
                if (object.host != null)
                    message.host = String(object.host);
                if (object.port != null)
                    message.port = object.port >>> 0;
                if (object.calculateDistanceBeforeOrderClose != null)
                    message.calculateDistanceBeforeOrderClose = Boolean(object.calculateDistanceBeforeOrderClose);
                return message;
            };

            /**
             * Creates a plain object from a CourierChannelConfig message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.CourierChannelConfig
             * @static
             * @param {fastcity.admin.CourierChannelConfig} message CourierChannelConfig
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CourierChannelConfig.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.host = "";
                    object.port = 0;
                    object.calculateDistanceBeforeOrderClose = false;
                }
                if (message.host != null && message.hasOwnProperty("host"))
                    object.host = message.host;
                if (message.port != null && message.hasOwnProperty("port"))
                    object.port = message.port;
                if (message.calculateDistanceBeforeOrderClose != null && message.hasOwnProperty("calculateDistanceBeforeOrderClose"))
                    object.calculateDistanceBeforeOrderClose = message.calculateDistanceBeforeOrderClose;
                return object;
            };

            /**
             * Converts this CourierChannelConfig to JSON.
             * @function toJSON
             * @memberof fastcity.admin.CourierChannelConfig
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CourierChannelConfig.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return CourierChannelConfig;
        })();

        admin.DatabaseConfig = (function() {

            /**
             * Properties of a DatabaseConfig.
             * @memberof fastcity.admin
             * @interface IDatabaseConfig
             * @property {string|null} [host] DatabaseConfig host
             * @property {number|null} [port] DatabaseConfig port
             * @property {string|null} [database] DatabaseConfig database
             * @property {string|null} [login] DatabaseConfig login
             * @property {string|null} [password] DatabaseConfig password
             * @property {string|null} [migrationsPath] DatabaseConfig migrationsPath
             */

            /**
             * Constructs a new DatabaseConfig.
             * @memberof fastcity.admin
             * @classdesc Represents a DatabaseConfig.
             * @implements IDatabaseConfig
             * @constructor
             * @param {fastcity.admin.IDatabaseConfig=} [properties] Properties to set
             */
            function DatabaseConfig(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * DatabaseConfig host.
             * @member {string} host
             * @memberof fastcity.admin.DatabaseConfig
             * @instance
             */
            DatabaseConfig.prototype.host = "";

            /**
             * DatabaseConfig port.
             * @member {number} port
             * @memberof fastcity.admin.DatabaseConfig
             * @instance
             */
            DatabaseConfig.prototype.port = 0;

            /**
             * DatabaseConfig database.
             * @member {string} database
             * @memberof fastcity.admin.DatabaseConfig
             * @instance
             */
            DatabaseConfig.prototype.database = "";

            /**
             * DatabaseConfig login.
             * @member {string} login
             * @memberof fastcity.admin.DatabaseConfig
             * @instance
             */
            DatabaseConfig.prototype.login = "";

            /**
             * DatabaseConfig password.
             * @member {string} password
             * @memberof fastcity.admin.DatabaseConfig
             * @instance
             */
            DatabaseConfig.prototype.password = "";

            /**
             * DatabaseConfig migrationsPath.
             * @member {string} migrationsPath
             * @memberof fastcity.admin.DatabaseConfig
             * @instance
             */
            DatabaseConfig.prototype.migrationsPath = "";

            /**
             * Creates a new DatabaseConfig instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.DatabaseConfig
             * @static
             * @param {fastcity.admin.IDatabaseConfig=} [properties] Properties to set
             * @returns {fastcity.admin.DatabaseConfig} DatabaseConfig instance
             */
            DatabaseConfig.create = function create(properties) {
                return new DatabaseConfig(properties);
            };

            /**
             * Encodes the specified DatabaseConfig message. Does not implicitly {@link fastcity.admin.DatabaseConfig.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.DatabaseConfig
             * @static
             * @param {fastcity.admin.IDatabaseConfig} message DatabaseConfig message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DatabaseConfig.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.host != null && Object.hasOwnProperty.call(message, "host"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.host);
                if (message.port != null && Object.hasOwnProperty.call(message, "port"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.port);
                if (message.database != null && Object.hasOwnProperty.call(message, "database"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.database);
                if (message.login != null && Object.hasOwnProperty.call(message, "login"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.login);
                if (message.password != null && Object.hasOwnProperty.call(message, "password"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.password);
                if (message.migrationsPath != null && Object.hasOwnProperty.call(message, "migrationsPath"))
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.migrationsPath);
                return writer;
            };

            /**
             * Decodes a DatabaseConfig message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.DatabaseConfig
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.DatabaseConfig} DatabaseConfig
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DatabaseConfig.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.DatabaseConfig();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.host = reader.string();
                        break;
                    case 2:
                        message.port = reader.uint32();
                        break;
                    case 3:
                        message.database = reader.string();
                        break;
                    case 4:
                        message.login = reader.string();
                        break;
                    case 5:
                        message.password = reader.string();
                        break;
                    case 6:
                        message.migrationsPath = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a DatabaseConfig message.
             * @function verify
             * @memberof fastcity.admin.DatabaseConfig
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DatabaseConfig.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.host != null && message.hasOwnProperty("host"))
                    if (!$util.isString(message.host))
                        return "host: string expected";
                if (message.port != null && message.hasOwnProperty("port"))
                    if (!$util.isInteger(message.port))
                        return "port: integer expected";
                if (message.database != null && message.hasOwnProperty("database"))
                    if (!$util.isString(message.database))
                        return "database: string expected";
                if (message.login != null && message.hasOwnProperty("login"))
                    if (!$util.isString(message.login))
                        return "login: string expected";
                if (message.password != null && message.hasOwnProperty("password"))
                    if (!$util.isString(message.password))
                        return "password: string expected";
                if (message.migrationsPath != null && message.hasOwnProperty("migrationsPath"))
                    if (!$util.isString(message.migrationsPath))
                        return "migrationsPath: string expected";
                return null;
            };

            /**
             * Creates a DatabaseConfig message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.DatabaseConfig
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.DatabaseConfig} DatabaseConfig
             */
            DatabaseConfig.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.DatabaseConfig)
                    return object;
                var message = new $root.fastcity.admin.DatabaseConfig();
                if (object.host != null)
                    message.host = String(object.host);
                if (object.port != null)
                    message.port = object.port >>> 0;
                if (object.database != null)
                    message.database = String(object.database);
                if (object.login != null)
                    message.login = String(object.login);
                if (object.password != null)
                    message.password = String(object.password);
                if (object.migrationsPath != null)
                    message.migrationsPath = String(object.migrationsPath);
                return message;
            };

            /**
             * Creates a plain object from a DatabaseConfig message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.DatabaseConfig
             * @static
             * @param {fastcity.admin.DatabaseConfig} message DatabaseConfig
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DatabaseConfig.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.host = "";
                    object.port = 0;
                    object.database = "";
                    object.login = "";
                    object.password = "";
                    object.migrationsPath = "";
                }
                if (message.host != null && message.hasOwnProperty("host"))
                    object.host = message.host;
                if (message.port != null && message.hasOwnProperty("port"))
                    object.port = message.port;
                if (message.database != null && message.hasOwnProperty("database"))
                    object.database = message.database;
                if (message.login != null && message.hasOwnProperty("login"))
                    object.login = message.login;
                if (message.password != null && message.hasOwnProperty("password"))
                    object.password = message.password;
                if (message.migrationsPath != null && message.hasOwnProperty("migrationsPath"))
                    object.migrationsPath = message.migrationsPath;
                return object;
            };

            /**
             * Converts this DatabaseConfig to JSON.
             * @function toJSON
             * @memberof fastcity.admin.DatabaseConfig
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DatabaseConfig.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return DatabaseConfig;
        })();

        admin.HttpServerConfig = (function() {

            /**
             * Properties of a HttpServerConfig.
             * @memberof fastcity.admin
             * @interface IHttpServerConfig
             * @property {string|null} [host] HttpServerConfig host
             * @property {number|null} [port] HttpServerConfig port
             * @property {string|null} [domain] HttpServerConfig domain
             * @property {number|null} [tokenLifetimeInMin] HttpServerConfig tokenLifetimeInMin
             */

            /**
             * Constructs a new HttpServerConfig.
             * @memberof fastcity.admin
             * @classdesc Represents a HttpServerConfig.
             * @implements IHttpServerConfig
             * @constructor
             * @param {fastcity.admin.IHttpServerConfig=} [properties] Properties to set
             */
            function HttpServerConfig(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * HttpServerConfig host.
             * @member {string} host
             * @memberof fastcity.admin.HttpServerConfig
             * @instance
             */
            HttpServerConfig.prototype.host = "";

            /**
             * HttpServerConfig port.
             * @member {number} port
             * @memberof fastcity.admin.HttpServerConfig
             * @instance
             */
            HttpServerConfig.prototype.port = 0;

            /**
             * HttpServerConfig domain.
             * @member {string} domain
             * @memberof fastcity.admin.HttpServerConfig
             * @instance
             */
            HttpServerConfig.prototype.domain = "";

            /**
             * HttpServerConfig tokenLifetimeInMin.
             * @member {number} tokenLifetimeInMin
             * @memberof fastcity.admin.HttpServerConfig
             * @instance
             */
            HttpServerConfig.prototype.tokenLifetimeInMin = 0;

            /**
             * Creates a new HttpServerConfig instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.HttpServerConfig
             * @static
             * @param {fastcity.admin.IHttpServerConfig=} [properties] Properties to set
             * @returns {fastcity.admin.HttpServerConfig} HttpServerConfig instance
             */
            HttpServerConfig.create = function create(properties) {
                return new HttpServerConfig(properties);
            };

            /**
             * Encodes the specified HttpServerConfig message. Does not implicitly {@link fastcity.admin.HttpServerConfig.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.HttpServerConfig
             * @static
             * @param {fastcity.admin.IHttpServerConfig} message HttpServerConfig message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            HttpServerConfig.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.host != null && Object.hasOwnProperty.call(message, "host"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.host);
                if (message.port != null && Object.hasOwnProperty.call(message, "port"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.port);
                if (message.domain != null && Object.hasOwnProperty.call(message, "domain"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.domain);
                if (message.tokenLifetimeInMin != null && Object.hasOwnProperty.call(message, "tokenLifetimeInMin"))
                    writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.tokenLifetimeInMin);
                return writer;
            };

            /**
             * Decodes a HttpServerConfig message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.HttpServerConfig
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.HttpServerConfig} HttpServerConfig
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            HttpServerConfig.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.HttpServerConfig();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.host = reader.string();
                        break;
                    case 2:
                        message.port = reader.uint32();
                        break;
                    case 3:
                        message.domain = reader.string();
                        break;
                    case 4:
                        message.tokenLifetimeInMin = reader.uint32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a HttpServerConfig message.
             * @function verify
             * @memberof fastcity.admin.HttpServerConfig
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            HttpServerConfig.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.host != null && message.hasOwnProperty("host"))
                    if (!$util.isString(message.host))
                        return "host: string expected";
                if (message.port != null && message.hasOwnProperty("port"))
                    if (!$util.isInteger(message.port))
                        return "port: integer expected";
                if (message.domain != null && message.hasOwnProperty("domain"))
                    if (!$util.isString(message.domain))
                        return "domain: string expected";
                if (message.tokenLifetimeInMin != null && message.hasOwnProperty("tokenLifetimeInMin"))
                    if (!$util.isInteger(message.tokenLifetimeInMin))
                        return "tokenLifetimeInMin: integer expected";
                return null;
            };

            /**
             * Creates a HttpServerConfig message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.HttpServerConfig
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.HttpServerConfig} HttpServerConfig
             */
            HttpServerConfig.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.HttpServerConfig)
                    return object;
                var message = new $root.fastcity.admin.HttpServerConfig();
                if (object.host != null)
                    message.host = String(object.host);
                if (object.port != null)
                    message.port = object.port >>> 0;
                if (object.domain != null)
                    message.domain = String(object.domain);
                if (object.tokenLifetimeInMin != null)
                    message.tokenLifetimeInMin = object.tokenLifetimeInMin >>> 0;
                return message;
            };

            /**
             * Creates a plain object from a HttpServerConfig message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.HttpServerConfig
             * @static
             * @param {fastcity.admin.HttpServerConfig} message HttpServerConfig
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            HttpServerConfig.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.host = "";
                    object.port = 0;
                    object.domain = "";
                    object.tokenLifetimeInMin = 0;
                }
                if (message.host != null && message.hasOwnProperty("host"))
                    object.host = message.host;
                if (message.port != null && message.hasOwnProperty("port"))
                    object.port = message.port;
                if (message.domain != null && message.hasOwnProperty("domain"))
                    object.domain = message.domain;
                if (message.tokenLifetimeInMin != null && message.hasOwnProperty("tokenLifetimeInMin"))
                    object.tokenLifetimeInMin = message.tokenLifetimeInMin;
                return object;
            };

            /**
             * Converts this HttpServerConfig to JSON.
             * @function toJSON
             * @memberof fastcity.admin.HttpServerConfig
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            HttpServerConfig.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return HttpServerConfig;
        })();

        admin.IIKOConfig = (function() {

            /**
             * Properties of a IIKOConfig.
             * @memberof fastcity.admin
             * @interface IIIKOConfig
             * @property {boolean|null} [useIiko] IIKOConfig useIiko
             * @property {number|null} [timeoutForAuthorizationInSeconds] IIKOConfig timeoutForAuthorizationInSeconds
             * @property {number|null} [loadingOrdersOnRestartTimeOffsetInSeconds] IIKOConfig loadingOrdersOnRestartTimeOffsetInSeconds
             * @property {boolean|null} [handleViaWebhook] IIKOConfig handleViaWebhook
             * @property {boolean|null} [handleOnlyTestOrders] IIKOConfig handleOnlyTestOrders
             * @property {boolean|null} [useDefaultWebhookSettings] IIKOConfig useDefaultWebhookSettings
             */

            /**
             * Constructs a new IIKOConfig.
             * @memberof fastcity.admin
             * @classdesc Represents a IIKOConfig.
             * @implements IIIKOConfig
             * @constructor
             * @param {fastcity.admin.IIIKOConfig=} [properties] Properties to set
             */
            function IIKOConfig(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * IIKOConfig useIiko.
             * @member {boolean} useIiko
             * @memberof fastcity.admin.IIKOConfig
             * @instance
             */
            IIKOConfig.prototype.useIiko = false;

            /**
             * IIKOConfig timeoutForAuthorizationInSeconds.
             * @member {number} timeoutForAuthorizationInSeconds
             * @memberof fastcity.admin.IIKOConfig
             * @instance
             */
            IIKOConfig.prototype.timeoutForAuthorizationInSeconds = 0;

            /**
             * IIKOConfig loadingOrdersOnRestartTimeOffsetInSeconds.
             * @member {number} loadingOrdersOnRestartTimeOffsetInSeconds
             * @memberof fastcity.admin.IIKOConfig
             * @instance
             */
            IIKOConfig.prototype.loadingOrdersOnRestartTimeOffsetInSeconds = 0;

            /**
             * IIKOConfig handleViaWebhook.
             * @member {boolean} handleViaWebhook
             * @memberof fastcity.admin.IIKOConfig
             * @instance
             */
            IIKOConfig.prototype.handleViaWebhook = false;

            /**
             * IIKOConfig handleOnlyTestOrders.
             * @member {boolean} handleOnlyTestOrders
             * @memberof fastcity.admin.IIKOConfig
             * @instance
             */
            IIKOConfig.prototype.handleOnlyTestOrders = false;

            /**
             * IIKOConfig useDefaultWebhookSettings.
             * @member {boolean} useDefaultWebhookSettings
             * @memberof fastcity.admin.IIKOConfig
             * @instance
             */
            IIKOConfig.prototype.useDefaultWebhookSettings = false;

            /**
             * Creates a new IIKOConfig instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.IIKOConfig
             * @static
             * @param {fastcity.admin.IIIKOConfig=} [properties] Properties to set
             * @returns {fastcity.admin.IIKOConfig} IIKOConfig instance
             */
            IIKOConfig.create = function create(properties) {
                return new IIKOConfig(properties);
            };

            /**
             * Encodes the specified IIKOConfig message. Does not implicitly {@link fastcity.admin.IIKOConfig.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.IIKOConfig
             * @static
             * @param {fastcity.admin.IIIKOConfig} message IIKOConfig message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            IIKOConfig.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.useIiko != null && Object.hasOwnProperty.call(message, "useIiko"))
                    writer.uint32(/* id 1, wireType 0 =*/8).bool(message.useIiko);
                if (message.timeoutForAuthorizationInSeconds != null && Object.hasOwnProperty.call(message, "timeoutForAuthorizationInSeconds"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.timeoutForAuthorizationInSeconds);
                if (message.loadingOrdersOnRestartTimeOffsetInSeconds != null && Object.hasOwnProperty.call(message, "loadingOrdersOnRestartTimeOffsetInSeconds"))
                    writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.loadingOrdersOnRestartTimeOffsetInSeconds);
                if (message.handleViaWebhook != null && Object.hasOwnProperty.call(message, "handleViaWebhook"))
                    writer.uint32(/* id 4, wireType 0 =*/32).bool(message.handleViaWebhook);
                if (message.handleOnlyTestOrders != null && Object.hasOwnProperty.call(message, "handleOnlyTestOrders"))
                    writer.uint32(/* id 5, wireType 0 =*/40).bool(message.handleOnlyTestOrders);
                if (message.useDefaultWebhookSettings != null && Object.hasOwnProperty.call(message, "useDefaultWebhookSettings"))
                    writer.uint32(/* id 6, wireType 0 =*/48).bool(message.useDefaultWebhookSettings);
                return writer;
            };

            /**
             * Decodes a IIKOConfig message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.IIKOConfig
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.IIKOConfig} IIKOConfig
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            IIKOConfig.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.IIKOConfig();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.useIiko = reader.bool();
                        break;
                    case 2:
                        message.timeoutForAuthorizationInSeconds = reader.uint32();
                        break;
                    case 3:
                        message.loadingOrdersOnRestartTimeOffsetInSeconds = reader.uint32();
                        break;
                    case 4:
                        message.handleViaWebhook = reader.bool();
                        break;
                    case 5:
                        message.handleOnlyTestOrders = reader.bool();
                        break;
                    case 6:
                        message.useDefaultWebhookSettings = reader.bool();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a IIKOConfig message.
             * @function verify
             * @memberof fastcity.admin.IIKOConfig
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            IIKOConfig.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.useIiko != null && message.hasOwnProperty("useIiko"))
                    if (typeof message.useIiko !== "boolean")
                        return "useIiko: boolean expected";
                if (message.timeoutForAuthorizationInSeconds != null && message.hasOwnProperty("timeoutForAuthorizationInSeconds"))
                    if (!$util.isInteger(message.timeoutForAuthorizationInSeconds))
                        return "timeoutForAuthorizationInSeconds: integer expected";
                if (message.loadingOrdersOnRestartTimeOffsetInSeconds != null && message.hasOwnProperty("loadingOrdersOnRestartTimeOffsetInSeconds"))
                    if (!$util.isInteger(message.loadingOrdersOnRestartTimeOffsetInSeconds))
                        return "loadingOrdersOnRestartTimeOffsetInSeconds: integer expected";
                if (message.handleViaWebhook != null && message.hasOwnProperty("handleViaWebhook"))
                    if (typeof message.handleViaWebhook !== "boolean")
                        return "handleViaWebhook: boolean expected";
                if (message.handleOnlyTestOrders != null && message.hasOwnProperty("handleOnlyTestOrders"))
                    if (typeof message.handleOnlyTestOrders !== "boolean")
                        return "handleOnlyTestOrders: boolean expected";
                if (message.useDefaultWebhookSettings != null && message.hasOwnProperty("useDefaultWebhookSettings"))
                    if (typeof message.useDefaultWebhookSettings !== "boolean")
                        return "useDefaultWebhookSettings: boolean expected";
                return null;
            };

            /**
             * Creates a IIKOConfig message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.IIKOConfig
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.IIKOConfig} IIKOConfig
             */
            IIKOConfig.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.IIKOConfig)
                    return object;
                var message = new $root.fastcity.admin.IIKOConfig();
                if (object.useIiko != null)
                    message.useIiko = Boolean(object.useIiko);
                if (object.timeoutForAuthorizationInSeconds != null)
                    message.timeoutForAuthorizationInSeconds = object.timeoutForAuthorizationInSeconds >>> 0;
                if (object.loadingOrdersOnRestartTimeOffsetInSeconds != null)
                    message.loadingOrdersOnRestartTimeOffsetInSeconds = object.loadingOrdersOnRestartTimeOffsetInSeconds >>> 0;
                if (object.handleViaWebhook != null)
                    message.handleViaWebhook = Boolean(object.handleViaWebhook);
                if (object.handleOnlyTestOrders != null)
                    message.handleOnlyTestOrders = Boolean(object.handleOnlyTestOrders);
                if (object.useDefaultWebhookSettings != null)
                    message.useDefaultWebhookSettings = Boolean(object.useDefaultWebhookSettings);
                return message;
            };

            /**
             * Creates a plain object from a IIKOConfig message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.IIKOConfig
             * @static
             * @param {fastcity.admin.IIKOConfig} message IIKOConfig
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            IIKOConfig.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.useIiko = false;
                    object.timeoutForAuthorizationInSeconds = 0;
                    object.loadingOrdersOnRestartTimeOffsetInSeconds = 0;
                    object.handleViaWebhook = false;
                    object.handleOnlyTestOrders = false;
                    object.useDefaultWebhookSettings = false;
                }
                if (message.useIiko != null && message.hasOwnProperty("useIiko"))
                    object.useIiko = message.useIiko;
                if (message.timeoutForAuthorizationInSeconds != null && message.hasOwnProperty("timeoutForAuthorizationInSeconds"))
                    object.timeoutForAuthorizationInSeconds = message.timeoutForAuthorizationInSeconds;
                if (message.loadingOrdersOnRestartTimeOffsetInSeconds != null && message.hasOwnProperty("loadingOrdersOnRestartTimeOffsetInSeconds"))
                    object.loadingOrdersOnRestartTimeOffsetInSeconds = message.loadingOrdersOnRestartTimeOffsetInSeconds;
                if (message.handleViaWebhook != null && message.hasOwnProperty("handleViaWebhook"))
                    object.handleViaWebhook = message.handleViaWebhook;
                if (message.handleOnlyTestOrders != null && message.hasOwnProperty("handleOnlyTestOrders"))
                    object.handleOnlyTestOrders = message.handleOnlyTestOrders;
                if (message.useDefaultWebhookSettings != null && message.hasOwnProperty("useDefaultWebhookSettings"))
                    object.useDefaultWebhookSettings = message.useDefaultWebhookSettings;
                return object;
            };

            /**
             * Converts this IIKOConfig to JSON.
             * @function toJSON
             * @memberof fastcity.admin.IIKOConfig
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            IIKOConfig.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return IIKOConfig;
        })();

        admin.ServerConfig = (function() {

            /**
             * Properties of a ServerConfig.
             * @memberof fastcity.admin
             * @interface IServerConfig
             * @property {fastcity.admin.ILogConfig|null} [logConfig] ServerConfig logConfig
             * @property {fastcity.admin.IAdminChannelConfig|null} [adminChannelConfig] ServerConfig adminChannelConfig
             * @property {fastcity.admin.IConnectorChannelConfig|null} [connectorChannelConfig] ServerConfig connectorChannelConfig
             * @property {fastcity.admin.ICourierChannelConfig|null} [courierChannelConfig] ServerConfig courierChannelConfig
             * @property {fastcity.admin.IDatabaseConfig|null} [databaseConfig] ServerConfig databaseConfig
             * @property {fastcity.admin.IHttpServerConfig|null} [httpServerConfig] ServerConfig httpServerConfig
             * @property {fastcity.admin.IIIKOConfig|null} [iikoConfig] ServerConfig iikoConfig
             */

            /**
             * Constructs a new ServerConfig.
             * @memberof fastcity.admin
             * @classdesc Represents a ServerConfig.
             * @implements IServerConfig
             * @constructor
             * @param {fastcity.admin.IServerConfig=} [properties] Properties to set
             */
            function ServerConfig(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ServerConfig logConfig.
             * @member {fastcity.admin.ILogConfig|null|undefined} logConfig
             * @memberof fastcity.admin.ServerConfig
             * @instance
             */
            ServerConfig.prototype.logConfig = null;

            /**
             * ServerConfig adminChannelConfig.
             * @member {fastcity.admin.IAdminChannelConfig|null|undefined} adminChannelConfig
             * @memberof fastcity.admin.ServerConfig
             * @instance
             */
            ServerConfig.prototype.adminChannelConfig = null;

            /**
             * ServerConfig connectorChannelConfig.
             * @member {fastcity.admin.IConnectorChannelConfig|null|undefined} connectorChannelConfig
             * @memberof fastcity.admin.ServerConfig
             * @instance
             */
            ServerConfig.prototype.connectorChannelConfig = null;

            /**
             * ServerConfig courierChannelConfig.
             * @member {fastcity.admin.ICourierChannelConfig|null|undefined} courierChannelConfig
             * @memberof fastcity.admin.ServerConfig
             * @instance
             */
            ServerConfig.prototype.courierChannelConfig = null;

            /**
             * ServerConfig databaseConfig.
             * @member {fastcity.admin.IDatabaseConfig|null|undefined} databaseConfig
             * @memberof fastcity.admin.ServerConfig
             * @instance
             */
            ServerConfig.prototype.databaseConfig = null;

            /**
             * ServerConfig httpServerConfig.
             * @member {fastcity.admin.IHttpServerConfig|null|undefined} httpServerConfig
             * @memberof fastcity.admin.ServerConfig
             * @instance
             */
            ServerConfig.prototype.httpServerConfig = null;

            /**
             * ServerConfig iikoConfig.
             * @member {fastcity.admin.IIIKOConfig|null|undefined} iikoConfig
             * @memberof fastcity.admin.ServerConfig
             * @instance
             */
            ServerConfig.prototype.iikoConfig = null;

            /**
             * Creates a new ServerConfig instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.ServerConfig
             * @static
             * @param {fastcity.admin.IServerConfig=} [properties] Properties to set
             * @returns {fastcity.admin.ServerConfig} ServerConfig instance
             */
            ServerConfig.create = function create(properties) {
                return new ServerConfig(properties);
            };

            /**
             * Encodes the specified ServerConfig message. Does not implicitly {@link fastcity.admin.ServerConfig.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.ServerConfig
             * @static
             * @param {fastcity.admin.IServerConfig} message ServerConfig message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ServerConfig.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.logConfig != null && Object.hasOwnProperty.call(message, "logConfig"))
                    $root.fastcity.admin.LogConfig.encode(message.logConfig, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.adminChannelConfig != null && Object.hasOwnProperty.call(message, "adminChannelConfig"))
                    $root.fastcity.admin.AdminChannelConfig.encode(message.adminChannelConfig, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.connectorChannelConfig != null && Object.hasOwnProperty.call(message, "connectorChannelConfig"))
                    $root.fastcity.admin.ConnectorChannelConfig.encode(message.connectorChannelConfig, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.courierChannelConfig != null && Object.hasOwnProperty.call(message, "courierChannelConfig"))
                    $root.fastcity.admin.CourierChannelConfig.encode(message.courierChannelConfig, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.databaseConfig != null && Object.hasOwnProperty.call(message, "databaseConfig"))
                    $root.fastcity.admin.DatabaseConfig.encode(message.databaseConfig, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.httpServerConfig != null && Object.hasOwnProperty.call(message, "httpServerConfig"))
                    $root.fastcity.admin.HttpServerConfig.encode(message.httpServerConfig, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.iikoConfig != null && Object.hasOwnProperty.call(message, "iikoConfig"))
                    $root.fastcity.admin.IIKOConfig.encode(message.iikoConfig, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a ServerConfig message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.ServerConfig
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.ServerConfig} ServerConfig
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ServerConfig.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.ServerConfig();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.logConfig = $root.fastcity.admin.LogConfig.decode(reader, reader.uint32());
                        break;
                    case 2:
                        message.adminChannelConfig = $root.fastcity.admin.AdminChannelConfig.decode(reader, reader.uint32());
                        break;
                    case 3:
                        message.connectorChannelConfig = $root.fastcity.admin.ConnectorChannelConfig.decode(reader, reader.uint32());
                        break;
                    case 4:
                        message.courierChannelConfig = $root.fastcity.admin.CourierChannelConfig.decode(reader, reader.uint32());
                        break;
                    case 5:
                        message.databaseConfig = $root.fastcity.admin.DatabaseConfig.decode(reader, reader.uint32());
                        break;
                    case 6:
                        message.httpServerConfig = $root.fastcity.admin.HttpServerConfig.decode(reader, reader.uint32());
                        break;
                    case 7:
                        message.iikoConfig = $root.fastcity.admin.IIKOConfig.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a ServerConfig message.
             * @function verify
             * @memberof fastcity.admin.ServerConfig
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ServerConfig.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.logConfig != null && message.hasOwnProperty("logConfig")) {
                    var error = $root.fastcity.admin.LogConfig.verify(message.logConfig);
                    if (error)
                        return "logConfig." + error;
                }
                if (message.adminChannelConfig != null && message.hasOwnProperty("adminChannelConfig")) {
                    var error = $root.fastcity.admin.AdminChannelConfig.verify(message.adminChannelConfig);
                    if (error)
                        return "adminChannelConfig." + error;
                }
                if (message.connectorChannelConfig != null && message.hasOwnProperty("connectorChannelConfig")) {
                    var error = $root.fastcity.admin.ConnectorChannelConfig.verify(message.connectorChannelConfig);
                    if (error)
                        return "connectorChannelConfig." + error;
                }
                if (message.courierChannelConfig != null && message.hasOwnProperty("courierChannelConfig")) {
                    var error = $root.fastcity.admin.CourierChannelConfig.verify(message.courierChannelConfig);
                    if (error)
                        return "courierChannelConfig." + error;
                }
                if (message.databaseConfig != null && message.hasOwnProperty("databaseConfig")) {
                    var error = $root.fastcity.admin.DatabaseConfig.verify(message.databaseConfig);
                    if (error)
                        return "databaseConfig." + error;
                }
                if (message.httpServerConfig != null && message.hasOwnProperty("httpServerConfig")) {
                    var error = $root.fastcity.admin.HttpServerConfig.verify(message.httpServerConfig);
                    if (error)
                        return "httpServerConfig." + error;
                }
                if (message.iikoConfig != null && message.hasOwnProperty("iikoConfig")) {
                    var error = $root.fastcity.admin.IIKOConfig.verify(message.iikoConfig);
                    if (error)
                        return "iikoConfig." + error;
                }
                return null;
            };

            /**
             * Creates a ServerConfig message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.ServerConfig
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.ServerConfig} ServerConfig
             */
            ServerConfig.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.ServerConfig)
                    return object;
                var message = new $root.fastcity.admin.ServerConfig();
                if (object.logConfig != null) {
                    if (typeof object.logConfig !== "object")
                        throw TypeError(".fastcity.admin.ServerConfig.logConfig: object expected");
                    message.logConfig = $root.fastcity.admin.LogConfig.fromObject(object.logConfig);
                }
                if (object.adminChannelConfig != null) {
                    if (typeof object.adminChannelConfig !== "object")
                        throw TypeError(".fastcity.admin.ServerConfig.adminChannelConfig: object expected");
                    message.adminChannelConfig = $root.fastcity.admin.AdminChannelConfig.fromObject(object.adminChannelConfig);
                }
                if (object.connectorChannelConfig != null) {
                    if (typeof object.connectorChannelConfig !== "object")
                        throw TypeError(".fastcity.admin.ServerConfig.connectorChannelConfig: object expected");
                    message.connectorChannelConfig = $root.fastcity.admin.ConnectorChannelConfig.fromObject(object.connectorChannelConfig);
                }
                if (object.courierChannelConfig != null) {
                    if (typeof object.courierChannelConfig !== "object")
                        throw TypeError(".fastcity.admin.ServerConfig.courierChannelConfig: object expected");
                    message.courierChannelConfig = $root.fastcity.admin.CourierChannelConfig.fromObject(object.courierChannelConfig);
                }
                if (object.databaseConfig != null) {
                    if (typeof object.databaseConfig !== "object")
                        throw TypeError(".fastcity.admin.ServerConfig.databaseConfig: object expected");
                    message.databaseConfig = $root.fastcity.admin.DatabaseConfig.fromObject(object.databaseConfig);
                }
                if (object.httpServerConfig != null) {
                    if (typeof object.httpServerConfig !== "object")
                        throw TypeError(".fastcity.admin.ServerConfig.httpServerConfig: object expected");
                    message.httpServerConfig = $root.fastcity.admin.HttpServerConfig.fromObject(object.httpServerConfig);
                }
                if (object.iikoConfig != null) {
                    if (typeof object.iikoConfig !== "object")
                        throw TypeError(".fastcity.admin.ServerConfig.iikoConfig: object expected");
                    message.iikoConfig = $root.fastcity.admin.IIKOConfig.fromObject(object.iikoConfig);
                }
                return message;
            };

            /**
             * Creates a plain object from a ServerConfig message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.ServerConfig
             * @static
             * @param {fastcity.admin.ServerConfig} message ServerConfig
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ServerConfig.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.logConfig = null;
                    object.adminChannelConfig = null;
                    object.connectorChannelConfig = null;
                    object.courierChannelConfig = null;
                    object.databaseConfig = null;
                    object.httpServerConfig = null;
                    object.iikoConfig = null;
                }
                if (message.logConfig != null && message.hasOwnProperty("logConfig"))
                    object.logConfig = $root.fastcity.admin.LogConfig.toObject(message.logConfig, options);
                if (message.adminChannelConfig != null && message.hasOwnProperty("adminChannelConfig"))
                    object.adminChannelConfig = $root.fastcity.admin.AdminChannelConfig.toObject(message.adminChannelConfig, options);
                if (message.connectorChannelConfig != null && message.hasOwnProperty("connectorChannelConfig"))
                    object.connectorChannelConfig = $root.fastcity.admin.ConnectorChannelConfig.toObject(message.connectorChannelConfig, options);
                if (message.courierChannelConfig != null && message.hasOwnProperty("courierChannelConfig"))
                    object.courierChannelConfig = $root.fastcity.admin.CourierChannelConfig.toObject(message.courierChannelConfig, options);
                if (message.databaseConfig != null && message.hasOwnProperty("databaseConfig"))
                    object.databaseConfig = $root.fastcity.admin.DatabaseConfig.toObject(message.databaseConfig, options);
                if (message.httpServerConfig != null && message.hasOwnProperty("httpServerConfig"))
                    object.httpServerConfig = $root.fastcity.admin.HttpServerConfig.toObject(message.httpServerConfig, options);
                if (message.iikoConfig != null && message.hasOwnProperty("iikoConfig"))
                    object.iikoConfig = $root.fastcity.admin.IIKOConfig.toObject(message.iikoConfig, options);
                return object;
            };

            /**
             * Converts this ServerConfig to JSON.
             * @function toJSON
             * @memberof fastcity.admin.ServerConfig
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ServerConfig.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return ServerConfig;
        })();

        admin.GetServerConfig = (function() {

            /**
             * Properties of a GetServerConfig.
             * @memberof fastcity.admin
             * @interface IGetServerConfig
             */

            /**
             * Constructs a new GetServerConfig.
             * @memberof fastcity.admin
             * @classdesc Represents a GetServerConfig.
             * @implements IGetServerConfig
             * @constructor
             * @param {fastcity.admin.IGetServerConfig=} [properties] Properties to set
             */
            function GetServerConfig(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new GetServerConfig instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.GetServerConfig
             * @static
             * @param {fastcity.admin.IGetServerConfig=} [properties] Properties to set
             * @returns {fastcity.admin.GetServerConfig} GetServerConfig instance
             */
            GetServerConfig.create = function create(properties) {
                return new GetServerConfig(properties);
            };

            /**
             * Encodes the specified GetServerConfig message. Does not implicitly {@link fastcity.admin.GetServerConfig.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.GetServerConfig
             * @static
             * @param {fastcity.admin.IGetServerConfig} message GetServerConfig message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetServerConfig.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Decodes a GetServerConfig message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.GetServerConfig
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.GetServerConfig} GetServerConfig
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetServerConfig.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.GetServerConfig();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a GetServerConfig message.
             * @function verify
             * @memberof fastcity.admin.GetServerConfig
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetServerConfig.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a GetServerConfig message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.GetServerConfig
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.GetServerConfig} GetServerConfig
             */
            GetServerConfig.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.GetServerConfig)
                    return object;
                return new $root.fastcity.admin.GetServerConfig();
            };

            /**
             * Creates a plain object from a GetServerConfig message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.GetServerConfig
             * @static
             * @param {fastcity.admin.GetServerConfig} message GetServerConfig
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetServerConfig.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this GetServerConfig to JSON.
             * @function toJSON
             * @memberof fastcity.admin.GetServerConfig
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetServerConfig.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return GetServerConfig;
        })();

        admin.UpdateServerConfig = (function() {

            /**
             * Properties of an UpdateServerConfig.
             * @memberof fastcity.admin
             * @interface IUpdateServerConfig
             * @property {fastcity.admin.ILogConfig|null} [logConfig] UpdateServerConfig logConfig
             * @property {fastcity.admin.IAdminChannelConfig|null} [adminChannelConfig] UpdateServerConfig adminChannelConfig
             * @property {fastcity.admin.IConnectorChannelConfig|null} [connectorChannelConfig] UpdateServerConfig connectorChannelConfig
             * @property {fastcity.admin.ICourierChannelConfig|null} [courierChannelConfig] UpdateServerConfig courierChannelConfig
             * @property {fastcity.admin.IDatabaseConfig|null} [databaseConfig] UpdateServerConfig databaseConfig
             * @property {fastcity.admin.IHttpServerConfig|null} [httpServerConfig] UpdateServerConfig httpServerConfig
             * @property {fastcity.admin.IIIKOConfig|null} [iikoConfig] UpdateServerConfig iikoConfig
             */

            /**
             * Constructs a new UpdateServerConfig.
             * @memberof fastcity.admin
             * @classdesc Represents an UpdateServerConfig.
             * @implements IUpdateServerConfig
             * @constructor
             * @param {fastcity.admin.IUpdateServerConfig=} [properties] Properties to set
             */
            function UpdateServerConfig(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * UpdateServerConfig logConfig.
             * @member {fastcity.admin.ILogConfig|null|undefined} logConfig
             * @memberof fastcity.admin.UpdateServerConfig
             * @instance
             */
            UpdateServerConfig.prototype.logConfig = null;

            /**
             * UpdateServerConfig adminChannelConfig.
             * @member {fastcity.admin.IAdminChannelConfig|null|undefined} adminChannelConfig
             * @memberof fastcity.admin.UpdateServerConfig
             * @instance
             */
            UpdateServerConfig.prototype.adminChannelConfig = null;

            /**
             * UpdateServerConfig connectorChannelConfig.
             * @member {fastcity.admin.IConnectorChannelConfig|null|undefined} connectorChannelConfig
             * @memberof fastcity.admin.UpdateServerConfig
             * @instance
             */
            UpdateServerConfig.prototype.connectorChannelConfig = null;

            /**
             * UpdateServerConfig courierChannelConfig.
             * @member {fastcity.admin.ICourierChannelConfig|null|undefined} courierChannelConfig
             * @memberof fastcity.admin.UpdateServerConfig
             * @instance
             */
            UpdateServerConfig.prototype.courierChannelConfig = null;

            /**
             * UpdateServerConfig databaseConfig.
             * @member {fastcity.admin.IDatabaseConfig|null|undefined} databaseConfig
             * @memberof fastcity.admin.UpdateServerConfig
             * @instance
             */
            UpdateServerConfig.prototype.databaseConfig = null;

            /**
             * UpdateServerConfig httpServerConfig.
             * @member {fastcity.admin.IHttpServerConfig|null|undefined} httpServerConfig
             * @memberof fastcity.admin.UpdateServerConfig
             * @instance
             */
            UpdateServerConfig.prototype.httpServerConfig = null;

            /**
             * UpdateServerConfig iikoConfig.
             * @member {fastcity.admin.IIIKOConfig|null|undefined} iikoConfig
             * @memberof fastcity.admin.UpdateServerConfig
             * @instance
             */
            UpdateServerConfig.prototype.iikoConfig = null;

            /**
             * Creates a new UpdateServerConfig instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.UpdateServerConfig
             * @static
             * @param {fastcity.admin.IUpdateServerConfig=} [properties] Properties to set
             * @returns {fastcity.admin.UpdateServerConfig} UpdateServerConfig instance
             */
            UpdateServerConfig.create = function create(properties) {
                return new UpdateServerConfig(properties);
            };

            /**
             * Encodes the specified UpdateServerConfig message. Does not implicitly {@link fastcity.admin.UpdateServerConfig.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.UpdateServerConfig
             * @static
             * @param {fastcity.admin.IUpdateServerConfig} message UpdateServerConfig message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateServerConfig.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.logConfig != null && Object.hasOwnProperty.call(message, "logConfig"))
                    $root.fastcity.admin.LogConfig.encode(message.logConfig, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.adminChannelConfig != null && Object.hasOwnProperty.call(message, "adminChannelConfig"))
                    $root.fastcity.admin.AdminChannelConfig.encode(message.adminChannelConfig, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.connectorChannelConfig != null && Object.hasOwnProperty.call(message, "connectorChannelConfig"))
                    $root.fastcity.admin.ConnectorChannelConfig.encode(message.connectorChannelConfig, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.courierChannelConfig != null && Object.hasOwnProperty.call(message, "courierChannelConfig"))
                    $root.fastcity.admin.CourierChannelConfig.encode(message.courierChannelConfig, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.databaseConfig != null && Object.hasOwnProperty.call(message, "databaseConfig"))
                    $root.fastcity.admin.DatabaseConfig.encode(message.databaseConfig, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.httpServerConfig != null && Object.hasOwnProperty.call(message, "httpServerConfig"))
                    $root.fastcity.admin.HttpServerConfig.encode(message.httpServerConfig, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.iikoConfig != null && Object.hasOwnProperty.call(message, "iikoConfig"))
                    $root.fastcity.admin.IIKOConfig.encode(message.iikoConfig, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes an UpdateServerConfig message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.UpdateServerConfig
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.UpdateServerConfig} UpdateServerConfig
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateServerConfig.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.UpdateServerConfig();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.logConfig = $root.fastcity.admin.LogConfig.decode(reader, reader.uint32());
                        break;
                    case 2:
                        message.adminChannelConfig = $root.fastcity.admin.AdminChannelConfig.decode(reader, reader.uint32());
                        break;
                    case 3:
                        message.connectorChannelConfig = $root.fastcity.admin.ConnectorChannelConfig.decode(reader, reader.uint32());
                        break;
                    case 4:
                        message.courierChannelConfig = $root.fastcity.admin.CourierChannelConfig.decode(reader, reader.uint32());
                        break;
                    case 5:
                        message.databaseConfig = $root.fastcity.admin.DatabaseConfig.decode(reader, reader.uint32());
                        break;
                    case 6:
                        message.httpServerConfig = $root.fastcity.admin.HttpServerConfig.decode(reader, reader.uint32());
                        break;
                    case 7:
                        message.iikoConfig = $root.fastcity.admin.IIKOConfig.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an UpdateServerConfig message.
             * @function verify
             * @memberof fastcity.admin.UpdateServerConfig
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            UpdateServerConfig.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.logConfig != null && message.hasOwnProperty("logConfig")) {
                    var error = $root.fastcity.admin.LogConfig.verify(message.logConfig);
                    if (error)
                        return "logConfig." + error;
                }
                if (message.adminChannelConfig != null && message.hasOwnProperty("adminChannelConfig")) {
                    var error = $root.fastcity.admin.AdminChannelConfig.verify(message.adminChannelConfig);
                    if (error)
                        return "adminChannelConfig." + error;
                }
                if (message.connectorChannelConfig != null && message.hasOwnProperty("connectorChannelConfig")) {
                    var error = $root.fastcity.admin.ConnectorChannelConfig.verify(message.connectorChannelConfig);
                    if (error)
                        return "connectorChannelConfig." + error;
                }
                if (message.courierChannelConfig != null && message.hasOwnProperty("courierChannelConfig")) {
                    var error = $root.fastcity.admin.CourierChannelConfig.verify(message.courierChannelConfig);
                    if (error)
                        return "courierChannelConfig." + error;
                }
                if (message.databaseConfig != null && message.hasOwnProperty("databaseConfig")) {
                    var error = $root.fastcity.admin.DatabaseConfig.verify(message.databaseConfig);
                    if (error)
                        return "databaseConfig." + error;
                }
                if (message.httpServerConfig != null && message.hasOwnProperty("httpServerConfig")) {
                    var error = $root.fastcity.admin.HttpServerConfig.verify(message.httpServerConfig);
                    if (error)
                        return "httpServerConfig." + error;
                }
                if (message.iikoConfig != null && message.hasOwnProperty("iikoConfig")) {
                    var error = $root.fastcity.admin.IIKOConfig.verify(message.iikoConfig);
                    if (error)
                        return "iikoConfig." + error;
                }
                return null;
            };

            /**
             * Creates an UpdateServerConfig message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.UpdateServerConfig
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.UpdateServerConfig} UpdateServerConfig
             */
            UpdateServerConfig.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.UpdateServerConfig)
                    return object;
                var message = new $root.fastcity.admin.UpdateServerConfig();
                if (object.logConfig != null) {
                    if (typeof object.logConfig !== "object")
                        throw TypeError(".fastcity.admin.UpdateServerConfig.logConfig: object expected");
                    message.logConfig = $root.fastcity.admin.LogConfig.fromObject(object.logConfig);
                }
                if (object.adminChannelConfig != null) {
                    if (typeof object.adminChannelConfig !== "object")
                        throw TypeError(".fastcity.admin.UpdateServerConfig.adminChannelConfig: object expected");
                    message.adminChannelConfig = $root.fastcity.admin.AdminChannelConfig.fromObject(object.adminChannelConfig);
                }
                if (object.connectorChannelConfig != null) {
                    if (typeof object.connectorChannelConfig !== "object")
                        throw TypeError(".fastcity.admin.UpdateServerConfig.connectorChannelConfig: object expected");
                    message.connectorChannelConfig = $root.fastcity.admin.ConnectorChannelConfig.fromObject(object.connectorChannelConfig);
                }
                if (object.courierChannelConfig != null) {
                    if (typeof object.courierChannelConfig !== "object")
                        throw TypeError(".fastcity.admin.UpdateServerConfig.courierChannelConfig: object expected");
                    message.courierChannelConfig = $root.fastcity.admin.CourierChannelConfig.fromObject(object.courierChannelConfig);
                }
                if (object.databaseConfig != null) {
                    if (typeof object.databaseConfig !== "object")
                        throw TypeError(".fastcity.admin.UpdateServerConfig.databaseConfig: object expected");
                    message.databaseConfig = $root.fastcity.admin.DatabaseConfig.fromObject(object.databaseConfig);
                }
                if (object.httpServerConfig != null) {
                    if (typeof object.httpServerConfig !== "object")
                        throw TypeError(".fastcity.admin.UpdateServerConfig.httpServerConfig: object expected");
                    message.httpServerConfig = $root.fastcity.admin.HttpServerConfig.fromObject(object.httpServerConfig);
                }
                if (object.iikoConfig != null) {
                    if (typeof object.iikoConfig !== "object")
                        throw TypeError(".fastcity.admin.UpdateServerConfig.iikoConfig: object expected");
                    message.iikoConfig = $root.fastcity.admin.IIKOConfig.fromObject(object.iikoConfig);
                }
                return message;
            };

            /**
             * Creates a plain object from an UpdateServerConfig message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.UpdateServerConfig
             * @static
             * @param {fastcity.admin.UpdateServerConfig} message UpdateServerConfig
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UpdateServerConfig.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.logConfig = null;
                    object.adminChannelConfig = null;
                    object.connectorChannelConfig = null;
                    object.courierChannelConfig = null;
                    object.databaseConfig = null;
                    object.httpServerConfig = null;
                    object.iikoConfig = null;
                }
                if (message.logConfig != null && message.hasOwnProperty("logConfig"))
                    object.logConfig = $root.fastcity.admin.LogConfig.toObject(message.logConfig, options);
                if (message.adminChannelConfig != null && message.hasOwnProperty("adminChannelConfig"))
                    object.adminChannelConfig = $root.fastcity.admin.AdminChannelConfig.toObject(message.adminChannelConfig, options);
                if (message.connectorChannelConfig != null && message.hasOwnProperty("connectorChannelConfig"))
                    object.connectorChannelConfig = $root.fastcity.admin.ConnectorChannelConfig.toObject(message.connectorChannelConfig, options);
                if (message.courierChannelConfig != null && message.hasOwnProperty("courierChannelConfig"))
                    object.courierChannelConfig = $root.fastcity.admin.CourierChannelConfig.toObject(message.courierChannelConfig, options);
                if (message.databaseConfig != null && message.hasOwnProperty("databaseConfig"))
                    object.databaseConfig = $root.fastcity.admin.DatabaseConfig.toObject(message.databaseConfig, options);
                if (message.httpServerConfig != null && message.hasOwnProperty("httpServerConfig"))
                    object.httpServerConfig = $root.fastcity.admin.HttpServerConfig.toObject(message.httpServerConfig, options);
                if (message.iikoConfig != null && message.hasOwnProperty("iikoConfig"))
                    object.iikoConfig = $root.fastcity.admin.IIKOConfig.toObject(message.iikoConfig, options);
                return object;
            };

            /**
             * Converts this UpdateServerConfig to JSON.
             * @function toJSON
             * @memberof fastcity.admin.UpdateServerConfig
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UpdateServerConfig.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return UpdateServerConfig;
        })();

        admin.ProtocolVersion = (function() {

            /**
             * Properties of a ProtocolVersion.
             * @memberof fastcity.admin
             * @interface IProtocolVersion
             */

            /**
             * Constructs a new ProtocolVersion.
             * @memberof fastcity.admin
             * @classdesc Represents a ProtocolVersion.
             * @implements IProtocolVersion
             * @constructor
             * @param {fastcity.admin.IProtocolVersion=} [properties] Properties to set
             */
            function ProtocolVersion(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new ProtocolVersion instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.ProtocolVersion
             * @static
             * @param {fastcity.admin.IProtocolVersion=} [properties] Properties to set
             * @returns {fastcity.admin.ProtocolVersion} ProtocolVersion instance
             */
            ProtocolVersion.create = function create(properties) {
                return new ProtocolVersion(properties);
            };

            /**
             * Encodes the specified ProtocolVersion message. Does not implicitly {@link fastcity.admin.ProtocolVersion.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.ProtocolVersion
             * @static
             * @param {fastcity.admin.IProtocolVersion} message ProtocolVersion message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ProtocolVersion.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Decodes a ProtocolVersion message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.ProtocolVersion
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.ProtocolVersion} ProtocolVersion
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ProtocolVersion.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.ProtocolVersion();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a ProtocolVersion message.
             * @function verify
             * @memberof fastcity.admin.ProtocolVersion
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ProtocolVersion.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a ProtocolVersion message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.ProtocolVersion
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.ProtocolVersion} ProtocolVersion
             */
            ProtocolVersion.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.ProtocolVersion)
                    return object;
                return new $root.fastcity.admin.ProtocolVersion();
            };

            /**
             * Creates a plain object from a ProtocolVersion message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.ProtocolVersion
             * @static
             * @param {fastcity.admin.ProtocolVersion} message ProtocolVersion
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ProtocolVersion.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this ProtocolVersion to JSON.
             * @function toJSON
             * @memberof fastcity.admin.ProtocolVersion
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ProtocolVersion.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Value enum.
             * @name fastcity.admin.ProtocolVersion.Value
             * @enum {number}
             * @property {number} PLACEHOLDER1=0 PLACEHOLDER1 value
             * @property {number} PLACEHOLDER2=0 PLACEHOLDER2 value
             * @property {number} MAJOR=5 MAJOR value
             * @property {number} MINOR=6 MINOR value
             * @property {number} PATCH=0 PATCH value
             */
            ProtocolVersion.Value = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "PLACEHOLDER1"] = 0;
                values["PLACEHOLDER2"] = 0;
                values[valuesById[5] = "MAJOR"] = 5;
                values[valuesById[6] = "MINOR"] = 6;
                values["PATCH"] = 0;
                return values;
            })();

            return ProtocolVersion;
        })();

        admin.GetServerVersion = (function() {

            /**
             * Properties of a GetServerVersion.
             * @memberof fastcity.admin
             * @interface IGetServerVersion
             */

            /**
             * Constructs a new GetServerVersion.
             * @memberof fastcity.admin
             * @classdesc Represents a GetServerVersion.
             * @implements IGetServerVersion
             * @constructor
             * @param {fastcity.admin.IGetServerVersion=} [properties] Properties to set
             */
            function GetServerVersion(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new GetServerVersion instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.GetServerVersion
             * @static
             * @param {fastcity.admin.IGetServerVersion=} [properties] Properties to set
             * @returns {fastcity.admin.GetServerVersion} GetServerVersion instance
             */
            GetServerVersion.create = function create(properties) {
                return new GetServerVersion(properties);
            };

            /**
             * Encodes the specified GetServerVersion message. Does not implicitly {@link fastcity.admin.GetServerVersion.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.GetServerVersion
             * @static
             * @param {fastcity.admin.IGetServerVersion} message GetServerVersion message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetServerVersion.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Decodes a GetServerVersion message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.GetServerVersion
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.GetServerVersion} GetServerVersion
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetServerVersion.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.GetServerVersion();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a GetServerVersion message.
             * @function verify
             * @memberof fastcity.admin.GetServerVersion
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetServerVersion.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a GetServerVersion message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.GetServerVersion
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.GetServerVersion} GetServerVersion
             */
            GetServerVersion.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.GetServerVersion)
                    return object;
                return new $root.fastcity.admin.GetServerVersion();
            };

            /**
             * Creates a plain object from a GetServerVersion message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.GetServerVersion
             * @static
             * @param {fastcity.admin.GetServerVersion} message GetServerVersion
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetServerVersion.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this GetServerVersion to JSON.
             * @function toJSON
             * @memberof fastcity.admin.GetServerVersion
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetServerVersion.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return GetServerVersion;
        })();

        return admin;
    })();

    fastcity.common = (function() {

        /**
         * Namespace common.
         * @memberof fastcity
         * @namespace
         */
        var common = {};

        common.Company = (function() {

            /**
             * Properties of a Company.
             * @memberof fastcity.common
             * @interface ICompany
             */

            /**
             * Constructs a new Company.
             * @memberof fastcity.common
             * @classdesc Represents a Company.
             * @implements ICompany
             * @constructor
             * @param {fastcity.common.ICompany=} [properties] Properties to set
             */
            function Company(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new Company instance using the specified properties.
             * @function create
             * @memberof fastcity.common.Company
             * @static
             * @param {fastcity.common.ICompany=} [properties] Properties to set
             * @returns {fastcity.common.Company} Company instance
             */
            Company.create = function create(properties) {
                return new Company(properties);
            };

            /**
             * Encodes the specified Company message. Does not implicitly {@link fastcity.common.Company.verify|verify} messages.
             * @function encode
             * @memberof fastcity.common.Company
             * @static
             * @param {fastcity.common.ICompany} message Company message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Company.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Decodes a Company message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.common.Company
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.common.Company} Company
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Company.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.common.Company();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a Company message.
             * @function verify
             * @memberof fastcity.common.Company
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Company.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a Company message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.common.Company
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.common.Company} Company
             */
            Company.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.common.Company)
                    return object;
                return new $root.fastcity.common.Company();
            };

            /**
             * Creates a plain object from a Company message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.common.Company
             * @static
             * @param {fastcity.common.Company} message Company
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Company.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this Company to JSON.
             * @function toJSON
             * @memberof fastcity.common.Company
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Company.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * MapType enum.
             * @name fastcity.common.Company.MapType
             * @enum {number}
             * @property {number} GOOGLE=0 GOOGLE value
             * @property {number} YANDEX=1 YANDEX value
             */
            Company.MapType = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "GOOGLE"] = 0;
                values[valuesById[1] = "YANDEX"] = 1;
                return values;
            })();

            return Company;
        })();

        /**
         * UserRole enum.
         * @name fastcity.common.UserRole
         * @enum {number}
         * @property {number} GLOBAL_ADMIN=0 GLOBAL_ADMIN value
         * @property {number} SUPPORT=1 SUPPORT value
         * @property {number} COMPANY_ADMIN=2 COMPANY_ADMIN value
         * @property {number} COMPANY_MANAGER=3 COMPANY_MANAGER value
         * @property {number} COMPANY_COURIER=4 COMPANY_COURIER value
         */
        common.UserRole = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "GLOBAL_ADMIN"] = 0;
            values[valuesById[1] = "SUPPORT"] = 1;
            values[valuesById[2] = "COMPANY_ADMIN"] = 2;
            values[valuesById[3] = "COMPANY_MANAGER"] = 3;
            values[valuesById[4] = "COMPANY_COURIER"] = 4;
            return values;
        })();

        common.Version = (function() {

            /**
             * Properties of a Version.
             * @memberof fastcity.common
             * @interface IVersion
             * @property {number|null} [major] Version major
             * @property {number|null} [minor] Version minor
             * @property {number|null} [patch] Version patch
             */

            /**
             * Constructs a new Version.
             * @memberof fastcity.common
             * @classdesc Represents a Version.
             * @implements IVersion
             * @constructor
             * @param {fastcity.common.IVersion=} [properties] Properties to set
             */
            function Version(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Version major.
             * @member {number} major
             * @memberof fastcity.common.Version
             * @instance
             */
            Version.prototype.major = 0;

            /**
             * Version minor.
             * @member {number} minor
             * @memberof fastcity.common.Version
             * @instance
             */
            Version.prototype.minor = 0;

            /**
             * Version patch.
             * @member {number} patch
             * @memberof fastcity.common.Version
             * @instance
             */
            Version.prototype.patch = 0;

            /**
             * Creates a new Version instance using the specified properties.
             * @function create
             * @memberof fastcity.common.Version
             * @static
             * @param {fastcity.common.IVersion=} [properties] Properties to set
             * @returns {fastcity.common.Version} Version instance
             */
            Version.create = function create(properties) {
                return new Version(properties);
            };

            /**
             * Encodes the specified Version message. Does not implicitly {@link fastcity.common.Version.verify|verify} messages.
             * @function encode
             * @memberof fastcity.common.Version
             * @static
             * @param {fastcity.common.IVersion} message Version message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Version.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.major != null && Object.hasOwnProperty.call(message, "major"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.major);
                if (message.minor != null && Object.hasOwnProperty.call(message, "minor"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.minor);
                if (message.patch != null && Object.hasOwnProperty.call(message, "patch"))
                    writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.patch);
                return writer;
            };

            /**
             * Decodes a Version message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.common.Version
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.common.Version} Version
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Version.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.common.Version();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.major = reader.uint32();
                        break;
                    case 2:
                        message.minor = reader.uint32();
                        break;
                    case 3:
                        message.patch = reader.uint32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a Version message.
             * @function verify
             * @memberof fastcity.common.Version
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Version.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.major != null && message.hasOwnProperty("major"))
                    if (!$util.isInteger(message.major))
                        return "major: integer expected";
                if (message.minor != null && message.hasOwnProperty("minor"))
                    if (!$util.isInteger(message.minor))
                        return "minor: integer expected";
                if (message.patch != null && message.hasOwnProperty("patch"))
                    if (!$util.isInteger(message.patch))
                        return "patch: integer expected";
                return null;
            };

            /**
             * Creates a Version message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.common.Version
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.common.Version} Version
             */
            Version.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.common.Version)
                    return object;
                var message = new $root.fastcity.common.Version();
                if (object.major != null)
                    message.major = object.major >>> 0;
                if (object.minor != null)
                    message.minor = object.minor >>> 0;
                if (object.patch != null)
                    message.patch = object.patch >>> 0;
                return message;
            };

            /**
             * Creates a plain object from a Version message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.common.Version
             * @static
             * @param {fastcity.common.Version} message Version
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Version.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.major = 0;
                    object.minor = 0;
                    object.patch = 0;
                }
                if (message.major != null && message.hasOwnProperty("major"))
                    object.major = message.major;
                if (message.minor != null && message.hasOwnProperty("minor"))
                    object.minor = message.minor;
                if (message.patch != null && message.hasOwnProperty("patch"))
                    object.patch = message.patch;
                return object;
            };

            /**
             * Converts this Version to JSON.
             * @function toJSON
             * @memberof fastcity.common.Version
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Version.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Version;
        })();

        common.SortOrder = (function() {

            /**
             * Properties of a SortOrder.
             * @memberof fastcity.common
             * @interface ISortOrder
             */

            /**
             * Constructs a new SortOrder.
             * @memberof fastcity.common
             * @classdesc Represents a SortOrder.
             * @implements ISortOrder
             * @constructor
             * @param {fastcity.common.ISortOrder=} [properties] Properties to set
             */
            function SortOrder(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new SortOrder instance using the specified properties.
             * @function create
             * @memberof fastcity.common.SortOrder
             * @static
             * @param {fastcity.common.ISortOrder=} [properties] Properties to set
             * @returns {fastcity.common.SortOrder} SortOrder instance
             */
            SortOrder.create = function create(properties) {
                return new SortOrder(properties);
            };

            /**
             * Encodes the specified SortOrder message. Does not implicitly {@link fastcity.common.SortOrder.verify|verify} messages.
             * @function encode
             * @memberof fastcity.common.SortOrder
             * @static
             * @param {fastcity.common.ISortOrder} message SortOrder message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SortOrder.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Decodes a SortOrder message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.common.SortOrder
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.common.SortOrder} SortOrder
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SortOrder.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.common.SortOrder();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a SortOrder message.
             * @function verify
             * @memberof fastcity.common.SortOrder
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SortOrder.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a SortOrder message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.common.SortOrder
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.common.SortOrder} SortOrder
             */
            SortOrder.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.common.SortOrder)
                    return object;
                return new $root.fastcity.common.SortOrder();
            };

            /**
             * Creates a plain object from a SortOrder message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.common.SortOrder
             * @static
             * @param {fastcity.common.SortOrder} message SortOrder
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SortOrder.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this SortOrder to JSON.
             * @function toJSON
             * @memberof fastcity.common.SortOrder
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SortOrder.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Order enum.
             * @name fastcity.common.SortOrder.Order
             * @enum {number}
             * @property {number} ASC=0 ASC value
             * @property {number} DESC=1 DESC value
             */
            SortOrder.Order = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "ASC"] = 0;
                values[valuesById[1] = "DESC"] = 1;
                return values;
            })();

            return SortOrder;
        })();

        /**
         * Source enum.
         * @name fastcity.common.Source
         * @enum {number}
         * @property {number} UNKNOWN=0 UNKNOWN value
         * @property {number} IIKO=1 IIKO value
         */
        common.Source = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "UNKNOWN"] = 0;
            values[valuesById[1] = "IIKO"] = 1;
            return values;
        })();

        common.OrderItem = (function() {

            /**
             * Properties of an OrderItem.
             * @memberof fastcity.common
             * @interface IOrderItem
             */

            /**
             * Constructs a new OrderItem.
             * @memberof fastcity.common
             * @classdesc Represents an OrderItem.
             * @implements IOrderItem
             * @constructor
             * @param {fastcity.common.IOrderItem=} [properties] Properties to set
             */
            function OrderItem(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new OrderItem instance using the specified properties.
             * @function create
             * @memberof fastcity.common.OrderItem
             * @static
             * @param {fastcity.common.IOrderItem=} [properties] Properties to set
             * @returns {fastcity.common.OrderItem} OrderItem instance
             */
            OrderItem.create = function create(properties) {
                return new OrderItem(properties);
            };

            /**
             * Encodes the specified OrderItem message. Does not implicitly {@link fastcity.common.OrderItem.verify|verify} messages.
             * @function encode
             * @memberof fastcity.common.OrderItem
             * @static
             * @param {fastcity.common.IOrderItem} message OrderItem message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            OrderItem.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Decodes an OrderItem message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.common.OrderItem
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.common.OrderItem} OrderItem
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            OrderItem.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.common.OrderItem();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an OrderItem message.
             * @function verify
             * @memberof fastcity.common.OrderItem
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            OrderItem.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates an OrderItem message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.common.OrderItem
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.common.OrderItem} OrderItem
             */
            OrderItem.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.common.OrderItem)
                    return object;
                return new $root.fastcity.common.OrderItem();
            };

            /**
             * Creates a plain object from an OrderItem message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.common.OrderItem
             * @static
             * @param {fastcity.common.OrderItem} message OrderItem
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            OrderItem.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this OrderItem to JSON.
             * @function toJSON
             * @memberof fastcity.common.OrderItem
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            OrderItem.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * ProductType enum.
             * @name fastcity.common.OrderItem.ProductType
             * @enum {number}
             * @property {number} T_UNKNOWN=0 T_UNKNOWN value
             * @property {number} T_DESSERT=1 T_DESSERT value
             * @property {number} T_SNACK=2 T_SNACK value
             * @property {number} T_DRINK=3 T_DRINK value
             * @property {number} T_BEER=4 T_BEER value
             * @property {number} T_PIZZA=5 T_PIZZA value
             * @property {number} T_GIFT=6 T_GIFT value
             * @property {number} T_SALAD=7 T_SALAD value
             * @property {number} T_BREAD=8 T_BREAD value
             * @property {number} T_SAUCE=9 T_SAUCE value
             * @property {number} T_COMPOUND=10 T_COMPOUND value
             * @property {number} T_SERVICE=11 T_SERVICE value
             */
            OrderItem.ProductType = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "T_UNKNOWN"] = 0;
                values[valuesById[1] = "T_DESSERT"] = 1;
                values[valuesById[2] = "T_SNACK"] = 2;
                values[valuesById[3] = "T_DRINK"] = 3;
                values[valuesById[4] = "T_BEER"] = 4;
                values[valuesById[5] = "T_PIZZA"] = 5;
                values[valuesById[6] = "T_GIFT"] = 6;
                values[valuesById[7] = "T_SALAD"] = 7;
                values[valuesById[8] = "T_BREAD"] = 8;
                values[valuesById[9] = "T_SAUCE"] = 9;
                values[valuesById[10] = "T_COMPOUND"] = 10;
                values[valuesById[11] = "T_SERVICE"] = 11;
                return values;
            })();

            return OrderItem;
        })();

        common.Order = (function() {

            /**
             * Properties of an Order.
             * @memberof fastcity.common
             * @interface IOrder
             */

            /**
             * Constructs a new Order.
             * @memberof fastcity.common
             * @classdesc Represents an Order.
             * @implements IOrder
             * @constructor
             * @param {fastcity.common.IOrder=} [properties] Properties to set
             */
            function Order(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new Order instance using the specified properties.
             * @function create
             * @memberof fastcity.common.Order
             * @static
             * @param {fastcity.common.IOrder=} [properties] Properties to set
             * @returns {fastcity.common.Order} Order instance
             */
            Order.create = function create(properties) {
                return new Order(properties);
            };

            /**
             * Encodes the specified Order message. Does not implicitly {@link fastcity.common.Order.verify|verify} messages.
             * @function encode
             * @memberof fastcity.common.Order
             * @static
             * @param {fastcity.common.IOrder} message Order message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Order.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Decodes an Order message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.common.Order
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.common.Order} Order
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Order.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.common.Order();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an Order message.
             * @function verify
             * @memberof fastcity.common.Order
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Order.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates an Order message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.common.Order
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.common.Order} Order
             */
            Order.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.common.Order)
                    return object;
                return new $root.fastcity.common.Order();
            };

            /**
             * Creates a plain object from an Order message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.common.Order
             * @static
             * @param {fastcity.common.Order} message Order
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Order.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this Order to JSON.
             * @function toJSON
             * @memberof fastcity.common.Order
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Order.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Status enum.
             * @name fastcity.common.Order.Status
             * @enum {number}
             * @property {number} S_UNKNOWN=0 S_UNKNOWN value
             * @property {number} S_IN_PROGRESS=1 S_IN_PROGRESS value
             * @property {number} S_MAKELINE=2 S_MAKELINE value
             * @property {number} S_ROUTING_STATION=3 S_ROUTING_STATION value
             * @property {number} S_OUT_THE_DOOR=4 S_OUT_THE_DOOR value
             * @property {number} S_COMPLETE=5 S_COMPLETE value
             * @property {number} S_GIFT=6 S_GIFT value
             * @property {number} S_BAD=7 S_BAD value
             * @property {number} S_VOID=8 S_VOID value
             * @property {number} S_BEING_TAKEN=9 S_BEING_TAKEN value
             * @property {number} S_ABANDONED=10 S_ABANDONED value
             * @property {number} S_SUSPENDED=11 S_SUSPENDED value
             * @property {number} S_GIFT_CARD_PURCHASE=12 S_GIFT_CARD_PURCHASE value
             * @property {number} S_GIFT_CARD_VOID=13 S_GIFT_CARD_VOID value
             * @property {number} S_TRANSFERRED=14 S_TRANSFERRED value
             * @property {number} S_HISTORIC_ORDER=15 S_HISTORIC_ORDER value
             * @property {number} S_BEING_ASSIGNED=16 S_BEING_ASSIGNED value
             */
            Order.Status = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "S_UNKNOWN"] = 0;
                values[valuesById[1] = "S_IN_PROGRESS"] = 1;
                values[valuesById[2] = "S_MAKELINE"] = 2;
                values[valuesById[3] = "S_ROUTING_STATION"] = 3;
                values[valuesById[4] = "S_OUT_THE_DOOR"] = 4;
                values[valuesById[5] = "S_COMPLETE"] = 5;
                values[valuesById[6] = "S_GIFT"] = 6;
                values[valuesById[7] = "S_BAD"] = 7;
                values[valuesById[8] = "S_VOID"] = 8;
                values[valuesById[9] = "S_BEING_TAKEN"] = 9;
                values[valuesById[10] = "S_ABANDONED"] = 10;
                values[valuesById[11] = "S_SUSPENDED"] = 11;
                values[valuesById[12] = "S_GIFT_CARD_PURCHASE"] = 12;
                values[valuesById[13] = "S_GIFT_CARD_VOID"] = 13;
                values[valuesById[14] = "S_TRANSFERRED"] = 14;
                values[valuesById[15] = "S_HISTORIC_ORDER"] = 15;
                values[valuesById[16] = "S_BEING_ASSIGNED"] = 16;
                return values;
            })();

            /**
             * PaymentType enum.
             * @name fastcity.common.Order.PaymentType
             * @enum {number}
             * @property {number} T_UNKNOWN=0 T_UNKNOWN value
             * @property {number} T_PAID=1 T_PAID value
             * @property {number} T_BY_CASH_TO_THE_COURIER=2 T_BY_CASH_TO_THE_COURIER value
             * @property {number} T_BY_CARD_TO_THE_COURIER=3 T_BY_CARD_TO_THE_COURIER value
             * @property {number} T_PAID_BY_CASH=4 T_PAID_BY_CASH value
             * @property {number} T_PAID_BY_CARD=5 T_PAID_BY_CARD value
             * @property {number} T_PAID_BY_CHECK=6 T_PAID_BY_CHECK value
             * @property {number} T_PAID_BY_CREDIT=7 T_PAID_BY_CREDIT value
             * @property {number} T_PAID_WRITEOFF=8 T_PAID_WRITEOFF value
             * @property {number} T_PAID_BY_VOUCHER=9 T_PAID_BY_VOUCHER value
             * @property {number} T_PAID_FROM_EXTERNAL=10 T_PAID_FROM_EXTERNAL value
             * @property {number} T_PAID_SMART_SALE=11 T_PAID_SMART_SALE value
             * @property {number} T_PAID_BY_SBERBANK=12 T_PAID_BY_SBERBANK value
             * @property {number} T_PAID_BY_TRPOS=13 T_PAID_BY_TRPOS value
             */
            Order.PaymentType = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "T_UNKNOWN"] = 0;
                values[valuesById[1] = "T_PAID"] = 1;
                values[valuesById[2] = "T_BY_CASH_TO_THE_COURIER"] = 2;
                values[valuesById[3] = "T_BY_CARD_TO_THE_COURIER"] = 3;
                values[valuesById[4] = "T_PAID_BY_CASH"] = 4;
                values[valuesById[5] = "T_PAID_BY_CARD"] = 5;
                values[valuesById[6] = "T_PAID_BY_CHECK"] = 6;
                values[valuesById[7] = "T_PAID_BY_CREDIT"] = 7;
                values[valuesById[8] = "T_PAID_WRITEOFF"] = 8;
                values[valuesById[9] = "T_PAID_BY_VOUCHER"] = 9;
                values[valuesById[10] = "T_PAID_FROM_EXTERNAL"] = 10;
                values[valuesById[11] = "T_PAID_SMART_SALE"] = 11;
                values[valuesById[12] = "T_PAID_BY_SBERBANK"] = 12;
                values[valuesById[13] = "T_PAID_BY_TRPOS"] = 13;
                return values;
            })();

            return Order;
        })();

        common.Coordinate = (function() {

            /**
             * Properties of a Coordinate.
             * @memberof fastcity.common
             * @interface ICoordinate
             * @property {number|null} [latitude] Coordinate latitude
             * @property {number|null} [longitude] Coordinate longitude
             */

            /**
             * Constructs a new Coordinate.
             * @memberof fastcity.common
             * @classdesc Represents a Coordinate.
             * @implements ICoordinate
             * @constructor
             * @param {fastcity.common.ICoordinate=} [properties] Properties to set
             */
            function Coordinate(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Coordinate latitude.
             * @member {number} latitude
             * @memberof fastcity.common.Coordinate
             * @instance
             */
            Coordinate.prototype.latitude = 0;

            /**
             * Coordinate longitude.
             * @member {number} longitude
             * @memberof fastcity.common.Coordinate
             * @instance
             */
            Coordinate.prototype.longitude = 0;

            /**
             * Creates a new Coordinate instance using the specified properties.
             * @function create
             * @memberof fastcity.common.Coordinate
             * @static
             * @param {fastcity.common.ICoordinate=} [properties] Properties to set
             * @returns {fastcity.common.Coordinate} Coordinate instance
             */
            Coordinate.create = function create(properties) {
                return new Coordinate(properties);
            };

            /**
             * Encodes the specified Coordinate message. Does not implicitly {@link fastcity.common.Coordinate.verify|verify} messages.
             * @function encode
             * @memberof fastcity.common.Coordinate
             * @static
             * @param {fastcity.common.ICoordinate} message Coordinate message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Coordinate.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.latitude != null && Object.hasOwnProperty.call(message, "latitude"))
                    writer.uint32(/* id 1, wireType 1 =*/9).double(message.latitude);
                if (message.longitude != null && Object.hasOwnProperty.call(message, "longitude"))
                    writer.uint32(/* id 2, wireType 1 =*/17).double(message.longitude);
                return writer;
            };

            /**
             * Decodes a Coordinate message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.common.Coordinate
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.common.Coordinate} Coordinate
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Coordinate.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.common.Coordinate();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.latitude = reader.double();
                        break;
                    case 2:
                        message.longitude = reader.double();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a Coordinate message.
             * @function verify
             * @memberof fastcity.common.Coordinate
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Coordinate.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.latitude != null && message.hasOwnProperty("latitude"))
                    if (typeof message.latitude !== "number")
                        return "latitude: number expected";
                if (message.longitude != null && message.hasOwnProperty("longitude"))
                    if (typeof message.longitude !== "number")
                        return "longitude: number expected";
                return null;
            };

            /**
             * Creates a Coordinate message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.common.Coordinate
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.common.Coordinate} Coordinate
             */
            Coordinate.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.common.Coordinate)
                    return object;
                var message = new $root.fastcity.common.Coordinate();
                if (object.latitude != null)
                    message.latitude = Number(object.latitude);
                if (object.longitude != null)
                    message.longitude = Number(object.longitude);
                return message;
            };

            /**
             * Creates a plain object from a Coordinate message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.common.Coordinate
             * @static
             * @param {fastcity.common.Coordinate} message Coordinate
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Coordinate.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.latitude = 0;
                    object.longitude = 0;
                }
                if (message.latitude != null && message.hasOwnProperty("latitude"))
                    object.latitude = options.json && !isFinite(message.latitude) ? String(message.latitude) : message.latitude;
                if (message.longitude != null && message.hasOwnProperty("longitude"))
                    object.longitude = options.json && !isFinite(message.longitude) ? String(message.longitude) : message.longitude;
                return object;
            };

            /**
             * Converts this Coordinate to JSON.
             * @function toJSON
             * @memberof fastcity.common.Coordinate
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Coordinate.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Coordinate;
        })();

        /**
         * OrderPathRequestResult enum.
         * @name fastcity.common.OrderPathRequestResult
         * @enum {number}
         * @property {number} OPRR_NOT_FOUND=0 OPRR_NOT_FOUND value
         * @property {number} OPRR_BAD_ORDER_ID_ORDER_NOT_FOUND=1 OPRR_BAD_ORDER_ID_ORDER_NOT_FOUND value
         * @property {number} OPRR_NOT_FOUND_SUB_COMPANY_OF_ORDER=2 OPRR_NOT_FOUND_SUB_COMPANY_OF_ORDER value
         */
        common.OrderPathRequestResult = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "OPRR_NOT_FOUND"] = 0;
            values[valuesById[1] = "OPRR_BAD_ORDER_ID_ORDER_NOT_FOUND"] = 1;
            values[valuesById[2] = "OPRR_NOT_FOUND_SUB_COMPANY_OF_ORDER"] = 2;
            return values;
        })();

        common.OrderPath = (function() {

            /**
             * Properties of an OrderPath.
             * @memberof fastcity.common
             * @interface IOrderPath
             * @property {number|Long|null} [orderId] OrderPath orderId
             * @property {Array.<fastcity.common.ICoordinate>|null} [coordinates] OrderPath coordinates
             * @property {fastcity.common.OrderPath.Type|null} [type] OrderPath type
             * @property {number|null} [inMapLength] OrderPath inMapLength
             * @property {number|null} [realLength] OrderPath realLength
             */

            /**
             * Constructs a new OrderPath.
             * @memberof fastcity.common
             * @classdesc Represents an OrderPath.
             * @implements IOrderPath
             * @constructor
             * @param {fastcity.common.IOrderPath=} [properties] Properties to set
             */
            function OrderPath(properties) {
                this.coordinates = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * OrderPath orderId.
             * @member {number|Long} orderId
             * @memberof fastcity.common.OrderPath
             * @instance
             */
            OrderPath.prototype.orderId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * OrderPath coordinates.
             * @member {Array.<fastcity.common.ICoordinate>} coordinates
             * @memberof fastcity.common.OrderPath
             * @instance
             */
            OrderPath.prototype.coordinates = $util.emptyArray;

            /**
             * OrderPath type.
             * @member {fastcity.common.OrderPath.Type} type
             * @memberof fastcity.common.OrderPath
             * @instance
             */
            OrderPath.prototype.type = 0;

            /**
             * OrderPath inMapLength.
             * @member {number} inMapLength
             * @memberof fastcity.common.OrderPath
             * @instance
             */
            OrderPath.prototype.inMapLength = 0;

            /**
             * OrderPath realLength.
             * @member {number} realLength
             * @memberof fastcity.common.OrderPath
             * @instance
             */
            OrderPath.prototype.realLength = 0;

            /**
             * Creates a new OrderPath instance using the specified properties.
             * @function create
             * @memberof fastcity.common.OrderPath
             * @static
             * @param {fastcity.common.IOrderPath=} [properties] Properties to set
             * @returns {fastcity.common.OrderPath} OrderPath instance
             */
            OrderPath.create = function create(properties) {
                return new OrderPath(properties);
            };

            /**
             * Encodes the specified OrderPath message. Does not implicitly {@link fastcity.common.OrderPath.verify|verify} messages.
             * @function encode
             * @memberof fastcity.common.OrderPath
             * @static
             * @param {fastcity.common.IOrderPath} message OrderPath message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            OrderPath.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.orderId != null && Object.hasOwnProperty.call(message, "orderId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.orderId);
                if (message.coordinates != null && message.coordinates.length)
                    for (var i = 0; i < message.coordinates.length; ++i)
                        $root.fastcity.common.Coordinate.encode(message.coordinates[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.type);
                if (message.inMapLength != null && Object.hasOwnProperty.call(message, "inMapLength"))
                    writer.uint32(/* id 4, wireType 1 =*/33).double(message.inMapLength);
                if (message.realLength != null && Object.hasOwnProperty.call(message, "realLength"))
                    writer.uint32(/* id 5, wireType 1 =*/41).double(message.realLength);
                return writer;
            };

            /**
             * Decodes an OrderPath message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.common.OrderPath
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.common.OrderPath} OrderPath
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            OrderPath.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.common.OrderPath();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.orderId = reader.int64();
                        break;
                    case 2:
                        if (!(message.coordinates && message.coordinates.length))
                            message.coordinates = [];
                        message.coordinates.push($root.fastcity.common.Coordinate.decode(reader, reader.uint32()));
                        break;
                    case 3:
                        message.type = reader.int32();
                        break;
                    case 4:
                        message.inMapLength = reader.double();
                        break;
                    case 5:
                        message.realLength = reader.double();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an OrderPath message.
             * @function verify
             * @memberof fastcity.common.OrderPath
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            OrderPath.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.orderId != null && message.hasOwnProperty("orderId"))
                    if (!$util.isInteger(message.orderId) && !(message.orderId && $util.isInteger(message.orderId.low) && $util.isInteger(message.orderId.high)))
                        return "orderId: integer|Long expected";
                if (message.coordinates != null && message.hasOwnProperty("coordinates")) {
                    if (!Array.isArray(message.coordinates))
                        return "coordinates: array expected";
                    for (var i = 0; i < message.coordinates.length; ++i) {
                        var error = $root.fastcity.common.Coordinate.verify(message.coordinates[i]);
                        if (error)
                            return "coordinates." + error;
                    }
                }
                if (message.type != null && message.hasOwnProperty("type"))
                    switch (message.type) {
                    default:
                        return "type: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
                if (message.inMapLength != null && message.hasOwnProperty("inMapLength"))
                    if (typeof message.inMapLength !== "number")
                        return "inMapLength: number expected";
                if (message.realLength != null && message.hasOwnProperty("realLength"))
                    if (typeof message.realLength !== "number")
                        return "realLength: number expected";
                return null;
            };

            /**
             * Creates an OrderPath message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.common.OrderPath
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.common.OrderPath} OrderPath
             */
            OrderPath.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.common.OrderPath)
                    return object;
                var message = new $root.fastcity.common.OrderPath();
                if (object.orderId != null)
                    if ($util.Long)
                        (message.orderId = $util.Long.fromValue(object.orderId)).unsigned = false;
                    else if (typeof object.orderId === "string")
                        message.orderId = parseInt(object.orderId, 10);
                    else if (typeof object.orderId === "number")
                        message.orderId = object.orderId;
                    else if (typeof object.orderId === "object")
                        message.orderId = new $util.LongBits(object.orderId.low >>> 0, object.orderId.high >>> 0).toNumber();
                if (object.coordinates) {
                    if (!Array.isArray(object.coordinates))
                        throw TypeError(".fastcity.common.OrderPath.coordinates: array expected");
                    message.coordinates = [];
                    for (var i = 0; i < object.coordinates.length; ++i) {
                        if (typeof object.coordinates[i] !== "object")
                            throw TypeError(".fastcity.common.OrderPath.coordinates: object expected");
                        message.coordinates[i] = $root.fastcity.common.Coordinate.fromObject(object.coordinates[i]);
                    }
                }
                switch (object.type) {
                case "T_ON_FOOT":
                case 0:
                    message.type = 0;
                    break;
                case "T_CAR":
                case 1:
                    message.type = 1;
                    break;
                case "T_BICYCLE":
                case 2:
                    message.type = 2;
                    break;
                case "T_PUBLIC_TRANSPORT":
                case 3:
                    message.type = 3;
                    break;
                }
                if (object.inMapLength != null)
                    message.inMapLength = Number(object.inMapLength);
                if (object.realLength != null)
                    message.realLength = Number(object.realLength);
                return message;
            };

            /**
             * Creates a plain object from an OrderPath message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.common.OrderPath
             * @static
             * @param {fastcity.common.OrderPath} message OrderPath
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            OrderPath.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.coordinates = [];
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.orderId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.orderId = options.longs === String ? "0" : 0;
                    object.type = options.enums === String ? "T_ON_FOOT" : 0;
                    object.inMapLength = 0;
                    object.realLength = 0;
                }
                if (message.orderId != null && message.hasOwnProperty("orderId"))
                    if (typeof message.orderId === "number")
                        object.orderId = options.longs === String ? String(message.orderId) : message.orderId;
                    else
                        object.orderId = options.longs === String ? $util.Long.prototype.toString.call(message.orderId) : options.longs === Number ? new $util.LongBits(message.orderId.low >>> 0, message.orderId.high >>> 0).toNumber() : message.orderId;
                if (message.coordinates && message.coordinates.length) {
                    object.coordinates = [];
                    for (var j = 0; j < message.coordinates.length; ++j)
                        object.coordinates[j] = $root.fastcity.common.Coordinate.toObject(message.coordinates[j], options);
                }
                if (message.type != null && message.hasOwnProperty("type"))
                    object.type = options.enums === String ? $root.fastcity.common.OrderPath.Type[message.type] : message.type;
                if (message.inMapLength != null && message.hasOwnProperty("inMapLength"))
                    object.inMapLength = options.json && !isFinite(message.inMapLength) ? String(message.inMapLength) : message.inMapLength;
                if (message.realLength != null && message.hasOwnProperty("realLength"))
                    object.realLength = options.json && !isFinite(message.realLength) ? String(message.realLength) : message.realLength;
                return object;
            };

            /**
             * Converts this OrderPath to JSON.
             * @function toJSON
             * @memberof fastcity.common.OrderPath
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            OrderPath.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Type enum.
             * @name fastcity.common.OrderPath.Type
             * @enum {number}
             * @property {number} T_ON_FOOT=0 T_ON_FOOT value
             * @property {number} T_CAR=1 T_CAR value
             * @property {number} T_BICYCLE=2 T_BICYCLE value
             * @property {number} T_PUBLIC_TRANSPORT=3 T_PUBLIC_TRANSPORT value
             */
            OrderPath.Type = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "T_ON_FOOT"] = 0;
                values[valuesById[1] = "T_CAR"] = 1;
                values[valuesById[2] = "T_BICYCLE"] = 2;
                values[valuesById[3] = "T_PUBLIC_TRANSPORT"] = 3;
                return values;
            })();

            return OrderPath;
        })();

        common.OrderPathList = (function() {

            /**
             * Properties of an OrderPathList.
             * @memberof fastcity.common
             * @interface IOrderPathList
             * @property {Array.<fastcity.common.IOrderPath>|null} [orderPaths] OrderPathList orderPaths
             */

            /**
             * Constructs a new OrderPathList.
             * @memberof fastcity.common
             * @classdesc Represents an OrderPathList.
             * @implements IOrderPathList
             * @constructor
             * @param {fastcity.common.IOrderPathList=} [properties] Properties to set
             */
            function OrderPathList(properties) {
                this.orderPaths = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * OrderPathList orderPaths.
             * @member {Array.<fastcity.common.IOrderPath>} orderPaths
             * @memberof fastcity.common.OrderPathList
             * @instance
             */
            OrderPathList.prototype.orderPaths = $util.emptyArray;

            /**
             * Creates a new OrderPathList instance using the specified properties.
             * @function create
             * @memberof fastcity.common.OrderPathList
             * @static
             * @param {fastcity.common.IOrderPathList=} [properties] Properties to set
             * @returns {fastcity.common.OrderPathList} OrderPathList instance
             */
            OrderPathList.create = function create(properties) {
                return new OrderPathList(properties);
            };

            /**
             * Encodes the specified OrderPathList message. Does not implicitly {@link fastcity.common.OrderPathList.verify|verify} messages.
             * @function encode
             * @memberof fastcity.common.OrderPathList
             * @static
             * @param {fastcity.common.IOrderPathList} message OrderPathList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            OrderPathList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.orderPaths != null && message.orderPaths.length)
                    for (var i = 0; i < message.orderPaths.length; ++i)
                        $root.fastcity.common.OrderPath.encode(message.orderPaths[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes an OrderPathList message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.common.OrderPathList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.common.OrderPathList} OrderPathList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            OrderPathList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.common.OrderPathList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.orderPaths && message.orderPaths.length))
                            message.orderPaths = [];
                        message.orderPaths.push($root.fastcity.common.OrderPath.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an OrderPathList message.
             * @function verify
             * @memberof fastcity.common.OrderPathList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            OrderPathList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.orderPaths != null && message.hasOwnProperty("orderPaths")) {
                    if (!Array.isArray(message.orderPaths))
                        return "orderPaths: array expected";
                    for (var i = 0; i < message.orderPaths.length; ++i) {
                        var error = $root.fastcity.common.OrderPath.verify(message.orderPaths[i]);
                        if (error)
                            return "orderPaths." + error;
                    }
                }
                return null;
            };

            /**
             * Creates an OrderPathList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.common.OrderPathList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.common.OrderPathList} OrderPathList
             */
            OrderPathList.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.common.OrderPathList)
                    return object;
                var message = new $root.fastcity.common.OrderPathList();
                if (object.orderPaths) {
                    if (!Array.isArray(object.orderPaths))
                        throw TypeError(".fastcity.common.OrderPathList.orderPaths: array expected");
                    message.orderPaths = [];
                    for (var i = 0; i < object.orderPaths.length; ++i) {
                        if (typeof object.orderPaths[i] !== "object")
                            throw TypeError(".fastcity.common.OrderPathList.orderPaths: object expected");
                        message.orderPaths[i] = $root.fastcity.common.OrderPath.fromObject(object.orderPaths[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from an OrderPathList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.common.OrderPathList
             * @static
             * @param {fastcity.common.OrderPathList} message OrderPathList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            OrderPathList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.orderPaths = [];
                if (message.orderPaths && message.orderPaths.length) {
                    object.orderPaths = [];
                    for (var j = 0; j < message.orderPaths.length; ++j)
                        object.orderPaths[j] = $root.fastcity.common.OrderPath.toObject(message.orderPaths[j], options);
                }
                return object;
            };

            /**
             * Converts this OrderPathList to JSON.
             * @function toJSON
             * @memberof fastcity.common.OrderPathList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            OrderPathList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return OrderPathList;
        })();

        common.GetOrderPath = (function() {

            /**
             * Properties of a GetOrderPath.
             * @memberof fastcity.common
             * @interface IGetOrderPath
             * @property {number|Long|null} [orderId] GetOrderPath orderId
             * @property {fastcity.common.OrderPath.Type|null} [type] GetOrderPath type
             */

            /**
             * Constructs a new GetOrderPath.
             * @memberof fastcity.common
             * @classdesc Represents a GetOrderPath.
             * @implements IGetOrderPath
             * @constructor
             * @param {fastcity.common.IGetOrderPath=} [properties] Properties to set
             */
            function GetOrderPath(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GetOrderPath orderId.
             * @member {number|Long} orderId
             * @memberof fastcity.common.GetOrderPath
             * @instance
             */
            GetOrderPath.prototype.orderId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * GetOrderPath type.
             * @member {fastcity.common.OrderPath.Type} type
             * @memberof fastcity.common.GetOrderPath
             * @instance
             */
            GetOrderPath.prototype.type = 0;

            /**
             * Creates a new GetOrderPath instance using the specified properties.
             * @function create
             * @memberof fastcity.common.GetOrderPath
             * @static
             * @param {fastcity.common.IGetOrderPath=} [properties] Properties to set
             * @returns {fastcity.common.GetOrderPath} GetOrderPath instance
             */
            GetOrderPath.create = function create(properties) {
                return new GetOrderPath(properties);
            };

            /**
             * Encodes the specified GetOrderPath message. Does not implicitly {@link fastcity.common.GetOrderPath.verify|verify} messages.
             * @function encode
             * @memberof fastcity.common.GetOrderPath
             * @static
             * @param {fastcity.common.IGetOrderPath} message GetOrderPath message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetOrderPath.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.orderId != null && Object.hasOwnProperty.call(message, "orderId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.orderId);
                if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.type);
                return writer;
            };

            /**
             * Decodes a GetOrderPath message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.common.GetOrderPath
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.common.GetOrderPath} GetOrderPath
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetOrderPath.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.common.GetOrderPath();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.orderId = reader.int64();
                        break;
                    case 2:
                        message.type = reader.int32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a GetOrderPath message.
             * @function verify
             * @memberof fastcity.common.GetOrderPath
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetOrderPath.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.orderId != null && message.hasOwnProperty("orderId"))
                    if (!$util.isInteger(message.orderId) && !(message.orderId && $util.isInteger(message.orderId.low) && $util.isInteger(message.orderId.high)))
                        return "orderId: integer|Long expected";
                if (message.type != null && message.hasOwnProperty("type"))
                    switch (message.type) {
                    default:
                        return "type: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
                return null;
            };

            /**
             * Creates a GetOrderPath message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.common.GetOrderPath
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.common.GetOrderPath} GetOrderPath
             */
            GetOrderPath.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.common.GetOrderPath)
                    return object;
                var message = new $root.fastcity.common.GetOrderPath();
                if (object.orderId != null)
                    if ($util.Long)
                        (message.orderId = $util.Long.fromValue(object.orderId)).unsigned = false;
                    else if (typeof object.orderId === "string")
                        message.orderId = parseInt(object.orderId, 10);
                    else if (typeof object.orderId === "number")
                        message.orderId = object.orderId;
                    else if (typeof object.orderId === "object")
                        message.orderId = new $util.LongBits(object.orderId.low >>> 0, object.orderId.high >>> 0).toNumber();
                switch (object.type) {
                case "T_ON_FOOT":
                case 0:
                    message.type = 0;
                    break;
                case "T_CAR":
                case 1:
                    message.type = 1;
                    break;
                case "T_BICYCLE":
                case 2:
                    message.type = 2;
                    break;
                case "T_PUBLIC_TRANSPORT":
                case 3:
                    message.type = 3;
                    break;
                }
                return message;
            };

            /**
             * Creates a plain object from a GetOrderPath message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.common.GetOrderPath
             * @static
             * @param {fastcity.common.GetOrderPath} message GetOrderPath
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetOrderPath.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.orderId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.orderId = options.longs === String ? "0" : 0;
                    object.type = options.enums === String ? "T_ON_FOOT" : 0;
                }
                if (message.orderId != null && message.hasOwnProperty("orderId"))
                    if (typeof message.orderId === "number")
                        object.orderId = options.longs === String ? String(message.orderId) : message.orderId;
                    else
                        object.orderId = options.longs === String ? $util.Long.prototype.toString.call(message.orderId) : options.longs === Number ? new $util.LongBits(message.orderId.low >>> 0, message.orderId.high >>> 0).toNumber() : message.orderId;
                if (message.type != null && message.hasOwnProperty("type"))
                    object.type = options.enums === String ? $root.fastcity.common.OrderPath.Type[message.type] : message.type;
                return object;
            };

            /**
             * Converts this GetOrderPath to JSON.
             * @function toJSON
             * @memberof fastcity.common.GetOrderPath
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetOrderPath.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return GetOrderPath;
        })();

        common.GetOrderPathList = (function() {

            /**
             * Properties of a GetOrderPathList.
             * @memberof fastcity.common
             * @interface IGetOrderPathList
             * @property {number|Long|null} [orderId] GetOrderPathList orderId
             */

            /**
             * Constructs a new GetOrderPathList.
             * @memberof fastcity.common
             * @classdesc Represents a GetOrderPathList.
             * @implements IGetOrderPathList
             * @constructor
             * @param {fastcity.common.IGetOrderPathList=} [properties] Properties to set
             */
            function GetOrderPathList(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GetOrderPathList orderId.
             * @member {number|Long} orderId
             * @memberof fastcity.common.GetOrderPathList
             * @instance
             */
            GetOrderPathList.prototype.orderId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Creates a new GetOrderPathList instance using the specified properties.
             * @function create
             * @memberof fastcity.common.GetOrderPathList
             * @static
             * @param {fastcity.common.IGetOrderPathList=} [properties] Properties to set
             * @returns {fastcity.common.GetOrderPathList} GetOrderPathList instance
             */
            GetOrderPathList.create = function create(properties) {
                return new GetOrderPathList(properties);
            };

            /**
             * Encodes the specified GetOrderPathList message. Does not implicitly {@link fastcity.common.GetOrderPathList.verify|verify} messages.
             * @function encode
             * @memberof fastcity.common.GetOrderPathList
             * @static
             * @param {fastcity.common.IGetOrderPathList} message GetOrderPathList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetOrderPathList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.orderId != null && Object.hasOwnProperty.call(message, "orderId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.orderId);
                return writer;
            };

            /**
             * Decodes a GetOrderPathList message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.common.GetOrderPathList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.common.GetOrderPathList} GetOrderPathList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetOrderPathList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.common.GetOrderPathList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.orderId = reader.int64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a GetOrderPathList message.
             * @function verify
             * @memberof fastcity.common.GetOrderPathList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetOrderPathList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.orderId != null && message.hasOwnProperty("orderId"))
                    if (!$util.isInteger(message.orderId) && !(message.orderId && $util.isInteger(message.orderId.low) && $util.isInteger(message.orderId.high)))
                        return "orderId: integer|Long expected";
                return null;
            };

            /**
             * Creates a GetOrderPathList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.common.GetOrderPathList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.common.GetOrderPathList} GetOrderPathList
             */
            GetOrderPathList.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.common.GetOrderPathList)
                    return object;
                var message = new $root.fastcity.common.GetOrderPathList();
                if (object.orderId != null)
                    if ($util.Long)
                        (message.orderId = $util.Long.fromValue(object.orderId)).unsigned = false;
                    else if (typeof object.orderId === "string")
                        message.orderId = parseInt(object.orderId, 10);
                    else if (typeof object.orderId === "number")
                        message.orderId = object.orderId;
                    else if (typeof object.orderId === "object")
                        message.orderId = new $util.LongBits(object.orderId.low >>> 0, object.orderId.high >>> 0).toNumber();
                return message;
            };

            /**
             * Creates a plain object from a GetOrderPathList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.common.GetOrderPathList
             * @static
             * @param {fastcity.common.GetOrderPathList} message GetOrderPathList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetOrderPathList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.orderId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.orderId = options.longs === String ? "0" : 0;
                if (message.orderId != null && message.hasOwnProperty("orderId"))
                    if (typeof message.orderId === "number")
                        object.orderId = options.longs === String ? String(message.orderId) : message.orderId;
                    else
                        object.orderId = options.longs === String ? $util.Long.prototype.toString.call(message.orderId) : options.longs === Number ? new $util.LongBits(message.orderId.low >>> 0, message.orderId.high >>> 0).toNumber() : message.orderId;
                return object;
            };

            /**
             * Converts this GetOrderPathList to JSON.
             * @function toJSON
             * @memberof fastcity.common.GetOrderPathList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetOrderPathList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return GetOrderPathList;
        })();

        common.SetOrderPath = (function() {

            /**
             * Properties of a SetOrderPath.
             * @memberof fastcity.common
             * @interface ISetOrderPath
             * @property {number|Long|null} [orderId] SetOrderPath orderId
             * @property {Array.<fastcity.common.ICoordinate>|null} [coordinates] SetOrderPath coordinates
             * @property {fastcity.common.OrderPath.Type|null} [type] SetOrderPath type
             * @property {number|null} [inMapLength] SetOrderPath inMapLength
             * @property {number|null} [realLength] SetOrderPath realLength
             */

            /**
             * Constructs a new SetOrderPath.
             * @memberof fastcity.common
             * @classdesc Represents a SetOrderPath.
             * @implements ISetOrderPath
             * @constructor
             * @param {fastcity.common.ISetOrderPath=} [properties] Properties to set
             */
            function SetOrderPath(properties) {
                this.coordinates = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * SetOrderPath orderId.
             * @member {number|Long} orderId
             * @memberof fastcity.common.SetOrderPath
             * @instance
             */
            SetOrderPath.prototype.orderId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * SetOrderPath coordinates.
             * @member {Array.<fastcity.common.ICoordinate>} coordinates
             * @memberof fastcity.common.SetOrderPath
             * @instance
             */
            SetOrderPath.prototype.coordinates = $util.emptyArray;

            /**
             * SetOrderPath type.
             * @member {fastcity.common.OrderPath.Type} type
             * @memberof fastcity.common.SetOrderPath
             * @instance
             */
            SetOrderPath.prototype.type = 0;

            /**
             * SetOrderPath inMapLength.
             * @member {number} inMapLength
             * @memberof fastcity.common.SetOrderPath
             * @instance
             */
            SetOrderPath.prototype.inMapLength = 0;

            /**
             * SetOrderPath realLength.
             * @member {number} realLength
             * @memberof fastcity.common.SetOrderPath
             * @instance
             */
            SetOrderPath.prototype.realLength = 0;

            /**
             * Creates a new SetOrderPath instance using the specified properties.
             * @function create
             * @memberof fastcity.common.SetOrderPath
             * @static
             * @param {fastcity.common.ISetOrderPath=} [properties] Properties to set
             * @returns {fastcity.common.SetOrderPath} SetOrderPath instance
             */
            SetOrderPath.create = function create(properties) {
                return new SetOrderPath(properties);
            };

            /**
             * Encodes the specified SetOrderPath message. Does not implicitly {@link fastcity.common.SetOrderPath.verify|verify} messages.
             * @function encode
             * @memberof fastcity.common.SetOrderPath
             * @static
             * @param {fastcity.common.ISetOrderPath} message SetOrderPath message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SetOrderPath.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.orderId != null && Object.hasOwnProperty.call(message, "orderId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.orderId);
                if (message.coordinates != null && message.coordinates.length)
                    for (var i = 0; i < message.coordinates.length; ++i)
                        $root.fastcity.common.Coordinate.encode(message.coordinates[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.type);
                if (message.inMapLength != null && Object.hasOwnProperty.call(message, "inMapLength"))
                    writer.uint32(/* id 4, wireType 1 =*/33).double(message.inMapLength);
                if (message.realLength != null && Object.hasOwnProperty.call(message, "realLength"))
                    writer.uint32(/* id 5, wireType 1 =*/41).double(message.realLength);
                return writer;
            };

            /**
             * Decodes a SetOrderPath message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.common.SetOrderPath
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.common.SetOrderPath} SetOrderPath
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SetOrderPath.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.common.SetOrderPath();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.orderId = reader.int64();
                        break;
                    case 2:
                        if (!(message.coordinates && message.coordinates.length))
                            message.coordinates = [];
                        message.coordinates.push($root.fastcity.common.Coordinate.decode(reader, reader.uint32()));
                        break;
                    case 3:
                        message.type = reader.int32();
                        break;
                    case 4:
                        message.inMapLength = reader.double();
                        break;
                    case 5:
                        message.realLength = reader.double();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a SetOrderPath message.
             * @function verify
             * @memberof fastcity.common.SetOrderPath
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SetOrderPath.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.orderId != null && message.hasOwnProperty("orderId"))
                    if (!$util.isInteger(message.orderId) && !(message.orderId && $util.isInteger(message.orderId.low) && $util.isInteger(message.orderId.high)))
                        return "orderId: integer|Long expected";
                if (message.coordinates != null && message.hasOwnProperty("coordinates")) {
                    if (!Array.isArray(message.coordinates))
                        return "coordinates: array expected";
                    for (var i = 0; i < message.coordinates.length; ++i) {
                        var error = $root.fastcity.common.Coordinate.verify(message.coordinates[i]);
                        if (error)
                            return "coordinates." + error;
                    }
                }
                if (message.type != null && message.hasOwnProperty("type"))
                    switch (message.type) {
                    default:
                        return "type: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
                if (message.inMapLength != null && message.hasOwnProperty("inMapLength"))
                    if (typeof message.inMapLength !== "number")
                        return "inMapLength: number expected";
                if (message.realLength != null && message.hasOwnProperty("realLength"))
                    if (typeof message.realLength !== "number")
                        return "realLength: number expected";
                return null;
            };

            /**
             * Creates a SetOrderPath message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.common.SetOrderPath
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.common.SetOrderPath} SetOrderPath
             */
            SetOrderPath.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.common.SetOrderPath)
                    return object;
                var message = new $root.fastcity.common.SetOrderPath();
                if (object.orderId != null)
                    if ($util.Long)
                        (message.orderId = $util.Long.fromValue(object.orderId)).unsigned = false;
                    else if (typeof object.orderId === "string")
                        message.orderId = parseInt(object.orderId, 10);
                    else if (typeof object.orderId === "number")
                        message.orderId = object.orderId;
                    else if (typeof object.orderId === "object")
                        message.orderId = new $util.LongBits(object.orderId.low >>> 0, object.orderId.high >>> 0).toNumber();
                if (object.coordinates) {
                    if (!Array.isArray(object.coordinates))
                        throw TypeError(".fastcity.common.SetOrderPath.coordinates: array expected");
                    message.coordinates = [];
                    for (var i = 0; i < object.coordinates.length; ++i) {
                        if (typeof object.coordinates[i] !== "object")
                            throw TypeError(".fastcity.common.SetOrderPath.coordinates: object expected");
                        message.coordinates[i] = $root.fastcity.common.Coordinate.fromObject(object.coordinates[i]);
                    }
                }
                switch (object.type) {
                case "T_ON_FOOT":
                case 0:
                    message.type = 0;
                    break;
                case "T_CAR":
                case 1:
                    message.type = 1;
                    break;
                case "T_BICYCLE":
                case 2:
                    message.type = 2;
                    break;
                case "T_PUBLIC_TRANSPORT":
                case 3:
                    message.type = 3;
                    break;
                }
                if (object.inMapLength != null)
                    message.inMapLength = Number(object.inMapLength);
                if (object.realLength != null)
                    message.realLength = Number(object.realLength);
                return message;
            };

            /**
             * Creates a plain object from a SetOrderPath message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.common.SetOrderPath
             * @static
             * @param {fastcity.common.SetOrderPath} message SetOrderPath
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SetOrderPath.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.coordinates = [];
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.orderId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.orderId = options.longs === String ? "0" : 0;
                    object.type = options.enums === String ? "T_ON_FOOT" : 0;
                    object.inMapLength = 0;
                    object.realLength = 0;
                }
                if (message.orderId != null && message.hasOwnProperty("orderId"))
                    if (typeof message.orderId === "number")
                        object.orderId = options.longs === String ? String(message.orderId) : message.orderId;
                    else
                        object.orderId = options.longs === String ? $util.Long.prototype.toString.call(message.orderId) : options.longs === Number ? new $util.LongBits(message.orderId.low >>> 0, message.orderId.high >>> 0).toNumber() : message.orderId;
                if (message.coordinates && message.coordinates.length) {
                    object.coordinates = [];
                    for (var j = 0; j < message.coordinates.length; ++j)
                        object.coordinates[j] = $root.fastcity.common.Coordinate.toObject(message.coordinates[j], options);
                }
                if (message.type != null && message.hasOwnProperty("type"))
                    object.type = options.enums === String ? $root.fastcity.common.OrderPath.Type[message.type] : message.type;
                if (message.inMapLength != null && message.hasOwnProperty("inMapLength"))
                    object.inMapLength = options.json && !isFinite(message.inMapLength) ? String(message.inMapLength) : message.inMapLength;
                if (message.realLength != null && message.hasOwnProperty("realLength"))
                    object.realLength = options.json && !isFinite(message.realLength) ? String(message.realLength) : message.realLength;
                return object;
            };

            /**
             * Converts this SetOrderPath to JSON.
             * @function toJSON
             * @memberof fastcity.common.SetOrderPath
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SetOrderPath.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return SetOrderPath;
        })();

        return common;
    })();

    fastcity.connector = (function() {

        /**
         * Namespace connector.
         * @memberof fastcity
         * @namespace
         */
        var connector = {};

        connector.ServerMessage = (function() {

            /**
             * Properties of a ServerMessage.
             * @memberof fastcity.connector
             * @interface IServerMessage
             * @property {number|Long|null} [requestId] ServerMessage requestId
             * @property {fastcity.connector.IGetCourierList|null} [getCourierList] ServerMessage getCourierList
             * @property {fastcity.connector.IGetOrderList|null} [getOrderList] ServerMessage getOrderList
             * @property {fastcity.connector.IGetTodaysShiftCourierList|null} [getTodaysShiftCourierList] ServerMessage getTodaysShiftCourierList
             * @property {fastcity.connector.IFinishOrder|null} [finishOrder] ServerMessage finishOrder
             * @property {fastcity.connector.IGetStartOfWorkingDay|null} [getStartOfWorkingDay] ServerMessage getStartOfWorkingDay
             * @property {fastcity.connector.IQuit|null} [quit] ServerMessage quit
             * @property {fastcity.connector.IGetWorkingTime|null} [getWorkingTime] ServerMessage getWorkingTime
             * @property {fastcity.connector.IGetLocation|null} [getLocation] ServerMessage getLocation
             */

            /**
             * Constructs a new ServerMessage.
             * @memberof fastcity.connector
             * @classdesc Represents a ServerMessage.
             * @implements IServerMessage
             * @constructor
             * @param {fastcity.connector.IServerMessage=} [properties] Properties to set
             */
            function ServerMessage(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ServerMessage requestId.
             * @member {number|Long} requestId
             * @memberof fastcity.connector.ServerMessage
             * @instance
             */
            ServerMessage.prototype.requestId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * ServerMessage getCourierList.
             * @member {fastcity.connector.IGetCourierList|null|undefined} getCourierList
             * @memberof fastcity.connector.ServerMessage
             * @instance
             */
            ServerMessage.prototype.getCourierList = null;

            /**
             * ServerMessage getOrderList.
             * @member {fastcity.connector.IGetOrderList|null|undefined} getOrderList
             * @memberof fastcity.connector.ServerMessage
             * @instance
             */
            ServerMessage.prototype.getOrderList = null;

            /**
             * ServerMessage getTodaysShiftCourierList.
             * @member {fastcity.connector.IGetTodaysShiftCourierList|null|undefined} getTodaysShiftCourierList
             * @memberof fastcity.connector.ServerMessage
             * @instance
             */
            ServerMessage.prototype.getTodaysShiftCourierList = null;

            /**
             * ServerMessage finishOrder.
             * @member {fastcity.connector.IFinishOrder|null|undefined} finishOrder
             * @memberof fastcity.connector.ServerMessage
             * @instance
             */
            ServerMessage.prototype.finishOrder = null;

            /**
             * ServerMessage getStartOfWorkingDay.
             * @member {fastcity.connector.IGetStartOfWorkingDay|null|undefined} getStartOfWorkingDay
             * @memberof fastcity.connector.ServerMessage
             * @instance
             */
            ServerMessage.prototype.getStartOfWorkingDay = null;

            /**
             * ServerMessage quit.
             * @member {fastcity.connector.IQuit|null|undefined} quit
             * @memberof fastcity.connector.ServerMessage
             * @instance
             */
            ServerMessage.prototype.quit = null;

            /**
             * ServerMessage getWorkingTime.
             * @member {fastcity.connector.IGetWorkingTime|null|undefined} getWorkingTime
             * @memberof fastcity.connector.ServerMessage
             * @instance
             */
            ServerMessage.prototype.getWorkingTime = null;

            /**
             * ServerMessage getLocation.
             * @member {fastcity.connector.IGetLocation|null|undefined} getLocation
             * @memberof fastcity.connector.ServerMessage
             * @instance
             */
            ServerMessage.prototype.getLocation = null;

            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;

            /**
             * ServerMessage body.
             * @member {"getCourierList"|"getOrderList"|"getTodaysShiftCourierList"|"finishOrder"|"getStartOfWorkingDay"|"quit"|"getWorkingTime"|"getLocation"|undefined} body
             * @memberof fastcity.connector.ServerMessage
             * @instance
             */
            Object.defineProperty(ServerMessage.prototype, "body", {
                get: $util.oneOfGetter($oneOfFields = ["getCourierList", "getOrderList", "getTodaysShiftCourierList", "finishOrder", "getStartOfWorkingDay", "quit", "getWorkingTime", "getLocation"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Creates a new ServerMessage instance using the specified properties.
             * @function create
             * @memberof fastcity.connector.ServerMessage
             * @static
             * @param {fastcity.connector.IServerMessage=} [properties] Properties to set
             * @returns {fastcity.connector.ServerMessage} ServerMessage instance
             */
            ServerMessage.create = function create(properties) {
                return new ServerMessage(properties);
            };

            /**
             * Encodes the specified ServerMessage message. Does not implicitly {@link fastcity.connector.ServerMessage.verify|verify} messages.
             * @function encode
             * @memberof fastcity.connector.ServerMessage
             * @static
             * @param {fastcity.connector.IServerMessage} message ServerMessage message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ServerMessage.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.requestId != null && Object.hasOwnProperty.call(message, "requestId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.requestId);
                if (message.getCourierList != null && Object.hasOwnProperty.call(message, "getCourierList"))
                    $root.fastcity.connector.GetCourierList.encode(message.getCourierList, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.getOrderList != null && Object.hasOwnProperty.call(message, "getOrderList"))
                    $root.fastcity.connector.GetOrderList.encode(message.getOrderList, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.getTodaysShiftCourierList != null && Object.hasOwnProperty.call(message, "getTodaysShiftCourierList"))
                    $root.fastcity.connector.GetTodaysShiftCourierList.encode(message.getTodaysShiftCourierList, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.finishOrder != null && Object.hasOwnProperty.call(message, "finishOrder"))
                    $root.fastcity.connector.FinishOrder.encode(message.finishOrder, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.getStartOfWorkingDay != null && Object.hasOwnProperty.call(message, "getStartOfWorkingDay"))
                    $root.fastcity.connector.GetStartOfWorkingDay.encode(message.getStartOfWorkingDay, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.quit != null && Object.hasOwnProperty.call(message, "quit"))
                    $root.fastcity.connector.Quit.encode(message.quit, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                if (message.getWorkingTime != null && Object.hasOwnProperty.call(message, "getWorkingTime"))
                    $root.fastcity.connector.GetWorkingTime.encode(message.getWorkingTime, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                if (message.getLocation != null && Object.hasOwnProperty.call(message, "getLocation"))
                    $root.fastcity.connector.GetLocation.encode(message.getLocation, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a ServerMessage message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.connector.ServerMessage
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.connector.ServerMessage} ServerMessage
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ServerMessage.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.connector.ServerMessage();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.requestId = reader.uint64();
                        break;
                    case 2:
                        message.getCourierList = $root.fastcity.connector.GetCourierList.decode(reader, reader.uint32());
                        break;
                    case 3:
                        message.getOrderList = $root.fastcity.connector.GetOrderList.decode(reader, reader.uint32());
                        break;
                    case 4:
                        message.getTodaysShiftCourierList = $root.fastcity.connector.GetTodaysShiftCourierList.decode(reader, reader.uint32());
                        break;
                    case 5:
                        message.finishOrder = $root.fastcity.connector.FinishOrder.decode(reader, reader.uint32());
                        break;
                    case 6:
                        message.getStartOfWorkingDay = $root.fastcity.connector.GetStartOfWorkingDay.decode(reader, reader.uint32());
                        break;
                    case 7:
                        message.quit = $root.fastcity.connector.Quit.decode(reader, reader.uint32());
                        break;
                    case 8:
                        message.getWorkingTime = $root.fastcity.connector.GetWorkingTime.decode(reader, reader.uint32());
                        break;
                    case 9:
                        message.getLocation = $root.fastcity.connector.GetLocation.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a ServerMessage message.
             * @function verify
             * @memberof fastcity.connector.ServerMessage
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ServerMessage.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                var properties = {};
                if (message.requestId != null && message.hasOwnProperty("requestId"))
                    if (!$util.isInteger(message.requestId) && !(message.requestId && $util.isInteger(message.requestId.low) && $util.isInteger(message.requestId.high)))
                        return "requestId: integer|Long expected";
                if (message.getCourierList != null && message.hasOwnProperty("getCourierList")) {
                    properties.body = 1;
                    {
                        var error = $root.fastcity.connector.GetCourierList.verify(message.getCourierList);
                        if (error)
                            return "getCourierList." + error;
                    }
                }
                if (message.getOrderList != null && message.hasOwnProperty("getOrderList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.connector.GetOrderList.verify(message.getOrderList);
                        if (error)
                            return "getOrderList." + error;
                    }
                }
                if (message.getTodaysShiftCourierList != null && message.hasOwnProperty("getTodaysShiftCourierList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.connector.GetTodaysShiftCourierList.verify(message.getTodaysShiftCourierList);
                        if (error)
                            return "getTodaysShiftCourierList." + error;
                    }
                }
                if (message.finishOrder != null && message.hasOwnProperty("finishOrder")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.connector.FinishOrder.verify(message.finishOrder);
                        if (error)
                            return "finishOrder." + error;
                    }
                }
                if (message.getStartOfWorkingDay != null && message.hasOwnProperty("getStartOfWorkingDay")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.connector.GetStartOfWorkingDay.verify(message.getStartOfWorkingDay);
                        if (error)
                            return "getStartOfWorkingDay." + error;
                    }
                }
                if (message.quit != null && message.hasOwnProperty("quit")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.connector.Quit.verify(message.quit);
                        if (error)
                            return "quit." + error;
                    }
                }
                if (message.getWorkingTime != null && message.hasOwnProperty("getWorkingTime")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.connector.GetWorkingTime.verify(message.getWorkingTime);
                        if (error)
                            return "getWorkingTime." + error;
                    }
                }
                if (message.getLocation != null && message.hasOwnProperty("getLocation")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.connector.GetLocation.verify(message.getLocation);
                        if (error)
                            return "getLocation." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a ServerMessage message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.connector.ServerMessage
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.connector.ServerMessage} ServerMessage
             */
            ServerMessage.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.connector.ServerMessage)
                    return object;
                var message = new $root.fastcity.connector.ServerMessage();
                if (object.requestId != null)
                    if ($util.Long)
                        (message.requestId = $util.Long.fromValue(object.requestId)).unsigned = true;
                    else if (typeof object.requestId === "string")
                        message.requestId = parseInt(object.requestId, 10);
                    else if (typeof object.requestId === "number")
                        message.requestId = object.requestId;
                    else if (typeof object.requestId === "object")
                        message.requestId = new $util.LongBits(object.requestId.low >>> 0, object.requestId.high >>> 0).toNumber(true);
                if (object.getCourierList != null) {
                    if (typeof object.getCourierList !== "object")
                        throw TypeError(".fastcity.connector.ServerMessage.getCourierList: object expected");
                    message.getCourierList = $root.fastcity.connector.GetCourierList.fromObject(object.getCourierList);
                }
                if (object.getOrderList != null) {
                    if (typeof object.getOrderList !== "object")
                        throw TypeError(".fastcity.connector.ServerMessage.getOrderList: object expected");
                    message.getOrderList = $root.fastcity.connector.GetOrderList.fromObject(object.getOrderList);
                }
                if (object.getTodaysShiftCourierList != null) {
                    if (typeof object.getTodaysShiftCourierList !== "object")
                        throw TypeError(".fastcity.connector.ServerMessage.getTodaysShiftCourierList: object expected");
                    message.getTodaysShiftCourierList = $root.fastcity.connector.GetTodaysShiftCourierList.fromObject(object.getTodaysShiftCourierList);
                }
                if (object.finishOrder != null) {
                    if (typeof object.finishOrder !== "object")
                        throw TypeError(".fastcity.connector.ServerMessage.finishOrder: object expected");
                    message.finishOrder = $root.fastcity.connector.FinishOrder.fromObject(object.finishOrder);
                }
                if (object.getStartOfWorkingDay != null) {
                    if (typeof object.getStartOfWorkingDay !== "object")
                        throw TypeError(".fastcity.connector.ServerMessage.getStartOfWorkingDay: object expected");
                    message.getStartOfWorkingDay = $root.fastcity.connector.GetStartOfWorkingDay.fromObject(object.getStartOfWorkingDay);
                }
                if (object.quit != null) {
                    if (typeof object.quit !== "object")
                        throw TypeError(".fastcity.connector.ServerMessage.quit: object expected");
                    message.quit = $root.fastcity.connector.Quit.fromObject(object.quit);
                }
                if (object.getWorkingTime != null) {
                    if (typeof object.getWorkingTime !== "object")
                        throw TypeError(".fastcity.connector.ServerMessage.getWorkingTime: object expected");
                    message.getWorkingTime = $root.fastcity.connector.GetWorkingTime.fromObject(object.getWorkingTime);
                }
                if (object.getLocation != null) {
                    if (typeof object.getLocation !== "object")
                        throw TypeError(".fastcity.connector.ServerMessage.getLocation: object expected");
                    message.getLocation = $root.fastcity.connector.GetLocation.fromObject(object.getLocation);
                }
                return message;
            };

            /**
             * Creates a plain object from a ServerMessage message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.connector.ServerMessage
             * @static
             * @param {fastcity.connector.ServerMessage} message ServerMessage
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ServerMessage.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.requestId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.requestId = options.longs === String ? "0" : 0;
                if (message.requestId != null && message.hasOwnProperty("requestId"))
                    if (typeof message.requestId === "number")
                        object.requestId = options.longs === String ? String(message.requestId) : message.requestId;
                    else
                        object.requestId = options.longs === String ? $util.Long.prototype.toString.call(message.requestId) : options.longs === Number ? new $util.LongBits(message.requestId.low >>> 0, message.requestId.high >>> 0).toNumber(true) : message.requestId;
                if (message.getCourierList != null && message.hasOwnProperty("getCourierList")) {
                    object.getCourierList = $root.fastcity.connector.GetCourierList.toObject(message.getCourierList, options);
                    if (options.oneofs)
                        object.body = "getCourierList";
                }
                if (message.getOrderList != null && message.hasOwnProperty("getOrderList")) {
                    object.getOrderList = $root.fastcity.connector.GetOrderList.toObject(message.getOrderList, options);
                    if (options.oneofs)
                        object.body = "getOrderList";
                }
                if (message.getTodaysShiftCourierList != null && message.hasOwnProperty("getTodaysShiftCourierList")) {
                    object.getTodaysShiftCourierList = $root.fastcity.connector.GetTodaysShiftCourierList.toObject(message.getTodaysShiftCourierList, options);
                    if (options.oneofs)
                        object.body = "getTodaysShiftCourierList";
                }
                if (message.finishOrder != null && message.hasOwnProperty("finishOrder")) {
                    object.finishOrder = $root.fastcity.connector.FinishOrder.toObject(message.finishOrder, options);
                    if (options.oneofs)
                        object.body = "finishOrder";
                }
                if (message.getStartOfWorkingDay != null && message.hasOwnProperty("getStartOfWorkingDay")) {
                    object.getStartOfWorkingDay = $root.fastcity.connector.GetStartOfWorkingDay.toObject(message.getStartOfWorkingDay, options);
                    if (options.oneofs)
                        object.body = "getStartOfWorkingDay";
                }
                if (message.quit != null && message.hasOwnProperty("quit")) {
                    object.quit = $root.fastcity.connector.Quit.toObject(message.quit, options);
                    if (options.oneofs)
                        object.body = "quit";
                }
                if (message.getWorkingTime != null && message.hasOwnProperty("getWorkingTime")) {
                    object.getWorkingTime = $root.fastcity.connector.GetWorkingTime.toObject(message.getWorkingTime, options);
                    if (options.oneofs)
                        object.body = "getWorkingTime";
                }
                if (message.getLocation != null && message.hasOwnProperty("getLocation")) {
                    object.getLocation = $root.fastcity.connector.GetLocation.toObject(message.getLocation, options);
                    if (options.oneofs)
                        object.body = "getLocation";
                }
                return object;
            };

            /**
             * Converts this ServerMessage to JSON.
             * @function toJSON
             * @memberof fastcity.connector.ServerMessage
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ServerMessage.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return ServerMessage;
        })();

        connector.ConnectorMessage = (function() {

            /**
             * Properties of a ConnectorMessage.
             * @memberof fastcity.connector
             * @interface IConnectorMessage
             * @property {number|Long|null} [requestId] ConnectorMessage requestId
             * @property {fastcity.connector.IResult|null} [result] ConnectorMessage result
             * @property {fastcity.connector.ICourierList|null} [courierList] ConnectorMessage courierList
             * @property {fastcity.connector.IOrderList|null} [orderList] ConnectorMessage orderList
             * @property {fastcity.connector.IStartOfWorkingDay|null} [startOfWorkingDay] ConnectorMessage startOfWorkingDay
             * @property {fastcity.connector.IWorkingTime|null} [workingTime] ConnectorMessage workingTime
             * @property {fastcity.connector.ILocation|null} [location] ConnectorMessage location
             */

            /**
             * Constructs a new ConnectorMessage.
             * @memberof fastcity.connector
             * @classdesc Represents a ConnectorMessage.
             * @implements IConnectorMessage
             * @constructor
             * @param {fastcity.connector.IConnectorMessage=} [properties] Properties to set
             */
            function ConnectorMessage(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ConnectorMessage requestId.
             * @member {number|Long} requestId
             * @memberof fastcity.connector.ConnectorMessage
             * @instance
             */
            ConnectorMessage.prototype.requestId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * ConnectorMessage result.
             * @member {fastcity.connector.IResult|null|undefined} result
             * @memberof fastcity.connector.ConnectorMessage
             * @instance
             */
            ConnectorMessage.prototype.result = null;

            /**
             * ConnectorMessage courierList.
             * @member {fastcity.connector.ICourierList|null|undefined} courierList
             * @memberof fastcity.connector.ConnectorMessage
             * @instance
             */
            ConnectorMessage.prototype.courierList = null;

            /**
             * ConnectorMessage orderList.
             * @member {fastcity.connector.IOrderList|null|undefined} orderList
             * @memberof fastcity.connector.ConnectorMessage
             * @instance
             */
            ConnectorMessage.prototype.orderList = null;

            /**
             * ConnectorMessage startOfWorkingDay.
             * @member {fastcity.connector.IStartOfWorkingDay|null|undefined} startOfWorkingDay
             * @memberof fastcity.connector.ConnectorMessage
             * @instance
             */
            ConnectorMessage.prototype.startOfWorkingDay = null;

            /**
             * ConnectorMessage workingTime.
             * @member {fastcity.connector.IWorkingTime|null|undefined} workingTime
             * @memberof fastcity.connector.ConnectorMessage
             * @instance
             */
            ConnectorMessage.prototype.workingTime = null;

            /**
             * ConnectorMessage location.
             * @member {fastcity.connector.ILocation|null|undefined} location
             * @memberof fastcity.connector.ConnectorMessage
             * @instance
             */
            ConnectorMessage.prototype.location = null;

            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;

            /**
             * ConnectorMessage body.
             * @member {"result"|"courierList"|"orderList"|"startOfWorkingDay"|"workingTime"|"location"|undefined} body
             * @memberof fastcity.connector.ConnectorMessage
             * @instance
             */
            Object.defineProperty(ConnectorMessage.prototype, "body", {
                get: $util.oneOfGetter($oneOfFields = ["result", "courierList", "orderList", "startOfWorkingDay", "workingTime", "location"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Creates a new ConnectorMessage instance using the specified properties.
             * @function create
             * @memberof fastcity.connector.ConnectorMessage
             * @static
             * @param {fastcity.connector.IConnectorMessage=} [properties] Properties to set
             * @returns {fastcity.connector.ConnectorMessage} ConnectorMessage instance
             */
            ConnectorMessage.create = function create(properties) {
                return new ConnectorMessage(properties);
            };

            /**
             * Encodes the specified ConnectorMessage message. Does not implicitly {@link fastcity.connector.ConnectorMessage.verify|verify} messages.
             * @function encode
             * @memberof fastcity.connector.ConnectorMessage
             * @static
             * @param {fastcity.connector.IConnectorMessage} message ConnectorMessage message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ConnectorMessage.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.requestId != null && Object.hasOwnProperty.call(message, "requestId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.requestId);
                if (message.result != null && Object.hasOwnProperty.call(message, "result"))
                    $root.fastcity.connector.Result.encode(message.result, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.courierList != null && Object.hasOwnProperty.call(message, "courierList"))
                    $root.fastcity.connector.CourierList.encode(message.courierList, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.orderList != null && Object.hasOwnProperty.call(message, "orderList"))
                    $root.fastcity.connector.OrderList.encode(message.orderList, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.startOfWorkingDay != null && Object.hasOwnProperty.call(message, "startOfWorkingDay"))
                    $root.fastcity.connector.StartOfWorkingDay.encode(message.startOfWorkingDay, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.workingTime != null && Object.hasOwnProperty.call(message, "workingTime"))
                    $root.fastcity.connector.WorkingTime.encode(message.workingTime, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.location != null && Object.hasOwnProperty.call(message, "location"))
                    $root.fastcity.connector.Location.encode(message.location, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a ConnectorMessage message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.connector.ConnectorMessage
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.connector.ConnectorMessage} ConnectorMessage
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ConnectorMessage.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.connector.ConnectorMessage();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.requestId = reader.uint64();
                        break;
                    case 2:
                        message.result = $root.fastcity.connector.Result.decode(reader, reader.uint32());
                        break;
                    case 3:
                        message.courierList = $root.fastcity.connector.CourierList.decode(reader, reader.uint32());
                        break;
                    case 4:
                        message.orderList = $root.fastcity.connector.OrderList.decode(reader, reader.uint32());
                        break;
                    case 5:
                        message.startOfWorkingDay = $root.fastcity.connector.StartOfWorkingDay.decode(reader, reader.uint32());
                        break;
                    case 6:
                        message.workingTime = $root.fastcity.connector.WorkingTime.decode(reader, reader.uint32());
                        break;
                    case 7:
                        message.location = $root.fastcity.connector.Location.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a ConnectorMessage message.
             * @function verify
             * @memberof fastcity.connector.ConnectorMessage
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ConnectorMessage.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                var properties = {};
                if (message.requestId != null && message.hasOwnProperty("requestId"))
                    if (!$util.isInteger(message.requestId) && !(message.requestId && $util.isInteger(message.requestId.low) && $util.isInteger(message.requestId.high)))
                        return "requestId: integer|Long expected";
                if (message.result != null && message.hasOwnProperty("result")) {
                    properties.body = 1;
                    {
                        var error = $root.fastcity.connector.Result.verify(message.result);
                        if (error)
                            return "result." + error;
                    }
                }
                if (message.courierList != null && message.hasOwnProperty("courierList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.connector.CourierList.verify(message.courierList);
                        if (error)
                            return "courierList." + error;
                    }
                }
                if (message.orderList != null && message.hasOwnProperty("orderList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.connector.OrderList.verify(message.orderList);
                        if (error)
                            return "orderList." + error;
                    }
                }
                if (message.startOfWorkingDay != null && message.hasOwnProperty("startOfWorkingDay")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.connector.StartOfWorkingDay.verify(message.startOfWorkingDay);
                        if (error)
                            return "startOfWorkingDay." + error;
                    }
                }
                if (message.workingTime != null && message.hasOwnProperty("workingTime")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.connector.WorkingTime.verify(message.workingTime);
                        if (error)
                            return "workingTime." + error;
                    }
                }
                if (message.location != null && message.hasOwnProperty("location")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.connector.Location.verify(message.location);
                        if (error)
                            return "location." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a ConnectorMessage message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.connector.ConnectorMessage
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.connector.ConnectorMessage} ConnectorMessage
             */
            ConnectorMessage.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.connector.ConnectorMessage)
                    return object;
                var message = new $root.fastcity.connector.ConnectorMessage();
                if (object.requestId != null)
                    if ($util.Long)
                        (message.requestId = $util.Long.fromValue(object.requestId)).unsigned = true;
                    else if (typeof object.requestId === "string")
                        message.requestId = parseInt(object.requestId, 10);
                    else if (typeof object.requestId === "number")
                        message.requestId = object.requestId;
                    else if (typeof object.requestId === "object")
                        message.requestId = new $util.LongBits(object.requestId.low >>> 0, object.requestId.high >>> 0).toNumber(true);
                if (object.result != null) {
                    if (typeof object.result !== "object")
                        throw TypeError(".fastcity.connector.ConnectorMessage.result: object expected");
                    message.result = $root.fastcity.connector.Result.fromObject(object.result);
                }
                if (object.courierList != null) {
                    if (typeof object.courierList !== "object")
                        throw TypeError(".fastcity.connector.ConnectorMessage.courierList: object expected");
                    message.courierList = $root.fastcity.connector.CourierList.fromObject(object.courierList);
                }
                if (object.orderList != null) {
                    if (typeof object.orderList !== "object")
                        throw TypeError(".fastcity.connector.ConnectorMessage.orderList: object expected");
                    message.orderList = $root.fastcity.connector.OrderList.fromObject(object.orderList);
                }
                if (object.startOfWorkingDay != null) {
                    if (typeof object.startOfWorkingDay !== "object")
                        throw TypeError(".fastcity.connector.ConnectorMessage.startOfWorkingDay: object expected");
                    message.startOfWorkingDay = $root.fastcity.connector.StartOfWorkingDay.fromObject(object.startOfWorkingDay);
                }
                if (object.workingTime != null) {
                    if (typeof object.workingTime !== "object")
                        throw TypeError(".fastcity.connector.ConnectorMessage.workingTime: object expected");
                    message.workingTime = $root.fastcity.connector.WorkingTime.fromObject(object.workingTime);
                }
                if (object.location != null) {
                    if (typeof object.location !== "object")
                        throw TypeError(".fastcity.connector.ConnectorMessage.location: object expected");
                    message.location = $root.fastcity.connector.Location.fromObject(object.location);
                }
                return message;
            };

            /**
             * Creates a plain object from a ConnectorMessage message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.connector.ConnectorMessage
             * @static
             * @param {fastcity.connector.ConnectorMessage} message ConnectorMessage
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ConnectorMessage.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.requestId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.requestId = options.longs === String ? "0" : 0;
                if (message.requestId != null && message.hasOwnProperty("requestId"))
                    if (typeof message.requestId === "number")
                        object.requestId = options.longs === String ? String(message.requestId) : message.requestId;
                    else
                        object.requestId = options.longs === String ? $util.Long.prototype.toString.call(message.requestId) : options.longs === Number ? new $util.LongBits(message.requestId.low >>> 0, message.requestId.high >>> 0).toNumber(true) : message.requestId;
                if (message.result != null && message.hasOwnProperty("result")) {
                    object.result = $root.fastcity.connector.Result.toObject(message.result, options);
                    if (options.oneofs)
                        object.body = "result";
                }
                if (message.courierList != null && message.hasOwnProperty("courierList")) {
                    object.courierList = $root.fastcity.connector.CourierList.toObject(message.courierList, options);
                    if (options.oneofs)
                        object.body = "courierList";
                }
                if (message.orderList != null && message.hasOwnProperty("orderList")) {
                    object.orderList = $root.fastcity.connector.OrderList.toObject(message.orderList, options);
                    if (options.oneofs)
                        object.body = "orderList";
                }
                if (message.startOfWorkingDay != null && message.hasOwnProperty("startOfWorkingDay")) {
                    object.startOfWorkingDay = $root.fastcity.connector.StartOfWorkingDay.toObject(message.startOfWorkingDay, options);
                    if (options.oneofs)
                        object.body = "startOfWorkingDay";
                }
                if (message.workingTime != null && message.hasOwnProperty("workingTime")) {
                    object.workingTime = $root.fastcity.connector.WorkingTime.toObject(message.workingTime, options);
                    if (options.oneofs)
                        object.body = "workingTime";
                }
                if (message.location != null && message.hasOwnProperty("location")) {
                    object.location = $root.fastcity.connector.Location.toObject(message.location, options);
                    if (options.oneofs)
                        object.body = "location";
                }
                return object;
            };

            /**
             * Converts this ConnectorMessage to JSON.
             * @function toJSON
             * @memberof fastcity.connector.ConnectorMessage
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ConnectorMessage.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return ConnectorMessage;
        })();

        connector.Courier = (function() {

            /**
             * Properties of a Courier.
             * @memberof fastcity.connector
             * @interface ICourier
             * @property {string|null} [externalCourierId] Courier externalCourierId
             * @property {string|null} [name] Courier name
             * @property {string|null} [phone] Courier phone
             * @property {Uint8Array|null} [passwordHash] Courier passwordHash
             * @property {Uint8Array|null} [passwordSalt] Courier passwordSalt
             * @property {boolean|null} [isDeleted] Courier isDeleted
             * @property {number|Long|null} [minimalDistanceToOrderTarget] Courier minimalDistanceToOrderTarget
             * @property {boolean|null} [useDifferentColorsForAllOrders] Courier useDifferentColorsForAllOrders
             */

            /**
             * Constructs a new Courier.
             * @memberof fastcity.connector
             * @classdesc Represents a Courier.
             * @implements ICourier
             * @constructor
             * @param {fastcity.connector.ICourier=} [properties] Properties to set
             */
            function Courier(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Courier externalCourierId.
             * @member {string} externalCourierId
             * @memberof fastcity.connector.Courier
             * @instance
             */
            Courier.prototype.externalCourierId = "";

            /**
             * Courier name.
             * @member {string} name
             * @memberof fastcity.connector.Courier
             * @instance
             */
            Courier.prototype.name = "";

            /**
             * Courier phone.
             * @member {string} phone
             * @memberof fastcity.connector.Courier
             * @instance
             */
            Courier.prototype.phone = "";

            /**
             * Courier passwordHash.
             * @member {Uint8Array} passwordHash
             * @memberof fastcity.connector.Courier
             * @instance
             */
            Courier.prototype.passwordHash = $util.newBuffer([]);

            /**
             * Courier passwordSalt.
             * @member {Uint8Array} passwordSalt
             * @memberof fastcity.connector.Courier
             * @instance
             */
            Courier.prototype.passwordSalt = $util.newBuffer([]);

            /**
             * Courier isDeleted.
             * @member {boolean} isDeleted
             * @memberof fastcity.connector.Courier
             * @instance
             */
            Courier.prototype.isDeleted = false;

            /**
             * Courier minimalDistanceToOrderTarget.
             * @member {number|Long} minimalDistanceToOrderTarget
             * @memberof fastcity.connector.Courier
             * @instance
             */
            Courier.prototype.minimalDistanceToOrderTarget = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Courier useDifferentColorsForAllOrders.
             * @member {boolean} useDifferentColorsForAllOrders
             * @memberof fastcity.connector.Courier
             * @instance
             */
            Courier.prototype.useDifferentColorsForAllOrders = false;

            /**
             * Creates a new Courier instance using the specified properties.
             * @function create
             * @memberof fastcity.connector.Courier
             * @static
             * @param {fastcity.connector.ICourier=} [properties] Properties to set
             * @returns {fastcity.connector.Courier} Courier instance
             */
            Courier.create = function create(properties) {
                return new Courier(properties);
            };

            /**
             * Encodes the specified Courier message. Does not implicitly {@link fastcity.connector.Courier.verify|verify} messages.
             * @function encode
             * @memberof fastcity.connector.Courier
             * @static
             * @param {fastcity.connector.ICourier} message Courier message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Courier.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.externalCourierId != null && Object.hasOwnProperty.call(message, "externalCourierId"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.externalCourierId);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                if (message.phone != null && Object.hasOwnProperty.call(message, "phone"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.phone);
                if (message.passwordHash != null && Object.hasOwnProperty.call(message, "passwordHash"))
                    writer.uint32(/* id 4, wireType 2 =*/34).bytes(message.passwordHash);
                if (message.passwordSalt != null && Object.hasOwnProperty.call(message, "passwordSalt"))
                    writer.uint32(/* id 5, wireType 2 =*/42).bytes(message.passwordSalt);
                if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                    writer.uint32(/* id 6, wireType 0 =*/48).bool(message.isDeleted);
                if (message.minimalDistanceToOrderTarget != null && Object.hasOwnProperty.call(message, "minimalDistanceToOrderTarget"))
                    writer.uint32(/* id 7, wireType 0 =*/56).uint64(message.minimalDistanceToOrderTarget);
                if (message.useDifferentColorsForAllOrders != null && Object.hasOwnProperty.call(message, "useDifferentColorsForAllOrders"))
                    writer.uint32(/* id 8, wireType 0 =*/64).bool(message.useDifferentColorsForAllOrders);
                return writer;
            };

            /**
             * Decodes a Courier message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.connector.Courier
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.connector.Courier} Courier
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Courier.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.connector.Courier();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.externalCourierId = reader.string();
                        break;
                    case 2:
                        message.name = reader.string();
                        break;
                    case 3:
                        message.phone = reader.string();
                        break;
                    case 4:
                        message.passwordHash = reader.bytes();
                        break;
                    case 5:
                        message.passwordSalt = reader.bytes();
                        break;
                    case 6:
                        message.isDeleted = reader.bool();
                        break;
                    case 7:
                        message.minimalDistanceToOrderTarget = reader.uint64();
                        break;
                    case 8:
                        message.useDifferentColorsForAllOrders = reader.bool();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a Courier message.
             * @function verify
             * @memberof fastcity.connector.Courier
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Courier.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.externalCourierId != null && message.hasOwnProperty("externalCourierId"))
                    if (!$util.isString(message.externalCourierId))
                        return "externalCourierId: string expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.phone != null && message.hasOwnProperty("phone"))
                    if (!$util.isString(message.phone))
                        return "phone: string expected";
                if (message.passwordHash != null && message.hasOwnProperty("passwordHash"))
                    if (!(message.passwordHash && typeof message.passwordHash.length === "number" || $util.isString(message.passwordHash)))
                        return "passwordHash: buffer expected";
                if (message.passwordSalt != null && message.hasOwnProperty("passwordSalt"))
                    if (!(message.passwordSalt && typeof message.passwordSalt.length === "number" || $util.isString(message.passwordSalt)))
                        return "passwordSalt: buffer expected";
                if (message.isDeleted != null && message.hasOwnProperty("isDeleted"))
                    if (typeof message.isDeleted !== "boolean")
                        return "isDeleted: boolean expected";
                if (message.minimalDistanceToOrderTarget != null && message.hasOwnProperty("minimalDistanceToOrderTarget"))
                    if (!$util.isInteger(message.minimalDistanceToOrderTarget) && !(message.minimalDistanceToOrderTarget && $util.isInteger(message.minimalDistanceToOrderTarget.low) && $util.isInteger(message.minimalDistanceToOrderTarget.high)))
                        return "minimalDistanceToOrderTarget: integer|Long expected";
                if (message.useDifferentColorsForAllOrders != null && message.hasOwnProperty("useDifferentColorsForAllOrders"))
                    if (typeof message.useDifferentColorsForAllOrders !== "boolean")
                        return "useDifferentColorsForAllOrders: boolean expected";
                return null;
            };

            /**
             * Creates a Courier message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.connector.Courier
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.connector.Courier} Courier
             */
            Courier.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.connector.Courier)
                    return object;
                var message = new $root.fastcity.connector.Courier();
                if (object.externalCourierId != null)
                    message.externalCourierId = String(object.externalCourierId);
                if (object.name != null)
                    message.name = String(object.name);
                if (object.phone != null)
                    message.phone = String(object.phone);
                if (object.passwordHash != null)
                    if (typeof object.passwordHash === "string")
                        $util.base64.decode(object.passwordHash, message.passwordHash = $util.newBuffer($util.base64.length(object.passwordHash)), 0);
                    else if (object.passwordHash.length)
                        message.passwordHash = object.passwordHash;
                if (object.passwordSalt != null)
                    if (typeof object.passwordSalt === "string")
                        $util.base64.decode(object.passwordSalt, message.passwordSalt = $util.newBuffer($util.base64.length(object.passwordSalt)), 0);
                    else if (object.passwordSalt.length)
                        message.passwordSalt = object.passwordSalt;
                if (object.isDeleted != null)
                    message.isDeleted = Boolean(object.isDeleted);
                if (object.minimalDistanceToOrderTarget != null)
                    if ($util.Long)
                        (message.minimalDistanceToOrderTarget = $util.Long.fromValue(object.minimalDistanceToOrderTarget)).unsigned = true;
                    else if (typeof object.minimalDistanceToOrderTarget === "string")
                        message.minimalDistanceToOrderTarget = parseInt(object.minimalDistanceToOrderTarget, 10);
                    else if (typeof object.minimalDistanceToOrderTarget === "number")
                        message.minimalDistanceToOrderTarget = object.minimalDistanceToOrderTarget;
                    else if (typeof object.minimalDistanceToOrderTarget === "object")
                        message.minimalDistanceToOrderTarget = new $util.LongBits(object.minimalDistanceToOrderTarget.low >>> 0, object.minimalDistanceToOrderTarget.high >>> 0).toNumber(true);
                if (object.useDifferentColorsForAllOrders != null)
                    message.useDifferentColorsForAllOrders = Boolean(object.useDifferentColorsForAllOrders);
                return message;
            };

            /**
             * Creates a plain object from a Courier message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.connector.Courier
             * @static
             * @param {fastcity.connector.Courier} message Courier
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Courier.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.externalCourierId = "";
                    object.name = "";
                    object.phone = "";
                    if (options.bytes === String)
                        object.passwordHash = "";
                    else {
                        object.passwordHash = [];
                        if (options.bytes !== Array)
                            object.passwordHash = $util.newBuffer(object.passwordHash);
                    }
                    if (options.bytes === String)
                        object.passwordSalt = "";
                    else {
                        object.passwordSalt = [];
                        if (options.bytes !== Array)
                            object.passwordSalt = $util.newBuffer(object.passwordSalt);
                    }
                    object.isDeleted = false;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.minimalDistanceToOrderTarget = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.minimalDistanceToOrderTarget = options.longs === String ? "0" : 0;
                    object.useDifferentColorsForAllOrders = false;
                }
                if (message.externalCourierId != null && message.hasOwnProperty("externalCourierId"))
                    object.externalCourierId = message.externalCourierId;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.phone != null && message.hasOwnProperty("phone"))
                    object.phone = message.phone;
                if (message.passwordHash != null && message.hasOwnProperty("passwordHash"))
                    object.passwordHash = options.bytes === String ? $util.base64.encode(message.passwordHash, 0, message.passwordHash.length) : options.bytes === Array ? Array.prototype.slice.call(message.passwordHash) : message.passwordHash;
                if (message.passwordSalt != null && message.hasOwnProperty("passwordSalt"))
                    object.passwordSalt = options.bytes === String ? $util.base64.encode(message.passwordSalt, 0, message.passwordSalt.length) : options.bytes === Array ? Array.prototype.slice.call(message.passwordSalt) : message.passwordSalt;
                if (message.isDeleted != null && message.hasOwnProperty("isDeleted"))
                    object.isDeleted = message.isDeleted;
                if (message.minimalDistanceToOrderTarget != null && message.hasOwnProperty("minimalDistanceToOrderTarget"))
                    if (typeof message.minimalDistanceToOrderTarget === "number")
                        object.minimalDistanceToOrderTarget = options.longs === String ? String(message.minimalDistanceToOrderTarget) : message.minimalDistanceToOrderTarget;
                    else
                        object.minimalDistanceToOrderTarget = options.longs === String ? $util.Long.prototype.toString.call(message.minimalDistanceToOrderTarget) : options.longs === Number ? new $util.LongBits(message.minimalDistanceToOrderTarget.low >>> 0, message.minimalDistanceToOrderTarget.high >>> 0).toNumber(true) : message.minimalDistanceToOrderTarget;
                if (message.useDifferentColorsForAllOrders != null && message.hasOwnProperty("useDifferentColorsForAllOrders"))
                    object.useDifferentColorsForAllOrders = message.useDifferentColorsForAllOrders;
                return object;
            };

            /**
             * Converts this Courier to JSON.
             * @function toJSON
             * @memberof fastcity.connector.Courier
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Courier.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Courier;
        })();

        connector.GetCourierList = (function() {

            /**
             * Properties of a GetCourierList.
             * @memberof fastcity.connector
             * @interface IGetCourierList
             */

            /**
             * Constructs a new GetCourierList.
             * @memberof fastcity.connector
             * @classdesc Represents a GetCourierList.
             * @implements IGetCourierList
             * @constructor
             * @param {fastcity.connector.IGetCourierList=} [properties] Properties to set
             */
            function GetCourierList(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new GetCourierList instance using the specified properties.
             * @function create
             * @memberof fastcity.connector.GetCourierList
             * @static
             * @param {fastcity.connector.IGetCourierList=} [properties] Properties to set
             * @returns {fastcity.connector.GetCourierList} GetCourierList instance
             */
            GetCourierList.create = function create(properties) {
                return new GetCourierList(properties);
            };

            /**
             * Encodes the specified GetCourierList message. Does not implicitly {@link fastcity.connector.GetCourierList.verify|verify} messages.
             * @function encode
             * @memberof fastcity.connector.GetCourierList
             * @static
             * @param {fastcity.connector.IGetCourierList} message GetCourierList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetCourierList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Decodes a GetCourierList message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.connector.GetCourierList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.connector.GetCourierList} GetCourierList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetCourierList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.connector.GetCourierList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a GetCourierList message.
             * @function verify
             * @memberof fastcity.connector.GetCourierList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetCourierList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a GetCourierList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.connector.GetCourierList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.connector.GetCourierList} GetCourierList
             */
            GetCourierList.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.connector.GetCourierList)
                    return object;
                return new $root.fastcity.connector.GetCourierList();
            };

            /**
             * Creates a plain object from a GetCourierList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.connector.GetCourierList
             * @static
             * @param {fastcity.connector.GetCourierList} message GetCourierList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetCourierList.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this GetCourierList to JSON.
             * @function toJSON
             * @memberof fastcity.connector.GetCourierList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetCourierList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return GetCourierList;
        })();

        connector.GetTodaysShiftCourierList = (function() {

            /**
             * Properties of a GetTodaysShiftCourierList.
             * @memberof fastcity.connector
             * @interface IGetTodaysShiftCourierList
             */

            /**
             * Constructs a new GetTodaysShiftCourierList.
             * @memberof fastcity.connector
             * @classdesc Represents a GetTodaysShiftCourierList.
             * @implements IGetTodaysShiftCourierList
             * @constructor
             * @param {fastcity.connector.IGetTodaysShiftCourierList=} [properties] Properties to set
             */
            function GetTodaysShiftCourierList(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new GetTodaysShiftCourierList instance using the specified properties.
             * @function create
             * @memberof fastcity.connector.GetTodaysShiftCourierList
             * @static
             * @param {fastcity.connector.IGetTodaysShiftCourierList=} [properties] Properties to set
             * @returns {fastcity.connector.GetTodaysShiftCourierList} GetTodaysShiftCourierList instance
             */
            GetTodaysShiftCourierList.create = function create(properties) {
                return new GetTodaysShiftCourierList(properties);
            };

            /**
             * Encodes the specified GetTodaysShiftCourierList message. Does not implicitly {@link fastcity.connector.GetTodaysShiftCourierList.verify|verify} messages.
             * @function encode
             * @memberof fastcity.connector.GetTodaysShiftCourierList
             * @static
             * @param {fastcity.connector.IGetTodaysShiftCourierList} message GetTodaysShiftCourierList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetTodaysShiftCourierList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Decodes a GetTodaysShiftCourierList message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.connector.GetTodaysShiftCourierList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.connector.GetTodaysShiftCourierList} GetTodaysShiftCourierList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetTodaysShiftCourierList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.connector.GetTodaysShiftCourierList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a GetTodaysShiftCourierList message.
             * @function verify
             * @memberof fastcity.connector.GetTodaysShiftCourierList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetTodaysShiftCourierList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a GetTodaysShiftCourierList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.connector.GetTodaysShiftCourierList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.connector.GetTodaysShiftCourierList} GetTodaysShiftCourierList
             */
            GetTodaysShiftCourierList.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.connector.GetTodaysShiftCourierList)
                    return object;
                return new $root.fastcity.connector.GetTodaysShiftCourierList();
            };

            /**
             * Creates a plain object from a GetTodaysShiftCourierList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.connector.GetTodaysShiftCourierList
             * @static
             * @param {fastcity.connector.GetTodaysShiftCourierList} message GetTodaysShiftCourierList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetTodaysShiftCourierList.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this GetTodaysShiftCourierList to JSON.
             * @function toJSON
             * @memberof fastcity.connector.GetTodaysShiftCourierList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetTodaysShiftCourierList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return GetTodaysShiftCourierList;
        })();

        connector.CourierList = (function() {

            /**
             * Properties of a CourierList.
             * @memberof fastcity.connector
             * @interface ICourierList
             * @property {Array.<fastcity.connector.ICourier>|null} [courier] CourierList courier
             */

            /**
             * Constructs a new CourierList.
             * @memberof fastcity.connector
             * @classdesc Represents a CourierList.
             * @implements ICourierList
             * @constructor
             * @param {fastcity.connector.ICourierList=} [properties] Properties to set
             */
            function CourierList(properties) {
                this.courier = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CourierList courier.
             * @member {Array.<fastcity.connector.ICourier>} courier
             * @memberof fastcity.connector.CourierList
             * @instance
             */
            CourierList.prototype.courier = $util.emptyArray;

            /**
             * Creates a new CourierList instance using the specified properties.
             * @function create
             * @memberof fastcity.connector.CourierList
             * @static
             * @param {fastcity.connector.ICourierList=} [properties] Properties to set
             * @returns {fastcity.connector.CourierList} CourierList instance
             */
            CourierList.create = function create(properties) {
                return new CourierList(properties);
            };

            /**
             * Encodes the specified CourierList message. Does not implicitly {@link fastcity.connector.CourierList.verify|verify} messages.
             * @function encode
             * @memberof fastcity.connector.CourierList
             * @static
             * @param {fastcity.connector.ICourierList} message CourierList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CourierList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.courier != null && message.courier.length)
                    for (var i = 0; i < message.courier.length; ++i)
                        $root.fastcity.connector.Courier.encode(message.courier[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a CourierList message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.connector.CourierList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.connector.CourierList} CourierList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CourierList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.connector.CourierList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.courier && message.courier.length))
                            message.courier = [];
                        message.courier.push($root.fastcity.connector.Courier.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a CourierList message.
             * @function verify
             * @memberof fastcity.connector.CourierList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CourierList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.courier != null && message.hasOwnProperty("courier")) {
                    if (!Array.isArray(message.courier))
                        return "courier: array expected";
                    for (var i = 0; i < message.courier.length; ++i) {
                        var error = $root.fastcity.connector.Courier.verify(message.courier[i]);
                        if (error)
                            return "courier." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a CourierList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.connector.CourierList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.connector.CourierList} CourierList
             */
            CourierList.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.connector.CourierList)
                    return object;
                var message = new $root.fastcity.connector.CourierList();
                if (object.courier) {
                    if (!Array.isArray(object.courier))
                        throw TypeError(".fastcity.connector.CourierList.courier: array expected");
                    message.courier = [];
                    for (var i = 0; i < object.courier.length; ++i) {
                        if (typeof object.courier[i] !== "object")
                            throw TypeError(".fastcity.connector.CourierList.courier: object expected");
                        message.courier[i] = $root.fastcity.connector.Courier.fromObject(object.courier[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a CourierList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.connector.CourierList
             * @static
             * @param {fastcity.connector.CourierList} message CourierList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CourierList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.courier = [];
                if (message.courier && message.courier.length) {
                    object.courier = [];
                    for (var j = 0; j < message.courier.length; ++j)
                        object.courier[j] = $root.fastcity.connector.Courier.toObject(message.courier[j], options);
                }
                return object;
            };

            /**
             * Converts this CourierList to JSON.
             * @function toJSON
             * @memberof fastcity.connector.CourierList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CourierList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return CourierList;
        })();

        connector.Location = (function() {

            /**
             * Properties of a Location.
             * @memberof fastcity.connector
             * @interface ILocation
             * @property {string|null} [address] Location address
             */

            /**
             * Constructs a new Location.
             * @memberof fastcity.connector
             * @classdesc Represents a Location.
             * @implements ILocation
             * @constructor
             * @param {fastcity.connector.ILocation=} [properties] Properties to set
             */
            function Location(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Location address.
             * @member {string} address
             * @memberof fastcity.connector.Location
             * @instance
             */
            Location.prototype.address = "";

            /**
             * Creates a new Location instance using the specified properties.
             * @function create
             * @memberof fastcity.connector.Location
             * @static
             * @param {fastcity.connector.ILocation=} [properties] Properties to set
             * @returns {fastcity.connector.Location} Location instance
             */
            Location.create = function create(properties) {
                return new Location(properties);
            };

            /**
             * Encodes the specified Location message. Does not implicitly {@link fastcity.connector.Location.verify|verify} messages.
             * @function encode
             * @memberof fastcity.connector.Location
             * @static
             * @param {fastcity.connector.ILocation} message Location message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Location.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.address);
                return writer;
            };

            /**
             * Decodes a Location message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.connector.Location
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.connector.Location} Location
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Location.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.connector.Location();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.address = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a Location message.
             * @function verify
             * @memberof fastcity.connector.Location
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Location.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.address != null && message.hasOwnProperty("address"))
                    if (!$util.isString(message.address))
                        return "address: string expected";
                return null;
            };

            /**
             * Creates a Location message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.connector.Location
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.connector.Location} Location
             */
            Location.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.connector.Location)
                    return object;
                var message = new $root.fastcity.connector.Location();
                if (object.address != null)
                    message.address = String(object.address);
                return message;
            };

            /**
             * Creates a plain object from a Location message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.connector.Location
             * @static
             * @param {fastcity.connector.Location} message Location
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Location.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.address = "";
                if (message.address != null && message.hasOwnProperty("address"))
                    object.address = message.address;
                return object;
            };

            /**
             * Converts this Location to JSON.
             * @function toJSON
             * @memberof fastcity.connector.Location
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Location.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Location;
        })();

        connector.GetLocation = (function() {

            /**
             * Properties of a GetLocation.
             * @memberof fastcity.connector
             * @interface IGetLocation
             */

            /**
             * Constructs a new GetLocation.
             * @memberof fastcity.connector
             * @classdesc Represents a GetLocation.
             * @implements IGetLocation
             * @constructor
             * @param {fastcity.connector.IGetLocation=} [properties] Properties to set
             */
            function GetLocation(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new GetLocation instance using the specified properties.
             * @function create
             * @memberof fastcity.connector.GetLocation
             * @static
             * @param {fastcity.connector.IGetLocation=} [properties] Properties to set
             * @returns {fastcity.connector.GetLocation} GetLocation instance
             */
            GetLocation.create = function create(properties) {
                return new GetLocation(properties);
            };

            /**
             * Encodes the specified GetLocation message. Does not implicitly {@link fastcity.connector.GetLocation.verify|verify} messages.
             * @function encode
             * @memberof fastcity.connector.GetLocation
             * @static
             * @param {fastcity.connector.IGetLocation} message GetLocation message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetLocation.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Decodes a GetLocation message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.connector.GetLocation
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.connector.GetLocation} GetLocation
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetLocation.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.connector.GetLocation();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a GetLocation message.
             * @function verify
             * @memberof fastcity.connector.GetLocation
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetLocation.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a GetLocation message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.connector.GetLocation
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.connector.GetLocation} GetLocation
             */
            GetLocation.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.connector.GetLocation)
                    return object;
                return new $root.fastcity.connector.GetLocation();
            };

            /**
             * Creates a plain object from a GetLocation message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.connector.GetLocation
             * @static
             * @param {fastcity.connector.GetLocation} message GetLocation
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetLocation.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this GetLocation to JSON.
             * @function toJSON
             * @memberof fastcity.connector.GetLocation
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetLocation.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return GetLocation;
        })();

        connector.OrderItem = (function() {

            /**
             * Properties of an OrderItem.
             * @memberof fastcity.connector
             * @interface IOrderItem
             * @property {string|null} [externalOrderItemId] OrderItem externalOrderItemId
             * @property {string|null} [externalItemCode] OrderItem externalItemCode
             * @property {string|null} [description] OrderItem description
             * @property {string|null} [finalPrice] OrderItem finalPrice
             * @property {string|null} [priceForOne] OrderItem priceForOne
             * @property {number|null} [quantity] OrderItem quantity
             * @property {fastcity.common.OrderItem.ProductType|null} [productType] OrderItem productType
             */

            /**
             * Constructs a new OrderItem.
             * @memberof fastcity.connector
             * @classdesc Represents an OrderItem.
             * @implements IOrderItem
             * @constructor
             * @param {fastcity.connector.IOrderItem=} [properties] Properties to set
             */
            function OrderItem(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * OrderItem externalOrderItemId.
             * @member {string} externalOrderItemId
             * @memberof fastcity.connector.OrderItem
             * @instance
             */
            OrderItem.prototype.externalOrderItemId = "";

            /**
             * OrderItem externalItemCode.
             * @member {string} externalItemCode
             * @memberof fastcity.connector.OrderItem
             * @instance
             */
            OrderItem.prototype.externalItemCode = "";

            /**
             * OrderItem description.
             * @member {string} description
             * @memberof fastcity.connector.OrderItem
             * @instance
             */
            OrderItem.prototype.description = "";

            /**
             * OrderItem finalPrice.
             * @member {string} finalPrice
             * @memberof fastcity.connector.OrderItem
             * @instance
             */
            OrderItem.prototype.finalPrice = "";

            /**
             * OrderItem priceForOne.
             * @member {string} priceForOne
             * @memberof fastcity.connector.OrderItem
             * @instance
             */
            OrderItem.prototype.priceForOne = "";

            /**
             * OrderItem quantity.
             * @member {number} quantity
             * @memberof fastcity.connector.OrderItem
             * @instance
             */
            OrderItem.prototype.quantity = 0;

            /**
             * OrderItem productType.
             * @member {fastcity.common.OrderItem.ProductType} productType
             * @memberof fastcity.connector.OrderItem
             * @instance
             */
            OrderItem.prototype.productType = 0;

            /**
             * Creates a new OrderItem instance using the specified properties.
             * @function create
             * @memberof fastcity.connector.OrderItem
             * @static
             * @param {fastcity.connector.IOrderItem=} [properties] Properties to set
             * @returns {fastcity.connector.OrderItem} OrderItem instance
             */
            OrderItem.create = function create(properties) {
                return new OrderItem(properties);
            };

            /**
             * Encodes the specified OrderItem message. Does not implicitly {@link fastcity.connector.OrderItem.verify|verify} messages.
             * @function encode
             * @memberof fastcity.connector.OrderItem
             * @static
             * @param {fastcity.connector.IOrderItem} message OrderItem message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            OrderItem.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.externalOrderItemId != null && Object.hasOwnProperty.call(message, "externalOrderItemId"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.externalOrderItemId);
                if (message.externalItemCode != null && Object.hasOwnProperty.call(message, "externalItemCode"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.externalItemCode);
                if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.description);
                if (message.finalPrice != null && Object.hasOwnProperty.call(message, "finalPrice"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.finalPrice);
                if (message.priceForOne != null && Object.hasOwnProperty.call(message, "priceForOne"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.priceForOne);
                if (message.quantity != null && Object.hasOwnProperty.call(message, "quantity"))
                    writer.uint32(/* id 6, wireType 0 =*/48).int32(message.quantity);
                if (message.productType != null && Object.hasOwnProperty.call(message, "productType"))
                    writer.uint32(/* id 7, wireType 0 =*/56).int32(message.productType);
                return writer;
            };

            /**
             * Decodes an OrderItem message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.connector.OrderItem
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.connector.OrderItem} OrderItem
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            OrderItem.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.connector.OrderItem();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.externalOrderItemId = reader.string();
                        break;
                    case 2:
                        message.externalItemCode = reader.string();
                        break;
                    case 3:
                        message.description = reader.string();
                        break;
                    case 4:
                        message.finalPrice = reader.string();
                        break;
                    case 5:
                        message.priceForOne = reader.string();
                        break;
                    case 6:
                        message.quantity = reader.int32();
                        break;
                    case 7:
                        message.productType = reader.int32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an OrderItem message.
             * @function verify
             * @memberof fastcity.connector.OrderItem
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            OrderItem.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.externalOrderItemId != null && message.hasOwnProperty("externalOrderItemId"))
                    if (!$util.isString(message.externalOrderItemId))
                        return "externalOrderItemId: string expected";
                if (message.externalItemCode != null && message.hasOwnProperty("externalItemCode"))
                    if (!$util.isString(message.externalItemCode))
                        return "externalItemCode: string expected";
                if (message.description != null && message.hasOwnProperty("description"))
                    if (!$util.isString(message.description))
                        return "description: string expected";
                if (message.finalPrice != null && message.hasOwnProperty("finalPrice"))
                    if (!$util.isString(message.finalPrice))
                        return "finalPrice: string expected";
                if (message.priceForOne != null && message.hasOwnProperty("priceForOne"))
                    if (!$util.isString(message.priceForOne))
                        return "priceForOne: string expected";
                if (message.quantity != null && message.hasOwnProperty("quantity"))
                    if (!$util.isInteger(message.quantity))
                        return "quantity: integer expected";
                if (message.productType != null && message.hasOwnProperty("productType"))
                    switch (message.productType) {
                    default:
                        return "productType: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                    case 9:
                    case 10:
                    case 11:
                        break;
                    }
                return null;
            };

            /**
             * Creates an OrderItem message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.connector.OrderItem
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.connector.OrderItem} OrderItem
             */
            OrderItem.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.connector.OrderItem)
                    return object;
                var message = new $root.fastcity.connector.OrderItem();
                if (object.externalOrderItemId != null)
                    message.externalOrderItemId = String(object.externalOrderItemId);
                if (object.externalItemCode != null)
                    message.externalItemCode = String(object.externalItemCode);
                if (object.description != null)
                    message.description = String(object.description);
                if (object.finalPrice != null)
                    message.finalPrice = String(object.finalPrice);
                if (object.priceForOne != null)
                    message.priceForOne = String(object.priceForOne);
                if (object.quantity != null)
                    message.quantity = object.quantity | 0;
                switch (object.productType) {
                case "T_UNKNOWN":
                case 0:
                    message.productType = 0;
                    break;
                case "T_DESSERT":
                case 1:
                    message.productType = 1;
                    break;
                case "T_SNACK":
                case 2:
                    message.productType = 2;
                    break;
                case "T_DRINK":
                case 3:
                    message.productType = 3;
                    break;
                case "T_BEER":
                case 4:
                    message.productType = 4;
                    break;
                case "T_PIZZA":
                case 5:
                    message.productType = 5;
                    break;
                case "T_GIFT":
                case 6:
                    message.productType = 6;
                    break;
                case "T_SALAD":
                case 7:
                    message.productType = 7;
                    break;
                case "T_BREAD":
                case 8:
                    message.productType = 8;
                    break;
                case "T_SAUCE":
                case 9:
                    message.productType = 9;
                    break;
                case "T_COMPOUND":
                case 10:
                    message.productType = 10;
                    break;
                case "T_SERVICE":
                case 11:
                    message.productType = 11;
                    break;
                }
                return message;
            };

            /**
             * Creates a plain object from an OrderItem message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.connector.OrderItem
             * @static
             * @param {fastcity.connector.OrderItem} message OrderItem
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            OrderItem.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.externalOrderItemId = "";
                    object.externalItemCode = "";
                    object.description = "";
                    object.finalPrice = "";
                    object.priceForOne = "";
                    object.quantity = 0;
                    object.productType = options.enums === String ? "T_UNKNOWN" : 0;
                }
                if (message.externalOrderItemId != null && message.hasOwnProperty("externalOrderItemId"))
                    object.externalOrderItemId = message.externalOrderItemId;
                if (message.externalItemCode != null && message.hasOwnProperty("externalItemCode"))
                    object.externalItemCode = message.externalItemCode;
                if (message.description != null && message.hasOwnProperty("description"))
                    object.description = message.description;
                if (message.finalPrice != null && message.hasOwnProperty("finalPrice"))
                    object.finalPrice = message.finalPrice;
                if (message.priceForOne != null && message.hasOwnProperty("priceForOne"))
                    object.priceForOne = message.priceForOne;
                if (message.quantity != null && message.hasOwnProperty("quantity"))
                    object.quantity = message.quantity;
                if (message.productType != null && message.hasOwnProperty("productType"))
                    object.productType = options.enums === String ? $root.fastcity.common.OrderItem.ProductType[message.productType] : message.productType;
                return object;
            };

            /**
             * Converts this OrderItem to JSON.
             * @function toJSON
             * @memberof fastcity.connector.OrderItem
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            OrderItem.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return OrderItem;
        })();

        connector.Order = (function() {

            /**
             * Properties of an Order.
             * @memberof fastcity.connector
             * @interface IOrder
             * @property {Array.<fastcity.connector.IOrderItem>|null} [orderItem] Order orderItem
             * @property {string|null} [externalOrderId] Order externalOrderId
             * @property {string|null} [externalCourierId] Order externalCourierId
             * @property {number|Long|null} [timestampOpen] Order timestampOpen
             * @property {fastcity.common.Order.Status|null} [status] Order status
             * @property {string|null} [finalPrice] Order finalPrice
             * @property {fastcity.common.Order.PaymentType|null} [paymentType] Order paymentType
             * @property {string|null} [clientPhone] Order clientPhone
             * @property {string|null} [clientName] Order clientName
             * @property {string|null} [clientCity] Order clientCity
             * @property {string|null} [comments] Order comments
             * @property {string|null} [clientStreet] Order clientStreet
             * @property {string|null} [clientStreetNumber] Order clientStreetNumber
             * @property {string|null} [clientRoom] Order clientRoom
             * @property {number|Long|null} [timestampOpenWorkingDay] Order timestampOpenWorkingDay
             * @property {fastcity.common.ICoordinate|null} [coordinate] Order coordinate
             * @property {string|null} [clientCountry] Order clientCountry
             * @property {string|null} [currencySymbol] Order currencySymbol
             * @property {number|Long|null} [timestampCancel] Order timestampCancel
             * @property {Array.<fastcity.common.Order.PaymentType>|null} [paymentTypes] Order paymentTypes
             */

            /**
             * Constructs a new Order.
             * @memberof fastcity.connector
             * @classdesc Represents an Order.
             * @implements IOrder
             * @constructor
             * @param {fastcity.connector.IOrder=} [properties] Properties to set
             */
            function Order(properties) {
                this.orderItem = [];
                this.paymentTypes = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Order orderItem.
             * @member {Array.<fastcity.connector.IOrderItem>} orderItem
             * @memberof fastcity.connector.Order
             * @instance
             */
            Order.prototype.orderItem = $util.emptyArray;

            /**
             * Order externalOrderId.
             * @member {string} externalOrderId
             * @memberof fastcity.connector.Order
             * @instance
             */
            Order.prototype.externalOrderId = "";

            /**
             * Order externalCourierId.
             * @member {string} externalCourierId
             * @memberof fastcity.connector.Order
             * @instance
             */
            Order.prototype.externalCourierId = "";

            /**
             * Order timestampOpen.
             * @member {number|Long} timestampOpen
             * @memberof fastcity.connector.Order
             * @instance
             */
            Order.prototype.timestampOpen = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Order status.
             * @member {fastcity.common.Order.Status} status
             * @memberof fastcity.connector.Order
             * @instance
             */
            Order.prototype.status = 0;

            /**
             * Order finalPrice.
             * @member {string} finalPrice
             * @memberof fastcity.connector.Order
             * @instance
             */
            Order.prototype.finalPrice = "";

            /**
             * Order paymentType.
             * @member {fastcity.common.Order.PaymentType} paymentType
             * @memberof fastcity.connector.Order
             * @instance
             */
            Order.prototype.paymentType = 0;

            /**
             * Order clientPhone.
             * @member {string} clientPhone
             * @memberof fastcity.connector.Order
             * @instance
             */
            Order.prototype.clientPhone = "";

            /**
             * Order clientName.
             * @member {string} clientName
             * @memberof fastcity.connector.Order
             * @instance
             */
            Order.prototype.clientName = "";

            /**
             * Order clientCity.
             * @member {string} clientCity
             * @memberof fastcity.connector.Order
             * @instance
             */
            Order.prototype.clientCity = "";

            /**
             * Order comments.
             * @member {string} comments
             * @memberof fastcity.connector.Order
             * @instance
             */
            Order.prototype.comments = "";

            /**
             * Order clientStreet.
             * @member {string} clientStreet
             * @memberof fastcity.connector.Order
             * @instance
             */
            Order.prototype.clientStreet = "";

            /**
             * Order clientStreetNumber.
             * @member {string} clientStreetNumber
             * @memberof fastcity.connector.Order
             * @instance
             */
            Order.prototype.clientStreetNumber = "";

            /**
             * Order clientRoom.
             * @member {string} clientRoom
             * @memberof fastcity.connector.Order
             * @instance
             */
            Order.prototype.clientRoom = "";

            /**
             * Order timestampOpenWorkingDay.
             * @member {number|Long} timestampOpenWorkingDay
             * @memberof fastcity.connector.Order
             * @instance
             */
            Order.prototype.timestampOpenWorkingDay = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Order coordinate.
             * @member {fastcity.common.ICoordinate|null|undefined} coordinate
             * @memberof fastcity.connector.Order
             * @instance
             */
            Order.prototype.coordinate = null;

            /**
             * Order clientCountry.
             * @member {string} clientCountry
             * @memberof fastcity.connector.Order
             * @instance
             */
            Order.prototype.clientCountry = "";

            /**
             * Order currencySymbol.
             * @member {string} currencySymbol
             * @memberof fastcity.connector.Order
             * @instance
             */
            Order.prototype.currencySymbol = "";

            /**
             * Order timestampCancel.
             * @member {number|Long} timestampCancel
             * @memberof fastcity.connector.Order
             * @instance
             */
            Order.prototype.timestampCancel = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Order paymentTypes.
             * @member {Array.<fastcity.common.Order.PaymentType>} paymentTypes
             * @memberof fastcity.connector.Order
             * @instance
             */
            Order.prototype.paymentTypes = $util.emptyArray;

            /**
             * Creates a new Order instance using the specified properties.
             * @function create
             * @memberof fastcity.connector.Order
             * @static
             * @param {fastcity.connector.IOrder=} [properties] Properties to set
             * @returns {fastcity.connector.Order} Order instance
             */
            Order.create = function create(properties) {
                return new Order(properties);
            };

            /**
             * Encodes the specified Order message. Does not implicitly {@link fastcity.connector.Order.verify|verify} messages.
             * @function encode
             * @memberof fastcity.connector.Order
             * @static
             * @param {fastcity.connector.IOrder} message Order message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Order.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.orderItem != null && message.orderItem.length)
                    for (var i = 0; i < message.orderItem.length; ++i)
                        $root.fastcity.connector.OrderItem.encode(message.orderItem[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.externalOrderId != null && Object.hasOwnProperty.call(message, "externalOrderId"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.externalOrderId);
                if (message.externalCourierId != null && Object.hasOwnProperty.call(message, "externalCourierId"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.externalCourierId);
                if (message.timestampOpen != null && Object.hasOwnProperty.call(message, "timestampOpen"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int64(message.timestampOpen);
                if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                    writer.uint32(/* id 5, wireType 0 =*/40).int32(message.status);
                if (message.finalPrice != null && Object.hasOwnProperty.call(message, "finalPrice"))
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.finalPrice);
                if (message.paymentType != null && Object.hasOwnProperty.call(message, "paymentType"))
                    writer.uint32(/* id 7, wireType 0 =*/56).int32(message.paymentType);
                if (message.clientPhone != null && Object.hasOwnProperty.call(message, "clientPhone"))
                    writer.uint32(/* id 8, wireType 2 =*/66).string(message.clientPhone);
                if (message.clientName != null && Object.hasOwnProperty.call(message, "clientName"))
                    writer.uint32(/* id 9, wireType 2 =*/74).string(message.clientName);
                if (message.clientCity != null && Object.hasOwnProperty.call(message, "clientCity"))
                    writer.uint32(/* id 10, wireType 2 =*/82).string(message.clientCity);
                if (message.comments != null && Object.hasOwnProperty.call(message, "comments"))
                    writer.uint32(/* id 11, wireType 2 =*/90).string(message.comments);
                if (message.clientStreet != null && Object.hasOwnProperty.call(message, "clientStreet"))
                    writer.uint32(/* id 12, wireType 2 =*/98).string(message.clientStreet);
                if (message.clientStreetNumber != null && Object.hasOwnProperty.call(message, "clientStreetNumber"))
                    writer.uint32(/* id 13, wireType 2 =*/106).string(message.clientStreetNumber);
                if (message.clientRoom != null && Object.hasOwnProperty.call(message, "clientRoom"))
                    writer.uint32(/* id 14, wireType 2 =*/114).string(message.clientRoom);
                if (message.timestampOpenWorkingDay != null && Object.hasOwnProperty.call(message, "timestampOpenWorkingDay"))
                    writer.uint32(/* id 15, wireType 0 =*/120).int64(message.timestampOpenWorkingDay);
                if (message.coordinate != null && Object.hasOwnProperty.call(message, "coordinate"))
                    $root.fastcity.common.Coordinate.encode(message.coordinate, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
                if (message.clientCountry != null && Object.hasOwnProperty.call(message, "clientCountry"))
                    writer.uint32(/* id 17, wireType 2 =*/138).string(message.clientCountry);
                if (message.currencySymbol != null && Object.hasOwnProperty.call(message, "currencySymbol"))
                    writer.uint32(/* id 18, wireType 2 =*/146).string(message.currencySymbol);
                if (message.timestampCancel != null && Object.hasOwnProperty.call(message, "timestampCancel"))
                    writer.uint32(/* id 19, wireType 0 =*/152).int64(message.timestampCancel);
                if (message.paymentTypes != null && message.paymentTypes.length) {
                    writer.uint32(/* id 20, wireType 2 =*/162).fork();
                    for (var i = 0; i < message.paymentTypes.length; ++i)
                        writer.int32(message.paymentTypes[i]);
                    writer.ldelim();
                }
                return writer;
            };

            /**
             * Decodes an Order message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.connector.Order
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.connector.Order} Order
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Order.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.connector.Order();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.orderItem && message.orderItem.length))
                            message.orderItem = [];
                        message.orderItem.push($root.fastcity.connector.OrderItem.decode(reader, reader.uint32()));
                        break;
                    case 2:
                        message.externalOrderId = reader.string();
                        break;
                    case 3:
                        message.externalCourierId = reader.string();
                        break;
                    case 4:
                        message.timestampOpen = reader.int64();
                        break;
                    case 5:
                        message.status = reader.int32();
                        break;
                    case 6:
                        message.finalPrice = reader.string();
                        break;
                    case 7:
                        message.paymentType = reader.int32();
                        break;
                    case 8:
                        message.clientPhone = reader.string();
                        break;
                    case 9:
                        message.clientName = reader.string();
                        break;
                    case 10:
                        message.clientCity = reader.string();
                        break;
                    case 11:
                        message.comments = reader.string();
                        break;
                    case 12:
                        message.clientStreet = reader.string();
                        break;
                    case 13:
                        message.clientStreetNumber = reader.string();
                        break;
                    case 14:
                        message.clientRoom = reader.string();
                        break;
                    case 15:
                        message.timestampOpenWorkingDay = reader.int64();
                        break;
                    case 16:
                        message.coordinate = $root.fastcity.common.Coordinate.decode(reader, reader.uint32());
                        break;
                    case 17:
                        message.clientCountry = reader.string();
                        break;
                    case 18:
                        message.currencySymbol = reader.string();
                        break;
                    case 19:
                        message.timestampCancel = reader.int64();
                        break;
                    case 20:
                        if (!(message.paymentTypes && message.paymentTypes.length))
                            message.paymentTypes = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.paymentTypes.push(reader.int32());
                        } else
                            message.paymentTypes.push(reader.int32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an Order message.
             * @function verify
             * @memberof fastcity.connector.Order
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Order.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.orderItem != null && message.hasOwnProperty("orderItem")) {
                    if (!Array.isArray(message.orderItem))
                        return "orderItem: array expected";
                    for (var i = 0; i < message.orderItem.length; ++i) {
                        var error = $root.fastcity.connector.OrderItem.verify(message.orderItem[i]);
                        if (error)
                            return "orderItem." + error;
                    }
                }
                if (message.externalOrderId != null && message.hasOwnProperty("externalOrderId"))
                    if (!$util.isString(message.externalOrderId))
                        return "externalOrderId: string expected";
                if (message.externalCourierId != null && message.hasOwnProperty("externalCourierId"))
                    if (!$util.isString(message.externalCourierId))
                        return "externalCourierId: string expected";
                if (message.timestampOpen != null && message.hasOwnProperty("timestampOpen"))
                    if (!$util.isInteger(message.timestampOpen) && !(message.timestampOpen && $util.isInteger(message.timestampOpen.low) && $util.isInteger(message.timestampOpen.high)))
                        return "timestampOpen: integer|Long expected";
                if (message.status != null && message.hasOwnProperty("status"))
                    switch (message.status) {
                    default:
                        return "status: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                    case 9:
                    case 10:
                    case 11:
                    case 12:
                    case 13:
                    case 14:
                    case 15:
                    case 16:
                        break;
                    }
                if (message.finalPrice != null && message.hasOwnProperty("finalPrice"))
                    if (!$util.isString(message.finalPrice))
                        return "finalPrice: string expected";
                if (message.paymentType != null && message.hasOwnProperty("paymentType"))
                    switch (message.paymentType) {
                    default:
                        return "paymentType: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                    case 9:
                    case 10:
                    case 11:
                    case 12:
                    case 13:
                        break;
                    }
                if (message.clientPhone != null && message.hasOwnProperty("clientPhone"))
                    if (!$util.isString(message.clientPhone))
                        return "clientPhone: string expected";
                if (message.clientName != null && message.hasOwnProperty("clientName"))
                    if (!$util.isString(message.clientName))
                        return "clientName: string expected";
                if (message.clientCity != null && message.hasOwnProperty("clientCity"))
                    if (!$util.isString(message.clientCity))
                        return "clientCity: string expected";
                if (message.comments != null && message.hasOwnProperty("comments"))
                    if (!$util.isString(message.comments))
                        return "comments: string expected";
                if (message.clientStreet != null && message.hasOwnProperty("clientStreet"))
                    if (!$util.isString(message.clientStreet))
                        return "clientStreet: string expected";
                if (message.clientStreetNumber != null && message.hasOwnProperty("clientStreetNumber"))
                    if (!$util.isString(message.clientStreetNumber))
                        return "clientStreetNumber: string expected";
                if (message.clientRoom != null && message.hasOwnProperty("clientRoom"))
                    if (!$util.isString(message.clientRoom))
                        return "clientRoom: string expected";
                if (message.timestampOpenWorkingDay != null && message.hasOwnProperty("timestampOpenWorkingDay"))
                    if (!$util.isInteger(message.timestampOpenWorkingDay) && !(message.timestampOpenWorkingDay && $util.isInteger(message.timestampOpenWorkingDay.low) && $util.isInteger(message.timestampOpenWorkingDay.high)))
                        return "timestampOpenWorkingDay: integer|Long expected";
                if (message.coordinate != null && message.hasOwnProperty("coordinate")) {
                    var error = $root.fastcity.common.Coordinate.verify(message.coordinate);
                    if (error)
                        return "coordinate." + error;
                }
                if (message.clientCountry != null && message.hasOwnProperty("clientCountry"))
                    if (!$util.isString(message.clientCountry))
                        return "clientCountry: string expected";
                if (message.currencySymbol != null && message.hasOwnProperty("currencySymbol"))
                    if (!$util.isString(message.currencySymbol))
                        return "currencySymbol: string expected";
                if (message.timestampCancel != null && message.hasOwnProperty("timestampCancel"))
                    if (!$util.isInteger(message.timestampCancel) && !(message.timestampCancel && $util.isInteger(message.timestampCancel.low) && $util.isInteger(message.timestampCancel.high)))
                        return "timestampCancel: integer|Long expected";
                if (message.paymentTypes != null && message.hasOwnProperty("paymentTypes")) {
                    if (!Array.isArray(message.paymentTypes))
                        return "paymentTypes: array expected";
                    for (var i = 0; i < message.paymentTypes.length; ++i)
                        switch (message.paymentTypes[i]) {
                        default:
                            return "paymentTypes: enum value[] expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                        case 9:
                        case 10:
                        case 11:
                        case 12:
                        case 13:
                            break;
                        }
                }
                return null;
            };

            /**
             * Creates an Order message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.connector.Order
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.connector.Order} Order
             */
            Order.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.connector.Order)
                    return object;
                var message = new $root.fastcity.connector.Order();
                if (object.orderItem) {
                    if (!Array.isArray(object.orderItem))
                        throw TypeError(".fastcity.connector.Order.orderItem: array expected");
                    message.orderItem = [];
                    for (var i = 0; i < object.orderItem.length; ++i) {
                        if (typeof object.orderItem[i] !== "object")
                            throw TypeError(".fastcity.connector.Order.orderItem: object expected");
                        message.orderItem[i] = $root.fastcity.connector.OrderItem.fromObject(object.orderItem[i]);
                    }
                }
                if (object.externalOrderId != null)
                    message.externalOrderId = String(object.externalOrderId);
                if (object.externalCourierId != null)
                    message.externalCourierId = String(object.externalCourierId);
                if (object.timestampOpen != null)
                    if ($util.Long)
                        (message.timestampOpen = $util.Long.fromValue(object.timestampOpen)).unsigned = false;
                    else if (typeof object.timestampOpen === "string")
                        message.timestampOpen = parseInt(object.timestampOpen, 10);
                    else if (typeof object.timestampOpen === "number")
                        message.timestampOpen = object.timestampOpen;
                    else if (typeof object.timestampOpen === "object")
                        message.timestampOpen = new $util.LongBits(object.timestampOpen.low >>> 0, object.timestampOpen.high >>> 0).toNumber();
                switch (object.status) {
                case "S_UNKNOWN":
                case 0:
                    message.status = 0;
                    break;
                case "S_IN_PROGRESS":
                case 1:
                    message.status = 1;
                    break;
                case "S_MAKELINE":
                case 2:
                    message.status = 2;
                    break;
                case "S_ROUTING_STATION":
                case 3:
                    message.status = 3;
                    break;
                case "S_OUT_THE_DOOR":
                case 4:
                    message.status = 4;
                    break;
                case "S_COMPLETE":
                case 5:
                    message.status = 5;
                    break;
                case "S_GIFT":
                case 6:
                    message.status = 6;
                    break;
                case "S_BAD":
                case 7:
                    message.status = 7;
                    break;
                case "S_VOID":
                case 8:
                    message.status = 8;
                    break;
                case "S_BEING_TAKEN":
                case 9:
                    message.status = 9;
                    break;
                case "S_ABANDONED":
                case 10:
                    message.status = 10;
                    break;
                case "S_SUSPENDED":
                case 11:
                    message.status = 11;
                    break;
                case "S_GIFT_CARD_PURCHASE":
                case 12:
                    message.status = 12;
                    break;
                case "S_GIFT_CARD_VOID":
                case 13:
                    message.status = 13;
                    break;
                case "S_TRANSFERRED":
                case 14:
                    message.status = 14;
                    break;
                case "S_HISTORIC_ORDER":
                case 15:
                    message.status = 15;
                    break;
                case "S_BEING_ASSIGNED":
                case 16:
                    message.status = 16;
                    break;
                }
                if (object.finalPrice != null)
                    message.finalPrice = String(object.finalPrice);
                switch (object.paymentType) {
                case "T_UNKNOWN":
                case 0:
                    message.paymentType = 0;
                    break;
                case "T_PAID":
                case 1:
                    message.paymentType = 1;
                    break;
                case "T_BY_CASH_TO_THE_COURIER":
                case 2:
                    message.paymentType = 2;
                    break;
                case "T_BY_CARD_TO_THE_COURIER":
                case 3:
                    message.paymentType = 3;
                    break;
                case "T_PAID_BY_CASH":
                case 4:
                    message.paymentType = 4;
                    break;
                case "T_PAID_BY_CARD":
                case 5:
                    message.paymentType = 5;
                    break;
                case "T_PAID_BY_CHECK":
                case 6:
                    message.paymentType = 6;
                    break;
                case "T_PAID_BY_CREDIT":
                case 7:
                    message.paymentType = 7;
                    break;
                case "T_PAID_WRITEOFF":
                case 8:
                    message.paymentType = 8;
                    break;
                case "T_PAID_BY_VOUCHER":
                case 9:
                    message.paymentType = 9;
                    break;
                case "T_PAID_FROM_EXTERNAL":
                case 10:
                    message.paymentType = 10;
                    break;
                case "T_PAID_SMART_SALE":
                case 11:
                    message.paymentType = 11;
                    break;
                case "T_PAID_BY_SBERBANK":
                case 12:
                    message.paymentType = 12;
                    break;
                case "T_PAID_BY_TRPOS":
                case 13:
                    message.paymentType = 13;
                    break;
                }
                if (object.clientPhone != null)
                    message.clientPhone = String(object.clientPhone);
                if (object.clientName != null)
                    message.clientName = String(object.clientName);
                if (object.clientCity != null)
                    message.clientCity = String(object.clientCity);
                if (object.comments != null)
                    message.comments = String(object.comments);
                if (object.clientStreet != null)
                    message.clientStreet = String(object.clientStreet);
                if (object.clientStreetNumber != null)
                    message.clientStreetNumber = String(object.clientStreetNumber);
                if (object.clientRoom != null)
                    message.clientRoom = String(object.clientRoom);
                if (object.timestampOpenWorkingDay != null)
                    if ($util.Long)
                        (message.timestampOpenWorkingDay = $util.Long.fromValue(object.timestampOpenWorkingDay)).unsigned = false;
                    else if (typeof object.timestampOpenWorkingDay === "string")
                        message.timestampOpenWorkingDay = parseInt(object.timestampOpenWorkingDay, 10);
                    else if (typeof object.timestampOpenWorkingDay === "number")
                        message.timestampOpenWorkingDay = object.timestampOpenWorkingDay;
                    else if (typeof object.timestampOpenWorkingDay === "object")
                        message.timestampOpenWorkingDay = new $util.LongBits(object.timestampOpenWorkingDay.low >>> 0, object.timestampOpenWorkingDay.high >>> 0).toNumber();
                if (object.coordinate != null) {
                    if (typeof object.coordinate !== "object")
                        throw TypeError(".fastcity.connector.Order.coordinate: object expected");
                    message.coordinate = $root.fastcity.common.Coordinate.fromObject(object.coordinate);
                }
                if (object.clientCountry != null)
                    message.clientCountry = String(object.clientCountry);
                if (object.currencySymbol != null)
                    message.currencySymbol = String(object.currencySymbol);
                if (object.timestampCancel != null)
                    if ($util.Long)
                        (message.timestampCancel = $util.Long.fromValue(object.timestampCancel)).unsigned = false;
                    else if (typeof object.timestampCancel === "string")
                        message.timestampCancel = parseInt(object.timestampCancel, 10);
                    else if (typeof object.timestampCancel === "number")
                        message.timestampCancel = object.timestampCancel;
                    else if (typeof object.timestampCancel === "object")
                        message.timestampCancel = new $util.LongBits(object.timestampCancel.low >>> 0, object.timestampCancel.high >>> 0).toNumber();
                if (object.paymentTypes) {
                    if (!Array.isArray(object.paymentTypes))
                        throw TypeError(".fastcity.connector.Order.paymentTypes: array expected");
                    message.paymentTypes = [];
                    for (var i = 0; i < object.paymentTypes.length; ++i)
                        switch (object.paymentTypes[i]) {
                        default:
                        case "T_UNKNOWN":
                        case 0:
                            message.paymentTypes[i] = 0;
                            break;
                        case "T_PAID":
                        case 1:
                            message.paymentTypes[i] = 1;
                            break;
                        case "T_BY_CASH_TO_THE_COURIER":
                        case 2:
                            message.paymentTypes[i] = 2;
                            break;
                        case "T_BY_CARD_TO_THE_COURIER":
                        case 3:
                            message.paymentTypes[i] = 3;
                            break;
                        case "T_PAID_BY_CASH":
                        case 4:
                            message.paymentTypes[i] = 4;
                            break;
                        case "T_PAID_BY_CARD":
                        case 5:
                            message.paymentTypes[i] = 5;
                            break;
                        case "T_PAID_BY_CHECK":
                        case 6:
                            message.paymentTypes[i] = 6;
                            break;
                        case "T_PAID_BY_CREDIT":
                        case 7:
                            message.paymentTypes[i] = 7;
                            break;
                        case "T_PAID_WRITEOFF":
                        case 8:
                            message.paymentTypes[i] = 8;
                            break;
                        case "T_PAID_BY_VOUCHER":
                        case 9:
                            message.paymentTypes[i] = 9;
                            break;
                        case "T_PAID_FROM_EXTERNAL":
                        case 10:
                            message.paymentTypes[i] = 10;
                            break;
                        case "T_PAID_SMART_SALE":
                        case 11:
                            message.paymentTypes[i] = 11;
                            break;
                        case "T_PAID_BY_SBERBANK":
                        case 12:
                            message.paymentTypes[i] = 12;
                            break;
                        case "T_PAID_BY_TRPOS":
                        case 13:
                            message.paymentTypes[i] = 13;
                            break;
                        }
                }
                return message;
            };

            /**
             * Creates a plain object from an Order message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.connector.Order
             * @static
             * @param {fastcity.connector.Order} message Order
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Order.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults) {
                    object.orderItem = [];
                    object.paymentTypes = [];
                }
                if (options.defaults) {
                    object.externalOrderId = "";
                    object.externalCourierId = "";
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.timestampOpen = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.timestampOpen = options.longs === String ? "0" : 0;
                    object.status = options.enums === String ? "S_UNKNOWN" : 0;
                    object.finalPrice = "";
                    object.paymentType = options.enums === String ? "T_UNKNOWN" : 0;
                    object.clientPhone = "";
                    object.clientName = "";
                    object.clientCity = "";
                    object.comments = "";
                    object.clientStreet = "";
                    object.clientStreetNumber = "";
                    object.clientRoom = "";
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.timestampOpenWorkingDay = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.timestampOpenWorkingDay = options.longs === String ? "0" : 0;
                    object.coordinate = null;
                    object.clientCountry = "";
                    object.currencySymbol = "";
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.timestampCancel = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.timestampCancel = options.longs === String ? "0" : 0;
                }
                if (message.orderItem && message.orderItem.length) {
                    object.orderItem = [];
                    for (var j = 0; j < message.orderItem.length; ++j)
                        object.orderItem[j] = $root.fastcity.connector.OrderItem.toObject(message.orderItem[j], options);
                }
                if (message.externalOrderId != null && message.hasOwnProperty("externalOrderId"))
                    object.externalOrderId = message.externalOrderId;
                if (message.externalCourierId != null && message.hasOwnProperty("externalCourierId"))
                    object.externalCourierId = message.externalCourierId;
                if (message.timestampOpen != null && message.hasOwnProperty("timestampOpen"))
                    if (typeof message.timestampOpen === "number")
                        object.timestampOpen = options.longs === String ? String(message.timestampOpen) : message.timestampOpen;
                    else
                        object.timestampOpen = options.longs === String ? $util.Long.prototype.toString.call(message.timestampOpen) : options.longs === Number ? new $util.LongBits(message.timestampOpen.low >>> 0, message.timestampOpen.high >>> 0).toNumber() : message.timestampOpen;
                if (message.status != null && message.hasOwnProperty("status"))
                    object.status = options.enums === String ? $root.fastcity.common.Order.Status[message.status] : message.status;
                if (message.finalPrice != null && message.hasOwnProperty("finalPrice"))
                    object.finalPrice = message.finalPrice;
                if (message.paymentType != null && message.hasOwnProperty("paymentType"))
                    object.paymentType = options.enums === String ? $root.fastcity.common.Order.PaymentType[message.paymentType] : message.paymentType;
                if (message.clientPhone != null && message.hasOwnProperty("clientPhone"))
                    object.clientPhone = message.clientPhone;
                if (message.clientName != null && message.hasOwnProperty("clientName"))
                    object.clientName = message.clientName;
                if (message.clientCity != null && message.hasOwnProperty("clientCity"))
                    object.clientCity = message.clientCity;
                if (message.comments != null && message.hasOwnProperty("comments"))
                    object.comments = message.comments;
                if (message.clientStreet != null && message.hasOwnProperty("clientStreet"))
                    object.clientStreet = message.clientStreet;
                if (message.clientStreetNumber != null && message.hasOwnProperty("clientStreetNumber"))
                    object.clientStreetNumber = message.clientStreetNumber;
                if (message.clientRoom != null && message.hasOwnProperty("clientRoom"))
                    object.clientRoom = message.clientRoom;
                if (message.timestampOpenWorkingDay != null && message.hasOwnProperty("timestampOpenWorkingDay"))
                    if (typeof message.timestampOpenWorkingDay === "number")
                        object.timestampOpenWorkingDay = options.longs === String ? String(message.timestampOpenWorkingDay) : message.timestampOpenWorkingDay;
                    else
                        object.timestampOpenWorkingDay = options.longs === String ? $util.Long.prototype.toString.call(message.timestampOpenWorkingDay) : options.longs === Number ? new $util.LongBits(message.timestampOpenWorkingDay.low >>> 0, message.timestampOpenWorkingDay.high >>> 0).toNumber() : message.timestampOpenWorkingDay;
                if (message.coordinate != null && message.hasOwnProperty("coordinate"))
                    object.coordinate = $root.fastcity.common.Coordinate.toObject(message.coordinate, options);
                if (message.clientCountry != null && message.hasOwnProperty("clientCountry"))
                    object.clientCountry = message.clientCountry;
                if (message.currencySymbol != null && message.hasOwnProperty("currencySymbol"))
                    object.currencySymbol = message.currencySymbol;
                if (message.timestampCancel != null && message.hasOwnProperty("timestampCancel"))
                    if (typeof message.timestampCancel === "number")
                        object.timestampCancel = options.longs === String ? String(message.timestampCancel) : message.timestampCancel;
                    else
                        object.timestampCancel = options.longs === String ? $util.Long.prototype.toString.call(message.timestampCancel) : options.longs === Number ? new $util.LongBits(message.timestampCancel.low >>> 0, message.timestampCancel.high >>> 0).toNumber() : message.timestampCancel;
                if (message.paymentTypes && message.paymentTypes.length) {
                    object.paymentTypes = [];
                    for (var j = 0; j < message.paymentTypes.length; ++j)
                        object.paymentTypes[j] = options.enums === String ? $root.fastcity.common.Order.PaymentType[message.paymentTypes[j]] : message.paymentTypes[j];
                }
                return object;
            };

            /**
             * Converts this Order to JSON.
             * @function toJSON
             * @memberof fastcity.connector.Order
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Order.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Order;
        })();

        connector.GetOrderList = (function() {

            /**
             * Properties of a GetOrderList.
             * @memberof fastcity.connector
             * @interface IGetOrderList
             * @property {number|Long|null} [workingDay] GetOrderList workingDay
             */

            /**
             * Constructs a new GetOrderList.
             * @memberof fastcity.connector
             * @classdesc Represents a GetOrderList.
             * @implements IGetOrderList
             * @constructor
             * @param {fastcity.connector.IGetOrderList=} [properties] Properties to set
             */
            function GetOrderList(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GetOrderList workingDay.
             * @member {number|Long} workingDay
             * @memberof fastcity.connector.GetOrderList
             * @instance
             */
            GetOrderList.prototype.workingDay = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Creates a new GetOrderList instance using the specified properties.
             * @function create
             * @memberof fastcity.connector.GetOrderList
             * @static
             * @param {fastcity.connector.IGetOrderList=} [properties] Properties to set
             * @returns {fastcity.connector.GetOrderList} GetOrderList instance
             */
            GetOrderList.create = function create(properties) {
                return new GetOrderList(properties);
            };

            /**
             * Encodes the specified GetOrderList message. Does not implicitly {@link fastcity.connector.GetOrderList.verify|verify} messages.
             * @function encode
             * @memberof fastcity.connector.GetOrderList
             * @static
             * @param {fastcity.connector.IGetOrderList} message GetOrderList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetOrderList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.workingDay != null && Object.hasOwnProperty.call(message, "workingDay"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.workingDay);
                return writer;
            };

            /**
             * Decodes a GetOrderList message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.connector.GetOrderList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.connector.GetOrderList} GetOrderList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetOrderList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.connector.GetOrderList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.workingDay = reader.int64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a GetOrderList message.
             * @function verify
             * @memberof fastcity.connector.GetOrderList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetOrderList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.workingDay != null && message.hasOwnProperty("workingDay"))
                    if (!$util.isInteger(message.workingDay) && !(message.workingDay && $util.isInteger(message.workingDay.low) && $util.isInteger(message.workingDay.high)))
                        return "workingDay: integer|Long expected";
                return null;
            };

            /**
             * Creates a GetOrderList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.connector.GetOrderList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.connector.GetOrderList} GetOrderList
             */
            GetOrderList.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.connector.GetOrderList)
                    return object;
                var message = new $root.fastcity.connector.GetOrderList();
                if (object.workingDay != null)
                    if ($util.Long)
                        (message.workingDay = $util.Long.fromValue(object.workingDay)).unsigned = false;
                    else if (typeof object.workingDay === "string")
                        message.workingDay = parseInt(object.workingDay, 10);
                    else if (typeof object.workingDay === "number")
                        message.workingDay = object.workingDay;
                    else if (typeof object.workingDay === "object")
                        message.workingDay = new $util.LongBits(object.workingDay.low >>> 0, object.workingDay.high >>> 0).toNumber();
                return message;
            };

            /**
             * Creates a plain object from a GetOrderList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.connector.GetOrderList
             * @static
             * @param {fastcity.connector.GetOrderList} message GetOrderList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetOrderList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.workingDay = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.workingDay = options.longs === String ? "0" : 0;
                if (message.workingDay != null && message.hasOwnProperty("workingDay"))
                    if (typeof message.workingDay === "number")
                        object.workingDay = options.longs === String ? String(message.workingDay) : message.workingDay;
                    else
                        object.workingDay = options.longs === String ? $util.Long.prototype.toString.call(message.workingDay) : options.longs === Number ? new $util.LongBits(message.workingDay.low >>> 0, message.workingDay.high >>> 0).toNumber() : message.workingDay;
                return object;
            };

            /**
             * Converts this GetOrderList to JSON.
             * @function toJSON
             * @memberof fastcity.connector.GetOrderList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetOrderList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return GetOrderList;
        })();

        connector.OrderList = (function() {

            /**
             * Properties of an OrderList.
             * @memberof fastcity.connector
             * @interface IOrderList
             * @property {Array.<fastcity.connector.IOrder>|null} [order] OrderList order
             */

            /**
             * Constructs a new OrderList.
             * @memberof fastcity.connector
             * @classdesc Represents an OrderList.
             * @implements IOrderList
             * @constructor
             * @param {fastcity.connector.IOrderList=} [properties] Properties to set
             */
            function OrderList(properties) {
                this.order = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * OrderList order.
             * @member {Array.<fastcity.connector.IOrder>} order
             * @memberof fastcity.connector.OrderList
             * @instance
             */
            OrderList.prototype.order = $util.emptyArray;

            /**
             * Creates a new OrderList instance using the specified properties.
             * @function create
             * @memberof fastcity.connector.OrderList
             * @static
             * @param {fastcity.connector.IOrderList=} [properties] Properties to set
             * @returns {fastcity.connector.OrderList} OrderList instance
             */
            OrderList.create = function create(properties) {
                return new OrderList(properties);
            };

            /**
             * Encodes the specified OrderList message. Does not implicitly {@link fastcity.connector.OrderList.verify|verify} messages.
             * @function encode
             * @memberof fastcity.connector.OrderList
             * @static
             * @param {fastcity.connector.IOrderList} message OrderList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            OrderList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.order != null && message.order.length)
                    for (var i = 0; i < message.order.length; ++i)
                        $root.fastcity.connector.Order.encode(message.order[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes an OrderList message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.connector.OrderList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.connector.OrderList} OrderList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            OrderList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.connector.OrderList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.order && message.order.length))
                            message.order = [];
                        message.order.push($root.fastcity.connector.Order.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an OrderList message.
             * @function verify
             * @memberof fastcity.connector.OrderList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            OrderList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.order != null && message.hasOwnProperty("order")) {
                    if (!Array.isArray(message.order))
                        return "order: array expected";
                    for (var i = 0; i < message.order.length; ++i) {
                        var error = $root.fastcity.connector.Order.verify(message.order[i]);
                        if (error)
                            return "order." + error;
                    }
                }
                return null;
            };

            /**
             * Creates an OrderList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.connector.OrderList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.connector.OrderList} OrderList
             */
            OrderList.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.connector.OrderList)
                    return object;
                var message = new $root.fastcity.connector.OrderList();
                if (object.order) {
                    if (!Array.isArray(object.order))
                        throw TypeError(".fastcity.connector.OrderList.order: array expected");
                    message.order = [];
                    for (var i = 0; i < object.order.length; ++i) {
                        if (typeof object.order[i] !== "object")
                            throw TypeError(".fastcity.connector.OrderList.order: object expected");
                        message.order[i] = $root.fastcity.connector.Order.fromObject(object.order[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from an OrderList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.connector.OrderList
             * @static
             * @param {fastcity.connector.OrderList} message OrderList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            OrderList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.order = [];
                if (message.order && message.order.length) {
                    object.order = [];
                    for (var j = 0; j < message.order.length; ++j)
                        object.order[j] = $root.fastcity.connector.Order.toObject(message.order[j], options);
                }
                return object;
            };

            /**
             * Converts this OrderList to JSON.
             * @function toJSON
             * @memberof fastcity.connector.OrderList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            OrderList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return OrderList;
        })();

        connector.FinishOrder = (function() {

            /**
             * Properties of a FinishOrder.
             * @memberof fastcity.connector
             * @interface IFinishOrder
             * @property {string|null} [externalOrderId] FinishOrder externalOrderId
             * @property {number|Long|null} [deliveryTime] FinishOrder deliveryTime
             */

            /**
             * Constructs a new FinishOrder.
             * @memberof fastcity.connector
             * @classdesc Represents a FinishOrder.
             * @implements IFinishOrder
             * @constructor
             * @param {fastcity.connector.IFinishOrder=} [properties] Properties to set
             */
            function FinishOrder(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * FinishOrder externalOrderId.
             * @member {string} externalOrderId
             * @memberof fastcity.connector.FinishOrder
             * @instance
             */
            FinishOrder.prototype.externalOrderId = "";

            /**
             * FinishOrder deliveryTime.
             * @member {number|Long} deliveryTime
             * @memberof fastcity.connector.FinishOrder
             * @instance
             */
            FinishOrder.prototype.deliveryTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Creates a new FinishOrder instance using the specified properties.
             * @function create
             * @memberof fastcity.connector.FinishOrder
             * @static
             * @param {fastcity.connector.IFinishOrder=} [properties] Properties to set
             * @returns {fastcity.connector.FinishOrder} FinishOrder instance
             */
            FinishOrder.create = function create(properties) {
                return new FinishOrder(properties);
            };

            /**
             * Encodes the specified FinishOrder message. Does not implicitly {@link fastcity.connector.FinishOrder.verify|verify} messages.
             * @function encode
             * @memberof fastcity.connector.FinishOrder
             * @static
             * @param {fastcity.connector.IFinishOrder} message FinishOrder message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FinishOrder.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.externalOrderId != null && Object.hasOwnProperty.call(message, "externalOrderId"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.externalOrderId);
                if (message.deliveryTime != null && Object.hasOwnProperty.call(message, "deliveryTime"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int64(message.deliveryTime);
                return writer;
            };

            /**
             * Decodes a FinishOrder message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.connector.FinishOrder
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.connector.FinishOrder} FinishOrder
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FinishOrder.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.connector.FinishOrder();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.externalOrderId = reader.string();
                        break;
                    case 2:
                        message.deliveryTime = reader.int64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a FinishOrder message.
             * @function verify
             * @memberof fastcity.connector.FinishOrder
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            FinishOrder.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.externalOrderId != null && message.hasOwnProperty("externalOrderId"))
                    if (!$util.isString(message.externalOrderId))
                        return "externalOrderId: string expected";
                if (message.deliveryTime != null && message.hasOwnProperty("deliveryTime"))
                    if (!$util.isInteger(message.deliveryTime) && !(message.deliveryTime && $util.isInteger(message.deliveryTime.low) && $util.isInteger(message.deliveryTime.high)))
                        return "deliveryTime: integer|Long expected";
                return null;
            };

            /**
             * Creates a FinishOrder message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.connector.FinishOrder
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.connector.FinishOrder} FinishOrder
             */
            FinishOrder.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.connector.FinishOrder)
                    return object;
                var message = new $root.fastcity.connector.FinishOrder();
                if (object.externalOrderId != null)
                    message.externalOrderId = String(object.externalOrderId);
                if (object.deliveryTime != null)
                    if ($util.Long)
                        (message.deliveryTime = $util.Long.fromValue(object.deliveryTime)).unsigned = false;
                    else if (typeof object.deliveryTime === "string")
                        message.deliveryTime = parseInt(object.deliveryTime, 10);
                    else if (typeof object.deliveryTime === "number")
                        message.deliveryTime = object.deliveryTime;
                    else if (typeof object.deliveryTime === "object")
                        message.deliveryTime = new $util.LongBits(object.deliveryTime.low >>> 0, object.deliveryTime.high >>> 0).toNumber();
                return message;
            };

            /**
             * Creates a plain object from a FinishOrder message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.connector.FinishOrder
             * @static
             * @param {fastcity.connector.FinishOrder} message FinishOrder
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FinishOrder.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.externalOrderId = "";
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.deliveryTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.deliveryTime = options.longs === String ? "0" : 0;
                }
                if (message.externalOrderId != null && message.hasOwnProperty("externalOrderId"))
                    object.externalOrderId = message.externalOrderId;
                if (message.deliveryTime != null && message.hasOwnProperty("deliveryTime"))
                    if (typeof message.deliveryTime === "number")
                        object.deliveryTime = options.longs === String ? String(message.deliveryTime) : message.deliveryTime;
                    else
                        object.deliveryTime = options.longs === String ? $util.Long.prototype.toString.call(message.deliveryTime) : options.longs === Number ? new $util.LongBits(message.deliveryTime.low >>> 0, message.deliveryTime.high >>> 0).toNumber() : message.deliveryTime;
                return object;
            };

            /**
             * Converts this FinishOrder to JSON.
             * @function toJSON
             * @memberof fastcity.connector.FinishOrder
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FinishOrder.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return FinishOrder;
        })();

        connector.Quit = (function() {

            /**
             * Properties of a Quit.
             * @memberof fastcity.connector
             * @interface IQuit
             */

            /**
             * Constructs a new Quit.
             * @memberof fastcity.connector
             * @classdesc Represents a Quit.
             * @implements IQuit
             * @constructor
             * @param {fastcity.connector.IQuit=} [properties] Properties to set
             */
            function Quit(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new Quit instance using the specified properties.
             * @function create
             * @memberof fastcity.connector.Quit
             * @static
             * @param {fastcity.connector.IQuit=} [properties] Properties to set
             * @returns {fastcity.connector.Quit} Quit instance
             */
            Quit.create = function create(properties) {
                return new Quit(properties);
            };

            /**
             * Encodes the specified Quit message. Does not implicitly {@link fastcity.connector.Quit.verify|verify} messages.
             * @function encode
             * @memberof fastcity.connector.Quit
             * @static
             * @param {fastcity.connector.IQuit} message Quit message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Quit.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Decodes a Quit message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.connector.Quit
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.connector.Quit} Quit
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Quit.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.connector.Quit();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a Quit message.
             * @function verify
             * @memberof fastcity.connector.Quit
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Quit.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a Quit message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.connector.Quit
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.connector.Quit} Quit
             */
            Quit.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.connector.Quit)
                    return object;
                return new $root.fastcity.connector.Quit();
            };

            /**
             * Creates a plain object from a Quit message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.connector.Quit
             * @static
             * @param {fastcity.connector.Quit} message Quit
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Quit.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this Quit to JSON.
             * @function toJSON
             * @memberof fastcity.connector.Quit
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Quit.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Quit;
        })();

        connector.Result = (function() {

            /**
             * Properties of a Result.
             * @memberof fastcity.connector
             * @interface IResult
             * @property {fastcity.connector.Result.Code|null} [code] Result code
             * @property {string|null} [description] Result description
             */

            /**
             * Constructs a new Result.
             * @memberof fastcity.connector
             * @classdesc Represents a Result.
             * @implements IResult
             * @constructor
             * @param {fastcity.connector.IResult=} [properties] Properties to set
             */
            function Result(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Result code.
             * @member {fastcity.connector.Result.Code} code
             * @memberof fastcity.connector.Result
             * @instance
             */
            Result.prototype.code = 0;

            /**
             * Result description.
             * @member {string} description
             * @memberof fastcity.connector.Result
             * @instance
             */
            Result.prototype.description = "";

            /**
             * Creates a new Result instance using the specified properties.
             * @function create
             * @memberof fastcity.connector.Result
             * @static
             * @param {fastcity.connector.IResult=} [properties] Properties to set
             * @returns {fastcity.connector.Result} Result instance
             */
            Result.create = function create(properties) {
                return new Result(properties);
            };

            /**
             * Encodes the specified Result message. Does not implicitly {@link fastcity.connector.Result.verify|verify} messages.
             * @function encode
             * @memberof fastcity.connector.Result
             * @static
             * @param {fastcity.connector.IResult} message Result message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Result.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.code != null && Object.hasOwnProperty.call(message, "code"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.code);
                if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.description);
                return writer;
            };

            /**
             * Decodes a Result message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.connector.Result
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.connector.Result} Result
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Result.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.connector.Result();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.code = reader.int32();
                        break;
                    case 2:
                        message.description = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a Result message.
             * @function verify
             * @memberof fastcity.connector.Result
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Result.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.code != null && message.hasOwnProperty("code"))
                    switch (message.code) {
                    default:
                        return "code: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
                if (message.description != null && message.hasOwnProperty("description"))
                    if (!$util.isString(message.description))
                        return "description: string expected";
                return null;
            };

            /**
             * Creates a Result message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.connector.Result
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.connector.Result} Result
             */
            Result.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.connector.Result)
                    return object;
                var message = new $root.fastcity.connector.Result();
                switch (object.code) {
                case "SUCCESS":
                case 0:
                    message.code = 0;
                    break;
                case "INVALID_REQUEST":
                case 1:
                    message.code = 1;
                    break;
                case "SERVER_ERROR":
                case 2:
                    message.code = 2;
                    break;
                }
                if (object.description != null)
                    message.description = String(object.description);
                return message;
            };

            /**
             * Creates a plain object from a Result message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.connector.Result
             * @static
             * @param {fastcity.connector.Result} message Result
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Result.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.code = options.enums === String ? "SUCCESS" : 0;
                    object.description = "";
                }
                if (message.code != null && message.hasOwnProperty("code"))
                    object.code = options.enums === String ? $root.fastcity.connector.Result.Code[message.code] : message.code;
                if (message.description != null && message.hasOwnProperty("description"))
                    object.description = message.description;
                return object;
            };

            /**
             * Converts this Result to JSON.
             * @function toJSON
             * @memberof fastcity.connector.Result
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Result.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Code enum.
             * @name fastcity.connector.Result.Code
             * @enum {number}
             * @property {number} SUCCESS=0 SUCCESS value
             * @property {number} INVALID_REQUEST=1 INVALID_REQUEST value
             * @property {number} SERVER_ERROR=2 SERVER_ERROR value
             */
            Result.Code = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "SUCCESS"] = 0;
                values[valuesById[1] = "INVALID_REQUEST"] = 1;
                values[valuesById[2] = "SERVER_ERROR"] = 2;
                return values;
            })();

            return Result;
        })();

        connector.StartOfWorkingDay = (function() {

            /**
             * Properties of a StartOfWorkingDay.
             * @memberof fastcity.connector
             * @interface IStartOfWorkingDay
             * @property {number|Long|null} [timestamp] StartOfWorkingDay timestamp
             */

            /**
             * Constructs a new StartOfWorkingDay.
             * @memberof fastcity.connector
             * @classdesc Represents a StartOfWorkingDay.
             * @implements IStartOfWorkingDay
             * @constructor
             * @param {fastcity.connector.IStartOfWorkingDay=} [properties] Properties to set
             */
            function StartOfWorkingDay(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * StartOfWorkingDay timestamp.
             * @member {number|Long} timestamp
             * @memberof fastcity.connector.StartOfWorkingDay
             * @instance
             */
            StartOfWorkingDay.prototype.timestamp = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Creates a new StartOfWorkingDay instance using the specified properties.
             * @function create
             * @memberof fastcity.connector.StartOfWorkingDay
             * @static
             * @param {fastcity.connector.IStartOfWorkingDay=} [properties] Properties to set
             * @returns {fastcity.connector.StartOfWorkingDay} StartOfWorkingDay instance
             */
            StartOfWorkingDay.create = function create(properties) {
                return new StartOfWorkingDay(properties);
            };

            /**
             * Encodes the specified StartOfWorkingDay message. Does not implicitly {@link fastcity.connector.StartOfWorkingDay.verify|verify} messages.
             * @function encode
             * @memberof fastcity.connector.StartOfWorkingDay
             * @static
             * @param {fastcity.connector.IStartOfWorkingDay} message StartOfWorkingDay message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            StartOfWorkingDay.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.timestamp != null && Object.hasOwnProperty.call(message, "timestamp"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.timestamp);
                return writer;
            };

            /**
             * Decodes a StartOfWorkingDay message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.connector.StartOfWorkingDay
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.connector.StartOfWorkingDay} StartOfWorkingDay
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            StartOfWorkingDay.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.connector.StartOfWorkingDay();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.timestamp = reader.int64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a StartOfWorkingDay message.
             * @function verify
             * @memberof fastcity.connector.StartOfWorkingDay
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            StartOfWorkingDay.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.timestamp != null && message.hasOwnProperty("timestamp"))
                    if (!$util.isInteger(message.timestamp) && !(message.timestamp && $util.isInteger(message.timestamp.low) && $util.isInteger(message.timestamp.high)))
                        return "timestamp: integer|Long expected";
                return null;
            };

            /**
             * Creates a StartOfWorkingDay message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.connector.StartOfWorkingDay
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.connector.StartOfWorkingDay} StartOfWorkingDay
             */
            StartOfWorkingDay.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.connector.StartOfWorkingDay)
                    return object;
                var message = new $root.fastcity.connector.StartOfWorkingDay();
                if (object.timestamp != null)
                    if ($util.Long)
                        (message.timestamp = $util.Long.fromValue(object.timestamp)).unsigned = false;
                    else if (typeof object.timestamp === "string")
                        message.timestamp = parseInt(object.timestamp, 10);
                    else if (typeof object.timestamp === "number")
                        message.timestamp = object.timestamp;
                    else if (typeof object.timestamp === "object")
                        message.timestamp = new $util.LongBits(object.timestamp.low >>> 0, object.timestamp.high >>> 0).toNumber();
                return message;
            };

            /**
             * Creates a plain object from a StartOfWorkingDay message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.connector.StartOfWorkingDay
             * @static
             * @param {fastcity.connector.StartOfWorkingDay} message StartOfWorkingDay
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            StartOfWorkingDay.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.timestamp = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.timestamp = options.longs === String ? "0" : 0;
                if (message.timestamp != null && message.hasOwnProperty("timestamp"))
                    if (typeof message.timestamp === "number")
                        object.timestamp = options.longs === String ? String(message.timestamp) : message.timestamp;
                    else
                        object.timestamp = options.longs === String ? $util.Long.prototype.toString.call(message.timestamp) : options.longs === Number ? new $util.LongBits(message.timestamp.low >>> 0, message.timestamp.high >>> 0).toNumber() : message.timestamp;
                return object;
            };

            /**
             * Converts this StartOfWorkingDay to JSON.
             * @function toJSON
             * @memberof fastcity.connector.StartOfWorkingDay
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            StartOfWorkingDay.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return StartOfWorkingDay;
        })();

        connector.WorkingTime = (function() {

            /**
             * Properties of a WorkingTime.
             * @memberof fastcity.connector
             * @interface IWorkingTime
             * @property {Array.<fastcity.connector.WorkingTime.IInterval>|null} [interval] WorkingTime interval
             */

            /**
             * Constructs a new WorkingTime.
             * @memberof fastcity.connector
             * @classdesc Represents a WorkingTime.
             * @implements IWorkingTime
             * @constructor
             * @param {fastcity.connector.IWorkingTime=} [properties] Properties to set
             */
            function WorkingTime(properties) {
                this.interval = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * WorkingTime interval.
             * @member {Array.<fastcity.connector.WorkingTime.IInterval>} interval
             * @memberof fastcity.connector.WorkingTime
             * @instance
             */
            WorkingTime.prototype.interval = $util.emptyArray;

            /**
             * Creates a new WorkingTime instance using the specified properties.
             * @function create
             * @memberof fastcity.connector.WorkingTime
             * @static
             * @param {fastcity.connector.IWorkingTime=} [properties] Properties to set
             * @returns {fastcity.connector.WorkingTime} WorkingTime instance
             */
            WorkingTime.create = function create(properties) {
                return new WorkingTime(properties);
            };

            /**
             * Encodes the specified WorkingTime message. Does not implicitly {@link fastcity.connector.WorkingTime.verify|verify} messages.
             * @function encode
             * @memberof fastcity.connector.WorkingTime
             * @static
             * @param {fastcity.connector.IWorkingTime} message WorkingTime message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WorkingTime.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.interval != null && message.interval.length)
                    for (var i = 0; i < message.interval.length; ++i)
                        $root.fastcity.connector.WorkingTime.Interval.encode(message.interval[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a WorkingTime message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.connector.WorkingTime
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.connector.WorkingTime} WorkingTime
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WorkingTime.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.connector.WorkingTime();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.interval && message.interval.length))
                            message.interval = [];
                        message.interval.push($root.fastcity.connector.WorkingTime.Interval.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a WorkingTime message.
             * @function verify
             * @memberof fastcity.connector.WorkingTime
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            WorkingTime.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.interval != null && message.hasOwnProperty("interval")) {
                    if (!Array.isArray(message.interval))
                        return "interval: array expected";
                    for (var i = 0; i < message.interval.length; ++i) {
                        var error = $root.fastcity.connector.WorkingTime.Interval.verify(message.interval[i]);
                        if (error)
                            return "interval." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a WorkingTime message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.connector.WorkingTime
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.connector.WorkingTime} WorkingTime
             */
            WorkingTime.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.connector.WorkingTime)
                    return object;
                var message = new $root.fastcity.connector.WorkingTime();
                if (object.interval) {
                    if (!Array.isArray(object.interval))
                        throw TypeError(".fastcity.connector.WorkingTime.interval: array expected");
                    message.interval = [];
                    for (var i = 0; i < object.interval.length; ++i) {
                        if (typeof object.interval[i] !== "object")
                            throw TypeError(".fastcity.connector.WorkingTime.interval: object expected");
                        message.interval[i] = $root.fastcity.connector.WorkingTime.Interval.fromObject(object.interval[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a WorkingTime message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.connector.WorkingTime
             * @static
             * @param {fastcity.connector.WorkingTime} message WorkingTime
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            WorkingTime.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.interval = [];
                if (message.interval && message.interval.length) {
                    object.interval = [];
                    for (var j = 0; j < message.interval.length; ++j)
                        object.interval[j] = $root.fastcity.connector.WorkingTime.Interval.toObject(message.interval[j], options);
                }
                return object;
            };

            /**
             * Converts this WorkingTime to JSON.
             * @function toJSON
             * @memberof fastcity.connector.WorkingTime
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            WorkingTime.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            WorkingTime.Interval = (function() {

                /**
                 * Properties of an Interval.
                 * @memberof fastcity.connector.WorkingTime
                 * @interface IInterval
                 * @property {number|Long|null} [openTime] Interval openTime
                 * @property {number|Long|null} [closeTime] Interval closeTime
                 */

                /**
                 * Constructs a new Interval.
                 * @memberof fastcity.connector.WorkingTime
                 * @classdesc Represents an Interval.
                 * @implements IInterval
                 * @constructor
                 * @param {fastcity.connector.WorkingTime.IInterval=} [properties] Properties to set
                 */
                function Interval(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Interval openTime.
                 * @member {number|Long} openTime
                 * @memberof fastcity.connector.WorkingTime.Interval
                 * @instance
                 */
                Interval.prototype.openTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Interval closeTime.
                 * @member {number|Long} closeTime
                 * @memberof fastcity.connector.WorkingTime.Interval
                 * @instance
                 */
                Interval.prototype.closeTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Creates a new Interval instance using the specified properties.
                 * @function create
                 * @memberof fastcity.connector.WorkingTime.Interval
                 * @static
                 * @param {fastcity.connector.WorkingTime.IInterval=} [properties] Properties to set
                 * @returns {fastcity.connector.WorkingTime.Interval} Interval instance
                 */
                Interval.create = function create(properties) {
                    return new Interval(properties);
                };

                /**
                 * Encodes the specified Interval message. Does not implicitly {@link fastcity.connector.WorkingTime.Interval.verify|verify} messages.
                 * @function encode
                 * @memberof fastcity.connector.WorkingTime.Interval
                 * @static
                 * @param {fastcity.connector.WorkingTime.IInterval} message Interval message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Interval.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.openTime != null && Object.hasOwnProperty.call(message, "openTime"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int64(message.openTime);
                    if (message.closeTime != null && Object.hasOwnProperty.call(message, "closeTime"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int64(message.closeTime);
                    return writer;
                };

                /**
                 * Decodes an Interval message from the specified reader or buffer.
                 * @function decode
                 * @memberof fastcity.connector.WorkingTime.Interval
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {fastcity.connector.WorkingTime.Interval} Interval
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Interval.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.connector.WorkingTime.Interval();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.openTime = reader.int64();
                            break;
                        case 2:
                            message.closeTime = reader.int64();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Verifies an Interval message.
                 * @function verify
                 * @memberof fastcity.connector.WorkingTime.Interval
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Interval.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.openTime != null && message.hasOwnProperty("openTime"))
                        if (!$util.isInteger(message.openTime) && !(message.openTime && $util.isInteger(message.openTime.low) && $util.isInteger(message.openTime.high)))
                            return "openTime: integer|Long expected";
                    if (message.closeTime != null && message.hasOwnProperty("closeTime"))
                        if (!$util.isInteger(message.closeTime) && !(message.closeTime && $util.isInteger(message.closeTime.low) && $util.isInteger(message.closeTime.high)))
                            return "closeTime: integer|Long expected";
                    return null;
                };

                /**
                 * Creates an Interval message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof fastcity.connector.WorkingTime.Interval
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {fastcity.connector.WorkingTime.Interval} Interval
                 */
                Interval.fromObject = function fromObject(object) {
                    if (object instanceof $root.fastcity.connector.WorkingTime.Interval)
                        return object;
                    var message = new $root.fastcity.connector.WorkingTime.Interval();
                    if (object.openTime != null)
                        if ($util.Long)
                            (message.openTime = $util.Long.fromValue(object.openTime)).unsigned = false;
                        else if (typeof object.openTime === "string")
                            message.openTime = parseInt(object.openTime, 10);
                        else if (typeof object.openTime === "number")
                            message.openTime = object.openTime;
                        else if (typeof object.openTime === "object")
                            message.openTime = new $util.LongBits(object.openTime.low >>> 0, object.openTime.high >>> 0).toNumber();
                    if (object.closeTime != null)
                        if ($util.Long)
                            (message.closeTime = $util.Long.fromValue(object.closeTime)).unsigned = false;
                        else if (typeof object.closeTime === "string")
                            message.closeTime = parseInt(object.closeTime, 10);
                        else if (typeof object.closeTime === "number")
                            message.closeTime = object.closeTime;
                        else if (typeof object.closeTime === "object")
                            message.closeTime = new $util.LongBits(object.closeTime.low >>> 0, object.closeTime.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from an Interval message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof fastcity.connector.WorkingTime.Interval
                 * @static
                 * @param {fastcity.connector.WorkingTime.Interval} message Interval
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Interval.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.openTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.openTime = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.closeTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.closeTime = options.longs === String ? "0" : 0;
                    }
                    if (message.openTime != null && message.hasOwnProperty("openTime"))
                        if (typeof message.openTime === "number")
                            object.openTime = options.longs === String ? String(message.openTime) : message.openTime;
                        else
                            object.openTime = options.longs === String ? $util.Long.prototype.toString.call(message.openTime) : options.longs === Number ? new $util.LongBits(message.openTime.low >>> 0, message.openTime.high >>> 0).toNumber() : message.openTime;
                    if (message.closeTime != null && message.hasOwnProperty("closeTime"))
                        if (typeof message.closeTime === "number")
                            object.closeTime = options.longs === String ? String(message.closeTime) : message.closeTime;
                        else
                            object.closeTime = options.longs === String ? $util.Long.prototype.toString.call(message.closeTime) : options.longs === Number ? new $util.LongBits(message.closeTime.low >>> 0, message.closeTime.high >>> 0).toNumber() : message.closeTime;
                    return object;
                };

                /**
                 * Converts this Interval to JSON.
                 * @function toJSON
                 * @memberof fastcity.connector.WorkingTime.Interval
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Interval.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return Interval;
            })();

            return WorkingTime;
        })();

        connector.GetStartOfWorkingDay = (function() {

            /**
             * Properties of a GetStartOfWorkingDay.
             * @memberof fastcity.connector
             * @interface IGetStartOfWorkingDay
             */

            /**
             * Constructs a new GetStartOfWorkingDay.
             * @memberof fastcity.connector
             * @classdesc Represents a GetStartOfWorkingDay.
             * @implements IGetStartOfWorkingDay
             * @constructor
             * @param {fastcity.connector.IGetStartOfWorkingDay=} [properties] Properties to set
             */
            function GetStartOfWorkingDay(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new GetStartOfWorkingDay instance using the specified properties.
             * @function create
             * @memberof fastcity.connector.GetStartOfWorkingDay
             * @static
             * @param {fastcity.connector.IGetStartOfWorkingDay=} [properties] Properties to set
             * @returns {fastcity.connector.GetStartOfWorkingDay} GetStartOfWorkingDay instance
             */
            GetStartOfWorkingDay.create = function create(properties) {
                return new GetStartOfWorkingDay(properties);
            };

            /**
             * Encodes the specified GetStartOfWorkingDay message. Does not implicitly {@link fastcity.connector.GetStartOfWorkingDay.verify|verify} messages.
             * @function encode
             * @memberof fastcity.connector.GetStartOfWorkingDay
             * @static
             * @param {fastcity.connector.IGetStartOfWorkingDay} message GetStartOfWorkingDay message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetStartOfWorkingDay.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Decodes a GetStartOfWorkingDay message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.connector.GetStartOfWorkingDay
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.connector.GetStartOfWorkingDay} GetStartOfWorkingDay
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetStartOfWorkingDay.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.connector.GetStartOfWorkingDay();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a GetStartOfWorkingDay message.
             * @function verify
             * @memberof fastcity.connector.GetStartOfWorkingDay
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetStartOfWorkingDay.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a GetStartOfWorkingDay message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.connector.GetStartOfWorkingDay
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.connector.GetStartOfWorkingDay} GetStartOfWorkingDay
             */
            GetStartOfWorkingDay.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.connector.GetStartOfWorkingDay)
                    return object;
                return new $root.fastcity.connector.GetStartOfWorkingDay();
            };

            /**
             * Creates a plain object from a GetStartOfWorkingDay message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.connector.GetStartOfWorkingDay
             * @static
             * @param {fastcity.connector.GetStartOfWorkingDay} message GetStartOfWorkingDay
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetStartOfWorkingDay.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this GetStartOfWorkingDay to JSON.
             * @function toJSON
             * @memberof fastcity.connector.GetStartOfWorkingDay
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetStartOfWorkingDay.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return GetStartOfWorkingDay;
        })();

        connector.GetWorkingTime = (function() {

            /**
             * Properties of a GetWorkingTime.
             * @memberof fastcity.connector
             * @interface IGetWorkingTime
             */

            /**
             * Constructs a new GetWorkingTime.
             * @memberof fastcity.connector
             * @classdesc Represents a GetWorkingTime.
             * @implements IGetWorkingTime
             * @constructor
             * @param {fastcity.connector.IGetWorkingTime=} [properties] Properties to set
             */
            function GetWorkingTime(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new GetWorkingTime instance using the specified properties.
             * @function create
             * @memberof fastcity.connector.GetWorkingTime
             * @static
             * @param {fastcity.connector.IGetWorkingTime=} [properties] Properties to set
             * @returns {fastcity.connector.GetWorkingTime} GetWorkingTime instance
             */
            GetWorkingTime.create = function create(properties) {
                return new GetWorkingTime(properties);
            };

            /**
             * Encodes the specified GetWorkingTime message. Does not implicitly {@link fastcity.connector.GetWorkingTime.verify|verify} messages.
             * @function encode
             * @memberof fastcity.connector.GetWorkingTime
             * @static
             * @param {fastcity.connector.IGetWorkingTime} message GetWorkingTime message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetWorkingTime.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Decodes a GetWorkingTime message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.connector.GetWorkingTime
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.connector.GetWorkingTime} GetWorkingTime
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetWorkingTime.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.connector.GetWorkingTime();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a GetWorkingTime message.
             * @function verify
             * @memberof fastcity.connector.GetWorkingTime
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetWorkingTime.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a GetWorkingTime message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.connector.GetWorkingTime
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.connector.GetWorkingTime} GetWorkingTime
             */
            GetWorkingTime.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.connector.GetWorkingTime)
                    return object;
                return new $root.fastcity.connector.GetWorkingTime();
            };

            /**
             * Creates a plain object from a GetWorkingTime message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.connector.GetWorkingTime
             * @static
             * @param {fastcity.connector.GetWorkingTime} message GetWorkingTime
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetWorkingTime.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this GetWorkingTime to JSON.
             * @function toJSON
             * @memberof fastcity.connector.GetWorkingTime
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetWorkingTime.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return GetWorkingTime;
        })();

        connector.Handshake = (function() {

            /**
             * Properties of a Handshake.
             * @memberof fastcity.connector
             * @interface IHandshake
             * @property {string|null} [authToken] Handshake authToken
             * @property {string|null} [externalSubCompanyId] Handshake externalSubCompanyId
             * @property {fastcity.connector.Handshake.ILauncher|null} [launcher] Handshake launcher
             * @property {fastcity.connector.Handshake.IConnector|null} [connector] Handshake connector
             */

            /**
             * Constructs a new Handshake.
             * @memberof fastcity.connector
             * @classdesc Represents a Handshake.
             * @implements IHandshake
             * @constructor
             * @param {fastcity.connector.IHandshake=} [properties] Properties to set
             */
            function Handshake(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Handshake authToken.
             * @member {string} authToken
             * @memberof fastcity.connector.Handshake
             * @instance
             */
            Handshake.prototype.authToken = "";

            /**
             * Handshake externalSubCompanyId.
             * @member {string} externalSubCompanyId
             * @memberof fastcity.connector.Handshake
             * @instance
             */
            Handshake.prototype.externalSubCompanyId = "";

            /**
             * Handshake launcher.
             * @member {fastcity.connector.Handshake.ILauncher|null|undefined} launcher
             * @memberof fastcity.connector.Handshake
             * @instance
             */
            Handshake.prototype.launcher = null;

            /**
             * Handshake connector.
             * @member {fastcity.connector.Handshake.IConnector|null|undefined} connector
             * @memberof fastcity.connector.Handshake
             * @instance
             */
            Handshake.prototype.connector = null;

            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;

            /**
             * Handshake type.
             * @member {"launcher"|"connector"|undefined} type
             * @memberof fastcity.connector.Handshake
             * @instance
             */
            Object.defineProperty(Handshake.prototype, "type", {
                get: $util.oneOfGetter($oneOfFields = ["launcher", "connector"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Creates a new Handshake instance using the specified properties.
             * @function create
             * @memberof fastcity.connector.Handshake
             * @static
             * @param {fastcity.connector.IHandshake=} [properties] Properties to set
             * @returns {fastcity.connector.Handshake} Handshake instance
             */
            Handshake.create = function create(properties) {
                return new Handshake(properties);
            };

            /**
             * Encodes the specified Handshake message. Does not implicitly {@link fastcity.connector.Handshake.verify|verify} messages.
             * @function encode
             * @memberof fastcity.connector.Handshake
             * @static
             * @param {fastcity.connector.IHandshake} message Handshake message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Handshake.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.authToken != null && Object.hasOwnProperty.call(message, "authToken"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.authToken);
                if (message.externalSubCompanyId != null && Object.hasOwnProperty.call(message, "externalSubCompanyId"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.externalSubCompanyId);
                if (message.launcher != null && Object.hasOwnProperty.call(message, "launcher"))
                    $root.fastcity.connector.Handshake.Launcher.encode(message.launcher, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.connector != null && Object.hasOwnProperty.call(message, "connector"))
                    $root.fastcity.connector.Handshake.Connector.encode(message.connector, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a Handshake message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.connector.Handshake
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.connector.Handshake} Handshake
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Handshake.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.connector.Handshake();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.authToken = reader.string();
                        break;
                    case 2:
                        message.externalSubCompanyId = reader.string();
                        break;
                    case 3:
                        message.launcher = $root.fastcity.connector.Handshake.Launcher.decode(reader, reader.uint32());
                        break;
                    case 4:
                        message.connector = $root.fastcity.connector.Handshake.Connector.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a Handshake message.
             * @function verify
             * @memberof fastcity.connector.Handshake
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Handshake.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                var properties = {};
                if (message.authToken != null && message.hasOwnProperty("authToken"))
                    if (!$util.isString(message.authToken))
                        return "authToken: string expected";
                if (message.externalSubCompanyId != null && message.hasOwnProperty("externalSubCompanyId"))
                    if (!$util.isString(message.externalSubCompanyId))
                        return "externalSubCompanyId: string expected";
                if (message.launcher != null && message.hasOwnProperty("launcher")) {
                    properties.type = 1;
                    {
                        var error = $root.fastcity.connector.Handshake.Launcher.verify(message.launcher);
                        if (error)
                            return "launcher." + error;
                    }
                }
                if (message.connector != null && message.hasOwnProperty("connector")) {
                    if (properties.type === 1)
                        return "type: multiple values";
                    properties.type = 1;
                    {
                        var error = $root.fastcity.connector.Handshake.Connector.verify(message.connector);
                        if (error)
                            return "connector." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a Handshake message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.connector.Handshake
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.connector.Handshake} Handshake
             */
            Handshake.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.connector.Handshake)
                    return object;
                var message = new $root.fastcity.connector.Handshake();
                if (object.authToken != null)
                    message.authToken = String(object.authToken);
                if (object.externalSubCompanyId != null)
                    message.externalSubCompanyId = String(object.externalSubCompanyId);
                if (object.launcher != null) {
                    if (typeof object.launcher !== "object")
                        throw TypeError(".fastcity.connector.Handshake.launcher: object expected");
                    message.launcher = $root.fastcity.connector.Handshake.Launcher.fromObject(object.launcher);
                }
                if (object.connector != null) {
                    if (typeof object.connector !== "object")
                        throw TypeError(".fastcity.connector.Handshake.connector: object expected");
                    message.connector = $root.fastcity.connector.Handshake.Connector.fromObject(object.connector);
                }
                return message;
            };

            /**
             * Creates a plain object from a Handshake message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.connector.Handshake
             * @static
             * @param {fastcity.connector.Handshake} message Handshake
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Handshake.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.authToken = "";
                    object.externalSubCompanyId = "";
                }
                if (message.authToken != null && message.hasOwnProperty("authToken"))
                    object.authToken = message.authToken;
                if (message.externalSubCompanyId != null && message.hasOwnProperty("externalSubCompanyId"))
                    object.externalSubCompanyId = message.externalSubCompanyId;
                if (message.launcher != null && message.hasOwnProperty("launcher")) {
                    object.launcher = $root.fastcity.connector.Handshake.Launcher.toObject(message.launcher, options);
                    if (options.oneofs)
                        object.type = "launcher";
                }
                if (message.connector != null && message.hasOwnProperty("connector")) {
                    object.connector = $root.fastcity.connector.Handshake.Connector.toObject(message.connector, options);
                    if (options.oneofs)
                        object.type = "connector";
                }
                return object;
            };

            /**
             * Converts this Handshake to JSON.
             * @function toJSON
             * @memberof fastcity.connector.Handshake
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Handshake.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            Handshake.Launcher = (function() {

                /**
                 * Properties of a Launcher.
                 * @memberof fastcity.connector.Handshake
                 * @interface ILauncher
                 * @property {fastcity.common.IVersion|null} [launcherVersion] Launcher launcherVersion
                 * @property {fastcity.common.IVersion|null} [connectorVersion] Launcher connectorVersion
                 */

                /**
                 * Constructs a new Launcher.
                 * @memberof fastcity.connector.Handshake
                 * @classdesc Represents a Launcher.
                 * @implements ILauncher
                 * @constructor
                 * @param {fastcity.connector.Handshake.ILauncher=} [properties] Properties to set
                 */
                function Launcher(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Launcher launcherVersion.
                 * @member {fastcity.common.IVersion|null|undefined} launcherVersion
                 * @memberof fastcity.connector.Handshake.Launcher
                 * @instance
                 */
                Launcher.prototype.launcherVersion = null;

                /**
                 * Launcher connectorVersion.
                 * @member {fastcity.common.IVersion|null|undefined} connectorVersion
                 * @memberof fastcity.connector.Handshake.Launcher
                 * @instance
                 */
                Launcher.prototype.connectorVersion = null;

                /**
                 * Creates a new Launcher instance using the specified properties.
                 * @function create
                 * @memberof fastcity.connector.Handshake.Launcher
                 * @static
                 * @param {fastcity.connector.Handshake.ILauncher=} [properties] Properties to set
                 * @returns {fastcity.connector.Handshake.Launcher} Launcher instance
                 */
                Launcher.create = function create(properties) {
                    return new Launcher(properties);
                };

                /**
                 * Encodes the specified Launcher message. Does not implicitly {@link fastcity.connector.Handshake.Launcher.verify|verify} messages.
                 * @function encode
                 * @memberof fastcity.connector.Handshake.Launcher
                 * @static
                 * @param {fastcity.connector.Handshake.ILauncher} message Launcher message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Launcher.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.launcherVersion != null && Object.hasOwnProperty.call(message, "launcherVersion"))
                        $root.fastcity.common.Version.encode(message.launcherVersion, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.connectorVersion != null && Object.hasOwnProperty.call(message, "connectorVersion"))
                        $root.fastcity.common.Version.encode(message.connectorVersion, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Decodes a Launcher message from the specified reader or buffer.
                 * @function decode
                 * @memberof fastcity.connector.Handshake.Launcher
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {fastcity.connector.Handshake.Launcher} Launcher
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Launcher.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.connector.Handshake.Launcher();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.launcherVersion = $root.fastcity.common.Version.decode(reader, reader.uint32());
                            break;
                        case 2:
                            message.connectorVersion = $root.fastcity.common.Version.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Verifies a Launcher message.
                 * @function verify
                 * @memberof fastcity.connector.Handshake.Launcher
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Launcher.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.launcherVersion != null && message.hasOwnProperty("launcherVersion")) {
                        var error = $root.fastcity.common.Version.verify(message.launcherVersion);
                        if (error)
                            return "launcherVersion." + error;
                    }
                    if (message.connectorVersion != null && message.hasOwnProperty("connectorVersion")) {
                        var error = $root.fastcity.common.Version.verify(message.connectorVersion);
                        if (error)
                            return "connectorVersion." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Launcher message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof fastcity.connector.Handshake.Launcher
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {fastcity.connector.Handshake.Launcher} Launcher
                 */
                Launcher.fromObject = function fromObject(object) {
                    if (object instanceof $root.fastcity.connector.Handshake.Launcher)
                        return object;
                    var message = new $root.fastcity.connector.Handshake.Launcher();
                    if (object.launcherVersion != null) {
                        if (typeof object.launcherVersion !== "object")
                            throw TypeError(".fastcity.connector.Handshake.Launcher.launcherVersion: object expected");
                        message.launcherVersion = $root.fastcity.common.Version.fromObject(object.launcherVersion);
                    }
                    if (object.connectorVersion != null) {
                        if (typeof object.connectorVersion !== "object")
                            throw TypeError(".fastcity.connector.Handshake.Launcher.connectorVersion: object expected");
                        message.connectorVersion = $root.fastcity.common.Version.fromObject(object.connectorVersion);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Launcher message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof fastcity.connector.Handshake.Launcher
                 * @static
                 * @param {fastcity.connector.Handshake.Launcher} message Launcher
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Launcher.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.launcherVersion = null;
                        object.connectorVersion = null;
                    }
                    if (message.launcherVersion != null && message.hasOwnProperty("launcherVersion"))
                        object.launcherVersion = $root.fastcity.common.Version.toObject(message.launcherVersion, options);
                    if (message.connectorVersion != null && message.hasOwnProperty("connectorVersion"))
                        object.connectorVersion = $root.fastcity.common.Version.toObject(message.connectorVersion, options);
                    return object;
                };

                /**
                 * Converts this Launcher to JSON.
                 * @function toJSON
                 * @memberof fastcity.connector.Handshake.Launcher
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Launcher.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return Launcher;
            })();

            Handshake.Connector = (function() {

                /**
                 * Properties of a Connector.
                 * @memberof fastcity.connector.Handshake
                 * @interface IConnector
                 * @property {fastcity.common.IVersion|null} [version] Connector version
                 */

                /**
                 * Constructs a new Connector.
                 * @memberof fastcity.connector.Handshake
                 * @classdesc Represents a Connector.
                 * @implements IConnector
                 * @constructor
                 * @param {fastcity.connector.Handshake.IConnector=} [properties] Properties to set
                 */
                function Connector(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Connector version.
                 * @member {fastcity.common.IVersion|null|undefined} version
                 * @memberof fastcity.connector.Handshake.Connector
                 * @instance
                 */
                Connector.prototype.version = null;

                /**
                 * Creates a new Connector instance using the specified properties.
                 * @function create
                 * @memberof fastcity.connector.Handshake.Connector
                 * @static
                 * @param {fastcity.connector.Handshake.IConnector=} [properties] Properties to set
                 * @returns {fastcity.connector.Handshake.Connector} Connector instance
                 */
                Connector.create = function create(properties) {
                    return new Connector(properties);
                };

                /**
                 * Encodes the specified Connector message. Does not implicitly {@link fastcity.connector.Handshake.Connector.verify|verify} messages.
                 * @function encode
                 * @memberof fastcity.connector.Handshake.Connector
                 * @static
                 * @param {fastcity.connector.Handshake.IConnector} message Connector message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Connector.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.version != null && Object.hasOwnProperty.call(message, "version"))
                        $root.fastcity.common.Version.encode(message.version, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Decodes a Connector message from the specified reader or buffer.
                 * @function decode
                 * @memberof fastcity.connector.Handshake.Connector
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {fastcity.connector.Handshake.Connector} Connector
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Connector.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.connector.Handshake.Connector();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.version = $root.fastcity.common.Version.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Verifies a Connector message.
                 * @function verify
                 * @memberof fastcity.connector.Handshake.Connector
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Connector.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.version != null && message.hasOwnProperty("version")) {
                        var error = $root.fastcity.common.Version.verify(message.version);
                        if (error)
                            return "version." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Connector message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof fastcity.connector.Handshake.Connector
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {fastcity.connector.Handshake.Connector} Connector
                 */
                Connector.fromObject = function fromObject(object) {
                    if (object instanceof $root.fastcity.connector.Handshake.Connector)
                        return object;
                    var message = new $root.fastcity.connector.Handshake.Connector();
                    if (object.version != null) {
                        if (typeof object.version !== "object")
                            throw TypeError(".fastcity.connector.Handshake.Connector.version: object expected");
                        message.version = $root.fastcity.common.Version.fromObject(object.version);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Connector message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof fastcity.connector.Handshake.Connector
                 * @static
                 * @param {fastcity.connector.Handshake.Connector} message Connector
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Connector.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.version = null;
                    if (message.version != null && message.hasOwnProperty("version"))
                        object.version = $root.fastcity.common.Version.toObject(message.version, options);
                    return object;
                };

                /**
                 * Converts this Connector to JSON.
                 * @function toJSON
                 * @memberof fastcity.connector.Handshake.Connector
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Connector.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return Connector;
            })();

            return Handshake;
        })();

        connector.launcher = (function() {

            /**
             * Namespace launcher.
             * @memberof fastcity.connector
             * @namespace
             */
            var launcher = {};

            launcher.Update = (function() {

                /**
                 * Properties of an Update.
                 * @memberof fastcity.connector.launcher
                 * @interface IUpdate
                 * @property {Uint8Array|null} [data] Update data
                 * @property {Uint8Array|null} [sign] Update sign
                 */

                /**
                 * Constructs a new Update.
                 * @memberof fastcity.connector.launcher
                 * @classdesc Represents an Update.
                 * @implements IUpdate
                 * @constructor
                 * @param {fastcity.connector.launcher.IUpdate=} [properties] Properties to set
                 */
                function Update(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Update data.
                 * @member {Uint8Array} data
                 * @memberof fastcity.connector.launcher.Update
                 * @instance
                 */
                Update.prototype.data = $util.newBuffer([]);

                /**
                 * Update sign.
                 * @member {Uint8Array} sign
                 * @memberof fastcity.connector.launcher.Update
                 * @instance
                 */
                Update.prototype.sign = $util.newBuffer([]);

                /**
                 * Creates a new Update instance using the specified properties.
                 * @function create
                 * @memberof fastcity.connector.launcher.Update
                 * @static
                 * @param {fastcity.connector.launcher.IUpdate=} [properties] Properties to set
                 * @returns {fastcity.connector.launcher.Update} Update instance
                 */
                Update.create = function create(properties) {
                    return new Update(properties);
                };

                /**
                 * Encodes the specified Update message. Does not implicitly {@link fastcity.connector.launcher.Update.verify|verify} messages.
                 * @function encode
                 * @memberof fastcity.connector.launcher.Update
                 * @static
                 * @param {fastcity.connector.launcher.IUpdate} message Update message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Update.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.data != null && Object.hasOwnProperty.call(message, "data"))
                        writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.data);
                    if (message.sign != null && Object.hasOwnProperty.call(message, "sign"))
                        writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.sign);
                    return writer;
                };

                /**
                 * Decodes an Update message from the specified reader or buffer.
                 * @function decode
                 * @memberof fastcity.connector.launcher.Update
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {fastcity.connector.launcher.Update} Update
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Update.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.connector.launcher.Update();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.data = reader.bytes();
                            break;
                        case 2:
                            message.sign = reader.bytes();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Verifies an Update message.
                 * @function verify
                 * @memberof fastcity.connector.launcher.Update
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Update.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.data != null && message.hasOwnProperty("data"))
                        if (!(message.data && typeof message.data.length === "number" || $util.isString(message.data)))
                            return "data: buffer expected";
                    if (message.sign != null && message.hasOwnProperty("sign"))
                        if (!(message.sign && typeof message.sign.length === "number" || $util.isString(message.sign)))
                            return "sign: buffer expected";
                    return null;
                };

                /**
                 * Creates an Update message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof fastcity.connector.launcher.Update
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {fastcity.connector.launcher.Update} Update
                 */
                Update.fromObject = function fromObject(object) {
                    if (object instanceof $root.fastcity.connector.launcher.Update)
                        return object;
                    var message = new $root.fastcity.connector.launcher.Update();
                    if (object.data != null)
                        if (typeof object.data === "string")
                            $util.base64.decode(object.data, message.data = $util.newBuffer($util.base64.length(object.data)), 0);
                        else if (object.data.length)
                            message.data = object.data;
                    if (object.sign != null)
                        if (typeof object.sign === "string")
                            $util.base64.decode(object.sign, message.sign = $util.newBuffer($util.base64.length(object.sign)), 0);
                        else if (object.sign.length)
                            message.sign = object.sign;
                    return message;
                };

                /**
                 * Creates a plain object from an Update message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof fastcity.connector.launcher.Update
                 * @static
                 * @param {fastcity.connector.launcher.Update} message Update
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Update.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        if (options.bytes === String)
                            object.data = "";
                        else {
                            object.data = [];
                            if (options.bytes !== Array)
                                object.data = $util.newBuffer(object.data);
                        }
                        if (options.bytes === String)
                            object.sign = "";
                        else {
                            object.sign = [];
                            if (options.bytes !== Array)
                                object.sign = $util.newBuffer(object.sign);
                        }
                    }
                    if (message.data != null && message.hasOwnProperty("data"))
                        object.data = options.bytes === String ? $util.base64.encode(message.data, 0, message.data.length) : options.bytes === Array ? Array.prototype.slice.call(message.data) : message.data;
                    if (message.sign != null && message.hasOwnProperty("sign"))
                        object.sign = options.bytes === String ? $util.base64.encode(message.sign, 0, message.sign.length) : options.bytes === Array ? Array.prototype.slice.call(message.sign) : message.sign;
                    return object;
                };

                /**
                 * Converts this Update to JSON.
                 * @function toJSON
                 * @memberof fastcity.connector.launcher.Update
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Update.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return Update;
            })();

            launcher.UpdateResult = (function() {

                /**
                 * Properties of an UpdateResult.
                 * @memberof fastcity.connector.launcher
                 * @interface IUpdateResult
                 * @property {fastcity.connector.launcher.UpdateResult.Code|null} [code] UpdateResult code
                 */

                /**
                 * Constructs a new UpdateResult.
                 * @memberof fastcity.connector.launcher
                 * @classdesc Represents an UpdateResult.
                 * @implements IUpdateResult
                 * @constructor
                 * @param {fastcity.connector.launcher.IUpdateResult=} [properties] Properties to set
                 */
                function UpdateResult(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UpdateResult code.
                 * @member {fastcity.connector.launcher.UpdateResult.Code} code
                 * @memberof fastcity.connector.launcher.UpdateResult
                 * @instance
                 */
                UpdateResult.prototype.code = 0;

                /**
                 * Creates a new UpdateResult instance using the specified properties.
                 * @function create
                 * @memberof fastcity.connector.launcher.UpdateResult
                 * @static
                 * @param {fastcity.connector.launcher.IUpdateResult=} [properties] Properties to set
                 * @returns {fastcity.connector.launcher.UpdateResult} UpdateResult instance
                 */
                UpdateResult.create = function create(properties) {
                    return new UpdateResult(properties);
                };

                /**
                 * Encodes the specified UpdateResult message. Does not implicitly {@link fastcity.connector.launcher.UpdateResult.verify|verify} messages.
                 * @function encode
                 * @memberof fastcity.connector.launcher.UpdateResult
                 * @static
                 * @param {fastcity.connector.launcher.IUpdateResult} message UpdateResult message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateResult.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.code != null && Object.hasOwnProperty.call(message, "code"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.code);
                    return writer;
                };

                /**
                 * Decodes an UpdateResult message from the specified reader or buffer.
                 * @function decode
                 * @memberof fastcity.connector.launcher.UpdateResult
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {fastcity.connector.launcher.UpdateResult} UpdateResult
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateResult.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.connector.launcher.UpdateResult();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.code = reader.int32();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Verifies an UpdateResult message.
                 * @function verify
                 * @memberof fastcity.connector.launcher.UpdateResult
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateResult.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.code != null && message.hasOwnProperty("code"))
                        switch (message.code) {
                        default:
                            return "code: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                            break;
                        }
                    return null;
                };

                /**
                 * Creates an UpdateResult message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof fastcity.connector.launcher.UpdateResult
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {fastcity.connector.launcher.UpdateResult} UpdateResult
                 */
                UpdateResult.fromObject = function fromObject(object) {
                    if (object instanceof $root.fastcity.connector.launcher.UpdateResult)
                        return object;
                    var message = new $root.fastcity.connector.launcher.UpdateResult();
                    switch (object.code) {
                    case "SUCCESS":
                    case 0:
                        message.code = 0;
                        break;
                    case "UNKNOWN_ERROR":
                    case 1:
                        message.code = 1;
                        break;
                    case "INCORRECT_SIGN":
                    case 2:
                        message.code = 2;
                        break;
                    case "CANNOT_DECOMPRESS":
                    case 3:
                        message.code = 3;
                        break;
                    case "CANNOT_REPLACE":
                    case 4:
                        message.code = 4;
                        break;
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an UpdateResult message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof fastcity.connector.launcher.UpdateResult
                 * @static
                 * @param {fastcity.connector.launcher.UpdateResult} message UpdateResult
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateResult.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.code = options.enums === String ? "SUCCESS" : 0;
                    if (message.code != null && message.hasOwnProperty("code"))
                        object.code = options.enums === String ? $root.fastcity.connector.launcher.UpdateResult.Code[message.code] : message.code;
                    return object;
                };

                /**
                 * Converts this UpdateResult to JSON.
                 * @function toJSON
                 * @memberof fastcity.connector.launcher.UpdateResult
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateResult.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Code enum.
                 * @name fastcity.connector.launcher.UpdateResult.Code
                 * @enum {number}
                 * @property {number} SUCCESS=0 SUCCESS value
                 * @property {number} UNKNOWN_ERROR=1 UNKNOWN_ERROR value
                 * @property {number} INCORRECT_SIGN=2 INCORRECT_SIGN value
                 * @property {number} CANNOT_DECOMPRESS=3 CANNOT_DECOMPRESS value
                 * @property {number} CANNOT_REPLACE=4 CANNOT_REPLACE value
                 */
                UpdateResult.Code = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "SUCCESS"] = 0;
                    values[valuesById[1] = "UNKNOWN_ERROR"] = 1;
                    values[valuesById[2] = "INCORRECT_SIGN"] = 2;
                    values[valuesById[3] = "CANNOT_DECOMPRESS"] = 3;
                    values[valuesById[4] = "CANNOT_REPLACE"] = 4;
                    return values;
                })();

                return UpdateResult;
            })();

            launcher.LogList = (function() {

                /**
                 * Properties of a LogList.
                 * @memberof fastcity.connector.launcher
                 * @interface ILogList
                 * @property {Array.<fastcity.connector.launcher.LogList.ILog>|null} [log] LogList log
                 */

                /**
                 * Constructs a new LogList.
                 * @memberof fastcity.connector.launcher
                 * @classdesc Represents a LogList.
                 * @implements ILogList
                 * @constructor
                 * @param {fastcity.connector.launcher.ILogList=} [properties] Properties to set
                 */
                function LogList(properties) {
                    this.log = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * LogList log.
                 * @member {Array.<fastcity.connector.launcher.LogList.ILog>} log
                 * @memberof fastcity.connector.launcher.LogList
                 * @instance
                 */
                LogList.prototype.log = $util.emptyArray;

                /**
                 * Creates a new LogList instance using the specified properties.
                 * @function create
                 * @memberof fastcity.connector.launcher.LogList
                 * @static
                 * @param {fastcity.connector.launcher.ILogList=} [properties] Properties to set
                 * @returns {fastcity.connector.launcher.LogList} LogList instance
                 */
                LogList.create = function create(properties) {
                    return new LogList(properties);
                };

                /**
                 * Encodes the specified LogList message. Does not implicitly {@link fastcity.connector.launcher.LogList.verify|verify} messages.
                 * @function encode
                 * @memberof fastcity.connector.launcher.LogList
                 * @static
                 * @param {fastcity.connector.launcher.ILogList} message LogList message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                LogList.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.log != null && message.log.length)
                        for (var i = 0; i < message.log.length; ++i)
                            $root.fastcity.connector.launcher.LogList.Log.encode(message.log[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Decodes a LogList message from the specified reader or buffer.
                 * @function decode
                 * @memberof fastcity.connector.launcher.LogList
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {fastcity.connector.launcher.LogList} LogList
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                LogList.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.connector.launcher.LogList();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            if (!(message.log && message.log.length))
                                message.log = [];
                            message.log.push($root.fastcity.connector.launcher.LogList.Log.decode(reader, reader.uint32()));
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Verifies a LogList message.
                 * @function verify
                 * @memberof fastcity.connector.launcher.LogList
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                LogList.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.log != null && message.hasOwnProperty("log")) {
                        if (!Array.isArray(message.log))
                            return "log: array expected";
                        for (var i = 0; i < message.log.length; ++i) {
                            var error = $root.fastcity.connector.launcher.LogList.Log.verify(message.log[i]);
                            if (error)
                                return "log." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a LogList message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof fastcity.connector.launcher.LogList
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {fastcity.connector.launcher.LogList} LogList
                 */
                LogList.fromObject = function fromObject(object) {
                    if (object instanceof $root.fastcity.connector.launcher.LogList)
                        return object;
                    var message = new $root.fastcity.connector.launcher.LogList();
                    if (object.log) {
                        if (!Array.isArray(object.log))
                            throw TypeError(".fastcity.connector.launcher.LogList.log: array expected");
                        message.log = [];
                        for (var i = 0; i < object.log.length; ++i) {
                            if (typeof object.log[i] !== "object")
                                throw TypeError(".fastcity.connector.launcher.LogList.log: object expected");
                            message.log[i] = $root.fastcity.connector.launcher.LogList.Log.fromObject(object.log[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a LogList message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof fastcity.connector.launcher.LogList
                 * @static
                 * @param {fastcity.connector.launcher.LogList} message LogList
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                LogList.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.log = [];
                    if (message.log && message.log.length) {
                        object.log = [];
                        for (var j = 0; j < message.log.length; ++j)
                            object.log[j] = $root.fastcity.connector.launcher.LogList.Log.toObject(message.log[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this LogList to JSON.
                 * @function toJSON
                 * @memberof fastcity.connector.launcher.LogList
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                LogList.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                LogList.Log = (function() {

                    /**
                     * Properties of a Log.
                     * @memberof fastcity.connector.launcher.LogList
                     * @interface ILog
                     * @property {string|null} [filename] Log filename
                     * @property {Uint8Array|null} [compressedLog] Log compressedLog
                     */

                    /**
                     * Constructs a new Log.
                     * @memberof fastcity.connector.launcher.LogList
                     * @classdesc Represents a Log.
                     * @implements ILog
                     * @constructor
                     * @param {fastcity.connector.launcher.LogList.ILog=} [properties] Properties to set
                     */
                    function Log(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Log filename.
                     * @member {string} filename
                     * @memberof fastcity.connector.launcher.LogList.Log
                     * @instance
                     */
                    Log.prototype.filename = "";

                    /**
                     * Log compressedLog.
                     * @member {Uint8Array} compressedLog
                     * @memberof fastcity.connector.launcher.LogList.Log
                     * @instance
                     */
                    Log.prototype.compressedLog = $util.newBuffer([]);

                    /**
                     * Creates a new Log instance using the specified properties.
                     * @function create
                     * @memberof fastcity.connector.launcher.LogList.Log
                     * @static
                     * @param {fastcity.connector.launcher.LogList.ILog=} [properties] Properties to set
                     * @returns {fastcity.connector.launcher.LogList.Log} Log instance
                     */
                    Log.create = function create(properties) {
                        return new Log(properties);
                    };

                    /**
                     * Encodes the specified Log message. Does not implicitly {@link fastcity.connector.launcher.LogList.Log.verify|verify} messages.
                     * @function encode
                     * @memberof fastcity.connector.launcher.LogList.Log
                     * @static
                     * @param {fastcity.connector.launcher.LogList.ILog} message Log message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Log.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.filename != null && Object.hasOwnProperty.call(message, "filename"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.filename);
                        if (message.compressedLog != null && Object.hasOwnProperty.call(message, "compressedLog"))
                            writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.compressedLog);
                        return writer;
                    };

                    /**
                     * Decodes a Log message from the specified reader or buffer.
                     * @function decode
                     * @memberof fastcity.connector.launcher.LogList.Log
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {fastcity.connector.launcher.LogList.Log} Log
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Log.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.connector.launcher.LogList.Log();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.filename = reader.string();
                                break;
                            case 2:
                                message.compressedLog = reader.bytes();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Verifies a Log message.
                     * @function verify
                     * @memberof fastcity.connector.launcher.LogList.Log
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Log.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.filename != null && message.hasOwnProperty("filename"))
                            if (!$util.isString(message.filename))
                                return "filename: string expected";
                        if (message.compressedLog != null && message.hasOwnProperty("compressedLog"))
                            if (!(message.compressedLog && typeof message.compressedLog.length === "number" || $util.isString(message.compressedLog)))
                                return "compressedLog: buffer expected";
                        return null;
                    };

                    /**
                     * Creates a Log message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof fastcity.connector.launcher.LogList.Log
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {fastcity.connector.launcher.LogList.Log} Log
                     */
                    Log.fromObject = function fromObject(object) {
                        if (object instanceof $root.fastcity.connector.launcher.LogList.Log)
                            return object;
                        var message = new $root.fastcity.connector.launcher.LogList.Log();
                        if (object.filename != null)
                            message.filename = String(object.filename);
                        if (object.compressedLog != null)
                            if (typeof object.compressedLog === "string")
                                $util.base64.decode(object.compressedLog, message.compressedLog = $util.newBuffer($util.base64.length(object.compressedLog)), 0);
                            else if (object.compressedLog.length)
                                message.compressedLog = object.compressedLog;
                        return message;
                    };

                    /**
                     * Creates a plain object from a Log message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof fastcity.connector.launcher.LogList.Log
                     * @static
                     * @param {fastcity.connector.launcher.LogList.Log} message Log
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Log.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.filename = "";
                            if (options.bytes === String)
                                object.compressedLog = "";
                            else {
                                object.compressedLog = [];
                                if (options.bytes !== Array)
                                    object.compressedLog = $util.newBuffer(object.compressedLog);
                            }
                        }
                        if (message.filename != null && message.hasOwnProperty("filename"))
                            object.filename = message.filename;
                        if (message.compressedLog != null && message.hasOwnProperty("compressedLog"))
                            object.compressedLog = options.bytes === String ? $util.base64.encode(message.compressedLog, 0, message.compressedLog.length) : options.bytes === Array ? Array.prototype.slice.call(message.compressedLog) : message.compressedLog;
                        return object;
                    };

                    /**
                     * Converts this Log to JSON.
                     * @function toJSON
                     * @memberof fastcity.connector.launcher.LogList.Log
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Log.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return Log;
                })();

                return LogList;
            })();

            launcher.LaunchError = (function() {

                /**
                 * Properties of a LaunchError.
                 * @memberof fastcity.connector.launcher
                 * @interface ILaunchError
                 * @property {fastcity.connector.launcher.LaunchError.Code|null} [code] LaunchError code
                 */

                /**
                 * Constructs a new LaunchError.
                 * @memberof fastcity.connector.launcher
                 * @classdesc Represents a LaunchError.
                 * @implements ILaunchError
                 * @constructor
                 * @param {fastcity.connector.launcher.ILaunchError=} [properties] Properties to set
                 */
                function LaunchError(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * LaunchError code.
                 * @member {fastcity.connector.launcher.LaunchError.Code} code
                 * @memberof fastcity.connector.launcher.LaunchError
                 * @instance
                 */
                LaunchError.prototype.code = 0;

                /**
                 * Creates a new LaunchError instance using the specified properties.
                 * @function create
                 * @memberof fastcity.connector.launcher.LaunchError
                 * @static
                 * @param {fastcity.connector.launcher.ILaunchError=} [properties] Properties to set
                 * @returns {fastcity.connector.launcher.LaunchError} LaunchError instance
                 */
                LaunchError.create = function create(properties) {
                    return new LaunchError(properties);
                };

                /**
                 * Encodes the specified LaunchError message. Does not implicitly {@link fastcity.connector.launcher.LaunchError.verify|verify} messages.
                 * @function encode
                 * @memberof fastcity.connector.launcher.LaunchError
                 * @static
                 * @param {fastcity.connector.launcher.ILaunchError} message LaunchError message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                LaunchError.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.code != null && Object.hasOwnProperty.call(message, "code"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.code);
                    return writer;
                };

                /**
                 * Decodes a LaunchError message from the specified reader or buffer.
                 * @function decode
                 * @memberof fastcity.connector.launcher.LaunchError
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {fastcity.connector.launcher.LaunchError} LaunchError
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                LaunchError.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.connector.launcher.LaunchError();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.code = reader.int32();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Verifies a LaunchError message.
                 * @function verify
                 * @memberof fastcity.connector.launcher.LaunchError
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                LaunchError.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.code != null && message.hasOwnProperty("code"))
                        switch (message.code) {
                        default:
                            return "code: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                            break;
                        }
                    return null;
                };

                /**
                 * Creates a LaunchError message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof fastcity.connector.launcher.LaunchError
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {fastcity.connector.launcher.LaunchError} LaunchError
                 */
                LaunchError.fromObject = function fromObject(object) {
                    if (object instanceof $root.fastcity.connector.launcher.LaunchError)
                        return object;
                    var message = new $root.fastcity.connector.launcher.LaunchError();
                    switch (object.code) {
                    case "UNKNOWN":
                    case 0:
                        message.code = 0;
                        break;
                    case "STOPPED_BY_ITSELF":
                    case 1:
                        message.code = 1;
                        break;
                    case "HEARTBEAT_TIMEOUT":
                    case 2:
                        message.code = 2;
                        break;
                    case "WAIT_HELLO_MESSAGE_TIMEOUT":
                    case 3:
                        message.code = 3;
                        break;
                    case "RUN_TIMEOUT":
                    case 4:
                        message.code = 4;
                        break;
                    case "QUIT_TIMEOUT":
                    case 5:
                        message.code = 5;
                        break;
                    case "CANNOT_QUIT":
                    case 6:
                        message.code = 6;
                        break;
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a LaunchError message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof fastcity.connector.launcher.LaunchError
                 * @static
                 * @param {fastcity.connector.launcher.LaunchError} message LaunchError
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                LaunchError.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.code = options.enums === String ? "UNKNOWN" : 0;
                    if (message.code != null && message.hasOwnProperty("code"))
                        object.code = options.enums === String ? $root.fastcity.connector.launcher.LaunchError.Code[message.code] : message.code;
                    return object;
                };

                /**
                 * Converts this LaunchError to JSON.
                 * @function toJSON
                 * @memberof fastcity.connector.launcher.LaunchError
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                LaunchError.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Code enum.
                 * @name fastcity.connector.launcher.LaunchError.Code
                 * @enum {number}
                 * @property {number} UNKNOWN=0 UNKNOWN value
                 * @property {number} STOPPED_BY_ITSELF=1 STOPPED_BY_ITSELF value
                 * @property {number} HEARTBEAT_TIMEOUT=2 HEARTBEAT_TIMEOUT value
                 * @property {number} WAIT_HELLO_MESSAGE_TIMEOUT=3 WAIT_HELLO_MESSAGE_TIMEOUT value
                 * @property {number} RUN_TIMEOUT=4 RUN_TIMEOUT value
                 * @property {number} QUIT_TIMEOUT=5 QUIT_TIMEOUT value
                 * @property {number} CANNOT_QUIT=6 CANNOT_QUIT value
                 */
                LaunchError.Code = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "UNKNOWN"] = 0;
                    values[valuesById[1] = "STOPPED_BY_ITSELF"] = 1;
                    values[valuesById[2] = "HEARTBEAT_TIMEOUT"] = 2;
                    values[valuesById[3] = "WAIT_HELLO_MESSAGE_TIMEOUT"] = 3;
                    values[valuesById[4] = "RUN_TIMEOUT"] = 4;
                    values[valuesById[5] = "QUIT_TIMEOUT"] = 5;
                    values[valuesById[6] = "CANNOT_QUIT"] = 6;
                    return values;
                })();

                return LaunchError;
            })();

            launcher.GetLogList = (function() {

                /**
                 * Properties of a GetLogList.
                 * @memberof fastcity.connector.launcher
                 * @interface IGetLogList
                 * @property {fastcity.connector.launcher.GetLogList.IByDay|null} [byDay] GetLogList byDay
                 */

                /**
                 * Constructs a new GetLogList.
                 * @memberof fastcity.connector.launcher
                 * @classdesc Represents a GetLogList.
                 * @implements IGetLogList
                 * @constructor
                 * @param {fastcity.connector.launcher.IGetLogList=} [properties] Properties to set
                 */
                function GetLogList(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetLogList byDay.
                 * @member {fastcity.connector.launcher.GetLogList.IByDay|null|undefined} byDay
                 * @memberof fastcity.connector.launcher.GetLogList
                 * @instance
                 */
                GetLogList.prototype.byDay = null;

                // OneOf field names bound to virtual getters and setters
                var $oneOfFields;

                /**
                 * GetLogList type.
                 * @member {"byDay"|undefined} type
                 * @memberof fastcity.connector.launcher.GetLogList
                 * @instance
                 */
                Object.defineProperty(GetLogList.prototype, "type", {
                    get: $util.oneOfGetter($oneOfFields = ["byDay"]),
                    set: $util.oneOfSetter($oneOfFields)
                });

                /**
                 * Creates a new GetLogList instance using the specified properties.
                 * @function create
                 * @memberof fastcity.connector.launcher.GetLogList
                 * @static
                 * @param {fastcity.connector.launcher.IGetLogList=} [properties] Properties to set
                 * @returns {fastcity.connector.launcher.GetLogList} GetLogList instance
                 */
                GetLogList.create = function create(properties) {
                    return new GetLogList(properties);
                };

                /**
                 * Encodes the specified GetLogList message. Does not implicitly {@link fastcity.connector.launcher.GetLogList.verify|verify} messages.
                 * @function encode
                 * @memberof fastcity.connector.launcher.GetLogList
                 * @static
                 * @param {fastcity.connector.launcher.IGetLogList} message GetLogList message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetLogList.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.byDay != null && Object.hasOwnProperty.call(message, "byDay"))
                        $root.fastcity.connector.launcher.GetLogList.ByDay.encode(message.byDay, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Decodes a GetLogList message from the specified reader or buffer.
                 * @function decode
                 * @memberof fastcity.connector.launcher.GetLogList
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {fastcity.connector.launcher.GetLogList} GetLogList
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetLogList.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.connector.launcher.GetLogList();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.byDay = $root.fastcity.connector.launcher.GetLogList.ByDay.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Verifies a GetLogList message.
                 * @function verify
                 * @memberof fastcity.connector.launcher.GetLogList
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetLogList.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    var properties = {};
                    if (message.byDay != null && message.hasOwnProperty("byDay")) {
                        properties.type = 1;
                        {
                            var error = $root.fastcity.connector.launcher.GetLogList.ByDay.verify(message.byDay);
                            if (error)
                                return "byDay." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a GetLogList message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof fastcity.connector.launcher.GetLogList
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {fastcity.connector.launcher.GetLogList} GetLogList
                 */
                GetLogList.fromObject = function fromObject(object) {
                    if (object instanceof $root.fastcity.connector.launcher.GetLogList)
                        return object;
                    var message = new $root.fastcity.connector.launcher.GetLogList();
                    if (object.byDay != null) {
                        if (typeof object.byDay !== "object")
                            throw TypeError(".fastcity.connector.launcher.GetLogList.byDay: object expected");
                        message.byDay = $root.fastcity.connector.launcher.GetLogList.ByDay.fromObject(object.byDay);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a GetLogList message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof fastcity.connector.launcher.GetLogList
                 * @static
                 * @param {fastcity.connector.launcher.GetLogList} message GetLogList
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetLogList.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (message.byDay != null && message.hasOwnProperty("byDay")) {
                        object.byDay = $root.fastcity.connector.launcher.GetLogList.ByDay.toObject(message.byDay, options);
                        if (options.oneofs)
                            object.type = "byDay";
                    }
                    return object;
                };

                /**
                 * Converts this GetLogList to JSON.
                 * @function toJSON
                 * @memberof fastcity.connector.launcher.GetLogList
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetLogList.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                GetLogList.ByDay = (function() {

                    /**
                     * Properties of a ByDay.
                     * @memberof fastcity.connector.launcher.GetLogList
                     * @interface IByDay
                     * @property {number|Long|null} [time] ByDay time
                     */

                    /**
                     * Constructs a new ByDay.
                     * @memberof fastcity.connector.launcher.GetLogList
                     * @classdesc Represents a ByDay.
                     * @implements IByDay
                     * @constructor
                     * @param {fastcity.connector.launcher.GetLogList.IByDay=} [properties] Properties to set
                     */
                    function ByDay(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * ByDay time.
                     * @member {number|Long} time
                     * @memberof fastcity.connector.launcher.GetLogList.ByDay
                     * @instance
                     */
                    ByDay.prototype.time = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * Creates a new ByDay instance using the specified properties.
                     * @function create
                     * @memberof fastcity.connector.launcher.GetLogList.ByDay
                     * @static
                     * @param {fastcity.connector.launcher.GetLogList.IByDay=} [properties] Properties to set
                     * @returns {fastcity.connector.launcher.GetLogList.ByDay} ByDay instance
                     */
                    ByDay.create = function create(properties) {
                        return new ByDay(properties);
                    };

                    /**
                     * Encodes the specified ByDay message. Does not implicitly {@link fastcity.connector.launcher.GetLogList.ByDay.verify|verify} messages.
                     * @function encode
                     * @memberof fastcity.connector.launcher.GetLogList.ByDay
                     * @static
                     * @param {fastcity.connector.launcher.GetLogList.IByDay} message ByDay message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ByDay.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.time != null && Object.hasOwnProperty.call(message, "time"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.time);
                        return writer;
                    };

                    /**
                     * Decodes a ByDay message from the specified reader or buffer.
                     * @function decode
                     * @memberof fastcity.connector.launcher.GetLogList.ByDay
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {fastcity.connector.launcher.GetLogList.ByDay} ByDay
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ByDay.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.connector.launcher.GetLogList.ByDay();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.time = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Verifies a ByDay message.
                     * @function verify
                     * @memberof fastcity.connector.launcher.GetLogList.ByDay
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ByDay.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.time != null && message.hasOwnProperty("time"))
                            if (!$util.isInteger(message.time) && !(message.time && $util.isInteger(message.time.low) && $util.isInteger(message.time.high)))
                                return "time: integer|Long expected";
                        return null;
                    };

                    /**
                     * Creates a ByDay message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof fastcity.connector.launcher.GetLogList.ByDay
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {fastcity.connector.launcher.GetLogList.ByDay} ByDay
                     */
                    ByDay.fromObject = function fromObject(object) {
                        if (object instanceof $root.fastcity.connector.launcher.GetLogList.ByDay)
                            return object;
                        var message = new $root.fastcity.connector.launcher.GetLogList.ByDay();
                        if (object.time != null)
                            if ($util.Long)
                                (message.time = $util.Long.fromValue(object.time)).unsigned = false;
                            else if (typeof object.time === "string")
                                message.time = parseInt(object.time, 10);
                            else if (typeof object.time === "number")
                                message.time = object.time;
                            else if (typeof object.time === "object")
                                message.time = new $util.LongBits(object.time.low >>> 0, object.time.high >>> 0).toNumber();
                        return message;
                    };

                    /**
                     * Creates a plain object from a ByDay message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof fastcity.connector.launcher.GetLogList.ByDay
                     * @static
                     * @param {fastcity.connector.launcher.GetLogList.ByDay} message ByDay
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ByDay.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.time = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.time = options.longs === String ? "0" : 0;
                        if (message.time != null && message.hasOwnProperty("time"))
                            if (typeof message.time === "number")
                                object.time = options.longs === String ? String(message.time) : message.time;
                            else
                                object.time = options.longs === String ? $util.Long.prototype.toString.call(message.time) : options.longs === Number ? new $util.LongBits(message.time.low >>> 0, message.time.high >>> 0).toNumber() : message.time;
                        return object;
                    };

                    /**
                     * Converts this ByDay to JSON.
                     * @function toJSON
                     * @memberof fastcity.connector.launcher.GetLogList.ByDay
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ByDay.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return ByDay;
                })();

                return GetLogList;
            })();

            launcher.ServerMessage = (function() {

                /**
                 * Properties of a ServerMessage.
                 * @memberof fastcity.connector.launcher
                 * @interface IServerMessage
                 * @property {number|Long|null} [requestId] ServerMessage requestId
                 * @property {fastcity.connector.launcher.IUpdate|null} [update] ServerMessage update
                 * @property {fastcity.connector.launcher.IGetLogList|null} [getLogList] ServerMessage getLogList
                 * @property {fastcity.connector.IQuit|null} [quit] ServerMessage quit
                 */

                /**
                 * Constructs a new ServerMessage.
                 * @memberof fastcity.connector.launcher
                 * @classdesc Represents a ServerMessage.
                 * @implements IServerMessage
                 * @constructor
                 * @param {fastcity.connector.launcher.IServerMessage=} [properties] Properties to set
                 */
                function ServerMessage(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ServerMessage requestId.
                 * @member {number|Long} requestId
                 * @memberof fastcity.connector.launcher.ServerMessage
                 * @instance
                 */
                ServerMessage.prototype.requestId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

                /**
                 * ServerMessage update.
                 * @member {fastcity.connector.launcher.IUpdate|null|undefined} update
                 * @memberof fastcity.connector.launcher.ServerMessage
                 * @instance
                 */
                ServerMessage.prototype.update = null;

                /**
                 * ServerMessage getLogList.
                 * @member {fastcity.connector.launcher.IGetLogList|null|undefined} getLogList
                 * @memberof fastcity.connector.launcher.ServerMessage
                 * @instance
                 */
                ServerMessage.prototype.getLogList = null;

                /**
                 * ServerMessage quit.
                 * @member {fastcity.connector.IQuit|null|undefined} quit
                 * @memberof fastcity.connector.launcher.ServerMessage
                 * @instance
                 */
                ServerMessage.prototype.quit = null;

                // OneOf field names bound to virtual getters and setters
                var $oneOfFields;

                /**
                 * ServerMessage body.
                 * @member {"update"|"getLogList"|"quit"|undefined} body
                 * @memberof fastcity.connector.launcher.ServerMessage
                 * @instance
                 */
                Object.defineProperty(ServerMessage.prototype, "body", {
                    get: $util.oneOfGetter($oneOfFields = ["update", "getLogList", "quit"]),
                    set: $util.oneOfSetter($oneOfFields)
                });

                /**
                 * Creates a new ServerMessage instance using the specified properties.
                 * @function create
                 * @memberof fastcity.connector.launcher.ServerMessage
                 * @static
                 * @param {fastcity.connector.launcher.IServerMessage=} [properties] Properties to set
                 * @returns {fastcity.connector.launcher.ServerMessage} ServerMessage instance
                 */
                ServerMessage.create = function create(properties) {
                    return new ServerMessage(properties);
                };

                /**
                 * Encodes the specified ServerMessage message. Does not implicitly {@link fastcity.connector.launcher.ServerMessage.verify|verify} messages.
                 * @function encode
                 * @memberof fastcity.connector.launcher.ServerMessage
                 * @static
                 * @param {fastcity.connector.launcher.IServerMessage} message ServerMessage message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ServerMessage.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.requestId != null && Object.hasOwnProperty.call(message, "requestId"))
                        writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.requestId);
                    if (message.update != null && Object.hasOwnProperty.call(message, "update"))
                        $root.fastcity.connector.launcher.Update.encode(message.update, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.getLogList != null && Object.hasOwnProperty.call(message, "getLogList"))
                        $root.fastcity.connector.launcher.GetLogList.encode(message.getLogList, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.quit != null && Object.hasOwnProperty.call(message, "quit"))
                        $root.fastcity.connector.Quit.encode(message.quit, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                    return writer;
                };

                /**
                 * Decodes a ServerMessage message from the specified reader or buffer.
                 * @function decode
                 * @memberof fastcity.connector.launcher.ServerMessage
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {fastcity.connector.launcher.ServerMessage} ServerMessage
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ServerMessage.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.connector.launcher.ServerMessage();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.requestId = reader.uint64();
                            break;
                        case 2:
                            message.update = $root.fastcity.connector.launcher.Update.decode(reader, reader.uint32());
                            break;
                        case 3:
                            message.getLogList = $root.fastcity.connector.launcher.GetLogList.decode(reader, reader.uint32());
                            break;
                        case 7:
                            message.quit = $root.fastcity.connector.Quit.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Verifies a ServerMessage message.
                 * @function verify
                 * @memberof fastcity.connector.launcher.ServerMessage
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ServerMessage.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    var properties = {};
                    if (message.requestId != null && message.hasOwnProperty("requestId"))
                        if (!$util.isInteger(message.requestId) && !(message.requestId && $util.isInteger(message.requestId.low) && $util.isInteger(message.requestId.high)))
                            return "requestId: integer|Long expected";
                    if (message.update != null && message.hasOwnProperty("update")) {
                        properties.body = 1;
                        {
                            var error = $root.fastcity.connector.launcher.Update.verify(message.update);
                            if (error)
                                return "update." + error;
                        }
                    }
                    if (message.getLogList != null && message.hasOwnProperty("getLogList")) {
                        if (properties.body === 1)
                            return "body: multiple values";
                        properties.body = 1;
                        {
                            var error = $root.fastcity.connector.launcher.GetLogList.verify(message.getLogList);
                            if (error)
                                return "getLogList." + error;
                        }
                    }
                    if (message.quit != null && message.hasOwnProperty("quit")) {
                        if (properties.body === 1)
                            return "body: multiple values";
                        properties.body = 1;
                        {
                            var error = $root.fastcity.connector.Quit.verify(message.quit);
                            if (error)
                                return "quit." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a ServerMessage message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof fastcity.connector.launcher.ServerMessage
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {fastcity.connector.launcher.ServerMessage} ServerMessage
                 */
                ServerMessage.fromObject = function fromObject(object) {
                    if (object instanceof $root.fastcity.connector.launcher.ServerMessage)
                        return object;
                    var message = new $root.fastcity.connector.launcher.ServerMessage();
                    if (object.requestId != null)
                        if ($util.Long)
                            (message.requestId = $util.Long.fromValue(object.requestId)).unsigned = true;
                        else if (typeof object.requestId === "string")
                            message.requestId = parseInt(object.requestId, 10);
                        else if (typeof object.requestId === "number")
                            message.requestId = object.requestId;
                        else if (typeof object.requestId === "object")
                            message.requestId = new $util.LongBits(object.requestId.low >>> 0, object.requestId.high >>> 0).toNumber(true);
                    if (object.update != null) {
                        if (typeof object.update !== "object")
                            throw TypeError(".fastcity.connector.launcher.ServerMessage.update: object expected");
                        message.update = $root.fastcity.connector.launcher.Update.fromObject(object.update);
                    }
                    if (object.getLogList != null) {
                        if (typeof object.getLogList !== "object")
                            throw TypeError(".fastcity.connector.launcher.ServerMessage.getLogList: object expected");
                        message.getLogList = $root.fastcity.connector.launcher.GetLogList.fromObject(object.getLogList);
                    }
                    if (object.quit != null) {
                        if (typeof object.quit !== "object")
                            throw TypeError(".fastcity.connector.launcher.ServerMessage.quit: object expected");
                        message.quit = $root.fastcity.connector.Quit.fromObject(object.quit);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ServerMessage message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof fastcity.connector.launcher.ServerMessage
                 * @static
                 * @param {fastcity.connector.launcher.ServerMessage} message ServerMessage
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ServerMessage.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, true);
                            object.requestId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.requestId = options.longs === String ? "0" : 0;
                    if (message.requestId != null && message.hasOwnProperty("requestId"))
                        if (typeof message.requestId === "number")
                            object.requestId = options.longs === String ? String(message.requestId) : message.requestId;
                        else
                            object.requestId = options.longs === String ? $util.Long.prototype.toString.call(message.requestId) : options.longs === Number ? new $util.LongBits(message.requestId.low >>> 0, message.requestId.high >>> 0).toNumber(true) : message.requestId;
                    if (message.update != null && message.hasOwnProperty("update")) {
                        object.update = $root.fastcity.connector.launcher.Update.toObject(message.update, options);
                        if (options.oneofs)
                            object.body = "update";
                    }
                    if (message.getLogList != null && message.hasOwnProperty("getLogList")) {
                        object.getLogList = $root.fastcity.connector.launcher.GetLogList.toObject(message.getLogList, options);
                        if (options.oneofs)
                            object.body = "getLogList";
                    }
                    if (message.quit != null && message.hasOwnProperty("quit")) {
                        object.quit = $root.fastcity.connector.Quit.toObject(message.quit, options);
                        if (options.oneofs)
                            object.body = "quit";
                    }
                    return object;
                };

                /**
                 * Converts this ServerMessage to JSON.
                 * @function toJSON
                 * @memberof fastcity.connector.launcher.ServerMessage
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ServerMessage.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ServerMessage;
            })();

            launcher.LauncherMessage = (function() {

                /**
                 * Properties of a LauncherMessage.
                 * @memberof fastcity.connector.launcher
                 * @interface ILauncherMessage
                 * @property {number|Long|null} [requestId] LauncherMessage requestId
                 * @property {fastcity.connector.launcher.IUpdateResult|null} [updateResult] LauncherMessage updateResult
                 * @property {fastcity.connector.launcher.ILogList|null} [logList] LauncherMessage logList
                 * @property {fastcity.connector.launcher.ILaunchError|null} [launchError] LauncherMessage launchError
                 * @property {fastcity.connector.IResult|null} [result] LauncherMessage result
                 */

                /**
                 * Constructs a new LauncherMessage.
                 * @memberof fastcity.connector.launcher
                 * @classdesc Represents a LauncherMessage.
                 * @implements ILauncherMessage
                 * @constructor
                 * @param {fastcity.connector.launcher.ILauncherMessage=} [properties] Properties to set
                 */
                function LauncherMessage(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * LauncherMessage requestId.
                 * @member {number|Long} requestId
                 * @memberof fastcity.connector.launcher.LauncherMessage
                 * @instance
                 */
                LauncherMessage.prototype.requestId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

                /**
                 * LauncherMessage updateResult.
                 * @member {fastcity.connector.launcher.IUpdateResult|null|undefined} updateResult
                 * @memberof fastcity.connector.launcher.LauncherMessage
                 * @instance
                 */
                LauncherMessage.prototype.updateResult = null;

                /**
                 * LauncherMessage logList.
                 * @member {fastcity.connector.launcher.ILogList|null|undefined} logList
                 * @memberof fastcity.connector.launcher.LauncherMessage
                 * @instance
                 */
                LauncherMessage.prototype.logList = null;

                /**
                 * LauncherMessage launchError.
                 * @member {fastcity.connector.launcher.ILaunchError|null|undefined} launchError
                 * @memberof fastcity.connector.launcher.LauncherMessage
                 * @instance
                 */
                LauncherMessage.prototype.launchError = null;

                /**
                 * LauncherMessage result.
                 * @member {fastcity.connector.IResult|null|undefined} result
                 * @memberof fastcity.connector.launcher.LauncherMessage
                 * @instance
                 */
                LauncherMessage.prototype.result = null;

                // OneOf field names bound to virtual getters and setters
                var $oneOfFields;

                /**
                 * LauncherMessage body.
                 * @member {"updateResult"|"logList"|"launchError"|"result"|undefined} body
                 * @memberof fastcity.connector.launcher.LauncherMessage
                 * @instance
                 */
                Object.defineProperty(LauncherMessage.prototype, "body", {
                    get: $util.oneOfGetter($oneOfFields = ["updateResult", "logList", "launchError", "result"]),
                    set: $util.oneOfSetter($oneOfFields)
                });

                /**
                 * Creates a new LauncherMessage instance using the specified properties.
                 * @function create
                 * @memberof fastcity.connector.launcher.LauncherMessage
                 * @static
                 * @param {fastcity.connector.launcher.ILauncherMessage=} [properties] Properties to set
                 * @returns {fastcity.connector.launcher.LauncherMessage} LauncherMessage instance
                 */
                LauncherMessage.create = function create(properties) {
                    return new LauncherMessage(properties);
                };

                /**
                 * Encodes the specified LauncherMessage message. Does not implicitly {@link fastcity.connector.launcher.LauncherMessage.verify|verify} messages.
                 * @function encode
                 * @memberof fastcity.connector.launcher.LauncherMessage
                 * @static
                 * @param {fastcity.connector.launcher.ILauncherMessage} message LauncherMessage message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                LauncherMessage.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.requestId != null && Object.hasOwnProperty.call(message, "requestId"))
                        writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.requestId);
                    if (message.updateResult != null && Object.hasOwnProperty.call(message, "updateResult"))
                        $root.fastcity.connector.launcher.UpdateResult.encode(message.updateResult, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.logList != null && Object.hasOwnProperty.call(message, "logList"))
                        $root.fastcity.connector.launcher.LogList.encode(message.logList, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.launchError != null && Object.hasOwnProperty.call(message, "launchError"))
                        $root.fastcity.connector.launcher.LaunchError.encode(message.launchError, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    if (message.result != null && Object.hasOwnProperty.call(message, "result"))
                        $root.fastcity.connector.Result.encode(message.result, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                    return writer;
                };

                /**
                 * Decodes a LauncherMessage message from the specified reader or buffer.
                 * @function decode
                 * @memberof fastcity.connector.launcher.LauncherMessage
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {fastcity.connector.launcher.LauncherMessage} LauncherMessage
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                LauncherMessage.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.connector.launcher.LauncherMessage();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.requestId = reader.uint64();
                            break;
                        case 2:
                            message.updateResult = $root.fastcity.connector.launcher.UpdateResult.decode(reader, reader.uint32());
                            break;
                        case 3:
                            message.logList = $root.fastcity.connector.launcher.LogList.decode(reader, reader.uint32());
                            break;
                        case 4:
                            message.launchError = $root.fastcity.connector.launcher.LaunchError.decode(reader, reader.uint32());
                            break;
                        case 5:
                            message.result = $root.fastcity.connector.Result.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Verifies a LauncherMessage message.
                 * @function verify
                 * @memberof fastcity.connector.launcher.LauncherMessage
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                LauncherMessage.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    var properties = {};
                    if (message.requestId != null && message.hasOwnProperty("requestId"))
                        if (!$util.isInteger(message.requestId) && !(message.requestId && $util.isInteger(message.requestId.low) && $util.isInteger(message.requestId.high)))
                            return "requestId: integer|Long expected";
                    if (message.updateResult != null && message.hasOwnProperty("updateResult")) {
                        properties.body = 1;
                        {
                            var error = $root.fastcity.connector.launcher.UpdateResult.verify(message.updateResult);
                            if (error)
                                return "updateResult." + error;
                        }
                    }
                    if (message.logList != null && message.hasOwnProperty("logList")) {
                        if (properties.body === 1)
                            return "body: multiple values";
                        properties.body = 1;
                        {
                            var error = $root.fastcity.connector.launcher.LogList.verify(message.logList);
                            if (error)
                                return "logList." + error;
                        }
                    }
                    if (message.launchError != null && message.hasOwnProperty("launchError")) {
                        if (properties.body === 1)
                            return "body: multiple values";
                        properties.body = 1;
                        {
                            var error = $root.fastcity.connector.launcher.LaunchError.verify(message.launchError);
                            if (error)
                                return "launchError." + error;
                        }
                    }
                    if (message.result != null && message.hasOwnProperty("result")) {
                        if (properties.body === 1)
                            return "body: multiple values";
                        properties.body = 1;
                        {
                            var error = $root.fastcity.connector.Result.verify(message.result);
                            if (error)
                                return "result." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a LauncherMessage message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof fastcity.connector.launcher.LauncherMessage
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {fastcity.connector.launcher.LauncherMessage} LauncherMessage
                 */
                LauncherMessage.fromObject = function fromObject(object) {
                    if (object instanceof $root.fastcity.connector.launcher.LauncherMessage)
                        return object;
                    var message = new $root.fastcity.connector.launcher.LauncherMessage();
                    if (object.requestId != null)
                        if ($util.Long)
                            (message.requestId = $util.Long.fromValue(object.requestId)).unsigned = true;
                        else if (typeof object.requestId === "string")
                            message.requestId = parseInt(object.requestId, 10);
                        else if (typeof object.requestId === "number")
                            message.requestId = object.requestId;
                        else if (typeof object.requestId === "object")
                            message.requestId = new $util.LongBits(object.requestId.low >>> 0, object.requestId.high >>> 0).toNumber(true);
                    if (object.updateResult != null) {
                        if (typeof object.updateResult !== "object")
                            throw TypeError(".fastcity.connector.launcher.LauncherMessage.updateResult: object expected");
                        message.updateResult = $root.fastcity.connector.launcher.UpdateResult.fromObject(object.updateResult);
                    }
                    if (object.logList != null) {
                        if (typeof object.logList !== "object")
                            throw TypeError(".fastcity.connector.launcher.LauncherMessage.logList: object expected");
                        message.logList = $root.fastcity.connector.launcher.LogList.fromObject(object.logList);
                    }
                    if (object.launchError != null) {
                        if (typeof object.launchError !== "object")
                            throw TypeError(".fastcity.connector.launcher.LauncherMessage.launchError: object expected");
                        message.launchError = $root.fastcity.connector.launcher.LaunchError.fromObject(object.launchError);
                    }
                    if (object.result != null) {
                        if (typeof object.result !== "object")
                            throw TypeError(".fastcity.connector.launcher.LauncherMessage.result: object expected");
                        message.result = $root.fastcity.connector.Result.fromObject(object.result);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a LauncherMessage message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof fastcity.connector.launcher.LauncherMessage
                 * @static
                 * @param {fastcity.connector.launcher.LauncherMessage} message LauncherMessage
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                LauncherMessage.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, true);
                            object.requestId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.requestId = options.longs === String ? "0" : 0;
                    if (message.requestId != null && message.hasOwnProperty("requestId"))
                        if (typeof message.requestId === "number")
                            object.requestId = options.longs === String ? String(message.requestId) : message.requestId;
                        else
                            object.requestId = options.longs === String ? $util.Long.prototype.toString.call(message.requestId) : options.longs === Number ? new $util.LongBits(message.requestId.low >>> 0, message.requestId.high >>> 0).toNumber(true) : message.requestId;
                    if (message.updateResult != null && message.hasOwnProperty("updateResult")) {
                        object.updateResult = $root.fastcity.connector.launcher.UpdateResult.toObject(message.updateResult, options);
                        if (options.oneofs)
                            object.body = "updateResult";
                    }
                    if (message.logList != null && message.hasOwnProperty("logList")) {
                        object.logList = $root.fastcity.connector.launcher.LogList.toObject(message.logList, options);
                        if (options.oneofs)
                            object.body = "logList";
                    }
                    if (message.launchError != null && message.hasOwnProperty("launchError")) {
                        object.launchError = $root.fastcity.connector.launcher.LaunchError.toObject(message.launchError, options);
                        if (options.oneofs)
                            object.body = "launchError";
                    }
                    if (message.result != null && message.hasOwnProperty("result")) {
                        object.result = $root.fastcity.connector.Result.toObject(message.result, options);
                        if (options.oneofs)
                            object.body = "result";
                    }
                    return object;
                };

                /**
                 * Converts this LauncherMessage to JSON.
                 * @function toJSON
                 * @memberof fastcity.connector.launcher.LauncherMessage
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                LauncherMessage.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return LauncherMessage;
            })();

            return launcher;
        })();

        return connector;
    })();

    fastcity.courier = (function() {

        /**
         * Namespace courier.
         * @memberof fastcity
         * @namespace
         */
        var courier = {};

        courier.AcceptOrder = (function() {

            /**
             * Properties of an AcceptOrder.
             * @memberof fastcity.courier
             * @interface IAcceptOrder
             * @property {number|Long|null} [orderId] AcceptOrder orderId
             * @property {number|Long|null} [timestampAccept] AcceptOrder timestampAccept
             */

            /**
             * Constructs a new AcceptOrder.
             * @memberof fastcity.courier
             * @classdesc Represents an AcceptOrder.
             * @implements IAcceptOrder
             * @constructor
             * @param {fastcity.courier.IAcceptOrder=} [properties] Properties to set
             */
            function AcceptOrder(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * AcceptOrder orderId.
             * @member {number|Long} orderId
             * @memberof fastcity.courier.AcceptOrder
             * @instance
             */
            AcceptOrder.prototype.orderId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * AcceptOrder timestampAccept.
             * @member {number|Long} timestampAccept
             * @memberof fastcity.courier.AcceptOrder
             * @instance
             */
            AcceptOrder.prototype.timestampAccept = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Creates a new AcceptOrder instance using the specified properties.
             * @function create
             * @memberof fastcity.courier.AcceptOrder
             * @static
             * @param {fastcity.courier.IAcceptOrder=} [properties] Properties to set
             * @returns {fastcity.courier.AcceptOrder} AcceptOrder instance
             */
            AcceptOrder.create = function create(properties) {
                return new AcceptOrder(properties);
            };

            /**
             * Encodes the specified AcceptOrder message. Does not implicitly {@link fastcity.courier.AcceptOrder.verify|verify} messages.
             * @function encode
             * @memberof fastcity.courier.AcceptOrder
             * @static
             * @param {fastcity.courier.IAcceptOrder} message AcceptOrder message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AcceptOrder.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.orderId != null && Object.hasOwnProperty.call(message, "orderId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.orderId);
                if (message.timestampAccept != null && Object.hasOwnProperty.call(message, "timestampAccept"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int64(message.timestampAccept);
                return writer;
            };

            /**
             * Decodes an AcceptOrder message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.courier.AcceptOrder
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.courier.AcceptOrder} AcceptOrder
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AcceptOrder.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.courier.AcceptOrder();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.orderId = reader.int64();
                        break;
                    case 2:
                        message.timestampAccept = reader.int64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an AcceptOrder message.
             * @function verify
             * @memberof fastcity.courier.AcceptOrder
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AcceptOrder.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.orderId != null && message.hasOwnProperty("orderId"))
                    if (!$util.isInteger(message.orderId) && !(message.orderId && $util.isInteger(message.orderId.low) && $util.isInteger(message.orderId.high)))
                        return "orderId: integer|Long expected";
                if (message.timestampAccept != null && message.hasOwnProperty("timestampAccept"))
                    if (!$util.isInteger(message.timestampAccept) && !(message.timestampAccept && $util.isInteger(message.timestampAccept.low) && $util.isInteger(message.timestampAccept.high)))
                        return "timestampAccept: integer|Long expected";
                return null;
            };

            /**
             * Creates an AcceptOrder message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.courier.AcceptOrder
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.courier.AcceptOrder} AcceptOrder
             */
            AcceptOrder.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.courier.AcceptOrder)
                    return object;
                var message = new $root.fastcity.courier.AcceptOrder();
                if (object.orderId != null)
                    if ($util.Long)
                        (message.orderId = $util.Long.fromValue(object.orderId)).unsigned = false;
                    else if (typeof object.orderId === "string")
                        message.orderId = parseInt(object.orderId, 10);
                    else if (typeof object.orderId === "number")
                        message.orderId = object.orderId;
                    else if (typeof object.orderId === "object")
                        message.orderId = new $util.LongBits(object.orderId.low >>> 0, object.orderId.high >>> 0).toNumber();
                if (object.timestampAccept != null)
                    if ($util.Long)
                        (message.timestampAccept = $util.Long.fromValue(object.timestampAccept)).unsigned = false;
                    else if (typeof object.timestampAccept === "string")
                        message.timestampAccept = parseInt(object.timestampAccept, 10);
                    else if (typeof object.timestampAccept === "number")
                        message.timestampAccept = object.timestampAccept;
                    else if (typeof object.timestampAccept === "object")
                        message.timestampAccept = new $util.LongBits(object.timestampAccept.low >>> 0, object.timestampAccept.high >>> 0).toNumber();
                return message;
            };

            /**
             * Creates a plain object from an AcceptOrder message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.courier.AcceptOrder
             * @static
             * @param {fastcity.courier.AcceptOrder} message AcceptOrder
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AcceptOrder.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.orderId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.orderId = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.timestampAccept = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.timestampAccept = options.longs === String ? "0" : 0;
                }
                if (message.orderId != null && message.hasOwnProperty("orderId"))
                    if (typeof message.orderId === "number")
                        object.orderId = options.longs === String ? String(message.orderId) : message.orderId;
                    else
                        object.orderId = options.longs === String ? $util.Long.prototype.toString.call(message.orderId) : options.longs === Number ? new $util.LongBits(message.orderId.low >>> 0, message.orderId.high >>> 0).toNumber() : message.orderId;
                if (message.timestampAccept != null && message.hasOwnProperty("timestampAccept"))
                    if (typeof message.timestampAccept === "number")
                        object.timestampAccept = options.longs === String ? String(message.timestampAccept) : message.timestampAccept;
                    else
                        object.timestampAccept = options.longs === String ? $util.Long.prototype.toString.call(message.timestampAccept) : options.longs === Number ? new $util.LongBits(message.timestampAccept.low >>> 0, message.timestampAccept.high >>> 0).toNumber() : message.timestampAccept;
                return object;
            };

            /**
             * Converts this AcceptOrder to JSON.
             * @function toJSON
             * @memberof fastcity.courier.AcceptOrder
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AcceptOrder.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return AcceptOrder;
        })();

        /**
         * CourierRequestResult enum.
         * @name fastcity.courier.CourierRequestResult
         * @enum {number}
         * @property {number} BAD_MINIMAL_DISTANCE_TO_ORDER_TARGET=0 BAD_MINIMAL_DISTANCE_TO_ORDER_TARGET value
         */
        courier.CourierRequestResult = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "BAD_MINIMAL_DISTANCE_TO_ORDER_TARGET"] = 0;
            return values;
        })();

        courier.Courier = (function() {

            /**
             * Properties of a Courier.
             * @memberof fastcity.courier
             * @interface ICourier
             * @property {string|null} [courierId] Courier courierId
             * @property {number|Long|null} [courierIdInt] Courier courierIdInt
             * @property {string|null} [name] Courier name
             * @property {string|null} [phone] Courier phone
             * @property {string|null} [supportPhone] Courier supportPhone
             * @property {number|null} [minimalDistanceToOrderTarget] Courier minimalDistanceToOrderTarget
             * @property {fastcity.common.Company.MapType|null} [mapType] Courier mapType
             * @property {boolean|null} [useDifferentColorsForAllOrders] Courier useDifferentColorsForAllOrders
             */

            /**
             * Constructs a new Courier.
             * @memberof fastcity.courier
             * @classdesc Represents a Courier.
             * @implements ICourier
             * @constructor
             * @param {fastcity.courier.ICourier=} [properties] Properties to set
             */
            function Courier(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Courier courierId.
             * @member {string} courierId
             * @memberof fastcity.courier.Courier
             * @instance
             */
            Courier.prototype.courierId = "";

            /**
             * Courier courierIdInt.
             * @member {number|Long} courierIdInt
             * @memberof fastcity.courier.Courier
             * @instance
             */
            Courier.prototype.courierIdInt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Courier name.
             * @member {string} name
             * @memberof fastcity.courier.Courier
             * @instance
             */
            Courier.prototype.name = "";

            /**
             * Courier phone.
             * @member {string} phone
             * @memberof fastcity.courier.Courier
             * @instance
             */
            Courier.prototype.phone = "";

            /**
             * Courier supportPhone.
             * @member {string} supportPhone
             * @memberof fastcity.courier.Courier
             * @instance
             */
            Courier.prototype.supportPhone = "";

            /**
             * Courier minimalDistanceToOrderTarget.
             * @member {number} minimalDistanceToOrderTarget
             * @memberof fastcity.courier.Courier
             * @instance
             */
            Courier.prototype.minimalDistanceToOrderTarget = 0;

            /**
             * Courier mapType.
             * @member {fastcity.common.Company.MapType} mapType
             * @memberof fastcity.courier.Courier
             * @instance
             */
            Courier.prototype.mapType = 0;

            /**
             * Courier useDifferentColorsForAllOrders.
             * @member {boolean} useDifferentColorsForAllOrders
             * @memberof fastcity.courier.Courier
             * @instance
             */
            Courier.prototype.useDifferentColorsForAllOrders = false;

            /**
             * Creates a new Courier instance using the specified properties.
             * @function create
             * @memberof fastcity.courier.Courier
             * @static
             * @param {fastcity.courier.ICourier=} [properties] Properties to set
             * @returns {fastcity.courier.Courier} Courier instance
             */
            Courier.create = function create(properties) {
                return new Courier(properties);
            };

            /**
             * Encodes the specified Courier message. Does not implicitly {@link fastcity.courier.Courier.verify|verify} messages.
             * @function encode
             * @memberof fastcity.courier.Courier
             * @static
             * @param {fastcity.courier.ICourier} message Courier message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Courier.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.courierId != null && Object.hasOwnProperty.call(message, "courierId"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.courierId);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                if (message.phone != null && Object.hasOwnProperty.call(message, "phone"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.phone);
                if (message.supportPhone != null && Object.hasOwnProperty.call(message, "supportPhone"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.supportPhone);
                if (message.minimalDistanceToOrderTarget != null && Object.hasOwnProperty.call(message, "minimalDistanceToOrderTarget"))
                    writer.uint32(/* id 5, wireType 0 =*/40).int32(message.minimalDistanceToOrderTarget);
                if (message.mapType != null && Object.hasOwnProperty.call(message, "mapType"))
                    writer.uint32(/* id 6, wireType 0 =*/48).int32(message.mapType);
                if (message.useDifferentColorsForAllOrders != null && Object.hasOwnProperty.call(message, "useDifferentColorsForAllOrders"))
                    writer.uint32(/* id 7, wireType 0 =*/56).bool(message.useDifferentColorsForAllOrders);
                if (message.courierIdInt != null && Object.hasOwnProperty.call(message, "courierIdInt"))
                    writer.uint32(/* id 8, wireType 0 =*/64).int64(message.courierIdInt);
                return writer;
            };

            /**
             * Decodes a Courier message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.courier.Courier
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.courier.Courier} Courier
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Courier.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.courier.Courier();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.courierId = reader.string();
                        break;
                    case 8:
                        message.courierIdInt = reader.int64();
                        break;
                    case 2:
                        message.name = reader.string();
                        break;
                    case 3:
                        message.phone = reader.string();
                        break;
                    case 4:
                        message.supportPhone = reader.string();
                        break;
                    case 5:
                        message.minimalDistanceToOrderTarget = reader.int32();
                        break;
                    case 6:
                        message.mapType = reader.int32();
                        break;
                    case 7:
                        message.useDifferentColorsForAllOrders = reader.bool();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a Courier message.
             * @function verify
             * @memberof fastcity.courier.Courier
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Courier.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.courierId != null && message.hasOwnProperty("courierId"))
                    if (!$util.isString(message.courierId))
                        return "courierId: string expected";
                if (message.courierIdInt != null && message.hasOwnProperty("courierIdInt"))
                    if (!$util.isInteger(message.courierIdInt) && !(message.courierIdInt && $util.isInteger(message.courierIdInt.low) && $util.isInteger(message.courierIdInt.high)))
                        return "courierIdInt: integer|Long expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.phone != null && message.hasOwnProperty("phone"))
                    if (!$util.isString(message.phone))
                        return "phone: string expected";
                if (message.supportPhone != null && message.hasOwnProperty("supportPhone"))
                    if (!$util.isString(message.supportPhone))
                        return "supportPhone: string expected";
                if (message.minimalDistanceToOrderTarget != null && message.hasOwnProperty("minimalDistanceToOrderTarget"))
                    if (!$util.isInteger(message.minimalDistanceToOrderTarget))
                        return "minimalDistanceToOrderTarget: integer expected";
                if (message.mapType != null && message.hasOwnProperty("mapType"))
                    switch (message.mapType) {
                    default:
                        return "mapType: enum value expected";
                    case 0:
                    case 1:
                        break;
                    }
                if (message.useDifferentColorsForAllOrders != null && message.hasOwnProperty("useDifferentColorsForAllOrders"))
                    if (typeof message.useDifferentColorsForAllOrders !== "boolean")
                        return "useDifferentColorsForAllOrders: boolean expected";
                return null;
            };

            /**
             * Creates a Courier message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.courier.Courier
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.courier.Courier} Courier
             */
            Courier.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.courier.Courier)
                    return object;
                var message = new $root.fastcity.courier.Courier();
                if (object.courierId != null)
                    message.courierId = String(object.courierId);
                if (object.courierIdInt != null)
                    if ($util.Long)
                        (message.courierIdInt = $util.Long.fromValue(object.courierIdInt)).unsigned = false;
                    else if (typeof object.courierIdInt === "string")
                        message.courierIdInt = parseInt(object.courierIdInt, 10);
                    else if (typeof object.courierIdInt === "number")
                        message.courierIdInt = object.courierIdInt;
                    else if (typeof object.courierIdInt === "object")
                        message.courierIdInt = new $util.LongBits(object.courierIdInt.low >>> 0, object.courierIdInt.high >>> 0).toNumber();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.phone != null)
                    message.phone = String(object.phone);
                if (object.supportPhone != null)
                    message.supportPhone = String(object.supportPhone);
                if (object.minimalDistanceToOrderTarget != null)
                    message.minimalDistanceToOrderTarget = object.minimalDistanceToOrderTarget | 0;
                switch (object.mapType) {
                case "GOOGLE":
                case 0:
                    message.mapType = 0;
                    break;
                case "YANDEX":
                case 1:
                    message.mapType = 1;
                    break;
                }
                if (object.useDifferentColorsForAllOrders != null)
                    message.useDifferentColorsForAllOrders = Boolean(object.useDifferentColorsForAllOrders);
                return message;
            };

            /**
             * Creates a plain object from a Courier message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.courier.Courier
             * @static
             * @param {fastcity.courier.Courier} message Courier
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Courier.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.courierId = "";
                    object.name = "";
                    object.phone = "";
                    object.supportPhone = "";
                    object.minimalDistanceToOrderTarget = 0;
                    object.mapType = options.enums === String ? "GOOGLE" : 0;
                    object.useDifferentColorsForAllOrders = false;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.courierIdInt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.courierIdInt = options.longs === String ? "0" : 0;
                }
                if (message.courierId != null && message.hasOwnProperty("courierId"))
                    object.courierId = message.courierId;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.phone != null && message.hasOwnProperty("phone"))
                    object.phone = message.phone;
                if (message.supportPhone != null && message.hasOwnProperty("supportPhone"))
                    object.supportPhone = message.supportPhone;
                if (message.minimalDistanceToOrderTarget != null && message.hasOwnProperty("minimalDistanceToOrderTarget"))
                    object.minimalDistanceToOrderTarget = message.minimalDistanceToOrderTarget;
                if (message.mapType != null && message.hasOwnProperty("mapType"))
                    object.mapType = options.enums === String ? $root.fastcity.common.Company.MapType[message.mapType] : message.mapType;
                if (message.useDifferentColorsForAllOrders != null && message.hasOwnProperty("useDifferentColorsForAllOrders"))
                    object.useDifferentColorsForAllOrders = message.useDifferentColorsForAllOrders;
                if (message.courierIdInt != null && message.hasOwnProperty("courierIdInt"))
                    if (typeof message.courierIdInt === "number")
                        object.courierIdInt = options.longs === String ? String(message.courierIdInt) : message.courierIdInt;
                    else
                        object.courierIdInt = options.longs === String ? $util.Long.prototype.toString.call(message.courierIdInt) : options.longs === Number ? new $util.LongBits(message.courierIdInt.low >>> 0, message.courierIdInt.high >>> 0).toNumber() : message.courierIdInt;
                return object;
            };

            /**
             * Converts this Courier to JSON.
             * @function toJSON
             * @memberof fastcity.courier.Courier
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Courier.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Courier;
        })();

        courier.GetCurrentCourier = (function() {

            /**
             * Properties of a GetCurrentCourier.
             * @memberof fastcity.courier
             * @interface IGetCurrentCourier
             */

            /**
             * Constructs a new GetCurrentCourier.
             * @memberof fastcity.courier
             * @classdesc Represents a GetCurrentCourier.
             * @implements IGetCurrentCourier
             * @constructor
             * @param {fastcity.courier.IGetCurrentCourier=} [properties] Properties to set
             */
            function GetCurrentCourier(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new GetCurrentCourier instance using the specified properties.
             * @function create
             * @memberof fastcity.courier.GetCurrentCourier
             * @static
             * @param {fastcity.courier.IGetCurrentCourier=} [properties] Properties to set
             * @returns {fastcity.courier.GetCurrentCourier} GetCurrentCourier instance
             */
            GetCurrentCourier.create = function create(properties) {
                return new GetCurrentCourier(properties);
            };

            /**
             * Encodes the specified GetCurrentCourier message. Does not implicitly {@link fastcity.courier.GetCurrentCourier.verify|verify} messages.
             * @function encode
             * @memberof fastcity.courier.GetCurrentCourier
             * @static
             * @param {fastcity.courier.IGetCurrentCourier} message GetCurrentCourier message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetCurrentCourier.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Decodes a GetCurrentCourier message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.courier.GetCurrentCourier
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.courier.GetCurrentCourier} GetCurrentCourier
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetCurrentCourier.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.courier.GetCurrentCourier();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a GetCurrentCourier message.
             * @function verify
             * @memberof fastcity.courier.GetCurrentCourier
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetCurrentCourier.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a GetCurrentCourier message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.courier.GetCurrentCourier
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.courier.GetCurrentCourier} GetCurrentCourier
             */
            GetCurrentCourier.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.courier.GetCurrentCourier)
                    return object;
                return new $root.fastcity.courier.GetCurrentCourier();
            };

            /**
             * Creates a plain object from a GetCurrentCourier message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.courier.GetCurrentCourier
             * @static
             * @param {fastcity.courier.GetCurrentCourier} message GetCurrentCourier
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetCurrentCourier.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this GetCurrentCourier to JSON.
             * @function toJSON
             * @memberof fastcity.courier.GetCurrentCourier
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetCurrentCourier.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return GetCurrentCourier;
        })();

        courier.UpdateMinimalDistanceToOrderTarget = (function() {

            /**
             * Properties of an UpdateMinimalDistanceToOrderTarget.
             * @memberof fastcity.courier
             * @interface IUpdateMinimalDistanceToOrderTarget
             * @property {number|null} [minimalDistanceToOrderTarget] UpdateMinimalDistanceToOrderTarget minimalDistanceToOrderTarget
             */

            /**
             * Constructs a new UpdateMinimalDistanceToOrderTarget.
             * @memberof fastcity.courier
             * @classdesc Represents an UpdateMinimalDistanceToOrderTarget.
             * @implements IUpdateMinimalDistanceToOrderTarget
             * @constructor
             * @param {fastcity.courier.IUpdateMinimalDistanceToOrderTarget=} [properties] Properties to set
             */
            function UpdateMinimalDistanceToOrderTarget(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * UpdateMinimalDistanceToOrderTarget minimalDistanceToOrderTarget.
             * @member {number} minimalDistanceToOrderTarget
             * @memberof fastcity.courier.UpdateMinimalDistanceToOrderTarget
             * @instance
             */
            UpdateMinimalDistanceToOrderTarget.prototype.minimalDistanceToOrderTarget = 0;

            /**
             * Creates a new UpdateMinimalDistanceToOrderTarget instance using the specified properties.
             * @function create
             * @memberof fastcity.courier.UpdateMinimalDistanceToOrderTarget
             * @static
             * @param {fastcity.courier.IUpdateMinimalDistanceToOrderTarget=} [properties] Properties to set
             * @returns {fastcity.courier.UpdateMinimalDistanceToOrderTarget} UpdateMinimalDistanceToOrderTarget instance
             */
            UpdateMinimalDistanceToOrderTarget.create = function create(properties) {
                return new UpdateMinimalDistanceToOrderTarget(properties);
            };

            /**
             * Encodes the specified UpdateMinimalDistanceToOrderTarget message. Does not implicitly {@link fastcity.courier.UpdateMinimalDistanceToOrderTarget.verify|verify} messages.
             * @function encode
             * @memberof fastcity.courier.UpdateMinimalDistanceToOrderTarget
             * @static
             * @param {fastcity.courier.IUpdateMinimalDistanceToOrderTarget} message UpdateMinimalDistanceToOrderTarget message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateMinimalDistanceToOrderTarget.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.minimalDistanceToOrderTarget != null && Object.hasOwnProperty.call(message, "minimalDistanceToOrderTarget"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.minimalDistanceToOrderTarget);
                return writer;
            };

            /**
             * Decodes an UpdateMinimalDistanceToOrderTarget message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.courier.UpdateMinimalDistanceToOrderTarget
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.courier.UpdateMinimalDistanceToOrderTarget} UpdateMinimalDistanceToOrderTarget
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateMinimalDistanceToOrderTarget.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.courier.UpdateMinimalDistanceToOrderTarget();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.minimalDistanceToOrderTarget = reader.int32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an UpdateMinimalDistanceToOrderTarget message.
             * @function verify
             * @memberof fastcity.courier.UpdateMinimalDistanceToOrderTarget
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            UpdateMinimalDistanceToOrderTarget.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.minimalDistanceToOrderTarget != null && message.hasOwnProperty("minimalDistanceToOrderTarget"))
                    if (!$util.isInteger(message.minimalDistanceToOrderTarget))
                        return "minimalDistanceToOrderTarget: integer expected";
                return null;
            };

            /**
             * Creates an UpdateMinimalDistanceToOrderTarget message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.courier.UpdateMinimalDistanceToOrderTarget
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.courier.UpdateMinimalDistanceToOrderTarget} UpdateMinimalDistanceToOrderTarget
             */
            UpdateMinimalDistanceToOrderTarget.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.courier.UpdateMinimalDistanceToOrderTarget)
                    return object;
                var message = new $root.fastcity.courier.UpdateMinimalDistanceToOrderTarget();
                if (object.minimalDistanceToOrderTarget != null)
                    message.minimalDistanceToOrderTarget = object.minimalDistanceToOrderTarget | 0;
                return message;
            };

            /**
             * Creates a plain object from an UpdateMinimalDistanceToOrderTarget message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.courier.UpdateMinimalDistanceToOrderTarget
             * @static
             * @param {fastcity.courier.UpdateMinimalDistanceToOrderTarget} message UpdateMinimalDistanceToOrderTarget
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UpdateMinimalDistanceToOrderTarget.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.minimalDistanceToOrderTarget = 0;
                if (message.minimalDistanceToOrderTarget != null && message.hasOwnProperty("minimalDistanceToOrderTarget"))
                    object.minimalDistanceToOrderTarget = message.minimalDistanceToOrderTarget;
                return object;
            };

            /**
             * Converts this UpdateMinimalDistanceToOrderTarget to JSON.
             * @function toJSON
             * @memberof fastcity.courier.UpdateMinimalDistanceToOrderTarget
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UpdateMinimalDistanceToOrderTarget.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return UpdateMinimalDistanceToOrderTarget;
        })();

        courier.DeliveryStatDay = (function() {

            /**
             * Properties of a DeliveryStatDay.
             * @memberof fastcity.courier
             * @interface IDeliveryStatDay
             * @property {number|Long|null} [timestampWorkingDay] DeliveryStatDay timestampWorkingDay
             * @property {number|null} [numberDelivered] DeliveryStatDay numberDelivered
             * @property {number|null} [numberDeliveredWithoutDelay] DeliveryStatDay numberDeliveredWithoutDelay
             * @property {number|null} [numberDeliveredWithDelay] DeliveryStatDay numberDeliveredWithDelay
             * @property {number|null} [averageDeliveryTime] DeliveryStatDay averageDeliveryTime
             * @property {number|null} [numberCanceled] DeliveryStatDay numberCanceled
             * @property {number|null} [numberDeliveredIn_30Minutes] DeliveryStatDay numberDeliveredIn_30Minutes
             */

            /**
             * Constructs a new DeliveryStatDay.
             * @memberof fastcity.courier
             * @classdesc Represents a DeliveryStatDay.
             * @implements IDeliveryStatDay
             * @constructor
             * @param {fastcity.courier.IDeliveryStatDay=} [properties] Properties to set
             */
            function DeliveryStatDay(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * DeliveryStatDay timestampWorkingDay.
             * @member {number|Long} timestampWorkingDay
             * @memberof fastcity.courier.DeliveryStatDay
             * @instance
             */
            DeliveryStatDay.prototype.timestampWorkingDay = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * DeliveryStatDay numberDelivered.
             * @member {number} numberDelivered
             * @memberof fastcity.courier.DeliveryStatDay
             * @instance
             */
            DeliveryStatDay.prototype.numberDelivered = 0;

            /**
             * DeliveryStatDay numberDeliveredWithoutDelay.
             * @member {number} numberDeliveredWithoutDelay
             * @memberof fastcity.courier.DeliveryStatDay
             * @instance
             */
            DeliveryStatDay.prototype.numberDeliveredWithoutDelay = 0;

            /**
             * DeliveryStatDay numberDeliveredWithDelay.
             * @member {number} numberDeliveredWithDelay
             * @memberof fastcity.courier.DeliveryStatDay
             * @instance
             */
            DeliveryStatDay.prototype.numberDeliveredWithDelay = 0;

            /**
             * DeliveryStatDay averageDeliveryTime.
             * @member {number} averageDeliveryTime
             * @memberof fastcity.courier.DeliveryStatDay
             * @instance
             */
            DeliveryStatDay.prototype.averageDeliveryTime = 0;

            /**
             * DeliveryStatDay numberCanceled.
             * @member {number} numberCanceled
             * @memberof fastcity.courier.DeliveryStatDay
             * @instance
             */
            DeliveryStatDay.prototype.numberCanceled = 0;

            /**
             * DeliveryStatDay numberDeliveredIn_30Minutes.
             * @member {number} numberDeliveredIn_30Minutes
             * @memberof fastcity.courier.DeliveryStatDay
             * @instance
             */
            DeliveryStatDay.prototype.numberDeliveredIn_30Minutes = 0;

            /**
             * Creates a new DeliveryStatDay instance using the specified properties.
             * @function create
             * @memberof fastcity.courier.DeliveryStatDay
             * @static
             * @param {fastcity.courier.IDeliveryStatDay=} [properties] Properties to set
             * @returns {fastcity.courier.DeliveryStatDay} DeliveryStatDay instance
             */
            DeliveryStatDay.create = function create(properties) {
                return new DeliveryStatDay(properties);
            };

            /**
             * Encodes the specified DeliveryStatDay message. Does not implicitly {@link fastcity.courier.DeliveryStatDay.verify|verify} messages.
             * @function encode
             * @memberof fastcity.courier.DeliveryStatDay
             * @static
             * @param {fastcity.courier.IDeliveryStatDay} message DeliveryStatDay message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeliveryStatDay.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.timestampWorkingDay != null && Object.hasOwnProperty.call(message, "timestampWorkingDay"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.timestampWorkingDay);
                if (message.numberDelivered != null && Object.hasOwnProperty.call(message, "numberDelivered"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.numberDelivered);
                if (message.numberDeliveredWithoutDelay != null && Object.hasOwnProperty.call(message, "numberDeliveredWithoutDelay"))
                    writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.numberDeliveredWithoutDelay);
                if (message.numberDeliveredWithDelay != null && Object.hasOwnProperty.call(message, "numberDeliveredWithDelay"))
                    writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.numberDeliveredWithDelay);
                if (message.averageDeliveryTime != null && Object.hasOwnProperty.call(message, "averageDeliveryTime"))
                    writer.uint32(/* id 5, wireType 0 =*/40).uint32(message.averageDeliveryTime);
                if (message.numberCanceled != null && Object.hasOwnProperty.call(message, "numberCanceled"))
                    writer.uint32(/* id 6, wireType 0 =*/48).uint32(message.numberCanceled);
                if (message.numberDeliveredIn_30Minutes != null && Object.hasOwnProperty.call(message, "numberDeliveredIn_30Minutes"))
                    writer.uint32(/* id 7, wireType 0 =*/56).uint32(message.numberDeliveredIn_30Minutes);
                return writer;
            };

            /**
             * Decodes a DeliveryStatDay message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.courier.DeliveryStatDay
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.courier.DeliveryStatDay} DeliveryStatDay
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeliveryStatDay.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.courier.DeliveryStatDay();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.timestampWorkingDay = reader.int64();
                        break;
                    case 2:
                        message.numberDelivered = reader.uint32();
                        break;
                    case 3:
                        message.numberDeliveredWithoutDelay = reader.uint32();
                        break;
                    case 4:
                        message.numberDeliveredWithDelay = reader.uint32();
                        break;
                    case 5:
                        message.averageDeliveryTime = reader.uint32();
                        break;
                    case 6:
                        message.numberCanceled = reader.uint32();
                        break;
                    case 7:
                        message.numberDeliveredIn_30Minutes = reader.uint32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a DeliveryStatDay message.
             * @function verify
             * @memberof fastcity.courier.DeliveryStatDay
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DeliveryStatDay.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.timestampWorkingDay != null && message.hasOwnProperty("timestampWorkingDay"))
                    if (!$util.isInteger(message.timestampWorkingDay) && !(message.timestampWorkingDay && $util.isInteger(message.timestampWorkingDay.low) && $util.isInteger(message.timestampWorkingDay.high)))
                        return "timestampWorkingDay: integer|Long expected";
                if (message.numberDelivered != null && message.hasOwnProperty("numberDelivered"))
                    if (!$util.isInteger(message.numberDelivered))
                        return "numberDelivered: integer expected";
                if (message.numberDeliveredWithoutDelay != null && message.hasOwnProperty("numberDeliveredWithoutDelay"))
                    if (!$util.isInteger(message.numberDeliveredWithoutDelay))
                        return "numberDeliveredWithoutDelay: integer expected";
                if (message.numberDeliveredWithDelay != null && message.hasOwnProperty("numberDeliveredWithDelay"))
                    if (!$util.isInteger(message.numberDeliveredWithDelay))
                        return "numberDeliveredWithDelay: integer expected";
                if (message.averageDeliveryTime != null && message.hasOwnProperty("averageDeliveryTime"))
                    if (!$util.isInteger(message.averageDeliveryTime))
                        return "averageDeliveryTime: integer expected";
                if (message.numberCanceled != null && message.hasOwnProperty("numberCanceled"))
                    if (!$util.isInteger(message.numberCanceled))
                        return "numberCanceled: integer expected";
                if (message.numberDeliveredIn_30Minutes != null && message.hasOwnProperty("numberDeliveredIn_30Minutes"))
                    if (!$util.isInteger(message.numberDeliveredIn_30Minutes))
                        return "numberDeliveredIn_30Minutes: integer expected";
                return null;
            };

            /**
             * Creates a DeliveryStatDay message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.courier.DeliveryStatDay
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.courier.DeliveryStatDay} DeliveryStatDay
             */
            DeliveryStatDay.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.courier.DeliveryStatDay)
                    return object;
                var message = new $root.fastcity.courier.DeliveryStatDay();
                if (object.timestampWorkingDay != null)
                    if ($util.Long)
                        (message.timestampWorkingDay = $util.Long.fromValue(object.timestampWorkingDay)).unsigned = false;
                    else if (typeof object.timestampWorkingDay === "string")
                        message.timestampWorkingDay = parseInt(object.timestampWorkingDay, 10);
                    else if (typeof object.timestampWorkingDay === "number")
                        message.timestampWorkingDay = object.timestampWorkingDay;
                    else if (typeof object.timestampWorkingDay === "object")
                        message.timestampWorkingDay = new $util.LongBits(object.timestampWorkingDay.low >>> 0, object.timestampWorkingDay.high >>> 0).toNumber();
                if (object.numberDelivered != null)
                    message.numberDelivered = object.numberDelivered >>> 0;
                if (object.numberDeliveredWithoutDelay != null)
                    message.numberDeliveredWithoutDelay = object.numberDeliveredWithoutDelay >>> 0;
                if (object.numberDeliveredWithDelay != null)
                    message.numberDeliveredWithDelay = object.numberDeliveredWithDelay >>> 0;
                if (object.averageDeliveryTime != null)
                    message.averageDeliveryTime = object.averageDeliveryTime >>> 0;
                if (object.numberCanceled != null)
                    message.numberCanceled = object.numberCanceled >>> 0;
                if (object.numberDeliveredIn_30Minutes != null)
                    message.numberDeliveredIn_30Minutes = object.numberDeliveredIn_30Minutes >>> 0;
                return message;
            };

            /**
             * Creates a plain object from a DeliveryStatDay message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.courier.DeliveryStatDay
             * @static
             * @param {fastcity.courier.DeliveryStatDay} message DeliveryStatDay
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DeliveryStatDay.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.timestampWorkingDay = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.timestampWorkingDay = options.longs === String ? "0" : 0;
                    object.numberDelivered = 0;
                    object.numberDeliveredWithoutDelay = 0;
                    object.numberDeliveredWithDelay = 0;
                    object.averageDeliveryTime = 0;
                    object.numberCanceled = 0;
                    object.numberDeliveredIn_30Minutes = 0;
                }
                if (message.timestampWorkingDay != null && message.hasOwnProperty("timestampWorkingDay"))
                    if (typeof message.timestampWorkingDay === "number")
                        object.timestampWorkingDay = options.longs === String ? String(message.timestampWorkingDay) : message.timestampWorkingDay;
                    else
                        object.timestampWorkingDay = options.longs === String ? $util.Long.prototype.toString.call(message.timestampWorkingDay) : options.longs === Number ? new $util.LongBits(message.timestampWorkingDay.low >>> 0, message.timestampWorkingDay.high >>> 0).toNumber() : message.timestampWorkingDay;
                if (message.numberDelivered != null && message.hasOwnProperty("numberDelivered"))
                    object.numberDelivered = message.numberDelivered;
                if (message.numberDeliveredWithoutDelay != null && message.hasOwnProperty("numberDeliveredWithoutDelay"))
                    object.numberDeliveredWithoutDelay = message.numberDeliveredWithoutDelay;
                if (message.numberDeliveredWithDelay != null && message.hasOwnProperty("numberDeliveredWithDelay"))
                    object.numberDeliveredWithDelay = message.numberDeliveredWithDelay;
                if (message.averageDeliveryTime != null && message.hasOwnProperty("averageDeliveryTime"))
                    object.averageDeliveryTime = message.averageDeliveryTime;
                if (message.numberCanceled != null && message.hasOwnProperty("numberCanceled"))
                    object.numberCanceled = message.numberCanceled;
                if (message.numberDeliveredIn_30Minutes != null && message.hasOwnProperty("numberDeliveredIn_30Minutes"))
                    object.numberDeliveredIn_30Minutes = message.numberDeliveredIn_30Minutes;
                return object;
            };

            /**
             * Converts this DeliveryStatDay to JSON.
             * @function toJSON
             * @memberof fastcity.courier.DeliveryStatDay
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DeliveryStatDay.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return DeliveryStatDay;
        })();

        courier.DeliveryStat = (function() {

            /**
             * Properties of a DeliveryStat.
             * @memberof fastcity.courier
             * @interface IDeliveryStat
             * @property {fastcity.courier.IDeliveryStatDay|null} [currentStatDay] DeliveryStat currentStatDay
             * @property {number|null} [averageDeliveryTime_1w] DeliveryStat averageDeliveryTime_1w
             * @property {number|null} [averageDeliveryTime_1m] DeliveryStat averageDeliveryTime_1m
             * @property {number|null} [maximumNumberDelivered] DeliveryStat maximumNumberDelivered
             * @property {number|null} [totalNumberDelivered] DeliveryStat totalNumberDelivered
             */

            /**
             * Constructs a new DeliveryStat.
             * @memberof fastcity.courier
             * @classdesc Represents a DeliveryStat.
             * @implements IDeliveryStat
             * @constructor
             * @param {fastcity.courier.IDeliveryStat=} [properties] Properties to set
             */
            function DeliveryStat(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * DeliveryStat currentStatDay.
             * @member {fastcity.courier.IDeliveryStatDay|null|undefined} currentStatDay
             * @memberof fastcity.courier.DeliveryStat
             * @instance
             */
            DeliveryStat.prototype.currentStatDay = null;

            /**
             * DeliveryStat averageDeliveryTime_1w.
             * @member {number} averageDeliveryTime_1w
             * @memberof fastcity.courier.DeliveryStat
             * @instance
             */
            DeliveryStat.prototype.averageDeliveryTime_1w = 0;

            /**
             * DeliveryStat averageDeliveryTime_1m.
             * @member {number} averageDeliveryTime_1m
             * @memberof fastcity.courier.DeliveryStat
             * @instance
             */
            DeliveryStat.prototype.averageDeliveryTime_1m = 0;

            /**
             * DeliveryStat maximumNumberDelivered.
             * @member {number} maximumNumberDelivered
             * @memberof fastcity.courier.DeliveryStat
             * @instance
             */
            DeliveryStat.prototype.maximumNumberDelivered = 0;

            /**
             * DeliveryStat totalNumberDelivered.
             * @member {number} totalNumberDelivered
             * @memberof fastcity.courier.DeliveryStat
             * @instance
             */
            DeliveryStat.prototype.totalNumberDelivered = 0;

            /**
             * Creates a new DeliveryStat instance using the specified properties.
             * @function create
             * @memberof fastcity.courier.DeliveryStat
             * @static
             * @param {fastcity.courier.IDeliveryStat=} [properties] Properties to set
             * @returns {fastcity.courier.DeliveryStat} DeliveryStat instance
             */
            DeliveryStat.create = function create(properties) {
                return new DeliveryStat(properties);
            };

            /**
             * Encodes the specified DeliveryStat message. Does not implicitly {@link fastcity.courier.DeliveryStat.verify|verify} messages.
             * @function encode
             * @memberof fastcity.courier.DeliveryStat
             * @static
             * @param {fastcity.courier.IDeliveryStat} message DeliveryStat message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeliveryStat.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.currentStatDay != null && Object.hasOwnProperty.call(message, "currentStatDay"))
                    $root.fastcity.courier.DeliveryStatDay.encode(message.currentStatDay, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.averageDeliveryTime_1w != null && Object.hasOwnProperty.call(message, "averageDeliveryTime_1w"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.averageDeliveryTime_1w);
                if (message.averageDeliveryTime_1m != null && Object.hasOwnProperty.call(message, "averageDeliveryTime_1m"))
                    writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.averageDeliveryTime_1m);
                if (message.maximumNumberDelivered != null && Object.hasOwnProperty.call(message, "maximumNumberDelivered"))
                    writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.maximumNumberDelivered);
                if (message.totalNumberDelivered != null && Object.hasOwnProperty.call(message, "totalNumberDelivered"))
                    writer.uint32(/* id 5, wireType 0 =*/40).uint32(message.totalNumberDelivered);
                return writer;
            };

            /**
             * Decodes a DeliveryStat message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.courier.DeliveryStat
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.courier.DeliveryStat} DeliveryStat
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeliveryStat.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.courier.DeliveryStat();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.currentStatDay = $root.fastcity.courier.DeliveryStatDay.decode(reader, reader.uint32());
                        break;
                    case 2:
                        message.averageDeliveryTime_1w = reader.uint32();
                        break;
                    case 3:
                        message.averageDeliveryTime_1m = reader.uint32();
                        break;
                    case 4:
                        message.maximumNumberDelivered = reader.uint32();
                        break;
                    case 5:
                        message.totalNumberDelivered = reader.uint32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a DeliveryStat message.
             * @function verify
             * @memberof fastcity.courier.DeliveryStat
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DeliveryStat.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.currentStatDay != null && message.hasOwnProperty("currentStatDay")) {
                    var error = $root.fastcity.courier.DeliveryStatDay.verify(message.currentStatDay);
                    if (error)
                        return "currentStatDay." + error;
                }
                if (message.averageDeliveryTime_1w != null && message.hasOwnProperty("averageDeliveryTime_1w"))
                    if (!$util.isInteger(message.averageDeliveryTime_1w))
                        return "averageDeliveryTime_1w: integer expected";
                if (message.averageDeliveryTime_1m != null && message.hasOwnProperty("averageDeliveryTime_1m"))
                    if (!$util.isInteger(message.averageDeliveryTime_1m))
                        return "averageDeliveryTime_1m: integer expected";
                if (message.maximumNumberDelivered != null && message.hasOwnProperty("maximumNumberDelivered"))
                    if (!$util.isInteger(message.maximumNumberDelivered))
                        return "maximumNumberDelivered: integer expected";
                if (message.totalNumberDelivered != null && message.hasOwnProperty("totalNumberDelivered"))
                    if (!$util.isInteger(message.totalNumberDelivered))
                        return "totalNumberDelivered: integer expected";
                return null;
            };

            /**
             * Creates a DeliveryStat message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.courier.DeliveryStat
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.courier.DeliveryStat} DeliveryStat
             */
            DeliveryStat.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.courier.DeliveryStat)
                    return object;
                var message = new $root.fastcity.courier.DeliveryStat();
                if (object.currentStatDay != null) {
                    if (typeof object.currentStatDay !== "object")
                        throw TypeError(".fastcity.courier.DeliveryStat.currentStatDay: object expected");
                    message.currentStatDay = $root.fastcity.courier.DeliveryStatDay.fromObject(object.currentStatDay);
                }
                if (object.averageDeliveryTime_1w != null)
                    message.averageDeliveryTime_1w = object.averageDeliveryTime_1w >>> 0;
                if (object.averageDeliveryTime_1m != null)
                    message.averageDeliveryTime_1m = object.averageDeliveryTime_1m >>> 0;
                if (object.maximumNumberDelivered != null)
                    message.maximumNumberDelivered = object.maximumNumberDelivered >>> 0;
                if (object.totalNumberDelivered != null)
                    message.totalNumberDelivered = object.totalNumberDelivered >>> 0;
                return message;
            };

            /**
             * Creates a plain object from a DeliveryStat message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.courier.DeliveryStat
             * @static
             * @param {fastcity.courier.DeliveryStat} message DeliveryStat
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DeliveryStat.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.currentStatDay = null;
                    object.averageDeliveryTime_1w = 0;
                    object.averageDeliveryTime_1m = 0;
                    object.maximumNumberDelivered = 0;
                    object.totalNumberDelivered = 0;
                }
                if (message.currentStatDay != null && message.hasOwnProperty("currentStatDay"))
                    object.currentStatDay = $root.fastcity.courier.DeliveryStatDay.toObject(message.currentStatDay, options);
                if (message.averageDeliveryTime_1w != null && message.hasOwnProperty("averageDeliveryTime_1w"))
                    object.averageDeliveryTime_1w = message.averageDeliveryTime_1w;
                if (message.averageDeliveryTime_1m != null && message.hasOwnProperty("averageDeliveryTime_1m"))
                    object.averageDeliveryTime_1m = message.averageDeliveryTime_1m;
                if (message.maximumNumberDelivered != null && message.hasOwnProperty("maximumNumberDelivered"))
                    object.maximumNumberDelivered = message.maximumNumberDelivered;
                if (message.totalNumberDelivered != null && message.hasOwnProperty("totalNumberDelivered"))
                    object.totalNumberDelivered = message.totalNumberDelivered;
                return object;
            };

            /**
             * Converts this DeliveryStat to JSON.
             * @function toJSON
             * @memberof fastcity.courier.DeliveryStat
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DeliveryStat.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return DeliveryStat;
        })();

        courier.DeliveryStatDayList = (function() {

            /**
             * Properties of a DeliveryStatDayList.
             * @memberof fastcity.courier
             * @interface IDeliveryStatDayList
             * @property {Array.<fastcity.courier.IDeliveryStatDay>|null} [deliveryStatDay] DeliveryStatDayList deliveryStatDay
             * @property {number|null} [totalDeliveryStatDays] DeliveryStatDayList totalDeliveryStatDays
             */

            /**
             * Constructs a new DeliveryStatDayList.
             * @memberof fastcity.courier
             * @classdesc Represents a DeliveryStatDayList.
             * @implements IDeliveryStatDayList
             * @constructor
             * @param {fastcity.courier.IDeliveryStatDayList=} [properties] Properties to set
             */
            function DeliveryStatDayList(properties) {
                this.deliveryStatDay = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * DeliveryStatDayList deliveryStatDay.
             * @member {Array.<fastcity.courier.IDeliveryStatDay>} deliveryStatDay
             * @memberof fastcity.courier.DeliveryStatDayList
             * @instance
             */
            DeliveryStatDayList.prototype.deliveryStatDay = $util.emptyArray;

            /**
             * DeliveryStatDayList totalDeliveryStatDays.
             * @member {number} totalDeliveryStatDays
             * @memberof fastcity.courier.DeliveryStatDayList
             * @instance
             */
            DeliveryStatDayList.prototype.totalDeliveryStatDays = 0;

            /**
             * Creates a new DeliveryStatDayList instance using the specified properties.
             * @function create
             * @memberof fastcity.courier.DeliveryStatDayList
             * @static
             * @param {fastcity.courier.IDeliveryStatDayList=} [properties] Properties to set
             * @returns {fastcity.courier.DeliveryStatDayList} DeliveryStatDayList instance
             */
            DeliveryStatDayList.create = function create(properties) {
                return new DeliveryStatDayList(properties);
            };

            /**
             * Encodes the specified DeliveryStatDayList message. Does not implicitly {@link fastcity.courier.DeliveryStatDayList.verify|verify} messages.
             * @function encode
             * @memberof fastcity.courier.DeliveryStatDayList
             * @static
             * @param {fastcity.courier.IDeliveryStatDayList} message DeliveryStatDayList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeliveryStatDayList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.deliveryStatDay != null && message.deliveryStatDay.length)
                    for (var i = 0; i < message.deliveryStatDay.length; ++i)
                        $root.fastcity.courier.DeliveryStatDay.encode(message.deliveryStatDay[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.totalDeliveryStatDays != null && Object.hasOwnProperty.call(message, "totalDeliveryStatDays"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.totalDeliveryStatDays);
                return writer;
            };

            /**
             * Decodes a DeliveryStatDayList message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.courier.DeliveryStatDayList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.courier.DeliveryStatDayList} DeliveryStatDayList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeliveryStatDayList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.courier.DeliveryStatDayList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.deliveryStatDay && message.deliveryStatDay.length))
                            message.deliveryStatDay = [];
                        message.deliveryStatDay.push($root.fastcity.courier.DeliveryStatDay.decode(reader, reader.uint32()));
                        break;
                    case 2:
                        message.totalDeliveryStatDays = reader.uint32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a DeliveryStatDayList message.
             * @function verify
             * @memberof fastcity.courier.DeliveryStatDayList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DeliveryStatDayList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.deliveryStatDay != null && message.hasOwnProperty("deliveryStatDay")) {
                    if (!Array.isArray(message.deliveryStatDay))
                        return "deliveryStatDay: array expected";
                    for (var i = 0; i < message.deliveryStatDay.length; ++i) {
                        var error = $root.fastcity.courier.DeliveryStatDay.verify(message.deliveryStatDay[i]);
                        if (error)
                            return "deliveryStatDay." + error;
                    }
                }
                if (message.totalDeliveryStatDays != null && message.hasOwnProperty("totalDeliveryStatDays"))
                    if (!$util.isInteger(message.totalDeliveryStatDays))
                        return "totalDeliveryStatDays: integer expected";
                return null;
            };

            /**
             * Creates a DeliveryStatDayList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.courier.DeliveryStatDayList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.courier.DeliveryStatDayList} DeliveryStatDayList
             */
            DeliveryStatDayList.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.courier.DeliveryStatDayList)
                    return object;
                var message = new $root.fastcity.courier.DeliveryStatDayList();
                if (object.deliveryStatDay) {
                    if (!Array.isArray(object.deliveryStatDay))
                        throw TypeError(".fastcity.courier.DeliveryStatDayList.deliveryStatDay: array expected");
                    message.deliveryStatDay = [];
                    for (var i = 0; i < object.deliveryStatDay.length; ++i) {
                        if (typeof object.deliveryStatDay[i] !== "object")
                            throw TypeError(".fastcity.courier.DeliveryStatDayList.deliveryStatDay: object expected");
                        message.deliveryStatDay[i] = $root.fastcity.courier.DeliveryStatDay.fromObject(object.deliveryStatDay[i]);
                    }
                }
                if (object.totalDeliveryStatDays != null)
                    message.totalDeliveryStatDays = object.totalDeliveryStatDays >>> 0;
                return message;
            };

            /**
             * Creates a plain object from a DeliveryStatDayList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.courier.DeliveryStatDayList
             * @static
             * @param {fastcity.courier.DeliveryStatDayList} message DeliveryStatDayList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DeliveryStatDayList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.deliveryStatDay = [];
                if (options.defaults)
                    object.totalDeliveryStatDays = 0;
                if (message.deliveryStatDay && message.deliveryStatDay.length) {
                    object.deliveryStatDay = [];
                    for (var j = 0; j < message.deliveryStatDay.length; ++j)
                        object.deliveryStatDay[j] = $root.fastcity.courier.DeliveryStatDay.toObject(message.deliveryStatDay[j], options);
                }
                if (message.totalDeliveryStatDays != null && message.hasOwnProperty("totalDeliveryStatDays"))
                    object.totalDeliveryStatDays = message.totalDeliveryStatDays;
                return object;
            };

            /**
             * Converts this DeliveryStatDayList to JSON.
             * @function toJSON
             * @memberof fastcity.courier.DeliveryStatDayList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DeliveryStatDayList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return DeliveryStatDayList;
        })();

        courier.GetDeliveryStatDayList = (function() {

            /**
             * Properties of a GetDeliveryStatDayList.
             * @memberof fastcity.courier
             * @interface IGetDeliveryStatDayList
             * @property {fastcity.courier.GetDeliveryStatDayList.ITimeInterval|null} [timeInterval] GetDeliveryStatDayList timeInterval
             */

            /**
             * Constructs a new GetDeliveryStatDayList.
             * @memberof fastcity.courier
             * @classdesc Represents a GetDeliveryStatDayList.
             * @implements IGetDeliveryStatDayList
             * @constructor
             * @param {fastcity.courier.IGetDeliveryStatDayList=} [properties] Properties to set
             */
            function GetDeliveryStatDayList(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GetDeliveryStatDayList timeInterval.
             * @member {fastcity.courier.GetDeliveryStatDayList.ITimeInterval|null|undefined} timeInterval
             * @memberof fastcity.courier.GetDeliveryStatDayList
             * @instance
             */
            GetDeliveryStatDayList.prototype.timeInterval = null;

            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;

            /**
             * GetDeliveryStatDayList type.
             * @member {"timeInterval"|undefined} type
             * @memberof fastcity.courier.GetDeliveryStatDayList
             * @instance
             */
            Object.defineProperty(GetDeliveryStatDayList.prototype, "type", {
                get: $util.oneOfGetter($oneOfFields = ["timeInterval"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Creates a new GetDeliveryStatDayList instance using the specified properties.
             * @function create
             * @memberof fastcity.courier.GetDeliveryStatDayList
             * @static
             * @param {fastcity.courier.IGetDeliveryStatDayList=} [properties] Properties to set
             * @returns {fastcity.courier.GetDeliveryStatDayList} GetDeliveryStatDayList instance
             */
            GetDeliveryStatDayList.create = function create(properties) {
                return new GetDeliveryStatDayList(properties);
            };

            /**
             * Encodes the specified GetDeliveryStatDayList message. Does not implicitly {@link fastcity.courier.GetDeliveryStatDayList.verify|verify} messages.
             * @function encode
             * @memberof fastcity.courier.GetDeliveryStatDayList
             * @static
             * @param {fastcity.courier.IGetDeliveryStatDayList} message GetDeliveryStatDayList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetDeliveryStatDayList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.timeInterval != null && Object.hasOwnProperty.call(message, "timeInterval"))
                    $root.fastcity.courier.GetDeliveryStatDayList.TimeInterval.encode(message.timeInterval, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a GetDeliveryStatDayList message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.courier.GetDeliveryStatDayList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.courier.GetDeliveryStatDayList} GetDeliveryStatDayList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetDeliveryStatDayList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.courier.GetDeliveryStatDayList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.timeInterval = $root.fastcity.courier.GetDeliveryStatDayList.TimeInterval.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a GetDeliveryStatDayList message.
             * @function verify
             * @memberof fastcity.courier.GetDeliveryStatDayList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetDeliveryStatDayList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                var properties = {};
                if (message.timeInterval != null && message.hasOwnProperty("timeInterval")) {
                    properties.type = 1;
                    {
                        var error = $root.fastcity.courier.GetDeliveryStatDayList.TimeInterval.verify(message.timeInterval);
                        if (error)
                            return "timeInterval." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a GetDeliveryStatDayList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.courier.GetDeliveryStatDayList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.courier.GetDeliveryStatDayList} GetDeliveryStatDayList
             */
            GetDeliveryStatDayList.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.courier.GetDeliveryStatDayList)
                    return object;
                var message = new $root.fastcity.courier.GetDeliveryStatDayList();
                if (object.timeInterval != null) {
                    if (typeof object.timeInterval !== "object")
                        throw TypeError(".fastcity.courier.GetDeliveryStatDayList.timeInterval: object expected");
                    message.timeInterval = $root.fastcity.courier.GetDeliveryStatDayList.TimeInterval.fromObject(object.timeInterval);
                }
                return message;
            };

            /**
             * Creates a plain object from a GetDeliveryStatDayList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.courier.GetDeliveryStatDayList
             * @static
             * @param {fastcity.courier.GetDeliveryStatDayList} message GetDeliveryStatDayList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetDeliveryStatDayList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (message.timeInterval != null && message.hasOwnProperty("timeInterval")) {
                    object.timeInterval = $root.fastcity.courier.GetDeliveryStatDayList.TimeInterval.toObject(message.timeInterval, options);
                    if (options.oneofs)
                        object.type = "timeInterval";
                }
                return object;
            };

            /**
             * Converts this GetDeliveryStatDayList to JSON.
             * @function toJSON
             * @memberof fastcity.courier.GetDeliveryStatDayList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetDeliveryStatDayList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            GetDeliveryStatDayList.TimeInterval = (function() {

                /**
                 * Properties of a TimeInterval.
                 * @memberof fastcity.courier.GetDeliveryStatDayList
                 * @interface ITimeInterval
                 * @property {number|Long|null} [fromTime] TimeInterval fromTime
                 * @property {number|Long|null} [toTime] TimeInterval toTime
                 * @property {number|null} [offset] TimeInterval offset
                 * @property {number|null} [limit] TimeInterval limit
                 * @property {fastcity.common.SortOrder.Order|null} [sortOrder] TimeInterval sortOrder
                 */

                /**
                 * Constructs a new TimeInterval.
                 * @memberof fastcity.courier.GetDeliveryStatDayList
                 * @classdesc Represents a TimeInterval.
                 * @implements ITimeInterval
                 * @constructor
                 * @param {fastcity.courier.GetDeliveryStatDayList.ITimeInterval=} [properties] Properties to set
                 */
                function TimeInterval(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * TimeInterval fromTime.
                 * @member {number|Long} fromTime
                 * @memberof fastcity.courier.GetDeliveryStatDayList.TimeInterval
                 * @instance
                 */
                TimeInterval.prototype.fromTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * TimeInterval toTime.
                 * @member {number|Long} toTime
                 * @memberof fastcity.courier.GetDeliveryStatDayList.TimeInterval
                 * @instance
                 */
                TimeInterval.prototype.toTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * TimeInterval offset.
                 * @member {number} offset
                 * @memberof fastcity.courier.GetDeliveryStatDayList.TimeInterval
                 * @instance
                 */
                TimeInterval.prototype.offset = 0;

                /**
                 * TimeInterval limit.
                 * @member {number} limit
                 * @memberof fastcity.courier.GetDeliveryStatDayList.TimeInterval
                 * @instance
                 */
                TimeInterval.prototype.limit = 0;

                /**
                 * TimeInterval sortOrder.
                 * @member {fastcity.common.SortOrder.Order} sortOrder
                 * @memberof fastcity.courier.GetDeliveryStatDayList.TimeInterval
                 * @instance
                 */
                TimeInterval.prototype.sortOrder = 0;

                /**
                 * Creates a new TimeInterval instance using the specified properties.
                 * @function create
                 * @memberof fastcity.courier.GetDeliveryStatDayList.TimeInterval
                 * @static
                 * @param {fastcity.courier.GetDeliveryStatDayList.ITimeInterval=} [properties] Properties to set
                 * @returns {fastcity.courier.GetDeliveryStatDayList.TimeInterval} TimeInterval instance
                 */
                TimeInterval.create = function create(properties) {
                    return new TimeInterval(properties);
                };

                /**
                 * Encodes the specified TimeInterval message. Does not implicitly {@link fastcity.courier.GetDeliveryStatDayList.TimeInterval.verify|verify} messages.
                 * @function encode
                 * @memberof fastcity.courier.GetDeliveryStatDayList.TimeInterval
                 * @static
                 * @param {fastcity.courier.GetDeliveryStatDayList.ITimeInterval} message TimeInterval message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                TimeInterval.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.fromTime != null && Object.hasOwnProperty.call(message, "fromTime"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int64(message.fromTime);
                    if (message.toTime != null && Object.hasOwnProperty.call(message, "toTime"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int64(message.toTime);
                    if (message.offset != null && Object.hasOwnProperty.call(message, "offset"))
                        writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.offset);
                    if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                        writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.limit);
                    if (message.sortOrder != null && Object.hasOwnProperty.call(message, "sortOrder"))
                        writer.uint32(/* id 5, wireType 0 =*/40).int32(message.sortOrder);
                    return writer;
                };

                /**
                 * Decodes a TimeInterval message from the specified reader or buffer.
                 * @function decode
                 * @memberof fastcity.courier.GetDeliveryStatDayList.TimeInterval
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {fastcity.courier.GetDeliveryStatDayList.TimeInterval} TimeInterval
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                TimeInterval.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.courier.GetDeliveryStatDayList.TimeInterval();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.fromTime = reader.int64();
                            break;
                        case 2:
                            message.toTime = reader.int64();
                            break;
                        case 3:
                            message.offset = reader.uint32();
                            break;
                        case 4:
                            message.limit = reader.uint32();
                            break;
                        case 5:
                            message.sortOrder = reader.int32();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Verifies a TimeInterval message.
                 * @function verify
                 * @memberof fastcity.courier.GetDeliveryStatDayList.TimeInterval
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                TimeInterval.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.fromTime != null && message.hasOwnProperty("fromTime"))
                        if (!$util.isInteger(message.fromTime) && !(message.fromTime && $util.isInteger(message.fromTime.low) && $util.isInteger(message.fromTime.high)))
                            return "fromTime: integer|Long expected";
                    if (message.toTime != null && message.hasOwnProperty("toTime"))
                        if (!$util.isInteger(message.toTime) && !(message.toTime && $util.isInteger(message.toTime.low) && $util.isInteger(message.toTime.high)))
                            return "toTime: integer|Long expected";
                    if (message.offset != null && message.hasOwnProperty("offset"))
                        if (!$util.isInteger(message.offset))
                            return "offset: integer expected";
                    if (message.limit != null && message.hasOwnProperty("limit"))
                        if (!$util.isInteger(message.limit))
                            return "limit: integer expected";
                    if (message.sortOrder != null && message.hasOwnProperty("sortOrder"))
                        switch (message.sortOrder) {
                        default:
                            return "sortOrder: enum value expected";
                        case 0:
                        case 1:
                            break;
                        }
                    return null;
                };

                /**
                 * Creates a TimeInterval message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof fastcity.courier.GetDeliveryStatDayList.TimeInterval
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {fastcity.courier.GetDeliveryStatDayList.TimeInterval} TimeInterval
                 */
                TimeInterval.fromObject = function fromObject(object) {
                    if (object instanceof $root.fastcity.courier.GetDeliveryStatDayList.TimeInterval)
                        return object;
                    var message = new $root.fastcity.courier.GetDeliveryStatDayList.TimeInterval();
                    if (object.fromTime != null)
                        if ($util.Long)
                            (message.fromTime = $util.Long.fromValue(object.fromTime)).unsigned = false;
                        else if (typeof object.fromTime === "string")
                            message.fromTime = parseInt(object.fromTime, 10);
                        else if (typeof object.fromTime === "number")
                            message.fromTime = object.fromTime;
                        else if (typeof object.fromTime === "object")
                            message.fromTime = new $util.LongBits(object.fromTime.low >>> 0, object.fromTime.high >>> 0).toNumber();
                    if (object.toTime != null)
                        if ($util.Long)
                            (message.toTime = $util.Long.fromValue(object.toTime)).unsigned = false;
                        else if (typeof object.toTime === "string")
                            message.toTime = parseInt(object.toTime, 10);
                        else if (typeof object.toTime === "number")
                            message.toTime = object.toTime;
                        else if (typeof object.toTime === "object")
                            message.toTime = new $util.LongBits(object.toTime.low >>> 0, object.toTime.high >>> 0).toNumber();
                    if (object.offset != null)
                        message.offset = object.offset >>> 0;
                    if (object.limit != null)
                        message.limit = object.limit >>> 0;
                    switch (object.sortOrder) {
                    case "ASC":
                    case 0:
                        message.sortOrder = 0;
                        break;
                    case "DESC":
                    case 1:
                        message.sortOrder = 1;
                        break;
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a TimeInterval message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof fastcity.courier.GetDeliveryStatDayList.TimeInterval
                 * @static
                 * @param {fastcity.courier.GetDeliveryStatDayList.TimeInterval} message TimeInterval
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                TimeInterval.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.fromTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.fromTime = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.toTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.toTime = options.longs === String ? "0" : 0;
                        object.offset = 0;
                        object.limit = 0;
                        object.sortOrder = options.enums === String ? "ASC" : 0;
                    }
                    if (message.fromTime != null && message.hasOwnProperty("fromTime"))
                        if (typeof message.fromTime === "number")
                            object.fromTime = options.longs === String ? String(message.fromTime) : message.fromTime;
                        else
                            object.fromTime = options.longs === String ? $util.Long.prototype.toString.call(message.fromTime) : options.longs === Number ? new $util.LongBits(message.fromTime.low >>> 0, message.fromTime.high >>> 0).toNumber() : message.fromTime;
                    if (message.toTime != null && message.hasOwnProperty("toTime"))
                        if (typeof message.toTime === "number")
                            object.toTime = options.longs === String ? String(message.toTime) : message.toTime;
                        else
                            object.toTime = options.longs === String ? $util.Long.prototype.toString.call(message.toTime) : options.longs === Number ? new $util.LongBits(message.toTime.low >>> 0, message.toTime.high >>> 0).toNumber() : message.toTime;
                    if (message.offset != null && message.hasOwnProperty("offset"))
                        object.offset = message.offset;
                    if (message.limit != null && message.hasOwnProperty("limit"))
                        object.limit = message.limit;
                    if (message.sortOrder != null && message.hasOwnProperty("sortOrder"))
                        object.sortOrder = options.enums === String ? $root.fastcity.common.SortOrder.Order[message.sortOrder] : message.sortOrder;
                    return object;
                };

                /**
                 * Converts this TimeInterval to JSON.
                 * @function toJSON
                 * @memberof fastcity.courier.GetDeliveryStatDayList.TimeInterval
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                TimeInterval.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return TimeInterval;
            })();

            return GetDeliveryStatDayList;
        })();

        courier.GetCurrentDeliveryStat = (function() {

            /**
             * Properties of a GetCurrentDeliveryStat.
             * @memberof fastcity.courier
             * @interface IGetCurrentDeliveryStat
             */

            /**
             * Constructs a new GetCurrentDeliveryStat.
             * @memberof fastcity.courier
             * @classdesc Represents a GetCurrentDeliveryStat.
             * @implements IGetCurrentDeliveryStat
             * @constructor
             * @param {fastcity.courier.IGetCurrentDeliveryStat=} [properties] Properties to set
             */
            function GetCurrentDeliveryStat(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new GetCurrentDeliveryStat instance using the specified properties.
             * @function create
             * @memberof fastcity.courier.GetCurrentDeliveryStat
             * @static
             * @param {fastcity.courier.IGetCurrentDeliveryStat=} [properties] Properties to set
             * @returns {fastcity.courier.GetCurrentDeliveryStat} GetCurrentDeliveryStat instance
             */
            GetCurrentDeliveryStat.create = function create(properties) {
                return new GetCurrentDeliveryStat(properties);
            };

            /**
             * Encodes the specified GetCurrentDeliveryStat message. Does not implicitly {@link fastcity.courier.GetCurrentDeliveryStat.verify|verify} messages.
             * @function encode
             * @memberof fastcity.courier.GetCurrentDeliveryStat
             * @static
             * @param {fastcity.courier.IGetCurrentDeliveryStat} message GetCurrentDeliveryStat message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetCurrentDeliveryStat.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Decodes a GetCurrentDeliveryStat message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.courier.GetCurrentDeliveryStat
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.courier.GetCurrentDeliveryStat} GetCurrentDeliveryStat
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetCurrentDeliveryStat.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.courier.GetCurrentDeliveryStat();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a GetCurrentDeliveryStat message.
             * @function verify
             * @memberof fastcity.courier.GetCurrentDeliveryStat
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetCurrentDeliveryStat.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a GetCurrentDeliveryStat message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.courier.GetCurrentDeliveryStat
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.courier.GetCurrentDeliveryStat} GetCurrentDeliveryStat
             */
            GetCurrentDeliveryStat.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.courier.GetCurrentDeliveryStat)
                    return object;
                return new $root.fastcity.courier.GetCurrentDeliveryStat();
            };

            /**
             * Creates a plain object from a GetCurrentDeliveryStat message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.courier.GetCurrentDeliveryStat
             * @static
             * @param {fastcity.courier.GetCurrentDeliveryStat} message GetCurrentDeliveryStat
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetCurrentDeliveryStat.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this GetCurrentDeliveryStat to JSON.
             * @function toJSON
             * @memberof fastcity.courier.GetCurrentDeliveryStat
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetCurrentDeliveryStat.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return GetCurrentDeliveryStat;
        })();

        courier.Heartbeat = (function() {

            /**
             * Properties of a Heartbeat.
             * @memberof fastcity.courier
             * @interface IHeartbeat
             */

            /**
             * Constructs a new Heartbeat.
             * @memberof fastcity.courier
             * @classdesc Represents a Heartbeat.
             * @implements IHeartbeat
             * @constructor
             * @param {fastcity.courier.IHeartbeat=} [properties] Properties to set
             */
            function Heartbeat(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new Heartbeat instance using the specified properties.
             * @function create
             * @memberof fastcity.courier.Heartbeat
             * @static
             * @param {fastcity.courier.IHeartbeat=} [properties] Properties to set
             * @returns {fastcity.courier.Heartbeat} Heartbeat instance
             */
            Heartbeat.create = function create(properties) {
                return new Heartbeat(properties);
            };

            /**
             * Encodes the specified Heartbeat message. Does not implicitly {@link fastcity.courier.Heartbeat.verify|verify} messages.
             * @function encode
             * @memberof fastcity.courier.Heartbeat
             * @static
             * @param {fastcity.courier.IHeartbeat} message Heartbeat message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Heartbeat.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Decodes a Heartbeat message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.courier.Heartbeat
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.courier.Heartbeat} Heartbeat
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Heartbeat.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.courier.Heartbeat();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a Heartbeat message.
             * @function verify
             * @memberof fastcity.courier.Heartbeat
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Heartbeat.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a Heartbeat message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.courier.Heartbeat
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.courier.Heartbeat} Heartbeat
             */
            Heartbeat.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.courier.Heartbeat)
                    return object;
                return new $root.fastcity.courier.Heartbeat();
            };

            /**
             * Creates a plain object from a Heartbeat message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.courier.Heartbeat
             * @static
             * @param {fastcity.courier.Heartbeat} message Heartbeat
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Heartbeat.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this Heartbeat to JSON.
             * @function toJSON
             * @memberof fastcity.courier.Heartbeat
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Heartbeat.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Heartbeat;
        })();

        courier.Location = (function() {

            /**
             * Properties of a Location.
             * @memberof fastcity.courier
             * @interface ILocation
             * @property {number|null} [latitude] Location latitude
             * @property {number|null} [longitude] Location longitude
             * @property {number|Long|null} [timestamp] Location timestamp
             * @property {number|null} [bearing] Location bearing
             * @property {number|null} [altitude] Location altitude
             * @property {number|null} [speed] Location speed
             * @property {number|Long|null} [orderId] Location orderId
             */

            /**
             * Constructs a new Location.
             * @memberof fastcity.courier
             * @classdesc Represents a Location.
             * @implements ILocation
             * @constructor
             * @param {fastcity.courier.ILocation=} [properties] Properties to set
             */
            function Location(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Location latitude.
             * @member {number} latitude
             * @memberof fastcity.courier.Location
             * @instance
             */
            Location.prototype.latitude = 0;

            /**
             * Location longitude.
             * @member {number} longitude
             * @memberof fastcity.courier.Location
             * @instance
             */
            Location.prototype.longitude = 0;

            /**
             * Location timestamp.
             * @member {number|Long} timestamp
             * @memberof fastcity.courier.Location
             * @instance
             */
            Location.prototype.timestamp = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Location bearing.
             * @member {number} bearing
             * @memberof fastcity.courier.Location
             * @instance
             */
            Location.prototype.bearing = 0;

            /**
             * Location altitude.
             * @member {number} altitude
             * @memberof fastcity.courier.Location
             * @instance
             */
            Location.prototype.altitude = 0;

            /**
             * Location speed.
             * @member {number} speed
             * @memberof fastcity.courier.Location
             * @instance
             */
            Location.prototype.speed = 0;

            /**
             * Location orderId.
             * @member {number|Long} orderId
             * @memberof fastcity.courier.Location
             * @instance
             */
            Location.prototype.orderId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Creates a new Location instance using the specified properties.
             * @function create
             * @memberof fastcity.courier.Location
             * @static
             * @param {fastcity.courier.ILocation=} [properties] Properties to set
             * @returns {fastcity.courier.Location} Location instance
             */
            Location.create = function create(properties) {
                return new Location(properties);
            };

            /**
             * Encodes the specified Location message. Does not implicitly {@link fastcity.courier.Location.verify|verify} messages.
             * @function encode
             * @memberof fastcity.courier.Location
             * @static
             * @param {fastcity.courier.ILocation} message Location message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Location.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.latitude != null && Object.hasOwnProperty.call(message, "latitude"))
                    writer.uint32(/* id 1, wireType 1 =*/9).double(message.latitude);
                if (message.longitude != null && Object.hasOwnProperty.call(message, "longitude"))
                    writer.uint32(/* id 2, wireType 1 =*/17).double(message.longitude);
                if (message.timestamp != null && Object.hasOwnProperty.call(message, "timestamp"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int64(message.timestamp);
                if (message.bearing != null && Object.hasOwnProperty.call(message, "bearing"))
                    writer.uint32(/* id 4, wireType 1 =*/33).double(message.bearing);
                if (message.altitude != null && Object.hasOwnProperty.call(message, "altitude"))
                    writer.uint32(/* id 5, wireType 1 =*/41).double(message.altitude);
                if (message.speed != null && Object.hasOwnProperty.call(message, "speed"))
                    writer.uint32(/* id 6, wireType 1 =*/49).double(message.speed);
                if (message.orderId != null && Object.hasOwnProperty.call(message, "orderId"))
                    writer.uint32(/* id 7, wireType 0 =*/56).int64(message.orderId);
                return writer;
            };

            /**
             * Decodes a Location message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.courier.Location
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.courier.Location} Location
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Location.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.courier.Location();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.latitude = reader.double();
                        break;
                    case 2:
                        message.longitude = reader.double();
                        break;
                    case 3:
                        message.timestamp = reader.int64();
                        break;
                    case 4:
                        message.bearing = reader.double();
                        break;
                    case 5:
                        message.altitude = reader.double();
                        break;
                    case 6:
                        message.speed = reader.double();
                        break;
                    case 7:
                        message.orderId = reader.int64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a Location message.
             * @function verify
             * @memberof fastcity.courier.Location
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Location.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.latitude != null && message.hasOwnProperty("latitude"))
                    if (typeof message.latitude !== "number")
                        return "latitude: number expected";
                if (message.longitude != null && message.hasOwnProperty("longitude"))
                    if (typeof message.longitude !== "number")
                        return "longitude: number expected";
                if (message.timestamp != null && message.hasOwnProperty("timestamp"))
                    if (!$util.isInteger(message.timestamp) && !(message.timestamp && $util.isInteger(message.timestamp.low) && $util.isInteger(message.timestamp.high)))
                        return "timestamp: integer|Long expected";
                if (message.bearing != null && message.hasOwnProperty("bearing"))
                    if (typeof message.bearing !== "number")
                        return "bearing: number expected";
                if (message.altitude != null && message.hasOwnProperty("altitude"))
                    if (typeof message.altitude !== "number")
                        return "altitude: number expected";
                if (message.speed != null && message.hasOwnProperty("speed"))
                    if (typeof message.speed !== "number")
                        return "speed: number expected";
                if (message.orderId != null && message.hasOwnProperty("orderId"))
                    if (!$util.isInteger(message.orderId) && !(message.orderId && $util.isInteger(message.orderId.low) && $util.isInteger(message.orderId.high)))
                        return "orderId: integer|Long expected";
                return null;
            };

            /**
             * Creates a Location message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.courier.Location
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.courier.Location} Location
             */
            Location.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.courier.Location)
                    return object;
                var message = new $root.fastcity.courier.Location();
                if (object.latitude != null)
                    message.latitude = Number(object.latitude);
                if (object.longitude != null)
                    message.longitude = Number(object.longitude);
                if (object.timestamp != null)
                    if ($util.Long)
                        (message.timestamp = $util.Long.fromValue(object.timestamp)).unsigned = false;
                    else if (typeof object.timestamp === "string")
                        message.timestamp = parseInt(object.timestamp, 10);
                    else if (typeof object.timestamp === "number")
                        message.timestamp = object.timestamp;
                    else if (typeof object.timestamp === "object")
                        message.timestamp = new $util.LongBits(object.timestamp.low >>> 0, object.timestamp.high >>> 0).toNumber();
                if (object.bearing != null)
                    message.bearing = Number(object.bearing);
                if (object.altitude != null)
                    message.altitude = Number(object.altitude);
                if (object.speed != null)
                    message.speed = Number(object.speed);
                if (object.orderId != null)
                    if ($util.Long)
                        (message.orderId = $util.Long.fromValue(object.orderId)).unsigned = false;
                    else if (typeof object.orderId === "string")
                        message.orderId = parseInt(object.orderId, 10);
                    else if (typeof object.orderId === "number")
                        message.orderId = object.orderId;
                    else if (typeof object.orderId === "object")
                        message.orderId = new $util.LongBits(object.orderId.low >>> 0, object.orderId.high >>> 0).toNumber();
                return message;
            };

            /**
             * Creates a plain object from a Location message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.courier.Location
             * @static
             * @param {fastcity.courier.Location} message Location
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Location.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.latitude = 0;
                    object.longitude = 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.timestamp = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.timestamp = options.longs === String ? "0" : 0;
                    object.bearing = 0;
                    object.altitude = 0;
                    object.speed = 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.orderId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.orderId = options.longs === String ? "0" : 0;
                }
                if (message.latitude != null && message.hasOwnProperty("latitude"))
                    object.latitude = options.json && !isFinite(message.latitude) ? String(message.latitude) : message.latitude;
                if (message.longitude != null && message.hasOwnProperty("longitude"))
                    object.longitude = options.json && !isFinite(message.longitude) ? String(message.longitude) : message.longitude;
                if (message.timestamp != null && message.hasOwnProperty("timestamp"))
                    if (typeof message.timestamp === "number")
                        object.timestamp = options.longs === String ? String(message.timestamp) : message.timestamp;
                    else
                        object.timestamp = options.longs === String ? $util.Long.prototype.toString.call(message.timestamp) : options.longs === Number ? new $util.LongBits(message.timestamp.low >>> 0, message.timestamp.high >>> 0).toNumber() : message.timestamp;
                if (message.bearing != null && message.hasOwnProperty("bearing"))
                    object.bearing = options.json && !isFinite(message.bearing) ? String(message.bearing) : message.bearing;
                if (message.altitude != null && message.hasOwnProperty("altitude"))
                    object.altitude = options.json && !isFinite(message.altitude) ? String(message.altitude) : message.altitude;
                if (message.speed != null && message.hasOwnProperty("speed"))
                    object.speed = options.json && !isFinite(message.speed) ? String(message.speed) : message.speed;
                if (message.orderId != null && message.hasOwnProperty("orderId"))
                    if (typeof message.orderId === "number")
                        object.orderId = options.longs === String ? String(message.orderId) : message.orderId;
                    else
                        object.orderId = options.longs === String ? $util.Long.prototype.toString.call(message.orderId) : options.longs === Number ? new $util.LongBits(message.orderId.low >>> 0, message.orderId.high >>> 0).toNumber() : message.orderId;
                return object;
            };

            /**
             * Converts this Location to JSON.
             * @function toJSON
             * @memberof fastcity.courier.Location
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Location.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Location;
        })();

        courier.Logon = (function() {

            /**
             * Properties of a Logon.
             * @memberof fastcity.courier
             * @interface ILogon
             * @property {string|null} [login] Logon login
             * @property {string|null} [password] Logon password
             * @property {fastcity.common.IVersion|null} [appVersion] Logon appVersion
             */

            /**
             * Constructs a new Logon.
             * @memberof fastcity.courier
             * @classdesc Represents a Logon.
             * @implements ILogon
             * @constructor
             * @param {fastcity.courier.ILogon=} [properties] Properties to set
             */
            function Logon(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Logon login.
             * @member {string} login
             * @memberof fastcity.courier.Logon
             * @instance
             */
            Logon.prototype.login = "";

            /**
             * Logon password.
             * @member {string} password
             * @memberof fastcity.courier.Logon
             * @instance
             */
            Logon.prototype.password = "";

            /**
             * Logon appVersion.
             * @member {fastcity.common.IVersion|null|undefined} appVersion
             * @memberof fastcity.courier.Logon
             * @instance
             */
            Logon.prototype.appVersion = null;

            /**
             * Creates a new Logon instance using the specified properties.
             * @function create
             * @memberof fastcity.courier.Logon
             * @static
             * @param {fastcity.courier.ILogon=} [properties] Properties to set
             * @returns {fastcity.courier.Logon} Logon instance
             */
            Logon.create = function create(properties) {
                return new Logon(properties);
            };

            /**
             * Encodes the specified Logon message. Does not implicitly {@link fastcity.courier.Logon.verify|verify} messages.
             * @function encode
             * @memberof fastcity.courier.Logon
             * @static
             * @param {fastcity.courier.ILogon} message Logon message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Logon.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.login != null && Object.hasOwnProperty.call(message, "login"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.login);
                if (message.password != null && Object.hasOwnProperty.call(message, "password"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.password);
                if (message.appVersion != null && Object.hasOwnProperty.call(message, "appVersion"))
                    $root.fastcity.common.Version.encode(message.appVersion, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a Logon message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.courier.Logon
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.courier.Logon} Logon
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Logon.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.courier.Logon();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.login = reader.string();
                        break;
                    case 2:
                        message.password = reader.string();
                        break;
                    case 3:
                        message.appVersion = $root.fastcity.common.Version.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a Logon message.
             * @function verify
             * @memberof fastcity.courier.Logon
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Logon.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.login != null && message.hasOwnProperty("login"))
                    if (!$util.isString(message.login))
                        return "login: string expected";
                if (message.password != null && message.hasOwnProperty("password"))
                    if (!$util.isString(message.password))
                        return "password: string expected";
                if (message.appVersion != null && message.hasOwnProperty("appVersion")) {
                    var error = $root.fastcity.common.Version.verify(message.appVersion);
                    if (error)
                        return "appVersion." + error;
                }
                return null;
            };

            /**
             * Creates a Logon message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.courier.Logon
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.courier.Logon} Logon
             */
            Logon.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.courier.Logon)
                    return object;
                var message = new $root.fastcity.courier.Logon();
                if (object.login != null)
                    message.login = String(object.login);
                if (object.password != null)
                    message.password = String(object.password);
                if (object.appVersion != null) {
                    if (typeof object.appVersion !== "object")
                        throw TypeError(".fastcity.courier.Logon.appVersion: object expected");
                    message.appVersion = $root.fastcity.common.Version.fromObject(object.appVersion);
                }
                return message;
            };

            /**
             * Creates a plain object from a Logon message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.courier.Logon
             * @static
             * @param {fastcity.courier.Logon} message Logon
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Logon.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.login = "";
                    object.password = "";
                    object.appVersion = null;
                }
                if (message.login != null && message.hasOwnProperty("login"))
                    object.login = message.login;
                if (message.password != null && message.hasOwnProperty("password"))
                    object.password = message.password;
                if (message.appVersion != null && message.hasOwnProperty("appVersion"))
                    object.appVersion = $root.fastcity.common.Version.toObject(message.appVersion, options);
                return object;
            };

            /**
             * Converts this Logon to JSON.
             * @function toJSON
             * @memberof fastcity.courier.Logon
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Logon.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Logon;
        })();

        courier.LogonResult = (function() {

            /**
             * Properties of a LogonResult.
             * @memberof fastcity.courier
             * @interface ILogonResult
             * @property {fastcity.courier.LogonResult.Code|null} [code] LogonResult code
             * @property {string|null} [description] LogonResult description
             */

            /**
             * Constructs a new LogonResult.
             * @memberof fastcity.courier
             * @classdesc Represents a LogonResult.
             * @implements ILogonResult
             * @constructor
             * @param {fastcity.courier.ILogonResult=} [properties] Properties to set
             */
            function LogonResult(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * LogonResult code.
             * @member {fastcity.courier.LogonResult.Code} code
             * @memberof fastcity.courier.LogonResult
             * @instance
             */
            LogonResult.prototype.code = 0;

            /**
             * LogonResult description.
             * @member {string} description
             * @memberof fastcity.courier.LogonResult
             * @instance
             */
            LogonResult.prototype.description = "";

            /**
             * Creates a new LogonResult instance using the specified properties.
             * @function create
             * @memberof fastcity.courier.LogonResult
             * @static
             * @param {fastcity.courier.ILogonResult=} [properties] Properties to set
             * @returns {fastcity.courier.LogonResult} LogonResult instance
             */
            LogonResult.create = function create(properties) {
                return new LogonResult(properties);
            };

            /**
             * Encodes the specified LogonResult message. Does not implicitly {@link fastcity.courier.LogonResult.verify|verify} messages.
             * @function encode
             * @memberof fastcity.courier.LogonResult
             * @static
             * @param {fastcity.courier.ILogonResult} message LogonResult message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            LogonResult.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.code != null && Object.hasOwnProperty.call(message, "code"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.code);
                if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.description);
                return writer;
            };

            /**
             * Decodes a LogonResult message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.courier.LogonResult
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.courier.LogonResult} LogonResult
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            LogonResult.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.courier.LogonResult();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.code = reader.int32();
                        break;
                    case 2:
                        message.description = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a LogonResult message.
             * @function verify
             * @memberof fastcity.courier.LogonResult
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            LogonResult.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.code != null && message.hasOwnProperty("code"))
                    switch (message.code) {
                    default:
                        return "code: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
                if (message.description != null && message.hasOwnProperty("description"))
                    if (!$util.isString(message.description))
                        return "description: string expected";
                return null;
            };

            /**
             * Creates a LogonResult message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.courier.LogonResult
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.courier.LogonResult} LogonResult
             */
            LogonResult.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.courier.LogonResult)
                    return object;
                var message = new $root.fastcity.courier.LogonResult();
                switch (object.code) {
                case "SUCCESS":
                case 0:
                    message.code = 0;
                    break;
                case "INCORRECT_LOGIN_PASSWORD":
                case 1:
                    message.code = 1;
                    break;
                case "ALREADY_CONNECTED":
                case 2:
                    message.code = 2;
                    break;
                case "INACTIVE":
                case 3:
                    message.code = 3;
                    break;
                }
                if (object.description != null)
                    message.description = String(object.description);
                return message;
            };

            /**
             * Creates a plain object from a LogonResult message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.courier.LogonResult
             * @static
             * @param {fastcity.courier.LogonResult} message LogonResult
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            LogonResult.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.code = options.enums === String ? "SUCCESS" : 0;
                    object.description = "";
                }
                if (message.code != null && message.hasOwnProperty("code"))
                    object.code = options.enums === String ? $root.fastcity.courier.LogonResult.Code[message.code] : message.code;
                if (message.description != null && message.hasOwnProperty("description"))
                    object.description = message.description;
                return object;
            };

            /**
             * Converts this LogonResult to JSON.
             * @function toJSON
             * @memberof fastcity.courier.LogonResult
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            LogonResult.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Code enum.
             * @name fastcity.courier.LogonResult.Code
             * @enum {number}
             * @property {number} SUCCESS=0 SUCCESS value
             * @property {number} INCORRECT_LOGIN_PASSWORD=1 INCORRECT_LOGIN_PASSWORD value
             * @property {number} ALREADY_CONNECTED=2 ALREADY_CONNECTED value
             * @property {number} INACTIVE=3 INACTIVE value
             */
            LogonResult.Code = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "SUCCESS"] = 0;
                values[valuesById[1] = "INCORRECT_LOGIN_PASSWORD"] = 1;
                values[valuesById[2] = "ALREADY_CONNECTED"] = 2;
                values[valuesById[3] = "INACTIVE"] = 3;
                return values;
            })();

            return LogonResult;
        })();

        /**
         * OrderRequestResult enum.
         * @name fastcity.courier.OrderRequestResult
         * @enum {number}
         * @property {number} ORR_NOT_FOUND=0 ORR_NOT_FOUND value
         * @property {number} ORR_ALREADY_ACCEPTED=1 ORR_ALREADY_ACCEPTED value
         * @property {number} ORR_ALREADY_FINISHED=2 ORR_ALREADY_FINISHED value
         * @property {number} ORR_ORDER_OF_ANOTHER_COURIER=3 ORR_ORDER_OF_ANOTHER_COURIER value
         * @property {number} ORR_FAILED_TO_ACCEPT_FAILED_TOO_MANY_ORDERS_ACCEPTED=4 ORR_FAILED_TO_ACCEPT_FAILED_TOO_MANY_ORDERS_ACCEPTED value
         * @property {number} ORR_FAILED_TO_ACCEPT_FAILED_TO_CHANGE_COURIER=5 ORR_FAILED_TO_ACCEPT_FAILED_TO_CHANGE_COURIER value
         * @property {number} ORR_FAILED_TO_ACCEPT_FAILED_TO_CHANGE_STATUS=6 ORR_FAILED_TO_ACCEPT_FAILED_TO_CHANGE_STATUS value
         * @property {number} ORR_FAILED_TO_FINISH_FAILED_TARGET_LOCATION_TOO_FAR=7 ORR_FAILED_TO_FINISH_FAILED_TARGET_LOCATION_TOO_FAR value
         * @property {number} ORR_FAILED_TO_FINISH_FAILED_TO_CHANGE_STATUS=8 ORR_FAILED_TO_FINISH_FAILED_TO_CHANGE_STATUS value
         * @property {number} ORR_FAILED_TO_FINISH_FAILED_TO_CLOSE_ORDER=9 ORR_FAILED_TO_FINISH_FAILED_TO_CLOSE_ORDER value
         */
        courier.OrderRequestResult = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "ORR_NOT_FOUND"] = 0;
            values[valuesById[1] = "ORR_ALREADY_ACCEPTED"] = 1;
            values[valuesById[2] = "ORR_ALREADY_FINISHED"] = 2;
            values[valuesById[3] = "ORR_ORDER_OF_ANOTHER_COURIER"] = 3;
            values[valuesById[4] = "ORR_FAILED_TO_ACCEPT_FAILED_TOO_MANY_ORDERS_ACCEPTED"] = 4;
            values[valuesById[5] = "ORR_FAILED_TO_ACCEPT_FAILED_TO_CHANGE_COURIER"] = 5;
            values[valuesById[6] = "ORR_FAILED_TO_ACCEPT_FAILED_TO_CHANGE_STATUS"] = 6;
            values[valuesById[7] = "ORR_FAILED_TO_FINISH_FAILED_TARGET_LOCATION_TOO_FAR"] = 7;
            values[valuesById[8] = "ORR_FAILED_TO_FINISH_FAILED_TO_CHANGE_STATUS"] = 8;
            values[valuesById[9] = "ORR_FAILED_TO_FINISH_FAILED_TO_CLOSE_ORDER"] = 9;
            return values;
        })();

        courier.OrderItem = (function() {

            /**
             * Properties of an OrderItem.
             * @memberof fastcity.courier
             * @interface IOrderItem
             * @property {number|Long|null} [orderItemId] OrderItem orderItemId
             * @property {string|null} [externalOrderItemId] OrderItem externalOrderItemId
             * @property {string|null} [name] OrderItem name
             * @property {string|null} [description] OrderItem description
             * @property {string|null} [price] OrderItem price
             * @property {string|null} [priceWithoutDiscounts] OrderItem priceWithoutDiscounts
             * @property {number|null} [quantity] OrderItem quantity
             * @property {fastcity.common.OrderItem.ProductType|null} [productType] OrderItem productType
             */

            /**
             * Constructs a new OrderItem.
             * @memberof fastcity.courier
             * @classdesc Represents an OrderItem.
             * @implements IOrderItem
             * @constructor
             * @param {fastcity.courier.IOrderItem=} [properties] Properties to set
             */
            function OrderItem(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * OrderItem orderItemId.
             * @member {number|Long} orderItemId
             * @memberof fastcity.courier.OrderItem
             * @instance
             */
            OrderItem.prototype.orderItemId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * OrderItem externalOrderItemId.
             * @member {string} externalOrderItemId
             * @memberof fastcity.courier.OrderItem
             * @instance
             */
            OrderItem.prototype.externalOrderItemId = "";

            /**
             * OrderItem name.
             * @member {string} name
             * @memberof fastcity.courier.OrderItem
             * @instance
             */
            OrderItem.prototype.name = "";

            /**
             * OrderItem description.
             * @member {string} description
             * @memberof fastcity.courier.OrderItem
             * @instance
             */
            OrderItem.prototype.description = "";

            /**
             * OrderItem price.
             * @member {string} price
             * @memberof fastcity.courier.OrderItem
             * @instance
             */
            OrderItem.prototype.price = "";

            /**
             * OrderItem priceWithoutDiscounts.
             * @member {string} priceWithoutDiscounts
             * @memberof fastcity.courier.OrderItem
             * @instance
             */
            OrderItem.prototype.priceWithoutDiscounts = "";

            /**
             * OrderItem quantity.
             * @member {number} quantity
             * @memberof fastcity.courier.OrderItem
             * @instance
             */
            OrderItem.prototype.quantity = 0;

            /**
             * OrderItem productType.
             * @member {fastcity.common.OrderItem.ProductType} productType
             * @memberof fastcity.courier.OrderItem
             * @instance
             */
            OrderItem.prototype.productType = 0;

            /**
             * Creates a new OrderItem instance using the specified properties.
             * @function create
             * @memberof fastcity.courier.OrderItem
             * @static
             * @param {fastcity.courier.IOrderItem=} [properties] Properties to set
             * @returns {fastcity.courier.OrderItem} OrderItem instance
             */
            OrderItem.create = function create(properties) {
                return new OrderItem(properties);
            };

            /**
             * Encodes the specified OrderItem message. Does not implicitly {@link fastcity.courier.OrderItem.verify|verify} messages.
             * @function encode
             * @memberof fastcity.courier.OrderItem
             * @static
             * @param {fastcity.courier.IOrderItem} message OrderItem message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            OrderItem.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.externalOrderItemId != null && Object.hasOwnProperty.call(message, "externalOrderItemId"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.externalOrderItemId);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.description);
                if (message.price != null && Object.hasOwnProperty.call(message, "price"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.price);
                if (message.priceWithoutDiscounts != null && Object.hasOwnProperty.call(message, "priceWithoutDiscounts"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.priceWithoutDiscounts);
                if (message.quantity != null && Object.hasOwnProperty.call(message, "quantity"))
                    writer.uint32(/* id 6, wireType 0 =*/48).int32(message.quantity);
                if (message.productType != null && Object.hasOwnProperty.call(message, "productType"))
                    writer.uint32(/* id 7, wireType 0 =*/56).int32(message.productType);
                if (message.orderItemId != null && Object.hasOwnProperty.call(message, "orderItemId"))
                    writer.uint32(/* id 8, wireType 0 =*/64).int64(message.orderItemId);
                return writer;
            };

            /**
             * Decodes an OrderItem message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.courier.OrderItem
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.courier.OrderItem} OrderItem
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            OrderItem.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.courier.OrderItem();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 8:
                        message.orderItemId = reader.int64();
                        break;
                    case 1:
                        message.externalOrderItemId = reader.string();
                        break;
                    case 2:
                        message.name = reader.string();
                        break;
                    case 3:
                        message.description = reader.string();
                        break;
                    case 4:
                        message.price = reader.string();
                        break;
                    case 5:
                        message.priceWithoutDiscounts = reader.string();
                        break;
                    case 6:
                        message.quantity = reader.int32();
                        break;
                    case 7:
                        message.productType = reader.int32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an OrderItem message.
             * @function verify
             * @memberof fastcity.courier.OrderItem
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            OrderItem.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.orderItemId != null && message.hasOwnProperty("orderItemId"))
                    if (!$util.isInteger(message.orderItemId) && !(message.orderItemId && $util.isInteger(message.orderItemId.low) && $util.isInteger(message.orderItemId.high)))
                        return "orderItemId: integer|Long expected";
                if (message.externalOrderItemId != null && message.hasOwnProperty("externalOrderItemId"))
                    if (!$util.isString(message.externalOrderItemId))
                        return "externalOrderItemId: string expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.description != null && message.hasOwnProperty("description"))
                    if (!$util.isString(message.description))
                        return "description: string expected";
                if (message.price != null && message.hasOwnProperty("price"))
                    if (!$util.isString(message.price))
                        return "price: string expected";
                if (message.priceWithoutDiscounts != null && message.hasOwnProperty("priceWithoutDiscounts"))
                    if (!$util.isString(message.priceWithoutDiscounts))
                        return "priceWithoutDiscounts: string expected";
                if (message.quantity != null && message.hasOwnProperty("quantity"))
                    if (!$util.isInteger(message.quantity))
                        return "quantity: integer expected";
                if (message.productType != null && message.hasOwnProperty("productType"))
                    switch (message.productType) {
                    default:
                        return "productType: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                    case 9:
                    case 10:
                    case 11:
                        break;
                    }
                return null;
            };

            /**
             * Creates an OrderItem message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.courier.OrderItem
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.courier.OrderItem} OrderItem
             */
            OrderItem.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.courier.OrderItem)
                    return object;
                var message = new $root.fastcity.courier.OrderItem();
                if (object.orderItemId != null)
                    if ($util.Long)
                        (message.orderItemId = $util.Long.fromValue(object.orderItemId)).unsigned = false;
                    else if (typeof object.orderItemId === "string")
                        message.orderItemId = parseInt(object.orderItemId, 10);
                    else if (typeof object.orderItemId === "number")
                        message.orderItemId = object.orderItemId;
                    else if (typeof object.orderItemId === "object")
                        message.orderItemId = new $util.LongBits(object.orderItemId.low >>> 0, object.orderItemId.high >>> 0).toNumber();
                if (object.externalOrderItemId != null)
                    message.externalOrderItemId = String(object.externalOrderItemId);
                if (object.name != null)
                    message.name = String(object.name);
                if (object.description != null)
                    message.description = String(object.description);
                if (object.price != null)
                    message.price = String(object.price);
                if (object.priceWithoutDiscounts != null)
                    message.priceWithoutDiscounts = String(object.priceWithoutDiscounts);
                if (object.quantity != null)
                    message.quantity = object.quantity | 0;
                switch (object.productType) {
                case "T_UNKNOWN":
                case 0:
                    message.productType = 0;
                    break;
                case "T_DESSERT":
                case 1:
                    message.productType = 1;
                    break;
                case "T_SNACK":
                case 2:
                    message.productType = 2;
                    break;
                case "T_DRINK":
                case 3:
                    message.productType = 3;
                    break;
                case "T_BEER":
                case 4:
                    message.productType = 4;
                    break;
                case "T_PIZZA":
                case 5:
                    message.productType = 5;
                    break;
                case "T_GIFT":
                case 6:
                    message.productType = 6;
                    break;
                case "T_SALAD":
                case 7:
                    message.productType = 7;
                    break;
                case "T_BREAD":
                case 8:
                    message.productType = 8;
                    break;
                case "T_SAUCE":
                case 9:
                    message.productType = 9;
                    break;
                case "T_COMPOUND":
                case 10:
                    message.productType = 10;
                    break;
                case "T_SERVICE":
                case 11:
                    message.productType = 11;
                    break;
                }
                return message;
            };

            /**
             * Creates a plain object from an OrderItem message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.courier.OrderItem
             * @static
             * @param {fastcity.courier.OrderItem} message OrderItem
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            OrderItem.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.externalOrderItemId = "";
                    object.name = "";
                    object.description = "";
                    object.price = "";
                    object.priceWithoutDiscounts = "";
                    object.quantity = 0;
                    object.productType = options.enums === String ? "T_UNKNOWN" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.orderItemId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.orderItemId = options.longs === String ? "0" : 0;
                }
                if (message.externalOrderItemId != null && message.hasOwnProperty("externalOrderItemId"))
                    object.externalOrderItemId = message.externalOrderItemId;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.description != null && message.hasOwnProperty("description"))
                    object.description = message.description;
                if (message.price != null && message.hasOwnProperty("price"))
                    object.price = message.price;
                if (message.priceWithoutDiscounts != null && message.hasOwnProperty("priceWithoutDiscounts"))
                    object.priceWithoutDiscounts = message.priceWithoutDiscounts;
                if (message.quantity != null && message.hasOwnProperty("quantity"))
                    object.quantity = message.quantity;
                if (message.productType != null && message.hasOwnProperty("productType"))
                    object.productType = options.enums === String ? $root.fastcity.common.OrderItem.ProductType[message.productType] : message.productType;
                if (message.orderItemId != null && message.hasOwnProperty("orderItemId"))
                    if (typeof message.orderItemId === "number")
                        object.orderItemId = options.longs === String ? String(message.orderItemId) : message.orderItemId;
                    else
                        object.orderItemId = options.longs === String ? $util.Long.prototype.toString.call(message.orderItemId) : options.longs === Number ? new $util.LongBits(message.orderItemId.low >>> 0, message.orderItemId.high >>> 0).toNumber() : message.orderItemId;
                return object;
            };

            /**
             * Converts this OrderItem to JSON.
             * @function toJSON
             * @memberof fastcity.courier.OrderItem
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            OrderItem.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return OrderItem;
        })();

        courier.Order = (function() {

            /**
             * Properties of an Order.
             * @memberof fastcity.courier
             * @interface IOrder
             * @property {number|Long|null} [orderId] Order orderId
             * @property {string|null} [externalOrderId] Order externalOrderId
             * @property {string|null} [orderIdToPrint] Order orderIdToPrint
             * @property {number|Long|null} [subCompanyId] Order subCompanyId
             * @property {number|Long|null} [timestampOpen] Order timestampOpen
             * @property {fastcity.common.Order.Status|null} [status] Order status
             * @property {string|null} [price] Order price
             * @property {fastcity.common.Order.PaymentType|null} [paymentType] Order paymentType
             * @property {string|null} [clientPhone] Order clientPhone
             * @property {string|null} [clientName] Order clientName
             * @property {string|null} [clientAddress] Order clientAddress
             * @property {Array.<fastcity.courier.IOrderItem>|null} [orderItem] Order orderItem
             * @property {boolean|null} [isFinished] Order isFinished
             * @property {string|null} [comments] Order comments
             * @property {number|Long|null} [timestampClose] Order timestampClose
             * @property {number|Long|null} [timestampOpenWorkingDay] Order timestampOpenWorkingDay
             * @property {fastcity.common.ICoordinate|null} [coordinate] Order coordinate
             * @property {string|null} [currencySymbol] Order currencySymbol
             * @property {number|Long|null} [timestampCancel] Order timestampCancel
             * @property {string|null} [clientRoom] Order clientRoom
             * @property {string|null} [courierId] Order courierId
             * @property {number|Long|null} [timestampAccept] Order timestampAccept
             * @property {number|Long|null} [timestampUpdated] Order timestampUpdated
             * @property {number|Long|null} [timestampDeliveryBefore] Order timestampDeliveryBefore
             * @property {Array.<fastcity.common.Order.PaymentType>|null} [paymentTypes] Order paymentTypes
             */

            /**
             * Constructs a new Order.
             * @memberof fastcity.courier
             * @classdesc Represents an Order.
             * @implements IOrder
             * @constructor
             * @param {fastcity.courier.IOrder=} [properties] Properties to set
             */
            function Order(properties) {
                this.orderItem = [];
                this.paymentTypes = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Order orderId.
             * @member {number|Long} orderId
             * @memberof fastcity.courier.Order
             * @instance
             */
            Order.prototype.orderId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Order externalOrderId.
             * @member {string} externalOrderId
             * @memberof fastcity.courier.Order
             * @instance
             */
            Order.prototype.externalOrderId = "";

            /**
             * Order orderIdToPrint.
             * @member {string} orderIdToPrint
             * @memberof fastcity.courier.Order
             * @instance
             */
            Order.prototype.orderIdToPrint = "";

            /**
             * Order subCompanyId.
             * @member {number|Long} subCompanyId
             * @memberof fastcity.courier.Order
             * @instance
             */
            Order.prototype.subCompanyId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Order timestampOpen.
             * @member {number|Long} timestampOpen
             * @memberof fastcity.courier.Order
             * @instance
             */
            Order.prototype.timestampOpen = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Order status.
             * @member {fastcity.common.Order.Status} status
             * @memberof fastcity.courier.Order
             * @instance
             */
            Order.prototype.status = 0;

            /**
             * Order price.
             * @member {string} price
             * @memberof fastcity.courier.Order
             * @instance
             */
            Order.prototype.price = "";

            /**
             * Order paymentType.
             * @member {fastcity.common.Order.PaymentType} paymentType
             * @memberof fastcity.courier.Order
             * @instance
             */
            Order.prototype.paymentType = 0;

            /**
             * Order clientPhone.
             * @member {string} clientPhone
             * @memberof fastcity.courier.Order
             * @instance
             */
            Order.prototype.clientPhone = "";

            /**
             * Order clientName.
             * @member {string} clientName
             * @memberof fastcity.courier.Order
             * @instance
             */
            Order.prototype.clientName = "";

            /**
             * Order clientAddress.
             * @member {string} clientAddress
             * @memberof fastcity.courier.Order
             * @instance
             */
            Order.prototype.clientAddress = "";

            /**
             * Order orderItem.
             * @member {Array.<fastcity.courier.IOrderItem>} orderItem
             * @memberof fastcity.courier.Order
             * @instance
             */
            Order.prototype.orderItem = $util.emptyArray;

            /**
             * Order isFinished.
             * @member {boolean} isFinished
             * @memberof fastcity.courier.Order
             * @instance
             */
            Order.prototype.isFinished = false;

            /**
             * Order comments.
             * @member {string} comments
             * @memberof fastcity.courier.Order
             * @instance
             */
            Order.prototype.comments = "";

            /**
             * Order timestampClose.
             * @member {number|Long} timestampClose
             * @memberof fastcity.courier.Order
             * @instance
             */
            Order.prototype.timestampClose = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Order timestampOpenWorkingDay.
             * @member {number|Long} timestampOpenWorkingDay
             * @memberof fastcity.courier.Order
             * @instance
             */
            Order.prototype.timestampOpenWorkingDay = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Order coordinate.
             * @member {fastcity.common.ICoordinate|null|undefined} coordinate
             * @memberof fastcity.courier.Order
             * @instance
             */
            Order.prototype.coordinate = null;

            /**
             * Order currencySymbol.
             * @member {string} currencySymbol
             * @memberof fastcity.courier.Order
             * @instance
             */
            Order.prototype.currencySymbol = "";

            /**
             * Order timestampCancel.
             * @member {number|Long} timestampCancel
             * @memberof fastcity.courier.Order
             * @instance
             */
            Order.prototype.timestampCancel = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Order clientRoom.
             * @member {string} clientRoom
             * @memberof fastcity.courier.Order
             * @instance
             */
            Order.prototype.clientRoom = "";

            /**
             * Order courierId.
             * @member {string} courierId
             * @memberof fastcity.courier.Order
             * @instance
             */
            Order.prototype.courierId = "";

            /**
             * Order timestampAccept.
             * @member {number|Long} timestampAccept
             * @memberof fastcity.courier.Order
             * @instance
             */
            Order.prototype.timestampAccept = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Order timestampUpdated.
             * @member {number|Long} timestampUpdated
             * @memberof fastcity.courier.Order
             * @instance
             */
            Order.prototype.timestampUpdated = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Order timestampDeliveryBefore.
             * @member {number|Long} timestampDeliveryBefore
             * @memberof fastcity.courier.Order
             * @instance
             */
            Order.prototype.timestampDeliveryBefore = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Order paymentTypes.
             * @member {Array.<fastcity.common.Order.PaymentType>} paymentTypes
             * @memberof fastcity.courier.Order
             * @instance
             */
            Order.prototype.paymentTypes = $util.emptyArray;

            /**
             * Creates a new Order instance using the specified properties.
             * @function create
             * @memberof fastcity.courier.Order
             * @static
             * @param {fastcity.courier.IOrder=} [properties] Properties to set
             * @returns {fastcity.courier.Order} Order instance
             */
            Order.create = function create(properties) {
                return new Order(properties);
            };

            /**
             * Encodes the specified Order message. Does not implicitly {@link fastcity.courier.Order.verify|verify} messages.
             * @function encode
             * @memberof fastcity.courier.Order
             * @static
             * @param {fastcity.courier.IOrder} message Order message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Order.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.externalOrderId != null && Object.hasOwnProperty.call(message, "externalOrderId"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.externalOrderId);
                if (message.timestampOpen != null && Object.hasOwnProperty.call(message, "timestampOpen"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int64(message.timestampOpen);
                if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.status);
                if (message.price != null && Object.hasOwnProperty.call(message, "price"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.price);
                if (message.paymentType != null && Object.hasOwnProperty.call(message, "paymentType"))
                    writer.uint32(/* id 5, wireType 0 =*/40).int32(message.paymentType);
                if (message.clientPhone != null && Object.hasOwnProperty.call(message, "clientPhone"))
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.clientPhone);
                if (message.clientName != null && Object.hasOwnProperty.call(message, "clientName"))
                    writer.uint32(/* id 7, wireType 2 =*/58).string(message.clientName);
                if (message.clientAddress != null && Object.hasOwnProperty.call(message, "clientAddress"))
                    writer.uint32(/* id 8, wireType 2 =*/66).string(message.clientAddress);
                if (message.orderItem != null && message.orderItem.length)
                    for (var i = 0; i < message.orderItem.length; ++i)
                        $root.fastcity.courier.OrderItem.encode(message.orderItem[i], writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                if (message.isFinished != null && Object.hasOwnProperty.call(message, "isFinished"))
                    writer.uint32(/* id 10, wireType 0 =*/80).bool(message.isFinished);
                if (message.comments != null && Object.hasOwnProperty.call(message, "comments"))
                    writer.uint32(/* id 11, wireType 2 =*/90).string(message.comments);
                if (message.timestampClose != null && Object.hasOwnProperty.call(message, "timestampClose"))
                    writer.uint32(/* id 12, wireType 0 =*/96).int64(message.timestampClose);
                if (message.timestampOpenWorkingDay != null && Object.hasOwnProperty.call(message, "timestampOpenWorkingDay"))
                    writer.uint32(/* id 13, wireType 0 =*/104).int64(message.timestampOpenWorkingDay);
                if (message.coordinate != null && Object.hasOwnProperty.call(message, "coordinate"))
                    $root.fastcity.common.Coordinate.encode(message.coordinate, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
                if (message.currencySymbol != null && Object.hasOwnProperty.call(message, "currencySymbol"))
                    writer.uint32(/* id 15, wireType 2 =*/122).string(message.currencySymbol);
                if (message.timestampCancel != null && Object.hasOwnProperty.call(message, "timestampCancel"))
                    writer.uint32(/* id 16, wireType 0 =*/128).int64(message.timestampCancel);
                if (message.clientRoom != null && Object.hasOwnProperty.call(message, "clientRoom"))
                    writer.uint32(/* id 17, wireType 2 =*/138).string(message.clientRoom);
                if (message.courierId != null && Object.hasOwnProperty.call(message, "courierId"))
                    writer.uint32(/* id 18, wireType 2 =*/146).string(message.courierId);
                if (message.timestampAccept != null && Object.hasOwnProperty.call(message, "timestampAccept"))
                    writer.uint32(/* id 19, wireType 0 =*/152).int64(message.timestampAccept);
                if (message.timestampUpdated != null && Object.hasOwnProperty.call(message, "timestampUpdated"))
                    writer.uint32(/* id 20, wireType 0 =*/160).int64(message.timestampUpdated);
                if (message.orderIdToPrint != null && Object.hasOwnProperty.call(message, "orderIdToPrint"))
                    writer.uint32(/* id 21, wireType 2 =*/170).string(message.orderIdToPrint);
                if (message.timestampDeliveryBefore != null && Object.hasOwnProperty.call(message, "timestampDeliveryBefore"))
                    writer.uint32(/* id 22, wireType 0 =*/176).int64(message.timestampDeliveryBefore);
                if (message.orderId != null && Object.hasOwnProperty.call(message, "orderId"))
                    writer.uint32(/* id 23, wireType 0 =*/184).int64(message.orderId);
                if (message.subCompanyId != null && Object.hasOwnProperty.call(message, "subCompanyId"))
                    writer.uint32(/* id 24, wireType 0 =*/192).uint64(message.subCompanyId);
                if (message.paymentTypes != null && message.paymentTypes.length) {
                    writer.uint32(/* id 25, wireType 2 =*/202).fork();
                    for (var i = 0; i < message.paymentTypes.length; ++i)
                        writer.int32(message.paymentTypes[i]);
                    writer.ldelim();
                }
                return writer;
            };

            /**
             * Decodes an Order message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.courier.Order
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.courier.Order} Order
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Order.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.courier.Order();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 23:
                        message.orderId = reader.int64();
                        break;
                    case 1:
                        message.externalOrderId = reader.string();
                        break;
                    case 21:
                        message.orderIdToPrint = reader.string();
                        break;
                    case 24:
                        message.subCompanyId = reader.uint64();
                        break;
                    case 2:
                        message.timestampOpen = reader.int64();
                        break;
                    case 3:
                        message.status = reader.int32();
                        break;
                    case 4:
                        message.price = reader.string();
                        break;
                    case 5:
                        message.paymentType = reader.int32();
                        break;
                    case 6:
                        message.clientPhone = reader.string();
                        break;
                    case 7:
                        message.clientName = reader.string();
                        break;
                    case 8:
                        message.clientAddress = reader.string();
                        break;
                    case 9:
                        if (!(message.orderItem && message.orderItem.length))
                            message.orderItem = [];
                        message.orderItem.push($root.fastcity.courier.OrderItem.decode(reader, reader.uint32()));
                        break;
                    case 10:
                        message.isFinished = reader.bool();
                        break;
                    case 11:
                        message.comments = reader.string();
                        break;
                    case 12:
                        message.timestampClose = reader.int64();
                        break;
                    case 13:
                        message.timestampOpenWorkingDay = reader.int64();
                        break;
                    case 14:
                        message.coordinate = $root.fastcity.common.Coordinate.decode(reader, reader.uint32());
                        break;
                    case 15:
                        message.currencySymbol = reader.string();
                        break;
                    case 16:
                        message.timestampCancel = reader.int64();
                        break;
                    case 17:
                        message.clientRoom = reader.string();
                        break;
                    case 18:
                        message.courierId = reader.string();
                        break;
                    case 19:
                        message.timestampAccept = reader.int64();
                        break;
                    case 20:
                        message.timestampUpdated = reader.int64();
                        break;
                    case 22:
                        message.timestampDeliveryBefore = reader.int64();
                        break;
                    case 25:
                        if (!(message.paymentTypes && message.paymentTypes.length))
                            message.paymentTypes = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.paymentTypes.push(reader.int32());
                        } else
                            message.paymentTypes.push(reader.int32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an Order message.
             * @function verify
             * @memberof fastcity.courier.Order
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Order.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.orderId != null && message.hasOwnProperty("orderId"))
                    if (!$util.isInteger(message.orderId) && !(message.orderId && $util.isInteger(message.orderId.low) && $util.isInteger(message.orderId.high)))
                        return "orderId: integer|Long expected";
                if (message.externalOrderId != null && message.hasOwnProperty("externalOrderId"))
                    if (!$util.isString(message.externalOrderId))
                        return "externalOrderId: string expected";
                if (message.orderIdToPrint != null && message.hasOwnProperty("orderIdToPrint"))
                    if (!$util.isString(message.orderIdToPrint))
                        return "orderIdToPrint: string expected";
                if (message.subCompanyId != null && message.hasOwnProperty("subCompanyId"))
                    if (!$util.isInteger(message.subCompanyId) && !(message.subCompanyId && $util.isInteger(message.subCompanyId.low) && $util.isInteger(message.subCompanyId.high)))
                        return "subCompanyId: integer|Long expected";
                if (message.timestampOpen != null && message.hasOwnProperty("timestampOpen"))
                    if (!$util.isInteger(message.timestampOpen) && !(message.timestampOpen && $util.isInteger(message.timestampOpen.low) && $util.isInteger(message.timestampOpen.high)))
                        return "timestampOpen: integer|Long expected";
                if (message.status != null && message.hasOwnProperty("status"))
                    switch (message.status) {
                    default:
                        return "status: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                    case 9:
                    case 10:
                    case 11:
                    case 12:
                    case 13:
                    case 14:
                    case 15:
                    case 16:
                        break;
                    }
                if (message.price != null && message.hasOwnProperty("price"))
                    if (!$util.isString(message.price))
                        return "price: string expected";
                if (message.paymentType != null && message.hasOwnProperty("paymentType"))
                    switch (message.paymentType) {
                    default:
                        return "paymentType: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                    case 9:
                    case 10:
                    case 11:
                    case 12:
                    case 13:
                        break;
                    }
                if (message.clientPhone != null && message.hasOwnProperty("clientPhone"))
                    if (!$util.isString(message.clientPhone))
                        return "clientPhone: string expected";
                if (message.clientName != null && message.hasOwnProperty("clientName"))
                    if (!$util.isString(message.clientName))
                        return "clientName: string expected";
                if (message.clientAddress != null && message.hasOwnProperty("clientAddress"))
                    if (!$util.isString(message.clientAddress))
                        return "clientAddress: string expected";
                if (message.orderItem != null && message.hasOwnProperty("orderItem")) {
                    if (!Array.isArray(message.orderItem))
                        return "orderItem: array expected";
                    for (var i = 0; i < message.orderItem.length; ++i) {
                        var error = $root.fastcity.courier.OrderItem.verify(message.orderItem[i]);
                        if (error)
                            return "orderItem." + error;
                    }
                }
                if (message.isFinished != null && message.hasOwnProperty("isFinished"))
                    if (typeof message.isFinished !== "boolean")
                        return "isFinished: boolean expected";
                if (message.comments != null && message.hasOwnProperty("comments"))
                    if (!$util.isString(message.comments))
                        return "comments: string expected";
                if (message.timestampClose != null && message.hasOwnProperty("timestampClose"))
                    if (!$util.isInteger(message.timestampClose) && !(message.timestampClose && $util.isInteger(message.timestampClose.low) && $util.isInteger(message.timestampClose.high)))
                        return "timestampClose: integer|Long expected";
                if (message.timestampOpenWorkingDay != null && message.hasOwnProperty("timestampOpenWorkingDay"))
                    if (!$util.isInteger(message.timestampOpenWorkingDay) && !(message.timestampOpenWorkingDay && $util.isInteger(message.timestampOpenWorkingDay.low) && $util.isInteger(message.timestampOpenWorkingDay.high)))
                        return "timestampOpenWorkingDay: integer|Long expected";
                if (message.coordinate != null && message.hasOwnProperty("coordinate")) {
                    var error = $root.fastcity.common.Coordinate.verify(message.coordinate);
                    if (error)
                        return "coordinate." + error;
                }
                if (message.currencySymbol != null && message.hasOwnProperty("currencySymbol"))
                    if (!$util.isString(message.currencySymbol))
                        return "currencySymbol: string expected";
                if (message.timestampCancel != null && message.hasOwnProperty("timestampCancel"))
                    if (!$util.isInteger(message.timestampCancel) && !(message.timestampCancel && $util.isInteger(message.timestampCancel.low) && $util.isInteger(message.timestampCancel.high)))
                        return "timestampCancel: integer|Long expected";
                if (message.clientRoom != null && message.hasOwnProperty("clientRoom"))
                    if (!$util.isString(message.clientRoom))
                        return "clientRoom: string expected";
                if (message.courierId != null && message.hasOwnProperty("courierId"))
                    if (!$util.isString(message.courierId))
                        return "courierId: string expected";
                if (message.timestampAccept != null && message.hasOwnProperty("timestampAccept"))
                    if (!$util.isInteger(message.timestampAccept) && !(message.timestampAccept && $util.isInteger(message.timestampAccept.low) && $util.isInteger(message.timestampAccept.high)))
                        return "timestampAccept: integer|Long expected";
                if (message.timestampUpdated != null && message.hasOwnProperty("timestampUpdated"))
                    if (!$util.isInteger(message.timestampUpdated) && !(message.timestampUpdated && $util.isInteger(message.timestampUpdated.low) && $util.isInteger(message.timestampUpdated.high)))
                        return "timestampUpdated: integer|Long expected";
                if (message.timestampDeliveryBefore != null && message.hasOwnProperty("timestampDeliveryBefore"))
                    if (!$util.isInteger(message.timestampDeliveryBefore) && !(message.timestampDeliveryBefore && $util.isInteger(message.timestampDeliveryBefore.low) && $util.isInteger(message.timestampDeliveryBefore.high)))
                        return "timestampDeliveryBefore: integer|Long expected";
                if (message.paymentTypes != null && message.hasOwnProperty("paymentTypes")) {
                    if (!Array.isArray(message.paymentTypes))
                        return "paymentTypes: array expected";
                    for (var i = 0; i < message.paymentTypes.length; ++i)
                        switch (message.paymentTypes[i]) {
                        default:
                            return "paymentTypes: enum value[] expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                        case 9:
                        case 10:
                        case 11:
                        case 12:
                        case 13:
                            break;
                        }
                }
                return null;
            };

            /**
             * Creates an Order message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.courier.Order
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.courier.Order} Order
             */
            Order.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.courier.Order)
                    return object;
                var message = new $root.fastcity.courier.Order();
                if (object.orderId != null)
                    if ($util.Long)
                        (message.orderId = $util.Long.fromValue(object.orderId)).unsigned = false;
                    else if (typeof object.orderId === "string")
                        message.orderId = parseInt(object.orderId, 10);
                    else if (typeof object.orderId === "number")
                        message.orderId = object.orderId;
                    else if (typeof object.orderId === "object")
                        message.orderId = new $util.LongBits(object.orderId.low >>> 0, object.orderId.high >>> 0).toNumber();
                if (object.externalOrderId != null)
                    message.externalOrderId = String(object.externalOrderId);
                if (object.orderIdToPrint != null)
                    message.orderIdToPrint = String(object.orderIdToPrint);
                if (object.subCompanyId != null)
                    if ($util.Long)
                        (message.subCompanyId = $util.Long.fromValue(object.subCompanyId)).unsigned = true;
                    else if (typeof object.subCompanyId === "string")
                        message.subCompanyId = parseInt(object.subCompanyId, 10);
                    else if (typeof object.subCompanyId === "number")
                        message.subCompanyId = object.subCompanyId;
                    else if (typeof object.subCompanyId === "object")
                        message.subCompanyId = new $util.LongBits(object.subCompanyId.low >>> 0, object.subCompanyId.high >>> 0).toNumber(true);
                if (object.timestampOpen != null)
                    if ($util.Long)
                        (message.timestampOpen = $util.Long.fromValue(object.timestampOpen)).unsigned = false;
                    else if (typeof object.timestampOpen === "string")
                        message.timestampOpen = parseInt(object.timestampOpen, 10);
                    else if (typeof object.timestampOpen === "number")
                        message.timestampOpen = object.timestampOpen;
                    else if (typeof object.timestampOpen === "object")
                        message.timestampOpen = new $util.LongBits(object.timestampOpen.low >>> 0, object.timestampOpen.high >>> 0).toNumber();
                switch (object.status) {
                case "S_UNKNOWN":
                case 0:
                    message.status = 0;
                    break;
                case "S_IN_PROGRESS":
                case 1:
                    message.status = 1;
                    break;
                case "S_MAKELINE":
                case 2:
                    message.status = 2;
                    break;
                case "S_ROUTING_STATION":
                case 3:
                    message.status = 3;
                    break;
                case "S_OUT_THE_DOOR":
                case 4:
                    message.status = 4;
                    break;
                case "S_COMPLETE":
                case 5:
                    message.status = 5;
                    break;
                case "S_GIFT":
                case 6:
                    message.status = 6;
                    break;
                case "S_BAD":
                case 7:
                    message.status = 7;
                    break;
                case "S_VOID":
                case 8:
                    message.status = 8;
                    break;
                case "S_BEING_TAKEN":
                case 9:
                    message.status = 9;
                    break;
                case "S_ABANDONED":
                case 10:
                    message.status = 10;
                    break;
                case "S_SUSPENDED":
                case 11:
                    message.status = 11;
                    break;
                case "S_GIFT_CARD_PURCHASE":
                case 12:
                    message.status = 12;
                    break;
                case "S_GIFT_CARD_VOID":
                case 13:
                    message.status = 13;
                    break;
                case "S_TRANSFERRED":
                case 14:
                    message.status = 14;
                    break;
                case "S_HISTORIC_ORDER":
                case 15:
                    message.status = 15;
                    break;
                case "S_BEING_ASSIGNED":
                case 16:
                    message.status = 16;
                    break;
                }
                if (object.price != null)
                    message.price = String(object.price);
                switch (object.paymentType) {
                case "T_UNKNOWN":
                case 0:
                    message.paymentType = 0;
                    break;
                case "T_PAID":
                case 1:
                    message.paymentType = 1;
                    break;
                case "T_BY_CASH_TO_THE_COURIER":
                case 2:
                    message.paymentType = 2;
                    break;
                case "T_BY_CARD_TO_THE_COURIER":
                case 3:
                    message.paymentType = 3;
                    break;
                case "T_PAID_BY_CASH":
                case 4:
                    message.paymentType = 4;
                    break;
                case "T_PAID_BY_CARD":
                case 5:
                    message.paymentType = 5;
                    break;
                case "T_PAID_BY_CHECK":
                case 6:
                    message.paymentType = 6;
                    break;
                case "T_PAID_BY_CREDIT":
                case 7:
                    message.paymentType = 7;
                    break;
                case "T_PAID_WRITEOFF":
                case 8:
                    message.paymentType = 8;
                    break;
                case "T_PAID_BY_VOUCHER":
                case 9:
                    message.paymentType = 9;
                    break;
                case "T_PAID_FROM_EXTERNAL":
                case 10:
                    message.paymentType = 10;
                    break;
                case "T_PAID_SMART_SALE":
                case 11:
                    message.paymentType = 11;
                    break;
                case "T_PAID_BY_SBERBANK":
                case 12:
                    message.paymentType = 12;
                    break;
                case "T_PAID_BY_TRPOS":
                case 13:
                    message.paymentType = 13;
                    break;
                }
                if (object.clientPhone != null)
                    message.clientPhone = String(object.clientPhone);
                if (object.clientName != null)
                    message.clientName = String(object.clientName);
                if (object.clientAddress != null)
                    message.clientAddress = String(object.clientAddress);
                if (object.orderItem) {
                    if (!Array.isArray(object.orderItem))
                        throw TypeError(".fastcity.courier.Order.orderItem: array expected");
                    message.orderItem = [];
                    for (var i = 0; i < object.orderItem.length; ++i) {
                        if (typeof object.orderItem[i] !== "object")
                            throw TypeError(".fastcity.courier.Order.orderItem: object expected");
                        message.orderItem[i] = $root.fastcity.courier.OrderItem.fromObject(object.orderItem[i]);
                    }
                }
                if (object.isFinished != null)
                    message.isFinished = Boolean(object.isFinished);
                if (object.comments != null)
                    message.comments = String(object.comments);
                if (object.timestampClose != null)
                    if ($util.Long)
                        (message.timestampClose = $util.Long.fromValue(object.timestampClose)).unsigned = false;
                    else if (typeof object.timestampClose === "string")
                        message.timestampClose = parseInt(object.timestampClose, 10);
                    else if (typeof object.timestampClose === "number")
                        message.timestampClose = object.timestampClose;
                    else if (typeof object.timestampClose === "object")
                        message.timestampClose = new $util.LongBits(object.timestampClose.low >>> 0, object.timestampClose.high >>> 0).toNumber();
                if (object.timestampOpenWorkingDay != null)
                    if ($util.Long)
                        (message.timestampOpenWorkingDay = $util.Long.fromValue(object.timestampOpenWorkingDay)).unsigned = false;
                    else if (typeof object.timestampOpenWorkingDay === "string")
                        message.timestampOpenWorkingDay = parseInt(object.timestampOpenWorkingDay, 10);
                    else if (typeof object.timestampOpenWorkingDay === "number")
                        message.timestampOpenWorkingDay = object.timestampOpenWorkingDay;
                    else if (typeof object.timestampOpenWorkingDay === "object")
                        message.timestampOpenWorkingDay = new $util.LongBits(object.timestampOpenWorkingDay.low >>> 0, object.timestampOpenWorkingDay.high >>> 0).toNumber();
                if (object.coordinate != null) {
                    if (typeof object.coordinate !== "object")
                        throw TypeError(".fastcity.courier.Order.coordinate: object expected");
                    message.coordinate = $root.fastcity.common.Coordinate.fromObject(object.coordinate);
                }
                if (object.currencySymbol != null)
                    message.currencySymbol = String(object.currencySymbol);
                if (object.timestampCancel != null)
                    if ($util.Long)
                        (message.timestampCancel = $util.Long.fromValue(object.timestampCancel)).unsigned = false;
                    else if (typeof object.timestampCancel === "string")
                        message.timestampCancel = parseInt(object.timestampCancel, 10);
                    else if (typeof object.timestampCancel === "number")
                        message.timestampCancel = object.timestampCancel;
                    else if (typeof object.timestampCancel === "object")
                        message.timestampCancel = new $util.LongBits(object.timestampCancel.low >>> 0, object.timestampCancel.high >>> 0).toNumber();
                if (object.clientRoom != null)
                    message.clientRoom = String(object.clientRoom);
                if (object.courierId != null)
                    message.courierId = String(object.courierId);
                if (object.timestampAccept != null)
                    if ($util.Long)
                        (message.timestampAccept = $util.Long.fromValue(object.timestampAccept)).unsigned = false;
                    else if (typeof object.timestampAccept === "string")
                        message.timestampAccept = parseInt(object.timestampAccept, 10);
                    else if (typeof object.timestampAccept === "number")
                        message.timestampAccept = object.timestampAccept;
                    else if (typeof object.timestampAccept === "object")
                        message.timestampAccept = new $util.LongBits(object.timestampAccept.low >>> 0, object.timestampAccept.high >>> 0).toNumber();
                if (object.timestampUpdated != null)
                    if ($util.Long)
                        (message.timestampUpdated = $util.Long.fromValue(object.timestampUpdated)).unsigned = false;
                    else if (typeof object.timestampUpdated === "string")
                        message.timestampUpdated = parseInt(object.timestampUpdated, 10);
                    else if (typeof object.timestampUpdated === "number")
                        message.timestampUpdated = object.timestampUpdated;
                    else if (typeof object.timestampUpdated === "object")
                        message.timestampUpdated = new $util.LongBits(object.timestampUpdated.low >>> 0, object.timestampUpdated.high >>> 0).toNumber();
                if (object.timestampDeliveryBefore != null)
                    if ($util.Long)
                        (message.timestampDeliveryBefore = $util.Long.fromValue(object.timestampDeliveryBefore)).unsigned = false;
                    else if (typeof object.timestampDeliveryBefore === "string")
                        message.timestampDeliveryBefore = parseInt(object.timestampDeliveryBefore, 10);
                    else if (typeof object.timestampDeliveryBefore === "number")
                        message.timestampDeliveryBefore = object.timestampDeliveryBefore;
                    else if (typeof object.timestampDeliveryBefore === "object")
                        message.timestampDeliveryBefore = new $util.LongBits(object.timestampDeliveryBefore.low >>> 0, object.timestampDeliveryBefore.high >>> 0).toNumber();
                if (object.paymentTypes) {
                    if (!Array.isArray(object.paymentTypes))
                        throw TypeError(".fastcity.courier.Order.paymentTypes: array expected");
                    message.paymentTypes = [];
                    for (var i = 0; i < object.paymentTypes.length; ++i)
                        switch (object.paymentTypes[i]) {
                        default:
                        case "T_UNKNOWN":
                        case 0:
                            message.paymentTypes[i] = 0;
                            break;
                        case "T_PAID":
                        case 1:
                            message.paymentTypes[i] = 1;
                            break;
                        case "T_BY_CASH_TO_THE_COURIER":
                        case 2:
                            message.paymentTypes[i] = 2;
                            break;
                        case "T_BY_CARD_TO_THE_COURIER":
                        case 3:
                            message.paymentTypes[i] = 3;
                            break;
                        case "T_PAID_BY_CASH":
                        case 4:
                            message.paymentTypes[i] = 4;
                            break;
                        case "T_PAID_BY_CARD":
                        case 5:
                            message.paymentTypes[i] = 5;
                            break;
                        case "T_PAID_BY_CHECK":
                        case 6:
                            message.paymentTypes[i] = 6;
                            break;
                        case "T_PAID_BY_CREDIT":
                        case 7:
                            message.paymentTypes[i] = 7;
                            break;
                        case "T_PAID_WRITEOFF":
                        case 8:
                            message.paymentTypes[i] = 8;
                            break;
                        case "T_PAID_BY_VOUCHER":
                        case 9:
                            message.paymentTypes[i] = 9;
                            break;
                        case "T_PAID_FROM_EXTERNAL":
                        case 10:
                            message.paymentTypes[i] = 10;
                            break;
                        case "T_PAID_SMART_SALE":
                        case 11:
                            message.paymentTypes[i] = 11;
                            break;
                        case "T_PAID_BY_SBERBANK":
                        case 12:
                            message.paymentTypes[i] = 12;
                            break;
                        case "T_PAID_BY_TRPOS":
                        case 13:
                            message.paymentTypes[i] = 13;
                            break;
                        }
                }
                return message;
            };

            /**
             * Creates a plain object from an Order message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.courier.Order
             * @static
             * @param {fastcity.courier.Order} message Order
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Order.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults) {
                    object.orderItem = [];
                    object.paymentTypes = [];
                }
                if (options.defaults) {
                    object.externalOrderId = "";
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.timestampOpen = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.timestampOpen = options.longs === String ? "0" : 0;
                    object.status = options.enums === String ? "S_UNKNOWN" : 0;
                    object.price = "";
                    object.paymentType = options.enums === String ? "T_UNKNOWN" : 0;
                    object.clientPhone = "";
                    object.clientName = "";
                    object.clientAddress = "";
                    object.isFinished = false;
                    object.comments = "";
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.timestampClose = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.timestampClose = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.timestampOpenWorkingDay = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.timestampOpenWorkingDay = options.longs === String ? "0" : 0;
                    object.coordinate = null;
                    object.currencySymbol = "";
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.timestampCancel = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.timestampCancel = options.longs === String ? "0" : 0;
                    object.clientRoom = "";
                    object.courierId = "";
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.timestampAccept = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.timestampAccept = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.timestampUpdated = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.timestampUpdated = options.longs === String ? "0" : 0;
                    object.orderIdToPrint = "";
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.timestampDeliveryBefore = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.timestampDeliveryBefore = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.orderId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.orderId = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.subCompanyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.subCompanyId = options.longs === String ? "0" : 0;
                }
                if (message.externalOrderId != null && message.hasOwnProperty("externalOrderId"))
                    object.externalOrderId = message.externalOrderId;
                if (message.timestampOpen != null && message.hasOwnProperty("timestampOpen"))
                    if (typeof message.timestampOpen === "number")
                        object.timestampOpen = options.longs === String ? String(message.timestampOpen) : message.timestampOpen;
                    else
                        object.timestampOpen = options.longs === String ? $util.Long.prototype.toString.call(message.timestampOpen) : options.longs === Number ? new $util.LongBits(message.timestampOpen.low >>> 0, message.timestampOpen.high >>> 0).toNumber() : message.timestampOpen;
                if (message.status != null && message.hasOwnProperty("status"))
                    object.status = options.enums === String ? $root.fastcity.common.Order.Status[message.status] : message.status;
                if (message.price != null && message.hasOwnProperty("price"))
                    object.price = message.price;
                if (message.paymentType != null && message.hasOwnProperty("paymentType"))
                    object.paymentType = options.enums === String ? $root.fastcity.common.Order.PaymentType[message.paymentType] : message.paymentType;
                if (message.clientPhone != null && message.hasOwnProperty("clientPhone"))
                    object.clientPhone = message.clientPhone;
                if (message.clientName != null && message.hasOwnProperty("clientName"))
                    object.clientName = message.clientName;
                if (message.clientAddress != null && message.hasOwnProperty("clientAddress"))
                    object.clientAddress = message.clientAddress;
                if (message.orderItem && message.orderItem.length) {
                    object.orderItem = [];
                    for (var j = 0; j < message.orderItem.length; ++j)
                        object.orderItem[j] = $root.fastcity.courier.OrderItem.toObject(message.orderItem[j], options);
                }
                if (message.isFinished != null && message.hasOwnProperty("isFinished"))
                    object.isFinished = message.isFinished;
                if (message.comments != null && message.hasOwnProperty("comments"))
                    object.comments = message.comments;
                if (message.timestampClose != null && message.hasOwnProperty("timestampClose"))
                    if (typeof message.timestampClose === "number")
                        object.timestampClose = options.longs === String ? String(message.timestampClose) : message.timestampClose;
                    else
                        object.timestampClose = options.longs === String ? $util.Long.prototype.toString.call(message.timestampClose) : options.longs === Number ? new $util.LongBits(message.timestampClose.low >>> 0, message.timestampClose.high >>> 0).toNumber() : message.timestampClose;
                if (message.timestampOpenWorkingDay != null && message.hasOwnProperty("timestampOpenWorkingDay"))
                    if (typeof message.timestampOpenWorkingDay === "number")
                        object.timestampOpenWorkingDay = options.longs === String ? String(message.timestampOpenWorkingDay) : message.timestampOpenWorkingDay;
                    else
                        object.timestampOpenWorkingDay = options.longs === String ? $util.Long.prototype.toString.call(message.timestampOpenWorkingDay) : options.longs === Number ? new $util.LongBits(message.timestampOpenWorkingDay.low >>> 0, message.timestampOpenWorkingDay.high >>> 0).toNumber() : message.timestampOpenWorkingDay;
                if (message.coordinate != null && message.hasOwnProperty("coordinate"))
                    object.coordinate = $root.fastcity.common.Coordinate.toObject(message.coordinate, options);
                if (message.currencySymbol != null && message.hasOwnProperty("currencySymbol"))
                    object.currencySymbol = message.currencySymbol;
                if (message.timestampCancel != null && message.hasOwnProperty("timestampCancel"))
                    if (typeof message.timestampCancel === "number")
                        object.timestampCancel = options.longs === String ? String(message.timestampCancel) : message.timestampCancel;
                    else
                        object.timestampCancel = options.longs === String ? $util.Long.prototype.toString.call(message.timestampCancel) : options.longs === Number ? new $util.LongBits(message.timestampCancel.low >>> 0, message.timestampCancel.high >>> 0).toNumber() : message.timestampCancel;
                if (message.clientRoom != null && message.hasOwnProperty("clientRoom"))
                    object.clientRoom = message.clientRoom;
                if (message.courierId != null && message.hasOwnProperty("courierId"))
                    object.courierId = message.courierId;
                if (message.timestampAccept != null && message.hasOwnProperty("timestampAccept"))
                    if (typeof message.timestampAccept === "number")
                        object.timestampAccept = options.longs === String ? String(message.timestampAccept) : message.timestampAccept;
                    else
                        object.timestampAccept = options.longs === String ? $util.Long.prototype.toString.call(message.timestampAccept) : options.longs === Number ? new $util.LongBits(message.timestampAccept.low >>> 0, message.timestampAccept.high >>> 0).toNumber() : message.timestampAccept;
                if (message.timestampUpdated != null && message.hasOwnProperty("timestampUpdated"))
                    if (typeof message.timestampUpdated === "number")
                        object.timestampUpdated = options.longs === String ? String(message.timestampUpdated) : message.timestampUpdated;
                    else
                        object.timestampUpdated = options.longs === String ? $util.Long.prototype.toString.call(message.timestampUpdated) : options.longs === Number ? new $util.LongBits(message.timestampUpdated.low >>> 0, message.timestampUpdated.high >>> 0).toNumber() : message.timestampUpdated;
                if (message.orderIdToPrint != null && message.hasOwnProperty("orderIdToPrint"))
                    object.orderIdToPrint = message.orderIdToPrint;
                if (message.timestampDeliveryBefore != null && message.hasOwnProperty("timestampDeliveryBefore"))
                    if (typeof message.timestampDeliveryBefore === "number")
                        object.timestampDeliveryBefore = options.longs === String ? String(message.timestampDeliveryBefore) : message.timestampDeliveryBefore;
                    else
                        object.timestampDeliveryBefore = options.longs === String ? $util.Long.prototype.toString.call(message.timestampDeliveryBefore) : options.longs === Number ? new $util.LongBits(message.timestampDeliveryBefore.low >>> 0, message.timestampDeliveryBefore.high >>> 0).toNumber() : message.timestampDeliveryBefore;
                if (message.orderId != null && message.hasOwnProperty("orderId"))
                    if (typeof message.orderId === "number")
                        object.orderId = options.longs === String ? String(message.orderId) : message.orderId;
                    else
                        object.orderId = options.longs === String ? $util.Long.prototype.toString.call(message.orderId) : options.longs === Number ? new $util.LongBits(message.orderId.low >>> 0, message.orderId.high >>> 0).toNumber() : message.orderId;
                if (message.subCompanyId != null && message.hasOwnProperty("subCompanyId"))
                    if (typeof message.subCompanyId === "number")
                        object.subCompanyId = options.longs === String ? String(message.subCompanyId) : message.subCompanyId;
                    else
                        object.subCompanyId = options.longs === String ? $util.Long.prototype.toString.call(message.subCompanyId) : options.longs === Number ? new $util.LongBits(message.subCompanyId.low >>> 0, message.subCompanyId.high >>> 0).toNumber(true) : message.subCompanyId;
                if (message.paymentTypes && message.paymentTypes.length) {
                    object.paymentTypes = [];
                    for (var j = 0; j < message.paymentTypes.length; ++j)
                        object.paymentTypes[j] = options.enums === String ? $root.fastcity.common.Order.PaymentType[message.paymentTypes[j]] : message.paymentTypes[j];
                }
                return object;
            };

            /**
             * Converts this Order to JSON.
             * @function toJSON
             * @memberof fastcity.courier.Order
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Order.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Order;
        })();

        courier.GetOrderList = (function() {

            /**
             * Properties of a GetOrderList.
             * @memberof fastcity.courier
             * @interface IGetOrderList
             * @property {fastcity.courier.GetOrderList.IAllTodaysOrders|null} [allTodaysOrders] GetOrderList allTodaysOrders
             * @property {fastcity.courier.GetOrderList.ITimeInterval|null} [timeInterval] GetOrderList timeInterval
             * @property {fastcity.courier.GetOrderList.IFastestDeliveries|null} [fastestDeliveries] GetOrderList fastestDeliveries
             * @property {Array.<fastcity.common.Order.Status>|null} [status] GetOrderList status
             */

            /**
             * Constructs a new GetOrderList.
             * @memberof fastcity.courier
             * @classdesc Represents a GetOrderList.
             * @implements IGetOrderList
             * @constructor
             * @param {fastcity.courier.IGetOrderList=} [properties] Properties to set
             */
            function GetOrderList(properties) {
                this.status = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GetOrderList allTodaysOrders.
             * @member {fastcity.courier.GetOrderList.IAllTodaysOrders|null|undefined} allTodaysOrders
             * @memberof fastcity.courier.GetOrderList
             * @instance
             */
            GetOrderList.prototype.allTodaysOrders = null;

            /**
             * GetOrderList timeInterval.
             * @member {fastcity.courier.GetOrderList.ITimeInterval|null|undefined} timeInterval
             * @memberof fastcity.courier.GetOrderList
             * @instance
             */
            GetOrderList.prototype.timeInterval = null;

            /**
             * GetOrderList fastestDeliveries.
             * @member {fastcity.courier.GetOrderList.IFastestDeliveries|null|undefined} fastestDeliveries
             * @memberof fastcity.courier.GetOrderList
             * @instance
             */
            GetOrderList.prototype.fastestDeliveries = null;

            /**
             * GetOrderList status.
             * @member {Array.<fastcity.common.Order.Status>} status
             * @memberof fastcity.courier.GetOrderList
             * @instance
             */
            GetOrderList.prototype.status = $util.emptyArray;

            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;

            /**
             * GetOrderList type.
             * @member {"allTodaysOrders"|"timeInterval"|"fastestDeliveries"|undefined} type
             * @memberof fastcity.courier.GetOrderList
             * @instance
             */
            Object.defineProperty(GetOrderList.prototype, "type", {
                get: $util.oneOfGetter($oneOfFields = ["allTodaysOrders", "timeInterval", "fastestDeliveries"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Creates a new GetOrderList instance using the specified properties.
             * @function create
             * @memberof fastcity.courier.GetOrderList
             * @static
             * @param {fastcity.courier.IGetOrderList=} [properties] Properties to set
             * @returns {fastcity.courier.GetOrderList} GetOrderList instance
             */
            GetOrderList.create = function create(properties) {
                return new GetOrderList(properties);
            };

            /**
             * Encodes the specified GetOrderList message. Does not implicitly {@link fastcity.courier.GetOrderList.verify|verify} messages.
             * @function encode
             * @memberof fastcity.courier.GetOrderList
             * @static
             * @param {fastcity.courier.IGetOrderList} message GetOrderList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetOrderList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.allTodaysOrders != null && Object.hasOwnProperty.call(message, "allTodaysOrders"))
                    $root.fastcity.courier.GetOrderList.AllTodaysOrders.encode(message.allTodaysOrders, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.timeInterval != null && Object.hasOwnProperty.call(message, "timeInterval"))
                    $root.fastcity.courier.GetOrderList.TimeInterval.encode(message.timeInterval, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.fastestDeliveries != null && Object.hasOwnProperty.call(message, "fastestDeliveries"))
                    $root.fastcity.courier.GetOrderList.FastestDeliveries.encode(message.fastestDeliveries, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.status != null && message.status.length) {
                    writer.uint32(/* id 4, wireType 2 =*/34).fork();
                    for (var i = 0; i < message.status.length; ++i)
                        writer.int32(message.status[i]);
                    writer.ldelim();
                }
                return writer;
            };

            /**
             * Decodes a GetOrderList message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.courier.GetOrderList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.courier.GetOrderList} GetOrderList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetOrderList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.courier.GetOrderList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.allTodaysOrders = $root.fastcity.courier.GetOrderList.AllTodaysOrders.decode(reader, reader.uint32());
                        break;
                    case 2:
                        message.timeInterval = $root.fastcity.courier.GetOrderList.TimeInterval.decode(reader, reader.uint32());
                        break;
                    case 3:
                        message.fastestDeliveries = $root.fastcity.courier.GetOrderList.FastestDeliveries.decode(reader, reader.uint32());
                        break;
                    case 4:
                        if (!(message.status && message.status.length))
                            message.status = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.status.push(reader.int32());
                        } else
                            message.status.push(reader.int32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a GetOrderList message.
             * @function verify
             * @memberof fastcity.courier.GetOrderList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetOrderList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                var properties = {};
                if (message.allTodaysOrders != null && message.hasOwnProperty("allTodaysOrders")) {
                    properties.type = 1;
                    {
                        var error = $root.fastcity.courier.GetOrderList.AllTodaysOrders.verify(message.allTodaysOrders);
                        if (error)
                            return "allTodaysOrders." + error;
                    }
                }
                if (message.timeInterval != null && message.hasOwnProperty("timeInterval")) {
                    if (properties.type === 1)
                        return "type: multiple values";
                    properties.type = 1;
                    {
                        var error = $root.fastcity.courier.GetOrderList.TimeInterval.verify(message.timeInterval);
                        if (error)
                            return "timeInterval." + error;
                    }
                }
                if (message.fastestDeliveries != null && message.hasOwnProperty("fastestDeliveries")) {
                    if (properties.type === 1)
                        return "type: multiple values";
                    properties.type = 1;
                    {
                        var error = $root.fastcity.courier.GetOrderList.FastestDeliveries.verify(message.fastestDeliveries);
                        if (error)
                            return "fastestDeliveries." + error;
                    }
                }
                if (message.status != null && message.hasOwnProperty("status")) {
                    if (!Array.isArray(message.status))
                        return "status: array expected";
                    for (var i = 0; i < message.status.length; ++i)
                        switch (message.status[i]) {
                        default:
                            return "status: enum value[] expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                        case 9:
                        case 10:
                        case 11:
                        case 12:
                        case 13:
                        case 14:
                        case 15:
                        case 16:
                            break;
                        }
                }
                return null;
            };

            /**
             * Creates a GetOrderList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.courier.GetOrderList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.courier.GetOrderList} GetOrderList
             */
            GetOrderList.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.courier.GetOrderList)
                    return object;
                var message = new $root.fastcity.courier.GetOrderList();
                if (object.allTodaysOrders != null) {
                    if (typeof object.allTodaysOrders !== "object")
                        throw TypeError(".fastcity.courier.GetOrderList.allTodaysOrders: object expected");
                    message.allTodaysOrders = $root.fastcity.courier.GetOrderList.AllTodaysOrders.fromObject(object.allTodaysOrders);
                }
                if (object.timeInterval != null) {
                    if (typeof object.timeInterval !== "object")
                        throw TypeError(".fastcity.courier.GetOrderList.timeInterval: object expected");
                    message.timeInterval = $root.fastcity.courier.GetOrderList.TimeInterval.fromObject(object.timeInterval);
                }
                if (object.fastestDeliveries != null) {
                    if (typeof object.fastestDeliveries !== "object")
                        throw TypeError(".fastcity.courier.GetOrderList.fastestDeliveries: object expected");
                    message.fastestDeliveries = $root.fastcity.courier.GetOrderList.FastestDeliveries.fromObject(object.fastestDeliveries);
                }
                if (object.status) {
                    if (!Array.isArray(object.status))
                        throw TypeError(".fastcity.courier.GetOrderList.status: array expected");
                    message.status = [];
                    for (var i = 0; i < object.status.length; ++i)
                        switch (object.status[i]) {
                        default:
                        case "S_UNKNOWN":
                        case 0:
                            message.status[i] = 0;
                            break;
                        case "S_IN_PROGRESS":
                        case 1:
                            message.status[i] = 1;
                            break;
                        case "S_MAKELINE":
                        case 2:
                            message.status[i] = 2;
                            break;
                        case "S_ROUTING_STATION":
                        case 3:
                            message.status[i] = 3;
                            break;
                        case "S_OUT_THE_DOOR":
                        case 4:
                            message.status[i] = 4;
                            break;
                        case "S_COMPLETE":
                        case 5:
                            message.status[i] = 5;
                            break;
                        case "S_GIFT":
                        case 6:
                            message.status[i] = 6;
                            break;
                        case "S_BAD":
                        case 7:
                            message.status[i] = 7;
                            break;
                        case "S_VOID":
                        case 8:
                            message.status[i] = 8;
                            break;
                        case "S_BEING_TAKEN":
                        case 9:
                            message.status[i] = 9;
                            break;
                        case "S_ABANDONED":
                        case 10:
                            message.status[i] = 10;
                            break;
                        case "S_SUSPENDED":
                        case 11:
                            message.status[i] = 11;
                            break;
                        case "S_GIFT_CARD_PURCHASE":
                        case 12:
                            message.status[i] = 12;
                            break;
                        case "S_GIFT_CARD_VOID":
                        case 13:
                            message.status[i] = 13;
                            break;
                        case "S_TRANSFERRED":
                        case 14:
                            message.status[i] = 14;
                            break;
                        case "S_HISTORIC_ORDER":
                        case 15:
                            message.status[i] = 15;
                            break;
                        case "S_BEING_ASSIGNED":
                        case 16:
                            message.status[i] = 16;
                            break;
                        }
                }
                return message;
            };

            /**
             * Creates a plain object from a GetOrderList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.courier.GetOrderList
             * @static
             * @param {fastcity.courier.GetOrderList} message GetOrderList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetOrderList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.status = [];
                if (message.allTodaysOrders != null && message.hasOwnProperty("allTodaysOrders")) {
                    object.allTodaysOrders = $root.fastcity.courier.GetOrderList.AllTodaysOrders.toObject(message.allTodaysOrders, options);
                    if (options.oneofs)
                        object.type = "allTodaysOrders";
                }
                if (message.timeInterval != null && message.hasOwnProperty("timeInterval")) {
                    object.timeInterval = $root.fastcity.courier.GetOrderList.TimeInterval.toObject(message.timeInterval, options);
                    if (options.oneofs)
                        object.type = "timeInterval";
                }
                if (message.fastestDeliveries != null && message.hasOwnProperty("fastestDeliveries")) {
                    object.fastestDeliveries = $root.fastcity.courier.GetOrderList.FastestDeliveries.toObject(message.fastestDeliveries, options);
                    if (options.oneofs)
                        object.type = "fastestDeliveries";
                }
                if (message.status && message.status.length) {
                    object.status = [];
                    for (var j = 0; j < message.status.length; ++j)
                        object.status[j] = options.enums === String ? $root.fastcity.common.Order.Status[message.status[j]] : message.status[j];
                }
                return object;
            };

            /**
             * Converts this GetOrderList to JSON.
             * @function toJSON
             * @memberof fastcity.courier.GetOrderList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetOrderList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            GetOrderList.AllTodaysOrders = (function() {

                /**
                 * Properties of an AllTodaysOrders.
                 * @memberof fastcity.courier.GetOrderList
                 * @interface IAllTodaysOrders
                 */

                /**
                 * Constructs a new AllTodaysOrders.
                 * @memberof fastcity.courier.GetOrderList
                 * @classdesc Represents an AllTodaysOrders.
                 * @implements IAllTodaysOrders
                 * @constructor
                 * @param {fastcity.courier.GetOrderList.IAllTodaysOrders=} [properties] Properties to set
                 */
                function AllTodaysOrders(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new AllTodaysOrders instance using the specified properties.
                 * @function create
                 * @memberof fastcity.courier.GetOrderList.AllTodaysOrders
                 * @static
                 * @param {fastcity.courier.GetOrderList.IAllTodaysOrders=} [properties] Properties to set
                 * @returns {fastcity.courier.GetOrderList.AllTodaysOrders} AllTodaysOrders instance
                 */
                AllTodaysOrders.create = function create(properties) {
                    return new AllTodaysOrders(properties);
                };

                /**
                 * Encodes the specified AllTodaysOrders message. Does not implicitly {@link fastcity.courier.GetOrderList.AllTodaysOrders.verify|verify} messages.
                 * @function encode
                 * @memberof fastcity.courier.GetOrderList.AllTodaysOrders
                 * @static
                 * @param {fastcity.courier.GetOrderList.IAllTodaysOrders} message AllTodaysOrders message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AllTodaysOrders.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Decodes an AllTodaysOrders message from the specified reader or buffer.
                 * @function decode
                 * @memberof fastcity.courier.GetOrderList.AllTodaysOrders
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {fastcity.courier.GetOrderList.AllTodaysOrders} AllTodaysOrders
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AllTodaysOrders.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.courier.GetOrderList.AllTodaysOrders();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Verifies an AllTodaysOrders message.
                 * @function verify
                 * @memberof fastcity.courier.GetOrderList.AllTodaysOrders
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                AllTodaysOrders.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates an AllTodaysOrders message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof fastcity.courier.GetOrderList.AllTodaysOrders
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {fastcity.courier.GetOrderList.AllTodaysOrders} AllTodaysOrders
                 */
                AllTodaysOrders.fromObject = function fromObject(object) {
                    if (object instanceof $root.fastcity.courier.GetOrderList.AllTodaysOrders)
                        return object;
                    return new $root.fastcity.courier.GetOrderList.AllTodaysOrders();
                };

                /**
                 * Creates a plain object from an AllTodaysOrders message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof fastcity.courier.GetOrderList.AllTodaysOrders
                 * @static
                 * @param {fastcity.courier.GetOrderList.AllTodaysOrders} message AllTodaysOrders
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                AllTodaysOrders.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this AllTodaysOrders to JSON.
                 * @function toJSON
                 * @memberof fastcity.courier.GetOrderList.AllTodaysOrders
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                AllTodaysOrders.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return AllTodaysOrders;
            })();

            GetOrderList.TimeInterval = (function() {

                /**
                 * Properties of a TimeInterval.
                 * @memberof fastcity.courier.GetOrderList
                 * @interface ITimeInterval
                 * @property {number|Long|null} [fromTime] TimeInterval fromTime
                 * @property {number|Long|null} [toTime] TimeInterval toTime
                 * @property {number|null} [offset] TimeInterval offset
                 * @property {number|null} [limit] TimeInterval limit
                 * @property {fastcity.common.SortOrder.Order|null} [sortOrder] TimeInterval sortOrder
                 */

                /**
                 * Constructs a new TimeInterval.
                 * @memberof fastcity.courier.GetOrderList
                 * @classdesc Represents a TimeInterval.
                 * @implements ITimeInterval
                 * @constructor
                 * @param {fastcity.courier.GetOrderList.ITimeInterval=} [properties] Properties to set
                 */
                function TimeInterval(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * TimeInterval fromTime.
                 * @member {number|Long} fromTime
                 * @memberof fastcity.courier.GetOrderList.TimeInterval
                 * @instance
                 */
                TimeInterval.prototype.fromTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * TimeInterval toTime.
                 * @member {number|Long} toTime
                 * @memberof fastcity.courier.GetOrderList.TimeInterval
                 * @instance
                 */
                TimeInterval.prototype.toTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * TimeInterval offset.
                 * @member {number} offset
                 * @memberof fastcity.courier.GetOrderList.TimeInterval
                 * @instance
                 */
                TimeInterval.prototype.offset = 0;

                /**
                 * TimeInterval limit.
                 * @member {number} limit
                 * @memberof fastcity.courier.GetOrderList.TimeInterval
                 * @instance
                 */
                TimeInterval.prototype.limit = 0;

                /**
                 * TimeInterval sortOrder.
                 * @member {fastcity.common.SortOrder.Order} sortOrder
                 * @memberof fastcity.courier.GetOrderList.TimeInterval
                 * @instance
                 */
                TimeInterval.prototype.sortOrder = 0;

                /**
                 * Creates a new TimeInterval instance using the specified properties.
                 * @function create
                 * @memberof fastcity.courier.GetOrderList.TimeInterval
                 * @static
                 * @param {fastcity.courier.GetOrderList.ITimeInterval=} [properties] Properties to set
                 * @returns {fastcity.courier.GetOrderList.TimeInterval} TimeInterval instance
                 */
                TimeInterval.create = function create(properties) {
                    return new TimeInterval(properties);
                };

                /**
                 * Encodes the specified TimeInterval message. Does not implicitly {@link fastcity.courier.GetOrderList.TimeInterval.verify|verify} messages.
                 * @function encode
                 * @memberof fastcity.courier.GetOrderList.TimeInterval
                 * @static
                 * @param {fastcity.courier.GetOrderList.ITimeInterval} message TimeInterval message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                TimeInterval.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.fromTime != null && Object.hasOwnProperty.call(message, "fromTime"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int64(message.fromTime);
                    if (message.toTime != null && Object.hasOwnProperty.call(message, "toTime"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int64(message.toTime);
                    if (message.offset != null && Object.hasOwnProperty.call(message, "offset"))
                        writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.offset);
                    if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                        writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.limit);
                    if (message.sortOrder != null && Object.hasOwnProperty.call(message, "sortOrder"))
                        writer.uint32(/* id 5, wireType 0 =*/40).int32(message.sortOrder);
                    return writer;
                };

                /**
                 * Decodes a TimeInterval message from the specified reader or buffer.
                 * @function decode
                 * @memberof fastcity.courier.GetOrderList.TimeInterval
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {fastcity.courier.GetOrderList.TimeInterval} TimeInterval
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                TimeInterval.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.courier.GetOrderList.TimeInterval();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.fromTime = reader.int64();
                            break;
                        case 2:
                            message.toTime = reader.int64();
                            break;
                        case 3:
                            message.offset = reader.uint32();
                            break;
                        case 4:
                            message.limit = reader.uint32();
                            break;
                        case 5:
                            message.sortOrder = reader.int32();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Verifies a TimeInterval message.
                 * @function verify
                 * @memberof fastcity.courier.GetOrderList.TimeInterval
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                TimeInterval.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.fromTime != null && message.hasOwnProperty("fromTime"))
                        if (!$util.isInteger(message.fromTime) && !(message.fromTime && $util.isInteger(message.fromTime.low) && $util.isInteger(message.fromTime.high)))
                            return "fromTime: integer|Long expected";
                    if (message.toTime != null && message.hasOwnProperty("toTime"))
                        if (!$util.isInteger(message.toTime) && !(message.toTime && $util.isInteger(message.toTime.low) && $util.isInteger(message.toTime.high)))
                            return "toTime: integer|Long expected";
                    if (message.offset != null && message.hasOwnProperty("offset"))
                        if (!$util.isInteger(message.offset))
                            return "offset: integer expected";
                    if (message.limit != null && message.hasOwnProperty("limit"))
                        if (!$util.isInteger(message.limit))
                            return "limit: integer expected";
                    if (message.sortOrder != null && message.hasOwnProperty("sortOrder"))
                        switch (message.sortOrder) {
                        default:
                            return "sortOrder: enum value expected";
                        case 0:
                        case 1:
                            break;
                        }
                    return null;
                };

                /**
                 * Creates a TimeInterval message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof fastcity.courier.GetOrderList.TimeInterval
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {fastcity.courier.GetOrderList.TimeInterval} TimeInterval
                 */
                TimeInterval.fromObject = function fromObject(object) {
                    if (object instanceof $root.fastcity.courier.GetOrderList.TimeInterval)
                        return object;
                    var message = new $root.fastcity.courier.GetOrderList.TimeInterval();
                    if (object.fromTime != null)
                        if ($util.Long)
                            (message.fromTime = $util.Long.fromValue(object.fromTime)).unsigned = false;
                        else if (typeof object.fromTime === "string")
                            message.fromTime = parseInt(object.fromTime, 10);
                        else if (typeof object.fromTime === "number")
                            message.fromTime = object.fromTime;
                        else if (typeof object.fromTime === "object")
                            message.fromTime = new $util.LongBits(object.fromTime.low >>> 0, object.fromTime.high >>> 0).toNumber();
                    if (object.toTime != null)
                        if ($util.Long)
                            (message.toTime = $util.Long.fromValue(object.toTime)).unsigned = false;
                        else if (typeof object.toTime === "string")
                            message.toTime = parseInt(object.toTime, 10);
                        else if (typeof object.toTime === "number")
                            message.toTime = object.toTime;
                        else if (typeof object.toTime === "object")
                            message.toTime = new $util.LongBits(object.toTime.low >>> 0, object.toTime.high >>> 0).toNumber();
                    if (object.offset != null)
                        message.offset = object.offset >>> 0;
                    if (object.limit != null)
                        message.limit = object.limit >>> 0;
                    switch (object.sortOrder) {
                    case "ASC":
                    case 0:
                        message.sortOrder = 0;
                        break;
                    case "DESC":
                    case 1:
                        message.sortOrder = 1;
                        break;
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a TimeInterval message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof fastcity.courier.GetOrderList.TimeInterval
                 * @static
                 * @param {fastcity.courier.GetOrderList.TimeInterval} message TimeInterval
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                TimeInterval.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.fromTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.fromTime = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.toTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.toTime = options.longs === String ? "0" : 0;
                        object.offset = 0;
                        object.limit = 0;
                        object.sortOrder = options.enums === String ? "ASC" : 0;
                    }
                    if (message.fromTime != null && message.hasOwnProperty("fromTime"))
                        if (typeof message.fromTime === "number")
                            object.fromTime = options.longs === String ? String(message.fromTime) : message.fromTime;
                        else
                            object.fromTime = options.longs === String ? $util.Long.prototype.toString.call(message.fromTime) : options.longs === Number ? new $util.LongBits(message.fromTime.low >>> 0, message.fromTime.high >>> 0).toNumber() : message.fromTime;
                    if (message.toTime != null && message.hasOwnProperty("toTime"))
                        if (typeof message.toTime === "number")
                            object.toTime = options.longs === String ? String(message.toTime) : message.toTime;
                        else
                            object.toTime = options.longs === String ? $util.Long.prototype.toString.call(message.toTime) : options.longs === Number ? new $util.LongBits(message.toTime.low >>> 0, message.toTime.high >>> 0).toNumber() : message.toTime;
                    if (message.offset != null && message.hasOwnProperty("offset"))
                        object.offset = message.offset;
                    if (message.limit != null && message.hasOwnProperty("limit"))
                        object.limit = message.limit;
                    if (message.sortOrder != null && message.hasOwnProperty("sortOrder"))
                        object.sortOrder = options.enums === String ? $root.fastcity.common.SortOrder.Order[message.sortOrder] : message.sortOrder;
                    return object;
                };

                /**
                 * Converts this TimeInterval to JSON.
                 * @function toJSON
                 * @memberof fastcity.courier.GetOrderList.TimeInterval
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                TimeInterval.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return TimeInterval;
            })();

            GetOrderList.FastestDeliveries = (function() {

                /**
                 * Properties of a FastestDeliveries.
                 * @memberof fastcity.courier.GetOrderList
                 * @interface IFastestDeliveries
                 * @property {number|null} [limit] FastestDeliveries limit
                 */

                /**
                 * Constructs a new FastestDeliveries.
                 * @memberof fastcity.courier.GetOrderList
                 * @classdesc Represents a FastestDeliveries.
                 * @implements IFastestDeliveries
                 * @constructor
                 * @param {fastcity.courier.GetOrderList.IFastestDeliveries=} [properties] Properties to set
                 */
                function FastestDeliveries(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * FastestDeliveries limit.
                 * @member {number} limit
                 * @memberof fastcity.courier.GetOrderList.FastestDeliveries
                 * @instance
                 */
                FastestDeliveries.prototype.limit = 0;

                /**
                 * Creates a new FastestDeliveries instance using the specified properties.
                 * @function create
                 * @memberof fastcity.courier.GetOrderList.FastestDeliveries
                 * @static
                 * @param {fastcity.courier.GetOrderList.IFastestDeliveries=} [properties] Properties to set
                 * @returns {fastcity.courier.GetOrderList.FastestDeliveries} FastestDeliveries instance
                 */
                FastestDeliveries.create = function create(properties) {
                    return new FastestDeliveries(properties);
                };

                /**
                 * Encodes the specified FastestDeliveries message. Does not implicitly {@link fastcity.courier.GetOrderList.FastestDeliveries.verify|verify} messages.
                 * @function encode
                 * @memberof fastcity.courier.GetOrderList.FastestDeliveries
                 * @static
                 * @param {fastcity.courier.GetOrderList.IFastestDeliveries} message FastestDeliveries message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FastestDeliveries.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                        writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.limit);
                    return writer;
                };

                /**
                 * Decodes a FastestDeliveries message from the specified reader or buffer.
                 * @function decode
                 * @memberof fastcity.courier.GetOrderList.FastestDeliveries
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {fastcity.courier.GetOrderList.FastestDeliveries} FastestDeliveries
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FastestDeliveries.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.courier.GetOrderList.FastestDeliveries();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.limit = reader.uint32();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Verifies a FastestDeliveries message.
                 * @function verify
                 * @memberof fastcity.courier.GetOrderList.FastestDeliveries
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                FastestDeliveries.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.limit != null && message.hasOwnProperty("limit"))
                        if (!$util.isInteger(message.limit))
                            return "limit: integer expected";
                    return null;
                };

                /**
                 * Creates a FastestDeliveries message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof fastcity.courier.GetOrderList.FastestDeliveries
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {fastcity.courier.GetOrderList.FastestDeliveries} FastestDeliveries
                 */
                FastestDeliveries.fromObject = function fromObject(object) {
                    if (object instanceof $root.fastcity.courier.GetOrderList.FastestDeliveries)
                        return object;
                    var message = new $root.fastcity.courier.GetOrderList.FastestDeliveries();
                    if (object.limit != null)
                        message.limit = object.limit >>> 0;
                    return message;
                };

                /**
                 * Creates a plain object from a FastestDeliveries message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof fastcity.courier.GetOrderList.FastestDeliveries
                 * @static
                 * @param {fastcity.courier.GetOrderList.FastestDeliveries} message FastestDeliveries
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                FastestDeliveries.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.limit = 0;
                    if (message.limit != null && message.hasOwnProperty("limit"))
                        object.limit = message.limit;
                    return object;
                };

                /**
                 * Converts this FastestDeliveries to JSON.
                 * @function toJSON
                 * @memberof fastcity.courier.GetOrderList.FastestDeliveries
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                FastestDeliveries.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return FastestDeliveries;
            })();

            return GetOrderList;
        })();

        courier.GetOrderHistoryList = (function() {

            /**
             * Properties of a GetOrderHistoryList.
             * @memberof fastcity.courier
             * @interface IGetOrderHistoryList
             * @property {fastcity.courier.GetOrderHistoryList.ITimeInterval|null} [timeInterval] GetOrderHistoryList timeInterval
             * @property {Array.<fastcity.common.Order.Status>|null} [status] GetOrderHistoryList status
             */

            /**
             * Constructs a new GetOrderHistoryList.
             * @memberof fastcity.courier
             * @classdesc Represents a GetOrderHistoryList.
             * @implements IGetOrderHistoryList
             * @constructor
             * @param {fastcity.courier.IGetOrderHistoryList=} [properties] Properties to set
             */
            function GetOrderHistoryList(properties) {
                this.status = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GetOrderHistoryList timeInterval.
             * @member {fastcity.courier.GetOrderHistoryList.ITimeInterval|null|undefined} timeInterval
             * @memberof fastcity.courier.GetOrderHistoryList
             * @instance
             */
            GetOrderHistoryList.prototype.timeInterval = null;

            /**
             * GetOrderHistoryList status.
             * @member {Array.<fastcity.common.Order.Status>} status
             * @memberof fastcity.courier.GetOrderHistoryList
             * @instance
             */
            GetOrderHistoryList.prototype.status = $util.emptyArray;

            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;

            /**
             * GetOrderHistoryList type.
             * @member {"timeInterval"|undefined} type
             * @memberof fastcity.courier.GetOrderHistoryList
             * @instance
             */
            Object.defineProperty(GetOrderHistoryList.prototype, "type", {
                get: $util.oneOfGetter($oneOfFields = ["timeInterval"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Creates a new GetOrderHistoryList instance using the specified properties.
             * @function create
             * @memberof fastcity.courier.GetOrderHistoryList
             * @static
             * @param {fastcity.courier.IGetOrderHistoryList=} [properties] Properties to set
             * @returns {fastcity.courier.GetOrderHistoryList} GetOrderHistoryList instance
             */
            GetOrderHistoryList.create = function create(properties) {
                return new GetOrderHistoryList(properties);
            };

            /**
             * Encodes the specified GetOrderHistoryList message. Does not implicitly {@link fastcity.courier.GetOrderHistoryList.verify|verify} messages.
             * @function encode
             * @memberof fastcity.courier.GetOrderHistoryList
             * @static
             * @param {fastcity.courier.IGetOrderHistoryList} message GetOrderHistoryList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetOrderHistoryList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.timeInterval != null && Object.hasOwnProperty.call(message, "timeInterval"))
                    $root.fastcity.courier.GetOrderHistoryList.TimeInterval.encode(message.timeInterval, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.status != null && message.status.length) {
                    writer.uint32(/* id 4, wireType 2 =*/34).fork();
                    for (var i = 0; i < message.status.length; ++i)
                        writer.int32(message.status[i]);
                    writer.ldelim();
                }
                return writer;
            };

            /**
             * Decodes a GetOrderHistoryList message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.courier.GetOrderHistoryList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.courier.GetOrderHistoryList} GetOrderHistoryList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetOrderHistoryList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.courier.GetOrderHistoryList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.timeInterval = $root.fastcity.courier.GetOrderHistoryList.TimeInterval.decode(reader, reader.uint32());
                        break;
                    case 4:
                        if (!(message.status && message.status.length))
                            message.status = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.status.push(reader.int32());
                        } else
                            message.status.push(reader.int32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a GetOrderHistoryList message.
             * @function verify
             * @memberof fastcity.courier.GetOrderHistoryList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetOrderHistoryList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                var properties = {};
                if (message.timeInterval != null && message.hasOwnProperty("timeInterval")) {
                    properties.type = 1;
                    {
                        var error = $root.fastcity.courier.GetOrderHistoryList.TimeInterval.verify(message.timeInterval);
                        if (error)
                            return "timeInterval." + error;
                    }
                }
                if (message.status != null && message.hasOwnProperty("status")) {
                    if (!Array.isArray(message.status))
                        return "status: array expected";
                    for (var i = 0; i < message.status.length; ++i)
                        switch (message.status[i]) {
                        default:
                            return "status: enum value[] expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                        case 9:
                        case 10:
                        case 11:
                        case 12:
                        case 13:
                        case 14:
                        case 15:
                        case 16:
                            break;
                        }
                }
                return null;
            };

            /**
             * Creates a GetOrderHistoryList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.courier.GetOrderHistoryList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.courier.GetOrderHistoryList} GetOrderHistoryList
             */
            GetOrderHistoryList.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.courier.GetOrderHistoryList)
                    return object;
                var message = new $root.fastcity.courier.GetOrderHistoryList();
                if (object.timeInterval != null) {
                    if (typeof object.timeInterval !== "object")
                        throw TypeError(".fastcity.courier.GetOrderHistoryList.timeInterval: object expected");
                    message.timeInterval = $root.fastcity.courier.GetOrderHistoryList.TimeInterval.fromObject(object.timeInterval);
                }
                if (object.status) {
                    if (!Array.isArray(object.status))
                        throw TypeError(".fastcity.courier.GetOrderHistoryList.status: array expected");
                    message.status = [];
                    for (var i = 0; i < object.status.length; ++i)
                        switch (object.status[i]) {
                        default:
                        case "S_UNKNOWN":
                        case 0:
                            message.status[i] = 0;
                            break;
                        case "S_IN_PROGRESS":
                        case 1:
                            message.status[i] = 1;
                            break;
                        case "S_MAKELINE":
                        case 2:
                            message.status[i] = 2;
                            break;
                        case "S_ROUTING_STATION":
                        case 3:
                            message.status[i] = 3;
                            break;
                        case "S_OUT_THE_DOOR":
                        case 4:
                            message.status[i] = 4;
                            break;
                        case "S_COMPLETE":
                        case 5:
                            message.status[i] = 5;
                            break;
                        case "S_GIFT":
                        case 6:
                            message.status[i] = 6;
                            break;
                        case "S_BAD":
                        case 7:
                            message.status[i] = 7;
                            break;
                        case "S_VOID":
                        case 8:
                            message.status[i] = 8;
                            break;
                        case "S_BEING_TAKEN":
                        case 9:
                            message.status[i] = 9;
                            break;
                        case "S_ABANDONED":
                        case 10:
                            message.status[i] = 10;
                            break;
                        case "S_SUSPENDED":
                        case 11:
                            message.status[i] = 11;
                            break;
                        case "S_GIFT_CARD_PURCHASE":
                        case 12:
                            message.status[i] = 12;
                            break;
                        case "S_GIFT_CARD_VOID":
                        case 13:
                            message.status[i] = 13;
                            break;
                        case "S_TRANSFERRED":
                        case 14:
                            message.status[i] = 14;
                            break;
                        case "S_HISTORIC_ORDER":
                        case 15:
                            message.status[i] = 15;
                            break;
                        case "S_BEING_ASSIGNED":
                        case 16:
                            message.status[i] = 16;
                            break;
                        }
                }
                return message;
            };

            /**
             * Creates a plain object from a GetOrderHistoryList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.courier.GetOrderHistoryList
             * @static
             * @param {fastcity.courier.GetOrderHistoryList} message GetOrderHistoryList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetOrderHistoryList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.status = [];
                if (message.timeInterval != null && message.hasOwnProperty("timeInterval")) {
                    object.timeInterval = $root.fastcity.courier.GetOrderHistoryList.TimeInterval.toObject(message.timeInterval, options);
                    if (options.oneofs)
                        object.type = "timeInterval";
                }
                if (message.status && message.status.length) {
                    object.status = [];
                    for (var j = 0; j < message.status.length; ++j)
                        object.status[j] = options.enums === String ? $root.fastcity.common.Order.Status[message.status[j]] : message.status[j];
                }
                return object;
            };

            /**
             * Converts this GetOrderHistoryList to JSON.
             * @function toJSON
             * @memberof fastcity.courier.GetOrderHistoryList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetOrderHistoryList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            GetOrderHistoryList.TimeInterval = (function() {

                /**
                 * Properties of a TimeInterval.
                 * @memberof fastcity.courier.GetOrderHistoryList
                 * @interface ITimeInterval
                 * @property {number|Long|null} [fromTime] TimeInterval fromTime
                 * @property {number|Long|null} [toTime] TimeInterval toTime
                 * @property {number|null} [offset] TimeInterval offset
                 * @property {number|null} [limit] TimeInterval limit
                 * @property {fastcity.common.SortOrder.Order|null} [sortOrder] TimeInterval sortOrder
                 */

                /**
                 * Constructs a new TimeInterval.
                 * @memberof fastcity.courier.GetOrderHistoryList
                 * @classdesc Represents a TimeInterval.
                 * @implements ITimeInterval
                 * @constructor
                 * @param {fastcity.courier.GetOrderHistoryList.ITimeInterval=} [properties] Properties to set
                 */
                function TimeInterval(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * TimeInterval fromTime.
                 * @member {number|Long} fromTime
                 * @memberof fastcity.courier.GetOrderHistoryList.TimeInterval
                 * @instance
                 */
                TimeInterval.prototype.fromTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * TimeInterval toTime.
                 * @member {number|Long} toTime
                 * @memberof fastcity.courier.GetOrderHistoryList.TimeInterval
                 * @instance
                 */
                TimeInterval.prototype.toTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * TimeInterval offset.
                 * @member {number} offset
                 * @memberof fastcity.courier.GetOrderHistoryList.TimeInterval
                 * @instance
                 */
                TimeInterval.prototype.offset = 0;

                /**
                 * TimeInterval limit.
                 * @member {number} limit
                 * @memberof fastcity.courier.GetOrderHistoryList.TimeInterval
                 * @instance
                 */
                TimeInterval.prototype.limit = 0;

                /**
                 * TimeInterval sortOrder.
                 * @member {fastcity.common.SortOrder.Order} sortOrder
                 * @memberof fastcity.courier.GetOrderHistoryList.TimeInterval
                 * @instance
                 */
                TimeInterval.prototype.sortOrder = 0;

                /**
                 * Creates a new TimeInterval instance using the specified properties.
                 * @function create
                 * @memberof fastcity.courier.GetOrderHistoryList.TimeInterval
                 * @static
                 * @param {fastcity.courier.GetOrderHistoryList.ITimeInterval=} [properties] Properties to set
                 * @returns {fastcity.courier.GetOrderHistoryList.TimeInterval} TimeInterval instance
                 */
                TimeInterval.create = function create(properties) {
                    return new TimeInterval(properties);
                };

                /**
                 * Encodes the specified TimeInterval message. Does not implicitly {@link fastcity.courier.GetOrderHistoryList.TimeInterval.verify|verify} messages.
                 * @function encode
                 * @memberof fastcity.courier.GetOrderHistoryList.TimeInterval
                 * @static
                 * @param {fastcity.courier.GetOrderHistoryList.ITimeInterval} message TimeInterval message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                TimeInterval.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.fromTime != null && Object.hasOwnProperty.call(message, "fromTime"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int64(message.fromTime);
                    if (message.toTime != null && Object.hasOwnProperty.call(message, "toTime"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int64(message.toTime);
                    if (message.offset != null && Object.hasOwnProperty.call(message, "offset"))
                        writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.offset);
                    if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                        writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.limit);
                    if (message.sortOrder != null && Object.hasOwnProperty.call(message, "sortOrder"))
                        writer.uint32(/* id 5, wireType 0 =*/40).int32(message.sortOrder);
                    return writer;
                };

                /**
                 * Decodes a TimeInterval message from the specified reader or buffer.
                 * @function decode
                 * @memberof fastcity.courier.GetOrderHistoryList.TimeInterval
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {fastcity.courier.GetOrderHistoryList.TimeInterval} TimeInterval
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                TimeInterval.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.courier.GetOrderHistoryList.TimeInterval();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.fromTime = reader.int64();
                            break;
                        case 2:
                            message.toTime = reader.int64();
                            break;
                        case 3:
                            message.offset = reader.uint32();
                            break;
                        case 4:
                            message.limit = reader.uint32();
                            break;
                        case 5:
                            message.sortOrder = reader.int32();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Verifies a TimeInterval message.
                 * @function verify
                 * @memberof fastcity.courier.GetOrderHistoryList.TimeInterval
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                TimeInterval.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.fromTime != null && message.hasOwnProperty("fromTime"))
                        if (!$util.isInteger(message.fromTime) && !(message.fromTime && $util.isInteger(message.fromTime.low) && $util.isInteger(message.fromTime.high)))
                            return "fromTime: integer|Long expected";
                    if (message.toTime != null && message.hasOwnProperty("toTime"))
                        if (!$util.isInteger(message.toTime) && !(message.toTime && $util.isInteger(message.toTime.low) && $util.isInteger(message.toTime.high)))
                            return "toTime: integer|Long expected";
                    if (message.offset != null && message.hasOwnProperty("offset"))
                        if (!$util.isInteger(message.offset))
                            return "offset: integer expected";
                    if (message.limit != null && message.hasOwnProperty("limit"))
                        if (!$util.isInteger(message.limit))
                            return "limit: integer expected";
                    if (message.sortOrder != null && message.hasOwnProperty("sortOrder"))
                        switch (message.sortOrder) {
                        default:
                            return "sortOrder: enum value expected";
                        case 0:
                        case 1:
                            break;
                        }
                    return null;
                };

                /**
                 * Creates a TimeInterval message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof fastcity.courier.GetOrderHistoryList.TimeInterval
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {fastcity.courier.GetOrderHistoryList.TimeInterval} TimeInterval
                 */
                TimeInterval.fromObject = function fromObject(object) {
                    if (object instanceof $root.fastcity.courier.GetOrderHistoryList.TimeInterval)
                        return object;
                    var message = new $root.fastcity.courier.GetOrderHistoryList.TimeInterval();
                    if (object.fromTime != null)
                        if ($util.Long)
                            (message.fromTime = $util.Long.fromValue(object.fromTime)).unsigned = false;
                        else if (typeof object.fromTime === "string")
                            message.fromTime = parseInt(object.fromTime, 10);
                        else if (typeof object.fromTime === "number")
                            message.fromTime = object.fromTime;
                        else if (typeof object.fromTime === "object")
                            message.fromTime = new $util.LongBits(object.fromTime.low >>> 0, object.fromTime.high >>> 0).toNumber();
                    if (object.toTime != null)
                        if ($util.Long)
                            (message.toTime = $util.Long.fromValue(object.toTime)).unsigned = false;
                        else if (typeof object.toTime === "string")
                            message.toTime = parseInt(object.toTime, 10);
                        else if (typeof object.toTime === "number")
                            message.toTime = object.toTime;
                        else if (typeof object.toTime === "object")
                            message.toTime = new $util.LongBits(object.toTime.low >>> 0, object.toTime.high >>> 0).toNumber();
                    if (object.offset != null)
                        message.offset = object.offset >>> 0;
                    if (object.limit != null)
                        message.limit = object.limit >>> 0;
                    switch (object.sortOrder) {
                    case "ASC":
                    case 0:
                        message.sortOrder = 0;
                        break;
                    case "DESC":
                    case 1:
                        message.sortOrder = 1;
                        break;
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a TimeInterval message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof fastcity.courier.GetOrderHistoryList.TimeInterval
                 * @static
                 * @param {fastcity.courier.GetOrderHistoryList.TimeInterval} message TimeInterval
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                TimeInterval.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.fromTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.fromTime = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.toTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.toTime = options.longs === String ? "0" : 0;
                        object.offset = 0;
                        object.limit = 0;
                        object.sortOrder = options.enums === String ? "ASC" : 0;
                    }
                    if (message.fromTime != null && message.hasOwnProperty("fromTime"))
                        if (typeof message.fromTime === "number")
                            object.fromTime = options.longs === String ? String(message.fromTime) : message.fromTime;
                        else
                            object.fromTime = options.longs === String ? $util.Long.prototype.toString.call(message.fromTime) : options.longs === Number ? new $util.LongBits(message.fromTime.low >>> 0, message.fromTime.high >>> 0).toNumber() : message.fromTime;
                    if (message.toTime != null && message.hasOwnProperty("toTime"))
                        if (typeof message.toTime === "number")
                            object.toTime = options.longs === String ? String(message.toTime) : message.toTime;
                        else
                            object.toTime = options.longs === String ? $util.Long.prototype.toString.call(message.toTime) : options.longs === Number ? new $util.LongBits(message.toTime.low >>> 0, message.toTime.high >>> 0).toNumber() : message.toTime;
                    if (message.offset != null && message.hasOwnProperty("offset"))
                        object.offset = message.offset;
                    if (message.limit != null && message.hasOwnProperty("limit"))
                        object.limit = message.limit;
                    if (message.sortOrder != null && message.hasOwnProperty("sortOrder"))
                        object.sortOrder = options.enums === String ? $root.fastcity.common.SortOrder.Order[message.sortOrder] : message.sortOrder;
                    return object;
                };

                /**
                 * Converts this TimeInterval to JSON.
                 * @function toJSON
                 * @memberof fastcity.courier.GetOrderHistoryList.TimeInterval
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                TimeInterval.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return TimeInterval;
            })();

            return GetOrderHistoryList;
        })();

        courier.OrderList = (function() {

            /**
             * Properties of an OrderList.
             * @memberof fastcity.courier
             * @interface IOrderList
             * @property {Array.<fastcity.courier.IOrder>|null} [order] OrderList order
             * @property {number|null} [totalOrders] OrderList totalOrders
             */

            /**
             * Constructs a new OrderList.
             * @memberof fastcity.courier
             * @classdesc Represents an OrderList.
             * @implements IOrderList
             * @constructor
             * @param {fastcity.courier.IOrderList=} [properties] Properties to set
             */
            function OrderList(properties) {
                this.order = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * OrderList order.
             * @member {Array.<fastcity.courier.IOrder>} order
             * @memberof fastcity.courier.OrderList
             * @instance
             */
            OrderList.prototype.order = $util.emptyArray;

            /**
             * OrderList totalOrders.
             * @member {number} totalOrders
             * @memberof fastcity.courier.OrderList
             * @instance
             */
            OrderList.prototype.totalOrders = 0;

            /**
             * Creates a new OrderList instance using the specified properties.
             * @function create
             * @memberof fastcity.courier.OrderList
             * @static
             * @param {fastcity.courier.IOrderList=} [properties] Properties to set
             * @returns {fastcity.courier.OrderList} OrderList instance
             */
            OrderList.create = function create(properties) {
                return new OrderList(properties);
            };

            /**
             * Encodes the specified OrderList message. Does not implicitly {@link fastcity.courier.OrderList.verify|verify} messages.
             * @function encode
             * @memberof fastcity.courier.OrderList
             * @static
             * @param {fastcity.courier.IOrderList} message OrderList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            OrderList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.order != null && message.order.length)
                    for (var i = 0; i < message.order.length; ++i)
                        $root.fastcity.courier.Order.encode(message.order[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.totalOrders != null && Object.hasOwnProperty.call(message, "totalOrders"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.totalOrders);
                return writer;
            };

            /**
             * Decodes an OrderList message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.courier.OrderList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.courier.OrderList} OrderList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            OrderList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.courier.OrderList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.order && message.order.length))
                            message.order = [];
                        message.order.push($root.fastcity.courier.Order.decode(reader, reader.uint32()));
                        break;
                    case 2:
                        message.totalOrders = reader.uint32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an OrderList message.
             * @function verify
             * @memberof fastcity.courier.OrderList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            OrderList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.order != null && message.hasOwnProperty("order")) {
                    if (!Array.isArray(message.order))
                        return "order: array expected";
                    for (var i = 0; i < message.order.length; ++i) {
                        var error = $root.fastcity.courier.Order.verify(message.order[i]);
                        if (error)
                            return "order." + error;
                    }
                }
                if (message.totalOrders != null && message.hasOwnProperty("totalOrders"))
                    if (!$util.isInteger(message.totalOrders))
                        return "totalOrders: integer expected";
                return null;
            };

            /**
             * Creates an OrderList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.courier.OrderList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.courier.OrderList} OrderList
             */
            OrderList.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.courier.OrderList)
                    return object;
                var message = new $root.fastcity.courier.OrderList();
                if (object.order) {
                    if (!Array.isArray(object.order))
                        throw TypeError(".fastcity.courier.OrderList.order: array expected");
                    message.order = [];
                    for (var i = 0; i < object.order.length; ++i) {
                        if (typeof object.order[i] !== "object")
                            throw TypeError(".fastcity.courier.OrderList.order: object expected");
                        message.order[i] = $root.fastcity.courier.Order.fromObject(object.order[i]);
                    }
                }
                if (object.totalOrders != null)
                    message.totalOrders = object.totalOrders >>> 0;
                return message;
            };

            /**
             * Creates a plain object from an OrderList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.courier.OrderList
             * @static
             * @param {fastcity.courier.OrderList} message OrderList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            OrderList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.order = [];
                if (options.defaults)
                    object.totalOrders = 0;
                if (message.order && message.order.length) {
                    object.order = [];
                    for (var j = 0; j < message.order.length; ++j)
                        object.order[j] = $root.fastcity.courier.Order.toObject(message.order[j], options);
                }
                if (message.totalOrders != null && message.hasOwnProperty("totalOrders"))
                    object.totalOrders = message.totalOrders;
                return object;
            };

            /**
             * Converts this OrderList to JSON.
             * @function toJSON
             * @memberof fastcity.courier.OrderList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            OrderList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return OrderList;
        })();

        courier.FinishOrder = (function() {

            /**
             * Properties of a FinishOrder.
             * @memberof fastcity.courier
             * @interface IFinishOrder
             * @property {number|Long|null} [orderId] FinishOrder orderId
             * @property {string|null} [externalOrderId] FinishOrder externalOrderId
             * @property {fastcity.courier.ILocation|null} [location] FinishOrder location
             * @property {number|Long|null} [timestampFinish] FinishOrder timestampFinish
             */

            /**
             * Constructs a new FinishOrder.
             * @memberof fastcity.courier
             * @classdesc Represents a FinishOrder.
             * @implements IFinishOrder
             * @constructor
             * @param {fastcity.courier.IFinishOrder=} [properties] Properties to set
             */
            function FinishOrder(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * FinishOrder orderId.
             * @member {number|Long} orderId
             * @memberof fastcity.courier.FinishOrder
             * @instance
             */
            FinishOrder.prototype.orderId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * FinishOrder externalOrderId.
             * @member {string} externalOrderId
             * @memberof fastcity.courier.FinishOrder
             * @instance
             */
            FinishOrder.prototype.externalOrderId = "";

            /**
             * FinishOrder location.
             * @member {fastcity.courier.ILocation|null|undefined} location
             * @memberof fastcity.courier.FinishOrder
             * @instance
             */
            FinishOrder.prototype.location = null;

            /**
             * FinishOrder timestampFinish.
             * @member {number|Long} timestampFinish
             * @memberof fastcity.courier.FinishOrder
             * @instance
             */
            FinishOrder.prototype.timestampFinish = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Creates a new FinishOrder instance using the specified properties.
             * @function create
             * @memberof fastcity.courier.FinishOrder
             * @static
             * @param {fastcity.courier.IFinishOrder=} [properties] Properties to set
             * @returns {fastcity.courier.FinishOrder} FinishOrder instance
             */
            FinishOrder.create = function create(properties) {
                return new FinishOrder(properties);
            };

            /**
             * Encodes the specified FinishOrder message. Does not implicitly {@link fastcity.courier.FinishOrder.verify|verify} messages.
             * @function encode
             * @memberof fastcity.courier.FinishOrder
             * @static
             * @param {fastcity.courier.IFinishOrder} message FinishOrder message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FinishOrder.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.externalOrderId != null && Object.hasOwnProperty.call(message, "externalOrderId"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.externalOrderId);
                if (message.location != null && Object.hasOwnProperty.call(message, "location"))
                    $root.fastcity.courier.Location.encode(message.location, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.timestampFinish != null && Object.hasOwnProperty.call(message, "timestampFinish"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int64(message.timestampFinish);
                if (message.orderId != null && Object.hasOwnProperty.call(message, "orderId"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int64(message.orderId);
                return writer;
            };

            /**
             * Decodes a FinishOrder message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.courier.FinishOrder
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.courier.FinishOrder} FinishOrder
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FinishOrder.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.courier.FinishOrder();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 4:
                        message.orderId = reader.int64();
                        break;
                    case 1:
                        message.externalOrderId = reader.string();
                        break;
                    case 2:
                        message.location = $root.fastcity.courier.Location.decode(reader, reader.uint32());
                        break;
                    case 3:
                        message.timestampFinish = reader.int64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a FinishOrder message.
             * @function verify
             * @memberof fastcity.courier.FinishOrder
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            FinishOrder.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.orderId != null && message.hasOwnProperty("orderId"))
                    if (!$util.isInteger(message.orderId) && !(message.orderId && $util.isInteger(message.orderId.low) && $util.isInteger(message.orderId.high)))
                        return "orderId: integer|Long expected";
                if (message.externalOrderId != null && message.hasOwnProperty("externalOrderId"))
                    if (!$util.isString(message.externalOrderId))
                        return "externalOrderId: string expected";
                if (message.location != null && message.hasOwnProperty("location")) {
                    var error = $root.fastcity.courier.Location.verify(message.location);
                    if (error)
                        return "location." + error;
                }
                if (message.timestampFinish != null && message.hasOwnProperty("timestampFinish"))
                    if (!$util.isInteger(message.timestampFinish) && !(message.timestampFinish && $util.isInteger(message.timestampFinish.low) && $util.isInteger(message.timestampFinish.high)))
                        return "timestampFinish: integer|Long expected";
                return null;
            };

            /**
             * Creates a FinishOrder message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.courier.FinishOrder
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.courier.FinishOrder} FinishOrder
             */
            FinishOrder.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.courier.FinishOrder)
                    return object;
                var message = new $root.fastcity.courier.FinishOrder();
                if (object.orderId != null)
                    if ($util.Long)
                        (message.orderId = $util.Long.fromValue(object.orderId)).unsigned = false;
                    else if (typeof object.orderId === "string")
                        message.orderId = parseInt(object.orderId, 10);
                    else if (typeof object.orderId === "number")
                        message.orderId = object.orderId;
                    else if (typeof object.orderId === "object")
                        message.orderId = new $util.LongBits(object.orderId.low >>> 0, object.orderId.high >>> 0).toNumber();
                if (object.externalOrderId != null)
                    message.externalOrderId = String(object.externalOrderId);
                if (object.location != null) {
                    if (typeof object.location !== "object")
                        throw TypeError(".fastcity.courier.FinishOrder.location: object expected");
                    message.location = $root.fastcity.courier.Location.fromObject(object.location);
                }
                if (object.timestampFinish != null)
                    if ($util.Long)
                        (message.timestampFinish = $util.Long.fromValue(object.timestampFinish)).unsigned = false;
                    else if (typeof object.timestampFinish === "string")
                        message.timestampFinish = parseInt(object.timestampFinish, 10);
                    else if (typeof object.timestampFinish === "number")
                        message.timestampFinish = object.timestampFinish;
                    else if (typeof object.timestampFinish === "object")
                        message.timestampFinish = new $util.LongBits(object.timestampFinish.low >>> 0, object.timestampFinish.high >>> 0).toNumber();
                return message;
            };

            /**
             * Creates a plain object from a FinishOrder message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.courier.FinishOrder
             * @static
             * @param {fastcity.courier.FinishOrder} message FinishOrder
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FinishOrder.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.externalOrderId = "";
                    object.location = null;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.timestampFinish = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.timestampFinish = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.orderId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.orderId = options.longs === String ? "0" : 0;
                }
                if (message.externalOrderId != null && message.hasOwnProperty("externalOrderId"))
                    object.externalOrderId = message.externalOrderId;
                if (message.location != null && message.hasOwnProperty("location"))
                    object.location = $root.fastcity.courier.Location.toObject(message.location, options);
                if (message.timestampFinish != null && message.hasOwnProperty("timestampFinish"))
                    if (typeof message.timestampFinish === "number")
                        object.timestampFinish = options.longs === String ? String(message.timestampFinish) : message.timestampFinish;
                    else
                        object.timestampFinish = options.longs === String ? $util.Long.prototype.toString.call(message.timestampFinish) : options.longs === Number ? new $util.LongBits(message.timestampFinish.low >>> 0, message.timestampFinish.high >>> 0).toNumber() : message.timestampFinish;
                if (message.orderId != null && message.hasOwnProperty("orderId"))
                    if (typeof message.orderId === "number")
                        object.orderId = options.longs === String ? String(message.orderId) : message.orderId;
                    else
                        object.orderId = options.longs === String ? $util.Long.prototype.toString.call(message.orderId) : options.longs === Number ? new $util.LongBits(message.orderId.low >>> 0, message.orderId.high >>> 0).toNumber() : message.orderId;
                return object;
            };

            /**
             * Converts this FinishOrder to JSON.
             * @function toJSON
             * @memberof fastcity.courier.FinishOrder
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FinishOrder.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return FinishOrder;
        })();

        courier.SetClientAddress = (function() {

            /**
             * Properties of a SetClientAddress.
             * @memberof fastcity.courier
             * @interface ISetClientAddress
             * @property {number|Long|null} [orderId] SetClientAddress orderId
             * @property {string|null} [clientAddress] SetClientAddress clientAddress
             */

            /**
             * Constructs a new SetClientAddress.
             * @memberof fastcity.courier
             * @classdesc Represents a SetClientAddress.
             * @implements ISetClientAddress
             * @constructor
             * @param {fastcity.courier.ISetClientAddress=} [properties] Properties to set
             */
            function SetClientAddress(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * SetClientAddress orderId.
             * @member {number|Long} orderId
             * @memberof fastcity.courier.SetClientAddress
             * @instance
             */
            SetClientAddress.prototype.orderId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * SetClientAddress clientAddress.
             * @member {string} clientAddress
             * @memberof fastcity.courier.SetClientAddress
             * @instance
             */
            SetClientAddress.prototype.clientAddress = "";

            /**
             * Creates a new SetClientAddress instance using the specified properties.
             * @function create
             * @memberof fastcity.courier.SetClientAddress
             * @static
             * @param {fastcity.courier.ISetClientAddress=} [properties] Properties to set
             * @returns {fastcity.courier.SetClientAddress} SetClientAddress instance
             */
            SetClientAddress.create = function create(properties) {
                return new SetClientAddress(properties);
            };

            /**
             * Encodes the specified SetClientAddress message. Does not implicitly {@link fastcity.courier.SetClientAddress.verify|verify} messages.
             * @function encode
             * @memberof fastcity.courier.SetClientAddress
             * @static
             * @param {fastcity.courier.ISetClientAddress} message SetClientAddress message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SetClientAddress.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.orderId != null && Object.hasOwnProperty.call(message, "orderId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.orderId);
                if (message.clientAddress != null && Object.hasOwnProperty.call(message, "clientAddress"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.clientAddress);
                return writer;
            };

            /**
             * Decodes a SetClientAddress message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.courier.SetClientAddress
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.courier.SetClientAddress} SetClientAddress
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SetClientAddress.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.courier.SetClientAddress();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.orderId = reader.int64();
                        break;
                    case 2:
                        message.clientAddress = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a SetClientAddress message.
             * @function verify
             * @memberof fastcity.courier.SetClientAddress
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SetClientAddress.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.orderId != null && message.hasOwnProperty("orderId"))
                    if (!$util.isInteger(message.orderId) && !(message.orderId && $util.isInteger(message.orderId.low) && $util.isInteger(message.orderId.high)))
                        return "orderId: integer|Long expected";
                if (message.clientAddress != null && message.hasOwnProperty("clientAddress"))
                    if (!$util.isString(message.clientAddress))
                        return "clientAddress: string expected";
                return null;
            };

            /**
             * Creates a SetClientAddress message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.courier.SetClientAddress
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.courier.SetClientAddress} SetClientAddress
             */
            SetClientAddress.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.courier.SetClientAddress)
                    return object;
                var message = new $root.fastcity.courier.SetClientAddress();
                if (object.orderId != null)
                    if ($util.Long)
                        (message.orderId = $util.Long.fromValue(object.orderId)).unsigned = false;
                    else if (typeof object.orderId === "string")
                        message.orderId = parseInt(object.orderId, 10);
                    else if (typeof object.orderId === "number")
                        message.orderId = object.orderId;
                    else if (typeof object.orderId === "object")
                        message.orderId = new $util.LongBits(object.orderId.low >>> 0, object.orderId.high >>> 0).toNumber();
                if (object.clientAddress != null)
                    message.clientAddress = String(object.clientAddress);
                return message;
            };

            /**
             * Creates a plain object from a SetClientAddress message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.courier.SetClientAddress
             * @static
             * @param {fastcity.courier.SetClientAddress} message SetClientAddress
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SetClientAddress.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.orderId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.orderId = options.longs === String ? "0" : 0;
                    object.clientAddress = "";
                }
                if (message.orderId != null && message.hasOwnProperty("orderId"))
                    if (typeof message.orderId === "number")
                        object.orderId = options.longs === String ? String(message.orderId) : message.orderId;
                    else
                        object.orderId = options.longs === String ? $util.Long.prototype.toString.call(message.orderId) : options.longs === Number ? new $util.LongBits(message.orderId.low >>> 0, message.orderId.high >>> 0).toNumber() : message.orderId;
                if (message.clientAddress != null && message.hasOwnProperty("clientAddress"))
                    object.clientAddress = message.clientAddress;
                return object;
            };

            /**
             * Converts this SetClientAddress to JSON.
             * @function toJSON
             * @memberof fastcity.courier.SetClientAddress
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SetClientAddress.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return SetClientAddress;
        })();

        courier.ClientMessage = (function() {

            /**
             * Properties of a ClientMessage.
             * @memberof fastcity.courier
             * @interface IClientMessage
             * @property {number|Long|null} [requestId] ClientMessage requestId
             * @property {fastcity.courier.ILogon|null} [logon] ClientMessage logon
             * @property {fastcity.courier.ILocation|null} [location] ClientMessage location
             * @property {fastcity.courier.IGetOrderList|null} [getOrderList] ClientMessage getOrderList
             * @property {fastcity.courier.IFinishOrder|null} [finishOrder] ClientMessage finishOrder
             * @property {fastcity.courier.IGetCurrentCourier|null} [getCurrentCourier] ClientMessage getCurrentCourier
             * @property {fastcity.courier.IGetDeliveryStatDayList|null} [getDeliveryStatDayList] ClientMessage getDeliveryStatDayList
             * @property {fastcity.courier.IGetCurrentDeliveryStat|null} [getCurrentDeliveryStat] ClientMessage getCurrentDeliveryStat
             * @property {fastcity.courier.IGetWorkingTime|null} [getWorkingTime] ClientMessage getWorkingTime
             * @property {fastcity.courier.ISetClientAddress|null} [setClientAddress] ClientMessage setClientAddress
             * @property {fastcity.courier.IHeartbeat|null} [heartbeat] ClientMessage heartbeat
             * @property {fastcity.courier.IGetOrderHistoryList|null} [getOrderHistoryList] ClientMessage getOrderHistoryList
             * @property {fastcity.courier.IAcceptOrder|null} [acceptOrder] ClientMessage acceptOrder
             * @property {fastcity.courier.IGetSubCompanyList|null} [getSubCompanyList] ClientMessage getSubCompanyList
             * @property {fastcity.common.ISetOrderPath|null} [setOrderPath] ClientMessage setOrderPath
             * @property {fastcity.common.IGetOrderPath|null} [getOrderPath] ClientMessage getOrderPath
             * @property {fastcity.common.IGetOrderPathList|null} [getOrderPathList] ClientMessage getOrderPathList
             * @property {fastcity.courier.IUpdateMinimalDistanceToOrderTarget|null} [updateMinimalDistanceToOrderTarget] ClientMessage updateMinimalDistanceToOrderTarget
             */

            /**
             * Constructs a new ClientMessage.
             * @memberof fastcity.courier
             * @classdesc Represents a ClientMessage.
             * @implements IClientMessage
             * @constructor
             * @param {fastcity.courier.IClientMessage=} [properties] Properties to set
             */
            function ClientMessage(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ClientMessage requestId.
             * @member {number|Long} requestId
             * @memberof fastcity.courier.ClientMessage
             * @instance
             */
            ClientMessage.prototype.requestId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * ClientMessage logon.
             * @member {fastcity.courier.ILogon|null|undefined} logon
             * @memberof fastcity.courier.ClientMessage
             * @instance
             */
            ClientMessage.prototype.logon = null;

            /**
             * ClientMessage location.
             * @member {fastcity.courier.ILocation|null|undefined} location
             * @memberof fastcity.courier.ClientMessage
             * @instance
             */
            ClientMessage.prototype.location = null;

            /**
             * ClientMessage getOrderList.
             * @member {fastcity.courier.IGetOrderList|null|undefined} getOrderList
             * @memberof fastcity.courier.ClientMessage
             * @instance
             */
            ClientMessage.prototype.getOrderList = null;

            /**
             * ClientMessage finishOrder.
             * @member {fastcity.courier.IFinishOrder|null|undefined} finishOrder
             * @memberof fastcity.courier.ClientMessage
             * @instance
             */
            ClientMessage.prototype.finishOrder = null;

            /**
             * ClientMessage getCurrentCourier.
             * @member {fastcity.courier.IGetCurrentCourier|null|undefined} getCurrentCourier
             * @memberof fastcity.courier.ClientMessage
             * @instance
             */
            ClientMessage.prototype.getCurrentCourier = null;

            /**
             * ClientMessage getDeliveryStatDayList.
             * @member {fastcity.courier.IGetDeliveryStatDayList|null|undefined} getDeliveryStatDayList
             * @memberof fastcity.courier.ClientMessage
             * @instance
             */
            ClientMessage.prototype.getDeliveryStatDayList = null;

            /**
             * ClientMessage getCurrentDeliveryStat.
             * @member {fastcity.courier.IGetCurrentDeliveryStat|null|undefined} getCurrentDeliveryStat
             * @memberof fastcity.courier.ClientMessage
             * @instance
             */
            ClientMessage.prototype.getCurrentDeliveryStat = null;

            /**
             * ClientMessage getWorkingTime.
             * @member {fastcity.courier.IGetWorkingTime|null|undefined} getWorkingTime
             * @memberof fastcity.courier.ClientMessage
             * @instance
             */
            ClientMessage.prototype.getWorkingTime = null;

            /**
             * ClientMessage setClientAddress.
             * @member {fastcity.courier.ISetClientAddress|null|undefined} setClientAddress
             * @memberof fastcity.courier.ClientMessage
             * @instance
             */
            ClientMessage.prototype.setClientAddress = null;

            /**
             * ClientMessage heartbeat.
             * @member {fastcity.courier.IHeartbeat|null|undefined} heartbeat
             * @memberof fastcity.courier.ClientMessage
             * @instance
             */
            ClientMessage.prototype.heartbeat = null;

            /**
             * ClientMessage getOrderHistoryList.
             * @member {fastcity.courier.IGetOrderHistoryList|null|undefined} getOrderHistoryList
             * @memberof fastcity.courier.ClientMessage
             * @instance
             */
            ClientMessage.prototype.getOrderHistoryList = null;

            /**
             * ClientMessage acceptOrder.
             * @member {fastcity.courier.IAcceptOrder|null|undefined} acceptOrder
             * @memberof fastcity.courier.ClientMessage
             * @instance
             */
            ClientMessage.prototype.acceptOrder = null;

            /**
             * ClientMessage getSubCompanyList.
             * @member {fastcity.courier.IGetSubCompanyList|null|undefined} getSubCompanyList
             * @memberof fastcity.courier.ClientMessage
             * @instance
             */
            ClientMessage.prototype.getSubCompanyList = null;

            /**
             * ClientMessage setOrderPath.
             * @member {fastcity.common.ISetOrderPath|null|undefined} setOrderPath
             * @memberof fastcity.courier.ClientMessage
             * @instance
             */
            ClientMessage.prototype.setOrderPath = null;

            /**
             * ClientMessage getOrderPath.
             * @member {fastcity.common.IGetOrderPath|null|undefined} getOrderPath
             * @memberof fastcity.courier.ClientMessage
             * @instance
             */
            ClientMessage.prototype.getOrderPath = null;

            /**
             * ClientMessage getOrderPathList.
             * @member {fastcity.common.IGetOrderPathList|null|undefined} getOrderPathList
             * @memberof fastcity.courier.ClientMessage
             * @instance
             */
            ClientMessage.prototype.getOrderPathList = null;

            /**
             * ClientMessage updateMinimalDistanceToOrderTarget.
             * @member {fastcity.courier.IUpdateMinimalDistanceToOrderTarget|null|undefined} updateMinimalDistanceToOrderTarget
             * @memberof fastcity.courier.ClientMessage
             * @instance
             */
            ClientMessage.prototype.updateMinimalDistanceToOrderTarget = null;

            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;

            /**
             * ClientMessage body.
             * @member {"logon"|"location"|"getOrderList"|"finishOrder"|"getCurrentCourier"|"getDeliveryStatDayList"|"getCurrentDeliveryStat"|"getWorkingTime"|"setClientAddress"|"heartbeat"|"getOrderHistoryList"|"acceptOrder"|"getSubCompanyList"|"setOrderPath"|"getOrderPath"|"getOrderPathList"|"updateMinimalDistanceToOrderTarget"|undefined} body
             * @memberof fastcity.courier.ClientMessage
             * @instance
             */
            Object.defineProperty(ClientMessage.prototype, "body", {
                get: $util.oneOfGetter($oneOfFields = ["logon", "location", "getOrderList", "finishOrder", "getCurrentCourier", "getDeliveryStatDayList", "getCurrentDeliveryStat", "getWorkingTime", "setClientAddress", "heartbeat", "getOrderHistoryList", "acceptOrder", "getSubCompanyList", "setOrderPath", "getOrderPath", "getOrderPathList", "updateMinimalDistanceToOrderTarget"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Creates a new ClientMessage instance using the specified properties.
             * @function create
             * @memberof fastcity.courier.ClientMessage
             * @static
             * @param {fastcity.courier.IClientMessage=} [properties] Properties to set
             * @returns {fastcity.courier.ClientMessage} ClientMessage instance
             */
            ClientMessage.create = function create(properties) {
                return new ClientMessage(properties);
            };

            /**
             * Encodes the specified ClientMessage message. Does not implicitly {@link fastcity.courier.ClientMessage.verify|verify} messages.
             * @function encode
             * @memberof fastcity.courier.ClientMessage
             * @static
             * @param {fastcity.courier.IClientMessage} message ClientMessage message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ClientMessage.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.requestId != null && Object.hasOwnProperty.call(message, "requestId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.requestId);
                if (message.logon != null && Object.hasOwnProperty.call(message, "logon"))
                    $root.fastcity.courier.Logon.encode(message.logon, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.location != null && Object.hasOwnProperty.call(message, "location"))
                    $root.fastcity.courier.Location.encode(message.location, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.getOrderList != null && Object.hasOwnProperty.call(message, "getOrderList"))
                    $root.fastcity.courier.GetOrderList.encode(message.getOrderList, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.finishOrder != null && Object.hasOwnProperty.call(message, "finishOrder"))
                    $root.fastcity.courier.FinishOrder.encode(message.finishOrder, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.getCurrentCourier != null && Object.hasOwnProperty.call(message, "getCurrentCourier"))
                    $root.fastcity.courier.GetCurrentCourier.encode(message.getCurrentCourier, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.getDeliveryStatDayList != null && Object.hasOwnProperty.call(message, "getDeliveryStatDayList"))
                    $root.fastcity.courier.GetDeliveryStatDayList.encode(message.getDeliveryStatDayList, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                if (message.getCurrentDeliveryStat != null && Object.hasOwnProperty.call(message, "getCurrentDeliveryStat"))
                    $root.fastcity.courier.GetCurrentDeliveryStat.encode(message.getCurrentDeliveryStat, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                if (message.getWorkingTime != null && Object.hasOwnProperty.call(message, "getWorkingTime"))
                    $root.fastcity.courier.GetWorkingTime.encode(message.getWorkingTime, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                if (message.setClientAddress != null && Object.hasOwnProperty.call(message, "setClientAddress"))
                    $root.fastcity.courier.SetClientAddress.encode(message.setClientAddress, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                if (message.heartbeat != null && Object.hasOwnProperty.call(message, "heartbeat"))
                    $root.fastcity.courier.Heartbeat.encode(message.heartbeat, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                if (message.getOrderHistoryList != null && Object.hasOwnProperty.call(message, "getOrderHistoryList"))
                    $root.fastcity.courier.GetOrderHistoryList.encode(message.getOrderHistoryList, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
                if (message.acceptOrder != null && Object.hasOwnProperty.call(message, "acceptOrder"))
                    $root.fastcity.courier.AcceptOrder.encode(message.acceptOrder, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
                if (message.getSubCompanyList != null && Object.hasOwnProperty.call(message, "getSubCompanyList"))
                    $root.fastcity.courier.GetSubCompanyList.encode(message.getSubCompanyList, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
                if (message.setOrderPath != null && Object.hasOwnProperty.call(message, "setOrderPath"))
                    $root.fastcity.common.SetOrderPath.encode(message.setOrderPath, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
                if (message.getOrderPath != null && Object.hasOwnProperty.call(message, "getOrderPath"))
                    $root.fastcity.common.GetOrderPath.encode(message.getOrderPath, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
                if (message.getOrderPathList != null && Object.hasOwnProperty.call(message, "getOrderPathList"))
                    $root.fastcity.common.GetOrderPathList.encode(message.getOrderPathList, writer.uint32(/* id 17, wireType 2 =*/138).fork()).ldelim();
                if (message.updateMinimalDistanceToOrderTarget != null && Object.hasOwnProperty.call(message, "updateMinimalDistanceToOrderTarget"))
                    $root.fastcity.courier.UpdateMinimalDistanceToOrderTarget.encode(message.updateMinimalDistanceToOrderTarget, writer.uint32(/* id 18, wireType 2 =*/146).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a ClientMessage message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.courier.ClientMessage
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.courier.ClientMessage} ClientMessage
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ClientMessage.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.courier.ClientMessage();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.requestId = reader.uint64();
                        break;
                    case 2:
                        message.logon = $root.fastcity.courier.Logon.decode(reader, reader.uint32());
                        break;
                    case 3:
                        message.location = $root.fastcity.courier.Location.decode(reader, reader.uint32());
                        break;
                    case 4:
                        message.getOrderList = $root.fastcity.courier.GetOrderList.decode(reader, reader.uint32());
                        break;
                    case 5:
                        message.finishOrder = $root.fastcity.courier.FinishOrder.decode(reader, reader.uint32());
                        break;
                    case 6:
                        message.getCurrentCourier = $root.fastcity.courier.GetCurrentCourier.decode(reader, reader.uint32());
                        break;
                    case 7:
                        message.getDeliveryStatDayList = $root.fastcity.courier.GetDeliveryStatDayList.decode(reader, reader.uint32());
                        break;
                    case 8:
                        message.getCurrentDeliveryStat = $root.fastcity.courier.GetCurrentDeliveryStat.decode(reader, reader.uint32());
                        break;
                    case 9:
                        message.getWorkingTime = $root.fastcity.courier.GetWorkingTime.decode(reader, reader.uint32());
                        break;
                    case 10:
                        message.setClientAddress = $root.fastcity.courier.SetClientAddress.decode(reader, reader.uint32());
                        break;
                    case 11:
                        message.heartbeat = $root.fastcity.courier.Heartbeat.decode(reader, reader.uint32());
                        break;
                    case 12:
                        message.getOrderHistoryList = $root.fastcity.courier.GetOrderHistoryList.decode(reader, reader.uint32());
                        break;
                    case 13:
                        message.acceptOrder = $root.fastcity.courier.AcceptOrder.decode(reader, reader.uint32());
                        break;
                    case 14:
                        message.getSubCompanyList = $root.fastcity.courier.GetSubCompanyList.decode(reader, reader.uint32());
                        break;
                    case 15:
                        message.setOrderPath = $root.fastcity.common.SetOrderPath.decode(reader, reader.uint32());
                        break;
                    case 16:
                        message.getOrderPath = $root.fastcity.common.GetOrderPath.decode(reader, reader.uint32());
                        break;
                    case 17:
                        message.getOrderPathList = $root.fastcity.common.GetOrderPathList.decode(reader, reader.uint32());
                        break;
                    case 18:
                        message.updateMinimalDistanceToOrderTarget = $root.fastcity.courier.UpdateMinimalDistanceToOrderTarget.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a ClientMessage message.
             * @function verify
             * @memberof fastcity.courier.ClientMessage
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ClientMessage.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                var properties = {};
                if (message.requestId != null && message.hasOwnProperty("requestId"))
                    if (!$util.isInteger(message.requestId) && !(message.requestId && $util.isInteger(message.requestId.low) && $util.isInteger(message.requestId.high)))
                        return "requestId: integer|Long expected";
                if (message.logon != null && message.hasOwnProperty("logon")) {
                    properties.body = 1;
                    {
                        var error = $root.fastcity.courier.Logon.verify(message.logon);
                        if (error)
                            return "logon." + error;
                    }
                }
                if (message.location != null && message.hasOwnProperty("location")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.courier.Location.verify(message.location);
                        if (error)
                            return "location." + error;
                    }
                }
                if (message.getOrderList != null && message.hasOwnProperty("getOrderList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.courier.GetOrderList.verify(message.getOrderList);
                        if (error)
                            return "getOrderList." + error;
                    }
                }
                if (message.finishOrder != null && message.hasOwnProperty("finishOrder")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.courier.FinishOrder.verify(message.finishOrder);
                        if (error)
                            return "finishOrder." + error;
                    }
                }
                if (message.getCurrentCourier != null && message.hasOwnProperty("getCurrentCourier")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.courier.GetCurrentCourier.verify(message.getCurrentCourier);
                        if (error)
                            return "getCurrentCourier." + error;
                    }
                }
                if (message.getDeliveryStatDayList != null && message.hasOwnProperty("getDeliveryStatDayList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.courier.GetDeliveryStatDayList.verify(message.getDeliveryStatDayList);
                        if (error)
                            return "getDeliveryStatDayList." + error;
                    }
                }
                if (message.getCurrentDeliveryStat != null && message.hasOwnProperty("getCurrentDeliveryStat")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.courier.GetCurrentDeliveryStat.verify(message.getCurrentDeliveryStat);
                        if (error)
                            return "getCurrentDeliveryStat." + error;
                    }
                }
                if (message.getWorkingTime != null && message.hasOwnProperty("getWorkingTime")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.courier.GetWorkingTime.verify(message.getWorkingTime);
                        if (error)
                            return "getWorkingTime." + error;
                    }
                }
                if (message.setClientAddress != null && message.hasOwnProperty("setClientAddress")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.courier.SetClientAddress.verify(message.setClientAddress);
                        if (error)
                            return "setClientAddress." + error;
                    }
                }
                if (message.heartbeat != null && message.hasOwnProperty("heartbeat")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.courier.Heartbeat.verify(message.heartbeat);
                        if (error)
                            return "heartbeat." + error;
                    }
                }
                if (message.getOrderHistoryList != null && message.hasOwnProperty("getOrderHistoryList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.courier.GetOrderHistoryList.verify(message.getOrderHistoryList);
                        if (error)
                            return "getOrderHistoryList." + error;
                    }
                }
                if (message.acceptOrder != null && message.hasOwnProperty("acceptOrder")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.courier.AcceptOrder.verify(message.acceptOrder);
                        if (error)
                            return "acceptOrder." + error;
                    }
                }
                if (message.getSubCompanyList != null && message.hasOwnProperty("getSubCompanyList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.courier.GetSubCompanyList.verify(message.getSubCompanyList);
                        if (error)
                            return "getSubCompanyList." + error;
                    }
                }
                if (message.setOrderPath != null && message.hasOwnProperty("setOrderPath")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.common.SetOrderPath.verify(message.setOrderPath);
                        if (error)
                            return "setOrderPath." + error;
                    }
                }
                if (message.getOrderPath != null && message.hasOwnProperty("getOrderPath")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.common.GetOrderPath.verify(message.getOrderPath);
                        if (error)
                            return "getOrderPath." + error;
                    }
                }
                if (message.getOrderPathList != null && message.hasOwnProperty("getOrderPathList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.common.GetOrderPathList.verify(message.getOrderPathList);
                        if (error)
                            return "getOrderPathList." + error;
                    }
                }
                if (message.updateMinimalDistanceToOrderTarget != null && message.hasOwnProperty("updateMinimalDistanceToOrderTarget")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.courier.UpdateMinimalDistanceToOrderTarget.verify(message.updateMinimalDistanceToOrderTarget);
                        if (error)
                            return "updateMinimalDistanceToOrderTarget." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a ClientMessage message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.courier.ClientMessage
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.courier.ClientMessage} ClientMessage
             */
            ClientMessage.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.courier.ClientMessage)
                    return object;
                var message = new $root.fastcity.courier.ClientMessage();
                if (object.requestId != null)
                    if ($util.Long)
                        (message.requestId = $util.Long.fromValue(object.requestId)).unsigned = true;
                    else if (typeof object.requestId === "string")
                        message.requestId = parseInt(object.requestId, 10);
                    else if (typeof object.requestId === "number")
                        message.requestId = object.requestId;
                    else if (typeof object.requestId === "object")
                        message.requestId = new $util.LongBits(object.requestId.low >>> 0, object.requestId.high >>> 0).toNumber(true);
                if (object.logon != null) {
                    if (typeof object.logon !== "object")
                        throw TypeError(".fastcity.courier.ClientMessage.logon: object expected");
                    message.logon = $root.fastcity.courier.Logon.fromObject(object.logon);
                }
                if (object.location != null) {
                    if (typeof object.location !== "object")
                        throw TypeError(".fastcity.courier.ClientMessage.location: object expected");
                    message.location = $root.fastcity.courier.Location.fromObject(object.location);
                }
                if (object.getOrderList != null) {
                    if (typeof object.getOrderList !== "object")
                        throw TypeError(".fastcity.courier.ClientMessage.getOrderList: object expected");
                    message.getOrderList = $root.fastcity.courier.GetOrderList.fromObject(object.getOrderList);
                }
                if (object.finishOrder != null) {
                    if (typeof object.finishOrder !== "object")
                        throw TypeError(".fastcity.courier.ClientMessage.finishOrder: object expected");
                    message.finishOrder = $root.fastcity.courier.FinishOrder.fromObject(object.finishOrder);
                }
                if (object.getCurrentCourier != null) {
                    if (typeof object.getCurrentCourier !== "object")
                        throw TypeError(".fastcity.courier.ClientMessage.getCurrentCourier: object expected");
                    message.getCurrentCourier = $root.fastcity.courier.GetCurrentCourier.fromObject(object.getCurrentCourier);
                }
                if (object.getDeliveryStatDayList != null) {
                    if (typeof object.getDeliveryStatDayList !== "object")
                        throw TypeError(".fastcity.courier.ClientMessage.getDeliveryStatDayList: object expected");
                    message.getDeliveryStatDayList = $root.fastcity.courier.GetDeliveryStatDayList.fromObject(object.getDeliveryStatDayList);
                }
                if (object.getCurrentDeliveryStat != null) {
                    if (typeof object.getCurrentDeliveryStat !== "object")
                        throw TypeError(".fastcity.courier.ClientMessage.getCurrentDeliveryStat: object expected");
                    message.getCurrentDeliveryStat = $root.fastcity.courier.GetCurrentDeliveryStat.fromObject(object.getCurrentDeliveryStat);
                }
                if (object.getWorkingTime != null) {
                    if (typeof object.getWorkingTime !== "object")
                        throw TypeError(".fastcity.courier.ClientMessage.getWorkingTime: object expected");
                    message.getWorkingTime = $root.fastcity.courier.GetWorkingTime.fromObject(object.getWorkingTime);
                }
                if (object.setClientAddress != null) {
                    if (typeof object.setClientAddress !== "object")
                        throw TypeError(".fastcity.courier.ClientMessage.setClientAddress: object expected");
                    message.setClientAddress = $root.fastcity.courier.SetClientAddress.fromObject(object.setClientAddress);
                }
                if (object.heartbeat != null) {
                    if (typeof object.heartbeat !== "object")
                        throw TypeError(".fastcity.courier.ClientMessage.heartbeat: object expected");
                    message.heartbeat = $root.fastcity.courier.Heartbeat.fromObject(object.heartbeat);
                }
                if (object.getOrderHistoryList != null) {
                    if (typeof object.getOrderHistoryList !== "object")
                        throw TypeError(".fastcity.courier.ClientMessage.getOrderHistoryList: object expected");
                    message.getOrderHistoryList = $root.fastcity.courier.GetOrderHistoryList.fromObject(object.getOrderHistoryList);
                }
                if (object.acceptOrder != null) {
                    if (typeof object.acceptOrder !== "object")
                        throw TypeError(".fastcity.courier.ClientMessage.acceptOrder: object expected");
                    message.acceptOrder = $root.fastcity.courier.AcceptOrder.fromObject(object.acceptOrder);
                }
                if (object.getSubCompanyList != null) {
                    if (typeof object.getSubCompanyList !== "object")
                        throw TypeError(".fastcity.courier.ClientMessage.getSubCompanyList: object expected");
                    message.getSubCompanyList = $root.fastcity.courier.GetSubCompanyList.fromObject(object.getSubCompanyList);
                }
                if (object.setOrderPath != null) {
                    if (typeof object.setOrderPath !== "object")
                        throw TypeError(".fastcity.courier.ClientMessage.setOrderPath: object expected");
                    message.setOrderPath = $root.fastcity.common.SetOrderPath.fromObject(object.setOrderPath);
                }
                if (object.getOrderPath != null) {
                    if (typeof object.getOrderPath !== "object")
                        throw TypeError(".fastcity.courier.ClientMessage.getOrderPath: object expected");
                    message.getOrderPath = $root.fastcity.common.GetOrderPath.fromObject(object.getOrderPath);
                }
                if (object.getOrderPathList != null) {
                    if (typeof object.getOrderPathList !== "object")
                        throw TypeError(".fastcity.courier.ClientMessage.getOrderPathList: object expected");
                    message.getOrderPathList = $root.fastcity.common.GetOrderPathList.fromObject(object.getOrderPathList);
                }
                if (object.updateMinimalDistanceToOrderTarget != null) {
                    if (typeof object.updateMinimalDistanceToOrderTarget !== "object")
                        throw TypeError(".fastcity.courier.ClientMessage.updateMinimalDistanceToOrderTarget: object expected");
                    message.updateMinimalDistanceToOrderTarget = $root.fastcity.courier.UpdateMinimalDistanceToOrderTarget.fromObject(object.updateMinimalDistanceToOrderTarget);
                }
                return message;
            };

            /**
             * Creates a plain object from a ClientMessage message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.courier.ClientMessage
             * @static
             * @param {fastcity.courier.ClientMessage} message ClientMessage
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ClientMessage.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.requestId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.requestId = options.longs === String ? "0" : 0;
                if (message.requestId != null && message.hasOwnProperty("requestId"))
                    if (typeof message.requestId === "number")
                        object.requestId = options.longs === String ? String(message.requestId) : message.requestId;
                    else
                        object.requestId = options.longs === String ? $util.Long.prototype.toString.call(message.requestId) : options.longs === Number ? new $util.LongBits(message.requestId.low >>> 0, message.requestId.high >>> 0).toNumber(true) : message.requestId;
                if (message.logon != null && message.hasOwnProperty("logon")) {
                    object.logon = $root.fastcity.courier.Logon.toObject(message.logon, options);
                    if (options.oneofs)
                        object.body = "logon";
                }
                if (message.location != null && message.hasOwnProperty("location")) {
                    object.location = $root.fastcity.courier.Location.toObject(message.location, options);
                    if (options.oneofs)
                        object.body = "location";
                }
                if (message.getOrderList != null && message.hasOwnProperty("getOrderList")) {
                    object.getOrderList = $root.fastcity.courier.GetOrderList.toObject(message.getOrderList, options);
                    if (options.oneofs)
                        object.body = "getOrderList";
                }
                if (message.finishOrder != null && message.hasOwnProperty("finishOrder")) {
                    object.finishOrder = $root.fastcity.courier.FinishOrder.toObject(message.finishOrder, options);
                    if (options.oneofs)
                        object.body = "finishOrder";
                }
                if (message.getCurrentCourier != null && message.hasOwnProperty("getCurrentCourier")) {
                    object.getCurrentCourier = $root.fastcity.courier.GetCurrentCourier.toObject(message.getCurrentCourier, options);
                    if (options.oneofs)
                        object.body = "getCurrentCourier";
                }
                if (message.getDeliveryStatDayList != null && message.hasOwnProperty("getDeliveryStatDayList")) {
                    object.getDeliveryStatDayList = $root.fastcity.courier.GetDeliveryStatDayList.toObject(message.getDeliveryStatDayList, options);
                    if (options.oneofs)
                        object.body = "getDeliveryStatDayList";
                }
                if (message.getCurrentDeliveryStat != null && message.hasOwnProperty("getCurrentDeliveryStat")) {
                    object.getCurrentDeliveryStat = $root.fastcity.courier.GetCurrentDeliveryStat.toObject(message.getCurrentDeliveryStat, options);
                    if (options.oneofs)
                        object.body = "getCurrentDeliveryStat";
                }
                if (message.getWorkingTime != null && message.hasOwnProperty("getWorkingTime")) {
                    object.getWorkingTime = $root.fastcity.courier.GetWorkingTime.toObject(message.getWorkingTime, options);
                    if (options.oneofs)
                        object.body = "getWorkingTime";
                }
                if (message.setClientAddress != null && message.hasOwnProperty("setClientAddress")) {
                    object.setClientAddress = $root.fastcity.courier.SetClientAddress.toObject(message.setClientAddress, options);
                    if (options.oneofs)
                        object.body = "setClientAddress";
                }
                if (message.heartbeat != null && message.hasOwnProperty("heartbeat")) {
                    object.heartbeat = $root.fastcity.courier.Heartbeat.toObject(message.heartbeat, options);
                    if (options.oneofs)
                        object.body = "heartbeat";
                }
                if (message.getOrderHistoryList != null && message.hasOwnProperty("getOrderHistoryList")) {
                    object.getOrderHistoryList = $root.fastcity.courier.GetOrderHistoryList.toObject(message.getOrderHistoryList, options);
                    if (options.oneofs)
                        object.body = "getOrderHistoryList";
                }
                if (message.acceptOrder != null && message.hasOwnProperty("acceptOrder")) {
                    object.acceptOrder = $root.fastcity.courier.AcceptOrder.toObject(message.acceptOrder, options);
                    if (options.oneofs)
                        object.body = "acceptOrder";
                }
                if (message.getSubCompanyList != null && message.hasOwnProperty("getSubCompanyList")) {
                    object.getSubCompanyList = $root.fastcity.courier.GetSubCompanyList.toObject(message.getSubCompanyList, options);
                    if (options.oneofs)
                        object.body = "getSubCompanyList";
                }
                if (message.setOrderPath != null && message.hasOwnProperty("setOrderPath")) {
                    object.setOrderPath = $root.fastcity.common.SetOrderPath.toObject(message.setOrderPath, options);
                    if (options.oneofs)
                        object.body = "setOrderPath";
                }
                if (message.getOrderPath != null && message.hasOwnProperty("getOrderPath")) {
                    object.getOrderPath = $root.fastcity.common.GetOrderPath.toObject(message.getOrderPath, options);
                    if (options.oneofs)
                        object.body = "getOrderPath";
                }
                if (message.getOrderPathList != null && message.hasOwnProperty("getOrderPathList")) {
                    object.getOrderPathList = $root.fastcity.common.GetOrderPathList.toObject(message.getOrderPathList, options);
                    if (options.oneofs)
                        object.body = "getOrderPathList";
                }
                if (message.updateMinimalDistanceToOrderTarget != null && message.hasOwnProperty("updateMinimalDistanceToOrderTarget")) {
                    object.updateMinimalDistanceToOrderTarget = $root.fastcity.courier.UpdateMinimalDistanceToOrderTarget.toObject(message.updateMinimalDistanceToOrderTarget, options);
                    if (options.oneofs)
                        object.body = "updateMinimalDistanceToOrderTarget";
                }
                return object;
            };

            /**
             * Converts this ClientMessage to JSON.
             * @function toJSON
             * @memberof fastcity.courier.ClientMessage
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ClientMessage.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return ClientMessage;
        })();

        courier.ServerMessage = (function() {

            /**
             * Properties of a ServerMessage.
             * @memberof fastcity.courier
             * @interface IServerMessage
             * @property {number|Long|null} [requestId] ServerMessage requestId
             * @property {fastcity.courier.IResult|null} [result] ServerMessage result
             * @property {fastcity.courier.ICourier|null} [courier] ServerMessage courier
             * @property {fastcity.courier.IOrderList|null} [orderList] ServerMessage orderList
             * @property {fastcity.courier.ILogonResult|null} [logonResult] ServerMessage logonResult
             * @property {fastcity.courier.IDeliveryStat|null} [deliveryStat] ServerMessage deliveryStat
             * @property {fastcity.courier.IDeliveryStatDayList|null} [deliveryStatDayList] ServerMessage deliveryStatDayList
             * @property {fastcity.courier.IWorkingTimeList|null} [workingTimeList] ServerMessage workingTimeList
             * @property {fastcity.courier.IHeartbeat|null} [heartbeat] ServerMessage heartbeat
             * @property {fastcity.courier.ISubCompanyList|null} [subCompanyList] ServerMessage subCompanyList
             * @property {fastcity.common.IOrderPath|null} [orderPath] ServerMessage orderPath
             * @property {fastcity.common.IOrderPathList|null} [orderPathList] ServerMessage orderPathList
             */

            /**
             * Constructs a new ServerMessage.
             * @memberof fastcity.courier
             * @classdesc Represents a ServerMessage.
             * @implements IServerMessage
             * @constructor
             * @param {fastcity.courier.IServerMessage=} [properties] Properties to set
             */
            function ServerMessage(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ServerMessage requestId.
             * @member {number|Long} requestId
             * @memberof fastcity.courier.ServerMessage
             * @instance
             */
            ServerMessage.prototype.requestId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * ServerMessage result.
             * @member {fastcity.courier.IResult|null|undefined} result
             * @memberof fastcity.courier.ServerMessage
             * @instance
             */
            ServerMessage.prototype.result = null;

            /**
             * ServerMessage courier.
             * @member {fastcity.courier.ICourier|null|undefined} courier
             * @memberof fastcity.courier.ServerMessage
             * @instance
             */
            ServerMessage.prototype.courier = null;

            /**
             * ServerMessage orderList.
             * @member {fastcity.courier.IOrderList|null|undefined} orderList
             * @memberof fastcity.courier.ServerMessage
             * @instance
             */
            ServerMessage.prototype.orderList = null;

            /**
             * ServerMessage logonResult.
             * @member {fastcity.courier.ILogonResult|null|undefined} logonResult
             * @memberof fastcity.courier.ServerMessage
             * @instance
             */
            ServerMessage.prototype.logonResult = null;

            /**
             * ServerMessage deliveryStat.
             * @member {fastcity.courier.IDeliveryStat|null|undefined} deliveryStat
             * @memberof fastcity.courier.ServerMessage
             * @instance
             */
            ServerMessage.prototype.deliveryStat = null;

            /**
             * ServerMessage deliveryStatDayList.
             * @member {fastcity.courier.IDeliveryStatDayList|null|undefined} deliveryStatDayList
             * @memberof fastcity.courier.ServerMessage
             * @instance
             */
            ServerMessage.prototype.deliveryStatDayList = null;

            /**
             * ServerMessage workingTimeList.
             * @member {fastcity.courier.IWorkingTimeList|null|undefined} workingTimeList
             * @memberof fastcity.courier.ServerMessage
             * @instance
             */
            ServerMessage.prototype.workingTimeList = null;

            /**
             * ServerMessage heartbeat.
             * @member {fastcity.courier.IHeartbeat|null|undefined} heartbeat
             * @memberof fastcity.courier.ServerMessage
             * @instance
             */
            ServerMessage.prototype.heartbeat = null;

            /**
             * ServerMessage subCompanyList.
             * @member {fastcity.courier.ISubCompanyList|null|undefined} subCompanyList
             * @memberof fastcity.courier.ServerMessage
             * @instance
             */
            ServerMessage.prototype.subCompanyList = null;

            /**
             * ServerMessage orderPath.
             * @member {fastcity.common.IOrderPath|null|undefined} orderPath
             * @memberof fastcity.courier.ServerMessage
             * @instance
             */
            ServerMessage.prototype.orderPath = null;

            /**
             * ServerMessage orderPathList.
             * @member {fastcity.common.IOrderPathList|null|undefined} orderPathList
             * @memberof fastcity.courier.ServerMessage
             * @instance
             */
            ServerMessage.prototype.orderPathList = null;

            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;

            /**
             * ServerMessage body.
             * @member {"result"|"courier"|"orderList"|"logonResult"|"deliveryStat"|"deliveryStatDayList"|"workingTimeList"|"heartbeat"|"subCompanyList"|"orderPath"|"orderPathList"|undefined} body
             * @memberof fastcity.courier.ServerMessage
             * @instance
             */
            Object.defineProperty(ServerMessage.prototype, "body", {
                get: $util.oneOfGetter($oneOfFields = ["result", "courier", "orderList", "logonResult", "deliveryStat", "deliveryStatDayList", "workingTimeList", "heartbeat", "subCompanyList", "orderPath", "orderPathList"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Creates a new ServerMessage instance using the specified properties.
             * @function create
             * @memberof fastcity.courier.ServerMessage
             * @static
             * @param {fastcity.courier.IServerMessage=} [properties] Properties to set
             * @returns {fastcity.courier.ServerMessage} ServerMessage instance
             */
            ServerMessage.create = function create(properties) {
                return new ServerMessage(properties);
            };

            /**
             * Encodes the specified ServerMessage message. Does not implicitly {@link fastcity.courier.ServerMessage.verify|verify} messages.
             * @function encode
             * @memberof fastcity.courier.ServerMessage
             * @static
             * @param {fastcity.courier.IServerMessage} message ServerMessage message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ServerMessage.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.requestId != null && Object.hasOwnProperty.call(message, "requestId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.requestId);
                if (message.result != null && Object.hasOwnProperty.call(message, "result"))
                    $root.fastcity.courier.Result.encode(message.result, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.courier != null && Object.hasOwnProperty.call(message, "courier"))
                    $root.fastcity.courier.Courier.encode(message.courier, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.orderList != null && Object.hasOwnProperty.call(message, "orderList"))
                    $root.fastcity.courier.OrderList.encode(message.orderList, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.logonResult != null && Object.hasOwnProperty.call(message, "logonResult"))
                    $root.fastcity.courier.LogonResult.encode(message.logonResult, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.deliveryStat != null && Object.hasOwnProperty.call(message, "deliveryStat"))
                    $root.fastcity.courier.DeliveryStat.encode(message.deliveryStat, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.deliveryStatDayList != null && Object.hasOwnProperty.call(message, "deliveryStatDayList"))
                    $root.fastcity.courier.DeliveryStatDayList.encode(message.deliveryStatDayList, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                if (message.workingTimeList != null && Object.hasOwnProperty.call(message, "workingTimeList"))
                    $root.fastcity.courier.WorkingTimeList.encode(message.workingTimeList, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                if (message.heartbeat != null && Object.hasOwnProperty.call(message, "heartbeat"))
                    $root.fastcity.courier.Heartbeat.encode(message.heartbeat, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                if (message.subCompanyList != null && Object.hasOwnProperty.call(message, "subCompanyList"))
                    $root.fastcity.courier.SubCompanyList.encode(message.subCompanyList, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                if (message.orderPath != null && Object.hasOwnProperty.call(message, "orderPath"))
                    $root.fastcity.common.OrderPath.encode(message.orderPath, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                if (message.orderPathList != null && Object.hasOwnProperty.call(message, "orderPathList"))
                    $root.fastcity.common.OrderPathList.encode(message.orderPathList, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a ServerMessage message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.courier.ServerMessage
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.courier.ServerMessage} ServerMessage
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ServerMessage.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.courier.ServerMessage();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.requestId = reader.uint64();
                        break;
                    case 2:
                        message.result = $root.fastcity.courier.Result.decode(reader, reader.uint32());
                        break;
                    case 3:
                        message.courier = $root.fastcity.courier.Courier.decode(reader, reader.uint32());
                        break;
                    case 4:
                        message.orderList = $root.fastcity.courier.OrderList.decode(reader, reader.uint32());
                        break;
                    case 5:
                        message.logonResult = $root.fastcity.courier.LogonResult.decode(reader, reader.uint32());
                        break;
                    case 6:
                        message.deliveryStat = $root.fastcity.courier.DeliveryStat.decode(reader, reader.uint32());
                        break;
                    case 7:
                        message.deliveryStatDayList = $root.fastcity.courier.DeliveryStatDayList.decode(reader, reader.uint32());
                        break;
                    case 8:
                        message.workingTimeList = $root.fastcity.courier.WorkingTimeList.decode(reader, reader.uint32());
                        break;
                    case 9:
                        message.heartbeat = $root.fastcity.courier.Heartbeat.decode(reader, reader.uint32());
                        break;
                    case 10:
                        message.subCompanyList = $root.fastcity.courier.SubCompanyList.decode(reader, reader.uint32());
                        break;
                    case 11:
                        message.orderPath = $root.fastcity.common.OrderPath.decode(reader, reader.uint32());
                        break;
                    case 12:
                        message.orderPathList = $root.fastcity.common.OrderPathList.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a ServerMessage message.
             * @function verify
             * @memberof fastcity.courier.ServerMessage
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ServerMessage.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                var properties = {};
                if (message.requestId != null && message.hasOwnProperty("requestId"))
                    if (!$util.isInteger(message.requestId) && !(message.requestId && $util.isInteger(message.requestId.low) && $util.isInteger(message.requestId.high)))
                        return "requestId: integer|Long expected";
                if (message.result != null && message.hasOwnProperty("result")) {
                    properties.body = 1;
                    {
                        var error = $root.fastcity.courier.Result.verify(message.result);
                        if (error)
                            return "result." + error;
                    }
                }
                if (message.courier != null && message.hasOwnProperty("courier")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.courier.Courier.verify(message.courier);
                        if (error)
                            return "courier." + error;
                    }
                }
                if (message.orderList != null && message.hasOwnProperty("orderList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.courier.OrderList.verify(message.orderList);
                        if (error)
                            return "orderList." + error;
                    }
                }
                if (message.logonResult != null && message.hasOwnProperty("logonResult")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.courier.LogonResult.verify(message.logonResult);
                        if (error)
                            return "logonResult." + error;
                    }
                }
                if (message.deliveryStat != null && message.hasOwnProperty("deliveryStat")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.courier.DeliveryStat.verify(message.deliveryStat);
                        if (error)
                            return "deliveryStat." + error;
                    }
                }
                if (message.deliveryStatDayList != null && message.hasOwnProperty("deliveryStatDayList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.courier.DeliveryStatDayList.verify(message.deliveryStatDayList);
                        if (error)
                            return "deliveryStatDayList." + error;
                    }
                }
                if (message.workingTimeList != null && message.hasOwnProperty("workingTimeList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.courier.WorkingTimeList.verify(message.workingTimeList);
                        if (error)
                            return "workingTimeList." + error;
                    }
                }
                if (message.heartbeat != null && message.hasOwnProperty("heartbeat")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.courier.Heartbeat.verify(message.heartbeat);
                        if (error)
                            return "heartbeat." + error;
                    }
                }
                if (message.subCompanyList != null && message.hasOwnProperty("subCompanyList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.courier.SubCompanyList.verify(message.subCompanyList);
                        if (error)
                            return "subCompanyList." + error;
                    }
                }
                if (message.orderPath != null && message.hasOwnProperty("orderPath")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.common.OrderPath.verify(message.orderPath);
                        if (error)
                            return "orderPath." + error;
                    }
                }
                if (message.orderPathList != null && message.hasOwnProperty("orderPathList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.common.OrderPathList.verify(message.orderPathList);
                        if (error)
                            return "orderPathList." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a ServerMessage message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.courier.ServerMessage
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.courier.ServerMessage} ServerMessage
             */
            ServerMessage.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.courier.ServerMessage)
                    return object;
                var message = new $root.fastcity.courier.ServerMessage();
                if (object.requestId != null)
                    if ($util.Long)
                        (message.requestId = $util.Long.fromValue(object.requestId)).unsigned = true;
                    else if (typeof object.requestId === "string")
                        message.requestId = parseInt(object.requestId, 10);
                    else if (typeof object.requestId === "number")
                        message.requestId = object.requestId;
                    else if (typeof object.requestId === "object")
                        message.requestId = new $util.LongBits(object.requestId.low >>> 0, object.requestId.high >>> 0).toNumber(true);
                if (object.result != null) {
                    if (typeof object.result !== "object")
                        throw TypeError(".fastcity.courier.ServerMessage.result: object expected");
                    message.result = $root.fastcity.courier.Result.fromObject(object.result);
                }
                if (object.courier != null) {
                    if (typeof object.courier !== "object")
                        throw TypeError(".fastcity.courier.ServerMessage.courier: object expected");
                    message.courier = $root.fastcity.courier.Courier.fromObject(object.courier);
                }
                if (object.orderList != null) {
                    if (typeof object.orderList !== "object")
                        throw TypeError(".fastcity.courier.ServerMessage.orderList: object expected");
                    message.orderList = $root.fastcity.courier.OrderList.fromObject(object.orderList);
                }
                if (object.logonResult != null) {
                    if (typeof object.logonResult !== "object")
                        throw TypeError(".fastcity.courier.ServerMessage.logonResult: object expected");
                    message.logonResult = $root.fastcity.courier.LogonResult.fromObject(object.logonResult);
                }
                if (object.deliveryStat != null) {
                    if (typeof object.deliveryStat !== "object")
                        throw TypeError(".fastcity.courier.ServerMessage.deliveryStat: object expected");
                    message.deliveryStat = $root.fastcity.courier.DeliveryStat.fromObject(object.deliveryStat);
                }
                if (object.deliveryStatDayList != null) {
                    if (typeof object.deliveryStatDayList !== "object")
                        throw TypeError(".fastcity.courier.ServerMessage.deliveryStatDayList: object expected");
                    message.deliveryStatDayList = $root.fastcity.courier.DeliveryStatDayList.fromObject(object.deliveryStatDayList);
                }
                if (object.workingTimeList != null) {
                    if (typeof object.workingTimeList !== "object")
                        throw TypeError(".fastcity.courier.ServerMessage.workingTimeList: object expected");
                    message.workingTimeList = $root.fastcity.courier.WorkingTimeList.fromObject(object.workingTimeList);
                }
                if (object.heartbeat != null) {
                    if (typeof object.heartbeat !== "object")
                        throw TypeError(".fastcity.courier.ServerMessage.heartbeat: object expected");
                    message.heartbeat = $root.fastcity.courier.Heartbeat.fromObject(object.heartbeat);
                }
                if (object.subCompanyList != null) {
                    if (typeof object.subCompanyList !== "object")
                        throw TypeError(".fastcity.courier.ServerMessage.subCompanyList: object expected");
                    message.subCompanyList = $root.fastcity.courier.SubCompanyList.fromObject(object.subCompanyList);
                }
                if (object.orderPath != null) {
                    if (typeof object.orderPath !== "object")
                        throw TypeError(".fastcity.courier.ServerMessage.orderPath: object expected");
                    message.orderPath = $root.fastcity.common.OrderPath.fromObject(object.orderPath);
                }
                if (object.orderPathList != null) {
                    if (typeof object.orderPathList !== "object")
                        throw TypeError(".fastcity.courier.ServerMessage.orderPathList: object expected");
                    message.orderPathList = $root.fastcity.common.OrderPathList.fromObject(object.orderPathList);
                }
                return message;
            };

            /**
             * Creates a plain object from a ServerMessage message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.courier.ServerMessage
             * @static
             * @param {fastcity.courier.ServerMessage} message ServerMessage
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ServerMessage.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.requestId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.requestId = options.longs === String ? "0" : 0;
                if (message.requestId != null && message.hasOwnProperty("requestId"))
                    if (typeof message.requestId === "number")
                        object.requestId = options.longs === String ? String(message.requestId) : message.requestId;
                    else
                        object.requestId = options.longs === String ? $util.Long.prototype.toString.call(message.requestId) : options.longs === Number ? new $util.LongBits(message.requestId.low >>> 0, message.requestId.high >>> 0).toNumber(true) : message.requestId;
                if (message.result != null && message.hasOwnProperty("result")) {
                    object.result = $root.fastcity.courier.Result.toObject(message.result, options);
                    if (options.oneofs)
                        object.body = "result";
                }
                if (message.courier != null && message.hasOwnProperty("courier")) {
                    object.courier = $root.fastcity.courier.Courier.toObject(message.courier, options);
                    if (options.oneofs)
                        object.body = "courier";
                }
                if (message.orderList != null && message.hasOwnProperty("orderList")) {
                    object.orderList = $root.fastcity.courier.OrderList.toObject(message.orderList, options);
                    if (options.oneofs)
                        object.body = "orderList";
                }
                if (message.logonResult != null && message.hasOwnProperty("logonResult")) {
                    object.logonResult = $root.fastcity.courier.LogonResult.toObject(message.logonResult, options);
                    if (options.oneofs)
                        object.body = "logonResult";
                }
                if (message.deliveryStat != null && message.hasOwnProperty("deliveryStat")) {
                    object.deliveryStat = $root.fastcity.courier.DeliveryStat.toObject(message.deliveryStat, options);
                    if (options.oneofs)
                        object.body = "deliveryStat";
                }
                if (message.deliveryStatDayList != null && message.hasOwnProperty("deliveryStatDayList")) {
                    object.deliveryStatDayList = $root.fastcity.courier.DeliveryStatDayList.toObject(message.deliveryStatDayList, options);
                    if (options.oneofs)
                        object.body = "deliveryStatDayList";
                }
                if (message.workingTimeList != null && message.hasOwnProperty("workingTimeList")) {
                    object.workingTimeList = $root.fastcity.courier.WorkingTimeList.toObject(message.workingTimeList, options);
                    if (options.oneofs)
                        object.body = "workingTimeList";
                }
                if (message.heartbeat != null && message.hasOwnProperty("heartbeat")) {
                    object.heartbeat = $root.fastcity.courier.Heartbeat.toObject(message.heartbeat, options);
                    if (options.oneofs)
                        object.body = "heartbeat";
                }
                if (message.subCompanyList != null && message.hasOwnProperty("subCompanyList")) {
                    object.subCompanyList = $root.fastcity.courier.SubCompanyList.toObject(message.subCompanyList, options);
                    if (options.oneofs)
                        object.body = "subCompanyList";
                }
                if (message.orderPath != null && message.hasOwnProperty("orderPath")) {
                    object.orderPath = $root.fastcity.common.OrderPath.toObject(message.orderPath, options);
                    if (options.oneofs)
                        object.body = "orderPath";
                }
                if (message.orderPathList != null && message.hasOwnProperty("orderPathList")) {
                    object.orderPathList = $root.fastcity.common.OrderPathList.toObject(message.orderPathList, options);
                    if (options.oneofs)
                        object.body = "orderPathList";
                }
                return object;
            };

            /**
             * Converts this ServerMessage to JSON.
             * @function toJSON
             * @memberof fastcity.courier.ServerMessage
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ServerMessage.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return ServerMessage;
        })();

        courier.SubCompany = (function() {

            /**
             * Properties of a SubCompany.
             * @memberof fastcity.courier
             * @interface ISubCompany
             * @property {number|Long|null} [companyId] SubCompany companyId
             * @property {number|Long|null} [subCompanyId] SubCompany subCompanyId
             * @property {string|null} [address] SubCompany address
             * @property {fastcity.common.ICoordinate|null} [coordinate] SubCompany coordinate
             * @property {string|null} [name] SubCompany name
             */

            /**
             * Constructs a new SubCompany.
             * @memberof fastcity.courier
             * @classdesc Represents a SubCompany.
             * @implements ISubCompany
             * @constructor
             * @param {fastcity.courier.ISubCompany=} [properties] Properties to set
             */
            function SubCompany(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * SubCompany companyId.
             * @member {number|Long} companyId
             * @memberof fastcity.courier.SubCompany
             * @instance
             */
            SubCompany.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * SubCompany subCompanyId.
             * @member {number|Long} subCompanyId
             * @memberof fastcity.courier.SubCompany
             * @instance
             */
            SubCompany.prototype.subCompanyId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * SubCompany address.
             * @member {string} address
             * @memberof fastcity.courier.SubCompany
             * @instance
             */
            SubCompany.prototype.address = "";

            /**
             * SubCompany coordinate.
             * @member {fastcity.common.ICoordinate|null|undefined} coordinate
             * @memberof fastcity.courier.SubCompany
             * @instance
             */
            SubCompany.prototype.coordinate = null;

            /**
             * SubCompany name.
             * @member {string} name
             * @memberof fastcity.courier.SubCompany
             * @instance
             */
            SubCompany.prototype.name = "";

            /**
             * Creates a new SubCompany instance using the specified properties.
             * @function create
             * @memberof fastcity.courier.SubCompany
             * @static
             * @param {fastcity.courier.ISubCompany=} [properties] Properties to set
             * @returns {fastcity.courier.SubCompany} SubCompany instance
             */
            SubCompany.create = function create(properties) {
                return new SubCompany(properties);
            };

            /**
             * Encodes the specified SubCompany message. Does not implicitly {@link fastcity.courier.SubCompany.verify|verify} messages.
             * @function encode
             * @memberof fastcity.courier.SubCompany
             * @static
             * @param {fastcity.courier.ISubCompany} message SubCompany message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SubCompany.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.companyId);
                if (message.subCompanyId != null && Object.hasOwnProperty.call(message, "subCompanyId"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int64(message.subCompanyId);
                if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.address);
                if (message.coordinate != null && Object.hasOwnProperty.call(message, "coordinate"))
                    $root.fastcity.common.Coordinate.encode(message.coordinate, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.name);
                return writer;
            };

            /**
             * Decodes a SubCompany message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.courier.SubCompany
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.courier.SubCompany} SubCompany
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SubCompany.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.courier.SubCompany();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.companyId = reader.int64();
                        break;
                    case 2:
                        message.subCompanyId = reader.int64();
                        break;
                    case 3:
                        message.address = reader.string();
                        break;
                    case 4:
                        message.coordinate = $root.fastcity.common.Coordinate.decode(reader, reader.uint32());
                        break;
                    case 5:
                        message.name = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a SubCompany message.
             * @function verify
             * @memberof fastcity.courier.SubCompany
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SubCompany.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                        return "companyId: integer|Long expected";
                if (message.subCompanyId != null && message.hasOwnProperty("subCompanyId"))
                    if (!$util.isInteger(message.subCompanyId) && !(message.subCompanyId && $util.isInteger(message.subCompanyId.low) && $util.isInteger(message.subCompanyId.high)))
                        return "subCompanyId: integer|Long expected";
                if (message.address != null && message.hasOwnProperty("address"))
                    if (!$util.isString(message.address))
                        return "address: string expected";
                if (message.coordinate != null && message.hasOwnProperty("coordinate")) {
                    var error = $root.fastcity.common.Coordinate.verify(message.coordinate);
                    if (error)
                        return "coordinate." + error;
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                return null;
            };

            /**
             * Creates a SubCompany message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.courier.SubCompany
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.courier.SubCompany} SubCompany
             */
            SubCompany.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.courier.SubCompany)
                    return object;
                var message = new $root.fastcity.courier.SubCompany();
                if (object.companyId != null)
                    if ($util.Long)
                        (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = false;
                    else if (typeof object.companyId === "string")
                        message.companyId = parseInt(object.companyId, 10);
                    else if (typeof object.companyId === "number")
                        message.companyId = object.companyId;
                    else if (typeof object.companyId === "object")
                        message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber();
                if (object.subCompanyId != null)
                    if ($util.Long)
                        (message.subCompanyId = $util.Long.fromValue(object.subCompanyId)).unsigned = false;
                    else if (typeof object.subCompanyId === "string")
                        message.subCompanyId = parseInt(object.subCompanyId, 10);
                    else if (typeof object.subCompanyId === "number")
                        message.subCompanyId = object.subCompanyId;
                    else if (typeof object.subCompanyId === "object")
                        message.subCompanyId = new $util.LongBits(object.subCompanyId.low >>> 0, object.subCompanyId.high >>> 0).toNumber();
                if (object.address != null)
                    message.address = String(object.address);
                if (object.coordinate != null) {
                    if (typeof object.coordinate !== "object")
                        throw TypeError(".fastcity.courier.SubCompany.coordinate: object expected");
                    message.coordinate = $root.fastcity.common.Coordinate.fromObject(object.coordinate);
                }
                if (object.name != null)
                    message.name = String(object.name);
                return message;
            };

            /**
             * Creates a plain object from a SubCompany message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.courier.SubCompany
             * @static
             * @param {fastcity.courier.SubCompany} message SubCompany
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SubCompany.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.companyId = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.subCompanyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.subCompanyId = options.longs === String ? "0" : 0;
                    object.address = "";
                    object.coordinate = null;
                    object.name = "";
                }
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (typeof message.companyId === "number")
                        object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                    else
                        object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber() : message.companyId;
                if (message.subCompanyId != null && message.hasOwnProperty("subCompanyId"))
                    if (typeof message.subCompanyId === "number")
                        object.subCompanyId = options.longs === String ? String(message.subCompanyId) : message.subCompanyId;
                    else
                        object.subCompanyId = options.longs === String ? $util.Long.prototype.toString.call(message.subCompanyId) : options.longs === Number ? new $util.LongBits(message.subCompanyId.low >>> 0, message.subCompanyId.high >>> 0).toNumber() : message.subCompanyId;
                if (message.address != null && message.hasOwnProperty("address"))
                    object.address = message.address;
                if (message.coordinate != null && message.hasOwnProperty("coordinate"))
                    object.coordinate = $root.fastcity.common.Coordinate.toObject(message.coordinate, options);
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                return object;
            };

            /**
             * Converts this SubCompany to JSON.
             * @function toJSON
             * @memberof fastcity.courier.SubCompany
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SubCompany.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return SubCompany;
        })();

        courier.SubCompanyList = (function() {

            /**
             * Properties of a SubCompanyList.
             * @memberof fastcity.courier
             * @interface ISubCompanyList
             * @property {Array.<fastcity.courier.ISubCompany>|null} [subCompanies] SubCompanyList subCompanies
             */

            /**
             * Constructs a new SubCompanyList.
             * @memberof fastcity.courier
             * @classdesc Represents a SubCompanyList.
             * @implements ISubCompanyList
             * @constructor
             * @param {fastcity.courier.ISubCompanyList=} [properties] Properties to set
             */
            function SubCompanyList(properties) {
                this.subCompanies = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * SubCompanyList subCompanies.
             * @member {Array.<fastcity.courier.ISubCompany>} subCompanies
             * @memberof fastcity.courier.SubCompanyList
             * @instance
             */
            SubCompanyList.prototype.subCompanies = $util.emptyArray;

            /**
             * Creates a new SubCompanyList instance using the specified properties.
             * @function create
             * @memberof fastcity.courier.SubCompanyList
             * @static
             * @param {fastcity.courier.ISubCompanyList=} [properties] Properties to set
             * @returns {fastcity.courier.SubCompanyList} SubCompanyList instance
             */
            SubCompanyList.create = function create(properties) {
                return new SubCompanyList(properties);
            };

            /**
             * Encodes the specified SubCompanyList message. Does not implicitly {@link fastcity.courier.SubCompanyList.verify|verify} messages.
             * @function encode
             * @memberof fastcity.courier.SubCompanyList
             * @static
             * @param {fastcity.courier.ISubCompanyList} message SubCompanyList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SubCompanyList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.subCompanies != null && message.subCompanies.length)
                    for (var i = 0; i < message.subCompanies.length; ++i)
                        $root.fastcity.courier.SubCompany.encode(message.subCompanies[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a SubCompanyList message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.courier.SubCompanyList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.courier.SubCompanyList} SubCompanyList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SubCompanyList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.courier.SubCompanyList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.subCompanies && message.subCompanies.length))
                            message.subCompanies = [];
                        message.subCompanies.push($root.fastcity.courier.SubCompany.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a SubCompanyList message.
             * @function verify
             * @memberof fastcity.courier.SubCompanyList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SubCompanyList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.subCompanies != null && message.hasOwnProperty("subCompanies")) {
                    if (!Array.isArray(message.subCompanies))
                        return "subCompanies: array expected";
                    for (var i = 0; i < message.subCompanies.length; ++i) {
                        var error = $root.fastcity.courier.SubCompany.verify(message.subCompanies[i]);
                        if (error)
                            return "subCompanies." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a SubCompanyList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.courier.SubCompanyList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.courier.SubCompanyList} SubCompanyList
             */
            SubCompanyList.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.courier.SubCompanyList)
                    return object;
                var message = new $root.fastcity.courier.SubCompanyList();
                if (object.subCompanies) {
                    if (!Array.isArray(object.subCompanies))
                        throw TypeError(".fastcity.courier.SubCompanyList.subCompanies: array expected");
                    message.subCompanies = [];
                    for (var i = 0; i < object.subCompanies.length; ++i) {
                        if (typeof object.subCompanies[i] !== "object")
                            throw TypeError(".fastcity.courier.SubCompanyList.subCompanies: object expected");
                        message.subCompanies[i] = $root.fastcity.courier.SubCompany.fromObject(object.subCompanies[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a SubCompanyList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.courier.SubCompanyList
             * @static
             * @param {fastcity.courier.SubCompanyList} message SubCompanyList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SubCompanyList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.subCompanies = [];
                if (message.subCompanies && message.subCompanies.length) {
                    object.subCompanies = [];
                    for (var j = 0; j < message.subCompanies.length; ++j)
                        object.subCompanies[j] = $root.fastcity.courier.SubCompany.toObject(message.subCompanies[j], options);
                }
                return object;
            };

            /**
             * Converts this SubCompanyList to JSON.
             * @function toJSON
             * @memberof fastcity.courier.SubCompanyList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SubCompanyList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return SubCompanyList;
        })();

        courier.GetSubCompanyList = (function() {

            /**
             * Properties of a GetSubCompanyList.
             * @memberof fastcity.courier
             * @interface IGetSubCompanyList
             */

            /**
             * Constructs a new GetSubCompanyList.
             * @memberof fastcity.courier
             * @classdesc Represents a GetSubCompanyList.
             * @implements IGetSubCompanyList
             * @constructor
             * @param {fastcity.courier.IGetSubCompanyList=} [properties] Properties to set
             */
            function GetSubCompanyList(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new GetSubCompanyList instance using the specified properties.
             * @function create
             * @memberof fastcity.courier.GetSubCompanyList
             * @static
             * @param {fastcity.courier.IGetSubCompanyList=} [properties] Properties to set
             * @returns {fastcity.courier.GetSubCompanyList} GetSubCompanyList instance
             */
            GetSubCompanyList.create = function create(properties) {
                return new GetSubCompanyList(properties);
            };

            /**
             * Encodes the specified GetSubCompanyList message. Does not implicitly {@link fastcity.courier.GetSubCompanyList.verify|verify} messages.
             * @function encode
             * @memberof fastcity.courier.GetSubCompanyList
             * @static
             * @param {fastcity.courier.IGetSubCompanyList} message GetSubCompanyList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetSubCompanyList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Decodes a GetSubCompanyList message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.courier.GetSubCompanyList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.courier.GetSubCompanyList} GetSubCompanyList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetSubCompanyList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.courier.GetSubCompanyList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a GetSubCompanyList message.
             * @function verify
             * @memberof fastcity.courier.GetSubCompanyList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetSubCompanyList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a GetSubCompanyList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.courier.GetSubCompanyList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.courier.GetSubCompanyList} GetSubCompanyList
             */
            GetSubCompanyList.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.courier.GetSubCompanyList)
                    return object;
                return new $root.fastcity.courier.GetSubCompanyList();
            };

            /**
             * Creates a plain object from a GetSubCompanyList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.courier.GetSubCompanyList
             * @static
             * @param {fastcity.courier.GetSubCompanyList} message GetSubCompanyList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetSubCompanyList.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this GetSubCompanyList to JSON.
             * @function toJSON
             * @memberof fastcity.courier.GetSubCompanyList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetSubCompanyList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return GetSubCompanyList;
        })();

        courier.Result = (function() {

            /**
             * Properties of a Result.
             * @memberof fastcity.courier
             * @interface IResult
             * @property {fastcity.courier.Result.Code|null} [code] Result code
             * @property {string|null} [description] Result description
             * @property {fastcity.courier.CourierRequestResult|null} [courierRequestResult] Result courierRequestResult
             * @property {fastcity.courier.OrderRequestResult|null} [orderRequestResult] Result orderRequestResult
             * @property {fastcity.common.OrderPathRequestResult|null} [orderPathRequestResult] Result orderPathRequestResult
             */

            /**
             * Constructs a new Result.
             * @memberof fastcity.courier
             * @classdesc Represents a Result.
             * @implements IResult
             * @constructor
             * @param {fastcity.courier.IResult=} [properties] Properties to set
             */
            function Result(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Result code.
             * @member {fastcity.courier.Result.Code} code
             * @memberof fastcity.courier.Result
             * @instance
             */
            Result.prototype.code = 0;

            /**
             * Result description.
             * @member {string} description
             * @memberof fastcity.courier.Result
             * @instance
             */
            Result.prototype.description = "";

            /**
             * Result courierRequestResult.
             * @member {fastcity.courier.CourierRequestResult|null|undefined} courierRequestResult
             * @memberof fastcity.courier.Result
             * @instance
             */
            Result.prototype.courierRequestResult = null;

            /**
             * Result orderRequestResult.
             * @member {fastcity.courier.OrderRequestResult|null|undefined} orderRequestResult
             * @memberof fastcity.courier.Result
             * @instance
             */
            Result.prototype.orderRequestResult = null;

            /**
             * Result orderPathRequestResult.
             * @member {fastcity.common.OrderPathRequestResult|null|undefined} orderPathRequestResult
             * @memberof fastcity.courier.Result
             * @instance
             */
            Result.prototype.orderPathRequestResult = null;

            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;

            /**
             * Result descriptionCode.
             * @member {"courierRequestResult"|"orderRequestResult"|"orderPathRequestResult"|undefined} descriptionCode
             * @memberof fastcity.courier.Result
             * @instance
             */
            Object.defineProperty(Result.prototype, "descriptionCode", {
                get: $util.oneOfGetter($oneOfFields = ["courierRequestResult", "orderRequestResult", "orderPathRequestResult"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Creates a new Result instance using the specified properties.
             * @function create
             * @memberof fastcity.courier.Result
             * @static
             * @param {fastcity.courier.IResult=} [properties] Properties to set
             * @returns {fastcity.courier.Result} Result instance
             */
            Result.create = function create(properties) {
                return new Result(properties);
            };

            /**
             * Encodes the specified Result message. Does not implicitly {@link fastcity.courier.Result.verify|verify} messages.
             * @function encode
             * @memberof fastcity.courier.Result
             * @static
             * @param {fastcity.courier.IResult} message Result message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Result.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.code != null && Object.hasOwnProperty.call(message, "code"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.code);
                if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.description);
                if (message.courierRequestResult != null && Object.hasOwnProperty.call(message, "courierRequestResult"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.courierRequestResult);
                if (message.orderRequestResult != null && Object.hasOwnProperty.call(message, "orderRequestResult"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int32(message.orderRequestResult);
                if (message.orderPathRequestResult != null && Object.hasOwnProperty.call(message, "orderPathRequestResult"))
                    writer.uint32(/* id 5, wireType 0 =*/40).int32(message.orderPathRequestResult);
                return writer;
            };

            /**
             * Decodes a Result message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.courier.Result
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.courier.Result} Result
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Result.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.courier.Result();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.code = reader.int32();
                        break;
                    case 2:
                        message.description = reader.string();
                        break;
                    case 3:
                        message.courierRequestResult = reader.int32();
                        break;
                    case 4:
                        message.orderRequestResult = reader.int32();
                        break;
                    case 5:
                        message.orderPathRequestResult = reader.int32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a Result message.
             * @function verify
             * @memberof fastcity.courier.Result
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Result.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                var properties = {};
                if (message.code != null && message.hasOwnProperty("code"))
                    switch (message.code) {
                    default:
                        return "code: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
                if (message.description != null && message.hasOwnProperty("description"))
                    if (!$util.isString(message.description))
                        return "description: string expected";
                if (message.courierRequestResult != null && message.hasOwnProperty("courierRequestResult")) {
                    properties.descriptionCode = 1;
                    switch (message.courierRequestResult) {
                    default:
                        return "courierRequestResult: enum value expected";
                    case 0:
                        break;
                    }
                }
                if (message.orderRequestResult != null && message.hasOwnProperty("orderRequestResult")) {
                    if (properties.descriptionCode === 1)
                        return "descriptionCode: multiple values";
                    properties.descriptionCode = 1;
                    switch (message.orderRequestResult) {
                    default:
                        return "orderRequestResult: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                    case 9:
                        break;
                    }
                }
                if (message.orderPathRequestResult != null && message.hasOwnProperty("orderPathRequestResult")) {
                    if (properties.descriptionCode === 1)
                        return "descriptionCode: multiple values";
                    properties.descriptionCode = 1;
                    switch (message.orderPathRequestResult) {
                    default:
                        return "orderPathRequestResult: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
                }
                return null;
            };

            /**
             * Creates a Result message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.courier.Result
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.courier.Result} Result
             */
            Result.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.courier.Result)
                    return object;
                var message = new $root.fastcity.courier.Result();
                switch (object.code) {
                case "SUCCESS":
                case 0:
                    message.code = 0;
                    break;
                case "INVALID_REQUEST":
                case 1:
                    message.code = 1;
                    break;
                case "SERVER_ERROR":
                case 2:
                    message.code = 2;
                    break;
                }
                if (object.description != null)
                    message.description = String(object.description);
                switch (object.courierRequestResult) {
                case "BAD_MINIMAL_DISTANCE_TO_ORDER_TARGET":
                case 0:
                    message.courierRequestResult = 0;
                    break;
                }
                switch (object.orderRequestResult) {
                case "ORR_NOT_FOUND":
                case 0:
                    message.orderRequestResult = 0;
                    break;
                case "ORR_ALREADY_ACCEPTED":
                case 1:
                    message.orderRequestResult = 1;
                    break;
                case "ORR_ALREADY_FINISHED":
                case 2:
                    message.orderRequestResult = 2;
                    break;
                case "ORR_ORDER_OF_ANOTHER_COURIER":
                case 3:
                    message.orderRequestResult = 3;
                    break;
                case "ORR_FAILED_TO_ACCEPT_FAILED_TOO_MANY_ORDERS_ACCEPTED":
                case 4:
                    message.orderRequestResult = 4;
                    break;
                case "ORR_FAILED_TO_ACCEPT_FAILED_TO_CHANGE_COURIER":
                case 5:
                    message.orderRequestResult = 5;
                    break;
                case "ORR_FAILED_TO_ACCEPT_FAILED_TO_CHANGE_STATUS":
                case 6:
                    message.orderRequestResult = 6;
                    break;
                case "ORR_FAILED_TO_FINISH_FAILED_TARGET_LOCATION_TOO_FAR":
                case 7:
                    message.orderRequestResult = 7;
                    break;
                case "ORR_FAILED_TO_FINISH_FAILED_TO_CHANGE_STATUS":
                case 8:
                    message.orderRequestResult = 8;
                    break;
                case "ORR_FAILED_TO_FINISH_FAILED_TO_CLOSE_ORDER":
                case 9:
                    message.orderRequestResult = 9;
                    break;
                }
                switch (object.orderPathRequestResult) {
                case "OPRR_NOT_FOUND":
                case 0:
                    message.orderPathRequestResult = 0;
                    break;
                case "OPRR_BAD_ORDER_ID_ORDER_NOT_FOUND":
                case 1:
                    message.orderPathRequestResult = 1;
                    break;
                case "OPRR_NOT_FOUND_SUB_COMPANY_OF_ORDER":
                case 2:
                    message.orderPathRequestResult = 2;
                    break;
                }
                return message;
            };

            /**
             * Creates a plain object from a Result message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.courier.Result
             * @static
             * @param {fastcity.courier.Result} message Result
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Result.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.code = options.enums === String ? "SUCCESS" : 0;
                    object.description = "";
                }
                if (message.code != null && message.hasOwnProperty("code"))
                    object.code = options.enums === String ? $root.fastcity.courier.Result.Code[message.code] : message.code;
                if (message.description != null && message.hasOwnProperty("description"))
                    object.description = message.description;
                if (message.courierRequestResult != null && message.hasOwnProperty("courierRequestResult")) {
                    object.courierRequestResult = options.enums === String ? $root.fastcity.courier.CourierRequestResult[message.courierRequestResult] : message.courierRequestResult;
                    if (options.oneofs)
                        object.descriptionCode = "courierRequestResult";
                }
                if (message.orderRequestResult != null && message.hasOwnProperty("orderRequestResult")) {
                    object.orderRequestResult = options.enums === String ? $root.fastcity.courier.OrderRequestResult[message.orderRequestResult] : message.orderRequestResult;
                    if (options.oneofs)
                        object.descriptionCode = "orderRequestResult";
                }
                if (message.orderPathRequestResult != null && message.hasOwnProperty("orderPathRequestResult")) {
                    object.orderPathRequestResult = options.enums === String ? $root.fastcity.common.OrderPathRequestResult[message.orderPathRequestResult] : message.orderPathRequestResult;
                    if (options.oneofs)
                        object.descriptionCode = "orderPathRequestResult";
                }
                return object;
            };

            /**
             * Converts this Result to JSON.
             * @function toJSON
             * @memberof fastcity.courier.Result
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Result.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Code enum.
             * @name fastcity.courier.Result.Code
             * @enum {number}
             * @property {number} SUCCESS=0 SUCCESS value
             * @property {number} INVALID_REQUEST=1 INVALID_REQUEST value
             * @property {number} SERVER_ERROR=2 SERVER_ERROR value
             */
            Result.Code = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "SUCCESS"] = 0;
                values[valuesById[1] = "INVALID_REQUEST"] = 1;
                values[valuesById[2] = "SERVER_ERROR"] = 2;
                return values;
            })();

            return Result;
        })();

        courier.WorkingTime = (function() {

            /**
             * Properties of a WorkingTime.
             * @memberof fastcity.courier
             * @interface IWorkingTime
             * @property {number|Long|null} [subCompanyId] WorkingTime subCompanyId
             * @property {Array.<fastcity.courier.WorkingTime.IInterval>|null} [interval] WorkingTime interval
             */

            /**
             * Constructs a new WorkingTime.
             * @memberof fastcity.courier
             * @classdesc Represents a WorkingTime.
             * @implements IWorkingTime
             * @constructor
             * @param {fastcity.courier.IWorkingTime=} [properties] Properties to set
             */
            function WorkingTime(properties) {
                this.interval = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * WorkingTime subCompanyId.
             * @member {number|Long} subCompanyId
             * @memberof fastcity.courier.WorkingTime
             * @instance
             */
            WorkingTime.prototype.subCompanyId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * WorkingTime interval.
             * @member {Array.<fastcity.courier.WorkingTime.IInterval>} interval
             * @memberof fastcity.courier.WorkingTime
             * @instance
             */
            WorkingTime.prototype.interval = $util.emptyArray;

            /**
             * Creates a new WorkingTime instance using the specified properties.
             * @function create
             * @memberof fastcity.courier.WorkingTime
             * @static
             * @param {fastcity.courier.IWorkingTime=} [properties] Properties to set
             * @returns {fastcity.courier.WorkingTime} WorkingTime instance
             */
            WorkingTime.create = function create(properties) {
                return new WorkingTime(properties);
            };

            /**
             * Encodes the specified WorkingTime message. Does not implicitly {@link fastcity.courier.WorkingTime.verify|verify} messages.
             * @function encode
             * @memberof fastcity.courier.WorkingTime
             * @static
             * @param {fastcity.courier.IWorkingTime} message WorkingTime message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WorkingTime.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.subCompanyId != null && Object.hasOwnProperty.call(message, "subCompanyId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.subCompanyId);
                if (message.interval != null && message.interval.length)
                    for (var i = 0; i < message.interval.length; ++i)
                        $root.fastcity.courier.WorkingTime.Interval.encode(message.interval[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a WorkingTime message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.courier.WorkingTime
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.courier.WorkingTime} WorkingTime
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WorkingTime.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.courier.WorkingTime();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.subCompanyId = reader.uint64();
                        break;
                    case 2:
                        if (!(message.interval && message.interval.length))
                            message.interval = [];
                        message.interval.push($root.fastcity.courier.WorkingTime.Interval.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a WorkingTime message.
             * @function verify
             * @memberof fastcity.courier.WorkingTime
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            WorkingTime.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.subCompanyId != null && message.hasOwnProperty("subCompanyId"))
                    if (!$util.isInteger(message.subCompanyId) && !(message.subCompanyId && $util.isInteger(message.subCompanyId.low) && $util.isInteger(message.subCompanyId.high)))
                        return "subCompanyId: integer|Long expected";
                if (message.interval != null && message.hasOwnProperty("interval")) {
                    if (!Array.isArray(message.interval))
                        return "interval: array expected";
                    for (var i = 0; i < message.interval.length; ++i) {
                        var error = $root.fastcity.courier.WorkingTime.Interval.verify(message.interval[i]);
                        if (error)
                            return "interval." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a WorkingTime message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.courier.WorkingTime
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.courier.WorkingTime} WorkingTime
             */
            WorkingTime.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.courier.WorkingTime)
                    return object;
                var message = new $root.fastcity.courier.WorkingTime();
                if (object.subCompanyId != null)
                    if ($util.Long)
                        (message.subCompanyId = $util.Long.fromValue(object.subCompanyId)).unsigned = true;
                    else if (typeof object.subCompanyId === "string")
                        message.subCompanyId = parseInt(object.subCompanyId, 10);
                    else if (typeof object.subCompanyId === "number")
                        message.subCompanyId = object.subCompanyId;
                    else if (typeof object.subCompanyId === "object")
                        message.subCompanyId = new $util.LongBits(object.subCompanyId.low >>> 0, object.subCompanyId.high >>> 0).toNumber(true);
                if (object.interval) {
                    if (!Array.isArray(object.interval))
                        throw TypeError(".fastcity.courier.WorkingTime.interval: array expected");
                    message.interval = [];
                    for (var i = 0; i < object.interval.length; ++i) {
                        if (typeof object.interval[i] !== "object")
                            throw TypeError(".fastcity.courier.WorkingTime.interval: object expected");
                        message.interval[i] = $root.fastcity.courier.WorkingTime.Interval.fromObject(object.interval[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a WorkingTime message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.courier.WorkingTime
             * @static
             * @param {fastcity.courier.WorkingTime} message WorkingTime
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            WorkingTime.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.interval = [];
                if (options.defaults)
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.subCompanyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.subCompanyId = options.longs === String ? "0" : 0;
                if (message.subCompanyId != null && message.hasOwnProperty("subCompanyId"))
                    if (typeof message.subCompanyId === "number")
                        object.subCompanyId = options.longs === String ? String(message.subCompanyId) : message.subCompanyId;
                    else
                        object.subCompanyId = options.longs === String ? $util.Long.prototype.toString.call(message.subCompanyId) : options.longs === Number ? new $util.LongBits(message.subCompanyId.low >>> 0, message.subCompanyId.high >>> 0).toNumber(true) : message.subCompanyId;
                if (message.interval && message.interval.length) {
                    object.interval = [];
                    for (var j = 0; j < message.interval.length; ++j)
                        object.interval[j] = $root.fastcity.courier.WorkingTime.Interval.toObject(message.interval[j], options);
                }
                return object;
            };

            /**
             * Converts this WorkingTime to JSON.
             * @function toJSON
             * @memberof fastcity.courier.WorkingTime
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            WorkingTime.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            WorkingTime.Interval = (function() {

                /**
                 * Properties of an Interval.
                 * @memberof fastcity.courier.WorkingTime
                 * @interface IInterval
                 * @property {number|Long|null} [openTime] Interval openTime
                 * @property {number|Long|null} [closeTime] Interval closeTime
                 */

                /**
                 * Constructs a new Interval.
                 * @memberof fastcity.courier.WorkingTime
                 * @classdesc Represents an Interval.
                 * @implements IInterval
                 * @constructor
                 * @param {fastcity.courier.WorkingTime.IInterval=} [properties] Properties to set
                 */
                function Interval(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Interval openTime.
                 * @member {number|Long} openTime
                 * @memberof fastcity.courier.WorkingTime.Interval
                 * @instance
                 */
                Interval.prototype.openTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Interval closeTime.
                 * @member {number|Long} closeTime
                 * @memberof fastcity.courier.WorkingTime.Interval
                 * @instance
                 */
                Interval.prototype.closeTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Creates a new Interval instance using the specified properties.
                 * @function create
                 * @memberof fastcity.courier.WorkingTime.Interval
                 * @static
                 * @param {fastcity.courier.WorkingTime.IInterval=} [properties] Properties to set
                 * @returns {fastcity.courier.WorkingTime.Interval} Interval instance
                 */
                Interval.create = function create(properties) {
                    return new Interval(properties);
                };

                /**
                 * Encodes the specified Interval message. Does not implicitly {@link fastcity.courier.WorkingTime.Interval.verify|verify} messages.
                 * @function encode
                 * @memberof fastcity.courier.WorkingTime.Interval
                 * @static
                 * @param {fastcity.courier.WorkingTime.IInterval} message Interval message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Interval.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.openTime != null && Object.hasOwnProperty.call(message, "openTime"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int64(message.openTime);
                    if (message.closeTime != null && Object.hasOwnProperty.call(message, "closeTime"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int64(message.closeTime);
                    return writer;
                };

                /**
                 * Decodes an Interval message from the specified reader or buffer.
                 * @function decode
                 * @memberof fastcity.courier.WorkingTime.Interval
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {fastcity.courier.WorkingTime.Interval} Interval
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Interval.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.courier.WorkingTime.Interval();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.openTime = reader.int64();
                            break;
                        case 2:
                            message.closeTime = reader.int64();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Verifies an Interval message.
                 * @function verify
                 * @memberof fastcity.courier.WorkingTime.Interval
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Interval.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.openTime != null && message.hasOwnProperty("openTime"))
                        if (!$util.isInteger(message.openTime) && !(message.openTime && $util.isInteger(message.openTime.low) && $util.isInteger(message.openTime.high)))
                            return "openTime: integer|Long expected";
                    if (message.closeTime != null && message.hasOwnProperty("closeTime"))
                        if (!$util.isInteger(message.closeTime) && !(message.closeTime && $util.isInteger(message.closeTime.low) && $util.isInteger(message.closeTime.high)))
                            return "closeTime: integer|Long expected";
                    return null;
                };

                /**
                 * Creates an Interval message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof fastcity.courier.WorkingTime.Interval
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {fastcity.courier.WorkingTime.Interval} Interval
                 */
                Interval.fromObject = function fromObject(object) {
                    if (object instanceof $root.fastcity.courier.WorkingTime.Interval)
                        return object;
                    var message = new $root.fastcity.courier.WorkingTime.Interval();
                    if (object.openTime != null)
                        if ($util.Long)
                            (message.openTime = $util.Long.fromValue(object.openTime)).unsigned = false;
                        else if (typeof object.openTime === "string")
                            message.openTime = parseInt(object.openTime, 10);
                        else if (typeof object.openTime === "number")
                            message.openTime = object.openTime;
                        else if (typeof object.openTime === "object")
                            message.openTime = new $util.LongBits(object.openTime.low >>> 0, object.openTime.high >>> 0).toNumber();
                    if (object.closeTime != null)
                        if ($util.Long)
                            (message.closeTime = $util.Long.fromValue(object.closeTime)).unsigned = false;
                        else if (typeof object.closeTime === "string")
                            message.closeTime = parseInt(object.closeTime, 10);
                        else if (typeof object.closeTime === "number")
                            message.closeTime = object.closeTime;
                        else if (typeof object.closeTime === "object")
                            message.closeTime = new $util.LongBits(object.closeTime.low >>> 0, object.closeTime.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from an Interval message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof fastcity.courier.WorkingTime.Interval
                 * @static
                 * @param {fastcity.courier.WorkingTime.Interval} message Interval
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Interval.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.openTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.openTime = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.closeTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.closeTime = options.longs === String ? "0" : 0;
                    }
                    if (message.openTime != null && message.hasOwnProperty("openTime"))
                        if (typeof message.openTime === "number")
                            object.openTime = options.longs === String ? String(message.openTime) : message.openTime;
                        else
                            object.openTime = options.longs === String ? $util.Long.prototype.toString.call(message.openTime) : options.longs === Number ? new $util.LongBits(message.openTime.low >>> 0, message.openTime.high >>> 0).toNumber() : message.openTime;
                    if (message.closeTime != null && message.hasOwnProperty("closeTime"))
                        if (typeof message.closeTime === "number")
                            object.closeTime = options.longs === String ? String(message.closeTime) : message.closeTime;
                        else
                            object.closeTime = options.longs === String ? $util.Long.prototype.toString.call(message.closeTime) : options.longs === Number ? new $util.LongBits(message.closeTime.low >>> 0, message.closeTime.high >>> 0).toNumber() : message.closeTime;
                    return object;
                };

                /**
                 * Converts this Interval to JSON.
                 * @function toJSON
                 * @memberof fastcity.courier.WorkingTime.Interval
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Interval.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return Interval;
            })();

            return WorkingTime;
        })();

        courier.WorkingTimeList = (function() {

            /**
             * Properties of a WorkingTimeList.
             * @memberof fastcity.courier
             * @interface IWorkingTimeList
             * @property {Array.<fastcity.courier.IWorkingTime>|null} [workingTime] WorkingTimeList workingTime
             */

            /**
             * Constructs a new WorkingTimeList.
             * @memberof fastcity.courier
             * @classdesc Represents a WorkingTimeList.
             * @implements IWorkingTimeList
             * @constructor
             * @param {fastcity.courier.IWorkingTimeList=} [properties] Properties to set
             */
            function WorkingTimeList(properties) {
                this.workingTime = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * WorkingTimeList workingTime.
             * @member {Array.<fastcity.courier.IWorkingTime>} workingTime
             * @memberof fastcity.courier.WorkingTimeList
             * @instance
             */
            WorkingTimeList.prototype.workingTime = $util.emptyArray;

            /**
             * Creates a new WorkingTimeList instance using the specified properties.
             * @function create
             * @memberof fastcity.courier.WorkingTimeList
             * @static
             * @param {fastcity.courier.IWorkingTimeList=} [properties] Properties to set
             * @returns {fastcity.courier.WorkingTimeList} WorkingTimeList instance
             */
            WorkingTimeList.create = function create(properties) {
                return new WorkingTimeList(properties);
            };

            /**
             * Encodes the specified WorkingTimeList message. Does not implicitly {@link fastcity.courier.WorkingTimeList.verify|verify} messages.
             * @function encode
             * @memberof fastcity.courier.WorkingTimeList
             * @static
             * @param {fastcity.courier.IWorkingTimeList} message WorkingTimeList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WorkingTimeList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.workingTime != null && message.workingTime.length)
                    for (var i = 0; i < message.workingTime.length; ++i)
                        $root.fastcity.courier.WorkingTime.encode(message.workingTime[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a WorkingTimeList message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.courier.WorkingTimeList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.courier.WorkingTimeList} WorkingTimeList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WorkingTimeList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.courier.WorkingTimeList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.workingTime && message.workingTime.length))
                            message.workingTime = [];
                        message.workingTime.push($root.fastcity.courier.WorkingTime.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a WorkingTimeList message.
             * @function verify
             * @memberof fastcity.courier.WorkingTimeList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            WorkingTimeList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.workingTime != null && message.hasOwnProperty("workingTime")) {
                    if (!Array.isArray(message.workingTime))
                        return "workingTime: array expected";
                    for (var i = 0; i < message.workingTime.length; ++i) {
                        var error = $root.fastcity.courier.WorkingTime.verify(message.workingTime[i]);
                        if (error)
                            return "workingTime." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a WorkingTimeList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.courier.WorkingTimeList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.courier.WorkingTimeList} WorkingTimeList
             */
            WorkingTimeList.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.courier.WorkingTimeList)
                    return object;
                var message = new $root.fastcity.courier.WorkingTimeList();
                if (object.workingTime) {
                    if (!Array.isArray(object.workingTime))
                        throw TypeError(".fastcity.courier.WorkingTimeList.workingTime: array expected");
                    message.workingTime = [];
                    for (var i = 0; i < object.workingTime.length; ++i) {
                        if (typeof object.workingTime[i] !== "object")
                            throw TypeError(".fastcity.courier.WorkingTimeList.workingTime: object expected");
                        message.workingTime[i] = $root.fastcity.courier.WorkingTime.fromObject(object.workingTime[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a WorkingTimeList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.courier.WorkingTimeList
             * @static
             * @param {fastcity.courier.WorkingTimeList} message WorkingTimeList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            WorkingTimeList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.workingTime = [];
                if (message.workingTime && message.workingTime.length) {
                    object.workingTime = [];
                    for (var j = 0; j < message.workingTime.length; ++j)
                        object.workingTime[j] = $root.fastcity.courier.WorkingTime.toObject(message.workingTime[j], options);
                }
                return object;
            };

            /**
             * Converts this WorkingTimeList to JSON.
             * @function toJSON
             * @memberof fastcity.courier.WorkingTimeList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            WorkingTimeList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return WorkingTimeList;
        })();

        courier.GetWorkingTime = (function() {

            /**
             * Properties of a GetWorkingTime.
             * @memberof fastcity.courier
             * @interface IGetWorkingTime
             */

            /**
             * Constructs a new GetWorkingTime.
             * @memberof fastcity.courier
             * @classdesc Represents a GetWorkingTime.
             * @implements IGetWorkingTime
             * @constructor
             * @param {fastcity.courier.IGetWorkingTime=} [properties] Properties to set
             */
            function GetWorkingTime(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new GetWorkingTime instance using the specified properties.
             * @function create
             * @memberof fastcity.courier.GetWorkingTime
             * @static
             * @param {fastcity.courier.IGetWorkingTime=} [properties] Properties to set
             * @returns {fastcity.courier.GetWorkingTime} GetWorkingTime instance
             */
            GetWorkingTime.create = function create(properties) {
                return new GetWorkingTime(properties);
            };

            /**
             * Encodes the specified GetWorkingTime message. Does not implicitly {@link fastcity.courier.GetWorkingTime.verify|verify} messages.
             * @function encode
             * @memberof fastcity.courier.GetWorkingTime
             * @static
             * @param {fastcity.courier.IGetWorkingTime} message GetWorkingTime message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetWorkingTime.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Decodes a GetWorkingTime message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.courier.GetWorkingTime
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.courier.GetWorkingTime} GetWorkingTime
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetWorkingTime.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.courier.GetWorkingTime();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a GetWorkingTime message.
             * @function verify
             * @memberof fastcity.courier.GetWorkingTime
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetWorkingTime.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a GetWorkingTime message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.courier.GetWorkingTime
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.courier.GetWorkingTime} GetWorkingTime
             */
            GetWorkingTime.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.courier.GetWorkingTime)
                    return object;
                return new $root.fastcity.courier.GetWorkingTime();
            };

            /**
             * Creates a plain object from a GetWorkingTime message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.courier.GetWorkingTime
             * @static
             * @param {fastcity.courier.GetWorkingTime} message GetWorkingTime
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetWorkingTime.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this GetWorkingTime to JSON.
             * @function toJSON
             * @memberof fastcity.courier.GetWorkingTime
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetWorkingTime.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return GetWorkingTime;
        })();

        courier.Version = (function() {

            /**
             * Properties of a Version.
             * @memberof fastcity.courier
             * @interface IVersion
             */

            /**
             * Constructs a new Version.
             * @memberof fastcity.courier
             * @classdesc Represents a Version.
             * @implements IVersion
             * @constructor
             * @param {fastcity.courier.IVersion=} [properties] Properties to set
             */
            function Version(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new Version instance using the specified properties.
             * @function create
             * @memberof fastcity.courier.Version
             * @static
             * @param {fastcity.courier.IVersion=} [properties] Properties to set
             * @returns {fastcity.courier.Version} Version instance
             */
            Version.create = function create(properties) {
                return new Version(properties);
            };

            /**
             * Encodes the specified Version message. Does not implicitly {@link fastcity.courier.Version.verify|verify} messages.
             * @function encode
             * @memberof fastcity.courier.Version
             * @static
             * @param {fastcity.courier.IVersion} message Version message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Version.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Decodes a Version message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.courier.Version
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.courier.Version} Version
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Version.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.courier.Version();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a Version message.
             * @function verify
             * @memberof fastcity.courier.Version
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Version.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a Version message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.courier.Version
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.courier.Version} Version
             */
            Version.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.courier.Version)
                    return object;
                return new $root.fastcity.courier.Version();
            };

            /**
             * Creates a plain object from a Version message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.courier.Version
             * @static
             * @param {fastcity.courier.Version} message Version
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Version.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this Version to JSON.
             * @function toJSON
             * @memberof fastcity.courier.Version
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Version.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Value enum.
             * @name fastcity.courier.Version.Value
             * @enum {number}
             * @property {number} PLACEHOLDER1=0 PLACEHOLDER1 value
             * @property {number} PLACEHOLDER2=0 PLACEHOLDER2 value
             * @property {number} MAJOR=3 MAJOR value
             * @property {number} MINOR=16 MINOR value
             * @property {number} PATCH=0 PATCH value
             */
            Version.Value = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "PLACEHOLDER1"] = 0;
                values["PLACEHOLDER2"] = 0;
                values[valuesById[3] = "MAJOR"] = 3;
                values[valuesById[16] = "MINOR"] = 16;
                values["PATCH"] = 0;
                return values;
            })();

            return Version;
        })();

        return courier;
    })();

    return fastcity;
})();

$root.google = (function() {

    /**
     * Namespace google.
     * @exports google
     * @namespace
     */
    var google = {};

    google.protobuf = (function() {

        /**
         * Namespace protobuf.
         * @memberof google
         * @namespace
         */
        var protobuf = {};

        protobuf.FileDescriptorSet = (function() {

            /**
             * Properties of a FileDescriptorSet.
             * @memberof google.protobuf
             * @interface IFileDescriptorSet
             * @property {Array.<google.protobuf.IFileDescriptorProto>|null} [file] FileDescriptorSet file
             */

            /**
             * Constructs a new FileDescriptorSet.
             * @memberof google.protobuf
             * @classdesc Represents a FileDescriptorSet.
             * @implements IFileDescriptorSet
             * @constructor
             * @param {google.protobuf.IFileDescriptorSet=} [properties] Properties to set
             */
            function FileDescriptorSet(properties) {
                this.file = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * FileDescriptorSet file.
             * @member {Array.<google.protobuf.IFileDescriptorProto>} file
             * @memberof google.protobuf.FileDescriptorSet
             * @instance
             */
            FileDescriptorSet.prototype.file = $util.emptyArray;

            /**
             * Creates a new FileDescriptorSet instance using the specified properties.
             * @function create
             * @memberof google.protobuf.FileDescriptorSet
             * @static
             * @param {google.protobuf.IFileDescriptorSet=} [properties] Properties to set
             * @returns {google.protobuf.FileDescriptorSet} FileDescriptorSet instance
             */
            FileDescriptorSet.create = function create(properties) {
                return new FileDescriptorSet(properties);
            };

            /**
             * Encodes the specified FileDescriptorSet message. Does not implicitly {@link google.protobuf.FileDescriptorSet.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.FileDescriptorSet
             * @static
             * @param {google.protobuf.IFileDescriptorSet} message FileDescriptorSet message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FileDescriptorSet.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.file != null && message.file.length)
                    for (var i = 0; i < message.file.length; ++i)
                        $root.google.protobuf.FileDescriptorProto.encode(message.file[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a FileDescriptorSet message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.FileDescriptorSet
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.FileDescriptorSet} FileDescriptorSet
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FileDescriptorSet.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.FileDescriptorSet();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.file && message.file.length))
                            message.file = [];
                        message.file.push($root.google.protobuf.FileDescriptorProto.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a FileDescriptorSet message.
             * @function verify
             * @memberof google.protobuf.FileDescriptorSet
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            FileDescriptorSet.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.file != null && message.hasOwnProperty("file")) {
                    if (!Array.isArray(message.file))
                        return "file: array expected";
                    for (var i = 0; i < message.file.length; ++i) {
                        var error = $root.google.protobuf.FileDescriptorProto.verify(message.file[i]);
                        if (error)
                            return "file." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a FileDescriptorSet message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.FileDescriptorSet
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.FileDescriptorSet} FileDescriptorSet
             */
            FileDescriptorSet.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.FileDescriptorSet)
                    return object;
                var message = new $root.google.protobuf.FileDescriptorSet();
                if (object.file) {
                    if (!Array.isArray(object.file))
                        throw TypeError(".google.protobuf.FileDescriptorSet.file: array expected");
                    message.file = [];
                    for (var i = 0; i < object.file.length; ++i) {
                        if (typeof object.file[i] !== "object")
                            throw TypeError(".google.protobuf.FileDescriptorSet.file: object expected");
                        message.file[i] = $root.google.protobuf.FileDescriptorProto.fromObject(object.file[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a FileDescriptorSet message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.FileDescriptorSet
             * @static
             * @param {google.protobuf.FileDescriptorSet} message FileDescriptorSet
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FileDescriptorSet.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.file = [];
                if (message.file && message.file.length) {
                    object.file = [];
                    for (var j = 0; j < message.file.length; ++j)
                        object.file[j] = $root.google.protobuf.FileDescriptorProto.toObject(message.file[j], options);
                }
                return object;
            };

            /**
             * Converts this FileDescriptorSet to JSON.
             * @function toJSON
             * @memberof google.protobuf.FileDescriptorSet
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FileDescriptorSet.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return FileDescriptorSet;
        })();

        protobuf.FileDescriptorProto = (function() {

            /**
             * Properties of a FileDescriptorProto.
             * @memberof google.protobuf
             * @interface IFileDescriptorProto
             * @property {string|null} [name] FileDescriptorProto name
             * @property {string|null} ["package"] FileDescriptorProto package
             * @property {Array.<string>|null} [dependency] FileDescriptorProto dependency
             * @property {Array.<number>|null} [publicDependency] FileDescriptorProto publicDependency
             * @property {Array.<number>|null} [weakDependency] FileDescriptorProto weakDependency
             * @property {Array.<google.protobuf.IDescriptorProto>|null} [messageType] FileDescriptorProto messageType
             * @property {Array.<google.protobuf.IEnumDescriptorProto>|null} [enumType] FileDescriptorProto enumType
             * @property {Array.<google.protobuf.IServiceDescriptorProto>|null} [service] FileDescriptorProto service
             * @property {Array.<google.protobuf.IFieldDescriptorProto>|null} [extension] FileDescriptorProto extension
             * @property {google.protobuf.IFileOptions|null} [options] FileDescriptorProto options
             * @property {google.protobuf.ISourceCodeInfo|null} [sourceCodeInfo] FileDescriptorProto sourceCodeInfo
             * @property {string|null} [syntax] FileDescriptorProto syntax
             */

            /**
             * Constructs a new FileDescriptorProto.
             * @memberof google.protobuf
             * @classdesc Represents a FileDescriptorProto.
             * @implements IFileDescriptorProto
             * @constructor
             * @param {google.protobuf.IFileDescriptorProto=} [properties] Properties to set
             */
            function FileDescriptorProto(properties) {
                this.dependency = [];
                this.publicDependency = [];
                this.weakDependency = [];
                this.messageType = [];
                this.enumType = [];
                this.service = [];
                this.extension = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * FileDescriptorProto name.
             * @member {string} name
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.name = "";

            /**
             * FileDescriptorProto package.
             * @member {string} package
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype["package"] = "";

            /**
             * FileDescriptorProto dependency.
             * @member {Array.<string>} dependency
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.dependency = $util.emptyArray;

            /**
             * FileDescriptorProto publicDependency.
             * @member {Array.<number>} publicDependency
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.publicDependency = $util.emptyArray;

            /**
             * FileDescriptorProto weakDependency.
             * @member {Array.<number>} weakDependency
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.weakDependency = $util.emptyArray;

            /**
             * FileDescriptorProto messageType.
             * @member {Array.<google.protobuf.IDescriptorProto>} messageType
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.messageType = $util.emptyArray;

            /**
             * FileDescriptorProto enumType.
             * @member {Array.<google.protobuf.IEnumDescriptorProto>} enumType
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.enumType = $util.emptyArray;

            /**
             * FileDescriptorProto service.
             * @member {Array.<google.protobuf.IServiceDescriptorProto>} service
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.service = $util.emptyArray;

            /**
             * FileDescriptorProto extension.
             * @member {Array.<google.protobuf.IFieldDescriptorProto>} extension
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.extension = $util.emptyArray;

            /**
             * FileDescriptorProto options.
             * @member {google.protobuf.IFileOptions|null|undefined} options
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.options = null;

            /**
             * FileDescriptorProto sourceCodeInfo.
             * @member {google.protobuf.ISourceCodeInfo|null|undefined} sourceCodeInfo
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.sourceCodeInfo = null;

            /**
             * FileDescriptorProto syntax.
             * @member {string} syntax
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.syntax = "";

            /**
             * Creates a new FileDescriptorProto instance using the specified properties.
             * @function create
             * @memberof google.protobuf.FileDescriptorProto
             * @static
             * @param {google.protobuf.IFileDescriptorProto=} [properties] Properties to set
             * @returns {google.protobuf.FileDescriptorProto} FileDescriptorProto instance
             */
            FileDescriptorProto.create = function create(properties) {
                return new FileDescriptorProto(properties);
            };

            /**
             * Encodes the specified FileDescriptorProto message. Does not implicitly {@link google.protobuf.FileDescriptorProto.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.FileDescriptorProto
             * @static
             * @param {google.protobuf.IFileDescriptorProto} message FileDescriptorProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FileDescriptorProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                if (message["package"] != null && Object.hasOwnProperty.call(message, "package"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message["package"]);
                if (message.dependency != null && message.dependency.length)
                    for (var i = 0; i < message.dependency.length; ++i)
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.dependency[i]);
                if (message.messageType != null && message.messageType.length)
                    for (var i = 0; i < message.messageType.length; ++i)
                        $root.google.protobuf.DescriptorProto.encode(message.messageType[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.enumType != null && message.enumType.length)
                    for (var i = 0; i < message.enumType.length; ++i)
                        $root.google.protobuf.EnumDescriptorProto.encode(message.enumType[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.service != null && message.service.length)
                    for (var i = 0; i < message.service.length; ++i)
                        $root.google.protobuf.ServiceDescriptorProto.encode(message.service[i], writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.extension != null && message.extension.length)
                    for (var i = 0; i < message.extension.length; ++i)
                        $root.google.protobuf.FieldDescriptorProto.encode(message.extension[i], writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                if (message.options != null && Object.hasOwnProperty.call(message, "options"))
                    $root.google.protobuf.FileOptions.encode(message.options, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                if (message.sourceCodeInfo != null && Object.hasOwnProperty.call(message, "sourceCodeInfo"))
                    $root.google.protobuf.SourceCodeInfo.encode(message.sourceCodeInfo, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                if (message.publicDependency != null && message.publicDependency.length)
                    for (var i = 0; i < message.publicDependency.length; ++i)
                        writer.uint32(/* id 10, wireType 0 =*/80).int32(message.publicDependency[i]);
                if (message.weakDependency != null && message.weakDependency.length)
                    for (var i = 0; i < message.weakDependency.length; ++i)
                        writer.uint32(/* id 11, wireType 0 =*/88).int32(message.weakDependency[i]);
                if (message.syntax != null && Object.hasOwnProperty.call(message, "syntax"))
                    writer.uint32(/* id 12, wireType 2 =*/98).string(message.syntax);
                return writer;
            };

            /**
             * Decodes a FileDescriptorProto message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.FileDescriptorProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.FileDescriptorProto} FileDescriptorProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FileDescriptorProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.FileDescriptorProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.name = reader.string();
                        break;
                    case 2:
                        message["package"] = reader.string();
                        break;
                    case 3:
                        if (!(message.dependency && message.dependency.length))
                            message.dependency = [];
                        message.dependency.push(reader.string());
                        break;
                    case 10:
                        if (!(message.publicDependency && message.publicDependency.length))
                            message.publicDependency = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.publicDependency.push(reader.int32());
                        } else
                            message.publicDependency.push(reader.int32());
                        break;
                    case 11:
                        if (!(message.weakDependency && message.weakDependency.length))
                            message.weakDependency = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.weakDependency.push(reader.int32());
                        } else
                            message.weakDependency.push(reader.int32());
                        break;
                    case 4:
                        if (!(message.messageType && message.messageType.length))
                            message.messageType = [];
                        message.messageType.push($root.google.protobuf.DescriptorProto.decode(reader, reader.uint32()));
                        break;
                    case 5:
                        if (!(message.enumType && message.enumType.length))
                            message.enumType = [];
                        message.enumType.push($root.google.protobuf.EnumDescriptorProto.decode(reader, reader.uint32()));
                        break;
                    case 6:
                        if (!(message.service && message.service.length))
                            message.service = [];
                        message.service.push($root.google.protobuf.ServiceDescriptorProto.decode(reader, reader.uint32()));
                        break;
                    case 7:
                        if (!(message.extension && message.extension.length))
                            message.extension = [];
                        message.extension.push($root.google.protobuf.FieldDescriptorProto.decode(reader, reader.uint32()));
                        break;
                    case 8:
                        message.options = $root.google.protobuf.FileOptions.decode(reader, reader.uint32());
                        break;
                    case 9:
                        message.sourceCodeInfo = $root.google.protobuf.SourceCodeInfo.decode(reader, reader.uint32());
                        break;
                    case 12:
                        message.syntax = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a FileDescriptorProto message.
             * @function verify
             * @memberof google.protobuf.FileDescriptorProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            FileDescriptorProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message["package"] != null && message.hasOwnProperty("package"))
                    if (!$util.isString(message["package"]))
                        return "package: string expected";
                if (message.dependency != null && message.hasOwnProperty("dependency")) {
                    if (!Array.isArray(message.dependency))
                        return "dependency: array expected";
                    for (var i = 0; i < message.dependency.length; ++i)
                        if (!$util.isString(message.dependency[i]))
                            return "dependency: string[] expected";
                }
                if (message.publicDependency != null && message.hasOwnProperty("publicDependency")) {
                    if (!Array.isArray(message.publicDependency))
                        return "publicDependency: array expected";
                    for (var i = 0; i < message.publicDependency.length; ++i)
                        if (!$util.isInteger(message.publicDependency[i]))
                            return "publicDependency: integer[] expected";
                }
                if (message.weakDependency != null && message.hasOwnProperty("weakDependency")) {
                    if (!Array.isArray(message.weakDependency))
                        return "weakDependency: array expected";
                    for (var i = 0; i < message.weakDependency.length; ++i)
                        if (!$util.isInteger(message.weakDependency[i]))
                            return "weakDependency: integer[] expected";
                }
                if (message.messageType != null && message.hasOwnProperty("messageType")) {
                    if (!Array.isArray(message.messageType))
                        return "messageType: array expected";
                    for (var i = 0; i < message.messageType.length; ++i) {
                        var error = $root.google.protobuf.DescriptorProto.verify(message.messageType[i]);
                        if (error)
                            return "messageType." + error;
                    }
                }
                if (message.enumType != null && message.hasOwnProperty("enumType")) {
                    if (!Array.isArray(message.enumType))
                        return "enumType: array expected";
                    for (var i = 0; i < message.enumType.length; ++i) {
                        var error = $root.google.protobuf.EnumDescriptorProto.verify(message.enumType[i]);
                        if (error)
                            return "enumType." + error;
                    }
                }
                if (message.service != null && message.hasOwnProperty("service")) {
                    if (!Array.isArray(message.service))
                        return "service: array expected";
                    for (var i = 0; i < message.service.length; ++i) {
                        var error = $root.google.protobuf.ServiceDescriptorProto.verify(message.service[i]);
                        if (error)
                            return "service." + error;
                    }
                }
                if (message.extension != null && message.hasOwnProperty("extension")) {
                    if (!Array.isArray(message.extension))
                        return "extension: array expected";
                    for (var i = 0; i < message.extension.length; ++i) {
                        var error = $root.google.protobuf.FieldDescriptorProto.verify(message.extension[i]);
                        if (error)
                            return "extension." + error;
                    }
                }
                if (message.options != null && message.hasOwnProperty("options")) {
                    var error = $root.google.protobuf.FileOptions.verify(message.options);
                    if (error)
                        return "options." + error;
                }
                if (message.sourceCodeInfo != null && message.hasOwnProperty("sourceCodeInfo")) {
                    var error = $root.google.protobuf.SourceCodeInfo.verify(message.sourceCodeInfo);
                    if (error)
                        return "sourceCodeInfo." + error;
                }
                if (message.syntax != null && message.hasOwnProperty("syntax"))
                    if (!$util.isString(message.syntax))
                        return "syntax: string expected";
                return null;
            };

            /**
             * Creates a FileDescriptorProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.FileDescriptorProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.FileDescriptorProto} FileDescriptorProto
             */
            FileDescriptorProto.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.FileDescriptorProto)
                    return object;
                var message = new $root.google.protobuf.FileDescriptorProto();
                if (object.name != null)
                    message.name = String(object.name);
                if (object["package"] != null)
                    message["package"] = String(object["package"]);
                if (object.dependency) {
                    if (!Array.isArray(object.dependency))
                        throw TypeError(".google.protobuf.FileDescriptorProto.dependency: array expected");
                    message.dependency = [];
                    for (var i = 0; i < object.dependency.length; ++i)
                        message.dependency[i] = String(object.dependency[i]);
                }
                if (object.publicDependency) {
                    if (!Array.isArray(object.publicDependency))
                        throw TypeError(".google.protobuf.FileDescriptorProto.publicDependency: array expected");
                    message.publicDependency = [];
                    for (var i = 0; i < object.publicDependency.length; ++i)
                        message.publicDependency[i] = object.publicDependency[i] | 0;
                }
                if (object.weakDependency) {
                    if (!Array.isArray(object.weakDependency))
                        throw TypeError(".google.protobuf.FileDescriptorProto.weakDependency: array expected");
                    message.weakDependency = [];
                    for (var i = 0; i < object.weakDependency.length; ++i)
                        message.weakDependency[i] = object.weakDependency[i] | 0;
                }
                if (object.messageType) {
                    if (!Array.isArray(object.messageType))
                        throw TypeError(".google.protobuf.FileDescriptorProto.messageType: array expected");
                    message.messageType = [];
                    for (var i = 0; i < object.messageType.length; ++i) {
                        if (typeof object.messageType[i] !== "object")
                            throw TypeError(".google.protobuf.FileDescriptorProto.messageType: object expected");
                        message.messageType[i] = $root.google.protobuf.DescriptorProto.fromObject(object.messageType[i]);
                    }
                }
                if (object.enumType) {
                    if (!Array.isArray(object.enumType))
                        throw TypeError(".google.protobuf.FileDescriptorProto.enumType: array expected");
                    message.enumType = [];
                    for (var i = 0; i < object.enumType.length; ++i) {
                        if (typeof object.enumType[i] !== "object")
                            throw TypeError(".google.protobuf.FileDescriptorProto.enumType: object expected");
                        message.enumType[i] = $root.google.protobuf.EnumDescriptorProto.fromObject(object.enumType[i]);
                    }
                }
                if (object.service) {
                    if (!Array.isArray(object.service))
                        throw TypeError(".google.protobuf.FileDescriptorProto.service: array expected");
                    message.service = [];
                    for (var i = 0; i < object.service.length; ++i) {
                        if (typeof object.service[i] !== "object")
                            throw TypeError(".google.protobuf.FileDescriptorProto.service: object expected");
                        message.service[i] = $root.google.protobuf.ServiceDescriptorProto.fromObject(object.service[i]);
                    }
                }
                if (object.extension) {
                    if (!Array.isArray(object.extension))
                        throw TypeError(".google.protobuf.FileDescriptorProto.extension: array expected");
                    message.extension = [];
                    for (var i = 0; i < object.extension.length; ++i) {
                        if (typeof object.extension[i] !== "object")
                            throw TypeError(".google.protobuf.FileDescriptorProto.extension: object expected");
                        message.extension[i] = $root.google.protobuf.FieldDescriptorProto.fromObject(object.extension[i]);
                    }
                }
                if (object.options != null) {
                    if (typeof object.options !== "object")
                        throw TypeError(".google.protobuf.FileDescriptorProto.options: object expected");
                    message.options = $root.google.protobuf.FileOptions.fromObject(object.options);
                }
                if (object.sourceCodeInfo != null) {
                    if (typeof object.sourceCodeInfo !== "object")
                        throw TypeError(".google.protobuf.FileDescriptorProto.sourceCodeInfo: object expected");
                    message.sourceCodeInfo = $root.google.protobuf.SourceCodeInfo.fromObject(object.sourceCodeInfo);
                }
                if (object.syntax != null)
                    message.syntax = String(object.syntax);
                return message;
            };

            /**
             * Creates a plain object from a FileDescriptorProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.FileDescriptorProto
             * @static
             * @param {google.protobuf.FileDescriptorProto} message FileDescriptorProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FileDescriptorProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults) {
                    object.dependency = [];
                    object.messageType = [];
                    object.enumType = [];
                    object.service = [];
                    object.extension = [];
                    object.publicDependency = [];
                    object.weakDependency = [];
                }
                if (options.defaults) {
                    object.name = "";
                    object["package"] = "";
                    object.options = null;
                    object.sourceCodeInfo = null;
                    object.syntax = "";
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message["package"] != null && message.hasOwnProperty("package"))
                    object["package"] = message["package"];
                if (message.dependency && message.dependency.length) {
                    object.dependency = [];
                    for (var j = 0; j < message.dependency.length; ++j)
                        object.dependency[j] = message.dependency[j];
                }
                if (message.messageType && message.messageType.length) {
                    object.messageType = [];
                    for (var j = 0; j < message.messageType.length; ++j)
                        object.messageType[j] = $root.google.protobuf.DescriptorProto.toObject(message.messageType[j], options);
                }
                if (message.enumType && message.enumType.length) {
                    object.enumType = [];
                    for (var j = 0; j < message.enumType.length; ++j)
                        object.enumType[j] = $root.google.protobuf.EnumDescriptorProto.toObject(message.enumType[j], options);
                }
                if (message.service && message.service.length) {
                    object.service = [];
                    for (var j = 0; j < message.service.length; ++j)
                        object.service[j] = $root.google.protobuf.ServiceDescriptorProto.toObject(message.service[j], options);
                }
                if (message.extension && message.extension.length) {
                    object.extension = [];
                    for (var j = 0; j < message.extension.length; ++j)
                        object.extension[j] = $root.google.protobuf.FieldDescriptorProto.toObject(message.extension[j], options);
                }
                if (message.options != null && message.hasOwnProperty("options"))
                    object.options = $root.google.protobuf.FileOptions.toObject(message.options, options);
                if (message.sourceCodeInfo != null && message.hasOwnProperty("sourceCodeInfo"))
                    object.sourceCodeInfo = $root.google.protobuf.SourceCodeInfo.toObject(message.sourceCodeInfo, options);
                if (message.publicDependency && message.publicDependency.length) {
                    object.publicDependency = [];
                    for (var j = 0; j < message.publicDependency.length; ++j)
                        object.publicDependency[j] = message.publicDependency[j];
                }
                if (message.weakDependency && message.weakDependency.length) {
                    object.weakDependency = [];
                    for (var j = 0; j < message.weakDependency.length; ++j)
                        object.weakDependency[j] = message.weakDependency[j];
                }
                if (message.syntax != null && message.hasOwnProperty("syntax"))
                    object.syntax = message.syntax;
                return object;
            };

            /**
             * Converts this FileDescriptorProto to JSON.
             * @function toJSON
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FileDescriptorProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return FileDescriptorProto;
        })();

        protobuf.DescriptorProto = (function() {

            /**
             * Properties of a DescriptorProto.
             * @memberof google.protobuf
             * @interface IDescriptorProto
             * @property {string|null} [name] DescriptorProto name
             * @property {Array.<google.protobuf.IFieldDescriptorProto>|null} [field] DescriptorProto field
             * @property {Array.<google.protobuf.IFieldDescriptorProto>|null} [extension] DescriptorProto extension
             * @property {Array.<google.protobuf.IDescriptorProto>|null} [nestedType] DescriptorProto nestedType
             * @property {Array.<google.protobuf.IEnumDescriptorProto>|null} [enumType] DescriptorProto enumType
             * @property {Array.<google.protobuf.DescriptorProto.IExtensionRange>|null} [extensionRange] DescriptorProto extensionRange
             * @property {Array.<google.protobuf.IOneofDescriptorProto>|null} [oneofDecl] DescriptorProto oneofDecl
             * @property {google.protobuf.IMessageOptions|null} [options] DescriptorProto options
             * @property {Array.<google.protobuf.DescriptorProto.IReservedRange>|null} [reservedRange] DescriptorProto reservedRange
             * @property {Array.<string>|null} [reservedName] DescriptorProto reservedName
             */

            /**
             * Constructs a new DescriptorProto.
             * @memberof google.protobuf
             * @classdesc Represents a DescriptorProto.
             * @implements IDescriptorProto
             * @constructor
             * @param {google.protobuf.IDescriptorProto=} [properties] Properties to set
             */
            function DescriptorProto(properties) {
                this.field = [];
                this.extension = [];
                this.nestedType = [];
                this.enumType = [];
                this.extensionRange = [];
                this.oneofDecl = [];
                this.reservedRange = [];
                this.reservedName = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * DescriptorProto name.
             * @member {string} name
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.name = "";

            /**
             * DescriptorProto field.
             * @member {Array.<google.protobuf.IFieldDescriptorProto>} field
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.field = $util.emptyArray;

            /**
             * DescriptorProto extension.
             * @member {Array.<google.protobuf.IFieldDescriptorProto>} extension
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.extension = $util.emptyArray;

            /**
             * DescriptorProto nestedType.
             * @member {Array.<google.protobuf.IDescriptorProto>} nestedType
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.nestedType = $util.emptyArray;

            /**
             * DescriptorProto enumType.
             * @member {Array.<google.protobuf.IEnumDescriptorProto>} enumType
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.enumType = $util.emptyArray;

            /**
             * DescriptorProto extensionRange.
             * @member {Array.<google.protobuf.DescriptorProto.IExtensionRange>} extensionRange
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.extensionRange = $util.emptyArray;

            /**
             * DescriptorProto oneofDecl.
             * @member {Array.<google.protobuf.IOneofDescriptorProto>} oneofDecl
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.oneofDecl = $util.emptyArray;

            /**
             * DescriptorProto options.
             * @member {google.protobuf.IMessageOptions|null|undefined} options
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.options = null;

            /**
             * DescriptorProto reservedRange.
             * @member {Array.<google.protobuf.DescriptorProto.IReservedRange>} reservedRange
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.reservedRange = $util.emptyArray;

            /**
             * DescriptorProto reservedName.
             * @member {Array.<string>} reservedName
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.reservedName = $util.emptyArray;

            /**
             * Creates a new DescriptorProto instance using the specified properties.
             * @function create
             * @memberof google.protobuf.DescriptorProto
             * @static
             * @param {google.protobuf.IDescriptorProto=} [properties] Properties to set
             * @returns {google.protobuf.DescriptorProto} DescriptorProto instance
             */
            DescriptorProto.create = function create(properties) {
                return new DescriptorProto(properties);
            };

            /**
             * Encodes the specified DescriptorProto message. Does not implicitly {@link google.protobuf.DescriptorProto.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.DescriptorProto
             * @static
             * @param {google.protobuf.IDescriptorProto} message DescriptorProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DescriptorProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                if (message.field != null && message.field.length)
                    for (var i = 0; i < message.field.length; ++i)
                        $root.google.protobuf.FieldDescriptorProto.encode(message.field[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.nestedType != null && message.nestedType.length)
                    for (var i = 0; i < message.nestedType.length; ++i)
                        $root.google.protobuf.DescriptorProto.encode(message.nestedType[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.enumType != null && message.enumType.length)
                    for (var i = 0; i < message.enumType.length; ++i)
                        $root.google.protobuf.EnumDescriptorProto.encode(message.enumType[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.extensionRange != null && message.extensionRange.length)
                    for (var i = 0; i < message.extensionRange.length; ++i)
                        $root.google.protobuf.DescriptorProto.ExtensionRange.encode(message.extensionRange[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.extension != null && message.extension.length)
                    for (var i = 0; i < message.extension.length; ++i)
                        $root.google.protobuf.FieldDescriptorProto.encode(message.extension[i], writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.options != null && Object.hasOwnProperty.call(message, "options"))
                    $root.google.protobuf.MessageOptions.encode(message.options, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                if (message.oneofDecl != null && message.oneofDecl.length)
                    for (var i = 0; i < message.oneofDecl.length; ++i)
                        $root.google.protobuf.OneofDescriptorProto.encode(message.oneofDecl[i], writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                if (message.reservedRange != null && message.reservedRange.length)
                    for (var i = 0; i < message.reservedRange.length; ++i)
                        $root.google.protobuf.DescriptorProto.ReservedRange.encode(message.reservedRange[i], writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                if (message.reservedName != null && message.reservedName.length)
                    for (var i = 0; i < message.reservedName.length; ++i)
                        writer.uint32(/* id 10, wireType 2 =*/82).string(message.reservedName[i]);
                return writer;
            };

            /**
             * Decodes a DescriptorProto message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.DescriptorProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.DescriptorProto} DescriptorProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DescriptorProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.DescriptorProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.name = reader.string();
                        break;
                    case 2:
                        if (!(message.field && message.field.length))
                            message.field = [];
                        message.field.push($root.google.protobuf.FieldDescriptorProto.decode(reader, reader.uint32()));
                        break;
                    case 6:
                        if (!(message.extension && message.extension.length))
                            message.extension = [];
                        message.extension.push($root.google.protobuf.FieldDescriptorProto.decode(reader, reader.uint32()));
                        break;
                    case 3:
                        if (!(message.nestedType && message.nestedType.length))
                            message.nestedType = [];
                        message.nestedType.push($root.google.protobuf.DescriptorProto.decode(reader, reader.uint32()));
                        break;
                    case 4:
                        if (!(message.enumType && message.enumType.length))
                            message.enumType = [];
                        message.enumType.push($root.google.protobuf.EnumDescriptorProto.decode(reader, reader.uint32()));
                        break;
                    case 5:
                        if (!(message.extensionRange && message.extensionRange.length))
                            message.extensionRange = [];
                        message.extensionRange.push($root.google.protobuf.DescriptorProto.ExtensionRange.decode(reader, reader.uint32()));
                        break;
                    case 8:
                        if (!(message.oneofDecl && message.oneofDecl.length))
                            message.oneofDecl = [];
                        message.oneofDecl.push($root.google.protobuf.OneofDescriptorProto.decode(reader, reader.uint32()));
                        break;
                    case 7:
                        message.options = $root.google.protobuf.MessageOptions.decode(reader, reader.uint32());
                        break;
                    case 9:
                        if (!(message.reservedRange && message.reservedRange.length))
                            message.reservedRange = [];
                        message.reservedRange.push($root.google.protobuf.DescriptorProto.ReservedRange.decode(reader, reader.uint32()));
                        break;
                    case 10:
                        if (!(message.reservedName && message.reservedName.length))
                            message.reservedName = [];
                        message.reservedName.push(reader.string());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a DescriptorProto message.
             * @function verify
             * @memberof google.protobuf.DescriptorProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DescriptorProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.field != null && message.hasOwnProperty("field")) {
                    if (!Array.isArray(message.field))
                        return "field: array expected";
                    for (var i = 0; i < message.field.length; ++i) {
                        var error = $root.google.protobuf.FieldDescriptorProto.verify(message.field[i]);
                        if (error)
                            return "field." + error;
                    }
                }
                if (message.extension != null && message.hasOwnProperty("extension")) {
                    if (!Array.isArray(message.extension))
                        return "extension: array expected";
                    for (var i = 0; i < message.extension.length; ++i) {
                        var error = $root.google.protobuf.FieldDescriptorProto.verify(message.extension[i]);
                        if (error)
                            return "extension." + error;
                    }
                }
                if (message.nestedType != null && message.hasOwnProperty("nestedType")) {
                    if (!Array.isArray(message.nestedType))
                        return "nestedType: array expected";
                    for (var i = 0; i < message.nestedType.length; ++i) {
                        var error = $root.google.protobuf.DescriptorProto.verify(message.nestedType[i]);
                        if (error)
                            return "nestedType." + error;
                    }
                }
                if (message.enumType != null && message.hasOwnProperty("enumType")) {
                    if (!Array.isArray(message.enumType))
                        return "enumType: array expected";
                    for (var i = 0; i < message.enumType.length; ++i) {
                        var error = $root.google.protobuf.EnumDescriptorProto.verify(message.enumType[i]);
                        if (error)
                            return "enumType." + error;
                    }
                }
                if (message.extensionRange != null && message.hasOwnProperty("extensionRange")) {
                    if (!Array.isArray(message.extensionRange))
                        return "extensionRange: array expected";
                    for (var i = 0; i < message.extensionRange.length; ++i) {
                        var error = $root.google.protobuf.DescriptorProto.ExtensionRange.verify(message.extensionRange[i]);
                        if (error)
                            return "extensionRange." + error;
                    }
                }
                if (message.oneofDecl != null && message.hasOwnProperty("oneofDecl")) {
                    if (!Array.isArray(message.oneofDecl))
                        return "oneofDecl: array expected";
                    for (var i = 0; i < message.oneofDecl.length; ++i) {
                        var error = $root.google.protobuf.OneofDescriptorProto.verify(message.oneofDecl[i]);
                        if (error)
                            return "oneofDecl." + error;
                    }
                }
                if (message.options != null && message.hasOwnProperty("options")) {
                    var error = $root.google.protobuf.MessageOptions.verify(message.options);
                    if (error)
                        return "options." + error;
                }
                if (message.reservedRange != null && message.hasOwnProperty("reservedRange")) {
                    if (!Array.isArray(message.reservedRange))
                        return "reservedRange: array expected";
                    for (var i = 0; i < message.reservedRange.length; ++i) {
                        var error = $root.google.protobuf.DescriptorProto.ReservedRange.verify(message.reservedRange[i]);
                        if (error)
                            return "reservedRange." + error;
                    }
                }
                if (message.reservedName != null && message.hasOwnProperty("reservedName")) {
                    if (!Array.isArray(message.reservedName))
                        return "reservedName: array expected";
                    for (var i = 0; i < message.reservedName.length; ++i)
                        if (!$util.isString(message.reservedName[i]))
                            return "reservedName: string[] expected";
                }
                return null;
            };

            /**
             * Creates a DescriptorProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.DescriptorProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.DescriptorProto} DescriptorProto
             */
            DescriptorProto.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.DescriptorProto)
                    return object;
                var message = new $root.google.protobuf.DescriptorProto();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.field) {
                    if (!Array.isArray(object.field))
                        throw TypeError(".google.protobuf.DescriptorProto.field: array expected");
                    message.field = [];
                    for (var i = 0; i < object.field.length; ++i) {
                        if (typeof object.field[i] !== "object")
                            throw TypeError(".google.protobuf.DescriptorProto.field: object expected");
                        message.field[i] = $root.google.protobuf.FieldDescriptorProto.fromObject(object.field[i]);
                    }
                }
                if (object.extension) {
                    if (!Array.isArray(object.extension))
                        throw TypeError(".google.protobuf.DescriptorProto.extension: array expected");
                    message.extension = [];
                    for (var i = 0; i < object.extension.length; ++i) {
                        if (typeof object.extension[i] !== "object")
                            throw TypeError(".google.protobuf.DescriptorProto.extension: object expected");
                        message.extension[i] = $root.google.protobuf.FieldDescriptorProto.fromObject(object.extension[i]);
                    }
                }
                if (object.nestedType) {
                    if (!Array.isArray(object.nestedType))
                        throw TypeError(".google.protobuf.DescriptorProto.nestedType: array expected");
                    message.nestedType = [];
                    for (var i = 0; i < object.nestedType.length; ++i) {
                        if (typeof object.nestedType[i] !== "object")
                            throw TypeError(".google.protobuf.DescriptorProto.nestedType: object expected");
                        message.nestedType[i] = $root.google.protobuf.DescriptorProto.fromObject(object.nestedType[i]);
                    }
                }
                if (object.enumType) {
                    if (!Array.isArray(object.enumType))
                        throw TypeError(".google.protobuf.DescriptorProto.enumType: array expected");
                    message.enumType = [];
                    for (var i = 0; i < object.enumType.length; ++i) {
                        if (typeof object.enumType[i] !== "object")
                            throw TypeError(".google.protobuf.DescriptorProto.enumType: object expected");
                        message.enumType[i] = $root.google.protobuf.EnumDescriptorProto.fromObject(object.enumType[i]);
                    }
                }
                if (object.extensionRange) {
                    if (!Array.isArray(object.extensionRange))
                        throw TypeError(".google.protobuf.DescriptorProto.extensionRange: array expected");
                    message.extensionRange = [];
                    for (var i = 0; i < object.extensionRange.length; ++i) {
                        if (typeof object.extensionRange[i] !== "object")
                            throw TypeError(".google.protobuf.DescriptorProto.extensionRange: object expected");
                        message.extensionRange[i] = $root.google.protobuf.DescriptorProto.ExtensionRange.fromObject(object.extensionRange[i]);
                    }
                }
                if (object.oneofDecl) {
                    if (!Array.isArray(object.oneofDecl))
                        throw TypeError(".google.protobuf.DescriptorProto.oneofDecl: array expected");
                    message.oneofDecl = [];
                    for (var i = 0; i < object.oneofDecl.length; ++i) {
                        if (typeof object.oneofDecl[i] !== "object")
                            throw TypeError(".google.protobuf.DescriptorProto.oneofDecl: object expected");
                        message.oneofDecl[i] = $root.google.protobuf.OneofDescriptorProto.fromObject(object.oneofDecl[i]);
                    }
                }
                if (object.options != null) {
                    if (typeof object.options !== "object")
                        throw TypeError(".google.protobuf.DescriptorProto.options: object expected");
                    message.options = $root.google.protobuf.MessageOptions.fromObject(object.options);
                }
                if (object.reservedRange) {
                    if (!Array.isArray(object.reservedRange))
                        throw TypeError(".google.protobuf.DescriptorProto.reservedRange: array expected");
                    message.reservedRange = [];
                    for (var i = 0; i < object.reservedRange.length; ++i) {
                        if (typeof object.reservedRange[i] !== "object")
                            throw TypeError(".google.protobuf.DescriptorProto.reservedRange: object expected");
                        message.reservedRange[i] = $root.google.protobuf.DescriptorProto.ReservedRange.fromObject(object.reservedRange[i]);
                    }
                }
                if (object.reservedName) {
                    if (!Array.isArray(object.reservedName))
                        throw TypeError(".google.protobuf.DescriptorProto.reservedName: array expected");
                    message.reservedName = [];
                    for (var i = 0; i < object.reservedName.length; ++i)
                        message.reservedName[i] = String(object.reservedName[i]);
                }
                return message;
            };

            /**
             * Creates a plain object from a DescriptorProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.DescriptorProto
             * @static
             * @param {google.protobuf.DescriptorProto} message DescriptorProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DescriptorProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults) {
                    object.field = [];
                    object.nestedType = [];
                    object.enumType = [];
                    object.extensionRange = [];
                    object.extension = [];
                    object.oneofDecl = [];
                    object.reservedRange = [];
                    object.reservedName = [];
                }
                if (options.defaults) {
                    object.name = "";
                    object.options = null;
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.field && message.field.length) {
                    object.field = [];
                    for (var j = 0; j < message.field.length; ++j)
                        object.field[j] = $root.google.protobuf.FieldDescriptorProto.toObject(message.field[j], options);
                }
                if (message.nestedType && message.nestedType.length) {
                    object.nestedType = [];
                    for (var j = 0; j < message.nestedType.length; ++j)
                        object.nestedType[j] = $root.google.protobuf.DescriptorProto.toObject(message.nestedType[j], options);
                }
                if (message.enumType && message.enumType.length) {
                    object.enumType = [];
                    for (var j = 0; j < message.enumType.length; ++j)
                        object.enumType[j] = $root.google.protobuf.EnumDescriptorProto.toObject(message.enumType[j], options);
                }
                if (message.extensionRange && message.extensionRange.length) {
                    object.extensionRange = [];
                    for (var j = 0; j < message.extensionRange.length; ++j)
                        object.extensionRange[j] = $root.google.protobuf.DescriptorProto.ExtensionRange.toObject(message.extensionRange[j], options);
                }
                if (message.extension && message.extension.length) {
                    object.extension = [];
                    for (var j = 0; j < message.extension.length; ++j)
                        object.extension[j] = $root.google.protobuf.FieldDescriptorProto.toObject(message.extension[j], options);
                }
                if (message.options != null && message.hasOwnProperty("options"))
                    object.options = $root.google.protobuf.MessageOptions.toObject(message.options, options);
                if (message.oneofDecl && message.oneofDecl.length) {
                    object.oneofDecl = [];
                    for (var j = 0; j < message.oneofDecl.length; ++j)
                        object.oneofDecl[j] = $root.google.protobuf.OneofDescriptorProto.toObject(message.oneofDecl[j], options);
                }
                if (message.reservedRange && message.reservedRange.length) {
                    object.reservedRange = [];
                    for (var j = 0; j < message.reservedRange.length; ++j)
                        object.reservedRange[j] = $root.google.protobuf.DescriptorProto.ReservedRange.toObject(message.reservedRange[j], options);
                }
                if (message.reservedName && message.reservedName.length) {
                    object.reservedName = [];
                    for (var j = 0; j < message.reservedName.length; ++j)
                        object.reservedName[j] = message.reservedName[j];
                }
                return object;
            };

            /**
             * Converts this DescriptorProto to JSON.
             * @function toJSON
             * @memberof google.protobuf.DescriptorProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DescriptorProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            DescriptorProto.ExtensionRange = (function() {

                /**
                 * Properties of an ExtensionRange.
                 * @memberof google.protobuf.DescriptorProto
                 * @interface IExtensionRange
                 * @property {number|null} [start] ExtensionRange start
                 * @property {number|null} [end] ExtensionRange end
                 */

                /**
                 * Constructs a new ExtensionRange.
                 * @memberof google.protobuf.DescriptorProto
                 * @classdesc Represents an ExtensionRange.
                 * @implements IExtensionRange
                 * @constructor
                 * @param {google.protobuf.DescriptorProto.IExtensionRange=} [properties] Properties to set
                 */
                function ExtensionRange(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ExtensionRange start.
                 * @member {number} start
                 * @memberof google.protobuf.DescriptorProto.ExtensionRange
                 * @instance
                 */
                ExtensionRange.prototype.start = 0;

                /**
                 * ExtensionRange end.
                 * @member {number} end
                 * @memberof google.protobuf.DescriptorProto.ExtensionRange
                 * @instance
                 */
                ExtensionRange.prototype.end = 0;

                /**
                 * Creates a new ExtensionRange instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.DescriptorProto.ExtensionRange
                 * @static
                 * @param {google.protobuf.DescriptorProto.IExtensionRange=} [properties] Properties to set
                 * @returns {google.protobuf.DescriptorProto.ExtensionRange} ExtensionRange instance
                 */
                ExtensionRange.create = function create(properties) {
                    return new ExtensionRange(properties);
                };

                /**
                 * Encodes the specified ExtensionRange message. Does not implicitly {@link google.protobuf.DescriptorProto.ExtensionRange.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.DescriptorProto.ExtensionRange
                 * @static
                 * @param {google.protobuf.DescriptorProto.IExtensionRange} message ExtensionRange message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ExtensionRange.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.start != null && Object.hasOwnProperty.call(message, "start"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.start);
                    if (message.end != null && Object.hasOwnProperty.call(message, "end"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.end);
                    return writer;
                };

                /**
                 * Decodes an ExtensionRange message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.DescriptorProto.ExtensionRange
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.DescriptorProto.ExtensionRange} ExtensionRange
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ExtensionRange.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.DescriptorProto.ExtensionRange();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.start = reader.int32();
                            break;
                        case 2:
                            message.end = reader.int32();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Verifies an ExtensionRange message.
                 * @function verify
                 * @memberof google.protobuf.DescriptorProto.ExtensionRange
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ExtensionRange.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.start != null && message.hasOwnProperty("start"))
                        if (!$util.isInteger(message.start))
                            return "start: integer expected";
                    if (message.end != null && message.hasOwnProperty("end"))
                        if (!$util.isInteger(message.end))
                            return "end: integer expected";
                    return null;
                };

                /**
                 * Creates an ExtensionRange message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.DescriptorProto.ExtensionRange
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.DescriptorProto.ExtensionRange} ExtensionRange
                 */
                ExtensionRange.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.DescriptorProto.ExtensionRange)
                        return object;
                    var message = new $root.google.protobuf.DescriptorProto.ExtensionRange();
                    if (object.start != null)
                        message.start = object.start | 0;
                    if (object.end != null)
                        message.end = object.end | 0;
                    return message;
                };

                /**
                 * Creates a plain object from an ExtensionRange message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.DescriptorProto.ExtensionRange
                 * @static
                 * @param {google.protobuf.DescriptorProto.ExtensionRange} message ExtensionRange
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ExtensionRange.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.start = 0;
                        object.end = 0;
                    }
                    if (message.start != null && message.hasOwnProperty("start"))
                        object.start = message.start;
                    if (message.end != null && message.hasOwnProperty("end"))
                        object.end = message.end;
                    return object;
                };

                /**
                 * Converts this ExtensionRange to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.DescriptorProto.ExtensionRange
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ExtensionRange.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ExtensionRange;
            })();

            DescriptorProto.ReservedRange = (function() {

                /**
                 * Properties of a ReservedRange.
                 * @memberof google.protobuf.DescriptorProto
                 * @interface IReservedRange
                 * @property {number|null} [start] ReservedRange start
                 * @property {number|null} [end] ReservedRange end
                 */

                /**
                 * Constructs a new ReservedRange.
                 * @memberof google.protobuf.DescriptorProto
                 * @classdesc Represents a ReservedRange.
                 * @implements IReservedRange
                 * @constructor
                 * @param {google.protobuf.DescriptorProto.IReservedRange=} [properties] Properties to set
                 */
                function ReservedRange(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ReservedRange start.
                 * @member {number} start
                 * @memberof google.protobuf.DescriptorProto.ReservedRange
                 * @instance
                 */
                ReservedRange.prototype.start = 0;

                /**
                 * ReservedRange end.
                 * @member {number} end
                 * @memberof google.protobuf.DescriptorProto.ReservedRange
                 * @instance
                 */
                ReservedRange.prototype.end = 0;

                /**
                 * Creates a new ReservedRange instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.DescriptorProto.ReservedRange
                 * @static
                 * @param {google.protobuf.DescriptorProto.IReservedRange=} [properties] Properties to set
                 * @returns {google.protobuf.DescriptorProto.ReservedRange} ReservedRange instance
                 */
                ReservedRange.create = function create(properties) {
                    return new ReservedRange(properties);
                };

                /**
                 * Encodes the specified ReservedRange message. Does not implicitly {@link google.protobuf.DescriptorProto.ReservedRange.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.DescriptorProto.ReservedRange
                 * @static
                 * @param {google.protobuf.DescriptorProto.IReservedRange} message ReservedRange message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ReservedRange.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.start != null && Object.hasOwnProperty.call(message, "start"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.start);
                    if (message.end != null && Object.hasOwnProperty.call(message, "end"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.end);
                    return writer;
                };

                /**
                 * Decodes a ReservedRange message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.DescriptorProto.ReservedRange
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.DescriptorProto.ReservedRange} ReservedRange
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ReservedRange.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.DescriptorProto.ReservedRange();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.start = reader.int32();
                            break;
                        case 2:
                            message.end = reader.int32();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Verifies a ReservedRange message.
                 * @function verify
                 * @memberof google.protobuf.DescriptorProto.ReservedRange
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ReservedRange.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.start != null && message.hasOwnProperty("start"))
                        if (!$util.isInteger(message.start))
                            return "start: integer expected";
                    if (message.end != null && message.hasOwnProperty("end"))
                        if (!$util.isInteger(message.end))
                            return "end: integer expected";
                    return null;
                };

                /**
                 * Creates a ReservedRange message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.DescriptorProto.ReservedRange
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.DescriptorProto.ReservedRange} ReservedRange
                 */
                ReservedRange.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.DescriptorProto.ReservedRange)
                        return object;
                    var message = new $root.google.protobuf.DescriptorProto.ReservedRange();
                    if (object.start != null)
                        message.start = object.start | 0;
                    if (object.end != null)
                        message.end = object.end | 0;
                    return message;
                };

                /**
                 * Creates a plain object from a ReservedRange message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.DescriptorProto.ReservedRange
                 * @static
                 * @param {google.protobuf.DescriptorProto.ReservedRange} message ReservedRange
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ReservedRange.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.start = 0;
                        object.end = 0;
                    }
                    if (message.start != null && message.hasOwnProperty("start"))
                        object.start = message.start;
                    if (message.end != null && message.hasOwnProperty("end"))
                        object.end = message.end;
                    return object;
                };

                /**
                 * Converts this ReservedRange to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.DescriptorProto.ReservedRange
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ReservedRange.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ReservedRange;
            })();

            return DescriptorProto;
        })();

        protobuf.FieldDescriptorProto = (function() {

            /**
             * Properties of a FieldDescriptorProto.
             * @memberof google.protobuf
             * @interface IFieldDescriptorProto
             * @property {string|null} [name] FieldDescriptorProto name
             * @property {number|null} [number] FieldDescriptorProto number
             * @property {google.protobuf.FieldDescriptorProto.Label|null} [label] FieldDescriptorProto label
             * @property {google.protobuf.FieldDescriptorProto.Type|null} [type] FieldDescriptorProto type
             * @property {string|null} [typeName] FieldDescriptorProto typeName
             * @property {string|null} [extendee] FieldDescriptorProto extendee
             * @property {string|null} [defaultValue] FieldDescriptorProto defaultValue
             * @property {number|null} [oneofIndex] FieldDescriptorProto oneofIndex
             * @property {string|null} [jsonName] FieldDescriptorProto jsonName
             * @property {google.protobuf.IFieldOptions|null} [options] FieldDescriptorProto options
             */

            /**
             * Constructs a new FieldDescriptorProto.
             * @memberof google.protobuf
             * @classdesc Represents a FieldDescriptorProto.
             * @implements IFieldDescriptorProto
             * @constructor
             * @param {google.protobuf.IFieldDescriptorProto=} [properties] Properties to set
             */
            function FieldDescriptorProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * FieldDescriptorProto name.
             * @member {string} name
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.name = "";

            /**
             * FieldDescriptorProto number.
             * @member {number} number
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.number = 0;

            /**
             * FieldDescriptorProto label.
             * @member {google.protobuf.FieldDescriptorProto.Label} label
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.label = 1;

            /**
             * FieldDescriptorProto type.
             * @member {google.protobuf.FieldDescriptorProto.Type} type
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.type = 1;

            /**
             * FieldDescriptorProto typeName.
             * @member {string} typeName
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.typeName = "";

            /**
             * FieldDescriptorProto extendee.
             * @member {string} extendee
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.extendee = "";

            /**
             * FieldDescriptorProto defaultValue.
             * @member {string} defaultValue
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.defaultValue = "";

            /**
             * FieldDescriptorProto oneofIndex.
             * @member {number} oneofIndex
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.oneofIndex = 0;

            /**
             * FieldDescriptorProto jsonName.
             * @member {string} jsonName
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.jsonName = "";

            /**
             * FieldDescriptorProto options.
             * @member {google.protobuf.IFieldOptions|null|undefined} options
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.options = null;

            /**
             * Creates a new FieldDescriptorProto instance using the specified properties.
             * @function create
             * @memberof google.protobuf.FieldDescriptorProto
             * @static
             * @param {google.protobuf.IFieldDescriptorProto=} [properties] Properties to set
             * @returns {google.protobuf.FieldDescriptorProto} FieldDescriptorProto instance
             */
            FieldDescriptorProto.create = function create(properties) {
                return new FieldDescriptorProto(properties);
            };

            /**
             * Encodes the specified FieldDescriptorProto message. Does not implicitly {@link google.protobuf.FieldDescriptorProto.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.FieldDescriptorProto
             * @static
             * @param {google.protobuf.IFieldDescriptorProto} message FieldDescriptorProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FieldDescriptorProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                if (message.extendee != null && Object.hasOwnProperty.call(message, "extendee"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.extendee);
                if (message.number != null && Object.hasOwnProperty.call(message, "number"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.number);
                if (message.label != null && Object.hasOwnProperty.call(message, "label"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int32(message.label);
                if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                    writer.uint32(/* id 5, wireType 0 =*/40).int32(message.type);
                if (message.typeName != null && Object.hasOwnProperty.call(message, "typeName"))
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.typeName);
                if (message.defaultValue != null && Object.hasOwnProperty.call(message, "defaultValue"))
                    writer.uint32(/* id 7, wireType 2 =*/58).string(message.defaultValue);
                if (message.options != null && Object.hasOwnProperty.call(message, "options"))
                    $root.google.protobuf.FieldOptions.encode(message.options, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                if (message.oneofIndex != null && Object.hasOwnProperty.call(message, "oneofIndex"))
                    writer.uint32(/* id 9, wireType 0 =*/72).int32(message.oneofIndex);
                if (message.jsonName != null && Object.hasOwnProperty.call(message, "jsonName"))
                    writer.uint32(/* id 10, wireType 2 =*/82).string(message.jsonName);
                return writer;
            };

            /**
             * Decodes a FieldDescriptorProto message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.FieldDescriptorProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.FieldDescriptorProto} FieldDescriptorProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FieldDescriptorProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.FieldDescriptorProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.name = reader.string();
                        break;
                    case 3:
                        message.number = reader.int32();
                        break;
                    case 4:
                        message.label = reader.int32();
                        break;
                    case 5:
                        message.type = reader.int32();
                        break;
                    case 6:
                        message.typeName = reader.string();
                        break;
                    case 2:
                        message.extendee = reader.string();
                        break;
                    case 7:
                        message.defaultValue = reader.string();
                        break;
                    case 9:
                        message.oneofIndex = reader.int32();
                        break;
                    case 10:
                        message.jsonName = reader.string();
                        break;
                    case 8:
                        message.options = $root.google.protobuf.FieldOptions.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a FieldDescriptorProto message.
             * @function verify
             * @memberof google.protobuf.FieldDescriptorProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            FieldDescriptorProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.number != null && message.hasOwnProperty("number"))
                    if (!$util.isInteger(message.number))
                        return "number: integer expected";
                if (message.label != null && message.hasOwnProperty("label"))
                    switch (message.label) {
                    default:
                        return "label: enum value expected";
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
                if (message.type != null && message.hasOwnProperty("type"))
                    switch (message.type) {
                    default:
                        return "type: enum value expected";
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                    case 9:
                    case 10:
                    case 11:
                    case 12:
                    case 13:
                    case 14:
                    case 15:
                    case 16:
                    case 17:
                    case 18:
                        break;
                    }
                if (message.typeName != null && message.hasOwnProperty("typeName"))
                    if (!$util.isString(message.typeName))
                        return "typeName: string expected";
                if (message.extendee != null && message.hasOwnProperty("extendee"))
                    if (!$util.isString(message.extendee))
                        return "extendee: string expected";
                if (message.defaultValue != null && message.hasOwnProperty("defaultValue"))
                    if (!$util.isString(message.defaultValue))
                        return "defaultValue: string expected";
                if (message.oneofIndex != null && message.hasOwnProperty("oneofIndex"))
                    if (!$util.isInteger(message.oneofIndex))
                        return "oneofIndex: integer expected";
                if (message.jsonName != null && message.hasOwnProperty("jsonName"))
                    if (!$util.isString(message.jsonName))
                        return "jsonName: string expected";
                if (message.options != null && message.hasOwnProperty("options")) {
                    var error = $root.google.protobuf.FieldOptions.verify(message.options);
                    if (error)
                        return "options." + error;
                }
                return null;
            };

            /**
             * Creates a FieldDescriptorProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.FieldDescriptorProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.FieldDescriptorProto} FieldDescriptorProto
             */
            FieldDescriptorProto.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.FieldDescriptorProto)
                    return object;
                var message = new $root.google.protobuf.FieldDescriptorProto();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.number != null)
                    message.number = object.number | 0;
                switch (object.label) {
                case "LABEL_OPTIONAL":
                case 1:
                    message.label = 1;
                    break;
                case "LABEL_REQUIRED":
                case 2:
                    message.label = 2;
                    break;
                case "LABEL_REPEATED":
                case 3:
                    message.label = 3;
                    break;
                }
                switch (object.type) {
                case "TYPE_DOUBLE":
                case 1:
                    message.type = 1;
                    break;
                case "TYPE_FLOAT":
                case 2:
                    message.type = 2;
                    break;
                case "TYPE_INT64":
                case 3:
                    message.type = 3;
                    break;
                case "TYPE_UINT64":
                case 4:
                    message.type = 4;
                    break;
                case "TYPE_INT32":
                case 5:
                    message.type = 5;
                    break;
                case "TYPE_FIXED64":
                case 6:
                    message.type = 6;
                    break;
                case "TYPE_FIXED32":
                case 7:
                    message.type = 7;
                    break;
                case "TYPE_BOOL":
                case 8:
                    message.type = 8;
                    break;
                case "TYPE_STRING":
                case 9:
                    message.type = 9;
                    break;
                case "TYPE_GROUP":
                case 10:
                    message.type = 10;
                    break;
                case "TYPE_MESSAGE":
                case 11:
                    message.type = 11;
                    break;
                case "TYPE_BYTES":
                case 12:
                    message.type = 12;
                    break;
                case "TYPE_UINT32":
                case 13:
                    message.type = 13;
                    break;
                case "TYPE_ENUM":
                case 14:
                    message.type = 14;
                    break;
                case "TYPE_SFIXED32":
                case 15:
                    message.type = 15;
                    break;
                case "TYPE_SFIXED64":
                case 16:
                    message.type = 16;
                    break;
                case "TYPE_SINT32":
                case 17:
                    message.type = 17;
                    break;
                case "TYPE_SINT64":
                case 18:
                    message.type = 18;
                    break;
                }
                if (object.typeName != null)
                    message.typeName = String(object.typeName);
                if (object.extendee != null)
                    message.extendee = String(object.extendee);
                if (object.defaultValue != null)
                    message.defaultValue = String(object.defaultValue);
                if (object.oneofIndex != null)
                    message.oneofIndex = object.oneofIndex | 0;
                if (object.jsonName != null)
                    message.jsonName = String(object.jsonName);
                if (object.options != null) {
                    if (typeof object.options !== "object")
                        throw TypeError(".google.protobuf.FieldDescriptorProto.options: object expected");
                    message.options = $root.google.protobuf.FieldOptions.fromObject(object.options);
                }
                return message;
            };

            /**
             * Creates a plain object from a FieldDescriptorProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.FieldDescriptorProto
             * @static
             * @param {google.protobuf.FieldDescriptorProto} message FieldDescriptorProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FieldDescriptorProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.name = "";
                    object.extendee = "";
                    object.number = 0;
                    object.label = options.enums === String ? "LABEL_OPTIONAL" : 1;
                    object.type = options.enums === String ? "TYPE_DOUBLE" : 1;
                    object.typeName = "";
                    object.defaultValue = "";
                    object.options = null;
                    object.oneofIndex = 0;
                    object.jsonName = "";
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.extendee != null && message.hasOwnProperty("extendee"))
                    object.extendee = message.extendee;
                if (message.number != null && message.hasOwnProperty("number"))
                    object.number = message.number;
                if (message.label != null && message.hasOwnProperty("label"))
                    object.label = options.enums === String ? $root.google.protobuf.FieldDescriptorProto.Label[message.label] : message.label;
                if (message.type != null && message.hasOwnProperty("type"))
                    object.type = options.enums === String ? $root.google.protobuf.FieldDescriptorProto.Type[message.type] : message.type;
                if (message.typeName != null && message.hasOwnProperty("typeName"))
                    object.typeName = message.typeName;
                if (message.defaultValue != null && message.hasOwnProperty("defaultValue"))
                    object.defaultValue = message.defaultValue;
                if (message.options != null && message.hasOwnProperty("options"))
                    object.options = $root.google.protobuf.FieldOptions.toObject(message.options, options);
                if (message.oneofIndex != null && message.hasOwnProperty("oneofIndex"))
                    object.oneofIndex = message.oneofIndex;
                if (message.jsonName != null && message.hasOwnProperty("jsonName"))
                    object.jsonName = message.jsonName;
                return object;
            };

            /**
             * Converts this FieldDescriptorProto to JSON.
             * @function toJSON
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FieldDescriptorProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Type enum.
             * @name google.protobuf.FieldDescriptorProto.Type
             * @enum {number}
             * @property {number} TYPE_DOUBLE=1 TYPE_DOUBLE value
             * @property {number} TYPE_FLOAT=2 TYPE_FLOAT value
             * @property {number} TYPE_INT64=3 TYPE_INT64 value
             * @property {number} TYPE_UINT64=4 TYPE_UINT64 value
             * @property {number} TYPE_INT32=5 TYPE_INT32 value
             * @property {number} TYPE_FIXED64=6 TYPE_FIXED64 value
             * @property {number} TYPE_FIXED32=7 TYPE_FIXED32 value
             * @property {number} TYPE_BOOL=8 TYPE_BOOL value
             * @property {number} TYPE_STRING=9 TYPE_STRING value
             * @property {number} TYPE_GROUP=10 TYPE_GROUP value
             * @property {number} TYPE_MESSAGE=11 TYPE_MESSAGE value
             * @property {number} TYPE_BYTES=12 TYPE_BYTES value
             * @property {number} TYPE_UINT32=13 TYPE_UINT32 value
             * @property {number} TYPE_ENUM=14 TYPE_ENUM value
             * @property {number} TYPE_SFIXED32=15 TYPE_SFIXED32 value
             * @property {number} TYPE_SFIXED64=16 TYPE_SFIXED64 value
             * @property {number} TYPE_SINT32=17 TYPE_SINT32 value
             * @property {number} TYPE_SINT64=18 TYPE_SINT64 value
             */
            FieldDescriptorProto.Type = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[1] = "TYPE_DOUBLE"] = 1;
                values[valuesById[2] = "TYPE_FLOAT"] = 2;
                values[valuesById[3] = "TYPE_INT64"] = 3;
                values[valuesById[4] = "TYPE_UINT64"] = 4;
                values[valuesById[5] = "TYPE_INT32"] = 5;
                values[valuesById[6] = "TYPE_FIXED64"] = 6;
                values[valuesById[7] = "TYPE_FIXED32"] = 7;
                values[valuesById[8] = "TYPE_BOOL"] = 8;
                values[valuesById[9] = "TYPE_STRING"] = 9;
                values[valuesById[10] = "TYPE_GROUP"] = 10;
                values[valuesById[11] = "TYPE_MESSAGE"] = 11;
                values[valuesById[12] = "TYPE_BYTES"] = 12;
                values[valuesById[13] = "TYPE_UINT32"] = 13;
                values[valuesById[14] = "TYPE_ENUM"] = 14;
                values[valuesById[15] = "TYPE_SFIXED32"] = 15;
                values[valuesById[16] = "TYPE_SFIXED64"] = 16;
                values[valuesById[17] = "TYPE_SINT32"] = 17;
                values[valuesById[18] = "TYPE_SINT64"] = 18;
                return values;
            })();

            /**
             * Label enum.
             * @name google.protobuf.FieldDescriptorProto.Label
             * @enum {number}
             * @property {number} LABEL_OPTIONAL=1 LABEL_OPTIONAL value
             * @property {number} LABEL_REQUIRED=2 LABEL_REQUIRED value
             * @property {number} LABEL_REPEATED=3 LABEL_REPEATED value
             */
            FieldDescriptorProto.Label = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[1] = "LABEL_OPTIONAL"] = 1;
                values[valuesById[2] = "LABEL_REQUIRED"] = 2;
                values[valuesById[3] = "LABEL_REPEATED"] = 3;
                return values;
            })();

            return FieldDescriptorProto;
        })();

        protobuf.OneofDescriptorProto = (function() {

            /**
             * Properties of an OneofDescriptorProto.
             * @memberof google.protobuf
             * @interface IOneofDescriptorProto
             * @property {string|null} [name] OneofDescriptorProto name
             * @property {google.protobuf.IOneofOptions|null} [options] OneofDescriptorProto options
             */

            /**
             * Constructs a new OneofDescriptorProto.
             * @memberof google.protobuf
             * @classdesc Represents an OneofDescriptorProto.
             * @implements IOneofDescriptorProto
             * @constructor
             * @param {google.protobuf.IOneofDescriptorProto=} [properties] Properties to set
             */
            function OneofDescriptorProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * OneofDescriptorProto name.
             * @member {string} name
             * @memberof google.protobuf.OneofDescriptorProto
             * @instance
             */
            OneofDescriptorProto.prototype.name = "";

            /**
             * OneofDescriptorProto options.
             * @member {google.protobuf.IOneofOptions|null|undefined} options
             * @memberof google.protobuf.OneofDescriptorProto
             * @instance
             */
            OneofDescriptorProto.prototype.options = null;

            /**
             * Creates a new OneofDescriptorProto instance using the specified properties.
             * @function create
             * @memberof google.protobuf.OneofDescriptorProto
             * @static
             * @param {google.protobuf.IOneofDescriptorProto=} [properties] Properties to set
             * @returns {google.protobuf.OneofDescriptorProto} OneofDescriptorProto instance
             */
            OneofDescriptorProto.create = function create(properties) {
                return new OneofDescriptorProto(properties);
            };

            /**
             * Encodes the specified OneofDescriptorProto message. Does not implicitly {@link google.protobuf.OneofDescriptorProto.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.OneofDescriptorProto
             * @static
             * @param {google.protobuf.IOneofDescriptorProto} message OneofDescriptorProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            OneofDescriptorProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                if (message.options != null && Object.hasOwnProperty.call(message, "options"))
                    $root.google.protobuf.OneofOptions.encode(message.options, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes an OneofDescriptorProto message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.OneofDescriptorProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.OneofDescriptorProto} OneofDescriptorProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            OneofDescriptorProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.OneofDescriptorProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.name = reader.string();
                        break;
                    case 2:
                        message.options = $root.google.protobuf.OneofOptions.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an OneofDescriptorProto message.
             * @function verify
             * @memberof google.protobuf.OneofDescriptorProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            OneofDescriptorProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.options != null && message.hasOwnProperty("options")) {
                    var error = $root.google.protobuf.OneofOptions.verify(message.options);
                    if (error)
                        return "options." + error;
                }
                return null;
            };

            /**
             * Creates an OneofDescriptorProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.OneofDescriptorProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.OneofDescriptorProto} OneofDescriptorProto
             */
            OneofDescriptorProto.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.OneofDescriptorProto)
                    return object;
                var message = new $root.google.protobuf.OneofDescriptorProto();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.options != null) {
                    if (typeof object.options !== "object")
                        throw TypeError(".google.protobuf.OneofDescriptorProto.options: object expected");
                    message.options = $root.google.protobuf.OneofOptions.fromObject(object.options);
                }
                return message;
            };

            /**
             * Creates a plain object from an OneofDescriptorProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.OneofDescriptorProto
             * @static
             * @param {google.protobuf.OneofDescriptorProto} message OneofDescriptorProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            OneofDescriptorProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.name = "";
                    object.options = null;
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.options != null && message.hasOwnProperty("options"))
                    object.options = $root.google.protobuf.OneofOptions.toObject(message.options, options);
                return object;
            };

            /**
             * Converts this OneofDescriptorProto to JSON.
             * @function toJSON
             * @memberof google.protobuf.OneofDescriptorProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            OneofDescriptorProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return OneofDescriptorProto;
        })();

        protobuf.EnumDescriptorProto = (function() {

            /**
             * Properties of an EnumDescriptorProto.
             * @memberof google.protobuf
             * @interface IEnumDescriptorProto
             * @property {string|null} [name] EnumDescriptorProto name
             * @property {Array.<google.protobuf.IEnumValueDescriptorProto>|null} [value] EnumDescriptorProto value
             * @property {google.protobuf.IEnumOptions|null} [options] EnumDescriptorProto options
             */

            /**
             * Constructs a new EnumDescriptorProto.
             * @memberof google.protobuf
             * @classdesc Represents an EnumDescriptorProto.
             * @implements IEnumDescriptorProto
             * @constructor
             * @param {google.protobuf.IEnumDescriptorProto=} [properties] Properties to set
             */
            function EnumDescriptorProto(properties) {
                this.value = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * EnumDescriptorProto name.
             * @member {string} name
             * @memberof google.protobuf.EnumDescriptorProto
             * @instance
             */
            EnumDescriptorProto.prototype.name = "";

            /**
             * EnumDescriptorProto value.
             * @member {Array.<google.protobuf.IEnumValueDescriptorProto>} value
             * @memberof google.protobuf.EnumDescriptorProto
             * @instance
             */
            EnumDescriptorProto.prototype.value = $util.emptyArray;

            /**
             * EnumDescriptorProto options.
             * @member {google.protobuf.IEnumOptions|null|undefined} options
             * @memberof google.protobuf.EnumDescriptorProto
             * @instance
             */
            EnumDescriptorProto.prototype.options = null;

            /**
             * Creates a new EnumDescriptorProto instance using the specified properties.
             * @function create
             * @memberof google.protobuf.EnumDescriptorProto
             * @static
             * @param {google.protobuf.IEnumDescriptorProto=} [properties] Properties to set
             * @returns {google.protobuf.EnumDescriptorProto} EnumDescriptorProto instance
             */
            EnumDescriptorProto.create = function create(properties) {
                return new EnumDescriptorProto(properties);
            };

            /**
             * Encodes the specified EnumDescriptorProto message. Does not implicitly {@link google.protobuf.EnumDescriptorProto.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.EnumDescriptorProto
             * @static
             * @param {google.protobuf.IEnumDescriptorProto} message EnumDescriptorProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EnumDescriptorProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                if (message.value != null && message.value.length)
                    for (var i = 0; i < message.value.length; ++i)
                        $root.google.protobuf.EnumValueDescriptorProto.encode(message.value[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.options != null && Object.hasOwnProperty.call(message, "options"))
                    $root.google.protobuf.EnumOptions.encode(message.options, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes an EnumDescriptorProto message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.EnumDescriptorProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.EnumDescriptorProto} EnumDescriptorProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EnumDescriptorProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.EnumDescriptorProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.name = reader.string();
                        break;
                    case 2:
                        if (!(message.value && message.value.length))
                            message.value = [];
                        message.value.push($root.google.protobuf.EnumValueDescriptorProto.decode(reader, reader.uint32()));
                        break;
                    case 3:
                        message.options = $root.google.protobuf.EnumOptions.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an EnumDescriptorProto message.
             * @function verify
             * @memberof google.protobuf.EnumDescriptorProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            EnumDescriptorProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.value != null && message.hasOwnProperty("value")) {
                    if (!Array.isArray(message.value))
                        return "value: array expected";
                    for (var i = 0; i < message.value.length; ++i) {
                        var error = $root.google.protobuf.EnumValueDescriptorProto.verify(message.value[i]);
                        if (error)
                            return "value." + error;
                    }
                }
                if (message.options != null && message.hasOwnProperty("options")) {
                    var error = $root.google.protobuf.EnumOptions.verify(message.options);
                    if (error)
                        return "options." + error;
                }
                return null;
            };

            /**
             * Creates an EnumDescriptorProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.EnumDescriptorProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.EnumDescriptorProto} EnumDescriptorProto
             */
            EnumDescriptorProto.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.EnumDescriptorProto)
                    return object;
                var message = new $root.google.protobuf.EnumDescriptorProto();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.value) {
                    if (!Array.isArray(object.value))
                        throw TypeError(".google.protobuf.EnumDescriptorProto.value: array expected");
                    message.value = [];
                    for (var i = 0; i < object.value.length; ++i) {
                        if (typeof object.value[i] !== "object")
                            throw TypeError(".google.protobuf.EnumDescriptorProto.value: object expected");
                        message.value[i] = $root.google.protobuf.EnumValueDescriptorProto.fromObject(object.value[i]);
                    }
                }
                if (object.options != null) {
                    if (typeof object.options !== "object")
                        throw TypeError(".google.protobuf.EnumDescriptorProto.options: object expected");
                    message.options = $root.google.protobuf.EnumOptions.fromObject(object.options);
                }
                return message;
            };

            /**
             * Creates a plain object from an EnumDescriptorProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.EnumDescriptorProto
             * @static
             * @param {google.protobuf.EnumDescriptorProto} message EnumDescriptorProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            EnumDescriptorProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.value = [];
                if (options.defaults) {
                    object.name = "";
                    object.options = null;
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.value && message.value.length) {
                    object.value = [];
                    for (var j = 0; j < message.value.length; ++j)
                        object.value[j] = $root.google.protobuf.EnumValueDescriptorProto.toObject(message.value[j], options);
                }
                if (message.options != null && message.hasOwnProperty("options"))
                    object.options = $root.google.protobuf.EnumOptions.toObject(message.options, options);
                return object;
            };

            /**
             * Converts this EnumDescriptorProto to JSON.
             * @function toJSON
             * @memberof google.protobuf.EnumDescriptorProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            EnumDescriptorProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return EnumDescriptorProto;
        })();

        protobuf.EnumValueDescriptorProto = (function() {

            /**
             * Properties of an EnumValueDescriptorProto.
             * @memberof google.protobuf
             * @interface IEnumValueDescriptorProto
             * @property {string|null} [name] EnumValueDescriptorProto name
             * @property {number|null} [number] EnumValueDescriptorProto number
             * @property {google.protobuf.IEnumValueOptions|null} [options] EnumValueDescriptorProto options
             */

            /**
             * Constructs a new EnumValueDescriptorProto.
             * @memberof google.protobuf
             * @classdesc Represents an EnumValueDescriptorProto.
             * @implements IEnumValueDescriptorProto
             * @constructor
             * @param {google.protobuf.IEnumValueDescriptorProto=} [properties] Properties to set
             */
            function EnumValueDescriptorProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * EnumValueDescriptorProto name.
             * @member {string} name
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @instance
             */
            EnumValueDescriptorProto.prototype.name = "";

            /**
             * EnumValueDescriptorProto number.
             * @member {number} number
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @instance
             */
            EnumValueDescriptorProto.prototype.number = 0;

            /**
             * EnumValueDescriptorProto options.
             * @member {google.protobuf.IEnumValueOptions|null|undefined} options
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @instance
             */
            EnumValueDescriptorProto.prototype.options = null;

            /**
             * Creates a new EnumValueDescriptorProto instance using the specified properties.
             * @function create
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @static
             * @param {google.protobuf.IEnumValueDescriptorProto=} [properties] Properties to set
             * @returns {google.protobuf.EnumValueDescriptorProto} EnumValueDescriptorProto instance
             */
            EnumValueDescriptorProto.create = function create(properties) {
                return new EnumValueDescriptorProto(properties);
            };

            /**
             * Encodes the specified EnumValueDescriptorProto message. Does not implicitly {@link google.protobuf.EnumValueDescriptorProto.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @static
             * @param {google.protobuf.IEnumValueDescriptorProto} message EnumValueDescriptorProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EnumValueDescriptorProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                if (message.number != null && Object.hasOwnProperty.call(message, "number"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.number);
                if (message.options != null && Object.hasOwnProperty.call(message, "options"))
                    $root.google.protobuf.EnumValueOptions.encode(message.options, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes an EnumValueDescriptorProto message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.EnumValueDescriptorProto} EnumValueDescriptorProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EnumValueDescriptorProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.EnumValueDescriptorProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.name = reader.string();
                        break;
                    case 2:
                        message.number = reader.int32();
                        break;
                    case 3:
                        message.options = $root.google.protobuf.EnumValueOptions.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an EnumValueDescriptorProto message.
             * @function verify
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            EnumValueDescriptorProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.number != null && message.hasOwnProperty("number"))
                    if (!$util.isInteger(message.number))
                        return "number: integer expected";
                if (message.options != null && message.hasOwnProperty("options")) {
                    var error = $root.google.protobuf.EnumValueOptions.verify(message.options);
                    if (error)
                        return "options." + error;
                }
                return null;
            };

            /**
             * Creates an EnumValueDescriptorProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.EnumValueDescriptorProto} EnumValueDescriptorProto
             */
            EnumValueDescriptorProto.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.EnumValueDescriptorProto)
                    return object;
                var message = new $root.google.protobuf.EnumValueDescriptorProto();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.number != null)
                    message.number = object.number | 0;
                if (object.options != null) {
                    if (typeof object.options !== "object")
                        throw TypeError(".google.protobuf.EnumValueDescriptorProto.options: object expected");
                    message.options = $root.google.protobuf.EnumValueOptions.fromObject(object.options);
                }
                return message;
            };

            /**
             * Creates a plain object from an EnumValueDescriptorProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @static
             * @param {google.protobuf.EnumValueDescriptorProto} message EnumValueDescriptorProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            EnumValueDescriptorProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.name = "";
                    object.number = 0;
                    object.options = null;
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.number != null && message.hasOwnProperty("number"))
                    object.number = message.number;
                if (message.options != null && message.hasOwnProperty("options"))
                    object.options = $root.google.protobuf.EnumValueOptions.toObject(message.options, options);
                return object;
            };

            /**
             * Converts this EnumValueDescriptorProto to JSON.
             * @function toJSON
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            EnumValueDescriptorProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return EnumValueDescriptorProto;
        })();

        protobuf.ServiceDescriptorProto = (function() {

            /**
             * Properties of a ServiceDescriptorProto.
             * @memberof google.protobuf
             * @interface IServiceDescriptorProto
             * @property {string|null} [name] ServiceDescriptorProto name
             * @property {Array.<google.protobuf.IMethodDescriptorProto>|null} [method] ServiceDescriptorProto method
             * @property {google.protobuf.IServiceOptions|null} [options] ServiceDescriptorProto options
             */

            /**
             * Constructs a new ServiceDescriptorProto.
             * @memberof google.protobuf
             * @classdesc Represents a ServiceDescriptorProto.
             * @implements IServiceDescriptorProto
             * @constructor
             * @param {google.protobuf.IServiceDescriptorProto=} [properties] Properties to set
             */
            function ServiceDescriptorProto(properties) {
                this.method = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ServiceDescriptorProto name.
             * @member {string} name
             * @memberof google.protobuf.ServiceDescriptorProto
             * @instance
             */
            ServiceDescriptorProto.prototype.name = "";

            /**
             * ServiceDescriptorProto method.
             * @member {Array.<google.protobuf.IMethodDescriptorProto>} method
             * @memberof google.protobuf.ServiceDescriptorProto
             * @instance
             */
            ServiceDescriptorProto.prototype.method = $util.emptyArray;

            /**
             * ServiceDescriptorProto options.
             * @member {google.protobuf.IServiceOptions|null|undefined} options
             * @memberof google.protobuf.ServiceDescriptorProto
             * @instance
             */
            ServiceDescriptorProto.prototype.options = null;

            /**
             * Creates a new ServiceDescriptorProto instance using the specified properties.
             * @function create
             * @memberof google.protobuf.ServiceDescriptorProto
             * @static
             * @param {google.protobuf.IServiceDescriptorProto=} [properties] Properties to set
             * @returns {google.protobuf.ServiceDescriptorProto} ServiceDescriptorProto instance
             */
            ServiceDescriptorProto.create = function create(properties) {
                return new ServiceDescriptorProto(properties);
            };

            /**
             * Encodes the specified ServiceDescriptorProto message. Does not implicitly {@link google.protobuf.ServiceDescriptorProto.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.ServiceDescriptorProto
             * @static
             * @param {google.protobuf.IServiceDescriptorProto} message ServiceDescriptorProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ServiceDescriptorProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                if (message.method != null && message.method.length)
                    for (var i = 0; i < message.method.length; ++i)
                        $root.google.protobuf.MethodDescriptorProto.encode(message.method[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.options != null && Object.hasOwnProperty.call(message, "options"))
                    $root.google.protobuf.ServiceOptions.encode(message.options, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a ServiceDescriptorProto message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.ServiceDescriptorProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.ServiceDescriptorProto} ServiceDescriptorProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ServiceDescriptorProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.ServiceDescriptorProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.name = reader.string();
                        break;
                    case 2:
                        if (!(message.method && message.method.length))
                            message.method = [];
                        message.method.push($root.google.protobuf.MethodDescriptorProto.decode(reader, reader.uint32()));
                        break;
                    case 3:
                        message.options = $root.google.protobuf.ServiceOptions.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a ServiceDescriptorProto message.
             * @function verify
             * @memberof google.protobuf.ServiceDescriptorProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ServiceDescriptorProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.method != null && message.hasOwnProperty("method")) {
                    if (!Array.isArray(message.method))
                        return "method: array expected";
                    for (var i = 0; i < message.method.length; ++i) {
                        var error = $root.google.protobuf.MethodDescriptorProto.verify(message.method[i]);
                        if (error)
                            return "method." + error;
                    }
                }
                if (message.options != null && message.hasOwnProperty("options")) {
                    var error = $root.google.protobuf.ServiceOptions.verify(message.options);
                    if (error)
                        return "options." + error;
                }
                return null;
            };

            /**
             * Creates a ServiceDescriptorProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.ServiceDescriptorProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.ServiceDescriptorProto} ServiceDescriptorProto
             */
            ServiceDescriptorProto.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.ServiceDescriptorProto)
                    return object;
                var message = new $root.google.protobuf.ServiceDescriptorProto();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.method) {
                    if (!Array.isArray(object.method))
                        throw TypeError(".google.protobuf.ServiceDescriptorProto.method: array expected");
                    message.method = [];
                    for (var i = 0; i < object.method.length; ++i) {
                        if (typeof object.method[i] !== "object")
                            throw TypeError(".google.protobuf.ServiceDescriptorProto.method: object expected");
                        message.method[i] = $root.google.protobuf.MethodDescriptorProto.fromObject(object.method[i]);
                    }
                }
                if (object.options != null) {
                    if (typeof object.options !== "object")
                        throw TypeError(".google.protobuf.ServiceDescriptorProto.options: object expected");
                    message.options = $root.google.protobuf.ServiceOptions.fromObject(object.options);
                }
                return message;
            };

            /**
             * Creates a plain object from a ServiceDescriptorProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.ServiceDescriptorProto
             * @static
             * @param {google.protobuf.ServiceDescriptorProto} message ServiceDescriptorProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ServiceDescriptorProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.method = [];
                if (options.defaults) {
                    object.name = "";
                    object.options = null;
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.method && message.method.length) {
                    object.method = [];
                    for (var j = 0; j < message.method.length; ++j)
                        object.method[j] = $root.google.protobuf.MethodDescriptorProto.toObject(message.method[j], options);
                }
                if (message.options != null && message.hasOwnProperty("options"))
                    object.options = $root.google.protobuf.ServiceOptions.toObject(message.options, options);
                return object;
            };

            /**
             * Converts this ServiceDescriptorProto to JSON.
             * @function toJSON
             * @memberof google.protobuf.ServiceDescriptorProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ServiceDescriptorProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return ServiceDescriptorProto;
        })();

        protobuf.MethodDescriptorProto = (function() {

            /**
             * Properties of a MethodDescriptorProto.
             * @memberof google.protobuf
             * @interface IMethodDescriptorProto
             * @property {string|null} [name] MethodDescriptorProto name
             * @property {string|null} [inputType] MethodDescriptorProto inputType
             * @property {string|null} [outputType] MethodDescriptorProto outputType
             * @property {google.protobuf.IMethodOptions|null} [options] MethodDescriptorProto options
             * @property {boolean|null} [clientStreaming] MethodDescriptorProto clientStreaming
             * @property {boolean|null} [serverStreaming] MethodDescriptorProto serverStreaming
             */

            /**
             * Constructs a new MethodDescriptorProto.
             * @memberof google.protobuf
             * @classdesc Represents a MethodDescriptorProto.
             * @implements IMethodDescriptorProto
             * @constructor
             * @param {google.protobuf.IMethodDescriptorProto=} [properties] Properties to set
             */
            function MethodDescriptorProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * MethodDescriptorProto name.
             * @member {string} name
             * @memberof google.protobuf.MethodDescriptorProto
             * @instance
             */
            MethodDescriptorProto.prototype.name = "";

            /**
             * MethodDescriptorProto inputType.
             * @member {string} inputType
             * @memberof google.protobuf.MethodDescriptorProto
             * @instance
             */
            MethodDescriptorProto.prototype.inputType = "";

            /**
             * MethodDescriptorProto outputType.
             * @member {string} outputType
             * @memberof google.protobuf.MethodDescriptorProto
             * @instance
             */
            MethodDescriptorProto.prototype.outputType = "";

            /**
             * MethodDescriptorProto options.
             * @member {google.protobuf.IMethodOptions|null|undefined} options
             * @memberof google.protobuf.MethodDescriptorProto
             * @instance
             */
            MethodDescriptorProto.prototype.options = null;

            /**
             * MethodDescriptorProto clientStreaming.
             * @member {boolean} clientStreaming
             * @memberof google.protobuf.MethodDescriptorProto
             * @instance
             */
            MethodDescriptorProto.prototype.clientStreaming = false;

            /**
             * MethodDescriptorProto serverStreaming.
             * @member {boolean} serverStreaming
             * @memberof google.protobuf.MethodDescriptorProto
             * @instance
             */
            MethodDescriptorProto.prototype.serverStreaming = false;

            /**
             * Creates a new MethodDescriptorProto instance using the specified properties.
             * @function create
             * @memberof google.protobuf.MethodDescriptorProto
             * @static
             * @param {google.protobuf.IMethodDescriptorProto=} [properties] Properties to set
             * @returns {google.protobuf.MethodDescriptorProto} MethodDescriptorProto instance
             */
            MethodDescriptorProto.create = function create(properties) {
                return new MethodDescriptorProto(properties);
            };

            /**
             * Encodes the specified MethodDescriptorProto message. Does not implicitly {@link google.protobuf.MethodDescriptorProto.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.MethodDescriptorProto
             * @static
             * @param {google.protobuf.IMethodDescriptorProto} message MethodDescriptorProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MethodDescriptorProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                if (message.inputType != null && Object.hasOwnProperty.call(message, "inputType"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.inputType);
                if (message.outputType != null && Object.hasOwnProperty.call(message, "outputType"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.outputType);
                if (message.options != null && Object.hasOwnProperty.call(message, "options"))
                    $root.google.protobuf.MethodOptions.encode(message.options, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.clientStreaming != null && Object.hasOwnProperty.call(message, "clientStreaming"))
                    writer.uint32(/* id 5, wireType 0 =*/40).bool(message.clientStreaming);
                if (message.serverStreaming != null && Object.hasOwnProperty.call(message, "serverStreaming"))
                    writer.uint32(/* id 6, wireType 0 =*/48).bool(message.serverStreaming);
                return writer;
            };

            /**
             * Decodes a MethodDescriptorProto message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.MethodDescriptorProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.MethodDescriptorProto} MethodDescriptorProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MethodDescriptorProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.MethodDescriptorProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.name = reader.string();
                        break;
                    case 2:
                        message.inputType = reader.string();
                        break;
                    case 3:
                        message.outputType = reader.string();
                        break;
                    case 4:
                        message.options = $root.google.protobuf.MethodOptions.decode(reader, reader.uint32());
                        break;
                    case 5:
                        message.clientStreaming = reader.bool();
                        break;
                    case 6:
                        message.serverStreaming = reader.bool();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a MethodDescriptorProto message.
             * @function verify
             * @memberof google.protobuf.MethodDescriptorProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            MethodDescriptorProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.inputType != null && message.hasOwnProperty("inputType"))
                    if (!$util.isString(message.inputType))
                        return "inputType: string expected";
                if (message.outputType != null && message.hasOwnProperty("outputType"))
                    if (!$util.isString(message.outputType))
                        return "outputType: string expected";
                if (message.options != null && message.hasOwnProperty("options")) {
                    var error = $root.google.protobuf.MethodOptions.verify(message.options);
                    if (error)
                        return "options." + error;
                }
                if (message.clientStreaming != null && message.hasOwnProperty("clientStreaming"))
                    if (typeof message.clientStreaming !== "boolean")
                        return "clientStreaming: boolean expected";
                if (message.serverStreaming != null && message.hasOwnProperty("serverStreaming"))
                    if (typeof message.serverStreaming !== "boolean")
                        return "serverStreaming: boolean expected";
                return null;
            };

            /**
             * Creates a MethodDescriptorProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.MethodDescriptorProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.MethodDescriptorProto} MethodDescriptorProto
             */
            MethodDescriptorProto.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.MethodDescriptorProto)
                    return object;
                var message = new $root.google.protobuf.MethodDescriptorProto();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.inputType != null)
                    message.inputType = String(object.inputType);
                if (object.outputType != null)
                    message.outputType = String(object.outputType);
                if (object.options != null) {
                    if (typeof object.options !== "object")
                        throw TypeError(".google.protobuf.MethodDescriptorProto.options: object expected");
                    message.options = $root.google.protobuf.MethodOptions.fromObject(object.options);
                }
                if (object.clientStreaming != null)
                    message.clientStreaming = Boolean(object.clientStreaming);
                if (object.serverStreaming != null)
                    message.serverStreaming = Boolean(object.serverStreaming);
                return message;
            };

            /**
             * Creates a plain object from a MethodDescriptorProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.MethodDescriptorProto
             * @static
             * @param {google.protobuf.MethodDescriptorProto} message MethodDescriptorProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            MethodDescriptorProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.name = "";
                    object.inputType = "";
                    object.outputType = "";
                    object.options = null;
                    object.clientStreaming = false;
                    object.serverStreaming = false;
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.inputType != null && message.hasOwnProperty("inputType"))
                    object.inputType = message.inputType;
                if (message.outputType != null && message.hasOwnProperty("outputType"))
                    object.outputType = message.outputType;
                if (message.options != null && message.hasOwnProperty("options"))
                    object.options = $root.google.protobuf.MethodOptions.toObject(message.options, options);
                if (message.clientStreaming != null && message.hasOwnProperty("clientStreaming"))
                    object.clientStreaming = message.clientStreaming;
                if (message.serverStreaming != null && message.hasOwnProperty("serverStreaming"))
                    object.serverStreaming = message.serverStreaming;
                return object;
            };

            /**
             * Converts this MethodDescriptorProto to JSON.
             * @function toJSON
             * @memberof google.protobuf.MethodDescriptorProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            MethodDescriptorProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return MethodDescriptorProto;
        })();

        protobuf.FileOptions = (function() {

            /**
             * Properties of a FileOptions.
             * @memberof google.protobuf
             * @interface IFileOptions
             * @property {string|null} [javaPackage] FileOptions javaPackage
             * @property {string|null} [javaOuterClassname] FileOptions javaOuterClassname
             * @property {boolean|null} [javaMultipleFiles] FileOptions javaMultipleFiles
             * @property {boolean|null} [javaGenerateEqualsAndHash] FileOptions javaGenerateEqualsAndHash
             * @property {boolean|null} [javaStringCheckUtf8] FileOptions javaStringCheckUtf8
             * @property {google.protobuf.FileOptions.OptimizeMode|null} [optimizeFor] FileOptions optimizeFor
             * @property {string|null} [goPackage] FileOptions goPackage
             * @property {boolean|null} [ccGenericServices] FileOptions ccGenericServices
             * @property {boolean|null} [javaGenericServices] FileOptions javaGenericServices
             * @property {boolean|null} [pyGenericServices] FileOptions pyGenericServices
             * @property {boolean|null} [deprecated] FileOptions deprecated
             * @property {boolean|null} [ccEnableArenas] FileOptions ccEnableArenas
             * @property {string|null} [objcClassPrefix] FileOptions objcClassPrefix
             * @property {string|null} [csharpNamespace] FileOptions csharpNamespace
             * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] FileOptions uninterpretedOption
             */

            /**
             * Constructs a new FileOptions.
             * @memberof google.protobuf
             * @classdesc Represents a FileOptions.
             * @implements IFileOptions
             * @constructor
             * @param {google.protobuf.IFileOptions=} [properties] Properties to set
             */
            function FileOptions(properties) {
                this.uninterpretedOption = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * FileOptions javaPackage.
             * @member {string} javaPackage
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.javaPackage = "";

            /**
             * FileOptions javaOuterClassname.
             * @member {string} javaOuterClassname
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.javaOuterClassname = "";

            /**
             * FileOptions javaMultipleFiles.
             * @member {boolean} javaMultipleFiles
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.javaMultipleFiles = false;

            /**
             * FileOptions javaGenerateEqualsAndHash.
             * @member {boolean} javaGenerateEqualsAndHash
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.javaGenerateEqualsAndHash = false;

            /**
             * FileOptions javaStringCheckUtf8.
             * @member {boolean} javaStringCheckUtf8
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.javaStringCheckUtf8 = false;

            /**
             * FileOptions optimizeFor.
             * @member {google.protobuf.FileOptions.OptimizeMode} optimizeFor
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.optimizeFor = 1;

            /**
             * FileOptions goPackage.
             * @member {string} goPackage
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.goPackage = "";

            /**
             * FileOptions ccGenericServices.
             * @member {boolean} ccGenericServices
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.ccGenericServices = false;

            /**
             * FileOptions javaGenericServices.
             * @member {boolean} javaGenericServices
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.javaGenericServices = false;

            /**
             * FileOptions pyGenericServices.
             * @member {boolean} pyGenericServices
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.pyGenericServices = false;

            /**
             * FileOptions deprecated.
             * @member {boolean} deprecated
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.deprecated = false;

            /**
             * FileOptions ccEnableArenas.
             * @member {boolean} ccEnableArenas
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.ccEnableArenas = false;

            /**
             * FileOptions objcClassPrefix.
             * @member {string} objcClassPrefix
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.objcClassPrefix = "";

            /**
             * FileOptions csharpNamespace.
             * @member {string} csharpNamespace
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.csharpNamespace = "";

            /**
             * FileOptions uninterpretedOption.
             * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.uninterpretedOption = $util.emptyArray;

            /**
             * Creates a new FileOptions instance using the specified properties.
             * @function create
             * @memberof google.protobuf.FileOptions
             * @static
             * @param {google.protobuf.IFileOptions=} [properties] Properties to set
             * @returns {google.protobuf.FileOptions} FileOptions instance
             */
            FileOptions.create = function create(properties) {
                return new FileOptions(properties);
            };

            /**
             * Encodes the specified FileOptions message. Does not implicitly {@link google.protobuf.FileOptions.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.FileOptions
             * @static
             * @param {google.protobuf.IFileOptions} message FileOptions message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FileOptions.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.javaPackage != null && Object.hasOwnProperty.call(message, "javaPackage"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.javaPackage);
                if (message.javaOuterClassname != null && Object.hasOwnProperty.call(message, "javaOuterClassname"))
                    writer.uint32(/* id 8, wireType 2 =*/66).string(message.javaOuterClassname);
                if (message.optimizeFor != null && Object.hasOwnProperty.call(message, "optimizeFor"))
                    writer.uint32(/* id 9, wireType 0 =*/72).int32(message.optimizeFor);
                if (message.javaMultipleFiles != null && Object.hasOwnProperty.call(message, "javaMultipleFiles"))
                    writer.uint32(/* id 10, wireType 0 =*/80).bool(message.javaMultipleFiles);
                if (message.goPackage != null && Object.hasOwnProperty.call(message, "goPackage"))
                    writer.uint32(/* id 11, wireType 2 =*/90).string(message.goPackage);
                if (message.ccGenericServices != null && Object.hasOwnProperty.call(message, "ccGenericServices"))
                    writer.uint32(/* id 16, wireType 0 =*/128).bool(message.ccGenericServices);
                if (message.javaGenericServices != null && Object.hasOwnProperty.call(message, "javaGenericServices"))
                    writer.uint32(/* id 17, wireType 0 =*/136).bool(message.javaGenericServices);
                if (message.pyGenericServices != null && Object.hasOwnProperty.call(message, "pyGenericServices"))
                    writer.uint32(/* id 18, wireType 0 =*/144).bool(message.pyGenericServices);
                if (message.javaGenerateEqualsAndHash != null && Object.hasOwnProperty.call(message, "javaGenerateEqualsAndHash"))
                    writer.uint32(/* id 20, wireType 0 =*/160).bool(message.javaGenerateEqualsAndHash);
                if (message.deprecated != null && Object.hasOwnProperty.call(message, "deprecated"))
                    writer.uint32(/* id 23, wireType 0 =*/184).bool(message.deprecated);
                if (message.javaStringCheckUtf8 != null && Object.hasOwnProperty.call(message, "javaStringCheckUtf8"))
                    writer.uint32(/* id 27, wireType 0 =*/216).bool(message.javaStringCheckUtf8);
                if (message.ccEnableArenas != null && Object.hasOwnProperty.call(message, "ccEnableArenas"))
                    writer.uint32(/* id 31, wireType 0 =*/248).bool(message.ccEnableArenas);
                if (message.objcClassPrefix != null && Object.hasOwnProperty.call(message, "objcClassPrefix"))
                    writer.uint32(/* id 36, wireType 2 =*/290).string(message.objcClassPrefix);
                if (message.csharpNamespace != null && Object.hasOwnProperty.call(message, "csharpNamespace"))
                    writer.uint32(/* id 37, wireType 2 =*/298).string(message.csharpNamespace);
                if (message.uninterpretedOption != null && message.uninterpretedOption.length)
                    for (var i = 0; i < message.uninterpretedOption.length; ++i)
                        $root.google.protobuf.UninterpretedOption.encode(message.uninterpretedOption[i], writer.uint32(/* id 999, wireType 2 =*/7994).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a FileOptions message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.FileOptions
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.FileOptions} FileOptions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FileOptions.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.FileOptions();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.javaPackage = reader.string();
                        break;
                    case 8:
                        message.javaOuterClassname = reader.string();
                        break;
                    case 10:
                        message.javaMultipleFiles = reader.bool();
                        break;
                    case 20:
                        message.javaGenerateEqualsAndHash = reader.bool();
                        break;
                    case 27:
                        message.javaStringCheckUtf8 = reader.bool();
                        break;
                    case 9:
                        message.optimizeFor = reader.int32();
                        break;
                    case 11:
                        message.goPackage = reader.string();
                        break;
                    case 16:
                        message.ccGenericServices = reader.bool();
                        break;
                    case 17:
                        message.javaGenericServices = reader.bool();
                        break;
                    case 18:
                        message.pyGenericServices = reader.bool();
                        break;
                    case 23:
                        message.deprecated = reader.bool();
                        break;
                    case 31:
                        message.ccEnableArenas = reader.bool();
                        break;
                    case 36:
                        message.objcClassPrefix = reader.string();
                        break;
                    case 37:
                        message.csharpNamespace = reader.string();
                        break;
                    case 999:
                        if (!(message.uninterpretedOption && message.uninterpretedOption.length))
                            message.uninterpretedOption = [];
                        message.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a FileOptions message.
             * @function verify
             * @memberof google.protobuf.FileOptions
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            FileOptions.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.javaPackage != null && message.hasOwnProperty("javaPackage"))
                    if (!$util.isString(message.javaPackage))
                        return "javaPackage: string expected";
                if (message.javaOuterClassname != null && message.hasOwnProperty("javaOuterClassname"))
                    if (!$util.isString(message.javaOuterClassname))
                        return "javaOuterClassname: string expected";
                if (message.javaMultipleFiles != null && message.hasOwnProperty("javaMultipleFiles"))
                    if (typeof message.javaMultipleFiles !== "boolean")
                        return "javaMultipleFiles: boolean expected";
                if (message.javaGenerateEqualsAndHash != null && message.hasOwnProperty("javaGenerateEqualsAndHash"))
                    if (typeof message.javaGenerateEqualsAndHash !== "boolean")
                        return "javaGenerateEqualsAndHash: boolean expected";
                if (message.javaStringCheckUtf8 != null && message.hasOwnProperty("javaStringCheckUtf8"))
                    if (typeof message.javaStringCheckUtf8 !== "boolean")
                        return "javaStringCheckUtf8: boolean expected";
                if (message.optimizeFor != null && message.hasOwnProperty("optimizeFor"))
                    switch (message.optimizeFor) {
                    default:
                        return "optimizeFor: enum value expected";
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
                if (message.goPackage != null && message.hasOwnProperty("goPackage"))
                    if (!$util.isString(message.goPackage))
                        return "goPackage: string expected";
                if (message.ccGenericServices != null && message.hasOwnProperty("ccGenericServices"))
                    if (typeof message.ccGenericServices !== "boolean")
                        return "ccGenericServices: boolean expected";
                if (message.javaGenericServices != null && message.hasOwnProperty("javaGenericServices"))
                    if (typeof message.javaGenericServices !== "boolean")
                        return "javaGenericServices: boolean expected";
                if (message.pyGenericServices != null && message.hasOwnProperty("pyGenericServices"))
                    if (typeof message.pyGenericServices !== "boolean")
                        return "pyGenericServices: boolean expected";
                if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                    if (typeof message.deprecated !== "boolean")
                        return "deprecated: boolean expected";
                if (message.ccEnableArenas != null && message.hasOwnProperty("ccEnableArenas"))
                    if (typeof message.ccEnableArenas !== "boolean")
                        return "ccEnableArenas: boolean expected";
                if (message.objcClassPrefix != null && message.hasOwnProperty("objcClassPrefix"))
                    if (!$util.isString(message.objcClassPrefix))
                        return "objcClassPrefix: string expected";
                if (message.csharpNamespace != null && message.hasOwnProperty("csharpNamespace"))
                    if (!$util.isString(message.csharpNamespace))
                        return "csharpNamespace: string expected";
                if (message.uninterpretedOption != null && message.hasOwnProperty("uninterpretedOption")) {
                    if (!Array.isArray(message.uninterpretedOption))
                        return "uninterpretedOption: array expected";
                    for (var i = 0; i < message.uninterpretedOption.length; ++i) {
                        var error = $root.google.protobuf.UninterpretedOption.verify(message.uninterpretedOption[i]);
                        if (error)
                            return "uninterpretedOption." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a FileOptions message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.FileOptions
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.FileOptions} FileOptions
             */
            FileOptions.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.FileOptions)
                    return object;
                var message = new $root.google.protobuf.FileOptions();
                if (object.javaPackage != null)
                    message.javaPackage = String(object.javaPackage);
                if (object.javaOuterClassname != null)
                    message.javaOuterClassname = String(object.javaOuterClassname);
                if (object.javaMultipleFiles != null)
                    message.javaMultipleFiles = Boolean(object.javaMultipleFiles);
                if (object.javaGenerateEqualsAndHash != null)
                    message.javaGenerateEqualsAndHash = Boolean(object.javaGenerateEqualsAndHash);
                if (object.javaStringCheckUtf8 != null)
                    message.javaStringCheckUtf8 = Boolean(object.javaStringCheckUtf8);
                switch (object.optimizeFor) {
                case "SPEED":
                case 1:
                    message.optimizeFor = 1;
                    break;
                case "CODE_SIZE":
                case 2:
                    message.optimizeFor = 2;
                    break;
                case "LITE_RUNTIME":
                case 3:
                    message.optimizeFor = 3;
                    break;
                }
                if (object.goPackage != null)
                    message.goPackage = String(object.goPackage);
                if (object.ccGenericServices != null)
                    message.ccGenericServices = Boolean(object.ccGenericServices);
                if (object.javaGenericServices != null)
                    message.javaGenericServices = Boolean(object.javaGenericServices);
                if (object.pyGenericServices != null)
                    message.pyGenericServices = Boolean(object.pyGenericServices);
                if (object.deprecated != null)
                    message.deprecated = Boolean(object.deprecated);
                if (object.ccEnableArenas != null)
                    message.ccEnableArenas = Boolean(object.ccEnableArenas);
                if (object.objcClassPrefix != null)
                    message.objcClassPrefix = String(object.objcClassPrefix);
                if (object.csharpNamespace != null)
                    message.csharpNamespace = String(object.csharpNamespace);
                if (object.uninterpretedOption) {
                    if (!Array.isArray(object.uninterpretedOption))
                        throw TypeError(".google.protobuf.FileOptions.uninterpretedOption: array expected");
                    message.uninterpretedOption = [];
                    for (var i = 0; i < object.uninterpretedOption.length; ++i) {
                        if (typeof object.uninterpretedOption[i] !== "object")
                            throw TypeError(".google.protobuf.FileOptions.uninterpretedOption: object expected");
                        message.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(object.uninterpretedOption[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a FileOptions message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.FileOptions
             * @static
             * @param {google.protobuf.FileOptions} message FileOptions
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FileOptions.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.uninterpretedOption = [];
                if (options.defaults) {
                    object.javaPackage = "";
                    object.javaOuterClassname = "";
                    object.optimizeFor = options.enums === String ? "SPEED" : 1;
                    object.javaMultipleFiles = false;
                    object.goPackage = "";
                    object.ccGenericServices = false;
                    object.javaGenericServices = false;
                    object.pyGenericServices = false;
                    object.javaGenerateEqualsAndHash = false;
                    object.deprecated = false;
                    object.javaStringCheckUtf8 = false;
                    object.ccEnableArenas = false;
                    object.objcClassPrefix = "";
                    object.csharpNamespace = "";
                }
                if (message.javaPackage != null && message.hasOwnProperty("javaPackage"))
                    object.javaPackage = message.javaPackage;
                if (message.javaOuterClassname != null && message.hasOwnProperty("javaOuterClassname"))
                    object.javaOuterClassname = message.javaOuterClassname;
                if (message.optimizeFor != null && message.hasOwnProperty("optimizeFor"))
                    object.optimizeFor = options.enums === String ? $root.google.protobuf.FileOptions.OptimizeMode[message.optimizeFor] : message.optimizeFor;
                if (message.javaMultipleFiles != null && message.hasOwnProperty("javaMultipleFiles"))
                    object.javaMultipleFiles = message.javaMultipleFiles;
                if (message.goPackage != null && message.hasOwnProperty("goPackage"))
                    object.goPackage = message.goPackage;
                if (message.ccGenericServices != null && message.hasOwnProperty("ccGenericServices"))
                    object.ccGenericServices = message.ccGenericServices;
                if (message.javaGenericServices != null && message.hasOwnProperty("javaGenericServices"))
                    object.javaGenericServices = message.javaGenericServices;
                if (message.pyGenericServices != null && message.hasOwnProperty("pyGenericServices"))
                    object.pyGenericServices = message.pyGenericServices;
                if (message.javaGenerateEqualsAndHash != null && message.hasOwnProperty("javaGenerateEqualsAndHash"))
                    object.javaGenerateEqualsAndHash = message.javaGenerateEqualsAndHash;
                if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                    object.deprecated = message.deprecated;
                if (message.javaStringCheckUtf8 != null && message.hasOwnProperty("javaStringCheckUtf8"))
                    object.javaStringCheckUtf8 = message.javaStringCheckUtf8;
                if (message.ccEnableArenas != null && message.hasOwnProperty("ccEnableArenas"))
                    object.ccEnableArenas = message.ccEnableArenas;
                if (message.objcClassPrefix != null && message.hasOwnProperty("objcClassPrefix"))
                    object.objcClassPrefix = message.objcClassPrefix;
                if (message.csharpNamespace != null && message.hasOwnProperty("csharpNamespace"))
                    object.csharpNamespace = message.csharpNamespace;
                if (message.uninterpretedOption && message.uninterpretedOption.length) {
                    object.uninterpretedOption = [];
                    for (var j = 0; j < message.uninterpretedOption.length; ++j)
                        object.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(message.uninterpretedOption[j], options);
                }
                return object;
            };

            /**
             * Converts this FileOptions to JSON.
             * @function toJSON
             * @memberof google.protobuf.FileOptions
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FileOptions.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * OptimizeMode enum.
             * @name google.protobuf.FileOptions.OptimizeMode
             * @enum {number}
             * @property {number} SPEED=1 SPEED value
             * @property {number} CODE_SIZE=2 CODE_SIZE value
             * @property {number} LITE_RUNTIME=3 LITE_RUNTIME value
             */
            FileOptions.OptimizeMode = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[1] = "SPEED"] = 1;
                values[valuesById[2] = "CODE_SIZE"] = 2;
                values[valuesById[3] = "LITE_RUNTIME"] = 3;
                return values;
            })();

            return FileOptions;
        })();

        protobuf.MessageOptions = (function() {

            /**
             * Properties of a MessageOptions.
             * @memberof google.protobuf
             * @interface IMessageOptions
             * @property {boolean|null} [messageSetWireFormat] MessageOptions messageSetWireFormat
             * @property {boolean|null} [noStandardDescriptorAccessor] MessageOptions noStandardDescriptorAccessor
             * @property {boolean|null} [deprecated] MessageOptions deprecated
             * @property {boolean|null} [mapEntry] MessageOptions mapEntry
             * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] MessageOptions uninterpretedOption
             */

            /**
             * Constructs a new MessageOptions.
             * @memberof google.protobuf
             * @classdesc Represents a MessageOptions.
             * @implements IMessageOptions
             * @constructor
             * @param {google.protobuf.IMessageOptions=} [properties] Properties to set
             */
            function MessageOptions(properties) {
                this.uninterpretedOption = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * MessageOptions messageSetWireFormat.
             * @member {boolean} messageSetWireFormat
             * @memberof google.protobuf.MessageOptions
             * @instance
             */
            MessageOptions.prototype.messageSetWireFormat = false;

            /**
             * MessageOptions noStandardDescriptorAccessor.
             * @member {boolean} noStandardDescriptorAccessor
             * @memberof google.protobuf.MessageOptions
             * @instance
             */
            MessageOptions.prototype.noStandardDescriptorAccessor = false;

            /**
             * MessageOptions deprecated.
             * @member {boolean} deprecated
             * @memberof google.protobuf.MessageOptions
             * @instance
             */
            MessageOptions.prototype.deprecated = false;

            /**
             * MessageOptions mapEntry.
             * @member {boolean} mapEntry
             * @memberof google.protobuf.MessageOptions
             * @instance
             */
            MessageOptions.prototype.mapEntry = false;

            /**
             * MessageOptions uninterpretedOption.
             * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
             * @memberof google.protobuf.MessageOptions
             * @instance
             */
            MessageOptions.prototype.uninterpretedOption = $util.emptyArray;

            /**
             * Creates a new MessageOptions instance using the specified properties.
             * @function create
             * @memberof google.protobuf.MessageOptions
             * @static
             * @param {google.protobuf.IMessageOptions=} [properties] Properties to set
             * @returns {google.protobuf.MessageOptions} MessageOptions instance
             */
            MessageOptions.create = function create(properties) {
                return new MessageOptions(properties);
            };

            /**
             * Encodes the specified MessageOptions message. Does not implicitly {@link google.protobuf.MessageOptions.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.MessageOptions
             * @static
             * @param {google.protobuf.IMessageOptions} message MessageOptions message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MessageOptions.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.messageSetWireFormat != null && Object.hasOwnProperty.call(message, "messageSetWireFormat"))
                    writer.uint32(/* id 1, wireType 0 =*/8).bool(message.messageSetWireFormat);
                if (message.noStandardDescriptorAccessor != null && Object.hasOwnProperty.call(message, "noStandardDescriptorAccessor"))
                    writer.uint32(/* id 2, wireType 0 =*/16).bool(message.noStandardDescriptorAccessor);
                if (message.deprecated != null && Object.hasOwnProperty.call(message, "deprecated"))
                    writer.uint32(/* id 3, wireType 0 =*/24).bool(message.deprecated);
                if (message.mapEntry != null && Object.hasOwnProperty.call(message, "mapEntry"))
                    writer.uint32(/* id 7, wireType 0 =*/56).bool(message.mapEntry);
                if (message.uninterpretedOption != null && message.uninterpretedOption.length)
                    for (var i = 0; i < message.uninterpretedOption.length; ++i)
                        $root.google.protobuf.UninterpretedOption.encode(message.uninterpretedOption[i], writer.uint32(/* id 999, wireType 2 =*/7994).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a MessageOptions message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.MessageOptions
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.MessageOptions} MessageOptions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MessageOptions.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.MessageOptions();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.messageSetWireFormat = reader.bool();
                        break;
                    case 2:
                        message.noStandardDescriptorAccessor = reader.bool();
                        break;
                    case 3:
                        message.deprecated = reader.bool();
                        break;
                    case 7:
                        message.mapEntry = reader.bool();
                        break;
                    case 999:
                        if (!(message.uninterpretedOption && message.uninterpretedOption.length))
                            message.uninterpretedOption = [];
                        message.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a MessageOptions message.
             * @function verify
             * @memberof google.protobuf.MessageOptions
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            MessageOptions.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.messageSetWireFormat != null && message.hasOwnProperty("messageSetWireFormat"))
                    if (typeof message.messageSetWireFormat !== "boolean")
                        return "messageSetWireFormat: boolean expected";
                if (message.noStandardDescriptorAccessor != null && message.hasOwnProperty("noStandardDescriptorAccessor"))
                    if (typeof message.noStandardDescriptorAccessor !== "boolean")
                        return "noStandardDescriptorAccessor: boolean expected";
                if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                    if (typeof message.deprecated !== "boolean")
                        return "deprecated: boolean expected";
                if (message.mapEntry != null && message.hasOwnProperty("mapEntry"))
                    if (typeof message.mapEntry !== "boolean")
                        return "mapEntry: boolean expected";
                if (message.uninterpretedOption != null && message.hasOwnProperty("uninterpretedOption")) {
                    if (!Array.isArray(message.uninterpretedOption))
                        return "uninterpretedOption: array expected";
                    for (var i = 0; i < message.uninterpretedOption.length; ++i) {
                        var error = $root.google.protobuf.UninterpretedOption.verify(message.uninterpretedOption[i]);
                        if (error)
                            return "uninterpretedOption." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a MessageOptions message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.MessageOptions
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.MessageOptions} MessageOptions
             */
            MessageOptions.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.MessageOptions)
                    return object;
                var message = new $root.google.protobuf.MessageOptions();
                if (object.messageSetWireFormat != null)
                    message.messageSetWireFormat = Boolean(object.messageSetWireFormat);
                if (object.noStandardDescriptorAccessor != null)
                    message.noStandardDescriptorAccessor = Boolean(object.noStandardDescriptorAccessor);
                if (object.deprecated != null)
                    message.deprecated = Boolean(object.deprecated);
                if (object.mapEntry != null)
                    message.mapEntry = Boolean(object.mapEntry);
                if (object.uninterpretedOption) {
                    if (!Array.isArray(object.uninterpretedOption))
                        throw TypeError(".google.protobuf.MessageOptions.uninterpretedOption: array expected");
                    message.uninterpretedOption = [];
                    for (var i = 0; i < object.uninterpretedOption.length; ++i) {
                        if (typeof object.uninterpretedOption[i] !== "object")
                            throw TypeError(".google.protobuf.MessageOptions.uninterpretedOption: object expected");
                        message.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(object.uninterpretedOption[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a MessageOptions message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.MessageOptions
             * @static
             * @param {google.protobuf.MessageOptions} message MessageOptions
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            MessageOptions.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.uninterpretedOption = [];
                if (options.defaults) {
                    object.messageSetWireFormat = false;
                    object.noStandardDescriptorAccessor = false;
                    object.deprecated = false;
                    object.mapEntry = false;
                }
                if (message.messageSetWireFormat != null && message.hasOwnProperty("messageSetWireFormat"))
                    object.messageSetWireFormat = message.messageSetWireFormat;
                if (message.noStandardDescriptorAccessor != null && message.hasOwnProperty("noStandardDescriptorAccessor"))
                    object.noStandardDescriptorAccessor = message.noStandardDescriptorAccessor;
                if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                    object.deprecated = message.deprecated;
                if (message.mapEntry != null && message.hasOwnProperty("mapEntry"))
                    object.mapEntry = message.mapEntry;
                if (message.uninterpretedOption && message.uninterpretedOption.length) {
                    object.uninterpretedOption = [];
                    for (var j = 0; j < message.uninterpretedOption.length; ++j)
                        object.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(message.uninterpretedOption[j], options);
                }
                return object;
            };

            /**
             * Converts this MessageOptions to JSON.
             * @function toJSON
             * @memberof google.protobuf.MessageOptions
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            MessageOptions.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return MessageOptions;
        })();

        protobuf.FieldOptions = (function() {

            /**
             * Properties of a FieldOptions.
             * @memberof google.protobuf
             * @interface IFieldOptions
             * @property {google.protobuf.FieldOptions.CType|null} [ctype] FieldOptions ctype
             * @property {boolean|null} [packed] FieldOptions packed
             * @property {google.protobuf.FieldOptions.JSType|null} [jstype] FieldOptions jstype
             * @property {boolean|null} [lazy] FieldOptions lazy
             * @property {boolean|null} [deprecated] FieldOptions deprecated
             * @property {boolean|null} [weak] FieldOptions weak
             * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] FieldOptions uninterpretedOption
             * @property {string|null} [".fastcity.admin.gmrpcResponse"] FieldOptions .fastcity.admin.gmrpcResponse
             * @property {boolean|null} [".fastcity.admin.gmrpcIsBroadcast"] FieldOptions .fastcity.admin.gmrpcIsBroadcast
             * @property {string|null} [".fastcity.connector.gmrpcResponse"] FieldOptions .fastcity.connector.gmrpcResponse
             * @property {string|null} [".fastcity.connector.launcher.gmrpcResponse"] FieldOptions .fastcity.connector.launcher.gmrpcResponse
             * @property {boolean|null} [".fastcity.connector.launcher.gmrpcIsBroadcast"] FieldOptions .fastcity.connector.launcher.gmrpcIsBroadcast
             * @property {string|null} [".fastcity.courier.gmrpcResponse"] FieldOptions .fastcity.courier.gmrpcResponse
             * @property {boolean|null} [".fastcity.courier.gmrpcIsBroadcast"] FieldOptions .fastcity.courier.gmrpcIsBroadcast
             */

            /**
             * Constructs a new FieldOptions.
             * @memberof google.protobuf
             * @classdesc Represents a FieldOptions.
             * @implements IFieldOptions
             * @constructor
             * @param {google.protobuf.IFieldOptions=} [properties] Properties to set
             */
            function FieldOptions(properties) {
                this.uninterpretedOption = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * FieldOptions ctype.
             * @member {google.protobuf.FieldOptions.CType} ctype
             * @memberof google.protobuf.FieldOptions
             * @instance
             */
            FieldOptions.prototype.ctype = 0;

            /**
             * FieldOptions packed.
             * @member {boolean} packed
             * @memberof google.protobuf.FieldOptions
             * @instance
             */
            FieldOptions.prototype.packed = false;

            /**
             * FieldOptions jstype.
             * @member {google.protobuf.FieldOptions.JSType} jstype
             * @memberof google.protobuf.FieldOptions
             * @instance
             */
            FieldOptions.prototype.jstype = 0;

            /**
             * FieldOptions lazy.
             * @member {boolean} lazy
             * @memberof google.protobuf.FieldOptions
             * @instance
             */
            FieldOptions.prototype.lazy = false;

            /**
             * FieldOptions deprecated.
             * @member {boolean} deprecated
             * @memberof google.protobuf.FieldOptions
             * @instance
             */
            FieldOptions.prototype.deprecated = false;

            /**
             * FieldOptions weak.
             * @member {boolean} weak
             * @memberof google.protobuf.FieldOptions
             * @instance
             */
            FieldOptions.prototype.weak = false;

            /**
             * FieldOptions uninterpretedOption.
             * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
             * @memberof google.protobuf.FieldOptions
             * @instance
             */
            FieldOptions.prototype.uninterpretedOption = $util.emptyArray;

            /**
             * FieldOptions .fastcity.admin.gmrpcResponse.
             * @member {string} .fastcity.admin.gmrpcResponse
             * @memberof google.protobuf.FieldOptions
             * @instance
             */
            FieldOptions.prototype[".fastcity.admin.gmrpcResponse"] = "";

            /**
             * FieldOptions .fastcity.admin.gmrpcIsBroadcast.
             * @member {boolean} .fastcity.admin.gmrpcIsBroadcast
             * @memberof google.protobuf.FieldOptions
             * @instance
             */
            FieldOptions.prototype[".fastcity.admin.gmrpcIsBroadcast"] = false;

            /**
             * FieldOptions .fastcity.connector.gmrpcResponse.
             * @member {string} .fastcity.connector.gmrpcResponse
             * @memberof google.protobuf.FieldOptions
             * @instance
             */
            FieldOptions.prototype[".fastcity.connector.gmrpcResponse"] = "";

            /**
             * FieldOptions .fastcity.connector.launcher.gmrpcResponse.
             * @member {string} .fastcity.connector.launcher.gmrpcResponse
             * @memberof google.protobuf.FieldOptions
             * @instance
             */
            FieldOptions.prototype[".fastcity.connector.launcher.gmrpcResponse"] = "";

            /**
             * FieldOptions .fastcity.connector.launcher.gmrpcIsBroadcast.
             * @member {boolean} .fastcity.connector.launcher.gmrpcIsBroadcast
             * @memberof google.protobuf.FieldOptions
             * @instance
             */
            FieldOptions.prototype[".fastcity.connector.launcher.gmrpcIsBroadcast"] = false;

            /**
             * FieldOptions .fastcity.courier.gmrpcResponse.
             * @member {string} .fastcity.courier.gmrpcResponse
             * @memberof google.protobuf.FieldOptions
             * @instance
             */
            FieldOptions.prototype[".fastcity.courier.gmrpcResponse"] = "";

            /**
             * FieldOptions .fastcity.courier.gmrpcIsBroadcast.
             * @member {boolean} .fastcity.courier.gmrpcIsBroadcast
             * @memberof google.protobuf.FieldOptions
             * @instance
             */
            FieldOptions.prototype[".fastcity.courier.gmrpcIsBroadcast"] = false;

            /**
             * Creates a new FieldOptions instance using the specified properties.
             * @function create
             * @memberof google.protobuf.FieldOptions
             * @static
             * @param {google.protobuf.IFieldOptions=} [properties] Properties to set
             * @returns {google.protobuf.FieldOptions} FieldOptions instance
             */
            FieldOptions.create = function create(properties) {
                return new FieldOptions(properties);
            };

            /**
             * Encodes the specified FieldOptions message. Does not implicitly {@link google.protobuf.FieldOptions.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.FieldOptions
             * @static
             * @param {google.protobuf.IFieldOptions} message FieldOptions message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FieldOptions.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.ctype != null && Object.hasOwnProperty.call(message, "ctype"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.ctype);
                if (message.packed != null && Object.hasOwnProperty.call(message, "packed"))
                    writer.uint32(/* id 2, wireType 0 =*/16).bool(message.packed);
                if (message.deprecated != null && Object.hasOwnProperty.call(message, "deprecated"))
                    writer.uint32(/* id 3, wireType 0 =*/24).bool(message.deprecated);
                if (message.lazy != null && Object.hasOwnProperty.call(message, "lazy"))
                    writer.uint32(/* id 5, wireType 0 =*/40).bool(message.lazy);
                if (message.jstype != null && Object.hasOwnProperty.call(message, "jstype"))
                    writer.uint32(/* id 6, wireType 0 =*/48).int32(message.jstype);
                if (message.weak != null && Object.hasOwnProperty.call(message, "weak"))
                    writer.uint32(/* id 10, wireType 0 =*/80).bool(message.weak);
                if (message.uninterpretedOption != null && message.uninterpretedOption.length)
                    for (var i = 0; i < message.uninterpretedOption.length; ++i)
                        $root.google.protobuf.UninterpretedOption.encode(message.uninterpretedOption[i], writer.uint32(/* id 999, wireType 2 =*/7994).fork()).ldelim();
                if (message[".fastcity.courier.gmrpcResponse"] != null && Object.hasOwnProperty.call(message, ".fastcity.courier.gmrpcResponse"))
                    writer.uint32(/* id 10001, wireType 2 =*/80010).string(message[".fastcity.courier.gmrpcResponse"]);
                if (message[".fastcity.courier.gmrpcIsBroadcast"] != null && Object.hasOwnProperty.call(message, ".fastcity.courier.gmrpcIsBroadcast"))
                    writer.uint32(/* id 10002, wireType 0 =*/80016).bool(message[".fastcity.courier.gmrpcIsBroadcast"]);
                if (message[".fastcity.connector.launcher.gmrpcResponse"] != null && Object.hasOwnProperty.call(message, ".fastcity.connector.launcher.gmrpcResponse"))
                    writer.uint32(/* id 10101, wireType 2 =*/80810).string(message[".fastcity.connector.launcher.gmrpcResponse"]);
                if (message[".fastcity.connector.launcher.gmrpcIsBroadcast"] != null && Object.hasOwnProperty.call(message, ".fastcity.connector.launcher.gmrpcIsBroadcast"))
                    writer.uint32(/* id 10102, wireType 0 =*/80816).bool(message[".fastcity.connector.launcher.gmrpcIsBroadcast"]);
                if (message[".fastcity.connector.gmrpcResponse"] != null && Object.hasOwnProperty.call(message, ".fastcity.connector.gmrpcResponse"))
                    writer.uint32(/* id 10103, wireType 2 =*/80826).string(message[".fastcity.connector.gmrpcResponse"]);
                if (message[".fastcity.admin.gmrpcResponse"] != null && Object.hasOwnProperty.call(message, ".fastcity.admin.gmrpcResponse"))
                    writer.uint32(/* id 10201, wireType 2 =*/81610).string(message[".fastcity.admin.gmrpcResponse"]);
                if (message[".fastcity.admin.gmrpcIsBroadcast"] != null && Object.hasOwnProperty.call(message, ".fastcity.admin.gmrpcIsBroadcast"))
                    writer.uint32(/* id 10202, wireType 0 =*/81616).bool(message[".fastcity.admin.gmrpcIsBroadcast"]);
                return writer;
            };

            /**
             * Decodes a FieldOptions message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.FieldOptions
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.FieldOptions} FieldOptions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FieldOptions.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.FieldOptions();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.ctype = reader.int32();
                        break;
                    case 2:
                        message.packed = reader.bool();
                        break;
                    case 6:
                        message.jstype = reader.int32();
                        break;
                    case 5:
                        message.lazy = reader.bool();
                        break;
                    case 3:
                        message.deprecated = reader.bool();
                        break;
                    case 10:
                        message.weak = reader.bool();
                        break;
                    case 999:
                        if (!(message.uninterpretedOption && message.uninterpretedOption.length))
                            message.uninterpretedOption = [];
                        message.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(reader, reader.uint32()));
                        break;
                    case 10201:
                        message[".fastcity.admin.gmrpcResponse"] = reader.string();
                        break;
                    case 10202:
                        message[".fastcity.admin.gmrpcIsBroadcast"] = reader.bool();
                        break;
                    case 10103:
                        message[".fastcity.connector.gmrpcResponse"] = reader.string();
                        break;
                    case 10101:
                        message[".fastcity.connector.launcher.gmrpcResponse"] = reader.string();
                        break;
                    case 10102:
                        message[".fastcity.connector.launcher.gmrpcIsBroadcast"] = reader.bool();
                        break;
                    case 10001:
                        message[".fastcity.courier.gmrpcResponse"] = reader.string();
                        break;
                    case 10002:
                        message[".fastcity.courier.gmrpcIsBroadcast"] = reader.bool();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a FieldOptions message.
             * @function verify
             * @memberof google.protobuf.FieldOptions
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            FieldOptions.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.ctype != null && message.hasOwnProperty("ctype"))
                    switch (message.ctype) {
                    default:
                        return "ctype: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
                if (message.packed != null && message.hasOwnProperty("packed"))
                    if (typeof message.packed !== "boolean")
                        return "packed: boolean expected";
                if (message.jstype != null && message.hasOwnProperty("jstype"))
                    switch (message.jstype) {
                    default:
                        return "jstype: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
                if (message.lazy != null && message.hasOwnProperty("lazy"))
                    if (typeof message.lazy !== "boolean")
                        return "lazy: boolean expected";
                if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                    if (typeof message.deprecated !== "boolean")
                        return "deprecated: boolean expected";
                if (message.weak != null && message.hasOwnProperty("weak"))
                    if (typeof message.weak !== "boolean")
                        return "weak: boolean expected";
                if (message.uninterpretedOption != null && message.hasOwnProperty("uninterpretedOption")) {
                    if (!Array.isArray(message.uninterpretedOption))
                        return "uninterpretedOption: array expected";
                    for (var i = 0; i < message.uninterpretedOption.length; ++i) {
                        var error = $root.google.protobuf.UninterpretedOption.verify(message.uninterpretedOption[i]);
                        if (error)
                            return "uninterpretedOption." + error;
                    }
                }
                if (message[".fastcity.admin.gmrpcResponse"] != null && message.hasOwnProperty(".fastcity.admin.gmrpcResponse"))
                    if (!$util.isString(message[".fastcity.admin.gmrpcResponse"]))
                        return ".fastcity.admin.gmrpcResponse: string expected";
                if (message[".fastcity.admin.gmrpcIsBroadcast"] != null && message.hasOwnProperty(".fastcity.admin.gmrpcIsBroadcast"))
                    if (typeof message[".fastcity.admin.gmrpcIsBroadcast"] !== "boolean")
                        return ".fastcity.admin.gmrpcIsBroadcast: boolean expected";
                if (message[".fastcity.connector.gmrpcResponse"] != null && message.hasOwnProperty(".fastcity.connector.gmrpcResponse"))
                    if (!$util.isString(message[".fastcity.connector.gmrpcResponse"]))
                        return ".fastcity.connector.gmrpcResponse: string expected";
                if (message[".fastcity.connector.launcher.gmrpcResponse"] != null && message.hasOwnProperty(".fastcity.connector.launcher.gmrpcResponse"))
                    if (!$util.isString(message[".fastcity.connector.launcher.gmrpcResponse"]))
                        return ".fastcity.connector.launcher.gmrpcResponse: string expected";
                if (message[".fastcity.connector.launcher.gmrpcIsBroadcast"] != null && message.hasOwnProperty(".fastcity.connector.launcher.gmrpcIsBroadcast"))
                    if (typeof message[".fastcity.connector.launcher.gmrpcIsBroadcast"] !== "boolean")
                        return ".fastcity.connector.launcher.gmrpcIsBroadcast: boolean expected";
                if (message[".fastcity.courier.gmrpcResponse"] != null && message.hasOwnProperty(".fastcity.courier.gmrpcResponse"))
                    if (!$util.isString(message[".fastcity.courier.gmrpcResponse"]))
                        return ".fastcity.courier.gmrpcResponse: string expected";
                if (message[".fastcity.courier.gmrpcIsBroadcast"] != null && message.hasOwnProperty(".fastcity.courier.gmrpcIsBroadcast"))
                    if (typeof message[".fastcity.courier.gmrpcIsBroadcast"] !== "boolean")
                        return ".fastcity.courier.gmrpcIsBroadcast: boolean expected";
                return null;
            };

            /**
             * Creates a FieldOptions message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.FieldOptions
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.FieldOptions} FieldOptions
             */
            FieldOptions.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.FieldOptions)
                    return object;
                var message = new $root.google.protobuf.FieldOptions();
                switch (object.ctype) {
                case "STRING":
                case 0:
                    message.ctype = 0;
                    break;
                case "CORD":
                case 1:
                    message.ctype = 1;
                    break;
                case "STRING_PIECE":
                case 2:
                    message.ctype = 2;
                    break;
                }
                if (object.packed != null)
                    message.packed = Boolean(object.packed);
                switch (object.jstype) {
                case "JS_NORMAL":
                case 0:
                    message.jstype = 0;
                    break;
                case "JS_STRING":
                case 1:
                    message.jstype = 1;
                    break;
                case "JS_NUMBER":
                case 2:
                    message.jstype = 2;
                    break;
                }
                if (object.lazy != null)
                    message.lazy = Boolean(object.lazy);
                if (object.deprecated != null)
                    message.deprecated = Boolean(object.deprecated);
                if (object.weak != null)
                    message.weak = Boolean(object.weak);
                if (object.uninterpretedOption) {
                    if (!Array.isArray(object.uninterpretedOption))
                        throw TypeError(".google.protobuf.FieldOptions.uninterpretedOption: array expected");
                    message.uninterpretedOption = [];
                    for (var i = 0; i < object.uninterpretedOption.length; ++i) {
                        if (typeof object.uninterpretedOption[i] !== "object")
                            throw TypeError(".google.protobuf.FieldOptions.uninterpretedOption: object expected");
                        message.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(object.uninterpretedOption[i]);
                    }
                }
                if (object[".fastcity.admin.gmrpcResponse"] != null)
                    message[".fastcity.admin.gmrpcResponse"] = String(object[".fastcity.admin.gmrpcResponse"]);
                if (object[".fastcity.admin.gmrpcIsBroadcast"] != null)
                    message[".fastcity.admin.gmrpcIsBroadcast"] = Boolean(object[".fastcity.admin.gmrpcIsBroadcast"]);
                if (object[".fastcity.connector.gmrpcResponse"] != null)
                    message[".fastcity.connector.gmrpcResponse"] = String(object[".fastcity.connector.gmrpcResponse"]);
                if (object[".fastcity.connector.launcher.gmrpcResponse"] != null)
                    message[".fastcity.connector.launcher.gmrpcResponse"] = String(object[".fastcity.connector.launcher.gmrpcResponse"]);
                if (object[".fastcity.connector.launcher.gmrpcIsBroadcast"] != null)
                    message[".fastcity.connector.launcher.gmrpcIsBroadcast"] = Boolean(object[".fastcity.connector.launcher.gmrpcIsBroadcast"]);
                if (object[".fastcity.courier.gmrpcResponse"] != null)
                    message[".fastcity.courier.gmrpcResponse"] = String(object[".fastcity.courier.gmrpcResponse"]);
                if (object[".fastcity.courier.gmrpcIsBroadcast"] != null)
                    message[".fastcity.courier.gmrpcIsBroadcast"] = Boolean(object[".fastcity.courier.gmrpcIsBroadcast"]);
                return message;
            };

            /**
             * Creates a plain object from a FieldOptions message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.FieldOptions
             * @static
             * @param {google.protobuf.FieldOptions} message FieldOptions
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FieldOptions.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.uninterpretedOption = [];
                if (options.defaults) {
                    object.ctype = options.enums === String ? "STRING" : 0;
                    object.packed = false;
                    object.deprecated = false;
                    object.lazy = false;
                    object.jstype = options.enums === String ? "JS_NORMAL" : 0;
                    object.weak = false;
                    object[".fastcity.courier.gmrpcResponse"] = "";
                    object[".fastcity.courier.gmrpcIsBroadcast"] = false;
                    object[".fastcity.connector.launcher.gmrpcResponse"] = "";
                    object[".fastcity.connector.launcher.gmrpcIsBroadcast"] = false;
                    object[".fastcity.connector.gmrpcResponse"] = "";
                    object[".fastcity.admin.gmrpcResponse"] = "";
                    object[".fastcity.admin.gmrpcIsBroadcast"] = false;
                }
                if (message.ctype != null && message.hasOwnProperty("ctype"))
                    object.ctype = options.enums === String ? $root.google.protobuf.FieldOptions.CType[message.ctype] : message.ctype;
                if (message.packed != null && message.hasOwnProperty("packed"))
                    object.packed = message.packed;
                if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                    object.deprecated = message.deprecated;
                if (message.lazy != null && message.hasOwnProperty("lazy"))
                    object.lazy = message.lazy;
                if (message.jstype != null && message.hasOwnProperty("jstype"))
                    object.jstype = options.enums === String ? $root.google.protobuf.FieldOptions.JSType[message.jstype] : message.jstype;
                if (message.weak != null && message.hasOwnProperty("weak"))
                    object.weak = message.weak;
                if (message.uninterpretedOption && message.uninterpretedOption.length) {
                    object.uninterpretedOption = [];
                    for (var j = 0; j < message.uninterpretedOption.length; ++j)
                        object.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(message.uninterpretedOption[j], options);
                }
                if (message[".fastcity.courier.gmrpcResponse"] != null && message.hasOwnProperty(".fastcity.courier.gmrpcResponse"))
                    object[".fastcity.courier.gmrpcResponse"] = message[".fastcity.courier.gmrpcResponse"];
                if (message[".fastcity.courier.gmrpcIsBroadcast"] != null && message.hasOwnProperty(".fastcity.courier.gmrpcIsBroadcast"))
                    object[".fastcity.courier.gmrpcIsBroadcast"] = message[".fastcity.courier.gmrpcIsBroadcast"];
                if (message[".fastcity.connector.launcher.gmrpcResponse"] != null && message.hasOwnProperty(".fastcity.connector.launcher.gmrpcResponse"))
                    object[".fastcity.connector.launcher.gmrpcResponse"] = message[".fastcity.connector.launcher.gmrpcResponse"];
                if (message[".fastcity.connector.launcher.gmrpcIsBroadcast"] != null && message.hasOwnProperty(".fastcity.connector.launcher.gmrpcIsBroadcast"))
                    object[".fastcity.connector.launcher.gmrpcIsBroadcast"] = message[".fastcity.connector.launcher.gmrpcIsBroadcast"];
                if (message[".fastcity.connector.gmrpcResponse"] != null && message.hasOwnProperty(".fastcity.connector.gmrpcResponse"))
                    object[".fastcity.connector.gmrpcResponse"] = message[".fastcity.connector.gmrpcResponse"];
                if (message[".fastcity.admin.gmrpcResponse"] != null && message.hasOwnProperty(".fastcity.admin.gmrpcResponse"))
                    object[".fastcity.admin.gmrpcResponse"] = message[".fastcity.admin.gmrpcResponse"];
                if (message[".fastcity.admin.gmrpcIsBroadcast"] != null && message.hasOwnProperty(".fastcity.admin.gmrpcIsBroadcast"))
                    object[".fastcity.admin.gmrpcIsBroadcast"] = message[".fastcity.admin.gmrpcIsBroadcast"];
                return object;
            };

            /**
             * Converts this FieldOptions to JSON.
             * @function toJSON
             * @memberof google.protobuf.FieldOptions
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FieldOptions.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * CType enum.
             * @name google.protobuf.FieldOptions.CType
             * @enum {number}
             * @property {number} STRING=0 STRING value
             * @property {number} CORD=1 CORD value
             * @property {number} STRING_PIECE=2 STRING_PIECE value
             */
            FieldOptions.CType = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "STRING"] = 0;
                values[valuesById[1] = "CORD"] = 1;
                values[valuesById[2] = "STRING_PIECE"] = 2;
                return values;
            })();

            /**
             * JSType enum.
             * @name google.protobuf.FieldOptions.JSType
             * @enum {number}
             * @property {number} JS_NORMAL=0 JS_NORMAL value
             * @property {number} JS_STRING=1 JS_STRING value
             * @property {number} JS_NUMBER=2 JS_NUMBER value
             */
            FieldOptions.JSType = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "JS_NORMAL"] = 0;
                values[valuesById[1] = "JS_STRING"] = 1;
                values[valuesById[2] = "JS_NUMBER"] = 2;
                return values;
            })();

            return FieldOptions;
        })();

        protobuf.OneofOptions = (function() {

            /**
             * Properties of an OneofOptions.
             * @memberof google.protobuf
             * @interface IOneofOptions
             * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] OneofOptions uninterpretedOption
             */

            /**
             * Constructs a new OneofOptions.
             * @memberof google.protobuf
             * @classdesc Represents an OneofOptions.
             * @implements IOneofOptions
             * @constructor
             * @param {google.protobuf.IOneofOptions=} [properties] Properties to set
             */
            function OneofOptions(properties) {
                this.uninterpretedOption = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * OneofOptions uninterpretedOption.
             * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
             * @memberof google.protobuf.OneofOptions
             * @instance
             */
            OneofOptions.prototype.uninterpretedOption = $util.emptyArray;

            /**
             * Creates a new OneofOptions instance using the specified properties.
             * @function create
             * @memberof google.protobuf.OneofOptions
             * @static
             * @param {google.protobuf.IOneofOptions=} [properties] Properties to set
             * @returns {google.protobuf.OneofOptions} OneofOptions instance
             */
            OneofOptions.create = function create(properties) {
                return new OneofOptions(properties);
            };

            /**
             * Encodes the specified OneofOptions message. Does not implicitly {@link google.protobuf.OneofOptions.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.OneofOptions
             * @static
             * @param {google.protobuf.IOneofOptions} message OneofOptions message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            OneofOptions.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.uninterpretedOption != null && message.uninterpretedOption.length)
                    for (var i = 0; i < message.uninterpretedOption.length; ++i)
                        $root.google.protobuf.UninterpretedOption.encode(message.uninterpretedOption[i], writer.uint32(/* id 999, wireType 2 =*/7994).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes an OneofOptions message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.OneofOptions
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.OneofOptions} OneofOptions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            OneofOptions.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.OneofOptions();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 999:
                        if (!(message.uninterpretedOption && message.uninterpretedOption.length))
                            message.uninterpretedOption = [];
                        message.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an OneofOptions message.
             * @function verify
             * @memberof google.protobuf.OneofOptions
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            OneofOptions.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.uninterpretedOption != null && message.hasOwnProperty("uninterpretedOption")) {
                    if (!Array.isArray(message.uninterpretedOption))
                        return "uninterpretedOption: array expected";
                    for (var i = 0; i < message.uninterpretedOption.length; ++i) {
                        var error = $root.google.protobuf.UninterpretedOption.verify(message.uninterpretedOption[i]);
                        if (error)
                            return "uninterpretedOption." + error;
                    }
                }
                return null;
            };

            /**
             * Creates an OneofOptions message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.OneofOptions
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.OneofOptions} OneofOptions
             */
            OneofOptions.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.OneofOptions)
                    return object;
                var message = new $root.google.protobuf.OneofOptions();
                if (object.uninterpretedOption) {
                    if (!Array.isArray(object.uninterpretedOption))
                        throw TypeError(".google.protobuf.OneofOptions.uninterpretedOption: array expected");
                    message.uninterpretedOption = [];
                    for (var i = 0; i < object.uninterpretedOption.length; ++i) {
                        if (typeof object.uninterpretedOption[i] !== "object")
                            throw TypeError(".google.protobuf.OneofOptions.uninterpretedOption: object expected");
                        message.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(object.uninterpretedOption[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from an OneofOptions message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.OneofOptions
             * @static
             * @param {google.protobuf.OneofOptions} message OneofOptions
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            OneofOptions.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.uninterpretedOption = [];
                if (message.uninterpretedOption && message.uninterpretedOption.length) {
                    object.uninterpretedOption = [];
                    for (var j = 0; j < message.uninterpretedOption.length; ++j)
                        object.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(message.uninterpretedOption[j], options);
                }
                return object;
            };

            /**
             * Converts this OneofOptions to JSON.
             * @function toJSON
             * @memberof google.protobuf.OneofOptions
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            OneofOptions.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return OneofOptions;
        })();

        protobuf.EnumOptions = (function() {

            /**
             * Properties of an EnumOptions.
             * @memberof google.protobuf
             * @interface IEnumOptions
             * @property {boolean|null} [allowAlias] EnumOptions allowAlias
             * @property {boolean|null} [deprecated] EnumOptions deprecated
             * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] EnumOptions uninterpretedOption
             */

            /**
             * Constructs a new EnumOptions.
             * @memberof google.protobuf
             * @classdesc Represents an EnumOptions.
             * @implements IEnumOptions
             * @constructor
             * @param {google.protobuf.IEnumOptions=} [properties] Properties to set
             */
            function EnumOptions(properties) {
                this.uninterpretedOption = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * EnumOptions allowAlias.
             * @member {boolean} allowAlias
             * @memberof google.protobuf.EnumOptions
             * @instance
             */
            EnumOptions.prototype.allowAlias = false;

            /**
             * EnumOptions deprecated.
             * @member {boolean} deprecated
             * @memberof google.protobuf.EnumOptions
             * @instance
             */
            EnumOptions.prototype.deprecated = false;

            /**
             * EnumOptions uninterpretedOption.
             * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
             * @memberof google.protobuf.EnumOptions
             * @instance
             */
            EnumOptions.prototype.uninterpretedOption = $util.emptyArray;

            /**
             * Creates a new EnumOptions instance using the specified properties.
             * @function create
             * @memberof google.protobuf.EnumOptions
             * @static
             * @param {google.protobuf.IEnumOptions=} [properties] Properties to set
             * @returns {google.protobuf.EnumOptions} EnumOptions instance
             */
            EnumOptions.create = function create(properties) {
                return new EnumOptions(properties);
            };

            /**
             * Encodes the specified EnumOptions message. Does not implicitly {@link google.protobuf.EnumOptions.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.EnumOptions
             * @static
             * @param {google.protobuf.IEnumOptions} message EnumOptions message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EnumOptions.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.allowAlias != null && Object.hasOwnProperty.call(message, "allowAlias"))
                    writer.uint32(/* id 2, wireType 0 =*/16).bool(message.allowAlias);
                if (message.deprecated != null && Object.hasOwnProperty.call(message, "deprecated"))
                    writer.uint32(/* id 3, wireType 0 =*/24).bool(message.deprecated);
                if (message.uninterpretedOption != null && message.uninterpretedOption.length)
                    for (var i = 0; i < message.uninterpretedOption.length; ++i)
                        $root.google.protobuf.UninterpretedOption.encode(message.uninterpretedOption[i], writer.uint32(/* id 999, wireType 2 =*/7994).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes an EnumOptions message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.EnumOptions
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.EnumOptions} EnumOptions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EnumOptions.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.EnumOptions();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 2:
                        message.allowAlias = reader.bool();
                        break;
                    case 3:
                        message.deprecated = reader.bool();
                        break;
                    case 999:
                        if (!(message.uninterpretedOption && message.uninterpretedOption.length))
                            message.uninterpretedOption = [];
                        message.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an EnumOptions message.
             * @function verify
             * @memberof google.protobuf.EnumOptions
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            EnumOptions.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.allowAlias != null && message.hasOwnProperty("allowAlias"))
                    if (typeof message.allowAlias !== "boolean")
                        return "allowAlias: boolean expected";
                if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                    if (typeof message.deprecated !== "boolean")
                        return "deprecated: boolean expected";
                if (message.uninterpretedOption != null && message.hasOwnProperty("uninterpretedOption")) {
                    if (!Array.isArray(message.uninterpretedOption))
                        return "uninterpretedOption: array expected";
                    for (var i = 0; i < message.uninterpretedOption.length; ++i) {
                        var error = $root.google.protobuf.UninterpretedOption.verify(message.uninterpretedOption[i]);
                        if (error)
                            return "uninterpretedOption." + error;
                    }
                }
                return null;
            };

            /**
             * Creates an EnumOptions message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.EnumOptions
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.EnumOptions} EnumOptions
             */
            EnumOptions.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.EnumOptions)
                    return object;
                var message = new $root.google.protobuf.EnumOptions();
                if (object.allowAlias != null)
                    message.allowAlias = Boolean(object.allowAlias);
                if (object.deprecated != null)
                    message.deprecated = Boolean(object.deprecated);
                if (object.uninterpretedOption) {
                    if (!Array.isArray(object.uninterpretedOption))
                        throw TypeError(".google.protobuf.EnumOptions.uninterpretedOption: array expected");
                    message.uninterpretedOption = [];
                    for (var i = 0; i < object.uninterpretedOption.length; ++i) {
                        if (typeof object.uninterpretedOption[i] !== "object")
                            throw TypeError(".google.protobuf.EnumOptions.uninterpretedOption: object expected");
                        message.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(object.uninterpretedOption[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from an EnumOptions message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.EnumOptions
             * @static
             * @param {google.protobuf.EnumOptions} message EnumOptions
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            EnumOptions.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.uninterpretedOption = [];
                if (options.defaults) {
                    object.allowAlias = false;
                    object.deprecated = false;
                }
                if (message.allowAlias != null && message.hasOwnProperty("allowAlias"))
                    object.allowAlias = message.allowAlias;
                if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                    object.deprecated = message.deprecated;
                if (message.uninterpretedOption && message.uninterpretedOption.length) {
                    object.uninterpretedOption = [];
                    for (var j = 0; j < message.uninterpretedOption.length; ++j)
                        object.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(message.uninterpretedOption[j], options);
                }
                return object;
            };

            /**
             * Converts this EnumOptions to JSON.
             * @function toJSON
             * @memberof google.protobuf.EnumOptions
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            EnumOptions.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return EnumOptions;
        })();

        protobuf.EnumValueOptions = (function() {

            /**
             * Properties of an EnumValueOptions.
             * @memberof google.protobuf
             * @interface IEnumValueOptions
             * @property {boolean|null} [deprecated] EnumValueOptions deprecated
             * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] EnumValueOptions uninterpretedOption
             */

            /**
             * Constructs a new EnumValueOptions.
             * @memberof google.protobuf
             * @classdesc Represents an EnumValueOptions.
             * @implements IEnumValueOptions
             * @constructor
             * @param {google.protobuf.IEnumValueOptions=} [properties] Properties to set
             */
            function EnumValueOptions(properties) {
                this.uninterpretedOption = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * EnumValueOptions deprecated.
             * @member {boolean} deprecated
             * @memberof google.protobuf.EnumValueOptions
             * @instance
             */
            EnumValueOptions.prototype.deprecated = false;

            /**
             * EnumValueOptions uninterpretedOption.
             * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
             * @memberof google.protobuf.EnumValueOptions
             * @instance
             */
            EnumValueOptions.prototype.uninterpretedOption = $util.emptyArray;

            /**
             * Creates a new EnumValueOptions instance using the specified properties.
             * @function create
             * @memberof google.protobuf.EnumValueOptions
             * @static
             * @param {google.protobuf.IEnumValueOptions=} [properties] Properties to set
             * @returns {google.protobuf.EnumValueOptions} EnumValueOptions instance
             */
            EnumValueOptions.create = function create(properties) {
                return new EnumValueOptions(properties);
            };

            /**
             * Encodes the specified EnumValueOptions message. Does not implicitly {@link google.protobuf.EnumValueOptions.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.EnumValueOptions
             * @static
             * @param {google.protobuf.IEnumValueOptions} message EnumValueOptions message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EnumValueOptions.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.deprecated != null && Object.hasOwnProperty.call(message, "deprecated"))
                    writer.uint32(/* id 1, wireType 0 =*/8).bool(message.deprecated);
                if (message.uninterpretedOption != null && message.uninterpretedOption.length)
                    for (var i = 0; i < message.uninterpretedOption.length; ++i)
                        $root.google.protobuf.UninterpretedOption.encode(message.uninterpretedOption[i], writer.uint32(/* id 999, wireType 2 =*/7994).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes an EnumValueOptions message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.EnumValueOptions
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.EnumValueOptions} EnumValueOptions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EnumValueOptions.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.EnumValueOptions();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.deprecated = reader.bool();
                        break;
                    case 999:
                        if (!(message.uninterpretedOption && message.uninterpretedOption.length))
                            message.uninterpretedOption = [];
                        message.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an EnumValueOptions message.
             * @function verify
             * @memberof google.protobuf.EnumValueOptions
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            EnumValueOptions.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                    if (typeof message.deprecated !== "boolean")
                        return "deprecated: boolean expected";
                if (message.uninterpretedOption != null && message.hasOwnProperty("uninterpretedOption")) {
                    if (!Array.isArray(message.uninterpretedOption))
                        return "uninterpretedOption: array expected";
                    for (var i = 0; i < message.uninterpretedOption.length; ++i) {
                        var error = $root.google.protobuf.UninterpretedOption.verify(message.uninterpretedOption[i]);
                        if (error)
                            return "uninterpretedOption." + error;
                    }
                }
                return null;
            };

            /**
             * Creates an EnumValueOptions message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.EnumValueOptions
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.EnumValueOptions} EnumValueOptions
             */
            EnumValueOptions.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.EnumValueOptions)
                    return object;
                var message = new $root.google.protobuf.EnumValueOptions();
                if (object.deprecated != null)
                    message.deprecated = Boolean(object.deprecated);
                if (object.uninterpretedOption) {
                    if (!Array.isArray(object.uninterpretedOption))
                        throw TypeError(".google.protobuf.EnumValueOptions.uninterpretedOption: array expected");
                    message.uninterpretedOption = [];
                    for (var i = 0; i < object.uninterpretedOption.length; ++i) {
                        if (typeof object.uninterpretedOption[i] !== "object")
                            throw TypeError(".google.protobuf.EnumValueOptions.uninterpretedOption: object expected");
                        message.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(object.uninterpretedOption[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from an EnumValueOptions message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.EnumValueOptions
             * @static
             * @param {google.protobuf.EnumValueOptions} message EnumValueOptions
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            EnumValueOptions.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.uninterpretedOption = [];
                if (options.defaults)
                    object.deprecated = false;
                if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                    object.deprecated = message.deprecated;
                if (message.uninterpretedOption && message.uninterpretedOption.length) {
                    object.uninterpretedOption = [];
                    for (var j = 0; j < message.uninterpretedOption.length; ++j)
                        object.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(message.uninterpretedOption[j], options);
                }
                return object;
            };

            /**
             * Converts this EnumValueOptions to JSON.
             * @function toJSON
             * @memberof google.protobuf.EnumValueOptions
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            EnumValueOptions.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return EnumValueOptions;
        })();

        protobuf.ServiceOptions = (function() {

            /**
             * Properties of a ServiceOptions.
             * @memberof google.protobuf
             * @interface IServiceOptions
             * @property {boolean|null} [deprecated] ServiceOptions deprecated
             * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] ServiceOptions uninterpretedOption
             */

            /**
             * Constructs a new ServiceOptions.
             * @memberof google.protobuf
             * @classdesc Represents a ServiceOptions.
             * @implements IServiceOptions
             * @constructor
             * @param {google.protobuf.IServiceOptions=} [properties] Properties to set
             */
            function ServiceOptions(properties) {
                this.uninterpretedOption = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ServiceOptions deprecated.
             * @member {boolean} deprecated
             * @memberof google.protobuf.ServiceOptions
             * @instance
             */
            ServiceOptions.prototype.deprecated = false;

            /**
             * ServiceOptions uninterpretedOption.
             * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
             * @memberof google.protobuf.ServiceOptions
             * @instance
             */
            ServiceOptions.prototype.uninterpretedOption = $util.emptyArray;

            /**
             * Creates a new ServiceOptions instance using the specified properties.
             * @function create
             * @memberof google.protobuf.ServiceOptions
             * @static
             * @param {google.protobuf.IServiceOptions=} [properties] Properties to set
             * @returns {google.protobuf.ServiceOptions} ServiceOptions instance
             */
            ServiceOptions.create = function create(properties) {
                return new ServiceOptions(properties);
            };

            /**
             * Encodes the specified ServiceOptions message. Does not implicitly {@link google.protobuf.ServiceOptions.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.ServiceOptions
             * @static
             * @param {google.protobuf.IServiceOptions} message ServiceOptions message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ServiceOptions.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.deprecated != null && Object.hasOwnProperty.call(message, "deprecated"))
                    writer.uint32(/* id 33, wireType 0 =*/264).bool(message.deprecated);
                if (message.uninterpretedOption != null && message.uninterpretedOption.length)
                    for (var i = 0; i < message.uninterpretedOption.length; ++i)
                        $root.google.protobuf.UninterpretedOption.encode(message.uninterpretedOption[i], writer.uint32(/* id 999, wireType 2 =*/7994).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a ServiceOptions message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.ServiceOptions
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.ServiceOptions} ServiceOptions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ServiceOptions.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.ServiceOptions();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 33:
                        message.deprecated = reader.bool();
                        break;
                    case 999:
                        if (!(message.uninterpretedOption && message.uninterpretedOption.length))
                            message.uninterpretedOption = [];
                        message.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a ServiceOptions message.
             * @function verify
             * @memberof google.protobuf.ServiceOptions
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ServiceOptions.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                    if (typeof message.deprecated !== "boolean")
                        return "deprecated: boolean expected";
                if (message.uninterpretedOption != null && message.hasOwnProperty("uninterpretedOption")) {
                    if (!Array.isArray(message.uninterpretedOption))
                        return "uninterpretedOption: array expected";
                    for (var i = 0; i < message.uninterpretedOption.length; ++i) {
                        var error = $root.google.protobuf.UninterpretedOption.verify(message.uninterpretedOption[i]);
                        if (error)
                            return "uninterpretedOption." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a ServiceOptions message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.ServiceOptions
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.ServiceOptions} ServiceOptions
             */
            ServiceOptions.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.ServiceOptions)
                    return object;
                var message = new $root.google.protobuf.ServiceOptions();
                if (object.deprecated != null)
                    message.deprecated = Boolean(object.deprecated);
                if (object.uninterpretedOption) {
                    if (!Array.isArray(object.uninterpretedOption))
                        throw TypeError(".google.protobuf.ServiceOptions.uninterpretedOption: array expected");
                    message.uninterpretedOption = [];
                    for (var i = 0; i < object.uninterpretedOption.length; ++i) {
                        if (typeof object.uninterpretedOption[i] !== "object")
                            throw TypeError(".google.protobuf.ServiceOptions.uninterpretedOption: object expected");
                        message.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(object.uninterpretedOption[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a ServiceOptions message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.ServiceOptions
             * @static
             * @param {google.protobuf.ServiceOptions} message ServiceOptions
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ServiceOptions.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.uninterpretedOption = [];
                if (options.defaults)
                    object.deprecated = false;
                if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                    object.deprecated = message.deprecated;
                if (message.uninterpretedOption && message.uninterpretedOption.length) {
                    object.uninterpretedOption = [];
                    for (var j = 0; j < message.uninterpretedOption.length; ++j)
                        object.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(message.uninterpretedOption[j], options);
                }
                return object;
            };

            /**
             * Converts this ServiceOptions to JSON.
             * @function toJSON
             * @memberof google.protobuf.ServiceOptions
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ServiceOptions.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return ServiceOptions;
        })();

        protobuf.MethodOptions = (function() {

            /**
             * Properties of a MethodOptions.
             * @memberof google.protobuf
             * @interface IMethodOptions
             * @property {boolean|null} [deprecated] MethodOptions deprecated
             * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] MethodOptions uninterpretedOption
             */

            /**
             * Constructs a new MethodOptions.
             * @memberof google.protobuf
             * @classdesc Represents a MethodOptions.
             * @implements IMethodOptions
             * @constructor
             * @param {google.protobuf.IMethodOptions=} [properties] Properties to set
             */
            function MethodOptions(properties) {
                this.uninterpretedOption = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * MethodOptions deprecated.
             * @member {boolean} deprecated
             * @memberof google.protobuf.MethodOptions
             * @instance
             */
            MethodOptions.prototype.deprecated = false;

            /**
             * MethodOptions uninterpretedOption.
             * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
             * @memberof google.protobuf.MethodOptions
             * @instance
             */
            MethodOptions.prototype.uninterpretedOption = $util.emptyArray;

            /**
             * Creates a new MethodOptions instance using the specified properties.
             * @function create
             * @memberof google.protobuf.MethodOptions
             * @static
             * @param {google.protobuf.IMethodOptions=} [properties] Properties to set
             * @returns {google.protobuf.MethodOptions} MethodOptions instance
             */
            MethodOptions.create = function create(properties) {
                return new MethodOptions(properties);
            };

            /**
             * Encodes the specified MethodOptions message. Does not implicitly {@link google.protobuf.MethodOptions.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.MethodOptions
             * @static
             * @param {google.protobuf.IMethodOptions} message MethodOptions message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MethodOptions.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.deprecated != null && Object.hasOwnProperty.call(message, "deprecated"))
                    writer.uint32(/* id 33, wireType 0 =*/264).bool(message.deprecated);
                if (message.uninterpretedOption != null && message.uninterpretedOption.length)
                    for (var i = 0; i < message.uninterpretedOption.length; ++i)
                        $root.google.protobuf.UninterpretedOption.encode(message.uninterpretedOption[i], writer.uint32(/* id 999, wireType 2 =*/7994).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a MethodOptions message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.MethodOptions
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.MethodOptions} MethodOptions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MethodOptions.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.MethodOptions();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 33:
                        message.deprecated = reader.bool();
                        break;
                    case 999:
                        if (!(message.uninterpretedOption && message.uninterpretedOption.length))
                            message.uninterpretedOption = [];
                        message.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a MethodOptions message.
             * @function verify
             * @memberof google.protobuf.MethodOptions
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            MethodOptions.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                    if (typeof message.deprecated !== "boolean")
                        return "deprecated: boolean expected";
                if (message.uninterpretedOption != null && message.hasOwnProperty("uninterpretedOption")) {
                    if (!Array.isArray(message.uninterpretedOption))
                        return "uninterpretedOption: array expected";
                    for (var i = 0; i < message.uninterpretedOption.length; ++i) {
                        var error = $root.google.protobuf.UninterpretedOption.verify(message.uninterpretedOption[i]);
                        if (error)
                            return "uninterpretedOption." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a MethodOptions message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.MethodOptions
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.MethodOptions} MethodOptions
             */
            MethodOptions.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.MethodOptions)
                    return object;
                var message = new $root.google.protobuf.MethodOptions();
                if (object.deprecated != null)
                    message.deprecated = Boolean(object.deprecated);
                if (object.uninterpretedOption) {
                    if (!Array.isArray(object.uninterpretedOption))
                        throw TypeError(".google.protobuf.MethodOptions.uninterpretedOption: array expected");
                    message.uninterpretedOption = [];
                    for (var i = 0; i < object.uninterpretedOption.length; ++i) {
                        if (typeof object.uninterpretedOption[i] !== "object")
                            throw TypeError(".google.protobuf.MethodOptions.uninterpretedOption: object expected");
                        message.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(object.uninterpretedOption[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a MethodOptions message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.MethodOptions
             * @static
             * @param {google.protobuf.MethodOptions} message MethodOptions
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            MethodOptions.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.uninterpretedOption = [];
                if (options.defaults)
                    object.deprecated = false;
                if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                    object.deprecated = message.deprecated;
                if (message.uninterpretedOption && message.uninterpretedOption.length) {
                    object.uninterpretedOption = [];
                    for (var j = 0; j < message.uninterpretedOption.length; ++j)
                        object.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(message.uninterpretedOption[j], options);
                }
                return object;
            };

            /**
             * Converts this MethodOptions to JSON.
             * @function toJSON
             * @memberof google.protobuf.MethodOptions
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            MethodOptions.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return MethodOptions;
        })();

        protobuf.UninterpretedOption = (function() {

            /**
             * Properties of an UninterpretedOption.
             * @memberof google.protobuf
             * @interface IUninterpretedOption
             * @property {Array.<google.protobuf.UninterpretedOption.INamePart>|null} [name] UninterpretedOption name
             * @property {string|null} [identifierValue] UninterpretedOption identifierValue
             * @property {number|Long|null} [positiveIntValue] UninterpretedOption positiveIntValue
             * @property {number|Long|null} [negativeIntValue] UninterpretedOption negativeIntValue
             * @property {number|null} [doubleValue] UninterpretedOption doubleValue
             * @property {Uint8Array|null} [stringValue] UninterpretedOption stringValue
             * @property {string|null} [aggregateValue] UninterpretedOption aggregateValue
             */

            /**
             * Constructs a new UninterpretedOption.
             * @memberof google.protobuf
             * @classdesc Represents an UninterpretedOption.
             * @implements IUninterpretedOption
             * @constructor
             * @param {google.protobuf.IUninterpretedOption=} [properties] Properties to set
             */
            function UninterpretedOption(properties) {
                this.name = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * UninterpretedOption name.
             * @member {Array.<google.protobuf.UninterpretedOption.INamePart>} name
             * @memberof google.protobuf.UninterpretedOption
             * @instance
             */
            UninterpretedOption.prototype.name = $util.emptyArray;

            /**
             * UninterpretedOption identifierValue.
             * @member {string} identifierValue
             * @memberof google.protobuf.UninterpretedOption
             * @instance
             */
            UninterpretedOption.prototype.identifierValue = "";

            /**
             * UninterpretedOption positiveIntValue.
             * @member {number|Long} positiveIntValue
             * @memberof google.protobuf.UninterpretedOption
             * @instance
             */
            UninterpretedOption.prototype.positiveIntValue = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * UninterpretedOption negativeIntValue.
             * @member {number|Long} negativeIntValue
             * @memberof google.protobuf.UninterpretedOption
             * @instance
             */
            UninterpretedOption.prototype.negativeIntValue = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * UninterpretedOption doubleValue.
             * @member {number} doubleValue
             * @memberof google.protobuf.UninterpretedOption
             * @instance
             */
            UninterpretedOption.prototype.doubleValue = 0;

            /**
             * UninterpretedOption stringValue.
             * @member {Uint8Array} stringValue
             * @memberof google.protobuf.UninterpretedOption
             * @instance
             */
            UninterpretedOption.prototype.stringValue = $util.newBuffer([]);

            /**
             * UninterpretedOption aggregateValue.
             * @member {string} aggregateValue
             * @memberof google.protobuf.UninterpretedOption
             * @instance
             */
            UninterpretedOption.prototype.aggregateValue = "";

            /**
             * Creates a new UninterpretedOption instance using the specified properties.
             * @function create
             * @memberof google.protobuf.UninterpretedOption
             * @static
             * @param {google.protobuf.IUninterpretedOption=} [properties] Properties to set
             * @returns {google.protobuf.UninterpretedOption} UninterpretedOption instance
             */
            UninterpretedOption.create = function create(properties) {
                return new UninterpretedOption(properties);
            };

            /**
             * Encodes the specified UninterpretedOption message. Does not implicitly {@link google.protobuf.UninterpretedOption.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.UninterpretedOption
             * @static
             * @param {google.protobuf.IUninterpretedOption} message UninterpretedOption message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UninterpretedOption.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.name != null && message.name.length)
                    for (var i = 0; i < message.name.length; ++i)
                        $root.google.protobuf.UninterpretedOption.NamePart.encode(message.name[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.identifierValue != null && Object.hasOwnProperty.call(message, "identifierValue"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.identifierValue);
                if (message.positiveIntValue != null && Object.hasOwnProperty.call(message, "positiveIntValue"))
                    writer.uint32(/* id 4, wireType 0 =*/32).uint64(message.positiveIntValue);
                if (message.negativeIntValue != null && Object.hasOwnProperty.call(message, "negativeIntValue"))
                    writer.uint32(/* id 5, wireType 0 =*/40).int64(message.negativeIntValue);
                if (message.doubleValue != null && Object.hasOwnProperty.call(message, "doubleValue"))
                    writer.uint32(/* id 6, wireType 1 =*/49).double(message.doubleValue);
                if (message.stringValue != null && Object.hasOwnProperty.call(message, "stringValue"))
                    writer.uint32(/* id 7, wireType 2 =*/58).bytes(message.stringValue);
                if (message.aggregateValue != null && Object.hasOwnProperty.call(message, "aggregateValue"))
                    writer.uint32(/* id 8, wireType 2 =*/66).string(message.aggregateValue);
                return writer;
            };

            /**
             * Decodes an UninterpretedOption message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.UninterpretedOption
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.UninterpretedOption} UninterpretedOption
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UninterpretedOption.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.UninterpretedOption();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 2:
                        if (!(message.name && message.name.length))
                            message.name = [];
                        message.name.push($root.google.protobuf.UninterpretedOption.NamePart.decode(reader, reader.uint32()));
                        break;
                    case 3:
                        message.identifierValue = reader.string();
                        break;
                    case 4:
                        message.positiveIntValue = reader.uint64();
                        break;
                    case 5:
                        message.negativeIntValue = reader.int64();
                        break;
                    case 6:
                        message.doubleValue = reader.double();
                        break;
                    case 7:
                        message.stringValue = reader.bytes();
                        break;
                    case 8:
                        message.aggregateValue = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an UninterpretedOption message.
             * @function verify
             * @memberof google.protobuf.UninterpretedOption
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            UninterpretedOption.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name")) {
                    if (!Array.isArray(message.name))
                        return "name: array expected";
                    for (var i = 0; i < message.name.length; ++i) {
                        var error = $root.google.protobuf.UninterpretedOption.NamePart.verify(message.name[i]);
                        if (error)
                            return "name." + error;
                    }
                }
                if (message.identifierValue != null && message.hasOwnProperty("identifierValue"))
                    if (!$util.isString(message.identifierValue))
                        return "identifierValue: string expected";
                if (message.positiveIntValue != null && message.hasOwnProperty("positiveIntValue"))
                    if (!$util.isInteger(message.positiveIntValue) && !(message.positiveIntValue && $util.isInteger(message.positiveIntValue.low) && $util.isInteger(message.positiveIntValue.high)))
                        return "positiveIntValue: integer|Long expected";
                if (message.negativeIntValue != null && message.hasOwnProperty("negativeIntValue"))
                    if (!$util.isInteger(message.negativeIntValue) && !(message.negativeIntValue && $util.isInteger(message.negativeIntValue.low) && $util.isInteger(message.negativeIntValue.high)))
                        return "negativeIntValue: integer|Long expected";
                if (message.doubleValue != null && message.hasOwnProperty("doubleValue"))
                    if (typeof message.doubleValue !== "number")
                        return "doubleValue: number expected";
                if (message.stringValue != null && message.hasOwnProperty("stringValue"))
                    if (!(message.stringValue && typeof message.stringValue.length === "number" || $util.isString(message.stringValue)))
                        return "stringValue: buffer expected";
                if (message.aggregateValue != null && message.hasOwnProperty("aggregateValue"))
                    if (!$util.isString(message.aggregateValue))
                        return "aggregateValue: string expected";
                return null;
            };

            /**
             * Creates an UninterpretedOption message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.UninterpretedOption
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.UninterpretedOption} UninterpretedOption
             */
            UninterpretedOption.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.UninterpretedOption)
                    return object;
                var message = new $root.google.protobuf.UninterpretedOption();
                if (object.name) {
                    if (!Array.isArray(object.name))
                        throw TypeError(".google.protobuf.UninterpretedOption.name: array expected");
                    message.name = [];
                    for (var i = 0; i < object.name.length; ++i) {
                        if (typeof object.name[i] !== "object")
                            throw TypeError(".google.protobuf.UninterpretedOption.name: object expected");
                        message.name[i] = $root.google.protobuf.UninterpretedOption.NamePart.fromObject(object.name[i]);
                    }
                }
                if (object.identifierValue != null)
                    message.identifierValue = String(object.identifierValue);
                if (object.positiveIntValue != null)
                    if ($util.Long)
                        (message.positiveIntValue = $util.Long.fromValue(object.positiveIntValue)).unsigned = true;
                    else if (typeof object.positiveIntValue === "string")
                        message.positiveIntValue = parseInt(object.positiveIntValue, 10);
                    else if (typeof object.positiveIntValue === "number")
                        message.positiveIntValue = object.positiveIntValue;
                    else if (typeof object.positiveIntValue === "object")
                        message.positiveIntValue = new $util.LongBits(object.positiveIntValue.low >>> 0, object.positiveIntValue.high >>> 0).toNumber(true);
                if (object.negativeIntValue != null)
                    if ($util.Long)
                        (message.negativeIntValue = $util.Long.fromValue(object.negativeIntValue)).unsigned = false;
                    else if (typeof object.negativeIntValue === "string")
                        message.negativeIntValue = parseInt(object.negativeIntValue, 10);
                    else if (typeof object.negativeIntValue === "number")
                        message.negativeIntValue = object.negativeIntValue;
                    else if (typeof object.negativeIntValue === "object")
                        message.negativeIntValue = new $util.LongBits(object.negativeIntValue.low >>> 0, object.negativeIntValue.high >>> 0).toNumber();
                if (object.doubleValue != null)
                    message.doubleValue = Number(object.doubleValue);
                if (object.stringValue != null)
                    if (typeof object.stringValue === "string")
                        $util.base64.decode(object.stringValue, message.stringValue = $util.newBuffer($util.base64.length(object.stringValue)), 0);
                    else if (object.stringValue.length)
                        message.stringValue = object.stringValue;
                if (object.aggregateValue != null)
                    message.aggregateValue = String(object.aggregateValue);
                return message;
            };

            /**
             * Creates a plain object from an UninterpretedOption message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.UninterpretedOption
             * @static
             * @param {google.protobuf.UninterpretedOption} message UninterpretedOption
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UninterpretedOption.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.name = [];
                if (options.defaults) {
                    object.identifierValue = "";
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.positiveIntValue = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.positiveIntValue = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.negativeIntValue = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.negativeIntValue = options.longs === String ? "0" : 0;
                    object.doubleValue = 0;
                    if (options.bytes === String)
                        object.stringValue = "";
                    else {
                        object.stringValue = [];
                        if (options.bytes !== Array)
                            object.stringValue = $util.newBuffer(object.stringValue);
                    }
                    object.aggregateValue = "";
                }
                if (message.name && message.name.length) {
                    object.name = [];
                    for (var j = 0; j < message.name.length; ++j)
                        object.name[j] = $root.google.protobuf.UninterpretedOption.NamePart.toObject(message.name[j], options);
                }
                if (message.identifierValue != null && message.hasOwnProperty("identifierValue"))
                    object.identifierValue = message.identifierValue;
                if (message.positiveIntValue != null && message.hasOwnProperty("positiveIntValue"))
                    if (typeof message.positiveIntValue === "number")
                        object.positiveIntValue = options.longs === String ? String(message.positiveIntValue) : message.positiveIntValue;
                    else
                        object.positiveIntValue = options.longs === String ? $util.Long.prototype.toString.call(message.positiveIntValue) : options.longs === Number ? new $util.LongBits(message.positiveIntValue.low >>> 0, message.positiveIntValue.high >>> 0).toNumber(true) : message.positiveIntValue;
                if (message.negativeIntValue != null && message.hasOwnProperty("negativeIntValue"))
                    if (typeof message.negativeIntValue === "number")
                        object.negativeIntValue = options.longs === String ? String(message.negativeIntValue) : message.negativeIntValue;
                    else
                        object.negativeIntValue = options.longs === String ? $util.Long.prototype.toString.call(message.negativeIntValue) : options.longs === Number ? new $util.LongBits(message.negativeIntValue.low >>> 0, message.negativeIntValue.high >>> 0).toNumber() : message.negativeIntValue;
                if (message.doubleValue != null && message.hasOwnProperty("doubleValue"))
                    object.doubleValue = options.json && !isFinite(message.doubleValue) ? String(message.doubleValue) : message.doubleValue;
                if (message.stringValue != null && message.hasOwnProperty("stringValue"))
                    object.stringValue = options.bytes === String ? $util.base64.encode(message.stringValue, 0, message.stringValue.length) : options.bytes === Array ? Array.prototype.slice.call(message.stringValue) : message.stringValue;
                if (message.aggregateValue != null && message.hasOwnProperty("aggregateValue"))
                    object.aggregateValue = message.aggregateValue;
                return object;
            };

            /**
             * Converts this UninterpretedOption to JSON.
             * @function toJSON
             * @memberof google.protobuf.UninterpretedOption
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UninterpretedOption.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            UninterpretedOption.NamePart = (function() {

                /**
                 * Properties of a NamePart.
                 * @memberof google.protobuf.UninterpretedOption
                 * @interface INamePart
                 * @property {string} namePart NamePart namePart
                 * @property {boolean} isExtension NamePart isExtension
                 */

                /**
                 * Constructs a new NamePart.
                 * @memberof google.protobuf.UninterpretedOption
                 * @classdesc Represents a NamePart.
                 * @implements INamePart
                 * @constructor
                 * @param {google.protobuf.UninterpretedOption.INamePart=} [properties] Properties to set
                 */
                function NamePart(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * NamePart namePart.
                 * @member {string} namePart
                 * @memberof google.protobuf.UninterpretedOption.NamePart
                 * @instance
                 */
                NamePart.prototype.namePart = "";

                /**
                 * NamePart isExtension.
                 * @member {boolean} isExtension
                 * @memberof google.protobuf.UninterpretedOption.NamePart
                 * @instance
                 */
                NamePart.prototype.isExtension = false;

                /**
                 * Creates a new NamePart instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.UninterpretedOption.NamePart
                 * @static
                 * @param {google.protobuf.UninterpretedOption.INamePart=} [properties] Properties to set
                 * @returns {google.protobuf.UninterpretedOption.NamePart} NamePart instance
                 */
                NamePart.create = function create(properties) {
                    return new NamePart(properties);
                };

                /**
                 * Encodes the specified NamePart message. Does not implicitly {@link google.protobuf.UninterpretedOption.NamePart.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.UninterpretedOption.NamePart
                 * @static
                 * @param {google.protobuf.UninterpretedOption.INamePart} message NamePart message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                NamePart.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.namePart);
                    writer.uint32(/* id 2, wireType 0 =*/16).bool(message.isExtension);
                    return writer;
                };

                /**
                 * Decodes a NamePart message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.UninterpretedOption.NamePart
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.UninterpretedOption.NamePart} NamePart
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                NamePart.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.UninterpretedOption.NamePart();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.namePart = reader.string();
                            break;
                        case 2:
                            message.isExtension = reader.bool();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    if (!message.hasOwnProperty("namePart"))
                        throw $util.ProtocolError("missing required 'namePart'", { instance: message });
                    if (!message.hasOwnProperty("isExtension"))
                        throw $util.ProtocolError("missing required 'isExtension'", { instance: message });
                    return message;
                };

                /**
                 * Verifies a NamePart message.
                 * @function verify
                 * @memberof google.protobuf.UninterpretedOption.NamePart
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                NamePart.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (!$util.isString(message.namePart))
                        return "namePart: string expected";
                    if (typeof message.isExtension !== "boolean")
                        return "isExtension: boolean expected";
                    return null;
                };

                /**
                 * Creates a NamePart message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.UninterpretedOption.NamePart
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.UninterpretedOption.NamePart} NamePart
                 */
                NamePart.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.UninterpretedOption.NamePart)
                        return object;
                    var message = new $root.google.protobuf.UninterpretedOption.NamePart();
                    if (object.namePart != null)
                        message.namePart = String(object.namePart);
                    if (object.isExtension != null)
                        message.isExtension = Boolean(object.isExtension);
                    return message;
                };

                /**
                 * Creates a plain object from a NamePart message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.UninterpretedOption.NamePart
                 * @static
                 * @param {google.protobuf.UninterpretedOption.NamePart} message NamePart
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                NamePart.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.namePart = "";
                        object.isExtension = false;
                    }
                    if (message.namePart != null && message.hasOwnProperty("namePart"))
                        object.namePart = message.namePart;
                    if (message.isExtension != null && message.hasOwnProperty("isExtension"))
                        object.isExtension = message.isExtension;
                    return object;
                };

                /**
                 * Converts this NamePart to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.UninterpretedOption.NamePart
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                NamePart.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return NamePart;
            })();

            return UninterpretedOption;
        })();

        protobuf.SourceCodeInfo = (function() {

            /**
             * Properties of a SourceCodeInfo.
             * @memberof google.protobuf
             * @interface ISourceCodeInfo
             * @property {Array.<google.protobuf.SourceCodeInfo.ILocation>|null} [location] SourceCodeInfo location
             */

            /**
             * Constructs a new SourceCodeInfo.
             * @memberof google.protobuf
             * @classdesc Represents a SourceCodeInfo.
             * @implements ISourceCodeInfo
             * @constructor
             * @param {google.protobuf.ISourceCodeInfo=} [properties] Properties to set
             */
            function SourceCodeInfo(properties) {
                this.location = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * SourceCodeInfo location.
             * @member {Array.<google.protobuf.SourceCodeInfo.ILocation>} location
             * @memberof google.protobuf.SourceCodeInfo
             * @instance
             */
            SourceCodeInfo.prototype.location = $util.emptyArray;

            /**
             * Creates a new SourceCodeInfo instance using the specified properties.
             * @function create
             * @memberof google.protobuf.SourceCodeInfo
             * @static
             * @param {google.protobuf.ISourceCodeInfo=} [properties] Properties to set
             * @returns {google.protobuf.SourceCodeInfo} SourceCodeInfo instance
             */
            SourceCodeInfo.create = function create(properties) {
                return new SourceCodeInfo(properties);
            };

            /**
             * Encodes the specified SourceCodeInfo message. Does not implicitly {@link google.protobuf.SourceCodeInfo.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.SourceCodeInfo
             * @static
             * @param {google.protobuf.ISourceCodeInfo} message SourceCodeInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SourceCodeInfo.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.location != null && message.location.length)
                    for (var i = 0; i < message.location.length; ++i)
                        $root.google.protobuf.SourceCodeInfo.Location.encode(message.location[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a SourceCodeInfo message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.SourceCodeInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.SourceCodeInfo} SourceCodeInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SourceCodeInfo.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.SourceCodeInfo();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.location && message.location.length))
                            message.location = [];
                        message.location.push($root.google.protobuf.SourceCodeInfo.Location.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a SourceCodeInfo message.
             * @function verify
             * @memberof google.protobuf.SourceCodeInfo
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SourceCodeInfo.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.location != null && message.hasOwnProperty("location")) {
                    if (!Array.isArray(message.location))
                        return "location: array expected";
                    for (var i = 0; i < message.location.length; ++i) {
                        var error = $root.google.protobuf.SourceCodeInfo.Location.verify(message.location[i]);
                        if (error)
                            return "location." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a SourceCodeInfo message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.SourceCodeInfo
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.SourceCodeInfo} SourceCodeInfo
             */
            SourceCodeInfo.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.SourceCodeInfo)
                    return object;
                var message = new $root.google.protobuf.SourceCodeInfo();
                if (object.location) {
                    if (!Array.isArray(object.location))
                        throw TypeError(".google.protobuf.SourceCodeInfo.location: array expected");
                    message.location = [];
                    for (var i = 0; i < object.location.length; ++i) {
                        if (typeof object.location[i] !== "object")
                            throw TypeError(".google.protobuf.SourceCodeInfo.location: object expected");
                        message.location[i] = $root.google.protobuf.SourceCodeInfo.Location.fromObject(object.location[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a SourceCodeInfo message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.SourceCodeInfo
             * @static
             * @param {google.protobuf.SourceCodeInfo} message SourceCodeInfo
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SourceCodeInfo.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.location = [];
                if (message.location && message.location.length) {
                    object.location = [];
                    for (var j = 0; j < message.location.length; ++j)
                        object.location[j] = $root.google.protobuf.SourceCodeInfo.Location.toObject(message.location[j], options);
                }
                return object;
            };

            /**
             * Converts this SourceCodeInfo to JSON.
             * @function toJSON
             * @memberof google.protobuf.SourceCodeInfo
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SourceCodeInfo.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            SourceCodeInfo.Location = (function() {

                /**
                 * Properties of a Location.
                 * @memberof google.protobuf.SourceCodeInfo
                 * @interface ILocation
                 * @property {Array.<number>|null} [path] Location path
                 * @property {Array.<number>|null} [span] Location span
                 * @property {string|null} [leadingComments] Location leadingComments
                 * @property {string|null} [trailingComments] Location trailingComments
                 * @property {Array.<string>|null} [leadingDetachedComments] Location leadingDetachedComments
                 */

                /**
                 * Constructs a new Location.
                 * @memberof google.protobuf.SourceCodeInfo
                 * @classdesc Represents a Location.
                 * @implements ILocation
                 * @constructor
                 * @param {google.protobuf.SourceCodeInfo.ILocation=} [properties] Properties to set
                 */
                function Location(properties) {
                    this.path = [];
                    this.span = [];
                    this.leadingDetachedComments = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Location path.
                 * @member {Array.<number>} path
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @instance
                 */
                Location.prototype.path = $util.emptyArray;

                /**
                 * Location span.
                 * @member {Array.<number>} span
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @instance
                 */
                Location.prototype.span = $util.emptyArray;

                /**
                 * Location leadingComments.
                 * @member {string} leadingComments
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @instance
                 */
                Location.prototype.leadingComments = "";

                /**
                 * Location trailingComments.
                 * @member {string} trailingComments
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @instance
                 */
                Location.prototype.trailingComments = "";

                /**
                 * Location leadingDetachedComments.
                 * @member {Array.<string>} leadingDetachedComments
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @instance
                 */
                Location.prototype.leadingDetachedComments = $util.emptyArray;

                /**
                 * Creates a new Location instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @static
                 * @param {google.protobuf.SourceCodeInfo.ILocation=} [properties] Properties to set
                 * @returns {google.protobuf.SourceCodeInfo.Location} Location instance
                 */
                Location.create = function create(properties) {
                    return new Location(properties);
                };

                /**
                 * Encodes the specified Location message. Does not implicitly {@link google.protobuf.SourceCodeInfo.Location.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @static
                 * @param {google.protobuf.SourceCodeInfo.ILocation} message Location message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Location.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.path != null && message.path.length) {
                        writer.uint32(/* id 1, wireType 2 =*/10).fork();
                        for (var i = 0; i < message.path.length; ++i)
                            writer.int32(message.path[i]);
                        writer.ldelim();
                    }
                    if (message.span != null && message.span.length) {
                        writer.uint32(/* id 2, wireType 2 =*/18).fork();
                        for (var i = 0; i < message.span.length; ++i)
                            writer.int32(message.span[i]);
                        writer.ldelim();
                    }
                    if (message.leadingComments != null && Object.hasOwnProperty.call(message, "leadingComments"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.leadingComments);
                    if (message.trailingComments != null && Object.hasOwnProperty.call(message, "trailingComments"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.trailingComments);
                    if (message.leadingDetachedComments != null && message.leadingDetachedComments.length)
                        for (var i = 0; i < message.leadingDetachedComments.length; ++i)
                            writer.uint32(/* id 6, wireType 2 =*/50).string(message.leadingDetachedComments[i]);
                    return writer;
                };

                /**
                 * Decodes a Location message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.SourceCodeInfo.Location} Location
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Location.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.SourceCodeInfo.Location();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            if (!(message.path && message.path.length))
                                message.path = [];
                            if ((tag & 7) === 2) {
                                var end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.path.push(reader.int32());
                            } else
                                message.path.push(reader.int32());
                            break;
                        case 2:
                            if (!(message.span && message.span.length))
                                message.span = [];
                            if ((tag & 7) === 2) {
                                var end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.span.push(reader.int32());
                            } else
                                message.span.push(reader.int32());
                            break;
                        case 3:
                            message.leadingComments = reader.string();
                            break;
                        case 4:
                            message.trailingComments = reader.string();
                            break;
                        case 6:
                            if (!(message.leadingDetachedComments && message.leadingDetachedComments.length))
                                message.leadingDetachedComments = [];
                            message.leadingDetachedComments.push(reader.string());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Verifies a Location message.
                 * @function verify
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Location.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.path != null && message.hasOwnProperty("path")) {
                        if (!Array.isArray(message.path))
                            return "path: array expected";
                        for (var i = 0; i < message.path.length; ++i)
                            if (!$util.isInteger(message.path[i]))
                                return "path: integer[] expected";
                    }
                    if (message.span != null && message.hasOwnProperty("span")) {
                        if (!Array.isArray(message.span))
                            return "span: array expected";
                        for (var i = 0; i < message.span.length; ++i)
                            if (!$util.isInteger(message.span[i]))
                                return "span: integer[] expected";
                    }
                    if (message.leadingComments != null && message.hasOwnProperty("leadingComments"))
                        if (!$util.isString(message.leadingComments))
                            return "leadingComments: string expected";
                    if (message.trailingComments != null && message.hasOwnProperty("trailingComments"))
                        if (!$util.isString(message.trailingComments))
                            return "trailingComments: string expected";
                    if (message.leadingDetachedComments != null && message.hasOwnProperty("leadingDetachedComments")) {
                        if (!Array.isArray(message.leadingDetachedComments))
                            return "leadingDetachedComments: array expected";
                        for (var i = 0; i < message.leadingDetachedComments.length; ++i)
                            if (!$util.isString(message.leadingDetachedComments[i]))
                                return "leadingDetachedComments: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a Location message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.SourceCodeInfo.Location} Location
                 */
                Location.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.SourceCodeInfo.Location)
                        return object;
                    var message = new $root.google.protobuf.SourceCodeInfo.Location();
                    if (object.path) {
                        if (!Array.isArray(object.path))
                            throw TypeError(".google.protobuf.SourceCodeInfo.Location.path: array expected");
                        message.path = [];
                        for (var i = 0; i < object.path.length; ++i)
                            message.path[i] = object.path[i] | 0;
                    }
                    if (object.span) {
                        if (!Array.isArray(object.span))
                            throw TypeError(".google.protobuf.SourceCodeInfo.Location.span: array expected");
                        message.span = [];
                        for (var i = 0; i < object.span.length; ++i)
                            message.span[i] = object.span[i] | 0;
                    }
                    if (object.leadingComments != null)
                        message.leadingComments = String(object.leadingComments);
                    if (object.trailingComments != null)
                        message.trailingComments = String(object.trailingComments);
                    if (object.leadingDetachedComments) {
                        if (!Array.isArray(object.leadingDetachedComments))
                            throw TypeError(".google.protobuf.SourceCodeInfo.Location.leadingDetachedComments: array expected");
                        message.leadingDetachedComments = [];
                        for (var i = 0; i < object.leadingDetachedComments.length; ++i)
                            message.leadingDetachedComments[i] = String(object.leadingDetachedComments[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Location message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @static
                 * @param {google.protobuf.SourceCodeInfo.Location} message Location
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Location.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults) {
                        object.path = [];
                        object.span = [];
                        object.leadingDetachedComments = [];
                    }
                    if (options.defaults) {
                        object.leadingComments = "";
                        object.trailingComments = "";
                    }
                    if (message.path && message.path.length) {
                        object.path = [];
                        for (var j = 0; j < message.path.length; ++j)
                            object.path[j] = message.path[j];
                    }
                    if (message.span && message.span.length) {
                        object.span = [];
                        for (var j = 0; j < message.span.length; ++j)
                            object.span[j] = message.span[j];
                    }
                    if (message.leadingComments != null && message.hasOwnProperty("leadingComments"))
                        object.leadingComments = message.leadingComments;
                    if (message.trailingComments != null && message.hasOwnProperty("trailingComments"))
                        object.trailingComments = message.trailingComments;
                    if (message.leadingDetachedComments && message.leadingDetachedComments.length) {
                        object.leadingDetachedComments = [];
                        for (var j = 0; j < message.leadingDetachedComments.length; ++j)
                            object.leadingDetachedComments[j] = message.leadingDetachedComments[j];
                    }
                    return object;
                };

                /**
                 * Converts this Location to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Location.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return Location;
            })();

            return SourceCodeInfo;
        })();

        protobuf.GeneratedCodeInfo = (function() {

            /**
             * Properties of a GeneratedCodeInfo.
             * @memberof google.protobuf
             * @interface IGeneratedCodeInfo
             * @property {Array.<google.protobuf.GeneratedCodeInfo.IAnnotation>|null} [annotation] GeneratedCodeInfo annotation
             */

            /**
             * Constructs a new GeneratedCodeInfo.
             * @memberof google.protobuf
             * @classdesc Represents a GeneratedCodeInfo.
             * @implements IGeneratedCodeInfo
             * @constructor
             * @param {google.protobuf.IGeneratedCodeInfo=} [properties] Properties to set
             */
            function GeneratedCodeInfo(properties) {
                this.annotation = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GeneratedCodeInfo annotation.
             * @member {Array.<google.protobuf.GeneratedCodeInfo.IAnnotation>} annotation
             * @memberof google.protobuf.GeneratedCodeInfo
             * @instance
             */
            GeneratedCodeInfo.prototype.annotation = $util.emptyArray;

            /**
             * Creates a new GeneratedCodeInfo instance using the specified properties.
             * @function create
             * @memberof google.protobuf.GeneratedCodeInfo
             * @static
             * @param {google.protobuf.IGeneratedCodeInfo=} [properties] Properties to set
             * @returns {google.protobuf.GeneratedCodeInfo} GeneratedCodeInfo instance
             */
            GeneratedCodeInfo.create = function create(properties) {
                return new GeneratedCodeInfo(properties);
            };

            /**
             * Encodes the specified GeneratedCodeInfo message. Does not implicitly {@link google.protobuf.GeneratedCodeInfo.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.GeneratedCodeInfo
             * @static
             * @param {google.protobuf.IGeneratedCodeInfo} message GeneratedCodeInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GeneratedCodeInfo.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.annotation != null && message.annotation.length)
                    for (var i = 0; i < message.annotation.length; ++i)
                        $root.google.protobuf.GeneratedCodeInfo.Annotation.encode(message.annotation[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a GeneratedCodeInfo message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.GeneratedCodeInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.GeneratedCodeInfo} GeneratedCodeInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GeneratedCodeInfo.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.GeneratedCodeInfo();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.annotation && message.annotation.length))
                            message.annotation = [];
                        message.annotation.push($root.google.protobuf.GeneratedCodeInfo.Annotation.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a GeneratedCodeInfo message.
             * @function verify
             * @memberof google.protobuf.GeneratedCodeInfo
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GeneratedCodeInfo.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.annotation != null && message.hasOwnProperty("annotation")) {
                    if (!Array.isArray(message.annotation))
                        return "annotation: array expected";
                    for (var i = 0; i < message.annotation.length; ++i) {
                        var error = $root.google.protobuf.GeneratedCodeInfo.Annotation.verify(message.annotation[i]);
                        if (error)
                            return "annotation." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a GeneratedCodeInfo message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.GeneratedCodeInfo
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.GeneratedCodeInfo} GeneratedCodeInfo
             */
            GeneratedCodeInfo.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.GeneratedCodeInfo)
                    return object;
                var message = new $root.google.protobuf.GeneratedCodeInfo();
                if (object.annotation) {
                    if (!Array.isArray(object.annotation))
                        throw TypeError(".google.protobuf.GeneratedCodeInfo.annotation: array expected");
                    message.annotation = [];
                    for (var i = 0; i < object.annotation.length; ++i) {
                        if (typeof object.annotation[i] !== "object")
                            throw TypeError(".google.protobuf.GeneratedCodeInfo.annotation: object expected");
                        message.annotation[i] = $root.google.protobuf.GeneratedCodeInfo.Annotation.fromObject(object.annotation[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a GeneratedCodeInfo message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.GeneratedCodeInfo
             * @static
             * @param {google.protobuf.GeneratedCodeInfo} message GeneratedCodeInfo
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GeneratedCodeInfo.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.annotation = [];
                if (message.annotation && message.annotation.length) {
                    object.annotation = [];
                    for (var j = 0; j < message.annotation.length; ++j)
                        object.annotation[j] = $root.google.protobuf.GeneratedCodeInfo.Annotation.toObject(message.annotation[j], options);
                }
                return object;
            };

            /**
             * Converts this GeneratedCodeInfo to JSON.
             * @function toJSON
             * @memberof google.protobuf.GeneratedCodeInfo
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GeneratedCodeInfo.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            GeneratedCodeInfo.Annotation = (function() {

                /**
                 * Properties of an Annotation.
                 * @memberof google.protobuf.GeneratedCodeInfo
                 * @interface IAnnotation
                 * @property {Array.<number>|null} [path] Annotation path
                 * @property {string|null} [sourceFile] Annotation sourceFile
                 * @property {number|null} [begin] Annotation begin
                 * @property {number|null} [end] Annotation end
                 */

                /**
                 * Constructs a new Annotation.
                 * @memberof google.protobuf.GeneratedCodeInfo
                 * @classdesc Represents an Annotation.
                 * @implements IAnnotation
                 * @constructor
                 * @param {google.protobuf.GeneratedCodeInfo.IAnnotation=} [properties] Properties to set
                 */
                function Annotation(properties) {
                    this.path = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Annotation path.
                 * @member {Array.<number>} path
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @instance
                 */
                Annotation.prototype.path = $util.emptyArray;

                /**
                 * Annotation sourceFile.
                 * @member {string} sourceFile
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @instance
                 */
                Annotation.prototype.sourceFile = "";

                /**
                 * Annotation begin.
                 * @member {number} begin
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @instance
                 */
                Annotation.prototype.begin = 0;

                /**
                 * Annotation end.
                 * @member {number} end
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @instance
                 */
                Annotation.prototype.end = 0;

                /**
                 * Creates a new Annotation instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @static
                 * @param {google.protobuf.GeneratedCodeInfo.IAnnotation=} [properties] Properties to set
                 * @returns {google.protobuf.GeneratedCodeInfo.Annotation} Annotation instance
                 */
                Annotation.create = function create(properties) {
                    return new Annotation(properties);
                };

                /**
                 * Encodes the specified Annotation message. Does not implicitly {@link google.protobuf.GeneratedCodeInfo.Annotation.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @static
                 * @param {google.protobuf.GeneratedCodeInfo.IAnnotation} message Annotation message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Annotation.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.path != null && message.path.length) {
                        writer.uint32(/* id 1, wireType 2 =*/10).fork();
                        for (var i = 0; i < message.path.length; ++i)
                            writer.int32(message.path[i]);
                        writer.ldelim();
                    }
                    if (message.sourceFile != null && Object.hasOwnProperty.call(message, "sourceFile"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.sourceFile);
                    if (message.begin != null && Object.hasOwnProperty.call(message, "begin"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int32(message.begin);
                    if (message.end != null && Object.hasOwnProperty.call(message, "end"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int32(message.end);
                    return writer;
                };

                /**
                 * Decodes an Annotation message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.GeneratedCodeInfo.Annotation} Annotation
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Annotation.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.GeneratedCodeInfo.Annotation();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            if (!(message.path && message.path.length))
                                message.path = [];
                            if ((tag & 7) === 2) {
                                var end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.path.push(reader.int32());
                            } else
                                message.path.push(reader.int32());
                            break;
                        case 2:
                            message.sourceFile = reader.string();
                            break;
                        case 3:
                            message.begin = reader.int32();
                            break;
                        case 4:
                            message.end = reader.int32();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Verifies an Annotation message.
                 * @function verify
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Annotation.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.path != null && message.hasOwnProperty("path")) {
                        if (!Array.isArray(message.path))
                            return "path: array expected";
                        for (var i = 0; i < message.path.length; ++i)
                            if (!$util.isInteger(message.path[i]))
                                return "path: integer[] expected";
                    }
                    if (message.sourceFile != null && message.hasOwnProperty("sourceFile"))
                        if (!$util.isString(message.sourceFile))
                            return "sourceFile: string expected";
                    if (message.begin != null && message.hasOwnProperty("begin"))
                        if (!$util.isInteger(message.begin))
                            return "begin: integer expected";
                    if (message.end != null && message.hasOwnProperty("end"))
                        if (!$util.isInteger(message.end))
                            return "end: integer expected";
                    return null;
                };

                /**
                 * Creates an Annotation message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.GeneratedCodeInfo.Annotation} Annotation
                 */
                Annotation.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.GeneratedCodeInfo.Annotation)
                        return object;
                    var message = new $root.google.protobuf.GeneratedCodeInfo.Annotation();
                    if (object.path) {
                        if (!Array.isArray(object.path))
                            throw TypeError(".google.protobuf.GeneratedCodeInfo.Annotation.path: array expected");
                        message.path = [];
                        for (var i = 0; i < object.path.length; ++i)
                            message.path[i] = object.path[i] | 0;
                    }
                    if (object.sourceFile != null)
                        message.sourceFile = String(object.sourceFile);
                    if (object.begin != null)
                        message.begin = object.begin | 0;
                    if (object.end != null)
                        message.end = object.end | 0;
                    return message;
                };

                /**
                 * Creates a plain object from an Annotation message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @static
                 * @param {google.protobuf.GeneratedCodeInfo.Annotation} message Annotation
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Annotation.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.path = [];
                    if (options.defaults) {
                        object.sourceFile = "";
                        object.begin = 0;
                        object.end = 0;
                    }
                    if (message.path && message.path.length) {
                        object.path = [];
                        for (var j = 0; j < message.path.length; ++j)
                            object.path[j] = message.path[j];
                    }
                    if (message.sourceFile != null && message.hasOwnProperty("sourceFile"))
                        object.sourceFile = message.sourceFile;
                    if (message.begin != null && message.hasOwnProperty("begin"))
                        object.begin = message.begin;
                    if (message.end != null && message.hasOwnProperty("end"))
                        object.end = message.end;
                    return object;
                };

                /**
                 * Converts this Annotation to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Annotation.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return Annotation;
            })();

            return GeneratedCodeInfo;
        })();

        return protobuf;
    })();

    return google;
})();

module.exports = $root;

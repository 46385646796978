import { KEY_STATUS, MAP_ORDER_STATUS_TRANSLATION_KEY } from 'constants/index';

export const getOrderStatus = (order) => {
    const source = order.source ?? 0;

    return order[KEY_STATUS[source] ?? 0];
};

export const getOrderStatusTranslationKey = (order) => {
    const ORDER_STATUS_TRANSLATION_KEY = MAP_ORDER_STATUS_TRANSLATION_KEY[order.source ?? 0] ?? {};

    return ORDER_STATUS_TRANSLATION_KEY[order.status] ?? 'n/a';
};
